// @flow
import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import { Grid, GridItem, Section } from '../../../../components/layout';
import { AntTable } from '../../../../components/ui';

import { federalHighwayCalculationApi, federalHighwayCalculationMonthApi, vehiclePlanApi } from '../../../../lib/api';
import { calculationStatusEnum } from '../../../../lib/enum';
import { getListInitialState, toLocalStringRu } from '../../../../lib/helpers';
import type { FederalHighwayCalculation, ListState, VehiclePlan } from '../../../../lib/types';

import type { VehicleListFilterParams } from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';

import { COLUMNS, filteredData, itogCalculation, MONTH } from '../lib';
import { notificationLoading } from './../../../../components/Notifications';

import { headerPanel } from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = ListState<FederalHighwayCalculation> & {
  vehiclePlan: ?VehiclePlan,
  columns: any,
  visible: boolean,
  rowIndex?: number,
  monthIndex?: number,
  filter: VehicleListFilterParams,
  federalHighwayCalculation: ?FederalHighwayCalculation
};

export default class extends Component<Props, State> {
  state = {
    vehiclePlan: null,
    ...getListInitialState(),
    columns: [
      ...COLUMNS,
      ...MONTH.map((month, monthIndex) => ({
        title: month.title,
        children: [
          {
            title: 'План. километраж',
            dataIndex: `months[${monthIndex}].plannedKilometrage`,
            onCell: (
              federalHighwayCalculation: FederalHighwayCalculation,
              rowIndex: number
            ) => {
              return {
                onClick:
                  this.isDraft() &&
                  this.isRangeMonths(monthIndex) &&
                  !this.afterPlannedWriteoffDate(
                    monthIndex,
                    federalHighwayCalculation.selfVehiclePlanVehicle?.vehicle
                      ?.plannedWriteoffDate ||
                      federalHighwayCalculation.selfVehiclePlanVehicle
                        ?.plannedWriteoffDate
                  )
                    ? () => {
                        this.setState({
                          rowIndex,
                          monthIndex,
                          visible: true,
                          federalHighwayCalculation: {
                            ...federalHighwayCalculation
                          }
                        });
                      }
                    : undefined
              };
            },
            render: (
              plannedKilometrage: number,
              federalHighwayCalculation: FederalHighwayCalculation
            ) => (
              <span
                className={
                  this.isDraft() &&
                  this.isRangeMonths(monthIndex) &&
                  !this.afterPlannedWriteoffDate(
                    monthIndex,
                    federalHighwayCalculation?.selfVehiclePlanVehicle?.vehicle
                      ?.plannedWriteoffDate ||
                      federalHighwayCalculation?.selfVehiclePlanVehicle
                        ?.plannedWriteoffDate
                  )
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(plannedKilometrage)}
              </span>
            )
          },
          {
            title: 'Стоимость',
            className: 'primary-background',
            dataIndex: `months[${monthIndex}].cost`,
            render: cost => toLocalStringRu(cost)
          }
        ]
      })),
      {
        title: 'Итог',
        width: 100,
        key: 'sum',
        dataIndex: 'sum',
        render: sum => toLocalStringRu(sum)
      }
    ],
    filter: {},
    visible: false,
    rowIndex: undefined,
    monthIndex: undefined,
    federalHighwayCalculation: null
  };

  componentDidMount() {
    this.getData();
  }

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  // дата после списания
  afterPlannedWriteoffDate = (
    monthIndex: number,
    plannedWriteoffDate?: ?string
  ) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan && plannedWriteoffDate) {
      const year = moment(vehiclePlan.date).get('year');
      return moment(`${year}-${monthIndex + 1}-01`).isAfter(
        plannedWriteoffDate,
        'month'
      );
    }
    return false;
  };

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;
    this.setState({ loading: true });
    try {
      let { data } = await federalHighwayCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data });
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  handleSave = async (federalHighwayCalculation: FederalHighwayCalculation) => {
    const { data, monthIndex = 0 } = this.state;
    this.setState({ loading: true });
    const findedRowIndex = data.findIndex(
      item => item.id === federalHighwayCalculation.id
    );
    try {
      if (federalHighwayCalculation && findedRowIndex !== -1) {
        await federalHighwayCalculationMonthApi.updateCalculations(
          federalHighwayCalculation.months[monthIndex]
        );
        data[findedRowIndex] = await federalHighwayCalculationApi.get(
          federalHighwayCalculation.id
        );
        this.setState({ data });
      }
    } catch (error) {
      notification.warning({ message: 'Не удалось обновить данные' + error });
    } finally {
      this.setState({
        loading: false,
        visible: false,
        rowIndex: undefined,
        federalHighwayCalculation: null,
        monthIndex: undefined
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      rowIndex: undefined,
      monthIndex: undefined,
      federalHighwayCalculation: null
    });
  };

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await federalHighwayCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    const { data } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      if (data.length === 0)
        await federalHighwayCalculationApi.calculate(vehiclePlanId);
      else await federalHighwayCalculationApi.updateCalculations(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.federalHighwayCalculationStatus ===
    calculationStatusEnum.draft;

  applyFilter = (filter: VehicleListFilterParams) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  render() {
    const { vehiclePlanId } = this.props;
    const {
      monthIndex = 0,
      columns,
      visible,
      loading,
      filter,
      data,
      federalHighwayCalculation
    } = this.state;

    const filtered = filteredData(data, filter);
    const itog = itogCalculation(filtered);

    return (
      <>
        {headerPanel({
          vehiclePlanId,
          title: 'Планирование потребности проезда по федеральным трассам'
        })}
        <Section>
          <div style={{ padding: '16px 16px 0px' }}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            scroll={{
              x: 4000,
              y: 'calc(100vh - 280px)'
            }}
            columns={columns}
          />
          <ModalFederalHighWay
            visible={visible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            monthIndex={monthIndex || 0}
            federalHighwayCalculation={federalHighwayCalculation}
          />
        </Section>
        {this.isDraft() && (
          <Section style={{ padding: '16px' }}>
            <Button type="primary" onClick={this.calculate}>
              {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              type="primary"
              onClick={this.changeStatus}
            >
              Утвердить
            </Button>
          </Section>
        )}
      </>
    );
  }
}

type ModalFederalHighwayProps = {
  visible: boolean,
  handleSave: Function,
  handleCancel: Function,
  monthIndex: number,
  federalHighwayCalculation: ?FederalHighwayCalculation
};

const ModalFederalHighWay = (props: ModalFederalHighwayProps) => {
  const {
    visible,
    handleSave,
    handleCancel,
    monthIndex,
    federalHighwayCalculation
  } = props;
  const [localFederalHighwayCalculation, setFederalHighway] = useState(
    cloneDeep(federalHighwayCalculation)
  );
  const onOk = () => {
    handleSave(localFederalHighwayCalculation);
  };
  const onCancel = () => {
    handleCancel();
  };

  useEffect(() => {
    if (federalHighwayCalculation) {
      setFederalHighway(cloneDeep(federalHighwayCalculation));
    }
  }, [federalHighwayCalculation]);

  return (
    <Modal
      forceRender
      destroyOnClose
      width={400}
      title="Редактирование"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Grid gutter="16px">
        <GridItem fullWidth>
          Планируемый пробег в месяц по трассам федерального значения
          <InputNumber
            defaultValue={
              federalHighwayCalculation?.months[monthIndex]
                .plannedKilometrage ?? 0
            }
            min={0}
            style={{ width: '100%' }}
            onChange={kilometrage => {
              if (localFederalHighwayCalculation) {
                localFederalHighwayCalculation.months[
                  monthIndex
                ].plannedKilometrage = +kilometrage;
                setFederalHighway(localFederalHighwayCalculation);
              }
            }}
            onPressEnter={onOk}
          />
        </GridItem>
      </Grid>
    </Modal>
  );
};
