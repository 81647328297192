import { TaxCard, TaxForm, TaxList } from '../../containers/budget/tax';
import { accessTypeEnum } from '../../lib/enum';

// бюджет налоги
export default [
    {
        component: TaxList,
        path: '/budget/tax',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingBranchVehicleTaxPlans,
            accessTypeEnum.handlingAllVehicleTaxPlans,
            accessTypeEnum.viewingBranchVehicleTaxPlans,
            accessTypeEnum.viewingAllVehicleTaxPlans,
        ],
    },
    {
        component: TaxForm,
        path: '/budget/tax/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingBranchVehicleTaxPlans,
            accessTypeEnum.handlingAllVehicleTaxPlans,
        ],
    },
    {
        component: TaxCard,
        path: '/budget/tax/:vehicleTaxPlanId/card',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingBranchVehicleTaxPlans,
            accessTypeEnum.handlingAllVehicleTaxPlans,
            accessTypeEnum.viewingBranchVehicleTaxPlans,
            accessTypeEnum.viewingAllVehicleTaxPlans,
        ],
    },
];