// @flow
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';

import { formatDateTimeToString, navigate } from '../../../../lib/helpers';
import { writeOffActApi } from '../../../../lib/api';
import type { Platon, UserAccess } from '../../../../lib/types';

import CommonListPage from '../../../../components/hoc/common/handbook/ListPage';
import Page from '../../../../components/hoc/common/handbook/PageContext';

import { withUserAccess } from '../../../withUserAccess';

import { Tabs } from '../../index';
import { Tabs as InternalTabs } from './../components';

import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
};

const columns = [
  {
    title: '№ документа',
    key: 'documentNumber',
    dataIndex: 'documentNumber',
  },
  {
    title: 'Период списания',
    key: 'period',
    dataIndex: 'startDate',
    render: (startDate: string, record: Platon) => {
      return `${formatDateTimeToString(
        record.startDate,
        'DD.MM.YYYY'
      )} - ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY')}`;
    },
  },
  {
    title: 'Дата документа',
    key: 'documentDate',
    dataIndex: 'documentDate',
    render: (documentDate) =>
      formatDateTimeToString(documentDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Сумма',
    key: 'sum',
    dataIndex: 'sum',
  },
  {
    title: '',
    key: 'file',
    dataIndex: 'file',
    render: (file) => {
      return file && file.url ? (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="small"
          href={`${file.url}?isInline=false`}
          target="_blank"
        />
      ) : null;
    },
  },
];

const entryPointPath = '/budget/act/internal/mpz/';

export class ActList extends React.Component<Props> {
  render() {
    const access = {
      add: () => navigate(`${entryPointPath}new/create`),
    };

    return (
      <Page>
        <CommonListPage
          crud={writeOffActApi}
          pageHeaderProps={{
            mainHeader: 'Акт списания МПЗ',
            access,
            rightHeader: (
              <>
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => navigate(`${entryPointPath}new/generate`)}
                  type="primary"
                >
                  Сформировать
                </Button>
              </>
            ),
          }}
          subHeader={
            <>
              <Tabs />
              <InternalTabs />
            </>
          }
          tableListProps={{
            columns,
          }}
          filter={Filter}
        />
      </Page>
    );
  }
}

export default withUserAccess(ActList);
