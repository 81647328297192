// @flow

import React, { useState, useEffect } from 'react';
import Button from 'antd/lib/button';
import type { FormikProps } from 'formik';

import { Selects, Form } from '../../../components';
import { Table } from '../../../components/ant/table/Table';
import { Icon } from '../../../components/ui';
import { InputNumber } from '../../../components/inputs';
import type {
  MaintenanceRegulation,
  MaintenanceRegulationOperation,
  MaintenanceRegulationMaterial,
} from '../../../lib/types';
import { Section, SectionTitle } from './../../../components/layout';
import { GridItem } from '../../../components/layout';
import CancelButton from '../../../components/CancelButton';
import {
  Footer,
  Content,
  StyledGrid,
  Operations,
} from './TORegulationForm.elements';
import { vehicleModelApi } from '../../../lib/api';
import { withEmptyRow, showWorkEngineHours } from '../../../lib/helpers';

const {
  MaintenanceWorkTypeSelect,
  SparePartSelect,
  MaintenanceOperationGroupSelect,
} = Selects;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  vehicleModelRegulation: ?MaintenanceRegulation,
};

const TORegulationForm = ({
  onSubmit,
  onCancel,
  vehicleModelRegulation,
}: FormProps) => {
  const [vehicleModel, setVehicleModel] = useState({});
  const getModel = async (id: number) => {
    try {
      const vehicleModel = await vehicleModelApi.fetchVehicleModel(id);
      setVehicleModel(vehicleModel);
    } catch (e) { }
  }
  useEffect(() => {
    if (vehicleModelRegulation?.vehicleModelId) {
      getModel(Number(vehicleModelRegulation.vehicleModelId));
    }
  }, [vehicleModelRegulation?.vehicleModelId]);
  return !!vehicleModelRegulation?.vehicleModelId && (
    <Form initialValues={vehicleModelRegulation} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps<MaintenanceRegulation>) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          dirty,
          isSubmitting,
          values,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <StyledGrid gutter="16px">
                  <GridItem>
                    <FormField
                      name="maintenanceWorkTypeId"
                      label="Вид работ"
                      fast
                    >
                      {({ name, value }) => (
                        <MaintenanceWorkTypeSelect
                          name={name}
                          value={value}
                          onChange={(workTypeId: number) =>
                            setFieldValue(name, workTypeId)
                          }
                          onBlur={() => handleBlur({ target: { name } })}
                          data-cy="workTypeSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    {showWorkEngineHours({ vehicleModel }) ? (
                      <FormField
                        label="Периодичность прохождения ТО, моточасы"
                        name="workEngineHours"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            decimalSeparator=","
                            step={0.1}
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    ) : (
                        <FormField
                          label="Периодичность прохождения ТО, км"
                          name="kilometrage"
                        >
                          {
                            ({ value, name }) => (
                              <InputNumber
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={
                                  (value: number) =>
                                    setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                        </FormField>
                      )}
                  </GridItem>
                </StyledGrid>
                <SectionTitle>Материалы и запчасти</SectionTitle>
                <StyledGrid>
                  <GridItem>
                    <FormField name="materials">
                      {({ name, value = [] }) => {
                        return (
                          <Table
                            pagination={false}
                            columns={[
                              {
                                title: 'Деталь',
                                dataIndex: 'sparePartId',
                                width: 350,
                                render: (
                                  sparePartId: number,
                                  data: MaintenanceRegulationMaterial,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].sparePartId`}
                                    >
                                      {({ name, value }) => (
                                        <SparePartSelect
                                          allVehicles
                                          vehicleModelId={values.vehicleModelId}
                                          value={value}
                                          name={name}
                                          onChange={(value: number) =>
                                            setFieldValue(name, value)
                                          }
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                title: 'Количество',
                                dataIndex: 'count',
                                render: (
                                  count: number,
                                  data: MaintenanceRegulationMaterial,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].count`}
                                    >
                                      {({ name, value }) => (
                                        <InputNumber
                                          name={name}
                                          value={value}
                                          onChange={(value: number) =>
                                            setFieldValue(name, value)
                                          }
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                key: 'operations',
                                render: (
                                  _,
                                  record: MaintenanceRegulationMaterial,
                                  index: number
                                ) => {
                                  return (
                                    <Operations>
                                      <Icon
                                        type="x"
                                        onClick={() => {
                                          const array = [...value];
                                          array.splice(index, 1);
                                          setFieldValue(name, array);
                                        }}
                                        size={12}
                                      />
                                    </Operations>
                                  );
                                },
                              },
                            ]}
                            dataSource={withEmptyRow(value)}
                          />
                        );
                      }}
                    </FormField>
                  </GridItem>
                </StyledGrid>
                <SectionTitle>Выполняемые работы</SectionTitle>
                <StyledGrid>
                  <GridItem>
                    <FormField name="operations">
                      {({ name, value = [] }) => {
                        return (
                          <Table
                            pagination={false}
                            columns={[
                              {
                                title: 'Вид работ',
                                dataIndex: 'operationId',
                                width: 350,
                                render: (
                                  stockPartId: number,
                                  data: MaintenanceRegulationOperation,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].operationId`}
                                    >
                                      {({ name, value }) => (
                                        <MaintenanceOperationGroupSelect
                                          value={value}
                                          name={name}
                                          onChange={(value: number) =>
                                            setFieldValue(name, value)
                                          }
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                title: 'Количество',
                                dataIndex: 'count',
                                render: (
                                  count: number,
                                  data: MaintenanceRegulationOperation,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].count`}
                                    >
                                      {({ name, value }) => (
                                        <InputNumber
                                          name={name}
                                          value={value}
                                          onChange={(value: number) =>
                                            setFieldValue(name, value)
                                          }
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                key: 'operations',
                                render: (
                                  _,
                                  record: MaintenanceRegulationOperation,
                                  index: number
                                ) => {
                                  return (
                                    <Operations>
                                      <Icon
                                        type="x"
                                        onClick={() => {
                                          const array = [...value];
                                          array.splice(index, 1);
                                          setFieldValue(name, array);
                                        }}
                                        size={12}
                                      />
                                    </Operations>
                                  );
                                },
                              },
                            ]}
                            dataSource={withEmptyRow(value)}
                          />
                        );
                      }}
                    </FormField>
                  </GridItem>
                </StyledGrid>
              </Content>
            </Section>
            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                data-cy="save"
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  )
};

export default TORegulationForm;
