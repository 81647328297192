// @flow
import React, { useState } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import moment from 'moment';
import styled from 'styled-components';
import { FormikProps } from 'formik';

import type { SideServiceTariff } from '../../../lib/types';
import { sideServiceTypeEnum } from '../../../lib/enum';

import { DatePicker } from '../../../components/ant/DatePicker';
import { InputNumber } from './../../../components/inputs';
import CancelButton from './../../../components/CancelButton';
import type { FormFieldType } from './../../../components/Form';
import Form from './../../../components/Form';
import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import Selects from './../../../components/selects';
import OrgUnitSelect from './../../../components/OrgUnitSelect';
import { LicensePlateInput } from './../../../components/inputs/masked-inputs';

const {
  SideServiceTypeSelect,
  SideServiceTariffMeasureSelect,
  VehicleTypeSelect,
  SideServiceTariffTypeSelect,
} = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  sideServiceTariff: ?SideServiceTariff,
  onCancel: Function,
  onSubmit: Function,
};

export default ({ sideServiceTariff, onCancel, onSubmit }: Props) => {
  const handleSubmit = async (sideServiceTariff: SideServiceTariff) => {
    onSubmit(sideServiceTariff);
  };
  return (
    <Form initialValues={sideServiceTariff} onSubmit={handleSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting,
        }: FormikProps
      ) => {
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <Grid gutter="16px" cols={2}>
                <GridItem>
                  <FormField label="Подразделение" name="orgUnitId" required>
                    {({ name, value }) => (
                      <OrgUnitSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип услуги" name="sideServiceType" required>
                    {({ name, value }) => (
                      <SideServiceTypeSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата начала" name="startDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                          setFieldValue('endDate', undefined);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата окончания" name="endDate">
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        disabled={values.startDate ? false : true}
                        disabledDate={(date: string) =>
                          values.startDate
                            ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                            : false
                        }
                        onChange={(value: string) =>
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          )
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Тип тс"
                    name="vehicleType"
                    required={
                      values.sideServiceType ===
                      sideServiceTypeEnum.providingEquipment
                    }
                  >
                    {({ name, value }) => (
                      <VehicleTypeSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена, руб" name="price" required>
                    {({ name, value }) => (
                      <InputNumber
                        fullWidth
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Тип учета"
                    name="sideServiceTariffMeasure"
                    required
                  >
                    {({ name, value }) => (
                      <SideServiceTariffMeasureSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Тип контракта услуги"
                    name="sideServiceTariffType"
                    required
                  >
                    {({ name, value }) => (
                      <SideServiceTariffTypeSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </StyledSection>

            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                onClick={handleSubmit}
              >
                {values.id ? 'Сохранить' : 'Создать'}
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
