// @flow
import { fetchRequest } from './index';
import type { TripAttachedEquipment } from './../types';
import CommonApi from '../commonApi';
import { convertFromHourToSecond, convertFromSecondToHour } from '../helpers';

export const convertDataToServer = (
  attachedEquipment: TripAttachedEquipment
): TripAttachedEquipment => {
  return {
    ...attachedEquipment,
    machineHoursAtStart: convertFromHourToSecond(
      attachedEquipment.machineHoursAtStart
    ),
    machineHoursAtEnd: convertFromHourToSecond(
      attachedEquipment.machineHoursAtEnd
    ),
  };
};

export const convertDataFromServer = (
  attachedEquipment: TripAttachedEquipment
): TripAttachedEquipment => ({
  ...attachedEquipment,
  machineHoursAtStart: convertFromSecondToHour(
    attachedEquipment.machineHoursAtStart
  ),
  machineHoursAtEnd: convertFromSecondToHour(
    attachedEquipment.machineHoursAtEnd
  ),
});

const basedUrl = 'tripAttachedEquipment';
const vehiclePlan = new CommonApi<TripAttachedEquipment>(
  basedUrl,
  'путевой лист наверсное оборудование',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...vehiclePlan,
};
