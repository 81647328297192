// @flow
import React, { Component } from 'react';
import styled from 'styled-components';

import type { AssignmentLimit } from './../../../../../../lib/types';
import { assignmentLimitApi } from './../../../../../../lib/api';
import {
  convertContractVehicleToString,
  convertEmployeeToString
} from './../../../../../../lib/helpers';

import {
  Header,
  TopPanel,
  Section,
  SectionTitle,
  Grid
} from './../../../../../../components/layout';
import Table from './../../../../../../components/ui/Table';
import { GridItem } from './../../../../../../components/layout/Grid';
import Breadcrumbs, {
  Crumb
} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';

import { COLUMNS } from './elements';
type Props = {
  assignmentLimitId: number
};
type State = {
  assignmentLimit: ?AssignmentLimit
};

const Content = styled.div`
  padding: 16px;
`;

export default class extends Component<Props, State> {
  state = {
    assignmentLimit: null
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { assignmentLimitId } = this.props;
    const assignmentLimit = await assignmentLimitApi.get(assignmentLimitId);
    this.setState({ assignmentLimit });
  };

  render() {
    const { assignmentLimit } = this.state;
    const data = (assignmentLimit && assignmentLimit.budgetDetails) || [];
    if (assignmentLimit === null) {
      return null;
    }
    return (
      <>
        {assignmentLimit && (
          <>
            <Header
              left={
                <Breadcrumbs>
                  <Crumb to="/">Главная</Crumb>
                  <Crumb to="/budget/contract-vehicle/fixed">
                    Закрепленные НТС
                  </Crumb>
                  <Crumb
                    to={`/budget/contract-vehicle/fixed/${
                      assignmentLimit.contractVehiclePlanId
                    }/budget`}
                  >
                    Бюджет
                  </Crumb>
                  <Crumb>Расшифровка затрат ТС</Crumb>
                </Breadcrumbs>
              }
            />
            <TopPanel>
              <h1>
                {convertContractVehicleToString(
                  assignmentLimit.contractVehicle
                )}
              </h1>
            </TopPanel>
            <Section>
              <SectionTitle divider>Расшифровка затрат по ТС</SectionTitle>
              <Content>
                <Grid gutter="16px" cols="5">
                  <GridItem>
                    <Field label="ФИО">
                      {convertEmployeeToString(
                        assignmentLimit.regulationLimit.employee
                      )}
                    </Field>
                  </GridItem>
                  {/* <GridItem>
                    <Field label="Должность">
                      <>Зам. директора по общим вопросам</>
                    </Field>
                  </GridItem> */}
                  <GridItem>
                    <Field label="Направление расходов">
                      {assignmentLimit.expenseDirectionName}
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
              <Table columns={COLUMNS} data={data} />
            </Section>
          </>
        )}
      </>
    );
  }
}
