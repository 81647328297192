// @flow
import React from 'react';

import type { PropsHeader } from '../../lib';
import { commonHeaderPanel } from '../../lib';

import RepairTab from './Tab';

export const headerPanel = (props: PropsHeader) => {
  const { title, vehiclePlanId } = props;
  return (
    <>
      {commonHeaderPanel({ title, vehiclePlanId })}
      <RepairTab vehiclePlanId={props.vehiclePlanId} />
    </>
  );
};
