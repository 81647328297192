// @flow
import React, { Component } from 'react';
import { navigate } from '@reach/router';

import { Tabs, TabItem } from '../../../components/ui';
import type { VehicleTariffType } from '../../../lib/types';
import { vehicleTariffEnum } from '../../../lib/enum';

type Props = {
  selectedTab: VehicleTariffType
};

const COMMON_ROUTE = 'vehicle-tariff';

export default class HeaderTabs extends Component<Props> {
  render() {
    return (
      <Tabs defaultTab={this.props.selectedTab}>
        <TabItem
          tabKey={vehicleTariffEnum.byBrand}
          label="По марке модели"
          onClick={() =>
            navigate(`/admin/${COMMON_ROUTE}/${vehicleTariffEnum.byBrand}`)
          }
        />
        <TabItem
          tabKey={vehicleTariffEnum.byType}
          label="По типу ТС"
          onClick={() =>
            navigate(`/admin/${COMMON_ROUTE}/${vehicleTariffEnum.byType}`)
          }
        />
      </Tabs>
    );
  }
}
