import React from 'react';
import styled from 'styled-components';
import List from 'antd/lib/list';

import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Section, Panel } from './../../components/layout';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding: 16px;
  padding-top: 0;
`;

type InstructionLink = {
  link: string,
  label: string
};

const links = [
  {
    link: '/instructions/Инструкция для очистки кэша браузера.docx',
    label: 'Инструкция для очистки кэша браузера.'
  },
  {
    link: '/instructions/Руководство_по_работе_с_Системой.docx',
    label: 'Руководство по работе с системой.'
  }
];

export default () => {
  links.sort((a: InstructionLink, b: InstructionLink) =>
    a.label.localeCompare(b.label)
  );

  return (
    <div>
      <Header
        left={
          <Breadcrumbs>
            <Crumb to="/">Главная</Crumb>
            <Crumb>Инструкции</Crumb>
          </Breadcrumbs>
        }
      />
      <StyledPanel>
        <h1>Инструкции</h1>
      </StyledPanel>
      <StyledSection>
        <List
          bordered
          dataSource={links}
          renderItem={item => (
            <List.Item>
              <a target="_blank" rel="noopener noreferrer" href={item.link}>
                {item.label}
              </a>
            </List.Item>
          )}
        />
      </StyledSection>
    </div>
  );
};
