// @flow
import React, { useMemo } from 'react';
import { Link, navigate } from '@reach/router';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { technicalFluidApi } from '../../../lib/api';
import { applyMaskToValue, formatDateTimeToString } from '../../../lib/helpers';
import type { Vehicle } from '../../../lib/types';
import { accessTypeEnum, technicalFluidTypes } from '../../../lib/enum';

import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { TicketsFilter } from '../components';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { notificationLoading } from '../../../components/Notifications';

import { withUserAccess } from '../../withUserAccess';

const entryPointPath = '/technical-fluid/tickets/';

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const handlePrint = async (id: number) => {
    try {
      notificationLoading({
        key: 'printing',
        message: 'Формирование печатной формы...'
      });
      await technicalFluidApi.print(id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  const canAdd = () =>
    userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingFluidsTickets
      ].includes(access)
    );

  const columns = useMemo(
    () => [
      {
        title: 'Гос. номер',
        dataIndex: 'vehicle.licensePlate',
        render: (licensePlate: ?string, record: Vehicle) =>
          licensePlate ? (
            <Link to={`/vehicles/${record.id}`}>
              {applyMaskToValue(licensePlate, formatLicensePlateMask)}
            </Link>
          ) : (
            licensePlate
          )
      },
      {
        title: 'Марка ТС',
        dataIndex: 'vehicle.vehicleModel.brandName'
      },
      {
        title: 'Модель',
        dataIndex: 'vehicle.vehicleModel.name'
      },
      {
        title: 'Год выпуска ТС',
        dataIndex: 'vehicle.yearIssued'
      },
      {
        title: 'Дата создания',
        dataIndex: 'date',
        render: (endDate: string) =>
          formatDateTimeToString(endDate, 'DD.MM.YYYY')
      },
      {
        title: 'Тип тех.жидкости',
        dataIndex: 'fluidType',
        render: (fluidType: string) => technicalFluidTypes[fluidType]
      },
      {
        title: 'Кол-во',
        dataIndex: 'liters',
        render: (liters: number) => `${liters} л`
      },
      {
        title: '',
        dataIndex: 'id',
        stopPropagation: true,
        render: (id: number) => (
          <Button type="primary" onClick={() => handlePrint(id)}>
            Печать
          </Button>
        )
      }
    ],
    []
  );

  return (
    <Page>
      <CommonListPage
        crud={technicalFluidApi}
        pageHeaderProps={{
          mainHeader: 'Талоны на выдачу тех. жидкостей',
          access: canAdd() ? access : undefined
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canAdd()
        }}
        filter={TicketsFilter}
      />
    </Page>
  );
});
