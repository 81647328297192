// @flow

export const serviceTypes = {
  insuranceVehicle: 'Страхование ТС',
  contractVehicles: 'Наемные ТС',
  fuelsAndOils: 'Поставка ГСМ',
  maintenance: 'Техническое обслуживание',
  expertise: 'Экспертиза',
  vehicleWash: 'Мойка ТС',
  issuanceOfSpringPermits: 'Выдача пропусков на весенний период',
  issuanceOfOversizeCargoPermits:
    'Выдача пропусков на негабаритный/тяжеловесный груз',
  issuanceOfCityPermits: 'Выдача пропусков для въезда в город',
  issuanceOfBridgesPermits: 'Выдача пропусков для проезда по мостам',
  spareParts: 'Поставка запасных частей',
  stateTechnicalSupervision: 'Услуги государственного технического надзора',
  vehicleMonitoring: 'Мониторинг транспорта',
  driversCheck: 'Предрейсовые/послерейсовые осмотры водителей',
  replacementLicensePlate: 'Замена гос. номера',
  instrumentationCertification: 'Сертификация средств измерений',
  maintenanceGpmSecurityDevices:
    'Проведение экспертизы и технического обслуживания приборов безопасности ГПМ',
  technicalInspection: 'Технический осмотр в ГИБДД',
  platon: 'Платон'
};

export const serviceTypeEnum = {
  insuranceVehicle: 'insuranceVehicle',
  expertise: 'expertise',
  contractVehicles: 'contractVehicles',
  fuelsAndOils: 'fuelsAndOils',
  maintenance: 'maintenance',
  vehicleWash: 'vehicleWash',
  issuanceOfSpringPermits: 'issuanceOfSpringPermits',
  issuanceOfOversizeCargoPermits: 'issuanceOfOversizeCargoPermits',
  issuanceOfCityPermits: 'issuanceOfCityPermits',
  issuanceOfBridgesPermits: 'issuanceOfBridgesPermits',
  spareParts: 'spareParts',
  stateTechnicalSupervision: 'stateTechnicalSupervision',
  vehicleMonitoring: 'vehicleMonitoring',
  driversCheck: 'driversCheck',
  replacementLicensePlate: 'replacementLicensePlate',
  instrumentationCertification: 'instrumentationCertification',
  maintenanceGpmSecurityDevices: 'maintenanceGpmSecurityDevices',
  technicalInspection: 'technicalInspection',
  platon: 'platon'
};
