// @flow
import React, { type Node } from 'react';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import get from 'lodash/get';
import max from 'lodash/max';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import FastList from 'react-virtualized/dist/commonjs/List';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import ScrollSync from 'react-virtualized/dist/commonjs/ScrollSync';

import { Cell, EmptyText, HeaderWrapper, TableWrapper } from './elements';
import CellInput from './components/CellInput';
import {
  changeColumns,
  computeHeaderHeight,
  defaultColumnWidth,
  defaultRowHeight,
  getDataColumns,
  prepareColumns
} from './lib';

import TableHeader from './components/TableHeader';
import Popover from '../Popover';

/**
 * Ячейка, отображает данные таблицы
 */
export type SpreadsheetTableCell = {
  // Является ли ячейка, ассоциируемая с этой колонкой, редактируемой
  editable?: boolean,
  // Ключ (в виде пути, например user.name и любой вложенности)
  // до значения в элементе данных
  keyPath?: string,
  // Высота ячейки
  height?: number,
  // Значение ячейки
  value?: string,
  // Форматтер значения ячейки
  formatValue?: (value: any, rowValue: any, rowIndex: number) => Node | string,
  // Стили для ячейки
  cellStyle?: $Shape<CSSStyleDeclaration>,
  // Функция изменения значения для конкретной ячейки
  onChange?: (value: any, key: string, index: number) => void,
  // Обертка вокруг ячейки
  wrapper?: (rowValue: any, style: any, children: Node) => Node,
  // Сравнение значений
  compareWith?: {
    // Путь поля до сравниваемного значения
    key: string,
    // Функция для сравнения
    // Возвращает строку с сообщением или null
    compare: (cellValue: number, comparableValue: number) => ?string,
    backgroundColor?: string,
    textColor?: string
  }
};

/**
 * Колонка таблицы
 */
export type SpreadsheetTableColumn = {
  // Ширина колонки
  width?: number,
  // Зафиксированая колонка
  fixed?: boolean,
  // Состояние раскрытости
  expanded?: boolean,
  header: {
    // Название колонки в шапке
    title?: string,
    // Высота в колонки шапке
    height?: number,
    // Раскрываемая колонка
    expandable?: boolean,
    render?: (value?: any) => Node,
    style?: any
  },
  cellStyle?: $Shape<CSSStyleDeclaration>,
  // Ячейка, задается для листовых колонок
  cell?: SpreadsheetTableCell,
  cells?: SpreadsheetTableCell[],
  // Вложенные колонки
  // (ячейки отрисовываются для листовых колонок (у которых нет вложенных колонок))
  columns?: SpreadsheetTableColumn[],
  // уникальный id для обновления значения колонки в дереве
  // Генерируется нами
  id?: string,
  parentId?: ?string
};

type Props = {
  // Колонки, описывающие структуру таблицы
  columns: SpreadsheetTableColumn[],
  // Данные
  data: any[],
  // Текст, выводимый при отстуствии данных
  notFoundText?: string,
  // Название класса для стилизации
  className?: string,
  // Стили для обертки таблицы
  style?: $Shape<CSSStyleDeclaration>,
  // Функция изменения значения в ячейке таблицы
  onChange: (value: any, key: string, index: number) => void,
  // Количество первых закрепленных колонок
  // React Virtualized позволяет закреплять только N-первых колонок
  fixedColumnCount?: number
};

type State = {
  /**
   * Ячейка в фокусе
   */
  focusedCell: string,
  /**
   * Колонки для отображения данных
   */
  dataColumns: SpreadsheetTableColumn[],
  columns: SpreadsheetTableColumn[],
  // Высота шапки
  headerHeight: number
};

/**
 * Компонент таблички для
 * отрисовки данных в виде Spreadsheet таблицы
 * с редактируемыми и группируемыми ячейками
 */
export default class SpreadsheetTable extends React.Component<Props, State> {
  state = {
    focusedCell: '',
    dataColumns: [],
    columns: [],
    headerHeight: 0
  };

  static defaultProps = {
    onChange: () => {},
    fixedColumnCount: 0
  };

  multiGrid: any = React.createRef();

  componentDidMount() {
    const { columns } = this.props;
    const dataColumns = getDataColumns(columns);
    const headerHeight = computeHeaderHeight(columns);
    this.setState({ dataColumns, columns, headerHeight });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { columns } = this.props;
    // Записываем во внутренний стейт колонки
    if (!isEqual(prevProps.columns, columns)) {
      this.setState({ columns: prepareColumns(columns) }, () => {
        // Обновляем листовые колонки при обновлении колонок в стейте
        const dataColumns = getDataColumns(this.state.columns);
        const headerHeight = computeHeaderHeight(this.state.columns);
        this.setState({ dataColumns, headerHeight });
        if (this.multiGrid.current) this.multiGrid.current.recomputeGridSize();
      });
    }
  }

  /**
   * Функция скрытия/раскрытия колонки
   * @param id
   */
  toggleExpandColumn = (id: string) => {
    const { columns } = this.state;
    changeColumns(columns, id, column => {
      column.expanded = !column.expanded;
    });
  };

  forceUpdateGrids = () =>
    this.multiGrid.current && this.multiGrid.current.forceUpdateGrids();

  /**
   * Возвращает ширину колонки для Grid элемента
   * @param index Индекс колонки
   * @returns {number} Ширина колонки
   */
  getColumnWidth = ({ index }: { index: number }) => {
    const { dataColumns } = this.state;
    return dataColumns[index].width || defaultColumnWidth;
  };

  /**
   * Возвращает высоту для всех строк Grid
   *
   * Вовзращается максимальная высота среди всех ячеек таблицы
   *
   * @returns {number} Максимальная высота среди всех ячеек таблицы
   */
  getRowHeight = () => {
    const { dataColumns } = this.state;
    return (
      max(
        dataColumns.map(column => {
          const { cell = {}, cells } = column;
          // Если есть cells, то берем сумму высот ячеек
          if (Array.isArray(cells)) {
            return sum(cells.map(cell => cell.height || defaultRowHeight));
          }
          return cell.height || defaultRowHeight;
        })
      ) || defaultRowHeight
    );
  };

  renderCell = ({
    key,
    focusedKey,
    cell,
    rowIndex,
    style
  }: {
    key: any,
    focusedKey: string,
    cell: SpreadsheetTableCell,
    rowIndex: number,
    style: $Shape<CSSStyleDeclaration>
  }) => {
    const { data, onChange } = this.props;
    const { focusedCell } = this.state;
    const {
      keyPath = '',
      editable = false,
      cellStyle = {},
      height = defaultRowHeight,
      formatValue = (value: any, rowValue: any, rowIndex: number) => value,
      value: cellValue,
      compareWith: { key: compareKey, compare, backgroundColor, textColor } = {}
    } = cell;
    const value = formatValue(
      get(data[rowIndex], keyPath),
      data[rowIndex],
      rowIndex
    );
    const focused = focusedCell === focusedKey;

    const renderedElement = (
      <Cell
        key={key}
        // $FlowFixMe
        style={{
          height,
          ...style,
          ...cellStyle,
          textAlign: 'center',
          background: rowIndex % 2 === 0 ? 'rgba(228, 235, 242, 0.3)' : 'white'
        }}
      >
        <CellInput
          onFocus={() => {
            this.setState({
              focusedCell: focusedKey
            });
            this.multiGrid.current && this.multiGrid.current.forceUpdateGrids();
          }}
          editable={cellValue ? false : editable}
          value={cellValue || value}
          onChange={value => {
            onChange(value, keyPath, rowIndex);
            this.multiGrid.current && this.multiGrid.current.forceUpdateGrids();
          }}
          focused={focused}
        />
      </Cell>
    );
    // Если есть ключ для сравнения
    if (compareKey) {
      // Значение для сравнения
      const compareValue = get(data[rowIndex], compareKey);
      // Значение в ячейке
      const value = get(data[rowIndex], keyPath);
      // Сообщение о результате сравнения
      const compareMessage = compare(value, compareValue);

      if (compareMessage !== null && !isNil(value) && !isNil(compareValue)) {
        return (
          <Popover content={compareMessage} width={150}>
            {React.cloneElement(renderedElement, {
              style: {
                ...renderedElement.props.style,
                background: backgroundColor,
                color: textColor
              }
            })}
          </Popover>
        );
      }
      return renderedElement;
    }
    return renderedElement;
  };

  render() {
    const { dataColumns, headerHeight } = this.state;
    const { data } = this.props;
    const {
      notFoundText,
      className,
      style,
      fixedColumnCount,
      columns
    } = this.props;
    const rowHeight = this.getRowHeight();

    return isEmpty(data) ? (
      <EmptyText>{notFoundText}</EmptyText>
    ) : (
      <ScrollSync>
        {({
          onScroll,
          scrollLeft,
          scrollTop,
          scrollWidth,
          scrollHeight,
          clientWidth,
          clientHeight
        }) => (
          <TableWrapper style={style} className={className}>
            <AutoSizer>
              {({ width, height }) => (
                <>
                  <HeaderWrapper height={headerHeight}>
                    <TableHeader
                      fixedColumnCount={fixedColumnCount}
                      width={width}
                      height={headerHeight}
                      columns={columns}
                      gridProps={{
                        onScroll,
                        scrollLeft,
                        scrollWidth,
                        scrollHeight,
                        clientWidth,
                        clientHeight
                      }}
                    />
                  </HeaderWrapper>
                  {// Проверка потому что React Virtualized
                  // почему-то для MultiGrid падает с ошибкой, что width неопеределен
                  // при этом для того же Grid такой ошибки нет
                  !!width && (
                    <MultiGrid
                      style={{
                        outline: 'none'
                      }}
                      ref={this.multiGrid}
                      // Высота области таблицы с ячейками
                      height={height - headerHeight}
                      // Кол-во строк таблицы
                      rowCount={data.length}
                      // Высота каждой строки таблицы
                      rowHeight={rowHeight}
                      // Ширина области таблицы с ячейками
                      width={width}
                      columnCount={dataColumns.length}
                      columnWidth={this.getColumnWidth}
                      // Кол-во заранее отрисовываемых строк при скроле
                      overscanRowCount={4}
                      onScroll={onScroll}
                      scrollTop={scrollTop}
                      scrollLeft={scrollLeft}
                      scrollWidth={scrollWidth}
                      scrollHeight={scrollHeight}
                      fixedColumnCount={fixedColumnCount}
                      // Функция рендера ячейки таблицы
                      cellRenderer={({ columnIndex, key, rowIndex, style }) => {
                        const {
                          cell = {},
                          cells,
                          width,
                          cellStyle: commonCellStyle
                        } = dataColumns[columnIndex];
                        if (Array.isArray(cells)) {
                          return (
                            <FastList
                              key={key}
                              style={{
                                ...style,
                                outline: 'none'
                              }}
                              rowCount={cells.length}
                              width={width}
                              height={rowHeight}
                              rowHeight={rowHeight / cells.length}
                              rowRenderer={({
                                key,
                                index: cellIndex,
                                style
                              }) => {
                                const cell = cells[cellIndex];
                                const { keyPath = '' } = cell;
                                return this.renderCell({
                                  key,
                                  rowIndex,
                                  cell,
                                  focusedKey: `${rowIndex}/${columnIndex}/${cellIndex}/${keyPath}`,
                                  style: {
                                    ...style,
                                    ...commonCellStyle
                                  }
                                });
                              }}
                            />
                          );
                        } else {
                          const { keyPath = '' } = cell;
                          return this.renderCell({
                            key,
                            cell,
                            rowIndex,
                            focusedKey: `${rowIndex}/${columnIndex}/${keyPath}`,
                            style
                          });
                        }
                      }}
                    />
                  )}
                </>
              )}
            </AutoSizer>
          </TableWrapper>
        )}
      </ScrollSync>
    );
  }
}
