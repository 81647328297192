import { OkeiCard, OkeiForm, OkeiList } from '../../containers/Okei';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: OkeiList,
        path: '/admin/okei',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: OkeiCard,
        path: '/admin/okei/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: OkeiForm,
        path: '/admin/okei/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: OkeiForm,
        path: '/admin/okei/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];