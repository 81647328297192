//@flow
import React from 'react';

import { ownerTypes } from '../../../../../lib/enum';
import type { Vehicle, VehicleType } from '../../../../../lib/types';
import { applyMaskToValue } from '../../../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem
} from '../../../../../components';
import Field from '../../../../../components/card/Field';
import { formatLicensePlateMask } from '../../../../../components/inputs/masked-inputs/LicensePlateInput';
import type { FilterChildrenParams } from '../../../../../components/hoc/Filter';
import { SectionContent } from '../../../../../components/hoc/common/components/elements';
import Selects from '../../../../../components/selects';
import Checkbox from 'antd/lib/checkbox';

const {
  BrandsSelect,
  VehicleTypeSelect,
  VehicleModelSelect,
  VehicleSelect
} = Selects;

export type ParamsFilter = {
  'selfVehiclePlanVehicle.vehicle.vehicleModel.type'?: VehicleType,
  'selfVehiclePlanVehicle.vehicle.vehicleModel.id'?: number,
  'selfVehiclePlanVehicle.vehicle.vehicleModel.brand.id'?: number,
  'selfVehiclePlanVehicle.vehicle.id'?: number,
  sum?: string // передаем выражение >1
};

type Props = {
  filter: ParamsFilter,
  showHideEmpty?: boolean,
  cleanFilter: Function,
  applyFilter: Function
};

const BudgetFilter = ({
  showHideEmpty = true,
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<ParamsFilter>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <BrandsSelect
                  placeholder="Все марки"
                  size="small"
                  value={
                    values[
                      'selfVehiclePlanVehicle.vehicle.vehicleModel.brand.id'
                    ]
                  }
                  onChange={value =>
                    changeValue(
                      'selfVehiclePlanVehicle.vehicle.vehicleModel.brand.id',
                      value
                    )
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleModelSelect
                  placeholder="Все модели ТС"
                  size="small"
                  value={
                    values['selfVehiclePlanVehicle.vehicle.vehicleModel.id']
                  }
                  filter={{
                    'selfVehiclePlanVehicle.vehicle.vehicleModel.brand.id':
                      values[
                        'selfVehiclePlanVehicle.vehicle.vehicleModel.brand.id'
                      ],
                    type:
                      values['selfVehiclePlanVehicle.vehicle.vehicleModel.type']
                  }}
                  onChange={value =>
                    changeValue(
                      'selfVehiclePlanVehicle.vehicle.vehicleModel.id',
                      value
                    )
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  placeholder="Все ТС"
                  size="small"
                  value={values['selfVehiclePlanVehicle.vehicle.id']}
                  optionTitle={(vehicle: Vehicle) =>
                    applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )
                  }
                  filter={{
                    ownerType: ownerTypes.self,
                    'selfVehiclePlanVehicle.vehicle.vehicleModel.type':
                      values['selfVehiclePlanVehicle.vehicle.vehicleModel.type']
                  }}
                  onChange={value =>
                    changeValue('selfVehiclePlanVehicle.vehicle.id', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  placeholder="Все типы ТС"
                  size="small"
                  value={
                    values['selfVehiclePlanVehicle.vehicle.vehicleModel.type']
                  }
                  selectableGroup
                  onChange={value =>
                    changeValue(
                      'selfVehiclePlanVehicle.vehicle.vehicleModel.type',
                      value
                    )
                  }
                />
              </Field>
            </FilterItem>
            {showHideEmpty && (
              <FilterItem style={{ minWidth: '165px' }}>
                <Field mBottomNone>
                  <Checkbox
                    style={{ position: 'relative', top: '5px' }}
                    size="small"
                    checked={values.sum}
                    onChange={e => {
                      changeValue('sum', e.target.checked ? '>1' : undefined); // На сервере обрабатывается как >=1 копейки
                    }}
                  >
                    Скрыть пустые
                  </Checkbox>
                </Field>
              </FilterItem>
            )}
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);
export default BudgetFilter;
