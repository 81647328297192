// @flow

import { fetchRequest, initialFetchListParams } from './index';
import type { FuelAndOil } from '../types/index';
import type { FetchListParams, ListResponse } from './index';

/**
 * Работа с API доп. оборудования
 */

// Получение списка
export const fetchFuelsAndOils = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<FuelAndOil>> =>
  await fetchRequest.get('/fuelsAndOils', {
    ...initialFetchListParams,
    ...params
  });

// Получение сущности по id
export const fetchFuelAndOil = async (
  id: number,
  params: any = {}
): Promise<FuelAndOil> => {
  const fuelAndOil = await fetchRequest.get(`/fuelsAndOils/${id}`, params);
  if (fuelAndOil) return fuelAndOil;
  throw new Error('Не удалось загрузить ГСМ');
};

// Добавление
export const addFuelAndOil = async (
  fuelAndOil: FuelAndOil
): Promise<FuelAndOil> => {
  const added = await fetchRequest.post('/fuelsAndOils', fuelAndOil);
  if (added) return added;
  throw new Error('Не удалось создать ГСМ');
};

// Обновление данных
export const updateFuelAndOil = async (
  fuelAndOil: FuelAndOil
): Promise<FuelAndOil> => {
  const updated = await fetchRequest.put('/fuelsAndOils', fuelAndOil);
  if (updated) return updated;
  throw new Error('Не удалось обновить ГСМ');
};

// Удаление
export const deleteFuelAndOil = async (id: number): Promise<FuelAndOil> => {
  const deleted = await fetchRequest.delete(`/fuelsAndOils/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить ГСМ');
};

export default {
  fetchFuelsAndOils,
  fetchFuelAndOil,
  addFuelAndOil,
  updateFuelAndOil,
  deleteFuelAndOil
};
