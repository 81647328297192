// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import type {
  ServiceType,
  Act,
  ActStatusType,
  UserAccess
} from './../../../../lib/types';
import { serviceTypeEnum, actStatusEnum } from './../../../../lib/enum';
import { setQueryParams } from './../../../../lib/helpers';
import {
  expertiseServiceGpmApi,
  inspectionGibddApi,
  inspectionGtnApi,
  licensePlateChangeApi,
  maintenanceApi,
  measuringDeviceCertificationApi,
  osagoApi,
  passApi,
  washingApi,
  platonApi,
  vehicleMonitoringApi,
  fuelCardApi
} from './../../../../lib/api';

import { Section } from './../../../../components/layout';

import { withUserAccess } from './../../../withUserAccess';
import { handlingExternalActs } from './../../accessRight';

import {
  Pass,
  ExpertiseServiceGpm,
  InspectionsGibdd,
  InspectionsGtn,
  MeasuringDeviceCertifications,
  LicensePlateChange,
  Washing,
  Maintenance,
  Osago,
  Platon,
  VehicleMonitoring
} from './listTable';

const Content = styled.div`
  padding: 16px;
`;
type Props = {
  services: ServiceType,
  actId: number,
  act: Act,
  changeStatusAct: Function,
  userAccess: UserAccess[]
};
type State = {
  unSelected: any,
  filter: any
};

export class List extends Component<Props, State> {
  state = {
    unSelected: {},
    filter: {}
  };

  onSelectAll = (
    data: any,
    selected: boolean,
    selectedRecord: any[],
    fieldId: string = 'id'
  ) => {
    let { unSelected } = this.state;
    if (selected) {
      selectedRecord.forEach(
        item => unSelected[item[fieldId]] && delete unSelected[item[fieldId]]
      );
    } else {
      data.forEach(item => (unSelected[item[fieldId]] = item));
    }
    this.setState({ unSelected });
  };

  onSelect = (record: any, selected: boolean, fieldId: string = 'id') => {
    let { unSelected } = this.state;
    if (!selected) {
      unSelected[record[fieldId]] = record;
    } else {
      delete unSelected[record[fieldId]];
    }
    this.setState({ unSelected });
  };

  applyFilter = (filter: any) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  changeStatus = async (status: ActStatusType) => {
    await this.props.changeStatusAct(status);
  };

  handleSubmit = async () => {
    const { actId, services } = this.props;
    const { filter, unSelected } = this.state;
    const maintenanceIds = [...Object.keys(unSelected)];
    const data = { actId, maintenanceIds };

    switch (services) {
      case serviceTypeEnum.maintenanceGpmSecurityDevices:
        await expertiseServiceGpmApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.technicalInspection:
        await inspectionGibddApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.stateTechnicalSupervision:
        await inspectionGtnApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.replacementLicensePlate:
        await licensePlateChangeApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.maintenance:
        await maintenanceApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.instrumentationCertification:
        await measuringDeviceCertificationApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.insuranceVehicle:
        await osagoApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.vehicleWash:
        await washingApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.platon:
        await platonApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.vehicleMonitoring:
        await vehicleMonitoringApi.updateAct(filter, data);
        break;
      case serviceTypeEnum.fuelsAndOils:
        await fuelCardApi.updateAct(filter, data);
        break;
      default:
    }

    if (
      [
        serviceTypeEnum.issuanceOfSpringPermits,
        serviceTypeEnum.issuanceOfOversizeCargoPermits,
        serviceTypeEnum.issuanceOfCityPermits,
        serviceTypeEnum.issuanceOfBridgesPermits
      ].includes(services)
    ) {
      passApi.updateAct(filter, data);
    }
    setQueryParams({ page: 1 });
    this.setState({ unSelected: {} });
    await this.changeStatus(actStatusEnum.signed);
  };

  getComponent = () => {
    const { services } = this.props;
    if (
      [
        serviceTypeEnum.issuanceOfSpringPermits,
        serviceTypeEnum.issuanceOfOversizeCargoPermits,
        serviceTypeEnum.issuanceOfCityPermits,
        serviceTypeEnum.issuanceOfBridgesPermits
      ].includes(services)
    ) {
      return Pass;
    }

    switch (services) {
      case serviceTypeEnum.maintenanceGpmSecurityDevices:
        return ExpertiseServiceGpm;
      case serviceTypeEnum.technicalInspection:
        return InspectionsGibdd;
      case serviceTypeEnum.stateTechnicalSupervision:
        return InspectionsGtn;
      case serviceTypeEnum.instrumentationCertification:
        return MeasuringDeviceCertifications;
      case serviceTypeEnum.replacementLicensePlate:
        return LicensePlateChange;
      case serviceTypeEnum.vehicleWash:
        return Washing;
      case serviceTypeEnum.maintenance:
        return Maintenance;
      case serviceTypeEnum.insuranceVehicle:
        return Osago;
      case serviceTypeEnum.platon:
        return Platon;
      case serviceTypeEnum.vehicleMonitoring:
        return VehicleMonitoring;
      default:
        return false;
    }
  };

  canAdd = () =>
    this.props.userAccess.some(access => handlingExternalActs.includes(access));

  render() {
    const { actId, act } = this.props;
    const { unSelected } = this.state;
    const Component = this.getComponent();
    return (
      <>
        {Component && (
          <Section>
            <Content>
              <Component
                actId={actId}
                actSum={act.incomeSchedule && act.incomeSchedule.sum}
                readOnly={[
                  actStatusEnum.signed,
                  actStatusEnum.approved
                ].includes(act.status)}
                applyFilter={this.applyFilter}
                cleanFilter={this.cleanFilter}
                onSelect={this.onSelect}
                onSelectAll={this.onSelectAll}
                location={window.location}
                unSelected={unSelected}
              />
            </Content>
          </Section>
        )}
        {this.canAdd() && act.status !== actStatusEnum.approved && (
          <Section>
            <Content>
              {act.status === actStatusEnum.created && Component !== false && (
                <Button onClick={this.handleSubmit} type="primary">
                  Сохранить
                </Button>
              )}
              {(act.status === actStatusEnum.signed || Component === false) && (
                <>
                  <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => this.changeStatus(actStatusEnum.approved)}
                    type="primary"
                  >
                    Применить
                  </Button>
                  {Component !== false && (
                    <Button
                      onClick={async () =>
                        await this.changeStatus(actStatusEnum.created)
                      }
                    >
                      Отменить
                    </Button>
                  )}
                </>
              )}
            </Content>
          </Section>
        )}
      </>
    );
  }
}

export default withUserAccess(List);
