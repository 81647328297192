import { CompaniesForm, CompaniesList } from '../../containers/Companies';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: CompaniesList,
        path: '/admin/companies',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingCompanies,
            accessTypeEnum.handlingCompanies,
            accessTypeEnum.viewingContractors,
            accessTypeEnum.handlingContractors,
        ],
    },
    {
        component: CompaniesForm,
        path: '/admin/companies/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingCompanies,
        ],
    },
    {
        component: CompaniesForm,
        path: '/admin/companies/:companyId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingCompanies,
            accessTypeEnum.handlingCompanies,
            accessTypeEnum.viewingContractors,
            accessTypeEnum.handlingContractors,
        ],
    },
];