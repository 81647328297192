// @flow
import queryString from 'query-string';
import type { InspectionGtn, ExecutionBudgetGroupedList } from './../types';

import {
  fetchRequest,
  downloadRequestWithToken,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { InspectionGtnFilterParams } from '../../containers/services/inspectionsGtn/Filter';

export const convertDataFromServer = (inspectionGtn: InspectionGtn) => ({
  ...inspectionGtn,
  price: convertFromKopToRub(inspectionGtn.price),
  taxPrice: convertFromKopToRub(inspectionGtn.taxPrice),
  totalPrice: convertFromKopToRub(inspectionGtn.totalPrice),
  act: inspectionGtn.act
    ? actConvertDataFromServer(inspectionGtn.act)
    : undefined
});

export const convertDataToServer = (inspectionGtn: InspectionGtn) => ({
  ...inspectionGtn,
  price: convertFromRubToKop(inspectionGtn.price),
  taxPrice: convertFromRubToKop(inspectionGtn.taxPrice),
  totalPrice: convertFromRubToKop(inspectionGtn.totalPrice)
});

export const addInspectionGtn = async (
  inspectionGtn: InspectionGtn
): Promise<InspectionGtn> => {
  const added = await fetchRequest.post(
    '/inspectionGtn',
    convertDataToServer(inspectionGtn)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать технический осмотр ГТН');
};

export const updateInspectionGtn = async (
  inspectionGtn: InspectionGtn
): Promise<InspectionGtn> => {
  const updated = await fetchRequest.put(
    '/inspectionGtn',
    convertDataToServer(inspectionGtn)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить технический осмотр ГТН');
};
export const deleteInspectionGtn = async (
  id: number
): Promise<InspectionGtn> => {
  const deleted = await fetchRequest.delete(`/inspectionGtn/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить технический осмотр ГТН');
};

export const fetchInspectionGtn = async (
  id: number,
  params: any = {}
): Promise<InspectionGtn> => {
  const inspectionGtn = await fetchRequest.get(`/inspectionGtn/${id}`, params);
  if (inspectionGtn) return convertDataFromServer(inspectionGtn);
  throw new Error('Не удалось получить технический осмотр ГТН');
};

export const fetchVehicleInspectionsGtn = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<InspectionGtn>> => {
  const inspectionGtnes = await fetchRequest.get(
    `/inspectionGtn/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (inspectionGtnes) {
    return {
      ...inspectionGtnes,
      data: inspectionGtnes.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список технических осмотров ГТН');
};

export const fetchInspectionsGtn = async (
  params: FetchListParams<InspectionGtn> = initialFetchListParams
): Promise<ListResponse<InspectionGtn>> => {
  const inspectionsGtn = await fetchRequest.get('/inspectionGtn', {
    ...initialFetchListParams,
    ...params
  });
  if (inspectionsGtn) {
    return {
      ...inspectionsGtn,
      data: inspectionsGtn.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список технических осмотров ГТН');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<InspectionGtn>> => {
  const expertiseServiceGpm = await fetchRequest.put(
    `/inspectionGtn/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (expertiseServiceGpm) {
    return {
      ...expertiseServiceGpm,
      data: expertiseServiceGpm.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за т.о. ГТН');
};

export const printReport = async (
  params: InspectionGtnFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/inspectionGtn/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/inspectionGtn/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/inspectionGtn/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export default {
  groupedList,
  getTotalSum,
  printReport,
  updateAct,
  addInspectionGtn,
  updateInspectionGtn,
  deleteInspectionGtn,
  fetchInspectionGtn,
  fetchVehicleInspectionsGtn,
  fetchInspectionsGtn
};
