import {
    TripInspectionFrequencyCard,
    TripInspectionFrequencyForm,
    TripInspectionFrequencyList,
} from '../../containers/TripInspectionFrequency';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: TripInspectionFrequencyList,
        path: '/admin/trip-inspection-frequency',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: TripInspectionFrequencyCard,
        path: '/admin/trip-inspection-frequency/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: TripInspectionFrequencyForm,
        path: '/admin/trip-inspection-frequency/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: TripInspectionFrequencyForm,
        path: '/admin/trip-inspection-frequency/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];