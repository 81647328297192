import {
    TechnicalFluidTicketsForm,
    TechnicalFluidTicketsList,
} from '../containers/TechnicalFluid';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: TechnicalFluidTicketsList,
        path: '/technical-fluid/tickets',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingFluidsTickets,
            accessTypeEnum.handlingFluidsTickets,
        ],
    },
    {
        component: TechnicalFluidTicketsForm,
        path: '/technical-fluid/tickets/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingFluidsTickets,
        ],
    },
    {
        component: TechnicalFluidTicketsForm,
        path: '/technical-fluid/tickets/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingFluidsTickets,
            accessTypeEnum.handlingFluidsTickets,
        ],
    },
];