import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import { Divider, Menu, notification, Popconfirm } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React, { Component } from 'react';

import Field from '../../components/card/Field';
import { SectionContent } from '../../components/hoc/common/components/elements';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import { Grid, GridItem, Section, SectionTitle } from '../../components/layout';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import { notificationLoading } from '../../components/Notifications';
import { Dropdown, Table } from '../../components/ui';
import type { Profile } from '../../ducks/auth';
import { driverApi, pddTestResultApi } from '../../lib/api';
import {
  accessTypeEnum,
  driverQualificationEnum,
  driverQualifications,
  drivingLicenseCategories,
  tractorDrivingLicenseCategories,
} from '../../lib/enum';
import {
  applyMaskToValue,
  formatDateTimeToString,
  getPathWithHistoryParams,
  navigate,
} from '../../lib/helpers';

import type { Driver, PddTestResult, UserAccess } from '../../lib/types';

import { COLUMNS } from '../pdd/Results/List';
import { withUserAccess } from '../withUserAccess';
import CardPrintButton from './components/CardPrintButton';
import { DriverRating } from './components/InnerForm.elements';
import { PhotoModal } from './components/Modal';

const StyledField = (props) => (
  <Field
    {...props}
    wrapperStyle={{
      justifyContent: 'flex-start',
    }}
  />
);

const licenseCategories = {
  ...drivingLicenseCategories,
  ...tractorDrivingLicenseCategories,
};

type Props = {
  driverId?: number,
  userAccess: UserAccess[],
  userProfile: Profile,
};

type State = {
  driver: ?Driver,
  loading: boolean,
  pddTestResults: ?(PddTestResult[]),
  showModal?: boolean,
};

class DriverCard extends Component<Props, State> {
  state = {
    loading: false,
  };

  async componentDidMount() {
    let { driverId } = this.props;
    if (!driverId) driverId = this.props.userProfile.employee.driverId;
    try {
      if (driverId) {
        const driver = await driverApi.fetchDriver(driverId);
        this.setState({
          driver,
        });
      }
      this.fetchPddFetchResults();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
      await navigate('/admin/drivers', true);
    }
  }

  fetchPddFetchResults = async () => {
    let { driverId } = this.props;
    if (!driverId) driverId = this.props.userProfile.employee.driverId;
    try {
      this.setState({ loading: true });
      const { data } = await pddTestResultApi.fetch({
        driverId,
        byDescending: true,
        orderBy: 'examinationDate',
      });
      this.setState({ pddTestResults: data });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDriver,
      ].includes(access)
    );

  async deleteDriver(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await driverApi.deleteDriver(id);
      await navigate('/admin/drivers', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  onImageUploaded = (file: UploadFile, localImageUrl: string) => {
    this.setState({
      driver: {
        ...this.state.driver,
        photo: {
          ...file,
          url: localImageUrl,
        },
      },
    });
  };

  render() {
    const { driver, loading, pddTestResults } = this.state;
    if (!driver) return null;
    return (
      <>
        <Header
          left={
            this.props.driverId ? (
              <Breadcrumbs>
                <Crumb to="/">Главная</Crumb>
                <Crumb to={getPathWithHistoryParams('/admin/drivers')}>
                  Водители
                </Crumb>

                <Crumb to={`/admin/drivers/${driver.id}`}>
                  {[
                    driver.employee?.lastname,
                    driver.employee?.firstname,
                    driver.employee?.middlename,
                  ].join(' ')}
                </Crumb>
              </Breadcrumbs>
            ) : (
              <Breadcrumbs>
                <Crumb to="/driverRedirect/">Личная карточка</Crumb>
                <Crumb>
                  {[
                    driver.employee?.lastname,
                    driver.employee?.firstname,
                    driver.employee?.middlename,
                  ].join(' ')}
                </Crumb>
              </Breadcrumbs>
            )
          }
          right={<CardPrintButton driverId={driver.id} />}
        />
        <Section>
          <SectionTitle
            divider
            suffix={
              this.canAdd() && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.setState({ showModal: true });
                        }}
                      >
                        Загрузить фото
                      </Menu.Item>

                      <Menu.Item
                        onClick={() =>
                          navigate(`/admin/drivers/${driver.id}/edit`)
                        }
                      >
                        Редактировать
                      </Menu.Item>

                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          onConfirm={async () =>
                            await this.deleteDriver(driver.id)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <EllipsisOutlined
                    style={{ fontSize: 16, color: '#2770FF' }}
                  />
                </Dropdown>
              )
            }
          >
            Карточка водителя
          </SectionTitle>
          <SectionContent>
            <Grid gutter="16px" cols={7}>
              <GridItem span={1}>
                {driver.photo && (
                  <img
                    src={driver.photo.url}
                    alt="Фото водителя"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  />
                )}
              </GridItem>
              <GridItem span={6}>
                <Grid
                  gutter="16px"
                  rowGutter="50px"
                  rows={2}
                  cols={3}
                  style={{ height: '100%' }}
                >
                  <GridItem>
                    <StyledField label="ФИО">
                      {[
                        driver.employee?.lastname,
                        driver.employee?.firstname,
                        driver.employee?.middlename,
                      ].join(' ')}
                    </StyledField>
                  </GridItem>
                  <GridItem>
                    <StyledField label="Закрепленные ТС">
                      {driver.vehicles
                        ? driver.vehicles.map?.((vehicle, index) => (
                            <Link
                              to={`/vehicles/${vehicle.id}`}
                              key={vehicle.id}
                            >
                              {applyMaskToValue(
                                vehicle.licensePlate,
                                formatLicensePlateMask
                              ) +
                                (index !== driver.vehicles.length - 1
                                  ? ', '
                                  : '')}
                            </Link>
                          ))
                        : '-'}
                    </StyledField>
                  </GridItem>
                  <GridItem>
                    <StyledField label="Номер карты тахографа">
                      {
                        driver.qualificationDocuments?.find(
                          (el) =>
                            el.qualification ===
                            driverQualificationEnum.skziDriverMap
                        )?.documentNumber
                      }
                    </StyledField>
                  </GridItem>
                  <GridItem>
                    <StyledField label="Оценка качества вождения">
                      {
                        <h1>
                          <DriverRating
                            value={driver.drivingRating}
                            withTranscript
                            size="large"
                          />
                        </h1>
                      }
                    </StyledField>
                  </GridItem>
                  <GridItem>
                    <StyledField label="Марки и модели">
                      {driver.vehicles
                        ? driver.vehicles
                            ?.filter((el) => !!el.vehicleModel)
                            .map((el) =>
                              [
                                el.vehicleModel?.brandName,
                                el.vehicleModel?.name,
                              ].join(' ')
                            )
                            .join()
                        : '-'}
                    </StyledField>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
            <Divider />
            {driver.qualificationDocuments
              ?.filter(
                (el) =>
                  el.qualification === driverQualificationEnum.driverLicense ||
                  el.qualification ===
                    driverQualificationEnum.bkmMachinistLicense ||
                  el.qualification ===
                    driverQualificationEnum.tractorMachinistLicense
              )
              .map((el) => (
                <>
                  <Grid gutter="16px" cols={3} key={el.documentNumber}>
                    <GridItem>
                      <Field label={driverQualifications[el.qualification]}>
                        {el.documentNumber}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Дата окончания водительских прав">
                        {formatDateTimeToString(
                          el.documentEndDate,
                          'DD.MM.YYYY'
                        )}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Категория">
                        {el.categories
                          .map((el) => licenseCategories[el])
                          .join()}
                      </Field>
                    </GridItem>
                  </Grid>
                  <Divider />
                </>
              ))}
            <Field label="Контактный телефон">
              {driver.phoneNumber ? driver.phoneNumber : '-'}
            </Field>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>
            Результаты прошедших тестирований ПДД
          </SectionTitle>
          <SectionContent>
            <Table
              loading={loading}
              data={pddTestResults}
              columns={COLUMNS.filter(
                (column) => column.dataIndex !== 'driver'
              ).map((column) =>
                column.dataIndex === 'id'
                  ? {
                      title: 'Ссылка на результат',
                      dataIndex: 'id',
                      render: (id) => {
                        return (
                          <Link
                            to={`/admin/drivers/${driver.id}/pddTestResult/${id}`}
                          >
                            перейти
                          </Link>
                        );
                      },
                    }
                  : column
              )}
            />
          </SectionContent>
        </Section>
        <PhotoModal
          visible={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
          driverId={driver.id}
          onUploaded={this.onImageUploaded}
        />
      </>
    );
  }
}

export default withUserAccess(DriverCard);
