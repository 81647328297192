import React from 'react';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { FormikProps } from 'formik';
import styled from 'styled-components';

import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { FormFieldType } from '../../../components/Form';
import Form from '../../../components/Form';
import CancelButton from '../../../components/CancelButton';

import {
  fuelAndOilGroup,
  fuelAndOilSubType,
  fuelAndOilSubView,
  fuelAndOilType,
  fuelAndOilView,
} from '../../../lib/enum';
import type {
  FuelAndOil,
  FuelAndOilGroup,
  FuelAndOilSubType,
  FuelAndOilSubView,
  FuelAndOilType,
  FuelAndOilView,
} from '../../../lib/types/fuelAndOil';
import { Option, Select } from './../../../components/ant/Select';

const Content = styled.div`
  padding: 16px;
`;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  fuelAndOil: ?FuelAndOil,
  onSubmit: Function,
  onCancel: Function,
  getSubViews: Function,
  getTypes: Function,
  getSubTypes: Function,
  getGroups: Function,
};

export default ({
  fuelAndOil,
  onSubmit,
  onCancel,
  getSubViews,
  getTypes,
  getSubTypes,
  getGroups,
}: FormProps) => (
  <Form initialValues={fuelAndOil} onSubmit={onSubmit}>
    {(FormField: FormFieldType, formikProps: FormikProps) => {
      const { handleSubmit, dirty, isSubmitting, setFieldValue, values } =
        formikProps;
      const subViews = getSubViews(values);
      const types = getTypes(values);
      const subTypes = getSubTypes(values);
      const groups = getGroups(values);
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Наименование" required name="name">
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Вязкость" required name="viscosity">
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField label="Вид" required name="view">
                    {({ name, value }) => (
                      <Select
                        placeholder="Выберите вид ГСМ"
                        value={value}
                        onChange={(view) => {
                          setFieldValue(name, view);
                          setFieldValue('subView', null);
                          setFieldValue('type', null);
                          setFieldValue('subType', null);
                          setFieldValue('group', null);
                        }}
                        data-cy="viewSelect"
                      >
                        {Object.keys(fuelAndOilView).map(
                          (view: FuelAndOilView) => (
                            <Option key={view} value={view}>
                              {fuelAndOilView[view]}
                            </Option>
                          )
                        )}
                      </Select>
                    )}
                  </FormField>
                </GridItem>

                {subViews && subViews.length > 0 && (
                  <GridItem>
                    <FormField label="Подвид" required name="subView">
                      {({ name, value }) => (
                        <Select
                          placeholder="Выберите подвид ГСМ"
                          value={value}
                          onChange={(subView) => {
                            setFieldValue(name, subView);
                            setFieldValue('type', null);
                            setFieldValue('subType', null);
                            setFieldValue('group', null);
                          }}
                          data-cy="subViewSelect"
                        >
                          {subViews.map((subView: FuelAndOilSubView) => (
                            <Option key={subView} value={subView}>
                              {fuelAndOilSubView[subView]}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                )}

                {types && types.length > 0 && (
                  <GridItem>
                    <FormField label="Тип" required name="type">
                      {({ name, value }) => (
                        <Select
                          placeholder="Выберите тип ГСМ"
                          value={value}
                          onChange={(type) => {
                            setFieldValue(name, type);
                            setFieldValue('subType', null);
                            setFieldValue('group', null);
                          }}
                        >
                          {types.map((type: FuelAndOilType) => (
                            <Option key={type} value={type}>
                              {fuelAndOilType[type]}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                )}

                {subTypes && subTypes.length > 0 && (
                  <GridItem>
                    <FormField label="Подтип" required name="subType">
                      {({ name, value }) => (
                        <Select
                          placeholder="Выберите подтип ГСМ"
                          value={value}
                          onChange={(subType) => {
                            setFieldValue(name, subType);
                            setFieldValue('group', null);
                          }}
                        >
                          {subTypes.map((subType: FuelAndOilSubType) => (
                            <Option key={subType} value={subType}>
                              {fuelAndOilSubType[subType]}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                )}

                {groups && groups.length > 0 && (
                  <GridItem>
                    <FormField label="Группа" required name="group">
                      {({ name, value }) => (
                        <Select
                          placeholder="Выберите группу ГСМ"
                          value={value}
                          onChange={(group) => {
                            setFieldValue(name, group);
                          }}
                        >
                          {groups.map((group: FuelAndOilGroup) => (
                            <Option key={group} value={group}>
                              {fuelAndOilGroup[group]}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                )}
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              data-cy="save"
              htmlType="submit"
              className="login-form-button"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);
