// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { maintenanceMatrixValidAction, maintenanceMatrixValidActionEnum } from '../../lib/enum';
import { Option, Select } from './../ant/Select';

type Props = SelectProps & {
  requiredAction?: boolean
};

export default ({ requiredAction = false, ...selectProps }: Props) => (
  <Select allowClear placeholder='Выберите тип' {...selectProps}>
    {Object.keys(maintenanceMatrixValidAction)
      .filter(key =>
        requiredAction
          ? true
          : key !== maintenanceMatrixValidActionEnum.required,
      )
      .map(key => (
        <Option key={key} value={key}>
          {maintenanceMatrixValidAction[key]}
        </Option>
      ))}
  </Select>
);
