import { Typography } from 'antd';
import React, { Component } from 'react';
import styled from 'styled-components';
import BackgroundImage from './background.svg';
import LoginForm from './LoginForm';

import { ReactComponent as Logo } from './logo.svg';

const { Text } = Typography;

const SupportInfo = styled.p`
  color: #98a3ad;
  padding-top: 10px;
  font-size: 0.8em;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

class LoginScreen extends Component {
  render() {
    return (
      <Background>
        <Content>
          <LogoWrapper>
            <Logo style={{ margin: '0 7px' }} />
            <LogoTitleWrapper>
              <Text strong style={{ fontSize: '18px', marginBottom: '6px' }}>
                Система управления
              </Text>
              <Text strong style={{ fontSize: '18px', marginBottom: '6px' }}>
                транспортом А2В
              </Text>
              {/* <Text style={{ fontSize: '14px' }}>Транспорт</Text> */}
            </LogoTitleWrapper>
          </LogoWrapper>
          <LoginForm />
        </Content>
        <SupportInfo>
          Телефоны технической поддержки: +7 (987) 225-55-16, (843) 225-55-16
        </SupportInfo>
      </Background>
    );
  }
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
  background-image: url(${BackgroundImage});
  background-size: cover;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 500px;
  width: 400px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 50px;
  display: flex;
`;

const LogoTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default LoginScreen;
