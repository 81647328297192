// @flow

import type { Dispatch } from 'redux';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import moment from 'moment';
import orderBy from 'lodash/orderBy';

import type { Order, OrderStatus } from './../lib/types';
import { orderApi, routeApi, orderGpmApi } from './../lib/api';
import type { Action } from './redux';

export const SET_ORDER = 'vehicles/order/set';
export const CLEAN_ORDER = 'vehicles/order/clean';

export type OrderState = ?Order;

export type OrderStatusOptions = {
  status: OrderStatus,
  vehicleId?: number
};

const reducer = (
  state: OrderState = null,
  { type, payload }: Action
): OrderState => {
  switch (type) {
    case SET_ORDER:
      return { ...payload };
    case CLEAN_ORDER:
      return null;
    default:
      return state;
  }
};

/**
 * Копирование заявки
 *
 * @param orderId Id заявки
 */
export const copyOrder = (orderId: number, date: Date) => async (
  dispatch: Dispatch
) => {
  const order = await orderApi.fetchOrder(orderId);
  const copy = pick(order, [
    'type',
    'objective',
    'employeeId',
    'notation',
    'isBusinessTrip',
    'businessTripOrderNumber',
    'businessTripDecreeNumber',
    'vehicleType',
    'vehicleGroup',
    'workersCount',
    'withTrailer',
    'route.waypoints',
    // ГПМ поля
    'isGpm',
    'mainEngineerId',
    'gpmOwnerId',
    'vehicleModelId',
    'natureOfWork',
    'object',
    'safetyResponsibles',
    'riggers',
    'secondaryRiggers',
    'project',
    'powerLinesInfo',
    'voltage',
    'vhDistance',
    'safetyZone',
    'permitInfo',
    'admission'
  ]);
  dispatch({
    type: SET_ORDER,
    /**
     * Берем все необходимые поля для создания заявки
     *
     * Лучше их явно указывать, чтобы не пропустить те поля,
     * которые проставляются в процессе работы с заявкой
     */
    payload: {
      ...copy,
      route: {
        waypoints: order.waypoints
          ? order.waypoints.map(waypoint => {
              const month = moment(date).month();
              const day = moment(date).date();
              const year = moment(date).year();
              // Заменяем день, год и месяц
              waypoint.arrivedDateTime = moment
                .utc(waypoint.arrivedDateTime)
                .set({ month, date: day, year })
                .toISOString();
              waypoint.departureDateTime = moment
                .utc(waypoint.departureDateTime)
                .set({ month, date: day, year })
                .toISOString();
              return omit(waypoint, 'id');
            })
          : []
      }
    }
  });
};

export const addOrder: Function = (order: Order): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const addedRoute = await routeApi.addRoute(order.route);
  const newOrder = {
    ...order,
    routeId: addedRoute.id
  };
  const addedOrder = order.isGpm
    ? await orderGpmApi.addGpmOrder(newOrder)
    : await orderApi.addOrder(newOrder);
  dispatch({
    type: SET_ORDER,
    payload: {
      ...addedOrder
    }
  });
  return addedOrder;
};

export const updateOrder: Function = (order: Order): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const route = await routeApi.updateRoute(order.route);
  const updatedOrder = order.isGpm
    ? await orderGpmApi.updateGpmOrder(order)
    : // $FlowFixMe несовместимость object и promise
      await orderApi.updateOrder(order);
  const newOrder = {
    ...updatedOrder,
    route
  };
  dispatch({
    type: SET_ORDER,
    payload: newOrder
  });
  return newOrder;
};

export const fetchOrder = (orderId: number) => async (
  dispatch: Dispatch
): Promise<Order> => {
  const order: Order = await orderApi.fetchOrder(orderId);
  const route = await routeApi.fetchRoute(order.routeId);

  const payload = {
    ...order,
    route: {
      ...route,
      waypoints: orderBy(
        route.waypoints,
        ['arrivedDateTime', 'departureDateTime'],
        ['asc', 'asc']
      )
    }
  };

  dispatch({
    type: SET_ORDER,
    payload
  });
  return payload;
};

export const cleanOrder: Function = (): Function => async (
  dispatch: Dispatch
): Promise<void> =>
  dispatch({
    type: CLEAN_ORDER
  });

export const changeStatus = (id: number, options: OrderStatusOptions) => async (
  dispatch: Dispatch
) => {
  await orderApi.changeStatus({ ...options, id });
  const order = await orderApi.fetchOrder(id);
  const route = await routeApi.fetchRoute(order.routeId);
  dispatch({
    type: SET_ORDER,
    payload: {
      ...order,
      route
    }
  });
};

export default reducer;
