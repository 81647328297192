// @flow
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type {
  OsagoGeneralMultiplier,
  OsagoBaseRateMultiplier,
  OsagoPowerMultiplier,
  OsagoBranchMultiplier,
  OsagoKprMultiplier
} from './../types';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

export const convertDataFromServer = (
  osagoBaseRate: OsagoBaseRateMultiplier
) => ({
  ...osagoBaseRate,
  bMin: convertFromKopToRub(osagoBaseRate.bMin),
  cMax16Min: convertFromKopToRub(osagoBaseRate.cMax16Min),
  cMin16Min: convertFromKopToRub(osagoBaseRate.cMin16Min),
  dMax16Min: convertFromKopToRub(osagoBaseRate.dMax16Min),
  dMin16Min: convertFromKopToRub(osagoBaseRate.dMin16Min),
  otherMin: convertFromKopToRub(osagoBaseRate.otherMin),
  bMax: convertFromKopToRub(osagoBaseRate.bMax),
  cMax16Max: convertFromKopToRub(osagoBaseRate.cMax16Max),
  cMin16Max: convertFromKopToRub(osagoBaseRate.cMin16Max),
  dMax16Max: convertFromKopToRub(osagoBaseRate.dMax16Max),
  dMin16Max: convertFromKopToRub(osagoBaseRate.dMin16Max),
  otherMax: convertFromKopToRub(osagoBaseRate.otherMax),
  bCur: convertFromKopToRub(osagoBaseRate.bCur),
  cMax16Cur: convertFromKopToRub(osagoBaseRate.cMax16Cur),
  cMin16Cur: convertFromKopToRub(osagoBaseRate.cMin16Cur),
  dMax16Cur: convertFromKopToRub(osagoBaseRate.dMax16Cur),
  dMin16Cur: convertFromKopToRub(osagoBaseRate.dMin16Cur),
  otherCur: convertFromKopToRub(osagoBaseRate.otherCur)
});

export const convertDataToServer = (
  osagoBaseRate: OsagoBaseRateMultiplier
) => ({
  ...osagoBaseRate,
  bMin: convertFromRubToKop(osagoBaseRate.bMin),
  cMax16Min: convertFromRubToKop(osagoBaseRate.cMax16Min),
  cMin16Min: convertFromRubToKop(osagoBaseRate.cMin16Min),
  dMax16Min: convertFromRubToKop(osagoBaseRate.dMax16Min),
  dMin16Min: convertFromRubToKop(osagoBaseRate.dMin16Min),
  otherMin: convertFromRubToKop(osagoBaseRate.otherMin),
  bMax: convertFromRubToKop(osagoBaseRate.bMax),
  cMax16Max: convertFromRubToKop(osagoBaseRate.cMax16Max),
  cMin16Max: convertFromRubToKop(osagoBaseRate.cMin16Max),
  dMax16Max: convertFromRubToKop(osagoBaseRate.dMax16Max),
  dMin16Max: convertFromRubToKop(osagoBaseRate.dMin16Max),
  otherMax: convertFromRubToKop(osagoBaseRate.otherMax),
  bCur: convertFromRubToKop(osagoBaseRate.bCur),
  cMax16Cur: convertFromRubToKop(osagoBaseRate.cMax16Cur),
  cMin16Cur: convertFromRubToKop(osagoBaseRate.cMin16Cur),
  dMax16Cur: convertFromRubToKop(osagoBaseRate.dMax16Cur),
  dMin16Cur: convertFromRubToKop(osagoBaseRate.dMin16Cur),
  otherCur: convertFromRubToKop(osagoBaseRate.otherCur)
});

export const deleteOsagoBranchMultiplier = async (
  id: number
): Promise<OsagoBranchMultiplier> => {
  const deleted = await fetchRequest.delete(`/osagoBranchMultiplier/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить коэффициент ОСАГО для филиала');
};

export const addOsagoBranchMultiplier = async (
  osagoBranchMultiplier: OsagoBranchMultiplier
): Promise<OsagoBranchMultiplier> => {
  const added = await fetchRequest.post(
    '/osagoBranchMultiplier',
    osagoBranchMultiplier
  );
  if (added) return added;
  throw new Error('Не удалось добавить коэффициенты ОСАГО для филиала');
};

export const updateOsagoBranchMultiplier = async (
  osagoBranchMultiplier: OsagoBranchMultiplier
): Promise<OsagoBranchMultiplier> => {
  const updated = await fetchRequest.put(
    '/osagoBranchMultiplier',
    osagoBranchMultiplier
  );
  if (updated) return updated;
  throw new Error('Не удалось отредактировать коэффициент ОСАГО для филиала');
};

export const getOsagoBranchMultiplier = async (
  id: number
): Promise<OsagoBranchMultiplier> => {
  const osagoBranchMultiplier = await fetchRequest.get(
    `/osagoBranchMultiplier/${id}`
  );
  if (osagoBranchMultiplier) return osagoBranchMultiplier;
  throw new Error('Не удалось получить коэффициент ОСАГО для филиала');
};

export const fetchOsagoBranchMultiplier = async (
  params: FetchListParams<OsagoBranchMultiplier> = initialFetchListParams
): Promise<ListResponse<OsagoBranchMultiplier>> => {
  const osagoBranchMultiplier = fetchRequest.get('/osagoBranchMultiplier', {
    ...initialFetchListParams,
    ...params
  });
  if (osagoBranchMultiplier) return osagoBranchMultiplier;
  throw new Error('Не удалось получить коэффициенты ОСАГО для филиалов');
};

export const fetchOsagoBaseRateMultiplier = async (
  params: FetchListParams<OsagoBaseRateMultiplier> = initialFetchListParams
): Promise<ListResponse<OsagoBaseRateMultiplier>> => {
  const osagoBaseRateMultiplier = await fetchRequest.get(
    '/osagoBaseRateMultiplier',
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (osagoBaseRateMultiplier)
    return {
      ...osagoBaseRateMultiplier,
      data: osagoBaseRateMultiplier.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось получить коэффициенты ОСАГО для моделей ТС');
};

export const getOsagoBaseRateMultiplier = async (
  id: number
): Promise<OsagoBaseRateMultiplier> => {
  const osagoBaseRateMultiplier = await fetchRequest.get(
    `/osagoBaseRateMultiplier/${id}`
  );
  if (osagoBaseRateMultiplier)
    return convertDataFromServer(osagoBaseRateMultiplier);
  throw new Error('Не удалось получить коэффициенты ОСАГО для моделей ТС');
};

export const deleteOsagoBaseRateMultiplier = async (
  id: number
): Promise<OsagoBaseRateMultiplier> => {
  const deleted = await fetchRequest.delete(`/osagoBaseRateMultiplier/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить запись');
};

export const addOsagoBaseRateMultiplier = async (
  osagoBaseRateMultiplier: OsagoBaseRateMultiplier
): Promise<OsagoBaseRateMultiplier> => {
  const added = await fetchRequest.post(
    '/osagoBaseRateMultiplier',
    convertDataToServer(osagoBaseRateMultiplier)
  );
  if (added) return added;
  throw new Error('Не удалось добавить коэффициенты ОСАГО для модели ТС');
};

export const updateOsagoBaseRateMultiplier = async (
  osagoBaseRateMultiplier: OsagoBaseRateMultiplier
): Promise<OsagoBaseRateMultiplier> => {
  const updated = await fetchRequest.put(
    '/osagoBaseRateMultiplier',
    convertDataToServer(osagoBaseRateMultiplier)
  );
  if (updated) return updated;
  throw new Error('Не удалось изменить коэффициенты ОСАГО для модели ТС');
};

export const fetchOsagoGeneralMultiplier = async (
  params: FetchListParams<OsagoGeneralMultiplier> = initialFetchListParams
): Promise<ListResponse<OsagoGeneralMultiplier>> => {
  const osagoGeneralMultiplier = fetchRequest.get('/osagoMultiplier', params);
  if (osagoGeneralMultiplier) return osagoGeneralMultiplier;
  throw new Error('Не удалось получить общие коэффициенты ОСАГО');
};

export const addOsagoGeneralMultiplier = async (
  osagoGeneralMultiplier: OsagoGeneralMultiplier
): Promise<OsagoGeneralMultiplier> => {
  const added = fetchRequest.post('/osagoMultiplier', osagoGeneralMultiplier);
  if (added) return added;
  throw new Error('Не удалось добавить общие коэффициенты ОСАГО');
};

export const updateOsagoGeneralMultiplier = async (
  osagoGeneralMultiplier: OsagoGeneralMultiplier
): Promise<OsagoGeneralMultiplier> => {
  const updated = fetchRequest.put('/osagoMultiplier', osagoGeneralMultiplier);
  if (updated) return updated;
  throw new Error('Не удалось изменить общие коэффициенты ОСАГО');
};

export const getOsagoGeneralMultiplier = async (
  id: number
): Promise<OsagoGeneralMultiplier> => {
  const osagoGeneralMultiplier = fetchRequest.get(`/osagoMultiplier/${id}`);
  if (osagoGeneralMultiplier) return osagoGeneralMultiplier;
  throw new Error('Не найден общий коэффициент ОСАГО');
};

export const deleteOsagoGeneralMultiplier = async (
  id: number
): Promise<OsagoGeneralMultiplier> => {
  const deleted = await fetchRequest.delete(`/osagoMultiplier/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить общий коэф. ОСАГО');
};

export const fetchOsagoKprMultiplier = async (
  params: FetchListParams<OsagoKprMultiplier> = initialFetchListParams
): Promise<ListResponse<OsagoKprMultiplier>> => {
  const osagoKprMultiplier = await fetchRequest.get(
    '/osagoKprMultiplier',
    params
  );
  if (osagoKprMultiplier) return osagoKprMultiplier;
  throw new Error('Не удалось получить КПР коэффициенты ОСАГО');
};

export const getOsagoKprMultiplier = async (
  id: number
): Promise<OsagoKprMultiplier> => {
  const osagoKprMultiplier = await fetchRequest.get(
    `/osagoKprMultiplier/${id}`
  );
  if (osagoKprMultiplier) return osagoKprMultiplier;
  throw new Error('Не удалось получить КПР коэффициент ОСАГО');
};

export const deleteOsagoKprMultiplier = async (
  id: number
): Promise<OsagoKprMultiplier> => {
  const deleted = await fetchRequest.delete(`/osagoKprMultiplier/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось получить КПР коэффициент ОСАГО');
};

export const addOsagoKprMultiplier = async (
  osagoKprMultiplier: OsagoKprMultiplier
): Promise<OsagoKprMultiplier> => {
  const added = await fetchRequest.post(
    '/osagoKprMultiplier',
    osagoKprMultiplier
  );
  if (added) return added;
  throw new Error('Не удалось добавить КПР коэффициенты ОСАГО');
};

export const updateOsagoKprMultiplier = async (
  osagoKprMultiplier: OsagoKprMultiplier
): Promise<OsagoKprMultiplier> => {
  const updated = await fetchRequest.put(
    '/osagoKprMultiplier',
    osagoKprMultiplier
  );
  if (updated) return updated;
  throw new Error('Не удалось отредактировать КПР коэффициенты ОСАГО');
};

export const fetchOsagoPowerMultiplier = async (
  params: FetchListParams<OsagoPowerMultiplier> = initialFetchListParams
): Promise<ListResponse<OsagoPowerMultiplier>> => {
  const osagoPowerMultiplier = await fetchRequest.get(
    '/osagoPowerMultiplier',
    params
  );
  if (osagoPowerMultiplier) return osagoPowerMultiplier;
  throw new Error('Не удалось получить КПР коэффициенты ОСАГО');
};

export const getOsagoPowerMultiplier = async (
  id: number
): Promise<OsagoPowerMultiplier> => {
  const osagoPowerMultiplier = await fetchRequest.get(
    `/osagoPowerMultiplier/${id}`
  );
  if (osagoPowerMultiplier) return osagoPowerMultiplier;
  throw new Error('Не удалось получить коэффициент ОСАГО мощностей');
};

export const deleteOsagoPowerMultiplier = async (
  id: number
): Promise<OsagoPowerMultiplier> => {
  const deleted = await fetchRequest.delete(`/osagoPowerMultiplier/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось получить коэффициент ОСАГО мощностей');
};

export const addOsagoPowerMultiplier = async (
  osagoPowerMultiplier: OsagoPowerMultiplier
): Promise<OsagoKprMultiplier> => {
  const added = await fetchRequest.post(
    '/osagoPowerMultiplier',
    osagoPowerMultiplier
  );
  if (added) return added;
  throw new Error('Не удалось добавить коэффициенты ОСАГО мощностей');
};

export const updateOsagoPowerMultiplier = async (
  osagoPowerMultiplier: OsagoPowerMultiplier
): Promise<OsagoKprMultiplier> => {
  const updated = await fetchRequest.put(
    '/osagoPowerMultiplier',
    osagoPowerMultiplier
  );
  if (updated) return updated;
  throw new Error('Не удалось отредактировать коэффициенты ОСАГО мощностей');
};

export const fetchDateIntersectionMultiplier = async <T>(
  fetchName: string,
  params: T
) => {
  const urls = {
    kpr: 'OsagoKprMultiplier',
    baseRate: 'OsagoBaseRateMultiplier',
    general: 'OsagoMultiplier',
    power: 'OsagoPowerMultiplier',
    branch: 'OsagoBranchMultiplier'
  };
  const osagoIntersaction = await fetchRequest.get(
    `/${urls[fetchName]}/CheckIntersections`,
    params
  );
  if (typeof osagoIntersaction !== 'boolean')
    throw new Error('Не удалось проверить пересечение диапазонов!');
  return osagoIntersaction;
};

export default {
  fetchOsagoPowerMultiplier,
  getOsagoPowerMultiplier,
  deleteOsagoPowerMultiplier,
  addOsagoPowerMultiplier,
  updateOsagoPowerMultiplier,
  deleteOsagoBranchMultiplier,
  addOsagoBranchMultiplier,
  updateOsagoBranchMultiplier,
  getOsagoBranchMultiplier,
  fetchOsagoBranchMultiplier,
  fetchOsagoBaseRateMultiplier,
  getOsagoBaseRateMultiplier,
  deleteOsagoBaseRateMultiplier,
  addOsagoBaseRateMultiplier,
  updateOsagoBaseRateMultiplier,
  fetchOsagoGeneralMultiplier,
  addOsagoGeneralMultiplier,
  updateOsagoGeneralMultiplier,
  getOsagoGeneralMultiplier,
  deleteOsagoGeneralMultiplier,
  fetchOsagoKprMultiplier,
  deleteOsagoKprMultiplier,
  getOsagoKprMultiplier,
  addOsagoKprMultiplier,
  updateOsagoKprMultiplier,
  fetchDateIntersectionMultiplier
};
