// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { navigate, Router } from '@reach/router';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';

import { fetchVehicle, cleanVehicle, updateVehicle } from '../../ducks/vehicle';
import type { Vehicle, UserAccess, FileTag } from '../../lib/types';
import type { DropdownType } from './../../components/ui/Dropdown';
import {
  vehicleTypes,
  vehicleStatusEnum,
  accessTypeEnum,
  fileTagEnum,
} from '../../lib/enum';
import { VehicleStatus } from '../../components';
import type { AppState } from '../../ducks/redux';
import { Panel } from './../../components/layout';
import { Popconfirm, Tabs, TabItem, Dropdown } from './../../components/ui';
import { Card } from './../../components';
import { withUserAccess } from './../withUserAccess';
import * as VehicleTabs from './components/tabs';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import FuelMeasurementForm from './components/FuelMeasurementForm';
import { vehicleApi } from '../../lib/api';
import { applyMaskToValue } from '../../lib/helpers';
import { canShowOsagoData } from './lib';
import { notificationLoading } from './../../components/Notifications';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

const {
  EquipmentTab,
  MainTab,
  FuelTab,
  InsuranceTab,
  KilometrageTab,
  MaintenanceTab,
  FilesTab,
  TrailersTab,
} = VehicleTabs;

type Props = {
  vehicleId: number,
  vehicle: Vehicle,
  fetchVehicle: (id: number) => Promise<void>,
  cleanVehicle: Function,
  updateVehicle: (vehicle: Vehicle) => void,
  userAccess: UserAccess[],
  location: Location & { state: { tab: string } },
  anchor?: string,
  tabName: string,
};

const CardWrapper = styled.div``;
const CardPanel = styled(Panel)`
  display: flex;
  padding-top: 0;
  justify-content: space-between;
`;
const CarInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const CarInfo = styled.div`
  display: flex;
  flex-direction: column;
  & > h1 {
    margin-bottom: 12px;
  }
`;
const CardKilometrage = styled.div`
  display: flex;
  & > * {
    margin-right: 50px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const Operations = styled.div`
  display: inline-flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .tabs__item {
    font-weight: bold;
  }
`;

const { Field } = Card;

export const canEditVehicleAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.creatingVehicle,
];

export const canApproveVehicleAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingVehicle,
];

export const canAddMeasurementAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.gager,
];

export const canHandleEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment,
];

type TabsProps = {
  vehicleId: number,
  filesTag?: FileTag,
  tabName?:
    | 'documentation'
    | 'insurance'
    | 'kilometrage'
    | 'fuel-and-oils'
    | 'equipment'
    | 'maintenance'
    | 'files',
  vehicle: Vehicle,
};

const HeaderTabs = ({ vehicleId, tabName, filesTag, vehicle }: TabsProps) => {
  return (
    <StyledTabs
      defaultTab={
        filesTag
          ? filesTag === fileTagEnum.mainDocs
            ? 'files'
            : 'documentation'
          : tabName
      }
      background="#F0F4F8"
    >
      <TabItem
        tabKey="documentation"
        label="Документация"
        onClick={() => navigate(`/vehicles/${vehicleId}/documentation`)}
      />
      {canShowOsagoData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
        <TabItem
          tabKey="insurance"
          label="Страхование"
          onClick={() => navigate(`/vehicles/${vehicleId}/insurance`)}
        />
      )}
      <TabItem
        tabKey="kilometrage"
        label="Пробег"
        onClick={() => navigate(`/vehicles/${vehicleId}/kilometrage`)}
      />
      <TabItem
        tabKey="fuel-and-oils"
        label="ГСМ"
        onClick={() => navigate(`/vehicles/${vehicleId}/fuel-and-oils`)}
      />
      <TabItem
        tabKey="equipment"
        label="Оборудование"
        onClick={() => navigate(`/vehicles/${vehicleId}/equipment`)}
      />
      <TabItem
        tabKey="maintenance"
        label="Обслуживание"
        onClick={() => navigate(`/vehicles/${vehicleId}/maintenance`)}
      />
      <TabItem
        tabKey="files"
        label="Файлы"
        onClick={() => navigate(`/vehicles/${vehicleId}/files/mainDocs`)}
      />
      {vehicle.withTrailer && (
        <TabItem
          tabKey="trailers"
          label="Прицепы"
          onClick={() => navigate(`/vehicles/${vehicleId}/trailers`)}
        />
      )}
    </StyledTabs>
  );
};

type State = {
  fuelMeasurementFormVisible: boolean,
};

export class VehicleCard extends Component<Props, State> {
  state = {
    fuelMeasurementFormVisible: false,
  };

  static defaultProps = {
    tabName: 'documentation',
  };

  dropdown: ?DropdownType;

  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
      navigate('/vehicles');
    }
  }

  approveVehicle = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle({
        ...this.props.vehicle,
        status: vehicleStatusEnum.working,
      });
      notification.success({
        message: 'Статус успешно обновлен',
      });
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при изменении статуса',
      });
    } finally {
      notification.close('saving');
    }
  };

  /**
   * Отправление в резерв
   */
  sendToReserve = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle({
        ...this.props.vehicle,
        status: vehicleStatusEnum.reserved,
      });
      notification.success({
        message: 'Успешно отправлено в резерв',
      });
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при отправлении в резерв',
      });
    } finally {
      notification.close('saving');
    }
  };

  /**
   * Отправление в ремонт
   */
  sendToRepair = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle({
        ...this.props.vehicle,
        status: vehicleStatusEnum.onRepair,
      });
      notification.success({
        message: 'Успешно отправлено в ремонт',
      });
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при отправлении в ремонт',
      });
    } finally {
      notification.close('saving');
    }
  };

  /**
   * Списание ТС
   */
  writeOfVehicle = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle({
        ...this.props.vehicle,
        status: vehicleStatusEnum.isArchive,
      });
      notification.success({
        message: 'Успешно списано',
      });
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при списании ТС',
      });
    } finally {
      notification.close('saving');
    }
  };

  canEditVehicle = () =>
    this.props.userAccess.some((access) =>
      canEditVehicleAccess.includes(access)
    );

  canApproveVehicle = () =>
    this.props.userAccess.some((access) =>
      canApproveVehicleAccess.includes(access)
    );

  canAddMeasurement = () =>
    this.props.userAccess.some((access) =>
      canAddMeasurementAccess.includes(access)
    );

  canHandleEquipment = () =>
    this.props.userAccess.some((access) =>
      canHandleEquipmentAccess.includes(access)
    );

  deleteVehicle = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await vehicleApi.deleteVehicle(this.props.vehicleId);
      navigate('/vehicles');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  canHandleMaintenanceServices = () => {
    return this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ].includes(access)
    );
  };

  canViewingSystemJournal = () => {
    return this.props.userAccess.some((access) =>
      [accessTypeEnum.admin, accessTypeEnum.viewingSystemJournal].includes(
        access
      )
    );
  };

  updateKilometrage = async () => {
    try {
      notificationLoading({
        message: 'Получение пробега и уровня топлива по ТС...',
        key: 'updating',
      });
      await vehicleApi.updateFromHozu(this.props.vehicleId);
      await this.props.fetchVehicle(this.props.vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('updating');
    }
  };

  render() {
    const { vehicle, vehicleId } = this.props;
    if (!vehicle) return null;
    const canEdit = this.canEditVehicle();
    const canApprove = this.canApproveVehicle();
    const enableAddMeasurement = this.canAddMeasurement();
    const canHandleEquipment = this.canHandleEquipment();
    const canHandleMaintenanceServices = this.canHandleMaintenanceServices();
    const canViewingSystemJournal = this.canViewingSystemJournal();
    const { fuelsAndOils } = vehicle;

    return (
      <CardWrapper>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/vehicles">Собственные ТС</Crumb>
              <Crumb to={`/vehicles/${vehicle.id}`}>ТС</Crumb>
            </Breadcrumbs>
          }
          right={
            <Operations>
              {vehicleStatusEnum.working === vehicle.status && canApprove && (
                <Button
                  type="primary"
                  onClick={this.sendToRepair}
                  data-cy="repair"
                >
                  В ремонте
                </Button>
              )}
              {[
                vehicleStatusEnum.reserved,
                vehicleStatusEnum.onRepair,
                vehicleStatusEnum.isArchive,
                vehicleStatusEnum.draft,
              ].includes(vehicle.status) &&
                canApprove && (
                  <Button
                    type="primary"
                    onClick={this.approveVehicle}
                    data-cy="approve"
                  >
                    Принять в работу
                  </Button>
                )}
              {(enableAddMeasurement ||
                canEdit ||
                canViewingSystemJournal ||
                canApprove) && (
                <Dropdown
                  ref={(dropdown) => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      {enableAddMeasurement && (
                        <Menu.Item
                          onClick={() => {
                            this.dropdown &&
                              this.dropdown.onVisibleChange(false);
                            this.setState({ fuelMeasurementFormVisible: true });
                          }}
                        >
                          Контрольный замер топлива
                        </Menu.Item>
                      )}
                      {canEdit && (
                        <Menu.Item
                          onClick={async () => {
                            this.dropdown &&
                              this.dropdown.onVisibleChange(false);
                            await this.updateKilometrage();
                          }}
                        >
                          Обновить данные по пробегу
                        </Menu.Item>
                      )}
                      {enableAddMeasurement && canEdit && <Menu.Divider />}
                      {canViewingSystemJournal && (
                        <Menu.Item
                          onClick={() =>
                            navigate(
                              `/admin/journals?entityType=vehicle&entityId=${vehicleId}`
                            )
                          }
                        >
                          История изменений
                        </Menu.Item>
                      )}
                      {canEdit &&
                        vehicleStatusEnum.isArchive !== vehicle.status && (
                          <Menu.Item>
                            <Popconfirm
                              overlayStyle={{
                                zIndex: 2000,
                              }}
                              title="Вы действительно хотите списать ТС?"
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={this.writeOfVehicle}
                              placement="left"
                              onVisibleChange={(flag) =>
                                this.dropdown &&
                                this.dropdown.onVisibleChange(flag)
                              }
                            >
                              Списание ТС
                            </Popconfirm>
                          </Menu.Item>
                        )}
                      {[
                        vehicleStatusEnum.onRepair,
                        vehicleStatusEnum.working,
                      ].includes(vehicle.status) &&
                        canApprove && (
                          <Menu.Item>
                            <Popconfirm
                              overlayStyle={{
                                zIndex: 2000,
                              }}
                              title="Вы действительно хотите отправить ТС в резерв?"
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={this.sendToReserve}
                              placement="left"
                              onVisibleChange={(flag) =>
                                this.dropdown &&
                                this.dropdown.onVisibleChange(flag)
                              }
                            >
                              В резерв
                            </Popconfirm>
                          </Menu.Item>
                        )}
                      {canEdit && (
                        <Menu.Item
                          onClick={() =>
                            navigate(`/vehicles/edit/${vehicleId}`)
                          }
                        >
                          Редактировать
                        </Menu.Item>
                      )}
                      {canEdit && (
                        <Menu.Item>
                          <Popconfirm
                            title="Вы действительно хотите удалить?"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={this.deleteVehicle}
                            placement="bottomRight"
                            onVisibleChange={(flag) =>
                              this.dropdown &&
                              this.dropdown.onVisibleChange(flag)
                            }
                          >
                            Удалить
                          </Popconfirm>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    style={{ padding: '9px' }}
                    type="primary"
                    icon={<EllipsisOutlined />}
                  />
                </Dropdown>
              )}
            </Operations>
          }
        />
        <CardPanel>
          <CarInfoWrapper>
            <CarInfo>
              <h1>
                {vehicle.vehicleModel &&
                  `${vehicleTypes[vehicle.vehicleModel.type]} ${
                    vehicle.vehicleModel.brandName
                  } ${vehicle.vehicleModel.name}`}
              </h1>
              <CardKilometrage>
                <Field label="Статус">
                  <VehicleStatus status={vehicle.status} />
                </Field>
                {vehicle.licensePlate && (
                  <Field label="Гос. номер">
                    {applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )}
                  </Field>
                )}
                <Field label="Год выпуска">{vehicle.yearIssued}</Field>
                {parseFloat(vehicle.engineWorkHours) > 0 && (
                  <Field label="Показания счетчика маш. часов">
                    {vehicle.engineWorkHours} ч
                  </Field>
                )}
              </CardKilometrage>
            </CarInfo>
          </CarInfoWrapper>
        </CardPanel>
        <Router primary={false}>
          <HeaderTabs
            path="files/:filesTag"
            vehicleId={vehicle.id}
            vehicle={vehicle}
          />
          <HeaderTabs
            path=":tabName"
            vehicleId={vehicle.id}
            vehicle={vehicle}
          />
          <HeaderTabs
            path=":tabName/:anchor"
            vehicleId={vehicle.id}
            vehicle={vehicle}
          />
          <HeaderTabs
            path="/"
            vehicleId={vehicle.id}
            tabName="documentation"
            vehicle={vehicle}
          />
        </Router>

        <Router primary={false}>
          <MainTab
            path="documentation"
            default
            vehicle={vehicle}
            editable={canEdit}
            canHandleMaintenanceServices={canHandleMaintenanceServices}
            anchor={this.props.anchor}
          />
          <MainTab
            path="documentation/:anchor"
            vehicle={vehicle}
            editable={canEdit}
            canHandleMaintenanceServices={canHandleMaintenanceServices}
            anchor={this.props.anchor}
          />
          <InsuranceTab
            path="insurance"
            vehicle={vehicle}
            editable={canEdit}
            canHandleMaintenanceServices={canHandleMaintenanceServices}
          />
          <InsuranceTab
            path="insurance/:anchor"
            vehicle={vehicle}
            editable={canEdit}
            canHandleMaintenanceServices={canHandleMaintenanceServices}
          />
          <KilometrageTab
            path="kilometrage"
            vehicle={vehicle}
            editable={canEdit}
          />
          <KilometrageTab
            path="kilometrage/:anchor"
            vehicle={vehicle}
            editable={canEdit}
          />
          <FuelTab
            path="fuel-and-oils"
            vehicle={vehicle}
            fuelsAndOils={fuelsAndOils}
            editable={canEdit}
            canAddFuelMeasurement={enableAddMeasurement}
          />
          <FuelTab
            path="fuel-and-oils/:anchor"
            vehicle={vehicle}
            fuelsAndOils={fuelsAndOils}
            editable={canEdit}
            canAddFuelMeasurement={enableAddMeasurement}
          />
          <EquipmentTab
            path="equipment"
            vehicle={vehicle}
            editable={canEdit}
            canAddFuelMeasurement={enableAddMeasurement}
            canHandleEquipment={canHandleEquipment}
          />
          <EquipmentTab
            path="equipment/:anchor"
            vehicle={vehicle}
            editable={canEdit}
            canAddFuelMeasurement={enableAddMeasurement}
            canHandleEquipment={canHandleEquipment}
          />
          <MaintenanceTab
            path="maintenance"
            vehicle={vehicle}
            editable={canEdit}
            canHandleMaintenanceServices={canHandleMaintenanceServices}
          />
          <MaintenanceTab
            path="maintenance/:anchor"
            vehicle={vehicle}
            editable={canEdit}
            canHandleMaintenanceServices={canHandleMaintenanceServices}
          />
          <FilesTab
            path="files/mainDocs"
            vehicle={vehicle}
            editable={canEdit}
          />
          <TrailersTab path="trailers" vehicle={vehicle} editable={canEdit} />
        </Router>
        <FuelMeasurementForm
          visible={this.state.fuelMeasurementFormVisible}
          hideModal={() => this.setState({ fuelMeasurementFormVisible: false })}
        />
      </CardWrapper>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10),
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle,
  }
)(withUserAccess(VehicleCard));
