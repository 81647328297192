// @flow
import { EllipsisOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import isEmpty from 'lodash/isEmpty';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Menu from 'antd/lib/menu';

import type { Contract, User, UserAccess } from '../../lib/types';
import { contractApi, stageApi, offerApi } from '../../lib/api';
import {
  entityStatus,
  entityStatusEnum,
  contractDocumentTypeEnum,
} from '../../lib/enum';
import {
  goBack,
  formatDateTimeToString,
  convertEmployeeToString,
} from './../../lib/helpers';

import { Card } from './../../components';
import Grid, { GridItem } from './../../components/layout/Grid';
import {
  Header,
  Panel,
  Section,
  SectionTitle,
} from './../../components/layout';
import { Crumb } from './../../components/layout/Breadcrumbs';
import Breadcrumbs from './../../components/layout/Breadcrumbs';
import { Icon, Dropdown } from './../../components/ui';
import { notificationLoading } from './../../components/Notifications';

import { withUserAccess } from './../withUserAccess';
import { addContractAccess, approvingStatusAccess } from './accessRight';

import { Stages, Offers } from './components';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Content = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  contractId: number,
  userProfile: User,
  userAccess: UserAccess[],
};

type State = {
  contract: ?Contract,
};

export class ContractCard extends React.Component<Props, State> {
  state = {
    contract: null,
  };

  async componentDidMount() {
    const { contractId } = this.props;
    try {
      const contract = await contractApi.getContract(contractId);
      this.setState({ contract });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  fetchStages = async () => {
    const { contract } = this.state;
    if (contract) {
      try {
        const stages = await stageApi.fetchStage({ contractId: contract.id });
        this.setState({
          contract: {
            ...contract,
            stages: stages.data,
          },
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  };

  fetchOffers = async () => {
    const { contract } = this.state;
    if (contract) {
      try {
        const offers = await offerApi.fetchOffer({ contractId: contract.id });
        this.setState({
          contract: {
            ...contract,
            offers: offers.data,
          },
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  };

  handleCancel = () => goBack('/budget/contract');

  changeStatus = async ({ key }: any) => {
    const { contractId } = this.props;
    const { contract } = this.state;
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'changeStatus',
      });
      if (
        contract &&
        isEmpty(contract.stages) &&
        [entityStatusEnum.approvement, entityStatusEnum.approved].includes(key)
      ) {
        throw new Error('Заполните этапы');
      }
      const updated = await contractApi.changeStatus(contractId, key);
      this.setState({ contract: updated });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('changeStatus');
    }
  };

  canChangeStatus = () => {
    const { contract } = this.state;
    return (
      this.props.userAccess.some((access) =>
        approvingStatusAccess.includes(access)
      ) ||
      (contract && contract.userId === this.props.userProfile.id)
    );
  };

  isAccount = () =>
    this.state.contract?.documentType === contractDocumentTypeEnum.account;

  canAprroved = () =>
    this.props.userAccess.some((access) =>
      approvingStatusAccess.includes(access)
    );

  canAdd = () =>
    this.props.userAccess.some((access) => addContractAccess.includes(access));

  render() {
    const { contractId } = this.props;
    const { contract } = this.state;
    if (contract === null) {
      return null;
    }
    const status = contract && contract.contractStatus;
    const readOnly =
      !this.canAdd() ||
      [
        entityStatusEnum.approvement,
        entityStatusEnum.approved,
        entityStatusEnum.declined,
      ].includes(status);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/contract">Реестр договоров</Crumb>
              <Crumb>Договор №{contractId}</Crumb>
            </Breadcrumbs>
          }
          right={
            <>
              {!readOnly &&
                (this.canChangeStatus() || this.canAprroved()) &&
                [entityStatusEnum.created, entityStatusEnum.editing].includes(
                  status
                ) && (
                  <Button
                    onClick={() =>
                      this.changeStatus({ key: entityStatusEnum.approvement })
                    }
                  >
                    На согласование
                  </Button>
                )}
              {this.canAprroved() &&
                [
                  entityStatusEnum.approvement,
                  entityStatusEnum.declined,
                  entityStatusEnum.approved,
                ].includes(status) && (
                  <Dropdown
                    overlay={
                      <Menu onClick={this.changeStatus}>
                        {entityStatusEnum.approvement === status &&
                          this.canChangeStatus() && (
                            <Menu.Item key={entityStatusEnum.approved}>
                              Утвердить
                            </Menu.Item>
                          )}
                        {entityStatusEnum.approvement === status &&
                          this.canChangeStatus() && (
                            <Menu.Item key={entityStatusEnum.declined}>
                              Отклонить
                            </Menu.Item>
                          )}
                        {[
                          entityStatusEnum.approved,
                          entityStatusEnum.declined,
                        ].includes(status) &&
                          this.canChangeStatus() && (
                            <Menu.Item key={entityStatusEnum.editing}>
                              Вернуть на редактирование
                            </Menu.Item>
                          )}
                      </Menu>
                    }
                  >
                    <Button>
                      <EllipsisOutlined
                        style={{ fontSize: 16, color: '#2770FF' }}
                      />
                    </Button>
                  </Dropdown>
                )}
            </>
          }
        />
        {contract && (
          <>
            <StyledPanel>
              <h1 style={{ marginBottom: '10px' }}>
                Договор №{contractId}
                {this.canAdd() && !readOnly && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/budget/contract/${contractId}/edit`)
                    }
                    type="edit"
                    size={16}
                  />
                )}
              </h1>
              <Grid gutter="16px" cols={7}>
                <GridItem>
                  <Field label="Статус">
                    {entityStatus[contract.contractStatus]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата начала">
                    {contract.startDate
                      ? `${formatDateTimeToString(
                          contract.startDate,
                          'DD.MM.YYYY'
                        )}`
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания">
                    {contract.endDate
                      ? `${formatDateTimeToString(
                          contract.endDate,
                          'DD.MM.YYYY'
                        )}`
                      : '-'}
                  </Field>
                </GridItem>
                {!this.isAccount() && (
                  <>
                    <GridItem>
                      <Field label="Контрагент">
                        {contract.contractor
                          ? contract.contractor.company.name
                          : '-'}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Номер протокола">
                        {contract.protocolNumber
                          ? contract.protocolNumber
                          : '-'}
                      </Field>
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <Field label="Куратор">
                    {contract.employee
                      ? convertEmployeeToString(contract.employee)
                      : '-'}
                  </Field>
                </GridItem>
              </Grid>
            </StyledPanel>
            {(contract.registrationDate ||
              contract.approvalDate ||
              contract.closingDate) &&
              !this.isAccount() && (
                <Section>
                  <SectionTitle divider>Общая информация</SectionTitle>
                  <Content>
                    <Grid cols={3}>
                      <GridItem>
                        <Field label="Дата регистрации">
                          {`${
                            contract.registrationDate
                              ? formatDateTimeToString(
                                  contract.registrationDate,
                                  'DD.MM.YYYY'
                                )
                              : '-'
                          }`}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата утверждения">
                          {`${
                            contract.approvalDate
                              ? formatDateTimeToString(
                                  contract.approvalDate,
                                  'DD.MM.YYYY'
                                )
                              : '-'
                          }`}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата закрытия">
                          {`${
                            contract.closingDate
                              ? formatDateTimeToString(
                                  contract.closingDate,
                                  'DD.MM.YYYY'
                                )
                              : '-'
                          }`}
                        </Field>
                      </GridItem>
                    </Grid>
                  </Content>
                </Section>
              )}
            <Stages
              readOnly={readOnly}
              contractId={contract.id}
              stages={contract.stages}
              fetchStages={this.fetchStages}
            />
            <Section>
              <SectionTitle divider>
                Журналы предложений поставщика
              </SectionTitle>
              <Content>
                <Offers
                  readOnly={readOnly}
                  contractId={contract.id}
                  offers={contract.offers}
                  fetchOffers={this.fetchOffers}
                />
              </Content>
            </Section>
          </>
        )}
      </>
    );
  }
}

export default withUserAccess(ContractCard);
