// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import type {
  OrgUnitNode,
  Vehicle,
  VehicleModel,
  UserAccess,
  VehicleStatus as VehicleStatusType,
  ListState
} from './../../lib/types';
import { vehicleTypes, accessTypeEnum } from './../../lib/enum';
import { Section } from './../../components/layout';
import VehicleStatus from '../../components/VehicleStatus';
import Filter, { type VehicleFilterParams } from './Filter';
import { withUserAccess } from './../withUserAccess';
import Header from '../../components/layout/Header';
import { Table } from '../../components/ui';
import {
  getListInitialState,
  setQueryParams,
  applyMaskToValue
} from '../../lib/helpers';
import type { FetchListParams } from '../../lib/api';
import { vehicleApi } from '../../lib/api';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import { notificationLoading } from '../../components/Notifications';
import type { AppState } from '../../ducks/redux';
import { setFilter } from '../../ducks/persistFilters';
import type { PersistFilterPayload } from '../../ducks/persistFilters';

const Buttons = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  userAccess: UserAccess[],
  orgUnitId: number,
  currentOrgUnit: OrgUnitNode,
  location: Location & { state: { page: number } },
  filter: VehicleFilterParams,
  setFilter: (payload: PersistFilterPayload) => void
};

export const canAddAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.creatingVehicle
];

type State = ListState<Vehicle>;

const filterPath = window.location.pathname;

export class VehicleList extends Component<Props, State> {
  state = {
    ...getListInitialState()
  };

  static defaultProps = {
    location: {}
  };

  canAddVehicles = () =>
    this.props.userAccess.some(role => canAddAccess.includes(role));

  handleRowClick = (id: number) => navigate(`/vehicles/${id}`);

  handlePrint = async () => {
    const { filter } = this.props;
    try {
      notificationLoading({
        message: 'Формирование файла для печати...',
        key: 'printing'
      });
      await vehicleApi.print({
        ...filter
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Служба использования',
      dataIndex: 'usingNodeName'
    },
    {
      title: 'Гос. номер',
      sorter: true,
      dataIndex: 'licensePlate',
      // Нужно для того, чтобы не отрабатывал onRow
      stopPropagation: true,
      render: (licensePlate: ?string, record: Vehicle) =>
        licensePlate ? (
          <Link to={`/vehicles/${record.id}`}>
            {applyMaskToValue(licensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          licensePlate
        )
    },
    {
      title: 'Год выпуска',
      sorter: true,
      dataIndex: 'yearIssued'
    },
    {
      title: 'Марка',
      sorter: true,
      sorterKey: 'vehicleModel.brand.name',
      dataIndex: 'vehicleModel.brandName'
    },
    {
      title: 'Модель',
      sorter: true,
      dataIndex: 'vehicleModel.name'
    },
    {
      title: 'Тип',
      dataIndex: 'vehicleModel',
      sorter: true,
      render: (vehicleModel: ?VehicleModel): ?string =>
        vehicleModel && vehicleTypes[vehicleModel.type]
    },
    {
      title: 'Собственник',
      sorter: true,
      dataIndex: 'orgUnitName',
      sorterKey: 'node.name'
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: true,
      render: (status: VehicleStatusType) => <VehicleStatus status={status} />
    }
  ];

  fetchVehicles = async (
    page: number = 1,
    params: FetchListParams<VehicleFilterParams> = {}
  ) => {
    const { filter } = this.props;
    this.setState({ loading: true });
    const { data, totalCount } = await vehicleApi.fetchVehicles({
      ownerType: 'self',
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  setPersistFilter = async (values: VehicleFilterParams) => {
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchVehicles();
  };

  applyFilter = async (values: VehicleFilterParams) => {
    await this.setPersistFilter(values);
    await this.fetchVehicles();
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchVehicles(page);
  }

  render() {
    const { location, filter } = this.props;
    const { data, totalCount, page, pageSize, loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Транспорт</h1>}
          right={
            <Buttons>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canAddVehicles() && (
                <Link to="/vehicles/new">
                  <Button type="primary" data-cy="addVehicle">
                    Создать
                  </Button>
                </Link>
              )}
            </Buttons>
          }
        />
        {/*<Tabs>*/}
        {/*  <TabItem tabKey="own" label="Собственные" />*/}
        {/*  <TabItem*/}
        {/*    tabKey="contract"*/}
        {/*    label="Наемные"*/}
        {/*    onClick={() => navigate('/vehicles/contract')}*/}
        {/*  />*/}
        {/*</Tabs>*/}
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            columns={this.columns}
            fetch={this.fetchVehicles}
            data={data.map(item => ({ ...item, key: item.id }))}
            loading={loading}
            pagination={{
              page,
              totalCount,
              pageSize,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filter: state.persistFilters[filterPath] || {}
  }),
  {
    setFilter
  }
)(withUserAccess(VehicleList));
