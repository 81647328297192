// @flow
import React, { useMemo } from 'react';
import { navigate } from '@reach/router';
import { crewApi } from '../../lib/api';
import CommonListPage from '../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from '../withUserAccess';
import type { UserAccess } from '../../lib/types';
import { accessTypeEnum } from '../../lib/enum';

const entryPointPath = '/admin/crew/';

const access = {
  add: () => navigate(`${entryPointPath}new`)
};
type Props = {
  userAccess: UserAccess[]
};
export default withUserAccess(({ userAccess }: Props) => {
  const columns = useMemo(
    () => [
      {
        title: '№',
        dataIndex: 'id'
      },
      {
        title: 'Наименование',
        dataIndex: 'name'
      }
    ],
    []
  );
  const canAdd = () =>
    userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.handlingCrew].includes(access)
    );

  return (
    <Page>
      <CommonListPage
        crud={crewApi}
        pageHeaderProps={{
          mainHeader: 'Бригады',
          access: canAdd() ? access : undefined
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) =>
            canAdd() && navigate(`${entryPointPath}${id}`),
          canDelete: canAdd()
        }}
      />
    </Page>
  );
});
