// @flow
import type { Kasko } from './../types';
import CommonApi, { type CommonApiMethod } from './../commonApi';
import { fetchRequest, initialFetchListParams } from './';
import type { FetchListParams, ListResponse } from './';

type kaskoApi = $Shape<
  CommonApiMethod & {
    fetchByVehicle: any
  }
>;

const kasko: kaskoApi = new CommonApi<Kasko>('kasko', 'Каско');

kasko.fetchByVehicle = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Kasko>> => {
  const kasko = await fetchRequest.get(`/kasko/vehicle/${vehicleId}`, {
    ...initialFetchListParams,
    ...params
  });
  if (kasko) return kasko;
  throw new Error('Не удалось получить список КАСКО');
};

export default kasko;
