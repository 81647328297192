import {
    SideServiceTariffList,
    SideServiceTariffForm,
    SideServiceTariffCard,
} from '../containers/SideServiceTariff';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: SideServiceTariffList,
        path: '/admin/sideServiceTariff',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingSideServiceTariff,
            accessTypeEnum.handlingSideServiceTariff,
        ],
    },
    {
        component: SideServiceTariffForm,
        path: '/admin/sideServiceTariff/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingSideServiceTariff,
        ],
    },
    {
        component: SideServiceTariffForm,
        path: '/admin/sideServiceTariff/:id/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingSideServiceTariff,
        ],
    },
    {
        component: SideServiceTariffCard,
        path: '/admin/sideServiceTariff/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingSideServiceTariff,
            accessTypeEnum.handlingSideServiceTariff,
        ],
    }
];