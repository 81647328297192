// @flow

import { journalApi } from '../lib/api';
import type { Journal } from './../lib/types';
import type { Action } from './redux';
import { type Dispatch } from 'redux';

export const FETCH_JOURNAL = 'vehicles/journal/fetch';

export type JournalState = ?Journal;

const initialState: JournalState = null;

const reducer = (
  state: JournalState = initialState,
  { type, payload }: Action
): JournalState => {
  switch (type) {
    case FETCH_JOURNAL:
      return { ...payload };
    default:
      return state;
  }
};

export const fetchJournal = (id: number) => async (dispatch: Dispatch) => {
  const journal = await journalApi.fetchJournal(id);
  dispatch({
    type: FETCH_JOURNAL,
    payload: {
      ...journal
    }
  });
};

export default reducer;
