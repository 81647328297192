// @flow

import type { Dispatch } from 'redux';

import { jobTitleApi } from './../lib/api';
import type { Action } from './redux';
import type { JobTitle } from '../lib/types/jobTitle';

export const FETCH_JOB_TITLE = 'vehicles/jobTitle/fetch';
export const ADD_JOB_TITLE = 'vehicles/jobTitle/add';
export const UPDATE_JOB_TITLE = 'vehicles/jobTitle/update';
export const CLEAN_JOB_TITLE = 'vehicles/jobTitle/clean';

export type JobTitleState = ?JobTitle;

const initialState: JobTitleState = null;

const reducer = (
  state: JobTitleState = initialState,
  { type, payload }: Action
): JobTitleState => {
  switch (type) {
    case FETCH_JOB_TITLE:
    case ADD_JOB_TITLE:
    case UPDATE_JOB_TITLE:
      return { ...payload };
    case CLEAN_JOB_TITLE:
      return null;
    default:
      return state;
  }
};

export const addJobTitle = (jobTitle: JobTitle) => async (
  dispatch: Dispatch
): Promise<void> => {
  const payload = await jobTitleApi.addJobTitle(jobTitle);
  dispatch({
    type: ADD_JOB_TITLE,
    payload
  });
};

export const updateJobTitle = (jobTitle: JobTitle) => async (
  dispatch: Dispatch
): Promise<void> => {
  const payload = await jobTitleApi.updateJobTitle(jobTitle);
  dispatch({
    type: UPDATE_JOB_TITLE,
    payload
  });
};

export const deleteJobTitle = (id: number) => async (
  dispatch: Dispatch
): Promise<void> => await jobTitleApi.deleteJobTitle(id);

export const fetchJobTitle = (id: number) => async (
  dispatch: Dispatch
): Promise<void> => {
  const jobTitle = await jobTitleApi.fetchJobTitle(id);
  dispatch({
    type: FETCH_JOB_TITLE,
    payload: { ...jobTitle }
  });
};

export const cleanJobTitle = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAN_JOB_TITLE
  });

export default reducer;
