// @flow
import type { FederalHighwayFare } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (federalHighwayFare: FederalHighwayFare) => ({
  ...federalHighwayFare,
  cost: convertFromRubToKop(federalHighwayFare.cost)
});

const convertDataFromServer = (federalHighwayFare: FederalHighwayFare) => ({
  ...federalHighwayFare,
  cost: convertFromKopToRub(federalHighwayFare.cost)
});

const federalHighwayFare = new CommonApi<FederalHighwayFare>(
  'federalHighwayFare',
  'Стоимость проезда по федеральным трассам',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...federalHighwayFare
};
