import {
  fuelAndOilViewEnum,
  vehicleTypeEnum,
  vehicleGroupEnum
} from './../../../../lib/enum';
import type { VehicleType } from './../../../../lib/types';
import { getVehicleGroup } from './../../lib';

export const canAddConsumption = fuelAndOilView =>
  fuelAndOilView === fuelAndOilViewEnum.lubricant;

export const getFilterByVehicleModelType = (vehicleType: VehicleType) => {
  let filter = {
    view: [
      fuelAndOilViewEnum.lubricant,
      fuelAndOilViewEnum.technicalFluid,
      fuelAndOilViewEnum.fuel
    ]
  };

  const vehicleGroup = getVehicleGroup(vehicleType);
  if (
    vehicleGroup === vehicleGroupEnum.trailers ||
    vehicleType === vehicleTypeEnum.trailers
  ) {
    filter.isTrailer = true;
  }
  return filter;
};
