import {
    OverhaulTypeCard,
    OverhaulTypeForm,
    OverhaulTypeList,
} from '../../containers/OverhaulType';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: OverhaulTypeList,
        path: '/admin/overhaul-types',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: OverhaulTypeCard,
        path: '/admin/overhaul-types/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: OverhaulTypeForm,
        path: '/admin/overhaul-types/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: OverhaulTypeForm,
        path: '/admin/overhaul-types/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];