// @flow
import React from 'react';
import styled from 'styled-components';
import Input, { type InputProps } from 'antd/lib/input';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import notification from 'antd/lib/notification';

import { mdmApi } from '../../lib/api';
import type { MdmNode } from '../../lib/types/mdm';

const Wrapper = styled.div`
  display: flex;
`;

type Props = InputProps & {
  /**
   * После ввода мнемокода в input и нажатия на кнопку МДМ
   * происходит подгрузка нужной записи,
   * которая выступает аргументом этой функции
   */
  onSelect: (mdmNode: MdmNode) => any
};

/**
 * Компонент для подгрузки данных из МДМ по мнемокоду
 */
export default class MdmInput extends React.Component<Props> {
  onSelect = async () => {
    const { value: mnemocode } = this.props;
    const { data } = await mdmApi.fetchMdmList({
      hasMnemocode: true,
      mnemocode
    });
    if (data.length) {
      this.props.onSelect(data[0]);
    } else {
      notification.error({
        message: 'Не найдено',
        description: 'Данные по мнемокоду в МТРиО не найдены'
      });
    }
  };

  render() {
    const { onSelect, ...inputProps } = this.props;
    return (
      <Wrapper>
        <Input {...inputProps} />
        <Tooltip title="Подгрузить данные из МТРиО">
          <Button
            type="primary"
            disabled={!inputProps.value}
            style={{ marginLeft: 16 }}
            onClick={this.onSelect}
          >
            МТРиО
          </Button>
        </Tooltip>
      </Wrapper>
    );
  }
}
