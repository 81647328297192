// @flow
import Input from 'antd/lib/input';
import { FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { Form, OrgUnitSelect, Selects } from '../../../components';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import YesNoSelect from '../../../components/selects/YesNoSelect';
import { orgUnitTypes } from '../../../lib/enum';
import type { OrgUnitNode } from '../../../lib/types';
import { Option, Select } from './../../../components/ant/Select';

const { CompanySelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;

type Props = {
  orgUnit: OrgUnitNode
};

export default ({ orgUnit }: Props) => {
  return (
    <Form initialValues={orgUnit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, handleBlur, values, setFieldValue } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  {values.parentId !== null && (
                    <GridItem>
                      <FormField
                        label="Родительское подразделение"
                        name="parentId"
                      >
                        {({ value, name }) => (
                          <OrgUnitSelect
                            disabled
                            value={value}
                            onChange={(id: number) => setFieldValue(name, id)}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField label="Наименование" name="name" required>
                      {field => <Input {...field} disabled />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Юридическое лицо" name="companyId">
                      {({ value, name }) => (
                        <CompanySelect
                          value={value}
                          disabled
                          onChange={value => setFieldValue(name, value)}
                          onBlur={() => handleBlur({ target: { name } })}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Мнемокод" required name="mnemonic">
                      {field => <Input {...field} disabled />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тип" name="departmentType">
                      {field => (
                        <Input disabled placeholder="Введите тип" {...field} />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Вид" required name="type">
                      {({ value, name }) => (
                        <Select
                          placeholder="Выберите вид"
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          onBlur={() => handleBlur({ target: { name } })}
                          data-cy="orgUnitTypeSelect"
                          value={value}
                          disabled
                        >
                          {Object.keys(orgUnitTypes).map(key => (
                            <Option key={key} value={key}>
                              {orgUnitTypes[key]}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Получать данные по договорам"
                      name="receiveContractsData"
                    >
                      {field => (
                        <YesNoSelect
                          {...field}
                          disabled
                          onChange={value => setFieldValue(field.name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
          </form>
        );
      }}
    </Form>
  );
};
