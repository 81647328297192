import { ContractVehicleBudget } from '../../containers/budget/contractVehicle';
import { accessTypeEnum } from '../../lib/enum';

// бюджет НТС
export default [
    {
        component: ContractVehicleBudget,
        path: '/budget/contract-vehicle/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingContractVehicleBudget,
            accessTypeEnum.viewingAllContractVehicleBudgets,
        ],
    },
];