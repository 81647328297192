// @flow
import { EllipsisOutlined } from '@ant-design/icons';
import Menu from 'antd/lib/menu';

import React, { useState } from 'react';
import { vehiclePlanStatus } from '../../../../lib/enum';
import {
  formatDateRangeString,
  formatDateTimeToString,
  navigate,
} from '../../../../lib/helpers';
import type { VehiclePlanStatus } from '../../../../lib/types';

import { canAdd } from '../accessRight';
import CommonListPage from './../../../../components/hoc/common/handbook/ListPage';

import Page from './../../../../components/hoc/common/handbook/PageContext';
import { Popconfirm, Dropdown } from './../../../../components/ui';

import { vehiclePlanApi } from './../../../../lib/api';
import { withUserAccess } from './../../../withUserAccess';

import Filter from './components/Filter';

const columns = [
  {
    title: '№',
    key: 'idBudgetVehicle',
    dataIndex: 'id',
  },
  {
    title: 'Филиал',
    key: 'orgUnitName',
    dataIndex: 'orgUnitName',
  },
  {
    title: 'Планируемый период',
    key: 'startDate',
    dataIndex: 'startDate',
    render: (text: string, record: any): ?string =>
      formatDateRangeString(record.startDate, record.endDate, 'D MMMM YYYY'),
  },
  {
    title: 'Версия бюджета',
    key: 'budgetVersionName',
    dataIndex: 'budgetVersion.name',
  },
  {
    title: 'Дата формирования',
    key: 'date',
    dataIndex: 'date',
    width: '150',
    render: (created: string) =>
      `${formatDateTimeToString(created, 'DD.MM.YYYY')}`,
  },
  {
    title: 'Наименование',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    render: (status: VehiclePlanStatus) =>
      status ? vehiclePlanStatus[status] : '-',
  },
];

const breadCrumbs = [{ to: '/', name: 'Главная' }, { name: 'Бюджет СТС' }];

const access = {
  add: () => navigate(`/budget/vehicle/new`),
};

export default withUserAccess(({ userAccess }) => {
  const [reFetch, setReFetch] = useState(false);

  const onDelete = async (id: number) => {
    try {
      await vehiclePlanApi.delete(id);
      await setReFetch(true);
    } catch (error) {
    } finally {
      setReFetch(false);
    }
  };

  const columnsTable = canAdd(userAccess)
    ? [
        ...columns,
        {
          stopPropagation: true,
          title: '',
          dataIndex: 'id',
          render: (id: number) => {
            return (
              <Dropdown
                key={id}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() =>
                        navigate(`/budget/vehicle/${id}/card/clone`)
                      }
                    >
                      Копировать перечень
                    </Menu.Item>
                    <Menu.Item>
                      <Popconfirm
                        overlayStyle={{
                          zIndex: 2000,
                        }}
                        placement="left"
                        title={<>Вы действительно хотите удалить?</>}
                        onConfirm={() => {
                          onDelete(id);
                        }}
                      >
                        Удалить
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
              </Dropdown>
            );
          },
        },
      ]
    : [...columns];
  return (
    <Page>
      <CommonListPage
        crud={vehiclePlanApi}
        reFetch={reFetch}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Бюджет СТС',
          access: canAdd(userAccess) ? access : undefined,
        }}
        tableListProps={{
          columns: [...columnsTable],
          onSelect: (id: number) => navigate(`/budget/vehicle/${id}/card`),
        }}
        filter={Filter}
      />
    </Page>
  );
});
