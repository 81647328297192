// @flow
import type { RegulationLimit } from './../types';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromMToKm,
  convertFromSecondToHour
} from './../helpers';
import CommonApi from './../commonApi';

const convertDataToServer = (regulationLimit: RegulationLimit) => ({
  ...regulationLimit,
  hours: convertFromHourToSecond(regulationLimit.hours),
  hoursWeekend: convertFromHourToSecond(regulationLimit.hoursWeekend),
  distance: convertFromKmToM(regulationLimit.distance),
  distanceWeekend: convertFromKmToM(regulationLimit.distanceWeekend)
});

const convertDataFromServer = (regulationLimit: RegulationLimit) => {
  return {
    ...regulationLimit,
    hours: convertFromSecondToHour(regulationLimit.hours),
    hoursWeekend: convertFromSecondToHour(regulationLimit.hoursWeekend),
    distance: convertFromMToKm(regulationLimit.distance),
    distanceWeekend: convertFromMToKm(regulationLimit.distanceWeekend)
  };
};

const regulationLimit = new CommonApi<RegulationLimit>(
  'regulationLimit',
  'регламент НТС',
  convertDataToServer,
  convertDataFromServer
);
export default regulationLimit;
