import styled from 'styled-components';
import { Panel as LayoutPanel } from '../../components/layout';

export const Panel = styled(LayoutPanel)`
  padding-top: 0;
`;

export const SectionContent = styled.div`
  padding: 16px;
`;
