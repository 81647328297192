// @flow
import React, { useState, useCallback } from 'react';

import { goBack } from '../../lib/helpers';
import { federalHighwayFareApi } from '../../lib/api';
import { type FederalHighwayFare } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/federal-highway-fare/';

export default (props: PageProps) => {
  const [data: FederalHighwayFare, setData] = useState({ id: props.id });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await federalHighwayFareApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: FederalHighwayFare) => {
    let id = payload.id;
    if (id) {
      await federalHighwayFareApi.update(payload);
    } else {
      await federalHighwayFareApi.add(payload);
    }
    await goBack(`${entryPointPath}`);
  };

  const onCancel = async (payload: FederalHighwayFare) => {
    await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость проезда по федеральным трассам'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
