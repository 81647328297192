import type { MonitoringVehicle } from '../../lib/types/monitoringVehicle';
import {
  monitoringVehicleStates,
  monitoringVehicleStatesEnum,
  monitoringVehicleStatusesEnum
} from '../../lib/enum';

export const getStatusIcon = (
  vehicle: MonitoringVehicle,
  isActive: boolean = false
): { type: string, color: string } => {
  const { state, status } = vehicle;
  let result = {};
  result.title = monitoringVehicleStates[state];
  if (isActive) {
    result.color = 'white';
  } else if (status === monitoringVehicleStatusesEnum.active) {
    result.color = '#27AE60';
  } else if (
    status === monitoringVehicleStatusesEnum.nosignal ||
    status === monitoringVehicleStatusesEnum.nonactive
  ) {
    result.color = '#98A3AD';
  }
  if (state === monitoringVehicleStatesEnum.inmove) {
    result.type = 'moving';
  } else if (state === monitoringVehicleStatesEnum.stopped) {
    result.type = 'stopped';
  } else if (state === monitoringVehicleStatesEnum.parking) {
    result.type = 'parking';
    result.color = '#2770FF';
  }
  return result;
};

export const getUnixTime = (date: Date) => {
  return Math.round(date.getTime() / 1000);
};

/**
 * Поиск тика относительно времени
 * @param ticks Массив тиков
 * @param curTime Время
 */
export const findTick = (ticks: string[], curTime: number) => {
  const numberTicks = ticks.map(v => Number(v));
  const finded = numberTicks.find(
    (v, index) => curTime >= v && curTime <= numberTicks[index + 1]
  );
  return finded;
};
