// @flow

import type { Shift } from './../types';
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertDataFromServer as convertTripDataFromServer } from './trip';
import type { ShiftFilterParams } from '../../containers/Shifts/Filter';

const convertDataFromServer = (shift: Shift) => ({
  ...shift,
  trips: shift.trips ? shift.trips.map(convertTripDataFromServer) : []
});

export const fetchShifts = async (
  params: FetchListParams<ShiftFilterParams> = initialFetchListParams
): Promise<ListResponse<Shift>> => {
  const shifts = await fetchRequest.get('/shift', {
    ...initialFetchListParams,
    ...params
  });
  return {
    ...shifts,
    data: shifts.data.map(convertDataFromServer)
  };
};

export const fetchShift = async (
  id: number,
  params: any = {}
): Promise<Shift> => {
  const shift = await fetchRequest.get(`/shift/${id}`, params);
  if (shift) return convertDataFromServer(shift);
  throw new Error('Не удалось загрузить заказ-наряд');
};

export const fetchShiftEmployeesByTripId = async (
  tripId: number,
  params: any = {}
): Promise<Shift> => {
  const shift = await fetchRequest.get(`/shift/by-trip/${tripId}`, params);
  if (shift) return convertDataFromServer(shift);
  throw new Error('Не удалось получить информацию по заказ-наряду');
};

export const updateShift = async (shift: Shift): Promise<Shift> => {
  const updated = await fetchRequest.put('/shift', shift);
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить заказ-наряд');
};

export default {
  fetchShifts,
  fetchShift,
  updateShift,
  fetchShiftEmployeesByTripId
};
