// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportVehicleWork,
  ReportVehicleWorkParams
} from '../types/reportVehicleWork';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportVehicleWork) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage)
});

/**
 * Получение отчета
 */
export const fetchReportVehicleWorks = async (
  params: ReportVehicleWorkParams
): Promise<ReportVehicleWork[]> => {
  const report = await fetchRequest.get('/report/vehicle-works', {
    ...params
  });
  return report.map(convertFromServer);
};

/**
 * Печать отчета
 */
export const printReportVehicleWorks = async (
  params: ReportVehicleWorkParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/vehicle-works/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportVehicleWorks,
  printReportVehicleWorks
};
