// @flow

import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { Item as FormItem } from 'antd/lib/form';
import notification from 'antd/lib/notification';

import type { RepairOrderOperation, FormErrors } from '../../../lib/types';
import { Icon as UiIcon } from '../../../components/ui';
import { notificationLoading } from '../../../components/Notifications';
import { InputNumber } from '../../../components/inputs';

const ItemCol = styled.div`
  ${props => css`
    ${props.width ? `width: ${props.width}px;` : `flex: ${props.flex || 1};`};
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;

  > .col-item {
    padding: 0 8px;
    text-align: left;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const OperationCol = styled(ItemCol)`
  height: 32px;
`;

const StyledIcon = styled(UiIcon)`
  margin-top: 8px;
  cursor: pointer;
`;

type Props = {
  repairOrderOperation?: RepairOrderOperation,
  onChange?: Function,
  onDelete?: Function
};

type State = {
  repairOrderOperation: $Shape<RepairOrderOperation>,
  errors: Object
};
/**
 * Строка редактирования ремонта ТС
 */
export default class RepairOrderOperationRow extends Component<Props, State> {
  state = {
    repairOrderOperation: this.props.repairOrderOperation || {},
    errors: {}
  };

  componentDidMount() {
    !isEmpty(this.props.repairOrderOperation) && this.validate();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.repairOrderOperation, this.props.repairOrderOperation)
    ) {
      this.setState(
        {
          repairOrderOperation: this.props.repairOrderOperation
        },
        () => this.validate()
      );
    }
  }

  validate = (): FormErrors<RepairOrderOperation> => {
    const { repairOrderOperation } = this.state;
    var errors = {};
    if (!repairOrderOperation.count) {
      errors.count = 'Обязательно для заполнения';
    }
    this.setState({
      errors
    });
    return errors;
  };

  updateRepairOrderOperation = () => {
    const errors = this.validate();
    if (isEmpty(errors)) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving'
        });
        this.props.onChange &&
          this.props.onChange(this.state.repairOrderOperation);
      } catch (err) {
        console.warn(err.message);
      } finally {
        notification.close('saving');
      }
    }
  };

  onChange = (repairOrderOperation: RepairOrderOperation) =>
    this.setState(
      {
        repairOrderOperation
      },
      () => this.updateRepairOrderOperation()
    );

  render() {
    const { onDelete } = this.props;
    const { repairOrderOperation, errors } = this.state;
    return (
      <Fragment>
        <Wrapper className="list-row-item">
          <ItemCol
            flex={1}
            className="col-item"
            style={{ maxWidth: 'calc(100% - 500px)' }}
          >
            {repairOrderOperation.operation
              ? repairOrderOperation.operation.name
              : ''}
          </ItemCol>
          <ItemCol flex={1} className="col-item">
            <FormItem
              validateStatus={errors.count && 'error'}
              help={errors.count}
            >
              <InputNumber
                min={1}
                value={repairOrderOperation.count}
                onChange={(count: number) =>
                  // $FlowFixMe не хватает параметров
                  this.onChange({ ...repairOrderOperation, count })
                }
              />
            </FormItem>
          </ItemCol>
          <OperationCol width={35} className="col-item">
            {onDelete && (
              <StyledIcon
                type="x"
                onClick={() => onDelete(repairOrderOperation)}
              />
            )}
          </OperationCol>
        </Wrapper>
      </Fragment>
    );
  }
}
