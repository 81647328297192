import isEmpty from 'lodash/isEmpty';

import type { OperationLimitGroupStatus } from '../../../lib/types/operationLimitGroup';
import type { DailyPlanning } from '../../../lib/types/contractVehicleDailyBudget';
import type { UserAccess } from '../../../lib/types';
import { accessTypeEnum } from '../../../lib/enum';

export const getStatus = (
  budgets: DailyPlanning[],
  type: 'monthStatus' | 'yearStatus'
): ?OperationLimitGroupStatus => {
  const budget = budgets.filter(budget => !isEmpty(budget.dateTimes))[0];
  if (budget) {
    const dateTime = budget.dateTimes[Object.keys(budget.dateTimes)[0]];
    return dateTime && dateTime[type];
  }
  return null;
};

/**
 * Проверка на возможность просмотра годового плана пользователем
 * @param userAccess Пользовательские доступы
 */
export const showYearPlan = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBranchYearLimitsPlan,
      accessTypeEnum.viewingYearLimitsPlan
    ].includes(access)
  );
};

/**
 * Проверка на возможность просмотра месячного плана пользователем
 * @param userAccess Пользовательские доступы
 */
export const showMonthPlan = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBranchMonthLimitsPlan,
      accessTypeEnum.viewingMonthLimitsPlan
    ].includes(access)
  );
};
