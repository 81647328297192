// @flow
import type { Cargo } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const createCargo = async (cargo: Cargo): Promise<Cargo> => {
  const added = await fetchRequest.post('/cargo', cargo);
  if (added) return added;
  throw new Error('Не удалось создать груз');
};

export const updateCargo = async (cargo: Cargo): Promise<Cargo> => {
  const updated = await fetchRequest.put('/cargo', cargo);
  if (updated) return updated;
  throw new Error('Не удалось обновить груз');
};

export const fetchCargos = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Cargo>> => {
  let nodeId = undefined;
  if (params.orgUnitId) {
    nodeId = params.orgUnitId;
    delete params.orgUnitId;
  }
  return await fetchRequest.get('/cargo', {
    ...initialFetchListParams,
    ...params,
    nodeId
  });
};

export const deleteCargo = async (id: number): Promise<Cargo> => {
  const deleted = await fetchRequest.delete(`/cargo/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить груз');
};

export const fetchCargo = async (
  id: number,
  params: any = {}
): Promise<Cargo> => {
  const cargo = await fetchRequest.get(`/cargo/${id}`, params);
  if (cargo) return cargo;
  throw new Error('Не удалось загрузить груз');
};

export default {
  createCargo,
  updateCargo,
  fetchCargos,
  deleteCargo,
  fetchCargo
};
