import {
    SelfTripCard,
    SelfTripList,
    TripForm,
    TripFormNew,
    TripVerification,
} from '../containers/Trips';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: SelfTripList,
        path: '/trips/self/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            // accessTypeEnum.handlingTrip
        ],
    },
    {
        component: TripFormNew,
        path: '/trips/self/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            // accessTypeEnum.handlingTrip
        ],
    },
    {
        component: TripForm,
        path: '/trips/self/:tripId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            // accessTypeEnum.handlingTrip
        ],
    },
    {
        component: TripFormNew,
        path: '/trips/self/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            accessTypeEnum.handlingTrip,
        ],
    },
    {
        component: SelfTripCard,
        path: '/trips/self/:tripId/card',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            accessTypeEnum.handlingTrip,
        ],
    },
    {
        component: TripVerification,
        path: '/trips/self/:tripId/verification',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingTrip,
        ],
    },
];