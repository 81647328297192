// @flow
import type {
  CalculationStatus,
  DriverInspectionCalculation,
  DriverInspectionCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  driverInspectionCalculation: DriverInspectionCalculation
): DriverInspectionCalculation => ({
  ...driverInspectionCalculation,
  sum: convertFromRubToKop(driverInspectionCalculation.sum),
  months: driverInspectionCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  driverInspectionCalculation: DriverInspectionCalculation
): DriverInspectionCalculation => ({
  ...driverInspectionCalculation,
  sum: convertFromKopToRub(driverInspectionCalculation.sum),
  months: driverInspectionCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  driverInspectionCalculationMonth: DriverInspectionCalculationMonth
): DriverInspectionCalculationMonth => ({
  ...driverInspectionCalculationMonth,
  cost: convertFromRubToKop(driverInspectionCalculationMonth.cost)
});

export const convertDataFromServerMonth = (
  driverInspectionCalculationMonth: DriverInspectionCalculationMonth
): DriverInspectionCalculationMonth => ({
  ...driverInspectionCalculationMonth,
  cost: convertFromKopToRub(driverInspectionCalculationMonth.cost)
});

const basedUrl = 'driverInspectionCalculation';
const driverInspectionCalculation = new CommonApi<DriverInspectionCalculation>(
  basedUrl,
  'расчет потребности в предрейсовых/послерейсовых осмотрах водителей',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export default {
  ...driverInspectionCalculation,
  calculate,
  changeStatus
};
