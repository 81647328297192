// @flow
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import shortId from 'shortid';
import omit from 'lodash/omit';

import type { BusinessDay, WayPoint } from './../../lib/types';
import type { FormErrors } from '../../lib/types';
import { waypointTypeEnum } from '../../lib/enum';
import { withEmptyRow, withoutEmptyRow } from '../../lib/helpers';
import type { WaypointsType } from './WaypointsForm';

/**
 * Сортирует маршрутные точки по времени прибытия и отправления
 * @param waypoints Маршрнутные точки
 * @returns {WayPoint[]} Отсортированные маршрутные точки
 */
export function sortWaypoints(waypoints: WayPoint[]) {
  return orderBy(waypoints, ['departureDateTime', 'arrivedDateTime']);
}

/**
 * Функция валидации маршрутной точки
 * @param waypoint Маршрутная точка
 * @param {FormErrors<WayPoint>} Объект с текстом ошибки по ключам
 */
export function validateWaypoint(
  waypoint: WayPoint,
  transitWaypoints?: WayPoint[]
): FormErrors<WayPoint> {
  const isEndPoint = waypoint.type === 'end';
  const isStartPoint = waypoint.type === 'start';
  const errors = {};
  if (isEmpty(waypoint.name)) {
    errors.name = 'Необходимо указать наименование точки';
  }
  if (!isStartPoint && isEmpty(waypoint.arrivedDateTime)) {
    errors.arrivedDateTime = 'Необходимо для заполнения';
  }
  if (!isEndPoint && isEmpty(waypoint.departureDateTime)) {
    errors.departureDateTime = 'Необходимо для заполнения';
  }
  if (isEndPoint && transitWaypoints) {
    const lastWaypoint = transitWaypoints[transitWaypoints.length - 1];
    if (
      lastWaypoint &&
      moment(lastWaypoint.departureDateTime).isAfter(waypoint.arrivedDateTime)
    ) {
      errors.arrivedDateTime =
        'Время прибытия должно быть больше времени отправления из последней точки';
    }
  }
  if (
    !isEmpty(waypoint.arrivedDateTime) &&
    !isEmpty(waypoint.departureDateTime)
  ) {
    const arrivedDateTime = moment.utc(waypoint.arrivedDateTime);
    const departureDateTime = moment.utc(waypoint.departureDateTime);
    if (!arrivedDateTime.isValid()) {
      errors.arrivedDateTime = 'Неверное значение даты';
    }
    if (!departureDateTime.isValid()) {
      errors.arrivedDateTime = 'Неверное значение даты';
    }
    if (
      arrivedDateTime.isValid() &&
      departureDateTime.isValid() &&
      arrivedDateTime.isAfter(departureDateTime)
    ) {
      errors.arrivedDateTime =
        'Время прибытия должно быть раньше времени отправления';
    }
  }
  return errors;
}

/**
 * Функция для получения маршрутных точек
 */
export const getWaypoints: (waypoints: WayPoint[]) => {
  start?: WayPoint,
  transit: WayPoint[],
  end?: WayPoint,
} = (waypoints: WayPoint[]) => {
  const transit = waypoints
    .filter((w) => w.type === waypointTypeEnum.transit)
    .sort((a, b) => {
      const first = new Date(a?.arrivedDateTime ?? 0);
      const second = new Date(b?.arrivedDateTime ?? 0);
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });
  const start = waypoints.find(
    (waypoint) => waypoint.type === waypointTypeEnum.start
  );

  const end = waypoints.find(
    (waypoint) => waypoint.type === waypointTypeEnum.end
  );
  return { start, transit, end };
};

/**
 * Возврашает по-умолчанию проставленные даты прибытия и отправления
 * по дате ПРИБЫТИЯ
 *
 *
 * @param value Дата прибытия
 * @param prevDepartureDate Предыдущая дата отправления
 * @param waypoint Точка
 * @returns
 * {{
 *  arrivedDateTime: string,
 *  departureDateTime?: string
 * }} Поля с временем прибытия и отправления
 */
export function getDefaultDateTimes(
  value?: string,
  prevDepartureDate: ?string,
  waypoint: WayPoint
): {|
  arrivedDateTime?: string,
  departureDateTime?: string,
|} {
  const valueValid = value || moment.utc().toISOString();
  const momentValue = moment.utc(valueValid);
  // .add(Math.abs(0 - (moment.utc(valueValid).minute() % 30)), 'm');
  const momentPrevDate = moment.utc(prevDepartureDate);
  let [hours, minutes] = prevDepartureDate
    ? [momentPrevDate.hours(), momentPrevDate.minutes()]
    : [8, 0];
  // Если мы меняем уже проставленное значение даты,
  // то берем значение часов и минут из даты прибытия
  const hasPrevValue = [
    waypoint.arrivedDateTime,
    waypoint.departureDateTime,
  ].filter(Boolean).length;
  if (hasPrevValue) {
    hours = momentValue.hours();
    minutes = momentValue.minutes();
  }
  const arrivedDateTime = moment.utc(valueValid).set({
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0,
  });
  if (prevDepartureDate && !hasPrevValue) arrivedDateTime.add(1, 'hour');
  return {
    arrivedDateTime: arrivedDateTime.toISOString(),
    departureDateTime:
      waypoint.type !== waypointTypeEnum.end
        ? arrivedDateTime.toISOString()
        : undefined,
  };
}

/**
 * Добавляет пустой WayPoint к массиву WayPoint
 *
 * @param waypoints Массив
 * @returns {WayPoint[]} Массив с пустым WayPoint
 */
export const withEmptyWaypoint = (waypoints: WayPoint[]) => {
  return withEmptyRow(
    waypoints.map((w) => ({
      // Добавляем key для элементов, у которых он отсутсвует
      ...w,
      key: w.key || shortId.generate(),
    })),
    {
      emptyRow: {
        key: shortId.generate(),
        type: 'transit',
      },
      ignoredEmptyRowKeys: ['key'],
    }
  );
};

/**
 * Вовзращает массив WayPoint без пустой маршрутной точки
 * @param waypoints Массив точек
 * @returns {WayPoint[]} Массив без пустых точек
 */
export const withoutEmptyWaypoint = (waypoints: WayPoint[]) => {
  return withoutEmptyRow(waypoints, { type: 'transit' }, ['key']);
};

/**
 * Копирует маршрутную точку и возвращает обновленный массив точек
 * @param waypoint Копируемая точка
 * @param waypoints Массив точек
 * @returns {WayPoint[]} Получившийся массив точек
 */
export const copyWaypoint = (waypoint: WayPoint, waypoints: WayPoint[]) => {
  const transit = [...waypoints];
  // Перегенерируем ключ для уникальности и удалим ненужные поля
  waypoint = omit({ ...waypoint, key: shortId.generate() }, [
    'id',
    'arrivedDateTime',
    'departureDateTime',
    'notation',
  ]);
  // Последний непустой WayPoint
  transit.splice(waypoints.length - 1, 0, waypoint);
  return transit;
};

export type DisableDatePayload = {
  type: WaypointsType,
  businessCalendar: BusinessDay[],
  nearlyDaysOff: string[],
};

export type DisabledDepartureDatePayload = {
  currentDate: string | Date,
  routeStartDate: ?string | ?Date,
  routeEndDate: ?string | ?Date,
  arrivalDate: ?string | ?Date,
  prevArrivalDate: ?(string | Date),
  setLoading: (value: boolean) => void,
};

export type DisabledArrivalDatePayload = {
  // Проверяемая дата
  currentDate: string | Date,
  // Дата начала маршрута
  routeStartDate: ?string | ?Date,
  // Дата окончания маршрута
  routeEndDate: ?string | ?Date,
  // Дата отправления
  departureDate: ?string | ?Date,
  // Дата прибытия предыдущей точки
  prevDepartureDate: ?string | ?Date,
  setLoading: (value: boolean) => void,
};

/**
 * Возвращает вчерашние выходные дни к указанной дате
 * @param calendar
 * @param date
 */
export const getNearlyDaysOff = (calendar: BusinessDay[], date: string) => {
  const getYesterdayDayOff = (date: string) => {
    const day = calendar.filter((day) =>
      moment
        .utc(date)
        .add(-1, 'day')
        .startOf('day')
        .isSame(moment.utc(day.date).startOf('day'))
    )[0];
    return day && day.date;
  };
  const yesterdayForDate = getYesterdayDayOff(date);
  if (yesterdayForDate) {
    const dates = [yesterdayForDate];
    for (let date of dates) {
      const ys = getYesterdayDayOff(date);
      if (ys) {
        dates.push(ys);
      } else {
        dates.push(moment.utc(date).add(-1, 'day').toISOString());
        break;
      }
    }
    return dates;
  }
  return [moment.utc(date).add(-1, 'day').toISOString()];
};

/**
 * Функция блокировки дат в селекторе даты
 *
 * Вызывается для каждого элемента даты в календаре
 *
 * Возвращает true, если нужно дату заблокировать
 *
 * @returns {string} Строка с сообщением об ошибке
 */
export const getDisabledArrivalDate = (
  {
    routeEndDate,
    routeStartDate,
    currentDate,
    departureDate,
    prevDepartureDate,
    setLoading,
  }: DisabledArrivalDatePayload,
  { type, businessCalendar, nearlyDaysOff }: DisableDatePayload
): string | boolean => {
  // Для всех заявок, кроме командировок
  if (type !== 'business') {
    if (prevDepartureDate) {
      if (moment(currentDate).isSame(prevDepartureDate))
        return 'Дата прибытия не может быть датой отправления предыдущей точки';
      if (
        moment
          .utc(currentDate)
          .startOf('day')
          .isBefore(moment.utc(prevDepartureDate).startOf('day'))
      ) {
        return 'Дата прибытия не может быть раньше дня отправления из предыдущей точки';
      }
    }
  }
  /**
   * Для аварийных заявок
   */
  if (type === 'emergency') {
    /**
     * Блокировать все даты, кроме вчерашних выходных
     *
     * Логика такая: если вчерашний день - это выходной,
     * то разблокируем все вчерашние выходные дни до первого буднего
     *
     */
    // Все даты после сегодняшнего дня блокируем
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isSameOrAfter(moment.utc().startOf('day'))
    )
      return true;
    return !nearlyDaysOff.filter((day) =>
      moment
        .utc(day)
        .startOf('day')
        .isSame(moment.utc(currentDate).startOf('day'))
    ).length;
  }
  if (type === 'business') return false;
  // Для всех типов заявок
  if (routeStartDate) {
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isBefore(moment.utc(routeStartDate).startOf('day'))
    ) {
      return 'Дата прибытия не может быть раньше начала маршрута';
    }
  }
  if (routeEndDate) {
    if (
      moment.utc(currentDate).isAfter(moment.utc(routeEndDate).endOf('day'))
    ) {
      return 'Дата прибытия не должна быть позже окончания маршрута';
    }
  }
  return false;
};

export const getDisabledDepartureDate = (
  {
    currentDate,
    routeStartDate,
    routeEndDate,
    arrivalDate,
    prevArrivalDate,
  }: DisabledDepartureDatePayload,
  { type, businessCalendar, nearlyDaysOff }: DisableDatePayload
): string | boolean => {
  if (type === 'business') {
    return false;
  }
  /**
   * Для аварийных заявок
   */
  if (type === 'emergency') {
    /**
     * Блокировать все даты, кроме вчерашних выходных
     *
     * Логика такая: если вчерашний день - это выходной,
     * то разблокируем все вчерашние выходные дни до первого буднего
     *
     */
    // Все даты после сегодняшнего дня блокируем
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isSameOrAfter(moment.utc().startOf('day'))
    )
      return true;
    return !nearlyDaysOff.filter((day) =>
      moment
        .utc(day)
        .startOf('day')
        .isSame(moment.utc(currentDate).startOf('day'))
    ).length;
  }
  if (arrivalDate) {
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isBefore(moment.utc(arrivalDate).startOf('day'))
    ) {
      return 'Дата отправления не может быть раньше дня прибытия';
    }
  }
  if (routeStartDate) {
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isBefore(moment.utc(routeStartDate).startOf('day'))
    ) {
      return 'Дата отправления не может быть раньше начала маршрута';
    }
  } else {
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isBefore(moment.utc().startOf('day'))
    ) {
      return 'Дата отправления не может быть раньше сегоднянего дня';
    }
  }
  return false;
};
