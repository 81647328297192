// @flow
import React from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input';
import moment from 'moment';

import { formatDateTimeToISOString } from '../../../../../lib/helpers';

import {
  Filter,
  FilterContainer,
  FilterItem,
  FilterButtonsContainer,
} from '../../../../../components';
import { DatePicker } from '../../../../../components/ant/DatePicker';
import { RangePicker } from '../../../../../components/ui';
import type { FilterChildrenParams } from '../../../../../components/hoc/Filter';
import Field from '../../../../../components/card/Field';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ActFilterParams = {
  documentNumber?: string,
  documentDate?: string,
  startDate?: string,
  endDate?: string,
};

type Props = {
  filter: ActFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams<ActFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  size="small"
                  placeholder={'Номер документа'}
                  value={values.documentNumber}
                  onChange={(e) =>
                    changeValue('documentNumber', e.target.value)
                  }
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  placeholder="Дата документа"
                  value={
                    values.documentDate
                      ? moment(values.documentDate)
                      : undefined
                  }
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'documentDate',
                      formatDateTimeToISOString(value, dateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null,
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
