// @flow
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import moment from 'moment';

import type { Driver, DriverQualificationDocument } from '../../../lib/types';
import { Popover } from '../../../components/ui';
import { formatDateTimeToString } from '../../../lib/helpers';
import { driverQualificationEnum } from '../../../lib/enum';

type Props = {
  // Водитель
  driver?: Driver
};

export const DriverLicenseExpired = ({ driver }: Props) => {
  const endDate = driver?.qualificationDocuments?.find(
    (el: DriverQualificationDocument) =>
      el.qualification === driverQualificationEnum.driverLicense
  )?.documentEndDate;
  if (!endDate || moment(endDate).isSameOrAfter(moment().utc())) {
    return null;
  }

  return (
    <Popover
      overlayStyle={{ zIndex: 9999 }}
      width={200}
      content={`Водительское удостоверение истекло ${formatDateTimeToString(
        endDate,
        'DD.MM.YYYY'
      )}`}
    >
      <InfoCircleOutlined
        style={{
          cursor: 'help',
          color: 'red',
          marginLeft: '8px'
        }}
      />
    </Popover>
  );
};

export default DriverLicenseExpired;
