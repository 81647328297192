// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { contractVehicleTypeEnum, vehicleStatusEnum, vehicleStatuses } from '../../lib/enum';
import { Option, Select } from './../ant/Select';
import VehicleStatus from './../VehicleStatus';

export default (selectProps: SelectProps) => {
  const { contractType } = selectProps;
  let keys = [];
  switch (contractType) {
    case contractVehicleTypeEnum.fixedPlan:
    case contractVehicleTypeEnum.oneOffPlan:
    case contractVehicleTypeEnum.indefined:
      keys = [vehicleStatusEnum.draft];
      break;
    case contractVehicleTypeEnum.fixed:
    case contractVehicleTypeEnum.oneOff:
    case contractVehicleTypeEnum.substitution:
    default:
      keys = Object.keys(vehicleStatuses);
  }
  return (
    <Select allowClear placeholder="Выберите статус ТС" {...selectProps}>
      {keys.map(key => (
        <Option key={key} value={key}>
          <VehicleStatus status={key} />
        </Option>
      ))}
    </Select>
  );
};
