import styled, { css } from 'styled-components';
import AntCheckbox from 'antd/lib/checkbox';

export const Wrapper = styled.div`
  background: #f0f4f8;
  padding: 0;
  height: 0px;
  transition: height 0.3s, padding .3s, opacity: .2s;
  visibility: hidden;
  opacity: 0;
  ${props =>
    props.expanded &&
    css`
      height: auto;
      padding: 14px 18px;
      visibility: visible;
      opacity: 1;
    `};
`;

export const Checkbox = styled(AntCheckbox).attrs(() => ({
  size: ''
}))`
  margin-bottom: 10px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
`;
