// @flow

import React, { Component } from 'react';
import { inspectionGibddCalculationApi } from '../../../../lib/api';
import { printNotification } from '../../../../lib/notificationWrapper';
import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};
export default class extends Component<Props, {}> {
  render() {
    const { location, vehiclePlanId } = this.props;
    return (
      <Common
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={inspectionGibddCalculationApi}
        calcScrollY={400}
        statusField={'inspectionGibddCalculationStatus'}
        title={'Планирование ТО ГИБДД'}
        handlePrint={filter => {
          printNotification(async () => {
            await inspectionGibddCalculationApi.print(vehiclePlanId, filter);
          });
        }}
      />
    );
  }
}
