// @flow
import queryString from 'query-string';
import type {
  Maintenance,
  MaintenanceStatusesType,
  ExecutionBudgetGroupedList,
} from './../types';
import {
  convertFromKopToRub,
  convertFromRubToKop,
  convertFromMToKm,
  convertFromKmToM,
  convertFromSecondToHour,
  convertFromHourToSecond,
} from '../helpers';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import { convertDataFromServer as vehicleConvertDataFromServer } from './vehicle';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken,
} from './index';
import type { FetchListParams, ListResponse } from './index';

const convertDataFromServer = (mantenance: Maintenance): Maintenance => ({
  ...mantenance,
  kilometrage: convertFromMToKm(mantenance.kilometrage),
  workEngineHours: convertFromSecondToHour(mantenance?.workEngineHours ?? 0),
  plannedKilometrage: convertFromMToKm(mantenance.plannedKilometrage),
  totalSum: convertFromKopToRub(mantenance.totalSum),
  vehicle: mantenance?.vehicle
    ? vehicleConvertDataFromServer(mantenance.vehicle)
    : null,
});

const convertDataToServer = (mantenance: Maintenance) => ({
  ...mantenance,
  kilometrage: convertFromKmToM(mantenance.kilometrage),
  workEngineHours: convertFromHourToSecond(mantenance?.workEngineHours ?? 0),
  plannedKilometrage: convertFromKmToM(mantenance.plannedKilometrage),
  totalSum: convertFromRubToKop(mantenance.totalSum),
});

export const addMaintenance = async (
  maintenance: Maintenance
): Promise<Maintenance> => {
  const added = await fetchRequest.post(
    '/maintenance',
    convertDataToServer(maintenance)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать ремонт');
};

export const updateMaintenance = async (
  maintenance: Maintenance
): Promise<Maintenance> => {
  const updated = await fetchRequest.put(
    '/maintenance',
    convertDataToServer(maintenance)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить ремонт');
};

export const fetchMaintenance = async (
  id: number,
  params: any = {}
): Promise<Maintenance> =>
  convertDataFromServer(await fetchRequest.get(`/maintenance/${id}`, params));

export const deleteMaintenance = async (id: number): Promise<Maintenance> => {
  const deleted = await fetchRequest.delete(`/maintenance/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить ремонт');
};

export const fetchMaintenances = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Maintenance>> => {
  const maintenances = await fetchRequest.get('/maintenance', {
    ...initialFetchListParams,
    ...params,
  });
  if (maintenances)
    return {
      ...maintenances,
      data: maintenances.data.map(convertDataFromServer),
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const printMaintenance = async (id: number) => {
  const print = await downloadRequestWithToken(`/maintenance/${id}/print`);
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export const print = async (params: any, filename?: string) => {
  const print = await downloadRequestWithToken(
    `/maintenance/print`,
    params,
    filename
  );
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export const changeCountStockPart = async (
  id: number,
  status: MaintenanceStatusesType
) => {
  try {
    const updated = await fetchRequest.put(
      '/maintenance/changeCountStockPart',
      {
        id,
        status,
      }
    );
    if (updated) return updated;
  } catch (err) {
    if (err.message !== 'Unexpected end of JSON input')
      throw new Error(err.message);
  }
};

export const changeStatusMaintenance = async (
  id: number,
  status: MaintenanceStatusesType
) => {
  const updated = await fetchRequest.post('/maintenance/changeStatus', {
    id,
    status,
  });
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить статус');
};

export const getMaintenanceExpenseDirection = async (params: any) => {
  const expenseDirection = fetchRequest.get(
    '/maintenance/getExpenseDirection',
    params
  );
  if (expenseDirection) return expenseDirection;
  throw new Error('Не удалось найти направление расходов');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<Maintenance>> => {
  const maintenance = await fetchRequest.put(
    `/maintenance/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (maintenance) {
    return maintenance;
  }
  throw new Error('Не удалось закрепить акт за списоком замен гос. номеров');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/maintenance/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/maintenance/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList),
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const fillNextToDate = async (
  maintenance: Maintenance
): Promise<Maintenance> => {
  const updated = await fetchRequest.put(
    '/maintenance/nextToDate/fill',
    convertDataToServer(maintenance)
  );
  if (updated) return updated;
  throw new Error('Не удалось проставить день следующего ТО');
};

export const printSchedule = async (params: any, filename?: string) => {
  const print = await downloadRequestWithToken(
    `/maintenance/printSchedule`,
    params,
    filename
  );
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export const getForVehicle = async (
  vehicleId: number
): Promise<{ data: Maintenance[], maintenanceCosts: number }> => {
  const data = await fetchRequest.get(
    `/maintenance/getForVehicle/${vehicleId}`
  );
  if (data)
    return {
      data: data.data.map((el) => convertDataFromServer(el)),
      maintenanceCosts: convertFromKopToRub(data.maintenanceCosts),
    };

  throw new Error('Не удалось получить ремонты');
};

export default {
  changeCountStockPart,
  groupedList,
  print,
  getTotalSum,
  updateAct,
  getMaintenanceExpenseDirection,
  printMaintenance,
  addMaintenance,
  fetchMaintenance,
  updateMaintenance,
  deleteMaintenance,
  changeStatusMaintenance,
  fetchMaintenances,
  fillNextToDate,
  printSchedule,
  getForVehicle,
};
