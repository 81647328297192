// @flow

import type { OrgUnitNode } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams } from './index';

export const addOrgUnit = async (
  orgUnit: OrgUnitNode
): Promise<OrgUnitNode> => {
  const added = await fetchRequest.post('/orgUnit', orgUnit);
  if (added) return added;
  throw new Error('Не удалось создать подразделение');
};

export const updateOrgUnit = async (
  orgUnit: OrgUnitNode
): Promise<OrgUnitNode> => {
  const updated = await fetchRequest.put('/orgUnit', orgUnit);
  if (updated) return updated;
  throw new Error('Не удалось обновить подразделение');
};

export const fetchOrgUnits = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<OrgUnitNode> =>
  await fetchRequest.get('/orgUnit', {
    ...initialFetchListParams,
    ...params
  });

export const deleteOrgUnit = async (id: number): Promise<OrgUnitNode> => {
  const deleted = await fetchRequest.delete(`/orgUnit/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить подразделение');
};

export const getUsingNodes = async (): Promise<OrgUnitNode[]> =>
  await fetchRequest.get('/orgUnit/usingNodes');

export default {
  addOrgUnit,
  updateOrgUnit,
  fetchOrgUnits,
  deleteOrgUnit,
  getUsingNodes
};
