// @flow
import RequestsController from '../requests';

import { Core } from './core';
import { Messages } from './messages';
import { Unit } from './unit';
import { Root } from './root';
import { Render } from './render';
import { Events } from './events';

export default (requestController: RequestsController) => {
  const sections = { Core, Messages, Unit, Root, Render, Events };
  return (Object.keys(sections).reduce(
    (acc: typeof sections, apiSectionKey) => {
      return {
        ...acc,
        [(apiSectionKey: any)]: new sections[apiSectionKey](requestController)
      };
    },
    {}
  ): { [key: $Keys<typeof sections>]: any });
};
