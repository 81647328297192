// @flow
import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import { navigate } from '@reach/router';
import { DownloadOutlined } from '@ant-design/icons';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import {
  maintenanceDocumentApi,
  outsourcingActApi,
  fuelsActApi,
  oilsActApi,
} from './../../../lib/api';
import {
  getListInitialState,
  formatDateTimeToString,
} from './../../../lib/helpers';
import type {
  ListState,
  UserAccess,
  MaintenanceDocumentType,
} from './../../../lib/types';
import { entityStatus, maintenanceDocumentTypeEnum } from './../../../lib/enum';

import Table from './../../../components/ui/Table';
import { Popconfirm, Icon } from './../../../components/ui';
import { Header, Section } from './../../../components/layout';
import { notificationLoading } from '../../../components/Notifications';

import { withUserAccess } from './../../withUserAccess';
import { handlingInternalActs } from './../accessRight';

import { Tabs } from './../index';
import { Filter, Tabs as InternalTabs } from './components';
import type { InternalActFilterParams } from './components';

type Props = {
  actType: string,
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<any> & {
  filter: any,
  type: MaintenanceDocumentType,
  columns: any[],
};

const MAINTENANCE_COLUMNS = [
  {
    title: '№ карточки работ',
    key: 'maintenanceId',
    dataIndex: 'maintenance.id',
  },
  {
    title: 'Вид  работ',
    key: 'work',
    dataIndex: 'maintenance.maintenanceWorkType.name',
  },
  {
    title: 'Дата создания',
    key: 'created',
    dataIndex: 'maintenance.created',
    width: '150',
    render: (created: string) =>
      `${formatDateTimeToString(created, 'DD.MM.YYYY')}`,
  },
  {
    title: 'Сумма',
    key: 'totalSum',
    dataIndex: 'maintenance.totalSum',
    render: (totalSum) => totalSum && totalSum.toLocaleString('ru-RU'),
  },
  {
    title: 'Служба',
    key: 'contractor',
    dataIndex: 'maintenance.contractor.company.name',
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    render: (status) => entityStatus[status],
  },
  {
    title: '',
    key: 'file',
    dataIndex: 'file',
    render: (file) => {
      return file && file.url ? (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="small"
          href={`${file.url}?isInline=false`}
          target="_blank"
        />
      ) : null;
    },
  },
];

const ACT_COLUMNS = [
  {
    title: '№ документа',
    key: 'documentNumber',
    dataIndex: 'documentNumber',
  },
  {
    title: 'Период оказанных услуг',
    width: '150',
    render: (record) => {
      return `${formatDateTimeToString(
        record.startDate,
        'DD.MM.YYYY HH:mm'
      )} - ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY HH:mm')}`;
    },
  },
  {
    title: 'Дата создания',
    key: '',
    dataIndex: 'documentDate',
    width: '150',
    render: (actDate: string) =>
      `${formatDateTimeToString(actDate, 'DD.MM.YYYY')}`,
  },
  {
    title: 'Сумма',
    key: 'sum',
    dataIndex: 'sum',
    render: (sum) => (sum ? sum.toLocaleString('ru-RU') : 0),
  },
  {
    title: 'Служба',
    key: 'company',
    dataIndex: 'orgUnitName',
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    render: (status) => entityStatus[status],
  },
  {
    title: '',
    key: 'file',
    dataIndex: 'file',
    render: (file) => {
      return file && file.url ? (
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="small"
          href={`${file.url}?isInline=false`}
          target="_blank"
        />
      ) : null;
    },
  },
];

const OUTSOURCING_ACT = [
  {
    title: '№ договора',
    key: 'contractNumber',
    dataIndex: 'contractNumber',
  },
  {
    title: 'Дата договора',
    key: 'contractDate',
    dataIndex: 'contractDate',
    render: (date) => formatDateTimeToString(date, 'DD.MM.YYYY'),
  },
];

export class ActList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: MAINTENANCE_COLUMNS,
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.setColumns()
    );
  }

  setColumns() {
    const { actType } = this.props;
    let columns = [];
    switch (actType) {
      case 'outsourcing-act':
        columns = [
          ...OUTSOURCING_ACT,
          ...ACT_COLUMNS,
          {
            stopPropagation: true,
            width: 20,
            dataIndex: 'id',
            render: (id: number) => (
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={() => this.deleteOutsourcingAct(id)}
              >
                <Icon type="x" />
              </Popconfirm>
            ),
          },
        ];
        break;
      case 'oils-act':
      case 'fuels-and-oils-act':
        columns = ACT_COLUMNS;
        break;
      default:
        columns = MAINTENANCE_COLUMNS;
    }
    this.setState({ columns }, () => this.fetch());
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.actType !== this.props.actType) {
      this.setColumns();
    }
  }

  deleteOutsourcingAct = async (id: number) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'deleteAct',
      });
      await outsourcingActApi.deleteOutsourcingAct(id);
      notification.success({
        message: 'Заявка успешно отменена',
      });
      await this.fetch();
    } catch (error) {
      notification.error({
        message: error.message,
      });
    } finally {
      notification.close('deleteAct');
    }
  };

  getType() {
    const { actType } = this.props;
    let type = undefined;
    switch (actType) {
      case 'defective-statement':
        type = maintenanceDocumentTypeEnum.defectiveStatement;
        break;
      case 'os3':
        type = maintenanceDocumentTypeEnum.os3;
        break;
      case undefined:
        type = maintenanceDocumentTypeEnum.detailedDefectiveStatement;
        break;
      default:
    }
    return type;
  }

  fetch = async (page: number = this.state.page) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    let data = {};
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'fetchAct',
      });
      switch (this.props.actType) {
        case 'outsourcing-act':
          data = await outsourcingActApi.fetchOutsourcingAct({
            page,
            ...filter,
          });
          break;
        case 'fuels-and-oils-act':
          data = await fuelsActApi.fetch({
            page,
            ...filter,
          });
          break;
        case 'oils-act':
          data = await oilsActApi.fetch({
            page,
            ...filter,
          });
          break;
        default:
          data = await maintenanceDocumentApi.fetchMaintenanceDocument({
            page,
            ...{ ...filter, type: this.getType() },
          });
      }
    } catch (error) {
      notification.error({
        message: error.message,
      });
    } finally {
      notification.close('fetchAct');
      this.setState({ ...data, loading: false, page });
    }
  };

  applyFilter = (filter: InternalActFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  canAdd = () =>
    this.props.userAccess.some((access) =>
      handlingInternalActs.includes(access)
    );

  render() {
    const { location, actType } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр первичных документов</h1>}
          right={
            this.canAdd() && (
              <>
                {actType === 'outsourcing-act' && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate('/budget/act/internal/outsourcing-act/new')
                    }
                  >
                    Создать
                  </Button>
                )}
                {actType === 'fuels-and-oils-act' && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate('/budget/act/internal/fuels-and-oils-act/new')
                    }
                  >
                    Создать
                  </Button>
                )}
                {actType === 'oils-act' && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate('/budget/act/internal/oils-act/new')
                    }
                  >
                    Создать
                  </Button>
                )}
              </>
            )
          }
        />
        <Tabs />
        <InternalTabs />
        <Section>
          {!['oils-act'].includes(actType) && (
            <Filter
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          )}
          <Table
            columns={this.state.columns}
            onRow={(record) => ({
              onClick: () => {
                actType === 'outsourcing-act' &&
                  navigate(
                    `/budget/act/internal/outsourcing-act/${record.id}/edit`
                  );
              },
            })}
            data={data}
            fetch={this.fetch}
            fetchOnMount
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(ActList);
