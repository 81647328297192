// @flow
import React, { useMemo, forwardRef, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Map as LeafletMap, TileLayer, Marker, Polyline } from 'react-leaflet';
import Leaflet from 'leaflet';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { DriftMarker } from 'leaflet-drift-marker';
import _takeWhile from 'lodash/takeWhile';

import type {
  MonitoringVehicle,
  MonitoringVehicleHistoryItem
} from '../../../lib/types/monitoringVehicle';
import { MAPBOX_API_KEY } from '../../../lib/gis';
import { getLinesGroups } from './utils';
import {
  Wrapper,
  VehicleMarker,
  StartPoint,
  EndPoint,
  VehicleTooltip
} from './styles';
import { convertVehicleToString } from '../../../lib/helpers';

const LeafletBox = { height: '100%', width: '100%', minWidth: 600 }; // для лифлета нужна явная высота

type Props = {
  vehicles: MonitoringVehicle[],
  onVehicleClick: (vehicle: MonitoringVehicle) => Promise<void>,
  selectedMonitoringVehicle?: MonitoringVehicle,
  isHistoryOpen?: boolean,
  history: MonitoringVehicleHistoryItem[],
  currentHistoryItem: ?MonitoringVehicleHistoryItem,
  mapRef?: any
};

const lineColor = {
  active: '#2770FF',
  nosignal: '#ccc',
  nonactive: '#E9180A'
};

export const MonitoringMap = ({
  vehicles,
  onVehicleClick,
  selectedMonitoringVehicle,
  isHistoryOpen,
  history,
  currentHistoryItem,
  mapRef
}: Props) => {
  const [boundsSetted, setBoundsSetted] = useState(false);

  const map = useMemo(
    () => mapRef && mapRef.current && mapRef.current.leafletElement,
    [mapRef]
  );

  const waypoints = useMemo(() => {
    return history.map(({ geoPosition: { lat, lng } }) => [lat, lng]);
  }, [history]);

  useEffect(() => {
    if (!boundsSetted || isHistoryOpen) {
      let points = [];
      if (isHistoryOpen && waypoints.length > 0) {
        // points = waypoints.slice(0, 10); // разобраться зачем это условие пока вставил просто return
        return;
      } else if (vehicles.length > 0) {
        points = vehicles
          .filter(
            vehicle => !!vehicle.geoPosition?.lat && !!vehicle.geoPosition?.lng
          )
          .map(vehicle => [
            vehicle?.geoPosition?.lat,
            vehicle?.geoPosition?.lng
          ]);
        if (points.length === 1) {
          // костыль
          points = [...points, ...points];
        }
      } else {
        points = [
          [55, 35],
          [55, 35.5]
        ];
      }
      const bounds = bbox(lineString(points));
      const areaBounds = Leaflet.latLngBounds(
        Leaflet.latLng(...[bounds.slice(0, 2)]),
        Leaflet.latLng(...[bounds.slice(2)])
      ).pad(1);
      map && map.fitBounds(areaBounds);
      setBoundsSetted(true);
    }
  }, [vehicles, boundsSetted, waypoints, map, isHistoryOpen]);

  const currentVehiclePosition = useMemo(() => {
    if (currentHistoryItem && isHistoryOpen) {
      const {
        geoPosition: { lat, lng }
      } = currentHistoryItem;
      return [lat, lng];
    } else if (selectedMonitoringVehicle) {
      const {
        // $FlowFixMe
        geoPosition: { lat, lng }
      } = selectedMonitoringVehicle;
      return [lat, lng];
    }
  }, [selectedMonitoringVehicle, isHistoryOpen, currentHistoryItem]);

  const vehiclesMarkers = useMemo(() => {
    return vehicles.length
      ? vehicles
          .filter(v => v.id !== selectedMonitoringVehicle?.id)
          .filter(v => !!v?.geoPosition?.lat && !!v?.geoPosition?.lng)
          .map(vehicle => {
            return (
              <Marker
                key={vehicle.id}
                position={[
                  vehicle?.geoPosition?.lat,
                  vehicle?.geoPosition?.lng
                ]}
                onClick={async () => await onVehicleClick(vehicle)}
                icon={Leaflet.divIcon({
                  className: 'vehicle-marker',
                  html: ReactDOMServer.renderToString(
                    <VehicleMarker
                      status={vehicle.status}
                      active={'true'}
                      direction={vehicle.direction}
                    />
                  )
                })}
              />
            );
          })
      : null;
  }, [vehicles, onVehicleClick, selectedMonitoringVehicle]);

  const vehiclesPolyline = useMemo(() => {
    return vehicles.length
      ? vehicles
          .filter(v => v.id !== selectedMonitoringVehicle?.id)
          .filter(v => !!v?.polyline)
          .map(vehicle => {
            return (
              <Polyline
                key={vehicle.id}
                opacity={0.6}
                positions={vehicle?.polyline ? [...vehicle.polyline] : []}
              />
            );
          })
      : null;
  }, [vehicles, selectedMonitoringVehicle]);

  useEffect(() => {
    if (map) {
      if (currentVehiclePosition) {
        const latLng = new Leaflet.LatLng(...currentVehiclePosition);
        map.panTo(latLng);
      }
    }
  }, [map, currentVehiclePosition, isHistoryOpen]);

  const [lat, lng] = currentVehiclePosition || [];

  const groupedLines = useMemo(() => {
    return getLinesGroups(history);
  }, [history]);

  const playedLineCoords = useMemo(() => {
    if (currentVehiclePosition) {
      const [curLat, curLng] = currentVehiclePosition;

      return _takeWhile(
        waypoints,
        ([lat, lng]) => lat !== curLat && lng !== curLng
      );
    }
    return [];
  }, [currentVehiclePosition, waypoints]);

  const lines = useMemo(() => {
    if (isHistoryOpen && waypoints) {
      return (
        <>
          <Polyline color="#697279" weight={6} positions={waypoints} />
          <Polyline color="white" weight={4} positions={waypoints} />
          {groupedLines.map(({ status, coords }, index) => (
            <Polyline
              key={index}
              color={lineColor[status]}
              weight={2}
              positions={coords}
            />
          ))}
          <Polyline color="#C0CCD7" weight={2} positions={playedLineCoords} />
        </>
      );
    }
    return null;
  }, [isHistoryOpen, waypoints, groupedLines, playedLineCoords]);

  const [startPoint, endPoint] = useMemo(() => {
    if (history && history.length > 0) {
      const getPosition = (item: MonitoringVehicleHistoryItem) => {
        const {
          geoPosition: { lat, lng }
        } = item;
        return [lat, lng];
      };
      const start = history[0];
      const end = history.slice(-1)[0] || history[0];
      return [getPosition(start), getPosition(end)];
    }
    return [];
  }, [history]);

  return (
    <Wrapper>
      <div className="leaflet-container" style={LeafletBox}>
        <LeafletMap
          center={[55.81446868470671, 49.1971421910889]}
          ref={mapRef}
          leaflet={{}} // хз что это, но помечено как обязательно поле
          style={LeafletBox}
          zoom={12}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
            url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
            id="mapbox/streets-v11"
            accessToken={MAPBOX_API_KEY}
          />
          {isHistoryOpen && (
            <>
              {startPoint && (
                <Marker
                  position={startPoint}
                  icon={Leaflet.divIcon({
                    className: 'vehicle-marker',
                    html: ReactDOMServer.renderToString(<StartPoint />)
                  })}
                />
              )}
              {endPoint && (
                <Marker
                  position={endPoint}
                  icon={Leaflet.divIcon({
                    className: 'vehicle-marker',
                    html: ReactDOMServer.renderToString(<EndPoint />)
                  })}
                />
              )}
            </>
          )}
          {selectedMonitoringVehicle && currentVehiclePosition && (
            <DriftMarker
              position={{ lat, lng }}
              onClick={async () =>
                await onVehicleClick(selectedMonitoringVehicle)
              }
              duration={50}
              zIndexOffset={1000}
              icon={Leaflet.divIcon({
                className: 'vehicle-marker',
                html: ReactDOMServer.renderToString(
                  <VehicleMarker
                    active
                    direction={currentHistoryItem?.direction}
                  />
                )
              })}
            >
              <VehicleTooltip
                offset={{ x: 25, y: 0 }}
                direction="right"
                permanent
              >
                {convertVehicleToString(selectedMonitoringVehicle.vehicle)}
              </VehicleTooltip>
            </DriftMarker>
          )}
          {lines}
          {!isHistoryOpen && vehiclesMarkers}
          {!isHistoryOpen && vehiclesPolyline}
        </LeafletMap>
      </div>
    </Wrapper>
  );
};

// $FlowFixMe
export default forwardRef((props, ref) => (
  <MonitoringMap {...props} mapRef={ref} />
));
