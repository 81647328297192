// @flow
import styled, { css } from 'styled-components';

export const FilterContainer = styled.div.attrs({
  className: 'filter__container'
})`
  ${() => {
    return css`
      display: flex;
      flex-flow: row wrap;
    `;
  }}
`;

export default FilterContainer;
