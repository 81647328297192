// @flow

import React from 'react';
import isEqual from 'lodash/isEqual';
import { SelectProps } from 'antd/lib/select';

import { incomeScheduleApi } from './../../lib/api';
import { nomenclatureType } from './../../lib/enum';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.filter, nextProps.filter) &&
  prevProps.value === nextProps.value;

export default React.memo<Props>(
  ({ filter = {}, ...selectProps }: Props) => (
    <AutocompleteSelect
      fetch={async params => {
        return await incomeScheduleApi.fetchIncomeSchedule({
          ...filter,
          ...params
        });
      }}
      refetchParams={{ filter }}
      fetchSingle={incomeScheduleApi.getIncomeSchedule}
      notFoundText="Графики поступлений не найдены"
      placeholder="Выберите график"
      renderOption={(incomeSchedule: any, Option) => (
        <Option
          key={incomeSchedule.id}
          value={incomeSchedule.id}
          incomeschedule={incomeSchedule}
        >
          {`${nomenclatureType[incomeSchedule.nomenclatureName]} ${
            incomeSchedule.count
          } ${incomeSchedule.sum} руб.`}
        </Option>
      )}
      {...selectProps}
    />
  ),
  areEqual
);
