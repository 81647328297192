// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { mdmApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import type { MdmFilterParams } from '../../containers/Mdm/components/Filter';

type Props = SelectProps & {
  filter?: MdmFilterParams,
  hideMnemocode?: boolean
};

export default ({
  filter = {},
  resultAsObject = false,
  hideMnemocode = false,
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    style={{ maxWidth: '100%' }}
    fetch={async params => {
      return await mdmApi.fetchMdmList({
        ...filter,
        ...params
      });
    }}
    fetchSingle={mdmApi.fetchMdmNode}
    notFoundText="Код не найден"
    placeholder="Выберите код мтр"
    renderOption={(mdm: any, Option) => (
      <Option key={mdm.mnemocode} value={mdm.mnemocode} mdm={mdm}>
        {(hideMnemocode ? '' : `${mdm.mnemocode} - `) + mdm.name}
      </Option>
    )}
    {...selectProps}
  />
);
