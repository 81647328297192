// @flow
import React from 'react';
import { connect } from 'react-redux';

import notification from 'antd/lib/notification';

import type { DriverInspection, UserAccess } from './../../../lib/types';
import { driverInspectionApi } from './../../../lib/api';
import {
  formatDateRangeString,
  getPathWithHistoryParams,
  navigate
} from './../../../lib/helpers';

import { Header } from './../../../components/layout';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import type { AppState } from './../../../ducks/redux';
import { Icon } from './../../../components/ui';
import Section from './../../../components/layout/Section';
import Field from './../../../components/card/Field';
import Grid, { GridItem } from './../../../components/layout/Grid';

import { ActInfo } from './../../Act';
import { Panel, SectionContent } from './../elements';
import { canHandleService } from './../lib';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  driverInspection: DriverInspection
};

class DriverInspectionsCard extends React.Component<Props, State> {
  state = {
    driverInspection: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const driverInspection = await driverInspectionApi.fetchDriverInspection(
        parseInt(id, 10)
      );
      this.setState({ driverInspection });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { driverInspection } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb
                to={getPathWithHistoryParams('/services/driver-inspections')}
              >
                Мед. осмотры водителей
              </Crumb>
              <Crumb>Акт мед. осмотра водителей №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() =>
                  navigate(`/services/driver-inspections/${id}/edit`)
                }
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Мед. осмотр водителей №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Период оказания">
                  {formatDateRangeString(
                    driverInspection.startDate,
                    driverInspection.endDate,
                    'D MMMM YYYY'
                  )}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Сумма оплаты, руб.">
                  {(driverInspection.paymentAmount || 0).toLocaleString(
                    'ru-RU',
                    {
                      style: 'currency',
                      currency: 'RUB'
                    }
                  )}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
          {driverInspection && driverInspection.act && (
            <ActInfo act={driverInspection.act} />
          )}
        </Section>
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(DriverInspectionsCard);
