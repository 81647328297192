import { OrgUnitForm, OrgUnitTable } from '../../containers/OrgUnits';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: OrgUnitTable,
        path: '/admin/org-units',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingOrgUnits,
            accessTypeEnum.handlingOrgUnits,
        ],
    },
    {
        component: OrgUnitForm,
        path: '/admin/org-units/:orgUnitId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingOrgUnits,
            accessTypeEnum.handlingOrgUnits,
        ],
    },
    {
        component: OrgUnitForm,
        path: '/admin/org-units/:parentOrgUnitId/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrgUnits,
        ],
    },
];