// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import moment from 'moment';

import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportVehicleWork,
  ReportVehicleWorkParams,
} from '../../../lib/types/reportVehicleWork';
import { fuelTypes, vehicleTypes } from '../../../lib/enum';
import type { FuelType, VehicleType } from '../../../lib/types';
import { reportVehicleWorksApi } from '../../../lib/api';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

import Filter from './components/Filter';

const initialParams: ReportVehicleWorkParams = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  params: ReportVehicleWorkParams,
  data: ReportVehicleWork[],
  loading: boolean,
  scrollY: number,
};

/**
 * Отчет о работе ТС
 */
export class VehicleWorksList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    await this.fetchReportVehicleWorks();
  }

  fetchReportVehicleWorks = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportVehicleWorksApi.fetchReportVehicleWorks(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<ReportVehicleWorkParams>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  applyParams = this.fetchReportVehicleWorks;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await reportVehicleWorksApi.printReportVehicleWorks(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      fixed: true,
      width: 150,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Марка ТС',
      dataIndex: 'brandName',
      fixed: true,
      width: 150,
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      width: 150,
      render: (type: VehicleType) => vehicleTypes[type],
    },
    {
      title: 'Служба',
      dataIndex: 'orgUnitName',
      width: 150,
    },
    {
      title: 'Пробег за период',
      dataIndex: 'kilometrage',
      width: 100,
      render: (kilometrage: number) => `${kilometrage} км.`,
    },
    {
      title: 'Количество маш/часов за период',
      dataIndex: 'workTime',
      width: 100,
      render: (workTime: number) => `${workTime} ч.`,
    },
    {
      title: 'Остаток на начало периода',
      dataIndex: 'fuelAmountAtTheBegining',
      width: 100,
      render: (fuelAmountAtTheBegining: number) =>
        `${fuelAmountAtTheBegining} л.`,
    },
    {
      title: 'Остаток на конец периода',
      dataIndex: 'fuelAmountAtTheEnd',
      width: 100,
      render: (fuelAmountAtTheEnd: number) => `${fuelAmountAtTheEnd} л.`,
    },
    {
      title: 'В том числе истрачено топлива',
      dataIndex: 'fuelConsumed',
      width: 100,
      render: (fuelConsumed: number) => `${fuelConsumed} (м/ч, л)`,
    },
    {
      title: 'Выдано топлива по Путевому Листу',
      dataIndex: 'fuelIssued',
      width: 100,
      render: (fuelIssued: number) => `${fuelIssued} л.`,
    },
    {
      title: 'Кол-во Путевых Листов',
      dataIndex: 'tripCount',
      width: 100,
    },
    {
      title: 'Вид первичного топлива',
      dataIndex: 'primaryFuelType',
      width: 100,
      render: (type: FuelType) => fuelTypes[type],
    },
    {
      title: 'Вид вторичного топлива',
      dataIndex: 'secondaryFuelType',
      width: 100,
      render: (type: FuelType) => fuelTypes[type],
    },
  ];

  render() {
    const { data, loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Отчет о работе ТС</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            rowKey="vehicleId"
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
            name="ReportsVehicleWorks"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1500,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default VehicleWorksList;
