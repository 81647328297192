// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RangePicker } from '../../../components/ui';
import Field from '../../../components/card/Field';
import { InputNumber } from '../../../components/inputs';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Spoiler from '../../../components/ui/Spoiler';
import type { JournalFilter } from '../../../ducks/journals';
import { applyFilters, cleanFilters } from '../../../ducks/journals';
import type { AppState } from '../../../ducks/redux';

import {
  formatDateTimeToISOString,
  setQueryParams,
} from '../../../lib/helpers';
import Select from './../../../components/selects';

const { JournalTypeSelect, UserSelect, EntityTypeSelect } = Select;

const Buttons = styled(GridItem).attrs({ fullWidth: true })`
  margin: 0 -10px;

  & .ant-btn {
    margin: 0 10px;
  }
`;

const SpoilerContent = styled.div`
  padding: 16px;
`;

type Props = {
  filters: JournalFilter,
  applyFilters: (filter: JournalFilter) => void,
  cleanFilters: (filter?: JournalFilter) => void,
};

type State = {
  filters: $Shape<JournalFilter>,
  fio: ?string,
};

export class Filter extends React.Component<Props, State> {
  state = {
    filters: {},
    fio: null,
  };

  async componentDidMount() {
    const { page, ...filters } = qs.parse(window.location.search);
    this.setState({ filters }, () => this.applyFilters());
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.filters, prevProps.filters)) {
      this.setState({ filters: this.props.filters });
    }
  }

  handleChangeFilters = (key: $Keys<JournalFilter>, value: any) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [(key: string)]: value,
      },
    }));
  };

  onFilterChange = (fieldName: string, value: any) =>
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [fieldName]: value,
      },
    }));

  applyFilters = async () => {
    try {
      await this.props.applyFilters(this.state.filters);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  cleanFilters = async () => {
    try {
      this.setState({});
      setQueryParams({ entityId: undefined, entityType: undefined });
      await this.props.cleanFilters();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  render() {
    const { filters, fio } = this.state;
    return (
      <Spoiler label="Фильтр" defaultExpanded>
        <SpoilerContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Период">
                <RangePicker
                  startDate={
                    filters.startDate ? moment.utc(filters.startDate) : null
                  }
                  endDate={filters.endDate ? moment.utc(filters.endDate) : null}
                  onChange={this.onFilterChange}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Пользователь">
                <UserSelect
                  showFio
                  value={fio}
                  onChange={(value: string, option?: any) => {
                    if (option) {
                      const {
                        props: { user },
                      } = option;
                      this.setState({
                        fio: `${user.employee.lastname} ${user.employee.firstname} ${user.employee.middlename}`,
                      });
                      this.handleChangeFilters('userName', user.userName);
                    } else {
                      this.handleChangeFilters('userName', undefined);
                    }
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип события">
                <JournalTypeSelect
                  value={filters.type}
                  disabled={!!filters.entityType}
                  onChange={(value: string) =>
                    this.handleChangeFilters('type', value)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Поисковый запрос">
                <Input
                  value={filters.search}
                  onChange={({ target: { value } }) =>
                    this.handleChangeFilters('search', value)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип объекта">
                <EntityTypeSelect
                  placeholder="Выберите тип объекта"
                  value={filters.entityType}
                  disabled={!!filters.type}
                  onChange={(value) =>
                    this.handleChangeFilters('entityType', value)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Идентификатор сущности">
                <InputNumber
                  value={filters.entityId}
                  onChange={(value) =>
                    this.handleChangeFilters('entityId', value)
                  }
                />
              </Field>
            </GridItem>
            <Buttons>
              <Button type="primary" onClick={this.applyFilters}>
                Применить
              </Button>
              <Button onClick={this.cleanFilters}>Очистить</Button>
            </Buttons>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filters: state.journals.filters,
  }),
  {
    applyFilters,
    cleanFilters,
  }
)(Filter);
