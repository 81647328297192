import React from 'react';

import PddTestResultCard from './../pdd/Results/Card';

type Props = {
  driverId: number,
  pddTestResultId: number
};
export default ({ driverId, pddTestResultId }: Props) => {
  const breadCrumbs = [
    { to: '/', name: 'Главная' },
    { to: '/admin/drivers', name: 'Водители' },
    { to: `/admin/drivers/${driverId}`, name: 'Карточка водителя' },
    { name: 'Просмотр теста' }
  ];
  return <PddTestResultCard id={pddTestResultId} breadCrumbs={breadCrumbs} />;
};
