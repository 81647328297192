// @flow
import moment from 'moment';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React from 'react';

import { Selects } from '../../components';
import { Header, Section } from '../../components/layout';
import { DatePicker } from '../../components/ant/DatePicker';
import { notificationLoading } from '../../components/Notifications';
import Table from '../../components/ui/Table';
import { Icon, Operations, Popconfirm } from './../../components/ui';
import { formatLicensePlateMask } from './../../components/inputs/masked-inputs/LicensePlateInput';

import {
  applyMaskToValue,
  formatDateTimeToString,
  getListInitialState,
} from './../../lib/helpers';
import { bioTreatmentFacilityContractApi } from '../../lib/api';
import {
  accessTypeEnum,
  bioTreatmentFacilityTalonStatusEnum,
} from '../../lib/enum';
import type {
  BioTreatmentFacilityContract,
  ListState,
  UserAccess,
} from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

import type { BioTreatmentFacilitiesFilterParams } from './components/Filter';
import Filter from './components/Filter';

const { YearSelect } = Selects;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<BioTreatmentFacilityContract> & {
  filter: BioTreatmentFacilitiesFilterParams,
  columns: Array<Object>,
  print: { startDate: string, endDate: string } | null,
  printYear: number | null
};

export class BioTreatmentFacilitiesList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: [
      {
        title: 'Контрагент',
        key: 'companyName',
        dataIndex: 'company.name',
      },
      {
        title: 'Номер договора',
        dataIndex: 'contractNumber',
      },
    ],
    print: null,
    printYear: null
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetch(page)
    );
    if (this.canAdd()) {
      this.setState({
        columns: [
          ...this.state.columns,
          {
            title: '',
            width: 80,
            dataIndex: 'operations',
            // eslint-disable-next-line no-unused-vars
            render: (
              text: string,
              bioTreatmentFacilityContract: BioTreatmentFacilityContract
            ) => {
              return (
                <Operations>
                  <Popconfirm
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() =>
                      this.delete(
                        bioTreatmentFacilityContract.id,
                        bioTreatmentFacilityContract
                      )
                    }
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              );
            },
          },
        ],
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBioTreatmentFacility,
      ].includes(access)
    );

  delete = async (
    id: number,
    bioTreatmentFacilityContract: BioTreatmentFacilityContract
  ) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });

      const talons =
        bioTreatmentFacilityContract?.volumes?.flatMap(
          ({ talons }) => talons
        ) ?? [];
      talons.forEach((talon) => {
        if (
          [
            bioTreatmentFacilityTalonStatusEnum.approved,
            bioTreatmentFacilityTalonStatusEnum.closed,
          ].includes(talon.status)
        ) {
          throw new Error(
            'Нельзя удалить, есть утвержденные или закрытые талоны'
          );
        }
      });
      await bioTreatmentFacilityContractApi.delete(id);
      await this.fetch(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetch = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await bioTreatmentFacilityContractApi.list({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: BioTreatmentFacilitiesFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  print = async () => {
    const { print } = this.state;
    try {
      notificationLoading({
        message: 'Печать...',
        key: 'printing',
      });
      await bioTreatmentFacilityContractApi.print(print);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  }

  printYear = async () => {
    const { printYear } = this.state;
    try {
      notificationLoading({
        message: 'Печать...',
        key: 'printing',
      });
      if (printYear === null || printYear === ''
        || printYear === undefined) {
        throw new Error('Не выбран год');
      }
      await bioTreatmentFacilityContractApi.printYear(printYear);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  }

  renderPrint = () => {
    return <DatePicker.MonthPicker
      placeholder="Выберите дату"
      format="MMM YYYY"
      onChange={(value) => {
        this.setState({
          print: {
            startDate: moment(value).utc().startOf('month').toISOString(),
            endDate: moment(value).utc().endOf('month').toISOString()
          }
        });
      }}
    />
  }

  renderPrintYear = () => {
    const { printYear } = this.state;
    return <YearSelect
      value={printYear}
      dropdownStyle={{ zIndex: 99999999 }}
      onChange={(printYear: number) => {
        this.setState({ printYear });
      }}
    />
  }

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter, columns } =
      this.state;
    return (
      <>
        <Header
          left={<h1>Учет БОС</h1>}
          right={
            <>
              <Popconfirm
                title={this.renderPrintYear()}
                onConfirm={async () => this.printYear()}
              >
                <Button style={{ marginRight: '16px' }}>
                  Годовой Отчет
                </Button>
              </Popconfirm>
              <Popconfirm
                title={this.renderPrint()}
                onConfirm={async () => this.print()}
              >
                <Button style={{ marginRight: '16px' }}>
                  Отчет
                </Button>
              </Popconfirm>
              {this.canAdd() && (
                <>
                  <Button
                    type="primary"
                    onClick={() => navigate('/bioTreatmentFacilityContract/new')}
                  >
                    Создать
                  </Button>
                </>
              )}
            </>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={columns}
            data={data}
            fetch={this.fetch}
            fetchOnMount
            onRow={(row: BioTreatmentFacilityContract) => ({
              onClick: () =>
                navigate(`/bioTreatmentFacilityContract/${row.id}`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(BioTreatmentFacilitiesList);
