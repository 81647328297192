// @flow

import type { AppState, Dispatch } from 'redux';

import type {
  DetailedDefectiveStatement,
  MaintenanceOperation,
  MaintenancePartAndConsumables
} from './../lib/types';
import type { Action } from './redux';
import { detailedDefectiveStatementApi } from '../lib/api';

export const CLEAR_DETAILED_DEFECTIVE_STATEMENT =
  'vehicles/detailed-defective-statement/clear';
export const SET_DETAILED_DEFECTIVE_STATEMENT =
  'vehicles/detailed-defective-statement/set';

const initialState: ?DetailedDefectiveStatement = null;

const reducer = (
  state: ?DetailedDefectiveStatement = initialState,
  { type, payload }: Action
): ?DetailedDefectiveStatement => {
  switch (type) {
    case SET_DETAILED_DEFECTIVE_STATEMENT:
      return payload;
    case CLEAR_DETAILED_DEFECTIVE_STATEMENT:
      return initialState;
    default:
      return state;
  }
};

export const clear = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_DETAILED_DEFECTIVE_STATEMENT
  });

export const setDetailedDefectiveStatement: Function = (
  detailedDefectiveStatement: DetailedDefectiveStatement
): Function => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: SET_DETAILED_DEFECTIVE_STATEMENT,
    payload: { ...detailedDefectiveStatement }
  });
};

export const saveOperations: Function = (
  operations: MaintenanceOperation[]
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const detailedDefectiveStatement = await detailedDefectiveStatementApi.updateDetailedDefectiveStatement(
    {
      ...getState().detailedDefectiveStatement,
      operations
    }
  );
  dispatch({
    type: SET_DETAILED_DEFECTIVE_STATEMENT,
    payload: {
      ...detailedDefectiveStatement
    }
  });
};

export const saveParts: Function = (
  parts: MaintenancePartAndConsumables[]
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const detailedDefectiveStatement = await detailedDefectiveStatementApi.updateDetailedDefectiveStatement(
    {
      ...getState().detailedDefectiveStatement,
      parts
    }
  );
  dispatch({
    type: SET_DETAILED_DEFECTIVE_STATEMENT,
    payload: {
      ...detailedDefectiveStatement
    }
  });
};

export const saveDetailedDefectiveStatement: Function = (
  detailedDefectiveStatement: DetailedDefectiveStatement
): Function => async (dispatch: Dispatch): Promise<void> => {
  const updated = await detailedDefectiveStatementApi.updateDetailedDefectiveStatement(
    detailedDefectiveStatement
  );
  dispatch({
    type: SET_DETAILED_DEFECTIVE_STATEMENT,
    payload: { ...updated }
  });
};

export default reducer;
