// @flow
import type { OilsAndFluidsConsumptionPlan } from '../types';
import CommonApi from './../commonApi';
import { downloadRequestWithToken, fetchRequest } from './index';
import type { TechnicalFluidStatusType } from '../types';
import type { FetchListParams } from './index';

const oilsAndFluidsConsumptionPlan = new CommonApi<OilsAndFluidsConsumptionPlan>(
  'oilsAndFluidsConsumptionPlan',
  'План расхода масел и техжидкостей'
);

export const changeStatus = async (data: {
  id: number,
  status: TechnicalFluidStatusType
}): Promise<OilsAndFluidsConsumptionPlan> => {
  const updated = await fetchRequest.put(
    `/oilsAndFluidsConsumptionPlan/changeStatus`,
    data
  );
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export const print = async (params: FetchListParams<any>): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    '/oilsAndFluidsConsumptionPlan/residueValuesReport/print',
    {
      ...params
    }
  );
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export default {
  ...oilsAndFluidsConsumptionPlan,
  changeStatus,
  print
};
