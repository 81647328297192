// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import moment from 'moment';
import { navigate } from '@reach/router';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Panel, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { Osago } from '../../../lib/types';
import { Selects, Form } from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { formatDateTimeToISOString, goBack } from '../../../lib/helpers';
import { osagoApi } from './../../../lib/api';
import OsagoInput from '../../../components/inputs/masked-inputs/OsagoInput';
import { notificationLoading } from '../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import { SectionTitle } from '../../../components/layout';
import { InputNumber } from '../../../components/inputs';
import { ownerTypes } from '../../../lib/enum';

const { VehicleSelect, ContractorSelect, InsuranceClassSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  osago: $Shape<Osago>,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  osago,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={osago} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        values,
        dirty
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          {showVehicleSelect && (
            <Section>
              <SectionTitle divider>Данные ТС</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          filter={{
                            ownerType: ownerTypes.self
                          }}
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
          )}
          <Section>
            <SectionTitle divider>Данные по ОСАГО</SectionTitle>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Серия и номер ОСАГО"
                    name="osagoNumber"
                    required
                    fast
                  >
                    {field => <OsagoInput {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата начала действия ОСАГО"
                    fast
                    name="osagoStartedDate"
                    required
                    validate={value =>
                      values.osagoFinishedDate &&
                      moment(value).isAfter(values.osagoFinishedDate) &&
                      'Дата начала действия ОСАГО не может быть позже даты окончания'
                    }
                  >
                    {({ value, name }) => (
                      <DatePicker
                        value={value ? moment(value) : undefined}
                        format={'DD.MM.YYYY'}
                        onChange={(value, dateString) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата окончания действия ОСАГО"
                    fast
                    name="osagoFinishedDate"
                    required
                    validate={value =>
                      values.osagoStartedDate &&
                      moment(value).isBefore(values.osagoStartedDate) &&
                      'Дата окончания действия ОСАГО не может быть раньше даты начала'
                    }
                  >
                    {({ value, name }) => (
                      <DatePicker
                        value={value ? moment(value) : undefined}
                        format={'DD.MM.YYYY'}
                        onChange={(value, dateString) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="insuranceClass"
                    label="Класс на начало годового срока страхования"
                  >
                    {({ value, name }) => (
                      <InsuranceClassSelect
                        name={name}
                        value={value || undefined}
                        onChange={value => setFieldValue(name, value)}
                        onBlur={() => handleBlur({ target: { name } })}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    required
                    label="Наименование страховщика"
                    name="contractorId"
                  >
                    {({ name, value }) => (
                      <ContractorSelect
                        name={name}
                        value={value}
                        onChange={(contractorId: number) =>
                          setFieldValue(name, contractorId)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Серия и номер диагностической карты"
                    name="diagnosticCardNumber"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="insuranceCasesCount"
                    label="Количество страховых случаев, по которым выплачена страховка"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                  >
                    {({ name, value }) => (
                      <InputNumber
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  osagoId: ?number,
  vehicleId: ?number
};

type State = {
  osago: ?$Shape<Osago>
};

class OsagoForm extends Component<Props, State> {
  state = {
    osago: null
  };

  async componentDidMount() {
    try {
      const osagoId = parseInt(this.props.osagoId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (osagoId) {
        const osago = await osagoApi.fetchOsago(osagoId);
        this.setState({
          osago
        });
      } else if (vehicleId) {
        this.setState({
          osago: {
            vehicleId
          }
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.osagoId) => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/osago`);
    } else {
      goBack(`/services/osagos/${id || ''}`);
    }
  };

  onSubmit = async (osago: Osago) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const osagoId = parseInt(this.props.osagoId, 10);
      if (osagoId) {
        await osagoApi.updateOsago(osago);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(osagoId);
      } else {
        const added = await osagoApi.addOsago(osago);

        notification.success({
          message: 'Успешное добавление',
          description: `ОСАГО успешно добавлено`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const osagoId = parseInt(this.props.osagoId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { osago } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {vehicleId > 0 ? (
                <Crumb to={`/vehicles/${vehicleId}`}>ТС</Crumb>
              ) : (
                <Crumb to={'/services/osagos'}>Список ОСАГО</Crumb>
              )}
              {osagoId ? (
                <Crumb>Редактирование ОСАГО</Crumb>
              ) : (
                <Crumb>Новый полис ОСАГО</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoId
              ? `ОСАГО ${(osago ? osago.osagoNumber : '') || ''}`
              : 'Новый полис ОСАГО'}
          </h1>
        </StyledPanel>
        <InnerForm
          osago={{
            paymentAmount: 0,
            id: 0,
            osagoNumber: '',
            ...osago
          }}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect((osago && osago.id) || osagoId)}
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default OsagoForm;
