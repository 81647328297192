// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';
import Input from 'antd/lib/input';
import { RangePicker } from '../../../../components/ant/DatePicker';

import Spoiler from '../../../../components/ui/Spoiler';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import Selects from '../../../../components/selects';
import {
  applyMaskToValue,
  formatDateTimeToISOString
} from '../../../../lib/helpers';
import type { ReportWeekendOrdersParams } from '../../../../lib/types/reportWeekendOrders';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import type { Vehicle } from '../../../../lib/types';
import { formatLicensePlateMask } from '../../../../components/inputs/masked-inputs/LicensePlateInput';

const {
  VehicleModelSelect,
  EmployeeSelect,
  VehicleSelect,
  VehicleTypeSelect,
  BrandsSelect
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  onChangeFilter: (key: $Keys<any>, value: any) => void,
  params: ReportWeekendOrdersParams,
  cleanParams: () => void,
  applyParams: () => Promise<any>,
  onExport: Function
};

/**
 * Фильтр с параметрами
 */
export default class Filter extends React.Component<Props> {
  render() {
    const { params, onExport } = this.props;
    return (
      <Spoiler defaultExpanded label="Параметры">
        <SpoilerContent>
          <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
            <GridItem>
              <Field label="Номер заявки">
                <Input
                  value={params.orderId}
                  onChange={event =>
                    this.props.onChangeFilter('orderId', event.target.value)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Сотрудник">
                <EmployeeSelect
                  value={params.employeeId}
                  onChange={id => this.props.onChangeFilter('employeeId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Подразделение">
                <OrgUnitSelect
                  value={params.orgUnitId}
                  onChange={id => this.props.onChangeFilter('orgUnitId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Отчетный период">
                <RangePicker
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    params.startDate ? moment.utc(params.startDate) : null,
                    params.endDate ? moment.utc(params.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    this.props.onChangeFilter(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    this.props.onChangeFilter(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Гос. номер">
                <VehicleSelect
                  value={params.vehicleId}
                  optionTitle={(vehicle: Vehicle) =>
                    applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )
                  }
                  onChange={id => this.props.onChangeFilter('vehicleId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип ТС">
                <VehicleTypeSelect
                  value={params.vehicleType}
                  onChange={type =>
                    this.props.onChangeFilter('vehicleType', type)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Марка ТС">
                <BrandsSelect
                  value={params.brandId}
                  onChange={id => this.props.onChangeFilter('brandId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Модель ТС">
                <VehicleModelSelect
                  value={params.vehicleModelId}
                  onChange={id =>
                    this.props.onChangeFilter('vehicleModelId', id)
                  }
                />
              </Field>
            </GridItem>
            <Footer fullWidth>
              <Button type="primary" onClick={onExport}>
                Экспорт
              </Button>
              <Button type="primary" onClick={this.props.applyParams}>
                Применить
              </Button>
              <Button type="secondary" onClick={this.props.cleanParams}>
                Очистить
              </Button>
            </Footer>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}
