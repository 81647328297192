// @flow
import React, { useState, type Node, useEffect } from 'react';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import findIndex from 'lodash/findIndex';

import type {
  BusinessDay,
  OrderType,
  WayPoint,
  WaypointType
} from '../../lib/types';
import { waypointTypeEnum } from '../../lib/enum';
import WaypointTable from './WaypointTable';
import {
  getWaypoints,
  copyWaypoint,
  withoutEmptyWaypoint,
  getDisabledDepartureDate,
  getDisabledArrivalDate,
  withEmptyWaypoint,
  getNearlyDaysOff
} from './lib';
import { businessDayApi } from '../../lib/api';

export type WaypointsType = 'standart' | 'emergency' | 'business';

type Props = {
  type: WaypointsType,
  // Точки маршрутного задания
  waypoints: WayPoint[],
  // Функция изменения точек
  onChange: (waypoints: WayPoint[]) => void,
  // Тип редактирования маршрутного задания
  editTypes: Array<WaypointType>,
  // Дополнительные строки для рендеринга в конце
  appendRows: Node[],
  // Отступ в часах для разблокировки
  offsetHours?: number,
  // ID филиала текущего пользователя
  employeeBranchOrgUnitId: number,
  // TODO: Временный пропс для разблокировки дат в ПЛ
  disableDates?: boolean,
  hasErrors?: boolean,
  orderType: OrderType
};

const WaypointsForm = ({
  waypoints = [],
  onChange,
  editTypes = [
    waypointTypeEnum.transit,
    waypointTypeEnum.start,
    waypointTypeEnum.end
  ],
  employeeBranchOrgUnitId,
  offsetHours,
  appendRows = [],
  disableDates = true,
  type,
  hasErrors,
  orderType
}: Props) => {
  const { start, transit, end } = getWaypoints(waypoints);
  const [businessCalendar, setBusinessCalendar] = useState<BusinessDay[]>([]);
  const [nearlyDaysOff, setNearlyDaysOff] = useState<string[]>([]);
  const [startWaypoint, setStartWaypoint] = useState<?WayPoint>(start);
  const [transitWaypoints, setTransitWaypoints] = useState<WayPoint[]>(
    withEmptyWaypoint(transit)
  );
  const [endWaypoint, setEndWaypoint] = useState<?WayPoint>(end);

  function copyWaypointValue(waypoint: WayPoint) {
    const waypoints = copyWaypoint(waypoint, transitWaypoints);
    reduceWaypoints('transit', waypoints);
  }

  const fetchBusinessCalendar = async () => {
    const { data } = await businessDayApi.fetchBusinessDays({ pageSize: 0 });
    setBusinessCalendar(data);
    setNearlyDaysOff(
      getNearlyDaysOff(
        data,
        moment
          .utc()
          .startOf('day')
          .toISOString()
      )
    );
  };

  /**
   * Обновляет список точек по типу
   * @param type Тип точки
   * @param value Новое значение
   */
  function reduceWaypoints(type?: WaypointType, value: any) {
    const setWaypoint = (value: any): WayPoint[] => {
      switch (type) {
        case 'start':
          setStartWaypoint((value: WayPoint));
          // $FlowFixMe
          return [value, ...transitWaypoints, endWaypoint].filter(w => !!w);
        case 'transit':
          setTransitWaypoints((value: WayPoint[]));
          return [startWaypoint, ...value, endWaypoint].filter(w => !!w);
        case 'end':
          setEndWaypoint((value: WayPoint));
          // $FlowFixMe
          return [startWaypoint, ...transitWaypoints, value].filter(w => !!w);
        default:
          return waypoints;
      }
    };
    const waypoints = setWaypoint(value);
    const { start, transit, end } = getWaypoints(waypoints);
    // Обновляем все точки по типам
    setStartWaypoint(start);
    setTransitWaypoints(transit);
    setEndWaypoint(end);
    // Обновляем точки
    onChange(withoutEmptyWaypoint(waypoints));
  }

  useEffect(() => {
    fetchBusinessCalendar();
  }, []);

  useEffect(() => {
    const { start, transit, end } = getWaypoints(waypoints);

    if (!isEqual(start, startWaypoint)) setStartWaypoint(start);
    // TODO: переделать этот быстрый фикс
    if (
      !isEqual(
        transit.map(v => omit(v, 'key')),
        withoutEmptyWaypoint(transitWaypoints).map(v => omit(v, 'key'))
      )
    )
      setTransitWaypoints(withEmptyWaypoint(transit));
    if (!isEqual(end, endWaypoint)) setEndWaypoint(end);
    // eslint-disable-next-line
  }, [waypoints]);

  /**
   * Функция обновления значения конкретной строки по типу точки
   * @param waypoint Точка
   */
  function changeWaypoint(waypoint: WayPoint) {
    switch (waypoint.type) {
      case 'transit':
        const waypointIndex = findIndex(
          transitWaypoints,
          w => waypoint.key === w.key
        );
        const waypoints = [...transitWaypoints];
        waypoints.splice(waypointIndex, 1, waypoint);
        reduceWaypoints('transit', waypoints);
        break;
      default:
        reduceWaypoints(waypoint.type, waypoint);
    }
  }

  const removeTransitWaypoint = (waypoint: WayPoint) => {
    reduceWaypoints(
      'transit',
      transitWaypoints.filter((wp: WayPoint) => wp.key !== waypoint.key)
    );
  };

  const addNewRow = () => {
    const { transit } = getWaypoints(waypoints);
    setTransitWaypoints(withEmptyWaypoint(transit));
  };

  return (
    <WaypointTable
      hasErrors={hasErrors}
      disableDates={disableDates}
      transitWaypoints={transitWaypoints}
      startWaypoint={startWaypoint}
      endWaypoint={endWaypoint}
      editTypes={editTypes}
      employeeBranchOrgUnitId={employeeBranchOrgUnitId}
      appendRows={appendRows}
      changeWaypoint={changeWaypoint}
      removeTransitWaypoint={removeTransitWaypoint}
      copyWaypoint={copyWaypointValue}
      orderType={orderType}
      getDisabledDepartureDate={payload =>
        getDisabledDepartureDate(payload, {
          type,
          businessCalendar,
          nearlyDaysOff
        })
      }
      addNewRow={addNewRow}
      getDisabledArrivalDate={payload =>
        getDisabledArrivalDate(payload, {
          type,
          businessCalendar,
          nearlyDaysOff
        })
      }
      routeStartDate={
        startWaypoint && startWaypoint.departureDateTime
          ? moment.utc(startWaypoint.departureDateTime).startOf('day')
          : moment().add(offsetHours, 'hour')
      }
      routeEndDate={
        endWaypoint &&
        endWaypoint.arrivedDateTime &&
        moment.utc(endWaypoint.arrivedDateTime).endOf('day')
      }
    />
  );
};

export default WaypointsForm;
