// @flow
import { MenuOutlined } from '@ant-design/icons';
import { CellProps } from 'antd/lib/descriptions/Cell';
import React, { type Node } from 'react';
import { Resizable } from 'react-resizable';

type TableHeaderCellCustomProps = CellProps & {
  title?: Node,
  hashId: number,
  width: number,
  index: number,
  onResize: (index: number) => void,
};

export const TableHeaderCell = (props: TableHeaderCellCustomProps) => {
  const { title, hashId, width, index, onResize, ...restProps } = props;

  return (
    <Resizable
      width={props.width}
      height={0}
      axis="x"
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={(e, params) => {
        props.onResize(hashId, params.size);
      }}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <div style={{ zIndex: 9 }}>
            <MenuOutlined style={{ cursor: 'move', margin: '0 5px' }} />
          </div>
          {props.title}
        </div>
      </th>
    </Resizable>
  );
};
