// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportBusinessTrip,
  ReportBusinessTripOrder,
  ReportBusinessTripParams
} from '../types/reportBusinessTrips';

/**
 * Получение отчета
 */
export const fetchReportBusinessTrips = async (
  params: ReportBusinessTripParams
): Promise<ReportBusinessTrip[]> => {
  const report = await fetchRequest.get('/report/businessTrips', {
    ...params
  });
  return report;
};

/**
 * Печать отчета
 */
export const printReportBusinessTrips = async (
  params: ReportBusinessTripParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/businessTrips/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

/**
 * Запросить данные командировок
 */
export const fetchData = async (
  params: ReportBusinessTripOrder
): Promise<boolean> => {
  const sended = await fetchRequest.get('/order/parus/updateOrders', {
    ...params
  });
  if (sended) return true;
  throw new Error('Не отправить данные');
};

export default {
  fetchReportBusinessTrips,
  printReportBusinessTrips,
  fetchData
};
