// @flow

import styled from 'styled-components';

export const PageFlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100vh;
`;

export const TableFlexColumnWrapper = styled.section`
  border-radius: 3px;
  margin: 16px;
  background: white;
  overflow: hidden;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
`;
