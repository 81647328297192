// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import React, { Component } from 'react';
import styled from 'styled-components';
import Field from '../../../../components/card/Field';
import { GridItem } from '../../../../components/layout/Grid';
import { notificationLoading } from '../../../../components/Notifications';
import { Icon } from '../../../../components/ui';
import { withUserAccess } from '../../../withUserAccess';
import { canApprovementVehiclePlan, canApprovingVehiclePlan } from '../accessRight';

import { Grid, Section, SectionTitle } from './../../../../components/layout';
import { vehiclePlanApi } from './../../../../lib/api';
import { vehiclePlanStatus, vehiclePlanStatusEnum } from './../../../../lib/enum';
import { formatDate, formatDateRangeString, navigate } from './../../../../lib/helpers';

import type { UserAccess, VehiclePlan, VehiclePlanStatus } from './../../../../lib/types';

import { commonHeaderPanel } from './../lib';
import VehicleSection from './components/VehicleSection';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};
type State = {
  vehiclePlan: ?$Shape<VehiclePlan>,
  loading: boolean
};

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

class BudgetVehicleCard extends Component<Props, State> {
  state = {
    vehiclePlan: null,
    loading: true
  };

  async componentDidMount() {
    const vehiclePlan = await this.getVehiclePlan(this.props.vehiclePlanId);
    this.setState({ vehiclePlan, loading: false });
  }

  getVehiclePlan = async (vehiclePlanId: number): Promise<?VehiclePlan> => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting'
      });
      return await vehiclePlanApi.get(vehiclePlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('getting');
    }
  };

  changeStatus = async (status: VehiclePlanStatus) => {
    const { vehiclePlanId } = this.props;
    let { vehiclePlan } = this.state;
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'change'
      });
      vehiclePlan = await vehiclePlanApi.changeStatus({
        id: vehiclePlanId,
        status
      });
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('change');
    }
  };

  topPanel = () => {
    const { vehiclePlanId } = this.props;
    const { vehiclePlan } = this.state;
    return (
      <h1>
        Планируемый перечень ТС
        {vehiclePlan?.status === vehiclePlanStatusEnum.created && (
          <StyledIcon
            onClick={() =>
              navigate(`/budget/vehicle/${vehiclePlanId}/card/edit`)
            }
            type="edit"
            size={16}
          />
        )}
      </h1>
    );
  };

  render() {
    const { vehiclePlanId, userAccess } = this.props;
    const { vehiclePlan, loading } = this.state;
    return (
      <>
        {commonHeaderPanel({
          title: 'Планируемый перечень ТС',
          vehiclePlanId,
          panel: this.topPanel,
          mainTab: vehiclePlan?.status === vehiclePlanStatusEnum.approved
        })}

        <Section>
          <SectionTitle divider>Период и версия бюджета</SectionTitle>
          <Content>
            <Grid gutter="16px" cols={6}>
              <GridItem>
                <Field label="Статус">
                  <>{vehiclePlan && vehiclePlanStatus[vehiclePlan.status]}</>
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Планируемый период">
                  <>
                    {formatDateRangeString(
                      vehiclePlan?.startDate,
                      vehiclePlan?.endDate,
                      'DD.MM.YYYY'
                    )}
                  </>
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Версия бюджета">
                  <>{vehiclePlan?.budgetVersion.name}</>
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата формирования">
                  <>{formatDate(vehiclePlan?.date)}</>
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Филилал">
                  <>{vehiclePlan?.orgUnitName}</>
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Баланс рабочего времени, ч.">
                  <>{vehiclePlan?.workBalance}</>
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle divider>ТС согласно инвентарной картотеке</SectionTitle>
          <Content>
            <VehicleSection
              data={vehiclePlan?.selfVehicles || []}
              loading={loading}
              vehiclePlanStatus={vehiclePlan?.status}
              readonly
            />
          </Content>
        </Section>

        <Section>
          <SectionTitle divider>ТС согласно плану обновления</SectionTitle>
          <Content>
            <VehicleSection
              data={vehiclePlan?.plannedVehicles || []}
              loading={loading}
              vehiclePlanStatus={vehiclePlan?.status}
              readonly
            />
          </Content>
        </Section>

        {![
          vehiclePlanStatusEnum.declined,
          vehiclePlanStatusEnum.approved
        ].includes(vehiclePlan?.status) && (
          <>
            {canApprovementVehiclePlan(userAccess) &&
              [vehiclePlanStatusEnum.created].includes(vehiclePlan?.status) && (
                <Footer>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.changeStatus(vehiclePlanStatusEnum.approvement)
                    }
                  >
                    На согласование
                  </Button>
                </Footer>
              )}
            {canApprovingVehiclePlan(userAccess) &&
              vehiclePlan?.status === vehiclePlanStatusEnum.approvement && (
                <Footer>
                  <div>
                    <Button
                      type="primary"
                      style={{ marginRight: '10px' }}
                      onClick={() =>
                        this.changeStatus(vehiclePlanStatusEnum.approved)
                      }
                    >
                      Утвердить
                    </Button>
                    <Button
                      onClick={() =>
                        this.changeStatus(vehiclePlanStatusEnum.declined)
                      }
                    >
                      Отклонить
                    </Button>
                  </div>
                </Footer>
              )}
          </>
        )}
      </>
    );
  }
}

export default withUserAccess(BudgetVehicleCard);
