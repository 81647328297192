export const accessTypes = {
  newFeaturesDemo: 'Демонстрационный клейм',

  viewingDashboard: 'Просмотр сводной аналитики (вкладка Главный дашборд)',
  viewingFluidsTickets: 'Просмотр  журнала и талонов  учета тех.жидкостей',
  handlingFluidsTickets: 'Создание Талона учета тех.жидкостей',
  viewingOilsAndFluidsConsumptionPlan: 'Просмотр Плана тех.жидкостей',
  handlingOilsAndFluidsConsumptionPlan: 'Редактирование Плана тех.жидкостей',
  viewingUsersAndRoles: 'Просмотр справочников Роли и Пользователи',
  handlingUsersAndRoles: 'Редактирование справочников Роли и Пользователи',
  viewingBrandsAndVehicleModels: 'Просмотр справочников Марки и Модели',
  handlingBrandsAndVehicleModels: 'Редактирование справочников Марки и Модели',
  viewingOrgUnits: 'Просмотр справочника Подразделения',
  handlingOrgUnits: 'Редактирование справочника Подразделения',
  viewingTrips: 'Просмотр путевых',
  handlingDriver: 'Создание/редактирование водителя',
  viewingAllDrivers: 'Просмотр водителей всех филиалов и служб',
  // viewingBranchDrivers: 'Просмотр водителей филиала и служб филиала',
  viewingSparePart: 'Просмотр справочника Запасные части',
  handlingSparePart: 'Редактирование справочника Запасные части',
  viewingCrew: 'Просмотр справочника Бригады',
  handlingCrew: 'Редактирование справочника Бригады',
  viewingAllTrips: 'Просмотр всех путевых листов',
  viewingTripsServiceOnly: 'Просмотр путевых листов службы',
  viewingTripsBranch: 'Просмотр путевых листов филиала и служб филиала',
  handlingTrip: 'Работа с путевыми листами',
  approvingSelfVehiclePlanBudget: 'Утверждение справочников бюджета',
  handlingSelfVehiclePlanBudget:
    'Создание и редактирование справочников бюджета',

  viewingDetailedBudget:
    'Просмотр предложения/уточнения в бюджет по всем филиалам',

  viewingContractVehicleBudget: 'Просмотр Бюджета НТС филиала',
  viewingAllContractVehicleBudgets: 'Просмотр всех бюджетов НТС',
  handlingRegulation: 'Создание Регламента НТС',
  approvingRegulation: 'Утверждение Регламента НТС',
  handlingContractVehicleBudget: 'Создание Бюджета НТС',
  approvingContractVehicleBudget: 'Утверждение Бюджета НТС',
  viewingVehicleTariff: 'Просмотр справочника "Тарифы на НТС"',
  handlingVehicleTariff: 'Заполнение справочника "Тарифы на НТС"',
  viewingBudgetVersion: 'Просмотр справочника "Версии бюджета"',
  handlingBudgetVersion: 'Заполнение справочника "Версии бюджета"',

  // Уведомления
  budgetAnalysisNotification: 'Оповещение по анализу затрат филиала',
  branchBudgetSummaryApprovedNotification:
    'Оповещение при факте согласования/отклонения Свода',
  regulationApprovementNotification:
    'Уведомление об отправке регламента бюджета НТС на утверждение',
  regulationApprovedNotification:
    'Уведомление об утверждении регламента бюджета НТС',
  vehicleTireScheduledReplacementNotification:
    'Уведомление о плановой замене автошины на автомобиле',
  tractorTireScheduledReplacementNotification:
    'Уведомление о плановой замене автошины на тракторе',
  tractorBatteryScheduledReplacementNotification:
    'Уведомление о плановой замене АКБ на ТС',
  gpmExpertiseNotification: 'Уведомление о проведении экспертизы ГПМ',
  maintenanceSafetyEquipmentNotification:
    'Уведомление о проведении ТО приборов безопасности ГПМ',
  tankCalibrationNotification:
    'Уведомление о проведении сертификации средств измерения (тарировка цистерн)',
  vehicleMaintenanceNotification: 'Уведомление о проведении ТО ТС',
  gibddMaintenanceNotification:
    'Уведомление о проведении ТО автомобилей в ГИБДД',
  gtnMaintenanceNotification: 'Уведомление о проведении ТО тракторов в ГТН',
  osagoExpirationNotification: 'Уведомление об окончании действия полиса ОСАГО',

  viewingMaintenances: 'Просмотр Журнала и графика ТО',
  creatingMaintenances: 'Создание карточки работ',
  // viewingAllMaintenances: 'Просмотр всех карточек работ',
  approvingDefectiveStatements:
    'Утверждение предварительной дефектной ведомости',
  approvingDetailedDefectiveStatements:
    'Утверждение детальной дефектной ведомости',
  approvingMaintenances: 'Утверждение карточки работ',
  viewingMaintenanceWorkTypes:
    'Просмотр справочника виды работ по обслуживанию ТС',
  handlingMaintenanceWorkTypes:
    'Редактирование справочника виды работ по обслуживанию ТС',
  viewingCompareExpenseDirections:
    'Просмотр справочника “Сопоставление видов ремонта и направлений затрат” ',
  handlingCompareExpenseDirections:
    'Редактирование справочника “Сопоставление видов ремонта и направлений затрат”',
  viewingMaintenanceMatrix: 'Просмотр “Матрица работ”',
  handlingMaintenanceMatrix: 'Редактирование “Матрица работ”',
  viewingExpenseDirections: 'Просмотр справочника направления расходов',
  handlingExpenseDirections: 'Редактирование справочника направления расходов',
  viewingVehicleIssueGroups: 'Просмотр справочника “Группы дефектов”',
  handlingVehicleIssueGroups: 'Редактирование справочника “Группы дефектов”',
  viewingStockParts: 'Просмотр “Перечень запасных частей” Филиала',
  viewingAllStockParts: 'Просмотр “Перечень запасных частей” всех Филиалов',
  handlingStockParts: 'Редактирование “Перечень запасных частей” Филиала',
  viewingMaintenanceOperationGroups: 'Просмотр справочника “Виды работ”',
  handlingMaintenanceOperationGroups: 'Редактирование справочника “Виды работ”',

  creatingOrder: 'Создание заявки',
  approvingGpmOrder: 'Подтверждение заявки на ГПМ',
  handlingOrder: 'Обработка заявки',
  creatingVehicle: 'Создание ТС',
  approvingVehicle: 'Подтверждение ТС',
  viewingAllVehicles: 'Просмотр всех ТС',
  viewingVehicleServiceOnly: 'Просмотр ТС служб',
  viewingVehicleBranch: 'Просмотр ТС филиалов',
  admin: 'Администрирование системы',
  adminBranch: 'Администрирование филиала',
  viewingReports: 'Просмотр отчетов',
  viewingAllOrders: 'Просмотр всех заявок',
  viewingOrdersServiceOnly: 'Просмотр заявок службы',
  viewingOrdersBranch: 'Просмотр заявок филиала и служб филиала',
  viewingAllShifts: 'Просмотр всех нарядов',
  viewingShiftsServiceOnly: 'Просмотр нарядов службы',
  viewingShiftsBranch: 'Просмотр нарядов филиала и служб филиала',
  gager: 'Проведение контрольных измерений',
  handlingEquipment: 'Создание/редактирование оборудования',
  viewingAllEquipments: 'Просмотр оборудования всех филиалов и служб',
  viewingBranchEquipments: 'Просмотр оборудования филиала и служб филиала',
  viewingSystemJournal: 'Просмотр системного журнала',
  handlingLocation: 'Создание/редактирование объектов',
  viewingAllLocations: 'Просмотр объектов всех филиалов и служб',
  viewingBranchLocations: 'Просмотр объектов филиала и служб филиала',
  handlingFuelCard: 'Создание/редактирование топливных карт',
  viewingAllFuelCards: 'Просмотр топливных карт всех филиалов и служб',
  viewingBranchFuelCards: 'Просмотр топливных карт филиала и служб филиала',
  firstBit: 'Интеграция "Энерготранс"/"КТС"',
  handlingEmployees: 'Создание/редактирование сотрудников',

  // Лимиты
  //    Регламент
  approvingTimeLimits: 'Подтверждение регламента',
  handlingTimeLimits: 'Создание/редактирование регламента',
  viewingTimeLimits: 'Просмотр всех регламентов',
  viewingBranchTimeLimits: 'Просмотр регламента филиала',
  //    Закрепление
  approvingLimitAssignment: 'Утверждение закрепления',
  handlingLimitAssignment: 'Редактирование закрепления',
  updatingDailyBudgets: 'Обновление бюджета',
  viewingLimitAssignment: 'Просмотр закрепления филиала',
  viewingAllLimitAssignment: 'Просмотр всех закреплений',
  //    Планирование год
  approvingYearLimitsPlan: 'Утверждение годового плана',
  handlingYearLimitsPlan: 'Редактирование годового плана',
  viewingBranchYearLimitsPlan: 'Просмотр годового плана филиала',
  viewingYearLimitsPlan: 'Просмотр всех годовых планов',
  //    Планирование месяц
  approvingMonthLimitsPlan: 'Утверждение месячного плана',
  handlingMonthLimitsPlan: 'Создание/редактирование месячного плана',
  viewingBranchMonthLimitsPlan: 'Просмотр месячного плана филиала',
  viewingMonthLimitsPlan: 'Просмотр всех месячных планов',
  // Клеймы 2-го этапа
  handlingMaintenanceServices: 'Редактирование журналов учета работ филиала',
  viewingMaintenanceServices: 'Просмотр журналов учета работ по филиалу',
  viewingAllMaintenanceServices: 'Просмотр всех журналов работ',
  formationOsagoCalculations: 'Формирование расчетов потребностей ОСАГО',
  viewingOsagoCalculation: 'Просмотр расчетов потребностей ОСАГО',
  approvingOsagoCalculation: 'Согласование расчетов потребностей ОСАГО',
  formationWashingPlans: 'Формирование плана моек ТС филиала',
  approvingWashingPlans: 'Согласование плана моек ТС филиала',
  viewingWashingPlans: 'Просмотр плана моек ТС филиала',
  viewingAllWashingPlans: 'Просмотр всех планов моек ТС',
  handlingOsagoMultipliers: 'Редактирование справочника коэффиценты ОСАГО',
  viewingOsagoMultipliers: 'Просмотр коэффициентов ОСАГО',
  handlingWashingVehicleTypes: 'Редактирование справочника типы тс для моек',
  viewingWashingVehicleTypes: 'Просмотр справочника типы тс для моек',
  handlingVehicleWashingTypeToGroupsMaps:
    'Редактирование справочника сопоставление типов тс для моек',
  viewingVehicleWashingTypeToGroupsMap:
    'Просмотр справочника сопоставление типов тс для моек',
  handlingPriceLists: 'Редактирование прейскурантов филиала',
  approvingPriceLists: 'Утверждение прейскурантов филиала',
  viewingPriceLists: 'Просмотр прейскурантов филиала',
  viewingAllPriceLists: 'Просмотр всех прейскурантов филиала',

  viewingJobTitles: 'Просмотр должностей для регламентов Филиала',
  viewingAllJobTitles: 'Просмотр всех должностей для регламентов',
  handlingJobTitles: 'Редактирование должностей для регламентов Филиала',

  viewingContracts: 'Просмотр договоров филиала',
  viewingAllContracts: 'Просмотр договоров всех филиалов',
  handlingContracts: 'Создание договоров',
  approvingContracts: 'Утверждение и отклонение договоров',

  viewingExternalActs: 'Просмотр внешних документов филиала',
  viewingAllExternalActs: 'Просмотр внешних документов всех филиалов',
  handlingExternalActs: 'Создание внешних документов',
  approvingExternalActs: 'Подтверждение внешних документов',

  viewingInternalActs: 'Просмотр внутрених документов филиала',
  viewingAllInternalActs: 'Просмотр внутрених документов всех филиалов',
  handlingInternalActs: 'Создание внутренних документов',
  approvingInternalActs: 'Утверждение внутренних документов',

  viewingBudgetExecutions: 'Просмотр исполнения бюджета по филиалу',
  viewingAllBudgetExecutions: 'Просмотр исполнения бюджета по всем филиалам',

  //уточнения в бюджет
  handlingDetailedBudget: 'Создание сводного предложения/уточнения в бюджет',
  handlingBranchDetailedBudget:
    'Добавление и редактирование предложения/уточнение в бюджет по филиалу',
  viewingDetailedBudget:
    'Просмотр предложения/уточнения в бюджет по всем филиалам',
  approvingBranchDetailedBudget:
    'Утверждение предложения по уточнению бюджета филиала',
  approvingDetailedBudget:
    'Утверждение предложения/уточнения в бюджет по филиалу',

  //виалон
  wialonPage: 'Просмотр страницы /wialon',

  //контрагенты
  viewingCompanies: 'Просмотр контрагентов',
  handlingCompanies: 'Добавление и редактирование контрагентов',

  //подрядчики
  viewingContractors: 'Просмотр подрядчиков',
  handlingContractors: 'Добавление и редактирование подрядчиков',

  // ПДД
  viewingPddTests: 'Прохождение тестирований',
  handlingPddTests: 'Создание тестирований',
  handlingPddQuestions: 'Создание вопросов',
  reloginPddTest: 'Перелогирование и результаты ПДД',
  handlingBranchVehicleTaxPlans:
    'Создание и редактирование Расчетов транспортного налога филиала',
  handlingAllVehicleTaxPlans:
    'Создание и редактирование Расчетов транспортного налога по всем филиалам',
  viewingBranchVehicleTaxPlans:
    'Просмотр расчетов транспортного налога филиала',
  viewingAllVehicleTaxPlans:
    'Просмотр расчетов транспортного налога по всем филиалам',
  getActualMileage: 'Получение фактических пробегов',

  //тарифы
  viewingSideServiceTariff:
    'Просмотр справочника Тарифы для оказания услуг на сторону',
  handlingSideServiceTariff:
    'Добавление/редактирование/удаление cправочника Тарифы для оказания услуг на сторону',

  //учет БОС
  notifBio: 'Оповещение БОС',
  viewingBioTreatmentFacility: 'Просмотр раздела БОС',
  handlingBioTreatmentFacility:
    'Добавление/редактирование/удаление договоров и талонов для БОС',
  approvingBioTreatmentFacility: 'Подтверждение талонов БОС',
  addFactBioTreatmentFacility: 'Внесение факта по талонам БОС',
  editFactBioTreatmentFacility: 'Редактирование факта по талонам БОС',
  expiringCouponsNotification: 'Уведомление о заканчивающихся купонах',
};

export const accessTypeEnum = {
  viewingDashboard: 'viewingDashboard',
  viewingFluidsTickets: 'viewingFluidsTickets',
  handlingFluidsTickets: 'handlingFluidsTickets',
  viewingOilsAndFluidsConsumptionPlan: 'viewingOilsAndFluidsConsumptionPlan',
  handlingOilsAndFluidsConsumptionPlan: 'handlingOilsAndFluidsConsumptionPlan',
  viewingUsersAndRoles: 'viewingUsersAndRoles',
  handlingUsersAndRoles: 'handlingUsersAndRoles',
  viewingBrandsAndVehicleModels: 'viewingBrandsAndVehicleModels',
  handlingBrandsAndVehicleModels: 'handlingBrandsAndVehicleModels',
  viewingOrgUnits: 'viewingOrgUnits',
  handlingOrgUnits: 'handlingOrgUnits',
  viewingTrips: 'viewingTrips',
  viewingSparePart: 'viewingSparePart',
  handlingSparePart: 'handlingSparePart',
  viewingCrew: 'viewingCrew',
  handlingCrew: 'handlingCrew',
  handlingTrip: 'handlingTrip',
  viewingAllTrips: 'viewingAllTrips',
  viewingTripsServiceOnly: 'viewingTripsServiceOnly',
  viewingTripsBranch: 'viewingTripsBranch',
  handlingDriver: 'handlingDriver',
  viewingAllDrivers: 'viewingAllDrivers',
  approvingSelfVehiclePlanBudget: 'approvingSelfVehiclePlanBudget',
  handlingSelfVehiclePlanBudget: 'handlingSelfVehiclePlanBudget',

  viewingDetailedBudget: 'viewingDetailedBudget',

  // viewingBranchDrivers: 'viewingBranchDrivers',

  viewingContractVehicleBudget: 'viewingContractVehicleBudget',
  viewingAllContractVehicleBudgets: 'viewingAllContractVehicleBudgets',
  handlingRegulation: 'handlingRegulation',
  approvingRegulation: 'approvingRegulation',
  handlingContractVehicleBudget: 'handlingContractVehicleBudget',
  approvingContractVehicleBudget: 'approvingContractVehicleBudget',
  viewingVehicleTariff: 'viewingVehicleTariff',
  handlingVehicleTariff: 'handlingVehicleTariff',
  viewingBudgetVersion: 'viewingBudgetVersion',
  handlingBudgetVersion: 'handlingBudgetVersion',

  // Уведомления
  budgetAnalysisNotification: 'budgetAnalysisNotification',
  branchBudgetSummaryApprovedNotification:
    'branchBudgetSummaryApprovedNotification',
  regulationApprovementNotification: 'regulationApprovementNotification',
  regulationApprovedNotification: 'regulationApprovedNotification',
  vehicleTireScheduledReplacementNotification:
    'vehicleTireScheduledReplacementNotification',
  tractorTireScheduledReplacementNotification:
    'tractorTireScheduledReplacementNotification',
  tractorBatteryScheduledReplacementNotification:
    'tractorBatteryScheduledReplacementNotification',
  gpmExpertiseNotification: 'gpmExpertiseNotification',
  maintenanceSafetyEquipmentNotification:
    'maintenanceSafetyEquipmentNotification',
  tankCalibrationNotification: 'tankCalibrationNotification',
  vehicleMaintenanceNotification: 'vehicleMaintenanceNotification',
  gibddMaintenanceNotification: 'gibddMaintenanceNotification',
  gtnMaintenanceNotification: 'gtnMaintenanceNotification',
  osagoExpirationNotification: 'osagoExpirationNotification',

  viewingMaintenances: 'viewingMaintenances',
  creatingMaintenances: 'creatingMaintenances',
  // viewingAllMaintenances: 'viewingAllMaintenances',
  approvingDefectiveStatements: 'approvingDefectiveStatements',
  approvingDetailedDefectiveStatements: 'approvingDetailedDefectiveStatements',
  approvingMaintenances: 'approvingMaintenances',
  viewingMaintenanceWorkTypes: 'viewingMaintenanceWorkTypes',
  handlingMaintenanceWorkTypes: 'handlingMaintenanceWorkTypes',
  viewingCompareExpenseDirections: 'viewingCompareExpenseDirections',
  handlingCompareExpenseDirections: 'handlingCompareExpenseDirections',
  viewingMaintenanceMatrix: 'viewingMaintenanceMatrix',
  handlingMaintenanceMatrix: 'handlingMaintenanceMatrix',
  viewingExpenseDirections: 'viewingExpenseDirections',
  handlingExpenseDirections: 'handlingExpenseDirections',
  viewingVehicleIssueGroups: 'viewingVehicleIssueGroups',
  handlingVehicleIssueGroups: 'handlingVehicleIssueGroups',
  viewingStockParts: 'viewingStockParts',
  viewingAllStockParts: 'viewingAllStockParts',
  handlingStockParts: 'handlingStockParts',
  viewingMaintenanceOperationGroups: 'viewingMaintenanceOperationGroups',
  handlingMaintenanceOperationGroups: 'handlingMaintenanceOperationGroups',

  viewingMaintenanceServices: 'viewingMaintenanceServices',
  viewingAllMaintenanceServices: 'viewingAllMaintenanceServices',
  formationOsagoCalculations: 'formationOsagoCalculations',
  viewingOsagoCalculation: 'viewingOsagoCalculation',
  approvingOsagoCalculation: 'approvingOsagoCalculation',
  formationWashingPlans: 'formationWashingPlans',
  approvingWashingPlans: 'approvingWashingPlans',
  viewingWashingPlans: 'viewingWashingPlans',
  viewingAllWashingPlans: 'viewingAllWashingPlans',
  handlingOsagoMultipliers: 'handlingOsagoMultipliers',
  viewingOsagoMultipliers: 'viewingOsagoMultipliers',
  handlingWashingVehicleTypes: 'handlingWashingVehicleTypes',
  viewingWashingVehicleTypes: 'viewingWashingVehicleTypes',
  handlingVehicleWashingTypeToGroupsMaps:
    'handlingVehicleWashingTypeToGroupsMaps',
  viewingVehicleWashingTypeToGroupsMap: 'viewingVehicleWashingTypeToGroupsMap',
  handlingPriceLists: 'handlingPriceLists',
  approvingPriceLists: 'approvingPriceLists',
  viewingPriceLists: 'viewingPriceLists',
  viewingAllPriceLists: 'viewingAllPriceLists',

  newFeaturesDemo: 'newFeaturesDemo',
  handlingEmployees: 'handlingEmployees',
  creatingOrder: 'creatingOrder',
  approvingGpmOrder: 'approvingGpmOrder',
  handlingOrder: 'handlingOrder',
  creatingVehicle: 'creatingVehicle',
  approvingVehicle: 'approvingVehicle',
  viewingAllVehicles: 'viewingAllVehicles',
  viewingVehicleServiceOnly: 'viewingVehicleServiceOnly',
  viewingVehicleBranch: 'viewingVehicleBranch',
  admin: 'admin',
  adminBranch: 'adminBranch',
  viewingReports: 'viewingReports',
  viewingAllOrders: 'viewingAllOrders',
  viewingOrdersServiceOnly: 'viewingOrdersServiceOnly',
  viewingOrdersBranch: 'viewingOrdersBranch',
  viewingAllShifts: 'viewingAllShifts',
  viewingShiftsServiceOnly: 'viewingShiftsServiceOnly',
  viewingShiftsBranch: 'viewingShiftsBranch',
  gager: 'gager',
  handlingEquipment: 'handlingEquipment',
  viewingAllEquipments: 'viewingAllEquipments',
  viewingBranchEquipments: 'viewingBranchEquipments',
  handlingMaintenanceServices: 'handlingMaintenanceServices',
  viewingSystemJournal: 'viewingSystemJournal',
  handlingLocation: 'handlingLocation',
  viewingAllLocations: 'viewingAllLocations',
  viewingBranchLocations: 'viewingBranchLocations',
  handlingFuelCard: 'handlingFuelCard',
  viewingAllFuelCards: 'viewingAllFuelCards',
  viewingBranchFuelCards: 'viewingBranchFuelCards',
  firstBit: 'firstBit',

  approvingTimeLimits: 'approvingTimeLimits',
  handlingTimeLimits: 'handlingTimeLimits',
  viewingTimeLimits: 'viewingTimeLimits',
  viewingBranchTimeLimits: 'viewingBranchTimeLimits',

  approvingLimitAssignment: 'approvingLimitAssignment',
  handlingLimitAssignment: 'handlingLimitAssignment',
  updatingDailyBudgets: 'updatingDailyBudgets',
  viewingLimitAssignment: 'viewingLimitAssignment',
  viewingAllLimitAssignment: 'viewingAllLimitAssignment',

  approvingYearLimitsPlan: 'approvingYearLimitsPlan',
  handlingYearLimitsPlan: 'handlingYearLimitsPlan',
  viewingBranchYearLimitsPlan: 'viewingBranchYearLimitsPlan',
  viewingYearLimitsPlan: 'viewingYearLimitsPlan',

  approvingMonthLimitsPlan: 'approvingMonthLimitsPlan',
  handlingMonthLimitsPlan: 'handlingMonthLimitsPlan',
  viewingBranchMonthLimitsPlan: 'viewingBranchMonthLimitsPlan',
  viewingMonthLimitsPlan: 'viewingMonthLimitsPlan',

  viewingJobTitles: 'viewingJobTitles',
  viewingAllJobTitles: 'viewingAllJobTitles',
  handlingJobTitles: 'handlingJobTitles',

  viewingContracts: 'viewingContracts',
  viewingAllContracts: 'viewingAllContracts',
  handlingContracts: 'handlingContracts',
  approvingContracts: 'approvingContracts',

  viewingExternalActs: 'viewingExternalActs',
  viewingAllExternalActs: 'viewingAllExternalActs',
  handlingExternalActs: 'handlingExternalActs',
  approvingExternalActs: 'approvingExternalActs',

  viewingInternalActs: 'viewingInternalActs',
  viewingAllInternalActs: 'viewingAllInternalActs',
  handlingInternalActs: 'handlingInternalActs',
  approvingInternalActs: 'approvingInternalActs',

  viewingBudgetExecutions: 'viewingBudgetExecutions',
  viewingAllBudgetExecutions: 'viewingAllBudgetExecutions',

  //уточнения в бюджет
  handlingDetailedBudget: 'handlingDetailedBudget',
  handlingBranchDetailedBudget: 'handlingBranchDetailedBudget',
  viewingDetailedBudget: 'viewingDetailedBudget',
  approvingBranchDetailedBudget: 'approvingBranchDetailedBudget',
  approvingDetailedBudget: 'approvingDetailedBudget',

  //виалон
  wialonPage: 'wialonPage',

  //контрагенты
  viewingCompanies: 'viewingCompanies',
  handlingCompanies: 'handlingCompanies',

  // Подрядчики
  viewingContractors: 'viewingContractors',
  handlingContractors: 'handlingContractors',

  // ПДД
  viewingPddTests: 'viewingPddTests',
  handlingPddTests: 'handlingPddTests',
  handlingPddQuestions: 'handlingPddQuestions',
  reloginPddTest: 'reloginPddTest',
  handlingBranchVehicleTaxPlans: 'handlingBranchVehicleTaxPlans',
  handlingAllVehicleTaxPlans: 'handlingAllVehicleTaxPlans',
  viewingBranchVehicleTaxPlans: 'viewingBranchVehicleTaxPlans',
  viewingAllVehicleTaxPlans: 'viewingAllVehicleTaxPlans',
  getActualMileage: 'getActualMileage',
  //тарифы
  viewingSideServiceTariff: 'viewingSideServiceTariff',
  handlingSideServiceTariff: 'handlingSideServiceTariff',

  //учет БОС
  notifBio: 'notifBio',
  viewingBioTreatmentFacility: 'viewingBioTreatmentFacility',
  handlingBioTreatmentFacility: 'handlingBioTreatmentFacility',
  approvingBioTreatmentFacility: 'approvingBioTreatmentFacility',
  addFactBioTreatmentFacility: 'addFactBioTreatmentFacility',
  editFactBioTreatmentFacility: 'editFactBioTreatmentFacility',
  expiringCouponsNotification: 'expiringCouponsNotification',
};
