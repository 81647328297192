import {
    DriverCard,
    DriverForm,
    DriverList,
    DriverPddTestResult,
} from '../../containers/Driver';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: DriverCard,
        path: '/driverRedirect/',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingPddTests,
        ],
    },
    {
        component: DriverPddTestResult,
        path: '/admin/drivers/:driverId/pddTestResult/:pddTestResultId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDriver,
            accessTypeEnum.viewingAllDrivers,
        ],
    },
    {
        component: DriverList,
        path: '/admin/drivers',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDriver,
            accessTypeEnum.viewingAllDrivers,
        ],
    },
    {
        component: DriverForm,
        path: '/admin/drivers/:driverId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDriver,
        ],
    },
    {
        component: DriverForm,
        path: '/admin/drivers/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDriver,
        ],
    },
    {
        component: DriverCard,
        path: '/admin/drivers/:driverId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDriver,
            accessTypeEnum.viewingAllDrivers,
        ],
    },
];