// @flow
import React, { Component } from 'react';
import qs from 'query-string';
import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {
  EntityStatusType,
  ListState,
  UserAccess
} from './../../../../../lib/types';
import { entityStatus } from './../../../../../lib/enum';
import {
  formatDateTimeToString,
  getListInitialState
} from './../../../../../lib/helpers';
import { regulationApi } from '../../../../../lib/api';

import { Header, Section, TopPanel } from './../../../../../components/layout';
import Table from './../../../../../components/ui/Table';
import Breadcrumbs, {
  Crumb
} from './../../../../../components/layout/Breadcrumbs';

import { withUserAccess } from './../../../../withUserAccess';

import type { WorkPlansFilterParams } from './../components';
import { FilterWorkPlans, MainTabs } from './../components';

import { addAccessRightWorkPlans } from './../accessRight';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};
type State = ListState<any> & {
  filter: WorkPlansFilterParams
};

class RegistrBudgetList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchWorkPlans(page)
    );
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id'
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Дата формирования',
      dataIndex: 'created',
      width: '150',
      render: (created: string) =>
        `${formatDateTimeToString(created, 'DD.MM.YYYY')}`
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: EntityStatusType) =>
        status ? entityStatus[status] : '-'
    },
    {
      title: 'Примечание',
      dataIndex: 'notation'
    }
  ];

  fetchWorkPlans = async (page: number = 1) => {
    try {
      const data = await regulationApi.fetch({ page });
      this.setState({ ...data, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  applyFilter = (filter: WorkPlansFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () => this.fetchWorkPlans(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchWorkPlans(page)
    );
  };

  canAdd = () =>
    this.props.userAccess.some(access =>
      addAccessRightWorkPlans.includes(access)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb>Бюджет</Crumb>
            </Breadcrumbs>
          }
          right={
            this.canAdd() && (
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/budget/contract-vehicle/work-plans/new`)
                }
              >
                Создать
              </Button>
            )
          }
        />
        <TopPanel>
          <h1>Закрепленные НТС</h1>
        </TopPanel>
        <MainTabs />
        <Section>
          <FilterWorkPlans
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchWorkPlans}
            fetchOnMount
            onRow={(row: any) => ({
              onClick: () =>
                navigate(`/budget/contract-vehicle/work-plans/${row.id}/card`)
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(RegistrBudgetList);
