// @flow

import { DeleteOutlined } from '@ant-design/icons';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import { Card } from './../components';
import { Divider } from './../components/layout';
import Grid, { GridItem } from './../components/layout/Grid';
import {
  fuelAndOilGroup,
  fuelAndOilSubType,
  fuelAndOilSubView,
  fuelAndOilType,
  fuelAndOilView
} from '../lib/enum';
import type { FuelAndOil } from './../lib/types';

const { Field } = Card;
const FuelAndOilDivider = styled(GridItem)`
  margin-bottom: 15px;
  margin-top: 15px;
`;

type Props = {
  fuelsAndOils: FuelAndOil[],
  canEdit: boolean,
  onDelete?: Function
};

/**
 * Компонент отображает содержимое списка ГСМ
 */

const FuelsAndOilsList = ({ fuelsAndOils = [], canEdit, onDelete }: Props) => (
  <Grid>
    {fuelsAndOils.map((fuelAndOil: FuelAndOil) => (
      <Fragment key={fuelAndOil.id}>
        <GridItem fullWidth>
          <Grid gutter="16px" cols={10}>
            <GridItem span={canEdit ? 9 : 10}>
              <Grid gutter="16px" cols={4}>
                <GridItem>
                  <Field label="Наименование">{fuelAndOil.name}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Вид">{fuelAndOilView[fuelAndOil.view]}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Подвид">
                    {fuelAndOilSubView[fuelAndOil.subView]}
                  </Field>
                </GridItem>
                {fuelAndOil.type && (
                  <GridItem>
                    <Field label="Тип">{fuelAndOilType[fuelAndOil.type]}</Field>
                  </GridItem>
                )}
                {fuelAndOil.subType && (
                  <GridItem>
                    <Field label="Подтип">
                      {fuelAndOilSubType[fuelAndOil.subType]}
                    </Field>
                  </GridItem>
                )}
                {fuelAndOil.group && (
                  <GridItem>
                    <Field label="Группа">
                      {fuelAndOilGroup[fuelAndOil.group]}
                    </Field>
                  </GridItem>
                )}
                {fuelAndOil.viscosity && (
                  <GridItem>
                    <Field label="Вязкость">{fuelAndOil.viscosity}</Field>
                  </GridItem>
                )}
              </Grid>
            </GridItem>
            {canEdit && (
              <GridItem span={1}>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={onDelete ? () => onDelete(fuelAndOil.id) : null}
                />
              </GridItem>
            )}
          </Grid>
        </GridItem>
        <FuelAndOilDivider fullWidth>
          <Divider />
        </FuelAndOilDivider>
      </Fragment>
    ))}
  </Grid>
);

export default FuelsAndOilsList;
