import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 300;
  color: #677078;
  text-align: center;
  margin: 6px 0 12px 0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

type Props = {
  title: string,
  children?: ReactNode
};

export default (props: Props) => {
  return (
    <Card>
      {props.children}
      <Title>{props.title}</Title>
    </Card>
  );
};
