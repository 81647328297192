import React, { Component } from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import moment from 'moment';

import type {
  ExecutionBudgetGroupedList,
  ListState
} from './../../../lib/types';
import {
  formatDateTimeToString,
  getListInitialState,
  setQueryParams
} from './../../../lib/helpers';

import { Table } from './../../../components/ui';

export type ListTableProps = {
  path?: string,
  location: Location & { state: { page: number } },
  applyFilter: Function,
  cleanFilter: Function,
  cleanFilter: Function
};

type Props = ListTableProps;
type State<T, P> = ListState<T> & {
  filter: P
};

const COLUMNS = [
  {
    title: 'Филиал',
    dataIndex: 'branchName'
  },
  {
    title: '№ документа',
    dataIndex: 'documentNumber'
  },
  {
    title: 'Период проведения',
    dataIndex: 'period',
    render: (period: string) => formatDateTimeToString(period, 'MMMM YYYY')
  },
  {
    title: 'Подрядчик',
    dataIndex: 'contractor'
  },
  {
    title: 'Сумма по плану',
    dataIndex: 'actSum'
  },
  {
    title: 'Сумма по факту',
    dataIndex: 'act.sum'
  },
  {
    title: 'Отклонение',
    dataIndex: '',
    render: (record: ExecutionBudgetGroupedList) =>
      record.actSum - record.factSum
  }
];

export class GroupListTable<T, P> extends Component<Props, State<T, P>> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filter: {}
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(this.props.location.search);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetch(page)
    );
  }

  getDate(period: string) {
    const startDate = moment(period)
      .startOf('month')
      .format();
    const endDate = moment(startDate)
      .endOf('month')
      .format();
    return { startDate, endDate };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.readOnly !== this.props.readOnly) {
      this.fetch();
    }
  }

  fetch(page: number = 1) {
    const { filter } = this.state;
    this.setState({ loading: true });
    return { page, onlyNode: true, ...filter };
  }

  applyFilter = (filter: any) => {
    this.setState({ filter }, () => this.fetch(this.state.page));
  };

  cleanFilter = () => {
    const { nodeId, period } = this.state.filter;
    this.setState({ filter: { nodeId, period } }, () => {
      nodeId && period && setQueryParams({ nodeId, period });
      this.fetch(this.state.page);
    });
  };

  renderFilter(Filter: any) {
    const { filter } = this.state;
    return (
      <Filter
        filter={filter}
        applyFilter={this.applyFilter}
        cleanFilter={this.cleanFilter}
      />
    );
  }

  renderTable(columns = COLUMNS) {
    const { location, path } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    return (
      <Table
        fetch={this.fetch}
        onRow={({ period, branchId }: ExecutionBudgetGroupedList) => ({
          onClick: () =>
            navigate(
              `/budget/execution${
                path === '/' ? '/maintenance' : path
              }/month?period=${period}&nodeId=${branchId}`
            )
        })}
        pagination={{
          page,
          pageSize,
          totalCount,
          location
        }}
        loading={loading}
        columns={columns}
        data={data}
      />
    );
  }
}
