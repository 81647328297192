// @flow
import type { MeasuringDeviceCertificationCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataToServer = (
  measuringDeviceCertificationCost: MeasuringDeviceCertificationCost
) => ({
  ...measuringDeviceCertificationCost,
  cost: convertFromRubToKop(measuringDeviceCertificationCost.cost)
});

export const convertDataFromServer = (
  measuringDeviceCertificationCost: MeasuringDeviceCertificationCost
) => ({
  ...measuringDeviceCertificationCost,
  cost: convertFromKopToRub(measuringDeviceCertificationCost.cost)
});

const measuringDeviceCertificationCost = new CommonApi<MeasuringDeviceCertificationCost>(
  'measuringDeviceCertificationCost',
  'Стоимость тарировки цистерн',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...measuringDeviceCertificationCost
};
