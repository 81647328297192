// @flow
import styled from 'styled-components';

import { Section } from '../../../components/layout';
import Grid from '../../../components/layout/Grid';
import { InputNumber } from '../../../components/inputs';

export const Content = styled.div`
  padding: 16px 0;
`;
export const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;
