import React from 'react';
import Popover from '../../ui/Popover';
import { WarningIcon } from './elements';

/**
 * Предупреждение о том, что ТС
 * не предназначено для поездок в выходные дни
 */
export const NotWeekendVehicleWarning = () => (
  <Popover
    title="Внимание!"
    width={200}
    content={<p>Данное ТС не предназначено для поездок в выходные дни</p>}
  >
    <WarningIcon />
  </Popover>
);

type UsedWeekendsVehicleWarningProps = {
  total: number,
  used: number
};

/**
 * Предупреждение о том, что ТС
 * исчерпала лимит поездок на выходные дни
 */
export const UsedWeekendsVehicleWarning = ({
  total,
  used
}: UsedWeekendsVehicleWarningProps) => (
  <Popover
    title="Внимание!"
    width={200}
    content={
      <p>
        Данное ТС исчерпало лимит поездок на выходные дни ({used} из {total}{' '}
        дней)
      </p>
    }
  >
    <WarningIcon />
  </Popover>
);
