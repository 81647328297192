// @flow

import Button from 'antd/lib/button';

import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { type ComponentType, useState } from 'react';
import { DatePicker } from '../../../../../components/ant/DatePicker';
import type { FormFieldProps } from './../../../../../components/Form';
import Form from './../../../../../components/Form';
import Grid, { GridItem } from './../../../../../components/layout/Grid';

import Selects from './../../../../../components/selects';
import {
  expertiseServiceGpmApi,
  inspectionGibddApi,
  inspectionGtnApi,
  measuringDeviceCertificationApi,
  vehicleApi,
} from './../../../../../lib/api';
import { ownerTypes, serviceTypeEnum, STSEnum } from './../../../../../lib/enum';
import { formatDateTimeToISOString } from './../../../../../lib/helpers';

import type { STSType } from './../../../../../lib/types';

import { gps, measuringDevices, toGibdd, toGpmAndExpertise, toGtn } from './../../lib';

const { VehicleModelSelect, YesNoSelect } = Selects;
type PlannedVehicle = {
  stsType: STSType,
  usingNodeId: number,
  orgUnitId: number,
  vehicleModelId: number,
  // Дата принятия тс в эксплуатацию
  commissioningDate: string,
  // Дата следующего ТО ГИБДД
  inspectionsGibddNextAct: string,
  // Дата следующего ТО ГТН
  inspectionsGtndNextAct: string,
  // Дата следующего ТО ГПМ
  inspectionsGpmNextAct: string,
  // Дата следующего эспертизы ГПМ
  inspectionsGpmExpertisedNextAct: string,
  // Дата следующего Мониторинга ТС
  hasGps: boolean,
  // Дата следующий тарировки
  measuringDeviceCertificationNextAct: string
};
type Props = {
  visible: boolean,
  orgUnitId?: number,
  onCancel: Function,
  addVehicle: Function
};
export default (props: Props) => {
  const { visible, onCancel, orgUnitId, addVehicle } = props;
  const [vehicleType, setVehicleType] = useState(undefined);
  const onSubmit = async (plannedVehicle: PlannedVehicle) => {
    try {
      // $FlowFixMe
      const vehicle = await vehicleApi.addVehicle(plannedVehicle);
      plannedVehicle.inspectionsGibddNextAct &&
        (await inspectionGibddApi.addInspectionGibdd({
          vehicleId: vehicle.id,
          nextActDate: plannedVehicle.inspectionsGibddNextAct
        }));
      plannedVehicle.inspectionsGtndNextAct &&
        (await inspectionGtnApi.addInspectionGtn({
          vehicleId: vehicle.id,
          nextActDate: plannedVehicle.inspectionsGtndNextAct
        }));
      plannedVehicle.inspectionsGpmNextAct &&
        (await expertiseServiceGpmApi.addExpertiseServiceGpm({
          vehicleId: vehicle.id,
          nextActDate: plannedVehicle.inspectionsGpmNextAct,
          services: serviceTypeEnum.maintenance
        }));
      plannedVehicle.inspectionsGpmExpertisedNextAct &&
        (await expertiseServiceGpmApi.addExpertiseServiceGpm({
          vehicleId: vehicle.id,
          nextActDate: plannedVehicle.inspectionsGpmExpertisedNextAct,
          services: serviceTypeEnum.expertise
        }));
      plannedVehicle.measuringDeviceCertificationNextAct &&
        (await measuringDeviceCertificationApi.addMeasuringDeviceCertification({
          vehicleId: vehicle.id,
          nextActDate: plannedVehicle.measuringDeviceCertificationNextAct
        }));

      addVehicle(vehicle);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Добавление нового ТС"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          stsType: STSEnum.forBudget,
          usingNodeId: orgUnitId,
          ownerType: ownerTypes.self,
          orgUnitId
        }}
      >
        {(
          FormField: ComponentType<FormFieldProps>,
          formikProps: FormikProps
        ) => {
          const {
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldValue
          } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px" cols={1}>
                <GridItem>
                  <FormField label="Модель ТС" name="vehicleModelId" required>
                    {({ value, name }) => (
                      <VehicleModelSelect
                        onChange={(vehicleModelId, option) => {
                          setVehicleType(option?.props?.vehicleModel?.type);
                          setFieldValue(name, vehicleModelId);
                        }}
                        value={value}
                      />
                    )}
                  </FormField>
                </GridItem>
                {/*<GridItem>*/}
                {/*  <FormField*/}
                {/*    fast*/}
                {/*    label="Дата принятия ТС в эксплуатацию"*/}
                {/*    name="commissioningDate"*/}
                {/*    required*/}
                {/*  >*/}
                {/*    {({ value, name }) => (*/}
                {/*      <DatePicker*/}
                {/*        format="DD.MM.YYYY"*/}
                {/*        value={value ? moment.utc(value) : value}*/}
                {/*        onChange={(value: Object, dateString: string) => {*/}
                {/*          setFieldValue(*/}
                {/*            name,*/}
                {/*            formatDateTimeToISOString(value, dateString)*/}
                {/*          );*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    )}*/}
                {/*  </FormField>*/}
                {/*</GridItem>*/}

                {toGibdd(vehicleType) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Дата следующего ТО ГИБДД"
                      name="inspectionsGibddNextAct"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {toGtn(vehicleType) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Дата следующего ТО ГТН"
                      name="inspectionsGtndNextAct"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {toGpmAndExpertise(vehicleType) && (
                  <>
                    <GridItem>
                      <FormField
                        fast
                        label="Дата следующего ТО ГПМ"
                        name="inspectionsGpmNextAct"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Дата следующей экспертизы ГПМ"
                        name="inspectionsGpmExpertisedNextAct"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </>
                )}

                {gps(vehicleType) && (
                  <GridItem>
                    <FormField fast label="Мониторинг ТС" name="hasGps">
                      {({ value, name }) => (
                        <YesNoSelect
                          format="DD.MM.YYYY"
                          value={value}
                          onChange={(value: boolean) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {measuringDevices(vehicleType) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Дата следующей тарировки"
                      name="measuringDeviceCertificationNextAct"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                <GridItem fullWidth>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    data-cy="save"
                    htmlType="submit"
                  >
                    Сохранить
                  </Button>
                  <Button
                    style={{ marginLeft: '16px' }}
                    dirty={dirty}
                    onClick={onCancel}
                  >
                    Отменить
                  </Button>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
