// @flow
import type { CalculationStatus, CurrentRepairCalculation } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import { fetchRequest } from './index';

export const convertDataFromServer = (repair: CurrentRepairCalculation) => ({
  ...repair,
  sum: convertFromKopToRub(repair.sum),
  contractMaterialsSum: convertFromKopToRub(repair.contractMaterialsSum),
  internalMaterialsSum: convertFromKopToRub(repair.internalMaterialsSum),
  fullMaterialsSum: convertFromKopToRub(repair.fullMaterialsSum),
  contractOperationsSum: convertFromKopToRub(repair.contractOperationsSum),
  internalOperationsSum: convertFromKopToRub(repair.internalOperationsSum),
  fullOperationsSum: convertFromKopToRub(repair.fullOperationsSum),
  months: repair.months
    ? [
        ...repair.months.map(month => ({
          ...month,
          cost: convertFromKopToRub(month.cost)
        }))
      ]
    : []
});

export const convertDataToServer = (repair: CurrentRepairCalculation) => ({
  ...repair,
  sum: convertFromRubToKop(repair.sum),
  contractMaterialsSum: convertFromRubToKop(repair.contractMaterialsSum),
  internalMaterialsSum: convertFromRubToKop(repair.internalMaterialsSum),
  fullMaterialsSum: convertFromRubToKop(repair.fullMaterialsSum),
  contractOperationsSum: convertFromRubToKop(repair.contractOperationsSum),
  internalOperationsSum: convertFromRubToKop(repair.internalOperationsSum),
  fullOperationsSum: convertFromRubToKop(repair.fullOperationsSum),
  months: repair.months
    ? [
        ...repair.months.map(month => ({
          ...month,
          cost: convertFromRubToKop(month.cost)
        }))
      ]
    : []
});
const basedUrl = 'currentRepairCalculation';
const currentRepairCalculation = new CommonApi<CurrentRepairCalculation>(
  basedUrl,
  'Расчет плана текущего ремонта',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

export default {
  ...currentRepairCalculation,
  changeStatus,
  calculate
};
