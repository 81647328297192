// @flow
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';

import _set from 'lodash/set';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import { selfRegulationsApi } from '../../lib/api';
import { applyMaskToValue } from '../../lib/helpers';
import { selfRegulationStatusesEnum } from '../../lib/enum/selfRegulationStatus';
import {
  type SelfVehicleRegulation,
  type SelfVehicleRegulationVehicle
} from '../../lib/types/selfVehicleRegulation';
import type { UserAccess } from '../../lib/types';

import { Section, SectionContent } from './../../components/layout';
import { Selects, OrgUnitSelect } from './../../components';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import Header from '../../components/layout/Header';
import { Table } from './../../components/ui';
import Grid, { GridItem } from './../../components/layout/Grid';
import { notificationLoading } from '../../components/Notifications';
import { InputNumber } from '../../components/inputs';

import withUserAccess from '../withUserAccess/withUserAccess';

import { canAdd, canApproving } from './accessRight';

const Total = styled.div`
  background: #f0f4f8;
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const TotalTitle = styled.p`
  color: #3d4042;
  text-transform: uppercase;
`;

const TotalValue = styled.p`
  color: #3d4042;
  width: 150px;
  box-sizing: content-box;
  padding: 0 16px;
`;

const TotalValues = styled.div`
  padding-right: 216px;
  display: flex;
`;

const Footer = styled(SectionContent)`
  & > * {
    margin-right: 16px;
  }
`;

const { VehicleModelSelect, VehicleSelect } = Selects;

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type Filters = {
  'vehicle.usingNodeId'?: number,
  'vehicle.id'?: number,
  'vehicle.vehicleModel.id'?: number
};

export const SelfRegulationsEditForm = withUserAccess(
  ({ id, userAccess }: Props) => {
    const [regulation, setRegulation] = useState<$Shape<SelfVehicleRegulation>>(
      {
        selfVehicleRegulationVehicles: []
      }
    );
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState<Filters>({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [values, setValues] = useState({});
    const [dirty, setDirty] = useState(false);

    const rowSelection = useMemo(
      () => ({
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRows(selectedRows);
        },
        getCheckboxProps: record => ({
          name: record.name
        }),
        selectedRowKeys: selectedRows.map(({ key }) => key)
      }),
      [selectedRows]
    );

    const isSelected = useCallback((id: number) => !!selectedRows.find(v => v.id === id), [selectedRows]);

    const saveRegulation = useCallback(async () => {
      try {
        setLoading(true);
        notificationLoading({
          key: 'loading',
          message: 'Сохранение регламента...'
        });
        const { selfVehicleRegulationVehicles } = regulation;
        regulation.selfVehicleRegulationVehicles = selfVehicleRegulationVehicles.map(
          v => {
            v.kilometrage = values[v.id];
            v.yearKilometrage = values[v.id] * regulation.workingDaysCount;
            v.isNeeded = isSelected(v.id);
            return v;
          }
        );
        await selfRegulationsApi.update(regulation);
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message
        });
      } finally {
        notification.close('loading');
        setLoading(false);
      }
    }, [regulation, values, isSelected]);

    async function fetchRegulation(id: number) {
      try {
        const result: SelfVehicleRegulation = await selfRegulationsApi.get(id);
        result.selfVehicleRegulationVehicles = result.selfVehicleRegulationVehicles.map(
          vehicle => ({
            ...vehicle,
            key: vehicle.id
          })
        );
        setRegulation(result);
        if (result.selfVehicleRegulationVehicles) {
          const values = result.selfVehicleRegulationVehicles.reduce(
            (acc, cur) => ({ ...acc, [cur.id]: cur.kilometrage }),
            {}
          );
          setValues(values);
          setSelectedRows((result.selfVehicleRegulationVehicles: any));
        }
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message
        });
      }
    }

    useEffect(() => {
      fetchRegulation(id);
    }, [id]);

    const handleChange = useCallback(
      (key: string, value: any) => {
        const dt = _cloneDeep(values);
        _set(dt, key, value);
        setValues(dt);
        setDirty(true);
      },
      [values]
    );

    const totalValue = useMemo(
      () =>
        Object.keys(values).reduce((acc, key) => acc + (values[key] || 0), 0),
      [values]
    );

    const hasErrors = useMemo(() => selectedRows.some(v => !values[v.id]), [
      selectedRows,
      values
    ]);

    const filterValues = useCallback(
      value => {
        const entries = Object.entries(filters).filter(
          ([key, value]) => !!value
        );

        return Array.isArray(entries)
          ? entries.every(
              ([key, filterValue]) => _get(value, key) === filterValue
            )
          : true;
      },
      [filters]
    );

    const changeFilter = useCallback(
      (name: string, value: any) => {
        setFilters({
          ...filters,
          [name]: value
        });
      },
      [filters]
    );

    const vehicles = useMemo(() => {
      return regulation.selfVehicleRegulationVehicles.filter(filterValues);
    }, [filterValues, regulation.selfVehicleRegulationVehicles]);

    const handleCancel = useCallback(() => navigate(`/self-regulations`), []);

    const handleApprove = useCallback(async () => {
      try {
        await selfRegulationsApi.changeStatus(
          id,
          selfRegulationStatusesEnum.approved
        );
        setRegulation({
          ...regulation,
          status: selfRegulationStatusesEnum.approved
        });
        notification.success({
          message: 'Регламент успешно утвержден'
        });
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message
        });
      }
    }, [id, regulation]);

    const isDraft = regulation.status === selfRegulationStatusesEnum.created;

    return (
      <>
        <Header
          left={<h1>Регламент использования ТС на {regulation.year} год</h1>}
        />
        <Section>
          <SectionContent style={{ position: 'relative' }}>
            <Grid cols={3} gutter="16px" style={{ marginBottom: 16 }}>
              <GridItem>
                <VehicleModelSelect
                  value={filters['vehicle.vehicleModel.id']}
                  onChange={id => changeFilter('vehicle.vehicleModel.id', id)}
                />
              </GridItem>
              <GridItem>
                <VehicleSelect
                  placeholder="Гос. номер"
                  value={filters['vehicle.id']}
                  onChange={id => changeFilter('vehicle.id', id)}
                />
              </GridItem>
              <GridItem>
                <OrgUnitSelect
                  nodeId={filters['vehicle.usingNodeId']}
                  onChange={id => changeFilter('vehicle.usingNodeId', id)}
                />
              </GridItem>
            </Grid>
            <Grid>
              <GridItem fullWidth>
                <Table
                  columns={[
                    {
                      dataIndex: 'vehicle.vehicleModel.name',
                      title: 'Модель ТС',
                      render: (
                        value: string,
                        record: SelfVehicleRegulationVehicle
                      ) =>
                        [record.vehicle.vehicleModel.brandName, value].join(' ')
                    },
                    {
                      dataIndex: 'vehicle.licensePlate',
                      title: 'Гос.номер',
                      render: (value: string) =>
                        applyMaskToValue(value, formatLicensePlateMask)
                    },
                    {
                      dataIndex: 'id',
                      width: '150px',
                      title: 'Пробег в день, км',
                      render: (id: number) => {
                        const selected = isSelected(id);
                        return (
                          <Form.Item
                            validateStatus={
                              selected && dirty && !values[id] && 'error'
                            }
                            help={
                              selected &&
                              dirty &&
                              !values[id] &&
                              'Обязательное поле'
                            }
                          >
                            <InputNumber
                              disabled={!selected || !isDraft}
                              style={{ width: '100%' }}
                              value={values[id]}
                              placeholder="км"
                              onChange={value => handleChange(`${id}`, value)}
                            />
                          </Form.Item>
                        );
                      }
                    },
                    {
                      dataIndex: 'id',
                      key: 'year',
                      width: '150px',
                      title: 'Пробег в год, км',
                      render: (id: number) => {
                        return values[id]
                          ? `${values[id] * regulation.workingDaysCount} км`
                          : 0;
                      }
                    },
                    {
                      dataIndex: 'vehicle.usingNodeName',
                      title: 'Подразделение использования',
                      width: '200px',
                      breakByWidth: true
                    }
                  ]}
                  rowSelection={isDraft ? rowSelection : undefined}
                  data={vehicles}
                />
              </GridItem>
            </Grid>
            <Total>
              <TotalTitle>Итого:</TotalTitle>
              <TotalValues>
                <TotalValue>{totalValue} км</TotalValue>
                <TotalValue>
                  {totalValue * regulation.workingDaysCount} км
                </TotalValue>
              </TotalValues>
            </Total>
          </SectionContent>
        </Section>
        {isDraft && (canAdd(userAccess) || canApproving(userAccess)) && (
          <Section>
            <Footer>
              {canAdd(userAccess) && (
                <Button
                  type="primary"
                  onClick={saveRegulation}
                  loading={loading}
                  disabled={hasErrors || loading}
                >
                  Сохранить
                </Button>
              )}
              {canApproving(userAccess) && (
                <Button type="primary" onClick={handleApprove}>
                  Утвердить
                </Button>
              )}
              <Button onClick={handleCancel}>Отменить</Button>
            </Footer>
          </Section>
        )}
      </>
    );
  }
);

export default SelfRegulationsEditForm;
