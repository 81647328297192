// @flow
import React from 'react';
import moment from 'moment';
import notification from 'antd/lib/notification';

import type {
  ReportBusinessTrip,
  ReportBusinessTripParams,
} from '../../../lib/types/reportBusinessTrips';
import reportBusinessTripsApi from '../../../lib/api/reportBusinessTrips';
import { applyMaskToValue } from '../../../lib/helpers';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { notificationLoading } from '../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

import Filter from './components/Filter';

const initialParams: ReportBusinessTripParams = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  params: any,
  data: ReportBusinessTrip[],
  loading: boolean,
  scrollY: number,
};

/**
 * Реестр путевых листов по автотранспортным услугам
 */
export class BusinessTripList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    await this.fetchReport();
  }

  fetchReport = async (params: ReportBusinessTripParams = initialParams) => {
    try {
      this.setState({ loading: true });
      const data = await reportBusinessTripsApi.fetchReportBusinessTrips(
        params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  fetchData = async () => {
    const {
      startDate,
      endDate,
      firstName,
      lastName,
      middleName,
      mnemocode,
      personNumber,
      businessTripOrderNumber,
    } = this.state.params;
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'req',
      });
      await reportBusinessTripsApi.fetchData({
        startDate,
        endDate,
        firstName,
        lastName,
        middleName,
        mnemocode,
        personNumber,
        businessTripOrderNumber,
      });
      notification.success({ message: 'Данные успешно запрошены!' });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('req');
    }
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  applyParams = async () => await this.fetchReport(this.state.params);

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print',
      });
      await reportBusinessTripsApi.printReportBusinessTrips(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: '№',
      dataIndex: 'tripNumber',
      width: 100,
    },
    {
      title: 'Дата путевого листа',
      dataIndex: 'date',
      width: 100,
      render: (date: string) => moment.utc(date).format('DD.MM.YYYY'),
    },
    {
      title: 'Марка',
      dataIndex: 'vehicleModel',
      width: 100,
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      width: 100,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Время работы всего',
      dataIndex: 'workingTime',
      width: 100,
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      width: 100,
      render: (kilometrage: number) => `${kilometrage} км`,
    },
    {
      title: 'Маршрут следования',
      dataIndex: 'route',
      width: 100,
    },
    {
      title: 'Кол-во дней командировки',
      dataIndex: 'daysCount',
      width: 100,
    },
    {
      title: 'ФИО, ответственного за ТС',
      dataIndex: 'employee',
      width: 100,
    },
    {
      title: 'Номер приказа о командировке',
      dataIndex: 'orderRequisites',
      width: 100,
    },
    {
      title: 'Номер заявки на командировку',
      dataIndex: 'orderNumber',
      width: 100,
    },
  ];

  render() {
    const { loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Командировки</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            fetchData={this.fetchData}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={this.state.data}
            columns={this.columns}
            name="ReportsBusinessTrip"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1100,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default BusinessTripList;
