// @flow

import React from 'react';

import notification from 'antd/lib/notification';

import { maintenanceApi } from './../../../../../lib/api';
import type { Maintenance } from './../../../../../lib/types';

import Filter from './../../../../Maintenance/components/Filter';
import { MAINTENANCE_COLUMNS } from './../../../../Maintenance';

import { ListTable } from './../../../lib';

export default class extends ListTable<Maintenance> {
  fetch = async (page: number = 1) => {
    try {
      const params = super.fetch(page);
      const data = await maintenanceApi.fetchMaintenances(params);
      const totalSum = await maintenanceApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(MAINTENANCE_COLUMNS)}
      </>
    );
  }
}
