/* @flow */

import { Link, navigate } from '@reach/router';
import Button from 'antd/lib/button/button';

import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../components/ant/DatePicker';
import { Header, Section } from '../../../components/layout';

import { notificationLoading } from '../../../components/Notifications';
import { Popconfirm, Icon, Operations, Table } from '../../../components/ui';
import type { FetchListParams } from '../../../lib/api';
import { osagoMultiplier } from '../../../lib/api';

import {
  formatDateTimeToISOString,
  formatDateTimeToString,
  getListInitialState,
  setQueryParams,
} from '../../../lib/helpers';

import { HeaderTabs } from '../components';
import type {
  ListState,
  OsagoBaseRateMultiplier,
  UserAccess,
} from './../../../lib/types';

import { withUserAccess } from './../../withUserAccess';
import canAddOsagoMultiplier from './../accessRight';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};
type State = ListState<OsagoBaseRateMultiplier> & {
  // Хранится значение для проверки пересечения до создания документа
  startDate: moment,
};

class BaseRateMultiplierList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    startDate: moment.utc(new Date()).startOf('day'),
  };

  async componentDidMount() {
    this.fetchOsagoBaseRateMultiplier();
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        align: 'right',
        stopPropagation: true,
        width: 50,
        render: (record) => (
          <Operations>
            <Popconfirm
              overlayStyle={{
                zIndex: 2000,
              }}
              placement="left"
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () =>
                await this.deleteOsagoBaseRateMultiplier(record.id)
              }
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ),
      });
    }
  }

  fetchOsagoBaseRateMultiplier = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    this.setState({ loading: true });
    const { data, totalCount } =
      await osagoMultiplier.fetchOsagoBaseRateMultiplier({
        page,
        ...params,
      });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  deleteOsagoBaseRateMultiplier = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await osagoMultiplier.deleteOsagoBaseRateMultiplier(id);
      await this.fetchOsagoBaseRateMultiplier(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50,
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      sorter: true,
      width: '130px',
      render: (startDate: string) =>
        formatDateTimeToString(startDate, 'DD.MM.YYYY'),
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      sorter: true,
      width: '130px',
      render: (endDate: string) =>
        formatDateTimeToString(endDate, 'DD.MM.YYYY'),
    },
    {
      title: '"B", "BE"',
      render: (record: OsagoBaseRateMultiplier) => {
        return `${record.bCur}(тек) ${record.bMin}(min) ${record.bMax}(max)`;
      },
    },
    {
      title: '"C" и "CE" 16 т. и менее',
      render: (record: OsagoBaseRateMultiplier) => {
        return `${record.cMax16Cur}(тек) ${record.cMax16Min}(min) ${record.cMax16Max}(max)`;
      },
    },
    {
      title: '"C" и "CE" более 16 т.',
      render: (record: OsagoBaseRateMultiplier) => {
        return `${record.cMin16Cur}(тек) ${record.cMin16Min}(min) ${record.cMin16Max}(max)`;
      },
    },
    {
      title: '"D" и "DE" 16 мест и менее',
      render: (record: OsagoBaseRateMultiplier) => {
        return `${record.dMax16Cur}(тек) ${record.dMax16Min}(min) ${record.dMax16Max}(max)`;
      },
    },
    {
      title: '"D" и "DE" более 16 мест',
      render: (record: OsagoBaseRateMultiplier) => {
        return `${record.dMin16Cur}(тек) ${record.dMin16Min}(min) ${record.dMin16Max}(max)`;
      },
    },
    {
      title: 'Трактора и др.',
      render: (record: OsagoBaseRateMultiplier) => {
        return `${record.otherCur}(тек) ${record.otherMin}(min) ${record.otherMax}(max)`;
      },
    },
  ];

  canAdd = () =>
    this.props.userAccess.some((access) =>
      canAddOsagoMultiplier.includes(access)
    );

  onConfirmCheck = async () => {
    try {
      const fetchParams = {
        startDate: formatDateTimeToISOString({}, moment(this.state.startDate)),
      };
      const res = await osagoMultiplier.fetchDateIntersectionMultiplier(
        'baseRate',
        fetchParams
      );
      if (!res) {
        navigate(`/admin/osago-multiplier/base-rate/new`, {
          state: fetchParams,
        });
      } else {
        notification.warning({
          message: 'Ошибка',
          description: 'Выбранная дата уже входит в существующий диапазон!',
        });
      }
    } catch (error) {
      notification.warning({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, startDate } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Коэффициенты ОСАГО базовая ставка</h1>}
          right={
            canAdd && (
              <Popconfirm
                title={
                  <>
                    <p>Дата начала действия</p>
                    <DatePicker
                      format="DD.MM.YYYY"
                      value={startDate}
                      onChange={(value: Object, dateString: string) => {
                        this.setState({
                          startDate: value,
                        });
                      }}
                    />
                  </>
                }
                onConfirm={this.onConfirmCheck}
              >
                <Link to="/admin/osago-multiplier/base-rate/new">
                  <Button type="primary">Создать</Button>
                </Link>
              </Popconfirm>
            )
          }
        />
        <HeaderTabs selectedTab="base" />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () =>
                canAdd
                  ? navigate(
                      `/admin/osago-multiplier/base-rate/edit/${record.id}`
                    )
                  : null,
            })}
            columns={this.columns}
            fetch={this.fetchOsagoBaseRateMultiplier}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(BaseRateMultiplierList);
