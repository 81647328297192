// @flow
import React from 'react';
import styled from 'styled-components';

import type { ServiceType, EntityStatusType } from './../../../../lib/types';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from './../../../../components';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import Field from './../../../../components/card/Field';

const { EntityStatusSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ActFilterParams = {
  'stage.contract.services'?: ServiceType,
  status?: EntityStatusType,
  'stage.contract.contractorId'?: number,
  startDate?: string,
  endDate?: string
};

type Props = {
  filter: ActFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<ActFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>

            {/* <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value =>
                    changeValue('stage.contract.contractorId', value)
                  }
                  value={values['stage.contract.contractorId']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem> */}

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
