// @flow
import Button from 'antd/lib/button';

import Input from 'antd/lib/input';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';
import CancelButton from './../../../../components/CancelButton';

import type { FormFieldType } from './../../../../components/Form';
import Form from './../../../../components/Form';
import { Section, SectionTitle } from './../../../../components/layout';
import Grid, { GridItem } from './../../../../components/layout/Grid';

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  padding: 16px;
`;

type Props = {
  onCancel: Function,
  onSubmit: Function
};

export default ({ onCancel, onSubmit }: Props) => {
  return (
    <Form initialValues={null} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => {
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionTitle divider>Данные документа</SectionTitle>
              <Content>
                <Grid gutter="16px" cols={2}>
                  <GridItem>
                    <FormField
                      label="Номер документа"
                      name="documentNumber"
                      required
                    >
                      {({ name, value }) => {
                        return (
                          <Input
                            placeholder="Введите номер"
                            name={name}
                            value={value}
                            onChange={e => {
                              setFieldValue(name, e.target.value);
                            }}
                          />
                        );
                      }}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="documentDate"
                      label="Дата составления акта"
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата начала работ"
                      fast
                      name="startDate"
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата окончания работ"
                      name="endDate"
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          disabled={values.startDate ? false : true}
                          disabledDate={(date: string) =>
                            values.startDate
                              ? moment
                                  .utc(date)
                                  .startOf('day')
                                  .isSameOrBefore(moment.utc(values.startDate))
                              : false
                          }
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {values.id ? 'Сохранить' : 'Создать'}
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
