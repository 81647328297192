// @flow
import React, { useState } from 'react';
import Input from 'antd/lib/input';

import type {
  FormErrors,
  RegulationLimit,
  RegulationLimitGroup,
  VehicleOvbType,
} from '../../../../../../../lib/types';
import { validateRegulationLimit } from '../lib';
import { DeleteCell, DeleteIcon, FormItem, getGridTemplate } from './elements';
import { Selects } from './../../../../../../../components';
import {
  regulationLimitTypeEnum,
  vehicleOvbTypes,
} from '../../../../../../../lib/enum';
import OrgUnitSelect from './../../../../../../../components/OrgUnitSelect';
import { Popconfirm } from './../../../../../../../components/ui';

import { InputNumber } from '../../../../../../../components/inputs';
import { formatLimitValue } from '../../../lib';
import { Cell, Row } from '../../../elements';
import { convertJobTitleToString } from '../../../../../../../lib/helpers';

const { EmployeePositionSelect, VehicleOvbTypeSelect } = Selects;

type Props = {
  regulationLimit: RegulationLimit,
  // Функция изменения значения
  onChange: Function,
  // Функция удаления значения
  onDelete: Function,
  // Индекс строки
  index: number,
  // Является ли эта строка пустой
  isEmptyRow: boolean,
  // Является ли редактируемым
  editable: boolean,
  // Группа, к которой относится лимит
  group: RegulationLimitGroup,
};

export default ({
  regulationLimit = {},
  onChange,
  onDelete,
  index,
  isEmptyRow,
  editable,
  group,
}: Props) => {
  const [errors, setErrors] = useState<FormErrors<RegulationLimit>>({});

  const validate = (regulationLimit: RegulationLimit) => {
    // Если это не пустая строка, то валидируем
    if (!isEmptyRow) {
      const errors = validateRegulationLimit(regulationLimit, group);
      setErrors(errors);
    }
  };

  const handleChangeValue = (value: $Shape<RegulationLimit>) => {
    const copy = { ...regulationLimit, ...value };
    validate(copy);
    onChange(copy, index);
  };

  return (
    <Row style={{ padding: 0 }} media={getGridTemplate(regulationLimit.type)}>
      {regulationLimit.type === regulationLimitTypeEnum.employee && (
        <Cell style={{ overflow: 'hidden' }}>
          {editable ? (
            <FormItem
              name="employeePositionTitle"
              validateStatus={errors.jobTitleId && 'error'}
              help={errors.jobTitleId}
              hasFeedback
            >
              <EmployeePositionSelect
                branchId={regulationLimit.orgUnitId}
                size="small"
                value={regulationLimit.employeePositionTitle}
                onChange={(employeePositionTitle) =>
                  handleChangeValue({
                    employeePositionTitle,
                  })
                }
              />
            </FormItem>
          ) : (
            convertJobTitleToString(regulationLimit.jobTitle)
          )}
        </Cell>
      )}
      {regulationLimit.type === regulationLimitTypeEnum.ovb && (
        <>
          <Cell>
            {editable ? (
              <FormItem
                name="ovbName"
                validateStatus={errors.ovbName && 'error'}
                help={errors.ovbName}
                hasFeedback
              >
                <Input
                  value={regulationLimit.ovbName}
                  size="small"
                  placeholder="Наименование ОВБ"
                  onChange={({ target: { value: ovbName } }) =>
                    handleChangeValue({
                      ovbName,
                    })
                  }
                />
              </FormItem>
            ) : (
              regulationLimit.ovbName
            )}
          </Cell>
          <Cell style={{ overflow: 'hidden' }}>
            {editable ? (
              <FormItem
                name="ovbType"
                validateStatus={errors.ovbType && 'error'}
                help={errors.ovbType}
                hasFeedback
              >
                <VehicleOvbTypeSelect
                  size="small"
                  value={regulationLimit.ovbType}
                  placeholder="Выберите тип ОВБ"
                  onChange={(ovbType) => {
                    const getHours = (ovbType: VehicleOvbType) => {
                      switch (ovbType) {
                        case 'forEightHour':
                          return 8;
                        case 'forTwelveHour':
                          return 12;
                        case 'forWholeDay':
                        default:
                          return 24;
                      }
                    };
                    const hours = getHours(ovbType);
                    handleChangeValue({
                      ovbType,
                      hours,
                      hoursWeekend: hours,
                    });
                  }}
                />
              </FormItem>
            ) : (
              vehicleOvbTypes[regulationLimit.ovbType]
            )}
          </Cell>
        </>
      )}
      {regulationLimit.type !== regulationLimitTypeEnum.employee && (
        <Cell style={{ overflow: 'hidden' }}>
          {editable ? (
            <FormItem
              name="orgUnitId"
              validateStatus={errors.orgUnitId && 'error'}
              help={errors.orgUnitId}
            >
              <OrgUnitSelect
                size="small"
                value={regulationLimit.orgUnitId}
                placeholder="Выберите службу"
                onChange={(orgUnitId) =>
                  handleChangeValue({
                    orgUnitId,
                  })
                }
              />
            </FormItem>
          ) : (
            regulationLimit.orgUnitName
          )}
        </Cell>
      )}
      <Cell>
        {editable ? (
          <FormItem
            name="hours"
            validateStatus={errors.hours && 'error'}
            help={errors.hours}
          >
            <InputNumber
              max={group.hours}
              size="small"
              placeholder="Часы"
              value={regulationLimit.hours}
              onBlur={() => validate(regulationLimit)}
              disabled={regulationLimit.type === regulationLimitTypeEnum.ovb}
              onChange={(hours) => {
                handleChangeValue({
                  hours,
                });
              }}
              min={1}
            />
          </FormItem>
        ) : (
          formatLimitValue('hours', regulationLimit.hours)
        )}
      </Cell>
      {regulationLimit.type !== regulationLimitTypeEnum.ovb && (
        <Cell>
          {editable ? (
            <FormItem
              name="hoursWeekend"
              validateStatus={errors.hoursWeekend && 'error'}
              help={errors.hoursWeekend}
            >
              <InputNumber
                disabled={!group.usedInWeekends}
                placeholder="Часы"
                max={group.hoursWeekend}
                size="small"
                value={regulationLimit.hoursWeekend}
                onBlur={() => validate(regulationLimit)}
                onChange={(hoursWeekend) =>
                  handleChangeValue({
                    hoursWeekend,
                  })
                }
                min={1}
              />
            </FormItem>
          ) : (
            formatLimitValue('hoursWeekend', regulationLimit.hoursWeekend)
          )}
        </Cell>
      )}
      <Cell>
        {editable ? (
          <FormItem
            name="distance"
            validateStatus={errors.distance && 'error'}
            help={errors.distance}
          >
            <InputNumber
              placeholder="Км"
              size="small"
              value={regulationLimit.distance}
              onBlur={() => validate(regulationLimit)}
              onChange={(value) => {
                handleChangeValue({
                  distance: value,
                  distanceWeekend:
                    regulationLimit.type === regulationLimitTypeEnum.ovb
                      ? value
                      : regulationLimit.distanceWeekend,
                });
              }}
              min={1}
            />
          </FormItem>
        ) : (
          formatLimitValue('distance', regulationLimit.distance)
        )}
      </Cell>
      {regulationLimit.type !== regulationLimitTypeEnum.ovb && (
        <Cell>
          {editable ? (
            <FormItem
              name="distanceWeekend"
              validateStatus={errors.distanceWeekend && 'error'}
              help={errors.distanceWeekend}
            >
              <InputNumber
                placeholder="Км"
                size="small"
                disabled={!group.usedInWeekends}
                value={regulationLimit.distanceWeekend}
                onBlur={() => validate(regulationLimit)}
                min={1}
                onChange={(distanceWeekend) =>
                  handleChangeValue({
                    distanceWeekend,
                  })
                }
              />
            </FormItem>
          ) : (
            formatLimitValue('distanceWeekend', regulationLimit.distanceWeekend)
          )}
        </Cell>
      )}
      {editable && (
        <DeleteCell>
          {!isEmptyRow &&
            (regulationLimit.id ? (
              <Popconfirm
                onConfirm={async () => await onDelete(index)}
                title="Вы уверены, что хотите удалить лимит?"
              >
                <DeleteIcon />
              </Popconfirm>
            ) : (
              <DeleteIcon onClick={async () => await onDelete(index)} />
            ))}
        </DeleteCell>
      )}
    </Row>
  );
};
