import React, { useEffect, useState } from 'react';
import Table from '../../../components/ui/Table';

import { getListInitialState } from '../../../lib/helpers';
import { bioTreatmentFacilityTalonApi } from '../../../lib/api';
import Filter from './Filter';
import Checkbox from 'antd/lib/checkbox/Checkbox';

type FilterTableProps = any;
export const BioTreatmentFacilityTalonFilterTable = ({
  filter,
  applyFilter,
  cleanFilter,
  data,
  fetch,
  loading,
  page,
  pageSize,
  totalCount,
  location,
  onRow,
  rowClassName,
  onChangeCheckbox,
  selected,
}: FilterTableProps) => {
  const columns = [
    {
      title: '',
      key: 'checkbox',
      dataIndex: 'id',
      render: (id, talon) => {
        return (
          <Checkbox
            checked={id === selected}
            onChange={(value) => {
              onChangeCheckbox && onChangeCheckbox(id, value, talon);
            }}
          />
        );
      },
    },
    {
      title: 'Контрагент',
      key: 'company',
      dataIndex:
        'bioTreatmentFacilityVolume.bioTreatmentFacilityContract.company.name',
    },
    {
      title: 'Номер договора',
      key: 'contractNumber',
      dataIndex:
        'bioTreatmentFacilityVolume.bioTreatmentFacilityContract.contractNumber',
    },
    {
      title: 'Гос.номер',
      key: 'licensePlate',
      dataIndex: 'bioTreatmentFacilityVolume.licensePlates',
      render: (licensePlates: string[]) => {
        return licensePlates.join(', ');
      },
    },
    {
      title: 'Объемы',
      key: 'volume',
      dataIndex: 'bioTreatmentFacilityVolume.volume',
    },
    {
      title: 'Остаток талонов',
      key: 'leftoverCoupons',
      dataIndex: 'leftoverCoupons',
    },
  ];
  return (
    <>
      <Filter
        filter={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
      />
      <Table
        rowClassName={rowClassName}
        onRow={onRow}
        columns={columns}
        data={data}
        fetch={fetch}
        fetchOnMount
        loading={loading}
        pagination={{
          disableSetQueryParamPage: true,
          page,
          pageSize,
          totalCount,
          location,
        }}
      />
    </>
  );
};
export default BioTreatmentFacilityTalonFilterTable;
