// @flow

import type { TireMeasurement } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addTireMeasurement = async (
  measurement: TireMeasurement
): Promise<TireMeasurement> => {
  const added = await fetchRequest.post('/tireMeasurement', measurement);
  if (added) return added;
  throw new Error('Не удалось создать замер толпива');
};

export const fetchTireMeasurements = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<TireMeasurement>> =>
  await fetchRequest.get('/TireMeasurement', {
    ...initialFetchListParams,
    ...params
  });

export const fetchTireMeasurement = async (
  id: number
): Promise<TireMeasurement> => {
  const measurement = await fetchRequest.get(`/tireMeasurement/${id}`);
  if (measurement) return measurement;
  throw new Error('Не удалось замер высоты проектора шины');
};

export const fetchTireMeasurementsByTireId = async (
  tireId: number
): Promise<TireMeasurement[]> => {
  const result = await fetchRequest.get('/tireMeasurement', { tireId });
  if (result) {
    return result.data || [];
  } else {
    throw new Error('Не удалось получить замеры высоты протектора шины');
  }
};

export default {
  addTireMeasurement,
  fetchTireMeasurements,
  fetchTireMeasurement,
  fetchTireMeasurementsByTireId
};
