// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportTaxedTrip,
  ReportTaxedTripsParams,
} from '../types/reportTaxedTrips';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportTaxedTrip) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage),
});

/**
 * Получение отчета
 */
export const fetchReportTaxedTrips = async (
  params: ReportTaxedTripsParams
): Promise<ReportTaxedTrip[]> => {
  const report = await fetchRequest.get('/report/taxed-trips', {
    ...params,
  });
  return report.map(convertFromServer);
};

export const printReportTaxedTrips = async (
  params: ReportTaxedTripsParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/taxed-trips/print',
    {
      ...params,
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportTaxedTrips,
  printReportTaxedTrips,
};
