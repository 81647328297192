import {
    BusinessCalendar,
    BusinessDayForm,
} from '../../containers/BusinessCalendar';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BusinessCalendar,
        path: '/admin/business-calendar',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BusinessDayForm,
        path: '/admin/business-calendar/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BusinessDayForm,
        path: '/admin/business-calendar/:dayId',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];