// @flow
import { navigate } from '@reach/router';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { MonthPicker } from '../../../components/ant/DatePicker';
import CancelButton from '../../../components/CancelButton';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';
import { ButtonsRow } from '../../../components/ui';
import { oilsAndFluidsConsumptionPlanApi } from '../../../lib/api';
import { accessTypeEnum, technicalFluidStatusEnum } from '../../../lib/enum';
import { changeNotification } from '../../../lib/notificationWrapper';
import type { OilsAndFluidsConsumptionPlan, UserAccess, Vehicle } from '../../../lib/types';
import { withUserAccess } from '../../withUserAccess';

import { Panel, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { notificationLoading } from './../../../components/Notifications';
import { formatDateTimeToString, goBack } from './../../../lib/helpers';
import OilsAndFluidsConsumptionList from './components/OilsAndFluidsConsumptionList';

const Content = styled.div`
  padding: 16px 0;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;

type FormProps = {
  userAccess: UserAccess[],
  onSubmit: Function,
  onCancel: Function,
  oilsAndFluidsConsumptionPlan: $Shape<OilsAndFluidsConsumptionPlan>
};

const InnerForm = withUserAccess(
  ({
    userAccess,
    onSubmit,
    onCancel,
    oilsAndFluidsConsumptionPlan
  }: FormProps) => (
    <Form initialValues={oilsAndFluidsConsumptionPlan} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps) => {
        const {
          handleSubmit,
          setFieldValue,
          dirty,
          isSubmitting
        } = formikProps;
        const canEdit = () =>
          userAccess.some(access =>
            [
              accessTypeEnum.admin,
              accessTypeEnum.adminBranch,
              accessTypeEnum.handlingOilsAndFluidsConsumptionPlan
            ].includes(access)
          );
        const readonly =
          oilsAndFluidsConsumptionPlan.status ===
            technicalFluidStatusEnum.approved || !canEdit();
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <StyledGrid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField label="Планируемый срок" name="period" required>
                      {({ value, name }) =>
                        readonly ? (
                          formatDateTimeToString(value, 'MMMM YYYY')
                        ) : (
                          <MonthPicker
                            format='MMMM YYYY'
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object) => {
                              setFieldValue(name, moment(value).toISOString());
                            }}
                          />
                        )
                      }
                    </FormField>
                  </GridItem>
                </StyledGrid>
              </Content>
              <FormField label="" name="vehicles">
                {({ value, name }) => (
                  <OilsAndFluidsConsumptionList
                    readonly={readonly}
                    vehicleOilsAndFluids={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </Section>
            <Section />
            {!readonly && (
              <Footer>
                <ButtonsRow>
                  <Button
                    disabled={isSubmitting || !dirty}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    data-cy="save"
                  >
                    Сохранить
                  </Button>
                  {!!oilsAndFluidsConsumptionPlan.id && (
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="primary"
                      data-cy="save"
                      onClick={() => {
                        changeNotification(async () => {
                          await oilsAndFluidsConsumptionPlanApi.changeStatus({
                            // $FlowFixMe проверка выше
                            id: oilsAndFluidsConsumptionPlan.id,
                            status: technicalFluidStatusEnum.approved
                          });
                          navigate('/budget/oils-and-fluids-consumption-plan');
                        });
                      }}
                    >
                      Утвердить
                    </Button>
                  )}
                </ButtonsRow>
                <CancelButton dirty={dirty} onClick={onCancel}>
                  Отменить
                </CancelButton>
              </Footer>
            )}
          </form>
        );
      }}
    </Form>
  )
);

type Props = {
  id: number
};

type State = {
  oilsAndFluidsConsumptionPlan?: OilsAndFluidsConsumptionPlan,
  vehicles: Vehicle[]
};

class OilsAndFluidsConsumptionPlanForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    const { id } = this.props;

    if (id) {
      try {
        const oilsAndFluidsConsumptionPlan = await oilsAndFluidsConsumptionPlanApi.get(
          id
        );
        this.setState({
          oilsAndFluidsConsumptionPlan
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        this.onCancel();
      }
    }
  }

  onCancel = () => goBack('/budget/oils-and-fluids-consumption-plan/');

  onSubmit = async (
    oilsAndFluidsConsumptionPlan: OilsAndFluidsConsumptionPlan
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });

      let id = oilsAndFluidsConsumptionPlan.id;
      if (id) {
        await oilsAndFluidsConsumptionPlanApi.update(
          oilsAndFluidsConsumptionPlan
        );
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        const added = await oilsAndFluidsConsumptionPlanApi.add(
          oilsAndFluidsConsumptionPlan
        );
        if (added && added.id) {
          id = added.id;
          notification.success({
            message: 'Успешное добавление',
            description: `План расхода тех. жидкостей и масел успешно добавлен`
          });
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      navigate('/budget/oils-and-fluids-consumption-plan');
      notification.close('saving');
    }
  };

  render() {
    const { oilsAndFluidsConsumptionPlan } = this.state;
    const { id } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget">Бюджет</Crumb>
              <Crumb to={'/budget/oils-and-fluids-consumption-plan'}>
                Список планов расхода тех. жидкостей и масел
              </Crumb>
              {id ? (
                <Crumb>Редактирование</Crumb>
              ) : (
                <Crumb>Новый план расхода тех. жидкостей и масел</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {id
              ? `План расхода тех. жидкостей и масел`
              : 'Новый план расхода тех. жидкостей и масел'}
          </h1>
        </StyledPanel>
        <InnerForm
          oilsAndFluidsConsumptionPlan={
            oilsAndFluidsConsumptionPlan || {
              period: moment().toISOString()
            }
          }
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}
export default OilsAndFluidsConsumptionPlanForm;
