// @flow
import { EditTwoTone } from '@ant-design/icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import { Link } from '@reach/router';
import moment from 'moment';

import type { Washing } from './../../lib/types';
import { Popconfirm, Icon, ListTable } from './../../components/ui';
import { formatDateTimeToString } from './../../lib/helpers';
import { washingTypes, washingStatusTypes } from './../../lib/enum';
import { washingApi } from './../../lib/api';
import { notificationLoading } from './../../components/Notifications';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const SyledAntIcon = styled(EditTwoTone)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean,
};

type State = {
  washings: Washing[],
};

class WashingList extends Component<Props, State> {
  state = {
    washings: [],
  };

  async componentDidMount() {
    await this.fetchWashings();
  }

  fetchWashings = async () => {
    try {
      const vehicleId = parseInt(this.props.vehicleId, 10);
      const washings = await washingApi.fetchWashings(vehicleId);
      this.setState({
        washings: washings.data,
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  };

  onDelete = async (washing: Washing) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      const washingId = parseInt(washing.id, 10);
      if (!washingId) {
        throw new Error('Неверный идентификатор мойки');
      }
      await washingApi.deleteWashing(washingId);
      notification.success({
        message: `Мойка от ${formatDateTimeToString(washing)} успешно удалена`,
      });
      await this.fetchWashings();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete } = this.props;
    const { washings } = this.state;
    return (
      <ListTable
        columns={[
          {
            title: 'Планируемая дата',
            key: 'date',
            dataIndex: 'date',
            render: (date: ?string) => {
              const monthYear = moment.utc(date).format('MMMM YYYY года');
              return monthYear.charAt(0).toUpperCase() + monthYear.slice(1);
            },
          },
          {
            title: 'Дата выполнения',
            key: 'factDate',
            dataIndex: 'factDate',
            render: (value: ?string) =>
              value ? formatDateTimeToString(value, 'DD.MM.YYYY') : null,
          },
          {
            title: 'Вид мойки',
            key: 'type',
            render: (type: string) => washingTypes[type],
          },
          {
            title: 'Статус',
            key: 'state',
            dataIndex: 'state',
            render: (state: string) => washingStatusTypes[state],
          },
          {
            width: '20px',
            renderRecord: (record: Washing) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/services/washings/${parseInt(record.id, 10)}/edit`}
                  >
                    <SyledAntIcon />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null,
          },
        ]}
        data={washings.sort(
          (a: Washing, b: Washing) => moment(b.date) - moment(a.date)
        )}
      />
    );
  }
}

export default WashingList;
