import {
    MaintenanceOperationsList,
} from '../../containers/MaintenanceOperations';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MaintenanceOperationsList,
        path: '/admin/maintenance-operations-by-brand',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingMaintenanceOperationGroups,
            accessTypeEnum.handlingMaintenanceOperationGroups,
        ],
    },
];