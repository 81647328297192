import isNil from 'lodash/isNil';
import moment from 'moment';

import type {
  OperationLimit,
  OperationLimitGroup,
  UserAccess
} from '../../lib/types';
import { declineNumber } from '../../lib/helpers';
import type { OperationLimitGroupStatus } from '../../lib/types/operationLimitGroup';
import { accessTypeEnum } from '../../lib/enum';

/**
 * Функция, для склонения часов
 * @param value Часы
 * @returns {string}
 */
export const declineHours = (value: number) =>
  isNil(value)
    ? ''
    : `${value} ${declineNumber(Math.round(value), 'час', 'часа', 'часов')}`;

/**
 * Функция форматирования значения по ее типу (часы или км)
 *
 * @param key
 * @param value
 * @returns {string}
 */
export const formatLimitValue = (
  key: $Keys<OperationLimit>,
  value: any
): string => {
  const hours = ['hours', 'hoursWeekend'];
  const distance = ['distance', 'distanceWeekend'];
  if (!value) {
    return '';
  }
  if (hours.includes(key)) {
    return declineHours(value);
  } else if (distance.includes(key)) {
    return `${value} км`;
  }
  return value;
};

/**
 * Возвращает даты на заданном промежутке
 * @param startDate Начальная дата
 * @param endDate Конечная дата
 * @returns {Array} Массив дат
 */
export const getDates = (startDate: string, endDate: string) => {
  const dateArray = [];
  let currentDate = moment.utc(startDate);
  endDate = moment.utc(endDate);
  while (currentDate <= endDate) {
    dateArray.push(currentDate.format('YYYY-MM-DDTHH:mm:ss\\Z'));
    currentDate = currentDate.add(1, 'days');
  }
  return dateArray;
};

/**
 * Входит ли период дат в один месяц
 * @param startDate Начальная дата
 * @param endDate Конечная дата
 * @returns {boolean}
 */
export const isRangeInOneMonth = (startDate: string, endDate: string) => {
  const startMonth = moment(startDate).month();
  const endMonth = moment(endDate).month();
  return startMonth === endMonth;
};

/**
 * Возвращает статус всех групп
 * @param groups Группы
 * @returns {OperationLimitGroupStatus|null}
 */
export const getTimeLimitStatus = (
  groups: OperationLimitGroup[],
  statusKey: 'timeLimitStatus' | 'assignmentLimitStatus'
): ?OperationLimitGroupStatus => {
  const [group] = groups;
  if (group) {
    return group[statusKey];
  }
  return null;
};

/**
 * Проверка на возможность просмотра пользователем раздела Регламентов в лимитах
 * @param userAccess Пользовательские доступы
 */
export const showTimeLimits = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingTimeLimits,
      accessTypeEnum.viewingBranchTimeLimits
    ].includes(access)
  );
};

/**
 * Проверка на возможность просмотра пользователем раздела Закрепления в лимитах
 * @param userAccess Пользовательские доступы
 */
export const showAssignments = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingLimitAssignment,
      accessTypeEnum.viewingAllLimitAssignment
    ].includes(access)
  );
};

/**
 * Проверка на возможность просмотра пользователем раздела Планирование в лимитах
 * @param userAccess Пользовательские доступы
 */
export const showDailyBudgets = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBranchMonthLimitsPlan,
      accessTypeEnum.viewingBranchYearLimitsPlan,
      accessTypeEnum.viewingMonthLimitsPlan,
      accessTypeEnum.viewingYearLimitsPlan
    ].includes(access)
  );
};
