import { Button, Result } from 'antd';
import React from 'react';
import { navigate } from '../lib/helpers';

const NotFound = () => (
  <Result
    status="404"
    title="Страница не найдена"
    extra={
      <Button type="primary" onClick={() => navigate('/')}>
        Перейти на главную страницу
      </Button>
    }
  />
);

export default NotFound;
