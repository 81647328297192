// @flow
import CommonApi from './../commonApi';
import type { InspectionGibddCost } from '../types';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (inspectionGibddCost: InspectionGibddCost) => ({
  ...inspectionGibddCost,
  cost: convertFromRubToKop(inspectionGibddCost.cost)
});

const convertDataFromServer = (inspectionGibddCost: InspectionGibddCost) => ({
  ...inspectionGibddCost,
  cost: convertFromKopToRub(inspectionGibddCost.cost)
});

const inspectionGibddCost = new CommonApi<InspectionGibddCost>(
  'inspectionGibddCost',
  'Стоимость ТО ГИБДД',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...inspectionGibddCost
};
