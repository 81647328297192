// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import { Tabs } from '../../../components/ui';

export const SectionContent = styled.div`
  padding: 16px;
  overflow-x: auto;
`;

export const HeaderCellText = styled(({ sticky, center, ...props }) => (
  <p {...props} />
))`
  color: #4c4c4c;
  font-size: 12px;
  font-weight: bold;
  ${props =>
    props.center &&
    css`
      width: 100%;
      text-align: center;
    `}
  ${props =>
    props.sticky &&
    css`
      position: sticky;
      margin: 0 32px;
      width: auto;
      left: 16px;
      text-align: left;
    `}
`;
HeaderCellText.defaultProps = {
  center: true
};

export const Cell = styled(({ index, ...props }) => <div {...props} />)`
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #838383;
  user-select: none;
`;

export const StyledTabs = styled(Tabs)`
  & .tabs__item {
    font-weight: bold;
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
