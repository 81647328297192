// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button/button';

import type { FetchListParams } from './../../../lib/api';
import type { FuelPrice, ListState, UserAccess } from './../../../lib/types';

import { fuelPriceApi } from './../../../lib/api';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
} from './../../../lib/helpers';

import { notificationLoading } from './../../../components/Notifications';
import { Header, Section } from './../../../components/layout';
import { Popconfirm, Table, Icon, Operations } from './../../../components/ui';

import { withUserAccess } from './../../withUserAccess';
import addAccess from './../accessRight';

import type { FuelPriceParamsFilter } from './components/Filter';
import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};
type State = ListState<FuelPrice> & {
  filter: FuelPriceParamsFilter,
};

const COLUMNS = [
  {
    title: 'АИ-80, руб. без НДС',
    dataIndex: 'ai80Price',
  },
  {
    title: 'АИ-92, руб. без НДС',
    dataIndex: 'ai92Price',
  },
  {
    title: 'АИ-95, руб. без НДС',
    dataIndex: 'ai95Price',
  },
  {
    title: 'ДТ, руб. без НДС',
    dataIndex: 'dieselFuelPrice',
  },
  {
    title: 'Моторное масло, руб. без НДС',
    dataIndex: 'motorOilPrice',
  },
  {
    title: 'Трансмиссионное масло, руб. без НДС',
    dataIndex: 'transmissionOilPrice',
  },
  {
    title: 'Масла и жидкости, руб. без НДС',
    dataIndex: 'specialOilPrice',
  },
  {
    title: 'Пластичные смазки, руб. без НДС',
    dataIndex: 'autoGreasePrice',
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    width: '130px',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    width: '130px',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY'),
  },
];
const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export class FuelPriceList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };
  columns = COLUMNS;

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.fetchFuelPrice(page));
    if (this.canAdd()) {
      this.columns = [
        ...COLUMNS,
        {
          title: ' ',
          render: (text: string, record: FuelPrice): any => (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () => await this.deleteFuelPrice(record.id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          ),
        },
      ];
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  deleteFuelPrice = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await fuelPriceApi.deleteFuelPrice(id);
      await this.fetchFuelPrice(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchFuelPrice = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await fuelPriceApi.fetchFuelPrice({
      ...filter,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  applyFilter = (filter: FuelPriceParamsFilter) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () => this.fetchFuelPrice(page));
  };
  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchFuelPrice(page)
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Прейскурант ГСМ</h1>}
          right={
            canAdd && (
              <Link to="/admin/fuel-price/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={(record) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/fuel-price/${record.id}/edit`)
                  : navigate(`/admin/fuel-price/${record.id}`),
            })}
            columns={this.columns}
            fetch={this.fetchFuelPrice}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(FuelPriceList);
