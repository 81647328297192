// @flow
import { WarningOutlined } from '@ant-design/icons';
import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';

import { FormField } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import CommonForm, {
  type CommonFormProps,
} from '../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Selects from '../../../components/selects';
import Popover from '../../../components/ui/Popover';

import { oilsAndFluidsConsumptionPlanVehicleApi } from '../../../lib/api';
import {
  technicalFluidStatusEnum,
  technicalFluidTypes,
  technicalFluidTypesEnum,
} from '../../../lib/enum';
import { convertVehicleToString } from '../../../lib/helpers';
import type { TechnicalFluid } from '../../../lib/types';

const { VehicleSelect, SparePartSelect, TechnicalFluidTypeSelect } = Selects;

export default (props: CommonFormProps<$Shape<TechnicalFluid>>) => {
  const [residues, setResidues] = useState({});
  const [readonly] = useState(!!props.data.id);
  const [status, setStatus] = useState(technicalFluidStatusEnum.draft);
  const loadResidues = async (vehicleId: number) => {
    if (vehicleId) {
      try {
        // $FlowFixMe если внизу math.abs убрать то все работает
        const plan = await oilsAndFluidsConsumptionPlanVehicleApi.vehiclePlan(
          vehicleId,
          moment().utc().toISOString()
        );
        const {
          oilLitersResidue,
          antifreezeLitersResidue,
          windshieldWasherFluidLitersResidue,
          brakeFluidLitersResidue,
          status,
        } = plan;
        setResidues({
          [technicalFluidTypesEnum.antifreeze]: Math.abs(
            antifreezeLitersResidue
          ),
          [technicalFluidTypesEnum.oil]: Math.abs(oilLitersResidue),
          [technicalFluidTypesEnum.windshieldWasherFluid]: Math.abs(
            windshieldWasherFluidLitersResidue
          ),
          [technicalFluidTypesEnum.brakeFluid]: Math.abs(
            brakeFluidLitersResidue
          ),
        });
        setStatus(status);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  };

  useEffect(() => {
    if (props.data.vehicleId) loadResidues(props.data.vehicleId);
  }, [props.data.vehicleId]);

  const onSubmit = useCallback(
    (data: any) => {
      if (status === technicalFluidStatusEnum.approved) {
        // eslint-disable-next-line
        const d = props.onSubmit?.(data);
      } else {
        notification.error({
          message:
            'Не удалось найти утверждённый план расхода на масла и тех. жидкости на текущую дату',
        });
      }
    },
    [props, status]
  );

  return (
    <CommonForm
      disableSubmitButton={(values) => {
        return typeof values.liters === 'undefined' || values.liters <= 0;
      }}
      {...props}
      onSubmit={onSubmit}
      useFooter={!readonly}
    >
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid cols={2} gutter="16px">
              <GridItem>
                <FormField
                  label="Транспортное средство"
                  fast
                  required
                  name="vehicleId"
                >
                  {({ name, value }) =>
                    readonly ? (
                      convertVehicleToString(values.vehicle)
                    ) : (
                      <VehicleSelect
                        value={value}
                        onlyBranches
                        onChange={async (value, option) => {
                          setFieldValue(name, value);
                          setFieldValue('vehicle', option?.props?.vehicle);
                          await loadResidues(value);
                        }}
                      />
                    )
                  }
                </FormField>
              </GridItem>
              <GridItem fullWidth />
              <GridItem>
                <FormField label="Вид тех. жидкости" required name="fluidType">
                  {({ name, value }) =>
                    readonly ? (
                      technicalFluidTypes[value] || ''
                    ) : (
                      <TechnicalFluidTypeSelect
                        name={name}
                        value={value}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )
                  }
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тех. жидкость" name="sparePartId">
                  {({ name, value }) => {
                    const filter = values.fluidType
                      ? {
                          type: values.fluidType,
                        }
                      : undefined;
                    return readonly ? (
                      values.spareParts?.name || '-'
                    ) : (
                      <SparePartSelect
                        allVehicles
                        vehicleModelId={values?.vehicle?.vehicleModelId}
                        filter={{ ...filter, isAvailable: true }}
                        refetchParams={{ filter }}
                        disabled={!values.vehicleId}
                        placeholder={'Выберите тех.жидкость'}
                        name={name}
                        value={value}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    );
                  }}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество тех. жидкости, л"
                  fast
                  required
                  name="liters"
                >
                  {({ name, value }) =>
                    readonly ? (
                      value || ''
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={(value) => setFieldValue(name, value)}
                        />
                        {value > residues[values.fluidType] && (
                          <Popover
                            content={`Количество техжидкости превышает остатки на складе. Расчеты могут быть некорректыми.`}
                            width={300}
                          >
                            <WarningOutlined
                              style={{
                                cursor: 'help',
                                color: 'red',
                                marginLeft: '8px',
                              }}
                            />
                          </Popover>
                        )}
                      </div>
                    )
                  }
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Остаток тех. жидкости по плану расхода"
                  name="liters"
                >
                  {({ name, value: liters = 0 }) => {
                    const value = (residues[values.fluidType] || 0) - liters;
                    const signum = value > 0 ? '+' : '';
                    const preparedValue = value < 0 ? 0 : value;
                    return readonly ? (
                      `${signum}${preparedValue}`
                    ) : (
                      <span
                        style={{
                          color: preparedValue >= 0 ? '#2770ff' : 'red',
                        }}
                      >
                        {signum}
                        {preparedValue} л
                      </span>
                    );
                  }}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
