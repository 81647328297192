// @flow
import React, { type ComponentType } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import { FormikProps } from 'formik';

import { Card } from './../../../components';
import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { Contractor, UserAccess } from '../../../lib/types';
import { accessTypeEnum } from '../../../lib/enum';
import { serviceTypes } from '../../../lib/enum/serviceTypes';
import Form, { type FormFieldProps } from '../../../components/Form';
import { Selects } from './../../../components';
import CancelButton from '../../../components/CancelButton';
import { withUserAccess } from './../../withUserAccess';

const { CompanySelect, ContractServiceSelect } = Selects;

const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  contractor: Contractor,
  onSubmit: Function,
  onCancel: Function,
  userAccess: UserAccess[],
};

const InnerForm = ({
  onSubmit,
  contractor,
  onCancel,
  userAccess,
}: FormProps) => {
  const canEdit = userAccess.some((access) =>
    [accessTypeEnum.admin, accessTypeEnum.handlingContractors].includes(access)
  );
  return (
    <Form onSubmit={onSubmit} initialValues={contractor || {}}>
      {(FormField: ComponentType<FormFieldProps>, formikProps: FormikProps) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          dirty,
          isSubmitting,
          values,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    {canEdit ? (
                      <FormField
                        label="Вид оказываемой услуги"
                        name="services"
                        required
                      >
                        {({ name, value }) => (
                          <ContractServiceSelect
                            mode="multiple"
                            onBlur={() => {
                              handleBlur({
                                target: {
                                  name,
                                },
                              });
                            }}
                            data-cy="servicesSelect"
                            value={value}
                            name={name}
                            onChange={(value) => setFieldValue(name, value)}
                          />
                        )}
                      </FormField>
                    ) : (
                      <Field label="Вид оказываемой услуги">
                        {values?.services
                          ?.map((service) => serviceTypes[service])
                          .join(', ')}
                      </Field>
                    )}
                  </GridItem>
                  <GridItem>
                    {canEdit ? (
                      <FormField label="Контрагенты" name="companyId" required>
                        {({ value, name }) => (
                          <CompanySelect
                            value={value}
                            name={name}
                            data-cy="companySelect"
                            onChange={(value) => setFieldValue(name, value)}
                            onBlur={() => {
                              handleBlur({
                                target: {
                                  name,
                                },
                              });
                            }}
                          />
                        )}
                      </FormField>
                    ) : (
                      <Field label="Контрагенты">{values?.company?.name}</Field>
                    )}
                  </GridItem>
                </Grid>
              </Content>
            </Section>
            {canEdit && (
              <Footer>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="primary"
                  data-cy="save"
                  htmlType="submit"
                >
                  Сохранить
                </Button>
                <CancelButton dirty={dirty} onClick={onCancel}>
                  Отменить
                </CancelButton>
              </Footer>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default withUserAccess(InnerForm);
