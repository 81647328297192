// @flow
import queryString from 'query-string';

import { fetchRequest, initialFetchListParams } from './index';
import type { OptionalEquipment } from '../types/index';
import type { FetchListParams, ListResponse } from './index';
import type { OptionalEquipmentFilterParams } from '../../containers/Equipment/OptionalEquipment/Filter';

/**
 * Работа с API доп. оборудования
 */

// Получение списка
export const fetchOptionalEquipments = async (
  params: FetchListParams<OptionalEquipmentFilterParams> = initialFetchListParams
): Promise<ListResponse<OptionalEquipment>> =>
  await fetchRequest.get('/optionalEquipment', {
    ...initialFetchListParams,
    ...params
  });

// Получение сущности по id
export const fetchOptionalEquipment = async (
  id: number,
  params: any = {}
): Promise<OptionalEquipment> => {
  const optional = await fetchRequest.get(`/optionalEquipment/${id}`, params);
  if (optional) return optional;
  throw new Error('Не удалось загрузить доп. оборудование');
};

// Добавление
export const addOptionalEquipment = async (
  optionalEquipment: OptionalEquipment
): Promise<OptionalEquipment> => {
  const added = await fetchRequest.post(
    '/optionalEquipment',
    optionalEquipment
  );
  if (added) return added;
  throw new Error('Не удалось создать доп. оборудование');
};

// Обновление данных
export const updateOptionalEquipment = async (
  optionalEquipment: OptionalEquipment
): Promise<OptionalEquipment> => {
  const updated = await fetchRequest.put(
    '/optionalEquipment',
    optionalEquipment
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить доп. оборудование');
};

// Удаление
export const deleteOptionalEquipment = async (
  id: number
): Promise<OptionalEquipment> => {
  const deleted = await fetchRequest.delete(`/optionalEquipment/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить доп. оборудование');
};

// Привязка доп. оборудования к ТС
export const setOptionalEquipmentVehicle = async (
  optionalEquipmentId: number,
  vehicleId: number,
  installDate?: string,
  removeDate?: string
): Promise<Object> =>
  await fetchRequest.post(
    `/optionalEquipment/${optionalEquipmentId}/${vehicleId}?${queryString.stringify(
      { installDate, removeDate },
      { skipNull: true }
    )}`
  );

// Получение доп. оборудования ТС
export const fetchVehicleOptionalEquipments = async (
  vehicleId: number
): Promise<OptionalEquipment[]> =>
  await fetchRequest.get(`/optionalEquipment/${vehicleId}/vehicle`);

export const updateOptionalEquipmentVehicle = async (
  optionalEquipment: OptionalEquipment
) => await fetchRequest.put('/optionalEquipment/', optionalEquipment);

export const fetchHistory = async (
  params: FetchListParams<any>,
  optionalEquipmentId: number
): Promise<ListResponse<Object>> =>
  await fetchRequest.get(`/optionalEquipmentHistory/${optionalEquipmentId}`);

export default {
  fetchOptionalEquipments,
  fetchOptionalEquipment,
  addOptionalEquipment,
  updateOptionalEquipment,
  deleteOptionalEquipment,
  setOptionalEquipmentVehicle,
  fetchVehicleOptionalEquipments,
  updateOptionalEquipmentVehicle,
  fetchHistory
};
