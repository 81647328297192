// @flow
import React, { useMemo } from 'react';

import { Wrapper, Event } from './styles';
import type { Interval } from '../grid';
import { monitoringVehicleStatesEnum } from '../../../../../../../../../lib/enum';

type Props = {
  intervals: Interval[]
};

/**
 * Мини-карта состояний трека
 */
export const TrackEvents = ({ intervals }: Props) => {
  const events = useMemo(() => {
    return intervals.map(({ width, state }, index) => (
      <Event key={index} style={{ width }} state={state}>
        {state === monitoringVehicleStatesEnum.parking && 'P'}
        {state === monitoringVehicleStatesEnum.stopped && 'S'}
      </Event>
    ));
  }, [intervals]);

  return <Wrapper>{events}</Wrapper>;
};
