import AutoGeneratedEnumSelect from './AutoGeneratedEnumSelect';
import {
  AuthorizeTypeSelect,
  BudgetSummaryStatusSelect,
  BusinessDayTypeSelect,
  CertificationAreaSelect,
  ChassisConfigurationSelect,
  ContractVehicleTypeSelect,
  DriverBriefingViewSelect,
  DriverStatusSelect,
  EmployeeBriefingViewSelect,
  EmployeeCertificationViewSelect,
  EngineTypeSelect,
  EntityStatusSelect,
  EntityTypeSelect,
  ExpenseDirectionTypeSelect,
  FuelGroupSelect,
  FuelTypeCalculationSelect,
  FuelTypeSelect,
  InsuranceClassSelect,
  MaintenanceOperationStatusSelect,
  MaintenanceStatusesTypeSelect,
  MaintenanceTypeOfWorkSelect,
  MaintenanceTypeSelect,
  MeasureTypeFuelPriceSelect,
  MeasureTypeSelect,
  MedicOwnerTypeSelect,
  MonthlyWashingPlanStatusSelect,
  MonthsSelect,
  NomenclatureTypeSelect,
  OperatingModeSelect,
  OperationTypeSelect,
  OrderContractorTypeSelect,
  OrderObjectiveSelect,
  OrderTypeDispatcherSelect,
  OrderTypeSelect,
  OsagoCalculationStatusSelect,
  OsagoTransportTypeSelect,
  PddTestStatusSelect,
  PddTestTypeSelect,
  PddTicketStatusesSelect,
  PositionSelect,
  PseudoNameSelect,
  RateTypeSelect,
  RepairePlanSelect,
  SeasonTypeTireSelect,
  ServiceOrderTypeSelect,
  SparePartMaterialSelect,
  STSTypeSelect,
  TariffTypeSelect,
  TechnicalFluidTypeSelect,
  TrainingViewSelect,
  VehicleIssueTypeSelect,
  VehicleOvbTypeSelect,
  WashingStatusSelect,
  WashingTypeSelect,
  WorkHourPriceSelect,
  VehiclePtsTypeSelect,
  AttachedEquipmentFuelTankTypeSelect,
  SideServiceTypeSelect,
  SideServiceTariffMeasureSelect,
  BioTreatmentFacilityFactStatusSelect,
  SideServiceTariffTypeSelect,
} from './AutoGeneratedEnumSelects';
import TripFreeVehiclesSelect from './TripFreeVehiclesSelect';
import BatterySelect from './BatterySelect';
import BranchBudgetSummarySelect from './BranchBudgetSummarySelect';
import BrandsSelect from './BrandsSelect';
import BudgetLineItemSelect from './BudgetLineItemSelect';
import BudgetVersionSelect from './BudgetVersionSelect';
import CompanySelect from './CompanySelect';
import ContractorSelect from './ContractorSelect';
import ContractSelect from './ContractSelect';
import ContractServiceSelect from './ContractServiceSelect';
import ContractVehicleSelect from './ContractVehicleSelect';
import CrewSelect from './CrewSelect';
import DriverLicenseCategorySelect from './DriverLicenseCategorySelect';
import DriverQualificationSelect from './DriverQualificationSelect';
import DriverSelect from './DriverSelect';
import EmployeePositionSelect from './EmployeePositionSelect';
import EmployeeSelect from './EmployeeSelect';
import AttachmentsSelect from './equipment/AttachmentsSelect';
import OptionalsTypeSelect from './equipment/OptionalsTypeSelect';
import ExpenseDirectionSelect from './ExpenseDirectionSelect';
import ExpenseDirectionTreeSelect from './ExpenseDirectionTreeSelect';
import FuelAndOilSelect from './FuelAndOilSelect';
import FuelMultipliersSelect from './FuelMultipliersSelect';
import HourSelect from './HourSelect';
import IncomeScheduleSelect from './IncomeScheduleSelect';
import JobTitleSelect from './JobTitleSelect';
import JournalTypeSelect from './JournalTypeSelect';
import LocationSelect from './LocationSelect';
import MaintenanceMatrixValidActionSelect from './MaintenanceMatrixValidActionSelect';
import MaintenanceOperationGroupSelect from './MaintenanceOperationGroupSelect';
import MaintenanceOperationSelect from './MaintenanceOperationSelect';
import MaintenanceWorkTypeSelect from './MaintenanceWorkTypeSelect';
import MdmSelect from './MdmSelect';
import OkeiSelect from './OkeiSelect';
import OverhaulTypeSelect from './OverhaulTypeSelect';
import PddGroupSelect from './PddGroupSelect';
import PddQuestionSelect from './PddQuestionSelect';
import PddTestSelect from './PddTestSelect';
import PddTicketSelect from './PddTicketSelect';
import RateTaxesSelect from './RateTaxesSelect';
import RegulationSelect from './RegulationSelect';
import SparePartSelect from './SparePartSelect';
import StageSelect from './StageSelect';
import StockPartSelect from './StockPartSelect';
import StouSelect from './StouSelect';
import TireSelect from './TiresSelect';
import TripSelect from './TripSelect';
import UserAccessTypesSelect from './UserAccessTypesSelect';
import UserRoleSelect from './UserRoleSelect';
import UserSelect from './UserSelect';
import VehicleCategorySelect from './VehicleCategorySelect';
import VehicleIssueGroupSelect from './VehicleIssueGroupSelect';
import VehicleModelSelect from './VehicleModelSelect';
import VehicleOwnerTypeSelect from './VehicleOwnerTypeSelect';
import VehiclePlanStatusSelect from './VehiclePlanStatusSelect';
import VehicleSelect from './VehicleSelect';
import VehicleStatusSelect from './VehicleStatusSelect';
import VehicleTaxRateSelect from './VehicleTaxRateSelect';
import VehicleTypeSelect from './VehicleTypeSelect';
import WashingVehicleTypeSelect from './WashingVehicleTypeSelect';
import YearIssueSelect from './YearIssueSelect';
import YearSelect from './YearSelect';
import YesNoSelect from './YesNoSelect';
import BudgetSummarySelect from './BudgetSummarySelect';
import VehicleContractPlanSelect from './VehicleContractPlanSelect';
import VehiclePlanSelect from './VehiclePlanSelect';
import BioTreatmentFacilityTalonSelect from './BioTreatmentFacilityTalonSelect';
import ChannelSelect from './ChannelSelect';

export default {
  TripFreeVehiclesSelect,
  VehiclePlanSelect,
  VehicleContractPlanSelect,
  BudgetSummarySelect,
  SparePartMaterialSelect,
  SeasonTypeTireSelect,
  TechnicalFluidTypeSelect,
  RegulationSelect,
  BudgetVersionSelect,
  ContractVehicleTypeSelect,
  RateTypeSelect,
  MeasureTypeFuelPriceSelect,
  OsagoCalculationStatusSelect,
  TripSelect,
  IncomeScheduleSelect,
  NomenclatureTypeSelect,
  YearSelect,
  RateTaxesSelect,
  MeasureTypeSelect,
  StageSelect,
  EntityStatusSelect,
  MaintenanceMatrixValidActionSelect,
  MaintenanceStatusesTypeSelect,
  MaintenanceWorkTypeSelect,
  MaintenanceTypeOfWorkSelect,
  EntityTypeSelect,
  OrderTypeDispatcherSelect,
  StockPartSelect,
  MdmSelect,
  VehicleIssueGroupSelect,
  BatterySelect,
  TireSelect,
  OrderContractorTypeSelect,
  MedicOwnerTypeSelect,
  DriverSelect,
  VehicleSelect,
  AttachmentsSelect,
  BrandsSelect,
  OptionalsTypeSelect,
  FuelTypeSelect,
  FuelGroupSelect,
  VehicleTypeSelect,
  VehicleModelSelect,
  LocationSelect,
  EmployeeSelect,
  FuelAndOilSelect,
  VehicleStatusSelect,
  OrderObjectiveSelect,
  UserAccessTypesSelect,
  PositionSelect,
  DriverStatusSelect,
  InsuranceClassSelect,
  CompanySelect,
  ContractorSelect,
  ContractServiceSelect,
  MaintenanceTypeSelect,
  MaintenanceOperationSelect,
  OperatingModeSelect,
  TariffTypeSelect,
  ServiceOrderTypeSelect,
  WashingTypeSelect,
  WashingStatusSelect,
  VehicleIssueTypeSelect,
  UserRoleSelect,
  FuelMultipliersSelect,
  MaintenanceOperationStatusSelect,
  MaintenanceOperationGroupSelect,
  DriverBriefingViewSelect,
  TrainingViewSelect,
  EmployeeCertificationViewSelect,
  EmployeeBriefingViewSelect,
  ContractVehicleSelect,
  DriverLicenseCategorySelect,
  OrderTypeSelect,
  AuthorizeTypeSelect,
  JournalTypeSelect,
  YearIssueSelect,
  VehicleOvbTypeSelect,
  JobTitleSelect,
  UserSelect,
  BusinessDayTypeSelect,
  YesNoSelect,
  ChassisConfigurationSelect,
  HourSelect,
  DriverQualificationSelect,
  EngineTypeSelect,
  VehicleCategorySelect,
  StouSelect,
  MonthlyWashingPlanStatusSelect,
  CertificationAreaSelect,
  RepairePlanSelect,
  WashingVehicleTypeSelect,
  VehicleOwnerTypeSelect,
  ExpenseDirectionSelect,
  ExpenseDirectionTreeSelect,
  ContractSelect,
  OsagoTransportTypeSelect,
  ExpenseDirectionTypeSelect,
  WorkHourPriceSelect,
  AutoGeneratedEnumSelect,
  STSTypeSelect,
  EmployeePositionSelect,
  VehicleTaxRateSelect,
  PseudoNameSelect,
  MonthsSelect,
  FuelTypeCalculationSelect,
  OperationTypeSelect,
  OverhaulTypeSelect,
  BudgetLineItemSelect,
  OkeiSelect,
  PddTicketStatusesSelect,
  PddGroupSelect,
  CrewSelect,
  SparePartSelect,
  PddTestTypeSelect,
  PddTestSelect,
  PddQuestionSelect,
  PddTestStatusSelect,
  PddTicketSelect,
  VehiclePlanStatusSelect,
  BranchBudgetSummarySelect,
  BudgetSummaryStatusSelect,
  VehiclePtsTypeSelect,
  AttachedEquipmentFuelTankTypeSelect,
  SideServiceTypeSelect,
  SideServiceTariffMeasureSelect,
  BioTreatmentFacilityFactStatusSelect,
  BioTreatmentFacilityTalonSelect,
  SideServiceTariffTypeSelect,
  ChannelSelect,
};
