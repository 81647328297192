// @flow
import React, { Component } from 'react';

import { measuringDeviceCertificationCalculationApi } from '../../../../lib/api';
import { printNotification } from '../../../../lib/notificationWrapper';
import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

export default class extends Component<Props, {}> {
  render() {
    const { location, vehiclePlanId } = this.props;
    return (
      <Common
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={measuringDeviceCertificationCalculationApi}
        calcScrollY={400}
        statusField={'measuringDeviceCertificationCalculationStatus'}
        title={'Тарировка цистерн'}
        handlePrint={filter => {
          printNotification(async () => {
            await measuringDeviceCertificationCalculationApi.print(
              vehiclePlanId,
              filter
            );
          });
        }}
      />
    );
  }
}
