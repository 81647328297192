import moment from 'moment';
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { MonitoringPrice } from '../types/monitoringPrice';
import {
  convertFromKopToRub,
  convertFromRubToKop,
  formatDateTimeToString
} from './../../lib/helpers';

export const convertDataFromServer = (monitoringPrice: MonitoringPrice) => ({
  ...monitoringPrice,
  amountPayment: convertFromKopToRub(monitoringPrice.amountPayment)
});

export const convertDataToServer = (monitoringPrice: MonitoringPrice) => ({
  ...monitoringPrice,
  amountPayment: convertFromRubToKop(monitoringPrice.amountPayment)
});

export const getMonitoringPriceByPeriod = async (date: moment | string) => {
  const periods = await fetchRequest.get(
    `/monitoringPrice/${formatDateTimeToString(date, 'YYYY-MM-DD')}/byPeriod`
  );
  if (periods) return convertDataFromServer(periods);
  throw new Error('Не удалось подтянуть прайс по данной дате акта');
};

export const addMonitoringPrice = async (
  monitoringPrice: MonitoringPrice
): Promise<void> => {
  const added = await fetchRequest.post(
    '/monitoringPrice',
    convertDataToServer(monitoringPrice)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось добавить прайс для мониторинга');
};

export const fetcMonitoringPrice = async (
  params: FetchListParams<> = initialFetchListParams
): Promise<ListResponse<MonitoringPrice>> => {
  const monitoringPrices = await fetchRequest.get('/monitoringPrice', {
    ...initialFetchListParams,
    ...params
  });
  if (monitoringPrices)
    return {
      ...monitoringPrices,
      data: monitoringPrices.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось получить список прайсов для мониторинга');
};

export const updateMonitoringPrice = async (
  monitoringPrice: MonitoringPrice
): Promise<MonitoringPrice> => {
  const updated = await fetchRequest.put(
    '/monitoringPrice',
    convertDataToServer(monitoringPrice)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить прайс мониторинга');
};

export const getMonitoringPrice = async (
  id: number
): Promise<MonitoringPrice> => {
  let washingVehicleType = await fetchRequest.get(`/monitoringPrice/${id}`);
  if (washingVehicleType) return convertDataFromServer(washingVehicleType);
  throw new Error('Не удалось загрузить прайс мониторинга');
};

export const deleteMonitoringPrice = async (
  id: number
): Promise<MonitoringPrice> => {
  const deleted = await fetchRequest.delete(`/monitoringPrice/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить прайс мониторинга');
};

export default {
  getMonitoringPriceByPeriod,
  addMonitoringPrice,
  fetcMonitoringPrice,
  updateMonitoringPrice,
  getMonitoringPrice,
  deleteMonitoringPrice
};
