// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input';
import { RangePicker } from '../../../components/ui';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  Selects,
} from './../../../components';
import Field from './../../../components/card/Field';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import { formatDateTimeToISOString } from './../../../lib/helpers';

const { SideServiceTypeSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type SideServiceTariffFilterParams = {
  sideServiceType?: string,
  startDate?: string,
  endDate?: string,
};

type Props = {
  filter: SideServiceTariffFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
};

export const SideServiceTariffFilter = ({
  filter,
  cleanFilter,
  applyFilter,
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams<SideServiceTariffFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <SideServiceTypeSelect
                  size="small"
                  onChange={(value) => changeValue('sideServiceType', value)}
                  value={values.sideServiceType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  startDate={
                    values.startDate ? moment.utc(values.startDate) : null
                  }
                  endDate={values.endDate ? moment.utc(values.endDate) : null}
                  onChange={changeValue}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default SideServiceTariffFilter;
