// @flow
import queryString from 'query-string';
import type {
  MeasuringDeviceCertification,
  ExecutionBudgetGroupedList
} from './../types';

import {
  fetchRequest,
  downloadRequestWithToken,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { MeasuringDeviceCertificationsFilterParams } from '../../containers/services/measuringDeviceCertification/Filter';

export const convertDataFromServer = (
  measuringDeviceCertification: MeasuringDeviceCertification
) => ({
  ...measuringDeviceCertification,
  paymentAmount: convertFromKopToRub(
    measuringDeviceCertification.paymentAmount
  ),
  act: measuringDeviceCertification.act
    ? actConvertDataFromServer(measuringDeviceCertification.act)
    : undefined
});

export const convertDataToServer = (
  measuringDeviceCertification: MeasuringDeviceCertification
) => ({
  ...measuringDeviceCertification,
  paymentAmount: convertFromRubToKop(measuringDeviceCertification.paymentAmount)
});

export const addMeasuringDeviceCertification = async (
  measuringDeviceCertification: MeasuringDeviceCertification
): Promise<MeasuringDeviceCertification> => {
  const added = await fetchRequest.post(
    '/measuringDeviceCertification',
    convertDataToServer(measuringDeviceCertification)
  );
  if (added) return added;
  throw new Error('Не удалось создать сертификацию средств измерений');
};

export const updateMeasuringDeviceCertification = async (
  measuringDeviceCertification: MeasuringDeviceCertification
): Promise<MeasuringDeviceCertification> => {
  const updated = await fetchRequest.put(
    '/measuringDeviceCertification',
    convertDataToServer(measuringDeviceCertification)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить сертификацию средств измерений');
};
export const deleteMeasuringDeviceCertification = async (
  id: number
): Promise<MeasuringDeviceCertification> => {
  const deleted = await fetchRequest.delete(
    `/measuringDeviceCertification/${id}`
  );
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить сертификацию средств измерений');
};

export const fetchMeasuringDeviceCertification = async (
  id: number,
  params: any = {}
): Promise<MeasuringDeviceCertification> => {
  const measuringDeviceCertification = await fetchRequest.get(
    `/measuringDeviceCertification/${id}`,
    params
  );
  if (measuringDeviceCertification)
    return convertDataFromServer(measuringDeviceCertification);
  throw new Error('Не удалось получить сертификацию средств измерений');
};

export const fetchVehicleMeasuringDeviceCertifications = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<MeasuringDeviceCertification>> => {
  const measuringDeviceCertifications = await fetchRequest.get(
    `/measuringDeviceCertification/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (measuringDeviceCertifications) {
    return {
      ...measuringDeviceCertifications,
      data: measuringDeviceCertifications.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список сертификаций средств измерений');
};

export const fetchMeasuringDeviceCertifications = async (
  params: FetchListParams<MeasuringDeviceCertification> = initialFetchListParams
): Promise<ListResponse<MeasuringDeviceCertification>> => {
  const measuringDeviceCertification = await fetchRequest.get(
    '/measuringDeviceCertification',
    {
      ...initialFetchListParams,
      ...params
    }
  );

  if (measuringDeviceCertification) {
    return {
      ...measuringDeviceCertification,
      data: measuringDeviceCertification.data.map(convertDataFromServer)
    };
  }

  throw new Error('Не удалось получить список сертификаций средств измерений');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<MeasuringDeviceCertification>> => {
  const measuringDeviceCertification = await fetchRequest.put(
    `/measuringDeviceCertification/updateActId?${queryString.stringify(
      params
    )}`,
    data
  );
  if (measuringDeviceCertification) {
    return {
      ...measuringDeviceCertification,
      data: measuringDeviceCertification.data.map(convertDataFromServer)
    };
  }
  throw new Error(
    'Не удалось закрепить акт за списоком сертификаций средств измерений'
  );
};

export const printReport = async (
  params: MeasuringDeviceCertificationsFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/measuringDeviceCertification/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(
    `/measuringDeviceCertification/totalSum`,
    params
  );
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить сертификацию средств измерений');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/measuringDeviceCertification/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export default {
  groupedList,
  getTotalSum,
  printReport,
  updateAct,
  addMeasuringDeviceCertification,
  updateMeasuringDeviceCertification,
  deleteMeasuringDeviceCertification,
  fetchMeasuringDeviceCertification,
  fetchVehicleMeasuringDeviceCertifications,
  fetchMeasuringDeviceCertifications
};
