// @flow
import React from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { maintenanceMatrixApi } from '../../lib/api';
import { getListInitialState } from './../../lib/helpers';
import type { MaintenanceMatrix, ListState, UserAccess } from '../../lib/types';
import {
  accessTypeEnum,
  orderContractorTypes,
  repairPlanTypes,
  maintenanceMatrixValidAction,
} from '../../lib/enum';
import { Popconfirm, Icon } from '../../components/ui';
import Table from '../../components/ui/Table';
import { Header, Section } from '../../components/layout';
import { notificationLoading } from './../../components/Notifications';

import { withUserAccess } from './../withUserAccess';

import Filter from './components/Filter';
import type { MaintenanceMatrixFilterParams } from './components/Filter';

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingMaintenanceMatrix,
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<MaintenanceMatrix> & {
  filter: MaintenanceMatrixFilterParams,
};

export class MaintenanceMatrixList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [
    {
      title: 'Номер',
      key: 'maintenanceMatrixId',
      dataIndex: 'id',
    },
    {
      title: 'Виды работ по обслуживание ТС',
      dataIndex: 'maintenanceWorkType.name',
    },
    {
      title: 'Способ выполнения',
      dataIndex: 'orderContractorType',
      render: (orderContractorType: string) =>
        orderContractorTypes[orderContractorType],
    },
    {
      title: 'График работ',
      dataIndex: 'repairPlan',
      render: (repairPlan: string) => repairPlanTypes[repairPlan],
    },
    {
      title: 'Подрядчика и договора',
      dataIndex: 'contractorRequired',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Описание неисправности',
      dataIndex: 'descriptionRequired',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Дефектная ведомость',
      dataIndex: 'defectiveStatementRequired',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Загрузка файлов',
      dataIndex: 'filesRequired',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Согласование деф. ведомости',
      dataIndex: 'approvingDefectiveStatementAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Согласование дет.деф. ведомости',
      dataIndex: 'approvingDetailedStatementAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Согласование карточки ремонта',
      dataIndex: 'approvingMaintenanceAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Печать деф.акта',
      dataIndex: 'printDefectiveActAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Печать деф. ведомости',
      dataIndex: 'printDefectiveStatementAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Печать дет.деф. ведомости',
      dataIndex: 'printDetailedDefectiveStatementAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Печать акта на ремонт хоз. способом',
      dataIndex: 'printRepairActAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Печать формы ОС-3',
      dataIndex: 'printFormOS3Allowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
    {
      title: 'Печать акта списания МПЗ',
      dataIndex: 'printActOffMPZAllowed',
      render: (value: string) => maintenanceMatrixValidAction[value],
    },
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteMaintenanceMatrix(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
      });
    }
    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetchMaintenanceMatrix(page)
    );
  }

  deleteMaintenanceMatrix = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await maintenanceMatrixApi.deleteMaintenanceMatrix(id);
      await this.fetchMaintenanceMatrix(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchMaintenanceMatrix = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await maintenanceMatrixApi.fetchMaintenanceMatrix({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  applyFilter = (filter: MaintenanceMatrixFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetchMaintenanceMatrix(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetchMaintenanceMatrix(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Матрица ремонтов</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() => navigate('/admin/maintenance-matrix/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchMaintenanceMatrix}
            fetchOnMount
            onRow={(row: MaintenanceMatrix) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/maintenance-matrix/${row.id}/edit`)
                  : navigate(`/admin/maintenance-matrix/${row.id}`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceMatrixList);
