// @flow
import React from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { stockPartApi } from '../../lib/api';
import {
  getListInitialState,
  toFixed,
  multipliedBy,
  formatRub,
} from '../../lib/helpers';
import { accessTypeEnum } from '../../lib/enum';
import type {
  StockPart,
  SpareParts,
  ListState,
  UserAccess,
  VehicleModel,
} from '../../lib/types';
import { Popconfirm, Icon, ButtonOperations } from '../../components/ui';
import Table from '../../components/ui/Table';
import { Header, Section } from '../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import type { StockPartFilterParams } from './components/Filter';
import ButtonsRow from '../../components/ui/ButtonsRow';

import { withUserAccess } from './../withUserAccess';

import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};
type State = ListState<StockPart> & {
  filter: StockPartFilterParams,
};

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingStockParts,
];

export class StockPartList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      className: 'vertical-align-top',
      render: (stockPartName: string, record: StockPart) =>
        record.sparePart ? record.sparePart.name : stockPartName,
    },
    {
      title: 'Номер детали',
      dataIndex: 'number',
      className: 'vertical-align-top',
      render: (number: string, record: StockPart) =>
        record.sparePart ? record.sparePart.catalogNumber : number,
    },
    {
      title: 'Стоимость',
      dataIndex: 'cost',
      className: 'vertical-align-top',
      render: (cost: StockPart, record: StockPart) => {
        return formatRub(
          cost ? cost : record.sparePart ? record.sparePart.price : 0
        );
      },
    },
    {
      title: 'Количество',
      dataIndex: 'count',
      className: 'vertical-align-top',
    },
    {
      title: 'Ед. измерения',
      dataIndex: 'sparePart',
      className: 'vertical-align-top',
      render: (sparePart: SpareParts) => sparePart?.unit?.name ?? null,
    },
    {
      title: 'Применяемость',
      dataIndex: 'sparePart.vehicleModels',
      width: '400px',
      className: 'vertical-align-top',
      render: (vehicleModels: any, record: any) => {
        const data = vehicleModels
          ?.map(
            (vehicleModel: VehicleModel) =>
              `${vehicleModel.name} ${vehicleModel.brandName}`
          )
          .join(', ');
        return record?.sparePart?.allVehicles ? (
          'Все модели'
        ) : (
          <div style={{ whiteSpace: 'pre-wrap' }}>{data}</div>
        );
      },
    },
    {
      title: 'Сумма',
      width: '50px',
      className: 'vertical-align-top',
      render: (record: StockPart) => {
        return formatRub(
          record.cost
            ? multipliedBy(record.cost, record.count)
            : record.sparePart
            ? multipliedBy(record.count, record.sparePart.price)
            : 0
        );
      },
    },
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteStockPart(id)}
          >
            <Icon type="trash" />
          </Popconfirm>
        ),
      });
    }
    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetchStockPart(page)
    );
  }

  deleteStockPart = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await stockPartApi.deleteStockPart(id);
      await this.fetchStockPart(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchStockPart = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await stockPartApi.fetchStockPart({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  onConfirmRequest = async (data: any) => {
    const checkDate = data.date;
    try {
      try {
        notificationLoading({
          message: 'Запрос данных',
          key: 'request',
        });
        const data = await stockPartApi.updateParusStockParts({
          checkDate,
        });
        if (data) {
          notification.success({ message: data.status });
        } else {
          notification.error('Ошибка запросы');
        }
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message,
        });
      }
    } finally {
      notification.close('request');
    }
  };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  applyFilter = (filter: StockPartFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () => this.fetchStockPart(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchStockPart(page)
    );
  };

  print = async () => {
    const { filter } = this.state;
    try {
      notificationLoading({
        message: 'Формируем файл для печати...',
        key: 'printing',
      });
      await stockPartApi.print(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={
            <h1>
              Перечень запасных частей и материалов для работ хоз. способом
            </h1>
          }
          right={
            canAdd && (
              <ButtonsRow>
                <Button onClick={this.print}>Печать</Button>
                <Button
                  type="primary"
                  onClick={() => navigate('/admin/stock-part/new')}
                >
                  Создать
                </Button>
              </ButtonsRow>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            // $FlowFixMe
            columns={this.columns}
            data={data}
            fetch={this.fetchStockPart}
            fetchOnMount
            onRow={(row: StockPart) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/stock-part/${row.id}/edit`)
                  : navigate(`/admin/stock-part/${row.id}`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(StockPartList);
