// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { pddQuestionApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any, disabledKeys?: number[] };

export default ({ filter = {}, disabledKeys = [], ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await pddQuestionApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={pddQuestionApi.get}
    notFoundText="Вопрос не найден"
    placeholder="Выберите вопрос"
    renderOption={(pddQuestion: any, Option) => (
      <Option
        key={pddQuestion.id}
        value={pddQuestion.id}
        pddQuestion={pddQuestion}
        disabled={disabledKeys.includes(pddQuestion.id)}
        className={
          disabledKeys.includes(pddQuestion.id)
            ? 'ant-select-dropdown-menu-item-selected'
            : ''
        }
      >
        {pddQuestion.question}
      </Option>
    )}
    {...selectProps}
  />
);
