import {
    MaintenanceCard,
    MaintenanceCardReadOnly,
    MaintenanceFiles,
    MaintenanceForm,
    MaintenanceList,
    MaintenanceOrder,
} from '../containers/Maintenance';
import {
    MaintenanceScheduleCalendarForm,
    MaintenanceScheduleList,
} from '../containers/MaintenanceSchedule';
import { accessTypeEnum } from '../lib/enum';

// ремонты
export default [
    {
        component: MaintenanceList,
        path: '/maintenances/journal',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingMaintenances,
            accessTypeEnum.creatingMaintenances,
            accessTypeEnum.viewingMaintenances,
        ],
    },
    {
        component: MaintenanceScheduleCalendarForm,
        path: '/maintenances/schedule',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingMaintenances,
            accessTypeEnum.creatingMaintenances,
            accessTypeEnum.viewingMaintenances,
        ],
    },
    {
        component: MaintenanceScheduleList,
        path: '/maintenances/schedule/:date',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingMaintenances,
            accessTypeEnum.creatingMaintenances,
            accessTypeEnum.viewingMaintenances,
        ],
    },
    {
        component: MaintenanceFiles,
        path: '/maintenances/journal/:vehicleIssueId/edit/files/:tag',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingMaintenances,
            accessTypeEnum.creatingMaintenances,
        ],
    },
    {
        component: MaintenanceCardReadOnly,
        path: '/maintenances/journal/read/:maintenanceId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenances,
        ],
    },
    {
        component: MaintenanceForm,
        path: '/maintenances/journal/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingMaintenances,
        ],
    },
    {
        component: MaintenanceCard,
        path: '/maintenances/journal/:maintenanceId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingMaintenances,
            accessTypeEnum.creatingMaintenances,
        ],
    },
    {
        component: MaintenanceOrder,
        path: '/maintenances/journal/:maintenanceId/order',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingMaintenances,
            accessTypeEnum.creatingMaintenances,
        ],
    },
];