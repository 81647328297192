// @flow

import React, { Component } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import { washingVehicleTypeApi } from './../../lib/api';
import { goBack } from './../../lib/helpers';

import { Panel } from './../../components/layout';
import Header from './../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';

import InnerForm from './components/InnerForm';
import type { WashingVehicleType } from '../../lib/types/washingPriceList';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id: ?string
};

type State = {
  washingVehicleType: ?WashingVehicleType
};

class WashingPriceListForm extends Component<Props, State> {
  state = {
    washingVehicleType: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.id, 10);
    if (id) {
      try {
        const wash = await washingVehicleTypeApi.fetchOne(id);
        this.setState({ washingVehicleType: wash });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  onSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.id) {
        await washingVehicleTypeApi.update(values);
      } else {
        await washingVehicleTypeApi.create(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/washing-vehicle-types');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => goBack('/admin/washing-vehicle-types');

  render() {
    const { washingVehicleType } = this.state;
    const { id } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/washing-vehicle-types">Типы ТС для моек</Crumb>
              {id ? (
                <Crumb to={`/admin/washing-vehicle-types/${id}`}>
                  Тип ТС для моек №{id}
                </Crumb>
              ) : (
                <Crumb to={`/admin/washing-vehicle-types/new`}>
                  Новый тип ТС для моек
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{id ? `Тип ТС для моек №${id}` : `Новый тип ТС для моек`}</h1>
        </StyledPanel>
        <InnerForm
          washingVehicleType={washingVehicleType}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default WashingPriceListForm;
