// @flow

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { type FormikProps } from 'formik';

import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import Switch from 'antd/lib/switch';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { TripMap, WaypointsForm, Selects, Card } from './../../../components';
import { Popconfirm } from './../../../components/ui';
import { Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import CancelButton from '../../../components/CancelButton';
import Popover from '../../../components/ui/Popover';
import Divider from '../../../components/layout/Divider';
import YesNoSelect, {
  yesNoValue,
} from '../../../components/selects/YesNoSelect';
import type { FormFieldType } from '../../../components/Form';
import Form from '../../../components/Form';
import {
  vehicleTypes,
  waypointTypeEnum,
  positionEnum,
  vehicleGroups,
  medicOwnerEnum,
  attachedEquipmentFuelTankTypeEnum,
  accessTypeEnum,
} from './../../../lib/enum';
import type {
  WayPoint,
  TripRange,
  Trip,
  KilometrageInfo,
  UserAccess,
} from './../../../lib/types';
import {
  formatDateTimeToISOString,
  formatDateTimeToString,
  calculateDateRangeString,
  convertFromMToKm,
  minus,
  plus,
  multipliedBy,
} from '../../../lib/helpers';
import { canShowEngineWorkHoursData } from '../../Vehicles/lib';
import { getMinMaxWaypointDateTime } from './../lib';
import TripRangeForm from '../TripRangeForm';
import {
  CarInfo,
  FormFooter,
  FormTripMapWrapper,
  InspectionGridItem,
  InspectionTitle,
  SectionContent,
  DatePicker,
  InputNumber,
  WaypointsWrapper,
  WarningIcon,
  ButtonWrapper,
} from './VerificationForm.elements';

const { Field } = Card;
const { EmployeeSelect, MedicOwnerTypeSelect } = Selects;

const StyledSpan = styled('span')`
  font-weight: 500;
  font-size: 14px;
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0px;
`;

const TripMapViewer = ({ geoJSON, distance, waypoints }) => (
  <FormTripMapWrapper>
    <TripMap geoJSON={geoJSON} waypoints={waypoints} />
    &nbsp;
    <p>Расчетный пробег: {convertFromMToKm(distance || 0).toFixed(2)} км.</p>
  </FormTripMapWrapper>
);

type FormProps = {
  userAccess: UserAccess[],
  // Путевой лист
  trip: $Shape<Trip>,
  // Функция для получения пробега из АвтоГрафа
  getKilometrage: () => Promise<?KilometrageInfo>,
  calculateRoute: Function,
  actualRouteGeometry: any,
  shiftEmployees: any,
  calculateWorkHoursFuelConsumption: (trip: Trip) => number,
  calculateDistanceFuelConsumption: (trip: Trip) => number,
  calculateFuelConsumption: (trip: Trip) => Trip,
  calculateAttachedEquipmentFuelConsumption: Function,
  onCancel: Function,
  onSubmit: Function,
  employeeId: number,
};

const InnerForm = ({
  calculateRoute,
  actualRouteGeometry,
  calculateFuelConsumption,
  calculateAttachedEquipmentFuelConsumption,
  getKilometrage,
  onCancel,
  trip,
  onSubmit,
  userAccess,
  employeeId,
}: FormProps) => {
  const [appliedKilometrageFromAutograf, setAppliedKilometrageFromAutograf] =
    useState(null);
  const [startSelectedMedicalType, setStartMedicalType] = useState(
    medicOwnerEnum.own
  );
  const [endSelectedMedicalType, setEndMedicalType] = useState(
    medicOwnerEnum.own
  );
  const [kilometragePopconfirmVisible, setKilometragePopconfirmVisible] =
    useState(false);
  const [monitoringDistance, setMonitoringDistance] = useState(0);
  const [monitoringMachineHours, setMonitoringMachineHours] = useState(0);
  const [sumFuelMultiplier, setSumFuelMultiplier] = useState(null);

  const canGetActualMileage = () => {
    return userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.getActualMileage,
      ].includes(access)
    );
  };

  // Если есть точки маршрута предзаполняем даты мед/тех осмотров если не заполнены
  if (trip.actualRoute && !isEmpty(trip.actualRoute.waypoints)) {
    const date = (name: string, value: ?Date | ?string) => {
      trip[`${name}MedicCheckupDate`] = isNil(trip[`${name}MedicCheckupDate`])
        ? value
        : trip[`${name}MedicCheckupDate`];
      trip[`${name}TechCheckupDate`] = isNil(trip[`${name}TechCheckupDate`])
        ? value
        : trip[`${name}TechCheckupDate`];
      if (trip.isGpm) {
        trip[`${name}GpmControllerCheckupDate`] = isNil(
          trip[`${name}GpmControllerCheckupDate`]
        )
          ? value
          : trip[`${name}GpmControllerCheckupDate`];
      }
    };
    trip.actualRoute.waypoints.forEach((point) => {
      if (point.type === waypointTypeEnum['start']) {
        date('start', point.departureDateTime);
      }
      if (point.type === waypointTypeEnum['end']) {
        date('end', point.arrivedDateTime);
      }
    });
  }

  useEffect(() => {
    setStartMedicalType(
      trip && trip.startMedicFullName
        ? medicOwnerEnum.hired
        : medicOwnerEnum.own
    );
    setEndMedicalType(
      trip && trip.endMedicFullName ? medicOwnerEnum.hired : medicOwnerEnum.own
    );
  }, [trip]);

  return (
    <Form initialValues={{ ...trip, sumFuelMultiplier }} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps<Trip>) => {
        const {
          values,
          setFieldValue,
          handleBlur,
          handleSubmit,
          dirty,
          isSubmitting,
        } = formikProps;

        // устнававливаем даты мед/тех осмотров
        const setDate = (waypoints: WayPoint[]) => {
          const setCheckupDate = (name: string, value: ?Date | ?string) => {
            setFieldValue(`${name}MedicCheckupDate`, value);
            setFieldValue(`${name}TechCheckupDate`, value);
            if (values.isGpm) {
              setFieldValue(`${name}GpmControllerCheckupDate`, value);
            }
          };
          waypoints.forEach((point: WayPoint) => {
            if (point.type === waypointTypeEnum.start) {
              setCheckupDate(
                'start',
                isEmpty(point.departureDateTime)
                  ? null
                  : point.departureDateTime
              );
            }
            if (point.type === waypointTypeEnum.end) {
              setCheckupDate(
                'end',
                isEmpty(point.arrivedDateTime) ? null : point.arrivedDateTime
              );
            }
          });
        };

        const canVerify = () => {
          const { actualRoute } = values;
          // Если пробег при заезде в гараж не введен
          if (!values.odometerAtEnd) {
            return false;
          }
          if (actualRoute) {
            const endWaypoint = actualRoute.waypoints.filter(
              (waypoint) => waypoint.type === waypointTypeEnum.end
            )[0];
            // Откидываем UTC таймзону для проверки времени
            const ISOWithoutUTC = 'YYYY-MM-DDTHH:mm:ss.SSSS';
            return moment(
              formatDateTimeToString(endWaypoint.arrivedDateTime, ISOWithoutUTC)
            ).isBefore(
              formatDateTimeToString(new Date(), ISOWithoutUTC, false)
            );
          }
        };

        let allWaypoints = [];
        let startWaypoint: ?WayPoint = null;
        if (values && values.actualRoute && values.actualRoute.waypoints) {
          allWaypoints = values.actualRoute.waypoints;

          startWaypoint = allWaypoints.find(
            (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.start
          );
        }
        const routeMinMaxDates = getMinMaxWaypointDateTime(
          values && values.actualRoute && values.actualRoute.waypoints
        );

        const distance = values.odometerAtEnd
          ? values.odometerAtEnd - values.odometerAtStart
          : 0;

        const canVerifyTrip = !canVerify();
        const canShowEngineHours = canShowEngineWorkHoursData(
          values?.vehicle?.vehicleModel?.type
        );
        const canShowAttachedEquipment = values?.attachedEquipments?.length > 0;
        return (
          <form>
            <Section>
              <SectionTitle divider>Транспортное средство</SectionTitle>
              <CarInfo>
                <Grid cols={4} media={[{ size: 'lg', cols: 5 }]}>
                  <GridItem>
                    <Field label="Тип ТС">
                      {vehicleTypes[values.vehicleType] ||
                        vehicleGroups[values.vehicleGroup]}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Марка ТС">
                      {values.vehicle &&
                        values.vehicle.vehicleModel &&
                        `${values.vehicle.vehicleModel.brandName} ${values.vehicle.vehicleModel.name}`}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Государственный регистрационный знак ТС">
                      {values.vehicle && values.vehicle.licensePlate}
                    </Field>
                  </GridItem>
                  {values.vehicle && values.vehicle.orgUnitName && (
                    <GridItem>
                      <Field label="Собственник машины">
                        {values.vehicle.orgUnitName}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="ФИО водителя">
                      {values.driver &&
                        values.driver.employee &&
                        `${values.driver.employee.lastname} ${values.driver.employee.firstname} ${values.driver.employee.middlename}`}
                    </Field>
                  </GridItem>
                </Grid>
              </CarInfo>
              <SectionTitle>Гараж</SectionTitle>
              <SectionContent>
                <Grid cols={2} gutter="16px">
                  {!!startWaypoint && (
                    <GridItem fullWidth>
                      <Field label="Отправление">
                        {formatDateTimeToString(
                          startWaypoint.departureDateTime,
                          'D MMMM YYYY, HH:mm'
                        )}
                        , {startWaypoint.name}, {startWaypoint.address}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
              <Divider />
              <SectionTitle
                suffix={
                  <Button type="primary" onClick={() => calculateRoute(values)}>
                    Построить маршрут
                  </Button>
                }
              >
                Маршрут
              </SectionTitle>
              <WaypointsWrapper>
                <Grid>
                  <GridItem>
                    <Field label="Суммарное время движения">
                      {calculateDateRangeString(
                        routeMinMaxDates.min,
                        routeMinMaxDates.max
                      )}
                    </Field>
                  </GridItem>
                  <GridItem fullWidth>
                    <FormField name="actualRoute.waypoints">
                      {({ value: waypoints }) => (
                        <WaypointsForm
                          waypoints={allWaypoints}
                          editTypes={[
                            waypointTypeEnum.transit,
                            waypointTypeEnum.end,
                          ]}
                          onChange={(waypoints: WayPoint[]) => {
                            setDate(waypoints);
                            setFieldValue('actualRoute.waypoints', waypoints);
                          }}
                          disableDates={false}
                          appendRows={[
                            actualRouteGeometry && (
                              <TripMapViewer
                                geoJSON={actualRouteGeometry.geoJSON}
                                waypoints={waypoints}
                                distance={actualRouteGeometry.distance}
                              />
                            ),
                          ]}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </WaypointsWrapper>
              <Divider />
              <SectionTitle
                suffix={
                  canGetActualMileage() && (
                    <Popconfirm
                      visible={kilometragePopconfirmVisible}
                      title={
                        <p>
                          Получен пробег по ПЛ: &nbsp;
                          {!isNaN(monitoringDistance) &&
                            parseFloat(monitoringDistance)
                              .toFixed(2)
                              .toLocaleString()}
                          &nbsp; км. <br />
                          {parseFloat(values.odometerAtEnd) > 0 && (
                            <>
                              Текущий пробег при заезде в гараж: &nbsp;
                              {parseFloat(values.odometerAtEnd)
                                .toFixed(2)
                                .toLocaleString()}
                              &nbsp;км.
                              <br />
                            </>
                          )}
                          {canShowEngineHours &&
                            !isNaN(monitoringMachineHours) && (
                              <>
                                Получены маш. часы по ПЛ: &nbsp;
                                {parseFloat(monitoringMachineHours)
                                  .toFixed(2)
                                  .toLocaleString()}
                                &nbsp; ч. <br />
                                {parseFloat(values.vehicleMachineHoursAtEnd) >
                                  0 && (
                                  <>
                                    Текущий пробег при заезде в гараж&nbsp;
                                    {parseFloat(values.vehicleMachineHoursAtEnd)
                                      .toFixed(2)
                                      .toLocaleString()}
                                    &nbsp;ч.
                                    <br />
                                  </>
                                )}
                              </>
                            )}
                          Вы хотите применить данные?
                        </p>
                      }
                      okText="Да"
                      cancelText="Нет"
                      onCancel={() => {
                        setKilometragePopconfirmVisible(false);
                        setFieldValue('odometerReaderEmployeeId', employeeId);
                        setAppliedKilometrageFromAutograf(false);
                      }}
                      onConfirm={() => {
                        const odometerAtEnd = parseFloat(
                          values.odometerAtStart + monitoringDistance
                        ).toFixed(2);
                        let vehicleMachineHoursAtEnd =
                          values.vehicleMachineHoursAtEnd;
                        if (canShowEngineHours) {
                          vehicleMachineHoursAtEnd = parseFloat(
                            values.vehicleMachineHoursAtStart +
                              monitoringMachineHours
                          ).toFixed(2);
                          if (!isNaN(vehicleMachineHoursAtEnd)) {
                            setFieldValue(
                              'vehicleMachineHoursAtEnd',
                              vehicleMachineHoursAtEnd
                            );
                          }
                        }
                        if (!isNaN(odometerAtEnd)) {
                          setFieldValue('odometerAtEnd', odometerAtEnd);
                        }
                        if (!isNaN(vehicleMachineHoursAtEnd)) {
                          setFieldValue(
                            'vehicleMachineHoursAtEnd',
                            vehicleMachineHoursAtEnd
                          );
                        }
                        const updatedTrip = calculateFuelConsumption({
                          ...values,
                          odometerAtEnd,
                          vehicleMachineHoursAtEnd,
                        });
                        const {
                          engineWorkFuelConsumption,
                          distanceFuelConsumption,
                          fuelAtEnd,
                        } = updatedTrip;
                        setAppliedKilometrageFromAutograf(true);
                        setFieldValue(
                          'engineWorkFuelConsumption',
                          engineWorkFuelConsumption
                        );
                        setFieldValue(
                          'distanceFuelConsumption',
                          distanceFuelConsumption
                        );
                        setFieldValue('fuelAtEnd', fuelAtEnd);
                        setKilometragePopconfirmVisible(false);
                      }}
                    >
                      <Popover content="Запросить пробег по GPS">
                        <Button
                          type="primary"
                          onClick={async () => {
                            const kilometrage = await getKilometrage();
                            // Если пришел пробег
                            if (kilometrage) {
                              setMonitoringDistance(
                                kilometrage.monitoringDistance
                              );
                              setMonitoringMachineHours(
                                kilometrage.monitoringMachineHours
                              );
                              setKilometragePopconfirmVisible(true);
                            } else {
                              notification.warning({
                                message:
                                  'Нет данных о пробеге по GPS. Введите пробег при заезде в гараж',
                              });
                              setAppliedKilometrageFromAutograf(false);
                              setFieldValue(
                                'odometerReaderEmployeeId',
                                employeeId
                              );
                            }
                          }}
                        >
                          Запросить пробег
                        </Button>
                      </Popover>
                    </Popconfirm>
                  )
                }
              >
                Пробег
              </SectionTitle>
              <SectionContent>
                <Grid cols={5} gutter="16px">
                  <GridItem>
                    <FormField
                      label="При выезде из гаража"
                      name="odometerAtStart"
                    >
                      {({ value }) => <InputNumber disabled value={value} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="odometerAtEnd"
                      label="При заезде в гараж"
                      required
                      // required={appliedKilometrageFromAutograf === false}
                    >
                      {({ name, value }) => (
                        <InputNumber
                          name={name}
                          onChange={(value) => setFieldValue(name, value)}
                          value={value}
                          min={values.odometerAtStart}
                          precision={2}
                          onBlur={(e) => {
                            const updatedTrip =
                              calculateFuelConsumption(values);
                            const {
                              engineWorkFuelConsumption,
                              distanceFuelConsumption,
                              fuelAtEnd,
                            } = updatedTrip;
                            setFieldValue(
                              'engineWorkFuelConsumption',
                              engineWorkFuelConsumption
                            );
                            setFieldValue(
                              'distanceFuelConsumption',
                              distanceFuelConsumption
                            );
                            setFieldValue('fuelAtEnd', fuelAtEnd);
                            handleBlur(e);
                          }}
                          // disabled={appliedKilometrageFromAutograf !== false}
                          placeholder="Пробег при заезде в гараж"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem span={2}>
                    <FormField
                      name="odometerReaderEmployeeId"
                      label="Работник, снявший показания"
                      // required={appliedKilometrageFromAutograf === false}
                    >
                      {({ name, value }) => (
                        <EmployeeSelect
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                          onBlur={() => handleBlur({ target: { name } })}
                          value={value}
                          placeholder="ФИО"
                          // disabled={() =>
                          //   appliedKilometrageFromAutograf !== false
                          // }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Примечание"
                      required
                      // required={appliedKilometrageFromAutograf === false}
                      name="monitoringNotation"
                    >
                      {(field) => (
                        <Input
                          {...field}
                          // disabled={appliedKilometrageFromAutograf !== false}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
              {canShowEngineHours && (
                <>
                  <Divider />
                  <SectionTitle>Маш. часы</SectionTitle>
                  <SectionContent>
                    <Grid cols={3} gutter="16px">
                      <GridItem>
                        <FormField
                          name="vehicleMachineHoursAtStart"
                          label="При выезде из гаража"
                        >
                          {({ name, value }) => (
                            <InputNumber
                              disabled
                              name={name}
                              onBlur={handleBlur}
                              value={value}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="При заезде в гараж"
                          name="vehicleMachineHoursAtEnd"
                          required
                          validate={(value: number) => {
                            if (
                              values.vehicle &&
                              parseFloat(
                                values.vehicle.vehicleModel
                                  .primaryEquipmentFuelConsumption
                              ) > 0
                            ) {
                              if (
                                value <
                                parseFloat(values.vehicleMachineHoursAtStart)
                              ) {
                                return 'Значение не может быть меньше начального значения';
                              }
                            }
                          }}
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              // disabled={
                              //   appliedKilometrageFromAutograf !== false
                              // }
                              min={values.vehicleMachineHoursAtStart || 0}
                              onChange={(value) => setFieldValue(name, value)}
                              precision={2}
                              value={value}
                              onBlur={(e) => {
                                const { fuelAtEnd, engineWorkFuelConsumption } =
                                  calculateFuelConsumption(values);
                                setFieldValue('fuelAtEnd', fuelAtEnd);
                                setFieldValue(
                                  'engineWorkFuelConsumption',
                                  engineWorkFuelConsumption
                                );
                                handleBlur(e);
                              }}
                              placeholder="Показания счетчика маш. часов при заезде в гараж"
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </>
              )}
              {canShowAttachedEquipment ? (
                <>
                  <Divider />
                  <SectionTitle>Навесное оборудование</SectionTitle>
                  <SectionContent>
                    {values.attachedEquipments.map(
                      (
                        {
                          attachedEquipment,
                          machineHoursAtStart,
                          machineHoursAtEnd,
                        },
                        index
                      ) => {
                        const isOwnTank =
                          attachedEquipment.fuelTankType ===
                          attachedEquipmentFuelTankTypeEnum.ownTank;
                        return (
                          <Grid cols={5} gutter="16px">
                            <GridItem fullWidth>
                              <StyledSpan>
                                {attachedEquipment.name}
                                {attachedEquipment?.brandName
                                  ? `(${attachedEquipment?.brandName}}`
                                  : ''}
                              </StyledSpan>
                              <StyledDivider />
                            </GridItem>

                            <GridItem>
                              <FormField label="Отработано" required>
                                {({ name, value }) => (
                                  <InputNumber
                                    min={0}
                                    onChange={(value) => {
                                      const machineHoursAtEnd = plus(
                                        machineHoursAtStart,
                                        value ?? 0
                                      );
                                      setFieldValue(
                                        `attachedEquipments[${index}].machineHoursAtEnd`,
                                        machineHoursAtEnd
                                      );
                                      isOwnTank &&
                                        setFieldValue(
                                          `attachedEquipments[${index}].fuelAmountAtEnd`,
                                          calculateAttachedEquipmentFuelConsumption(
                                            {
                                              ...values.attachedEquipments[
                                                index
                                              ],
                                              machineHoursAtEnd,
                                            }
                                          ).fuelAmountAtEnd
                                        );
                                    }}
                                    precision={1}
                                    value={minus(
                                      values.attachedEquipments[index]
                                        .machineHoursAtEnd,
                                      values.attachedEquipments[index]
                                        .machineHoursAtStart
                                    )}
                                    onBlur={(e) => {
                                      if (!isOwnTank) {
                                        const {
                                          fuelAtEnd,
                                          engineWorkFuelConsumption,
                                        } = calculateFuelConsumption(values);
                                        setFieldValue('fuelAtEnd', fuelAtEnd);
                                        setFieldValue(
                                          'engineWorkFuelConsumption',
                                          engineWorkFuelConsumption
                                        );
                                      }
                                      handleBlur(e);
                                    }}
                                    placeholder="Введите показания"
                                  />
                                )}
                              </FormField>
                            </GridItem>
                            {isOwnTank && (
                              <GridItem>
                                <FormField
                                  label="Выдано топлива, л"
                                  required
                                  name={`attachedEquipments[${index}].fuelIssued`}
                                >
                                  {({ name, value }) => (
                                    <InputNumber
                                      name={name}
                                      min={0}
                                      onChange={(value) => {
                                        const {
                                          fuelAmountAtStart,
                                          attachedEquipment,
                                        } = values.attachedEquipments[index];
                                        setFieldValue(name, value);
                                        setFieldValue(
                                          `attachedEquipments[${index}].fuelAmountAtEnd`,
                                          calculateAttachedEquipmentFuelConsumption(
                                            {
                                              ...values.attachedEquipments[
                                                index
                                              ],
                                              fuelIssued: value,
                                            }
                                          ).fuelAmountAtEnd
                                        );
                                      }}
                                      precision={1}
                                      value={value}
                                      placeholder="Введите показания"
                                    />
                                  )}
                                </FormField>
                              </GridItem>
                            )}
                            <GridItem>
                              <FormField label="Фактический расход">
                                {({ name, value }) => (
                                  <InputNumber
                                    disabled
                                    value={multipliedBy(
                                      minus(
                                        machineHoursAtEnd,
                                        machineHoursAtStart
                                      ),
                                      attachedEquipment.fuelConsumption
                                    )}
                                  />
                                )}
                              </FormField>
                            </GridItem>
                            {isOwnTank && (
                              <GridItem>
                                <FormField
                                  label="Остаток"
                                  required
                                  name={`attachedEquipments[${index}].fuelAmountAtEnd`}
                                >
                                  {({ name, value }) => {
                                    return (
                                      <InputNumber
                                        disabled
                                        value={value}
                                        placeholder="Введите показания"
                                      />
                                    );
                                  }}
                                </FormField>
                              </GridItem>
                            )}
                          </Grid>
                        );
                      }
                    )}
                  </SectionContent>
                </>
              ) : (
                <>
                  <Divider />
                  <SectionTitle>Навесное оборудование отсутствует</SectionTitle>
                </>
              )}
              <Divider />
              <SectionTitle>Расход ГСМ</SectionTitle>
              <SectionContent>
                <Grid cols={4} gutter="16px">
                  {values.vehicleId && (
                    <GridItem fullWidth>
                      <Field>
                        <TripRangeForm
                          vehicleId={values.vehicleId}
                          tripId={values.id}
                          onChange={(
                            sumFuelMultiplier: ?number,
                            tripRanges: TripRange[]
                          ) => {
                            // Записывам итоговый коэффициент в трип
                            setSumFuelMultiplier(sumFuelMultiplier);
                            setFieldValue(
                              'sumFuelMultiplier',
                              sumFuelMultiplier
                            );
                            setFieldValue('tripRanges', tripRanges);
                            const { fuelAtEnd, distanceFuelConsumption } =
                              calculateFuelConsumption({
                                ...values,
                                tripRanges,
                                sumFuelMultiplier,
                              });
                            setFieldValue(
                              'distanceFuelConsumption',
                              distanceFuelConsumption
                            );
                            setFieldValue('fuelAtEnd', fuelAtEnd);
                          }}
                          distance={distance}
                        />
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField
                      name="fuelAtStart"
                      label="Уровень топлива при выезде, л"
                      defaultValue={0}
                    >
                      {({ name, value }) => (
                        <InputNumber
                          disabled
                          onBlur={handleBlur}
                          name={name}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Выдано топлива, л"
                      required
                      name="issuedFuel"
                      defaultValue={0}
                    >
                      {({ name, value }) => (
                        <InputNumber
                          value={value}
                          name={name}
                          onChange={(value) => setFieldValue(name, value)}
                          onBlur={(e) => {
                            const { fuelAtEnd } =
                              calculateFuelConsumption(values);
                            setFieldValue('fuelAtEnd', fuelAtEnd);
                            handleBlur(e);
                          }}
                          placeholder="Выдано топлива, л"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="fuelAtEnd"
                      label="Уровень топлива при заезде, л"
                      required
                      validate={(value: string) => {
                        const fuelAtEnd = parseFloat(value);
                        if (fuelAtEnd < 0 || !fuelAtEnd) {
                          return 'Неверное количество топлива';
                        }
                      }}
                    >
                      {({ name, value }) => (
                        <InputNumber
                          disabled
                          value={value}
                          name={name}
                          placeholder="Показания ГСМ при заезде в гараж"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Фактический расход топлива, л">
                      {() => (
                        <InputNumber
                          disabled
                          value={(
                            (values.engineWorkFuelConsumption || 0) +
                            (values.distanceFuelConsumption || 0)
                          ).toFixed(2)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
              <Divider />
              <SectionTitle>Иное</SectionTitle>
              <SectionContent>
                <Grid cols={2} gutter="16px">
                  <GridItem fullWidth>
                    <Field label="На сторону">
                      {values.toOtherSide ? 'Да' : 'Нет'}
                    </Field>
                    {/* <FormField
                      label="На сторону"
                      name="toOtherSide"
                      defaultValue={yesNoValue.no}
                    >
                      {({ name, value }) => (
                        <YesNoSelect
                          onChange={(value: boolean) =>
                            setFieldValue(name, value)
                          }
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                        />
                      )}
                    </FormField> */}
                  </GridItem>
                </Grid>
              </SectionContent>
              <Divider />

              <SectionTitle>Медосмотр</SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  <InspectionGridItem>
                    <InspectionTitle>Предрейсовый медосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField label="Вид мед. работника">
                          {({ value }) => (
                            <MedicOwnerTypeSelect
                              value={startSelectedMedicalType}
                              onChange={(value) => {
                                setStartMedicalType(value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        {startSelectedMedicalType === medicOwnerEnum.own ? (
                          <FormField
                            label="Мед. работник"
                            required
                            name="startMedicId"
                            fast
                          >
                            {({ name, value }) => (
                              <EmployeeSelect
                                placeholder="ФИО"
                                onChange={(value: string) =>
                                  setFieldValue(name, value)
                                }
                                name={name}
                                value={value}
                                onBlur={() => handleBlur({ target: { name } })}
                                filter={{
                                  positions: [positionEnum.medic],
                                }}
                              />
                            )}
                          </FormField>
                        ) : (
                          <FormField
                            label="Мед. работник"
                            name="startMedicFullName"
                          >
                            {({ name, value }) => (
                              <Input
                                name={name}
                                value={value}
                                onChange={(e) =>
                                  setFieldValue(name, e.target.value)
                                }
                              />
                            )}
                          </FormField>
                        )}
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="startMedicCheckupDate"
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY'
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                  <InspectionGridItem>
                    <InspectionTitle>Послерейсовый медосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField label="Вид мед. работника">
                          {({ value }) => (
                            <MedicOwnerTypeSelect
                              value={endSelectedMedicalType}
                              onChange={(value) => {
                                setEndMedicalType(value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        {endSelectedMedicalType === medicOwnerEnum.own ? (
                          <FormField
                            label="Мед. работник"
                            required
                            fast
                            name="endMedicId"
                          >
                            {({ name, value }) => (
                              <EmployeeSelect
                                placeholder="ФИО"
                                onChange={(value: string) =>
                                  setFieldValue(name, value)
                                }
                                name={name}
                                value={value}
                                filter={{
                                  positions: [positionEnum.medic],
                                }}
                              />
                            )}
                          </FormField>
                        ) : (
                          <FormField
                            label="Мед. работник"
                            name="endMedicFullName"
                          >
                            {({ name, value }) => (
                              <Input
                                name={name}
                                value={value}
                                onChange={(e) =>
                                  setFieldValue(name, e.target.value)
                                }
                              />
                            )}
                          </FormField>
                        )}
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="endMedicCheckupDate"
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY'
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                </Grid>
              </SectionContent>
              <Divider />
              <SectionTitle>
                Контроль технического состояния машины
              </SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  <InspectionGridItem>
                    <InspectionTitle>Предрейсовый техосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField
                          label="Механик"
                          required
                          name="startMechanicId"
                          fast
                        >
                          {({ name, value }) => (
                            <EmployeeSelect
                              placeholder="ФИО"
                              onChange={(value: string) =>
                                setFieldValue(name, value)
                              }
                              onBlur={() => handleBlur({ target: { name } })}
                              name={name}
                              value={value}
                              filter={{
                                positions: [positionEnum.mechanic],
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="startTechCheckupDate"
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY'
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                  <InspectionGridItem>
                    <InspectionTitle>Послерейсовый техосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField
                          label="Механик"
                          required
                          name="endMechanicId"
                          fast
                        >
                          {({ name, value }) => (
                            <EmployeeSelect
                              placeholder="ФИО"
                              onChange={(value: string) =>
                                setFieldValue(name, value)
                              }
                              onBlur={() => handleBlur({ target: { name } })}
                              name={name}
                              value={value}
                              filter={{
                                positions: [positionEnum.mechanic],
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Состояние ТС"
                          defaultValue={true}
                          name="isExploitableVehicle"
                        >
                          {({ name, value }) => (
                            <Switch
                              checkedChildren="Исправен"
                              unCheckedChildren="Неисправен"
                              defaultChecked
                              name={name}
                              onChange={(checked: boolean) =>
                                setFieldValue(name, checked)
                              }
                              checked={value}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="endTechCheckupDate"
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              name={name}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY'
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                </Grid>
              </SectionContent>
              {values.isGpm && (
                <>
                  <Divider />
                  <SectionTitle>Контроль ГПМ</SectionTitle>
                  <SectionContent>
                    <Grid gutter="16px">
                      <InspectionGridItem>
                        <InspectionTitle>Предрейсовый осмотр</InspectionTitle>
                        <Grid cols={1}>
                          <GridItem>
                            <FormField
                              label="Контролёр ГПМ"
                              required
                              fast
                              name="startGpmControllerId"
                            >
                              {({ name, value }) => (
                                <EmployeeSelect
                                  placeholder="ФИО"
                                  onChange={(value: string) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={() =>
                                    handleBlur({ target: { name } })
                                  }
                                  name={name}
                                  value={value}
                                  filter={{
                                    positions: [positionEnum.gpmController],
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="Дата"
                              required
                              name="startGpmControllerCheckupDate"
                            >
                              {({ name, value }) => (
                                <DatePicker
                                  format="D MMMM YYYY"
                                  value={value ? moment.utc(value) : value}
                                  onChange={(
                                    value: Object,
                                    dateString: string
                                  ) => {
                                    const dateTime = formatDateTimeToISOString(
                                      value,
                                      dateString,
                                      'D MMMM YYYY'
                                    );
                                    setFieldValue(name, dateTime);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </InspectionGridItem>
                      <InspectionGridItem>
                        <InspectionTitle>
                          Послерейсовый техосмотр
                        </InspectionTitle>
                        <Grid cols={1}>
                          <GridItem>
                            <FormField
                              label="Контролёр ГПМ"
                              required
                              fast
                              name="endGpmControllerId"
                            >
                              {({ name, value }) => (
                                <EmployeeSelect
                                  placeholder="ФИО"
                                  onChange={(value: string) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={() =>
                                    handleBlur({ target: { name } })
                                  }
                                  name={name}
                                  value={value}
                                  filter={{
                                    positions: [positionEnum.gpmController],
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="Дата"
                              required
                              name="endGpmControllerCheckupDate"
                            >
                              {({ name, value }) => (
                                <DatePicker
                                  format="D MMMM YYYY"
                                  value={value ? moment.utc(value) : value}
                                  onChange={(
                                    value: Object,
                                    dateString: string
                                  ) => {
                                    const dateTime = formatDateTimeToISOString(
                                      value,
                                      dateString,
                                      'D MMMM YYYY'
                                    );
                                    setFieldValue(name, dateTime);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </InspectionGridItem>
                    </Grid>
                  </SectionContent>
                </>
              )}
            </Section>
            <FormFooter>
              <ButtonWrapper>
                <Button
                  disabled={canVerifyTrip || isSubmitting}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  type="primary"
                >
                  Таксировать
                </Button>
                {canVerifyTrip && (
                  <Popover
                    width={200}
                    content={
                      !values.odometerAtEnd
                        ? 'Нельзя таксировать путевой лист без показаний пробега при заезде в гараж'
                        : 'Нельзя таксировать путевой лист раньше времени окончания маршрутного задания.'
                    }
                  >
                    <WarningIcon type="warning" />
                  </Popover>
                )}
              </ButtonWrapper>
              <CancelButton dirty={dirty} type="plain" onClick={onCancel}>
                Отменить
              </CancelButton>
            </FormFooter>
          </form>
        );
      }}
    </Form>
  );
};

export default InnerForm;
