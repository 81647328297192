import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import notification from 'antd/lib/notification';
import { Icon } from '../components/ui';

export const anyNotificationWithFeedback = async (
  func: (any) => any,
  params: any
) => {
  try {
    notification.open({
      ...params,
      icon: <Icon style={{ color: '#286EF6' }} type="loading" />,
      key: 'anyNotification',
    });
    const { description } = await func();
    description && notification.info({ description });
  } catch (error) {
    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  } finally {
    notification.close('anyNotification');
  }
};

export const printNotification = async (func: (any) => any) => {
  try {
    notification.open({
      message: 'Формирование печатной формы...',
      key: 'printing',
      duration: 0,
      icon: <LoadingOutlined style={{ color: '#286EF6' }} />,
    });

    await func();
  } catch (error) {
    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  } finally {
    notification.close('printing');
  }
};

export const saveNotification = async (func: (any) => any) => {
  try {
    notification.open({
      message: 'Сохранение данных...',
      key: 'saving',
      duration: 0,
      icon: <LoadingOutlined style={{ color: '#286EF6' }} />,
    });

    await func();
  } catch (error) {
    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  } finally {
    notification.close('saving');
  }
};

export const changeNotification = async (func: (any) => any) => {
  try {
    notification.open({
      message: 'Смена статуса...',
      key: 'changeStatus',
      duration: 0,
      icon: <LoadingOutlined style={{ color: '#286EF6' }} />,
    });

    await func();
  } catch (error) {
    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  } finally {
    notification.close('changeStatus');
  }
};
