import React, { useState } from 'react';

import type { FileInfo } from './../../../../lib/types';
import orderBy from 'lodash/orderBy';

import ModalSlider from './../../../components/ui/ModalSlider';
import { prepareSliderItem } from './../../Vehicles/lib';

type Props = {
  files: FileInfo[],
  vehicleIssueId: number,
  noStyle: boolean
};

const ModalImage = ({ noStyle, files, vehicleIssueId }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const style = {
    fontSize: '13px',
    color: '#2770ff',
    top: '8px',
    left: '5px',
    position: 'relative',
    cursor: 'pointer'
  };
  return (
    <>
      {showModal && (
        <ModalSlider
          show={showModal}
          handleModalVisible={setShowModal}
          items={orderBy(files, 'createdTime', ['desc']).map(prepareSliderItem)}
        />
      )}
      <span style={noStyle ? {} : style} onClick={() => setShowModal(true)}>
        Просмотреть файлы
      </span>
    </>
  );
};

export default ModalImage;
