// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { BudgetVersion } from './../../lib/types';
import { budgetVersionApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: { IsAvailableForSelect: boolean } };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    placeholder="Выберите версию"
    fetch={async params => {
      return await budgetVersionApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={budgetVersionApi.get}
    notFoundText="Версии бюджета не найдены"
    renderOption={(budgetVersion: BudgetVersion, Option) => (
      <Option key={budgetVersion.id} value={budgetVersion.id}>
        {budgetVersion.name}
      </Option>
    )}
    {...selectProps}
  />
);
