// @flow

import { WarningOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';
import notification from 'antd/lib/notification';
import React, { Component } from 'react';
import styled from 'styled-components';
import { notificationLoading } from '../../../../components/Notifications';

import Popover from '../../../../components/ui/Popover';

import { COLUMNS_MONTH } from '../lib';

import { batteryCalculationApi } from './../../../../lib/api';
import type { BatteryCalculation } from './../../../../lib/types';
import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

const StyledIcon = styled(WarningOutlined)`
  position: relative;
  top: -1px;
  right: -4px;
`;

class BudgetVehicleBattery extends Component<Props, {}> {
  handlePrint = async (filter: any) => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'print'
      });
      await batteryCalculationApi.print(vehiclePlanId, filter);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось выполнить'
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location, vehiclePlanId } = this.props;
    const columns = [
      ...COLUMNS_MONTH.map(column =>
        column.key === 'licensePlate'
          ? {
              ...column,
              render: (licensePlate: string, record: BatteryCalculation) => {
                return record.hasNoCost
                  ? licensePlate && (
                      <Popover
                        placement="right"
                        width={150}
                        zIndex={3000}
                        content={
                          'Необходимо проверить наличие аналога АКБ или стоимости в справочнике МТРиО'
                        }
                      >
                        <Link
                          to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}
                        >
                          {licensePlate}{' '}
                        </Link>
                        <StyledIcon
                          type="warning"
                          style={{ color: '#eccd0e' }}
                        />
                      </Popover>
                    )
                  : licensePlate && (
                      <Link
                        to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}
                      >
                        {licensePlate}
                      </Link>
                    );
              }
            }
          : { ...column }
      )
    ];
    return (
      <Common
        location={location}
        columns={columns}
        vehiclePlanId={vehiclePlanId}
        calcScrollY={400}
        crud={batteryCalculationApi}
        statusField={'batteryCalculationStatus'}
        title={'План расхода АКБ'}
        handlePrint={this.handlePrint}
      />
    );
  }
}

export default BudgetVehicleBattery;
