// @flow

import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import qs from 'query-string';
import React, { Component } from 'react';
import styled from 'styled-components';
import { MonthPicker } from '../../components/ant/DatePicker';
import { Calendar } from '../../components/Calendar';

import { Section, SectionTitle } from '../../components/layout';
import Header from '../../components/layout/Header';
import { notificationLoading } from '../../components/Notifications';
import { maintenanceApi, vehicleApi } from '../../lib/api';

import type { MaintenanceSchedule, UserAccess } from '../../lib/types';
import { isCurrentMonth } from './../../components/Calendar/helpers';
import { setQueryParams } from './../../lib/helpers';

const Wrapper = styled.div`
  padding: 10px;
  padding-top: 0;
`;
const Day = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
const DayCount = styled.p`
  color: ${(props) => props.color || '#0093fe'};
  display: inline-block;
  text-align: right;
`;
const DayTitle = styled.p`
  font-weight: normal;
  font-size: 14px;
  text-transform: uppercase;
  color: #4f4f4f;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  position: relative;
  top: 6px;
  text-align: right;
  flex: 1;
`;
const DayTick = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4px 0;
`;
const DayCounts = styled.div`
  margin: 0px 4px 0 4px;
  padding-top: 30px;
  display: flex;
  width: 100%;
  margin-left: -22px;
  flex-direction: column;
  font-weight: 500;
`;
const DayInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 27px;
`;

const SpaceBetween = styled.div`
  flex-grow: 1;
`;

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = {
  currentDate: string,
  days: MaintenanceSchedule[],
  loading: boolean,
};

export default class CalendarForm extends Component<Props, State> {
  state: State = {
    currentDate: new Date().toISOString(),
    days: [],
    loading: false,
  };

  componentDidMount = async () => {
    let { currentDate } = qs.parse(window.location.search);
    if (currentDate) {
      this.setState(
        { currentDate },
        async () => await this.fetchData(this.state.currentDate)
      );
    } else {
      await this.fetchData(this.state.currentDate);
    }
  };

  fetchData = async (currentDate: string) => {
    try {
      this.setState({ loading: true });
      const days = await vehicleApi.getMaintenanceSchedule(currentDate);
      this.setState({ days, loading: false });
    } catch (e) {
      notification.error({
        message: 'Ошибка запроса данных',
        description: e.message,
      });
    }
  };

  componentDidUpdate(prevProps: Props, prevState: State) {}

  getDayByDate = (date: Date) => {
    return this.state.days.find((day) => moment(day.date).isSame(date, 'day'));
  };

  renderDay = (date: Date) => {
    const day = this.getDayByDate(date);
    if (day) {
      const {
        planCount,
        workCount,
        isOverdue,
        doneCount,
        regulationCount,
        date,
      } = day;
      return (
        <Day>
          <DayInfo>
            <div>{new Date(date).getDate()}</div>
            <SpaceBetween />
            {isOverdue && (
              <ExclamationCircleTwoTone
                twoToneColor="red"
                style={{
                  alignItems: "flex-end",
                  fontSize: "13px",
                  position: "relative",
                  top: "4px",
                  left: "-8px",
                }}
              />
            )}
          </DayInfo>
          <DayCounts>
            {planCount ? (
              <DayTick>
                <DayTitle>План</DayTitle>
                <DayCount>{planCount}</DayCount>
              </DayTick>
            ) : null}
            {workCount ? (
              <DayTick>
                <DayTitle>В работе</DayTitle>
                <DayCount color="#7EAAFF">{workCount}</DayCount>
              </DayTick>
            ) : null}
            {doneCount ? (
              <DayTick>
                <DayTitle>Выполнено</DayTitle>
                <DayCount color="#22D397">{doneCount}</DayCount>
              </DayTick>
            ) : null}
            {regulationCount ? (
              <DayTick>
                <DayTitle>Регламент</DayTitle>
                <DayCount color="#A1C1FF">{regulationCount}</DayCount>
              </DayTick>
            ) : null}
          </DayCounts>
        </Day>
      );
    }
    return null;
  };

  renderMonthPicker = () => (
    <MonthPicker
      format="MMMM YYYY"
      value={moment(this.state.currentDate)}
      placeholder="Период графика"
      onChange={(value: Object, dateString: string) => {
        const currentDate = value
          ? moment(value).utc().toISOString()
          : new Date().toISOString();
        setQueryParams({ currentDate });
        this.setState({ currentDate }, () => {
          this.fetchData(this.state.currentDate);
        });
      }}
    />
  );

  handlePrint = async () => {
    try {
      notificationLoading({
        key: 'printing',
        description: 'Подготовка отчета для скачивания...',
      });
      await maintenanceApi.printSchedule();
    } catch (error) {
      notification.error({
        message: error.description,
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  render() {
    const { loading, currentDate } = this.state;
    const windowDate = new Date(currentDate);
    return (
      <>
        <Header
          left={
            <>
              <h1 style={{ whiteSpace: 'nowrap', margin: '0 40px 0 0' }}>
                График ТО за
              </h1>
              {this.renderMonthPicker()}
            </>
          }
          right={
            <Button type="primary" onClick={this.handlePrint}>
              Печать графика ТО
            </Button>
          }
        />
        <Section>
          <SectionTitle>
            {moment(windowDate).format('MMMM YYYY').toLocaleUpperCase()}
          </SectionTitle>
          <Wrapper>
            <Calendar
              loading={loading}
              onClick={(date) =>
                isCurrentMonth(windowDate, date)
                  ? navigate(
                      `/maintenances/schedule/${date.valueOf() + 43200000}`
                    )
                  : undefined
              }
              windowDate={windowDate}
              renderDay={this.renderDay}
            />
          </Wrapper>
        </Section>
      </>
    );
  }
}
