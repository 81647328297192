import { FuelsAndOilsForm, FuelsAndOilsList } from '../../containers/FuelsAndOils';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: FuelsAndOilsList,
        path: '/admin/fuels-and-oils',
        access: [accessTypeEnum.admin],
    },
    {
        component: FuelsAndOilsForm,
        path: '/admin/fuels-and-oils/new',
        access: [accessTypeEnum.admin],
    },
    {
        component: FuelsAndOilsForm,
        path: '/admin/fuels-and-oils/:fuelAndOilId',
        access: [accessTypeEnum.admin],
    },
];