// @flow
import type { RequestSection } from '../requests';
import RequestsController from '../requests';

export class Messages {
  controller: RequestSection;

  constructor(controller: RequestsController) {
    this.controller = controller.getApiSection('messages');
  }

  /**
   * Подгрузка последнего сообщения
   * @param params Параметры запроса
   */
  async loadLast(params: {|
    // ID объекта (зависит от типа сообщений которые нужно получить)
    itemId: number,
    // время, для которого запрашиваются сообщения
    lastTime: number,
    // количество сообщений, которое будет загружено
    lastCount: number,
    // количество сообщений, которое будет возвращено
    loadCount: number,
    // будут загружены только указанные типы сообщений
    flags: number,
    // маска
    flagsMask: number
  |}) {
    return await this.controller.fetch('load_last', params);
  }

  /**
   * Загрузить сообщения за интервал
   */
  async loadInterval(params: {|
    // ID объекта или ресурса (зависит от типа сообщений которые нужно получить)
    itemId: number,
    // начало интервала
    timeFrom: number,
    // окончание интервала
    timeTo: number,
    // флаги
    flags: number,
    // маска
    flagsMask: number,
    // количество сообщений, которое будет возвращено (0xffffffff - все найденные)
    loadCount: number
  |}) {
    return await this.controller.fetch('load_interval', {
      flag: 1,
      flagsMask: 65281,
      loadCount: 4294967295,
      ...params
    });
  }

  /**
   * Получить сообщения
   */
  async getMessages(params: {|
    // ID объекта или ресурса (зависит от типа сообщений которые нужно получить)
    itemId: number,
    // начало интервала
    timeFrom: number,
    // окончание интервала
    timeTo: number,
    // флаги
    flags: number,
    // маска
    flagsMask: number,
    // количество сообщений, которое будет возвращено (0xffffffff - все найденные)
    loadCount: number
  |}) {
    return await this.controller.fetch('load_interval', params);
  }
}
