// @flow

import { TreeSelectProps } from 'antd/lib/tree-select';
import React, { Component } from 'react';

import { TreeSelect as AntdTreeSelectSelect } from '../ant/Select';

type Props = TreeSelectProps & {
  size?: string,
  dropdownStyle?: any,
};

export class TreeSelect extends Component<Props> {
  render() {
    return (
      <AntdTreeSelectSelect
        dropdownStyle={{ minWidth: '150px' }}
        {...this.props}
      />
    );
  }
}

export default TreeSelect;
