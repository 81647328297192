// @flow
import React, { type ComponentType, type Node } from 'react';
import styled, { css } from 'styled-components';

type sizes = 'sm' | 'md' | 'lg' | string;

const getGridTemplate = (cols: Columns = 2) => {
  if (Array.isArray(cols)) {
    return cols.join(' ');
  } else return `repeat(${cols}, minmax(1px, 1fr))`;
};

/**
 * Либо количество колонок
 * Либо массив с шириной столбцов
 */
type Columns = number | string[];

type MediaQuery = {
  size: sizes,
  // Количество колонок
  cols?: Columns,
  // Отступ между колонками
  gutter?: string,
  // Отступ между рядами
  rowGutter?: string
};

/**
 * Набор заданных переходов
 */
const mediaQueries = {
  sm: '(min-width: 320px) and (max-767px)',
  md: '(min-width: 768px) and (max-width: 1023px)',
  lg: '(min-width: 1024px)'
};

const parseMedia = (mediaColumns: MediaQuery[]) => {
  return mediaColumns
    .map(
      media => `
      @media screen and ${mediaQueries[media.size] || media.size} {
        grid-template-columns: ${getGridTemplate(media.cols)};
        column-gap: ${media.gutter !== undefined ? media.gutter : 'invalid'};
        row-gap: ${media.rowGutter !== undefined ? media.rowGutter : 'invalid'};
      }
    `
    )
    .join('\n');
};

export type GridProps = {
  /**
   * Количество колонок или массив breakpoint'ов
   */
  cols?: Columns,
  /**
   * Количество строк
   */
  rows?: string,
  /**
   * Отступы между колонками
   */
  gutter?: string,
  /**
   * Отступы между рядами
   */
  rowGutter?: string,
  /**
   * Медиа запросы
   */
  media?: MediaQuery[],
  /**
   * Допонительные стили
   */
  customStyles?: string,
  children: Node,
  lastItem?: boolean
};
const Grid: ComponentType<GridProps> = styled(
  ({ media, rowGutter, gutter, cols, rows, lastItem = true, customStyles, ...props }) => (
    <div {...props} />
  )
)`
  display: grid;
  width: 100%;
  ${props =>
    props.media &&
    css`
      ${parseMedia(props.media)};
    `};
  ${props => css`
    column-gap: ${props.gutter || 'normal'};
    row-gap: ${props.rowGutter || 'normal'};
    grid-template-rows: ${props.rows ? props.rows : 'auto'};
    grid-template-columns: ${getGridTemplate(props.cols)};
    // берем последний ряд в grid и убираем нижние отступы у field
    &
      .grid__item:nth-child(${props.cols}n + 1):nth-last-child(-n
        + ${props.cols}),
    &
      .grid__item:nth-child(${props.cols}n + 1):nth-last-child(-n
        + ${props.cols})
      ~ .grid__item {
      & .field__value {
        margin-bottom: ${props.lastItem === false ? '23px' : '0'};
      }
    }
  `};
  ${props =>
    css`
      ${props.customStyles}
    `};
`;

export type GridItemProps = {
  /**
   * Кол-во занимаемых колонок
   * По-умолчанию: 1
   */
  span?: number,
  /**
   * Занять целиком всю строку
   */
  fullWidth?: boolean,
  /**
   * colspan && rowspan
   */
  cellUnion?: {
    gridColumn?: string,
    gridRow?: string
  },
  /**
   * Допонительные стили
   */
  customStyles?: string
};

export const GridItem: ComponentType<GridItemProps> = styled.div.attrs({
  className: 'grid__item'
})`
  ${({ fullWidth, span, cellUnion, customStyles }: GridItemProps) => {
    let styles = `grid-column: span ${span || 1};`;
    if (fullWidth) {
      styles = `grid-column: 1 / -1;`;
    } else if (cellUnion) {
      styles = `
        grid-column: ${cellUnion.gridColumn || 'span 1 / span 1'};
        grid-row: ${cellUnion.gridRow || 'span 1 / span 1'};
      `;
    }
    return css`
      ${styles}
      ${customStyles}
    `;
  }}
`;

export default Grid;
