// @flow
import React from 'react';
import { Link } from '@reach/router';

import { pddTestResultApi } from '../../../lib/api';
import type { Driver, PddTestStatusesType } from '../../../lib/types';
import { convertDriverToString, formatDate } from '../../../lib/helpers';

import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import Filter from './components/Filter';
import {
  pddTest,
  pddTestStatuses,
  pddTestStatusesEnum,
} from '../../../lib/enum';

type PageProps = {
  testId: number,
};

const entryPointPath = '/pdd/results/';
export const COLUMNS = [
  {
    title: 'Дата тестирования',
    dataIndex: 'examinationDate',
    render: (date: string) => formatDate(date),
  },
  {
    title: 'ФИО водителя',
    dataIndex: 'driver',
    render: (driver: Driver) => convertDriverToString(driver),
  },
  {
    title: 'Наименование теста',
    dataIndex: 'pddTest.name',
  },
  {
    title: 'Правильных ответов',
    dataIndex: 'rightAnswersCount',
  },
  {
    title: 'Неправильных ответов',
    dataIndex: 'wrongAnswersCount',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (status: PddTestStatusesType) => (
      <span
        style={{
          color: pddTestStatusesEnum.passed === status ? '#219653' : '#E9180A',
        }}
      >
        {pddTestStatuses[status]}
      </span>
    ),
  },
  {
    title: 'Ссылка на результат',
    dataIndex: 'id',
    render: (id: number) => {
      return <Link to={`${entryPointPath}${id}`}>перейти</Link>;
    },
  },
];

export default (props: PageProps) => {
  return (
    <Page>
      <CommonListPage
        crud={pddTestResultApi}
        pageHeaderProps={{
          breadCrumbs: [
            { to: '/', name: 'Главная' },
            { name: 'Результаты', to: '/pdd/results/training' },
            { name: 'Обучение' },
          ],
          mainHeader: 'Результаты тестирований',
        }}
        tableListProps={{
          columns: COLUMNS,
        }}
        filter={Filter}
        filterMixin={{
          pddTestId: props.testId,
          'pddTest.type': pddTest.training,
        }}
      />
    </Page>
  );
};
