/* @flow */

import { LoadingOutlined } from '@ant-design/icons';
import { Redirect, Router } from '@reach/router';
import notification from 'antd/lib/notification';
import Spin from 'antd/lib/spin';
import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { ErrorHandler, NotFound } from './components';
import { Sidebar } from './components/layout';
import Burger from './components/ui/Burger';
import { fetchProfile, type Profile } from './ducks/auth';
import { toggleSidebar } from './ducks/sidebar';
import { fetchWialonToken } from './ducks/wialonStore';
import { accessTypeEnum } from './lib/enum';
import tokenManager from './lib/tokenManager';

import type { UserAccess } from './lib/types';
import routes from './routes';
/*####APP_LAYOUT_IMPORT*/

const StyledBurger = styled(Burger)`
  position: fixed;
  z-index: 1000;
  border-radius: 50%;
  padding: 16px;
  ${(props) =>
    !props.expanded &&
    css`
      position: absolute;
    `};
`;

type Props = {
  fetchProfile: () => void,
  fetchWialonToken: () => void,
  toggleSidebar: Function,
  sidebarOpened: boolean,
  profile: Profile,
  isBurgerHidden: boolean,
  uri: string,
};

type State = {
  appState: 'loading' | 'authError' | 'normal',
};

export class AppLayout extends Component<Props, State> {
  token: string;

  constructor(props: Props) {
    super(props);
    this.token = tokenManager.getToken();

    this.state = {
      appState: 'loading',
    };
  }

  static defaultProps = {
    profile: {
      access: [],
    },
  };

  async componentDidMount() {
    if (this.token) {
      await this.fetchCurrentUserProfile();
      await this.fetchWialonToken();
    }
  }

  async fetchCurrentUserProfile() {
    try {
      await this.props.fetchProfile();
      this.setState({ appState: 'normal' });
    } catch (err) {
      this.setState({ appState: 'authError' });
      console.error(err && err.message ? err.message : 'App init error');
    }
  }

  async fetchWialonToken() {
    try {
      await this.props.fetchWialonToken();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  }

  render() {
    if (!this.token || this.state.appState === 'authError') {
      return <Redirect to="/login" state={{ uri: this.props.uri }} noThrow />;
    }

    if (this.state.appState === 'loading') {
      return <AppLoader />;
    }

    const { profile, sidebarOpened, isBurgerHidden } = this.props;
    const { access: userAccess } = profile;
    return (
      <StyledLayout>
        {!isBurgerHidden && (
          <StyledBurger
            expanded={sidebarOpened}
            onClick={this.props.toggleSidebar}
          />
        )}
        <StyledContent>
          <Sidebar
            profile={profile}
            expanded={sidebarOpened && !isBurgerHidden}
          />
          <StyledMain>
            <ErrorHandler>
              <Router>
                {userAccess.some((access) =>
                  [
                    accessTypeEnum.admin,
                    accessTypeEnum.adminBranch,
                    accessTypeEnum.viewingDashboard,
                  ].includes(access)
                ) ? (
                  <Redirect from="/" to="/dashboard" noThrow />
                ) : userAccess.some((access) =>
                    [
                      accessTypeEnum.creatingVehicle,
                      accessTypeEnum.handlingOrder,
                      accessTypeEnum.viewingVehicleBranch,
                      accessTypeEnum.viewingVehicleServiceOnly,
                      accessTypeEnum.viewingAllVehicles,
                      accessTypeEnum.approvingVehicle,
                    ].includes(access)
                  ) ? (
                  <Redirect from="/" to="/vehicles" noThrow />
                ) : (
                  <Redirect from="/" to="/driverRedirect/" noThrow />
                )}
                {routes.map(
                  (item, index) =>
                    userAccess.some((access) => item.access.includes(access)) &&
                    /* $FlowFixMe */
                    createElement(item.component, {
                      path: item.path,
                      key: index,
                    })
                )}
                <NotFound default />
              </Router>
            </ErrorHandler>
          </StyledMain>
        </StyledContent>
      </StyledLayout>
    );
  }
}

export default connect(
  (state) => ({
    profile: state.auth.profile,
    sidebarOpened: state.sidebar.opened,
    isBurgerHidden: state.sidebar.isBurgerHidden,
  }),
  {
    fetchProfile,
    fetchWialonToken,
    toggleSidebar,
  }
)(AppLayout);

const AppLoader = () => (
  <Loader>
    <Spin size="large" />
  </Loader>
);

Spin.setDefaultIndicator(<LoadingOutlined style={{ fontSize: 24 }} spin />);

const Loader = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const StyledContent = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;
const StyledMain = styled.main`
  width: 100%;
  background: #e4ebf2;
  overflow: hidden;
`;
