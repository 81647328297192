// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { tireApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import type { TireFilterParams } from './../../containers/Equipment/Tires/Filter';

type Props = SelectProps & { filter: TireFilterParams, withMnemocode: boolean };

export default ({ filter = {}, withMnemocode, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async (params) => {
      return await tireApi.fetchTires({
        ...filter,
        ...params,
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={tireApi.fetchTire}
    notFoundText="Шины не найдены"
    placeholder="Выберите шину"
    renderOption={(tire: any, Option) => (
      <Option key={tire.id} value={tire.id} tire={tire}>
        {tire.name}
        {withMnemocode ? ` - ${tire.code ?? 'Мнемокод отсутствует'}` : ''}
      </Option>
    )}
    {...selectProps}
  />
);
