// @flow

import type { RepairBudget } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  repairBudget: RepairBudget
): RepairBudget => ({
  ...repairBudget,
  plannedSum: convertFromRubToKop(repairBudget.plannedSum),
  differenceSum: convertFromRubToKop(repairBudget.differenceSum),
  operationsSum: convertFromRubToKop(repairBudget.operationsSum),
  materialsSum: convertFromRubToKop(repairBudget.materialsSum),
  currentRepairSum: convertFromRubToKop(repairBudget.currentRepairSum),
  currentRepairFullInternalSum: convertFromRubToKop(
    repairBudget.currentRepairFullInternalSum
  ),
  currentRepairFullContractSum: convertFromRubToKop(
    repairBudget.currentRepairFullContractSum
  ),
  currentRepairInternalOperationsSum: convertFromRubToKop(
    repairBudget.currentRepairInternalOperationsSum
  ),
  currentRepairInternalMaterialsSum: convertFromRubToKop(
    repairBudget.currentRepairInternalMaterialsSum
  ),
  currentRepairContractOperationsSum: convertFromRubToKop(
    repairBudget.currentRepairContractOperationsSum
  ),
  currentRepairContractMaterialsSum: convertFromRubToKop(
    repairBudget.currentRepairContractMaterialsSum
  ),
  overhaulSum: convertFromRubToKop(repairBudget.overhaulSum),
  overhaulFullInternalSum: convertFromRubToKop(
    repairBudget.overhaulFullInternalSum
  ),
  overhaulFullContractSum: convertFromRubToKop(
    repairBudget.overhaulFullContractSum
  ),
  overhaulInternalOperationsSum: convertFromRubToKop(
    repairBudget.overhaulInternalOperationsSum
  ),
  overhaulInternalMaterialsSum: convertFromRubToKop(
    repairBudget.overhaulInternalMaterialsSum
  ),
  overhaulContractOperationsSum: convertFromRubToKop(
    repairBudget.overhaulContractOperationsSum
  ),
  overhaulContractMaterialsSum: convertFromRubToKop(
    repairBudget.overhaulContractMaterialsSum
  )
});

export const convertDataFromServer = (
  repairBudget: RepairBudget
): RepairBudget => ({
  ...repairBudget,
  plannedSum: convertFromKopToRub(repairBudget.plannedSum),
  differenceSum: convertFromKopToRub(repairBudget.differenceSum),
  operationsSum: convertFromKopToRub(repairBudget.operationsSum),
  materialsSum: convertFromKopToRub(repairBudget.materialsSum),
  currentRepairSum: convertFromKopToRub(repairBudget.currentRepairSum),
  currentRepairFullInternalSum: convertFromKopToRub(
    repairBudget.currentRepairFullInternalSum
  ),
  currentRepairFullContractSum: convertFromKopToRub(
    repairBudget.currentRepairFullContractSum
  ),
  currentRepairInternalOperationsSum: convertFromKopToRub(
    repairBudget.currentRepairInternalOperationsSum
  ),
  currentRepairInternalMaterialsSum: convertFromKopToRub(
    repairBudget.currentRepairInternalMaterialsSum
  ),
  currentRepairContractOperationsSum: convertFromKopToRub(
    repairBudget.currentRepairContractOperationsSum
  ),
  currentRepairContractMaterialsSum: convertFromKopToRub(
    repairBudget.currentRepairContractMaterialsSum
  ),
  overhaulSum: convertFromKopToRub(repairBudget.overhaulSum),
  overhaulFullInternalSum: convertFromKopToRub(
    repairBudget.overhaulFullInternalSum
  ),
  overhaulFullContractSum: convertFromKopToRub(
    repairBudget.overhaulFullContractSum
  ),
  overhaulInternalOperationsSum: convertFromKopToRub(
    repairBudget.overhaulInternalOperationsSum
  ),
  overhaulInternalMaterialsSum: convertFromKopToRub(
    repairBudget.overhaulInternalMaterialsSum
  ),
  overhaulContractOperationsSum: convertFromKopToRub(
    repairBudget.overhaulContractOperationsSum
  ),
  overhaulContractMaterialsSum: convertFromKopToRub(
    repairBudget.overhaulContractMaterialsSum
  )
});

const baseUrl = 'repairBudget';
const repairBudget = new CommonApi<RepairBudget>(
  baseUrl,
  'Бюджеты по ремонтам',
  convertDataToServer,
  convertDataFromServer
);

export const getByVehiclePlan = async (
  vehiclePlanId: number
): Promise<RepairBudget> => {
  const repairBudget = await fetchRequest.get(
    `/${baseUrl}/getByVehiclePlan/${vehiclePlanId}`
  );
  if (repairBudget) return repairBudget;
  throw new Error('Не удалось получить бюджет');
};

export default {
  ...repairBudget,
  getByVehiclePlan
};
