// @flow

import type { Dispatch } from 'redux';

import type { ContractVehicleOrder } from './../lib/types';
import { contractVehicleOrderApi } from './../lib/api';
import type { Action, AppState } from './redux';
export const SET_CONTRACT_VEHICLE_ORDER = 'vehicles/contractVehicleOrder/set';
export const CLEAN_CONTRACT_VEHICLE_ORDER =
  'vehicles/contractVehicleOrder/clean';

export type ContractVehicleOrderState = ?ContractVehicleOrder;

const initialState: ContractVehicleOrderState = null;

const reducer = (
  state: ContractVehicleOrderState = initialState,
  { type, payload }: Action
): ContractVehicleOrderState => {
  switch (type) {
    case SET_CONTRACT_VEHICLE_ORDER:
      return { ...payload };
    case CLEAN_CONTRACT_VEHICLE_ORDER:
      return initialState;
    default:
      return state;
  }
};

export const addContractVehicleOrder: Function = (
  contractVehicleOrder: ContractVehicleOrder
): Function => async (dispatch: Dispatch): Promise<void> => {
  await contractVehicleOrderApi.addContractVehicleOrder(contractVehicleOrder);
};

export const updateContractVehicleOrder: Function = (
  contractVehicleOrder: ContractVehicleOrder,
  getState: () => AppState
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const updatedContractVehicleOrder = await contractVehicleOrderApi.updateContractVehicleOrder(
    contractVehicleOrder
  );
  dispatch({
    type: SET_CONTRACT_VEHICLE_ORDER,
    payload: {
      ...updatedContractVehicleOrder
    }
  });
};

export const deleteContractVehicleOrder: Function = (
  id: number
): Function => async (dispatch: Dispatch): Promise<void> =>
  await contractVehicleOrderApi.deleteContractVehicleOrder(id);

export const fetchContractVehicleOrder = (id: number) => async (
  dispatch: Dispatch
): Promise<void> => {
  const contractVehicleOrder = await contractVehicleOrderApi.fetchContractVehicleOrder(
    id
  );
  dispatch({
    type: SET_CONTRACT_VEHICLE_ORDER,
    payload: { ...contractVehicleOrder }
  });
};

export const cleanContractVehicleOrder = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAN_CONTRACT_VEHICLE_ORDER
  });

export default reducer;
