// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer,
} from '../../../../components';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import type {
  Vehicle,
  VehicleStatus,
  VehicleType,
} from '../../../../lib/types';
import { ownerTypes } from '../../../../lib/enum';
import Field from '../../../../components/card/Field';
import { applyMaskToValue } from '../../../../lib/helpers';
import { formatLicensePlateMask } from '../../../../components/inputs/masked-inputs/LicensePlateInput';
import VehicleSelect from '../../../../components/selects/VehicleSelect';

const { VehicleTypeSelect, VehicleStatusSelect, YearIssueSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type OilsAndFluidsConsumptionPlanParams = {|
  yearIssued?: number,
  status?: VehicleStatus,
  'vehicleModel.type'?: VehicleType,
  id?: number,
|};

type Props = {
  filter: OilsAndFluidsConsumptionPlanParams,
  oilsAndFluidsConsumptionPlanId: number,
  cleanFilter: Function,
  applyFilter: Function,
};

export const OilsAndFluidsConsumptionPlanFilter = ({
  filter,
  cleanFilter,
  applyFilter,
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional,
        }: FilterChildrenParams<OilsAndFluidsConsumptionPlanParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.id}
                  optionTitle={(vehicle: Vehicle) =>
                    applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )
                  }
                  filter={{
                    ownerType: ownerTypes.self,
                  }}
                  onChange={(value) => {
                    changeValue('id', value);
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  placeholder="Все типы ТС"
                  size="small"
                  value={values['vehicleModel.type']}
                  selectableGroup
                  onChange={(value) => changeValue('vehicleModel.type', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleStatusSelect
                  placeholder="Все статусы"
                  size="small"
                  value={values.status}
                  onChange={(value) => changeValue('status', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YearIssueSelect
                  size="small"
                  value={values.yearIssued}
                  onChange={(value) => changeValue('yearIssued', value)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              showAdditional={showAdditional}
              handleAdditional={handleAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default OilsAndFluidsConsumptionPlanFilter;
