// @flow
import { accessTypeEnum } from './../../../../lib/enum';

export const addAccessRightContractVehicleBudget = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingContractVehicleBudget
];

export const handlingAccessRightContractVehicleBudget = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingContractVehicleBudget
];

export const approveAccessRightContractVehicleBudget = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingContractVehicleBudget,
  accessTypeEnum.approvingContractVehicleBudget
];

export const approvedAccessRightWorkPlans = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.approvingRegulation
];

export const addAccessRightWorkPlans = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingRegulation
];

export const handlingAccessRightWorkPlans = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingRegulation
];

export default {
  addAccessRightContractVehicleBudget,
  approveAccessRightContractVehicleBudget,
  handlingAccessRightContractVehicleBudget,
  addAccessRightWorkPlans,
  approvedAccessRightWorkPlans,
  handlingAccessRightWorkPlans
};
