// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import AutocompleteSelect from './AutocompleteSelect';
import { washingVehicleTypeApi } from '../../lib/api';
import type { WashingVehicleType } from '../../lib/types/washingPriceList';

type Props = SelectProps;

export default (selectProps: Props) => (
  <AutocompleteSelect
    placeholder="Выберите тип ТС для моек"
    fetch={async params => await washingVehicleTypeApi.fetchList({ ...params })}
    fetchSingle={washingVehicleTypeApi.fetchOne}
    notFoundText="Типы ТС не найдены"
    renderOption={(type: WashingVehicleType, Option) => (
      <Option key={type.id} washingVehicleType={type} value={type.id}>
        {type.type}
      </Option>
    )}
    {...selectProps}
  />
);
