// @flow
import type { UserAccess } from '../../lib/types';
import { accessTypeEnum } from '../../lib/enum';
import { formatDateTimeToString } from '../../lib/helpers';

export const ACT_COLUMNS = [
  {
    title: 'Номер акта',
    dataIndex: 'act.actNumber'
  },
  {
    title: 'Дата акта',
    dataIndex: 'act.actDate',
    render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY')
  },
  {
    title: 'Подрядчик',
    dataIndex: 'act.contract.contractor.company.name'
  }
];

/**
 * Проверка на возможность создания/редактирования
 * @param userAccess Доступы пользователя
 * @returns {boolean}
 */
export const canHandleService = (userAccess: UserAccess[]) => {
  return [
    accessTypeEnum.admin,
    accessTypeEnum.adminBranch,
    accessTypeEnum.handlingMaintenanceServices
  ].some(access => userAccess.includes(access));
};

/**
 * Проверка на возможность просмотра пользователем разделов обслуживания
 * @param userAccess Доступы пользователя
 * @returns {boolean}
 */
export const canViewingService = (userAccess: UserAccess[]) => {
  return [
    accessTypeEnum.admin,
    accessTypeEnum.adminBranch,
    accessTypeEnum.viewingMaintenanceServices,
    accessTypeEnum.viewingAllMaintenanceServices
  ].some(access => userAccess.includes(access));
};
