// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import moment from 'moment';

import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportVehicleOutputRatio,
  ReportVehicleOutputRatioParams,
} from '../../../lib/types/reportVehicleOutputRatio';
import { reportVehicleOutputRatioApi } from '../../../lib/api';
import type { VehicleType } from '../../../lib/types';
import { vehicleTypes } from '../../../lib/enum';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { notificationLoading } from '../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

import Filter from './components/Filter';

const initialParams: ReportVehicleOutputRatioParams = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  params: ReportVehicleOutputRatioParams,
  data: ReportVehicleOutputRatio[],
  loading: boolean,
  scrollY: number,
};

/**
 * Отчет об учете заявок ГПМ
 */
export class VehicleOutputRatioList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  async componentDidMount() {
    await this.fetchReportVehicleOutputRatios();
  }

  fetchReportVehicleOutputRatios = async () => {
    try {
      this.setState({ loading: true });
      const data =
        await reportVehicleOutputRatioApi.fetchReportVehicleOutputRatio(
          this.state.params
        );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyParams = this.fetchReportVehicleOutputRatios;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print',
      });
      await reportVehicleOutputRatioApi.printReportVehicleOutputRatio(
        this.state.params
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      width: 150,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Марка ТС',
      dataIndex: 'brandName',
      width: 150,
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      width: 100,
      render: (type: VehicleType) => vehicleTypes[type],
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
      width: 100,
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      width: 100,
      render: (value: number) => `${value} км.`,
    },
    {
      title: 'Коэффициент выхода',
      dataIndex: 'outputRatio',
      width: 100,
    },
    {
      title: 'Количество путевых листов',
      dataIndex: 'tripCount',
      width: 100,
      render: (value: number) => `${value} шт.`,
    },
  ];

  render() {
    const { loading, data, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Отчет о коэффициентах выхода ТС</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
            name="ReportsVehicleOutputRatio"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 800,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default VehicleOutputRatioList;
