// @flow
import React from 'react';

import Input from 'antd/lib/input';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import type { BudgetLineItem } from '../../../../lib/types';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';

export default (props: CommonFormProps<$Shape<BudgetLineItem>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Наименование статьи бюджетной формы"
                  name="name"
                  required
                >
                  {({ name, value }) => (
                    <Input
                      value={value}
                      onChange={e => {
                        setFieldValue(name, e.target.value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Идентификатор статьи бюджетной формы"
                  name="mnemocode"
                  required
                >
                  {({ name, value }) => (
                    <Input
                      value={value}
                      onChange={e => {
                        setFieldValue(name, e.target.value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
