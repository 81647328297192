import {
    SparePartsCard,
    SparePartsForm,
    SparePartsList,
} from '../../containers/SpareParts';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: SparePartsList,
        path: '/admin/spare-parts',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingSparePart,
            accessTypeEnum.viewingSparePart,
        ],
    },
    {
        component: SparePartsCard,
        path: '/admin/spare-parts/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingSparePart,
            accessTypeEnum.viewingSparePart,
        ],
    },
    {
        component: SparePartsForm,
        path: '/admin/spare-parts/edit/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingSparePart,
        ],
    },
    {
        component: SparePartsForm,
        path: '/admin/spare-parts/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingSparePart,
        ],
    },
];