// @flow
import React from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { expenseDirectionApi } from '../../../lib/api';
import { accessTypeEnum, expenseDirectionTypes } from '../../../lib/enum';
import {
  formatDateTimeToString,
  getListInitialState,
} from '../../../lib/helpers';
import type {
  ExpenseDirection,
  ListState,
  UserAccess,
} from '../../../lib/types';
import { Popconfirm, Icon } from '../../../components/ui';
import Table from '../../../components/ui/Table';
import { Header, Section } from '../../../components/layout';
import { notificationLoading } from '../../../components/Notifications';

import { withUserAccess } from '../../withUserAccess';
import Tab from '../components/Tab';
import type { ExpenseDirectionFilterParams } from './components/Filter';
import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<ExpenseDirection> & {
  filter: ExpenseDirectionFilterParams,
};

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingExpenseDirections,
];

export class ExpenseDirectionList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [
    {
      title: 'Номер',
      dataIndex: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Ид. статьи бюдж. формы',
      dataIndex: 'budgetLineItemId',
    },
    {
      title: 'Наименование статьи бюдж. формы',
      dataIndex: 'budgetLineItemName',
    },
    {
      title: 'Тип направления',
      dataIndex: 'type',
      render: (type: string) => (type ? expenseDirectionTypes[type] : '-'),
    },
    {
      title: 'Мнемокод направления',
      dataIndex: 'cognosId',
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      render: (startDate: string) =>
        `${formatDateTimeToString(startDate, 'DD.MM.YYYY')}`,
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      render: (endDate: string) =>
        `${formatDateTimeToString(endDate, 'DD.MM.YYYY')}`,
    },
  ];

  componentDidMount = async () => {
    const { page } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteExpenseDirection(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
      });
    }
    await this.fetchExpenseDirection(page);
  };

  deleteExpenseDirection = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await expenseDirectionApi.deleteExpenseDirection(id);
      await this.fetchExpenseDirection(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchExpenseDirection = async (page: number) => {
    this.setState({ loading: true });
    const data = await expenseDirectionApi.fetchExpenseDirection({
      ...this.state.filter,
      page,
    });
    this.setState({ ...data, page, loading: false });
  };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  applyFilter = (filter: ExpenseDirectionFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchExpenseDirection(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchExpenseDirection(page)
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Направление расходов</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() => navigate('/admin/expense-direction/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <Tab />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchExpenseDirection}
            fetchOnMount
            onRow={(row: ExpenseDirection) => ({
              onClick: () =>
                canAdd && navigate(`/admin/expense-direction/${row.id}/edit`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(ExpenseDirectionList);
