import { JournalCard, JournalList } from '../../containers/Journal';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: JournalList,
        path: '/admin/journals',
        access: [accessTypeEnum.admin, accessTypeEnum.viewingSystemJournal],
    },
    {
        component: JournalCard,
        path: '/admin/journal/:journalId',
        access: [accessTypeEnum.admin, accessTypeEnum.viewingSystemJournal],
    },
];