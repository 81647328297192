// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { StockPart } from '../../lib/types';
import { goBack, multipliedBy } from './../../lib/helpers';
import { stockPartApi } from '../../lib/api';

import { Card } from './../../components';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Grid, { GridItem } from './../../components/layout/Grid';
import { Header, Panel, Section } from '../../components/layout';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  stockPartId: number
};

type State = {
  stockPart: ?StockPart
};

export class StockPartForm extends React.Component<Props, State> {
  state = {
    stockPart: null
  };

  async componentDidMount() {
    const { stockPartId } = this.props;
    try {
      if (stockPartId) {
        const stockPart = await stockPartApi.getStockPart(stockPartId);
        this.setState({ stockPart });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: StockPart) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await stockPartApi.updateStockPart(values);
      } else {
        await stockPartApi.addStockPart(values);
      }
      navigate(`/admin/stock-part`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/admin/stock-part');

  render() {
    const { stockPart } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/stock-part">
                Справочник материалов для проведения работ хоз.спопсобом
              </Crumb>
              <Crumb>Деталь №{stockPart && stockPart.id}</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{stockPart ? `Деталь №${stockPart.id}` : 'Новая деталь'}</h1>
        </StyledPanel>
        <Section>
          {stockPart && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Номер детали">
                    {stockPart.sparePart
                      ? stockPart.sparePart.catalogNumber
                      : stockPart.number}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Наименование детали">
                    {stockPart.sparePart
                      ? stockPart.sparePart.name
                      : stockPart.name}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Стоимость ед. в руб. без НДС">
                    {stockPart.sparePart
                      ? stockPart.sparePart.price
                      : stockPart.cost}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Количество">{stockPart.count}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Ед. измерения">
                    {!!stockPart.sparePart && stockPart.sparePart.unit.name}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Сумма">
                    {stockPart.sparePart
                      ? multipliedBy(stockPart.count, stockPart.sparePart.price)
                      : multipliedBy(stockPart.cost, stockPart.count)}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default StockPartForm;
