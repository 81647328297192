// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import type {
  RegulationLimit,
  RegulationLimitGroup,
  RegulationLimitType,
} from './../../../../../../../lib/types';
import {
  aggregateBy,
  aggregateLimits,
  isEmptyLimit,
  saveGroup,
  validateRegulationLimit,
  withEmptyLimit,
} from '../lib';
import { getGridTemplate, QuestionIcon } from './elements';
import RegulationLimitRow from './RegulationLimitRow';
import {
  Popconfirm,
  Dropdown,
  Icon,
  Popover,
  Spoiler,
} from './../../../../../../../components/ui';
import GroupModal from './GroupModal';
import {
  operationLimitAggregationFieldsEnum,
  regulationLimitTypeEnum,
} from './../../../../../../../lib/enum';
import { formatLimitValue } from './../../../lib';
import { regulationLimitApi } from './../../../../../../../lib/api';
import { Cell, Row } from './../../../elements';
import { notificationLoading } from './../../../../../../../components/Notifications';

type Props = {
  // Группа лимитов
  group: RegulationLimitGroup,
  onDelete: Function,
  type: RegulationLimitType,
  orgUnitId: number,
  // Функция сохранения изменений группы (в модалке)
  saveGroup: Function,
  setGroup: (index: number, group: RegulationLimitGroup) => void,
  index: number,
  // Редактируемые ли данные
  editable: boolean,
};

type State = {
  limits: RegulationLimit[],
  // Показывать модалку
  modalVisible: boolean,
  // Показывать кнопку сохранения
  needSave: boolean,
  hasErrors: boolean,
  aggregations: $Shape<{
    [key: $Keys<typeof operationLimitAggregationFieldsEnum>]: number,
  }>,
};

export default class RegulationLimitGroupRow extends React.Component<
  Props,
  State
> {
  state = {
    limits: [],
    modalVisible: false,
    needSave: false,
    hasErrors: false,
    aggregations: {},
  };

  componentDidMount() {
    this.setLimitsFromGroup();
  }

  componentDidUpdate(prevProps: Props) {
    const { group, editable } = this.props;
    if (
      !isEqual(prevProps.group.regulationLimits, group.regulationLimits) ||
      !isEqual(prevProps.editable, editable)
    ) {
      this.setLimitsFromGroup();
    }
  }

  /**
   * Функция для проверки лимитов на ошибки
   */
  checkErrors = () => {
    const { limits } = this.state;
    const { group } = this.props;
    let hasErrors = false;
    limits
      .filter((limit) => !isEmptyLimit(limit, group))
      .forEach((limit) => {
        const validationResult = validateRegulationLimit(limit, group);
        if (!isEmpty(validationResult)) hasErrors = true;
      });
    Object.keys(operationLimitAggregationFieldsEnum).forEach((key) => {
      const result = aggregateBy(limits, key);
      // Если аггрегация выходит за границы значения по группе
      if (result > group[key]) {
        hasErrors = true;
      }
    });
    this.setState({ hasErrors });
  };

  /**
   * Функция для сохранения изменений группы
   * @returns {Promise<void>}
   */
  handleSaveGroup = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const { orgUnitId, group, setGroup, index } = this.props;
      // Сохраняем группу
      const savedGroup = await saveGroup(
        {
          ...group,
          // удаляем пустую строку
          regulationLimits: this.state.limits.filter(
            (limit) => !isEmptyLimit(limit, group)
          ),
        },
        orgUnitId
      );
      notification.success({
        message: 'Изменения были успешно сохранены',
      });
      // Сохраняем в стейт сохраненные лимиты
      this.setState({
        limits: withEmptyLimit(savedGroup.regulationLimits, group),
        needSave: false,
      });
      setGroup(index, savedGroup);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  setLimitsFromGroup = () => {
    const { group, editable } = this.props;
    this.setState({
      limits: editable
        ? withEmptyLimit(group.regulationLimits, group)
        : group.regulationLimits,
    });
  };

  // Ссылка на dropdown для контроля над ним
  dropdown = React.createRef<Dropdown>();

  toggleGroupModal = () =>
    this.setState(({ modalVisible }: State) => ({
      modalVisible: !modalVisible,
    }));

  /**
   * Функция удаления группы
   */
  handleDeleteGroup = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await this.props.onDelete(this.props.group.id);
      this.dropdown.current && this.dropdown.current.onVisibleChange(false);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  /**
   * Функция удаления лимита в группе
   * @param index Индекс лимита в списке лимитов
   */
  handleDeleteLimit = async (index: number) => {
    const limits = [...this.state.limits];
    const limit = this.state.limits[index];
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      if (limit.id) {
        await regulationLimitApi.delete(limit.id);
      }
      limits.splice(index, 1);
      this.setState({ limits });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  /**
   * Функция изменения лимита в списке групп
   * @param limit Лимит
   * @param index Индекс лимита в списке
   */
  handleChangeLimit = (limit: RegulationLimit, index: number) => {
    this.setState({ needSave: true });
    const limits = [...this.state.limits];
    limits.splice(index, 1, limit);
    this.setState(
      { limits: withEmptyLimit(limits, this.props.group) },
      this.checkErrors
    );
  };

  render() {
    const { group, saveGroup, editable } = this.props;
    const { modalVisible, limits, needSave, hasErrors } = this.state;
    const { type } = group;
    const aggregations = aggregateLimits(limits);

    return (
      <>
        <Spoiler
          label={group.name}
          labelStyles={{
            background: '#F0F4F8',
            color: '#838D96',
            height: '45px',
          }}
          defaultExpanded
          iconPlacement="left"
          suffix={
            editable && (
              <>
                <Dropdown
                  ref={this.dropdown}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          this.toggleGroupModal();
                          this.dropdown.current &&
                            this.dropdown.current.onVisibleChange(false);
                        }}
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          zIndex={2000}
                          onConfirm={this.handleDeleteGroup}
                          title="Вы уверены что хотите удалить группу?"
                        >
                          <p>Удалить</p>
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Icon
                    style={{ fontSize: 18, color: '#2770ff' }}
                    type="dots"
                    size={12}
                  />
                </Dropdown>
              </>
            )
          }
        >
          {limits.map((limit, index) => (
            <RegulationLimitRow
              editable={editable}
              key={limit.id || limit.key}
              index={index}
              regulationLimit={limit}
              onChange={this.handleChangeLimit}
              onDelete={this.handleDeleteLimit}
              group={group}
              isEmptyRow={isEmptyLimit(limit, group)}
            />
          ))}
          {Object.keys(aggregations).filter((key) => aggregations[key] > 0)
            .length ? (
            <Row media={getGridTemplate(type)} key="aggregation">
              <Cell key="empty">Итог:</Cell>
              <Cell />
              <Cell />
              {type === regulationLimitTypeEnum.ovb && (
                <>
                  <Cell key="ovbEmpty1" />
                </>
              )}
              {(type === regulationLimitTypeEnum.ovb
                ? ['distance']
                : group.usedInWeekends
                ? ['distance', 'distanceWeekend']
                : ['distance']
              ).map((key) => (
                <Cell key={key}>
                  <span
                    style={
                      !(aggregations[key] <= group[key]) ? { color: 'red' } : {}
                    }
                  >
                    {formatLimitValue(key, aggregations[key] || 0)}
                  </span>
                  &nbsp;из {group[key]}
                  <Popover
                    width={120}
                    content="Агрегированные средние показатели"
                  >
                    <QuestionIcon />
                  </Popover>
                </Cell>
              ))}
            </Row>
          ) : null}
          {needSave && (
            <Row cols={1}>
              <Cell
                fullWidth
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  disabled={hasErrors}
                  onClick={this.handleSaveGroup}
                  type="primary"
                >
                  Сохранить изменения
                </Button>
              </Cell>
            </Row>
          )}
        </Spoiler>
        <GroupModal
          group={group}
          toggleModal={this.toggleGroupModal}
          visible={modalVisible}
          saveGroup={saveGroup}
        />
      </>
    );
  }
}
