// @flow
import React, { useState, useCallback } from 'react';

import { goBack } from '../../lib/helpers';
import { measuringDeviceCertificationCostApi } from '../../lib/api';
import { type MeasuringDeviceCertificationCost } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/measuring-device-certification-cost/';

export default (props: PageProps) => {
  const [data: MeasuringDeviceCertificationCost, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id &&
      setData(await measuringDeviceCertificationCostApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: MeasuringDeviceCertificationCost) => {
    let id = payload.id;
    if (id) {
      await measuringDeviceCertificationCostApi.update(payload);
    } else {
      await measuringDeviceCertificationCostApi.add(payload);
    }
    await goBack(`${entryPointPath}`);
  };

  const onCancel = async (payload: MeasuringDeviceCertificationCost) => {
    if (data.id) {
      await goBack(`${entryPointPath}${data.id}`);
    } else await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость тарировки цистерн'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
