// @flow
import type { ExpertiseServiceGpmCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (
  expertiseServiceGpmCost: ExpertiseServiceGpmCost
) => ({
  ...expertiseServiceGpmCost,
  workHour: convertFromRubToKop(expertiseServiceGpmCost.workHour),
  materialsCost: convertFromRubToKop(expertiseServiceGpmCost.materialsCost),
  expertiseCost: convertFromRubToKop(expertiseServiceGpmCost.expertiseCost)
});

const convertDataFromServer = (
  expertiseServiceGpmCost: ExpertiseServiceGpmCost
) => ({
  ...expertiseServiceGpmCost,
  workHour: convertFromKopToRub(expertiseServiceGpmCost.workHour),
  materialsCost: convertFromKopToRub(expertiseServiceGpmCost.materialsCost),
  expertiseCost: convertFromKopToRub(expertiseServiceGpmCost.expertiseCost)
});

const expertiseServiceGpmCost = new CommonApi<ExpertiseServiceGpmCost>(
  'expertiseServiceGpmCost',
  'Стоимость ТО ГПМ и промышленной экспертизы',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...expertiseServiceGpmCost
};
