// @flow

import type { MaintenanceMatrix } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addMaintenanceMatrix = async (
  maintenanceMatrix: MaintenanceMatrix
): Promise<MaintenanceMatrix> => {
  const added = await fetchRequest.post(
    '/maintenanceMatrix',
    maintenanceMatrix
  );
  if (added) return added;
  throw new Error('Не удалось создать запись в матрице ремонтов');
};

export const updateMaintenanceMatrix = async (
  maintenanceMatrix: MaintenanceMatrix
): Promise<MaintenanceMatrix> => {
  const updated = await fetchRequest.put(
    '/maintenanceMatrix',
    maintenanceMatrix
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить запись матрицы ремонтов');
};

export const getMaintenanceMatrix = async (
  id: number,
  params: any = {}
): Promise<MaintenanceMatrix> => {
  const maintenanceMatrix = await fetchRequest.get(
    `/maintenanceMatrix/${id}`,
    params
  );
  if (maintenanceMatrix) return maintenanceMatrix;
  throw new Error('Не удалось получить запись матрицы ремонтов');
};

export const deleteMaintenanceMatrix = async (
  id: number
): Promise<MaintenanceMatrix> => {
  const deleted = await fetchRequest.delete(`/maintenanceMatrix/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить запись в матрице ремонтов');
};

export const fetchMaintenanceMatrix = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<MaintenanceMatrix>> => {
  const maintenanceMatrix = await fetchRequest.get('/maintenanceMatrix', {
    ...initialFetchListParams,
    ...params
  });
  if (maintenanceMatrix) return maintenanceMatrix;
  throw new Error('Не удалось получить список матрицы ремонтов');
};

export const getMaintenanceMatrixByParams = async (
  params: any
): Promise<MaintenanceMatrix> => {
  const maintenanceMatrix = await fetchRequest.get(
    '/maintenanceMatrix/getByParams',
    params
  );
  if (maintenanceMatrix) return maintenanceMatrix;
  throw new Error(
    'Не удалось получить матрицу ремонтов по заданным параметрам'
  );
};

export default {
  getMaintenanceMatrixByParams,
  fetchMaintenanceMatrix,
  deleteMaintenanceMatrix,
  getMaintenanceMatrix,
  updateMaintenanceMatrix,
  addMaintenanceMatrix
};
