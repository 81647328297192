// @flow
import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';

import type { Platon, UserAccess } from '../../../lib/types';
import { platonApi } from '../../../lib/api';
import {
  convertVehicleToString,
  formatDateTimeToString
} from '../../../lib/helpers';

import type { AppState } from '../../../ducks/redux';

import { Header } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { Icon } from '../../../components/ui';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, { GridItem } from '../../../components/layout/Grid';

import { ActInfo } from './../../Act';

import { canHandleService } from '../lib';
import { Panel, SectionContent } from '../elements';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  platon: Platon
};

class PlatonCard extends React.Component<Props, State> {
  state = {
    platon: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const platon = await platonApi.fetchPlaton(parseInt(id, 10));
      this.setState({ platon });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { platon } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={'/services/platons'}>Список актов "Платон"</Crumb>
              <Crumb>Акт "Платон" №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() => navigate(`/services/platons/${id}/edit`)}
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Акт "Платон" №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              {platon.vehicle !== null && (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(platon.vehicle)}
                  </Field>
                </GridItem>
              )}

              {!!platon.maintenanceDate && (
                <GridItem>
                  <Field label="Дата оказания">
                    {formatDateTimeToString(
                      platon.maintenanceDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              )}

              {!!platon.startDate && (
                <GridItem>
                  <Field label="Период оказания">
                    {formatDateTimeToString(platon.startDate, 'DD.MM.YYYY')} -
                    {formatDateTimeToString(platon.endDate, 'DD.MM.YYYY')}
                  </Field>
                </GridItem>
              )}

              <GridItem>
                <Field label="Сумма оплаты, руб.">
                  {(platon.paymentAmount || 0).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                  })}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>

        {platon && platon.act && <ActInfo act={platon.act} />}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(PlatonCard);
