// @flow
import React, { useState, useCallback } from 'react';

import { navigate } from '../../lib/helpers';
import { gpsSubscriptionServiceCostApi } from '../../lib/api';
import { type GpsSubscriptionServiceCost } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/gps-subscription-service-cost/';

export default (props: PageProps) => {
  const [data: GpsSubscriptionServiceCost, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await gpsSubscriptionServiceCostApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: GpsSubscriptionServiceCost) => {
    let id = payload.id;
    if (id) {
      await gpsSubscriptionServiceCostApi.update(payload);
    } else {
      await gpsSubscriptionServiceCostApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };

  const onCancel = async (payload: GpsSubscriptionServiceCost) => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость абонентского обслуживания GPS'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
