// @flow
import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Divider from 'antd/lib/divider';

import type {
  Washing,
  UserAccess,
  WashingPlanBalance
} from '../../../lib/types';
import {
  convertDriverToString,
  convertVehicleToString,
  formatDateTimeToString
} from '../../../lib/helpers';
import { monthlyWashingPlanApi, washingApi } from '../../../lib/api';
import { accessTypeEnum, washingTypes } from '../../../lib/enum';

import { Header, SectionTitle } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../../components/Notifications';
import type { AppState } from '../../../ducks/redux';
import { Icon } from '../../../components/ui';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, { GridItem } from '../../../components/layout/Grid';

import { ActInfo } from './../../Act';

import { canHandleService } from '../lib';
import { Panel, SectionContent } from '../elements';

const Actions = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  washing: Washing,
  planData: ?WashingPlanBalance
};

class WashingCard extends React.Component<Props, State> {
  state = {
    washing: {},
    planData: null
  };

  async componentDidMount() {
    const { id, userAccess } = this.props;
    try {
      const washing = await washingApi.fetchWashing(parseInt(id, 10));
      if (
        userAccess.some(access =>
          [
            accessTypeEnum.adminBranch,
            accessTypeEnum.admin,
            accessTypeEnum.viewingWashingPlans
          ].includes(access)
        )
      ) {
        await this.fetchPlanData(washing);
      }
      this.setState({ washing });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  fetchPlanData = async (washing: Washing) => {
    const { washing: existingWashing } = this.state;
    const { vehicleId, date, type } = washing;
    const parsedVehicleId = parseInt(vehicleId, 10);
    let needToFetchPlan = false;
    if (
      !isEmpty(existingWashing) &&
      ((parsedVehicleId > 0 && parsedVehicleId !== existingWashing.vehicleId) ||
        (date &&
          !moment(date).isBetween(
            moment(existingWashing.date).startOf('month'),
            moment(existingWashing.date).endOf('month')
          )) ||
        type !== existingWashing.type)
    ) {
      needToFetchPlan = true;
    } else if (parsedVehicleId > 0 && date && moment(date).isValid()) {
      needToFetchPlan = true;
    }

    if (needToFetchPlan) {
      const planData = await monthlyWashingPlanApi.fetchWashingPlanBalance(
        parsedVehicleId,
        date
      );
      if (planData) {
        this.setState({ planData });
      } else {
        this.setState({ planData: null });
        notification.error({
          message: 'Не удалось получить данные о плане моек'
        });
      }
    }
  };

  handlePrint = async (id: ?number) => {
    try {
      notificationLoading({
        message: 'Загрузка файла...',
        key: 'print'
      });
      if (id) await washingApi.printTicket(id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { id, userAccess } = this.props;
    const { washing, planData } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={'/services/washings'}>Список талонов на мойку</Crumb>
              <Crumb>Талон на мойку №{washing.id}</Crumb>
            </Breadcrumbs>
          }
          right={
            <Actions>
              <Button onClick={async () => await this.handlePrint(id)}>
                Печать
              </Button>
              {canHandle && (
                <Icon
                  onClick={() => navigate(`/services/washings/${id}/edit`)}
                  size={16}
                  style={{ cursor: 'pointer' }}
                  type="edit"
                />
              )}
            </Actions>
          }
        />
        <Panel>
          <h1>Талон на мойку №{washing.id}</h1>
        </Panel>
        <Section>
          <SectionTitle divider>Данные ТС</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              {!!washing.vehicle ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(washing.vehicle)}
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
            {planData ? (
              <>
                <Divider />
                <Grid cols={3}>
                  <GridItem>
                    <Field label="Остаток наружных моек">
                      {planData.vehicleBodyCount > 0
                        ? planData.vehicleBodyCount
                        : 0}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Остаток моек салона">
                      {planData.vehicleInteriorCount > 0
                        ? planData.vehicleInteriorCount
                        : 0}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Остаток моек ДВС">
                      {planData.vehicleEngineCount > 0
                        ? planData.vehicleEngineCount
                        : 0}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Остаток суммы на ТС">
                      {(
                        planData.vehiclePlanSum - planData.vehicleFactSum
                      ).toLocaleString('ru-RU')}
                      &nbsp;&#8381;
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Остаток суммы по филиалу">
                      {(planData.planSum - planData.factSum).toLocaleString(
                        'ru-RU'
                      )}
                      &nbsp;&#8381;
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Остаток суммы в подразделении">
                      0 &nbsp;&#8381;
                    </Field>
                  </GridItem>
                </Grid>
              </>
            ) : null}
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Данные талона</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Месяц">
                  {formatDateTimeToString(washing.date, 'MMMM YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип мойки">{washingTypes[washing.type]}</Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {[washing.factDate, washing.driver].filter(Boolean).length ? (
          <Section>
            <SectionTitle divider>Факт от водителя</SectionTitle>
            <SectionContent>
              <Grid gutter="16px">
                {!!washing.factDate ? (
                  <GridItem>
                    <Field label="Дата мойки">
                      {formatDateTimeToString(washing.factDate, 'DD.MM.YYYY')}
                    </Field>
                  </GridItem>
                ) : null}
                {!!washing.driver ? (
                  <GridItem>
                    <Field label="Водитель">
                      {convertDriverToString(washing.driver)}
                    </Field>
                  </GridItem>
                ) : null}
              </Grid>
            </SectionContent>
          </Section>
        ) : null}

        {washing && washing.act && <ActInfo act={washing.act} />}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(WashingCard);
