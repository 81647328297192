// @flow
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Input, TimePicker, Popconfirm } from 'antd';

import CommonForm, {
  type CommonFormProps
} from '../../../../../components/hoc/common/handbook/CommonForm';
import type { PddGroup, PddTest, PddTicket } from '../../../../../lib/types';
import Grid, { GridItem } from '../../../../../components/layout/Grid';
import { FormField } from '../../../../../components/Form';
import InputNumber from '../../../../../components/inputs/InputNumber';
import YesNoSelect from '../../../../../components/selects/YesNoSelect';
import Section from '../../../../../components/layout/Section';
import { SectionContent, SectionTitle } from '../../../../../components/layout';
import Table from '../../../../../components/ui/Table';
import type { PddTestGroup } from '../../../../../lib/types/pddTest';
import { GroupModal } from './GroupModal';
import { PddTestTypeSelect } from '../../../../../components/selects/AutoGeneratedEnumSelects';
import { pddTicketApi } from '../../../../../lib/api';
import { navigate } from '../../../../../lib/helpers';
import { Link } from '@reach/router';
import StyledIcon from '../../../../../components/ui/Icon';
import { submitPddTest } from '../Form';

const GroupTableSection = ({ values, setFieldValue }) => {
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [group, setGroup] = useState(null);
  return (
    <Section>
      <SectionTitle
        divider
        suffix={
          <Button
            onClick={() => {
              setGroup({
                groupId: 0,
                questionCount: 0
              });
              setShowGroupModal(true);
            }}
          >
            Добавить тему ПДД
          </Button>
        }
      >
        Темы ПДД
      </SectionTitle>
      <SectionContent>
        <Table
          columns={[
            {
              title: 'Наименование темы ПДД',
              dataIndex: 'pddGroup.name'
            },
            {
              title: 'Кол-во вопросов в билете',
              dataIndex: 'questionCount'
            }
          ]}
          data={values.pddTestGroups}
          onRow={(record: PddTestGroup) => {
            return {
              onClick: () => {
                setGroup(record);
                setShowGroupModal(true);
              }
            };
          }}
        />
        <GroupModal
          visible={showGroupModal}
          onClose={() => setShowGroupModal(false)}
          onSave={(oldGroup: PddTestGroup, group: PddTestGroup) => {
            let newGroups = values.pddTestGroups || [];
            if (oldGroup.pddGroupId) {
              newGroups = newGroups.map(el => {
                if (el === oldGroup) {
                  return group;
                }
                return el;
              });
            } else {
              newGroups.push(group);
            }
            setFieldValue('pddTestGroups', newGroups);
            setShowGroupModal(false);
          }}
          onDelete={(group: PddTestGroup) => {
            let newGroups = values.pddTestGroups || [];
            newGroups = newGroups.filter(el => {
              return group !== el;
            });
            setFieldValue('pddTestGroups', newGroups);
            setShowGroupModal(false);
          }}
          group={group}
        />
      </SectionContent>
    </Section>
  );
};

type TicketTableProps = {
  withoutTickets: boolean,
  pddTestId?: number,
  values: PddTest
};
const TicketTableSection = ({
  withoutTickets,
  pddTestId,
  values
}: TicketTableProps) => {
  const [data, setData] = useState([]);

  const fetch = useCallback(async () => {
    if (pddTestId) {
      const { data } = await pddTicketApi.fetch({ pddTestId });
      setData(data);
    }
  }, [pddTestId]);

  useEffect(() => {
    fetch();
  }, [fetch, pddTestId]);

  return withoutTickets === false ? (
    <Section>
      <SectionTitle
        divider
        suffix={
          pddTestId ? (
            <Button
              onClick={() => navigate(`/admin/pdd/tickets/new/${pddTestId}`)}
            >
              Добавить билет
            </Button>
          ) : (
            <Popconfirm
              onConfirm={async () => {
                const id = await submitPddTest(values);
                if (id) await navigate(`/admin/pdd/tickets/new/${id}`);
              }}
              title="Перед добавлением билета необходимо сохранить тест. Сохранить тест и продолжить?"
            >
              <Button>Добавить билет</Button>
            </Popconfirm>
          )
        }
      >
        Билеты
      </SectionTitle>
      <SectionContent>
        <Table
          columns={[
            {
              title: 'Уникальный номер билета',
              dataIndex: 'idNumber'
            },
            {
              title: '',
              width: 50,
              dataIndex: 'id',
              render: (id: number, record: PddTicket) => {
                return (
                  <Link
                    to={`/admin/pdd/tickets/edit/${id}/${record.pddTestId}`}
                  >
                    перейти
                  </Link>
                );
              }
            },
            {
              width: '20px',
              dataIndex: 'id',
              render: (id: number) => (
                <Popconfirm
                  placement="topRight"
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={async () => {
                    await pddTicketApi.delete(id);
                    await fetch();
                  }}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              )
            }
          ]}
          data={data}
        />
      </SectionContent>
    </Section>
  ) : null;
};

export default (props: CommonFormProps<$Shape<PddGroup>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Наименование" fast required name="name">
                  {({ name, value }) => (
                    <Input
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Тип"
                  fast
                  required
                  name="type"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <PddTestTypeSelect
                      name={name}
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Время тестирования"
                  fast
                  required
                  name="examTime"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <TimePicker
                      name={name}
                      value={moment.unix(value).utc()}
                      format="HH[ч ]: mm[м ]"
                      onChange={value => {
                        setFieldValue(
                          name,
                          moment(value)
                            .utc()
                            .unix() || 0
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem />
              <GridItem>
                <FormField
                  label="Наличие билетов"
                  fast
                  required
                  name="withoutTickets"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <YesNoSelect
                      name={name}
                      value={!value}
                      onChange={value => setFieldValue(name, !value)}
                      reset
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Кол-во вопросов в тесте"
                  fast
                  required
                  name="questionsCount"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <InputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дополнительные вопросы"
                  fast
                  required
                  name="addQuestions"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <YesNoSelect
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                      reset
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Кол-во допустимых ошибок"
                  fast
                  name="maxWrongAnswers"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <InputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <GroupTableSection
              noWrapMe
              values={values}
              setFieldValue={setFieldValue}
            />
            <TicketTableSection
              noWrapMe
              withoutTickets={values.withoutTickets}
              pddTestId={values.id}
              values={values}
            />
          </>
        );
      }}
    </CommonForm>
  );
};
