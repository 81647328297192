//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../components/hoc/common/handbook/CommonFilter';
import Select from '../../../../components/selects';

const { YearSelect } = Select;

type BudgetSummaryParamsFilter = {
  year?: number
};

export const BudgetSummaryFilter = (
  props: FilterProps<BudgetSummaryParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<BudgetSummaryParamsFilter>>) =>
      CommonFilter<BudgetSummaryParamsFilter>({ ...filterProps, ...props }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => {
        return (
          <>
            <FilterItem>
              <Field mBottomNone>
                <YearSelect
                  size="small"
                  onChange={value => changeValue('year', value)}
                  value={values.year}
                />
              </Field>
            </FilterItem>
          </>
        );
      }}
    </Filter>
  );
};
export default BudgetSummaryFilter;
