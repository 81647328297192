import { ShiftCard, ShiftList } from '../containers/Shifts';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: ShiftList,
        path: '/shifts',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: ShiftCard,
        path: '/shifts/:shiftId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
];