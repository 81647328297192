// @flow
import type { BioTreatmentFacilityTalon } from '../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams,
} from './index';
import type { FetchListParams, ListResponse } from './index';

export const convertDataToServer = (
  bioTreatmentFacilityTalon: BioTreatmentFacilityTalon
) => ({
  ...bioTreatmentFacilityTalon,
  sum: convertFromRubToKop(bioTreatmentFacilityTalon.sum),
});
export const convertDataFromServer = (
  bioTreatmentFacilityTalon: BioTreatmentFacilityTalon
) => ({
  ...bioTreatmentFacilityTalon,
  sum: convertFromKopToRub(bioTreatmentFacilityTalon.sum),
});

const baseUrl = 'bioTreatmentFacilityTalon';
const bioTreatmentFacilityTalon = new CommonApi<BioTreatmentFacilityTalon>(
  baseUrl,
  'учет БОС',
  convertDataToServer,
  convertDataFromServer
);

const print = async (id: number) => {
  const print = await downloadRequestWithToken(`/${baseUrl}/${id}/print`);
  if (print) return print;
  throw new Error('Не удалось распечатать');
};

const list = async (params: FetchListParams<any> = initialFetchListParams) => {
  const list = await fetchRequest.get(`/${baseUrl}/talonList`, {
    ...initialFetchListParams,
    ...params,
  });
  if (list) {
    return list;
  }
  throw new Error('Не удалось получить список');
};

export default { ...bioTreatmentFacilityTalon, print, list };
