// @flow
import React from 'react';
import styled from 'styled-components';

import type { AssignmentLimit } from '../../../../../../../lib/types';
import { regulationLimitTypeEnum } from '../../../../../../../lib/enum';
import {
  convertContractVehicleToString,
  getValueObject
} from '../../../../../../../lib/helpers';
import { InputNumber } from '../../../../../../../components/inputs';
import { Row, Cell } from '../../../elements';

import ContractVehicleSelect from './ContractVehicleSelect';
import { getGridTemplate } from './elements';

type Props = {
  // Лимит
  limit: AssignmentLimit,
  // Индекс лимита в массиве лимитов
  index: number,
  // Функция изменения значения лимита по ключу в массиве лимитов
  onChange: (index: number, name: $Keys<AssignmentLimit>, value: any) => void,
  editable: boolean
};

const NumberCell = styled(Cell)`
  padding: 0px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: initial;
  margin: 2px;
`;
/**
 * Компонент строки лимита в закреплении
 */
export default ({ limit, index, editable, onChange }: Props) => {
  return (
    <>
      <Row media={getGridTemplate(limit.regulationLimit.type)} gutter={0}>
        {limit.regulationLimit.type === regulationLimitTypeEnum.orgUnit && (
          <Cell>
            {limit && getValueObject(limit, 'regulationLimit.orgUnitName')
              ? getValueObject(limit, 'regulationLimit.orgUnitName')
              : '-'}
          </Cell>
        )}
        {limit.regulationLimit.type ===
          regulationLimitTypeEnum.oneOffRequests && (
          <Cell>
            {limit && getValueObject(limit, 'regulationLimit.orgUnitName')
              ? getValueObject(limit, 'regulationLimit.orgUnitName')
              : '-'}
          </Cell>
        )}
        {limit.regulationLimit.type === regulationLimitTypeEnum.employee && (
          <Cell>
            {limit && getValueObject(limit, 'regulationLimit.jobTitle.name')
              ? getValueObject(limit, 'regulationLimit.jobTitle.name')
              : '-'}
          </Cell>
        )}
        {limit.regulationLimit.type === regulationLimitTypeEnum.ovb && (
          <Cell>
            {limit && getValueObject(limit, 'regulationLimit.ovbName')
              ? getValueObject(limit, 'regulationLimit.ovbName')
              : '-'}
          </Cell>
        )}

        <Cell style={{ overflow: 'hidden' }}>
          {editable ? (
            <ContractVehicleSelect
              size="small"
              contractVehiclePlanId={limit.contractVehiclePlanId}
              value={limit.contractVehicleId}
              onChange={id => onChange(index, 'contractVehicleId', id)}
            />
          ) : (
            convertContractVehicleToString(limit.contractVehicle)
          )}
        </Cell>
        {limit.assignmentMonths.map((month, indexMonth) => {
          const { assignmentMonths } = limit;
          return (
            <>
              {editable ? (
                <NumberCell>
                  <StyledInputNumber
                    value={month.weekDaysCount}
                    onChange={weekDaysCount => {
                      assignmentMonths[indexMonth] = {
                        ...assignmentMonths[indexMonth],
                        weekDaysCount
                      };
                      onChange(index, 'assignmentMonths', assignmentMonths);
                    }}
                  />
                </NumberCell>
              ) : (
                <Cell>{month.weekDaysCount}</Cell>
              )}
              {editable ? (
                <NumberCell>
                  <StyledInputNumber
                    value={month.daysOffCount}
                    onChange={daysOffCount => {
                      assignmentMonths[indexMonth] = {
                        ...assignmentMonths[indexMonth],
                        daysOffCount
                      };
                      onChange(index, 'assignmentMonths', assignmentMonths);
                    }}
                  />
                </NumberCell>
              ) : (
                <Cell>{month.daysOffCount}</Cell>
              )}
            </>
          );
        })}
      </Row>
    </>
  );
};
