// @flow
import type {
  AdditionalCalculationMonth,
  AdditionalCalculation,
  CalculationStatus
} from '../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  additionalCalculation: AdditionalCalculation
): AdditionalCalculation => ({
  ...additionalCalculation,
  sum: convertFromRubToKop(additionalCalculation.sum),
  months: additionalCalculation.months.map(
    convertDataToServerAdditionalCalculationMonth
  )
});

export const convertDataFromServer = (
  additionalCalculation: AdditionalCalculation
): AdditionalCalculation => ({
  ...additionalCalculation,
  sum: convertFromKopToRub(additionalCalculation.sum),
  months: additionalCalculation.months.map(
    convertDataFromServerAdditionalCalculationMonth
  )
});

export const convertDataToServerAdditionalCalculationMonth = (
  additionalCalculationMonth: AdditionalCalculationMonth
): AdditionalCalculationMonth => ({
  ...additionalCalculationMonth,
  cost: convertFromRubToKop(additionalCalculationMonth.cost)
});

export const convertDataFromServerAdditionalCalculationMonth = (
  additionalCalculationMonth: AdditionalCalculationMonth
): AdditionalCalculationMonth => ({
  ...additionalCalculationMonth,
  cost: convertFromKopToRub(additionalCalculationMonth.cost)
});

const baseUrl = 'additionalCalculation';
const additionalCalculation = new CommonApi<AdditionalCalculation>(
  baseUrl,
  'расчет стоимости доп расходов',
  convertDataToServer,
  convertDataFromServer
);

const changeStatus = async (data: {
  vehiclePlanId: number,
  status: CalculationStatus
}): Promise<any> => {
  const updated = await fetchRequest.put(`/${baseUrl}/changeStatus`, data);
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export default {
  ...additionalCalculation,
  changeStatus
};
