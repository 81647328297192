// @flow
import type { RequestSection } from '../requests';
import RequestsController from '../requests';

export class Events {
  controller: RequestSection;

  constructor(controller: RequestsController) {
    this.controller = controller.getApiSection('events');
  }

  // проверяем изменения
  async checkUpdates() {
    return await this.controller.fetch('check_updates', {
      detalization: 3
    });
  }

  // подписываемся на изменения элементов
  async updateUnits(unitIds: number[]) {
    return await this.controller.fetch('update_units', {
      mode: 'add',
      units: unitIds.map(id => ({
        id,
        detect: { '*': 0 }
      }))
    });
  }
}
