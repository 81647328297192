//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import OrgUnitSelect from '../../../components/OrgUnitSelect';
import { StyledInput } from '../../../components/hoc/common/components/elements';

export type StockFilterParams = {
  nodeId?: number,
  mnemocode?: string
};

export const StockFilter = (props: FilterProps<StockFilterParams>) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<StockFilterParams>>) =>
      CommonFilter<StockFilterParams>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                onlyService
                placeholder="Служба"
                size="small"
                onChange={value => changeValue('nodeId', value)}
                value={values.nodeId}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <StyledInput
                placeholder="Мнемокод"
                size="small"
                value={values.mnemocode}
                onChange={e =>
                  changeValue('mnemocode', e.target.value || undefined)
                }
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default StockFilter;
