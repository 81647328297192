// @flow
import React from 'react';

import type { BranchBudgetSummary, BudgetSummary } from '../../../../lib/types';

import Contracts from './Contracts/Form';
import CostEstimation from './CostEstimation/Form';

type Props = {
  id: number,
  orgUnitId: number,
  branchBudgetSummary: ?BranchBudgetSummary,
  budgetSummary: ?BudgetSummary,
  type?: string,
  onSubmit: Function,
};

export default (props: Props) => {
  const { id, orgUnitId, branchBudgetSummary, budgetSummary, onSubmit } = props;

  const getComponents = () => {
    switch (props.type) {
      case 'contracts':
        return (
          <Contracts
            id={id}
            orgUnitId={orgUnitId}
            budgetSummary={budgetSummary}
            branchBudgetSummary={branchBudgetSummary}
          />
        );
      default:
        return (
          <CostEstimation
            id={id}
            orgUnitId={orgUnitId}
            budgetSummary={budgetSummary}
            branchBudgetSummary={branchBudgetSummary}
            onSubmit={onSubmit}
          />
        );
    }
  };

  return getComponents();
};
