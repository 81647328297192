//@flow
import React, { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import random from 'lodash/random';
import isEmpty from 'lodash/isEmpty';

import { GridItem } from '../../../../../components/layout';
import Grid from '../../../../../components/layout/Grid';
import Field from '../../../../../components/card/Field';
import Selects from '../../../../../components/selects';
import type { PddTicket } from '../../../../../lib/types';

const { PddTicketSelect } = Selects;

type PddTicketModalProps = {
  testId: number,
  visible: boolean,
  onClose: () => any,
  onSelect: (ticket: PddTicket) => any
};

export const TicketModal = (props: PddTicketModalProps) => {
  const tickets = useRef([]);
  const [ticket, setTicket] = useState({});

  return (
    <Modal
      centered
      destroyOnClose
      footer={[
        <Button key="back" onClick={() => props.onClose()}>
          Отмена
        </Button>,
        <Button
          disabled={!ticket.id}
          key="submit"
          type="primary"
          onClick={() => props.onSelect(ticket)}
        >
          Ок
        </Button>
      ]}
      title={'Выбор билета'}
      visible={props.visible}
      onCancel={props.onClose}
      width={500}
      height={500}
    >
      <Grid cols={1}>
        <GridItem>
          <Field>
            <Button
              onClick={() => {
                !isEmpty(tickets.current) &&
                  setTicket(
                    tickets.current[random(0, tickets.current.length - 1)]
                  );
              }}
            >
              Выбрать случайный билет
            </Button>
          </Field>
        </GridItem>
        <GridItem>
          <Field>
            <PddTicketSelect
              value={ticket.id}
              onChange={(value: number, option) => {
                setTicket(option.props.ticket);
              }}
              filter={{
                pddTestId: props.testId
              }}
              onFetched={data => {
                tickets.current = data;
              }}
            />
          </Field>
        </GridItem>
      </Grid>
    </Modal>
  );
};
