// @flow
import React from 'react';

import { TabItem, Tabs } from './../../../../components/ui';
import { entityStatusEnum } from '../../../../lib/enum';

type Props = {
  orgUnitId: number,
  id: number,
  calculationEconomyStatus?: string,
  distributionEconomyStatus?: string,
  proposalEconomyStatus?: string
};

export default ({
  id,
  orgUnitId,
  calculationEconomyStatus,
  distributionEconomyStatus,
  proposalEconomyStatus
}: Props) => {
  return (
    <Tabs withRouter defaultTab={`/budget/details/${id}/${orgUnitId}`}>
      <TabItem
        label={'Расчет экономии'}
        key={'1'}
        url={`/budget/details/${id}/${orgUnitId}`}
      />
      {calculationEconomyStatus === entityStatusEnum.approved && (
        <TabItem
          label={'Распределение экономии'}
          key={'2'}
          url={`/budget/details/${id}/${orgUnitId}/distribution-economy`}
        />
      )}
      {distributionEconomyStatus === entityStatusEnum.approved && (
        <TabItem
          label={'Предложение'}
          key={'3'}
          url={`/budget/details/${id}/${orgUnitId}/proposal-economy`}
        />
      )}
      {proposalEconomyStatus === entityStatusEnum.approved && (
        <TabItem
          label={'Договоры'}
          key={'3'}
          url={`/budget/details/${id}/${orgUnitId}/contracts`}
        />
      )}
    </Tabs>
  );
};
