// @flow
import React, { useCallback, useEffect, useMemo } from 'react';
import Select from 'antd/lib/select';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import Grid from '../../../../../../../../../components/layout/Grid';
import { ControlButton, GridItem } from './styles';
import { Icon } from '../../../../../../../../../components/ui';
import type { MonitoringHistoryState } from '../../../../../../../MonitoringScreen';
import { basePlaybackSpeed } from '../../../../../../../MonitoringScreen';

const { RangePicker } = DatePicker;

type Props = {
  // Состояние истории
  state: MonitoringHistoryState,
  // Инстанс проигрывателя треков
  playback: any,
  // Обработка изменения стейта
  onStateChange: (
    fn: (
      state: $Shape<MonitoringHistoryState>
    ) => $Shape<MonitoringHistoryState>
  ) => void,
};

/**
 * Контрольная панель управления историей
 */
export const ControlPanel = ({ onStateChange, playback, state }: Props) => {
  const { speed, status, startDate, endDate, history, isEnded, currentIndex } =
    state;

  const currentHistoryItem = useMemo(() => {
    return history[currentIndex] || {};
  }, [currentIndex, history]);

  /**
   * Обработка изменения скорости воспроизведения
   */
  const handleSpeedChange = useCallback(
    (speed: 1 | 2 | 4) => {
      onStateChange(() => ({ speed }));
    },
    [onStateChange]
  );

  /**
   * Обработка изменения периода
   */
  const handlePeriodChange = useCallback(
    (startDate: Date, endDate: Date) => {
      onStateChange(() => ({ startDate, endDate }));
    },
    [onStateChange]
  );

  const speedSelect = useMemo(() => {
    return (
      <Select size="small" value={speed} onChange={handleSpeedChange}>
        {[1, 2, 4].map((value, index) => (
          <Select.Option key={index} value={value}>
            {value}x
          </Select.Option>
        ))}
      </Select>
    );
  }, [handleSpeedChange, speed]);

  /**
   * Воспроизведение
   */
  const play = useCallback(() => {
    // Если воспроизведение было окончено
    // то при повторном нажатии начать заново
    if (isEnded) playback && playback.rePlaying();
    // Продолжить воспроизведение
    else playback && playback.start();
    onStateChange(() => ({ status: 'play' }));
  }, [isEnded, playback, onStateChange]);

  /**
   * Пауза воспроизведения
   */
  const pause = useCallback(() => {
    playback && playback.stop();
    onStateChange(() => ({ status: 'pause' }));
  }, [playback, onStateChange]);

  const vehicleSpeed = useMemo(() => {
    return currentHistoryItem.speed
      ? Math.round(currentHistoryItem.speed).toFixed(0)
      : 0;
  }, [currentHistoryItem.speed]);

  /**
   * При изменении скорости в селекте
   * устанавливает скорость у плагина.
   * Сделано через useEffect, т.к. в callback
   * playback будет при первом вызове null
   */
  useEffect(() => {
    if (playback) {
      playback.setSpeed(basePlaybackSpeed * speed * 0.8);
    }
  }, [speed, playback]);

  /**
   * Устанавливает статус воспроизведения на паузу
   * при окончании воспроизведения
   */
  useEffect(() => {
    if (isEnded) onStateChange(() => ({ status: 'pause' }));
  }, [isEnded, onStateChange]);

  /**
   * При unmount останавливаем воспроизведение
   */
  useEffect(() => {
    return () => {
      pause();
      playback && playback.stop();
    };
  }, [pause, playback]);

  const renderControls = useMemo(() => {
    return (
      <>
        {status === 'play' && (
          <ControlButton onClick={() => pause()}>
            <Icon type="pause" size={12} />
          </ControlButton>
        )}
        {status === 'pause' && (
          <ControlButton onClick={() => play()}>
            <Icon type="play" size={12} />
          </ControlButton>
        )}
      </>
    );
  }, [status, play, pause]);

  return (
    <Grid cols={['130px', '1fr', '350px']} gutter="16px">
      <GridItem>
        {speedSelect}
        {renderControls}
      </GridItem>
      <GridItem>
        <Grid gutter="16px">
          <GridItem>{vehicleSpeed} км/ч</GridItem>
          <GridItem>
            {currentHistoryItem.timestamp &&
              moment(currentHistoryItem.timestamp).format('DD.MM.YYYY HH:mm')}
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem>
        <RangePicker
          size="small"
          format="DD.MM.YYYY"
          placeholder={['Начало', 'Конец']}
          showTime={{
            format: 'HH:mm',
            defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
          }}
          value={[
            startDate ? moment(startDate) : null,
            endDate ? moment(endDate) : null,
          ]}
          disabledDate={(current) => {
            return current && current.valueOf() > Date.now();
          }}
          onChange={(value, dateString) => {
            const [startDate, endDate] = value;
            handlePeriodChange(startDate?.toDate(), endDate?.toDate());
          }}
        />
      </GridItem>
    </Grid>
  );
};
