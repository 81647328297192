// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';

import MaskInput from '../MaskInput';
import { cyrLetter } from '../../../lib/validation';

export const formatLicenseNumberMask = (value: string) => {
  const cyrRegexp = new RegExp(`^\\d{2}[${cyrLetter}]`),
    numbersRegexp = new RegExp('^\\d{2}\\d');
  if (numbersRegexp.test(value)) {
    return '99 99 999999';
  } else if (cyrRegexp.test(value)) {
    return '99 aa 999999';
  }
  return '** ** ******';
};

/**
 * Маска водительского удостоверения
 */
export default (props: InputProps) => (
  <MaskInput
    mask="99 ** 999999"
    formatChars={{
      a: `[${cyrLetter}]`,
      '*': `[0-9${cyrLetter}]`
    }}
    formatMask={formatLicenseNumberMask}
    placeholder="77 77 123456"
    transform={(value: string) => value.toUpperCase()}
    convertOnSave={(value: string) => value.replace(/\s+/g, '')}
    {...props}
  />
);
