import {
    ContractVehicleCard,
    ContractVehiclesForm,
    ContractVehiclesList,
} from '../containers/ContractVehicles';
import {
    ExpertiseServiceGpmForm,
    InspectionGibddForm,
    InspectionGtnForm,
    KaskoForm,
    LicensePlateChangeForm,
    MeasuringDeviceCertificationForm,
    OsagoForm,
    PlatonForm,
    VehicleMonitoringForm,
    PassForm,
} from '../containers/services';
import {
    VehicleAccountant,
    VehicleAttachment,
    VehicleCard,
    VehicleChangeTools,
    VehicleDriver,
    VehicleFiles,
    VehicleForm,
    VehicleFuel,
    VehicleFuelsEndOilsForm,
    VehicleGpm,
    VehicleKilometrage,
    VehicleList,
    VehicleMaintenance,
    VehicleNumbers,
    VehicleOsago,
    VehicleOverhaul,
    VehiclePlanedMaintenance,
    VehiclePrimary,
    VehicleTariffs,
    VehicleTrailers,
} from '../containers/Vehicles';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: VehicleForm,
        path: '/vehicles/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: VehicleForm,
        path: '/vehicles/edit/:vehicleId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: VehicleList,
        path: '/vehicles',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
            accessTypeEnum.approvingVehicle,
            accessTypeEnum.viewingVehicleServiceOnly,
            accessTypeEnum.viewingVehicleBranch,
            accessTypeEnum.viewingAllVehicles,
            accessTypeEnum.handlingOrder,
            accessTypeEnum.gager,
        ],
    },
    {
        component: ContractVehiclesForm,
        path: '/vehicles/contract/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: ContractVehiclesForm,
        path: '/vehicles/contract/edit/:vehicleId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: ContractVehiclesList,
        path: '/vehicles/contract',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
            accessTypeEnum.approvingVehicle,
            accessTypeEnum.viewingVehicleServiceOnly,
            accessTypeEnum.viewingVehicleBranch,
            accessTypeEnum.viewingAllVehicles,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: ContractVehicleCard,
        path: '/vehicles/contract/:vehicleId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
            accessTypeEnum.approvingVehicle,
            accessTypeEnum.viewingVehicleServiceOnly,
            accessTypeEnum.viewingVehicleBranch,
            accessTypeEnum.viewingAllVehicles,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/osago',
        component: VehicleOsago,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/kilometrage',
        component: VehicleKilometrage,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/numbers',
        component: VehicleNumbers,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/primary',
        component: VehiclePrimary,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/driver',
        component: VehicleDriver,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/gpm',
        component: VehicleGpm,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/planed-maintenance',
        component: VehiclePlanedMaintenance,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/files',
        component: VehicleFiles,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/files/:tag',
        component: VehicleFiles,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/attachment',
        component: VehicleAttachment,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/accountant',
        component: VehicleAccountant,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/tariffs',
        component: VehicleTariffs,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/fuel',
        component: VehicleFuel,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/oils-technical-liquids',
        component: VehicleFuelsEndOilsForm,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/changeTools',
        component: VehicleChangeTools,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/overhaul',
        component: VehicleOverhaul,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/maintenance',
        component: VehicleMaintenance,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/edit/trailers',
        component: VehicleTrailers,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    // Тех. осмотр ГТН
    {
        component: InspectionGtnForm,
        path: '/vehicles/:vehicleId/inspections-gtn/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: InspectionGtnForm,
        path: '/vehicles/:vehicleId/inspections-gtn/:inspectionGtnId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: InspectionGibddForm,
        path: '/vehicles/:vehicleId/inspections-gibdd/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: InspectionGibddForm,
        path: '/vehicles/:vehicleId/inspections-gibdd/:inspectionGibddId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    // ОСАГО
    {
        component: OsagoForm,
        path: '/vehicles/:vehicleId/osagos/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: OsagoForm,
        path: '/vehicles/:vehicleId/osagos/:osagoId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    // КАСКО
    {
        component: KaskoForm,
        path: '/vehicles/:vehicleId/kasko/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: KaskoForm,
        path: '/vehicles/:vehicleId/kasko/:kaskoId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: ExpertiseServiceGpmForm,
        path: '/vehicles/:vehicleId/expertises-services-gpm/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: ExpertiseServiceGpmForm,
        path: '/vehicles/:vehicleId/expertises-services-gpm/:expertiseServiceGpmId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: ExpertiseServiceGpmForm,
        path: '/vehicles/:vehicleId/expertises-services-gpm/:expertiseServiceGpmId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: MeasuringDeviceCertificationForm,
        path: '/vehicles/:vehicleId/measuring-device-certifications/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: MeasuringDeviceCertificationForm,
        path: '/vehicles/:vehicleId/measuring-device-certifications/:measuringDeviceCertificationId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: MeasuringDeviceCertificationForm,
        path: '/vehicles/:vehicleId/measuring-device-certifications/:measuringDeviceCertificationId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: PlatonForm,
        path: '/vehicles/:vehicleId/platons/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: PlatonForm,
        path: '/vehicles/:vehicleId/platons/:platonId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: VehicleMonitoringForm,
        path: '/vehicles/:vehicleId/vehicles-monitoring/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: VehicleMonitoringForm,
        path: '/vehicles/:vehicleId/vehicles-monitoring/:vehicleMonitoringId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: LicensePlateChangeForm,
        path: '/vehicles/:vehicleId/license-plate-changes/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        component: LicensePlateChangeForm,
        path: '/vehicles/:vehicleId/license-plate-changes/:licensePlateChangeId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
        ],
    },
    {
        path: '/vehicles/:vehicleId/*',
        component: VehicleCard,
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingVehicle,
            accessTypeEnum.approvingVehicle,
            accessTypeEnum.viewingVehicleServiceOnly,
            accessTypeEnum.viewingVehicleBranch,
            accessTypeEnum.viewingAllVehicles,
            accessTypeEnum.handlingOrder,
            accessTypeEnum.gager,
        ],
    },
    {
        component: PassForm,
        path: '/vehicles/:vehicleId/passes/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
];