import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { notification, Statistic } from 'antd';
import {
  Bar,
  Cell,
  ComposedChart,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
} from 'recharts';
import { DatePicker } from '../../components/ant/DatePicker';

import { SectionContent } from '../../components/hoc/common/components/elements';
import Grid, { GridItem } from '../../components/layout/Grid';
import { dashboardApi } from '../../lib/api';
import type { Dashboard } from '../../lib/types';

import DashboardCard from './components/DashboardCard';
import CustomStatistic from './components/CustomStatistic';
import { navigate } from '@reach/router';

const DashboardPage = styled.div`
  font-size: max(0.5rem, min(calc(0.9vh + 0.5vw), 2rem));
  margin-bottom: 2.5em;
`;

const MainStatistic = (props) => (
  <Statistic groupSeparator=" " decimalSeparator="," {...props} />
);
const StyledStatistic = styled(MainStatistic)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GridItemHover = styled(GridItem)`
  cursor: pointer;
  transition: 0.5s;
  :hover {
    -webkit-box-shadow: -1px 1px 5px 3px rgba(171, 171, 171, 1);
    -moz-box-shadow: -1px 1px 5px 3px rgba(171, 171, 171, 1);
    box-shadow: -1px 1px 5px 3px rgba(171, 171, 171, 1);
  }
`;

const MaintenanceBar = (props) => {
  return <rect {...props} rx="5" />;
};

const StyledLegend = (value) => {
  return (
    <>
      <span style={{ color: '#677078', fontSize: '0.7em', fontWeight: 300 }}>
        {value}
      </span>
    </>
  );
};

export default () => {
  const [prevData, setPrevData] = useState({});
  const [data, setData] = useState({});
  const [date, setDate] = useState(moment().utc());
  const [maintenancePlan, setMaintenancePlan] = useState();
  const [perfomancePlan, setPerfomancePlan] = useState();

  const fetchData = useCallback(async () => {
    try {
      const data: Dashboard = await dashboardApi.get({
        date: date.toISOString(),
      });
      setData(data);
      setMaintenancePlan([
        {
          name: 'В работе',
          value: data.atWorkPlannedMaintenancesCount,
          color: '#2770FF',
        },
        {
          name: 'Регламент',
          value: data.regulationMaintenancesCount,
          color: '#CCDDFF',
        },
        {
          name: 'План',
          value: data.plannedMaintenancesCount,
          color: '#7EAAFF',
        },
        {
          name: 'Выполнено',
          value: data.donePlannedMaintenancesCount,
          color: '#6FCF97',
        },
      ]);
      setPerfomancePlan([
        {
          name: 'Запланировано',
          value: 100 - data.maintenancePerformance,
          color: '#A1C1FF',
        },
        {
          name: 'Просрочено',
          value: data.maintenancePerformance,
          color: '#17408E',
        },
      ]);
      const prevData: Dashboard = await dashboardApi.get({
        date: moment(date).add('-1', 'd').toISOString(),
      });
      setPrevData(prevData);
    } catch (e) {
      notification.error({
        message: e.message,
      });
    }
  }, [date]);

  useEffect(() => {
    fetchData().then();
  }, [date, fetchData]);

  return (
    <DashboardPage>
      <SectionContent style={{ float: 'right' }}>
        <DatePicker
          format="DD.MM.YYYY"
          value={date}
          onChange={(date) => {
            setDate(date || moment());
          }}
        />
      </SectionContent>
      <SectionContent>
        <Grid
          cols={12}
          rows="18% 46% 18% 18%"
          gutter="1.5em"
          rowGutter="1em"
          customStyles={`
            height: 80vh;
            min-height: 600px;
          `}
        >
          <GridItem span={2}>
            <DashboardCard title="Топливо и ТЖ">
              <StyledStatistic
                value={data.fuelsAndLiquidsSum}
                suffix="руб."
                valueStyle={{
                  color:
                    prevData.fuelsAndLiquidsSum >= data.fuelsAndLiquidsSum
                      ? '#27AE60'
                      : '#E9180A',
                  fontSize: '1.5em',
                }}
              />
            </DashboardCard>
          </GridItem>
          <GridItem span={2}>
            <DashboardCard title="Ремонты">
              <StyledStatistic
                value={data.repairsSum}
                suffix="руб."
                valueStyle={{
                  color:
                    prevData.repairsSum < data.repairsSum
                      ? '#27AE60'
                      : '#E9180A',
                  fontSize: '1.5em',
                }}
              />
            </DashboardCard>
          </GridItem>
          <GridItem span={2}>
            <DashboardCard title="ТО">
              <StyledStatistic
                value={data.maintenancesSum}
                suffix="руб."
                valueStyle={{
                  color:
                    prevData.maintenancesSum < data.maintenancesSum
                      ? '#27AE60'
                      : '#E9180A',
                  fontSize: '1.5em',
                }}
              />
            </DashboardCard>
          </GridItem>
          <GridItem span={2}>
            <DashboardCard title="На линии" titlePosition="bottom">
              <StyledStatistic
                value={data.onLineTripsCount}
                valueStyle={{
                  color: '#2770FF',
                  fontSize: '2em',
                  fontWeight: 400,
                }}
              />
            </DashboardCard>
          </GridItem>
          <GridItem span={2}>
            <DashboardCard title="В парке" titlePosition="bottom">
              <StyledStatistic
                value={data.parkVehiclesCount}
                valueStyle={{
                  color: '#A1C1FF',
                  fontSize: '2em',
                  fontWeight: 400,
                }}
              />
            </DashboardCard>
          </GridItem>
          <GridItem span={2}>
            <DashboardCard title="На ремонте" titlePosition="bottom">
              <StyledStatistic
                value={data.maintenanceVehiclesCount}
                valueStyle={{
                  color: '#17408E',
                  fontSize: '2em',
                  fontWeight: 400,
                }}
              />
            </DashboardCard>
          </GridItem>
          <GridItem
            cellUnion={{
              gridColumn: '1 / span 3',
              gridRow: '2 / span 2',
            }}
          >
            <DashboardCard title="План ТО">
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  data={maintenancePlan}
                  margin={{
                    top: 10,
                    bottom: 10,
                  }}
                >
                  <XAxis
                    type="category"
                    dataKey="name"
                    tickLine={false}
                    stroke="#677078"
                    label={{
                      stroke: '#677078',
                    }}
                    fontSize="0.7em"
                    fontWeight={300}
                    height={20}
                    offset={20}
                    interval={0}
                  />
                  <Bar
                    dataKey="value"
                    shape={<MaintenanceBar />}
                    fill="#528DFF"
                    barSize={44}
                    label={{
                      position: 'insideTop',
                      fontSize: '1.2em',
                      stroke: 'white',
                      fill: 'white',
                      offset: 10,
                      content: (el) => {
                        return el.value || null;
                      },
                    }}
                  >
                    {maintenancePlan?.map((el, index) => (
                      <Cell key={`cell-${index}`} fill={el.color} />
                    ))}
                  </Bar>
                </ComposedChart>
              </ResponsiveContainer>
            </DashboardCard>
          </GridItem>
          <GridItemHover
            cellUnion={{
              gridColumn: '4 / span 3',
              gridRow: '2 / span 2',
            }}
            onClick={() => navigate(`/maintenances/journal?isOverdue=true`)}
          >
            <DashboardCard title="Исполнение ТО (год)">
              <ResponsiveContainer>
                <PieChart>
                  <Legend
                    payload={perfomancePlan?.map((el) => ({
                      value: el.name,
                      color: el.color,
                    }))}
                    formatter={StyledLegend}
                  />
                  <Pie
                    data={perfomancePlan}
                    dataKey="name"
                    innerRadius="45%"
                    outerRadius="75%"
                  >
                    {perfomancePlan?.map((el, index) => (
                      <Cell key={`cell-${index}`} fill={el.color} />
                    ))}
                    <Label
                      value={`${data.maintenancePerformance}%`}
                      position="center"
                      stroke="#677078"
                      fill="#677078"
                      fontSize="1.5em"
                    />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </DashboardCard>
          </GridItemHover>
          <GridItem
            cellUnion={{
              gridColumn: '7 / span 6',
              gridRow: '2',
            }}
          >
            <DashboardCard title="Стиль вождения">
              <Grid gutter="1.2em" cols={3} customStyles={{ height: '100%' }}>
                <GridItem>
                  <CustomStatistic title="Отлично">
                    <StyledStatistic
                      value={data.perfectRatesCount}
                      valueStyle={{
                        color: '#27AE60',
                        fontSize: '2em',
                        fontWeight: 400,
                      }}
                    />
                  </CustomStatistic>
                </GridItem>
                <GridItem>
                  <CustomStatistic title="Хорошо">
                    <StyledStatistic
                      value={data.goodRatesCount}
                      valueStyle={{
                        color: '#7EAAFF',
                        fontSize: '2em',
                        fontWeight: 400,
                      }}
                    />
                  </CustomStatistic>
                </GridItem>
                <GridItem>
                  <CustomStatistic title="Плохо">
                    <StyledStatistic
                      value={data.badRatesCount}
                      valueStyle={{
                        color: '#E9180A',
                        fontSize: '2em',
                        fontWeight: 400,
                      }}
                    />
                  </CustomStatistic>
                </GridItem>
              </Grid>
            </DashboardCard>
          </GridItem>
          <GridItem
            cellUnion={{
              gridColumn: '7 / span 6',
              gridRow: '3',
            }}
          >
            <DashboardCard title="Превышение скорости">
              <Grid gutter="1.2em" cols={3} customStyles={{ height: '100%' }}>
                <GridItem>
                  <CustomStatistic title="20-40 км/ч">
                    <StyledStatistic
                      value={data.from20to40Count}
                      valueStyle={{
                        color: '#2770FF',
                        fontSize: '2em',
                        fontWeight: 400,
                      }}
                    />
                  </CustomStatistic>
                </GridItem>
                <GridItem>
                  <CustomStatistic title="40-60 км/ч">
                    <StyledStatistic
                      value={data.from40to60Count}
                      valueStyle={{
                        color: '#E9180A',
                        fontSize: '2em',
                        fontWeight: 400,
                      }}
                    />
                  </CustomStatistic>
                </GridItem>
                <GridItem>
                  <CustomStatistic title="Выше 60 км/ч">
                    <StyledStatistic
                      value={data.from60Count}
                      valueStyle={{
                        color: '#E9180A',
                        fontSize: '2em',
                        fontWeight: 400,
                      }}
                    />
                  </CustomStatistic>
                </GridItem>
              </Grid>
            </DashboardCard>
          </GridItem>
        </Grid>
      </SectionContent>
    </DashboardPage>
  );
};
