// @flow
import React from 'react';
import { Router } from '@reach/router';
import { navigate } from '../../../lib/helpers';
import Button from 'antd/lib/button';

import { Header, Section } from './../../../components/layout';
import { Tabs, TabItem } from './../../../components/ui';
import ArchiveTripList from './ArchiveTripList';
import IncomeTripList from './IncomeTripList';

export default () => {
  return (
    <>
      <Header left={<h1>Путевые листы собственных ТС</h1>} />
      <Tabs
        withRouter
        rightElement={
          (window.location.pathname === '/trips/self' ||
            window.location.pathname === '/trips/self/') && (
            <Button
              type="primary"
              style={{ marginRight: '20px' }}
              onClick={() => navigate('/trips/self/new')}
            >
              Создать
            </Button>
          )
        }
      >
        <TabItem label="Входящие" url="/trips/self" />
        <TabItem label="Архив" url="/trips/self/archive" />
      </Tabs>
      <Section>
        <Router>
          <IncomeTripList path="/" />
          <ArchiveTripList path="archive" />
        </Router>
      </Section>
    </>
  );
};
