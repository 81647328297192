// @flow

import type { User, Role } from './../types';
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const fetchRoles = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<User>> =>
  await fetchRequest.get('/role', {
    ...initialFetchListParams,
    ...params
  });

export const addRole = async (role: Role): Promise<Role> => {
  const added = await fetchRequest.post('/role', role);
  if (added) return added;
  throw new Error('Не удалось создать роль');
};

export const fetchRole = async (
  id: number,
  params: any = {}
): Promise<Role> => {
  const role = await fetchRequest.get(`/role/${id}`, params);
  if (role) return role;
  throw new Error('Не удалось получить роль');
};

export const updateRole = async (role: Role): Promise<Role> => {
  const updated = await fetchRequest.put('/role', role);
  if (updated) return updated;
  throw new Error('Не удалось обновить роль');
};

export const deleteRole = async (id: number): Promise<Role> => {
  const deleted = await fetchRequest.delete(`/role/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить роль');
};

export default {
  fetchRoles,
  addRole,
  fetchRole,
  updateRole,
  deleteRole
};
