import { accessTypeEnum } from '../../../lib/enum';

export const addDetailedBudgetAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingDetailedBudget
];

export const viewDetailedBudgetAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingDetailedBudget,
  accessTypeEnum.handlingBranchDetailedBudget,
  accessTypeEnum.viewingDetailedBudget
];

export const editDetailedBudgetAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingBranchDetailedBudget
];

export const approveDetailedBudgetBranchAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.approvingBranchDetailedBudget
];

export const approveDetailedBudgetAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.approvingBranchDetailedBudget
];
