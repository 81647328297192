// @flow

import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { fuelMultiplierApi } from '../../lib/api';
import type {
  FuelMultiplier,
  FuelMultiplierFilter
} from '../../lib/types/fuelMultiplier';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & {
  filter: FuelMultiplierFilter
};

export default (selectProps: Props) => (
  <AutocompleteSelect
    fetch={async params =>
      await fuelMultiplierApi.fetchFuelMultipliers({
        ...selectProps.filter,
        ...params
      })
    }
    fetchSingle={fuelMultiplierApi.fetchFuelMultiplier}
    notFoundText="Коэффициенты не найдены"
    valueKey="id"
    renderOption={(multiplier: FuelMultiplier, Option) => (
      <Option key={multiplier.id} value={multiplier.id} multiplier={multiplier}>
        {multiplier.name} x{multiplier.value}
      </Option>
    )}
    {...selectProps}
  />
);
