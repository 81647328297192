import styled, { css } from 'styled-components';
import UIPagination from '../../../../components/ui/Pagination';

export const Pagination = styled(UIPagination)`
  margin: 16px;
`;
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  overflow-x: auto;
  height: 100%;
  flex: 1;
`;
export const Item = styled.li`
  padding: 5px 16px;
  min-height: 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #3d4042;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      background: #2770ff;
      color: white;
    `};
  :hover {
    background: #2770ffc9;
    color: white;
  }
`;
export const Wrapper = styled.div`
  width: 330px;
  background: white;
  border-top: 1px solid #c0ccd7;
  border-right: 1px solid #c0ccd7;
  display: flex;
  flex-direction: column;
`;
export const Header = styled.header`
  min-height: 66px;
  border-bottom: 1px solid #c0ccd7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px;
`;
export const HeaderSide = styled.div`
  width: 50%;
  display: flex;
  ${props =>
    props.right &&
    css`
      justify-content: flex-end;
    `}
`;
