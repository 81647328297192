// @flow

import type { Contractor } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { ContractorsFilter } from '../../ducks/contractors';

export const addContractor = async (
  contractor: Contractor
): Promise<Contractor> => {
  const added = await fetchRequest.post('/contractor', contractor);
  if (added) return added;
  throw new Error('Не удалось создать подрядчика');
};

export const updateContractor = async (
  contractor: Contractor
): Promise<Contractor> => {
  const updated = await fetchRequest.put('/contractor', contractor);
  if (updated) return updated;
  throw new Error('Не удалось обновить подрядчика');
};

export const fetchContractors = async (
  params: FetchListParams<ContractorsFilter> = initialFetchListParams
): Promise<ListResponse<Contractor>> =>
  await fetchRequest.get('/contractor', {
    ...initialFetchListParams,
    ...params
  });

export const fetchContractor = async (
  id: number,
  params: any = {}
): Promise<Contractor> => {
  const Contractor = await fetchRequest.get(`/contractor/${id}`, params);
  if (Contractor) return Contractor;
  throw new Error('Не удалось загрузить подрядчика');
};

export const deleteContractor = async (id: number): Promise<Contractor> => {
  const deleted = await fetchRequest.delete(`/contractor/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить подрялчика');
};

export default {
  addContractor,
  updateContractor,
  fetchContractors,
  deleteContractor,
  fetchContractor
};
