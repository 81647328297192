import React from 'react';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { WashingFrequency } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import WashingVehicleTypeSelect from '../../../components/selects/WashingVehicleTypeSelect';
import { MonthsSelect } from '../../../components/selects/AutoGeneratedEnumSelects';

export default (props: CommonFormProps<$Shape<WashingFrequency>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Тип ТС для мойки"
                  fast
                  required
                  name="vehicleType.id"
                >
                  {({ name, value }) => (
                    <WashingVehicleTypeSelect
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                        setFieldValue('vehicleTypeId', value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество наружных моек в месяц"
                  fast
                  required
                  name="externalWashingCount"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Месяцы проведения моек салона"
                  fast
                  required
                  name="internalWashingCount"
                >
                  {() => (
                    <MonthsSelect
                      value={values.internalWashingMonths}
                      onChange={value => {
                        setFieldValue(`internalWashingMonths`, value);
                        setFieldValue(
                          'internalWashingCount',
                          value.length || undefined
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Месяцы проведения моек двигателя"
                  fast
                  required
                  name="engineWashingCount"
                >
                  {() => (
                    <MonthsSelect
                      value={values.engineWashingMonths}
                      onChange={value => {
                        setFieldValue(`engineWashingMonths`, value);
                        setFieldValue(
                          'engineWashingCount',
                          value.length || undefined
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
