// @flow

import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button/button';

import type { FetchListParams } from './../../lib/api';
import type { ListState, UserAccess, VehicleGroup } from './../../lib/types';
import { getListInitialState, setQueryParams } from './../../lib/helpers';

import { notificationLoading } from './../../components/Notifications';
import { Header, Section } from './../../components/layout';
import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import type { WashingVehicleType } from '../../lib/types/washingPriceList';
import { vehicleGroups } from '../../lib/enum';
import vehicleWashingTypeToGroupsMapApi from '../../lib/api/vehicleWashingTypeToGroupsMap';

import { withUserAccess } from './../withUserAccess';
import canAddWashingVehicleTypeMap from './accessRight';
import type { WashingVehicleTypeFilterParams } from './components/Filter';
import Filter from './components/Filter';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};

type State = ListState<WashingVehicleType> & {
  filter: WashingVehicleTypeFilterParams,
};

export class WashingPriceListTable extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50,
    },
    {
      title: 'Тип ТС',
      dataIndex: 'washingVehicleType',
      sorter: true,
    },
    {
      title: 'Марка',
      dataIndex: 'brandName',
    },
    {
      title: 'Группа ТС',
      dataIndex: 'vehicleGroup',
      render: (vehicleGroup: VehicleGroup) => vehicleGroups[vehicleGroup],
    },
  ];

  delete = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await vehicleWashingTypeToGroupsMapApi.deleteMap(id);
      await this.fetchData(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchData = async (page: number = 1, params: FetchListParams<any> = {}) => {
    this.setState({ loading: true });
    const { data, totalCount } =
      await vehicleWashingTypeToGroupsMapApi.fetchList({
        page,
        ...params,
        ...this.state.filter,
      });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    await this.fetchData(page);
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        width: '30px',
        stopPropagation: true,
        render: (record: any) => (
          <Operations>
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await this.delete(record.id)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ),
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      canAddWashingVehicleTypeMap.includes(access)
    );

  applyFilter = (filter: WashingVehicleTypeFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () => this.fetchData(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () => this.fetchData(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Сопоставление типов ТС для моек</h1>}
          right={
            canAdd && (
              <Link to="/admin/washing-vehicle-type-map/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={(record) => ({
              onClick: () =>
                canAdd
                  ? navigate(
                      `/admin/washing-vehicle-type-map/${record.id}/edit`
                    )
                  : navigate(`/admin/washing-vehicle-type-map/${record.id}`),
            })}
            columns={this.columns}
            fetch={this.fetchData}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(WashingPriceListTable);
