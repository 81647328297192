// @flow

import React, { Component } from 'react';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import moment from 'moment';
import styled from 'styled-components';

import {
  cleanContractVehicle,
  updateContractVehicle,
  addContractVehicle,
  fetchContractVehicle
} from './../../ducks/contractVehicle';
import type { AppState } from '../../ducks/redux';
import InnerForm from './components/InnerForm';
import { Panel } from './../../components/layout';
import { formatDateTimeToISOString } from './../../lib/helpers';
import type { ContractVehicle } from '../../lib/types';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { vehicleTypes, ownerTypes, vehicleStatusEnum } from '../../lib/enum';
import Header from '../../components/layout/Header';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  contractVehicle: ContractVehicle,
  updateContractVehicle: Function,
  orgUnitId: ?number,
  vehicleId: ?number,
  fetchContractVehicle: (id: ?number) => Promise<void>,
  uri: string,
  cleanContractVehicle: Function,
  addContractVehicle: Function,
  employeeBranchOrgUnitId: number
};

const initialContractVehicle = {
  vehicle: {}
};

class Form extends Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    this.props.cleanContractVehicle();
    if (vehicleId) {
      try {
        await this.props.fetchContractVehicle(vehicleId);
      } catch (error) {
        navigate('/vehicles/contract');
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { contractVehicle, employeeBranchOrgUnitId } = this.props;
    const { vehicle } = contractVehicle || {};
    const vehicleId = parseInt(this.props.vehicleId, 10);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {vehicle ? (
                <>
                  <Crumb to={`/vehicles/contract/${vehicle.id}`}>
                    {vehicle.vehicleModel &&
                      `${vehicleTypes[vehicle.vehicleModel.type]} ${
                        vehicle.vehicleModel.brandName
                      } ${vehicle.vehicleModel.name}`}
                    , {vehicle.yearIssued} ({vehicle.licensePlate})
                  </Crumb>
                  <Crumb to={`/vehicles/contract/edit/${vehicle.id}`}>
                    Редактирование
                  </Crumb>
                </>
              ) : (
                <Crumb to="/vehicles/contract/new">
                  Новое наемное транспортное средство
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {contractVehicle
              ? `Транспорт №${contractVehicle.id}`
              : 'Новый наемный транспорт'}
          </h1>
        </StyledPanel>
        <InnerForm
          vehicle={contractVehicle || initialContractVehicle}
          employeeBranchOrgUnitId={employeeBranchOrgUnitId}
          onSubmit={async (values: ContractVehicle) => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await this.props.updateContractVehicle({
                  ...values,
                  vehicle: {
                    ...values.vehicle,
                    status: vehicleStatusEnum.draft
                  }
                });
              } else {
                values.vehicle = {
                  ...values.vehicle,
                  ownerType: ownerTypes.contract
                };
                await this.props.addContractVehicle(values);
              }
              notification.success({
                message: 'Успешно сохранено',
                description: 'Изменения успешно сохранены'
              });
              navigate(`/vehicles/contract/${this.props.contractVehicle.id}`);
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
          onCancel={() =>
            vehicleId
              ? navigate(`/vehicles/contract/${vehicleId}`)
              : navigate('/vehicles/contract')
          }
          formatDate={this.formatDate}
          changeDate={formatDateTimeToISOString}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    contractVehicle: state.contractVehicle,
    vehicleId: parseInt(props.vehicleId, 10),
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }),
  {
    updateContractVehicle,
    fetchContractVehicle,
    cleanContractVehicle,
    addContractVehicle
  }
)(Form);
