// @flow

import type { Dispatch } from 'redux';

import type { Contractor, ServiceType } from './../lib/types';
import { contractorApi } from './../lib/api';
import type { Action, ListState, AppState } from './redux';
import { defaultPageSize } from './constants';
import type { FetchListParams } from './../lib/api';

export const DELETE_CONTRACTOR = 'vehicles/contractors/delete';
export const FETCH_CONTRACTORS = 'vehicles/contractors/fetch';
export const SET_CONTRACTORS_FILTERS = 'vehicles/contractors/filters/set';
export const CLEAN_CONTRACTORS_FILTERS = 'vehicles/contractors/filters/clean';

export type ContractorsFilter = {
  services?: ServiceType[],
  'company.id'?: number,
  'generalContractor.id'?: number
};

export type ContractorsState = ListState<Contractor> & {
  filters: ContractorsFilter
};

const initialState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0,
  filters: {}
};

const reducer = (
  state: ContractorsState = initialState,
  { type, payload }: Action
): ContractorsState => {
  switch (type) {
    case DELETE_CONTRACTOR:
      return {
        ...state,
        data: state.data.filter(contractor => contractor.id !== payload)
      };
    case FETCH_CONTRACTORS:
      return {
        ...state,
        ...payload
      };
    case SET_CONTRACTORS_FILTERS:
      return {
        ...state,
        filters: payload
      };
    case CLEAN_CONTRACTORS_FILTERS:
      return {
        ...state,
        filters: {}
      };
    default:
      return state;
  }
};

export const fetchContractors: Function = (
  page: number = 1,
  params: FetchListParams<ContractorsFilter> = {}
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const { filters, pageSize } = getState().contractors;
  const { data, totalCount } = await contractorApi.fetchContractors({
    ...filters,
    page,
    pageSize,
    ...params
  });
  dispatch({
    type: FETCH_CONTRACTORS,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export const applyFilters = (filters: ContractorsFilter) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_CONTRACTORS_FILTERS,
    payload: filters
  });
  dispatch(fetchContractors());
};

export const cleanFilters = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_CONTRACTORS_FILTERS
  });
  dispatch(fetchContractors());
};

export default reducer;
