// @flow
import type {
  CalculationStatus,
  MaintenanceOperationContractCalculation,
  MaintenanceOperationContractCalculationMonth
} from './../types';

import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  maintenanceOperationContractCalculation: MaintenanceOperationContractCalculation
): MaintenanceOperationContractCalculation => ({
  ...maintenanceOperationContractCalculation,
  sum: convertFromRubToKop(maintenanceOperationContractCalculation.sum),
  materialSum: convertFromRubToKop(
    maintenanceOperationContractCalculation.materialSum
  ),
  materialLessSum: convertFromRubToKop(
    maintenanceOperationContractCalculation.materialLessSum
  ),
  months: maintenanceOperationContractCalculation.months.map(
    convertDataToServerMonth
  )
});

export const convertDataFromServer = (
  maintenanceOperationContractCalculation: MaintenanceOperationContractCalculation
): MaintenanceOperationContractCalculation => ({
  ...maintenanceOperationContractCalculation,
  sum: convertFromKopToRub(maintenanceOperationContractCalculation.sum),
  materialSum: convertFromKopToRub(
    maintenanceOperationContractCalculation.materialSum
  ),
  materialLessSum: convertFromKopToRub(
    maintenanceOperationContractCalculation.materialLessSum
  ),
  months: maintenanceOperationContractCalculation.months.map(
    convertDataFromServerMonth
  )
});

export const convertDataToServerMonth = (
  maintenanceOperationContractCalculationMonth: MaintenanceOperationContractCalculationMonth
): MaintenanceOperationContractCalculationMonth => ({
  ...maintenanceOperationContractCalculationMonth,
  cost: convertFromRubToKop(maintenanceOperationContractCalculationMonth.cost),
  materialSum: convertFromRubToKop(
    maintenanceOperationContractCalculationMonth.materialSum
  )
});

export const convertDataFromServerMonth = (
  maintenanceOperationContractCalculationMonth: MaintenanceOperationContractCalculationMonth
): MaintenanceOperationContractCalculationMonth => ({
  ...maintenanceOperationContractCalculationMonth,
  cost: convertFromKopToRub(maintenanceOperationContractCalculationMonth.cost),
  materialSum: convertFromKopToRub(
    maintenanceOperationContractCalculationMonth.materialSum
  )
});

const basedUrl = 'maintenanceOperationContractCalculation';
const maintenanceOperationContractCalculation = new CommonApi<MaintenanceOperationContractCalculation>(
  basedUrl,
  'расчет техобслуживания',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

export default {
  ...maintenanceOperationContractCalculation,
  calculate,
  changeStatus
};
