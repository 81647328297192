//@flow
import React, { useState, useEffect } from 'react';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';

import { channelApi } from '../../lib/api';
import type { OrgUnitNode, Channel } from '../../lib/types';
import { getListInitialState, navigate } from './../../lib/helpers';

import { Icon, Operations, Popconfirm } from './../../components/ui';
import { notificationLoading } from '../../components/Notifications';
import { Header, Section } from '../../components/layout';
import Table from '../../components/ui/Table';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export const ChannelList = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState(getListInitialState());

  const fetch = async (page: number) => {
    setLoading(true);
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'fetchChannel',
      });
      const { data, totalCount } = await channelApi.fetch({
        page,
      });
      setState((oldState) => ({
        ...oldState,
        data,
        totalCount,
      }));
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('fetchChannel');
      setLoading(false);
    }
  };

  const handleClickAdd = () => {
    navigate('/admin/channel/new');
  };

  const handleDelete = (id: number) => async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await channelApi.delete(id);
      await fetch(state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  useEffect(() => {
    const { page } = qs.parse(window.location.search);
    setState((oldState) => ({
      ...oldState,
      page,
    }));
  }, []);

  const { data, page, totalCount, pageSize } = state;
  return (
    <>
      <Header
        left={<h1>Каналы учета БОС</h1>}
        right={<Button onClick={handleClickAdd}>Создать</Button>}
      />
      <Section>
        <Table
          onRow={(channel: Channel) => ({
            onClick: () => navigate(`/admin/channel/${channel.id}`),
          })}
          columns={[
            {
              title: 'Канал',
              key: 'channelId',
              dataIndex: 'channelId',
            },
            {
              title: 'Подразделение',
              key: 'organizationUnit',
              dataIndex: 'organizationUnit',
              render: (organizationUnit: ?OrgUnitNode) => {
                return organizationUnit?.name ?? '-';
              },
            },
            {
              title: '',
              width: 80,
              dataIndex: 'operations',
              // eslint-disable-next-line no-unused-vars
              render: (text: string, channel: Channel) => {
                return (
                  <Operations>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={handleDelete(channel.id)}
                    >
                      <StyledIcon type="x" />
                    </Popconfirm>
                  </Operations>
                );
              },
            },
          ]}
          data={data}
          fetch={fetch}
          fetchOnMount
          loading={loading}
          pagination={{
            page,
            pageSize,
            totalCount,
          }}
        />
      </Section>
    </>
  );
};
