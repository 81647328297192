// @flow
import React, { useCallback, useEffect, useState } from 'react';

import { calculationStatusEnum } from '../../../../lib/enum';

import { TabItem, Tabs } from './../../../../components/ui';
import { vehiclePlanApi } from './../../../../lib/api';

type Props = {
  vehiclePlanId: ?number
};

export default ({ vehiclePlanId }: Props) => {
  const [vehiclePlan, setVehiclePlan] = useState(null);
  let activeUrl = null;
  let activeOperationBudgetUrl = null;
  let activeUrlRepair = null;

  const getVehiclePlan = useCallback(async () => {
    if (vehiclePlanId) {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      setVehiclePlan(vehiclePlan);
    }
  }, [vehiclePlanId]);

  useEffect(() => {
    getVehiclePlan();
  }, [getVehiclePlan, vehiclePlanId]);

  if (vehiclePlanId) {
    activeUrl = [
      `/budget/vehicle/${vehiclePlanId}/measuring-device-certifications`,
      `/budget/vehicle/${vehiclePlanId}/license-plate-change`,
      `/budget/vehicle/${vehiclePlanId}/driver-inspection`,
      `/budget/vehicle/${vehiclePlanId}/inspections-gibdd`,
      `/budget/vehicle/${vehiclePlanId}/inspections-gtn`,
      `/budget/vehicle/${vehiclePlanId}/federal-highway`,
      `/budget/vehicle/${vehiclePlanId}/gpm-maintenance`,
      `/budget/vehicle/${vehiclePlanId}/spring-pass`,
      `/budget/vehicle/${vehiclePlanId}/cargo-pass`,
      `/budget/vehicle/${vehiclePlanId}/batteries`,
      `/budget/vehicle/${vehiclePlanId}/material`,
      `/budget/vehicle/${vehiclePlanId}/washing`,
      `/budget/vehicle/${vehiclePlanId}/pass`,
      `/budget/vehicle/${vehiclePlanId}/gps`
    ];
    activeOperationBudgetUrl = [
      `/budget/vehicle/${vehiclePlanId}/fuels-and-oils`,
      `/budget/vehicle/${vehiclePlanId}/mechanisms`,
      `/budget/vehicle/${vehiclePlanId}/tire`,
      `/budget/vehicle/${vehiclePlanId}/maintenance`
    ];
    activeUrlRepair = [
      `/budget/vehicle/${vehiclePlanId}/current-repair`,
      `/budget/vehicle/${vehiclePlanId}/overhaul-repair`,
      `/budget/vehicle/${vehiclePlanId}/budget-repair`
    ];
  }

  return (
    <Tabs withRouter>
      {!!vehiclePlanId && (
        <TabItem
          label="Перечень CТС"
          url={`/budget/vehicle/${vehiclePlanId}/card`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          activeUrl={activeUrl}
          label="Независящие от режима эксплуатации ТС"
          url={`/budget/vehicle/${vehiclePlanId}/batteries`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          activeUrl={activeOperationBudgetUrl}
          label="Зависящие от режима эксплуатации ТС"
          url={`/budget/vehicle/${vehiclePlanId}/fuels-and-oils`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          activeUrl={activeUrlRepair}
          label="Ремонт"
          url={`/budget/vehicle/${vehiclePlanId}/current-repair`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          label="Доп. потребности"
          url={`/budget/vehicle/${vehiclePlanId}/additional`}
        />
      )}
      {!!vehiclePlanId &&
        vehiclePlan?.budgetStatus === calculationStatusEnum.calculationDone && (
          <TabItem
            label="Бюджет"
            url={`/budget/vehicle/${vehiclePlanId}/itog`}
          />
        )}
    </Tabs>
  );
};
