// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer,
} from '../../../components';
import { DatePicker, RangePicker } from '../../../components/ant/DatePicker';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import type { Vehicle } from '../../../lib/types';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import { ownerTypes } from '../../../lib/enum';
import Field from '../../../components/card/Field';
import Input from '../../../components/ui/CustomInput';

const { VehicleSelect, ContractorSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type OsagoFilterParams = {
  osagoNumber?: string,
  contractorId?: number,
  vehicleId?: number,
  diagnosticCardNumber?: string,
  osagoFinishedDate?: string | moment,
  startDate?: string | moment,
  endDate?: string | moment
};

type Props = {
  filter: OsagoFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const OsagoFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          applyFilter,
          showAdditional,
          handleAdditional,
          cleanFilter
        }: FilterChildrenParams<OsagoFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  filter={{
                    ownerType: ownerTypes.self
                  }}
                  onChange={(value: number) => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Серия и номер ОСАГО"
                  size="small"
                  onChange={({ target: { value } }) =>
                    changeValue('osagoNumber', value)
                  }
                  value={values.osagoNumber}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  placeholder="Выберите страховщика"
                  onChange={value => changeValue('contractorId', value)}
                  value={values.contractorId}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  value={
                    values.osagoFinishedDate &&
                    moment.utc(values.osagoFinishedDate)
                  }
                  placeholder="Дата окончания"
                  onChange={(value: Object, dateString: string) =>
                    changeValue(
                      'osagoFinishedDate',
                      formatDateTimeToISOString(value, dateString)
                    )
                  }
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Серия и номер диаг. карты"
                  size="small"
                  onChange={({ target: { value } }) =>
                    changeValue('diagnosticCardNumber', value)
                  }
                  value={values.diagnosticCardNumber}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default OsagoFilter;
