// @flow
import React, { Component } from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle,
} from '../../../ducks/vehicle';
import type { Vehicle } from '../../../lib/types';
import { vehicleApi } from '../../../lib/api';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { Form, Selects } from '../../../components';
import { trailerVehicleTypes, vehicleStatusEnum } from '../../../lib/enum';
import { convertVehicleToString, withEmptyRow } from '../../../lib/helpers';
import { ListTable, ActionIcon, Operations } from '../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { VehicleSelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px 0;
`;
const SectionContent = styled.div`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ListTableWrapper = styled.div`
  .list-table {
    border: none;
    margin: 0;
  }
  .list-table__row {
    align-items: center;
  }
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function,
};

type State = {
  trailerIds: number[],
};

class Trailers extends Component<Props, State> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      let trailerIds = this.props.vehicle.trailers.map(
        (trailer: Vehicle) => trailer.id
      );
      const addedTrailerIds = values.trailers.map(
        (trailer: Vehicle) => trailer.id
      );
      trailerIds = uniq(trailerIds.concat(addedTrailerIds));
      const drivingVehicleId = values.id;
      await Promise.all(
        trailerIds.map(async (trailerId: number) =>
          addedTrailerIds.includes(trailerId)
            ? await vehicleApi.setDrivingVehicle(trailerId, drivingVehicleId)
            : await vehicleApi.setDrivingVehicle(trailerId, null)
        )
      );
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate(`/vehicles/${values.id}/trailers`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/trailers`);
  };

  formatDate = (value) => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {} } = this.props;
    return (
      <>
        <Header
          left={
            vehicle && <h1>Прицепы для {convertVehicleToString(vehicle)}</h1>
          }
        />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, dirty } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <SectionContent>
                    <Grid gutter="16px">
                      <GridItem fullWidth>
                        <FormField fast name="trailers">
                          {({ value = [], name }) => (
                            <ListTableWrapper>
                              <ListTable
                                showHeader={false}
                                columns={[
                                  {
                                    title: 'Прицеп',
                                    key: 'id',
                                    render: (
                                      id: number,
                                      record: Vehicle,
                                      index: number
                                    ) => (
                                      <VehicleSelect
                                        value={id}
                                        onChange={(
                                          id: number,
                                          selected: any
                                        ) => {
                                          let trailers = [...value];
                                          if (selected) {
                                            if (trailers[index]) {
                                              trailers.splice(
                                                index,
                                                1,
                                                trailers[index]
                                              );
                                            } else {
                                              trailers[index] =
                                                selected.props.vehicle;
                                            }
                                          }
                                          setFieldValue(name, trailers);
                                        }}
                                        placeholder="Выберите прицепы"
                                        allowClear={false}
                                        filter={{
                                          'vehicleModel.type': trailerVehicleTypes,
                                          status: vehicleStatusEnum.working,
                                          drivingVehicleId: null,
                                        }}
                                        disabledValues={value.map(
                                          (item: Vehicle) => item.id
                                        )}
                                      />
                                    ),
                                  },
                                  {
                                    width: '30px',
                                    renderRecord: (
                                      record: Vehicle,
                                      index: number
                                    ) =>
                                      isEmpty(record) ? null : (
                                        <Operations>
                                          <ActionIcon
                                            onClick={() => {
                                              setFieldValue(
                                                name,
                                                value.filter(
                                                  (item: Vehicle) =>
                                                    item.id !== record.id
                                                )
                                              );
                                            }}
                                            type="x"
                                            size={12}
                                          />
                                        </Operations>
                                      ),
                                  },
                                ]}
                                data={withEmptyRow(value)}
                              />
                            </ListTableWrapper>
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10),
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle,
  }
)(Trailers);
