// @flow
import Button from 'antd/lib/button';
import Radio from 'antd/lib/radio';
import moment from 'moment';
import React, { useState } from 'react';
import { MonthPicker } from '../../../../components/ant/DatePicker';
import { Popconfirm } from '../../../../components/ui';
import { Grid, GridItem } from './../../../../components/layout';

import { pddTestResultApi } from './../../../../lib/api';
import { formatDateTimeToISOString } from './../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';

const resultTypeEnum = {
  resultTest: 'resultTest',
  resultExam: 'resultExam',
};
type Props = {
  text?: string,
};
export default ({ text = 'Печать' }: Props) => {
  const [date, setDate] = useState(moment.utc().startOf('day'));
  const [type, setType] = useState(resultTypeEnum.resultExam);
  const onConfirm = (date: string, type: string) => {
    printNotification(async () => {
      if (type === resultTypeEnum.resultTest) {
        await pddTestResultApi.printTraining(date);
      } else {
        await pddTestResultApi.printExam(date);
      }
    });
  };
  return (
    <Popconfirm
      title={
        <Grid>
          <GridItem fullWidth>
            <p>Выберите дату</p>
          </GridItem>
          <GridItem fullWidth>
            <MonthPicker
              format="MMM YYYY"
              value={date}
              onChange={(date) => setDate(date)}
            />
          </GridItem>
          <GridItem fullWidth>
            <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
              <Radio value={resultTypeEnum.resultExam}>
                Результаты сдачи экзаменов
              </Radio>
              <Radio value={resultTypeEnum.resultTest}>
                Результаты тестирований водителей
              </Radio>
            </Radio.Group>
          </GridItem>
        </Grid>
      }
      onConfirm={() =>
        onConfirm(
          formatDateTimeToISOString(
            date,
            moment(date).format('DD.MM.YYYY HH:mm')
          ),
          type
        )
      }
    >
      <Button type="primary">{text}</Button>
    </Popconfirm>
  );
};
