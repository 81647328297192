// @flow
import React, { Component } from 'react';
import { navigate } from '@reach/router';

import { Tabs, TabItem } from './../../../components/ui';

type Props = {
  selectedTab: 'general' | 'branch' | 'vehicle' | 'kpr'
};

const COMMON_ROUTE = 'osago-multiplier';

export default class HeaderTabs extends Component<Props> {
  render() {
    return (
      <Tabs defaultTab={this.props.selectedTab}>
        <TabItem
          tabKey="general"
          label="Общие"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/general`)}
        />
        <TabItem
          tabKey="branch"
          label="Терр. коэф."
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/branch`)}
        />
        <TabItem
          tabKey="base"
          label="Базовая ставка"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/base-rate`)}
        />
        <TabItem
          tabKey="kpr"
          label="КПР"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/kpr`)}
        />
        <TabItem
          tabKey="power"
          label="Мощность"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/power`)}
        />
      </Tabs>
    );
  }
}
