//@flow
import React, { useState, useEffect } from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';

import { channelApi } from '../../lib/api';
import type { OrgUnitNode, Channel } from '../../lib/types';
import { goBack } from './../../lib/helpers';

import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { notificationLoading } from '../../components/Notifications';
import { Header, Panel } from '../../components/layout';
import { InnerForm } from './components/InnerForm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id?: number,
};
export const ChannelForm = ({ id }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [channel, setChannel] = useState<Channel | null>(null);

  const getChannel = async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    try {
      notificationLoading({
        message: 'Запрашиваем данные',
        key: 'getChannel',
      });
      const channel = await channelApi.get(id);
      setChannel(channel);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('getChannel');
      setLoading(false);
    }
  };

  const handleSubmit = async (value: Channel) => {
    setLoading(true);
    try {
      notificationLoading({
        message: 'Сохраняем данные',
        key: 'saving',
      });
      if (value.id) {
        await channelApi.update(value);
      } else {
        await channelApi.add(value);
      }
      goBack();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
      setLoading(false);
    }
  };

  const handleCancel = () => goBack('/admin/channel');

  useEffect(() => {
    getChannel();
  }, []);

  return (
    <>
      <Header
        left={
          <Breadcrumbs>
            <Crumb to="/">Главная</Crumb>
            <Crumb to="/admin/channel">Каналы БОС</Crumb>
            {channel ? (
              <Crumb>Канал №{channel.id ?? ''}</Crumb>
            ) : (
              <Crumb>Новая запись</Crumb>
            )}
          </Breadcrumbs>
        }
      />
      <StyledPanel>
        <h1>
          {channel ? `Канал №${channel.id ?? ''}` : 'Добавление новой записи'}
        </h1>
      </StyledPanel>
      <InnerForm
        channel={channel}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};
