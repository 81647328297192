// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { contractVehiclePlanApi } from './../../lib/api';
import type { ContractVehiclePlan } from '../../lib/types';
import { contractVehiclePlanInfoString } from '../../lib/helpers';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any };

export default ({ filter = {}, ...selectProps }: Props) => {
  return (
    <AutocompleteSelect
      fetch={async params => {
        return await contractVehiclePlanApi.fetch({
          ...params,
          ...filter
        });
      }}
      refetchParams={{ filter }}
      fetchSingle={contractVehiclePlanApi.get}
      notFoundText="Бюджеты не найдены"
      placeholder="Выберите бюджет"
      renderOption={(vehiclePlan: ContractVehiclePlan, Option) => (
        <Option
          key={vehiclePlan.id}
          value={vehiclePlan.id}
          vehiclePlan={vehiclePlan}
        >
          {contractVehiclePlanInfoString(vehiclePlan)}
        </Option>
      )}
      {...selectProps}
    />
  );
};
