import {
    PassTariffCard,
    PassTariffForm,
    PassTariffList,
} from '../../containers/PassTariff';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: PassTariffList,
        path: '/admin/pass-tariff',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: PassTariffCard,
        path: '/admin/pass-tariff/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: PassTariffForm,
        path: '/admin/pass-tariff/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: PassTariffForm,
        path: '/admin/pass-tariff/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];