// @flow
import styled from 'styled-components';

const size = 14;

const Badge = styled.div`
  border-radius: ${size}px;
  min-width: ${size}px;
  min-height: ${size}px;
  font-size: 9px;
  color: white;
  font-weight: bold;
  background: #e9180a;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.07em;
`;

/**
 * Ярлычок для индикации (например, количество уведомлений)
 */
export default Badge;
