// @flow
import _range from 'lodash/range';
import moment from 'moment';

export type CalendarDay = {
  date: Date
};

export const getDaysByWindowDate = (
  windowDate: Date,
  cols: number = 7,
  rows: number = 6
): CalendarDay[] => {
  const daysCount = cols * rows;
  const year = windowDate.getFullYear();
  const month = windowDate.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const monthDays = _range(1, daysInMonth + 1).map((day: number) => {
    return {
      date: new Date(year, month, day)
    };
  });
  const dayWeek =
    new Date(monthDays[0].date).getDay() !== 0
      ? new Date(monthDays[0].date).getDay()
      : 7;
  const nextDays = daysCount - daysInMonth - (dayWeek - 1);
  const beforeDays = _range(1, dayWeek)
    .map((day: number) => {
      return {
        date: new Date(year, month, -(day - 1))
      };
    })
    .reverse();
  const afterDays = _range(1, nextDays + 1).map((day: number) => {
    return {
      date: new Date(year, month + 1, day)
    };
  });

  return [...beforeDays, ...monthDays, ...afterDays];
};

export const isToday = (date: Date) => {
  return moment(date).isSame(new Date(), 'day');
};

export const isBefore = (date1: Date, date2: Date) => {
  return moment(date1)
    .startOf('day')
    .isBefore(moment(date2).startOf('day'));
};

export const isCurrentMonth = (date1: Date, date2: Date) => {
  return new Date(date1).getMonth() === new Date(date2).getMonth();
};
