// @flow
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { AppState } from '../../ducks/redux';
import {
  addJobTitle,
  cleanJobTitle,
  fetchJobTitle,
  updateJobTitle
} from '../../ducks/jobTitle';
import type { JobTitle } from '../../lib/types/jobTitle';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { Header, Panel } from '../../components/layout';
import InnerForm from './components/InnerForm';
import { goBack } from './../../lib/helpers';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  jobTitleId: number,
  jobTitle: JobTitle,
  fetchJobTitle: Function,
  cleanJobTitle: Function,
  addJobTitle: Function,
  updateJobTitle: Function
};

export class JobTitleForm extends React.Component<Props> {
  async componentDidMount() {
    const { jobTitleId } = this.props;
    await this.props.cleanJobTitle();
    try {
      if (jobTitleId) {
        await this.props.fetchJobTitle(jobTitleId);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: JobTitle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await this.props.updateJobTitle(values);
      } else {
        await this.props.addJobTitle(values);
      }
      navigate(`/admin/job-titles`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/admin/job-titles');

  render() {
    const { jobTitle } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/job-titles">Должности для регламентов</Crumb>
              {jobTitle ? (
                <Crumb>Должность №{jobTitle.id}</Crumb>
              ) : (
                <Crumb>Новая должность</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{jobTitle ? `Должность №${jobTitle.id}` : 'Новая должность'}</h1>
        </StyledPanel>
        <InnerForm
          jobTitle={jobTitle}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    jobTitleId: parseInt(props.jobTitleId, 10),
    jobTitle: state.jobTitle
  }),
  {
    addJobTitle,
    updateJobTitle,
    fetchJobTitle,
    cleanJobTitle
  }
)(JobTitleForm);
