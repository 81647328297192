// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { accessTypes } from '../../lib/enum';
import { Option, Select } from './../ant/Select';

export default ({ value, onChange, ...selectProps }: SelectProps) => (
  <Select
    placeholder="Выберите доступы"
    showSearch
    optionFilterProp="label"
    mode="multiple"
    labelInValue
    {...selectProps}
    value={(value || []).map(key => ({
      key,
      label: accessTypes[key]
    }))}
    onChange={value => {
      onChange(value.map(value => value.key));
    }}
    allowClear
  >
    {Object.keys(accessTypes).map(key => (
      <Option key={key} value={key} label={accessTypes[key]}>
        {accessTypes[key]}
      </Option>
    ))}
  </Select>
);
