import {
    SelfRegulationsEditForm,
    SelfRegulationsForm,
    SelfRegulationsList,
} from '../containers/SelfRegulations';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: SelfRegulationsList,
        path: '/self-regulations',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingTimeLimits,
            accessTypeEnum.handlingTimeLimits,
            accessTypeEnum.viewingTimeLimits,
            accessTypeEnum.viewingBranchTimeLimits,
        ],
    },
    {
        component: SelfRegulationsForm,
        path: '/self-regulations/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingTimeLimits,
            accessTypeEnum.handlingTimeLimits,
            accessTypeEnum.viewingTimeLimits,
            accessTypeEnum.viewingBranchTimeLimits,
        ],
    },
    {
        component: SelfRegulationsEditForm,
        path: '/self-regulations/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingTimeLimits,
            accessTypeEnum.handlingTimeLimits,
            accessTypeEnum.viewingTimeLimits,
            accessTypeEnum.viewingBranchTimeLimits,
        ],
    },
];