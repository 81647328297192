// @flow
import { downloadRequestWithToken } from './index';
import type { VehicleTaxPlan } from './../types';
import CommonApi from '../commonApi';

const basedUrl = 'vehicleTaxPlan';
const vehicleTaxPlan = new CommonApi<VehicleTaxPlan>(basedUrl, 'налог СТС');

const print = async (vehicleTaxPlanId: number) => {
  const print = await downloadRequestWithToken(`/vehicleTaxPlan/print`, {
    vehicleTaxPlanId
  });
  if (print) return print;
  throw new Error('Не удалось распечатать');
};

export default {
  ...vehicleTaxPlan,
  print
};
