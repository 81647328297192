// @flow

import React, { Component } from 'react';
import { vehicleMonitoringCalculationApi } from '../../../../lib/api';
import { printNotification } from '../../../../lib/notificationWrapper';
import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

export default class extends Component<Props, {}> {
  render() {
    const { location, vehiclePlanId } = this.props;
    return (
      <Common
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={vehicleMonitoringCalculationApi}
        title={'Обслуживание GPS'}
        calcScrollY={400}
        statusField={'vehicleMonitoringCalculationStatus'}
        handlePrint={filter => {
          printNotification(async () => {
            await vehicleMonitoringCalculationApi.print(vehiclePlanId, filter);
          });
        }}
      />
    );
  }
}
