// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { currentMaintenanceCostApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { formatDateTimeToString } from '../../lib/helpers';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/current-maintenance-cost/';

const columns = [
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModelName'
  },
  {
    title: 'Трудоёмкость работ на 1000 км. пробега (н/ч)',
    dataIndex: 'workEffort'
  },
  {
    title: 'Норма затрат на материалы на 1000 км. пробега',
    dataIndex: 'materialExpenseRate'
  },
  {
    title:
      'Норма затрат на материалы на 1000 км. пробега с учётом коэффициента',
    dataIndex: 'materialExpenseRateWithCoefficient'
  },
  {
    title: 'Трудоемкость на 1000 м/ч (н/ч)',
    dataIndex: 'effort'
  },
  {
    title:
      'Доля затрат на материалы от первоначальной стоимости ТС (для самоходных машин и прицепов',
    dataIndex: 'materialsToTotalVehiclePriceRatio'
  },
  {
    title: 'Коэффициент увеличения цены на материалы',
    dataIndex: 'costMagnificationFactor'
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  return (
    <Page>
      <CommonListPage
        crud={currentMaintenanceCostApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Стоимость текущего ремонта',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: true
        }}
        filter={Filter}
      />
    </Page>
  );
});
