// @flow

import React, { type Node } from 'react';
import { SelectProps } from 'antd/lib/select';

import AutocompleteSelect from './AutocompleteSelect';
import type { ContractVehicle } from '../../lib/types';
import { contractVehicleApi } from '../../lib/api';
import { convertContractVehicleToString } from '../../lib/helpers';

type Props = SelectProps & {
  // вывод имени в Option
  optionTitle?: (vehicle: ContractVehicle) => Node
};

const defaultOptionName = (contractVehicle: ContractVehicle) =>
  convertContractVehicleToString(contractVehicle);

export default ({
  optionTitle = defaultOptionName,
  filters,
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    placeholder="Выберите ТС"
    fetch={async params =>
      contractVehicleApi.fetchContractVehicles({ ...filters, ...params })
    }
    fetchSingle={contractVehicleApi.fetchContractVehicle}
    notFoundText="ТС не найдены"
    renderOption={(contractVehicle: ContractVehicle, Option) => (
      <Option
        key={contractVehicle.id}
        value={contractVehicle.id}
        vehicle={contractVehicle}
      >
        {optionTitle(contractVehicle)}
      </Option>
    )}
    {...selectProps}
  />
);
