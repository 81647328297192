// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input';
import { RangePicker } from '../../../components/ui';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  Selects,
} from './../../../components';
import Field from './../../../components/card/Field';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import { formatDateTimeToISOString } from './../../../lib/helpers';

const { CompanySelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type BioTreatmentFacilitiesFilterParams = {
  // search?: string,
  companyId?: number,
  startDate?: string,
  endDate?: string,
};

type Props = {
  filter: BioTreatmentFacilitiesFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
};

export const ContractFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams<BioTreatmentFacilitiesFilterParams>) => (
          <>
            {/* <FilterItem>
              <Field mBottomNone>
                <Input
                  size="small"
                  placeholder="Номер ТС"
                  onChange={(e) => changeValue('search', e.target.value)}
                  value={values.search}
                />
              </Field>
            </FilterItem> */}
            <FilterItem>
              <Field mBottomNone>
                <CompanySelect
                  size="small"
                  onChange={(value) => changeValue('companyId', value)}
                  value={values.companyId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  startDate={
                    values.startDate ? moment.utc(values.startDate) : null
                  }
                  endDate={values.endDate ? moment.utc(values.endDate) : null}
                  onChange={changeValue}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ContractFilter;
