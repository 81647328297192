// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Card,
  Filter,
  Selects,
  OrgUnitSelect,
  FilterButtonsContainer
} from './../../../components';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import { serviceTypeEnum } from '../../../lib/enum';
import type { MonthlyWashingPlanStatus } from '../../../lib/types';
import FilterContainer from '../../../components/hoc/FilterContainer';
import FilterItem from '../../../components/hoc/FilterItem';
import { InputNumber } from '../../../components/inputs';

const { Field } = Card;
const { MonthlyWashingPlanStatusSelect, ContractorSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type MonthlyWashingPlanFilterParams = {
  year?: number,
  contractorId?: number,
  status?: MonthlyWashingPlanStatus,
  nodeId?: number
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: MonthlyWashingPlanFilterParams
};

const MonthlyWashingPlanFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          showAdditional,
          handleAdditional,
          applyFilter
        }: FilterChildrenParams<MonthlyWashingPlanFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  value={values.nodeId}
                  onChange={id => changeValue('nodeId', id)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <MonthlyWashingPlanStatusSelect
                  size="small"
                  value={values.status}
                  onChange={id => changeValue('status', id)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  value={values.contractorId}
                  onChange={id => changeValue('contractorId', id)}
                  filter={{
                    services: [serviceTypeEnum.vehicleWash]
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <InputNumber
                  size="small"
                  placeholder="Год"
                  value={values.year}
                  min={2000}
                  onChange={year => changeValue('year', year)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MonthlyWashingPlanFilter;
