import { RoleCard, RoleForm, RoleList } from '../../containers/Roles';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: RoleList,
        path: '/admin/roles',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingUsersAndRoles,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
    {
        component: RoleForm,
        path: '/admin/roles/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
    {
        component: RoleCard,
        path: '/admin/roles/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingUsersAndRoles,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
    {
        component: RoleForm,
        path: '/admin/roles/:roleId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
];