import React, { Component } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import { goBack, getValueObject } from '../../../lib/helpers';
import { osagoMultiplier } from '../../../lib/api';
import type { OsagoBaseRateMultiplier } from './../../../lib/types';

import { Header, Panel } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { notificationLoading } from '../../../components/Notifications';

import InnerForm from './InnerForm';

type Props = {
  osagoBaseRateMultiplierId: ?string
};
type State = {
  osagoBaseRateMultiplier: ?OsagoBaseRateMultiplier
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

export default class BaseRateMultiplierForm extends Component<Props, State> {
  state = {
    osagoBaseRateMultiplier: null
  };

  async componentDidMount() {
    let osagoBaseRateMultiplierId = parseInt(
      this.props.osagoBaseRateMultiplierId,
      10
    );
    if (osagoBaseRateMultiplierId) {
      try {
        const osagoBaseRateMultiplier = await osagoMultiplier.getOsagoBaseRateMultiplier(
          osagoBaseRateMultiplierId
        );
        this.setState({ osagoBaseRateMultiplier });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    } else {
      let osagoBaseRateMultiplier = {};
      osagoBaseRateMultiplier.startDate = getValueObject(
        this.props,
        'location.state.startDate'
      );
      this.setState({ osagoBaseRateMultiplier });
    }
  }

  onSubmit = async (values: OsagoBaseRateMultiplier) => {
    const { osagoBaseRateMultiplierId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (osagoBaseRateMultiplierId) {
        await osagoMultiplier.updateOsagoBaseRateMultiplier(values);
      } else {
        await osagoMultiplier.addOsagoBaseRateMultiplier(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/osago-multiplier/base-rate');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => goBack('/admin/osago-multiplier/base-rate');

  render() {
    const { osagoBaseRateMultiplier } = this.state;
    const { osagoBaseRateMultiplierId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/osago-multiplier/base-rate">
                Базовые коэффициенты ОСАГО
              </Crumb>
              {osagoBaseRateMultiplierId ? (
                <Crumb
                  to={`/admin/osago-multiplier/base-rate/edit/${osagoBaseRateMultiplierId}`}
                >
                  Базовый коэффициент ОСАГО №{osagoBaseRateMultiplierId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/osago-multiplier/base-rate/new`}>
                  Новый базовый коэффициент ОСАГО
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoBaseRateMultiplierId
              ? `Редактируем запись №${osagoBaseRateMultiplierId}`
              : `Новая запись`}
          </h1>
        </StyledPanel>
        <InnerForm
          osagoBaseRateMultiplier={osagoBaseRateMultiplier}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}
