// @flow
import styled, { css } from 'styled-components';

export const FilterItem = styled.div.attrs({
  className: 'filter__item'
})`
  ${() => {
    return css`
      max-width: 200px;
      margin-right: 10px;
      width: calc(100% / 4 - 40px);
    `;
  }}
`;

export default FilterItem;
