// @flow

import type { Dispatch } from 'redux';

import { wialonTripApi, tripApi } from '../lib/api';
import type { Action, AppState } from './redux';
import type { WialonTrip } from '../lib/types';

export const FETCH_TRIP = 'vehicles/wialonTrip/fetch';
export const CLEAN_TRIP = 'vehicles/wialonTrip/clean';

export type TripState = ?WialonTrip;

const initialState: TripState = null;

const reducer = (
  state: TripState = initialState,
  { type, payload }: Action
): TripState => {
  switch (type) {
    case FETCH_TRIP:
      return { ...payload };
    case CLEAN_TRIP:
      return null;
    default:
      return state;
  }
};

export const updateWialon =
  (id: number, params: any): Function =>
  async (dispatch: Dispatch) => {
    try {
      const trip = await wialonTripApi.updateWialonData(id);
      // const trip = await wialonTripApi.getWialonData(params);
    } catch (error) {
      throw new Error(error.message);
    }
    return await dispatch(fetchTrip(id));
  };

export const updateTrip: Function =
  (trip: WialonTrip): Function =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<WialonTrip> => {
    const updated: WialonTrip = await wialonTripApi.update(trip);

    // Обновляем стейт, если только в нем что-то есть,
    // т.к. обновление ПЛ (путевого листа) может происходит
    // не только в форме и карточке ПЛ
    if (getState().trip !== null) {
      dispatch({
        type: FETCH_TRIP,
        payload: {
          ...updated,
        },
      });
    }
    return updated;
  };

export const fetchTrip: Function =
  (id: number): Function =>
  async (dispatch: Dispatch): Promise<WialonTrip> => {
    const trip: WialonTrip = await wialonTripApi.get(id);

    dispatch({
      type: FETCH_TRIP,
      payload: trip,
    });
    return trip;
  };

export const cleanTrip =
  (): Function =>
  (dispatch: Dispatch): void =>
    dispatch({
      type: CLEAN_TRIP,
    });

export default reducer;
