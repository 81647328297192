// @flow
import Button from 'antd/lib/button';
import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects
} from '../../../../../components';
import Field from '../../../../../components/card/Field';
import type { FilterChildrenParams } from '../../../../../components/hoc/Filter';
import type {
  CognosContract,
  ExpenseDirectionCognosStatus
} from '../../../../../lib/types';

const {
  BudgetLineItemSelect,
  ExpenseDirectionSelect,
  ExpenseDirectionCognosStatusSelect,
  CognosContractsSelect
} = Selects;

const StyleContainer = styled.div`
  min-width: 275px;
  flex: 1 auto;
  text-align: right;

  & .ant-btn {
    margin: 0px;
  }
`;

export type CognosContractRegisterFilterParams = {
  budgetLineItemId?: number,
  expenseDirectionId?: number,
  status?: ExpenseDirectionCognosStatus,
  cognosContractNumber?: string
};

type Props = {
  filter: CognosContractRegisterFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  cognosContracts?: CognosContract[]
};

export default ({
  filter,
  cleanFilter,
  applyFilter,
  cognosContracts
}: Props) => (
  <FilterContainer style={{ margin: '16px' }}>
    <Filter initialValues={filter}>
      {({
        values,
        changeValue
      }: FilterChildrenParams<CognosContractRegisterFilterParams>) => (
        <>
          <FilterItem maxWidth="300px">
            <Field mBottomNone>
              <BudgetLineItemSelect
                size="small"
                value={values.budgetLineItemId}
                onChange={value => changeValue('budgetLineItemId', value)}
              />
            </Field>
          </FilterItem>

          <FilterItem maxWidth="300px">
            <Field mBottomNone>
              <ExpenseDirectionSelect
                size="small"
                value={values.expenseDirectionId}
                onChange={value => changeValue('expenseDirectionId', value)}
              />
            </Field>
          </FilterItem>

          <FilterItem maxWidth="300px">
            <Field mBottomNone>
              <ExpenseDirectionCognosStatusSelect
                size="small"
                value={values.status}
                onChange={value => changeValue('status', value)}
              />
            </Field>
          </FilterItem>

          <FilterItem maxWidth="300px">
            <Field mBottomNone>
              <CognosContractsSelect
                size="small"
                value={values.cognosContractNumber}
                onChange={value => changeValue('cognosContractNumber', value)}
                cognosContracts={cognosContracts}
              />
            </Field>
          </FilterItem>

          <StyleContainer style={{ minWidth: '150px' }}>
            <Button
              type="primary"
              size="small"
              onClick={() => applyFilter(values)}
            >
              Применить
            </Button>
            <Button type="plain" size="small" onClick={() => cleanFilter()}>
              Очистить
            </Button>
          </StyleContainer>
        </>
      )}
    </Filter>
  </FilterContainer>
);
