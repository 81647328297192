// @flow
import React from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';
import * as yup from 'yup';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle,
} from '../../../ducks/vehicle';
import type { Vehicle } from '../../../lib/types';
import { vehiclePtsTypeEnum } from '../../../lib/enum';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { Selects, Form } from '../../../components';
import { formatDateTimeToISOString as changeDate } from './../../../lib/helpers';
import { getDisabledDate } from '../lib';
import { notificationLoading } from '../../../components/Notifications';

import {
  VINInput,
  LicensePlateInput,
  PTSInput,
  STSInput,
  EPTSInput,
} from '../../../components/inputs/masked-inputs';
import {
  licensePlateValidate,
  ptsValidate,
  stsValidate,
  eptsValidate,
  vinValidate,
} from '../../../lib/validation';
import CancelButton from '../../../components/CancelButton';
import YesNoSelect from '../../../components/selects/YesNoSelect';

const { VehiclePtsTypeSelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function,
};
type State = {
  vehiclePts: string,
};

class Numbers extends React.Component<Props, State> {
  state = {
    vehiclePts: vehiclePtsTypeEnum.paper,
  };
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.vehicle?.epts !== prevProps?.vehicle?.epts) {
      this.setState({
        vehiclePts: this.props?.vehicle?.epts
          ? vehiclePtsTypeEnum.electronic
          : vehiclePtsTypeEnum.paper,
      });
    }
  }

  handleSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate(`/vehicles/${values.id}/documentation/numbers`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/documentation/numbers`);
  };

  formatDate = (value) => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehiclePts } = this.state;
    const { vehicle = {} } = this.props;
    return (
      <>
        <Header left={vehicle && <h1>Номера {vehicle.licensePlate}</h1>} />
        <Form
          initialValues={vehicle}
          onSubmit={this.handleSubmit}
          validationSchema={yup.object().shape({
            licensePlate: licensePlateValidate.nullable(),
            vin: vinValidate.nullable(),
            sts: stsValidate.nullable(),
            pts: ptsValidate.nullable(),
            epts: eptsValidate.nullable(),
          })}
        >
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              values,
              dirty,
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField
                        label="VIN"
                        name="vin"
                        required={!values.serialNumber && !values.unregistered}
                      >
                        {({ value, name }) => (
                          <VINInput
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        required={!values.vin && !values.unregistered}
                        label="Серийный номер"
                        name="serialNumber"
                      >
                        {(field) => (
                          <Input
                            {...field}
                            placeholder="Введите серийный номер"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="licensePlate"
                        label="Гос. номер"
                        required={!values.unregistered}
                      >
                        {(field) => <LicensePlateInput {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Инвентарный номер"
                        fast
                        name="inventoryNumber"
                      >
                        {({ value, name }) => (
                          <Input
                            placeholder="Введите инвентарный номер"
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Серия и номер СТС"
                        name="sts"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <STSInput
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Дата выдачи СТС"
                        name="stsDate"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={this.formatDate(value)}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            /**
                             * Валидация, минимальное значение 01.01.1980,
                             * но не позже текущего дня
                             */
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField fast label="Тип ПТС">
                        {() => (
                          <VehiclePtsTypeSelect
                            value={vehiclePts}
                            onChange={(vehiclePts) => {
                              this.setState({ vehiclePts });
                              if (vehiclePts === vehiclePtsTypeEnum.paper) {
                                setFieldValue('epts', null);
                              } else {
                                setFieldValue('pts', null);
                              }
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem />
                    {vehiclePts === vehiclePtsTypeEnum.paper ? (
                      <GridItem>
                        <FormField
                          fast
                          label="Серия и номер ПТС"
                          name="pts"
                          required={!values.unregistered}
                        >
                          {({ value, name }) => (
                            <PTSInput
                              value={value}
                              name={name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    ) : (
                      <GridItem>
                        <FormField
                          fast
                          label="ЭПТС"
                          name="epts"
                          required={!values.unregistered}
                        >
                          {({ value, name }) => (
                            <EPTSInput
                              value={value}
                              name={name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <FormField
                        label="Дата выдачи ПТС"
                        name="ptsDate"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={this.formatDate(value)}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="garageNumber"
                        fast
                        label="Гаражный номер"
                      >
                        {(field) => (
                          <Input
                            {...field}
                            placeholder="Введите гаражный номер"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Дата снятия с учета в ГИБДД"
                        name="deregistrationDate"
                        fast
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={this.formatDate(value)}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate, values.yearIssued)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Вид документа (Иной документ)"
                        name="otherDocumentKind"
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Серия номер (Иной документ)"
                        name="otherDocumentSerialNumber"
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Дата выдачи (Иной документ)"
                        name="otherDocumentIssuedDate"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate, values.yearIssued)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem />
                    <GridItem>
                      <FormField
                        label="Идентификатор ТС в мониторинге"
                        name="wialonVehicleId"
                        fast
                      >
                        {(field) => <Input {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Получать данные из мониторинга"
                        name="receiveWialonData"
                        fast
                      >
                        {({ value, name }) => (
                          <YesNoSelect
                            value={value}
                            onChange={(value) => {
                              setFieldValue(name, value);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10),
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle,
  }
)(Numbers);
