// @flow
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { EmployeeDocument } from '../types/employeeDocument';
import isArray from 'lodash/isArray';

const convertDataToServer = data => {
  return {
    ...data,
    certificationAreas: isArray(data.certificationAreas)
      ? data.certificationAreas
      : data.certificationAreas
      ? [data.certificationAreas]
      : null
  };
};

export const fetchDocument = (id: number) => {
  const document = fetchRequest.get(`/document/${id}`);
  if (document) return document;
  throw new Error('Не удалось загрузить документ');
};

export const fetchDocuments = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<EmployeeDocument>> =>
  await fetchRequest.get('/document', {
    ...initialFetchListParams,
    ...params
  });

export const deleteDocument = async (id: number) => {
  const deleted = await fetchRequest.delete(`/document/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить документ');
};

export const updateDocument = async (employeeDocument: EmployeeDocument) => {
  const updated = await fetchRequest.put(
    '/document',
    convertDataToServer(employeeDocument)
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить документ');
};

export const addDocument = async (employeeDocument: EmployeeDocument) => {
  const added = await fetchRequest.post(
    '/document',
    convertDataToServer(employeeDocument)
  );
  if (added) return added;
  throw new Error('Не удалось обновить документ');
};

export default {
  fetchDocument,
  fetchDocuments,
  deleteDocument,
  updateDocument,
  addDocument
};
