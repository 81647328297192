// @flow
import isNil from 'lodash/isNil';
import moment from 'moment';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';

import { convertFromSecondToHour } from '../../../lib/helpers';
import { quartersEnum } from '../../../lib/enum';
import type { ContractVehicleAnalysis } from '../../../lib/types';
import type { ContractVehicleAnalysisParams } from '../../../lib/types/contractVehicle';
import type { SpreadsheetTableCell } from '../../../components/ui/SpreadsheetTable';
import WarningCell from './components/WarningCell';
import React from 'react';

export const paramsKeys = [
  'hours',
  'engineOffDowntime',
  'downTime',
  'hoursOnRoad',
  'distance'
];

type AnalysisValueType = 'plan' | '1c' | 'autograph';

// Конвертеры полей
export const convertParamsKeys = {
  hours: (value: number) =>
    !isNil(value) && value > 0
      ? `${convertFromSecondToHour(value).toFixed(2)} ч.`
      : '-',
  engineOffDowntime: (value: number) =>
    !isNil(value) && value > 0
      ? `${convertFromSecondToHour(value).toFixed(2)} ч.`
      : '-',
  downTime: (value: number) =>
    !isNil(value) && value > 0
      ? `${convertFromSecondToHour(value).toFixed(2)} ч.`
      : '-',
  hoursOnRoad: (value: number) =>
    !isNil(value) && value > 0
      ? `${convertFromSecondToHour(value).toFixed(2)} ч.`
      : '-',
  distance: (value: number) =>
    !isNil(value) && value > 0 ? `${value.toFixed(2)} км.` : '-'
};

export const getQuarterByDate = (date: string) => {
  const month = moment(date).format('MMMM');
  const quarters = Object.keys(quartersEnum).filter(quarter =>
    quartersEnum[quarter].includes(month)
  );
  return quarters[0];
};

export const getQuarterByMonths = (months: string[]) => {
  const quarters = Object.keys(quartersEnum).filter(quarter => {
    const values = quartersEnum[quarter];
    return values.some(month => months.includes(month));
  });
  return quarters[0];
};

/**
 * Кусок агрегации по кварталам
 */
export type QuarterAggregation = {
  // Квартал
  [quarter: $Keys<typeof quartersEnum>]: {
    // Тип данных (По плану, по автографу, по 1С)
    [type: AnalysisValueType]: ContractVehicleAnalysisParams
  }
};

/**
 * Агрегирует данные по кварталам
 * @param analysis Данные из таблицы
 * @param quartersKeys Кварталы
 */
export const aggregateByQuarters = (
  analysis: ContractVehicleAnalysis[],
  quartersKeys: any
): QuarterAggregation[] => {
  // Формируем объект quarters
  let quarters: any = new Array(analysis.length).fill(0).map(v =>
    quartersKeys.reduce((obj, quarter) => {
      return {
        ...obj,
        [quarter]: {
          ...['plan', 'autograph', '1c'].reduce(
            (obj, key) => ({
              ...obj,
              [(key: string)]: {
                hours: 0,
                engineOffDowntime: 0,
                downTime: 0,
                hoursOnRoad: 0,
                distance: 0
              }
            }),
            {}
          )
        }
      };
    }, {})
  );
  analysis.forEach((value, index) => {
    const { dateTimes } = value;
    if (dateTimes) {
      // Идем по дням
      forEach(dateTimes, (dateTimeValue, date) => {
        // Определяем к какому кварталу относится месяц
        const quarter = getQuarterByDate(date);
        if (quartersKeys.includes(quarter)) {
          // Пробегаемя по полям План, Автограф и 1С
          forEach(dateTimeValue, (values, key) => {
            if (values) {
              // Пробегаемся по полям значений hours, distance и т.д.
              paramsKeys.forEach(paramKey => {
                if (values[paramKey]) {
                  quarters[index][quarter][key][paramKey] += values[paramKey];
                }
              });
            }
          });
        }
      });
    }
  });
  return quarters;
};

/**
 * Агрегация по месяцам
 */
type MonthAggregation = {
  // Тип значения (план, автограф, 1с)
  [type: AnalysisValueType]: ContractVehicleAnalysisParams
};

/**
 * Агрегирует данные по месяцам периода
 * @param analysis Данные из таблицы
 * @param startDate Начало периода
 * @param endDate Конец периода
 */
export const aggregateByMonth = (
  analysis: ContractVehicleAnalysis[],
  startDate: string,
  endDate: string
): MonthAggregation[] => {
  return analysis.map<any>(value => {
    const { dateTimes } = value;
    let result = ['plan', 'autograph', '1c'].reduce((obj, key) => {
      return {
        ...obj,
        [(key: string)]: {
          ...paramsKeys.reduce((obj, key: string) => ({ ...obj, [key]: 0 }), {})
        }
      };
    }, {});
    Object.keys(dateTimes)
      .filter(date => {
        return moment(date).isBetween(startDate, endDate);
      })
      .forEach(date => {
        const dateTimeValue = dateTimes[date];
        forEach(dateTimeValue, (values, key) => {
          forEach(values, (value, paramKey) => {
            result[key][paramKey] += value;
          });
        });
      });

    return result;
  });
};

/**
 * Суммирование по агрегациям кварталов
 * @param aggregation Агрегации
 * @param type Тип (plan, 1c, autograph)
 * @returns {Array<SpreadsheetTableCell>}
 */
export const sumQuarterAggregation = (
  aggregation: QuarterAggregation[],
  type: AnalysisValueType
) => {
  const aggregateBy = (type: AnalysisValueType, rowIndex: number, key: any) => {
    return reduce(
      aggregation[rowIndex],
      (sum, value) => {
        return value[type] && value[type][key] ? sum + value[type][key] : sum;
      },
      0
    );
  };
  if (type === '1c') {
    return paramsKeys.map<SpreadsheetTableCell>(key => ({
      cellStyle: {
        padding: '0'
      },
      formatValue: (cellValue, rowData, rowIndex) => {
        const planValue = aggregateBy('plan', rowIndex, key),
          oneCValue = aggregateBy('1c', rowIndex, key),
          autographValue = aggregateBy('autograph', rowIndex, key);
        if (oneCValue) {
          return (
            <WarningCell
              planValue={planValue}
              oneCValue={oneCValue}
              autographValue={autographValue}
            >
              {convertParamsKeys[key](oneCValue)}
            </WarningCell>
          );
        } else return '-';
      }
    }));
  } else
    return paramsKeys.map<SpreadsheetTableCell>(key => ({
      formatValue: (cellValue, rowData, rowIndex) =>
        convertParamsKeys[key](aggregateBy(type, rowIndex, key))
    }));
};
