import {
    TripInspectionCostCard,
    TripInspectionCostForm,
    TripInspectionCostList,
} from '../../containers/TripInspectionCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: TripInspectionCostList,
        path: '/admin/trip-inspection-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: TripInspectionCostCard,
        path: '/admin/trip-inspection-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: TripInspectionCostForm,
        path: '/admin/trip-inspection-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: TripInspectionCostForm,
        path: '/admin/trip-inspection-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];