import React from 'react';
import Popconfirm from 'antd/lib/popconfirm';

export default ({ children, ...props }) => {
    return <Popconfirm
        cancelText="Отмена"
        okText="Ок"
        icon={null}
        {...props}
    >
        {children}
    </Popconfirm>
}