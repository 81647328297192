import styled from 'styled-components';
import Input from 'antd/lib/input';
import { InputNumber } from '../../../inputs';

export const SectionContent = styled.div`
  padding: 16px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
