// @flow
import Button from 'antd/lib/button';
import type { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../components/ant/DatePicker';
import type { FormFieldType } from '../../../components/Form';
import { InputNumber } from '../../../components/inputs';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import type { MonitoringPrice } from '../../../lib/types';

import { Form } from './../../../components';
import CancelButton from './../../../components/CancelButton';
import { Footer, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import OrgUnitSelect from './../../../components/OrgUnitSelect';

const SectionContent = styled.div`
  padding: 16px;
`;

type FormProps = {
  monitoringPrice: ?MonitoringPrice,
  onSubmit: Function,
  onCancel: () => void
};

export default ({ monitoringPrice, onSubmit, onCancel }: FormProps) => {
  return (
    <Form initialValues={monitoringPrice} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, dirty, setFieldValue } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField name="startDate" required label="Дата начала">
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          placeholder="Введите дату"
                          value={value ? moment.utc(value) : value}
                          onChange={(value, dateString) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name="endDate" label="Дата окончания">
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          placeholder="Введите дату"
                          value={value ? moment.utc(value) : value}
                          onChange={(value, dateString) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Филиал" name="orgUnitId" required>
                      {({ name, value }) => (
                        <OrgUnitSelect
                          value={value}
                          onlyBranches
                          onChange={(orgUnitId: number, option) =>
                            setFieldValue(name, orgUnitId)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Стоимость мониторинга"
                      name="amountPayment"
                      required
                      fast
                    >
                      {({ name, value }) => (
                        <InputNumber
                          style={{ width: '100%' }}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          placeholder="Введите стоимость мониторинга"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
