// @flow
import React from 'react';

import type {
  VehicleModel,
  ContractVehicle,
  VehicleType,
  ContractVehicleType
} from './../../../../../../../lib/types';
import {
  vehicleTypes,
  contractVehicleType,
  vehicleStatuses
} from './../../../../../../../lib/enum';
import { formatDateTimeToString } from './../../../../../../../lib/helpers';
import { Table } from './../../../../../../../components/ui';

type Props = {
  data: ContractVehicle[],
  onSelect?: Function,
  onSelectAll?: Function,
  selectedRowKeys?: number[],
  loading?: boolean
};

const COLUMNS = [
  {
    title: 'Тип ТС',
    width: 200,
    dataIndex: 'vehicle.vehicleModel.type',
    render: (type: VehicleType) => vehicleTypes[type]
  },
  {
    title: 'Maрка и модель',
    width: 150,
    dataIndex: 'vehicle.vehicleModel',
    render: (record: VehicleModel) => `${record.brandName} ${record.name}`
  },
  {
    title: 'Госномер',
    width: 150,
    dataIndex: 'vehicle.licensePlate'
  },
  {
    title: 'Год выпуска',
    width: 100,
    dataIndex: 'vehicle.yearIssued'
  },
  {
    title: 'Тип НТС',
    width: 150,
    dataIndex: 'type',
    render: (type: ContractVehicleType) => contractVehicleType[type]
  },
  {
    title: 'Дата начала',
    width: 150,
    dataIndex: 'startDateStatus',
    render: (startDateStatus: string) =>
      formatDateTimeToString(startDateStatus, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    width: 150,
    dataIndex: 'endDateStatus',
    render: (endDateStatus: string) =>
      formatDateTimeToString(endDateStatus, 'DD.MM.YYYY')
  },
  {
    title: 'Текущий статус',
    width: 100,
    dataIndex: 'vehicle.status',
    render: status => vehicleStatuses[status]
  }
];

export default (props: Props) => {
  const { data, loading, selectedRowKeys, onSelect, onSelectAll } = props;
  const rowSelection =
    onSelect && onSelectAll
      ? {
          selectedRowKeys,
          onSelect,
          onSelectAll
        }
      : undefined;
  return (
    <div style={{ overflow: 'auto' }}>
      <Table
        style={{ minWidth: '1490px' }}
        rowSelection={rowSelection}
        columns={COLUMNS}
        data={data}
        loading={loading}
        scroll={{ y: 240 }}
      />
    </div>
  );
};
