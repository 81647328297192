import React from 'react';
import styled, { css } from 'styled-components';

export const Input = styled(({ focused, ...props }) => (
  <input {...props} type="text" />
)).attrs(({ css }) => ({
  style: css
}))`
  border: none;
  background: none;
  outline: none;
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  text-align: center;
  padding: 5px;
  ${({ focused }) =>
    focused &&
    css`
      box-shadow: inset 0 0 0 2px #2770ff;
    `}
`;
