// @flow
import styled from 'styled-components';

const Panel = styled.div`
  background: white;
  padding: 16px;
`;

export const TopPanel = styled(Panel)`
  padding-top: 0;
`;

export default Panel;
