// @flow

import type { MaintenanceOperation } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addMaintenanceOperation = async (
  maintenanceOperation: MaintenanceOperation
): Promise<MaintenanceOperation> => {
  const added = await fetchRequest.post(
    '/maintenanceOperation',
    maintenanceOperation
  );
  if (added) return added;
  throw new Error('Не удалось создать вид работы');
};

export const updateMaintenanceOperation = async (
  maintenanceOperation: MaintenanceOperation
): Promise<MaintenanceOperation> => {
  const updated = await fetchRequest.put(
    '/maintenanceOperation',
    maintenanceOperation
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить вид работы');
};

export const fetchMaintenanceOperation = async (
  id: number,
  params: any = {}
): Promise<MaintenanceOperation> =>
  await fetchRequest.get(`/maintenanceOperation/${id}`, params);

export const deleteMaintenanceOperation = async (
  id: number
): Promise<MaintenanceOperation> => {
  const deleted = await fetchRequest.delete(`/maintenanceOperation/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить вид работы');
};

export const fetchMaintenanceOperations = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Location>> =>
  await fetchRequest.get('/maintenanceOperation', {
    ...initialFetchListParams,
    ...params
  });

export default {
  addMaintenanceOperation,
  fetchMaintenanceOperation,
  updateMaintenanceOperation,
  deleteMaintenanceOperation,
  fetchMaintenanceOperations
};
