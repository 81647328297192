// @flow

import type { FuelMultiplier } from './../types/fuelMultiplier';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addFuelMultiplier = async (
  multiplier: FuelMultiplier
): Promise<FuelMultiplier> => {
  const added = await fetchRequest.post('/fuelMultiplier', multiplier);
  if (added) return added;
  throw new Error('Не удалось создать топливынй коэффициент');
};

export const updateFuelMultiplier = async (
  fuelMultiplier: FuelMultiplier
): Promise<FuelMultiplier> => {
  const updated = await fetchRequest.put('/fuelMultiplier', fuelMultiplier);
  if (updated) return updated;
  throw new Error('Не удалось обновить топливный коэффициент');
};

export const fetchFuelMultipliers = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<FuelMultiplier>> =>
  await fetchRequest.get('/fuelMultiplier', {
    ...initialFetchListParams,
    ...params
  });

export const fetchFuelMultiplier = async (
  id: number
): Promise<FuelMultiplier> => {
  const multiplier = await fetchRequest.get(`/fuelMultiplier/${id}`);
  if (multiplier) return multiplier;
  throw new Error('Не удалось загрузить коэффициент');
};

export const deleteFuelMultiplier = async (
  id: number
): Promise<FuelMultiplier> => {
  const deleted = await fetchRequest.delete(`/fuelMultiplier/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить коэффициент');
};

export const addVehicleFuelMultiplier = async (
  vehicleId: number,
  fuelMultplierId: number
) => {
  const result = await fetchRequest.post(
    `/vehicle/${vehicleId}/fuelMultipliers/${fuelMultplierId}`
  );
  if (result) {
    return result;
  } else {
    throw new Error('Не удалось добавить коэффициент к ТС');
  }
};

export const fetchVehicleFuelMultipliers = async (
  vehicleId: number
): Promise<FuelMultiplier[]> => {
  const result = await fetchRequest.get(
    `/vehicle/${vehicleId}/fuelMultipliers`
  );
  if (result) {
    return result;
  } else {
    throw new Error('Не удалось получить топливные коэффициенты');
  }
};

export const deleteVehicleFuelMultiplier = async (
  vehicleId: number,
  multiplierId: number
): Promise<void> => {
  const result = await fetchRequest.delete(
    `/vehicle/${vehicleId}/fuelMultipliers/${multiplierId}`
  );
  if (!result) {
    throw new Error('Не удалось получить топливные коэффициенты');
  }
};

export default {
  addVehicleFuelMultiplier,
  addFuelMultiplier,
  updateFuelMultiplier,
  fetchFuelMultipliers,
  deleteFuelMultiplier,
  fetchFuelMultiplier,
  fetchVehicleFuelMultipliers,
  deleteVehicleFuelMultiplier
};
