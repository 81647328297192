// @flow
import normalizeUrl from 'normalize-url';
import { proxyApi } from './../../api';
import qs from 'query-string';
import _isEmpty from 'lodash/isEmpty';

export default class RequestsController {
  host: string;
  sid: ?string;
  accessToken: ?string;
  avlInterval: ?IntervalID;

  constructor(host: string, accessToken?: string) {
    this.host = host;
    this.accessToken = accessToken;
  }

  /**
   * Аутентификация внутри виалона и активация avl_evts счетчика
   * @returns {Promise<void>}
   */
  async login() {
    const { eid } = await this.fetch('token/login', {
      token: this.accessToken
    });
    this.sid = eid;
    this.avlInterval = setInterval(async () => {
      await this.fetch(null, null, '/avl_evts');
    }, 1000);
  }

  /**
   * Прекращение запроса событий
   */
  async logout() {
    clearInterval(this.avlInterval);
  }

  /**
   * Функция для отправки запросов к виалону
   * @param svc Идентификатор команды. Например, user/update_auth_params
   * @param params Параметры запроса
   * @param root Корень пути запроса
   */
  async fetch(svc: ?string, params?: any, root: string = 'wialon/ajax.html') {
    const formData = params ? new URLSearchParams() : undefined;
    if (this.sid && formData) {
      formData.append('sid', this.sid);
    }
    if (params && formData) {
      formData.append('params', JSON.stringify(params));
    }
    const url = this._path(svc, root, JSON.stringify(params));
    return proxyApi.proxy(url);
  }

  async request(svc: string, params?: any) {
    return await this.fetch(svc, params);
  }

  /**
   * Функция нормализации пути запроса
   * @param svc Идентификатор команды
   * @param root Корень пути
   * @param params Параметры query-string
   */
  _path = (svc: ?string, root: string, params?: any) => {
    const queryParams = { svc, sid: this.sid, params };
    const queryString = _isEmpty(queryParams)
      ? ''
      : `${qs.stringify(queryParams, { strict: false, skipNull: true })}`;
    return normalizeUrl(`${this.host}/${root}?${queryString}`);
  };

  /**
   * Создание API секции для удобства документирования api виалона в коде
   * @param sectionName Название секции в Виалон API. Например, unit
   */
  getApiSection = (sectionName: string) => {
    return {
      fetch: async (path: string, body?: any) =>
        await this.fetch(`${sectionName}/${path}`, body),
      path: this._path
    };
  };
}

export type RequestSection = {
  fetch: <T>(path: string, body?: any) => Promise<T>,
  path: (svc: ?string, root: string, params?: any) => string
};
