import React from 'react';

export default styles => {
  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        background: '#c1c1c170',
        height: '100vh',
        width: '100%',
        zIndex: 999,
        justifyContent: 'center',
        alignItems: 'center',
        ...styles
      }}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
