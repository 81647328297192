// @flow
import React, { useRef } from 'react';
import Menu from 'antd/lib/menu';
import CopyButton from './CopyButton';
import Dropdown from '../../../components/ui/Dropdown';
import { Popconfirm } from '../../../components/ui';

type Props = {
  children: any,
  onDelete: Function,
  washingPriceListId: number,
};

const ActionsDropdown = ({ children, onDelete, washingPriceListId }: Props) => {
  const { current: dropdown } = useRef();

  const openDropdown = () => dropdown && dropdown.onVisibleChange(true);
  const closeDropdown = () => dropdown && dropdown.onVisibleChange(false);

  return (
    <Dropdown
      ref={dropdown}
      overlayStyle={{ zIndex: 2000 }}
      overlay={
        <Menu onClick={openDropdown}>
          <Menu.Item>
            <CopyButton
              closeDropdown={closeDropdown}
              washingPriceListId={washingPriceListId}
            />
          </Menu.Item>
          <Menu.Item>
            <Popconfirm
              overlayStyle={{ zIndex: 2001 }}
              zIndex={2001}
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await onDelete(washingPriceListId)}
            >
              <p>Удалить</p>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      }
    >
      {children}
    </Dropdown>
  );
};

export default ActionsDropdown;
