// @flow
import React from 'react';
import styled from 'styled-components';

import type { EntityStatusType } from './../../../../lib/types';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer,
  OrgUnitSelect
} from './../../../../components';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import Field from './../../../../components/card/Field';

const { ContractorSelect, YearSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ExecutionBudgetFilterParams = {
  status?: EntityStatusType,
  branchId?: number,
  orgUnitId?: number,
  year?: number,
  contractorId?: number
};

type Props = {
  filter: ExecutionBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        // additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: // showAdditional,
        // handleAdditional
        FilterChildrenParams<ExecutionBudgetFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  placeholder="Подразделение"
                  onChange={value => changeValue('orgUnitId', value)}
                  value={values.orgUnitId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YearSelect
                  size="small"
                  onChange={value => changeValue('year', value)}
                  value={values.year}
                />
              </Field>
            </FilterItem>
            {/* <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem> */}

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value => changeValue('contractorId', value)}
                  value={values.contractorId}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              // handleAdditional={handleAdditional}
              // showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
