// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  CalculationStatus,
  CargoPassCalculation,
  CargoPassCalculationMonth,
  VehiclePlan
} from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { convertFromKmToM, convertFromMToKm } from '../helpers';
import type { CommonApiMethod } from './../commonApi';

type cargoPassCalculationApiMethod = $Shape<
  CommonApiMethod & {
    calculation: CommonApiMethod
  }
>;

export const convertDataToServer = (
  cargoPassCalculation: CargoPassCalculation
): CargoPassCalculation => ({
  ...cargoPassCalculation,
  blankSum: convertFromRubToKop(cargoPassCalculation.blankSum),
  plannedKilometrage: convertFromKmToM(cargoPassCalculation.plannedKilometrage),
  agreementSum: convertFromRubToKop(cargoPassCalculation.agreementSum),
  passSum: convertFromRubToKop(cargoPassCalculation.passSum),
  taxSum: convertFromRubToKop(cargoPassCalculation.taxSum),
  sum: convertFromRubToKop(cargoPassCalculation.sum),
  excessRate: convertFromRubToKop(cargoPassCalculation.excessRate),
  taxRate: convertFromRubToKop(cargoPassCalculation.taxRate),
  agreementRate: convertFromRubToKop(cargoPassCalculation.agreementRate),
  months: cargoPassCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  cargoPassCalculation: CargoPassCalculation
): CargoPassCalculation => ({
  ...cargoPassCalculation,
  plannedKilometrage: convertFromMToKm(
    cargoPassCalculation.plannedKilometrage,
    2
  ),
  blankSum: convertFromKopToRub(cargoPassCalculation.blankSum),
  agreementSum: convertFromKopToRub(cargoPassCalculation.agreementSum),
  passSum: convertFromKopToRub(cargoPassCalculation.passSum),
  taxSum: convertFromKopToRub(cargoPassCalculation.taxSum),
  sum: convertFromKopToRub(cargoPassCalculation.sum),
  excessRate: convertFromKopToRub(cargoPassCalculation.excessRate),
  taxRate: convertFromKopToRub(cargoPassCalculation.taxRate),
  agreementRate: convertFromKopToRub(cargoPassCalculation.agreementRate),
  months: cargoPassCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  cargoPassCalculationMonth: CargoPassCalculationMonth
): CargoPassCalculationMonth => ({
  ...cargoPassCalculationMonth,
  blankPrice: convertFromRubToKop(cargoPassCalculationMonth.blankPrice),
  cost: convertFromRubToKop(cargoPassCalculationMonth.cost),
  tax: convertFromRubToKop(cargoPassCalculationMonth.tax),
  agreementCost: convertFromRubToKop(cargoPassCalculationMonth.agreementCost)
});

export const convertDataFromServerMonth = (
  cargoPassCalculationMonth: CargoPassCalculationMonth
): CargoPassCalculationMonth => ({
  ...cargoPassCalculationMonth,
  blankPrice: convertFromKopToRub(cargoPassCalculationMonth.blankPrice),
  cost: convertFromKopToRub(cargoPassCalculationMonth.cost),
  tax: convertFromKopToRub(cargoPassCalculationMonth.tax),
  agreementCost: convertFromKopToRub(cargoPassCalculationMonth.agreementCost)
});

let basedUrl = 'cargoPassCalculation';
export const updateCalculations = async (
  vehiclePlanId: number
): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const cargoPassCalculation: cargoPassCalculationApiMethod = new CommonApi<CargoPassCalculation>(
  basedUrl,
  'Пропуска негабарит',
  convertDataToServer,
  convertDataFromServer
);

cargoPassCalculation.calculation = new CommonApi<CargoPassCalculation>(
  `${basedUrl}/updateCalculation`,
  'Пропуска негабарит обновление',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...cargoPassCalculation,
  calculate,
  changeStatus,
  updateCalculations,
  print
};
