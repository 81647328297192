import {
    GpsSubscriptionServiceCostForm,
    GpsSubscriptionServiceCostList,
} from '../../containers/GpsSubscriptionServiceCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: GpsSubscriptionServiceCostList,
        path: '/admin/gps-subscription-service-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: GpsSubscriptionServiceCostForm,
        path: '/admin/gps-subscription-service-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: GpsSubscriptionServiceCostForm,
        path: '/admin/gps-subscription-service-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];