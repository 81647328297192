import React from 'react';
import styled from 'styled-components';

import type { PddAnswer, PddTestResult } from '../../../../lib/types';
import {
  pddTest,
  pddTestStatuses,
  pddTestStatusesEnum
} from '../../../../lib/enum';

import { GridItem, Section, SectionTitle } from '../../../../components/layout';
import Grid from '../../../../components/layout/Grid';
import { Table } from '../../../../components/ui';
import { Card } from '../../../../components';
import { formatSecondsToHumanTime } from '../../../../lib/helpers';

const { Field } = Card;
const GREEN = '#219653';
const RED = '#e9180a';
const Status = styled.span`
  ${({ color }) => {
    return {
      fontSize: '20px',
      color: color,
      fontWeight: 'bold'
    };
  }}
`;
const RightAnswers = styled.span`
  color: ${GREEN};
`;
const WrongAnswers = styled.span`
  color: ${RED};
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const Text = styled.div`
  white-space: break-spaces;
  vertical-align: top;
`;
const Answer = styled(Text)`
  color: #054dd9;
`;
const RightAnswer = styled(Text)`
  color: ${GREEN};
`;

type Props = {
  data: PddTestResult,
  loading: boolean
};
export default ({ data, loading }: Props) => (
  <>
    <Section>
      <SectionTitle divider>Результаты</SectionTitle>
      <SectionContent>
        <Grid gutter="16px" cols={3}>
          <GridItem>
            <Field label="Статус">
              <Status
                color={pddTestStatusesEnum.passed === data.status ? GREEN : RED}
              >
                {pddTestStatuses[data.status]}
              </Status>
            </Field>
          </GridItem>
          {data?.pddTest?.type === pddTest.training && (
            <GridItem>
              <Field label="Наименование теста">
                <RightAnswers>{data?.pddTest?.name ?? '-'}</RightAnswers>
              </Field>
            </GridItem>
          )}

          {data?.pddTest?.examTime !== 0 ? (
            <GridItem>
              <Field label="Всего пройдено времени">
                {formatSecondsToHumanTime(data?.totalTime)}
              </Field>
            </GridItem>
          ) : (
            <GridItem />
          )}
          <GridItem>
            <Field label="Правильных ответов">
              <RightAnswers>{data.rightAnswersCount}</RightAnswers>
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Неправильных ответов">
              <WrongAnswers>{data.wrongAnswersCount}</WrongAnswers>
            </Field>
          </GridItem>
        </Grid>
      </SectionContent>
    </Section>
    <Section>
      <Table
        loading={loading}
        columns={[
          {
            title: 'Раздел',
            dataIndex: 'pddQuestion.group.name',
            className: 'vertical-align-top'
          },
          {
            title: 'Вопрос',
            dataIndex: 'pddQuestion.question',
            className: 'vertical-align-top',
            render: (question: string) => <Text>{question}</Text>
          },
          {
            title: 'Ваш ответ',
            width: 300,
            dataIndex: 'pddAnswer.description',
            className: 'vertical-align-top',
            render: (answer: string) => <Answer>{answer}</Answer>
          },
          {
            title: 'Правильный ответ',
            width: 300,
            dataIndex: 'pddQuestion.answers',
            className: 'vertical-align-top',
            render: (pddAnswers: PddAnswer[]) => (
              <RightAnswer>
                {pddAnswers
                  ?.filter(answer => answer.isRight)
                  .map(answer => `${answer.description}\n`)}
              </RightAnswer>
            )
          }
        ]}
        data={
          data?.pddTestResultAnswers?.filter(result => result.isWrongAnswer) ??
          []
        }
      />
    </Section>
  </>
);
