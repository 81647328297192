// @flow
import React, { useMemo } from 'react';
import _last from 'lodash/last';

import type {
  MonitoringVehicle
} from '../../../../../../../lib/types/monitoringVehicle';
import type { MonitoringHistoryState } from '../../../../../MonitoringScreen';
import { ControlPanel, Grid } from './elements';

import { Wrapper } from './styles';

type Props = {
  monitoringVehicle: MonitoringVehicle,
  state: MonitoringHistoryState,
  onStateChange: (
    fn: (
      state: $Shape<MonitoringHistoryState>
    ) => $Shape<MonitoringHistoryState>
  ) => void,
  playback: any
};

/**
 * Вкладка истории
 */
export const HistoryTab = ({ state, playback, onStateChange }: Props) => {
  const { history } = state;

  const { startDate, endDate } = useMemo(() => {
    const startDate = history[0]?.timestamp;
    const endDate = _last(history)?.timestamp;
    return { startDate, endDate };
  }, [history]);

  return (
    <Wrapper>
      <ControlPanel
        playback={playback}
        state={state}
        onStateChange={onStateChange}
      />
      <Grid
        currentTickTime={state.currentTick}
        history={history}
        playback={playback}
        endDate={endDate}
        startDate={startDate}
      />
    </Wrapper>
  );
};
