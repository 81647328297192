// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { RangePicker } from '../../../../components/ant/DatePicker';
import Field from '../../../../components/card/Field';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import { formatDateTimeToISOString } from '../../../../lib/helpers';

import type { Vehicle } from '../../../../lib/types';
import type { ReportFuelCardsParams } from '../../../../lib/types/reportFuelCards';
import type { ReportVehicleWorkParams } from '../../../../lib/types/reportVehicleWork';

import { Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects } from './../../../../components';

const SpoilerContent = styled.div`
  padding: 16px;
`;

const {
  BrandsSelect,
  VehicleTypeSelect,
  FuelGroupSelect,
  VehicleSelect,
} = Selects;

type Props = {
  filter: ReportVehicleWorkParams,
  cleanFilter: Function,
  applyFilter: Function,
  style?: any
};

const ReportFuelCardsFilter = ({
  style,
  filter,
  applyFilter,
  cleanFilter
}: Props) => (
  <SpoilerContent style={{ ...style }}>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<ReportFuelCardsParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <BrandsSelect
                  size="small"
                  value={values.brandId}
                  mode="multiple"
                  onChange={ids => changeValue('brandId', ids)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  size="small"
                  value={values.type}
                  selectableGroup
                  onChange={types => changeValue('type', types)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY HH:mm"
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm')
                    ]
                  }}
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <FuelGroupSelect
                  size="small"
                  value={values.fuelGroup}
                  onChange={fuelGroup => changeValue('fuelGroup', fuelGroup)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.vehicleId}
                  mode="multiple"
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  onChange={ids => changeValue('vehicleId', ids)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  value={values.orgUnitId}
                  onChange={id => changeValue('orgUnitId', id)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ReportFuelCardsFilter;
