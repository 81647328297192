// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import type { Battery as BatteryType } from './../../../lib/types';
import { Batteries as BatteriesComponents } from './../components';
import {
  resetVehicleStatus,
  setVehicleBatteries
} from './../../../ducks/vehicle';
import { batteryApi } from './../../../lib/api';
import type { AppState } from '../../../ducks/redux';

const { BatteryForm, EditableItem } = BatteriesComponents;

type Props = {
  items: Array<BatteryType>,
  resetVehicleStatus: (vehicleId: number) => void,
  setVehicleBatteries: (vehicleId: number) => void,
  vehicleId: number,
  editable: boolean,
  employeeBranchOrgUnitId: number
};

type State = {
  availableBatteries: BatteryType[],
  isLoading: boolean
};

export class Battery extends Component<Props, State> {
  state = {
    availableBatteries: [],
    isLoading: false
  };

  async componentDidMount() {
    await this.fetchAvailableBatteries();
  }

  fetchAvailableBatteries = async (search?: string) => {
    if (this.props.editable) {
      this.setState({
        isLoading: true
      });
      let availableBatteries = [];
      const result = await batteryApi.fetchBatteries({
        search,
        page: 1,
        pageSize: 50,
        isFree: true,
        nodeId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren'
      });

      if (result) {
        availableBatteries = result.data;
      }
      this.setState({
        availableBatteries,
        isLoading: false
      });
    }
  };

  onAdd = async (battery: BatteryType) => {
    await batteryApi.updateBattery({
      ...battery,
      vehicleId: this.props.vehicleId
    });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleBatteries(this.props.vehicleId);
    await this.fetchAvailableBatteries();
  };

  onEdit = async (battery: BatteryType) => {
    await batteryApi.updateBattery(battery);
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleBatteries(this.props.vehicleId);
  };

  onRemove = async (battery: BatteryType) => {
    await batteryApi.updateBattery({ ...battery, vehicleId: null });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleBatteries(this.props.vehicleId);
    await this.fetchAvailableBatteries();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableBatteries(value),
    500
  );
  render() {
    const { items = [], editable }: Props = this.props;
    const { availableBatteries, isLoading } = this.state;
    return (
      <>
        {items.map((item: BatteryType) => {
          if (!item) {
            return null;
          }
          return (
            <EditableItem
              key={item.id}
              battery={item}
              onChange={this.onEdit}
              onRemove={editable ? this.onRemove : null}
              editable={false} // TODO: нужны требования, но пока не можем редактировать АКБ в инвентарной карточке ТС
            />
          );
        })}
        {editable && (
          <BatteryForm
            onSubmit={this.onAdd}
            handleSearch={this.handleSearch}
            fetchData={this.fetchAvailableBatteries}
            isLoading={isLoading}
            availableBatteries={availableBatteries}
          />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }),
  {
    resetVehicleStatus,
    setVehicleBatteries
  }
)(Battery);
