// @flow

import type { Dispatch } from 'redux';
import type { EmployeeDocument } from '../lib/types/employeeDocument';

import type { Employee } from './../lib/types';
import { employeeApi, employeeDocumentApi } from './../lib/api';
import type { Action, AppState } from './redux';
import { employeeDocumentTypeEnum } from '../lib/enum';

export const FETCH_EMPLOYEE = 'vehicles/employee/fetch';
export const CLEAN_EMPLOYEE = 'vehicles/employee/clean';

export type EmployeeState = ?Employee;

const initialState: EmployeeState = null;

const reducer = (
  state: EmployeeState = initialState,
  { type, payload }: Action
): EmployeeState => {
  switch (type) {
    case FETCH_EMPLOYEE:
      return { ...payload };
    case CLEAN_EMPLOYEE:
      return null;
    default:
      return state;
  }
};

export const addEmployee = (employee: Employee) => async (
  dispatch: Dispatch
): Promise<void> => {
  const added = await employeeApi.addEmployee(employee);
  const { id: employeeId } = added;
  if (employee.riggerDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.riggerDocument,
      documentType: employeeDocumentTypeEnum.rigger,
      employeeId
    });
  }
  if (employee.lulechnikDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.lulechnikDocument,
      documentType: employeeDocumentTypeEnum.lulechnik,
      employeeId
    });
  }
  if (employee.safetyTechnicianDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.safetyTechnicianDocument,
      documentType: employeeDocumentTypeEnum.safetyTechnician,
      employeeId
    });
  }
  if (employee.safetyCarLiftDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.safetyCarLiftDocument,
      documentType: employeeDocumentTypeEnum.safetyCarLift,
      employeeId
    });
  }
  if (employee.controllerGpmDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.controllerGpmDocument,
      documentType: employeeDocumentTypeEnum.controllerGpm,
      employeeId
    });
  }
  if (employee.carLiftOperatorDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.carLiftOperatorDocument,
      documentType: employeeDocumentTypeEnum.carLiftOperator,
      employeeId
    });
  }
  if (employee.truckCranesMachinistDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.truckCranesMachinistDocument,
      documentType: employeeDocumentTypeEnum.truckCranesMachinist,
      employeeId
    });
  }
  if (employee.manipulatorCranesOperatorDocument) {
    await employeeDocumentApi.addDocument({
      ...employee.manipulatorCranesOperatorDocument,
      documentType: employeeDocumentTypeEnum.manipulatorCranesOperator,
      employeeId
    });
  }
};

export const updateEmployee: Function = (
  employee: Employee
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const updated = await employeeApi.updateEmployee(employee);
  const { id: employeeId } = updated;
  if (employee.riggerDocument) {
    if (employee.riggerDocument.id) {
      await employeeDocumentApi.updateDocument(employee.riggerDocument);
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.riggerDocument,
        documentType: employeeDocumentTypeEnum.rigger,
        employeeId
      });
    }
  }
  if (employee.lulechnikDocument) {
    if (employee.lulechnikDocument.id) {
      await employeeDocumentApi.updateDocument(employee.lulechnikDocument);
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.lulechnikDocument,
        documentType: employeeDocumentTypeEnum.lulechnik,
        employeeId
      });
    }
  }
  if (employee.safetyTechnicianDocument) {
    if (employee.safetyTechnicianDocument.id) {
      await employeeDocumentApi.updateDocument(
        employee.safetyTechnicianDocument
      );
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.safetyTechnicianDocument,
        documentType: employeeDocumentTypeEnum.safetyTechnician,
        employeeId
      });
    }
  }
  if (employee.safetyCarLiftDocument) {
    if (employee.safetyCarLiftDocument.id) {
      await employeeDocumentApi.updateDocument(employee.safetyCarLiftDocument);
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.safetyCarLiftDocument,
        documentType: employeeDocumentTypeEnum.safetyCarLift,
        employeeId
      });
    }
  }
  if (employee.controllerGpmDocument) {
    if (employee.controllerGpmDocument.id) {
      await employeeDocumentApi.updateDocument(employee.controllerGpmDocument);
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.controllerGpmDocument,
        documentType: employeeDocumentTypeEnum.controllerGpm,
        employeeId
      });
    }
  }
  if (employee.carLiftOperatorDocument) {
    if (employee.carLiftOperatorDocument.id) {
      await employeeDocumentApi.updateDocument(
        employee.carLiftOperatorDocument
      );
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.carLiftOperatorDocument,
        documentType: employeeDocumentTypeEnum.carLiftOperator,
        employeeId
      });
    }
  }
  if (employee.truckCranesMachinistDocument) {
    if (employee.truckCranesMachinistDocument.id) {
      await employeeDocumentApi.updateDocument(
        employee.truckCranesMachinistDocument
      );
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.truckCranesMachinistDocument,
        documentType: employeeDocumentTypeEnum.truckCranesMachinist,
        employeeId
      });
    }
  }
  if (employee.manipulatorCranesOperatorDocument) {
    if (employee.manipulatorCranesOperatorDocument.id) {
      await employeeDocumentApi.updateDocument(
        employee.manipulatorCranesOperatorDocument
      );
    } else {
      await employeeDocumentApi.addDocument({
        ...employee.manipulatorCranesOperatorDocument,
        documentType: employeeDocumentTypeEnum.manipulatorCranesOperator,
        employeeId
      });
    }
  }
};

export const fetchEmployee = (id: number) => async (
  dispatch: Dispatch
): Promise<void> => {
  const employee = await employeeApi.fetchEmployee(id);
  // Приводим массив документов к объекту с соответсвующими ключами
  // array-callback-return
  const documents = employee.documents.reduce((acc: Employee, cur: EmployeeDocument) => {
    if (cur.documentType === employeeDocumentTypeEnum.rigger) {
      return {
        ...acc,
        riggerDocument: cur
      };
    } else if (cur.documentType === employeeDocumentTypeEnum.lulechnik) {
      return {
        ...acc,
        lulechnikDocument: cur
      };
    } else if (cur.documentType === employeeDocumentTypeEnum.safetyTechnician) {
      return {
        ...acc,
        safetyTechnicianDocument: cur
      };
    } else if (cur.documentType === employeeDocumentTypeEnum.safetyCarLift) {
      return {
        ...acc,
        safetyCarLiftDocument: cur
      };
    } else if (cur.documentType === employeeDocumentTypeEnum.controllerGpm) {
      return {
        ...acc,
        controllerGpmDocument: cur
      };
    } else if (cur.documentType === employeeDocumentTypeEnum.carLiftOperator) {
      return {
        ...acc,
        carLiftOperatorDocument: cur
      };
    } else if (
      cur.documentType === employeeDocumentTypeEnum.truckCranesMachinist
    ) {
      return {
        ...acc,
        truckCranesMachinistDocument: cur
      };
    } else if (
      cur.documentType === employeeDocumentTypeEnum.manipulatorCranesOperator
    ) {
      return {
        ...acc,
        manipulatorCranesOperatorDocument: cur
      };
    }
    return {
      ...acc
    };
  }, {});
  dispatch({
    type: FETCH_EMPLOYEE,
    payload: { ...employee, ...documents }
  });
};

export const cleanEmployee = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAN_EMPLOYEE
  });

export default reducer;
