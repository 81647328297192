// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportVehicleOutputRatio,
  ReportVehicleOutputRatioParams
} from '../types/reportVehicleOutputRatio';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportVehicleOutputRatio) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage),
  outputRatio: reportItem.outputRatio.toFixed(3)
});

/**
 * Получение отчета
 */
export const fetchReportVehicleOutputRatio = async (
  params: ReportVehicleOutputRatioParams
): Promise<ReportVehicleOutputRatio[]> => {
  const report = await fetchRequest.get('/report/vehicle-output-ratio', {
    ...params
  });
  return report.map(convertFromServer);
};

/**
 * Печать отчета
 */
export const printReportVehicleOutputRatio = async (
  params: ReportVehicleOutputRatioParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/vehicle-output-ratio/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportVehicleOutputRatio,
  printReportVehicleOutputRatio
};
