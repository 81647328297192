// @flow

import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import Grid, { GridItem } from '../../../../components/layout/Grid';

import { oilsAndFluidsConsumptionPlanVehicleApi } from '../../../../lib/api';
import {
  technicalFluidTypes,
  technicalFluidTypesEnum,
} from '../../../../lib/enum';
import { minus } from '../../../../lib/helpers';
import type { OilsAndFluidsConsumptionPlanVehicle } from '../../../../lib/types';

const Cell = styled(GridItem)`
  padding: 10px;
`;

const Header = styled(Cell)`
  background: #c0ccd7;
`;

type Props = {
  vehicleId: number,
};

type State = {
  plan: ?OilsAndFluidsConsumptionPlanVehicle | ?boolean,
  isLoading: boolean,
};

/**
 * Компонент отображает содержимое списка ГСМ
 */
export default class FuelsAndOilsList extends Component<Props, State> {
  state = {
    plan: null,
    isLoading: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      await this.fetchPlan();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err ? err.message : '',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  fetchPlan = async () => {
    const { vehicleId } = this.props;
    try {
      const plan = await oilsAndFluidsConsumptionPlanVehicleApi.vehiclePlan(
        vehicleId,
        moment().format('YYYY-MM-DD')
      );
      this.setState({
        plan,
      });
    } catch (err) {
      this.setState({ plan: false });
      notification.error({
        message: 'Ошибка',
        description: err ? err.message : '',
      });
    }
  };

  renderRow = (name: string) => {
    const { plan } = this.state;
    // $FlowFixMe
    const planLiterts = plan ? plan[`${name}Liters`] : 0;
    // $FlowFixMe
    const ostatokLiters = plan ? plan[`${name}LitersResidue`] : 0;
    return (
      <>
        <Cell>{technicalFluidTypes[name]}</Cell>
        <Cell>{planLiterts ?? '-'}</Cell>
        <Cell>{minus(planLiterts, ostatokLiters)}</Cell>
        <Cell>{ostatokLiters ?? '-'}</Cell>
      </>
    );
  };

  render() {
    const { isLoading } = this.state;
    return (
      !isLoading && (
        <>
          <Grid
            cols={4}
            style={{
              border: '1px solid #c0ccd7',
              borderRadius: '3px',
            }}
          >
            <Header>Тех.жидкость</Header>
            <Header>План, л.</Header>
            <Header>Выдано по талонам, л.</Header>
            <Header>Остаток, л.</Header>

            {this.renderRow(technicalFluidTypesEnum.oil)}
            {this.renderRow(technicalFluidTypesEnum.antifreeze)}
            {this.renderRow(technicalFluidTypesEnum.windshieldWasherFluid)}
          </Grid>
        </>
      )
    );
  }
}
