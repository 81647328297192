// @flow

export const driverQualifications = {
  driverLicense: 'Водительское удостоверение',
  tractorMachinistLicense: 'Удостоверение тракториста машиниста',
  bkmMachinistLicense: 'Удостоверение машиниста бурильно-крановой машины',
  dopogDriverLicense: 'Свидетельство ДОПОГ о подготовке водителя',
  skziDriverMap: 'СКЗИ (карта водителя) тахографа'
};

export const driverQualificationEnum = {
  driverLicense: 'driverLicense',
  tractorMachinistLicense: 'tractorMachinistLicense',
  bkmMachinistLicense: 'bkmMachinistLicense',
  dopogDriverLicense: 'dopogDriverLicense',
  skziDriverMap: 'skziDriverMap'
};
