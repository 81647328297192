// @flow
import { Dispatch } from 'redux';

import type { Action } from './redux';
import type { VehicleModel } from '../lib/types';
import { vehicleModelApi } from '../lib/api';
import { DELETE_VEHICLE_MODEL } from './vehicleModels';

export const FETCH_VEHICLE_MODEL = 'vehicles/model/fetch';
export const CLEAN_VEHICLE_MODEL = 'vehicles/model/clean';

export type VehicleModelState = ?VehicleModel;

const initialState: VehicleModelState = null;

const reducer = (
  state: VehicleModelState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case FETCH_VEHICLE_MODEL:
      return { ...payload };
    case CLEAN_VEHICLE_MODEL:
      return null;
    default:
      return state;
  }
};

export const addVehicleModel = (model: VehicleModel) => async (
  dispatch: Dispatch
) => await vehicleModelApi.addVehicleModel(model);

export const deleteVehicleModel = (id: number) => async (
  dispatch: Dispatch
) => {
  await vehicleModelApi.deleteVehicleModel(id);
  dispatch({
    type: DELETE_VEHICLE_MODEL,
    payload: id
  });
};

export const updateVehicleModel = (model: VehicleModel) => async () => {
  await vehicleModelApi.updateVehicleModel(model);
};

export const fetchVehicleModel = (id: number) => async (dispatch: Dispatch) => {
  let model = await vehicleModelApi.fetchVehicleModel(id);
  dispatch({
    type: FETCH_VEHICLE_MODEL,
    payload: { ...model }
  });
  return model;
};

export const cleanVehicleModel = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_VEHICLE_MODEL
  });
};

export default reducer;
