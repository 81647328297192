// @flow
import React from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { maintenanceWorkTypeApi } from '../../lib/api';
import { getListInitialState } from './../../lib/helpers';
import type {
  ListState,
  MaintenanceWorkType,
  UserAccess,
} from '../../lib/types';
import { accessTypeEnum, maintenanceTypeOfWork } from '../../lib/enum';
import { Popconfirm, Icon } from '../../components/ui';
import Table from '../../components/ui/Table';
import { Header, Section } from '../../components/layout';
import { notificationLoading } from './../../components/Notifications';

import { withUserAccess } from './../withUserAccess';
import type { MaintenanceWorkTypeFilterParams } from './components/Filter';
import Filter from './components/Filter';

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingMaintenanceWorkTypes,
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<MaintenanceWorkType> & {
  filter: MaintenanceWorkTypeFilterParams,
};

export class MaintenanceTypeWorkList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: 'Тип работ',
      dataIndex: 'workType',
      render: (value: string) => maintenanceTypeOfWork[value],
    },
  ];

  componentDidMount = async () => {
    const { page } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteMaintenanceTypeWork(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
      });
    }
    await this.fetchMaintenanceTypeWork(page);
  };

  deleteMaintenanceTypeWork = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await maintenanceWorkTypeApi.deleteMaintenanceTypeWork(id);
      await this.fetchMaintenanceTypeWork(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchMaintenanceTypeWork = async (page: number) => {
    this.setState({ loading: true });
    const data = await maintenanceWorkTypeApi.fetchMaintenanceTypeWork({
      ...this.state.filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  applyFilter = (filter: MaintenanceWorkTypeFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchMaintenanceTypeWork(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchMaintenanceTypeWork(page)
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Виды работ по обслуживанию ТС</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() => navigate('/admin/maintenance-work-type/new')}
              >
                Создать
              </Button>
            )
          }
        />

        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchMaintenanceTypeWork}
            fetchOnMount
            onRow={(row: MaintenanceWorkType) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/maintenance-work-type/${row.id}/edit`)
                  : null,
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceTypeWorkList);
