// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import ButtonGroup from 'antd/lib/button/button-group';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Menu from 'antd/lib/menu';

import type {
  Order,
  Vehicle,
  VehicleForSelection,
  Employee,
  UserAccess,
  ContractVehicleOrder,
} from './../../lib/types';
import {
  fetchOrder,
  changeStatus,
  updateOrder,
  cleanOrder,
} from './../../ducks/order';
import type { OrderStatusOptions } from './../../ducks/order';
import {
  orderApi,
  orderGpmApi,
  contractVehicleOrderApi,
} from './../../lib/api';
import {
  formatDateRangeString,
  convertEmployeeToString,
  goBack,
} from './../../lib/helpers';
import {
  orderObjectives,
  vehicleTypes,
  orderStatusEnum,
  accessTypeEnum,
  orderTypes,
  ownerTypes,
  trailerVehicleTypes,
  vehicleGroups,
} from './../../lib/enum';
import type { AppState } from '../../ducks/redux';
import {
  Card as CardComponent,
  WaypointsViewer,
  VehicleSelection,
  AccessDenied,
} from './../../components';
import {
  Panel,
  Section,
  SectionTitle,
  Header,
} from './../../components/layout';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import Grid, { GridItem } from './../../components/layout/Grid';
import { notificationLoading } from './../../components/Notifications';
import { Popconfirm, ButtonOperations, Dropdown } from './../../components/ui';
import type { DropdownType } from './../../components/ui/Dropdown';
import OrderCardActionsDropdown from './components/OrderCardActionsDropdown';

import { withUserAccess } from './../withUserAccess';
import { ContractVehicleOrderCard } from './../ContractVehicleOrder';
import {
  getSelectionVehicles,
  canCopyOrder,
  canCancelOrder,
  canCancelGpmOrder,
} from './lib';
import ListTable from '../../components/ui/ListTable';

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
`;
const BusinessTrip = styled.span`
  line-height: 1.8;
  background: #838d96;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: normal;
  padding: 3px 5px;
`;
const Operations = styled.div`
  display: inline-flex;
  align-items: center;
`;

const { Field } = CardComponent;

const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];

// Список доступов, у которых есть разрешение на просмотр информации в карточке
const containerAccess = [
  ...adminAccess,
  accessTypeEnum.creatingOrder,
  accessTypeEnum.approvingGpmOrder,
  accessTypeEnum.handlingOrder,
];

type Props = {
  fetchOrder: Function,
  orderId: number,
  employeeId: number,
  order: Order,
  updateOrder: (order: Order) => void,
  changeStatus: (id: number, options: OrderStatusOptions) => void,
  cleanOrder: Function,
  userAccess: UserAccess[],
  currentEmployeeId: number,
};

type State = {
  touched: boolean,
  vehicleId?: number,
  order: ?Order,
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  showApproveByContractVehicleOrder: boolean,
  contractVehicleOrder: ?ContractVehicleOrder,
  copyOrderDate: ?string,
  vehiclesLoading: boolean,
  loading: boolean,
};

export class OrderCard extends Component<Props, State> {
  state: State = {
    touched: false,
    order: this.props.order,
    freeVehicles: [],
    freeTrailers: [],
    showApproveByContractVehicleOrder: false,
    contractVehicleOrder: null,
    copyOrderDate: null,
    vehiclesLoading: false,
    loading: false,
  };

  static defaultProps = {
    contractVehicleOrder: {},
  };

  dropdown: ?DropdownType;

  async componentDidMount() {
    const { orderId } = this.props;
    await this.props.cleanOrder();
    if (!this.hasAccess()) {
      return;
    }
    if (orderId) {
      await this.fetchOrder(orderId);
    }
  }

  fetchOrder = async (orderId: number) => {
    try {
      const order = await this.props.fetchOrder(orderId);
      if (order && order.contractVehicleOrderId) {
        const contractVehicleOrder =
          await contractVehicleOrderApi.fetchContractVehicleOrder(
            order.contractVehicleOrderId
          );
        this.setState({
          contractVehicleOrder,
        });
      }
      if (
        order &&
        ((!order.isGpm &&
          [
            orderStatusEnum.created,
            orderStatusEnum.approvedByMainEngineer,
          ].includes(order.status)) ||
          (order.isGpm &&
            order.status === orderStatusEnum.approvedByMainEngineer))
      ) {
        await this.fetchFreeVehicles();
      }
      this.setState({ order });
    } catch (error) {
      notification.error({
        message: 'Ошибка при загрузке заявки',
        description: error.message || 'Заявка не найдена',
      });
      goBack('/orders');
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.order, this.props.order)) {
      if (!isEqual(this.props.order, this.state.order)) {
        this.setState({
          order: this.props.order,
        });
      }
    }
  }

  fetchFreeVehicles = async () => {
    const { order, orderId } = this.props;
    this.setState({ vehiclesLoading: true });
    const freeAllVehicles: VehicleForSelection[] =
      await orderApi.fetchOrderFreeVehicles(orderId);
    const freeVehicles = [];
    const freeTrailers = [];
    const withTrailer = order && order.withTrailer;
    freeAllVehicles.forEach((vehicle: VehicleForSelection) => {
      if (
        withTrailer &&
        trailerVehicleTypes.includes(vehicle.vehicleModel.type)
      ) {
        freeTrailers.push({
          ...vehicle,
          disabled: false,
        });
      } else {
        freeVehicles.push(vehicle);
        /**
         * Если у нас заявка на ТС с прицепом и к ТС прикреплены прицепы,
         * то мы их добавляем, как прицепы, которые можно выбрать, но ставим отметку,
         * что они заблокированы для выбора.
         * Блокируем мы их потому, что прицеп можно выбрать только в связке ТС + прицеп.
         * Выбрать прикрепленный прицеп к другому ТС нельзя, для этого нужно произвести
         * манипуляции по откреплению прицепа в инвентарной карточке
         */
        if (withTrailer) {
          const vehicleTrailers = vehicle.trailers.map<Vehicle>(
            (item: Vehicle) => ({
              ...item,
              disabled: true,
            })
          );
          freeTrailers.push(...vehicleTrailers);
        }
      }
    });

    const { trailers, vehicles } = getSelectionVehicles({
      selectedTrailer: order.trailer,
      selectedVehicle: order.vehicle,
      trailers: freeTrailers,
      vehicles: freeVehicles,
      withTrailer: order.withTrailer,
    });

    this.setState({
      vehiclesLoading: false,
      freeVehicles: vehicles,
      freeTrailers: trailers,
    });
  };

  deleteOrder = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await orderApi.deleteOrder(this.props.orderId);
      navigate('/orders');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  vehicleChange = async (vehicle: Vehicle): Promise<void> =>
    this.setState((prevState) => {
      if (prevState.order) {
        let vehicleId = vehicle.id;

        if (vehicleId === prevState.order.vehicleId) {
          vehicleId = null;
        }

        /**
         * Если у выбранного ТС есть только один прицеп, то автоматом выбираем его.
         * Если прицепов нет, то оставляем тот, который был выбран до этого.
         * Если ТС не выбран, то и прицеп нам не нужен
         */
        let trailerId, trailer;
        if (vehicleId) {
          trailer =
            vehicle.trailers?.length > 0
              ? vehicle.trailers[0]
              : prevState.order.trailer;
          trailerId = trailer && trailer.id;
        }

        return {
          touched: true,
          order: {
            ...prevState.order,
            vehicleId,
            vehicle,
            trailerId,
            trailer,
          },
        };
      }
    });

  trailerChange = async (trailer: Vehicle): Promise<void> =>
    this.setState((prevState) => {
      if (prevState.order) {
        let trailerId = trailer.id;

        if (trailerId === prevState.order.trailerId) {
          trailerId = null;
        }
        return {
          touched: true,
          order: {
            ...prevState.order,
            trailerId,
            trailer,
          },
        };
      }
    });

  onApprove = async () => {
    const { order } = this.state;
    if (order) {
      this.setState({ loading: true });
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        if (!order.vehicleId) {
          notification.error({
            message: 'Для подтверждения заявки необходимо указать ТС',
          });
          return;
        }
        if (order.withTrailer && !order.trailerId) {
          notification.error({
            message: 'Для подтверждения заявки необходимо указать прицеп',
          });
          return;
        }
        // Обновляем заявку
        await this.props.updateOrder(order);
        this.setState({ touched: false });
        // Меняем статус
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.approved,
          vehicleId: parseInt(order.vehicleId, 10),
        });
        await this.fetchFreeVehicles();
        notification.success({
          message: 'Заявка успешно подтверждена',
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      } finally {
        notification.close('saving');
        this.setState({ loading: false });
      }
    }
  };

  onApproveByContractVehicleOrder = async () => {
    const { order, contractVehicleOrder } = this.state;
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        // Обновляем заявку на доп. найм ТС с указанием гос. номера и водителя
        if (contractVehicleOrder) {
          await contractVehicleOrderApi.updateContractVehicleOrder(
            contractVehicleOrder
          );
        }

        this.setState({ touched: false });
        // Меняем статус
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.approvedByContractVehicleOrder,
        });
        this.toggleApproveByContractVehicleOrder();
        await this.fetchFreeVehicles();
        notification.success({
          message: 'Заявка успешно подтверждена',
        });
      } catch (error) {
        notification.error({
          message: 'Подтверждение заявки завершилось неудачно',
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  hasAccess = () =>
    this.props.userAccess.some((access) => containerAccess.includes(access));

  onEngineerApprove = async () => {
    const { order } = this.state;
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        this.setState({ touched: false });
        // Меняем статус
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.approvedByMainEngineer,
        });
        await this.fetchFreeVehicles();
        notification.success({
          message: 'Заявка успешно утверждена',
        });
      } catch (error) {
        notification.error({
          message: 'Утверждение заявки завершилось неудачно',
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  canUserRestoreOrder = (order: Order): boolean => {
    const { userAccess } = this.props;
    return (
      order.status === orderStatusEnum.cancelled &&
      userAccess.some((access) =>
        [...adminAccess, accessTypeEnum.handlingOrder].includes(access)
      )
    );
  };

  canUserEditOrders = (order: Order): boolean => {
    const { userAccess, currentEmployeeId } = this.props;
    return (
      (order.isGpm
        ? order.status === orderStatusEnum.approvingByMainEngineer
        : order.status === orderStatusEnum.created) &&
      userAccess.some(
        (access) =>
          adminAccess.includes(access) ||
          userAccess.some(
            (access) =>
              [
                accessTypeEnum.creatingOrder,
                accessTypeEnum.handlingOrder,
              ].includes(access) && order.claimantId === currentEmployeeId
          )
      )
    );
  };

  /**
   * Возможность подтверждения заявки ГПМ
   * 1) Заявка должна быть ГПМ
   * 2) Заявка должна быть в статусе "На подтверждении у главного инженера"
   * 3) Подтверждение доступно только с доступом "Подтверждение заявки на ГПМ"
   */
  canApproveGpmOrder = (order: Order): boolean => {
    const { userAccess } = this.props;
    return (
      order.isGpm &&
      order.status === orderStatusEnum.approvingByMainEngineer &&
      userAccess.some((access) =>
        [...adminAccess, accessTypeEnum.approvingGpmOrder].includes(access)
      )
    );
  };

  /**
   * Возможность подтверждения любой заявки
   * 1) Если обычная заявка, то она должна быть в статусе "Создана"
   * 2) Если заявка ГПМ, то должна быть в статусе "Утверждено главным инженером"
   * 3) Подтверждение доступно только с доступом "Обработка заявки"
   */
  canApproveOrder = (order: Order): boolean => {
    const { userAccess } = this.props;
    return (
      ((!order.isGpm &&
        [
          orderStatusEnum.created,
          orderStatusEnum.approvedByMainEngineer,
        ].includes(order.status)) ||
        (order.isGpm &&
          order.status === orderStatusEnum.approvedByMainEngineer)) &&
      userAccess.some((access) =>
        [...adminAccess, accessTypeEnum.handlingOrder].includes(access)
      )
    );
  };

  /**
   * Возможность отредактировать заявку
   */
  canEditOrder = () =>
    this.props.userAccess.some((access) =>
      [...adminAccess, accessTypeEnum.handlingOrder].includes(access)
    );

  /**
   * Возможность перенаправить заявку
   */
  canRedirectOrder = (): boolean =>
    this.props.userAccess.some((access) =>
      [...adminAccess, accessTypeEnum.handlingOrder].includes(access)
    );

  onCancel = async () => {
    const { order } = this.state;
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.cancelled,
        });
        notification.success({
          message: 'Заявка успешно отменена',
        });
        navigate('/orders');
      } catch (error) {
        notification.error({
          message: error.message,
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  changeContractVehicleOrder = (field: string, value: any) =>
    // $FlowFixMe не хватает параметров
    this.setState((prevState) => ({
      contractVehicleOrder: {
        ...prevState.contractVehicleOrder,
        [field]: value,
      },
    }));

  toggleApproveByContractVehicleOrder = () =>
    this.setState((prevState) => ({
      showApproveByContractVehicleOrder:
        !prevState.showApproveByContractVehicleOrder,
    }));

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await orderGpmApi.printGpmOrder(this.props.orderId);
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  handleContractVehicleOrderPrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await contractVehicleOrderApi.printContractVehicleOrder(
        parseInt(this.props.order.contractVehicleOrderId, 10)
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  deleteContractVehicleOrder = async () => {
    const { order } = this.props;
    const contractVehicleOrderId = parseInt(order.contractVehicleOrderId, 10);
    if (contractVehicleOrderId > 0) {
      await contractVehicleOrderApi.deleteContractVehicleOrder(
        contractVehicleOrderId
      );
      await this.props.fetchOrder(order.id);
    }
  };

  // Восстановление заявки
  restoreOrder = async () => {
    try {
      notificationLoading({
        message: 'Восстановление...',
        key: 'restoring',
      });
      await this.props.changeStatus(this.props.orderId, {
        status: orderStatusEnum.created,
      });
      notification.success({
        message: 'Успешно',
        description: 'Заявка восстановлена',
      });
      await this.fetchOrder(this.props.orderId);
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('restoring');
    }
  };

  // Пока необходмой кнопки аппрува
  renderApproveButtons = () => {
    const { order, loading } = this.state;
    if (!order) {
      return null;
    }
    const contractVehicleOrderId = parseInt(order.contractVehicleOrderId, 10);
    const vehicleId = parseInt(order.vehicleId, 10);
    const trailerId = parseInt(order.trailerId, 10);
    const canApproveByContractVehicleOrder =
      contractVehicleOrderId > 0 &&
      (order.withTrailer ? !trailerId : true) &&
      !vehicleId;
    // Если у нас имеется заявка на доп. найм ТС и не выбран собственный/наемный ТС
    if (canApproveByContractVehicleOrder) {
      return (
        <Button
          loading={loading}
          disabled={loading}
          type="primary"
          onClick={this.toggleApproveByContractVehicleOrder}
        >
          Подтвердить
        </Button>
      );
    }
    return (
      <Button
        className="orderApproveBtn"
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={this.onApprove}
      >
        Подтвердить
      </Button>
    );
  };

  render() {
    if (!this.hasAccess()) {
      return <AccessDenied />;
    }
    const { order, freeTrailers, freeVehicles, vehiclesLoading }: State =
      this.state;
    const { userAccess, employeeId } = this.props;
    if (!order) {
      return null;
    }

    const canCancel =
      order && order.isGpm
        ? canCancelGpmOrder(order, userAccess)
        : canCancelOrder(order, userAccess, employeeId);
    const canApproveGsmOrder = order && this.canApproveGpmOrder(order);
    const canApproveOrder = order && this.canApproveOrder(order);
    const canEditOrder = this.canEditOrder() || this.canUserEditOrders(order);
    const canRestore = this.canUserRestoreOrder(order);
    const canCopy = canCopyOrder(this.props.userAccess);

    let trailer,
      vehicle,
      withTrailer = false;
    if (order) {
      trailer = order.trailer;
      vehicle = order.vehicle;
      withTrailer = order.withTrailer;
    }

    const { trailers, vehicles } = getSelectionVehicles({
      selectedTrailer: trailer,
      selectedVehicle: vehicle,
      trailers: freeTrailers,
      vehicles: freeVehicles,
      withTrailer,
    });

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/orders">Заявки</Crumb>
              <Crumb>Заявка №{order.id}</Crumb>
            </Breadcrumbs>
          }
          right={
            <ButtonOperations>
              {order.isGpm && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
              {
                <>
                  <ButtonGroup>
                    {canApproveGsmOrder && (
                      <Button
                        className="engineerApproveBtn"
                        type="primary"
                        onClick={this.onEngineerApprove}
                      >
                        Утвердить
                      </Button>
                    )}
                    {canApproveOrder && this.renderApproveButtons()}
                  </ButtonGroup>
                  <OrderCardActionsDropdown
                    order={order}
                    canRedirectOrder={this.canRedirectOrder()}
                    canApprove={canApproveOrder}
                    canCancel={canCancel}
                    canRestore={canRestore}
                    canEdit={canEditOrder}
                    canCopy={canCopy}
                    onCancel={this.onCancel}
                    onRestore={this.restoreOrder}
                    onDelete={this.deleteOrder}
                  />
                </>
              }
            </ButtonOperations>
          }
        />
        <StyledPanel>
          <h1>Заявка №{order.id}</h1>
        </StyledPanel>
        <Section>
          <SectionTitle
            divider
            suffix={
              order.isBusinessTrip && <BusinessTrip>Командировка</BusinessTrip>
            }
          >
            Назначение
          </SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Период выделения ТС">
                  {formatDateRangeString(order.startDate, order.endDate)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">{orderTypes[order.type]}</Field>
              </GridItem>
              <GridItem>
                <Field label="Цель поездки">
                  {orderObjectives[order.objective]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="С прицепом">
                  {order.withTrailer ? 'Да' : 'Нет'}
                </Field>
              </GridItem>
              {order.notation && (
                <GridItem>
                  <Field label="Примечание">{order.notation}</Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="В распоряжение службы">{order.orgUnitName}</Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение (ФИО)">
                  {convertEmployeeToString(order.employee)}
                </Field>
              </GridItem>
              {order.businessTripOrderNumber && (
                <GridItem>
                  <Field label="Номер заявки на командировку">
                    {order.businessTripOrderNumber}
                  </Field>
                </GridItem>
              )}
              {order.businessTripDecreeNumber && (
                <GridItem>
                  <Field label="Номер приказа на командировку">
                    {order.businessTripDecreeNumber}
                  </Field>
                </GridItem>
              )}
              {order.isGpm && (
                <>
                  <GridItem>
                    <Field label="Главный инженер">
                      {convertEmployeeToString(order.mainEngineer)}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Владелец ГПМ">
                      {convertEmployeeToString(order.gpmOwner)}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Заявитель">
                      {convertEmployeeToString(order.claimant)}
                      {order.claimant ? `, ${order.claimant.orgUnitName}` : ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Характер работы">{order.natureOfWork}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Объект (№ цеха, участка)">
                      {order.object}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Лица, ответственные за безопасное проведение работ">
                      {(order.safetyResponsibles || [])
                        .map((employee: Employee) =>
                          convertEmployeeToString(employee)
                        )
                        .join(', ')}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Рабочие стропальщики">
                      {(order.riggers || [])
                        .map((employee: Employee) =>
                          convertEmployeeToString(employee)
                        )
                        .join(', ')}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Рабочие люльки">
                      {(order.secondaryRiggers || [])
                        .map((employee: Employee) =>
                          convertEmployeeToString(employee)
                        )
                        .join(', ')}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Наличие проекта производства работ">
                      {order.project}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Сведения о линиях электропередач, воздушной электросети, контактных проводах городского транспорта и др.">
                      {order.powerLinesInfo}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Напряжение (В)">{order.voltage}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Расстояние по горизонтали и вертикали (м)">
                      {order.vhDistance}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Cведения об охранной зоне">
                      {order.safetyZone}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Cведения о наличие разрешения организации, эксплатирующей линию электропередачи">
                      {order.permitInfo}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Cведения о наличии наряда-допуска">
                      {order.admission}
                    </Field>
                  </GridItem>
                </>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Тип ТС">
                  {vehicleTypes[order.vehicleType] ||
                    vehicleGroups[order.vehicleGroup]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Количество пассажиров">
                  {order.workersCount}
                </Field>
              </GridItem>
              {canApproveOrder ? (
                <>
                  <GridItem fullWidth>
                    <VehicleSelection
                      vehicles={vehicles}
                      selected={order.vehicleId}
                      onSelect={this.vehicleChange}
                      editable
                      loading={vehiclesLoading}
                    />
                  </GridItem>
                </>
              ) : (
                order.vehicle && (
                  <GridItem>
                    <Field label="ТС">{order.vehicle.licensePlate}</Field>
                  </GridItem>
                )
              )}
            </Grid>
          </SectionContent>
        </Section>
        {order.withTrailer && (
          <Section>
            <SectionTitle divider>Прицеп</SectionTitle>
            <SectionContent>
              <Grid>
                {canApproveOrder ? (
                  <GridItem fullWidth>
                    <VehicleSelection
                      onlyType={ownerTypes.self}
                      vehicles={trailers}
                      selected={order.trailerId}
                      onSelect={this.trailerChange}
                      loading={vehiclesLoading}
                      editable
                    />
                  </GridItem>
                ) : (
                  !!order.trailer && (
                    <>
                      <GridItem>
                        <Field label="Тип прицепа">
                          {vehicleTypes[order.trailer.vehicleModel.type]}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Марка">
                          {order.trailer.vehicleModel.brandName}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Модель">
                          {order.trailer.vehicleModel.name}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Гос. номер">
                          {order.trailer.licensePlate}
                        </Field>
                      </GridItem>
                    </>
                  )
                )}
              </Grid>
            </SectionContent>
          </Section>
        )}
        {order.hasCargos && order.cargos?.length ? (
          <Section>
            <SectionTitle divider>Грузы</SectionTitle>
            <SectionContent>
              <ListTable
                columns={[
                  {
                    title: 'Наименование',
                    key: 'name',
                  },
                  {
                    title: 'Количество мест, общ',
                    key: 'placeNumber',
                    width: '90px',
                  },
                  {
                    title: 'Общий вес, кг',
                    key: 'totalWeight',
                    width: '90px',
                  },
                  {
                    title: 'Вид упаковки',
                    key: 'packageType',
                  },
                ]}
                data={order.cargos}
              />
            </SectionContent>
          </Section>
        ) : null}
        {order.hasCargos && order.loadCargos?.length ? (
          <Section>
            <SectionTitle divider>Погрузка</SectionTitle>
            <SectionContent>
              <ListTable
                columns={[
                  {
                    title: 'Адрес',
                    key: 'address',
                  },
                  {
                    title: 'Начало',
                    key: 'startDateTime',
                    width: '210px',
                    render: (date: string) =>
                      moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                  },
                  {
                    title: 'Конец',
                    key: 'endDateTime',
                    width: '210px',
                    render: (date: string) =>
                      moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                  },
                  {
                    title: 'Контактные лица',
                    key: 'contactEmployees',
                  },
                ]}
                data={order.loadCargos}
              />
            </SectionContent>
          </Section>
        ) : null}
        {order.hasCargos && order.unloadCargos?.length ? (
          <Section>
            <SectionTitle divider>Выгрузка</SectionTitle>
            <SectionContent>
              <ListTable
                columns={[
                  {
                    title: 'Адрес',
                    key: 'address',
                  },
                  {
                    title: 'Начало',
                    key: 'startDateTime',
                    width: '210px',
                    render: (date: string) =>
                      moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                  },
                  {
                    title: 'Конец',
                    key: 'endDateTime',
                    width: '210px',
                    render: (date: string) =>
                      moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                  },
                  {
                    title: 'Контактные лица',
                    key: 'contactEmployees',
                  },
                ]}
                data={order.unloadCargos}
              />
            </SectionContent>
          </Section>
        ) : null}
        {parseInt(order.contractVehicleOrderId, 10) > 0 && (
          <Section>
            <SectionTitle
              divider={canEditOrder}
              suffix={
                <Operations>
                  {canApproveOrder && (
                    <Button onClick={this.handleContractVehicleOrderPrint}>
                      Печать
                    </Button>
                  )}
                  {canEditOrder && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() =>
                              navigate(
                                `/orders/${order.id}/contract-vehicle-order/${
                                  order.contractVehicleOrderId || ''
                                }`
                              )
                            }
                          >
                            Редактировать
                          </Menu.Item>
                          <Menu.Item>
                            <Popconfirm
                              overlayStyle={{
                                zIndex: 2000,
                              }}
                              placement="left"
                              title="Вы действительно хотите удалить?"
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={this.deleteContractVehicleOrder}
                            >
                              Удалить
                            </Popconfirm>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button style={{ marginLeft: 16 }}>
                        <EllipsisOutlined
                          style={{ fontSize: 16, color: '#2770FF' }}
                        />
                      </Button>
                    </Dropdown>
                  )}
                </Operations>
              }
            >
              Заявка на доп. найм ТС
            </SectionTitle>
            <SectionContent>
              <ContractVehicleOrderCard
                contractVehicleOrderId={order.contractVehicleOrderId}
              />
            </SectionContent>
          </Section>
        )}
        <Section>
          <SectionTitle divider>Маршрут</SectionTitle>
          <SectionContent>
            <WaypointsViewer waypoints={order.route && order.route.waypoints} />
          </SectionContent>
        </Section>
        {this.state.contractVehicleOrder && (
          <Modal
            visible={this.state.showApproveByContractVehicleOrder}
            title="Подтверждение заявки"
            onCancel={this.toggleApproveByContractVehicleOrder}
            footer={[
              <Button
                key="back"
                onClick={this.toggleApproveByContractVehicleOrder}
              >
                Отмена
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.onApproveByContractVehicleOrder}
              >
                Подтвердить
              </Button>,
            ]}
          >
            <Grid cols={2} gutter="16px">
              <GridItem>
                <Field label="Гос. номер">
                  <Input
                    value={this.state.contractVehicleOrder.licensePlate}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.changeContractVehicleOrder('licensePlate', value);
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Водитель">
                  <Input
                    value={this.state.contractVehicleOrder.driver}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.changeContractVehicleOrder('driver', value);
                    }}
                  />
                </Field>
              </GridItem>
            </Grid>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props): Object => ({
  order: state.order,
  employeeId: state.auth.profile.employeeId,
  orderId: props.orderId,
  contractVehicleOrder: state.contractVehicleOrder,
  currentEmployeeId: state.auth.profile.employeeId,
});

export default connect(mapStateToProps, {
  fetchOrder,
  changeStatus,
  updateOrder,
  cleanOrder,
})(withUserAccess(OrderCard));
