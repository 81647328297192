// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import moment from 'moment';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportTaxedTrips,
  ReportTaxedTripsParams,
} from '../../../lib/types/reportTaxedTrips';
import Filter from './components/Filter';
import { reportTaxedTripsApi } from '../../../lib/api';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const initialParams: ReportTaxedTripsParams = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
  vehicleId: undefined,
};

type State = {
  params: ReportTaxedTripsParams,
  data: ReportTaxedTrips[],
  loading: boolean,
  scrollY: number,
};

/**
 * Отчет о таксировках
 */
export class TaxedTripsList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    await this.fetchReportTaxedTrips();
  }

  fetchReportTaxedTrips = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportTaxedTripsApi.fetchReportTaxedTrips(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<ReportTaxedTripsParams>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  applyParams = this.fetchReportTaxedTrips;

  cleanParams = () =>
    this.setState({
      params: initialParams,
    });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await reportTaxedTripsApi.printReportTaxedTrips(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Гос. номер',
      width: 150,
      dataIndex: 'licensePlate',
      fixed: true,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Модель',
      width: 150,
      dataIndex: 'vehicleName',
      fixed: true,
    },
    {
      title: 'Дата путевого листа',
      width: 150,
      dataIndex: 'allocationPeriod',
    },
    {
      title: 'Номер путевого листа',
      width: 150,
      dataIndex: 'idNumber',
      render: (idNumber: ?string) => idNumber,
    },
    {
      title: 'Контроллер технического состояния машины',
      width: 150,
      dataIndex: 'vehicleInspectorFullName',
    },
    {
      title: 'Пробег по СМТ',
      width: 150,
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`,
    },
    {
      title: 'Количество отработанных часов водителем',
      dataIndex: 'workTime',
      width: 150,
      render: (workTime: number) => `${workTime} ч.`,
    },
    {
      title: 'Уровень топлива при выезде',
      dataIndex: 'fuelAtStart',
      width: 150,
      render: (fuelAtStart: number) => `${fuelAtStart.toFixed(2)} л.`,
    },
    {
      title: 'Уровень топлива при заезде',
      dataIndex: 'fuelAtEnd',
      width: 150,
      render: (fuelAtEnd: number) => `${fuelAtEnd.toFixed(2)} л.`,
    },
    {
      title: 'Выдано топлива',
      dataIndex: 'issuedFuel',
      width: 150,
      render: (issuedFuel: number) =>
        issuedFuel && `${issuedFuel.toFixed(2)} л.`,
    },
    {
      title: 'Расход ГСМ',
      dataIndex: 'fuelConsumption',
      width: 150,
      render: (fuelConsumption: number) => `${fuelConsumption.toFixed(2)}, л`,
    },
    {
      title: 'Моточасы транспортного средства',
      dataIndex: 'vehicleMachineHours',
      width: 150,
      render: (vehicleMachineHours: number) => `${vehicleMachineHours || 0}`,
    },
    {
      title: 'На сторону',
      dataIndex: 'toOtherSide',
      width: 150,
      render: (toOtherSide: boolean) => (toOtherSide ? 'Да' : 'Нет'),
    },
  ];

  render() {
    const { data, loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Отчет о таксированных путевых листах</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onChangeFilter={this.handleChangeFilter}
            onExport={this.handleExport}
          />
          <Table
            pagination={false}
            rowKey="vehicleId"
            loading={loading}
            dataSource={data}
            columns={this.columns}
            name="ReportsTaxedTrips"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1800,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default TaxedTripsList;
