import Act from '../../containers/Act';
import { accessTypeEnum } from '../../lib/enum';

//   реестр первичных документов(Акты)
export default [
    {
        component: Act,
        path: '/budget/act/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingExternalActs,
            accessTypeEnum.viewingAllExternalActs,
            accessTypeEnum.handlingExternalActs,
            accessTypeEnum.viewingInternalActs,
            accessTypeEnum.viewingAllInternalActs,
            accessTypeEnum.handlingInternalActs,
        ],
    },
];