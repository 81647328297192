// @flow

import { SelectProps } from 'antd/lib/select';
import React, { type Node } from 'react';
import { convertVehicleToString } from '../../lib/helpers';
import { vehicleApi } from './../../lib/api';

import type { OwnerType, Vehicle } from './../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & {
  // вывод имени в Option
  optionTitle?: (vehicle: Vehicle) => Node,
  filter?: {
    ownerType?: OwnerType
  },
  disabledValues?: number[]
};

const defaultOptionName = (vehicle: Vehicle) => convertVehicleToString(vehicle);

export default class extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.value !== this.props.value) {
      return true;
    }
    return false;
  }

  render() {
    const {
      optionTitle = defaultOptionName,
      filter = {},
      disabledValues = [],
      ...selectProps
    } = this.props;
    return (
      <AutocompleteSelect
        placeholder="Выберите ТС"
        fetch={async params =>
          await vehicleApi.fetchVehicles({ ...params, ...filter })
        }
        refetchParams={{ filter }}
        fetchSingle={vehicleApi.fetchVehicle}
        notFoundText="ТС не найдены"
        renderOption={(vehicle: Vehicle, Option) => (
          <Option
            key={vehicle.id}
            value={vehicle.id}
            vehicle={vehicle}
            disabled={disabledValues.includes(vehicle.id)}
          >
            {optionTitle(vehicle)}
          </Option>
        )}
        {...selectProps}
      />
    );
  }
}
