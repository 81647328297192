// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { Card } from './../../components';
import {
  printNotification,
  saveNotification,
} from '../../lib/notificationWrapper';
import { tripDrivingQualityApi } from './../../lib/api';
import type { WialonTrip, UserAccess, Trip } from '../../lib/types';
import { selectWialonTrip } from '../../ducks/selectors';
import {
  cleanTrip,
  fetchTrip,
  updateTrip,
  updateWialon,
} from '../../ducks/wialonTrip';
import { vehicleTypes, accessTypeEnum } from '../../lib/enum';
import type { AppState } from '../../ducks/redux';
import {
  formatDateTimeToString,
  convertDriverToString,
  applyMaskToValue,
  minus,
  plus,
} from '../../lib/helpers';
import { Panel, Section, SectionTitle } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import { withUserAccess } from './../withUserAccess';
import Header from './../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import { getQualityColor } from './List';

const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;
const MarginLeftDiv = styled.div`
  margin-left: 16px;
`;
const StyledPanel = styled(Panel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
`;

type Props = {
  wialonTrip: WialonTrip,
  fetchTrip: Function,
  tripId: string,
  cleanTrip: () => void,
  updateTrip: Function,
  updateWialon: Function,
  userAccess: UserAccess[],
};

type State = {
  showSelectDriver: boolean,
  wialonTrip: WialonTrip,
  touched: boolean,
  expectedRouteGeometry: any,
  loading: boolean,
};

export class TripCard extends Component<Props, State> {
  state = {
    showSelectDriver: false,
    wialonTrip: this.props.wialonTrip,
    touched: false,
    expectedRouteGeometry: null,
    loading: false,
  };

  async componentDidMount() {
    try {
      const { tripId } = this.props;
      await this.props.cleanTrip();
      if (tripId) {
        await this.props.fetchTrip(tripId);
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.wialonTrip, this.props.wialonTrip)) {
      this.setState({
        wialonTrip: this.props.wialonTrip,
      });
    }
  }

  handlePrint = async () => {
    printNotification(
      async () => await tripDrivingQualityApi.print(parseInt(this.props.tripId))
    );
  };

  getHeaderTitle = function (trip: Trip) {
    return `Путевой лист №${trip?.idNumber || ''}`;
  };

  updateWialon = () => {
    saveNotification(async () => {
      try {
        const { wialonTrip } = this.props;
        await this.props.updateWialon(wialonTrip.id, {
          vehicleId: wialonTrip.vehicleId,
          startDate: wialonTrip.actualTimeAtStart,
          endDate: wialonTrip.actualTimeAtEnd,
        });
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message,
        });
      }
    });
  };

  isAdmin = () =>
    this.props.userAccess.some((access) =>
      [accessTypeEnum.admin].includes(access)
    );

  calculateMachineHours = (trip: ?Trip) => {
    let hours = 0;
    for (let i: number = 0; i < (trip?.attachedEquipments?.length ?? 0); i++) {
      hours = plus(
        hours,
        minus(
          trip?.attachedEquipments[i]?.machineHoursAtEnd ?? 0,
          trip?.attachedEquipments[i]?.machineHoursAtStart ?? 0
        )
      );
    }
    return hours?.toLocaleString('ru') ?? 0;
  };

  render() {
    const { wialonTrip } = this.state;
    if (!wialonTrip) {
      return null;
    }

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/wialonTrips">Поездки</Crumb>
            </Breadcrumbs>
          }
          right={
            <>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.isAdmin() && (
                <MarginLeftDiv>
                  <Button onClick={this.updateWialon}>
                    Запросить повторно
                  </Button>
                </MarginLeftDiv>
              )}
            </>
          }
        />
        <StyledPanel>
          <h1>{this.getHeaderTitle(wialonTrip.trip)}</h1>
        </StyledPanel>

        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <Content>
            <Grid cols={3} media={[{ size: 'md', cols: 2 }]}>
              <GridItem>
                <Field inverted label="В распоряжение">
                  {wialonTrip.vehicle?.usingNodeName}
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Дата и время открытия">
                  {formatDateTimeToString(wialonTrip.actualTimeAtStart)}
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Дата и время закрытия">
                  {formatDateTimeToString(wialonTrip.actualTimeAtEnd)}
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Тип ТС">
                  {wialonTrip.vehicle?.vehicleModel?.type
                    ? vehicleTypes[wialonTrip.vehicle.vehicleModel.type]
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Марка и модель ТС">
                  {wialonTrip.vehicle?.vehicleModel
                    ? `${wialonTrip.vehicle?.vehicleModel.brandName} ${wialonTrip.vehicle?.vehicleModel.name}`
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Госномер">
                  {wialonTrip.vehicle
                    ? applyMaskToValue(
                        wialonTrip.vehicle.licensePlate,
                        formatLicensePlateMask
                      )
                    : wialonTrip.licensePlate || '-'}
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle divider>Поездки</SectionTitle>
          <Content>
            <Grid cols={3} media={[{ size: 'md', cols: 2 }]}>
              <GridItem>
                <Field inverted label="Водитель">
                  {convertDriverToString(wialonTrip.driver)}
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Оценка качества вождения">
                  <span
                    style={{ color: getQualityColor(wialonTrip.driverRating) }}
                  >
                    {wialonTrip.driverRating}
                  </span>
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Кол-во нарушений от 20 до 40 км/ч">
                  <span
                    style={{
                      color: wialonTrip.overSpeedFrom20To40 > 0 ? 'red' : null,
                    }}
                  >
                    {wialonTrip.overSpeedFrom20To40}
                  </span>
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Кол-во нарушений от 40 до 60 км/ч">
                  <span
                    style={{
                      color: wialonTrip.overSpeedFrom40To60 > 0 ? 'red' : null,
                    }}
                  >
                    {wialonTrip.overSpeedFrom40To60}
                  </span>
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Кол-во нарушений более 60 км/ч">
                  <span
                    style={{
                      color:
                        wialonTrip.overSpeedGreaterThan60 > 0 ? 'red' : null,
                    }}
                  >
                    {wialonTrip.overSpeedGreaterThan60}
                  </span>
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle divider>Пробег</SectionTitle>
          <Content>
            <Grid cols={3} media={[{ size: 'md', cols: 2 }]}>
              <GridItem>
                <Field inverted label="Пробег по данным ПЛ">
                  {minus(
                    wialonTrip?.trip?.odometerAtEnd ?? 0,
                    wialonTrip?.trip?.odometerAtStart ?? 0
                  ).toLocaleString('ru')}{' '}
                  км
                </Field>
              </GridItem>

              <GridItem>
                <Field inverted label="Время работы оборудования по ПЛ">
                  {this.calculateMachineHours(wialonTrip?.trip)} ч
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Отработано м/ч по ПЛ">
                  {minus(
                    wialonTrip?.trip?.vehicleMachineHoursAtEnd ?? 0,
                    wialonTrip?.trip?.vehicleMachineHoursAtStart ?? 0
                  ).toLocaleString('ru')}{' '}
                  ч
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Отработано м/ч по данным системы">
                  {(wialonTrip?.workHours || 0).toLocaleString('ru')} ч
                </Field>
              </GridItem>

              <GridItem>
                <Field inverted label="Пробег по данным системы">
                  {(wialonTrip.kilometrage || 0).toLocaleString('ru')} км
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle divider>Движение топлива по ПЛ</SectionTitle>
          <Content>
            <Grid cols={3} media={[{ size: 'md', cols: 2 }]}>
              <GridItem>
                <Field inverted label="Кол-во при выезде">
                  {wialonTrip?.trip?.fuelAtStart.toLocaleString('ru')} л
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Выдано">
                  {(wialonTrip?.trip?.issuedFuel || 0).toLocaleString('ru')} л
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Израсходовано">
                  {(
                    wialonTrip?.trip?.distanceFuelConsumption || 0
                  ).toLocaleString('ru')}{' '}
                  л
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Кол-во при заезде">
                  {(wialonTrip?.trip?.fuelAtEnd || 0).toLocaleString('ru')} л
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle divider>
            Движение топлива по данным системы
          </SectionTitle>
          <Content>
            <Grid cols={3} media={[{ size: 'md', cols: 2 }]}>
              <GridItem>
                <Field inverted label="Кол-во при выезде">
                  {wialonTrip.fuelAtStart.toLocaleString('ru')} л
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Выдано">
                  {(wialonTrip.fuelIssued || 0).toLocaleString('ru')} л
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Израсходовано">
                  {(wialonTrip.fuelConsumed || 0).toLocaleString('ru')} л
                </Field>
              </GridItem>
              <GridItem>
                <Field inverted label="Кол-во при заезде">
                  {(wialonTrip.fuelAtEnd || 0).toLocaleString('ru')} л
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    wialonTrip: selectWialonTrip(state),
  }),
  {
    fetchTrip,
    cleanTrip,
    updateTrip,
    updateWialon,
  }
)(withUserAccess(TripCard));
