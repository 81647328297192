export const operatingModeEnum = {
  cot: 'cot',
  brigade: 'brigade',
  passenger: 'passenger'
};

export const operatingModes = {
  cot: 'ОВБ',
  brigade: 'Бригадный',
  passenger: 'Легковой'
};
