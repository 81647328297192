// @flow

import React, { PureComponent } from 'react';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {
  MonthlyWashingPlan,
  ListState,
  Contractor,
  UserAccess,
  MonthlyWashingPlanStatus
} from '../../../lib/types';
import {
  monthlyWashingPlanApi,
  type FetchListParams
} from './../../../lib/api';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
  convertContractorToString
} from './../../../lib/helpers';
import { monthlyWashingPlanStatuses } from './../../../lib/enum';

import { Table } from './../../../components/ui';
import { Section } from './../../../components/layout';
import Header from './../../../components/layout/Header';
import Filter, { type MonthlyWashingPlanFilterParams } from './Filter';
import { notificationLoading } from './../../../components/Notifications';

import { withUserAccess } from './../../withUserAccess';
import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';

import { addPlanAccessRight } from './accessRight';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<MonthlyWashingPlan> & {
  filterPath: string
};

export class MonthlyWashingPlansList extends PureComponent<Props, State> {
  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };

  static defaultProps = {
    location: {}
  };

  async deleteMonthlyWashingPlan(id: number) {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await monthlyWashingPlanApi.deleteMonthlyWashingPlan(id);
      notification.success({
        message: 'Успешное удаление',
        description: `Расчет успешно удален`
      });
      await this.fetchMonthlyWashingPlans(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: 'Номер',
      dataIndex: 'id'
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: MonthlyWashingPlanStatus) =>
        monthlyWashingPlanStatuses[status]
    },
    {
      title: 'Период проведения',
      dataIndex: 'date',
      render: (month: string) => formatDateTimeToString(month, 'MMMM YYYY')
    },
    {
      title: 'Количество ТС',
      dataIndex: 'vehicleCount'
    },
    {
      title: 'Подрядчик',
      dataIndex: 'contractor',
      render: (contractor: Contractor) => convertContractorToString(contractor)
    },
    {
      title: 'Сумма в руб. без НДС',
      dataIndex: 'price',
      render: (price: number) => `${price} руб.`
    }
  ];

  fetchMonthlyWashingPlans = async (
    page: number = 1,
    params: FetchListParams<MonthlyWashingPlanFilterParams> = {}
  ) => {
    try {
      const { filterPath } = this.state;
      const filter = this.props.persistFilters[filterPath] || {};
      this.setState({ loading: true });
      const {
        data,
        totalCount
      } = await monthlyWashingPlanApi.fetchMonthlyWashingPlans({
        ...filter,
        page,
        ...params
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  setPersistFilter = async (values: MonthlyWashingPlanFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async (filter: MonthlyWashingPlanFilterParams = {}) => {
    await this.setPersistFilter(filter);
    await this.fetchMonthlyWashingPlans();
  };

  cleanFilter = this.applyFilter;

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchMonthlyWashingPlans(page);
  }

  handleRowClick = (id: number) =>
    navigate(`/budget/monthly-washing-plans/${id}`);

  canAdd = () =>
    this.props.userAccess.some(access => addPlanAccessRight.includes(access));

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      page,
      pageSize,
      loading,
      filterPath
    } = this.state;
    const canAdd = this.canAdd();
    const filter = this.props.persistFilters[filterPath];

    return (
      <>
        <Header
          left={<h1>План моек</h1>}
          right={
            canAdd && (
              <Link to="/budget/monthly-washing-plans/new">
                <Button type="primary" data-cy="add">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            columns={this.columns}
            data={data.map(model => ({
              ...model,
              key: model.id
            }))}
            fetch={this.fetchMonthlyWashingPlans}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(MonthlyWashingPlansList));
