// @flow
import type { BusinessCalendarForBudget } from './../types';
import CommonApi from './../commonApi';

const convertDataToServer = (
  businessCalendarForBudget: BusinessCalendarForBudget
) => ({
  ...businessCalendarForBudget,
  month: businessCalendarForBudget.month + 1
});

const convertDataFromServer = (
  businessCalendarForBudget: BusinessCalendarForBudget
) => ({
  ...businessCalendarForBudget,
  month: businessCalendarForBudget.month - 1
});

const businessCalendarForBudget = new CommonApi<BusinessCalendarForBudget>(
  'productionCalendarForBudget',
  'Производственный календарь для бюджета НТС',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...businessCalendarForBudget
};
