import { ExpertiseServiceGpmCard } from '../containers/services/expertisesServicesGpm';
import { InspectionGtnCard } from '../containers/services/inspectionsGtn';
import { LicensePlateChangeCard } from '../containers/services/licensePlateChange';
import { MeasuringDeviceCertificationCard } from '../containers/services/measuringDeviceCertification';
import { OsagoCard } from '../containers/services/osago';
import { PassCard } from '../containers/services/pass';
import { PlatonCard } from '../containers/services/platon';
import { VehicleMonitoringCard } from '../containers/services/vehicleMonitoring';
import { WashingCard } from '../containers/services/washings';
import { WashingForm } from '../containers/Washings';
import { RepairOrderCard, RepairOrderForm } from '../containers/ServiceOrders';
import {
    DriverInspectionCard,
    DriverInspectionForm,
    DriverInspectionList,
    ExpertiseServiceGpmForm,
    ExpertiseServiceGpmList,
    InspectionGibddForm,
    InspectionGibddCard,
    InspectionGibddList,
    InspectionGtnForm,
    InspectionGtnList,
    KaskoForm,
    LicensePlateChangeForm,
    LicensePlateChangeList,
    MeasuringDeviceCertificationForm,
    MeasuringDeviceCertificationList,
    OsagoForm,
    OsagoList,
    PassForm,
    PassList,
    PlatonForm,
    PlatonList,
    VehicleMonitoringForm,
    VehicleMonitoringList,
    WashingList,
} from '../containers/services';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: PlatonList,
        path: '/services/platons',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: VehicleMonitoringForm,
        path: '/services/vehicles-monitoring/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: VehicleMonitoringCard,
        path: '/services/vehicles-monitoring/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: VehicleMonitoringForm,
        path: '/services/vehicles-monitoring/:vehicleMonitoringId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: VehicleMonitoringList,
        path: '/services/vehicles-monitoring',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: LicensePlateChangeForm,
        path: '/services/license-plate-changes/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
            accessTypeEnum.newFeaturesDemo,
        ],
    },
    {
        component: LicensePlateChangeCard,
        path: '/services/license-plate-changes/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: LicensePlateChangeForm,
        path: '/services/license-plate-changes/:licensePlateChangeId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: LicensePlateChangeList,
        path: '/services/license-plate-changes',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: MeasuringDeviceCertificationForm,
        path: '/services/measuring-device-certifications/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: MeasuringDeviceCertificationCard,
        path: '/services/measuring-device-certifications/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: MeasuringDeviceCertificationForm,
        path: '/services/measuring-device-certifications/:measuringDeviceCertificationId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: MeasuringDeviceCertificationList,
        path: '/services/measuring-device-certifications',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    //
    {
        component: DriverInspectionForm,
        path: '/services/driver-inspections/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: DriverInspectionCard,
        path: '/services/driver-inspections/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: DriverInspectionForm,
        path: '/services/driver-inspections/:driverInspectionId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: DriverInspectionList,
        path: '/services/driver-inspections',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    // Платон
    {
        component: PlatonForm,
        path: '/services/platons/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: PlatonCard,
        path: '/services/platons/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: PlatonForm,
        path: '/services/platons/:platonId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: PassForm,
        path: '/services/pass/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
            accessTypeEnum.viewingMaintenanceServices,
        ],
    },
    {
        component: PassCard,
        path: '/services/pass/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: PassForm,
        path: '/services/pass/:passId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
            accessTypeEnum.newFeaturesDemo,
        ],
    },
    {
        component: PassList,
        path: '/services/pass',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: OsagoForm,
        path: '/services/osagos/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: OsagoCard,
        path: '/services/osagos/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: OsagoForm,
        path: '/services/osagos/:osagoId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: OsagoList,
        path: '/services/osagos',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGibddForm,
        path: '/services/inspections-gibdd/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGibddCard,
        path: '/services/inspections-gibdd/:inspectionGibddId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGibddForm,
        path: '/services/inspections-gibdd/:inspectionGibddId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGibddList,
        path: '/services/inspections-gibdd',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGtnForm,
        path: '/services/inspections-gtn/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGtnCard,
        path: '/services/inspections-gtn/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGtnForm,
        path: '/services/inspections-gtn/:inspectionGtnId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: InspectionGtnList,
        path: '/services/inspections-gtn',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: ExpertiseServiceGpmForm,
        path: '/services/expertises-services-gpm/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: ExpertiseServiceGpmCard,
        path: '/services/expertises-services-gpm/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: ExpertiseServiceGpmForm,
        path: '/services/expertises-services-gpm/:id/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: ExpertiseServiceGpmList,
        path: '/services/expertises-services-gpm',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: WashingForm,
        path: '/services/washings/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
            accessTypeEnum.newFeaturesDemo,
        ],
    },
    {
        component: WashingForm,
        path: '/services/washings/new/vehicles/:vehicleId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: WashingCard,
        path: '/services/washings/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: WashingForm,
        path: '/services/washings/:washingId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingMaintenanceServices,
            accessTypeEnum.newFeaturesDemo,
        ],
    },
    {
        component: WashingList,
        path: '/services/washings',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
            accessTypeEnum.handlingMaintenanceServices,
        ],
    },
    {
        component: RepairOrderForm,
        path: '/services/repair-order/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
        ],
    },
    {
        component: RepairOrderForm,
        path: '/services/repair-order/edit/:repairOrderId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
        ],
    },
    {
        component: RepairOrderCard,
        path: '/services/repair-order/:repairOrderId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingMaintenanceServices,
            accessTypeEnum.viewingAllMaintenanceServices,
        ],
    },
];