// @flow

import type { OrgUnitNode, Trip, WialonTrip } from '../lib/types';

import { createSelector } from 'reselect';

import { findOrgUnitInTree } from '../lib/helpers';
import type { AppState } from './redux';

export const selectOrgUnits = (state: AppState): OrgUnitNode => state.orgUnits;

export const selectOrgUnit = (orgUnitId: number) =>
  createSelector(selectOrgUnits, (orgUnits: OrgUnitNode) =>
    orgUnits ? findOrgUnitInTree(orgUnits, orgUnitId) : null
  );

export const selectTrip = (state: AppState): ?Trip => state.trip;
export const selectWialonTrip = (state: AppState): ?WialonTrip =>
  state.wialonTrip;
