// @flow

import notification from 'antd/lib/notification';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Card, Spinner } from '../../../../components';
import { Icon } from '../../../../components/ui';
import type { Vehicle } from '../../../../lib/types';
import { Option, Select } from './../../../../components/ant/Select';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import type { Tire } from './../../../../lib/types';
import { seasonTypeTire } from './../../../../lib/enum';
import AttachVehicleButton, {
  type AdditionalData,
} from './../../../Equipment/Tires/components/AttachVehicleButton';

const { Field } = Card;

const StyledSelect = styled(Select)`
  min-width: 200px;
`;
const Wrapper = styled.div`
  padding: 16px;
`;
const AddButton = styled.span`
  cursor: pointer;
  color: #2770ff;
  display: flex;
  & .icon {
    margin-right: 5px;
  }
`;

type Props = {
  onSubmit: Function,
  availableTires: Tire[],
  handleSearch: (serachString: string) => void,
  fetchData: () => void,
  isLoading: boolean,
  vehicle: Vehicle,
};

type State = {
  selectedTire?: ?Tire,
  visibleForm: boolean,
};

class TiresForm extends Component<Props, State> {
  state = {
    selectedTire: null,
    visibleForm: false,
  };

  selectTire = (selectedTireId: number, options: Object) => {
    const { tire } = options.props;
    this.setState({
      selectedTire: tire,
    });
  };

  onAddClick = async (vehicleId: ?number, additionalData: AdditionalData) => {
    const { selectedTire } = this.state;
    if (selectedTire) {
      // $FlowFixMe не хватает параметров
      await this.props.onSubmit({
        ...selectedTire,
        ...additionalData,
      });
      this.setState({
        selectedTire: null,
        visibleForm: false,
      });
    } else {
      notification.error({
        message: 'Необходимо выбрать шины из списка',
      });
    }
  };

  handleAddButtonClick = () => this.setState({ visibleForm: true });

  getOptionLabel = (tire: Tire) =>
    [
      tire.name,
      tire.brand.name,
      seasonTypeTire[tire.seasonType],
      tire.factoryNumber && `Заводской номер: ${tire.factoryNumber}`,
    ]
      .filter((item) => !!item)
      .join(' | ');

  render() {
    const { selectedTire, visibleForm } = this.state;
    const { availableTires, handleSearch, fetchData, isLoading, vehicle } =
      this.props;
    return (
      <Wrapper>
        {visibleForm ? (
          <Grid gutter="16px">
            <GridItem>
              <Field label="Шины">
                <StyledSelect
                  placeholder="Выберите шины"
                  value={selectedTire && selectedTire.id}
                  onChange={this.selectTire}
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  onBlur={() => fetchData()}
                  notFoundContent={
                    <Spinner
                      isLoading={isLoading}
                      notFoundText="Оборудование не найдено"
                    />
                  }
                >
                  {availableTires.map((tire: Tire) => (
                    <Option key={tire.id} value={tire.id} tire={tire}>
                      {this.getOptionLabel(tire)}
                    </Option>
                  ))}
                </StyledSelect>
              </Field>
            </GridItem>
            <GridItem fullWidth>
              <AttachVehicleButton
                tire={selectedTire}
                onConfirm={this.onAddClick}
                vehicle={vehicle}
                disabled={!selectedTire}
                showVehicleSelector={false}
              />
            </GridItem>
          </Grid>
        ) : (
          <AddButton onClick={this.handleAddButtonClick}>
            <Icon type="plus" />
            Добавить шины
          </AddButton>
        )}
      </Wrapper>
    );
  }
}

export default TiresForm;
