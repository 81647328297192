import React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip } from 'react-leaflet';
import { monitoringVehicleStatusesEnum } from '../../../lib/enum';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  & .vehicle-marker {
    outline: none;
  }
`;

export const VehicleMarkerWrapper = styled(
  ({ direction, active, ...props }) => <div {...props} />
)`
  border-radius: 50%;
  background: white;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${props =>
    props.direction &&
    css`
      transform: rotate(${props.direction}deg);
    `};
  ${props =>
    props.active &&
    css`
      width: 26px;
      height: 26px;
    `};
`;

export const VehicleMarkerArrow = styled.div`
  position: absolute;
  transform: translate(0, -19px);
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  &::before {
    content: '';
    display: block;
    width: 0;
    transform: translate(-5px, 1px);
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #2770ff;
  }
`;

export const VehicleMarker = styled(({ direction, status, ...props }) => (
  <VehicleMarkerWrapper active={props.active} direction={direction}>
    <div {...props} />
    {props.active && <VehicleMarkerArrow />}
  </VehicleMarkerWrapper>
))`
  border-radius: 50%;
  width: 6px;
  height: 6px;
  padding: 4px;
  background: white;
  border: 6px solid;
  ${props =>
    props.active &&
    css`
      width: 10px;
      height: 10px;
      border: 8px solid;
      border-color: #2770ff;
      position: relative;
    `};
  ${props =>
    props.status === monitoringVehicleStatusesEnum.active &&
    css`
      border-color: #2770ff;
    `}
  ${props =>
    props.status === monitoringVehicleStatusesEnum.nonactive &&
    css`
      border-color: red;
    `}
  ${props =>
    props.status === monitoringVehicleStatusesEnum.nosignal &&
    css`
      border-color: orange;
    `}
`;

export const BoundaryPoint = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid;
  box-sizing: border-box;
  background: white;
`;

export const StartPoint = styled(BoundaryPoint)`
  border-color: #f2994a;
`;

export const EndPoint = styled(BoundaryPoint)`
  border-color: #27ae60;
`;

export const VehicleTooltip = styled(Tooltip)`
  box-shadow: none;
  font-size: 10px;
  font-family: inherit;
  font-weight: bold;
  border-radius: 5px;
  padding: 9px;
  &::before {
    display: none;
  }
`;
