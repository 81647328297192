// @flow

import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { RangePicker } from '../../components/ui';
import type { FilterChildrenParams } from '../../components/hoc/Filter';
import { InputNumber } from '../../components/inputs';

import { gpmVehicleTypes, orderStatuses } from '../../lib/enum';
import { formatDateTimeToISOString } from '../../lib/helpers';
import type {
  OrderObjective,
  OrderStatus,
  OwnerType,
  VehicleType,
} from '../../lib/types';
import {
  Card,
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
} from './../../components';
import { OrgUnitSelect, Selects } from './../../components/';
import { Option, Select } from './../../components/ant/Select';

const { Field } = Card;
const {
  OrderTypeDispatcherSelect,
  VehicleTypeSelect,
  OrderObjectiveSelect,
  YesNoSelect,
  VehicleSelect,
  VehicleOwnerTypeSelect,
} = Selects;

const StyledContent = styled.div`
  padding: 16px;
`;

export type OrderFilterParams = {
  id?: number,
  status?: OrderStatus,
  isArchive?: boolean,
  vehicleType?: VehicleType[],
  objective?: OrderObjective,
  orgUnitId?: number,
  startDate?: string,
  endDate?: string,
  isGpm?: boolean,
  getBranch?: boolean,
  isBusinessTrip?: boolean,
  'vehicle.id'?: number,
  'vehicle.ownerType'?: OwnerType,
};

export type OrdersType = 'income' | 'archive';

type Props = {
  filter: OrderFilterParams,
  applyFilter: Function,
  cleanFilter: Function,
  mode: OrdersType,
  handlingOrder: boolean,
};

const getStatuses = (mode: OrdersType) => {
  switch (mode) {
    case 'income':
      return ['created', 'approvingByMainEngineer', 'approvedByMainEngineer'];
    case 'archive':
      return ['approved', 'cancelled'];
    default:
      return [
        'created',
        'approvingByMainEngineer',
        'approvedByMainEngineer',
        'approved',
        'cancelled',
      ];
  }
};

export const OrderFilter = ({
  filter,
  applyFilter,
  cleanFilter,
  mode,
  handlingOrder,
}: Props) => (
  <StyledContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        additionalFilter={true}
      >
        {({
          values,
          showAdditional,
          changeValue,
          applyFilter,
          handleAdditional,
          cleanFilter,
        }: FilterChildrenParams<OrderFilterParams>) => (
          <>
            {handlingOrder && (
              <FilterItem>
                <Field mBottomNone>
                  <OrderTypeDispatcherSelect
                    size="small"
                    value={values.getBranch ? 'all' : 'self'}
                    onChange={(value: string) =>
                      changeValue('getBranch', value === 'all')
                    }
                  />
                </Field>
              </FilterItem>
            )}
            <FilterItem>
              <Field mBottomNone>
                <InputNumber
                  style={{ width: '100%' }}
                  size="small"
                  placeholder="Номер заявки"
                  onChange={(value) => changeValue('id', value)}
                  value={values.id}
                  min={1}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  startDate={
                    values.startDate ? moment.utc(values.startDate) : null
                  }
                  endDate={values.endDate ? moment.utc(values.endDate) : null}
                  onChange={changeValue}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  placeholder="Все службы"
                  onChange={(value) => changeValue('orgUnitId', value)}
                  value={values.orgUnitId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  size="small"
                  placeholder="Все статусы"
                  value={values.status}
                  onChange={(value: string) => changeValue('status', value)}
                >
                  {getStatuses(mode).map((key: string) => (
                    <Option key={key} value={key}>
                      {orderStatuses[key]}
                    </Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  placeholder="Все ТС"
                  size="small"
                  value={values['vehicle.id']}
                  onChange={(id: number) => changeValue('vehicle.id', id)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleOwnerTypeSelect
                  placeholder="Собственные и наемные ТС"
                  size="small"
                  value={values['vehicle.ownerType']}
                  onChange={(id: number) =>
                    changeValue('vehicle.ownerType', id)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  size="small"
                  placeholder="Все типы ТС"
                  value={values.vehicleType}
                  selectableGroup
                  onlyTypes={values.isGpm && gpmVehicleTypes}
                  onChange={(value) => changeValue('vehicleType', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrderObjectiveSelect
                  size="small"
                  placeholder="Цель поездки"
                  value={values.objective}
                  onChange={(value) => changeValue('objective', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Укажите командировку"
                  optionText="Командировка"
                  size="small"
                  reset
                  fullWidth
                  value={values.isBusinessTrip}
                  onChange={(value: boolean) =>
                    changeValue('isBusinessTrip', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  size="small"
                  placeholder="Укажите ГПМ"
                  optionText="ГПМ"
                  reset
                  fullWidth
                  value={values.isGpm}
                  onChange={(value: boolean) => changeValue('isGpm', value)}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </StyledContent>
);

export default OrderFilter;
