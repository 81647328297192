// @flow
import queryString from 'query-string';
import type { Washing, ExecutionBudgetGroupedList } from './../types';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

const convertDataToServer = (washing: Washing) => ({
  ...washing,
  paymentAmount: convertFromRubToKop(washing.paymentAmount)
});

const convertDataFromServer = (washing: Washing) => ({
  ...washing,
  act: washing.act ? actConvertDataFromServer(washing.act) : undefined,
  paymentAmount: convertFromKopToRub(washing.paymentAmount)
});

export const addWashing = async (washing: Washing): Promise<Washing> => {
  const added = await fetchRequest.post(
    '/washing',
    convertDataToServer(washing)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать мойку');
};

export const updateWashing = async (washing: Washing): Promise<Washing> => {
  const updated = await fetchRequest.put(
    '/washing',
    convertDataToServer(washing)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить мойку');
};

export const deleteWashing = async (id: number): Promise<Washing> => {
  const deleted = await fetchRequest.delete(`/washing/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить мойку');
};

export const fetchWashing = async (
  id: number,
  params: any = {}
): Promise<Washing> => {
  const washing = await fetchRequest.get(`/washing/${id}`, params);
  if (washing) return convertDataFromServer(washing);
  throw new Error('Не удалось получить мойку');
};

export const fetchAllWashings = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Washing>> => {
  const washings = await fetchRequest.get('/washing', {
    ...initialFetchListParams,
    ...params
  });
  if (washings)
    return {
      ...washings,
      data: washings.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось получить список моек');
};

export const fetchWashings = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Washing>> => {
  const washings = await fetchRequest.get(`/washing/vehicle/${vehicleId}`, {
    ...initialFetchListParams,
    ...params
  });
  if (washings)
    return {
      ...washings,
      data: washings.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось список моек');
};

export const printTicket = async (washingId: number): Promise<boolean> => {
  const printed = await downloadRequestWithToken(`/washing/${washingId}/print`);

  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<Washing>> => {
  const washing = await fetchRequest.put(
    `/washing/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (washing) {
    return {
      ...washing,
      data: washing.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за списоком моек');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/washing/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(`/washing/groupedList`, params);
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export default {
  groupedList,
  getTotalSum,
  updateAct,
  addWashing,
  updateWashing,
  deleteWashing,
  fetchWashing,
  fetchWashings,
  fetchAllWashings,
  printTicket
};
