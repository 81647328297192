// @flow

import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { maintenanceOperationGroupApi } from '../../lib/api';
import type { MaintenanceOperationGroup } from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

export default (selectProps: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите виды работ"
    fetch={async params =>
      await maintenanceOperationGroupApi.fetchMaintenanceOperationGroups({
        ...params
      })
    }
    fetchSingle={maintenanceOperationGroupApi.fetchMaintenanceOperationGroup}
    notFoundText="Виды работ не найдены"
    renderOption={(maintenanceOperation: MaintenanceOperationGroup, Option) => (
      <Option
        key={maintenanceOperation.id}
        value={maintenanceOperation.id}
        maintenanceoperation={maintenanceOperation}
      >
        {maintenanceOperation.name}
      </Option>
    )}
    {...selectProps}
  />
);
