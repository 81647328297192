// @flow
import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import moment from 'moment';

import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { DatePicker } from '../../../components/ant/DatePicker';

import type { VehicleTariff } from '../../../lib/types';

import { Selects, Form } from '../../../components';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import CancelButton from '../../../components/CancelButton';
import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import { serviceTypeEnum, vehicleTariffEnum } from '../../../lib/enum';
import type { VehicleTariffType } from '../../../lib/types';

const {
  RateTypeSelect,
  ContractorSelect,
  OperatingModeSelect,
  TariffTypeSelect,
  YearSelect,
  YesNoSelect
} = Selects;

export const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  vehicleTariff: ?VehicleTariff,
  vehicleTariffType: VehicleTariffType,
  onCancel: Function,
  onSubmit: Function
};

export default ({
  vehicleTariff,
  vehicleTariffType,
  onCancel,
  onSubmit
}: Props) => {
  return (
    <Form initialValues={vehicleTariff} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px">
              <GridItem style={{ overflow: 'hidden' }}>
                {vehicleTariffType === vehicleTariffEnum.byBrand ? (
                  <FormField
                    required
                    fast
                    label="Модель ТС"
                    name="vehicleModelId"
                  >
                    {({ value, name }) => (
                      <VehicleModelSelect
                        value={value}
                        name={name}
                        filter={{
                          returnDeleted: false
                        }}
                        data-cy="vehicleModelSelect"
                        onBlur={() => handleBlur({ target: { name } })}
                        onChange={async (value: number) =>
                          setFieldValue(name, value)
                        }
                      />
                    )}
                  </FormField>
                ) : (
                  <FormField
                    label="Тип ТС «Тарификатор»"
                    fast
                    required
                    name="vehicleTypeTariff"
                  >
                    {field => <StyledInput {...field} />}
                  </FormField>
                )}
              </GridItem>
              <GridItem>
                <FormField
                  label="Год выпуска"
                  fast
                  required
                  name="productionYear"
                >
                  {({ name, value }) => (
                    <YearSelect
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField required label="Подрядчик" name="contractorId">
                  {({ name, value }) => (
                    <ContractorSelect
                      name={name}
                      value={value}
                      onBlur={() =>
                        handleBlur({
                          target: { name }
                        })
                      }
                      filter={{
                        services: serviceTypeEnum.contractVehicles,
                        returnDeleted: false
                      }}
                      onChange={id => setFieldValue(name, id)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Версия тарифа" fast required name="version">
                  {({ name, value }) => (
                    <RateTypeSelect
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тип тарифа" fast required name="tariffType">
                  {({ name, value }) => (
                    <TariffTypeSelect
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Режим эксплуатации"
                  fast
                  required
                  name="operatingMode"
                >
                  {({ name, value }) => (
                    <OperatingModeSelect
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Основания для тарифа"
                  fast
                  required
                  name="tariffBases"
                >
                  {field => <StyledInput {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Стоимость топлива за 1л(руб)"
                  fast
                  required
                  name="oilCost"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Начало действия тарифа"
                  name="startDate"
                  required
                >
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Окончание действия тарифа" name="endDate">
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={values.startDate ? false : true}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Тариф (руб.) за 1 час эксплуатации постоянная часть"
                  fast
                  required
                  name="tariffFixed"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Тариф (руб.) за 1 км пробега переменная часть"
                  fast
                  required
                  name="tariffDynamic"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Норма списания на 1 час прогрева (л/час)"
                  fast
                  required
                  name="warmingNorm"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Лизинг" fast name="leasing">
                  {({ name, value }) => (
                    <YesNoSelect
                      fullWidth={true}
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Установлено ГБО" fast name="hasGas">
                  {({ name, value }) => (
                    <YesNoSelect
                      fullWidth={true}
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {values.id ? 'Сохранить' : 'Создать'}
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
};
