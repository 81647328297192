// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { OverhaulType } from './../../lib/types';
import { overhaulTypeApi } from '../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

export default (selectProps: SelectProps & { filter?: any }) => (
  <AutocompleteSelect
    placeholder="Выберите вид работ"
    fetch={async params => {
      return await overhaulTypeApi.fetch({
        ...selectProps.filter,
        ...params
      });
    }}
    fetchSingle={overhaulTypeApi.get}
    notFoundText="Работы не найдены"
    renderOption={(overhaulType: OverhaulType, Option) => (
      <Option key={overhaulType.id} value={overhaulType.id}>
        {`${overhaulType.vehicleModelName} ${overhaulType.vehicleIssueGroupName}`}
      </Option>
    )}
    {...selectProps}
  />
);
