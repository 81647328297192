// @flow
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import type { BranchBudgetSummaryMonth } from '../types';
import { fetchRequest } from './index';
import CommonApi from './../commonApi';

const convertDataFromServer = (month: BranchBudgetSummaryMonth) => {
  return {
    ...month,
    economy: convertFromKopToRub(month.economy),
    economyResidue: convertFromKopToRub(month.economyResidue),
    plan: convertFromKopToRub(month.plan),
    need: convertFromKopToRub(month.need),
    fact: convertFromKopToRub(month.fact)
  };
};

const convertDataToServer = (month: BranchBudgetSummaryMonth) => {
  return {
    ...month,
    economy: convertFromRubToKop(month.economy),
    economyResidue: convertFromRubToKop(month.economyResidue),
    plan: convertFromRubToKop(month.plan),
    need: convertFromRubToKop(month.need),
    fact: convertFromRubToKop(month.fact)
  };
};

const covertDataWriteOffToServer = (data: any) => {
  return {
    ...data,
    writeOff: convertFromRubToKop(data.writeOff)
  };
};

const baseUrl = 'branchBudgetSummaryMonth';
const branchBudgetSummaryMonth = new CommonApi<BranchBudgetSummaryMonth>(
  baseUrl,
  'Бюджет свод',
  convertDataToServer,
  convertDataFromServer
);

const writeOff = async (data: any): Promise<any> => {
  const updated = await fetchRequest.put(
    `/${baseUrl}/writeOff`,
    covertDataWriteOffToServer(data)
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить');
};

export default { ...branchBudgetSummaryMonth, writeOff };
