// @flow
import React from 'react';
import styled from 'styled-components';

import Divider from 'antd/lib/divider';

import type { Act } from './../../../../lib/types';
import { formatDateTimeToString } from './../../../../lib/helpers';
import {
  serviceTypes,
  contractDocumentTypeEnum,
  measureType
} from './../../../../lib/enum';

import { Card } from './../../../../components';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import { Section, SectionTitle } from './../../../../components/layout';

const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;

type Props = {
  act: Act
};

export default ({ act }: Props) => (
  <>
    <Section>
      <SectionTitle divider>Акт</SectionTitle>
      <Content>
        <Grid gutter="16px" cols={4}>
          <GridItem>
            <Field
              label={`Номер ${
                act.contract.documentType === contractDocumentTypeEnum.contract
                  ? 'договора'
                  : 'лицевого  счета'
              }`}
            >
              {act.contract.documentType === contractDocumentTypeEnum.contract
                ? act.contract.documentNumber
                : act.contract.accountNumber}
            </Field>
          </GridItem>
          {act.contract && act.contract.registrationDate && (
            <GridItem>
              <Field label={`Дата договора`}>
                {`${formatDateTimeToString(
                  act.contract.registrationDate,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            </GridItem>
          )}
          {act.contract.contractor && act.contract.contractor.company && (
            <GridItem>
              <Field label="Контрагент">
                {act.contract.contractor.company.name}
              </Field>
            </GridItem>
          )}
          <GridItem>
            <Field label="Вид услуги">
              {serviceTypes[act.contract.services]}
            </Field>
          </GridItem>
          {act.stage.expenseDirection && (
            <GridItem>
              <Field label="Направление расходов">
                {act.stage.expenseDirection.name}
              </Field>
            </GridItem>
          )}
          <GridItem>
            <Field label="Номер л/с">{act.stage.accountNumber}</Field>
          </GridItem>
        </Grid>

        <Divider />

        <Grid gutter="16px" cols={4}>
          <GridItem>
            <Field label="Номер акта">{act.actNumber}</Field>
          </GridItem>
          <GridItem>
            <Field label="Дата акта">
              {`${formatDateTimeToString(act.actDate, 'DD.MM.YYYY')}`}
            </Field>
          </GridItem>
        </Grid>
      </Content>
    </Section>
    {act.incomeSchedule && (
      <>
        <Section>
          <SectionTitle divider>График акта</SectionTitle>
          <Content>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <Field label="Период оказания">
                  {`${formatDateTimeToString(
                    act.incomeSchedule.startDate,
                    'DD.MM.YYYY'
                  )} - ${formatDateTimeToString(
                    act.incomeSchedule && act.incomeSchedule.endDate,
                    'DD.MM.YYYY'
                  )}`}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Единица измерения">
                  {act.incomeSchedule &&
                    measureType[act.incomeSchedule.measureType]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Количество">
                  {act.incomeSchedule && act.incomeSchedule.count}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Сумма(без НДС)">
                  {act.incomeSchedule &&
                    act.incomeSchedule.sum.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: 'RUB'
                    })}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Сумма(c НДС)">
                  {act.incomeSchedule &&
                    act.incomeSchedule.sumWithTax.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: 'RUB'
                    })}
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>
      </>
    )}
  </>
);
