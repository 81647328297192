import {
    ExpertiseServiceGpmCostCard,
    ExpertiseServiceGpmCostForm,
    ExpertiseServiceGpmCostList,
} from '../../containers/ExpertiseServiceGpmCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: ExpertiseServiceGpmCostList,
        path: '/admin/expertise-service-gpm-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: ExpertiseServiceGpmCostCard,
        path: '/admin/expertise-service-gpm-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: ExpertiseServiceGpmCostForm,
        path: '/admin/expertise-service-gpm-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: ExpertiseServiceGpmCostForm,
        path: '/admin/expertise-service-gpm-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];