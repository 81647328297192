// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';

import { ListTable } from './../../../../../../components/ui';
import type {
  Regulation,
  RegulationLimitGroup,
  RegulationLimitType,
  UserAccess,
} from './../../../../../../lib/types';
import RegulationLimitGroupRow from './components/RegulationLimitGroupRow';
import {
  operationLimitGroupStatusEnum,
  regulationLimitTypeEnum,
} from './../../../../../../lib/enum';
import {
  AddButton,
  ListTableHeader,
  ListTableOvbHeader,
  StatusButton,
} from './components/elements';
import { regulationLimitGroupApi } from './../../../../../../lib/api';
import Spinner from './../../../../../../components/Spinner';
import GroupModal from './components/GroupModal';
import { Cell, Row } from './../../elements';
import { notificationLoading } from './../../../../../../components/Notifications';
import type { RegulationLimitGroupStatus } from './../../../../../../lib/types/regulationLimitGroup';
import { canApprove, canHandling, canSendToAgreeing } from './../../lib';
import type { AppState } from './../../../../../../ducks/redux';
import { Buttons } from '../../../../../ContractVehicleWorkPlans/TimeLimit/components/elements';
import isEmpty from 'lodash/isEmpty';

type Props = {
  regulation: Regulation,
  type: RegulationLimitType,
  userAccess: UserAccess[],
  readonly: boolean,
  statusChangeCallback: Function, //fucking dirty hack
};

type State = {
  groups: RegulationLimitGroup[],
  newGroup: $Shape<RegulationLimitGroup>,
  modalVisible: boolean,
  loading: boolean,
};

class RegulationLimitGroups extends React.Component<Props, State> {
  state = {
    groups: [],
    newGroup: {
      type: this.props.type,
      orgUnitId: this.props.regulation.orgUnitId,
      regulationId: this.props.regulation.id,
    },
    modalVisible: false,
    loading: false,
  };

  async componentDidMount() {
    await this.fetchGroups();
    this.checkStatus(this.state);
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.regulation.orgUnitId !== prevProps.regulation.orgUnitId) {
      await this.fetchGroups();
      this.setState(({ newGroup }) => ({
        newGroup: {
          ...newGroup,
          orgUnitId: this.props.regulation.orgUnitId,
          regulationId: this.props.regulation.id,
        },
      }));
    }

    this.checkStatus(this.state, prevState);
  }

  checkStatus = (state: State, prevState?: State) => {
    if (state.groups[0] && prevState?.groups[0]) {
      if (
        state.groups[0].timeLimitStatus !== prevState.groups[0].timeLimitStatus
      ) {
        this.props.statusChangeCallback({
          status: state.groups[0].timeLimitStatus,
          type: state.groups[0].type,
        });
      }
    } else if (!prevState && state.groups[0]) {
      this.props.statusChangeCallback(
        {
          status: state.groups[0].timeLimitStatus,
          type: state.groups[0].type,
        },
        true
      );
    }
  };

  handleSaveOnlyGroup = async (group: RegulationLimitGroup) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (group.id) {
        await regulationLimitGroupApi.update(group);
      } else {
        await regulationLimitGroupApi.add(group);
      }
      this.setState({ modalVisible: false });
      await this.fetchGroups();
      await this.changeGroupsStatus(
        operationLimitGroupStatusEnum.draft,
        operationLimitGroupStatusEnum.draft
      );
      notification.success({
        message: 'Группа была успешно сохранена.',
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  changeGroupsStatus = async (
    status: RegulationLimitGroupStatus,
    assignmentLimitStatus?: RegulationLimitGroupStatus
  ) => {
    const { groups } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const updatedGroups = groups.map<RegulationLimitGroup>((group) => ({
        ...group,
        timeLimitStatus: status,
        assignmentLimitStatus:
          assignmentLimitStatus || group.assignmentLimitStatus,
      }));
      await Promise.all(
        updatedGroups.map(
          async (group) => await regulationLimitGroupApi.update(group)
        )
      );
      this.setState({ groups: updatedGroups });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  getGroupsStatus = () => {
    const { groups } = this.state;
    return this.hasEmptyGroups() ? undefined : groups[0].timeLimitStatus;
  };

  toggleModalVisible = () =>
    this.setState((prevState: State) => ({
      modalVisible: !prevState.modalVisible,
    }));

  fetchGroups = async () => {
    try {
      const { type, regulation } = this.props;
      this.setState({ loading: true });
      const { data: groups } = await regulationLimitGroupApi.fetch({
        type,
        orgUnitId: regulation.orgUnitId,
        regulationId: regulation.id,
        pageSize: 0,
        includeRegulationLimit: true,
      });
      this.setState({ groups, loading: false });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  setGroup = (index: number, group: RegulationLimitGroup) => {
    const groups = [...this.state.groups];
    groups.splice(index, 1, group);
    this.setState({ groups });
  };

  handleDeleteGroup = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await regulationLimitGroupApi.delete(id);
      await this.fetchGroups();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  getTypeForNotification = () => {
    switch (this.props.type) {
      case 'employee':
        return 'должностных групп';
      case 'orgUnit':
        return 'служб';
      case 'ovb':
        return 'ОВБ';
      case 'oneOffRequests':
        return 'разовых заявок';
      default:
        return '';
    }
  };

  handleApprove = async () => {
    await this.changeGroupsStatus(operationLimitGroupStatusEnum.approved);
    notification.success({
      message: `Регламент ${this.getTypeForNotification()} был утвержден`,
    });
  };

  handleSendToAgreeing = async () => {
    await this.changeGroupsStatus(operationLimitGroupStatusEnum.onAgreeing);
    notification.success({
      message: `Регламент ${this.getTypeForNotification()} был отправлен на согласование`,
    });
  };

  /**
   * Проверка на наличие незаполненных полей в лимитах групп
   */
  hasEmptyGroups = () => {
    const { groups } = this.state;
    return (
      groups.length === 0 ||
      groups.some((group) => {
        return isEmpty(group.regulationLimits);
      })
    );
  };

  render() {
    const { type, readonly, userAccess, regulation } = this.props;
    const { loading, groups, modalVisible, newGroup } = this.state;
    const canApproving = canApprove(userAccess, this.getGroupsStatus());
    const canHandle = canHandling(userAccess);
    const canSendToAgree = canSendToAgreeing(
      userAccess,
      this.getGroupsStatus()
    );
    const hasEmptyGroups = this.hasEmptyGroups();
    return (
      <Spinner isLoading={loading}>
        <ListTable
          data={groups}
          renderRow={(group: RegulationLimitGroup, index: number) => {
            return (
              <RegulationLimitGroupRow
                group={group}
                setGroup={this.setGroup}
                type={type}
                key={group.id}
                index={index}
                editable={canHandle && !readonly}
                saveGroup={this.handleSaveOnlyGroup}
                orgUnitId={regulation.orgUnitId}
                onDelete={this.handleDeleteGroup}
              />
            );
          }}
          header={
            type === regulationLimitTypeEnum.ovb ? (
              <ListTableOvbHeader />
            ) : (
              <ListTableHeader type={type} />
            )
          }
        >
          {({ content }) => (
            <>
              {content}
              {canHandle && !readonly && (
                <Row
                  style={{
                    borderTop: '1px solid #E4EBF2',
                  }}
                >
                  <Cell>
                    {
                      <AddButton onClick={this.toggleModalVisible}>
                        Добавить новую группу
                      </AddButton>
                    }
                  </Cell>
                  <Cell>
                    <Buttons>
                      {(hasEmptyGroups || canSendToAgree) && (
                        <StatusButton
                          withWarn={hasEmptyGroups}
                          onClick={this.handleSendToAgreeing}
                        >
                          На утверждение
                        </StatusButton>
                      )}
                      {canApproving && (
                        <StatusButton
                          withWarn={hasEmptyGroups}
                          onClick={this.handleApprove}
                        >
                          Утвердить
                        </StatusButton>
                      )}
                    </Buttons>
                  </Cell>
                </Row>
              )}
            </>
          )}
        </ListTable>
        <GroupModal
          group={newGroup}
          afterClose={() => {
            this.setState({
              newGroup: {
                type,
                orgUnitId: regulation.orgUnitId,
                regulationId: regulation.id,
              },
            });
          }}
          toggleModal={this.toggleModalVisible}
          visible={modalVisible}
          saveGroup={this.handleSaveOnlyGroup}
        />
      </Spinner>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  userAccess: state.auth.profile.access,
}))(RegulationLimitGroups);
