// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import type { Company } from '../../lib/types/company';
import {
  fetchCompany,
  cleanCompany,
  updateCompany,
  addCompany
} from '../../ducks/company';
import type { AppState } from '../../ducks/redux';
import { Panel } from './../../components/layout';
import InnerForm from './components/InnerForm';
import Header from '../../components/layout/Header';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  company: Company,
  fetchCompany: (id: number) => Promise<void>,
  updateCompany: (company: Company) => Promise<void>,
  addCompany: Function,
  cleanCompany: Function,
  companyId: number
};

export class Form extends Component<Props> {
  async componentDidMount() {
    const { companyId } = this.props;
    await this.props.cleanCompany();
    if (companyId) {
      try {
        await this.props.fetchCompany(companyId);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/admin/companies');
      }
    }
  }

  onCancel = () => {
    navigate('/admin/companies');
  };

  onSubmit = async (company: Company) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (company.id) {
        await this.props.updateCompany(company);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        await this.props.addCompany(company);
        notification.success({
          message: 'Успешное добавление',
          description: `Новый контрагент успешно добавлен`
        });
      }
      navigate('/admin/companies');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { company } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/companies">Контрагент</Crumb>
              {company ? (
                <>
                  <Crumb to={`/admin/companies/${company.id}`}>
                    {company.name}
                  </Crumb>
                </>
              ) : (
                <Crumb to="/admin/companies/new">Новый контрагент</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{company ? `Контрагент №${company.id}` : 'Новый контрагент'}</h1>
        </StyledPanel>
        <InnerForm
          company={company}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    company: state.company,
    companyId: parseInt(props.companyId, 10)
  }),
  {
    fetchCompany,
    updateCompany,
    cleanCompany,
    addCompany
  }
)(Form);
