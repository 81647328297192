//@flow

import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterContainer,
  FilterItem,
  FilterButtonsContainer
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import Input from 'antd/lib/input';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

export type FuelPriceParamsFilter = {
  modelUat?: string,
  modelAutosoft?: string,
  operationName?: string,
  operationCode?: string
};

type Props = {
  filter: FuelPriceParamsFilter,
  cleanFilter: Function,
  applyFilter: Function
};

export const OperationsFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<FuelPriceParamsFilter>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <StyledInput
                  size="small"
                  placeholder="Модель УАТ"
                  onChange={({ target: { value } }) =>
                    changeValue('modelUat', value)
                  }
                  value={values.modelUat}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <StyledInput
                  size="small"
                  placeholder="Модель Автософт"
                  onChange={({ target: { value } }) =>
                    changeValue('modelAutosoft', value)
                  }
                  value={values.modelAutosoft}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <StyledInput
                  size="small"
                  placeholder="Наименование работ"
                  onChange={({ target: { value } }) =>
                    changeValue('operationName', value)
                  }
                  value={values.operationName}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <StyledInput
                  size="small"
                  placeholder="Id работы"
                  onChange={({ target: { value } }) =>
                    changeValue('operationCode', value)
                  }
                  value={values.operationCode}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default OperationsFilter;
