// @flow
import styled from 'styled-components';

import { SectionTitle } from '../../../components/layout';

export default styled(SectionTitle)`
  & .section__header {
    padding: 19px 16px;
  }
`;
