// @flow

import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import React from 'react';
import { GeoJSON, Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import { MAPBOX_API_KEY } from '../../lib/gis';

import type { WayPoint } from '../../lib/types/index';
import { Icon } from '../ui';
import { sortWaypoints } from './../Waypoints/lib';

const LeafletBox = { height: 400, width: 750 }; // для лифлета нужна явная высота

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

type Props = {
  geoJSON: any,
  waypoints: WayPoint[]
};

type State = {
  modalVisible: boolean
};

// Компонент рисует кнопку, по нажатию которую открывается модалка с картой,
// где отображается переданная геометрия в формате GeoJSON(те чаще всего - маршрут)
// и массив вейпойнтов, который будет показан как маркеры
//
// TODO: прикрутить правильный bounding box, который передает гугл - те правильно масштабировать карту
// чтобы сразу маршрут помещался
class TripMap extends React.Component<Props, State> {
  state = {
    modalVisible: false
  };

  render() {
    return (
      <div>
        <Button onClick={() => this.setState({ modalVisible: true })}>
          <StyledIcon type="map" size={16} /> Показать на карте
        </Button>
        <Modal
          title="Предполагаемый маршрут"
          visible={this.state.modalVisible}
          onOk={() => this.setState({ modalVisible: false })}
          onCancel={() => {
            this.setState({ modalVisible: false });
          }}
          height={800}
          width={800}
        >
          <ModalContent>
            <div className="leaflet-container" style={LeafletBox}>
              <Map
                geoJSON={this.props.geoJSON}
                waypoints={sortWaypoints(this.props.waypoints)}
              />
            </div>
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

/*
  Компонент врапает лифлетовскую карту
 */
const Map = ({ geoJSON, waypoints }) => {
  const center = [55.791278, 49.115734];

  // key нужно передавать GeoJSON компоненту чтобы ReactLeaflet мог перерисовать
  // карту при новой пачке данных
  const hashKey = waypoints.map(w => `${w.latitude}${w.longitude}`).join('');

  return (
    <LeafletMap
      leaflet={{}} // хз что это, но помечено как обязательно поле
      style={{ height: 400, width: 750 }}
      center={center}
      zoom={14}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id="mapbox/streets-v11"
        accessToken={MAPBOX_API_KEY}
      />
      <GeoJSON key={hashKey} data={geoJSON} />
      {waypoints.map(waypoint => (
        <Marker
          key={'' + waypoint.id}
          position={[waypoint.latitude, waypoint.longitude]}
        />
      ))}
    </LeafletMap>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

export default TripMap;
