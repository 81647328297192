// @flow
import _isEmpty from 'lodash/isEmpty';

import type {
  SelfVehicleRegulation,
  SelfVehicleRegulationStatus
} from './../types';
import CommonApi from './../commonApi';
import type { CommonApiMethod } from './../commonApi';
import { fetchRequest } from '.';
import { convertFromMToKm, convertFromKmToM } from '../helpers';

const convertDataFromServer = (value: SelfVehicleRegulation) => {
  if (!_isEmpty(value.orgUnits)) {
    value.orgUnits = Object.entries(value.orgUnits).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: convertFromMToKm(value)
      }),
      {}
    );
  }
  if (Array.isArray(value.selfVehicleRegulationVehicles)) {
    value.selfVehicleRegulationVehicles = value.selfVehicleRegulationVehicles.map(
      v => ({
        ...v,
        kilometrage: convertFromMToKm(v.kilometrage),
        yearKilometrage: convertFromMToKm(v.yearKilometrage)
      })
    );
  }

  return value;
};

const convertDataToServer = (value: SelfVehicleRegulation) => {
  if (!_isEmpty(value.orgUnits)) {
    value.orgUnits = Object.entries(value.orgUnits).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: convertFromKmToM(value)
      }),
      {}
    );
  }
  if (Array.isArray(value.selfVehicleRegulationVehicles)) {
    value.selfVehicleRegulationVehicles = value.selfVehicleRegulationVehicles.map(
      v => ({
        ...v,
        kilometrage: convertFromKmToM(v.kilometrage),
        yearKilometrage: convertFromKmToM(v.yearKilometrage)
      })
    );
  }

  return value;
};

const selfVehicleRegulations: $Shape<
  CommonApiMethod & { changeStatus: Function }
> = new CommonApi<SelfVehicleRegulation>(
  'selfVehicleRegulation',
  'бюджет',
  convertDataToServer,
  convertDataFromServer
);

selfVehicleRegulations.changeStatus = async (
  id: number,
  status: SelfVehicleRegulationStatus
) => {
  return await fetchRequest.put(`/selfVehicleRegulation/changeStatus`, {
    id,
    status
  });
};

export default selfVehicleRegulations;
