import { StouCard, StouForm, StouList } from '../../containers/Stou';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: StouList,
        path: '/admin/stou',
        access: [accessTypeEnum.admin],
    },
    {
        component: StouForm,
        path: '/admin/stou/new',
        access: [accessTypeEnum.admin],
    },
    {
        component: StouForm,
        path: '/admin/stou/edit/:stouId',
        access: [accessTypeEnum.admin],
    },
    {
        component: StouCard,
        path: '/admin/stou/:stouId',
        access: [accessTypeEnum.admin],
    },
];