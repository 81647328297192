import { WarningOutlined } from '@ant-design/icons';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Button, Form } from 'antd';

import { Icon, Popover } from './../../../../../../../components/ui';
import type { RegulationLimitType } from '../../../../../../../lib/types';
import { GridItem } from '../../../../../../../components/layout';
import {
  Cell,
  HeaderBoldCell,
  HeaderCell,
  ListHeader
} from '../../../elements';
import {
  regulationLimitTypeEnum,
  regulationLimitTypes
} from '../../../../../../../lib/enum';

// Разметка таблицы лимитов
export const getGridTemplate = (type: RegulationLimitType) => {
  switch (type) {
    case regulationLimitTypeEnum.employee:
    case regulationLimitTypeEnum.orgUnit:
    case regulationLimitTypeEnum.oneOffRequests:
      return [
        {
          size: '(max-width: 1399px)',
          gutter: 0,
          cols: ['300px', '1fr', '1fr', '1fr', '1fr', '50px']
        },
        {
          size: '(min-width: 1400px)',
          gutter: 0,
          cols: ['400px', '1fr', '1fr', '1fr', '1fr', '50px']
        }
      ];
    case regulationLimitTypeEnum.ovb:
    default:
      return [
        {
          size: '(max-width: 1399px)',
          gutter: 0,
          cols: ['200px', '130px', '280px', '1fr', '1fr', '50px']
        },
        {
          size: '(min-width: 1400px)',
          gutter: 0,
          cols: ['300px', '160px', '380px', '1fr', '1fr', '50px']
        }
      ];
  }
};

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
`;

export const DeleteIcon = styled(Icon).attrs(() => ({
  type: 'trash'
}))`
  cursor: pointer;
`;

export const DeleteCell = styled(Cell)`
  justify-content: center;
`;

export const ListTableHeader = ({ type }: { type: RegulationLimitType }) => (
  <ListHeader media={getGridTemplate(type)} rows={2}>
    <GridItem
      cellUnion={{
        gridRow: 'span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>{regulationLimitTypes[type]}</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: 'span 2'
      }}
    >
      <HeaderBoldCell>Время использования (часы)</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7',
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '2',
        gridRow: `2`
      }}
    >
      <HeaderCell>Будние дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{
        borderTop: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '3',
        gridRow: `2`
      }}
    >
      <HeaderCell>Выходные дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{}}
      cellUnion={{
        gridColumn: '4 / span 3'
      }}
    >
      <HeaderBoldCell>Пробег (км)</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderRight: '1px solid #c0ccd7',
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '4',
        gridRow: `2`
      }}
    >
      <HeaderCell>Будние дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '5 / span 2',
        gridRow: `2`
      }}
    >
      <HeaderCell>Выходные дни</HeaderCell>
    </GridItem>
  </ListHeader>
);

export const ListTableOvbHeader = () => (
  <ListHeader media={getGridTemplate(regulationLimitTypeEnum.ovb)}>
    <GridItem>
      <HeaderBoldCell>ОВБ</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Тип ОВБ</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Служба</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Время использования (часы)</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Пробег (км)</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const QuestionIcon = styled(Icon).attrs(() => ({
  type: 'question'
}))`
  cursor: help;
  margin-left: 8px;
`;

export const AddButton = styled(({ children, ...props }) => (
  <button {...props}>
    <Icon type="plus" style={{ marginRight: 8 }} />
    {children}
  </button>
))`
  background: none;
  border: none;
  color: #2770ff;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type StatusButtonProps =
  | PropsWithChildren
  | {
      withWarn?: boolean,
      content?: string,
      onClick?: Function
    };

export const StatusButton = ({
  withWarn,
  content,
  ...props
}: StatusButtonProps) => {
  return withWarn ? (
    <Popover
      title="Внимание!"
      content={content || 'Имеются пустые группы лимитов'}
    >
      <Button
        size="small"
        disabled={true}
        icon={<WarningOutlined />}
        type="primary"
        {...props}
      >
        {props.children}
      </Button>
    </Popover>
  ) : (
    <Button
      size="small"
      disabled={false}
      type="primary"
      {...props}
    >
      {props.children}
    </Button>
  );
};
