// @flow
import { navigate } from '@reach/router';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input/Input';
import TextArea from 'antd/lib/input/TextArea';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Form, OrgUnitSelect, Selects } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import CancelButton from '../../../components/CancelButton';
import { InputNumber } from '../../../components/inputs';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';
import { notificationLoading } from '../../../components/Notifications';
import type { Profile } from '../../../ducks/auth';
import type { AppState } from '../../../ducks/redux';
import { attachedEquipmentApi } from '../../../lib/api';
import type { AttachedEquipment } from '../../../lib/types';
import { attachedEquipmentFuelTankTypeEnum } from '../../../lib/enum';
import { convertDateToString } from '../../Vehicles/lib';
import { getDisabledEqipmentInstallRemoveDate } from '../lib';

import { Panel, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';

const {
  AttachmentsSelect,
  BrandsSelect,
  AttachedEquipmentFuelTankTypeSelect,
  FuelTypeSelect,
} = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  attachedEquipment: $Shape<AttachedEquipment>,
  employeeOrgUnitId: number,
};

const InnerForm = ({
  onSubmit,
  onCancel,
  attachedEquipment,
  employeeOrgUnitId,
}: FormProps) => (
  <Form initialValues={attachedEquipment} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        isSubmitting,
        values,
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Подразделение"
                    required
                    name="orgUnitId"
                  >
                    {({ value, name }) => (
                      <OrgUnitSelect
                        value={value || ''}
                        onChange={(orgUnitId: number) =>
                          setFieldValue(name, parseInt(orgUnitId, 10))
                        }
                        filter={{
                          nodeId: employeeOrgUnitId,
                          nodeFilterType: 'branchAndChildren',
                        }}
                        data-cy="orgUnitSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Наименование" required name="name" fast>
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="inventoryNumber"
                    label="Инвентарный номер"
                    fast
                  >
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип" required name="type" fast>
                    {({ name, value }) => (
                      <AttachmentsSelect
                        name={name}
                        value={value}
                        onBlur={() => handleBlur({ target: { name } })}
                        onChange={(value: string) => setFieldValue(name, value)}
                        data-cy="typeSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка" required name="brandId" fast>
                    {({ value, name }) => (
                      <BrandsSelect
                        name={name}
                        value={value}
                        showSearch
                        onChange={(value: number) => setFieldValue(name, value)}
                        data-cy="brandSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="code" label="Мнемокод" required fast>
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField required fast label="Тип бака" name="fuelTankType">
                    {({ value, name }) => (
                      <AttachedEquipmentFuelTankTypeSelect
                        value={value}
                        name={name}
                        onChange={(value: number) => {
                          setFieldValue(name, value);
                          if (
                            value !== attachedEquipmentFuelTankTypeEnum.ownTank
                          ) {
                            setFieldValue('fuelType', undefined);
                            setFieldValue('tankVolume', null);
                            setFieldValue('fuelRemaining', null);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.fuelTankType ===
                  attachedEquipmentFuelTankTypeEnum.ownTank && (
                  <GridItem>
                    <FormField
                      required
                      fast
                      label="Тип топлива"
                      name="fuelType"
                    >
                      {({ value, name }) => (
                        <FuelTypeSelect
                          value={value}
                          name={name}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem fullWidth>
                  <FormField name="specifications" label="Характеристики" fast>
                    {(field) => <TextArea {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Показания счётчика моточасов, ч"
                    name="machineHours"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        disabled
                        step={0.1}
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Расход топлива, л/маш. час"
                    name="fuelConsumption"
                    required
                  >
                    {({ value, name }) => (
                      <InputNumber
                        step={0.1}
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.fuelTankType ===
                  attachedEquipmentFuelTankTypeEnum.ownTank && (
                  <>
                    <GridItem>
                      <FormField
                        fast
                        required
                        label="Объем топливного бака, л"
                        name="tankVolume"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Остаток топлива, л"
                        required
                        name="fuelRemaining"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <FormField label="Дата выдачи" name="installDate" fast>
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                        disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата снятия" name="removeDate" fast>
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                        disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  attachedEquipmentId: number,
  profile: Profile,
};

type State = {
  attachedEquipment?: AttachedEquipment,
};

class AttachedForm extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const { attachedEquipmentId } = this.props;
    if (attachedEquipmentId) {
      try {
        const attachedEquipment =
          await attachedEquipmentApi.fetchAttachedEquipment(
            attachedEquipmentId
          );
        this.setState({
          attachedEquipment,
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
        navigate('/equipment/attached');
      }
    }
  }

  onCancel = () => {
    if (this.props.attachedEquipmentId) {
      navigate(`/equipment/attached/${this.props.attachedEquipmentId}`);
    } else navigate('/equipment/attached');
  };

  onSubmit = async (attachedEquipment: AttachedEquipment) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      let attachedEquipmentId = attachedEquipment.id;
      if (attachedEquipmentId) {
        await attachedEquipmentApi.updateAttachedEquipment(attachedEquipment);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены',
        });
      } else {
        const addedAttachedEquipment =
          await attachedEquipmentApi.addAttachedEquipment(attachedEquipment);
        attachedEquipmentId = addedAttachedEquipment.id;
        notification.success({
          message: 'Успешное добавление',
          description: `Успешно добавлено навесное оборудование`,
        });
      }
      navigate(`/equipment/attached/${attachedEquipmentId}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { profile } = this.props;
    const { attachedEquipment } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/equipment/attached">Навесное оборудование</Crumb>
              {attachedEquipment ? (
                <>
                  <Crumb to={`/equipment/attached/${attachedEquipment.id}`}>
                    {attachedEquipment.brandName} {attachedEquipment.name}
                  </Crumb>
                  <Crumb>Редактирование</Crumb>
                </>
              ) : (
                <Crumb>Новое навесное оборудование</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {attachedEquipment
              ? `Навесное оборудование ${attachedEquipment.id}`
              : 'Новое навесное оборудование'}
          </h1>
        </StyledPanel>
        <InnerForm
          attachedEquipment={
            attachedEquipment || { orgUnitId: profile.employeeOrgUnitId }
          }
          employeeOrgUnitId={profile.employeeOrgUnitId}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect((state: AppState, props: Props) => ({
  attachedEquipmentId: parseInt(props.attachedEquipmentId, 10),
  profile: state.auth.profile,
}))(AttachedForm);
