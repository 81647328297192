import {
    ReportArchiveVehiclesList,
    ReportBusinessTripList,
    ReportComparingTransportServicesList,
    ReportDriversList,
    ReportFuelCardsList,
    ReportFuelsAndOilsList,
    ReportGpmOrderList,
    ReportScheduleMaintenanceList,
    ReportTaxedTripsList,
    ReportTransportConsumptionsJournalList,
    ReportTripsRegistryList,
    ReportVehicleOutputRatioList,
    ReportVehicleWorksList,
    ReportWeekendOrdersList,
} from '../containers/Reports';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: ReportGpmOrderList,
        path: '/report/gpm-order',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportVehicleOutputRatioList,
        path: '/report/vehicle-output-ratio',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportTaxedTripsList,
        path: '/report/taxed-trips',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportScheduleMaintenanceList,
        path: '/report/schedule-maintenance',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportVehicleWorksList,
        path: '/report/vehicle-works',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportFuelCardsList,
        path: '/report/fuel-cards',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportArchiveVehiclesList,
        path: '/report/write-off-vehicles',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportFuelsAndOilsList,
        path: '/report/fuels-and-oils',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportDriversList,
        path: '/report/drivers',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportTripsRegistryList,
        path: '/report/trips-registry',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportComparingTransportServicesList,
        path: '/report/comparing-transport-services',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportTransportConsumptionsJournalList,
        path: '/report/transport-consumptions-journal',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportBusinessTripList,
        path: '/report/business-trip',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
    {
        component: ReportWeekendOrdersList,
        path: '/report/weekend-orders',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingReports,
        ],
    },
];