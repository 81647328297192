import React from 'react';
import { TabItem, Tabs } from './../../../components/ui';

import { UserAccess } from '../../../lib/types';
import { withUserAccess } from './../../withUserAccess';
import { viewingExternalActs, viewingInternalActs } from './../accessRight';

type Props = {
  userAccess: UserAccess[],
};

export default withUserAccess((props: Props) => {
  const canViewingExternalActs = () =>
    props.userAccess.some((access) => viewingExternalActs.includes(access));
  const canViewingInternalActs = () =>
    props.userAccess.some((access) => viewingInternalActs.includes(access));

  return (
    <Tabs withRouter>
      {canViewingExternalActs() && (
        <TabItem
          label="Внешние"
          url="/budget/act"
          activeUrl={['/budget/act']}
        />
      )}
      {canViewingInternalActs() && (
        <TabItem
          label="Внутренние"
          url="/budget/act/internal"
          activeUrl={[
            '/budget/act/internal',
            '/budget/act/internal/defective-statement',
            '/budget/act/internal/os3',
            '/budget/act/internal/outsourcing-act',
            '/budget/act/internal/fuels-and-oils-act',
            '/budget/act/internal/oils-act',
            '/budget/act/internal/mpz',
          ]}
        />
      )}
    </Tabs>
  );
});
