//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import { StyledInput } from '../../../components/hoc/common/components/elements';

export type OkeiParamsFilter = {
  code?: string,
  name?: string,
  alias?: string
};

export const OkeiFilter = (props: FilterProps<OkeiParamsFilter>) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<OkeiParamsFilter>>) =>
      CommonFilter<OkeiParamsFilter>({ ...filterProps, ...props }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <StyledInput
                size="small"
                placeholder="Код"
                onChange={({ target: { value } }) =>
                  changeValue('code', value || undefined)
                }
                value={values.code}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <StyledInput
                size="small"
                placeholder="Наименование единицы измерения"
                onChange={({ target: { value } }) =>
                  changeValue('name', value || undefined)
                }
                value={values.name}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <StyledInput
                size="small"
                placeholder="Условное обозначение"
                onChange={({ target: { value } }) =>
                  changeValue('alias', value || undefined)
                }
                value={values.alias}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default OkeiFilter;
