// @flow
import type { ExpensesDistribution } from '../types';
import CommonApi from './../commonApi';

const expensesDistribution = new CommonApi<ExpensesDistribution>(
  'expensesDistribution',
  'акт списания топлива'
);

export default expensesDistribution;
