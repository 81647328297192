// @flow
import type { ContractVehicleOrder } from './../types';
import { fetchRequest, downloadRequestWithToken } from './index';

// Получение информации об инструктаже
export const fetchContractVehicleOrder = async (
  id: number,
  params: any = {}
): Promise<ContractVehicleOrder> => {
  const contractVehicleOrder = await fetchRequest.get(
    `/contractVehicleOrder/${id}`,
    params
  );
  if (contractVehicleOrder) return contractVehicleOrder;
  throw new Error('Не удалось загрузить информацию о заявке');
};

// Добавление информации о заявке
export const addContractVehicleOrder = async (
  contractVehicleOrder: ContractVehicleOrder
): Promise<ContractVehicleOrder> => {
  const added = await fetchRequest.post(
    '/ContractVehicleOrder',
    contractVehicleOrder
  );
  if (added) return added;
  throw new Error('Не удалось добавить информацию о заявке');
};

// Обновление информации о заявке
export const updateContractVehicleOrder = async (
  contractVehicleOrder: ContractVehicleOrder
): Promise<ContractVehicleOrder> => {
  const updated = await fetchRequest.put(
    '/contractVehicleOrder',
    contractVehicleOrder
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить информацию о заявке');
};

// Удаление информации о заявке
export const deleteContractVehicleOrder = async (
  contractVehicleOrderId: number
): Promise<ContractVehicleOrder> => {
  const deleted = await fetchRequest.delete(
    `/contractVehicleOrder/${contractVehicleOrderId}`
  );
  if (deleted) return deleted;
  throw new Error('Не удалось удалить информацию о заявке');
};

export const printContractVehicleOrder = async (
  id: number
): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    `/contractVehicleOrder/${id}/print`
  );
  if (printed) return printed;
  throw new Error('Не удалось напечатать заявку');
};

export default {
  fetchContractVehicleOrder,
  addContractVehicleOrder,
  updateContractVehicleOrder,
  deleteContractVehicleOrder,
  printContractVehicleOrder
};
