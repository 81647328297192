import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { VehicleIssueGroup } from '../types/vehicleIssueGroup';

export const addVehicleIssueGroup = async (
  vehicleIssueGroup: VehicleIssueGroup
): Promise<void> => {
  const added = await fetchRequest.post(
    '/vehicleIssueGroup',
    vehicleIssueGroup
  );
  if (added) return added;
  throw new Error('Не удалось создать группу дефектов');
};

export const fetchVehicleIssueGroup = async (
  params: FetchListParams<> = initialFetchListParams
): Promise<ListResponse<VehicleIssueGroup>> => {
  const list = await fetchRequest.get('/vehicleIssueGroup', {
    ...initialFetchListParams,
    ...params
  });
  if (list) return list;
  throw new Error('Не удалось получить список групп дефектов');
};

export const updateVehicleIssueGroup = async (
  vehicleIssueGroup: VehicleIssueGroup
): Promise<VehicleIssueGroup> => {
  const updated = await fetchRequest.put(
    '/vehicleIssueGroup',
    vehicleIssueGroup
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить группу дефекта');
};

export const getVehicleIssueGroup = async (
  id: number
): Promise<VehicleIssueGroup> => {
  let vehicleIssueGroup = await fetchRequest.get(`/vehicleIssueGroup/${id}`);
  if (vehicleIssueGroup) return vehicleIssueGroup;
  throw new Error('Не удалось загрузить группу дефектов');
};

export const deleteVehicleIssueGroup = async (
  id: number
): Promise<VehicleIssueGroup> => {
  const deleted = await fetchRequest.delete(`/vehicleIssueGroup/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить группу дефектов');
};

export default {
  fetchVehicleIssueGroup,
  getVehicleIssueGroup,
  deleteVehicleIssueGroup,
  addVehicleIssueGroup,
  updateVehicleIssueGroup
};
