// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { driverQualifications } from '../../lib/enum';
import type { DriverQualification } from '../../lib/types';
import { Option, Select } from './../ant/Select';

type Props = SelectProps & {
  exclude?: DriverQualification[],
  multiple?: boolean,
  disabledValues?: string[]
};

export default ({
  value,
  onChange,
  multiple,
  exclude = [],
  disabledValues = [],
  ...selectProps
}: Props) => (
  <Select
    placeholder="Выберите квалификации сотрудников"
    showSearch
    optionFilterProp="label"
    mode={multiple ? 'multiple' : 'default'}
    labelInValue={multiple}
    value={
      multiple
        ? (value || []).map(key => ({
            key,
            label: driverQualifications[key]
          }))
        : value
    }
    onChange={value => {
      multiple ? onChange(value.map(value => value.key)) : onChange(value);
    }}
    allowClear
    {...selectProps}
  >
    {Object.keys(driverQualifications)
      .filter(qualification => !exclude.includes(qualification))
      .map(key => (
        <Option
          key={key}
          value={key}
          label={driverQualifications[key]}
          disabled={disabledValues.includes(key)}
        >
          {driverQualifications[key]}
        </Option>
      ))}
  </Select>
);
