// @flow
import React, { useCallback } from 'react';
import { FormikProps } from 'formik';

import { Form } from '../../../index';
import PageFooter from './PageFooter';
import { CardWrapper } from './CardPage';

// Пропсы формы справочников
export type CommonFormProps<T> = {
  // Метод отправки измененных данных
  onSubmit?: T => any,
  // Метод отмены изменения данных
  onCancel?: T => any,
  // данные
  data: T,
  //
  children?: (props: FormikProps<T>) => React$Element<any>,
  // использовать футер
  useFooter?: boolean,
  disableSubmitButton?: (values: any) => boolean
};

export default <T>({
  onSubmit,
  onCancel,
  data,
  children,
  useFooter,
  disableSubmitButton
}: CommonFormProps<T>) => {
  /**
   *  Обертка для children. Обертывает каждый элемент в "карточки"
   *  В случае, если встречает объект со свойством noWrapMe, то не оборачивает его
   */
  const Content = useCallback(
    (formikProps: FormikProps<T>) => {
      let content;
      content = React.Children.map(
        children?.(formikProps).props.children,
        (child: any, index: number) => {
          if (child?.props?.noWrapMe) {
            return child;
          } else return <CardWrapper key={index}>{child || null}</CardWrapper>;
        }
      );
      content = content ?? [];
      useFooter &&
        content.push(
          // 99999, так как маловероятно, что будет такое количество элементов
          <CardWrapper key={99999}>
            <PageFooter
              disableSubmitButton={disableSubmitButton}
              {...formikProps}
              onCancel={onCancel}
            />
          </CardWrapper>
        );
      return content;
    },
    [children, disableSubmitButton, onCancel, useFooter]
  );

  return (
    <Form initialValues={data} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps) => {
        return (
          <form onSubmit={formikProps.handleSubmit}>
            <Content {...formikProps} />
          </form>
        );
      }}
    </Form>
  );
};
