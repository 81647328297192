// @flow

import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button/button';

import { washingVehicleTypeApi } from './../../lib/api';
import type { FetchListParams } from './../../lib/api';
import type { ListState, UserAccess } from './../../lib/types';
import { getListInitialState, setQueryParams } from './../../lib/helpers';

import { notificationLoading } from './../../components/Notifications';
import { Header, Section } from './../../components/layout';
import { Popconfirm, Table, Operations, Icon } from './../../components/ui';
import type { WashingVehicleType } from '../../lib/types/washingPriceList';

import { withUserAccess } from './../withUserAccess';
import canAddWashingVehicleType from './accessRight';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};

type State = ListState<WashingVehicleType>;

export class WashingPriceListTable extends Component<Props, State> {
  state = getListInitialState();

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50,
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      sorter: true,
    },
  ];

  deleteVehicleType = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await washingVehicleTypeApi.deleteVehicleType(id);
      await this.fetchWashingVehicleTypes(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchWashingVehicleTypes = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    this.setState({ loading: true });
    const { data, totalCount } = await washingVehicleTypeApi.fetchList({
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    await this.fetchWashingVehicleTypes(page);
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        width: '30px',
        stopPropagation: true,
        render: (record: any) => (
          <Operations>
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await this.deleteVehicleType(record.id)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ),
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      canAddWashingVehicleType.includes(access)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Типы ТС для моек</h1>}
          right={
            canAdd && (
              <Link to="/admin/washing-vehicle-types/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/washing-vehicle-types/${record.id}/edit`)
                  : null,
            })}
            columns={this.columns}
            fetch={this.fetchWashingVehicleTypes}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(WashingPriceListTable);
