import { WialonTripsCard, WialonTripsList } from '../containers/WialonTrips';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: WialonTripsList,
        path: '/wialonTrips',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            accessTypeEnum.viewingTrips,
        ],
    },
    {
        component: WialonTripsCard,
        path: '/wialonTrips/:tripId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
            accessTypeEnum.viewingTrips,
        ],
    },
];