import { FuelPriceForm, FuelPriceList } from '../../containers/priceLists';
import { accessTypeEnum } from '../../lib/enum';

// прейскурант ГСМ
export default [
    {
        component: FuelPriceList,
        path: '/admin/fuel-price',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
    {
        component: FuelPriceForm,
        path: '/admin/fuel-price/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
    {
        component: FuelPriceForm,
        path: '/admin/fuel-price/:fuelPriceId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
];