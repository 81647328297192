// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';

import { goBack } from '../../lib/helpers';
import { fuelCardApi } from '../../lib/api';
import type { FuelCard, UserAccess } from '../../lib/types';
import { fuelGroups, accessTypeEnum } from '../../lib/enum';

import type { AppState } from '../../ducks/redux';

import { Header, Panel, Section, SectionTitle } from '../../components/layout';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Popconfirm, Icon } from '../../components/ui';
import Grid, { GridItem } from '../../components/layout/Grid';
import Field from '../../components/card/Field';

import { withUserAccess } from './../withUserAccess';
import { notificationLoading } from './../../components/Notifications';

import { ActInfo } from './../Act';

import FuelCardHistory from './FuelCardHistory';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const StyledSection = styled(Section)`
  padding: 16px;
`;

const canEditAccess = [accessTypeEnum.admin, accessTypeEnum.handlingEquipment];

type Props = {
  fuelCardId: number,
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = {
  fuelCard: ?FuelCard,
};

export class FuelCardsCard extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const fuelCard = await fuelCardApi.fetchFuelCard(this.props.fuelCardId);
      this.setState({
        fuelCard,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  handleDelete = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await fuelCardApi.deleteFuelCard(this.props.fuelCardId);
      goBack('/admin/fuel-cards');
    } catch (error) {
      notification.error({
        message: 'Ошибка удаления',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  handleEdit = () =>
    navigate(`/admin/fuel-cards/${this.props.fuelCardId}/edit`);

  render() {
    const { userAccess, fuelCardId, location } = this.props;
    const { fuelCard } = this.state;
    const canEdit = userAccess.some((access) => canEditAccess.includes(access));

    if (!fuelCard) return null;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/fuel-cards">Топливные карты</Crumb>
              <Crumb>Топливная карта №{fuelCard.id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <Popconfirm
                title="Вы уверены, что хотите удалить топливную карту?"
                onConfirm={this.handleDelete}
              >
                <StyledIcon type="trash" size={16} />
              </Popconfirm>
            )
          }
        />
        <StyledPanel>
          <h1>
            Топливная карта №{fuelCard.id}
            {canEdit && (
              <StyledIcon onClick={this.handleEdit} type="edit" size={16} />
            )}
          </h1>
        </StyledPanel>
        <StyledSection>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Номер карты">{fuelCard.cardNumber}</Field>
            </GridItem>
            <GridItem>
              <Field label="Вид топлива">
                {fuelGroups[fuelCard.fuelGroup]}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Лимит">{fuelCard.limit}</Field>
            </GridItem>
            {fuelCard.balance && (
              <GridItem>
                <Field label="Остаток">{fuelCard.balance}</Field>
              </GridItem>
            )}
          </Grid>
        </StyledSection>
        <Section>
          <SectionTitle>История</SectionTitle>
          {/* 
            $FlowFixMe проблемы с Location (который системный, а не наш объект)
            мол ваш location не такой какой требуется location - хз че эт такое
          */}
          <FuelCardHistory location={location} fuelCardId={fuelCardId} />
        </Section>
        {fuelCard && fuelCard.act && <ActInfo act={fuelCard.act} />}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
}))(withUserAccess(FuelCardsCard));
