// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { pddTicketApi } from '../../../lib/api';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';

import type { PddTestType } from '../../../lib/types';
import { pddTestEnum } from '../../../lib/enum';

import Filter from './components/Filter';

const entryPointPath = '/admin/pdd/tickets/';

const columns = [
  {
    title: 'Наименование теста',
    dataIndex: 'pddTest.name'
  },
  {
    title: 'Тип',
    dataIndex: 'pddTest.type',
    render: (type: PddTestType) => pddTestEnum[type]
  },
  {
    title: 'Кол-во вопросов в билете',
    dataIndex: 'pddTest.questionsCount'
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default () => {
  return (
    <Page>
      <CommonListPage
        crud={pddTicketApi}
        pageHeaderProps={{
          breadCrumbs: [
            { to: '/', name: 'Главная' },
            { name: 'Список билетов' }
          ],
          mainHeader: 'Билеты ПДД',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}edit/${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
};
