// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Contractor, ServiceType } from './../../lib/types';
import { contractorApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import { convertContractorToString } from '../../lib/helpers';

export type ContractorFilter = {
  services?: ServiceType
};

export default (selectProps: SelectProps & { filter?: ContractorFilter }) => (
  <AutocompleteSelect
    placeholder="Выберите подрядчика"
    fetch={async params => {
      return await contractorApi.fetchContractors({
        ...selectProps.filter,
        ...params
      });
    }}
    fetchSingle={contractorApi.fetchContractor}
    notFoundText="Подрядчики не найдены"
    renderOption={(contractor: Contractor, Option) => (
      <Option key={contractor.id} value={contractor.id}>
        {convertContractorToString(contractor)}
      </Option>
    )}
    {...selectProps}
  />
);
