//@flow
import React from 'react';
import { Modal, notification } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { apiUrl } from '../../../lib/api';
import { fileTagEnum } from '../../../lib/enum';

import { ImageUploader } from '../../../components/Uploader/ImageUploader';

type DriverModalProps = {
  driverId: number,
  title?: string,
  visible: boolean,
  onClose: () => any,
  onUploaded?: (file: UploadFile, localImageUrl: string) => any
};
export const PhotoModal = (props: DriverModalProps) => {
  const onImageUploaded = async (file: UploadFile, localImageUrl: string) => {
    try {
      props.onUploaded && (await props.onUploaded(file, localImageUrl));
    } catch (error) {
      notification.error({
        message: error.message
      });
    }
  };
  return (
    <Modal
      centered
      destroyOnClose
      footer={null}
      title={props.title || 'Загрузить фото'}
      visible={props.visible}
      onCancel={props.onClose}
      width={500}
      height={500}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ImageUploader
          onUploaded={onImageUploaded}
          tag={fileTagEnum.driverPhoto}
          url={`${apiUrl}/driver/${props.driverId}/photo`}
        />
      </div>
    </Modal>
  );
};
