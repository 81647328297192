// @flow

import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ContractVehicleDailyBudget,
  DailyPlanning,
  DailyPlanningDate
} from '../types/contractVehicleDailyBudget';
import { convertFromHourToSecond, convertFromSecondToHour } from '../helpers';
import type { OperationLimitGroupStatus } from '../types/operationLimitGroup';

type DailyBudgetFilterParams = any;

const convertDateTimeFromServer = (dateTime: DailyPlanningDate) => {
  return {
    ...dateTime,
    hours: convertFromSecondToHour(dateTime.hours),
    yearHours: convertFromSecondToHour(dateTime.yearHours)
  };
};

const convertDateTimeToServer = (dateTime: DailyPlanningDate) => {
  return {
    ...dateTime,
    hours: convertFromHourToSecond(dateTime.hours),
    yearHours: convertFromHourToSecond(dateTime.yearHours)
  };
};

const convertFromServer = (budget: DailyPlanning) => {
  const { timeLimit, dateTimes } = budget;

  return {
    ...budget,
    timeLimit: {
      ...timeLimit,
      hours: convertFromSecondToHour(timeLimit.hours),
      hoursWeekend: convertFromSecondToHour(timeLimit.hoursWeekend)
    },
    dateTimes: {
      ...Object.keys(dateTimes).reduce((obj, key: string) => {
        const dateTime = dateTimes[key];
        return {
          ...obj,
          [key]: convertDateTimeFromServer(dateTime)
        };
      }, {})
    }
  };
};

/**
 * Создание и обновление бюджета
 *
 * Если даты не указаны,
 * то создается бюджет от сегодняшнего дня и до конца года
 *
 * @param startDate Дата начала
 * @param endDate Дата окончания
 * @return {Promise<ContractVehicleDailyBudget[]>}
 */
export const createDailyBudgets = async (
  startDate?: string,
  endDate?: string
): Promise<ContractVehicleDailyBudget[]> => {
  const budgets = await fetchRequest.post(
    '/contractVehicleDailyBudget/create-budgets',
    {
      startDate,
      endDate
    }
  );
  if (budgets) return budgets;
  throw new Error('Не удалось обновить бюджет');
};

/**
 * Получение бюджета на день по идентификатору
 *
 * @param id Идентификатор
 * @return {Promise<ContractVehicleDailyBudget>}
 */
export const getDailyBudget = async (
  id: number
): Promise<ContractVehicleDailyBudget> => {
  const budget = await fetchRequest.get(`/contractVehicleDailyBudget/${id}`);
  if (budget) return budget;
  throw new Error('Не удалось получить бюджет на день');
};

/**
 * Удаление бюджета на день по идентификатору
 *
 * @param id Идентификатор
 * @returns {Promise<ContractVehicleDailyBudget>} Удаленный бюджет
 */
export const deleteDailyBudget = async (
  id: number
): Promise<ContractVehicleDailyBudget> => {
  const budget = await fetchRequest.delete(`/contractVehicleDailyBudget/${id}`);
  if (budget) return budget;
  throw new Error('Не удалось удалить бюджет на день');
};

/**
 * Получение списка бюджетов
 *
 */
export const fetchDailyBudgets = async (
  filter: DailyBudgetFilterParams
): Promise<DailyPlanning[]> => {
  const budgets = await fetchRequest.get(
    '/contractVehicleDailyBudget/budgets',
    filter
  );
  if (budgets) return budgets.map(convertFromServer);
  throw new Error('Не удалось получить список бюджетов');
};

/**
 * Обновление дня лимита
 *
 * @param dailyPlanningDate Обновляемый день
 * @returns {Promise<DailyPlanningDate>}
 */
export const updateDailyPlanningDate = async (
  dailyPlanningDate: DailyPlanningDate
): Promise<DailyPlanningDate> => {
  const budget = await fetchRequest.put(
    '/contractVehicleDailyBudget',
    convertDateTimeToServer(dailyPlanningDate)
  );
  if (budget) return budget;
  throw new Error('Не удалось обновить лимит на день');
};

/**
 * Создание дня лимита
 *
 * @param dailyPlanningDate Создаваемый день
 * @returns {Promise<DailyPlanningDate>}
 */
export const createDailyPlanningDate = async (
  dailyPlanningDate: DailyPlanningDate
): Promise<DailyPlanningDate> => {
  const budget = await fetchRequest.post(
    '/contractVehicleDailyBudget',
    convertDateTimeToServer(dailyPlanningDate)
  );
  if (budget) return budget;
  throw new Error('Не удалось создать лимит на день');
};

/**
 * Печать месячного плана
 */
export const print = async (
  startDate: string,
  endDate: string
): Promise<boolean> => {
  const print = await downloadRequestWithToken(
    `/contractVehicleDailyBudget/print`,
    {
      startDate,
      endDate
    }
  );
  if (print) return print;
  throw new Error('Не удалось распечатать месячный план');
};

/**
 * Обновление бюджета - смена статуса
 */
export const updateBudgets = async (body: {
  startDate: string,
  endDate: string,
  status: OperationLimitGroupStatus,
  limitId: number,
  isYearStatus: boolean
}) => {
  const updated = convertFromServer(
    await fetchRequest.put('/contractVehicleDailyBudget/update-budgets', body)
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить бюджет');
};

export default {
  createDailyBudgets,
  getDailyBudget,
  deleteDailyBudget,
  fetchDailyBudgets,
  updateDailyPlanningDate,
  createDailyPlanningDate,
  print,
  updateBudgets
};
