// @flow

import { SelectProps } from 'antd/lib/select';
import range from 'lodash/range';
import React from 'react';
import { Option, Select } from './../ant/Select';

let timeRange = {};

range(25).forEach(hour => {
  timeRange[`${hour} ч.`] = hour;
});

// Выпадающий список часов с шагом 1
export default (props: SelectProps) => (
  <Select {...props} allowClear>
    {Object.keys(timeRange).map(group => (
      <Option value={timeRange[group]} key={group}>
        {group}
      </Option>
    ))}
  </Select>
);
