// @flow
import React from 'react';
import moment from 'moment';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import Button from 'antd/lib/button';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';

import Header from '../../../components/layout/Header';
import { vehicleTypes, fuelTypes } from '../../../lib/enum';
import Filter from './components/Filter';
import { applyMaskToValue } from './../../../lib/helpers';
import type { FuelType, VehicleType } from '../../../lib/types';
import type {
  ReportFuelConsumption,
  ReportFuelConsumptionParams,
} from '../../../lib/types/reportFuelConsumption';
import { reportFuelConsumptionApi } from '../../../lib/api';
import { notificationLoading } from '../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const initialFilter: any = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  filter: ReportFuelConsumptionParams,
  data: ReportFuelConsumption[],
  loading: boolean,
  scrollY: number,
};

/**
 * Отчет о расходе ГСМ
 */
export class FuelConsumptionList extends React.Component<null, State> {
  state = {
    filter: initialFilter,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    const { ...filter } = qs.parse(window.location.search);
    this.setState(
      { filter: isEmpty(filter) ? initialFilter : filter },
      this.fetchReportFuelConsumption
    );
  }

  fetchReportFuelConsumption = async () => {
    const { filter } = this.state;
    try {
      this.setState({ loading: true });
      const data = await reportFuelConsumptionApi.fetchReportFuelConsumption(
        filter
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleTableChange = async (pagination: any, filters: any, sorter: any) => {
    await this.applyFilter({
      ...this.state.filter,
      orderBy: sorter.field,
      byDescending: sorter.order === 'descend',
    });
    await this.fetchReportFuelConsumption();
  };

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла отчета...',
        key: 'print',
      });
      await reportFuelConsumptionApi.printReportFuelConsumption(
        this.state.filter
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  applyFilter = async (filter: ReportFuelConsumptionParams) =>
    this.setState({ filter }, this.fetchReportFuelConsumption);

  cleanFilter = () =>
    this.setState({ filter: initialFilter }, this.fetchReportFuelConsumption);

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      fixed: true,
      width: 150,
      sorter: true,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Тип ТС',
      dataIndex: 'vehicleType',
      width: 100,
      sorter: true,
      render: (type: VehicleType) => vehicleTypes[type],
    },
    {
      title: 'Служба',
      dataIndex: 'orgUnitName',
      width: 150,
      sorter: true,
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      width: 100,
      sorter: true,
      render: (kilometrage: number) => `${kilometrage} км.`,
    },
    {
      title: 'Вид топлива',
      dataIndex: 'fuelType',
      width: 150,
      sorter: true,
      render: (fuelType: FuelType) => fuelTypes[fuelType] || '-',
    },
    {
      title: 'Марка',
      dataIndex: 'brandName',
      width: 150,
      sorter: true,
    },
    {
      title: 'Количество маш/часов',
      dataIndex: 'machineHours',
      width: 150,
      sorter: true,
      render: (machineHours: number) => `${machineHours} ч.`,
    },
    {
      title: 'Выдано топлива по ПЛ',
      dataIndex: 'issuedFuel',
      width: 150,
      sorter: true,
      render: (value: number) => `${value} л.`,
    },
    {
      title: 'Истрачено топлива по ПЛ',
      dataIndex: 'consumedFuel',
      width: 150,
      sorter: true,
      render: (value: number) => `${value} л.`,
    },
    {
      title: 'Кол-во выписанных ПЛ',
      dataIndex: 'tripsCount',
      width: 150,
      sorter: true,
      render: (count: number) => `${count} шт.`,
    },
    {
      title: 'Остаток топлива на начало периода',
      dataIndex: 'fuelAtBeginning',
      width: 200,
      sorter: true,
      render: (value: number) => `${value} л.`,
    },
    {
      title: 'Остаток топлива на конец периода',
      dataIndex: 'fuelAtEnd',
      width: 200,
      sorter: true,
      render: (value: number) => `${value} л.`,
    },
  ];

  render() {
    const { filter, data, loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header
          left={<h1>Отчет о расходе ГСМ</h1>}
          right={
            <Button type="primary" onClick={this.handleExport}>
              Экспорт
            </Button>
          }
        />
        <TableFlexColumnWrapper>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={data}
            columns={this.columns}
            rowKey="vehicleId"
            name="FuelConsumption"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1800,
            }}
            onChange={this.handleTableChange}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default FuelConsumptionList;
