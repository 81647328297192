// @flow
import React, { useState } from 'react';

import Tabs from '../../../../components/ui/Tabs/Tabs';
import { TabItem } from '../../../../components/ui';
import type { MonitoringVehicle } from '../../../../lib/types/monitoringVehicle';
import { formatLicensePlateMask } from '../../../../components/inputs/masked-inputs/LicensePlateInput';
import { applyMaskToValue } from '../../../../lib/helpers';
import { Wrapper, Content, Header, HeaderItem, CloseIcon } from './styles';
import { HistoryTab, VehicleTab } from './elements';
import type { MonitoringHistoryState } from '../../MonitoringScreen';

type Props = {
  // ТС, информацию которой отображаем
  monitoringVehicle?: MonitoringVehicle,
  // Обработка закрытия окна информации
  close: () => void,
  // Открыт или нет
  open: boolean,
  // Инстанс проигрывателя треков
  playback: any,
  // Обработка открытия вкладки истории для контроля проигрывателя
  handleHistoryOpen: (opened: boolean) => void,
  // Состояние истории
  historyState: MonitoringHistoryState,
  // Обработка изменения стейта истории
  onStateChange: (
    fn: (
      state: $Shape<MonitoringHistoryState>
    ) => $Shape<MonitoringHistoryState>
  ) => void
};

/**
 * Информация о ТС
 */
export const VehicleInfo = ({
  monitoringVehicle,
  open,
  handleHistoryOpen,
  onStateChange,
  historyState,
  close,
  playback
}: Props) => {
  const [currentTab, setCurrentTab] = useState('vehicle');

  /**
   * Событие изменения вкладки
   * @param tab Идентификатор вкладки
   */
  const handleTabChange = (tab?: string | number) => {
    setCurrentTab(tab);
    handleHistoryOpen(tab === 'history');
  };

  if (!monitoringVehicle) return null;
  const { vehicle } = monitoringVehicle;
  return (
    open && (
      <Wrapper>
        <Header cols={['1fr', '1fr', '1fr', '180px']}>
          <HeaderItem>
            {vehicle.vehicleModel.brandName} {vehicle.vehicleModel.name}
          </HeaderItem>
          <HeaderItem>
            {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
          </HeaderItem>
          <HeaderItem align={{ horizontal: 'right' }}>
            <Tabs
              onChange={handleTabChange}
              defaultTab="vehicle"
              background="#3d4042"
            >
              <TabItem tabKey="vehicle" label="Машина" />
              <TabItem tabKey="history" label="История" />
            </Tabs>
          </HeaderItem>
          <CloseIcon
            onClick={() => {
              handleTabChange('vehicle');
              close();
            }}
          />
        </Header>
        <Content>
          {currentTab === 'vehicle' && (
            <VehicleTab monitoringVehicle={monitoringVehicle} />
          )}
          {currentTab === 'history' && (
            <HistoryTab
              playback={playback}
              state={historyState}
              onStateChange={onStateChange}
              monitoringVehicle={monitoringVehicle}
            />
          )}
        </Content>
      </Wrapper>
    )
  );
};
