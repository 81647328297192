// @flow
import type { SmallScaleMechanizationTool } from '../types';
import CommonApi from './../commonApi';

const baseUrl = 'smallScaleMechanizationTool';
const smallScaleMechanizationTool = new CommonApi<SmallScaleMechanizationTool>(
  baseUrl,
  'Средства малой механизации'
);

export default {
  ...smallScaleMechanizationTool
};
