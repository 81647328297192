// @flow
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Link } from '@reach/router';
import moment from 'moment';

import { pddTestResultApi } from '../../../../lib/api';
import CommonListPage from '../../../../components/hoc/common/handbook/ListPage';
import Page from '../../../../components/hoc/common/handbook/PageContext';

import { getBreadCrumbsByUrl } from '../../../../lib/autoBreadcrumbs';
import { formatSecondsToHumanTime, navigate } from '../../../../lib/helpers';

import { FilterResults, HeaderTabs } from './components';
import type {
  PddGroup,
  PddTestResult,
  PddTestStatusesType,
  PddTestType,
  UserAccess
} from '../../../../lib/types';
import {
  accessTypeEnum,
  pddTest,
  pddTestStatuses,
  pddTestStatusesEnum
} from '../../../../lib/enum';
import RightHeader from './components/RightHeader';
import { withUserAccess } from '../../../withUserAccess';
import { TableHeader } from '../../../../components/ui/Table';

type PageProps = {
  examTraining: PddTestType,
  userAccess: UserAccess[]
};

export default withUserAccess((props: PageProps) => {
  const breadCrumbs = getBreadCrumbsByUrl(
    `/pdd/test/${props.examTraining}/tests/`
  );

  const [canRelogin, setCanRelogin] = useState(false);
  const [canStartTest, setCanStartTest] = useState(false);

  const columnsTraining = [
    {
      title: '',
      dataIndex: 'id',
      render: (id: number, record: PddTestResult) => (
        <Button
          onClick={() =>
            navigate(
              `/pdd/test/${props.examTraining}/exam/${record.pddTest.id}`
            )
          }
        >
          Повторить
        </Button>
      )
    },
    {
      title: 'Дата тестирования',
      dataIndex: 'examinationDate',
      render: (examinationDate: string) =>
        moment(examinationDate).format('DD.MM.YYYY')
    },
    {
      title: 'Тема обучения',
      dataIndex: 'pddTest.pddTestGroups',
      width: 400,
      render: (pddTestGroups?: PddGroup[]) => (
        <div style={{ whiteSpace: 'break-spaces' }}>
          {pddTestGroups?.map(el => el.pddGroup?.name).join(', ')}
        </div>
      )
    },
    {
      title: 'Наименование теста',
      dataIndex: 'pddTest.name',
      width: 300,
      render: (name: string) => (
        <div style={{ whiteSpace: 'break-spaces' }}>{name}</div>
      )
    },
    {
      title: 'Время',
      dataIndex: 'totalTime',
      render: (totalTime: number) =>
        totalTime > 0 ? formatSecondsToHumanTime(totalTime) : ''
    },
    {
      title: <TableHeader>Правильных ответов</TableHeader>,
      width: 80,
      dataIndex: 'rightAnswersCount'
    },
    {
      title: <TableHeader>Неправильных ответов</TableHeader>,
      width: 85,
      dataIndex: 'wrongAnswersCount'
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: PddTestStatusesType) => {
        const color =
          status === pddTestStatusesEnum.passed ? '#27ae60' : '#eb2f22';
        return <div style={{ color }}> {pddTestStatuses[status]}</div>;
      }
    },
    {
      title: 'Ссылка на результат',
      dataIndex: 'id',
      render: (id?: number) =>
        id ? <Link to={`/pdd/results/${id}`}>Перейти</Link> : ''
    }
  ];

  const columnExam = [
    {
      title: 'Дата',
      dataIndex: 'examinationDate',
      render: (examinationDate: string) =>
        moment(examinationDate).format('DD.MM.YYYY')
    },
    {
      title: 'Время',
      dataIndex: 'totalTime',
      render: (totalTime: number) =>
        totalTime > 0 ? formatSecondsToHumanTime(totalTime) : ''
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: PddTestStatusesType) => {
        const color =
          status === pddTestStatusesEnum.passed ? '#27ae60' : '#eb2f22';
        return <div style={{ color }}> {pddTestStatuses[status]}</div>;
      }
    },
    {
      title: 'Ссылка на результат',
      dataIndex: 'id',
      render: (id?: number) =>
        id ? <Link to={`/pdd/results/${id}`}>Перейти</Link> : ''
    }
  ];

  const wrappedPddTestResultApi = {
    ...pddTestResultApi,
    fetch: async (params): Promise<any> =>
      await pddTestResultApi.fetch({
        ...params,
        'pddTest.type': props.examTraining
      })
  };

  useEffect(() => {
    setCanRelogin(
      props.userAccess.find(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.reloginPddTest
        ].includes(access)
      )
    );
    setCanStartTest(
      props.userAccess.find(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.viewingPddTests
        ].includes(access)
      )
    );
  }, [props.userAccess]);

  return (
    <Page>
      <CommonListPage
        crud={wrappedPddTestResultApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader:
            props.examTraining === pddTest.training
              ? 'Тестирование в режиме обучения'
              : 'Экзамен',
          rightHeader: (
            <RightHeader
              examTraining={props.examTraining}
              canRelogin={canRelogin}
              canStartTest={canStartTest}
            />
          )
        }}
        subHeader={
          <HeaderTabs selectedTab="results" examTraining={props.examTraining} />
        }
        tableListProps={{
          columns:
            props.examTraining === pddTest.exam ? columnExam : columnsTraining
        }}
        filter={FilterResults}
      />
    </Page>
  );
});
