// @flow
import Button from 'antd/lib/button';

import notification from 'antd/lib/notification';
import React from 'react';
import { notificationLoading } from '../../../../../components/Notifications';
import { calculationStatusEnum } from '../../../../../lib/enum';
import { plus, toLocalStringRu } from '../../../../../lib/helpers';
import { Section } from './../../../../../components/layout';

import { AntTable } from './../../../../../components/ui';
import { currentRepairCalculationApi, vehiclePlanApi } from './../../../../../lib/api';

import type { CurrentRepairCalculation, VehiclePlan } from './../../../../../lib/types';

import type { VehicleListFilterParams } from './../../components/FilterVehicleList';
import Filter from './../../components/FilterVehicleList';

import { COLUMNS, filteredData, MONTH } from './../../lib';

import { headerPanel } from './../components/Common';

type Props = {
  vehiclePlanId: number
};
type State = {
  data: CurrentRepairCalculation[],
  loading: boolean,
  filter: VehicleListFilterParams,
  vehiclePlan: ?VehiclePlan
};
export default class extends React.Component<Props, State> {
  state = {
    data: [],
    filter: {},
    loading: false,
    vehiclePlan: null
  };
  columns = [
    ...COLUMNS,
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      width: 150,
      dataIndex: `months[${monthIndex}].cost`,
      render: (sum: number) => toLocalStringRu(sum)
    })),
    {
      title: 'Итого стоимость',
      children: [
        {
          title: 'Материалы',
          children: [
            {
              title: 'Хоз. способ',
              width: 150,
              dataIndex: 'internalMaterialsSum',
              render: (internalMaterialsSum: number) =>
                toLocalStringRu(internalMaterialsSum)
            },
            {
              title: 'Подряд',
              width: 150,
              dataIndex: 'contractMaterialsSum',
              render: (contractMaterialsSum: number) =>
                toLocalStringRu(contractMaterialsSum)
            },
            {
              title: 'Общая',
              width: 150,
              dataIndex: 'fullMaterialsSum',
              render: (fullMaterialsSum: number) =>
                toLocalStringRu(fullMaterialsSum)
            }
          ]
        },
        {
          title: 'Работы',
          children: [
            {
              title: 'Хоз. способ',
              width: 150,
              dataIndex: 'internalOperationsSum',
              render: (internalOperationsSum: number) =>
                toLocalStringRu(internalOperationsSum)
            },
            {
              title: 'Подряд',
              width: 150,
              dataIndex: 'contractOperationsSum',
              render: (contractOperationsSum: number) =>
                toLocalStringRu(contractOperationsSum)
            },
            {
              title: 'Общая',
              width: 150,
              dataIndex: 'fullOperationsSum',
              render: (fullOperationsSum: number) =>
                toLocalStringRu(fullOperationsSum)
            }
          ]
        }
      ]
    },
    {
      title: 'Итого',
      width: 150,
      dataIndex: 'sum',
      render: (sum: number) => toLocalStringRu(sum)
    }
  ];

  componentWillMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;

    const itogCalculation = (data: any) => {
      const itog = {
        months: Array(12)
          .fill()
          .map(u => ({ cost: 0 })),
        fullMaterialsSum: 0,
        fullOperationsSum: 0,
        sum: 0
      };
      data.forEach((item: any) => {
        itog.sum = plus(item.sum || 0, itog.sum);
        itog.fullMaterialsSum = plus(
          item.fullMaterialsSum || 0,
          itog.fullMaterialsSum
        );
        itog.fullOperationsSum = plus(
          item.fullOperationsSum || 0,
          itog.fullOperationsSum
        );
        item.months.forEach((month: any, index) => {
          itog.months[index].cost = plus(
            itog.months[index].cost,
            month.cost || 0
          );
        });
      });
      return itog;
    };

    try {
      let { data } = await currentRepairCalculationApi.fetch({
        vehiclePlanId,
        pageSize: undefined,
        page: undefined
      });

      if (data.length) {
        data = [...data, itogCalculation(data)];
      }

      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  applyFilter = (filter: VehicleListFilterParams) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await currentRepairCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await currentRepairCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.currentRepairCalculationStatus ===
    calculationStatusEnum.draft;

  render() {
    const { vehiclePlanId } = this.props;
    const { data, loading, filter } = this.state;
    const filtered = filteredData(data, filter);
    return (
      <>
        {headerPanel({ title: 'Текущий ремонт', vehiclePlanId })}
        <Section>
          <div style={{ padding: '16px 16px 0px' }}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </div>
          <AntTable
            data={filtered}
            bordered
            loading={loading}
            pagination={false}
            columns={this.columns}
            scroll={{
              y: 'calc(100vh - 450px)',
              x: '3500px'
            }}
          />
        </Section>
        {this.isDraft() && (
          <Section style={{ padding: '16px' }}>
            <Button type="primary" onClick={this.calculate}>
              Рассчитать
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              type="primary"
              onClick={this.changeStatus}
            >
              Утвердить
            </Button>
          </Section>
        )}
      </>
    );
  }
}
