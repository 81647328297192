// @flow
import React, { Component } from 'react';

import { driverInspectionCalculationApi } from '../../../../lib/api';
import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

export default class extends Component<Props, {}> {
  render() {
    const { location, vehiclePlanId } = this.props;
    return (
      <Common
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={driverInspectionCalculationApi}
        calcScrollY={400}
        statusField={'driverInspectionCalculationStatus'}
        title={
          'Расчет потребностей в предрейсовых/послерейсовых осмотров водителей'
        }
      />
    );
  }
}
