// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import type { OrderType, WayPoint, WaypointType } from '../../lib/types';
import ListTable from '../ui/ListTable';
import { waypointTypeEnum } from '../../lib/enum';
import WaypointTableRow from './WaypointTableRow';

const RowHeader = styled.div`
  width: 100%;
  background: #f0f4f8;
  color: #838d96;
  padding: 11px 15px;
`;

type Props = {
  // Начальная точка
  startWaypoint: ?WayPoint,
  // Промежуточные точки
  transitWaypoints: WayPoint[],
  // Конечная точка
  endWaypoint: ?WayPoint,
  // Функция удаления точки
  removeTransitWaypoint: (waypoint: WayPoint) => void,
  // Дата отправления из гаража
  routeStartDate?: string | moment,
  // Дата прибытия в гараж
  routeEndDate?: string | moment,
  // Типы отображаемых точек
  editTypes: WaypointType[],
  // Id филиала текущего пользователя
  employeeBranchOrgUnitId: number,
  // Функция изменения точки
  changeWaypoint: (waypoint: WayPoint) => void,
  // Копирование точки
  copyWaypoint: (waypoint: WayPoint) => void,
  // Дополнительные строки для отрисовки
  appendRows: Node[],
  getDisabledArrivalDate: Function,
  getDisabledDepartureDate: Function,
  hasErrors?: boolean,
  addNewRow: Function,
  orderType: OrderType,
};

export default ({
  transitWaypoints,
  editTypes,
  employeeBranchOrgUnitId,
  routeStartDate,
  routeEndDate,
  startWaypoint,
  addNewRow,
  endWaypoint,
  removeTransitWaypoint,
  changeWaypoint,
  appendRows,
  copyWaypoint,
  getDisabledArrivalDate,
  getDisabledDepartureDate,
  hasErrors,
  orderType,
}: Props) => {
  return (
    <ListTable
      style={{
        border: hasErrors ? '1px solid red' : null,
      }}
      data={transitWaypoints}
      renderRow={(waypoint: WayPoint, index: number) => {
        const row = (
          <WaypointTableRow
            routeEndDate={routeEndDate}
            routeStartDate={routeStartDate}
            prevDepartureDateTime={
              transitWaypoints[index - 1] &&
              transitWaypoints[index - 1].departureDateTime
            }
            orderType={orderType}
            addNewRow={addNewRow}
            prevArrivalDateTime={
              transitWaypoints[index - 1] &&
              transitWaypoints[index - 1].arrivedDateTime
            }
            getDisabledArrivalDate={getDisabledArrivalDate}
            getDisabledDepartureDate={getDisabledDepartureDate}
            copyWaypoint={copyWaypoint}
            waypoint={waypoint}
            index={index}
            key={waypoint.key}
            isEmptyRow={index === transitWaypoints.length - 1}
            removeWaypoint={removeTransitWaypoint}
            employeeBranchOrgUnitId={employeeBranchOrgUnitId}
            onChange={changeWaypoint}
          />
        );
        if (index === 0) {
          return (
            <React.Fragment key={`header${waypoint?.key ?? index}`}>
              <RowHeader style={{ borderTop: '1px solid #e4ebf2' }}>
                Точка подачи ТС
              </RowHeader>
              {row}
            </React.Fragment>
          );
        } else if (index === 1) {
          return (
            <React.Fragment key={`header${waypoint?.key ?? index}`}>
              <RowHeader>Точка маршрута</RowHeader>
              {row}
            </React.Fragment>
          );
        }
        return row;
      }}
      columns={[
        {
          title: 'Точка маршрута',
          key: 'address',
          style: { minWidth: '100px' },
        },
        {
          key: 'map',
          width: '100px',
        },
        { title: 'Прибытие', key: 'arrivedDateTime', width: '230px' },
        {
          title: 'Отправление',
          key: 'departureDateTime',
          width: '230px',
        },
        {
          key: 'notation',
        },
        {
          key: 'operations',
          width: '16px',
        },
      ]}
    >
      {({ content, Row }) => (
        <React.Fragment key="content">
          {editTypes.includes(waypointTypeEnum.start) && (
            <>
              <RowHeader>Нулевой пробег от гаража до объекта</RowHeader>
              {startWaypoint && (
                <WaypointTableRow
                  routeEndDate={routeEndDate}
                  routeStartDate={routeStartDate}
                  waypoint={startWaypoint}
                  employeeBranchOrgUnitId={employeeBranchOrgUnitId}
                  onChange={changeWaypoint}
                  getDisabledArrivalDate={getDisabledArrivalDate}
                  getDisabledDepartureDate={getDisabledDepartureDate}
                  key={startWaypoint.key}
                />
              )}
            </>
          )}
          {(editTypes.includes(waypointTypeEnum.end) ||
            editTypes.includes(waypointTypeEnum.start)) &&
            transitWaypoints.length > 0 && (
              <RowHeader key="tripHeader">Рейс</RowHeader>
            )}
          {content}
          {editTypes.includes(waypointTypeEnum.end) && (
            <React.Fragment key="endWaypoint">
              <RowHeader>Нулевой пробег от объекта до гаража</RowHeader>
              {endWaypoint && (
                <WaypointTableRow
                  routeEndDate={routeEndDate}
                  routeStartDate={routeStartDate}
                  waypoint={endWaypoint}
                  employeeBranchOrgUnitId={employeeBranchOrgUnitId}
                  onChange={changeWaypoint}
                  transitWaypoints={transitWaypoints}
                  getDisabledArrivalDate={getDisabledArrivalDate}
                  getDisabledDepartureDate={getDisabledDepartureDate}
                  prevDepartureDateTime={
                    transitWaypoints[transitWaypoints.length - 2] &&
                    transitWaypoints[transitWaypoints.length - 2]
                      .departureDateTime
                  }
                  key={endWaypoint.key}
                />
              )}
              {appendRows.map((node, index) =>
                node ? <Row key={index}>{node}</Row> : null
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </ListTable>
  );
};
