// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportArchiveVehicle,
  ReportArchiveVehicleParams
} from '../types/reportArchiveVehicle';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportArchiveVehicle) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage)
});

/**
 * Получение отчета
 */
export const fetchReportArchiveVehicles = async (
  params: ReportArchiveVehicleParams
): Promise<ReportArchiveVehicle[]> => {
  const report = await fetchRequest.get('/report/archiveVehicle', {
    ...params
  });
  return report.map(convertFromServer);
};

/**
 * Печать отчета
 */
export const printReportArchiveVehicles = async (
  params: ReportArchiveVehicleParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/archiveVehicle/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportArchiveVehicles,
  printReportArchiveVehicles
};
