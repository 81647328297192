import React from 'react';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import { Section } from '../../../components/layout';
import { Icon, ListTable } from '../../../components/ui';
import { toFixed } from '../../../lib/helpers';

export const Content = styled.div`
  padding: 16px;
`;
export const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
export const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const StyledListTable = styled(ListTable)`
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
`;
export const DriverRating = ({
  value,
  withTranscript,
  size = 'small'
}: {
  value: number,
  withTranscript?: boolean,
  size?: 'small' | 'large'
}) => {
  let color = 'black';
  let transcription = null;
  if (value !== 0) {
    color = value < 3 ? 'red' : value < 4 ? '#ffd300' : '#00C12B';
    transcription =
      value < 3 ? 'Плохо' : value < 4 ? 'Удовлетворительно' : 'Отлично';
  }
  return (
    <>
      <div style={{ display: 'flex' }}>
        {size === 'large' ? (
          <Title level={4} style={{ color }}>
            {toFixed(value)}
            {withTranscript && `${transcription ? ` (${transcription})` : ``}`}
          </Title>
        ) : (
          <div style={{ color }}>{toFixed(value)}</div>
        )}
      </div>
    </>
  );
};
