// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';
import styled from 'styled-components';

import { jobTitleApi } from '../../lib/api';
import type { JobTitle, JobTitleFilter } from '../../lib/types/jobTitle';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & {
  filter?: JobTitleFilter
};

export const OptionContent = styled.span.attrs(() => ({
  className: 'optionContent'
}))`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;
export const OptionOrgUnit = styled.span.attrs(() => ({
  className: 'orgUnit'
}))`
  color: #838d96;
  font-size: 10px;
  line-height: 1.7;
`;
export const OptionName = styled.span.attrs(() => ({
  className: 'name'
}))`
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Select = styled(AutocompleteSelect)`
  .ant-select-selection-selected-value .orgUnit,
  .ant-select-selection__choice__content .orgUnit {
    display: none;
  }
  .ant-select-selection-selected-value .name,
  .ant-select-selection__choice__content .name {
    max-width: 100%;
  }
  & .optionContent {
    height: initial;
  }
`;

export default ({ filter = {}, ...selectProps }: Props) => (
  <Select
    fetch={async params => {
      return await jobTitleApi.fetchJobTitles({
        ...filter,
        ...params
      });
    }}
    fetchSingle={jobTitleApi.fetchJobTitle}
    notFoundText="Должности не найдены"
    placeholder="Введите должность"
    renderOption={(jobTitle: JobTitle, Option) => (
      <Option key={jobTitle.id} value={jobTitle.id} jobTitle={jobTitle}>
        <OptionContent>
          <OptionName>{jobTitle.name}</OptionName>
          <OptionOrgUnit>{jobTitle.orgUnitName}</OptionOrgUnit>
        </OptionContent>
      </Option>
    )}
    {...selectProps}
  />
);
