import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { settingApi } from '../../lib/api';
import { Icon, Loading } from '../../components/ui';
import { closeSidebar } from '../../ducks/sidebar';

const Card = ({ closeSidebar }) => {
  const [url, setUrl] = useState(null);
  const [token, setToken] = useState(null);
  const fetch = async () => {
    const url = await settingApi.fetch({
      settingKey: 'WialonBaseUrl',
    });
    const token = await settingApi.fetch({
      settingKey: 'WialonApiToken',
    });
    setUrl(url.value);
    setToken(token.value);
  };

  useEffect(() => {
    if (url !== null && token !== null) {
      document.location.href = `${url}?token=${token}`;
    }
  }, [url, token]);

  useEffect(() => {
    closeSidebar();
    fetch();
  }, []);

  return <Loading />;
  // return url !== null && token !== null ? (
  //   <iframe
  //     title="wialon"
  //     src={`${url}?token=${token}`}
  //     style={{ width: '100%', height: 'calc(100vh - 3px)' }}
  //   >
  //     Ваш браузер не поддерживает плавающие фреймы!
  //   </iframe>
  // ) : null;
};

export default connect((state) => state, {
  closeSidebar,
})(Card);
