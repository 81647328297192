import {
    BudgetVersionCard,
    BudgetVersionForm,
    BudgetVersionList,
} from '../../containers/BudgetVersion';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BudgetVersionList,
        path: '/admin/budget-version',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingBudgetVersion,
        ],
    },
    {
        component: BudgetVersionCard,
        path: '/admin/budget-version/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingBudgetVersion,
        ],
    },
    {
        component: BudgetVersionForm,
        path: '/admin/budget-version/edit/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingBudgetVersion,
        ],
    },
    {
        component: BudgetVersionForm,
        path: '/admin/budget-version/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingBudgetVersion,
        ],
    },
];