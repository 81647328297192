// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { pddGroupApi } from '../../../lib/api';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';

import { getBreadCrumbsByUrl } from '../../../lib/autoBreadcrumbs';
import { Filter } from './components';

const entryPointPath = '/admin/pdd/groups/';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Наименование темы',
    dataIndex: 'name'
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default () => {
  return (
    <Page>
      <CommonListPage
        crud={pddGroupApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Темы ПДД',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: true
        }}
        filter={Filter}
      />
    </Page>
  );
};
