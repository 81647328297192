// @flow
import type { OverhaulCalculationMonth } from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataFromServer = (
  overhaulCalculationMonth: OverhaulCalculationMonth
) => ({
  ...overhaulCalculationMonth,
  cost: convertFromKopToRub(overhaulCalculationMonth.cost)
});

export const convertDataToServer = (
  overhaulCalculationMonth: OverhaulCalculationMonth
) => ({
  ...overhaulCalculationMonth,
  cost: convertFromRubToKop(overhaulCalculationMonth.cost)
});

const overhaulCalculationMonth = new CommonApi<OverhaulCalculationMonth>(
  'overhaulCalculationMonth',
  'Расчет плана кап. ремонтов для месяцев',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...overhaulCalculationMonth
};
