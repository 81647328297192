// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number
};

export default ({ vehiclePlanId }: Props) => {
  let activeUrlPass = null;
  if (vehiclePlanId) {
    activeUrlPass = [
      `/budget/vehicle/${vehiclePlanId}/spring-pass`,
      `/budget/vehicle/${vehiclePlanId}/cargo-pass`
    ];
  }
  return (
    <Tabs withRouter>
      <TabItem label="АКБ" url={`/budget/vehicle/${vehiclePlanId}/batteries`} />
      <TabItem
        label="ТО ПБ ГПМ"
        url={`/budget/vehicle/${vehiclePlanId}/gpm-maintenance`}
      />
      <TabItem label="GPS" url={`/budget/vehicle/${vehiclePlanId}/gps`} />
      <TabItem
        label="Тарировка"
        url={`/budget/vehicle/${vehiclePlanId}/measuring-device-certifications`}
      />
      <TabItem
        label="Гостехнадзор"
        url={`/budget/vehicle/${vehiclePlanId}/inspections-gtn`}
      />
      <TabItem
        label="ТО ГИБДД"
        url={`/budget/vehicle/${vehiclePlanId}/inspections-gibdd`}
      />
      <TabItem
        label="Замена гос.номера"
        url={`/budget/vehicle/${vehiclePlanId}/license-plate-change`}
      />
      <TabItem
        label="Материалы"
        url={`/budget/vehicle/${vehiclePlanId}/material`}
      />
      <TabItem label="Мойки" url={`/budget/vehicle/${vehiclePlanId}/washing`} />
      <TabItem
        label="Осмотры водителей"
        url={`/budget/vehicle/${vehiclePlanId}/driver-inspection`}
      />
      <TabItem
        label="Федеральные трассы"
        url={`/budget/vehicle/${vehiclePlanId}/federal-highway`}
      />
      <TabItem
        activeUrl={activeUrlPass}
        label="Пропуска"
        url={`/budget/vehicle/${vehiclePlanId}/spring-pass`}
      />
    </Tabs>
  );
};
