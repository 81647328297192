// @flow

import { fetchRequest } from './index';
import type { ListResponse } from './index';
import type { FileInfo } from '../types';

export const getFileResource = async (
  resource: string
): Promise<ListResponse<FileInfo>> => {
  const list = await fetchRequest.get(`/file/${resource}`);
  if (list) return list;
  throw new Error('Не удалось получить список файлов');
};

export const deleteFile = async (id: number): Promise<FileInfo> => {
  const file = await fetchRequest.delete(`/file/${id}`);
  if (file) return file;
  throw new Error('Не удалось удалить файл');
};

export const postFile = async (file: any): Promise<FileInfo> => {
  const fileInfo = await fetchRequest.post(`/file`, file);
  if (fileInfo) return fileInfo;
  throw new Error('Не удалось добавить файл');
};

export default {
  getFileResource,
  deleteFile,
  postFile,
};
