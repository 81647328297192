// @flow

import type { Dispatch } from 'redux';
import moment from 'moment';

import type { Shift, Trip, Order } from './../lib/types';
import {
  shiftApi,
  routeApi,
  contractVehicleOrderApi,
  userApi
} from './../lib/api';
import type { Action, AppState } from './redux';
import { ownerTypes } from '../lib/enum';
import { isDispatcher } from '../lib/helpers';

export const UPDATE_SHIFT = 'vehicles/shift/update';
export const FETCH_SHIFT = 'vehicles/shift/fetch';
export const CLEAN_SHIFT = 'vehicles/shift/clean';

export type ShiftState = ?Shift;

const initialState: ShiftState = null;

/**
 * Устанавливаем диспетчера по умолчанию,
 * если он не выбран, ставить по дефолту того кто
 * запрашивает наряд
 */
const setDefaultDispatcher = async (shift: Shift) => {
  const canEdit = moment.utc(shift.date).isSameOrAfter(moment().startOf('day')); // если дата просрочена не можем менять
  if (canEdit && shift.dispatcherId === null) {
    const { employeeId, employee } = await userApi.fetchProfile();
    if (isDispatcher(employee)) {
      shift = {
        ...shift,
        dispatcherId: employeeId,
        dispatcher: employee
      };
      return await shiftApi.updateShift(shift);
    }
  }
  return shift;
};

const reducer = (
  state: ShiftState = initialState,
  { type, payload }: Action
): ShiftState => {
  switch (type) {
    case FETCH_SHIFT:
      return { ...payload };
    case CLEAN_SHIFT:
      return null;
    default:
      return state;
  }
};

export const updateShift: Function = (shift: Shift): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  // TODO: Решить в ПР что делать с этой ф-й
  await shiftApi.updateShift(shift);
};

export const updateShiftTrip = (updateTrip: Trip) => (
  dispatch: Dispatch,
  getState: () => AppState
) => {
  const shift = getState().shift;
  if (shift) {
    dispatch({
      type: UPDATE_SHIFT,
      payload: {
        ...shift,
        trips: [
          shift.trips.filter((trip: Trip) => trip.id !== updateTrip.id),
          updateTrip
        ]
      }
    });
  }
};

export const fetchShift: Function = (id: number): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  let shift: Shift = await shiftApi.fetchShift(id);
  shift = await setDefaultDispatcher(shift);
  const trips = shift.trips
    .filter(trip => trip.vehicle && trip.vehicle.ownerType === ownerTypes.self)
    .map(async (trip: Trip) => {
      const newTrip: Trip = {
        ...trip
      };
      if (trip.expectedRouteId) {
        newTrip.expectedRoute = await routeApi.fetchRoute(trip.expectedRouteId);
      }
      if (trip.actualRouteId) {
        newTrip.actualRoute = await routeApi.fetchRoute(trip.actualRouteId);
      }
      return newTrip;
    });
  shift.trips = await Promise.all(trips);
  const orders = shift.orders.map(async (order: Order) => {
    const newOrder: Order = {
      ...order
    };
    const contractVehicleOrderId = parseInt(order.contractVehicleOrderId, 10);
    if (contractVehicleOrderId > 0) {
      newOrder.contractVehicleOrder = await contractVehicleOrderApi.fetchContractVehicleOrder(
        contractVehicleOrderId
      );
    }
    const routeId = parseInt(order.routeId, 10);
    if (routeId > 0) {
      newOrder.route = await routeApi.fetchRoute(routeId);
    }
    return newOrder;
  });
  shift.orders = await Promise.all(orders);
  dispatch({
    type: FETCH_SHIFT,
    payload: {
      ...shift
    }
  });
};

export const cleanShift: Function = (): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch({
    type: CLEAN_SHIFT
  });
};

export default reducer;
