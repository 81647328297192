// @flow
import React, { type Node } from 'react';
import { connect } from 'react-redux';

import type { AppState } from '../../../ducks/redux';
import { Wrapper, Left, Right } from './elements';

type Props = {
  left?: Node,
  right?: Node,
  sidebarOpened?: boolean,
  className?: string,
  style?: any
};

export const Header = ({
  left,
  right,
  style,
  className,
  sidebarOpened
}: Props) => (
  <Wrapper style={style} className={className}>
    <Left style={{ paddingLeft: !sidebarOpened ? 40 : 0 }}>{left}</Left>
    <Right>{right}</Right>
  </Wrapper>
);

export default connect((state: AppState) => ({
  sidebarOpened: state.sidebar.opened
}))(Header);
