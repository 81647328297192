// @flow
import React from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';

import { Popconfirm, Icon, Table, Operations } from './../../components/ui';
import Header from '../../components/layout/Header';
import { Section } from './../../components/layout';
import type { FuelMultiplier } from '../../lib/types/fuelMultiplier';
import api from '../../lib/api/fuelMultiplier';
import { notificationLoading } from './../../components/Notifications';

type Props = {};

type State = {
  multipliers: FuelMultiplier[],
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class FuelMultipliersList extends React.Component<Props, State> {
  state = {
    multipliers: [],
  };

  columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      width: 200,
      breakByWidth: true,
    },
    {
      title: 'Значение',
      dataIndex: 'value',
    },
    {
      title: 'Постоянный коэффициент',
      dataIndex: 'shouldUsedAlways',
      render: (shouldUsedAlways: boolean) => (shouldUsedAlways ? 'Да' : 'Нет'),
    },
    {
      title: '',
      // eslint-disable-next-line no-unused-vars
      render: (model: FuelMultiplier) => (
        <Operations>
          <Popconfirm
            title="Вы действительно хотите удалить?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => this.deleteFuelMultiplier(model)}
            placement="topRight"
          >
            <StyledIcon type="x" />
          </Popconfirm>
        </Operations>
      ),
    },
  ];

  deleteFuelMultiplier = async (fuelMultiplier: FuelMultiplier) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await api.deleteFuelMultiplier(parseInt(fuelMultiplier.id, 10));
      notification.success({
        message: 'Успешное удаление',
        description: `Коэффициент "${fuelMultiplier.name}" успешно удален`,
      });
      await this.fetchMultipliers();
    } catch (err) {
      notification.error({
        message: 'Ошибка при удалении',
        description: err.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchMultipliers = async () => {
    var result = await api.fetchFuelMultipliers();
    this.setState({
      multipliers: result.data,
    });
  };

  handleRowClick = (id: number) => navigate(`/admin/fuel-multipliers/${id}`);
  render() {
    const { multipliers } = this.state;
    return (
      <>
        <Header
          left={<h1>Топливные коэффициенты</h1>}
          right={
            <Link to="/admin/fuel-multipliers/new">
              <Button type="primary" data-cy="add">
                Создать
              </Button>
            </Link>
          }
        />

        <Section>
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            columns={this.columns}
            data={multipliers.map((model) => ({
              ...model,
              key: model.id,
            }))}
            fetchOnMount
            fetch={this.fetchMultipliers}
          />
        </Section>
      </>
    );
  }
}

export default FuelMultipliersList;
