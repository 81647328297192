//@flow
import { Link } from '@reach/router';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import React, { Fragment, useState } from 'react';

import Field from '../../../../../components/card/Field';
import { FormField } from '../../../../../components/Form';
import { SectionContent } from '../../../../../components/hoc/common/components/elements';
import CommonForm from '../../../../../components/hoc/common/handbook/CommonForm';
import { SectionTitle } from '../../../../../components/layout';

import Grid, { GridItem } from '../../../../../components/layout/Grid';
import VehicleContractPlanSelect from '../../../../../components/selects/VehicleContractPlanSelect';
import VehiclePlanSelect from '../../../../../components/selects/VehiclePlanSelect';
import { branchBudgetSummaryApi } from '../../../../../lib/api';

import {
  budgetLineItemBudgetInline,
  budgetSummaryStatus,
  budgetSummaryStatusEnum,
} from '../../../../../lib/enum';
import {
  contractVehiclePlanInfoString,
  formatDateRangeString,
  formatDateTimeToString,
  plus,
  vehiclePlanInfoString,
} from '../../../../../lib/helpers';
import { printNotification } from '../../../../../lib/notificationWrapper';
import type {
  BranchBudgetSummary,
  BranchBudgetSummaryLineItem,
  BudgetLineItemBudgetType,
  BudgetSummary,
  UserAccess,
} from '../../../../../lib/types';
import type { BudgetSummaryMonth } from '../../../../../lib/types/budgetSummary';

import Table from '../../../details/components/Table';
import BranchTabs from '../BranchTabs';
import Columns from '../TableColumns';
import { editDetailedBudgetAccessRight } from '../../../details/accessRight';
import { withUserAccess } from '../../../../withUserAccess';

type Props = {
  branchBudgetSummary?: BranchBudgetSummary,
  budgetSummary: BudgetSummary,
  orgUnitId: number,
  onSubmit: (payload: any) => any,
  userAccess: UserAccess[],
};

export default withUserAccess((props: Props) => {
  const [filter] = useState({
    status: 'approved',
    budgetStatus: 'calculationDone',
    budgetVersionId: props.budgetSummary?.budgetVersionId,
  });

  const print = () => {
    printNotification(
      async () =>
        props?.branchBudgetSummary?.id &&
        (await branchBudgetSummaryApi.print(props.branchBudgetSummary?.id))
    );
  };

  const summator = (months, ids: number[]) =>
    months.reduce(
      (sum, month) => {
        let summator = sum;
        if (ids.includes(month.month)) {
          summator.plan = plus(summator.plan, month.plan);
        }
        return summator;
      },
      {
        plan: 0,
      }
    );

  return (
    <>
      <CommonForm
        onSubmit={props.onSubmit}
        data={props.branchBudgetSummary}
        useFooter={false}
      >
        {({ values, setFieldValue, handleSubmit, dirty, isSubmitting }) => {
          let itogData: Map<
            BudgetLineItemBudgetType,
            BudgetSummaryMonth & {
              itogAfterLine: number,
            }
          > = new Map();

          const data =
            values.budgetSummaryLineItems?.map(
              (budget: BranchBudgetSummaryLineItem, index: number) => {
                const months = [
                  ...budget.months,
                  {
                    month: 13,
                    ...summator(budget.months, [1, 2, 3]),
                  },
                  {
                    month: 14,
                    ...summator(budget.months, [4, 5, 6]),
                  },
                  {
                    month: 15,
                    ...summator(budget.months, [7, 8, 9]),
                  },
                  {
                    month: 16,
                    ...summator(budget.months, [10, 11, 12]),
                  },
                  {
                    month: 17,
                    ...summator(
                      budget.months,
                      Array.from({ length: 12 }, (_, i) => i + 1)
                    ),
                  },
                ];
                // Формируем итог по месяцам
                const branchBudget = itogData.get(
                  budget.budgetLineItemBudgetType
                );
                itogData.set(budget.budgetLineItemBudgetType, {
                  itogAfterLine: index,
                  sum: plus(branchBudget?.sum ?? 0, budget.sum ?? 0),
                  months: months.map((month: BudgetSummaryMonth) => ({
                    month: month.month,
                    plan: plus(
                      branchBudget?.months?.find(
                        (el) => el.month === month.month
                      )?.plan ?? 0,
                      month.plan ?? 0
                    ),
                  })),
                });

                return {
                  ...budget,
                  expenseDirectionName: 'Итого',
                  rowId: index,
                  months,
                  expenseDirections: budget.expenseDirections.map(
                    (expenseDirection, index2) => ({
                      ...expenseDirection,
                      rowId: index * 1000 + index2,
                      months: [
                        ...expenseDirection.months,
                        {
                          month: 13,
                          ...summator(expenseDirection.months, [1, 2, 3]),
                        },
                        {
                          month: 14,
                          ...summator(expenseDirection.months, [4, 5, 6]),
                        },
                        {
                          month: 15,
                          ...summator(expenseDirection.months, [7, 8, 9]),
                        },
                        {
                          month: 16,
                          ...summator(expenseDirection.months, [10, 11, 12]),
                        },
                        {
                          month: 17,
                          ...summator(
                            expenseDirection.months,
                            Array.from({ length: 12 }, (_, i) => i + 1)
                          ),
                        },
                      ],
                    })
                  ),
                };
              }
            ) ?? [];

          if (data.length > 0) {
            let rowId = 0;

            for (let [budgetLineItemBudgetName, branchBudget] of itogData) {
              rowId--;
              // Не выводим undefined
              if (budgetLineItemBudgetName)
                // Вставляем итог с учетом уже вставленных итогов
                data.splice(branchBudget.itogAfterLine - rowId, 0, {
                  ...branchBudget,
                  budgetLineItemName:
                    'Итого по ' +
                    budgetLineItemBudgetInline[budgetLineItemBudgetName],
                  rowId,
                });
            }
          }
          const canEdit =
            (values.status === budgetSummaryStatusEnum.created ||
              values.status === budgetSummaryStatusEnum.declined) &&
            props.userAccess.some((access) =>
              editDetailedBudgetAccessRight.includes(access)
            );
          return (
            <>
              <Fragment noWrapMe>
                {+props.orgUnitId > 0 && (
                  <SectionTitle
                    divider
                    noWrapMe
                    suffix={
                      <>
                        <Button onClick={print}>Печать</Button>
                      </>
                    }
                  >
                    <BranchTabs
                      id={props.budgetSummary.id}
                      orgUnitId={props.orgUnitId}
                      branchBudgetStatus={props.branchBudgetSummary?.status}
                    />
                  </SectionTitle>
                )}
              </Fragment>
              <SectionContent noWrapMe>
                {values.id > 0 && (
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField label="Наименование" required name="name">
                        {({ name, value }) => {
                          return canEdit ? (
                            <Input
                              value={value}
                              onChange={(e) =>
                                setFieldValue(name, e.target.value)
                              }
                            />
                          ) : (
                            values.name
                          );
                        }}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <GridItem>
                        <FormField label="Статус" name="status">
                          {({ value }) => {
                            return budgetSummaryStatus[value];
                          }}
                        </FormField>
                      </GridItem>
                    </GridItem>

                    {!!values.declineReason &&
                      values.status !== budgetSummaryStatusEnum.approved && (
                        <GridItem fullWidth>
                          <FormField
                            label="Причина отклонения"
                            name="declineReason"
                          >
                            {({ value }) => {
                              return value;
                            }}
                          </FormField>
                        </GridItem>
                      )}
                    <GridItem>
                      <FormField
                        label="Наименование бюджета СТС"
                        required
                        name="selfVehiclePlanId"
                      >
                        {({ name, value }) => {
                          return canEdit ? (
                            <VehiclePlanSelect
                              value={value}
                              onChange={(value?: number, option: any) => {
                                !!value &&
                                  setFieldValue(
                                    'selfVehiclePlan',
                                    option.props.vehiclePlan
                                  );
                                setFieldValue(name, value);
                              }}
                              filter={filter}
                            />
                          ) : (
                            !!values.selfVehiclePlan && (
                              <Link to={`/budget/vehicle/${value}/card`}>
                                {vehiclePlanInfoString(values.selfVehiclePlan)}
                              </Link>
                            )
                          );
                        }}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Наименование бюджета НТС"
                        required
                        name="contractVehiclePlanId"
                      >
                        {({ name, value }) => {
                          return canEdit ? (
                            <VehicleContractPlanSelect
                              value={value}
                              onChange={(value?: number, option: any) => {
                                !!value &&
                                  setFieldValue(
                                    'contractVehiclePlan',
                                    option.props.vehiclePlan
                                  );
                                setFieldValue(name, value);
                              }}
                              filter={filter}
                            />
                          ) : (
                            !!values.contractVehiclePlan && (
                              <Link
                                to={`/budget/contract-vehicle/fixed/${value}/card`}
                              >
                                {contractVehiclePlanInfoString(
                                  values.contractVehiclePlan
                                )}
                              </Link>
                            )
                          );
                        }}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      {values.selfVehiclePlan && (
                        <Field label="Период формирования">
                          {`${formatDateRangeString(
                            values.selfVehiclePlan.startDate,
                            values.selfVehiclePlan.endDate,
                            'DD.MM.YYYY'
                          )}`}
                        </Field>
                      )}
                    </GridItem>
                    <GridItem>
                      {values.contractVehiclePlan && (
                        <Field label="Период формирования">
                          {`${formatDateRangeString(
                            values.contractVehiclePlan.startDate,
                            values.contractVehiclePlan.endDate,
                            'DD.MM.YYYY'
                          )}`}
                        </Field>
                      )}
                    </GridItem>
                    <GridItem>
                      {values.selfVehiclePlan && (
                        <Field label="Дата формирования">
                          {`${formatDateTimeToString(
                            values.selfVehiclePlan.date,
                            'DD.MM.YYYY'
                          )}`}
                        </Field>
                      )}
                    </GridItem>
                    <GridItem>
                      {values.contractVehiclePlan && (
                        <Field label="Дата формирования">
                          {`${formatDateTimeToString(
                            values.contractVehiclePlan.date,
                            'DD.MM.YYYY'
                          )}`}
                        </Field>
                      )}
                    </GridItem>
                  </Grid>
                )}
                {dirty && (
                  <GridItem>
                    <Field label=" ">
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        Сохранить
                      </Button>
                    </Field>
                  </GridItem>
                )}
                <Table data={data} columns={Columns} loading={true} />
              </SectionContent>
            </>
          );
        }}
      </CommonForm>
    </>
  );
});
