// @flow

import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type { User, UserAccess } from './../../lib/types';
import type { Employee, ListState } from '../../lib/types';
import { accessTypeEnum } from '../../lib/enum';
import { userApi } from '../../lib/api';
import type { FetchListParams } from '../../lib/api';
import {
  convertEmployeeToString,
  getListInitialState,
  setQueryParams,
} from '../../lib/helpers';

import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import { notificationLoading } from './../../components/Notifications';
import { withUserAccess } from '../withUserAccess';

import Filter from './Filter';
import type { UsersFilterParams } from './Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<User> & {
  filter: UsersFilterParams,
  columns: Array<Object>,
};

class UserList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: [
      {
        title: 'Логин',
        dataIndex: 'userName',
        sorter: true,
      },
      {
        title: 'Роль',
        dataIndex: 'roleName',
        sorterKey: 'role.name',
        sorter: true,
      },
      {
        title: 'ФИО',
        dataIndex: 'employee',
        sorter: true,
        sorterKey: 'employee.person.lastName',
        render: (employee: Employee) => convertEmployeeToString(employee),
      },
      {
        title: 'Подразделение',
        dataIndex: 'employee.orgUnitName',
        sorter: true,
        sorterKey: 'employee.node.name',
      },
    ],
  };

  static defaultProps = {
    location: {},
  };

  async deleteUser(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await userApi.deleteUser(id);
      await this.fetchUsers(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  handleRowClick = (id: number) => navigate(`/admin/users/${id}`);

  fetchUsers = async (
    page: number = 1,
    params: FetchListParams<UsersFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await userApi.fetchUsers({
      ...filter,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  cleanFilter = () => this.setState({ filter: {} }, this.fetchUsers);

  applyFilter = (filter: UsersFilterParams) =>
    this.setState({ filter }, this.fetchUsers);

  async componentDidMount() {
    const { columns } = this.state;
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.fetchUsers(page));
    if (this.canAdd()) {
      this.setState({
        columns: [
          ...columns,
          {
            title: ' ',
            dataIndex: 'id',
            // eslint-disable-next-line no-unused-vars
            render: (text: string, record: User): any => (
              <Operations>
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={async () => await this.deleteUser(record.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              </Operations>
            ),
          },
        ],
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingUsersAndRoles,
      ].includes(access)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, filter, loading, columns } =
      this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Пользователи</h1>}
          right={
            canAdd && (
              <Link to="/admin/users/new">
                <Button type="primary" data-cy="addUser">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={columns}
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            fetch={this.fetchUsers}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export default withUserAccess(UserList);
