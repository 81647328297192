// @flow

import type { Route } from './../types';

import { fetchRequest } from './index';
import { convertFromKmToM, convertFromMToKm } from './../helpers';

const convertDataFromServer = (route: Route) => ({
  ...route,
  distance: convertFromMToKm(route.distance)
});
const convertDataToServer = (route: Route) => ({
  ...route,
  distance: convertFromKmToM(route.distance)
});

export const fetchRoute = async (
  id: number,
  params: any = {}
): Promise<Route> => {
  const route = await fetchRequest.get(`/route/${id}`, params);
  if (route) return convertDataFromServer(route);
  throw new Error('Не удалось загрузить маршрут');
};

export const deleteRoute = async (id: number): Promise<Route> => {
  const route = await fetchRequest.delete(`/route/${id}`);
  if (route) return convertDataFromServer(route);
  throw new Error('Не удалось удалить маршрут');
};

export const cloneRoute = async (id: number): Promise<Route> => {
  const route = await fetchRequest.post(`/route/${id}/clone`);
  if (route) return convertDataFromServer(route);
  throw new Error('Не удалось склонировать маршрут');
};

export const addRoute = async (route: Route): Promise<Route> => {
  const added = await fetchRequest.post('/route', convertDataToServer(route));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать маршрут');
};

export const updateRoute = async (route: Route): Promise<Route> => {
  const updated = await fetchRequest.put('/route', convertDataToServer(route));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить маршрут');
};

export default {
  addRoute,
  updateRoute,
  fetchRoute,
  deleteRoute,
  cloneRoute
};
