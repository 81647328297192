// @flow
import { fetchRequest } from './index';
import type { SideServiceTariff } from '../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

const convertDataToServer = (sideServiceTariff: SideServiceTariff) => ({
  ...sideServiceTariff,
  price: convertFromRubToKop(sideServiceTariff.price),
});
const convertDataFromServer = (sideServiceTariff: SideServiceTariff) => ({
  ...sideServiceTariff,
  price: convertFromKopToRub(sideServiceTariff.price),
});
const baseUrl = 'sideServiceTariff';
const sideServiceTariff = new CommonApi<SideServiceTariff>(
  baseUrl,
  'тарифы для услуг на сторону',
  convertDataToServer,
  convertDataFromServer
);
const current = async (params: any): Promise<SideServiceTariff | null> => {
  const sideServiceTariff = await fetchRequest.get(`/${baseUrl}/current`, {
    ...params,
  });
  if (sideServiceTariff) return convertDataFromServer(sideServiceTariff);
  return null;
};
export default { ...sideServiceTariff, current };
