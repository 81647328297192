// @flow
import UniqBy from 'lodash/uniqBy';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Selects } from '../../../../components';

import type { CommonFormProps } from '../../../../components/hoc/common/handbook/CommonForm';
import CommonForm from '../../../../components/hoc/common/handbook/CommonForm';
import {
  Section,
  SectionContent,
  SectionTitle,
} from '../../../../components/layout';
import { Icon, ListTable } from '../../../../components/ui';

import { pddTestApi } from '../../../../lib/api';
import { withEmptyRow, withoutEmptyRow } from '../../../../lib/helpers';
import type { PddTicket } from '../../../../lib/types';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const { PddQuestionSelect } = Selects;

export default (
  props: CommonFormProps<$Shape<PddTicket>> & { pddTestId?: number }
) => {
  const { pddTestId } = props;
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        const [pddTestGroups, setPddTestGroups] = useState(null);

        const setPddQuestions = (
          oldPddQuestionId: number,
          newPddQuestionId: number
        ) => {
          let oldPddTicketQuestions = values?.pddTicketQuestions ?? [];
          if (oldPddQuestionId)
            oldPddTicketQuestions = oldPddTicketQuestions.filter(
              (el) => el.pddQuestionId !== oldPddQuestionId
            );
          if (newPddQuestionId)
            oldPddTicketQuestions.push({ pddQuestionId: newPddQuestionId });
          setFieldValue('pddTicketQuestions', [...oldPddTicketQuestions]);
        };

        useEffect(() => {
          const fetch = async () => {
            setPddTestGroups(null);
            if (values.pddTestId) {
              const { pddTestGroups } = await pddTestApi.get(values.pddTestId);
              setPddTestGroups(pddTestGroups);
            }
          };
          fetch();
        }, [values.pddTestId]);

        useEffect(() => {
          setFieldValue('pddTestId', pddTestId);
        }, [setFieldValue]);

        return (
          <>
            {pddTestGroups &&
              pddTestGroups.map((group) => (
                <Section noWrapMe>
                  <SectionTitle divider>
                    {group.pddGroup.name} кол-во вопросов: {group.questionCount}
                  </SectionTitle>
                  <SectionContent>
                    <Question
                      count={group.questionCount}
                      questions={
                        values?.pddTicketQuestions?.filter(
                          (pddTicketQuestion) =>
                            pddTicketQuestion?.pddQuestion?.groupId ===
                            group.pddGroupId
                        ) ?? []
                      }
                      selectedQuestions={values?.pddTicketQuestions?.map(
                        (pddTicketQuestion) => pddTicketQuestion.pddQuestionId
                      )}
                      groupId={group.pddGroupId}
                      setPddQuestions={setPddQuestions}
                    />
                  </SectionContent>
                </Section>
              ))}
          </>
        );
      }}
    </CommonForm>
  );
};

type QuestionProps = {
  count: number,
  questions: any[],
  groupId: number,
  setPddQuestions: Function,
  selectedQuestions?: number[],
};
const Question = ({
  count,
  questions,
  groupId,
  setPddQuestions,
  selectedQuestions,
}: QuestionProps) => {
  const [data, setData] = useState(questions);

  const columns = [
    {
      title: '',
      key: 'pddQuestionId',
      style: {
        overflow: 'hidden',
      },
      dataIndex: 'pddQuestionId',
      render: (id: number, record: any, index: number) => {
        return (
          <PddQuestionSelect
            filter={{ groupId }}
            value={id}
            onChange={(pddQuestionId: number) => {
              setPddQuestions(id, pddQuestionId);

              data.splice(index, 1, { pddQuestionId });
              setData(UniqBy([...data], 'pddQuestionId'));
            }}
            disabledKeys={selectedQuestions}
          />
        );
      },
    },
    {
      width: '20px',
      renderRecord: (record, index: number) => {
        return (
          <Operations>
            <StyledIcon
              onClick={() => {
                if (record?.id) setPddQuestions(record.pddQuestionId, null);
                setData(
                  data.filter(
                    (data: any, dataIndex: number) => dataIndex !== index
                  )
                );
              }}
              type="x"
            />
          </Operations>
        );
      },
    },
  ];

  return (
    <ListTable
      columns={columns}
      data={data.length >= count ? withoutEmptyRow(data) : withEmptyRow(data)}
    />
  );
};
