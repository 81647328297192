// @flow
import Button from 'antd/lib/button';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../components/ant/DatePicker';
import type { FormFieldType } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';

import type { MaintenanceOperationPrice } from '../../../lib/types';
import { Selects } from './../../../components';
import CancelButton from './../../../components/CancelButton';
import Form from './../../../components/Form';
import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import OrgUnitSelect from './../../../components/OrgUnitSelect';

const { WorkHourPriceSelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  maintenanceOperationPrice: ?MaintenanceOperationPrice,
  onCancel: Function,
  onSubmit: Function
};

export default ({ maintenanceOperationPrice, onCancel, onSubmit }: Props) => {
  return (
    <Form initialValues={maintenanceOperationPrice} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Филиал" required name="orgUnitId" fast>
                  {({ name, value }) => (
                    <OrgUnitSelect
                      onlyBranches
                      placeholder="Выберите филиал"
                      name={name}
                      value={value}
                      onChange={orgUnitId => setFieldValue(name, orgUnitId)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Вид нормо-часа"
                  required
                  name="workingHourPriceType"
                  fast
                >
                  {({ name, value }) => (
                    <WorkHourPriceSelect
                      placeholder="Выберите вид нормо-часа"
                      name={name}
                      value={value}
                      onChange={workHourPrice =>
                        setFieldValue(name, workHourPrice)
                      }
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Стоимость нормо-часа"
                  fast
                  required
                  name="workingHourCost"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      onChange={cost => setFieldValue(name, cost)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Дата начала" name="startDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                        setFieldValue('endDate', undefined);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата окончания" name="endDate">
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.startDate}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) =>
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        )
                      }
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {values.id ? 'Сохранить' : 'Создать'}
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
};
