// @flow
import Checkbox from 'antd/lib/checkbox';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DatePicker } from '../../../../../components/ant/DatePicker';
import Grid, { GridItem } from './../../../../../components/layout/Grid';
import Spinner from './../../../../../components/Spinner';

import ListTable from './../../../../../components/ui/ListTable';

import { setVehiclePlanVehicle } from './../../../../../ducks/vehiclePlan';
import { vehicleStatuses, vehicleTypes } from './../../../../../lib/enum';

import type { SelfVehiclePlanVehicle, VehiclePlan } from './../../../../../lib/types';

import type { VehicleListFilterParams } from './../../components/FilterVehicleList';
import Filter from './../../components/FilterVehicleList';

type Props = {
  data: SelfVehiclePlanVehicle[],
  vehiclePlan: ?$Shape<VehiclePlan>,
  vehiclePlanType: string,
  setVehiclePlanVehicle: Function,
  selectedVehicle?: SelfVehiclePlanVehicle[],
  loading?: boolean
};

type State = {
  localData: SelfVehiclePlanVehicle[],
  selectedVehicle: Map<number, SelfVehiclePlanVehicle>,
  filter: VehicleListFilterParams
};

const Row = styled(Grid).attrs(props => {
  return {
    column: props.column || false
  };
})`
  font-size: 12px;
  grid-template-columns: 30px 1fr 1fr 1fr 150px 150px ${props =>
      props.column ? '1fr' : ''};
`;
const ListHeader = styled(Row)`
  background: #e4ebf2;
  font-weight: bold;
  min-width: 730px;
  border-radius: 2px 2px 0px 0px;
`;
const Cell = styled(GridItem)`
  padding: 11px 16px;
`;
const CheckboxCell = styled(Cell)`
  width: 30px;
`;

const RenderRow = props => {
  const {
    row,
    selected,
    addVehicle,
    deleteVehicle,
    isSelfVehicle,
    indexRow,
    changeDateEnd,
    changePlannedPurchaseDate
  } = props;
  return (
    <Row column={true}>
      <CheckboxCell>
        <Checkbox
          checked={selected}
          onChange={e => {
            if (e.target.checked) {
              addVehicle(row.vehicleId, row);
            } else {
              deleteVehicle(row.vehicleId);
            }
          }}
        />
      </CheckboxCell>
      <Cell>{vehicleTypes[row.vehicle?.vehicleModel?.type]}</Cell>
      <Cell>
        {`${row.vehicle.vehicleModel.brandName} ${row.vehicle.vehicleModel.name}`}
      </Cell>
      <Cell>{row.vehicle.licensePlate}</Cell>
      <Cell>{row.vehicle.yearIssued}</Cell>
      <Cell>{vehicleStatuses[row.vehicle.status]}</Cell>
      <Cell>
        <DatePicker
          size={'small'}
          format="DD MMMM YYYY"
          defaultValue={
            !isSelfVehicle
              ? row.plannedPurchaseDate && moment(row.plannedPurchaseDate).utc()
              : row.plannedWriteoffDate && moment(row.plannedWriteoffDate).utc()
          }
          onChange={(value: string) => {
            isSelfVehicle
              ? changeDateEnd(value, row, indexRow)
              : changePlannedPurchaseDate(value, row, indexRow);
          }}
        />
      </Cell>
    </Row>
  );
};

class InnerTable extends React.PureComponent<Props, State> {
  state = {
    localData: [],
    selectedVehicle: new Map(),
    filter: {}
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      let selectedVehicle = new Map();
      if (isEmpty(this.props.selectedVehicle)) {
        this.props.data.forEach(item =>
          selectedVehicle.set(item.vehicleId, item)
        );
      } else {
        if (Array.isArray(this.props.selectedVehicle))
          this.props.selectedVehicle.forEach(item => {
            selectedVehicle.set(item.vehicleId, item);
          });
      }
      this.setState(
        { selectedVehicle, localData: [...this.props.data] },
        this.setVehicle
      );
    }
  }

  addVehicle = (vehicleId: number, data: SelfVehiclePlanVehicle) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      selectedVehicle.set(vehicleId, data);
      return { selectedVehicle };
    }, this.setVehicle);
  };

  deleteVehicle = (vehicleId: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      selectedVehicle.delete(vehicleId);
      return { selectedVehicle };
    }, this.setVehicle);
  };

  setVehicle = () =>
    this.props.setVehiclePlanVehicle(
      this.state.selectedVehicle,
      this.props.vehiclePlanType === 'selfVehicles'
    );

  onChangeMonth = (name: string, value: boolean, index: number) => {
    let { localData } = this.state;
    localData[index][name] = value;
    this.setState({ localData }, () =>
      this.addVehicle(localData[index]['vehicleId'], localData[index])
    );
  };

  header = () => {
    const { data, vehiclePlanType } = this.props;
    const { selectedVehicle, filter } = this.state;
    const isSelfVehicle = vehiclePlanType === 'selfVehicles';
    return (
      <ListHeader column={true}>
        <CheckboxCell>
          {isEmpty(filter) && (
            <Checkbox
              indeterminate={
                selectedVehicle.size > 0 && selectedVehicle.size < data.length
              }
              checked={
                selectedVehicle.size === data.length && data.length !== 0
              }
              onChange={e => {
                let selectedVehicle = new Map();
                if (e.target.checked) {
                  data.forEach(item =>
                    selectedVehicle.set(item.vehicleId, item)
                  );
                }
                this.setState({ selectedVehicle }, this.setVehicle);
              }}
            />
          )}
        </CheckboxCell>
        <Cell>Тип ТС</Cell>
        <Cell>Марка и модель</Cell>
        <Cell>Госномер</Cell>
        <Cell>Год выпуска</Cell>
        <Cell>Текущий статус</Cell>
        {isSelfVehicle ? (
          <Cell>Планируемая дата списания</Cell>
        ) : (
          <Cell>Планируемая дата приобретения</Cell>
        )}
      </ListHeader>
    );
  };

  changeDateEnd = (date: moment, row: any, indexRow: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      const data = {
        ...row,
        plannedWriteoffDate: date
          ? moment
              .utc(date)
              .startOf('day')
              .toISOString()
          : null
      };
      selectedVehicle.get(row.vehicleId) &&
        selectedVehicle.set(row.vehicleId, data);

      return { selectedVehicle, localData: state.localData };
    }, this.setVehicle);
  };

  changePlannedPurchaseDate = (date: moment, row: any, indexRow: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      const data = {
        ...row,
        plannedPurchaseDate: date
          ? moment
              .utc(date)
              .startOf('day')
              .toISOString()
          : null
      };
      selectedVehicle.get(row.vehicleId) &&
        selectedVehicle.set(row.vehicleId, data);

      return { selectedVehicle, localData: state.localData };
    }, this.setVehicle);
  };

  applyFilter = filter => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  render() {
    const { loading, vehiclePlanType } = this.props;
    const { selectedVehicle, localData, filter } = this.state;
    return (
      <>
        <Filter
          filter={filter}
          showHideEmpty={false}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
        />
        <Spinner isLoading={loading}>
          <ListTable
            key={vehiclePlanType}
            style={{ overflowX: 'auto' }}
            styleContent={{ minWidth: '730px' }}
            data={localData
              .filter((selfVehicle: SelfVehiclePlanVehicle) => {
                const { yearIssued } = filter;
                return yearIssued
                  ? selfVehicle.vehicle.yearIssued === yearIssued
                  : true;
              })
              .filter((selfVehicle: SelfVehiclePlanVehicle) => {
                const { vehicleModelId } = filter;
                return vehicleModelId
                  ? selfVehicle.vehicle.vehicleModelId === vehicleModelId
                  : true;
              })
              .filter((selfVehicle: SelfVehiclePlanVehicle) => {
                const { vehicleId } = filter;
                return vehicleId ? selfVehicle.vehicle.id === vehicleId : true;
              })}
            header={this.header()}
            renderRow={(row, index) => {
              return (
                <RenderRow
                  indexRow={index}
                  isSelfVehicle={vehiclePlanType === 'selfVehicles'}
                  onChangeMonth={this.onChangeMonth}
                  changeDateEnd={this.changeDateEnd}
                  changePlannedPurchaseDate={this.changePlannedPurchaseDate}
                  key={row.id}
                  idx={row.id}
                  selected={selectedVehicle.has(row.vehicleId)}
                  row={row}
                  addVehicle={this.addVehicle}
                  deleteVehicle={this.deleteVehicle}
                />
              );
            }}
          />
        </Spinner>
      </>
    );
  }
}

export default connect(() => ({}), { setVehiclePlanVehicle })(InnerTable);
