// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { BioTreatmentFacilityContract } from '../../lib/types';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { Header, Panel } from '../../components/layout';
import InnerForm from './components/InnerForm';
import { goBack } from './../../lib/helpers';
import { notificationLoading } from './../../components/Notifications';
import { bioTreatmentFacilityContractApi } from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id?: number,
};

type State = {
  bioTreatmentFacilityContract: ?BioTreatmentFacilityContract,
};

export class Form extends React.Component<Props, State> {
  state = {
    // $FlowFixMe
    bioTreatmentFacilityContract: {
      volumes: [],
    },
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      if (id) {
        const bioTreatmentFacilityContract =
          await bioTreatmentFacilityContractApi.get(id);
        this.setState({ bioTreatmentFacilityContract });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  handleSubmit = async (values: BioTreatmentFacilityContract) => {
    let bioTreatmentFacilityContract = null;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        bioTreatmentFacilityContract =
          await bioTreatmentFacilityContractApi.update(values);
      } else {
        bioTreatmentFacilityContract =
          await bioTreatmentFacilityContractApi.add(values);
      }
      navigate(
        `/bioTreatmentFacilityContract/${bioTreatmentFacilityContract.id}`
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/bioTreatmentFacility');

  render() {
    const { bioTreatmentFacilityContract } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {bioTreatmentFacilityContract ? (
                <Crumb>БОС №{bioTreatmentFacilityContract?.id ?? ''}</Crumb>
              ) : (
                  <Crumb>Новая запись БОС</Crumb>
                )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {bioTreatmentFacilityContract
              ? `БОС №${bioTreatmentFacilityContract?.id ?? ''}`
              : 'Добавление новой записи'}
          </h1>
        </StyledPanel>
        <InnerForm
          bioTreatmentFacilityContract={bioTreatmentFacilityContract}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default Form;
