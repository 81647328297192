//@flow

import React, { Component } from 'react';
import notification from 'antd/lib/notification';

import type { Battery } from './../../../../lib/types';
import { batteryApi } from './../../../../lib/api';
import { notificationLoading } from './../../../../components/Notifications';

type Props = {
  battery?: Battery,
  afterSubmit: () => void | Promise<void>
};

class CopyBatteryButton extends Component<Props> {
  copyBattery = async () => {
    const { battery, afterSubmit } = this.props;
    try {
      if (!battery) {
        return;
      }
      notificationLoading({
        message: 'Копирование...',
        key: 'copying'
      });
      const newCopy = {
        batchNumber: battery.batchNumber,
        brand: battery.brand,
        capacity: battery.capacity,
        code: battery.code,
        name: battery.name,
        voltage: battery.voltage,
        norm: battery.norm,
        orgUnitId: battery.orgUnitId
      };
      await batteryApi.addBattery(newCopy);

      notification.success({
        message: 'АКБ скопирована',
        description: `Копия АКБ создана`
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('copying');
      if (afterSubmit instanceof Function) {
        afterSubmit();
      }
    }
  };

  render() {
    return <div onClick={this.copyBattery}>Копировать</div>;
  }
}

export default CopyBatteryButton;
