// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportWeekendOrders,
  ReportWeekendOrdersParams
} from '../types/reportWeekendOrders';

/**
 * Получение отчета
 */
export const fetchReportWeekendOrders = async (
  params: ReportWeekendOrdersParams
): Promise<ReportWeekendOrders[]> => {
  const report = await fetchRequest.get('/report/weekendOrder', {
    ...params
  });
  return report;
};

/**
 * Печать отчета
 */
export const printReportWeekendOrders = async (
  params: ReportWeekendOrdersParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/weekendOrder/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportWeekendOrders,
  printReportWeekendOrders
};
