// @flow
import { plus } from '../../../lib/helpers';
import { budgetLineItemBudgetInline } from '../../../lib/enum';

export const sumBudgetLineItemBudget = (budgetSummaryLineItems: any[]) => {
  let itogData: Map<any, any> = new Map();

  let newBudgetSummaryLineItems =
    // $FlowFixMe
    budgetSummaryLineItems?.map((budget, index) => {
      const branchBudget = itogData.get(budget.budgetLineItemBudgetType);

      itogData.set(budget.budgetLineItemBudgetType, {
        itogAfterLine: index,
        sum: plus(branchBudget?.sum ?? 0, budget.sum ?? 0),
        economy: plus(
          parseFloat(branchBudget?.economy) > 0 ? branchBudget?.economy : 0,
          budget.economy ?? 0
        ),
        economyResidue: plus(
          branchBudget?.economyResidue ?? 0,
          budget.economyResidue ?? 0
        ),
        factSum: plus(branchBudget?.factSum ?? 0, budget.factSum ?? 0),
        additionalNeed: plus(
          branchBudget?.additionalNeed ?? 0,
          budget.additionalNeed ?? 0
        ),
        coveredByOpenPeriod: plus(
          branchBudget?.coveredByOpenPeriod ?? 0,
          budget.coveredByOpenPeriod ?? 0
        ),
        coveredByClosedPeriod: plus(
          branchBudget?.coveredByClosedPeriod ?? 0,
          budget.coveredByClosedPeriod ?? 0
        ),
        economyResidueFromOpenPeriod: plus(
          branchBudget?.economyResidueFromOpenPeriod ?? 0,
          budget.economyResidueFromOpenPeriod ?? 0
        ),
        economyResidueFromClosedPeriod: plus(
          branchBudget?.economyResidueFromClosedPeriod ?? 0,
          budget.economyResidueFromClosedPeriod ?? 0
        ),
        budgetChange: plus(
          branchBudget?.budgetChange ?? 0,
          budget.budgetChange ?? 0
        ),
        months:
          budget.months.length > 0
            ? budget?.months?.map(month => {
                const monthIndex = branchBudget?.months?.findIndex(
                  el => el.month === month.month
                );

                return {
                  ...month,
                  economy: plus(
                    branchBudget?.months[monthIndex]?.economy ?? 0,
                    month.economy ?? 0
                  ),
                  economyResidue: plus(
                    branchBudget?.months[monthIndex]?.economyResidue ?? 0,
                    month.economyResidue ?? 0
                  ),
                  need: plus(
                    branchBudget?.months[monthIndex]?.need ?? 0,
                    month.need ?? 0
                  ),
                  plan: plus(
                    branchBudget?.months[monthIndex]?.plan ?? 0,
                    month.plan ?? 0
                  ),
                  fact: plus(
                    branchBudget?.months[monthIndex]?.fact ?? 0,
                    month.fact ?? 0
                  )
                };
              }) ?? []
            : [...(branchBudget?.months ?? [])]
      });

      return {
        ...budget,
        expenseDirectionName: 'Итого',
        rowId: index,
        expenseDirections:
          budget?.expenseDirections?.map((expenseDirection, index2) => ({
            ...expenseDirection,
            rowId: index * 1000 + index2
          })) ?? []
      };
    }) ?? [];

  if (newBudgetSummaryLineItems.length > 0) {
    let rowId = 0;

    for (let [budgetLineItemBudgetName, branchBudget] of itogData) {
      rowId--;
      // Не выводим undefined
      if (budgetLineItemBudgetName)
        // Вставляем итог с учетом уже вставленных итогов
        newBudgetSummaryLineItems.splice(
          branchBudget.itogAfterLine - rowId,
          0,
          {
            ...branchBudget,
            budgetLineItemName:
              'Итого по ' +
              budgetLineItemBudgetInline[budgetLineItemBudgetName],
            rowId
          }
        );
    }
  }

  return newBudgetSummaryLineItems;
};
