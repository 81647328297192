import type { Action } from './redux';
import { Dispatch } from 'redux';

const OPEN_SIDEBAR = 'vehicles/sidebar/open';
const CLOSE_SIDEBAR = 'vehicles/sidebar/close';
const TOGGLE_SIDEBAR = 'vehicles/sidebar/toggle';
const HIDE_SIDEBAR_BURGER = 'vehicles/sidebar/hideSidebarBurger';
const SHOW_SIDEBAR_BURGER = 'vehicles/sidebar/showSidebarBurger';

type SidebarState = {
  opened: boolean,
  isBurgerHidden: boolean
};

const initialState: SidebarState = {
  opened: true,
  isBurgerHidden: false
};

const reducer = (state: SidebarState = initialState, action: Action) => {
  const { type } = action;
  switch (type) {
    case OPEN_SIDEBAR:
      return { ...state, opened: true };
    case CLOSE_SIDEBAR:
      return { ...state, opened: false };
    case TOGGLE_SIDEBAR:
      return { ...state, opened: !state.opened };
    case HIDE_SIDEBAR_BURGER:
      return { ...state, isBurgerHidden: true };
    case SHOW_SIDEBAR_BURGER:
      return { ...state, isBurgerHidden: false };
    default:
      return state;
  }
};

export const openSidebar = () => (dispatch: Dispatch) =>
  dispatch({ type: OPEN_SIDEBAR });

export const closeSidebar = () => (dispatch: Dispatch) =>
  dispatch({ type: CLOSE_SIDEBAR });

export const hideSidebarBurger = () => (dispatch: Dispatch) =>
  dispatch({ type: HIDE_SIDEBAR_BURGER });

export const showSidebarBurger = () => (dispatch: Dispatch) =>
  dispatch({ type: SHOW_SIDEBAR_BURGER });

export const toggleSidebar = () => (dispatch: Dispatch) =>
  dispatch({ type: TOGGLE_SIDEBAR });

export default reducer;
