import {
    MaintenanceOperationGroupForm,
    MaintenanceOperationsTable,
} from '../../containers/MaintenanceOperations';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MaintenanceOperationsTable,
        path: '/admin/maintenance-operation-groups',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingMaintenanceOperationGroups,
            accessTypeEnum.handlingMaintenanceOperationGroups,
        ],
    },
    {
        component: MaintenanceOperationGroupForm,
        path: '/admin/maintenance-operation-groups/:maintenanceOperationGroupId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingMaintenanceOperationGroups,
        ],
    },
    {
        component: MaintenanceOperationGroupForm,
        path: '/admin/maintenance-operation-groups/:parentMaintenanceOperationGroupId/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingMaintenanceOperationGroups,
        ],
    },
];