// @flow

import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { Option, Select } from './../ant/Select';

const vehicleCategories = [
  'L1',
  'L2',
  'L3',
  'L4',
  'L5',
  'L6',
  'L7',
  'M1',
  'M2',
  'M3',
  'N1',
  'N2',
  'N3',
  'O1',
  'O2',
  'O3',
  'O4'
];
// Выпадающий список категорий
export default (props: SelectProps) => (
  <Select {...props} allowClear>
    {vehicleCategories.map(category => (
      <Option value={category} key={category}>
        {category}
      </Option>
    ))}
  </Select>
);
