// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type {
  MaintenanceOperation,
  MaintenanceOperationPrice,
  OrderContractorType
} from '../../../../lib/types';
import { orderContractorTypeEnum } from './../../../../lib/enum';
import {
  withEmptyRow,
  withoutEmptyRow,
  multipliedBy
} from './../../../../lib/helpers';
import { saveOperations } from '../../../../ducks/detailedDefectiveStatement';

import { Selects, Card } from './../../../../components';
import { Icon, ListTable } from './../../../../components/ui';
import Grid, { GridItem } from './../../../../components/layout/Grid';

import { itog, operationColumns } from './../elements';
import { sumOperations } from './../lib';

const { MaintenanceOperationSelect } = Selects;
const { Field } = Card;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const OperationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const SelectWrapper = styled(Grid)`
  margin-bottom: 16px;
`;

type Props = {
  saveOperations: Function,
  orderContractorType: OrderContractorType,
  operations: MaintenanceOperation[],
  maintenanceId: number,
  maintenanceOperationPrice: ?MaintenanceOperationPrice
};

type State = {
  touched: boolean,
  columns: any[],
  operations: MaintenanceOperation[]
};

/**
 * Работы
 */
class Operations extends React.Component<Props, State> {
  static defaultProps = {
    detailedDefectiveStatement: null
  };

  state = {
    touched: false,
    columns: [],
    operations: []
  };

  componentDidMount() {
    const columns = [
      ...operationColumns(this.onChange),
      {
        width: '20px',
        renderRecord: (record: MaintenanceOperation, index: number) =>
          !isEmpty(record) ? (
            <OperationButtons>
              <StyledIcon onClick={() => this.onDelete(index)} type="x" />
            </OperationButtons>
          ) : null
      }
    ];
    this.setState({ columns, operations: this.props.operations });
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.operations, prevProps.operations)) {
      this.setState({ operations: this.props.operations });
    }
  }

  isContractor = () =>
    this.props.orderContractorType === orderContractorTypeEnum.contractor;

  onChange = (index: number, key: $Keys<MaintenanceOperation>, value: any) => {
    let operations = withEmptyRow([...this.state.operations]);
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    operations.splice(index, 1, {
      ...operations[index],
      [(key: string)]: value
    });
    this.setState({ operations });
  };

  onDelete = (index: number) => {
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    this.setState({
      operations: this.state.operations.filter((value, id) => id !== index)
    });
  };

  onSelectOperation = (value: MaintenanceOperation, row: any) => {
    const { maintenanceoperation } = row.props;
    const { maintenanceOperationPrice, maintenanceId } = this.props;
    const { operations } = this.state;
    let price = 0;
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    if (maintenanceoperation.hours && maintenanceOperationPrice) {
      price = multipliedBy(
        maintenanceoperation.hours,
        maintenanceOperationPrice.workingHourCost
      );
    }
    this.setState({
      operations: [
        ...withoutEmptyRow(operations),
        {
          ...maintenanceoperation,
          price,
          maintenanceId: maintenanceId,
          id: undefined
        }
      ]
    });
  };

  handleSubmit = async () => {
    const { saveOperations } = this.props;
    const { operations } = this.state;
    try {
      withoutEmptyRow(operations).forEach(operation => {
        if (!operation.name || !operation.count) {
          throw new Error(
            `Проверьте заполненность полей: наименование, количество`
          );
        }
      });
      await saveOperations(withoutEmptyRow(operations));
      this.setState({ touched: false });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { columns, touched, operations } = this.state;
    const data = withEmptyRow(operations);
    const { sum, sumContractor } = sumOperations(
      withoutEmptyRow(data),
      this.isContractor()
    );
    return (
      <>
        <SelectWrapper cols={3}>
          <GridItem>
            <Field label="Вид работ">
              <MaintenanceOperationSelect
                onChange={this.onSelectOperation}
                value={undefined}
              />
            </Field>
          </GridItem>
        </SelectWrapper>
        <ListTable columns={columns} data={data} />
        {itog(sum, sumContractor)}
        {touched && (
          <Grid style={{ marginTop: '10px' }}>
            <GridItem fullWidth>
              <Button type="primary" onClick={this.handleSubmit}>
                Сохранить
              </Button>
            </GridItem>
          </Grid>
        )}
      </>
    );
  }
}

export default connect(() => ({}), { saveOperations })(Operations);
