import type { MonitoringVehicleHistoryItem } from '../../../../../../../../../lib/types/monitoringVehicle';
import moment from 'moment';

/**
 * Возвращает промежутки для отрисовки
 * мини-карты в виде линии отображающей состояния и статусы авто
 * @param history Трек
 * @param width Базовая ширина контейнера, в котором будет отрисована линия
 */
export const getIntervals = (
  history: MonitoringVehicleHistoryItem[] = [],
  width: number
) => {
  const intervals = [];
  const start = history[0];
  const end = history.slice(-1)[0];
  const commonDiff = new Date(end?.timestamp) - new Date(start?.timestamp);
  let lastStart: MonitoringVehicleHistoryItem = null;
  let lastEnd: MonitoringVehicleHistoryItem = null;
  /**
   * Собираем в промежутки по принципу группировки по одному статусу.
   * Т.е. если есть подряд 500 точек с одним и тем же статусом
   * то нет смысла рисовать 501 промежуток, если можем нарисовать
   * всего один зная первую и последнюю точки.
   * Следующий промежуток берется при условии, что статус следующей точки
   * отличается от текущей.
   */
  history.forEach((item, index) => {
    if (!lastStart) lastStart = item;
    const nextItem = history[index + 1];
    lastEnd = nextItem || end;
    if ((lastEnd && lastEnd.state !== item.state) || lastEnd === end) {
      const diff = new Date(lastEnd.timestamp) - new Date(lastStart.timestamp);
      const percentage = diff / commonDiff;
      intervals.push({
        width: width * percentage,
        status: lastEnd.status,
        state: lastEnd.state
      });
      lastEnd = null;
      lastStart = null;
    }
  });
  return intervals;
};

/**
 * Подсчет текущего времени воспроизведения на основе кол-ва пикселей
 * Т.е. если мы нажимаем в проигрывателе на точку 200px, то подсчитается
 * время на которое нужно перемотать
 * @param x Кол-во пикселей пройденной метки вопроизведения
 * @param startDate Начало интервала
 * @param endDate Конец интервала
 * @param width Ширина контейнера
 * @returns Время воспроизведения на основе пикселей
 */
export const calculateTick = (
  x: number,
  startDate: Date,
  endDate: Date,
  width: number
) => {
  const percentage = x / width;
  const startUnix = moment(startDate).unix();
  const endUnix = moment(endDate).unix();
  const intervalDiff = endUnix - startUnix;
  const diffPercentage = percentage * intervalDiff;
  return startUnix + diffPercentage;
};

/**
 * Преорбразует время воспроизведения в кол-во пикселей
 * Необходимо для отрисовки положения метки воспроизведения
 * относительно пройденных секунд от начала воспроизведения
 * @param tick Время воспроизведения
 * @param startDate Дата начала интервала
 * @param endDate Дата конца интервала
 * @param width Ширина контейнера
 * @returns Кол-во пикселей
 */
export const calculateTickToPx = (
  tick: number,
  startDate: Date,
  endDate: Date,
  width: number
) => {
  const startUnix = moment(startDate).unix();
  const endUnix = moment(endDate).unix();
  const tickDiff = tick - startUnix;
  const intervalDiff = endUnix - startUnix;
  const percentage = tickDiff / intervalDiff;
  return percentage * width;
};
