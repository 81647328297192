// @flow
import React from 'react';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import { SectionContent } from '../../../components/hoc/common/components/elements';
import type { VehicleGroup } from '../../../lib/types';
import WashingVehicleTypeSelect from '../../../components/selects/WashingVehicleTypeSelect';
import BrandsSelect from '../../../components/selects/BrandsSelect';
import VehicleTypeSelect from '../../../components/selects/VehicleTypeSelect';

export type WashingVehicleTypeFilterParams = {
  washingVehicleTypeId?: number,
  brandId?: number,
  vehicleGroup?: VehicleGroup
};

type Props = {
  filter: WashingVehicleTypeFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const StockPartFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<WashingVehicleTypeFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <WashingVehicleTypeSelect
                  placeholder="Тип ТС"
                  size="small"
                  onChange={value => changeValue('washingVehicleTypeId', value)}
                  value={values.washingVehicleTypeId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <BrandsSelect
                  placeholder="Бренд>"
                  size="small"
                  onChange={value => changeValue('brandId', value)}
                  value={values.brandId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  onlyGroups
                  placeholder="Группа ТС"
                  size="small"
                  onChange={value => changeValue('vehicleGroup', value)}
                  value={values.vehicleGroup}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);

export default StockPartFilter;
