import {
    VehicleTariffBrandForm,
    VehicleTariffBrandList,
    VehicleTariffTypeForm,
    VehicleTariffTypeList,
} from '../../containers/vehicleTariff';
import { accessTypeEnum } from '../../lib/enum';

// тарифы на НТС
export default [
    {
        component: VehicleTariffBrandList,
        path: '/admin/vehicle-tariff/byBrand',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingVehicleTariff,
        ],
    },
    {
        component: VehicleTariffBrandForm,
        path: '/admin/vehicle-tariff/byBrand/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingVehicleTariff,
        ],
    },
    {
        component: VehicleTariffBrandForm,
        path: '/admin/vehicle-tariff/byBrand/:vehicleTariffId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingVehicleTariff,
        ],
    },
    {
        component: VehicleTariffTypeList,
        path: '/admin/vehicle-tariff/byType',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingVehicleTariff,
        ],
    },
    {
        component: VehicleTariffTypeForm,
        path: '/admin/vehicle-tariff/byType/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingVehicleTariff,
        ],
    },
    {
        component: VehicleTariffTypeForm,
        path: '/admin/vehicle-tariff/byType/:vehicleTariffId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingVehicleTariff,
        ],
    },
];