// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { navigate, Link } from '@reach/router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { Popconfirm, Button, DatePicker } from 'antd';
import moment from 'moment';

import { vehicleTypes } from '../../lib/enum';
import {
  formatDateRangeString,
  formatDateTimeToISOString,
  setQueryParams,
} from '../../lib/helpers';
import type {
  ListState,
  Vehicle,
  VehicleType,
  WialonTrip,
} from '../../lib/types';
import { getListInitialState, applyMaskToValue } from '../../lib/helpers';
import type { FetchListParams } from '../../lib/api';
import { wialonTripApi, tripDrivingQualityApi, tripApi } from './../../lib/api';
import { Table } from './../../components/ui';
import ButtonsRow from '../../components/ui/ButtonsRow';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import Filter, { type TripFilterParams } from './components/Filter';
import { Header } from '../../components/layout';
import Section from '../../components/layout/Section';
import { printNotification } from '../../lib/notificationWrapper';

const DriverRatingContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 160px;
`;

export const getQualityColor = (quality: number) => {
  if (quality === 0) return 'black';
  else if (quality > 0 && quality <= 3) return '#f55b5b';
  else if (quality > 3 && quality <= 4.5) return '#f5b05b';
  else return 'green';
};

export const COLUMNS = [
  {
    title: '№ Пл',
    dataIndex: 'trip.idNumber',
    sorter: true,
  },
  {
    title: 'Тип',
    dataIndex: 'vehicleType',
    sorter: true,
    render: (vehicleType: VehicleType, record: WialonTrip): ?string =>
      record.vehicle
        ? record.vehicle.vehicleModel &&
          vehicleTypes[record.vehicle.vehicleModel.type]
        : vehicleTypes[vehicleType],
  },
  {
    title: 'Служба использования',
    sorter: true,
    sorterKey: 'node.name',
    dataIndex: 'vehicle.usingNodeName',
  },
  {
    title: 'Период выделения',
    dataIndex: 'actualTimeAtStart',
    sorterKey: 'actualTimeAtStart',
    sorter: true,
    render: (
      text: string, // eslint-disable-line no-unused-vars
      record: WialonTrip
    ): string =>
      formatDateRangeString(record.actualTimeAtStart, record.actualTimeAtEnd),
  },
  {
    title: 'ТС',
    dataIndex: 'vehicle',
    sorter: true,
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (vehicle: Vehicle, trip: WialonTrip) => {
      if (vehicle) {
        const licensePlate =
          vehicle.licensePlate &&
          applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask);
        if (vehicle.isDeleted) return licensePlate;
        return (
          <Link to={`/vehicles/${trip.vehicleId || ''}`}>{licensePlate}</Link>
        );
      }
      return null;
    },
  },
  //Временно скрыто для показа
  // {
  //   title: 'Пробег по регламенту',
  //   dataIndex: 'regulationsDistance',
  //   render: (distance: number) => !!distance && `${distance.toFixed(2)} км`
  // },
  {
    title: 'Пробег по системе',
    dataIndex: 'kilometrage',
    render: (distance: number) => !!distance && `${distance.toFixed(2)} км`,
  },
  {
    title: 'Оценка качества вождения',
    dataIndex: 'driverRating',
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (quality: number, trip: WialonTrip) => (
      <DriverRatingContainer>
        <span style={{ color: getQualityColor(quality), flex: 'auto' }}>
          {quality}
        </span>
        <Button
          type="primary"
          onClick={() =>
            printNotification(
              async () => await tripDrivingQualityApi.print(trip.id)
            )
          }
        >
          Отчет
        </Button>
      </DriverRatingContainer>
    ),
  },
];

type Props = {
  location?: any,
};
type State = ListState<WialonTrip> & {
  filter: TripFilterParams,
  print: {
    startDate?: string,
    endDate?: string,
  },
  printDeviationReport: {
    startDate?: string,
    endDate?: string,
  },
};

export default class TripsList extends Component<Props, State> {
  static defaultProps = {
    location: {},
    selectable: true,
  };

  state: State = {
    ...getListInitialState(),
    filter: {},
    print: {},
    printDeviationReport: {},
  };

  handleRowClick = (id: number) => navigate(`/wialonTrips/${id}`);

  fetchTrips = async (page: number = 1, params: FetchListParams<any> = {}) => {
    this.setState({ loading: true });
    if (isEmpty(params)) {
      params = { orderBy: 'actualTimeAtStart', byDescending: true };
    }
    const { data, totalCount } = await wialonTripApi.fetch({
      ...this.state.filter,
      isArchive: true,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  cleanFilter = () => this.setState({ filter: {} }, this.fetchTrips);

  applyFilter = (filter: TripFilterParams) =>
    this.setState({ filter }, this.fetchTrips);

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    await this.setState({ filter }, async () => await this.fetchTrips(page));
  }

  onConfirmCheck = async (name: 'print' | 'printDeviationReport') => {
    await printNotification(async () => {
      const fetchParams = {
        startDate: formatDateTimeToISOString(
          {},
          moment(this.state[name].startDate).hour(12)
        ),
        endDate: formatDateTimeToISOString(
          {},
          moment(this.state[name].endDate).hour(12)
        ),
      };
      switch (name) {
        case 'printDeviationReport':
          await tripApi[name](fetchParams);
          break;
        case 'print':
          await wialonTripApi[name](fetchParams);
          break;
        default:
      }
      this.setState({
        // $FlowFixMe ругается на [name]
        [name]: {
          ...this.state[name],
          startDate: undefined,
          endDate: undefined,
        },
      });
    });
  };

  renderPrint = (
    name: 'print' | 'printDeviationReport',
    buttonText: string
  ) => {
    return (
      <Popconfirm
        title={
          <>
            <p>Введите период отбора</p>
            <DatePicker
              placeholder="Дата начала"
              format="DD.MM.YYYY"
              value={this.state[name]?.startDate}
              onChange={(value: Object) => {
                this.setState({
                  // $FlowFixMe ругается на [name]
                  [name]: { ...this.state[name], startDate: value },
                });
              }}
            />
            <DatePicker
              placeholder="Дата окончания"
              format="DD.MM.YYYY"
              value={this.state[name]?.endDate}
              onChange={(value: Object) => {
                this.setState({
                  // $FlowFixMe ругается на [name]
                  [name]: { ...this.state[name], endDate: value },
                });
              }}
            />
          </>
        }
        onConfirm={() => this.onConfirmCheck(name)}
        onCancel={() =>
          this.setState({
            // $FlowFixMe ругается на [name]
            [name]: {
              ...this.state[name],
              startDate: undefined,
              endDate: undefined,
            },
          })
        }
      >
        <Button>{buttonText}</Button>
      </Popconfirm>
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Поездки</h1>}
          right={
            <>
              <ButtonsRow>
                {this.renderPrint(
                  'printDeviationReport',
                  'Отчет по расхождению данных системы и ПЛ'
                )}
              </ButtonsRow>
            </>
          }
        />
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            columns={COLUMNS}
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            defaultOrderBy={'actualTimeAtStart'}
            fetch={this.fetchTrips}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data.map((item) => ({ ...item, key: item.id }))}
          />
        </Section>
      </>
    );
  }
}
