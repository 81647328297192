// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { okeiApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { Okei } from '../../lib/types';

export default (selectProps: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите ОКЕИ"
    fetch={okeiApi.fetch}
    fetchSingle={okeiApi.get}
    notFoundText="ОКЕИ не найдены"
    renderOption={(okei: Okei, Option) => (
      <Option value={okei.id} key={okei.id} okei={okei}>
        {okei.name}
      </Option>
    )}
    {...selectProps}
  />
);
