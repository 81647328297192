// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import Button from 'antd/lib/button';

import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { VehicleStatus } from '../../components';
import { RangePicker } from '../../components/ant/DatePicker';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Grid, { GridItem } from '../../components/layout/Grid';
import Header from '../../components/layout/Header';
import EmployeeSelect from '../../components/selects/EmployeeSelect';

import {
  cleanContractVehicle,
  fetchContractVehicle,
  updateContractVehicle,
} from '../../ducks/contractVehicle';
import type { AppState } from '../../ducks/redux';
import { contractVehicleApi } from '../../lib/api';
import {
  accessTypeEnum,
  contractVehicleType,
  gpmVehicleTypes,
  vehicleOvbTypes,
  vehicleStatusEnum,
  vehicleTypes,
} from '../../lib/enum';
import {
  convertEmployeeToString,
  convertJobTitleToString,
  formatDateTimeToISOString,
} from '../../lib/helpers';
import type { ContractVehicle, UserAccess } from '../../lib/types';
import { Card as CardUI } from './../../components';
import { Option, Select } from './../../components/ant/Select';
import { Section, SectionTitle } from './../../components/layout';
import { notificationLoading } from './../../components/Notifications';
import { Popconfirm, Dropdown } from './../../components/ui';
import type { DropdownType } from './../../components/ui/Dropdown';

import { withUserAccess } from './../withUserAccess';
import {
  CardPanel,
  CarInfo,
  CarInfoWrapper,
  Image,
  ImageWrapper,
  Operations,
  SectionContent,
} from './Card.elements';

type Props = {
  vehicleId: number,
  contractVehicle: ContractVehicle,
  fetchContractVehicle: (id: number) => Promise<void>,
  cleanContractVehicle: Function,
  updateContractVehicle: (vehicle: ContractVehicle) => void,
  userAccess: UserAccess[],
};

export const canEditVehicleAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.creatingVehicle,
];

export const canApproveVehicleAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.approvingVehicle,
];

const { Field } = CardUI;
const currentYear = moment().year();

type State = {
  showReserveByEmployeeForm: boolean,
  showReserveByOvbForm: boolean,
  attachedEmployeeId: ?number,
  attachingEmployee: boolean,
  attachingOvb: boolean,
  attachedStartDate: ?(string | moment),
  attachedEndDate: ?(string | moment),
  attachedYear: ?number,
};

export class Card extends Component<Props, State> {
  state = {
    showReserveByEmployeeForm: false,
    showReserveByOvbForm: false,
    attachedEmployeeId: null,
    attachingEmployee: false,
    attachingOvb: false,
    attachedStartDate: moment.utc(),
    attachedEndDate: moment.utc().endOf('day'),
    attachedYear: currentYear,
  };

  dropdown: ?DropdownType;

  async componentDidMount() {
    const { vehicleId } = this.props;
    this.props.cleanContractVehicle();
    if (vehicleId) {
      try {
        await this.props.fetchContractVehicle(vehicleId);
      } catch (error) {
        navigate('/vehicles/contract');
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  }

  approveVehicle = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateContractVehicle({
        ...this.props.contractVehicle,
        vehicle: {
          ...this.props.contractVehicle.vehicle,
          status: vehicleStatusEnum.working,
        },
      });
      notification.success({
        message: 'Статус успешно обновлен',
      });
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при изменении статуса',
      });
    } finally {
      notification.close('saving');
    }
  };

  onReserveByEmployee = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      this.setState({ attachingEmployee: true });
      await contractVehicleApi.reserveByEmployee({
        vehicleId: this.props.vehicleId,
        employeeId: this.state.attachedEmployeeId,
        startDate: this.state.attachedStartDate,
        endDate: moment
          .utc(this.state.attachedEndDate)
          .endOf('day')
          .toISOString(),
      });
      notification.success({
        message: 'ТС успешно закреплен',
      });
      this.toggleReserveByEmployeeForm();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({ attachingEmployee: false });
    }
  };

  onReserveByOvb = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      this.setState({ attachingOvb: true });
      await contractVehicleApi.reserveByOvb({
        vehicleId: this.props.vehicleId,
        year: this.state.attachedYear,
      });
      notification.success({
        message: 'ТС успешно закреплен за ОВБ',
      });
      this.toggleReserveByOvbForm();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({ attachingOvb: false });
      notification.close('saving');
    }
  };

  canEditVehicle = () =>
    this.props.userAccess.some((access) =>
      canEditVehicleAccess.includes(access)
    );

  canApproveVehicle = () =>
    this.props.userAccess.some((access) =>
      canApproveVehicleAccess.includes(access)
    );

  canUserDeleteVehicles = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingVehicle,
      ].includes(access)
    );

  deleteContractVehicle = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await contractVehicleApi.deleteContractVehicle(this.props.vehicleId);
      navigate('/vehicles/contract');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  toggleReserveByEmployeeForm = () =>
    this.setState((prevState: State) => ({
      showReserveByEmployeeForm: !prevState.showReserveByEmployeeForm,
    }));

  toggleReserveByOvbForm = () =>
    this.setState((prevState: State) => ({
      showReserveByOvbForm: !prevState.showReserveByOvbForm,
    }));

  render() {
    const { contractVehicle, vehicleId } = this.props;
    if (!contractVehicle) return null;
    const canEdit = this.canEditVehicle();
    const canApprove = this.canApproveVehicle();
    const { vehicle } = contractVehicle;
    const { vehicleModel } = vehicle;
    return (
      <div>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/vehicles/contract">Наемные ТС</Crumb>
              <Crumb to={`/vehicles/contract/${vehicle.id}`}>ТС</Crumb>
            </Breadcrumbs>
          }
          right={
            <Operations>
              {canApprove && (
                <Button
                  type="primary"
                  onClick={this.approveVehicle}
                  data-cy="approve"
                >
                  Принять в работу
                </Button>
              )}
              {vehicle.status === vehicleStatusEnum.working &&
                contractVehicle.ovbType &&
                canEdit && (
                  <Button type="primary" onClick={this.toggleReserveByOvbForm}>
                    Назначить ТС ОВБ
                  </Button>
                )}
              {vehicle.status === vehicleStatusEnum.working &&
                canEdit &&
                (parseFloat(contractVehicle.workWeekHours) > 0 ||
                  parseFloat(contractVehicle.workWeekendHours) > 0) && (
                  <Button
                    type="primary"
                    onClick={this.toggleReserveByEmployeeForm}
                  >
                    Назначить ТС сотруднику
                  </Button>
                )}
              <Dropdown
                ref={(dropdown) => (this.dropdown = dropdown)}
                overlay={
                  <Menu>
                    {canEdit && (
                      <Menu.Item
                        onClick={() =>
                          navigate(`/vehicles/contract/edit/${vehicleId}`)
                        }
                      >
                        Редактировать
                      </Menu.Item>
                    )}
                    {canEdit && (
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteContractVehicle}
                          onVisibleChange={(flag) =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button type="primary" icon={<EllipsisOutlined />} />
              </Dropdown>
            </Operations>
          }
        />
        <CardPanel>
          <CarInfoWrapper>
            <CarInfo>
              <div style={styles.cardHeader}>
                <p style={styles.pHeader}>{vehicle.licensePlate}</p>
                {contractVehicle.type && (
                  <p style={styles.pHeader}>
                    {contractVehicleType[contractVehicle.type]}
                  </p>
                )}
                {contractVehicle.startDateStatus && (
                  <p style={styles.pHeader}>
                    {formatDateTimeToISOString(contractVehicle.startDateStatus)}{' '}
                    - {formatDateTimeToISOString(contractVehicle.endDateStatus)}
                  </p>
                )}
                {contractVehicle.type && (
                  <p style={styles.pHeader}>
                    {contractVehicleType[contractVehicle.type]}
                  </p>
                )}
                {contractVehicle.leasing && (
                  <p style={styles.pHeader}>Лизинг</p>
                )}
                {contractVehicle.hasGBO && (
                  <p style={styles.pHeader}>Установлено ГБО</p>
                )}
                <VehicleStatus status={vehicle.status} />
              </div>
              <h1>
                {vehicleModel &&
                  `${vehicleTypes[vehicleModel.type]} ${
                    vehicleModel.brandName
                  } ${vehicleModel.name}`}
                , {vehicle.yearIssued}
              </h1>
            </CarInfo>
            <ImageWrapper>
              {vehicle.posterImage && (
                <Image
                  alt={`${vehicleModel.brandName} ${vehicleModel.name} ${
                    vehicle.licensePlate || ''
                  }`}
                  src={vehicle.posterImage.url}
                />
              )}
            </ImageWrapper>
          </CarInfoWrapper>
        </CardPanel>
        <Section>
          <SectionTitle divider>Номера</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="VIN номер">{vehicle.vin}</Field>
              </GridItem>
              {vehicle.firstBitGarageNumber && (
                <GridItem>
                  <Field label="Гаражный номер ТС подрядчика">
                    {vehicle.firstBitGarageNumber}
                  </Field>
                </GridItem>
              )}
              {vehicle.serialNumber && (
                <GridItem>
                  <Field label="Серийный номер">{vehicle.serialNumber}</Field>
                </GridItem>
              )}
              {gpmVehicleTypes.includes(vehicleModel.type) && (
                <GridItem>
                  <Field label="Регистрационный номер подъемного сооружения">
                    {vehicle.gpmRegistrationNumber}
                  </Field>
                </GridItem>
              )}
              <GridItem fullWidth />
              {vehicle.deviceId !== null && (
                <GridItem>
                  <Field label="ГЛОНАСС/GPS-трекер">{vehicle.deviceId}</Field>
                </GridItem>
              )}
              {vehicle.configFileName && (
                <GridItem>
                  <Field label="Имя конфигурационного файла для АвтоГРАФ">
                    {vehicle.configFileName}
                  </Field>
                </GridItem>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Водители</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Закрепленный водитель">
                  {contractVehicle.primaryDriverLastname}
                  &nbsp;
                  {contractVehicle.primaryDriverFirstname}
                  &nbsp;
                  {contractVehicle.primaryDriverMiddlename}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Контактный номер">
                  {contractVehicle.primaryContactNumber}
                </Field>
              </GridItem>

              {contractVehicle.ovbType && (
                <>
                  {(contractVehicle.secondaryDriverLastname ||
                    contractVehicle.secondaryDriverFirstname ||
                    contractVehicle.secondaryDriverMiddlename) && (
                    <GridItem>
                      <Field label="Закрепленный второй водитель">
                        {contractVehicle.secondaryDriverLastname}
                        &nbsp;
                        {contractVehicle.secondaryDriverFirstname}
                        &nbsp;
                        {contractVehicle.secondaryDriverMiddlename}
                      </Field>
                    </GridItem>
                  )}
                  {contractVehicle.secondaryContactNumber && (
                    <GridItem>
                      <Field label="Контактный номер второго водителя">
                        {contractVehicle.secondaryContactNumber}
                      </Field>
                    </GridItem>
                  )}

                  {(contractVehicle.tertiaryDriverLastname ||
                    contractVehicle.tertiaryDriverFirstname ||
                    contractVehicle.tertiaryDriverMiddlename) && (
                    <GridItem>
                      <Field label="Закрепленный третий водитель">
                        {contractVehicle.tertiaryDriverLastname}
                        &nbsp;
                        {contractVehicle.tertiaryDriverFirstname}
                        &nbsp;
                        {contractVehicle.tertiaryDriverMiddlename}
                      </Field>
                    </GridItem>
                  )}
                  {contractVehicle.tertiaryContactNumber && (
                    <GridItem>
                      <Field label="Контактный номер третьего водителя">
                        {contractVehicle.tertiaryContactNumber}
                      </Field>
                    </GridItem>
                  )}
                </>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Прикрепление</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Подрядчик">
                  {contractVehicle.contractor.company
                    ? contractVehicle.contractor.company.name
                    : contractVehicle.contractor.generalContractor.name}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Филиал владелец">
                  {vehicle.ownerOrgUnitName}
                </Field>
              </GridItem>
              {vehicle.parentOrgUnitMnemonic && (
                <GridItem>
                  <Field label="Мнемокод филиала закрепления">
                    {vehicle.parentOrgUnitMnemonic}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Служба закрепления/РЭС">
                  {vehicle.orgUnitName}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Служба использования/РЭС">
                  {vehicle.usingNodeName}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Мнемокод службы закрепления">
                  {vehicle.orgUnitMnemonic}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Населенный пункт базирования ТС">
                  {vehicle.location &&
                    `${vehicle.location.name} ${vehicle.location.address}`}
                </Field>
              </GridItem>
              {contractVehicle.ovbType && (
                <GridItem>
                  <Field label="Режим работы ОВБ">
                    {vehicleOvbTypes[contractVehicle.ovbType]}
                  </Field>
                </GridItem>
              )}
              {contractVehicle.reservedByJobTitle && (
                <GridItem>
                  <Field label="Закрепленная должность">
                    {convertJobTitleToString(
                      contractVehicle.reservedByJobTitle
                    )}
                  </Field>
                </GridItem>
              )}
              {contractVehicle.reservedByEmployee && (
                <GridItem>
                  <Field label="Закрепленный сотрудник">
                    {convertEmployeeToString(
                      contractVehicle.reservedByEmployee
                    )}
                  </Field>
                </GridItem>
              )}
              {parseFloat(contractVehicle.workWeekHours) > 0 && (
                <GridItem>
                  <Field label="Время работы по регламенту в будний день">
                    {contractVehicle.workWeekHours} ч.
                  </Field>
                </GridItem>
              )}
              {parseFloat(contractVehicle.workWeekendHours) > 0 && (
                <GridItem>
                  <Field label="Время работы по регламенту в выходной день">
                    {contractVehicle.workWeekendHours} ч.
                  </Field>
                </GridItem>
              )}
              {parseFloat(contractVehicle.kilometrageLimitWorkWeek) > 0 && (
                <GridItem>
                  <Field label="Пробег по регламенту в будний день">
                    {contractVehicle.kilometrageLimitWorkWeek} км.
                  </Field>
                </GridItem>
              )}
              {parseFloat(contractVehicle.kilometrageLimitWeekend) > 0 && (
                <GridItem>
                  <Field label="Пробег по регламенту в выходной день">
                    {contractVehicle.kilometrageLimitWeekend} км.
                  </Field>
                </GridItem>
              )}
            </Grid>
          </SectionContent>
        </Section>
        {vehicleModel && (
          <>
            <Section>
              <SectionTitle divider>Тарифы</SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  {!isNil(vehicleModel.fixedRatePart) && (
                    <GridItem>
                      <Field label="Постоянная часть тарифа">
                        {vehicleModel.fixedRatePart} ₽
                      </Field>
                    </GridItem>
                  )}
                  {!isNil(vehicleModel.fixedRateInTransit) && (
                    <GridItem>
                      <Field label="Постоянная часть тарифа (в пути)">
                        {vehicleModel.fixedRateInTransit} ₽
                      </Field>
                    </GridItem>
                  )}
                  {!isNil(vehicleModel.fixedRateInDowntime) && (
                    <GridItem>
                      <Field label="Переменная часть тарифа">
                        {vehicleModel.fixedRateInDowntime} ₽
                      </Field>
                    </GridItem>
                  )}
                  {!isNil(vehicleModel.warmingUpCost) && (
                    <GridItem>
                      <Field label="Стоимость прогрева">
                        {vehicleModel.warmingUpCost} ₽
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            </Section>
            {(!isNil(vehicleModel.secondaryFixedRatePart) ||
              !isNil(vehicleModel.secondaryFixedRateInTransit) ||
              !isNil(vehicleModel.secondaryFixedRateInDowntime) ||
              !isNil(vehicleModel.secondaryWarmingUpCost)) && (
              <Section>
                <SectionTitle divider>Тарифы для доп. топлива</SectionTitle>
                <SectionContent>
                  <Grid gutter="16px">
                    {!isNil(vehicleModel.secondaryFixedRatePart) && (
                      <GridItem>
                        <Field label="Постоянная часть тарифа">
                          {vehicleModel.secondaryFixedRatePart || 0} ₽
                        </Field>
                      </GridItem>
                    )}
                    {!isNil(vehicleModel.secondaryFixedRateInTransit) && (
                      <GridItem>
                        <Field label="Постоянная часть тарифа (в пути)">
                          {vehicleModel.secondaryFixedRateInTransit || 0} ₽
                        </Field>
                      </GridItem>
                    )}
                    {!isNil(vehicleModel.secondaryFixedRateInDowntime) && (
                      <GridItem>
                        <Field label="Переменная часть тарифа">
                          {vehicleModel.secondaryFixedRateInDowntime || 0} ₽
                        </Field>
                      </GridItem>
                    )}
                    {!isNil(vehicleModel.secondaryWarmingUpCost) && (
                      <GridItem>
                        <Field label="Стоимость прогрева">
                          {vehicleModel.secondaryWarmingUpCost || 0} ₽
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </SectionContent>
              </Section>
            )}
          </>
        )}
        <Modal
          visible={this.state.showReserveByEmployeeForm}
          title="Назначение ТС сотруднику"
          onCancel={this.toggleReserveByEmployeeForm}
          footer={[
            <Button key="back" onClick={this.toggleReserveByEmployeeForm}>
              Отменить
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.onReserveByEmployee}
              disabled={!this.state.attachedEmployeeId}
              loading={this.state.attachingEmployee}
            >
              Подтвердить
            </Button>,
          ]}
        >
          <Grid gutter="16px">
            <GridItem>
              <Field label="Сотрудник">
                <EmployeeSelect
                  placeholder="Выберите сотрудника"
                  onChange={(value: number) =>
                    this.setState({ attachedEmployeeId: value })
                  }
                  value={this.state.attachedEmployeeId}
                  filter={{
                    nodeId: vehicle.orgUnitId,
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Период выделения">
                <RangePicker
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    this.state.attachedStartDate
                      ? moment.utc(this.state.attachedStartDate)
                      : null,
                    this.state.attachedEndDate
                      ? moment.utc(this.state.attachedEndDate)
                      : null,
                  ]}
                  onChange={(value, dateString) => {
                    const [attachedStartDate, attachedEndDate] = value;
                    const [attachedStartDateString, attachedEndDateString] =
                      dateString;
                    this.setState({
                      attachedStartDate: formatDateTimeToISOString(
                        attachedStartDate,
                        attachedStartDateString
                      ),
                      attachedEndDate: formatDateTimeToISOString(
                        attachedEndDate,
                        attachedEndDateString
                      ),
                    });
                  }}
                />
              </Field>
            </GridItem>
          </Grid>
        </Modal>

        {/* модальное окно для закрепления за ОВБ */}
        <Modal
          visible={this.state.showReserveByOvbForm}
          title="Назначение ТС ОВБ"
          onCancel={this.toggleReserveByOvbForm}
          footer={[
            <Button key="back" onClick={this.toggleReserveByOvbForm}>
              Отменить
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.onReserveByOvb}
              loading={this.state.attachingOvb}
            >
              Подтвердить
            </Button>,
          ]}
        >
          <Grid gutter="16px">
            <GridItem>
              <Field label="Год">
                <Select
                  onChange={(value: number) =>
                    this.setState({ attachedYear: value })
                  }
                  value={this.state.attachedYear}
                >
                  {range(currentYear, currentYear + 10).map((year: number) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Field>
            </GridItem>
          </Grid>
        </Modal>
      </div>
    );
  }
}

const styles = {
  cardHeader: {
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
  },
  carKilometrage: {
    display: 'flex',
  },
  pHeader: {
    marginRight: 10,
    fontSize: 14,
    marginBottom: 0,
  },
  editButton: {
    marginRight: 15,
  },
};

export default connect(
  (state: AppState, props: Props) => ({
    contractVehicle: state.contractVehicle,
    vehicleId: parseInt(props.vehicleId, 10),
  }),
  {
    fetchContractVehicle,
    cleanContractVehicle,
    updateContractVehicle,
  }
)(withUserAccess(Card));
