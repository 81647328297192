import React from 'react';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { Stock } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import { StyledInput } from '../../../components/hoc/common/components/elements';
import { OrgUnitSelect } from '../../../components';

export default (props: CommonFormProps<$Shape<Stock>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Служба" fast required name="orgUnitId">
                  {({ name, value }) => (
                    <OrgUnitSelect
                      value={value}
                      onlyService
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label={`Мнемокод склада`}
                  fast
                  required
                  name="mnemocode"
                >
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
