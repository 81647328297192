// @flow
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import {
  pddQuestionApi,
  pddTestApi,
  pddTestResultApi,
  pddTicketApi
} from '../../../../lib/api';

import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';

import { getBreadCrumbsByUrl } from '../../../../lib/autoBreadcrumbs';
import type {
  PddAnswer,
  PddQuestion,
  PddTest,
  PddTestType,
  PddTicket,
  PddTicketQuestion
} from '../../../../lib/types';

import Section, {
  SectionContent,
  SectionTitle
} from '../../../../components/layout/Section';
import { pddTest, pddTestStatusesEnum } from '../../../../lib/enum';
import { formatSecondsToHumanTime, navigate } from '../../../../lib/helpers';
import { TicketModal } from './components/TicketModal';
import Results from '../../Results/components/Results';

type InnerProps = {
  data: PddQuestion,
  answer: PddAnswer,
  setAnswer: (answer: PddAnswer) => any,
  getNextQuestion: () => any,
  isLastQuestion: boolean,
  noHighlighting: boolean
};

type PageProps = {
  id?: number,
  ticketId?: number, //номер билета, нужен для повторного тестирования
  examTraining: PddTestType
};

const TestPaginationButton = styled(Button)`
  background: ${props =>
    props.answer && !props.noHighlighting
      ? props.answer.isRight
        ? '#27ae60'
        : '#eb2f22'
      : props.answer
      ? '#999999'
      : 'white'};
  border-color: ${props => {
    let color = 'black';
    if (props.answer && !props.noHighlighting) {
      color = props.answer.isRight ? '#27ae60' : '#eb2f22';
    }
    return color;
  }};
  border-width: ${props => (props.bezel ? '3px' : '1px')};
  color: ${props => (props.answer ? 'white' : 'black')};
  padding: 10px;
  height: auto;
  margin: 0.5rem;
  word-wrap: break-spaces;
  min-width: 40px;

  &:hover {
    background: ${props =>
      props.answer && !props.noHighlighting
        ? props.answer.isRight
          ? '#27ae60'
          : '#eb2f22'
        : 'white'};
    border-width: 3px;
    border-color: black;
    box-shadow: none;
    color: ${props => (props.answer ? 'white' : 'black')};
  }
`;

const AnswerButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // gap: 1rem;
  margin: -0.5rem; // так как gap не не поддерживается в safari 13.1
`;

const AnswerButton = styled(Button)`
  background: #f0f4f8;
  border-color: #f0f4f8;
  color: black;
  padding: 15px;
  height: auto;
  margin: 0.5rem;
  white-space: pre-line;
  min-width: 300px;

  &:hover {
    background: #ccddff;
    border-color: #ccddff;
    box-shadow: none;
    color: black;
  }

  &:disabled,
  &:disabled:hover {
    background: ${props => props.highlight};
    color: ${props => (props.highlight ? 'white' : 'auto')};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 0;

  @media (min-width: 1440px) {
    padding: 0 10% 0 10%;
  }

  @media (min-width: 2560px) {
    padding: 0 20% 0 20%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  border-radius: 5px;
  object-fit: contain;
`;

type TimerProps = {
  totalTime: number,
  onTimeExpired: () => any,
  forwardedRef: any,
  timeStart: number
};

const Timer = (props: TimerProps) => {
  const [currentTime, setCurrentTime] = useState(0); // нужен для обновления

  useEffect(() => {
    const diff = Math.max(
      Date.now() - props.timeStart - props.forwardedRef.current * 1000,
      0
    );
    let interval = setTimeout(() => {
      props.forwardedRef.current = props.forwardedRef.current + 1; // не менять местами
      setCurrentTime(currentTime => currentTime + 1);
    }, 1000 - diff);
    return () => clearTimeout(interval);
  });

  useEffect(() => {
    const f = async () => {
      if (props.forwardedRef.current === props.totalTime) {
        await props.onTimeExpired();
      }
    };
    props.totalTime && f();
  }, [props, currentTime]);

  return props.totalTime
    ? `Тест закончится через ${formatSecondsToHumanTime(
        props.totalTime - props.forwardedRef.current
      )}`
    : `Пройдено времени ${formatSecondsToHumanTime(
        props.forwardedRef.current
      )}`;
};

const InnerForm = (props: InnerProps) => {
  const [userAnswer, setUserAnswer] = useState();

  useEffect(() => {
    setUserAnswer(props.answer);
  }, [props.answer]);

  if (!props.data) return null;

  return <>
    <Section>
      {props.data.photo && (
        <ImageWrapper>
          <StyledImage src={props.data.photo.url} loading="lazy" alt="Фото" />
        </ImageWrapper>
      )}

      <SectionTitle>{props.data.question}</SectionTitle>

      <SectionContent>
        <AnswerButtonsRow>
          {props.data.answers?.map((answer: PddAnswer) => (
            <AnswerButton
              onClick={() => props.setAnswer(answer)}
              highlight={
                props.noHighlighting
                  ? userAnswer === answer
                    ? '#999999'
                    : null
                  : userAnswer === answer
                  ? answer.isRight
                    ? '#27ae60'
                    : '#eb2f22'
                  : !userAnswer?.isRight && answer.isRight
                  ? 'rgba(39, 174, 96, 0.5)'
                  : null
              }
              disabled={userAnswer}
            >
              {answer.description}
            </AnswerButton>
          ))}
        </AnswerButtonsRow>

        {!!userAnswer && !props.noHighlighting && (
          <>
            <SectionTitle>Поснение к ответу:</SectionTitle>
            <SectionContent>{props.data.answerNote}</SectionContent>
          </>
        )}

        {!!userAnswer && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              marginTop: '50px'
            }}
          >
            {!props.isLastQuestion && (
              <Button
                type="primary"
                onClick={() => {
                  props.getNextQuestion();
                }}
              >
                Следующий вопрос <ArrowRightOutlined />
              </Button>
            )}
          </div>
        )}
      </SectionContent>
    </Section>
  </>;
};

export default (props: PageProps) => {
  const breadCrumbs = [
    ...getBreadCrumbsByUrl(`/pdd/test/${props.examTraining}/tests/`),
    {
      name: 'Тестирование'
    }
  ];
  const [ticketSelectModalVisible, setTicketSelectModalVisible] = useState(
    false
  );

  const [test: PddTest, setTest] = useState({});
  const [ticket: PddTicket, setTicket] = useState(null);

  const [questions: PddQuestion[], setQuestions] = useState([]);
  const [answers: PddAnswer[], setAnswers] = useState([]);

  const [questionsNumbers, setQuestionsNumbers] = useState([]);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [wrongGroups, setWrongGroups] = useState({});

  const [pddTestResults, setPddTestResults] = useState(null);

  const timeRef = useRef(0);
  const [testTimeStart, setTestTimeStart] = useState(0);

  const fetchTest = useCallback(async (testId: number) => {
    try {
      const test = await pddTestApi.get(testId);
      setTest(test);
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, []);

  const fetchExam = useCallback(async () => {
    try {
      const examQuestions = await pddTestApi.generateExam();
      // $FlowFixMe flow ожидает функцию
      setTicket({
        idNumber: 0,
        pddTicketQuestions: examQuestions.map(q => ({
          pddTickeId: 0,
          pddQuestion: q
        }))
      });
      setTest({
        id: 0,
        name: '',
        type: pddTest.exam,
        questionsCount: examQuestions.length,
        addQuestions: true,
        maxWrongAnswers: 2,
        withoutTickets: true,
        examTime: 20 * 60,
        pddTestGroups: [],
        pddTestResultId: null,
        passedCount: 0,
        notPassedCount: 0
      });
    } catch (e) {
      notification.error({ message: e.message });
    }
  }, []);

  const fetchSingleTicket = useCallback(
    async (pddTestId: number, ticketId?: number) => {
      try {
        let tickets = await pddTicketApi.fetch({ pddTestId, id: ticketId });
        if (!tickets.data[0]) {
          notification.error({
            message: 'К тесту не привязаны билеты. Обратитесь к администратору'
          });
          await navigate(`/pdd/test/${props.examTraining}/tests/`, true);
          return;
        }
        setTicket(tickets.data[0]);
      } catch (e) {
        notification.error({ message: e.message });
      }
    },
    [props.examTraining]
  );

  useEffect(() => {
    if (
      Number.isInteger(+props.id) &&
      props.examTraining === pddTest.training
    ) {
      fetchTest(+props.id);
    } else if (props.examTraining === pddTest.exam) {
      fetchExam();
    } else {
      notification.error({
        message: 'Ошибка навигации. Обратитесь к администратору'
      });
      navigate(`/pdd/test/${props.examTraining}/tests/`, true);
    }
  }, [fetchExam, fetchTest, props.examTraining, props.id]);

  useEffect(() => {
    // запрашиваем 1 билет только если указан тип теста без билетов, иначе всплывающее окно с выбором билетов
    if (isEmpty(test) || props.examTraining === pddTest.exam) return;

    if (!test.withoutTickets) {
      if (props.ticketId) {
        fetchSingleTicket(test.id, props.ticketId);
      } else {
        setTicketSelectModalVisible(true);
      }
    } else {
      fetchSingleTicket(test.id);
    }
  }, [fetchSingleTicket, props.examTraining, props.ticketId, test]);

  useEffect(() => {
    if (!ticket) return;

    const qnumbers = ticket.pddTicketQuestions.map(el => el.pddQuestion.id);

    setQuestionsNumbers(qnumbers);
    setTotalQuestionsCount(qnumbers.length);
    setCurrentQuestionNumber(1);
    if (props.examTraining === pddTest.training) {
      setQuestions(Array(qnumbers.length));
    } else {
      setQuestions(
        ticket.pddTicketQuestions.map((q: PddTicketQuestion) => q.pddQuestion)
      );
    }
    setAnswers(Array(qnumbers.length).fill(null));
    setTestTimeStart(Date.now());
  }, [props.examTraining, ticket]);

  useEffect(() => {
    // запрашиваем вопросы по мере прохождения теста
    const fetch = async () => {
      try {
        if (!questions[currentQuestionNumber - 1]) {
          const question = await pddQuestionApi.get(
            questionsNumbers[currentQuestionNumber - 1]
          );
          let q = [...questions];
          q[currentQuestionNumber - 1] = question;
          setQuestions(q);
        }
      } catch (e) {
        notification.error({ message: e.message });
      }
    };
    if (
      !!ticket &&
      !!questionsNumbers &&
      !!currentQuestionNumber &&
      currentQuestionNumber <= totalQuestionsCount
    )
      fetch();
  }, [
    currentQuestionNumber,
    questions,
    questionsNumbers,
    ticket,
    totalQuestionsCount
  ]);

  const fetchAdditionalQuestions = useCallback(
    async (groupId: number) => {
      let addQuestions = [];
      if (props.examTraining === pddTest.exam) {
        addQuestions = await pddQuestionApi.getAdditionalQuestions(
          [groupId],
          undefined,
          questionsNumbers
        );
      } else {
        addQuestions = await pddQuestionApi.getAdditionalQuestions(
          [groupId],
          test.id
        );
      }
      setQuestionsNumbers([
        ...questionsNumbers,
        ...addQuestions.map(el => el.id)
      ]);
      setQuestions([...questions, ...addQuestions]);
      setAnswers([...answers, ...Array(addQuestions.length).fill(null)]);
      setTotalQuestionsCount([...questions, ...addQuestions].length);
      if (props.examTraining === pddTest.exam) {
        // $FlowFixMe flow думает что передается функция
        setTest({
          ...test,
          examTime: test.examTime + 5 * 60
        });
      }
    },
    [answers, props.examTraining, questions, questionsNumbers, test]
  );

  useEffect(() => {
    if (
      test.addQuestions &&
      Object.keys(wrongGroups).filter(key => wrongGroups[key] === 'fetched')
        .length < 2 &&
      Object.keys(wrongGroups).filter(key => wrongGroups[key] === 'non_fetched')
        .length > 0
    ) {
      const unfetchedGroup = +Object.keys(wrongGroups).find(
        key => wrongGroups[key] === 'non_fetched'
      );
      fetchAdditionalQuestions(unfetchedGroup);
      let newWrongGroups = wrongGroups;
      newWrongGroups[unfetchedGroup] = 'fetched';
      setWrongGroups(newWrongGroups);
    }
  }, [
    fetchAdditionalQuestions,
    test.addQuestions,
    test.maxWrongAnswers,
    wrongGroups
  ]);

  const getRightAnswersCount = useCallback(
    () =>
      answers.reduce(
        (rightAnswersCount: number, answer?: PddAnswer) =>
          answer?.isRight ? rightAnswersCount + 1 : rightAnswersCount,
        0
      ),
    [answers]
  );

  const endTest = useCallback(
    async (totalTime: number) => {
      try {
        const rightAnswersCount = getRightAnswersCount();

        let status = pddTestStatusesEnum.passed;
        if (totalQuestionsCount - rightAnswersCount > test.maxWrongAnswers) {
          status = pddTestStatusesEnum.notPassed;
        }
        if (props.examTraining === pddTest.exam) {
          const wrongAnswersCountByGroup = {};
          let max = 0;
          answers.forEach((answer, index) => {
            if (!questions[index].groupId) return;

            if (!wrongAnswersCountByGroup[questions[index].groupId]) {
              wrongAnswersCountByGroup[questions[index].groupId] = 1;
            } else {
              wrongAnswersCountByGroup[questions[index].groupId] += 1;
            }
            max = Math.max(
              wrongAnswersCountByGroup[questions[index].groupId],
              max
            );
          });
          if (max >= 2) status = pddTestStatusesEnum.notPassed;
        }

        const testResult = await pddTestResultApi.add({
          examinationDate: moment()
            .utc()
            .toISOString(),
          pddTestId:
            props.examTraining === pddTest.training ? props.id : undefined,
          pddTicketId:
            props.examTraining === pddTest.training ? ticket?.id : undefined,
          totalTime,
          status,
          wrongAnswersCount: totalQuestionsCount - rightAnswersCount,
          rightAnswersCount: rightAnswersCount,
          pddTestResultAnswers: answers.map(
            (el?: PddAnswer, index: number) => ({
              pddQuestionId: questionsNumbers[index],
              pddAnswerId: el?.id,
              isWrongAnswer: el?.questionId ? !el?.isRight : undefined
            })
          )
        });

        setPddTestResults(testResult);
      } catch (e) {
        notification.error({ message: e.message });
        await navigate(`/pdd/test/${props.examTraining}/tests/`);
      }
    },
    [
      answers,
      getRightAnswersCount,
      props.examTraining,
      props.id,
      questions,
      questionsNumbers,
      test.maxWrongAnswers,
      ticket,
      totalQuestionsCount
    ]
  );

  const LocTimer = useCallback(
    () => (
      <Timer
        totalTime={test.examTime}
        onTimeExpired={async () => {
          await endTest(test.examTime);
          notification.warning({
            message: 'Время для тестирования закончилось.'
          });
        }}
        forwardedRef={timeRef}
        timeStart={testTimeStart}
      />
    ),
    [endTest, test.examTime, testTimeStart]
  );

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: pddTestResults
          ? 'Тестирование завершено'
          : questionsNumbers.map((el, index) => (
              <TestPaginationButton
                onClick={() => setCurrentQuestionNumber(index + 1)}
                answer={answers[index]}
                bezel={index + 1 === currentQuestionNumber}
                noHighlighting={props.examTraining === pddTest.exam}
              >
                {index + 1}
              </TestPaginationButton>
            )),
        rightHeader: pddTestResults ? (
          <Button
            type="primary"
            onClick={() => {
              navigate('/pdd/test/training/tests/');
            }}
          >
            Вернуться к списку тестов
          </Button>
        ) : answers.reduce(
            (count, answer?: PddAnswer) =>
              count + (Number.isInteger(answer?.id) ? 1 : 0),
            0
          ) === totalQuestionsCount && totalQuestionsCount !== 0 ? (
          <Button
            type="primary"
            onClick={() => {
              endTest(timeRef.current);
            }}
          >
            Закончить тест
          </Button>
        ) : ticket ? (
          <LocTimer />
        ) : (
          ''
        )
      }}
      onFetch={() => {}}
    >
      {ticket ? (
        !pddTestResults ? (
          <InnerForm
            data={questions[currentQuestionNumber - 1]}
            answer={answers[currentQuestionNumber - 1]}
            setAnswer={async (answer: PddAnswer) => {
              let a = [...answers];
              a[currentQuestionNumber - 1] = answer;
              setAnswers(a);

              if (answer && !answer.isRight) {
                if (
                  !wrongGroups[questions[currentQuestionNumber - 1].groupId]
                ) {
                  let newWrongGroups = wrongGroups;
                  newWrongGroups[questions[currentQuestionNumber - 1].groupId] =
                    'non_fetched';
                  setWrongGroups(newWrongGroups);
                }
              }
            }}
            getNextQuestion={() =>
              setCurrentQuestionNumber(currentQuestionNumber + 1)
            }
            isLastQuestion={currentQuestionNumber === totalQuestionsCount}
            noWrapMe
            noHighlighting={props.examTraining === pddTest.exam}
          />
        ) : (
          <Results data={pddTestResults} noWrapMe />
        )
      ) : (
        <TicketModal
          testId={+props.id}
          visible={ticketSelectModalVisible}
          onClose={() => {
            navigate(`/pdd/test/${props.examTraining}/tests/`, true);
          }}
          onSelect={ticket => {
            setTicket(ticket);
          }}
        />
      )}
    </CommonCardPage>
  );
};
