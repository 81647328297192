// @flow
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import Popover from 'antd/lib/popover';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';

import { setVehicleTaxPlanVehicle } from '../../../../ducks/vehicleTaxPlan';
import { vehicleStatuses, vehicleTypes } from '../../../../lib/enum';

import type { VehicleTaxPlan, VehicleTaxPlanVehicle } from '../../../../lib/types';

import type { VehicleListFilterParams } from '../../vehicle/components/FilterVehicleList';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import Spinner from './../../../../components/Spinner';

import ListTable from './../../../../components/ui/ListTable';
import Filter from './../../vehicle/components/FilterVehicleList';

type Props = {
  data: VehicleTaxPlanVehicle[],
  vehicleTaxPlan: ?$Shape<VehicleTaxPlan>,
  vehiclePlanType: string,
  setVehicleTaxPlanVehicle: Function,
  selectedVehicle?: VehicleTaxPlanVehicle[],
  loading?: boolean
};

type State = {
  localData: VehicleTaxPlanVehicle[],
  selectedVehicle: Map<number, VehicleTaxPlanVehicle>,
  filter: VehicleListFilterParams
};

const Row = styled(Grid).attrs(props => {
  return {
    column: props.column || false
  };
})`
  font-size: 12px;
  grid-template-columns: 1fr 1fr 1fr 150px 150px ${props =>
      props.column ? '1fr' : ''};
`;
const ListHeader = styled(Row)`
  background: #e4ebf2;
  font-weight: bold;
  min-width: 730px;
  border-radius: 2px 2px 0 0;
`;
const Cell = styled(GridItem)`
  padding: 11px 16px;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const VehicleType = styled.div`
  flex: 1;
`;
const IconWarn = styled(ExclamationCircleTwoTone)`
  font-size: 13px;
  position: relative;
  margin-left: 10px;
  top: 4px;
`;

const RenderRow = props => {
  const { row, isSelfVehicle, indexRow, changeDateEnd } = props;

  return (
    <Row column={true}>
      <Cell>
        <Container>
          <VehicleType>
            {vehicleTypes[row.vehicle?.vehicleModel?.type]}
          </VehicleType>
          {row?.hasTaxRate === false && (
            <Popover
              content={<>Не задан налоговый коэффициент</>}
              placement="right"
            >
              <IconWarn
                twoToneColor="#faad14"
              />
            </Popover>
          )}
        </Container>
      </Cell>
      <Cell>
        {`${row.vehicle.vehicleModel.brandName} ${row.vehicle.vehicleModel.name}`}
      </Cell>
      <Cell>{row.vehicle.licensePlate}</Cell>
      <Cell>{row.vehicle.yearIssued}</Cell>
      <Cell>{vehicleStatuses[row.vehicle.status]}</Cell>
      <Cell>
        <DatePicker
          size={'small'}
          format="DD MMMM YYYY"
          value={
            isSelfVehicle
              ? row.plannedWriteoffDate
                ? moment(row.plannedWriteoffDate)
                : null
              : row.plannedPurchaseDate
              ? moment(row.plannedPurchaseDate)
              : null
          }
          onChange={(value: string) => {
            changeDateEnd(value, row, indexRow, isSelfVehicle);
          }}
        />
      </Cell>
    </Row>
  );
};

class InnerTable extends React.PureComponent<Props, State> {
  state = {
    localData: [],
    selectedVehicle: new Map(),
    filter: {}
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      let selectedVehicle = new Map();
      if (isEmpty(this.props.selectedVehicle)) {
        this.props.data.forEach(item =>
          selectedVehicle.set(item.vehicleId, item)
        );
      } else {
        if (Array.isArray(this.props.selectedVehicle))
          this.props.selectedVehicle.forEach(item => {
            selectedVehicle.set(item.vehicleId, item);
          });
      }
      this.setState(
        { selectedVehicle, localData: [...this.props.data] },
        this.setVehicle
      );
    }
  }

  addVehicle = (vehicleId: number, data: VehicleTaxPlanVehicle) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      selectedVehicle.set(vehicleId, data);
      return { selectedVehicle };
    }, this.setVehicle);
  };

  deleteVehicle = (vehicleId: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      selectedVehicle.delete(vehicleId);
      return { selectedVehicle };
    }, this.setVehicle);
  };

  setVehicle = () =>
    this.props.setVehicleTaxPlanVehicle(
      this.state.selectedVehicle,
      this.props.vehiclePlanType === 'selfVehicles'
    );

  onChangeMonth = (name: string, value: boolean, index: number) => {
    let { localData } = this.state;
    localData[index][name] = value;
    this.setState({ localData }, () =>
      this.addVehicle(localData[index]['vehicleId'], localData[index])
    );
  };

  header = () => {
    const { vehiclePlanType } = this.props;
    const isSelfVehicle = vehiclePlanType === 'selfVehicles';
    return (
      <ListHeader column={true}>
        <Cell>Тип ТС</Cell>
        <Cell>Марка и модель</Cell>
        <Cell>Госномер</Cell>
        <Cell>Год выпуска</Cell>
        <Cell>Текущий статус</Cell>
        {isSelfVehicle ? (
          <Cell>Планируемая дата списания</Cell>
        ) : (
          <Cell>Планируемая дата приобретения</Cell>
        )}
      </ListHeader>
    );
  };

  changeDateEnd = (
    date: moment,
    row: any,
    indexRow: number,
    isSelfVehicle: boolean
  ) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      const data = isSelfVehicle
        ? {
            ...row,
            plannedWriteoffDate: date
              ? moment
                  .utc(date)
                  .startOf('day')
                  .toISOString()
              : null
          }
        : {
            ...row,
            plannedPurchaseDate: date
              ? moment
                  .utc(date)
                  .startOf('day')
                  .toISOString()
              : null
          };
      selectedVehicle.get(row.vehicleId) &&
        selectedVehicle.set(row.vehicleId, data);
      state.localData[indexRow] = data;
      return { selectedVehicle, localData: state.localData };
    }, this.setVehicle);
  };

  applyFilter = filter => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  render() {
    const { loading, vehiclePlanType } = this.props;
    const { selectedVehicle, localData, filter } = this.state;
    return (
      <>
        <Filter
          filter={filter}
          showHideEmpty={false}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
        />
        <Spinner isLoading={loading}>
          <ListTable
            key={vehiclePlanType}
            style={{ overflowX: 'auto' }}
            styleContent={{ minWidth: '830px' }}
            data={localData
              .filter((selfVehicle: VehicleTaxPlanVehicle) => {
                const { yearIssued } = filter;
                return yearIssued
                  ? selfVehicle.vehicle.yearIssued === yearIssued
                  : true;
              })
              .filter((selfVehicle: VehicleTaxPlanVehicle) => {
                const { vehicleModelId } = filter;
                return vehicleModelId
                  ? selfVehicle.vehicle.vehicleModelId === vehicleModelId
                  : true;
              })
              .filter((selfVehicle: VehicleTaxPlanVehicle) => {
                const { vehicleId } = filter;
                return vehicleId ? selfVehicle.vehicle.id === vehicleId : true;
              })}
            header={this.header()}
            renderRow={(row, index) => {
              return (
                <RenderRow
                  indexRow={index}
                  isSelfVehicle={vehiclePlanType === 'selfVehicles'}
                  changeDateEnd={this.changeDateEnd}
                  key={row.id}
                  idx={row.id}
                  selected={selectedVehicle.has(row.vehicleId)}
                  row={row}
                  addVehicle={this.addVehicle}
                  deleteVehicle={this.deleteVehicle}
                />
              );
            }}
          />
        </Spinner>
      </>
    );
  }
}

export default connect(() => ({}), { setVehicleTaxPlanVehicle })(InnerTable);
