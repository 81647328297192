// @flow

import notification from 'antd/lib/notification';
import { TreeSelectProps } from 'antd/lib/tree-select';
import React from 'react';
import { orgUnitApi } from '../lib/api';

import type { NodeFilterType, OrgUnitNode } from './../lib/types/';

import TreeSelect from './ui/TreeSelect';

// Фильтры по оргюнитам
type OrgUnitFilter = {
  nodeId?: number,
  nodeFilterType?: NodeFilterType,
  // Выбор только среди филиалов
  onlyBranch?: boolean,
  // Выбор только среди служб
  onlyService?: boolean,
};

type State = {
  tree: ?OrgUnitNode,
};

type Props = TreeSelectProps & {
  onlyBranches?: boolean,
  filter?: OrgUnitFilter,
};

export default class OrgUnitSelect extends React.Component<Props, State> {
  static defaultProps = {
    onlyBranches: false,
  };

  state = {
    tree: null,
  };

  _isMounted = false;

  mapData = (node: OrgUnitNode) => ({
    ...node,
    value: node.id,
    title: node.name,
    children: node.children
      ? node.children
        .filter((node) =>
          this.props.onlyBranches ? node.type === 'branch' : true,
        )
        .map<OrgUnitNode>(this.mapData)
      : [],
  });

  async componentDidMount() {
    this._isMounted = true;
    try {
      const tree = this.mapData(
        await orgUnitApi.fetchOrgUnits({ ...this.props.filter })
      );
      if (this._isMounted) {
        this.setState({ tree });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { tree } = this.state;
    const { filter, onlyBranches, ...props } = this.props;
    return (
      <TreeSelect
        treeDefaultExpandAll={false}
        placeholder="Выберите подразделение"
        className="orgUnitSelect"
        allowClear
        showSearch
        treeNodeFilterProp="title"
        treeData={tree ? [tree] : []}
        {...props}
      />
    );
  }
}
