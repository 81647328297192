// @flow
import { fetchRequest } from './index';
import type {
  WashingCalculation,
  WashingCalculationMonth,
  VehiclePlan,
  CalculationStatus
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataToServer = (
  washingCalculation: WashingCalculation
): WashingCalculation => ({
  ...washingCalculation,
  sum: convertFromRubToKop(washingCalculation.sum),
  months: washingCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  washingCalculation: WashingCalculation
): WashingCalculation => ({
  ...washingCalculation,
  sum: convertFromKopToRub(washingCalculation.sum),
  months: washingCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  washingCalculationMonth: WashingCalculationMonth
): WashingCalculationMonth => ({
  ...washingCalculationMonth,
  cost: convertFromRubToKop(washingCalculationMonth.cost)
});

export const convertDataFromServerMonth = (
  washingCalculationMonth: WashingCalculationMonth
): WashingCalculationMonth => ({
  ...washingCalculationMonth,
  cost: convertFromKopToRub(washingCalculationMonth.cost)
});

const basedUrl = 'washingCalculation';
const washingCalculation = new CommonApi<WashingCalculation>(
  basedUrl,
  'расчет потребности в мойке ТС',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  vehiclePlanId: number
): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось рассчитать`);
};

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

export default {
  ...washingCalculation,
  updateCalculations,
  calculate,
  changeStatus
};
