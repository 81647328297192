//@flow
import { Input } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';

import { FilterItem } from '../../../../../components';
import { DatePicker } from '../../../../../components/ant/DatePicker';
import Field from '../../../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../../../components/hoc/common/handbook/CommonFilter';
import Selects from '../../../../../components/selects';
import { PddTestTypeSelect } from '../../../../../components/selects/AutoGeneratedEnumSelects';

import { formatDateTimeToISOString } from '../../../../../lib/helpers';
import type { PddTestStatusesType } from '../../../../../lib/types';

const { PddGroupSelect } = Selects;

export type PddTestTrainingParamsFilter = {
  startDate?: string,
  endDate?: string,
  pddGroupId?: number,
  name?: string,
  status?: PddTestStatusesType
};

export const PddTestTrainingFilter = (
  props: FilterProps<PddTestTrainingParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PddTestTrainingParamsFilter>>) =>
      CommonFilter<PddTestTrainingParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter additionalFilter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <PddGroupSelect
                size="small"
                value={values.pddGroupId}
                onChange={value => changeValue('pddGroupId', value)}
                placeholder="Тема обучения"
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                value={values.name}
                onChange={e => changeValue('name', e.target.value || undefined)}
                placeholder="Наименование теста"
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <PddTestTypeSelect
                size="small"
                value={values.status}
                onChange={value => changeValue('status', value)}
                reset
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PddTestTrainingFilter;
