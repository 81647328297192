// @flow

import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Panel } from '../../components/layout';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import { notificationLoading } from '../../components/Notifications';

import { setMaintenance } from '../../ducks/maintenance';

import { maintenanceApi } from '../../lib/api';
import { maintenanceStatusesEnum } from '../../lib/enum';
import type { Maintenance } from '../../lib/types';

import InnerForm from './components/InnerForm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  location: Location & {
    state: {
      vehicleId: number,
      maintenanceWorkTypeId: number,
      plannedDate: string
    }
  },
  setMaintenance: Function
};

class MaintenanceForm extends Component<Props> {
  onSubmit = async (values: Maintenance) => {
    const { setMaintenance } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const maintenance = await maintenanceApi.addMaintenance({
        ...values,
        status: maintenanceStatusesEnum.draft
      });
      setMaintenance(maintenance);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });

      navigate(`/maintenances/journal/${maintenance.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { location } = this.props;
    const initialValue: $Shape<Maintenance> = {
      vehicleId: location.state.vehicleId,
      maintenanceWorkTypeId: location.state.maintenanceWorkTypeId,
      maintenancePlannedDate: location.state.plannedDate
    };
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Транспорт</Crumb>
              <Crumb to="/maintenances/journal">Ремонт</Crumb>
              <Crumb to="/maintenances/journal/new">Новая карточка работ</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>Новая карточка работ</h1>
        </StyledPanel>
        <InnerForm initialValue={initialValue} onSubmit={this.onSubmit} />
      </>
    );
  }
}

export default connect(() => ({}), { setMaintenance })(MaintenanceForm);
