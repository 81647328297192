// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { pddQuestionApi } from './../../../lib/api';
import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../../withUserAccess';

import { getBreadCrumbsByUrl } from '../../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/pdd/questions/';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Наименование темы',
    dataIndex: 'group.name',
    render: name => <div style={{ whiteSpace: 'break-spaces' }}>{name}</div>
  },
  {
    title: 'Вопрос',
    dataIndex: 'question',
    render: question => (
      <div style={{ whiteSpace: 'break-spaces' }}>{question}</div>
    )
  },
  {
    title: 'Фото',
    dataIndex: 'photo',
    render: photo => (photo?.id ? 'Да' : 'Нет')
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  return (
    <Page>
      <CommonListPage
        crud={pddQuestionApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Вопросы ПДД',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: true
        }}
      />
    </Page>
  );
});
