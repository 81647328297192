// @flow
import { Dispatch } from 'redux';

import type { Action, AppState, ListState } from './redux';
import { defaultPageSize } from './constants';
import type { FuelAndOil } from '../lib/types';
import { fuelAndOilApi } from '../lib/api';
import type { FetchListParams } from '../lib/api';

export const FETCH_FUELS_AND_OILS = 'vehicles/fuels-and-oils/fetch';
export const DELETE_VEHICLE_MODEL = 'vehicles/models/delete';

export type FuelsAndOilsState = ListState<FuelAndOil> & {};

const initialState: FuelsAndOilsState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0
};

const reducer = (
  state: FuelsAndOilsState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case FETCH_FUELS_AND_OILS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const fetchFuelsAndOils = (
  page: number = 1,
  params: FetchListParams<any> = {}
) => async (dispatch: Dispatch, getState: () => AppState) => {
  const { pageSize } = getState().fuelsAndOils;
  const { data, totalCount } = await fuelAndOilApi.fetchFuelsAndOils({
    page,
    pageSize,
    ...params
  });
  dispatch({
    type: FETCH_FUELS_AND_OILS,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export default reducer;
