// @flow
import { type ComponentType } from 'react';
import styled, { css } from 'styled-components';
/**
 * Тип разделителя
 */
export type DividerType = 'shadow' | 'solid';

/**
 * Заданные цвета разделителя
 */
export const DividerColorEnum = {
  gray: '#adb8c3',
  light: '#c0ccd7'
};

type Props = {
  /**
   * Тип
   */
  type?: DividerType,
  /** Высота */
  height?: string,
  /**
   * Основной цвет
   * Может быть значением из enum,
   * а может быть произвольным цветом
   */
  color?: $Keys<typeof DividerColorEnum> | string
};

function getColor(color: string) {
  return DividerColorEnum[color] || color;
}

/**
 * Компонент разделитель
 * Необходим для разделения частей интерфейса
 */
const Divider: ComponentType<Props> = styled.hr.attrs(props => ({
  type: props.type || 'solid'
}))`
  border: none;
  margin: 0;
  ${props =>
    props.type === 'solid' &&
    css`
      height: ${props.height || '1px'};
      background: ${getColor(props.color) || '#adb8c3'};
    `};
  ${props =>
    props.type === 'shadow' &&
    css`
      height: ${props.height || '10px'};
      background: linear-gradient(
        180deg,
        ${getColor(props.color) || '#dee5eb'} 0%,
        rgba(255, 255, 255, 0) 100%
      );
    `};
`;

export default Divider;
