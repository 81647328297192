// @flow
import React, { useCallback, useState } from 'react';

import { businessCalendarForBudgetApi } from '../../../lib/api';
import type { BusinessCalendarForBudget } from '../../../lib/types';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { goBack } from '../../../lib/helpers';

type PageProps = {
  dayId: number
};

const entryPointPath = '/admin/the-business-calendar-for-budget/';

export default (props: PageProps) => {
  const [data: BusinessCalendarForBudget, setData] = useState({
    id: props.dayId
  });

  const breadCrumbs = [
    { to: '/', name: 'Главная' },
    { name: 'Справочники' },
    { to: `${entryPointPath}`, name: 'Производственный календарь для бюджета' },
    ...(data.id
      ? [
          { to: `${entryPointPath}${data.id}`, name: data.id.toString() },
          { name: 'Редактирование' }
        ]
      : [{ name: 'Новая запись' }])
  ];

  const onFetch = useCallback(async () => {
    !!props.dayId &&
      setData(await businessCalendarForBudgetApi.get(props.dayId));
  }, [props.dayId]);

  const onSubmit = async (payload: BusinessCalendarForBudget) => {
    let id = payload.id;
    if (id) {
      setData(await businessCalendarForBudgetApi.update(payload));
    } else {
      let data = await businessCalendarForBudgetApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}${id}`);
  };

  const onCancel = async (payload: BusinessCalendarForBudget) => {
    if (data.id) {
      await goBack(`${entryPointPath}${data.id}`);
    } else await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Производственный календарь для бюджета'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
