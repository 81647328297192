// @flow

import React, { Component } from 'react';

import { Formik } from 'formik';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';

import type { Trip, Vehicle, WayPoint } from '../../lib/types';
import { selectTrip } from '../../ducks/selectors';
import { fetchTrip, cleanTrip, updateTrip } from '../../ducks/trip';
import { orderApi, tripApi } from './../../lib/api';
import InnerForm from './components/InnerForm';
import {
  tripStatusEnum,
  waypointTypeEnum,
  trailerVehicleTypes,
} from '../../lib/enum';
import type { AppState } from '../../ducks/redux';
import { directions } from '../../lib/gis';
import type { Direction } from '../../lib/gis';
import { Panel } from './../../components/layout';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import { getPathWithHistoryParams, navigate } from '../../lib/helpers';
import { getSelectionVehicles } from '../Orders/lib';
import { notificationLoading } from '../../components/Notifications';
import type { Order } from '../../lib/types';

type Props = {
  trip: Trip,
  updateTrip: Function,
  orgUnitId: ?number,
  tripId?: number,
  fetchTrip: Function,
  cleanTrip: () => void,
  employeeBranchOrgUnitId: number,
};

type State = {
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  geometry: ?Direction,
  orders: Order[],
  isSubmitting: boolean,
};

class TripForm extends Component<Props, State> {
  state = {
    freeVehicles: [],
    freeTrailers: [],
    geometry: null,
    orders: [],
    isSubmitting: false,
  };
  async componentDidMount() {
    const { tripId } = this.props;
    await this.props.cleanTrip();
    try {
      const trip = await this.props.fetchTrip(tripId);
      if (trip) {
        const withTrailer = trip.withTrailer;
        const freeAllVehicles = await tripApi.fetchTripFreeVehicles(trip.id);
        const orders = await Promise.all(
          trip.ordersId.map(async (id) => await orderApi.fetchOrder(id))
        );
        let freeVehicles = [];
        let freeTrailers = [];
        freeAllVehicles.forEach((vehicle: Vehicle) => {
          if (
            trip.withTrailer &&
            trailerVehicleTypes.includes(vehicle.vehicleModel.type)
          ) {
            freeTrailers.push({
              ...vehicle,
              disabled: false,
            });
          } else {
            freeVehicles.push(vehicle);

            /**
             * Если у нас заявка на ТС с прицепом и к ТС прикреплены прицепы,
             * то мы их добавляем, как прицепы, которые можно выбрать, но ставим отметку,
             * что они заблокированы для выбора.
             * Блокируем мы их потому, что прицеп можно выбрать только в связке ТС + прицеп.
             * Выбрать прикрепленный прицеп к другому ТС нельзя, для этого нужно произвести
             * манипуляции по откреплению прицепа в инвентарной карточке
             */
            if (withTrailer) {
              const vehicleTrailers = vehicle.trailers.map<Vehicle>(
                (item: Vehicle) => ({
                  ...item,
                  disabled: true,
                })
              );
              freeTrailers.push(...vehicleTrailers);
            }
          }
        });

        const { trailers, vehicles } = getSelectionVehicles({
          selectedTrailer: trip.trailer,
          selectedVehicle: trip.vehicle,
          trailers: freeTrailers,
          vehicles: freeVehicles,
          withTrailer: trip.withTrailer,
        });

        this.setState({
          freeVehicles: vehicles,
          freeTrailers: trailers,
          orders,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  // Проставляем новые точки стоянки взамен старых, если такие присутствуют в ТС
  vehicleChanged = async (
    vehicle: Vehicle,
    expectedWaypoints: Array<WayPoint>
  ): Promise<{
    driverId?: ?number,
    expectedWaypoints: Array<WayPoint>,
  }> => {
    const result = {};
    if (parseInt(vehicle.driverId) > 0) {
      result.driverId = vehicle.driverId;
    }
    const transitWaypoints = expectedWaypoints.filter(
      (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.transit
    );
    if (vehicle.location) {
      const { latitude, longitude, name, radius, address } = vehicle.location;
      const waypoint = { latitude, longitude, name, radius, address };
      result.expectedWaypoints = [
        { ...waypoint, type: waypointTypeEnum.start },
        ...transitWaypoints,
        { ...waypoint, type: waypointTypeEnum.end },
      ];
    } else {
      result.expectedWaypoints = transitWaypoints;
    }
    if (vehicle.trailers && vehicle.trailers.length > 0) {
      result.trailer = vehicle.trailers[0];
      result.trailerId = result.trailer.id;
    } else {
      result.trailer = null;
      result.trailerId = null;
    }
    return result;
  };

  onSubmit = async (values: Trip) => {
    const { tripId } = this.props;
    notificationLoading({
      message: 'Сохранение данных...',
      key: 'saving',
    });
    try {
      await this.props.updateTrip(values);
      navigate(`/trips/self/${tripId ? `${tripId}/card` : ''}`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { trip, employeeBranchOrgUnitId } = this.props;
    const { freeVehicles, freeTrailers, orders, isSubmitting } = this.state;
    if (!trip) {
      return null;
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/trips/self/')}>
                Путевые листы
              </Crumb>
              <Crumb>
                {trip.status === tripStatusEnum.draft
                  ? 'Заявка'
                  : `Путевой лист №${parseInt(trip.idNumber) || ''}`}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <Panel>
          <h1>Путевой лист №{trip.id}</h1>
        </Panel>
        <Formik
          initialValues={{
            ...trip,
            // Если у нас имеется авто, но нет пробега на старте, то записываем это значение
            odometerAtStart:
              parseFloat(trip.vehicleId) > 0 && !trip.odometerAtStart
                ? trip.vehicle.waybillKilometrage
                : trip.odometerAtStart,
          }}
          render={(formikProps) => (
            <InnerForm
              {...formikProps}
              isEdit
              data={{
                ...trip,
                // Если у нас имеется авто, но нет пробега на старте, то записываем это значение
                odometerAtStart:
                  parseFloat(trip.vehicleId) > 0 && !trip.odometerAtStart
                    ? trip.vehicle.waybillKilometrage
                    : trip.odometerAtStart,
              }}
              onSubmit={this.onSubmit}
              onCancel={() => navigate('/trips/self/', true)}
              onVehicleChange={this.vehicleChanged}
              freeVehicles={freeVehicles}
              freeTrailers={freeTrailers}
              onCalculateGeometry={async (formTrip) => {
                const directionsResponse = await directions(
                  formTrip.expectedRoute.waypoints
                );
                this.setState({ geometry: directionsResponse });
                return directionsResponse;
              }}
              isSubmitting={isSubmitting}
              order={orders}
              tripGeometry={this.state.geometry}
              employeeBranchOrgUnitId={employeeBranchOrgUnitId}
            />
          )}
          enableReinitialize
          validate={(values: Trip) => {
            let errors = {};
            if (!values.objective) {
              errors.name = 'Обязательно для заполнения';
            }
            if (!values.vehicleType && !values.vehicleGroup) {
              errors.vehicleType = 'Обязательно для заполнения';
            }
            if (isNaN(parseFloat(values.workersCount))) {
              errors.workersCount = 'Обязательно для заполнения';
            } else if (values.workersCount < 0) {
              errors.workersCount = 'Неверное количество работников';
            }
            if (!parseInt(values.employeeId)) {
              errors.employeeId = 'Обязательно для заполнения';
            }
            if (
              !(
                values.expectedRoute.waypoints &&
                values.expectedRoute.waypoints.length
              )
            ) {
              errors.expectedWaypoints = 'Маршрут обязателен для заполнения';
            }
            if (!parseFloat(values.expectedRoute.distance)) {
              errors.expectedDistance = 'Обязательно для заполнения';
            } else if (values.expectedRoute.distance < 0) {
              errors.expectedDistance =
                'Неверное значение предполагаемого пробега';
            }
            if (!values.odometerAtStart) {
              errors.odometerAtStart = 'Обязательно для заполнения';
            } else if (
              values.odometerAtStart < (values.vehicle.waybillKilometrage ?? 0)
            ) {
              // $FlowFixMe Ругается на строку, хочет number
              errors.odometerAtStart = `Пробег при выезде не может быть меньше фактического пробега (текущее значение ${values.vehicle.waybillKilometrage} км.)`;
            }
            if (!parseFloat(values.distanceAtStart)) {
              errors.distanceAtStart = 'Обязательно для заполнения';
            }
            if (!parseFloat(values.distanceAtEnd)) {
              errors.distanceAtEnd = 'Обязательно для заполнения';
            }
            return errors;
          }}
          onSubmit={async (values: Trip) => {
            this.setState({ isSubmitting: true });
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving',
              });
              await this.props.updateTrip({
                ...values,
                odometerAtEnd:
                  values.odometerAtStart + values.expectedRoute.distance,
              });
              notification.success({
                message: 'Успешно сохранено',
                description: 'Изменения успешно сохранены',
              });
              this.props?.tripId &&
                navigate(`/trips/self/${this.props.tripId}/card`);
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message,
              });
            } finally {
              notification.close('saving');
              this.setState({ isSubmitting: false });
            }
          }}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    trip: selectTrip(state),
    orgUnitId: parseInt(ownProps.orgUnitId, 10),
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  }),
  {
    updateTrip,
    fetchTrip,
    cleanTrip,
  }
)(TripForm);
