// @flow
import type { PddTestResult } from '../types';
import CommonApi from './../commonApi';
import { downloadRequestWithToken } from './';

const pddTestResult = new CommonApi<PddTestResult>(
  'pddTestResult',
  'Результаты ПДД'
);

export const printExam = async (date: string): Promise<boolean> => {
  const print = await downloadRequestWithToken(
    `/pddTestResult/pddExamTestsResults/print`,
    {
      date
    }
  );
  if (print) return print;
  throw new Error('Не удалось распечатать');
};

export const printTraining = async (date: string): Promise<boolean> => {
  const print = await downloadRequestWithToken(
    `/pddTestResult/pddTrainingTestsResults/print`,
    {
      date
    }
  );
  if (print) return print;
  throw new Error('Не удалось распечатать');
};

export default {
  ...pddTestResult,
  printExam,
  printTraining
};
