// @flow
import React, { useState, useCallback } from 'react';

import { goBack } from '../../lib/helpers';
import { inspectionGtnCostApi } from '../../lib/api';
import { type InspectionGtnCost } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/inspection-gtn-cost/';

export default (props: PageProps) => {
  const [data: InspectionGtnCost, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await inspectionGtnCostApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: InspectionGtnCost) => {
    let id = payload.id;
    if (id) {
      await inspectionGtnCostApi.update(payload);
    } else {
      let data = await inspectionGtnCostApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}${id}`);
  };

  const onCancel = async (payload: InspectionGtnCost) => {
    if (data.id) {
      await goBack(`${entryPointPath}${data.id}`);
    } else await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость и периодичность  ТО Гостехнадзор'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
