// @flow
import type { InspectionGtnCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (inspectionGtnCost: InspectionGtnCost) => ({
  ...inspectionGtnCost,
  cost: convertFromRubToKop(inspectionGtnCost.cost),
  tax: convertFromRubToKop(inspectionGtnCost.tax)
});

const convertDataFromServer = (inspectionGtnCost: InspectionGtnCost) => ({
  ...inspectionGtnCost,
  cost: convertFromKopToRub(inspectionGtnCost.cost),
  tax: convertFromKopToRub(inspectionGtnCost.tax)
});

const inspectionGtnCost = new CommonApi<InspectionGtnCost>(
  'inspectionGtnCost',
  'Стоимость и периодичность ТО Гостехнадзор',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...inspectionGtnCost
};
