// @flow
import { EllipsisOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import Menu from 'antd/lib/menu';

import type { VehicleIssue, MaintenanceWorkType } from '../../../lib/types';
import { ListTable, Dropdown } from './../../../components/ui';
import ModalImage from './../components/ModalImage';
import { maintenanceTypeOfWorkEnum } from '../../../lib/enum';

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleIssues: VehicleIssue[],
  handlePrint: ({ key: string, vehicleIssueId: number }) => void,
  maintenanceWorkType: MaintenanceWorkType
};
type State = {
  columns: any[]
};

/**
 * Дефектовая ведомость только просмотр
 */
export default class extends React.Component<Props, State> {
  state = {
    columns: []
  };

  componentDidMount() {
    const { maintenanceWorkType } = this.props;
    switch (maintenanceWorkType.workType) {
      case maintenanceTypeOfWorkEnum.tire:
        this.setState({ columns: this.getColumnsTire() });
        break;
      case maintenanceTypeOfWorkEnum.akb:
        this.setState({ columns: this.getColumnsAkb() });
        break;
      default:
        this.setState({ columns: this.getColumnsCommon() });
        break;
    }
  }

  getColumnsCommon() {
    return [
      {
        title: 'Группа дефектов',
        style: {
          overflow: 'hidden'
        },
        key: 'vehicleIssueGroupId',
        render: (type: number, record: VehicleIssue, index: number) =>
          record && record.vehicleIssueGroup
            ? record.vehicleIssueGroup.name
            : '-'
      },
      {
        title: 'Характеристика дефекта',
        key: 'description',
        render: (description: string, record: VehicleIssue, index: number) =>
          description === null || description === '' ? '-' : description
      },
      {
        width: '45px',
        renderRecord: (record: any, index: any) => {
          return (
            <Operations>
              <Dropdown
                overlayStyle={{ zIndex: '999' }}
                overlay={
                  <Menu>
                    {!isEmpty(record.files) ? (
                      <Menu.Item>
                        <ModalImage
                          noStyle
                          vehicleIssueId={record.id}
                          files={record.files}
                        />
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: 16, color: '#2770FF' }}
                />
              </Dropdown>
            </Operations>
          );
        }
      }
    ];
  }

  getColumnsAkb() {
    return [
      {
        title: 'АКБ',
        style: {
          overflow: 'hidden'
        },
        key: 'batteryId',
        render: (type: number, record: VehicleIssue, index: number) =>
          record && record.battery ? record.battery.name : '-'
      },
      {
        title: 'Заводской номер',
        key: 'battery',
        render: (battery: any) =>
          battery && battery.factoryNumber ? battery.factoryNumber : '-'
      },
      {
        title: 'Характеристика дефекта',
        key: 'description',
        render: (description: string, record: VehicleIssue, index: number) =>
          description === null || description === '' ? '-' : description
      },
      {
        width: '45px',
        renderRecord: (record: any, index: any) => {
          return (
            <Operations>
              <Dropdown
                overlayStyle={{ zIndex: '999' }}
                overlay={
                  <Menu>
                    {!!record.id && (
                      <Menu.Item
                        onClick={() =>
                          this.props.handlePrint({
                            key: 'printVehicleIssue',
                            vehicleIssueId: record.id
                          })
                        }
                      >
                        Печать деф. акта
                      </Menu.Item>
                    )}
                    {!isEmpty(record.files) ? (
                      <Menu.Item>
                        <ModalImage
                          noStyle
                          vehicleIssueId={record.id}
                          files={record.files}
                        />
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: 16, color: '#2770FF' }}
                />
              </Dropdown>
            </Operations>
          );
        }
      }
    ];
  }

  getColumnsTire() {
    return [
      {
        title: 'Шина',
        style: {
          overflow: 'hidden'
        },
        key: 'tireId',
        render: (type: number, record: VehicleIssue, index: number) => {
          return record && record.tire ? record.tire.name : '-';
        }
      },
      {
        title: 'Заводской номер',
        key: 'tire',
        render: (tire: any) =>
          tire && tire.factoryNumber ? tire.factoryNumber : '-'
      },
      {
        title: 'Характеристика дефекта',
        key: 'description',
        render: (description: string, record: VehicleIssue, index: number) =>
          description === null || description === '' ? '-' : description
      },
      {
        width: '45px',
        renderRecord: (record: any, index: any) => {
          return (
            <Operations>
              <Dropdown
                overlayStyle={{ zIndex: '999' }}
                overlay={
                  <Menu>
                    {!!record.id && (
                      <Menu.Item
                        onClick={() => this.props.handlePrint(record.id)}
                      >
                        Печать деф. акта
                      </Menu.Item>
                    )}
                    {!isEmpty(record.files) ? (
                      <Menu.Item>
                        <ModalImage
                          noStyle
                          vehicleIssueId={record.id}
                          files={record.files}
                        />
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
              >
                <EllipsisOutlined
                  style={{ fontSize: 16, color: '#2770FF' }}
                />
              </Dropdown>
            </Operations>
          );
        }
      }
    ];
  }

  render() {
    const { vehicleIssues } = this.props;
    const { columns } = this.state;
    return (
      <>
        <ListTable columns={columns} data={vehicleIssues} />
      </>
    );
  }
}
