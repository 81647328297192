import styled, { css } from 'styled-components';

export const SignalLevel = styled.div`
  width: 1px;
  background: #adb8c3;
  border-radius: 1px;
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `};
  ${props =>
    props.active &&
    css`
      background: #27ae60;
    `}
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  & ${SignalLevel} {
    margin-right: 2px;
  }
  & ${SignalLevel}:last-child {
    margin-right: 0;
  }
`;
