// @flow
import React from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import Modal from 'antd/lib/modal';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import { type UploadProps } from 'antd/lib/upload';
import orderBy from 'lodash/orderBy';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import type { FileTag, Vehicle } from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';
import { Form } from '../../../components';
import { isImage, validateFile } from '../../../lib/helpers';
import { fileTagEnum, fileTags } from '../../../lib/enum';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import Uploader from '../components/Uploader';

const StyledSection = styled(Section)`
  padding: 16px 0;
`;
const SectionContent = styled.div`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const redirectToByTag = {
  [fileTagEnum.all]: `files/${fileTagEnum.all}`,
  [fileTagEnum.repairActs]: `files/${fileTagEnum.mainDocs}`,
  [fileTagEnum.mainDocs]: `files/${fileTagEnum.mainDocs}`,
  [fileTagEnum.osago]: `files/${fileTagEnum.mainDocs}`,
  [fileTagEnum.driverPhoto]: `files/${fileTagEnum.driverPhoto}`,
  [fileTagEnum.pddPhoto]: `files/${fileTagEnum.pddPhoto}`
};

type Props = {
  vehicle: Vehicle,
  tag: FileTag,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

type State = {
  previewVisible: boolean,
  previewFile: Object
};

class Files extends React.Component<Props, State> {
  state = {
    previewVisible: false,
    previewFile: {}
  };

  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    const { tag } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/${redirectToByTag[tag]}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    const { vehicleId, tag } = this.props;
    navigate(`/vehicles/${vehicleId}/${redirectToByTag[tag]}`);
  };

  handlePreview = file => {
    this.setState({
      previewFile: file,
      previewVisible: true
    });
  };

  beforeUpload = function(file: UploadProps) {
    const validFileType = validateFile(file.type, this.props.tag);
    if (!validFileType) {
      notification.error({
        message: 'Загружать можно только изображения и PDF!'
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.error({ message: 'Файл должен быть менее 10MB!' });
    }
    return validFileType && isLt2M;
  };

  handleClose = () => this.setState({ previewVisible: false, previewFile: {} });

  render() {
    const { vehicle = {}, tag } = this.props;
    const { previewVisible, previewFile } = this.state;
    return (
      <>
        <Header
          left={
            vehicle && (
              <h1>
                {fileTags[tag]} для {vehicle.licensePlate}
              </h1>
            )
          }
        />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, dirty } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <SectionContent>
                    <FormField label="Файлы" required name="files">
                      {({ value, name }) => (
                        <Uploader
                          value={orderBy(value, 'createdTime', ['desc'])}
                          multiple
                          tag={tag}
                          onChange={files => setFieldValue(name, files)}
                        />
                      )}
                    </FormField>
                  </SectionContent>
                </StyledSection>
                <Modal
                  visible={previewVisible}
                  footer={null}
                  onCancel={this.handleClose}
                >
                  {isImage(previewFile.contentType) ? (
                    <img
                      alt={previewFile.fullName || 'Изображение'}
                      style={{ width: '100%' }}
                      src={previewFile.url || previewFile.thumbUrl}
                    />
                  ) : (
                    <div>
                      Файл недоступен для просмотра.&nbsp;
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a href={previewFile.url} target="_blank">
                        Скачать
                      </a>
                    </div>
                  )}
                </Modal>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(Files);
