// @flow
import React from 'react';
import { Input } from 'antd';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { SmallScaleMechanizationTool } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';

export default (
  props: CommonFormProps<$Shape<SmallScaleMechanizationTool>>
) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Наименование" fast required name="name">
                  {({ name, value }) => (
                    <Input
                      min={0}
                      max={100}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
