// @flow
import type { LicensePlateChangeCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (
  licensePlateChangeCost: LicensePlateChangeCost
) => ({
  ...licensePlateChangeCost,
  cost: convertFromRubToKop(licensePlateChangeCost.cost)
});

const convertDataFromServer = (
  licensePlateChangeCost: LicensePlateChangeCost
) => ({
  ...licensePlateChangeCost,
  cost: convertFromKopToRub(licensePlateChangeCost.cost)
});

const licensePlateChangeCost = new CommonApi<LicensePlateChangeCost>(
  'licensePlateChangeCost',
  'Стоимость замены гос. номеров',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...licensePlateChangeCost
};
