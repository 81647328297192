// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';
import Input from 'antd/lib/input';
import InputMask from 'react-input-mask';

/**
 * Маска ПТС
 */
export default (props: InputProps) => (
  <InputMask
    mask="***************"
    placeholder="_______________"
    formatChars={{
      '*': `[0-9]`,
    }}
    {...props}
  >
    {(inputProps) => <Input {...inputProps} />}
  </InputMask>
);
