// @flow
import moment from 'moment';
import find from 'lodash/find';

import type {
  Vehicle,
  VehicleType,
  VehicleGroup,
  UserAccess,
  BusinessDay,
  Order,
} from './../../lib/types';
import { canShowWithTrailer } from './../Vehicles/lib';
import {
  vehicleGroupEnum,
  accessTypeEnum,
  businessDayTypeEnum,
  orderObjectivesEnum,
  positionEnum,
  orderStatusEnum,
} from './../../lib/enum';
import type { Employee, WayPoint } from '../../lib/types';

const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];

type SelectionVehicles = {
  vehicles: Vehicle[],
  trailers: Vehicle[],
  selectedVehicle: ?$Shape<Vehicle>,
  selectedTrailer: ?$Shape<Vehicle>,
  withTrailer: boolean,
};

/**
 * Функция отдает ТС и прицепы для отображения подбора
 * @param {SelectionVehicles} data
 */
export const getSelectionVehicles = (data: SelectionVehicles) => {
  const {
    vehicles = [],
    trailers = [],
    selectedTrailer,
    selectedVehicle,
    withTrailer = false,
  } = data;

  let result: SelectionVehicles = {
    vehicles,
    trailers,
    selectedTrailer,
    selectedVehicle,
    withTrailer,
  };

  /**
   * Если выбран ТС, но его нет в списке доступных, то добавяем его в список
   */
  if (selectedVehicle && !find(vehicles, { id: selectedVehicle.id })) {
    result.vehicles = [selectedVehicle, ...vehicles];
  }

  /**
   * В списке прицепов могут быть прицепы, которые можно выбрать только в связке прицеп + ТС
   * для этого случая мы эти прицепы отображаем, но запрещаем их выбирать, пока не
   * выберут ТС, к которому они прикреплены
   *  */
  if (withTrailer) {
    result.trailers = trailers.map<Vehicle>((trailer: Vehicle) => ({
      ...trailer,
      disabled:
        parseInt(trailer.drivingVehicleId, 10) > 0 &&
        !!selectedVehicle &&
        trailer.drivingVehicleId !== selectedVehicle.id,
    }));

    if (selectedTrailer && !find(trailers, { id: selectedTrailer.id })) {
      result.trailers = [selectedTrailer, ...trailers];
    }
  }

  return result;
};

/**
 * Логика по отображению поля "С прицепом"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const needShowWithTrailer = (
  vehicleType: VehicleType,
  vehicleGroup: VehicleGroup
) => {
  if (vehicleType) {
    return canShowWithTrailer(vehicleType);
  }
  return (
    vehicleGroup !== vehicleGroupEnum.truckCranes &&
    vehicleGroup !== vehicleGroupEnum.carLifts
  );
};

/**
 * Отмена заявки-ГПМ
 * Если заявка не подтверждена "Главным инженером", то отменить может только доступ "Потверждение заявки на ГПМ"
 * Если заявка подтверждена "Главным механиком", то отменить может доступ "Обработка заявки"
 */
export const canCancelGpmOrder = (
  order: Order,
  userAccess: UserAccess[]
): boolean => {
  let accessList = [];

  if (order.status === orderStatusEnum.approvedByMainEngineer) {
    accessList = [...adminAccess, accessTypeEnum.handlingOrder];
  } else if (order.status === orderStatusEnum.approvingByMainEngineer) {
    accessList = [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.approvingGpmOrder,
    ];
  }
  return userAccess.some((access) => accessList.includes(access));
};

/**
 * Отмена обычной заявки.
 *
 * Отменить можно только неотмененную заявку
 * Отменить неподтвержденную заявку может только заявитель
 * Отменить подтвержденную заявку может только диспетчер
 */
export const canCancelOrder = (
  order: Order,
  userAccess: UserAccess[],
  employeeId: number
): boolean => {
  const canHandleAccess = [...adminAccess, accessTypeEnum.handlingOrder];
  if (order.status === orderStatusEnum.cancelled) return false;
  // Статусы заявок при которых нельзя отменять обычному заявителю
  const statuses = [
    orderStatusEnum.cancelled,
    orderStatusEnum.approved,
    orderStatusEnum.approvedByMainEngineer,
  ];
  // Может ли пользователь обрабатывать заявку
  const canHandleOrder = userAccess.some((access) =>
    canHandleAccess.includes(access)
  );
  // Если пользователь заявитель
  if (order.claimantId === employeeId && !statuses.includes(order.status)) {
    return true;
  }
  return canHandleOrder;
};

/**
 * Возможность копирования заявки
 *
 * Можно копировать любые заявки, любого заявителя
 */
export const canCopyOrder = (userAccess: UserAccess[]): boolean => {
  return userAccess.some((access) =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.handlingOrder,
      accessTypeEnum.creatingOrder,
    ].includes(access)
  );
};

/**
 * Проверяет является ли заявка выходным днем
 */
export const isOrderWeekend = (
  waypoints: WayPoint[],
  calendar: BusinessDay[]
) => {
  const isWeekend = (date: string | ?Date) => {
    return calendar.some(
      (day) =>
        moment
          .utc(date)
          .startOf('day')
          .isSame(moment.utc(day.date).startOf('day')) &&
        day.dayType === businessDayTypeEnum.dayOff
    );
  };
  return !!waypoints.filter((waypoint: WayPoint) => {
    const { arrivedDateTime, departureDateTime } = waypoint;
    return isWeekend(arrivedDateTime) || isWeekend(departureDateTime);
  }).length;
};

/**
 * Показывать поле Требуется перевозка груза
 * @param order Заявка
 */
export const showHasCargosField = (order: Order): boolean => {
  if (!order.objective) return false;
  if (order.objective === orderObjectivesEnum.shipping) {
    return false;
  }
  return true;
};

/**
 * Валидация люлечников, стропальщиков
 * @param employee Сотрудник
 * @param type Тип позиция
 * @returns {*}
 */
export const validateEmployee = (
  employee: Employee,
  type:
    | typeof positionEnum.rigger
    | typeof positionEnum.lulechnik
    | typeof positionEnum.safetyTechnician
    | typeof positionEnum.safetyCarLift
) => {
  const { documents } = employee;
  const docs = documents.filter((doc) => doc.documentType === type);
  return docs.some((doc) => {
    return moment(doc.nextProtocolDate).isBefore();
  });
};
