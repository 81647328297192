// @flow

import React, { Component } from 'react';

import type { WayPoint } from './../../lib/types';
import { sortWaypoints } from './lib';
import { formatDateTimeToString } from './../../lib/helpers';
import ListTable from '../ui/ListTable';

type Props = {
  waypoints: Array<WayPoint>
};

/**
 * Маршрут в виде списка точек
 */
export default class WaypointsViewer extends Component<Props> {
  columns = [
    {
      title: 'Точка отправления',
      key: 'name'
    },
    {
      title: 'Время прибытия',
      key: 'arrivedDateTime',
      render: (arrivedDateTime: string) =>
        formatDateTimeToString(arrivedDateTime)
    },
    {
      title: 'Время отправления',
      key: 'departureDateTime',
      render: (departureDateTime: string) =>
        formatDateTimeToString(departureDateTime)
    },
    {
      title: 'Примечание',
      key: 'notation',
      render: (notation: string) => notation || '-'
    }
  ];

  render() {
    const { waypoints } = this.props;
    return <ListTable data={sortWaypoints(waypoints)} columns={this.columns} />;
  }
}
