// @flow
import queryString from 'query-string';
import type {
  ExpertiseServiceGpm,
  ExecutionBudgetGroupedList
} from './../types';

import {
  fetchRequest,
  downloadRequestWithToken,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { ExpertisesServicesGpmFilterParams } from '../../containers/services/expertisesServicesGpm/Filter';

export const convertDataFromServer = (
  expertiseServiceGpm: ExpertiseServiceGpm
) => ({
  ...expertiseServiceGpm,
  paymentAmount: convertFromKopToRub(expertiseServiceGpm.paymentAmount)
});

export const convertDataToServer = (
  expertiseServiceGpm: ExpertiseServiceGpm
) => ({
  ...expertiseServiceGpm,
  paymentAmount: convertFromRubToKop(expertiseServiceGpm.paymentAmount)
});

export const addExpertiseServiceGpm = async (
  expertiseServiceGpm: ExpertiseServiceGpm
): Promise<ExpertiseServiceGpm> => {
  const added = await fetchRequest.post(
    '/expertiseServiceGpm',
    convertDataToServer(expertiseServiceGpm)
  );
  if (added) return added;
  throw new Error('Не удалось создать экспертизу/тех. обслуживание ГПМ');
};

export const updateExpertiseServiceGpm = async (
  expertiseServiceGpm: ExpertiseServiceGpm
): Promise<ExpertiseServiceGpm> => {
  const updated = await fetchRequest.put(
    '/expertiseServiceGpm',
    convertDataToServer(expertiseServiceGpm)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить экспертизу/тех. обслуживание ГПМ');
};

export const deleteExpertiseServiceGpm = async (
  id: number
): Promise<ExpertiseServiceGpm> => {
  const deleted = await fetchRequest.delete(`/expertiseServiceGpm/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить экспертизу/тех. обслуживание ГПМ');
};

export const fetchExpertiseServiceGpm = async (
  id: number,
  params: any = {}
): Promise<ExpertiseServiceGpm> => {
  const expertiseServiceGpm = await fetchRequest.get(
    `/expertiseServiceGpm/${id}`,
    params
  );
  if (expertiseServiceGpm) return convertDataFromServer(expertiseServiceGpm);
  throw new Error('Не удалось получить экспертизу/тех. обслуживание ГПМ');
};

export const fetchVehicleExpertiseServiceGpmList = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<ExpertiseServiceGpm>> => {
  const expertiseServiceGpmes = await fetchRequest.get(
    `/expertiseServiceGpm/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (expertiseServiceGpmes) {
    return {
      ...expertiseServiceGpmes,
      data: expertiseServiceGpmes.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список экспертиз/тех. обслуживаний ГПМ');
};

export const fetchExpertiseServiceGpmList = async (
  params: FetchListParams<ExpertiseServiceGpm> = initialFetchListParams
): Promise<ListResponse<ExpertiseServiceGpm>> => {
  const expertiseServiceGpm = await fetchRequest.get('/expertiseServiceGpm', {
    ...initialFetchListParams,
    ...params
  });
  if (expertiseServiceGpm) {
    return {
      ...expertiseServiceGpm,
      data: expertiseServiceGpm.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список экспертиз/тех. обслуживаний ГПМ');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<ExpertiseServiceGpm>> => {
  const expertiseServiceGpm = await fetchRequest.put(
    `/expertiseServiceGpm/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (expertiseServiceGpm) {
    return {
      ...expertiseServiceGpm,
      data: expertiseServiceGpm.data.map(convertDataFromServer)
    };
  }
  throw new Error(
    'Не удалось закрепить акт за экспертиз/тех. обслуживаний ГПМ'
  );
};

export const printReport = async (
  params: ExpertisesServicesGpmFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/expertiseServiceGpm/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/expertiseServiceGpm/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/expertiseServiceGpm/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export default {
  groupedList,
  getTotalSum,
  printReport,
  updateAct,
  addExpertiseServiceGpm,
  updateExpertiseServiceGpm,
  deleteExpertiseServiceGpm,
  fetchExpertiseServiceGpm,
  fetchVehicleExpertiseServiceGpmList,
  fetchExpertiseServiceGpmList
};
