// @flow
import Button from 'antd/lib/button/button';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Selects } from '../../../../components';
import { RangePicker } from '../../../../components/ui';
import Field from '../../../../components/card/Field';
import { Filter } from '../../../../components/hoc';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';

import Spoiler from '../../../../components/ui/Spoiler';
import { formatDateTimeToISOString } from '../../../../lib/helpers';
import type { ReportFuelConsumptionParams } from '../../../../lib/types/reportFuelConsumption';

const { BrandsSelect, VehicleTypeSelect, FuelTypeSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  filter: ReportFuelConsumptionParams,
  cleanFilter: Function,
  applyFilter: Function,
};

const ReportFuelConsumptionFilter = ({
  filter,
  applyFilter,
  cleanFilter,
}: Props) => (
  <Spoiler defaultExpanded label="Параметры">
    <SpoilerContent>
      <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
        <Filter
          initialValues={filter}
          applyFilter={applyFilter}
          cleanFilter={cleanFilter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter,
          }: FilterChildrenParams<ReportFuelConsumptionParams>) => (
            <>
              <GridItem>
                <Field label="Подразделение">
                  <OrgUnitSelect
                    allowClear
                    value={values.orgUnitId}
                    onChange={(id) => changeValue('orgUnitId', id)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Марка ТС">
                  <BrandsSelect
                    allowClear
                    value={values.vehicleBrandId}
                    mode="multiple"
                    onChange={(values) => changeValue('vehicleBrandId', values)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип ТС">
                  <VehicleTypeSelect
                    value={values.vehicleType}
                    selectableGroup
                    onChange={(types) => changeValue('vehicleType', types)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Вид топлива">
                  <FuelTypeSelect
                    allowClear
                    mode="multiple"
                    value={values.fuelType}
                    onChange={(fuelTypes) => changeValue('fuelType', fuelTypes)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Отчетный период">
                  <RangePicker
                    startDate={values.startDate}
                    endDate={values.endDate}
                    onChange={changeValue}
                  />
                </Field>
              </GridItem>
              <Footer fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="secondary" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Footer>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default ReportFuelConsumptionFilter;
