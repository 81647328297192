import { NotificationType } from '../types';
import { notificationType } from '../enum';
import { fetchRequest, initialFetchListParams } from './index';

export const notification = async (type: NotificationType): Promise<any> => {
    const notification = await fetchRequest.get(`/notify/notification?notificationType=${type}`);
    if (notification) return notification;
    throw new Error(`Не удалось получить уведомления ${notificationType[type]}`);
};

export default { notification };