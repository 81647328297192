// @flow

export const fuelGroups = {
  petrol: 'Бензин',
  diesel: 'Дизельное топливо',
  gas: 'Газообразное топливо',
  kerosene: 'Керосин'
};

export const fuelGroupEnum = {
  petrol: 'petrol',
  diesel: 'diesel',
  gas: 'gas',
  kerosene: 'kerosene'
};
