// @flow

import type { Dispatch } from 'redux';

import type { Maintenance } from './../lib/types';
import { maintenanceApi } from '../lib/api';
import type { Action } from './redux';

export const CLEAR_MAINTENANCE = 'vehicles/maintenance/clear';
export const SET_MAINTENANCE = 'vehicles/maintenance/set';

export type MaintenanceState = ?Maintenance;

const initialState: MaintenanceState = null;

const reducer = (
  state: MaintenanceState = initialState,
  { type, payload }: Action
): MaintenanceState => {
  switch (type) {
    case SET_MAINTENANCE:
      return payload;
    case CLEAR_MAINTENANCE:
      return initialState;
    default:
      return state;
  }
};

export const clear = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE
  });

export const setMaintenance: Function = (maintenance: Maintenance) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MAINTENANCE,
    payload: { ...maintenance }
  });
};

export const addMaintenance: Function = (
  maintenance: Maintenance
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const addedMaintenance = await maintenanceApi.addMaintenance(maintenance);
  dispatch({
    type: SET_MAINTENANCE,
    payload: addedMaintenance
  });
  return addedMaintenance;
};

export const updateMaintenance: Function = (
  maintenance: Maintenance
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const updatedMaintenance = await maintenanceApi.updateMaintenance(
    maintenance
  );
  dispatch({
    type: SET_MAINTENANCE,
    payload: updatedMaintenance
  });
};

export const deleteMaintenance: Function = (id: number): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => await maintenanceApi.deleteMaintenance(id);

export const fetchMaintenance: Function = (id: number): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const maintenance = await maintenanceApi.fetchMaintenance(id);
  dispatch({
    type: SET_MAINTENANCE,
    payload: maintenance
  });
};

export default reducer;
