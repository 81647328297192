//@flow
import moment from 'moment';
import React, { useCallback } from 'react';

import { FilterItem, OrgUnitSelect } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import Field from '../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../components/hoc/common/handbook/CommonFilter';
import { ApproveYesNoSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import type { VehicleGroupAndType } from '../../../components/selects/VehicleTypeSelect';
import VehicleTypeSelect from '../../../components/selects/VehicleTypeSelect';
import { vehicleTypeEnum } from '../../../lib/enum';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import type { ApproveType } from '../../../lib/types';

export type InspectionGtnCostFilterParams = {
  nodeId?: string,
  vehicleType?: vehicleTypeEnum,
  startDate?: string,
  endDate?: string,
  status?: ApproveType
};

export const InspectionGtnCostFilter = (
  props: FilterProps<InspectionGtnCostFilterParams>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<InspectionGtnCostFilterParams>>) =>
      CommonFilter<InspectionGtnCostFilterParams>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter additionalFilter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                size="small"
                value={values.nodeId}
                onlyBranches
                onChange={value => changeValue('nodeId', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleTypeSelect
                size="small"
                resultAsObject
                onChange={({ type }: VehicleGroupAndType) => {
                  changeValue('vehicleType', type);
                }}
                value={values.vehicleType}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ApproveYesNoSelect
                size="small"
                value={values.status}
                onChange={value => changeValue('status', value)}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default InspectionGtnCostFilter;
