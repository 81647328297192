import styled from 'styled-components';

const ButtonsRow = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default ButtonsRow;
