import {
    BudgetDetailForm,
    BudgetDetailList,
} from '../../containers/budget/details';
import { accessTypeEnum } from '../../lib/enum';

// уточнения в бюджет
export default [
    {
        component: BudgetDetailList,
        path: '/budget/details',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetDetailForm,
        path: '/budget/details/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetDetailForm,
        path: '/budget/details/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetDetailForm,
        path: '/budget/details/:id/:orgUnitId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetDetailForm,
        path: '/budget/details/:id/:orgUnitId/:type',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
];