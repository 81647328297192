//@flow
import React from 'react';
import { Link } from '@reach/router';

import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import { entityStatus, entityStatusEnum } from '../../../../lib/enum';
import {
  formatDateRangeString,
  formatDateTimeToString,
  vehiclePlanInfoString,
  contractVehiclePlanInfoString
} from '../../../../lib/helpers';
import { SectionContent } from '../../../../components/hoc/common/components/elements';
import type { BranchBudgetSummary } from '../../../../lib/types';

type Props = {
  branchBudgetSummary?: BranchBudgetSummary,
  proposal: boolean
};

export default (props: Props) => {
  return (
    <>
      <SectionContent>
        <Grid gutter="16px">
          {props.proposal && (
            <>
              <GridItem fullWidth>
                {!!props.branchBudgetSummary?.proposalStatus && (
                  <Field label="Статус">
                    {entityStatus[props.branchBudgetSummary?.proposalStatus]}
                  </Field>
                )}
              </GridItem>
              {!!props.branchBudgetSummary?.declineReason &&
                props.branchBudgetSummary?.status !==
                  entityStatusEnum.approved && (
                  <GridItem fullWidth>
                    <Field label="Причина отклонения" name="declineReason">
                      {props.branchBudgetSummary?.declineReason}
                    </Field>
                  </GridItem>
                )}
            </>
          )}
          <GridItem>
            <Field label="Наименование бюджета СТС" name="selfVehiclePlanId">
              {!!props?.branchBudgetSummary?.selfVehiclePlan && (
                <Link
                  to={`/budget/vehicle/${props.branchBudgetSummary.selfVehiclePlanId}/card`}
                >
                  {vehiclePlanInfoString(
                    props.branchBudgetSummary.selfVehiclePlan
                  )}
                </Link>
              )}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Наименование бюджета НТС">
              {!!props?.branchBudgetSummary?.contractVehiclePlan && (
                <Link
                  to={`/budget/contract-vehicle/fixed/${props.branchBudgetSummary.contractVehiclePlanId}/card`}
                >
                  {contractVehiclePlanInfoString(
                    props.branchBudgetSummary.contractVehiclePlan
                  )}
                </Link>
              )}
            </Field>
          </GridItem>
          <GridItem>
            {props?.branchBudgetSummary?.selfVehiclePlan && (
              <Field label="Период формирования">
                {`${formatDateRangeString(
                  props.branchBudgetSummary.selfVehiclePlan.startDate,
                  props.branchBudgetSummary.selfVehiclePlan.endDate,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.contractVehiclePlan && (
              <Field label="Период формирования">
                {`${formatDateRangeString(
                  props.branchBudgetSummary.contractVehiclePlan.startDate,
                  props.branchBudgetSummary.contractVehiclePlan.endDate,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.selfVehiclePlan && (
              <Field label="Дата формирования">
                {`${formatDateTimeToString(
                  props.branchBudgetSummary.selfVehiclePlan.date,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.contractVehiclePlan && (
              <Field label="Дата формирования">
                {`${formatDateTimeToString(
                  props.branchBudgetSummary.contractVehiclePlan.date,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
        </Grid>
      </SectionContent>
    </>
  );
};
