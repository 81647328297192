import styled from 'styled-components';
import Form from 'antd/lib/form';
import { Icon as UiIcon } from '../ui';

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
  & .ant-select {
    width: 100% !important;
  }
`;
export const LocationWrapper = styled.div`
  display: flex;
`;
export const WaypointPickerWrapper = styled.div`
  margin-top: 16px;
`;
export const MapLabel = styled.p`
  color: #2770ff;
  font-size: 12px;
  text-decoration: underline;
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const RemoveIcon = styled(UiIcon).attrs(props => ({
  type: 'x',
  size: 12
}))`
  cursor: pointer;
`;
export const OperationColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;
