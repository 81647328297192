// @flow
import CommonApi from '../commonApi';
import type { TripDrivingQuality } from '../types';
import { downloadRequestWithToken } from './index';

const basedUrl = 'tripDrivingQuality';
const tripDrivingQuality = new CommonApi<TripDrivingQuality>(
  basedUrl,
  'детализация по качеству вождения'
);

export const print = async (tripId: number) => {
  const print = await downloadRequestWithToken(`/${basedUrl}/${tripId}/print`);
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...tripDrivingQuality,
  print
};
