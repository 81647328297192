// @flow
import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import AntInputNumber from 'antd/lib/input-number';
import { DatePicker as Datepicker } from '../../../components/ant/DatePicker';

import { GridItem, Section } from '../../../components/layout';

export const InspectionGridItem = styled(GridItem)`
  & > .field:last-child > .field__value {
    margin-bottom: 0;
  }
`;
export const InspectionTitle = styled.p`
  color: #838d96;
  font-weight: 500;
  margin-bottom: 16px;
`;
export const InputNumber = styled(AntInputNumber)`
  width: 100%;
`;
export const DatePicker = styled(Datepicker)`
  width: 300px;
`;
export const SectionContent = styled.div`
  padding: 16px;
`;

export const CarInfo = styled.div`
  padding: 16px;
  background: #f0f4f8;
  & .field__value {
    margin-bottom: 0;
  }
`;
export const WaypointsWrapper = styled(SectionContent)`
  padding-top: 0;
`;

export const FormFooter = styled(Section)`
  padding: 16px;
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const FormTripMapWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  p {
    padding-left: 10px;
  }
`;

export const WarningIcon = styled(WarningOutlined)`
  cursor: help;
  color: #faad14;
  margin-left: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
