// @flow
import React, { Component, type Node } from 'react';
import styled, { css } from 'styled-components';

import Icon from './Icon';
import Divider from './../layout/Divider';

const Label = styled.div`
  font-weight: 500;
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LabelLeft = styled.div`
  display: flex;
  align-items: center;
`;
const LabelRight = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  ${({ placement }) =>
    placement === 'right'
      ? css`
          margin-left: 10px;
        `
      : css`
          margin-right: 10px;
        `}
`;

const Content = styled.div`
  display: none;
  ${props =>
    props.visible &&
    css`
      display: block;
    `};
`;

type Props = {
  label: string,
  onChange?: (value: boolean) => void,
  suffix?: Node,
  showSuffixOnExpand?: boolean,
  showDivider?: boolean,
  children: Node,
  defaultExpanded?: boolean,
  labelStyles?: $Shape<CSSStyleDeclaration>,
  iconPlacement: 'left' | 'right'
};

type State = {
  expanded: boolean
};
/**
 * Компонент споилера
 */
export default class Spoiler extends Component<Props, State> {
  state = {
    expanded: !!this.props.defaultExpanded
  };

  static defaultProps = {
    iconPlacement: 'right',
    showDivider: true
  };

  toggleExpanded = () => {
    const { onChange } = this.props;
    this.setState(prev => {
      if (onChange) onChange(!prev.expanded);
      return {
        expanded: !prev.expanded
      };
    });
  };

  render() {
    const {
      label,
      children,
      suffix,
      showSuffixOnExpand,
      labelStyles,
      iconPlacement,
      showDivider
    } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        <Label style={labelStyles} onClick={this.toggleExpanded}>
          <LabelLeft>
            {iconPlacement === 'left' && (
              <StyledIcon
                placement="left"
                type={expanded ? 'arrow-up' : 'arrow-down'}
              />
            )}
            {label}
            {iconPlacement === 'right' && (
              <StyledIcon
                placement="right"
                type={expanded ? 'arrow-up' : 'arrow-down'}
              />
            )}
          </LabelLeft>
          {showSuffixOnExpand ? (
            expanded ? (
              <LabelRight onClick={e => e.stopPropagation()}>
                {suffix}
              </LabelRight>
            ) : null
          ) : (
            <LabelRight onClick={e => e.stopPropagation()}>{suffix}</LabelRight>
          )}
        </Label>
        {showDivider && <Divider type="solid" color="light" />}
        <Content visible={expanded}>{children}</Content>
      </div>
    );
  }
}
