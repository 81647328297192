import {
    MaintenanceCostAllocationTRCard,
    MaintenanceCostAllocationTRForm,
    MaintenanceCostAllocationTRList,
} from '../../containers/MaintenanceCostAllocationTR';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MaintenanceCostAllocationTRList,
        path: '/admin/maintenance-cost-allocation-tr',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MaintenanceCostAllocationTRCard,
        path: '/admin/maintenance-cost-allocation-tr/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MaintenanceCostAllocationTRForm,
        path: '/admin/maintenance-cost-allocation-tr/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MaintenanceCostAllocationTRForm,
        path: '/admin/maintenance-cost-allocation-tr/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];