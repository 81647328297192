// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';

import MaskInput from '../MaskInput';
import { cyrLetter } from '../../../lib/validation';

export const formatSTSMask = (value: string) => {
  const startWithNumber = new RegExp(`^\\d`),
    lettersRegexp = new RegExp(`^\\d{2}[a-zA-Z${cyrLetter}]`),
    lettersNumbersRegexp = new RegExp(`^[a-zA-Z${cyrLetter}]{2}\\d{2}`),
    numbersRegexp = new RegExp(`^\\d{2}\\d`);
  if (startWithNumber.test(value)) {
    if (numbersRegexp.test(value)) {
      return '99 99 999999';
    } else if (lettersRegexp.test(value)) {
      return '99 aa 999999';
    }
    return '99 ** ******';
  } else if (lettersNumbersRegexp.test(value)) {
    return 'aa 999999';
  }
  return '** ** ******';
};

/**
 * Маска СТС
 */
export default (props: InputProps) => (
  <MaskInput
    mask="** ** ******"
    placeholder="11 11 111111"
    formatChars={{
      a: `[a-zA-Z${cyrLetter}]`,
      '*': `[0-9a-zA-Z${cyrLetter}]`
    }}
    formatMask={formatSTSMask}
    convertOnSave={(value: string) => value.replace(/\s+/g, '')}
    upperCase
    {...props}
  />
);
