// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../../components';
import Field from './../../../../components/card/Field';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import { formatDateTimeToISOString } from './../../../../lib/helpers';

import type { FuelType, MeasureTypeFuelPrice, RateType } from './../../../../lib/types';

const { FuelTypeSelect, MeasureTypeFuelPriceSelect, RateTypeSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type FuelPriceParamsFilter = {
  startDate?: string,
  endDate?: string,
  measureType?: MeasureTypeFuelPrice,
  fuelType?: FuelType,
  nodeId?: number,
  rateType?: RateType
};

type Props = {
  filter: FuelPriceParamsFilter,
  cleanFilter: Function,
  applyFilter: Function
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<FuelPriceParamsFilter>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onChange={value => changeValue('nodeId', value)}
                  value={values['nodeId']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <FuelTypeSelect
                  size="small"
                  onChange={value => changeValue('fuelType', value)}
                  value={values.fuelType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RateTypeSelect
                  size="small"
                  onChange={value => changeValue('rateType', value)}
                  value={values.rateType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <MeasureTypeFuelPriceSelect
                  size="small"
                  onChange={value => changeValue('measureType', value)}
                  value={values['measureType']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  placeholder="Дата начала"
                  value={
                    values.startDate ? moment(values.startDate) : undefined
                  }
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(value, dateString),
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  placeholder="Дата окончания"
                  value={
                    values.startDate ? moment(values.startDate) : undefined
                  }
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(value, dateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
