// @flow
import type { MaintenanceCostAllocationTR } from './../types';
import CommonApi from './../commonApi';

const maintenanceCostAllocationTR = new CommonApi<MaintenanceCostAllocationTR>(
  'maintenanceCostAllocationTR',
  'Распределение затрат на ТР'
);

export default {
  ...maintenanceCostAllocationTR
};
