// @flow
import React from 'react';
import { smallScaleMechanizationToolApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';

import { navigate } from '../../lib/helpers';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/small-scale-mechanization/';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Наименование',
    dataIndex: 'name'
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default () => {
  return (
    <Page>
      <CommonListPage
        crud={smallScaleMechanizationToolApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Средства малой механизации',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: true
        }}
      />
    </Page>
  );
};
