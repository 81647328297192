// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { type FormikProps } from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import styled from 'styled-components';

import type { Role } from './../../lib/types';
import { fetchRole, cleanRole, updateRole, addRole } from '../../ducks/role';
import Grid, { GridItem } from './../../components/layout/Grid';
import { Form, Selects } from './../../components';
import type { AppState } from '../../ducks/redux';
import { Panel, Section } from './../../components/layout';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import { goBack } from './../../lib/helpers';
import { notificationLoading } from './../../components/Notifications';
import CancelButton from '../../components/CancelButton';

const { UserAccessTypesSelect } = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  role: Role,
  updateRole: Function,
  addRole: Function,
  roleId: number,
  fetchRole: Function,
  cleanRole: () => void
};

export class RoleForm extends Component<Props> {
  async componentDidMount() {
    const { roleId } = this.props;
    await this.props.cleanRole();
    try {
      if (roleId) {
        await this.props.fetchRole(roleId);
      }
    } catch (error) {
      notification.error({
        message: 'Не найдено'
      });
      goBack('/admin/roles');
    }
  }

  render() {
    const { role, roleId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/roles">Роли</Crumb>
              <Crumb>
                {roleId > 0 ? `Роль ${role ? role.name : ''}` : 'Новая роль'}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{roleId ? `Роль ${role ? role.name : ''}` : 'Новая роль'}</h1>
        </StyledPanel>
        <Form
          initialValues={role}
          enableReinitialize
          validate={(values: Role) => {
            let errors = {};
            if (!values.access) {
              errors.access = 'Обязательно для заполнения';
            }
            if (!values.name) {
              errors.name = 'Обязательно для заполнения';
            }
            return errors;
          }}
          onSubmit={async (values: Role) => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await this.props.updateRole(values);
                notification.success({
                  message: 'Успешно сохранено',
                  description: 'Изменения успешно сохранены'
                });
              } else {
                await this.props.addRole(values);
                notification.success({
                  message: 'Успешно сохранено',
                  description: 'Изменения успешно сохранены'
                });
              }

              goBack(`/admin/roles/${this.props.roleId}`);
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
        >
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              setFieldValue,
              handleBlur,
              dirty,
              isSubmitting
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <Panel>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Наименование"
                          hasFeedback
                          required
                          fast
                          name="name"
                        >
                          {fieldProps => <Input {...fieldProps} />}
                        </FormField>
                      </GridItem>
                      <GridItem fullWidth>
                        <FormField
                          fast
                          name="access"
                          label="Доступы"
                          hasFeedback
                          required
                        >
                          {props => {
                            const { value, name, ...otherProps } = props;
                            return (
                              <UserAccessTypesSelect
                                {...otherProps}
                                onChange={(
                                  value: string,
                                  selectedOptions: Object[]
                                ) => setFieldValue(name, value)}
                                onBlur={() =>
                                  handleBlur({
                                    target: { name }
                                  })
                                }
                                value={value}
                                mode="multiple"
                                data-cy="userAccessTypesSelect"
                              />
                            );
                          }}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </Panel>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    data-cy="save"
                  >
                    Сохранить
                  </Button>
                  <CancelButton
                    dirty={dirty}
                    onClick={() => goBack('/admin/roles')}
                  >
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    role: state.role,
    roleId: parseInt(ownProps.roleId, 10)
  }),
  {
    updateRole,
    addRole,
    fetchRole,
    cleanRole
  }
)(RoleForm);
