// @flow
import moment from 'moment';
import React from 'react';
import { OrgUnitSelect } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import { FormField } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';

import CommonForm, { type CommonFormProps } from '../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { ApproveYesNoSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import type { MaintenanceCostAllocation } from '../../../lib/types';

export default (props: CommonFormProps<$Shape<MaintenanceCostAllocation>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter='16px'>
              <GridItem>
                <FormField label="Подразделение" fast required name="orgUnitId">
                  {({ name, value }) => (
                    <OrgUnitSelect
                      value={value}
                      onlyBranches
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Доля затрат на техническое обслуживание подрядом, %"
                  fast
                  required
                  name="costContract"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      max={100}
                      onChange={value => {
                        setFieldValue(name, value);
                        setFieldValue('cost', 100 - value || 0);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Доля затрат на техническое обслуживание хоз.способом, %"
                  fast
                  required
                  name="cost"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      max={100}
                      onChange={value => {
                        setFieldValue('costContract', 100 - value || 0);
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата начала" name="startDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата окончания" name="endDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.startDate}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Статус" fast required name="status">
                  {({ name, value }) => (
                    <ApproveYesNoSelect
                      name={name}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
