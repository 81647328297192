import React, { useState } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import CancelButton from '../../../components/CancelButton';
import Form from '../../../components/Form';
import type { FormFieldType } from '../../../components/Form';
import { InputNumber } from '../../../components/inputs';
import { validateWaypoint } from '../../../components/Waypoints/lib';
import type { Order } from '../../../lib/types';

import { Selects, WaypointsForm } from './../../../components';
import { Option, Select } from './../../../components/ant/Select';
import { Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import {
  orderObjectives,
  waypointTypeEnum,
  orderTypeEnum,
  positionEnum,
  medicOwnerEnum,
} from '../../../lib/enum';
import type { Direction } from '../../../lib/gis';
import { validateWaypointsOrder } from '../../../lib/helpers';
import type { Trip, Vehicle, WayPoint } from '../../../lib/types';

const {
  EmployeeSelect,
  TripFreeVehiclesSelect,
  DriverSelect,
  MedicOwnerTypeSelect,
} = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 16px;
`;

type FormProps = {
  tripGeometry: any,
  onCalculateGeometry: (values: any) => Promise<?Direction>,
  onVehicleChange: (
    vehicle: Vehicle,
    waypoints: Array<WayPoint>
  ) => Promise<{
    driverId?: ?number,
    expectedWaypoint: Array<WayPoint>,
  }>,
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  employeeBranchOrgUnitId: number,
  onCancel: Function,
  orders: Order[],
  onSubmit: Function,
  isEdit?: boolean,
  handleBlur: Function,
  touched: any,
};

const InnerForm = ({
  values,
  handleFocus,
  onVehicleChange,
  freeVehicles,
  freeTrailers,
  tripGeometry,
  onCalculateGeometry,
  employeeBranchOrgUnitId,
  onCancel,
  data,
  onSubmit,
  touched,
  handleBlur,
  isEdit = false,
}: FormProps) => {
  const [medicType, setMedicType] = useState(
    values?.startMedicFullName ? medicOwnerEnum.hired : medicOwnerEnum.own
  );
  const waypointsType = values?.isBusinessTrip ? 'business' : 'standart';
  /**
   * Ограничение в количестве часов,
   * на которые мы можем задать маршрут
   */
  const getOffsetHours = (order: Order) => {
    if (order.isBusinessTrip) {
      return -1 * moment().diff(moment().add(-1, 'year'), 'hours');
    }
    if (order.type === orderTypeEnum.emergency) {
      return -48;
    } else {
      return 0;
    }
  };

  return (
    <Form
      initialValues={data}
      onSubmit={onSubmit}
      validate={(values: Trip) => {
        let errors = {};
        // if (!values.odometerAtStart) {
        //   errors.odometerAtStart = 'Обязательно для заполнения';
        // } else if (
        //   values.odometerAtStart < (values.vehicle.waybillKilometrage ?? 0)
        // ) {
        //   // $FlowFixMe Ругается на строку, хочет number
        //   errors.odometerAtStart = `Пробег при выезде не может быть меньше фактического пробега (текущее значение ${values.vehicle.waybillKilometrage} км.)`;
        // }
        return errors;
      }}
    >
      {(
        FormField: FormFieldType,
        {
          setTouched,
          setFieldValue,
          handleSubmit,
          values,
          dirty,
          isSubmitting,
          errors,
        }: FormikProps
      ) => {
        const arrayWaypointsLength = values?.route?.waypoints.length;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ value }) => {
                        return (
                          <TripFreeVehiclesSelect
                            value={value}
                            filter={{
                              at:
                                values?.route?.waypoints[0]?.arrivedDateTime ??
                                undefined,
                              to:
                                values?.route?.waypoints[
                                  arrayWaypointsLength - 1
                                ]?.departureDateTime ?? undefined,
                            }}
                            disabled={!arrayWaypointsLength && !isEdit}
                            onChange={(value: any, props: any) => {
                              setFieldValue('vehicleId', value);
                              setFieldValue('vehicle', props?.vehicle);
                            }}
                          />
                        );
                      }}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Цель поездки"
                      hasFeedback
                      required
                      name="objective"
                    >
                      {({ value }) => (
                        <Select
                          value={value}
                          onChange={(value: string) =>
                            setFieldValue('objective', value)
                          }
                        >
                          {Object.keys(orderObjectives).map((key) => (
                            <Option key={key} value={key}>
                              {orderObjectives[key]}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Количество пассажиров"
                      required
                      hasFeedback={false}
                      name="workersCount"
                    >
                      {({ value }) => (
                        <InputNumber
                          fullWidth
                          value={value}
                          onChange={(value: number) =>
                            setFieldValue('workersCount', value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="В распоряжение (ФИО)"
                      hasFeedback
                      required
                      name="employeeId"
                    >
                      {({ value }) => (
                        <EmployeeSelect
                          onChange={(value: string) =>
                            setFieldValue('employeeId', value)
                          }
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
            {isEdit && (
              <Section>
                <Content>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Водитель"
                        required
                        hasFeedback={false}
                        name="driverId"
                      >
                        {({ name, value }) => (
                          <DriverSelect
                            value={value}
                            onChange={(value: any, props: any) => {
                              setFieldValue('driverId', value);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="startMechanicId"
                        label="Контролер технического обслуживания при выезде"
                        hasFeedback
                      >
                        {({ name, value }) => (
                          <EmployeeSelect
                            onChange={(value: number) =>
                              setFieldValue(
                                'startMechanicId',
                                parseInt(value, 10)
                              )
                            }
                            filter={{
                              positions: [positionEnum.mechanic],
                            }}
                            onFocus={handleFocus}
                            value={value}
                            onBlur={() =>
                              handleBlur({
                                target: { name: 'startMechanicId' },
                              })
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Тип мед. работника">
                        {() => (
                          <MedicOwnerTypeSelect
                            onChange={(type) => setMedicType(type)}
                            value={medicType}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      {medicType === medicOwnerEnum.own ? (
                        <FormField
                          label="Мед. работник при выезде"
                          name="startMedicId"
                          hasFeedback
                          validateStatus={
                            touched.startMedicId &&
                            errors.startMedicId &&
                            'error'
                          }
                          help={errors.startMedicId}
                        >
                          {({ name, value }) => (
                            <EmployeeSelect
                              name="startMedicId"
                              filter={{
                                positions: [positionEnum.medic],
                              }}
                              onChange={(value: number) => {
                                setFieldValue(
                                  'startMedicId',
                                  value ? parseInt(value, 10) : undefined
                                );
                                setFieldValue('startMedicFullName', null);
                              }}
                              onFocus={handleFocus}
                              value={value}
                              onBlur={() =>
                                handleBlur({ target: { name: 'startMedicId' } })
                              }
                            />
                          )}
                        </FormField>
                      ) : (
                        <FormField
                          name="startMedicFullName"
                          label="Мед. работник при выезде"
                        >
                          {({ name, value }) => (
                            <Input
                              value={value}
                              onChange={(e) => {
                                setFieldValue(
                                  'startMedicFullName',
                                  e.target.value
                                );
                                setFieldValue('startMedicId', null);
                              }}
                            />
                          )}
                        </FormField>
                      )}
                    </GridItem>
                  </Grid>
                </Content>
              </Section>
            )}
            {!isEdit && (
              <Section>
                <SectionTitle divider>Задание</SectionTitle>
                <Content>
                  <FormField
                    required
                    hasFeedback={false}
                    name="route.waypoints"
                    // выключаем подсветку ошибки для поля, если есть точки
                    validateStatus={(value: WayPoint[] = [], error: string) =>
                      !!value.length ? null : error && 'error'
                    }
                    renderHelp={(help: string) => (
                      <p style={{ color: 'red', fontSize: 12 }}>{help}</p>
                    )}
                    validate={(waypoints: WayPoint[]) => {
                      const errors = waypoints
                        .map((waypoint) => validateWaypoint(waypoint))
                        .filter((errors) => !isEmpty(errors));
                      const waypointsOrderErrors =
                        validateWaypointsOrder(waypoints);
                      if (waypointsOrderErrors) return waypointsOrderErrors;
                      if (!waypoints.length) {
                        return 'Задание обязательно для заполнения';
                      } else if (errors.length) {
                        return 'Обнаружены ошибки в маршрутном задании';
                      }
                    }}
                  >
                    {({ name, value }) => (
                      <WaypointsForm
                        orderType={values.type}
                        waypoints={value}
                        hasErrors={!!errors[name]}
                        type={waypointsType}
                        editTypes={[waypointTypeEnum.transit]}
                        onChange={(waypoints: WayPoint[]) => {
                          setFieldValue(name, waypoints);
                          setTouched([name]);
                        }}
                        offsetHours={getOffsetHours(values)}
                      />
                    )}
                  </FormField>
                </Content>
              </Section>
            )}
            <Footer>
              <Button
                disabled={isSubmitting || !isEmpty(errors)}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};

export default InnerForm;
