// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { Formik } from 'formik';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import type { FuelAndOil } from '../../lib/types/fuelAndOil';
import {
  fetchFuelAndOil,
  cleanFuelAndOil,
  updateFuelAndOil,
  addFuelAndOil
} from '../../ducks/fuelAndOil';
import {
  fuelAndOilGroupEnum,
  fuelAndOilSubTypeEnum,
  fuelAndOilSubViewEnum,
  fuelAndOilViewEnum,
  fuelAndOilTypeEnum
} from '../../lib/enum';
import type { AppState } from '../../ducks/redux';
import { Panel } from './../../components/layout';
import InnerForm from './components/InnerForm';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  fuelAndOil: FuelAndOil,
  fetchFuelAndOil: (id: number) => Promise<void>,
  updateFuelAndOil: (fuelAndOil: FuelAndOil) => Promise<void>,
  addFuelAndOil: Function,
  cleanFuelAndOil: Function,
  fuelAndOilId: number
};

export class Form extends Component<Props> {
  async componentDidMount() {
    const { fuelAndOilId } = this.props;
    await this.props.cleanFuelAndOil();
    if (fuelAndOilId) {
      try {
        await this.props.fetchFuelAndOil(fuelAndOilId);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/admin/fuels-and-oils');
      }
    }
  }

  onCancel = () => {
    navigate('/admin/fuels-and-oils');
  };

  onSubmit = async (fuelAndOil: FuelAndOil) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (fuelAndOil.id) {
        await this.props.updateFuelAndOil(fuelAndOil);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        await this.props.addFuelAndOil(fuelAndOil);
        notification.success({
          message: 'Успешное добавление',
          description: `ГСМ успешно добавлен`
        });
      }
      navigate('/admin/fuels-and-oils');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  getSubviews = function (data: FuelAndOil) {
    switch (data?.view) {
      case fuelAndOilViewEnum.fuel:
        return [
          fuelAndOilSubViewEnum.diesel,
          fuelAndOilSubViewEnum.petrol,
          fuelAndOilSubViewEnum.gaseousFuel
        ];
      case fuelAndOilViewEnum.lubricant:
        return [
          fuelAndOilSubViewEnum.liquidOils,
          fuelAndOilSubViewEnum.plasticLubricants
        ];
      case fuelAndOilViewEnum.technicalFluid:
        return [
          fuelAndOilSubViewEnum.coolingLiquid,
          fuelAndOilSubViewEnum.brakeFluid,
          fuelAndOilSubViewEnum.washingFluid,
          fuelAndOilSubViewEnum.amortizationFluid
        ];
      default:
        break;
    }
  };

  getTypes = function (data: FuelAndOil) {
    switch (data?.subView) {
      case fuelAndOilSubViewEnum.liquidOils:
        return [
          fuelAndOilTypeEnum.motorOil,
          fuelAndOilTypeEnum.transmissionOil,
          fuelAndOilTypeEnum.industrialOil,
          fuelAndOilTypeEnum.hydraulicOil
        ];
      case fuelAndOilSubViewEnum.plasticLubricants:
        return [
          fuelAndOilTypeEnum.lubricantsForConservation,
          fuelAndOilTypeEnum.sealingLubricants,
          fuelAndOilTypeEnum.antiGreaseLubricants
        ];
      case fuelAndOilSubViewEnum.coolingLiquid:
        return [fuelAndOilTypeEnum.tosol, fuelAndOilTypeEnum.antifreeze];
      case fuelAndOilSubViewEnum.washingFluid:
        return [fuelAndOilTypeEnum.washingForEngineOilSystem];
      default:
        break;
    }
  };

  getSubTypes = function (data: FuelAndOil) {
    switch (data?.type) {
      case fuelAndOilTypeEnum.motorOil:
        return [
          fuelAndOilSubTypeEnum.oilForPetrolEngine,
          fuelAndOilSubTypeEnum.oilForDieselEngine
        ];
      case fuelAndOilTypeEnum.hydraulicOil:
        return [fuelAndOilSubTypeEnum.fluidForGur];
      default:
        break;
    }
  };

  getGroups = function (data: FuelAndOil) {
    switch (data?.subView) {
      case fuelAndOilSubViewEnum.diesel:
        return [
          fuelAndOilGroupEnum.summerDieselFuel,
          fuelAndOilGroupEnum.winterDieselFuel
        ];
      case fuelAndOilSubViewEnum.petrol:
        return [
          fuelAndOilGroupEnum.ai76,
          fuelAndOilGroupEnum.ai92,
          fuelAndOilGroupEnum.ai95
        ];
      case fuelAndOilSubViewEnum.gaseousFuel:
        return [fuelAndOilGroupEnum.propaneButane, fuelAndOilGroupEnum.methane];
      case fuelAndOilSubViewEnum.brakeFluid:
        return [
          fuelAndOilGroupEnum.dot2,
          fuelAndOilGroupEnum.dot3,
          fuelAndOilGroupEnum.dot4,
          fuelAndOilGroupEnum.dot5
        ];
      case fuelAndOilSubViewEnum.amortizationFluid:
        return [fuelAndOilGroupEnum.azh12t];
      default:
        break;
    }
    switch (data?.subType) {
      case fuelAndOilSubTypeEnum.oilForPetrolEngine:
        return [
          fuelAndOilGroupEnum.mineralOil,
          fuelAndOilGroupEnum.semisyntheticOil,
          fuelAndOilGroupEnum.synthetic
        ];
      case fuelAndOilSubTypeEnum.oilForDieselEngine:
        return [
          fuelAndOilGroupEnum.mineralOil,
          fuelAndOilGroupEnum.semisyntheticOil,
          fuelAndOilGroupEnum.synthetic
        ];
      default:
        break;
    }
    switch (data?.type) {
      case fuelAndOilTypeEnum.transmissionOil:
        return [
          fuelAndOilGroupEnum.mineralOil,
          fuelAndOilGroupEnum.semisyntheticOil,
          fuelAndOilGroupEnum.synthetic
        ];
      case fuelAndOilTypeEnum.antiGreaseLubricants:
        return [fuelAndOilGroupEnum.shrus, fuelAndOilGroupEnum.litol];
      case fuelAndOilTypeEnum.tosol:
        return [
          fuelAndOilGroupEnum.am,
          fuelAndOilGroupEnum.a40m,
          fuelAndOilGroupEnum.a65m
        ];
      case fuelAndOilTypeEnum.antifreeze:
        return [
          fuelAndOilGroupEnum.antifreezeGreen,
          fuelAndOilGroupEnum.antifreezeRed,
          fuelAndOilGroupEnum.antifreezeViolet
        ];
      case fuelAndOilTypeEnum.washingForEngineOilSystem:
        return [fuelAndOilGroupEnum.fullVolume, fuelAndOilGroupEnum.fiveMinute];
      default:
        break;
    }
  };

  render() {
    const { fuelAndOil } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/fuels-and-oils">ГСМ</Crumb>
              {fuelAndOil ? (
                <Crumb to={`/admin/fuels-and-oils/${fuelAndOil.id}`}>
                  ГСМ №{fuelAndOil.id}
                </Crumb>
              ) : (
                  <Crumb to="/admin/fuels-and-oils/new">Новый ГСМ</Crumb>
                )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{fuelAndOil ? `ГСМ №${fuelAndOil.id}` : 'Новый ГСМ'}</h1>
        </StyledPanel>
        <InnerForm
          fuelAndOil={fuelAndOil}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          getSubViews={this.getSubviews}
          getTypes={this.getTypes}
          getSubTypes={this.getSubTypes}
          getGroups={this.getGroups}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    fuelAndOil: state.fuelAndOil
  }),
  {
    fetchFuelAndOil,
    updateFuelAndOil,
    cleanFuelAndOil,
    addFuelAndOil
  }
)(Form);
