// @flow
import type { Okei } from './../types';
import CommonApi from './../commonApi';

const okei = new CommonApi<Okei>(
  'okei',
  'Общероссийский классификатор единиц измерения'
);

export default {
  ...okei
};
