import {
    MonthlyWashingPlanCard,
    MonthlyWashingPlanForm,
    MonthlyWashingPlanList,
} from '../../containers/budget/monthlyWashingPlans';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MonthlyWashingPlanList,
        path: '/budget/monthly-washing-plans',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingAllWashingPlans,
            accessTypeEnum.viewingWashingPlans,
            accessTypeEnum.formationWashingPlans,
            accessTypeEnum.approvingWashingPlans,
        ],
    },
    {
        component: MonthlyWashingPlanForm,
        path: '/budget/monthly-washing-plans/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.formationWashingPlans,
            accessTypeEnum.approvingWashingPlans,
        ],
    },
    {
        component: MonthlyWashingPlanForm,
        path: '/budget/monthly-washing-plans/edit/:monthlyWashingPlanId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.formationWashingPlans,
            accessTypeEnum.approvingWashingPlans,
        ],
    },
    {
        component: MonthlyWashingPlanCard,
        path: '/budget/monthly-washing-plans/:monthlyWashingPlanId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingAllWashingPlans,
            accessTypeEnum.viewingWashingPlans,
            accessTypeEnum.formationWashingPlans,
            accessTypeEnum.approvingWashingPlans,
        ],
    },
];