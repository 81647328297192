import React from 'react';
import { WarningCellElement } from './elements';
import Popover from '../../../../components/ui/Popover';

type Props = {
  children: any,
  planValue: number,
  oneCValue: number,
  autographValue: number
};

export default ({ children, planValue, oneCValue, autographValue }: Props) => {
  const hasWarning = oneCValue
    ? oneCValue > planValue || oneCValue > autographValue
    : false;

  return hasWarning ? (
    <Popover
      width={200}
      title="Внимание!"
      content={
        <p>
          {oneCValue > planValue &&
            `Значения от подрядчика больше планового значения`}
          <br />
          {oneCValue > autographValue &&
            `Значения от подрядчика больше значения из GPS`}
        </p>
      }
    >
      <WarningCellElement>{children}</WarningCellElement>
    </Popover>
  ) : (
    children
  );
};
