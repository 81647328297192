// @flow

import Button from 'antd/lib/button';

import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Selects } from '../../../../components';
import { Section } from '../../../../components/layout';
import { AntTable } from '../../../../components/ui';
import ButtonsRow from '../../../../components/ui/ButtonsRow';
import { printNotification } from '../../../../lib/notificationWrapper';
import type { VehicleListFilterParams } from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';

import { COLUMNS, filteredData, itogCalculation, MONTH } from '../lib';
import { notificationLoading } from './../../../../components/Notifications';
import { licensePlateChangeCalculationApi, vehiclePlanApi } from './../../../../lib/api';
import { calculationStatusEnum } from './../../../../lib/enum';
import { getListInitialState, toLocalStringRu } from './../../../../lib/helpers';

import type { LicensePlateChangeCalculation, ListState, VehiclePlan } from './../../../../lib/types';

import { headerPanel } from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};
type State = ListState<LicensePlateChangeCalculation> & {
  vehiclePlan: ?VehiclePlan,
  itog: any,
  filter: VehicleListFilterParams
};
const { YesNoSelect } = Selects;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    filter: {},
    itog: itogCalculation([])
  };

  columns = [
    ...COLUMNS,
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      key: `${monthIndex}`,
      children: [
        {
          title: 'Замена',
          dataIndex: `months[${monthIndex}].isCalculationNeeded`,
          render: (isNeeded: boolean, record: LicensePlateChangeCalculation) =>
            Number.isInteger(record.id) && (
              <YesNoSelect
                size="small"
                value={isNeeded}
                disabled={!this.isRangeMonths(monthIndex)}
                onChange={value => {
                  let { data } = this.state;
                  const findIndex = data.findIndex(
                    item => item.id === record.id
                  );
                  data[findIndex].months.forEach(
                    (month: any, index: number) => {
                      if (month.isCalculationNeeded)
                        notification.warning({
                          message: `Для этой записи была уже указана замена госномера за ${MONTH[index].title}`
                        });
                      month.isCalculationNeeded = false;
                    }
                  );
                  data[findIndex].months[
                    monthIndex
                  ].isCalculationNeeded = value;
                  this.setState({ data });
                }}
              />
            )
        },
        {
          title: 'Стоимость',
          dataIndex: `months[${monthIndex}].cost`,
          render: (cost: number) => toLocalStringRu(cost)
        }
      ]
    })),
    {
      title: 'Итог',
      width: 200,
      key: 'sum',
      dataIndex: 'sum',
      render: (sum: number) => toLocalStringRu(sum)
    }
  ];

  componentDidMount() {
    this.getData();
  }

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;
    try {
      let { data } = await licensePlateChangeCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    const { data } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await licensePlateChangeCalculationApi.updateCalculations(data);
      await licensePlateChangeCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    const { data } = this.state;
    try {
      notificationLoading({
        message: 'Расчет стоимости...',
        key: 'saving'
      });
      if (data.length !== 0) {
        await licensePlateChangeCalculationApi.updateCalculations(data);
      } else {
        await licensePlateChangeCalculationApi.calculate(vehiclePlanId);
      }
      this.getData();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  applyFilter = (filter: VehicleListFilterParams) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  handlePrint = () => {
    printNotification(async () => {
      await licensePlateChangeCalculationApi.print(
        this.props.vehiclePlanId,
        this.state.filter
      );
    });
  };

  render() {
    const { vehiclePlanId } = this.props;
    const { data, loading, vehiclePlan, filter } = this.state;
    const filtered = filteredData(data, filter);
    const itog = itogCalculation(filtered);
    const isDraft =
      vehiclePlan?.licensePlateChangeCalculationStatus ===
      calculationStatusEnum.draft;
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          title: 'Расчет стоимости и потребности в замене гос. номеров'
        })}
        <Section>
          <div style={{ padding: '16px 16px 0px' }}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            columns={
              isDraft
                ? this.columns
                : [
                    ...COLUMNS,
                    ...MONTH.map((month, monthIndex) => ({
                      title: month.title,
                      dataIndex: `months[${monthIndex}].cost`,
                      render: (cost: number) => toLocalStringRu(cost)
                    })),
                    {
                      title: 'Итог',
                      width: 150,
                      key: 'sum',
                      dataIndex: 'sum',
                      render: (sum: number) => toLocalStringRu(sum)
                    }
                  ]
            }
            bordered
            scroll={{
              x: isDraft ? 3500 : 2400,
              y: 'calc(100vh - 450px)'
            }}
          />
        </Section>
        {!loading && (
          <Footer>
            <ButtonsRow>
              {isDraft && (
                <>
                  <Button type="primary" onClick={this.calculate}>
                    {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                  </Button>
                  <Button type="primary" onClick={this.changeStatus}>
                    Утвердить
                  </Button>
                </>
              )}
              {data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Footer>
        )}
      </>
    );
  }
}
