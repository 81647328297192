import React from 'react';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  height: 5px;
  width: 100%;
  display: flex;
  pointer-events: none;
  align-items: center;
  &::before,
  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    background: white;
    border-radius: 50%;
    border: 2px solid;
  }
  &::before {
    border-color: #f2994a;
    left: -5px;
  }
  &::after {
    border-color: #27ae60;
    right: -5px;
  }
`;

const statusColor = {
  active: '#7EAAFF',
  nosignal: '#E9180A',
  nonactive: '#ccc'
};

export const Pie = styled(({ status, ...props }) => <div {...props} />)`
  height: 5px;
  pointer-events: none;
  user-select: none;
  ${props =>
    props.status &&
    css`
      background: ${statusColor[props.status]};
    `};
`;
