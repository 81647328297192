import {
    WashingVehicleTypesForm,
    WashingVehicleTypesList,
} from '../../containers/WashingVehicleTypes';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: WashingVehicleTypesList,
        path: '/admin/washing-vehicle-types',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingWashingVehicleTypes,
            accessTypeEnum.handlingWashingVehicleTypes,
        ],
    },
    {
        component: WashingVehicleTypesForm,
        path: '/admin/washing-vehicle-types/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingWashingVehicleTypes,
        ],
    },
    {
        component: WashingVehicleTypesForm,
        path: '/admin/washing-vehicle-types/:id/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingWashingVehicleTypes,
        ],
    },
];