import React from 'react';
import { Router } from '@reach/router';

import { withUserAccess } from '../withUserAccess';

import ExpenseDirectionList from './ExpenseDirection/ExpenseDirectionList';
import ExpenseDirectionForm from './ExpenseDirection/ExpenseDirectionForm';

import BudgetLineItemList from './BudgetLineItem/List';
import BudgetLineItemForm from './BudgetLineItem/Form';

export default withUserAccess(({ userAccess }) => {
  return (
    <>
      <Router>
        <ExpenseDirectionList path="/" />
        <ExpenseDirectionForm path="/new" />
        <ExpenseDirectionForm path="/:expenseDirectionId/edit" />

        <BudgetLineItemList path="/budget-line-item" />
        <BudgetLineItemForm path="/budget-line-item/new" />
        <BudgetLineItemForm path="/budget-line-item/:id/edit" />
      </Router>
    </>
  );
});
