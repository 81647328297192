// @flow
import { Dispatch } from 'redux';

import type { Action } from './redux';
import type { SelfVehiclePlanVehicle } from '../lib/types';

export const SET_SELF_VEHICLE_PLAN = 'vehiclePlan/self/set';
export const SET_PLANNED_VEHICLE_PLAN = 'vehiclePlan/planned/set';

export const DEL_SELF_VEHICLE_PLAN = 'vehiclePlan/self/delete';
export const DEL_PLANNED_VEHICLE_PLAN = 'vehiclePlan/planned/delete';

export type VehiclePlanState = {
  selfVehicles: ?Map<number, SelfVehiclePlanVehicle>,
  plannedVehicles: ?Map<number, SelfVehiclePlanVehicle>
};

const initialState: VehiclePlanState = {
  selfVehicles: new Map(),
  plannedVehicles: new Map()
};

const reducer = (
  state: VehiclePlanState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case SET_SELF_VEHICLE_PLAN:
      return {
        ...state,
        selfVehicles: new Map<number, SelfVehiclePlanVehicle>(payload)
      };
    case SET_PLANNED_VEHICLE_PLAN:
      return {
        ...state,
        plannedVehicles: new Map<number, SelfVehiclePlanVehicle>(payload)
      };
    case DEL_SELF_VEHICLE_PLAN:
      return {
        ...state,
        selfVehicles: new Map<number, SelfVehiclePlanVehicle>(payload)
      };
    case DEL_PLANNED_VEHICLE_PLAN:
      return {
        ...state,
        plannedVehicles: new Map<number, SelfVehiclePlanVehicle>(payload)
      };
    default:
      return state;
  }
};

export const addVehiclePlanVehicle = (
  selfVehiclePlanVehicle: Map<number, SelfVehiclePlanVehicle>,
  self: boolean = true
) => async (dispatch: Dispatch) => {
  dispatch({
    type: self ? SET_SELF_VEHICLE_PLAN : SET_PLANNED_VEHICLE_PLAN,
    payload: selfVehiclePlanVehicle
  });
};

export const setVehiclePlanVehicle = (
  selfVehiclePlanVehicle: Map<number, SelfVehiclePlanVehicle>,
  self: boolean = true
) => async (dispatch: Dispatch) => {
  dispatch({
    type: self ? SET_SELF_VEHICLE_PLAN : SET_PLANNED_VEHICLE_PLAN,
    payload: selfVehiclePlanVehicle
  });
};

export const deleteVehiclePlanVehicle = (
  selfVehiclePlanVehicle: Map<number, SelfVehiclePlanVehicle>,
  self: boolean = true
) => async (dispatch: Dispatch) => {
  dispatch({
    type: self ? DEL_SELF_VEHICLE_PLAN : DEL_PLANNED_VEHICLE_PLAN,
    payload: selfVehiclePlanVehicle
  });
};

export default reducer;
