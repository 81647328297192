// @flow
import max from 'lodash/maxBy';
import sum from 'lodash/sum';
import flattenDeep from 'lodash/flattenDeep';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import shortid from 'shortid';

import type { SpreadsheetTableColumn } from './index';

// Высота строки таблицы по-умолчанию
export const defaultRowHeight = 35;
// Ширина ячейки таблицы по-умолчанию
export const defaultColumnWidth = 80;

/**
 * Функция возращает максимальное количество строк для шапки
 * на основе вложенности колонок
 *
 * Это количество нужно для разметки css grid
 *
 * @param columns Колонки
 * @returns {number} Максимальное количество строк
 */
export const getRowsCount = (columns: SpreadsheetTableColumn[]) => {
  /**
   * Возвращает уровень вложенности для колонок
   * @param column Колонка
   * @param parentLevel Уровень вложенности родителя
   * @returns {number} Максимальный уровень вложенности для колонки
   */
  const getLevel = (column: SpreadsheetTableColumn, parentLevel: number) => {
    const { columns } = column;
    if (Array.isArray(columns)) {
      // Находим все уровни вложенности по дочерним колонкам
      const levels = columns.map(column => getLevel(column, parentLevel + 1));
      return max(levels);
    }
    return parentLevel;
  };

  // Находим все уровни вложенности от родительских колонок
  const levels = columns.map(column => getLevel(column, 1));

  // Возвращаем максимальный уровень - это и есть количество строк таблицы
  return max(levels);
};

/**
 * Возвращает листовые колонки
 *
 * Листовые колонки - это колонки, у которых нет детей.
 * Они отвечают за отображение данных в ячейках таблицы,
 * в то время как их родители отвечают за отображение вложенности
 * в шапке таблицы
 *
 * @param columns {SpreadsheetTableColumn[]} Колонки
 * @returns {SpreadsheetTableColumn[]} Листовые колонки
 */
export const getDataColumns = (
  columns: SpreadsheetTableColumn[]
): SpreadsheetTableColumn[] => {
  /**
   * Вспомогательная функция, возвращает
   * массив листовых колонок для родительской колонки
   * @param column Колонка
   * @returns {SpreadsheetTableColumn|SpreadsheetTableColumn[]} Лист или массив листов
   */
  function getColumns(column: SpreadsheetTableColumn) {
    const { columns } = column;

    // Если нет дочерних колонок, то это лист
    if (!Array.isArray(columns)) {
      return column;
    }

    return columns.map(child => getColumns(child));
  }

  // Разворачиваем массив массивов
  return flattenDeep(columns.map(column => getColumns(column)));
};

/**
 * Функция подсчета ширины колонки на основе детей
 */
export const getColumnWidth = (column: SpreadsheetTableColumn) => {
  const { columns, width } = column;
  if (Array.isArray(columns)) {
    // Возвращаем сумму ширин всех дочерних колонок
    return sum(columns.map(col => getColumnWidth(col)));
  }
  return width || defaultColumnWidth;
};

/**
 * Функция подсчета максимальной высоты шапки среди всех колонок
 * @param columns Колонки
 * @param sumHeight Высота по-умолчанию (нужна для рекурсии)
 */
export const computeHeaderHeight = (
  columns: SpreadsheetTableColumn[],
  sumHeight: number = 0
) => {
  const getHeights = (column: SpreadsheetTableColumn) => {
    const { columns = [], header } = column;
    const height = header.height || defaultRowHeight;
    if (Array.isArray(columns)) {
      return computeHeaderHeight(columns, sumHeight + height);
    }
    return sumHeight + height;
  };

  return max(
    columns.reduce(
      (heights, column) => {
        const height = getHeights(column);
        return [...heights, height];
      },
      [sumHeight]
    )
  );
};

/**
 * Функция для подсчета ширины родительских колонок
 * @param columns {SpreadsheetTableColumn[]} Массив колонок
 * @returns {number[]}
 */
export const computeColumnsWidth = (columns: SpreadsheetTableColumn[]) => {
  return columns.map<number>(col => {
    const width = getColumnWidth(col);
    return Array.isArray(width) ? sum(width) : width;
  });
};

/**
 * Возвращает общее кол-во дочерних листовых колонок у колонки
 * @param {SpreadsheetTableColumn} column Колонка
 */
const getLeafColumnsCount = column => {
  const getCount = (column, acc = 0) => {
    const { columns } = column;
    if (Array.isArray(columns)) {
      const result = columns.map(column => getCount(column, acc));
      return sum(result);
    }
    // Если лист,
    return acc + 1;
  };

  const result = getCount(column);

  return result === 1 ? 0 : result;
};

/**
 * Приводит дерево колонок к плоскому массиву с css grid
 * свойствами для отрисовки шапки таблицы
 *
 * Удобнее всего отрисовывать массив с заранее
 * проставленными css grid свойствами
 * grid-column-start(-end), grid-row-start(-end)
 *
 * @param columns Колонки
 * @param rowsCount Количество строк в шапке
 * @returns {Array}
 */
export const treeToFlattenGrid = (
  columns: SpreadsheetTableColumn[],
  rowsCount: number
): {
  column: SpreadsheetTableColumn,
  gridColumn: { start: number, end: number },
  gridRow: { start: number, end: number },
  isDeepLeaf?: boolean
}[] => {
  /**
   * Вспомогательная функция для обхода дерева
   * @param column Колонка
   * @param rowIndex Номер строки
   * @param prevColumnEnd Значние grid-column-end предыдущей, лежащей на одном уровне, колонки
   * @param hasParent Есть ли родитель у колонки
   */
  const getGridItem = (column, rowIndex, prevColumnEnd, hasParent) => {
    const { columns } = column;
    // Количество листовых колонок у данной колонки,
    // определяет количество столбцов, которые нужно охватить в ширину
    // т.е. сделать span по столбцам
    const leafCount = getLeafColumnsCount(column);
    if (Array.isArray(columns)) {
      const result = [
        {
          column,
          gridColumn: {
            // Столбец начинается там, где заканчивается предыдущий
            start: prevColumnEnd,
            // Конец столбца: (конец предыщуего + количество листовых колонок)
            end: prevColumnEnd + leafCount
          },
          gridRow: {
            start: rowIndex,
            end: rowIndex + 1
          }
        }
      ];
      columns.forEach((column, childColumnIndex) => {
        // Находим предыдущий столбец и берем его gridColumn.end
        const childPrevColumnEnd =
          childColumnIndex > 0
            ? find(result, { column: columns[childColumnIndex - 1] }).gridColumn
                .end
            : // Если нет предыдущего столбца (такая ситуация у первых столбцов),
              // то берем это значение из родительской колонки
              // так как начало родителя и первого его ребенка совпадают
              prevColumnEnd;
        result.push(
          ...getGridItem(column, rowIndex + 1, childPrevColumnEnd, true)
        );
      });
      return result;
    }
    return [
      {
        column,
        isDeepLeaf: hasParent,
        gridColumn: {
          start: prevColumnEnd,
          end: prevColumnEnd + 1
        },
        gridRow: {
          start: rowIndex,
          end: rowsCount + 1
        }
      }
    ];
  };

  const flatten = [];

  columns.forEach(column => {
    const result = getGridItem(column, 1, 1, false);
    flatten.push(...result);
  });

  return flatten;
};

/**
 * Функция проставляет id и parentId в колонки дерева
 * @param columns Колонки
 * @param parentId Родительский для колонок id по-умолчанию
 * @returns {SpreadsheetTableColumn[]}
 */
export const prepareColumns = (
  columns: SpreadsheetTableColumn[],
  parentId: ?string = null
) => {
  const prepareColumn = column => {
    const { columns } = column;
    column.id = shortid.generate();
    column.parentId = parentId;

    if (Array.isArray(columns)) {
      column.columns = prepareColumns(columns, column.id);
    }
    return column;
  };

  return columns.map<SpreadsheetTableColumn>(column => prepareColumn(column));
};

/**
 * Находит колонку в дереве по id
 * @param columns Колонки
 * @param id Идентификатор колонки
 * @returns {SpreadsheetTableColumn} Колонка
 */
export const findColumnById = (
  columns: SpreadsheetTableColumn[],
  id: string
) => {
  const findColumn = (column, id) => {
    if (column.id === id) {
      return column;
    } else if (Array.isArray(column.columns)) {
      return findColumnById(column.columns, id);
    }
  };

  return columns
    .map<?SpreadsheetTableColumn>(column => findColumn(column, id))
    .filter(Boolean)[0];
};

/**
 * Функция изменения дерева колонок по id
 * @param columns Колонки
 * @param columnId Идентификатор колонки
 * @param format Функция изменения колонки по ссылке
 * @returns {SpreadsheetTableColumn[]} Обновленное дерево
 */
export const changeColumns = (
  columns: SpreadsheetTableColumn[],
  columnId: string,
  format: (column: SpreadsheetTableColumn) => void
) => {
  const newColumns = cloneDeep(columns);
  let column = findColumnById(newColumns, columnId);
  format(column);
  return [...newColumns];
};
