// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { brandApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { Brand } from '../../lib/types';

export default (props: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите марку"
    fetch={brandApi.fetchBrands}
    fetchSingle={brandApi.fetchBrand}
    notFoundText="Марки не найдены"
    renderOption={(brand: Brand, Option) => (
      <Option value={brand.id} key={brand.id}>
        {brand.name}
      </Option>
    )}
    {...props}
  />
);
