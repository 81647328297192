//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import VehicleTaxRateSelect from '../../../components/selects/VehicleTaxRateSelect';
import VehicleTypeSelect from '../../../components/selects/VehicleTypeSelect';
import type { VehicleGroupAndType } from '../../../components/selects/VehicleTypeSelect';
import { vehicleTypeEnum } from '../../../lib/enum';

export type VehicleTaxMapParamsFilter = {
  vehicleType: vehicleTypeEnum,
  vehicleTaxRateId: number
};

export const VehicleTaxMapFilter = (
  props: FilterProps<VehicleTaxMapParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<VehicleTaxMapParamsFilter>>) =>
      CommonFilter<VehicleTaxMapParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleTaxRateSelect
                size="small"
                value={values.vehicleTaxRateId}
                onChange={value => changeValue('vehicleTaxRateId', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleTypeSelect
                size="small"
                resultAsObject
                onChange={({ type }: VehicleGroupAndType) => {
                  changeValue('vehicleType', type);
                }}
                value={values.vehicleType}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default VehicleTaxMapFilter;
