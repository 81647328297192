// @flow

import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import {
  DatePicker,
  RangePicker,
} from '../../../../../../components/ant/DatePicker';
import {
  contractVehicleApi,
  contractVehiclePlanApi,
} from '../../../../../../lib/api';
import { OrgUnitSelect, Selects } from './../../../../../../components';
import Field from './../../../../../../components/card/Field';

import {
  Grid,
  Header,
  Section,
  SectionTitle,
  TopPanel,
} from './../../../../../../components/layout';
import Breadcrumbs, {
  Crumb,
} from './../../../../../../components/layout/Breadcrumbs';
import { GridItem } from './../../../../../../components/layout/Grid';
import { notificationLoading } from './../../../../../../components/Notifications';
import {
  contractVehicleTypeEnum,
  entityStatusEnum,
  vehicleStatusEnum,
} from './../../../../../../lib/enum';
import {
  formatDateTimeToISOString,
  getValueObject,
  isEmptyValue,
} from './../../../../../../lib/helpers';

import type {
  ContractVehicle,
  ContractVehiclePlan,
  ContractVehicleType,
} from './../../../../../../lib/types';

import Tabs from './../components/Tabs';

import type { FixedVehicleListFilterParams } from './components/FilterVehicleList';
import ModalContractVehicle from './components/ModalContractVehicle';
import ModalPlannedContractVehicle from './components/ModalPlannedContractVehicle';

import VehicleSection from './components/VehicleSection';

type FixedContractVehicleParams = {
  data: ContractVehicle[],
  loading: boolean,
  modal: boolean,
};
type Props = {
  location: Location & { state: { page: number } },
  contractVehiclePlanId: ?number,
};
type State = {
  contractVehiclePlan: ?$Shape<ContractVehiclePlan>,
  contractVehicles: FixedContractVehicleParams,
  plannedContractVehicles: FixedContractVehicleParams,
};
export type ContractVehicleTypeData =
  | 'contractVehicles'
  | 'plannedContractVehicles';

const { BudgetVersionSelect, RegulationSelect } = Selects;
const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const CONTRACT_VEHICLE_TYPE = [
  contractVehicleTypeEnum.fixed,
  contractVehicleTypeEnum.oneOff,
];
const PLANNED_CONTRACT_VEHICLE_TYPE = [
  contractVehicleTypeEnum.oneOffPlan,
  contractVehicleTypeEnum.fixedPlan,
];
export default class extends Component<Props, State> {
  emptyFixedContractVehicleParams = {
    data: [],
    loading: false,
    modal: false,
  };

  state = {
    contractVehicles: { ...this.emptyFixedContractVehicleParams },
    plannedContractVehicles: { ...this.emptyFixedContractVehicleParams },
    contractVehiclePlan: null,
  };

  async componentDidMount() {
    const { contractVehiclePlanId } = this.props;
    if (contractVehiclePlanId) {
      const contractVehiclePlan = await this.getContractVehiclePlan(
        contractVehiclePlanId
      );
      if (contractVehiclePlan) {
        this.setState((prevState) => ({
          contractVehiclePlan: {
            orgUnitId: contractVehiclePlan.orgUnitId,
            regulationId: contractVehiclePlan.regulationId,
            budgetVersionId: contractVehiclePlan.budgetVersionId,
          },
          contractVehicles: {
            ...prevState.contractVehicles,
            data: contractVehiclePlan.contractVehicles || [],
          },
          plannedContractVehicles: {
            ...prevState.plannedContractVehicles,
            data: contractVehiclePlan.plannedContractVehicles || [],
          },
        }));
      }
    }
  }

  getContractVehiclePlan = async (
    contractVehiclePlanId: number
  ): Promise<?ContractVehiclePlan> => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting',
      });
      return await contractVehiclePlanApi.get(contractVehiclePlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('getting');
    }
  };

  fetch =
    (selectedType: ContractVehicleTypeData) =>
    async (
      orgUnitId: number,
      type: ContractVehicleType[],
      filter: FixedVehicleListFilterParams
    ) => {
      if (orgUnitId) {
        this.onChangeVehicle(selectedType)('loading', true);
        try {
          const { data } = await contractVehicleApi.fetchContractVehicles({
            'vehicle.nodeId': orgUnitId,
            'vehicle.status':
              selectedType === 'contractVehicles'
                ? [vehicleStatusEnum.draft, vehicleStatusEnum.working]
                : undefined,
            ...filter,
            page: undefined,
            pageSize: undefined,
            type,
          });
          this.onChangeVehicle(selectedType)('data', data);
        } catch (error) {
          notification.error({
            message: 'Ошибка',
            description: error.message,
          });
        } finally {
          this.onChangeVehicle(selectedType)('loading', false);
        }
      }
    };

  onChangeVehicle =
    (selectedType: ContractVehicleTypeData) => (key: string, value: any) => {
      this.setState((prevState) => ({
        [(selectedType: string)]: {
          ...prevState[selectedType],
          [key]: value,
        },
      }));
    };

  actionFilter = (
    selectedType: ContractVehicleTypeData,
    filter: FixedVehicleListFilterParams
  ) => {
    const { contractVehiclePlan } = this.state;
    if (contractVehiclePlan) {
      const type =
        selectedType === 'contractVehicles'
          ? CONTRACT_VEHICLE_TYPE
          : PLANNED_CONTRACT_VEHICLE_TYPE;
      this.setState(
        (state) => {
          return {
            contractVehiclePlan: {
              ...state.contractVehiclePlan,
              [(selectedType: string)]: [],
            },
          };
        },
        () =>
          this.fetch(selectedType)(contractVehiclePlan.orgUnitId, type, filter)
      );
    }
  };

  applyFilter = (
    selectedType: ContractVehicleTypeData,
    filter: FixedVehicleListFilterParams
  ) => {
    this.actionFilter(selectedType, filter);
  };

  cleanFilter = (selectedType: ContractVehicleTypeData) => {
    this.actionFilter(selectedType, { ...null });
  };

  onChangeContractVehiclePlan = async (key: string, value: any) => {
    await this.setState(
      (prevState) => {
        return {
          contractVehiclePlan: {
            ...prevState.contractVehiclePlan,
            [key]: value,
          },
        };
      },
      () => {
        if (key === 'orgUnitId') {
          // сбрасываем выбранные ТС
          this.setState(
            (prevState) => {
              return {
                contractVehiclePlan: {
                  ...prevState.contractVehiclePlan,
                  contractVehicles: [],
                  plannedContractVehicles: [],
                },
                contractVehicles: { ...this.emptyFixedContractVehicleParams },
                plannedContractVehicles: {
                  ...this.emptyFixedContractVehicleParams,
                },
              };
            },
            () => {
              if (value) {
                this.fetch('contractVehicles')(value, CONTRACT_VEHICLE_TYPE, {
                  ...null,
                });
                this.fetch('plannedContractVehicles')(
                  value,
                  PLANNED_CONTRACT_VEHICLE_TYPE,
                  { ...null }
                );
              }
            }
          );
        }
      }
    );
  };

  validation = (contractVehiclePlan: ContractVehiclePlan) => {
    const fields = {
      startDate: 'дату начала планируемого периода',
      endDate: 'дату окончания планируемого периода',
      date: 'дату формирования',
      orgUnitId: 'филиал',
      budgetVersionId: 'версию бюджета',
      regulationId: 'регламент',
      contractVehicles: 'ТС согласно инвентарной картотеке',
    };
    Object.keys(fields).forEach((field) => {
      if (isEmptyValue(contractVehiclePlan[field]))
        throw new Error(`Выберите ${fields[field]}`);
    });
  };

  handleContractVehiclePlan = async () => {
    let { contractVehiclePlan } = this.state;
    if (contractVehiclePlan) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        this.validation(contractVehiclePlan);
        contractVehiclePlan = await contractVehiclePlanApi.add(
          contractVehiclePlan
        );
        navigate(
          `/budget/contract-vehicle/fixed/${contractVehiclePlan.id}/card`
        );
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  hideModal = (selectedType: ContractVehicleTypeData) => () => {
    this.onChangeVehicle(selectedType)('modal', false);
  };

  addContractVehicle = async (contractVehicle: ContractVehicle) => {
    const { contractVehicles } = this.state;
    this.onChangeVehicle('contractVehicles')('data', [
      ...contractVehicles.data,
      contractVehicle,
    ]);
    this.hideModal('contractVehicles')();
  };

  addNewContractVehicle = async (contractVehicle: ContractVehicle) => {
    const plannedContractVehicle = await contractVehicleApi.addContractVehicle(
      contractVehicle
    );
    this.hideModal('plannedContractVehicles')();
    this.onChangeVehicle('plannedContractVehicles')('data', [
      ...this.state.plannedContractVehicles.data,
      plannedContractVehicle,
    ]);
  };

  render() {
    const { contractVehiclePlan, contractVehicles, plannedContractVehicles } =
      this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/contract-vehicle/fixed">Наемные ТС</Crumb>
              <Crumb>Перечень НТС</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Планируемый перечень ТС</h1>
        </TopPanel>
        <Tabs />

        <Section>
          <SectionTitle divider>Период и версия бюджета</SectionTitle>
          <Content>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <Field label="Планируемый период">
                  <RangePicker
                    size="small"
                    format="DD.MM.YYYY"
                    startDate={
                      contractVehiclePlan && contractVehiclePlan.startDate
                        ? moment.utc(contractVehiclePlan.startDate)
                        : null
                    }
                    endDate={
                      contractVehiclePlan && contractVehiclePlan.endDate
                        ? moment.utc(contractVehiclePlan.endDate)
                        : null
                    }
                    onChange={this.onChangeContractVehiclePlan}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Версия бюджета">
                  <BudgetVersionSelect
                    size="small"
                    filter={{ IsAvailableForSelect: true }}
                    value={getValueObject(
                      contractVehiclePlan,
                      'budgetVersionId'
                    )}
                    onChange={(budgetVersionId: number) => {
                      this.onChangeContractVehiclePlan(
                        'budgetVersionId',
                        budgetVersionId
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата формирования">
                  <DatePicker
                    size="small"
                    format="DD.MM.YYYY"
                    value={
                      contractVehiclePlan && contractVehiclePlan.date
                        ? moment(contractVehiclePlan.date)
                        : null
                    }
                    onChange={(value: string) =>
                      this.onChangeContractVehiclePlan('date', value)
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Регламент">
                  <RegulationSelect
                    placeholder="Выберите регламент"
                    size="small"
                    filter={{ status: entityStatusEnum.approved }}
                    value={getValueObject(contractVehiclePlan, 'regulationId')}
                    onChange={(regulationId) =>
                      this.onChangeContractVehiclePlan(
                        'regulationId',
                        regulationId
                      )
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Филилал">
                  <OrgUnitSelect
                    onlyBranches
                    size="small"
                    value={getValueObject(contractVehiclePlan, 'orgUnitId')}
                    onChange={(orgUnitId) =>
                      this.onChangeContractVehiclePlan('orgUnitId', orgUnitId)
                    }
                  />
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.onChangeVehicle('contractVehicles')('modal', true)
                    }
                    disabled={
                      !(contractVehiclePlan && contractVehiclePlan.orgUnitId)
                    }
                  >
                    Добавить ТС
                  </Button>
                </>
              </>
            }
          >
            ТС согласно инвентарной картотеке
          </SectionTitle>
          <Content>
            <VehicleSection
              contractVehiclePlanType="contractVehicles"
              data={contractVehicles.data}
              loading={contractVehicles.loading}
              onChange={this.onChangeContractVehiclePlan}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    this.onChangeVehicle('plannedContractVehicles')(
                      'modal',
                      true
                    )
                  }
                  disabled={
                    !(contractVehiclePlan && contractVehiclePlan.orgUnitId)
                  }
                >
                  Добавить новый ТС
                </Button>
              </>
            }
          >
            ТС согласно плану обновления
          </SectionTitle>
          <Content>
            <VehicleSection
              contractVehiclePlanType="plannedContractVehicles"
              data={plannedContractVehicles.data}
              loading={plannedContractVehicles.loading}
              onChange={this.onChangeContractVehiclePlan}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </Content>
        </Section>

        <ModalContractVehicle
          visible={contractVehicles.modal}
          onCancel={this.hideModal('contractVehicles')}
          addContractVehicle={this.addContractVehicle}
          orgUnitId={contractVehiclePlan && contractVehiclePlan.orgUnitId}
        />
        <ModalPlannedContractVehicle
          visible={plannedContractVehicles.modal}
          onCancel={this.hideModal('plannedContractVehicles')}
          addContractVehicle={this.addNewContractVehicle}
          orgUnitId={contractVehiclePlan && contractVehiclePlan.orgUnitId}
        />
        <Footer>
          <Button onClick={this.handleContractVehiclePlan} type="primary">
            Сформировать
          </Button>
        </Footer>
      </>
    );
  }
}
