// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import moment from 'moment';
import qs from 'query-string';

import { contractVehicleApi } from '../../../lib/api';
import { Section } from '../../../components/layout';
import { SectionContent } from './components/elements';
import { SpreadsheetTable } from '../../../components/ui';
import Spinner from '../../../components/Spinner';
import {
  aggregateByMonth,
  aggregateByQuarters,
  convertParamsKeys,
  paramsKeys,
  sumQuarterAggregation
} from './lib';
import type { SpreadsheetTableColumn } from '../../../components/ui/SpreadsheetTable';
import type { ContractVehicleAnalysis } from '../../../lib/types';
import { quarters, vehicleTypes } from '../../../lib/enum';
import Filter, { type ContractVehicleAnalysisFilterParams } from './Filter';
import { getDates, isRangeInOneMonth } from '../lib';
import { Bold, Day, VehicleInfo } from '../elements';
import type { QuarterAggregation } from './lib';
import WarningCell from './components/WarningCell';

const initialFilter: ContractVehicleAnalysisFilterParams = {
  quarters: [],
  mode: 'byMonth',
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type Props = {
  orgUnitId: number
};

type State = {
  analysis: ContractVehicleAnalysis[],
  monthColumns: SpreadsheetTableColumn[],
  loading: boolean,
  filter: ContractVehicleAnalysisFilterParams,
  quarters: QuarterAggregation[]
};

/**
 * Планирование лимитных дней на год
 */
export default class AnalysisTable extends React.Component<Props, State> {
  state = {
    analysis: [],
    monthColumns: [],
    changedDateTimes: [],
    loading: false,
    filter: initialFilter,
    quarters: []
  };

  async componentDidMount() {
    // Явно проставляем значения из queryString,
    // чтобы корректно работало очищение фильтра
    const {
      mode = initialFilter.mode,
      startDate = initialFilter.startDate,
      endDate = initialFilter.endDate,
      vehicleId
    } = qs.parse(window.location.search);
    this.setState(
      {
        filter: {
          mode,
          startDate,
          endDate,
          vehicleId: vehicleId && parseInt(vehicleId, 10)
        }
      },
      async () => {
        // Если даты из периода в одном месяце, то запрашиваем данные
        if (isRangeInOneMonth(startDate, endDate)) {
          await this.fetchContractVehicleAnalysis();
        } else {
          // иначе меняем отображение колонок на период
          // и запрашиваем данные
          this.setState(
            ({ filter }) => ({
              filter: {
                ...filter,
                mode: 'byPeriod'
              }
            }),
            this.fetchContractVehicleAnalysis
          );
        }
      }
    );
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.orgUnitId !== this.props.orgUnitId) {
      await this.fetchContractVehicleAnalysis();
    }
  }

  /**
   * Генерирует колонки за кварталы на основе приходящих с сервера данных
   *
   * @param days Дни
   * @returns {Array<SpreadsheetTableColumn>} Массив колонок
   */
  getColumnsByQuarters = () => {
    const {
      filter: { quarters: filterQuarters = [] },
      quarters: stateQuarters
    } = this.state;
    return [
      ...filterQuarters
        .sort((a, b) => quarters[a].localeCompare(quarters[b]))
        .map<SpreadsheetTableColumn>(quarter => {
          return {
            header: {
              title: quarters[quarter]
            },
            columns: [
              {
                width: 70,
                header: {
                  title: 'План'
                },
                cells: paramsKeys.map(key => ({
                  formatValue: (cellKey, rowData, rowIndex) =>
                    convertParamsKeys[key](
                      stateQuarters[rowIndex][quarter] &&
                        stateQuarters[rowIndex][quarter].plan &&
                        stateQuarters[rowIndex][quarter].plan[key]
                    )
                }))
              },
              {
                width: 70,
                header: {
                  title: 'GPS'
                },
                cells: paramsKeys.map(key => ({
                  formatValue: (cellKey, rowData, rowIndex) =>
                    convertParamsKeys[key](
                      stateQuarters[rowIndex][quarter] &&
                        stateQuarters[rowIndex][quarter].autograph &&
                        stateQuarters[rowIndex][quarter].autograph[key]
                    )
                }))
              },
              {
                width: 70,
                header: {
                  title: 'Подрядчик'
                },
                cells: paramsKeys.map(key => ({
                  cellStyle: {
                    padding: '0'
                  },
                  formatValue: (cellValue, rowData, rowIndex) => {
                    const values = stateQuarters[rowIndex][quarter],
                      planValue = values && values.plan[key],
                      oneCValue = values && values['1c'][key],
                      autographValue = values && values.autograph[key];
                    if (oneCValue) {
                      return (
                        <WarningCell
                          planValue={planValue}
                          oneCValue={oneCValue}
                          autographValue={autographValue}
                        >
                          {convertParamsKeys[key](oneCValue)}
                        </WarningCell>
                      );
                    } else return '-';
                  }
                }))
              }
            ]
          };
        }),
      {
        header: {
          title: 'Итого'
        },
        columns: [
          {
            width: 70,
            header: {
              title: 'План'
            },
            cells: sumQuarterAggregation(stateQuarters, 'plan')
          },
          {
            width: 70,
            header: {
              title: 'GPS'
            },
            cells: sumQuarterAggregation(stateQuarters, 'autograph')
          },
          {
            width: 70,
            header: {
              title: 'Подрядчик'
            },
            cells: sumQuarterAggregation(stateQuarters, '1c')
          }
        ]
      }
    ];
  };

  /**
   * Генерирует колонки за период на основе приходящих с сервера данных
   *
   * @param days Дни
   * @returns {Array<SpreadsheetTableColumn>} Массив колонок
   */
  getColumnsByPeriod = (days: string[]) => {
    const {
      analysis,
      filter: { startDate, endDate }
    } = this.state;
    const aggregations = aggregateByMonth(analysis, startDate, endDate);

    return [
      ...days.map<SpreadsheetTableColumn>(day => ({
        header: {
          title: moment(day).format('DD.MM')
        },
        columns: [
          {
            width: 70,
            header: {
              title: 'План'
            },
            cells: paramsKeys.map(key => ({
              keyPath: `dateTimes.${day}.plan.${key}`,
              formatValue: convertParamsKeys[key]
            }))
          },
          {
            width: 70,
            header: {
              title: 'GPS'
            },
            cells: paramsKeys.map(key => ({
              keyPath: `dateTimes.${day}.autograph.${key}`,
              formatValue: convertParamsKeys[key]
            }))
          },
          {
            width: 70,
            header: {
              title: 'Подрядчик'
            },
            cells: paramsKeys.map(key => ({
              keyPath: `dateTimes.${day}.1c.${key}`,
              // formatValue: convertParamsKeys[key],
              cellStyle: {
                padding: '0'
              },
              formatValue: (cellValue, rowData, rowIndex) => {
                const dateTime = rowData.dateTimes[day];
                if (dateTime) {
                  return (
                    <WarningCell
                      planValue={dateTime.plan && dateTime.plan[key]}
                      oneCValue={cellValue}
                      autographValue={
                        dateTime.autograph && dateTime.autograph[key]
                      }
                    >
                      {convertParamsKeys[key](cellValue)}
                    </WarningCell>
                  );
                } else return '-';
              }
            }))
          }
        ]
      })),
      {
        header: {
          title: 'Итого'
        },
        columns: [
          {
            width: 70,
            header: {
              title: 'План'
            },
            cells: paramsKeys.map(key => ({
              formatValue: (cellKey, rowData, rowIndex) => {
                return convertParamsKeys[key](aggregations[rowIndex].plan[key]);
              }
            }))
          },
          {
            width: 70,
            header: {
              title: 'GPS'
            },
            cells: paramsKeys.map(key => ({
              formatValue: (cellKey, rowData, rowIndex) => {
                return convertParamsKeys[key](
                  aggregations[rowIndex].autograph[key]
                );
              }
            }))
          },
          {
            width: 70,
            header: {
              title: 'Подрядчик'
            },
            cells: paramsKeys.map(key => ({
              cellStyle: {
                padding: '0'
              },
              formatValue: (cellValue, rowData, rowIndex) => {
                const values = aggregations[rowIndex],
                  planValue = values.plan[key],
                  oneCValue = values['1c'][key],
                  autograph = values.autograph[key];
                if (oneCValue) {
                  return (
                    <WarningCell
                      planValue={planValue}
                      oneCValue={oneCValue}
                      autographValue={autograph}
                    >
                      {convertParamsKeys[key](oneCValue)}
                    </WarningCell>
                  );
                } else return '-';
              }
            }))
          }
        ]
      }
    ];
  };

  /**
   * Генерирует колонки за месяц на основе приходящих с сервера данных
   * @param days Дни
   * @returns {Array<SpreadsheetTableColumn>} Массив колонок
   */
  getColumnsByMonth = (days: string[]) => {
    const {
      analysis,
      filter: { startDate, endDate }
    } = this.state;

    const aggregations = aggregateByMonth(analysis, startDate, endDate);

    return [
      ...days
        .sort((a, b) => new Date(a) - new Date(b))
        .map<SpreadsheetTableColumn>(day => {
          return {
            header: {
              render: () => (
                <>
                  {moment(day).format('DD MMM')}
                  <Day>{moment(day).format('ddd')}</Day>
                </>
              )
            },
            columns: [
              {
                width: 70,
                header: {
                  title: 'План'
                },
                cells: paramsKeys.map(key => ({
                  keyPath: `dateTimes.${day}.plan.${key}`,
                  formatValue: convertParamsKeys[key]
                }))
              },
              {
                width: 70,
                header: {
                  title: 'GPS'
                },
                cells: paramsKeys.map(key => ({
                  keyPath: `dateTimes.${day}.autograph.${key}`,
                  formatValue: convertParamsKeys[key]
                }))
              },
              {
                width: 70,
                header: {
                  title: 'Подрядчик'
                },
                cells: paramsKeys.map(key => ({
                  keyPath: `dateTimes.${day}.1c.${key}`,
                  formatValue: convertParamsKeys[key]
                }))
              }
            ]
          };
        }),
      {
        header: {
          title: 'Итого'
        },
        columns: [
          {
            width: 70,
            header: {
              title: 'План'
            },
            cells: paramsKeys.map(key => ({
              formatValue: (cellValue, rowData, rowIndex) => {
                return convertParamsKeys[key](aggregations[rowIndex].plan[key]);
              }
            }))
          },
          {
            width: 70,
            header: {
              title: 'GPS'
            },
            cells: paramsKeys.map(key => ({
              formatValue: (cellValue, rowData, rowIndex) => {
                return convertParamsKeys[key](
                  aggregations[rowIndex].autograph[key]
                );
              }
            }))
          },
          {
            width: 70,
            header: {
              title: 'Подрядчик'
            },
            cells: paramsKeys.map(key => ({
              formatValue: (cellValue, rowData, rowIndex) => {
                return convertParamsKeys[key](
                  aggregations[rowIndex]['1c'][key]
                );
              }
            }))
          }
        ]
      }
    ];
  };

  columns = [
    {
      header: {
        title: 'ТС'
      },
      width: 210,
      cellStyle: {
        justifyContent: 'flex-start'
      },
      cell: {
        keyPath: 'vehicle',
        formatValue: (vehicle: any, value: ContractVehicleAnalysis) => (
          <VehicleInfo>
            <p>
              <Bold>Госномер:</Bold> {vehicle.licensePlate}
            </p>
            <p>
              <Bold>Тип ТС:</Bold> {vehicleTypes[vehicle.type]}
            </p>
            <p>
              <Bold>Служба закрепления:</Bold> {vehicle.orgUnitName}
            </p>
            {value.timeLimit && (
              <p>
                <Bold>Закрепление:</Bold>
                {value.timeLimit.targetName}
              </p>
            )}
          </VehicleInfo>
        )
      }
    },
    {
      width: 130,
      fixed: true,
      header: {
        title: 'Параметр выработки'
      },
      cells: [
        {
          value: 'Часы'
        },
        {
          value: 'Простой с вкл. двиг.'
        },
        {
          value: 'Простой'
        },
        {
          value: 'Часы в пути'
        },
        {
          value: 'Пробег'
        }
      ]
    }
  ];

  fetchContractVehicleAnalysis = async () => {
    const { orgUnitId } = this.props;
    const {
      filter: { vehicleId, ...filter }
    } = this.state;
    try {
      this.setState({ loading: true });
      const analysis: ContractVehicleAnalysis[] = await contractVehicleApi.fetchContractVehicleAnalysis(
        {
          ...filter,
          orgUnitId
        }
      );
      const dates = getDates(filter.startDate, filter.endDate);
      this.setState(
        {
          analysis: vehicleId
            ? analysis.filter(analysis => analysis.vehicle.id === vehicleId)
            : analysis,
          loading: false
        },
        () => {
          const { mode, quarters } = filter;
          if (mode === 'byMonth') {
            this.setState({ monthColumns: this.getColumnsByMonth(dates) });
          } else if (mode === 'byPeriod') {
            this.setState({
              monthColumns: this.getColumnsByPeriod(dates)
            });
          } else if (mode === 'byQuarters') {
            this.setState(
              { quarters: aggregateByQuarters(analysis, quarters) },
              () => {
                this.setState({ monthColumns: this.getColumnsByQuarters() });
              }
            );
          }
        }
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  applyFilter = (filter: ContractVehicleAnalysisFilterParams) =>
    this.setState({ filter }, this.fetchContractVehicleAnalysis);

  cleanFilter = () =>
    this.setState(
      {
        filter: {
          ...initialFilter,
          mode: 'byMonth'
        }
      },
      this.fetchContractVehicleAnalysis
    );

  render() {
    const { loading, analysis, filter } = this.state;

    return (
      <>
        <Section>
          <SectionContent>
            <Filter
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              filter={filter}
            />
          </SectionContent>
          {loading ? (
            <SectionContent>
              <Spinner isLoading />
            </SectionContent>
          ) : (
            <SpreadsheetTable
              fixedColumnCount={2}
              headerHeight={140}
              style={{ height: 'calc(100vh - 205px)' }}
              data={analysis}
              notFoundText="Данных о бюджете нет"
              columns={[...this.columns, ...this.state.monthColumns]}
            />
          )}
        </Section>
      </>
    );
  }
}
