import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import Button from 'antd/lib/button/button';

import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';
import type { VehicleModel } from '../../../lib/types';
import { Popconfirm, Operations } from '../../../components/ui';
import StyledIcon from '../../../components/ui/Icon';

type Props = {
  value: VehicleModel[],
  onChange: () => any,
};
export default (props: Props) => {
  const [data, setData] = useState(props.value || []);
  const [newVehicleModel, setNewVehicleModel] = useState();

  const columns = [
    {
      title: 'Наименование',
      render: (record: VehicleModel) => `${record.name} ${record.brandName}`,
    },
    {
      title: ' ',
      // eslint-disable-next-line no-unused-vars
      render: (record: VehicleModel, _, idx: number): any => {
        return (
          <Operations>
            {
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => {
                  const newData = data.filter((el, index) => {
                    return index !== idx;
                  });
                  setData(newData);
                  props.onChange(newData);
                }}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            }
          </Operations>
        );
      },
    },
  ];

  useEffect(() => {
    if (Array.isArray(props.value)) {
      setData([...props.value]);
    }
  }, [props.value]);

  return (
    <>
      {!!data?.length && (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
        />
      )}
      <Popconfirm
        okText="Добавить"
        cancelText="Отмена"
        placement="topLeft"
        title={
          <div style={{ width: '400px', margin: '0px 50px 20px 0px' }}>
            <VehicleModelSelect
              mode="multiple"
              dropdownStyle={{ zIndex: 1070 }}
              value={newVehicleModel?.map(({ id }) => id) ?? []}
              onChange={(value, option) => {
                setNewVehicleModel(
                  option.map(({ vehicleModel }) => vehicleModel)
                );
              }}
            />
          </div>
        }
        onConfirm={() => {
          if (Array.isArray(newVehicleModel)) {
            props.onChange([
              ...data,
              ...newVehicleModel.filter((model) => {
                return !data.map(({ id }) => id).includes(model.id);
              }),
            ]);
            setNewVehicleModel(null);
          }
        }}
        onCancel={() => {
          setNewVehicleModel(null);
        }}
      >
        <Button>Добавить</Button>
      </Popconfirm>
    </>
  );
};
