// @flow
import { fetchRequest } from './index';
import type { OsagoCalculationYear } from '../types/osagoCalculation';

export const fetchOsagoCalculationYear = async (year: number) =>
  await fetchRequest.get(`/osagoCalculationYear/${year}/byYear`);

export const changeOsagoCalculationStatusYear = async (
  osagoCalculationYear: OsagoCalculationYear
) =>
  await fetchRequest.post(
    `/osagoCalculationYear/changeStatus`,
    osagoCalculationYear
  );

export const getOsagoCalculationYear = async (id: number) =>
  await fetchRequest.get(`/osagoCalculationYear/${id}`);

export const deleteOsagoCalculationYear = async (id: number) =>
  await fetchRequest.delete(`/osagoCalculationYear/${id}`);

export const addOsagoCalculationYear = async (
  osagoCalculationYear: OsagoCalculationYear
) => {
  const added = await fetchRequest.post(
    '/osagoCalculationYear',
    osagoCalculationYear
  );
  if (added) return added;
  throw new Error('Не удалось создать расчет стоимости ОСАГО');
};

export const recalculationOsagoCalculationYear = async (data: {
  id: number,
  year: number
}) => {
  const added = await fetchRequest.put(
    '/osagoCalculationYear/recalculation',
    data
  );
  if (added) return added;
  throw new Error('Не удалось пересчитать расчет стоимости ОСАГО');
};

export default {
  addOsagoCalculationYear,
  getOsagoCalculationYear,
  fetchOsagoCalculationYear,
  deleteOsagoCalculationYear,
  changeOsagoCalculationStatusYear,
  recalculationOsagoCalculationYear
};
