// @flow

import type { Journal } from './../types';

import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { JournalFilter } from '../../ducks/journals';

const fetchJournals = async (
  params: FetchListParams<JournalFilter> = initialFetchListParams
): Promise<ListResponse<Journal>> =>
  await fetchRequest.get('/journal', {
    ...initialFetchListParams,
    ...params
  });

export const fetchJournal = async (
  id: number,
  params: any = {}
): Promise<Journal> => {
  const journal = await fetchRequest.get(`/journal/${id}`, params);
  if (journal) return journal;
  throw new Error('Не удалось загрузить сотрудника');
};

export const printJournal = async (): Promise<boolean> => {
  const print = await downloadRequestWithToken(`/journal/print`);
  if (print) return print;
  throw new Error('Не удалось загрузить журнал');
};

export default {
  fetchJournals,
  fetchJournal,
  printJournal
};
