// @flow
import React, { type ComponentType } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  color: string,
  type: string,
  size?: 8 | 12 | 16,
  className?: string,
  disabled?: boolean,
  onClick?: Function,
  color?: string
};

// TODO: сделано плохо, зачем брать свг файлы в разных размерах если это свг и еще и цвет тоже можно налету задавать

/**
 * Компонент иконки
 */
const Icon = ({ size = 12, type, onClick, ...props }: Props) => (
  <span {...props} onClick={onClick}>
    <img
      className="icon__image"
      src={`/img/icons/${type}-${size}.svg`}
      alt={type}
    />
  </span>
);

const StyledIcon: ComponentType<Props> = styled(Icon).attrs({
  className: 'icon'
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.disabled &&
    css`
      fill: ${props.color ? props.color : '#c0ccd7'};
    `};
`;

StyledIcon.displayName = 'Icon';

export default StyledIcon;
