import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { VehicleIssue } from '../types/vehicleIssue';

export const addVehicleIssue = async (
  vehicleIssue: VehicleIssue
): Promise<void> => {
  const added = await fetchRequest.post('/vehicleIssue', vehicleIssue);
  if (added) return added;
  throw new Error('Не удалось создать дефект');
};

export const fetchVehicleIssue = async (
  params: FetchListParams<> = initialFetchListParams
): Promise<ListResponse<VehicleIssue>> => {
  const list = await fetchRequest.get('/vehicleIssue', {
    ...initialFetchListParams,
    ...params
  });
  if (list) return list;
  throw new Error('Не удалось получить список дефектов');
};

export const updateVehicleIssue = async (
  vehicleIssue: VehicleIssue
): Promise<VehicleIssue> => {
  const updated = await fetchRequest.put('/vehicleIssue', vehicleIssue);
  if (updated) return updated;
  throw new Error('Не удалось обновить дефект');
};

export const getVehicleIssue = async (id: number): Promise<VehicleIssue> => {
  let vehicleIssue = await fetchRequest.get(`/vehicleIssue/${id}`);
  if (vehicleIssue) return vehicleIssue;
  throw new Error('Не удалось загрузить дефект');
};

export const deleteVehicleIssue = async (id: number): Promise<VehicleIssue> => {
  const deleted = await fetchRequest.delete(`/vehicleIssue/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить дефект');
};

export const printVehicleIssue = async (id: number) => {
  const print = await downloadRequestWithToken(`/vehicleIssue/${id}/print`);
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export default {
  printVehicleIssue,
  fetchVehicleIssue,
  getVehicleIssue,
  deleteVehicleIssue,
  addVehicleIssue,
  updateVehicleIssue
};
