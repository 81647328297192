// @flow
import queryString from 'query-string';
import type { Pass, ExecutionBudgetGroupedList } from './../types';

import {
  fetchRequest,
  downloadRequestWithToken,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { PassFilterParams } from '../../containers/services/pass/Filter';

export const convertDataFromServer = (pass: Pass) => ({
  ...pass,
  paymentAmount: convertFromKopToRub(pass.paymentAmount),
  paymentAmountPass: convertFromKopToRub(pass.paymentAmountPass),
  paymentAmountTax: convertFromKopToRub(pass.paymentAmountTax),
  act: pass.act ? actConvertDataFromServer(pass.act) : undefined
});

export const convertDataToServer = (pass: Pass) => ({
  ...pass,
  paymentAmount: convertFromRubToKop(pass.paymentAmount),
  paymentAmountPass: convertFromRubToKop(pass.paymentAmountPass),
  paymentAmountTax: convertFromRubToKop(pass.paymentAmountTax)
});

export const addPass = async (pass: Pass): Promise<Pass> => {
  const added = await fetchRequest.post('/pass', convertDataToServer(pass));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать пропуск');
};

export const updatePass = async (pass: Pass): Promise<Pass> => {
  const updated = await fetchRequest.put('/pass', convertDataToServer(pass));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить пропуск');
};
export const deletePass = async (id: number): Promise<Pass> => {
  const deleted = await fetchRequest.delete(`/pass/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить пропуск');
};

export const fetchPass = async (
  id: number,
  params: any = {}
): Promise<Pass> => {
  const pass = await fetchRequest.get(`/pass/${id}`, params);
  if (pass) return convertDataFromServer(pass);
  throw new Error('Не удалось получить пропуск');
};

export const fetchVehiclePasses = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Pass>> => {
  const passes = await fetchRequest.get(`/pass/vehicle/${vehicleId}`, {
    ...initialFetchListParams,
    ...params
  });
  if (passes) {
    return {
      ...passes,
      data: passes.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список пропусков');
};

export const fetchPasses = async (
  params: FetchListParams<Pass> = initialFetchListParams
): Promise<ListResponse<Pass>> => {
  const passList = await fetchRequest.get('/pass', {
    ...initialFetchListParams,
    ...params
  });
  if (passList) {
    return {
      ...passList,
      data: passList.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список пропусков');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<Pass>> => {
  const pass = await fetchRequest.put(
    `/pass/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (pass) {
    return {
      ...pass,
      data: pass.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за списоком пропусков');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/pass/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(`/pass/groupedList`, params);
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const printReport = async (
  params: PassFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/pass/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  groupedList,
  getTotalSum,
  updateAct,
  addPass,
  updatePass,
  deletePass,
  fetchPass,
  fetchVehiclePasses,
  fetchPasses,
  printReport
};
