// @flow
import React from 'react';

import type { Driver, Vehicle } from '../../../lib/types';
import { convertEmployeeToString } from '../../../lib/helpers';
import DriverSelect from '../../../components/selects/DriverSelect';
import type { DriverSelectProps } from '../../../components/selects/DriverSelect';
import DriverWarningIndicator from './DriverWarningIndicator';
import DriverInfoPopover from './DriverInfoPopover';

type Props = DriverSelectProps & {
  // ТС для которой проверяется соответствие водителей
  vehicle: ?Vehicle
};

/**
 * Селект водителей в инвентарной карточке ТС
 *
 * Выводит в списке предупреждающий маркер напротив водителей,
 * у которых квалификации несоответствуют для данной ТС
 */
const VehicleDriverSelect = ({ vehicle, ...selectProps }: Props) => (
  <DriverSelect
    {...selectProps}
    placeholder="Выберите водителя"
    renderOption={(driver: Driver, Option) => (
      <Option key={driver.id} value={driver.id}>
        {convertEmployeeToString(driver.employee)}
        <DriverWarningIndicator driver={driver} vehicle={vehicle} />
        <DriverInfoPopover driver={driver} />
      </Option>
    )}
  />
);

export default VehicleDriverSelect;
