// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Company } from './../../lib/types';
import { companyApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

export default (selectProps: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите компанию"
    fetch={async params => {
      return await companyApi.fetchCompanies({
        isArchived: false,
        isDeleted: false,
        ...params
      });
    }}
    fetchSingle={companyApi.fetchCompany}
    notFoundText="Юридические лица не найдены"
    renderOption={(company: Company, Option) => (
      <Option key={company.id} value={company.id}>
        {company.name}{' '}
        {(company.inn || company.kpp) &&
          `(${company.inn ? `ИНН: ${company.inn}; ` : ''} ${
            company.kpp ? `КПП: ${company.kpp}` : ''
          })`}
      </Option>
    )}
    {...selectProps}
  />
);
