// @flow

import React, { useState, useEffect } from 'react';
import Button from 'antd/lib/button';
import type { FormikProps } from 'formik';

import { Selects, Form } from '../../../components';
import { Table } from '../../../components/ant/table/Table';
import { Icon, Spoiler } from '../../../components/ui';
import type {
  MaintenanceRegulation,
  MaintenanceRegulationMaterial,
  MaintenanceRegulationOperation,
} from '../../../lib/types';
import { SectionTitle } from '../../../components/layout';
import { GridItem } from '../../../components/layout';
import { InputNumber } from '../../../components/inputs';
import {
  Content,
  StyledGrid,
  Operations,
  FooterSpoiler,
} from './TORegulationForm.elements';
import { withEmptyRow, showWorkEngineHours } from '../../../lib/helpers';
import { vehicleModelApi } from '../../../lib/api';

const { SparePartSelect, MaintenanceOperationGroupSelect } = Selects;

type FormProps = {
  onSubmit: Function,
  readOnly: boolean,
  vehicleModelRegulation: ?MaintenanceRegulation,
};

const TORegulationFormSpoiler = ({
  vehicleModelRegulation,
  readOnly,
  onSubmit,
}: FormProps) => {
  const [vehicleModel, setVehicleModel] = useState({});
  const getModel = async (id: number) => {
    try {
      const vehicleModel = await vehicleModelApi.fetchVehicleModel(id);
      setVehicleModel(vehicleModel);
    } catch (e) { }
  }
  useEffect(() => {
    if (vehicleModelRegulation?.vehicleModelId) {
      getModel(Number(vehicleModelRegulation.vehicleModelId));
    }
  }, [vehicleModelRegulation?.vehicleModelId]);
  return (
    <Form initialValues={vehicleModelRegulation} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps<MaintenanceRegulation>) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          values,
          dirty,
          isSubmitting,
        } = formikProps;
        return (
          <Spoiler
            label={values.maintenanceWorkType && values.maintenanceWorkType.name}
          >
            <form onSubmit={handleSubmit}>
              <Content>
                <StyledGrid gutter="16px">
                  <GridItem>
                    {showWorkEngineHours({ vehicleModel }) ? (
                      <FormField
                        label="Периодичность прохождения ТО, моточасы"
                        name="workEngineHours"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            disabled={readOnly}
                            decimalSeparator=","
                            step={0.1}
                            value={value}
                            name={name}
                            onChange={(value: number) => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    ) : (
                        <FormField
                          label="Периодичность прохождения ТО, км"
                          name="kilometrage"
                        >
                          {
                            ({ value, name }) => (
                              <InputNumber
                                disabled={readOnly}
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={(value: number) => setFieldValue(name, value)}
                                onBlur={handleBlur}
                              />
                            )}
                        </FormField>
                      )}
                  </GridItem>
                </StyledGrid>
                <SectionTitle>Материалы и запчасти</SectionTitle>
                <StyledGrid cols={1}>
                  <GridItem>
                    <FormField name="materials">
                      {({ name, value = [] }) => {
                        return (
                          <Table
                            pagination={false}
                            columns={[
                              {
                                title: 'Деталь',
                                dataIndex: 'sparePartId',
                                // width: 350,
                                render: (
                                  sparePartId: number,
                                  data: MaintenanceRegulationMaterial,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].sparePartId`}
                                    >
                                      {({ name, value: val }) => (
                                        <SparePartSelect
                                          value={val}
                                          name={name}
                                          disabled={readOnly}
                                          allVehicles
                                          vehicleModelId={values.vehicleModelId}
                                          onChange={(val: number) => {
                                            if (val === null || val === undefined) {
                                              const array = [...value];
                                              array.splice(index, 1);
                                              setFieldValue('materials', array);
                                            } else {
                                              setFieldValue(name, val);
                                            }
                                          }}
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                title: 'Количество',
                                dataIndex: 'count',
                                width: 120,
                                render: (
                                  count: number,
                                  data: MaintenanceRegulationMaterial,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].count`}
                                    >
                                      {({ name, value }) => (
                                        <InputNumber
                                          name={name}
                                          value={value}
                                          disabled={readOnly}
                                          onChange={(value: number) =>
                                            setFieldValue(name, value)
                                          }
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                key: 'materials',
                                width: 40,
                                render: (
                                  _,
                                  record: MaintenanceRegulationMaterial,
                                  index: number
                                ) => {
                                  return (
                                    !readOnly && (
                                      <Operations>
                                        <Icon
                                          type="x"
                                          onClick={() => {
                                            const array = [...value];
                                            array.splice(index, 1);
                                            setFieldValue(name, array);
                                          }}
                                          size={12}
                                        />
                                      </Operations>
                                    )
                                  );
                                },
                              },
                            ]}
                            dataSource={readOnly ? value : withEmptyRow(value)}
                          />
                        );
                      }}
                    </FormField>
                  </GridItem>
                </StyledGrid>
                <SectionTitle>Выполняемые работы</SectionTitle>
                <StyledGrid cols={1}>
                  <GridItem>
                    <FormField name="operations">
                      {({ name, value = [] }) => {
                        return (
                          <Table
                            pagination={false}
                            columns={[
                              {
                                title: 'Вид работ',
                                dataIndex: 'operationId',
                                render: (
                                  stockPartId: number,
                                  data: MaintenanceRegulationOperation,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].operationId`}
                                    >
                                      {({ name, value: val }) => (
                                        <MaintenanceOperationGroupSelect
                                          value={val}
                                          name={name}
                                          disabled={readOnly}
                                          onChange={(val: number) => {
                                            if (val === null || val === undefined) {
                                              const array = [...value];
                                              array.splice(index, 1);
                                              setFieldValue('operations', array);
                                            } else {
                                              setFieldValue(name, val);
                                            }
                                          }}
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                title: 'Количество',
                                dataIndex: 'count',
                                width: 120,
                                render: (
                                  count: number,
                                  data: MaintenanceRegulationOperation,
                                  index: number
                                ) => (
                                    <FormField
                                      style={{ marginBottom: 0 }}
                                      name={`${name}[${index}].count`}
                                    >
                                      {({ name, value }) => (
                                        <InputNumber
                                          disabled={readOnly}
                                          name={name}
                                          value={value}
                                          onChange={(value: number) =>
                                            setFieldValue(name, value)
                                          }
                                        />
                                      )}
                                    </FormField>
                                  ),
                              },
                              {
                                key: 'operations',
                                width: 40,
                                render: (
                                  _,
                                  record: MaintenanceRegulationOperation,
                                  index: number
                                ) => {
                                  return (
                                    !readOnly && (
                                      <Operations>
                                        <Icon
                                          type="x"
                                          onClick={() => {
                                            const array = [...value];
                                            array.splice(index, 1);
                                            setFieldValue(name, array);
                                          }}
                                          size={12}
                                        />
                                      </Operations>
                                    )
                                  );
                                },
                              },
                            ]}
                            dataSource={readOnly ? value : withEmptyRow(value)}
                          />
                        );
                      }}
                    </FormField>
                  </GridItem>
                </StyledGrid>
              </Content>
              {dirty && (
                <FooterSpoiler>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    data-cy="save"
                  >
                    Сохранить
                </Button>
                </FooterSpoiler>
              )}
            </form>
          </Spoiler>
        );
      }}
    </Form >
  )
};

export default TORegulationFormSpoiler;
