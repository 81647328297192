// @flow
import { SelectProps } from 'antd/lib/select';
import range from 'lodash/range';
import React from 'react';
import { Option, Select } from './../ant/Select';

const years = range(new Date().getFullYear(), 1979);

type State = {
  years: number[]
};

// Выпадающий список годов выпуска ТС
export default class YearIssueSelect extends React.Component<SelectProps,
  State> {
  state = {
    years: years,
  };

  handleSearch = (value: string) => {
    this.setState({
      years: years.filter(year => year.toString().includes(value)),
    });
  };

  render() {
    const { years } = this.state;
    return (
      <Select
        onSearch={this.handleSearch}
        showSearch
        placeholder="Выберите год выпуска ТС"
        allowClear
        {...this.props}
      >
        {years.map(year => (
          <Option value={year} key={year}>
            {year} год
          </Option>
        ))}
      </Select>
    );
  }
}
