// @flow
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { BusinessDay } from '../types';

/**
 * Получение списка дней
 */
export const fetchBusinessDays = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<BusinessDay>> =>
  await fetchRequest.get('/businessDay', {
    ...initialFetchListParams,
    ...params
  });

/**
 * Получение дня по идентификатору
 */
export const fetchBusinessDay = async (
  id: number,
  params: any = {}
): Promise<BusinessDay> => {
  const businessDay = await fetchRequest.get(`/businessDay/${id}`, params);
  if (businessDay) return businessDay;
  throw new Error('Не удалось день для производственного календаря');
};

/**
 * Удаление дня
 */
export const deleteBusinessDay = async (id: number): Promise<BusinessDay> => {
  const deleted = await fetchRequest.delete(`/businessDay/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить день для производственного календаря');
};

/**
 * Обновление дня
 */
export const updateBusinessDay = async (
  businessDay: BusinessDay
): Promise<BusinessDay> => {
  const updated = await fetchRequest.put('/businessDay', businessDay);
  if (updated) return updated;
  throw new Error('Не удалось обновить день для производственного календаря');
};

/**
 * Добавление дня
 */
export const addBusinessDay = async (
  businessDay: BusinessDay
): Promise<BusinessDay> => {
  const added = await fetchRequest.post('/businessDay', businessDay);
  if (added) return added;
  throw new Error('Не удалось добавить день для производственного календаря');
};

export default {
  fetchBusinessDays,
  fetchBusinessDay,
  deleteBusinessDay,
  updateBusinessDay,
  addBusinessDay
};
