import type { ExecutionBudgetGroupedList } from './../types';
import { convertFromKopToRub } from './../helpers';

export const convertDataFromServer = (
  groupedList: ExecutionBudgetGroupedList
): ExecutionBudgetGroupedList => ({
  ...groupedList,
  actSum: convertFromKopToRub(groupedList.actSum),
  factSum: convertFromKopToRub(groupedList.factSum)
});
