// @flow
import React from 'react';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { VehicleTaxMap } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import VehicleTaxRateSelect from '../../../components/selects/VehicleTaxRateSelect';
import VehicleTypeSelect from '../../../components/selects/VehicleTypeSelect';
import type { VehicleGroupAndType } from '../../../components/selects/VehicleTypeSelect';

export default (props: CommonFormProps<$Shape<VehicleTaxMap>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Наименование объекта налогообложения"
                  fast
                  required
                  name="vehicleTaxRateId"
                >
                  {({ name, value }) => (
                    <VehicleTaxRateSelect
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тип ТС" fast required name="vehicleType">
                  {({ name, value }) => (
                    <VehicleTypeSelect
                      resultAsObject
                      onChange={({ type }: VehicleGroupAndType) => {
                        setFieldValue(name, type);
                      }}
                      value={value}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
