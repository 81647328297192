// @flow
import type { MaintenanceCostAllocation } from './../types';
import CommonApi from './../commonApi';

const maintenanceCostAllocation = new CommonApi<MaintenanceCostAllocation>(
  'maintenanceCostAllocation',
  'Распределение затрат на ТО'
);

export default {
  ...maintenanceCostAllocation
};
