/* @flow */

import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import moment from 'moment';

import { Button, Popconfirm, DatePicker } from 'antd';
import notification from 'antd/lib/notification';

import { osagoMultiplier } from '../../../lib/api';
import type { FetchListParams } from '../../../lib/api';
import { osagoTransportType } from '../../../lib/enum';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
  formatDateTimeToISOString
} from '../../../lib/helpers';
import type {
  ListState,
  OsagoBranchMultiplier,
  UserAccess
} from './../../../lib/types';

import { Header, Section } from '../../../components/layout';
import { notificationLoading } from '../../../components/Notifications';
import { Icon, Operations, Table } from '../../../components/ui';
import { OrgUnitSelect, Selects } from '../../../components';

import { HeaderTabs } from '../components';

import { withUserAccess } from './../../withUserAccess';
import canAddOsagoMultiplier from './../accessRight';

const { OsagoTransportTypeSelect } = Selects;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[]
};
type State = ListState<OsagoBranchMultiplier> & {
  // Хранится значение для проверки пересечения до создания документа
  startDate: moment,
  orgUnitId: number,
  osagoType: string
};
class OsagoBranchMultiplierList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    startDate: moment.utc(new Date()).startOf('day')
  };
  oldValue = null;

  async componentDidMount() {
    this.fetchOsagoBranchMultiplier();
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        align: 'right',
        stopPropagation: true,
        width: 50,
        render: record => (
          <Operations>
            <Popconfirm
              overlayStyle={{
                zIndex: 2000
              }}
              placement="left"
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () =>
                await this.deleteBranchMultiplier(record.id)
              }
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        )
      });
    }
  }

  fetchOsagoBranchMultiplier = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    this.setState({ loading: true });
    const {
      data,
      totalCount
    } = await osagoMultiplier.fetchOsagoBranchMultiplier({
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  handleRowClick = (id: number) =>
    navigate(`/admin/osago-multiplier/branch/edit/${id}`);

  deleteBranchMultiplier = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await osagoMultiplier.deleteOsagoBranchMultiplier(id);
      await this.fetchOsagoBranchMultiplier(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      sorter: true,
      width: '130px',
      render: (startDate: string) =>
        formatDateTimeToString(startDate, 'DD.MM.YYYY')
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      sorter: true,
      width: '130px',
      render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName',
      sorter: true
    },
    {
      title: 'Тип ТС',
      dataIndex: 'osagoType',
      render: osagoType => osagoTransportType[osagoType]
    },
    {
      title: 'КТ',
      dataIndex: 'kt',
      sorter: true,
      width: 150
    }
  ];

  canAdd = () =>
    this.props.userAccess.some(access =>
      canAddOsagoMultiplier.includes(access)
    );

  onConfirmCheck = async () => {
    if (!this.state.orgUnitId || !this.state.osagoType) return;
    try {
      let fetchParams = {
        startDate: formatDateTimeToISOString({}, moment(this.state.startDate)),
        orgUnitId: this.state.orgUnitId,
        osagoType: this.state.osagoType
      };
      const res = await osagoMultiplier.fetchDateIntersectionMultiplier(
        'branch',
        fetchParams
      );
      if (!res) {
        navigate(`/admin/osago-multiplier/branch/new`, {
          state: fetchParams
        });
      } else {
        notification.warning({
          message: 'Ошибка',
          description:
            'Выбранная дата уже входит в существующий диапазон для выбранного филиала и типа транспорта!'
        });
      }
    } catch (error) {
      notification.warning({
        message: 'Ошибка',
        description: error.message
      });
    }
  };
  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      startDate,
      osagoType,
      orgUnitId
    } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Терр. коэф.</h1>}
          right={
            canAdd && (
              <Popconfirm
                title={
                  <>
                    <p>
                      Выберите филиал, тип транспорта и дату начала действия
                      коэффциента
                    </p>
                    <OrgUnitSelect
                      filter={{ onlyBranch: true }}
                      value={orgUnitId}
                      onSelect={(value, node) => {
                        this.setState({
                          orgUnitId: value
                        });
                      }}
                      data-cy="orgUnitSelect"
                    />
                    <OsagoTransportTypeSelect
                      value={osagoType}
                      onSelect={(value: string) => {
                        this.setState({ osagoType: value });
                      }}
                    />
                    <DatePicker
                      disabled={!(startDate && osagoType)}
                      format="DD.MM.YYYY"
                      value={startDate}
                      onChange={(value: Object, dateString: string) => {
                        this.setState({
                          startDate: value
                        });
                      }}
                    />
                  </>
                }
                onConfirm={this.onConfirmCheck}
              >
                <Link to="/admin/osago-multiplier/branch/new">
                  <Button type="primary">Создать</Button>
                </Link>
              </Popconfirm>
            )
          }
        />
        <HeaderTabs selectedTab="branch" />
        <Section>
          <Table
            onRow={record => ({
              onClick: () => (canAdd ? this.handleRowClick(record.id) : null)
            })}
            columns={this.columns}
            fetch={this.fetchOsagoBranchMultiplier}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(OsagoBranchMultiplierList);
