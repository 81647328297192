// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { DatePicker, RangePicker } from '../../../components/ant/DatePicker';
import { GridItem } from '../../../components/layout';
import { notificationLoading } from '../../../components/Notifications';
import { ownerTypes, STSEnum, vehicleStatusEnum } from '../../../lib/enum';
import {
  formatDateTimeToISOString,
  getPathWithHistoryParams,
  getValueObject,
  isEmptyValue,
  navigate,
} from '../../../lib/helpers';

import type { Vehicle, VehicleStatus, VehicleTaxPlan, VehicleTaxPlanVehicle } from '../../../lib/types';
import { OrgUnitSelect, Selects } from './../../../components';
import Field from './../../../components/card/Field';

import { Grid, Header, Section, SectionTitle, TopPanel } from './../../../components/layout';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { vehicleApi, vehicleTaxCalculationApi, vehicleTaxPlanApi } from './../../../lib/api';
import ModalPlannedVehicle from './../vehicle/VehicleList/components/ModalPlannedVehicle';

import ModalVehicle from './../vehicle/VehicleList/components/ModalVehicle';

import InnerTable from './components/InnerTable';

export type VehicleTypeData = 'selfVehicles' | 'plannedVehicles';

type VehicleParams = {
  data: VehicleTaxPlanVehicle[],
  loading: boolean,
  modal: boolean
};
type Props = {
  location: Location & { state: { page: number } },
  vehicleTaxPlanId: ?$Shape<number>,
  selfVehicles: ?$Shape<Map<number, VehicleTaxPlanVehicle>>,
  plannedVehicles: ?$Shape<Map<number, VehicleTaxPlanVehicle>>
};
type State = {
  vehicleTaxPlan: ?$Shape<VehicleTaxPlan>,
  selfVehicles: VehicleParams,
  plannedVehicles: VehicleParams
};

const { BudgetVersionSelect } = Selects;
const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const VEHICLE_STATUS = [
  vehicleStatusEnum.draft,
  vehicleStatusEnum.working,
  vehicleStatusEnum.onAgreeing,
  vehicleStatusEnum.onAgreeing
];
const PLANNED_VEHICLE_STATUS = [vehicleStatusEnum.draft];
class BudgetVehicleTaxPlanForm extends Component<Props, State> {
  emptyVehicleParams = {
    data: [],
    loading: false,
    modal: false
  };

  state = {
    selfVehicles: { ...this.emptyVehicleParams },
    plannedVehicles: { ...this.emptyVehicleParams },
    vehicleTaxPlan: null
  };

  getContractVehicleTaxPlan = async (
    vehicleTaxPlanId: number
  ): Promise<?VehicleTaxPlan> => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting'
      });
      return await vehicleTaxPlanApi.get(vehicleTaxPlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('getting');
    }
  };

  fetch = (selectedType: VehicleTypeData) => async (
    status: VehicleStatus[]
  ) => {
    const { vehicleTaxPlan } = this.state;
    if (vehicleTaxPlan && vehicleTaxPlan.orgUnitId && vehicleTaxPlan.endDate) {
      this.onChangeVehicle(selectedType)('loading', true);
      const { orgUnitId, startDate, endDate } = vehicleTaxPlan;
      try {
        const { data } = await vehicleApi.getVehicles({
          nodeId: orgUnitId,
          startDate,
          endDate,
          status,
          page: undefined,
          pageSize: undefined,
          ownerType: ownerTypes.self,
          stsType:
            selectedType === 'selfVehicles'
              ? STSEnum.exploited
              : STSEnum.forBudget
        });
        this.onChangeVehicle(selectedType)('data', data);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      } finally {
        this.onChangeVehicle(selectedType)('loading', false);
      }
    }
  };

  onChangeVehicle = (selectedType: VehicleTypeData) => (
    key: string,
    value: any
  ) => {
    this.setState(prevState => ({
      // $FlowFixMe
      [selectedType]: {
        ...prevState[selectedType],
        [key]: value
      }
    }));
  };

  onChangeVehicleTaxPlan = async (key: string, value: any) => {
    await this.setState(
      prevState => {
        return {
          vehicleTaxPlan: {
            ...prevState.vehicleTaxPlan,
            [key]: value
          }
        };
      },
      () => {
        if (key === 'orgUnitId' || key === 'endDate') {
          // сбрасываем выбранные ТС
          this.setState(
            prevState => {
              return {
                vehicleTaxPlan: {
                  ...prevState.vehicleTaxPlan,
                  selfVehicles: [],
                  plannedVehicles: []
                },
                selfVehicles: { ...this.emptyVehicleParams },
                plannedVehicles: {
                  ...this.emptyVehicleParams
                }
              };
            },
            () => {
              if (value) {
                this.fetch('selfVehicles')(VEHICLE_STATUS);
                this.fetch('plannedVehicles')(PLANNED_VEHICLE_STATUS);
              }
            }
          );
        }
      }
    );
  };

  validation = (vehicleTaxPlan: VehicleTaxPlan) => {
    const fields = {
      startDate: 'дату начала планируемого периода',
      endDate: 'дату окончания планируемого периода',
      date: 'дату формирования',
      orgUnitId: 'филиал',
      budgetVersionId: 'версию бюджета',
      selfVehicles: 'ТС согласно инвентарной картотеке'
    };
    Object.keys(fields).forEach(field => {
      if (isEmptyValue(vehicleTaxPlan[field]))
        throw new Error(`Выберите ${fields[field]}`);
    });
  };

  handleVehicleTaxPlan = async () => {
    let { vehicleTaxPlan } = this.state;
    const { selfVehicles, plannedVehicles } = this.props;
    if (vehicleTaxPlan) {
      try {
        vehicleTaxPlan.selfVehicles = selfVehicles
          ? Array.from(selfVehicles.values())
          : [];
        vehicleTaxPlan.plannedVehicles = plannedVehicles
          ? Array.from(plannedVehicles.values())
          : [];
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving'
        });
        this.validation(vehicleTaxPlan);
        vehicleTaxPlan = await vehicleTaxPlanApi.add(vehicleTaxPlan);
        await vehicleTaxCalculationApi.calculate(vehicleTaxPlan.id);
        if (vehicleTaxPlan.id) {
          navigate(`/budget/tax/${vehicleTaxPlan.id}/card`);
        }
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  hideModal = (selectedType: VehicleTypeData) => () => {
    this.onChangeVehicle(selectedType)('modal', false);
  };

  addVehicle = async (vehicle: Vehicle) => {
    const { selfVehicles } = this.state;
    if (
      selfVehicles.data.findIndex(self => {
        return self.vehicleId === vehicle.id;
      }) === -1
    ) {
      this.onChangeVehicle('selfVehicles')('data', [
        ...selfVehicles.data,
        { vehicle, vehicleId: vehicle.id }
      ]);
    }
    this.hideModal('selfVehicles')();
  };

  addNewVehicle = async (vehicle: Vehicle) => {
    this.hideModal('plannedVehicles')();
    this.onChangeVehicle('plannedVehicles')('data', [
      ...this.state.plannedVehicles.data,
      { vehicle, vehicleId: vehicle.id }
    ]);
  };

  render() {
    const { vehicleTaxPlan, selfVehicles, plannedVehicles } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/budget/tax')}>Налог</Crumb>
              <Crumb>Налог СТС</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Планируемый перечень ТС</h1>
        </TopPanel>

        <Section>
          <SectionTitle divider>Период и версия бюджета</SectionTitle>
          <Content>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <Field label="Планируемый период">
                  <RangePicker
                    size="small"
                    format="DD.MM.YYYY"
                    placeholder={['Начало', 'Конец']}
                    value={[
                      vehicleTaxPlan?.startDate
                        ? moment.utc(vehicleTaxPlan.startDate)
                        : null,
                      vehicleTaxPlan?.endDate
                        ? moment.utc(vehicleTaxPlan.endDate)
                        : null
                    ]}
                    onChange={(value, dateString) => {
                      const [startDate, endDate] = value;
                      const [startDateString, endDateString] = dateString;
                      this.onChangeVehicleTaxPlan(
                        'startDate',
                        formatDateTimeToISOString(startDate, startDateString)
                      );
                      this.onChangeVehicleTaxPlan(
                        'endDate',
                        formatDateTimeToISOString(endDate, endDateString)
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Версия бюджета">
                  <BudgetVersionSelect
                    size="small"
                    filter={{ IsAvailableForSelect: true }}
                    value={getValueObject(vehicleTaxPlan, 'budgetVersionId')}
                    onChange={(budgetVersionId: number) => {
                      this.onChangeVehicleTaxPlan(
                        'budgetVersionId',
                        budgetVersionId
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата формирования">
                  <DatePicker
                    size="small"
                    format="DD.MM.YYYY"
                    value={
                      vehicleTaxPlan?.date ? moment(vehicleTaxPlan.date) : null
                    }
                    onChange={(value: string) =>
                      this.onChangeVehicleTaxPlan('date', value)
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Филилал">
                  <OrgUnitSelect
                    onlyBranches
                    size="small"
                    value={getValueObject(vehicleTaxPlan, 'orgUnitId')}
                    onChange={orgUnitId =>
                      this.onChangeVehicleTaxPlan('orgUnitId', orgUnitId)
                    }
                  />
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    this.onChangeVehicle('selfVehicles')('modal', true)
                  }
                  disabled={!vehicleTaxPlan?.orgUnitId}
                >
                  Добавить ТС
                </Button>
              </>
            }
          >
            ТС согласно инвентарной картотеке
          </SectionTitle>
          <Content>
            <InnerTable
              key={'selfVehicles'}
              vehiclePlanType="selfVehicles"
              data={selfVehicles.data.map(value => {
                return {
                  ...value,
                  plannedWriteoffDate: value.vehicle.plannedWriteoffDate
                };
              })}
              loading={selfVehicles.loading}
              onChange={this.onChangeVehicleTaxPlan}
              selectedVehicle={vehicleTaxPlan?.selfVehicles}
            />
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    this.onChangeVehicle('plannedVehicles')('modal', true)
                  }
                  disabled={!vehicleTaxPlan?.orgUnitId}
                >
                  Добавить новый ТС
                </Button>
              </>
            }
          >
            ТС согласно плану обновления
          </SectionTitle>
          <Content>
            <InnerTable
              key={'plannedVehicles'}
              vehiclePlanType="plannedVehicles"
              data={plannedVehicles.data.map(value => {
                return {
                  ...value,
                  plannedPurchaseDate: value.vehicle.plannedPurchaseDate
                };
              })}
              loading={plannedVehicles.loading}
              onChange={this.onChangeVehicleTaxPlan}
              selectedVehicle={vehicleTaxPlan?.plannedVehicles}
            />
          </Content>
        </Section>

        {
          <>
            <ModalVehicle
              visible={selfVehicles.modal}
              onCancel={this.hideModal('selfVehicles')}
              addVehicle={this.addVehicle}
              orgUnitId={vehicleTaxPlan?.orgUnitId}
            />
            <ModalPlannedVehicle
              visible={plannedVehicles.modal}
              onCancel={this.hideModal('plannedVehicles')}
              addVehicle={this.addNewVehicle}
              orgUnitId={vehicleTaxPlan?.orgUnitId}
            />
          </>
        }
        <Footer>
          <Button onClick={this.handleVehicleTaxPlan} type="primary">
            Сформировать
          </Button>
        </Footer>
      </>
    );
  }
}

export default connect(state => ({
  selfVehicles: state.vehicleTaxPlan.selfVehicles,
  plannedVehicles: state.vehicleTaxPlan.plannedVehicles
}))(BudgetVehicleTaxPlanForm);
