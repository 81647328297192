// @flow
import { fetchRequest } from './index';
import type { WashingCalculationMonth } from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataToServer = (
  washingCalculationMonth: WashingCalculationMonth
): WashingCalculationMonth => ({
  ...washingCalculationMonth,
  sum: convertFromRubToKop(washingCalculationMonth.cost)
});

export const convertDataFromServer = (
  washingCalculationMonth: WashingCalculationMonth
): WashingCalculationMonth => ({
  ...washingCalculationMonth,
  sum: convertFromKopToRub(washingCalculationMonth.cost)
});

export const updateCalculations = async (
  data: WashingCalculationMonth
): Promise<WashingCalculationMonth> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculation`,
    data
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const basedUrl = 'washingCalculationMonth';
const washingCalculationMonth = new CommonApi<WashingCalculationMonth>(
  basedUrl,
  'расчет потребности в мойке ТС',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...washingCalculationMonth,
  updateCalculations
};
