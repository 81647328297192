// @flow

import type { Dispatch } from 'redux';

import type { MaintenanceOperation } from './../lib/types';
import { maintenanceOperationApi } from '../lib/api';
import type { Action } from './redux';

export const CLEAR_MAINTENANCE_OPERATION =
  'vehicles/maintenance-operation/clear';
export const SET_MAINTENANCE_OPERATION = 'vehicles/maintenance-operation/set';

const initialState: ?MaintenanceOperation = null;

const reducer = (
  state: ?MaintenanceOperation = initialState,
  { type, payload }: Action
): ?MaintenanceOperation => {
  switch (type) {
    case SET_MAINTENANCE_OPERATION:
      return payload;
    case CLEAR_MAINTENANCE_OPERATION:
      return initialState;
    default:
      return state;
  }
};

export const clear = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_OPERATION
  });

export const addMaintenanceOperation: Function = (
  maintenanceOperation: MaintenanceOperation,
  groupId: number
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const addedMaintenanceOperation = await maintenanceOperationApi.addMaintenanceOperation(
    {
      ...maintenanceOperation,
      groupId
    }
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION,
    payload: addedMaintenanceOperation
  });
};

export const updateMaintenanceOperation: Function = (
  maintenanceOperation: MaintenanceOperation
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const updatedMaintenanceOperation = await maintenanceOperationApi.updateMaintenanceOperation(
    maintenanceOperation
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION,
    payload: updatedMaintenanceOperation
  });
};

export const fetchMaintenanceOperation: Function = (
  id: number
): Function => async (dispatch: Dispatch): Promise<void> => {
  const maintenanceOperation = await maintenanceOperationApi.fetchMaintenanceOperation(
    id
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION,
    payload: maintenanceOperation
  });
};

export default reducer;
