import AdvantumHack from '../components/AdvantumHack';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: AdvantumHack,
        path: '/distance-tracking',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.monitoring,
        ],
    },
];