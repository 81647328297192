// @flow
import { store } from '../../../App';
import createSections from './sections';
import RequestsController from './requests';
import * as ringle from 'berish-ringle';

class WialonApi {
  sections: any;
  controller: RequestsController;

  constructor() {
    const { accessToken } = store.getState().wialonStore;
    this.controller = new RequestsController(
      (process.env.REACT_APP_WIALON_API: any),
      accessToken
    );
    this.sections = createSections(this.controller);
  }

  async login() {
    await this.controller.login();
  }

  async logout() {
    await this.controller.logout();
  }
}

// Возвращает синглтон между импортами
export default () => ringle.getSingleton(WialonApi);
