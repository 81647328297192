// @flow
import React from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import type { Vehicle } from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Form from '../../../components/Form';
import { formatDateTimeToISOString as changeDate } from './../../../lib/helpers';
import { Selects } from '../../../components';
import OsagoInput from '../../../components/inputs/masked-inputs/OsagoInput';
import { notificationLoading } from './../../../components/Notifications';
import { canShowDiagnosticCard } from './../lib';
import CancelButton from '../../../components/CancelButton';
import Uploader from '../components/Uploader';
import { fileTagEnum } from '../../../lib/enum';
import ContractorSelect from '../../../components/selects/ContractorSelect';

const { InsuranceClassSelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class Osago extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/insurance`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/insurance`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {} } = this.props;
    return (
      <>
        <Header left={vehicle && <h1>ОСАГО {vehicle.licensePlate}</h1>} />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              values,
              dirty
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Номер полиса ОСАГО"
                        required
                        name="osago.osagoNumber"
                      >
                        {field => <OsagoInput {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Скан документа" name="files">
                        {({ value = [], name }) => {
                          const preparedValue = orderBy(
                            value.filter(v => v.tag === fileTagEnum.osago),
                            'createdTime',
                            ['desc']
                          ).slice(0, 1);
                          return (
                            <Uploader
                              tag={fileTagEnum.osago}
                              value={preparedValue}
                              onChange={files => {
                                setFieldValue(name, [
                                  ...files,
                                  ...vehicle.files
                                ]);
                              }}
                            />
                          );
                        }}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Наименование страховщика"
                        required
                        name="osago.contractorId"
                      >
                        {({ value, name }) => (
                          <ContractorSelect
                            value={value}
                            name={name}
                            onChange={(id: number) => setFieldValue(name, id)}
                            placeholder="Введите страховщика"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {canShowDiagnosticCard(
                      values.vehicleModel && values.vehicleModel.type
                    ) && (
                      <GridItem>
                        <FormField
                          label="Диагностическая карта (серия, номер)"
                          name="osago.diagnosticCardNumber"
                        >
                          {fields => (
                            <Input
                              {...fields}
                              placeholder="Введите диагностическую карту"
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <Grid gutter="16px">
                        <GridItem>
                          <FormField
                            label="Дата начала действия"
                            required
                            name="osago.osagoStartedDate"
                          >
                            {({ value, name }) => (
                              <DatePicker
                                format="DD.MM.YYYY"
                                value={this.formatDate(value)}
                                onChange={(
                                  value: Object,
                                  dateString: string
                                ) => {
                                  setFieldValue(
                                    name,
                                    changeDate(value, dateString)
                                  );
                                }}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            label="Дата окончания действия"
                            required
                            name="osago.osagoFinishedDate"
                          >
                            {({ value, name }) => (
                              <DatePicker
                                format="DD.MM.YYYY"
                                value={this.formatDate(value)}
                                name={name}
                                onChange={(
                                  value: Object,
                                  dateString: string
                                ) => {
                                  setFieldValue(
                                    name,
                                    changeDate(value, dateString)
                                  );
                                }}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      </Grid>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="osago.insuranceClass"
                        label="Класс на начало годового срока страхования"
                      >
                        {({ value, name }) => (
                          <InsuranceClassSelect
                            name={name}
                            value={value || undefined}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={() => handleBlur({ target: { name } })}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="osago.insuranceCasesCount"
                        label="Количество страховых случаев, по которым выплачена страховка"
                      >
                        {({ value, name }) => (
                          <Input
                            value={value}
                            name={name}
                            onChange={handleChange}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(Osago);
