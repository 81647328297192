// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';

import type {
  ContractVehicleBudget,
  AssignmentLimit,
  EntityStatusType,
  UserAccess
} from './../../../../../../lib/types';
import { entityStatus, entityStatusEnum } from './../../../../../../lib/enum';
import {
  contractVehicleBudgetApi,
  assignmentLimitApi
} from './../../../../../../lib/api';
import { formatRub } from './../../../../../../lib/helpers';

import {
  Header,
  TopPanel,
  Section,
  SectionTitle,
  Grid
} from './../../../../../../components/layout';
import { GridItem } from './../../../../../../components/layout/Grid';
import Breadcrumbs, {
  Crumb
} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';
import ListTable from './../../../../../../components/ui/ListTable';
import { notificationLoading } from './../../../../../../components/Notifications';

import { withUserAccess } from './../../../../../withUserAccess';

import Tabs from './../components/Tabs';

import { approveAccessRightContractVehicleBudget } from './../../accessRight';

import type { FixedRegistBudgetBudgetFilterParams } from './components/Filter';
import Filter from './components/Filter';
import { listHeaderBudgetList, listRowBudgetList } from './elements';

type Props = {
  contractVehiclePlanId: number,
  userAccess: UserAccess[]
};
type State = {
  filter: FixedRegistBudgetBudgetFilterParams,
  contractVehicleBudget: ?ContractVehicleBudget,
  assignmentLimits: AssignmentLimit[],
  loading: boolean
};

const Content = styled.div`
  padding: 16px;
`;
const StyledSpan = styled.div`
  color: #054dd9;
  cursor: pointer;
`;
class FixedRegistrBudgetBudgetList extends Component<Props, State> {
  state = {
    filter: {},
    contractVehicleBudget: null,
    assignmentLimits: [],
    loading: false
  };

  componentDidMount() {
    this.get();
  }

  apllyFilter = (filter: FixedRegistBudgetBudgetFilterParams) => {
    this.setState({ filter }, this.fetchAssignLimit);
  };

  cleanFilter = () => {
    this.setState({ filter: {} }, this.fetchAssignLimit);
  };

  get = async () => {
    const { contractVehiclePlanId } = this.props;
    try {
      const contractVehicleBudget = await contractVehicleBudgetApi.getByContractVehiclePlan(
        contractVehiclePlanId
      );
      this.setState({ contractVehicleBudget }, this.fetchAssignLimit);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  fetchAssignLimit = async () => {
    const { contractVehiclePlanId } = this.props;
    const { filter } = this.state;
    this.setState({ loading: true });
    try {
      const { data } = await assignmentLimitApi.fetch({
        ...filter,
        'assignmentLimitGroup.assignment.contractVehiclePlanId': contractVehiclePlanId,
        page: 0
      });
      this.setState({ assignmentLimits: data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeStatus = (status: EntityStatusType) => async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.state.contractVehicleBudget) {
        const contractVehicleBudget = await contractVehicleBudgetApi.update({
          ...this.state.contractVehicleBudget,
          status
        });
        this.setState({ contractVehicleBudget });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  canApproved = () =>
    this.props.userAccess.some(access =>
      approveAccessRightContractVehicleBudget.includes(access)
    );

  render() {
    const { contractVehiclePlanId } = this.props;
    const {
      filter,
      contractVehicleBudget,
      assignmentLimits,
      loading
    } = this.state;
    if (contractVehicleBudget === null) {
      return null;
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/contract-vehicle/fixed">
                Закрепленные НТС
              </Crumb>
              <Crumb>Бюджет</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Расчет бюджета на закрепленные НТС</h1>
        </TopPanel>
        <Tabs
          contractVehiclePlanId={contractVehiclePlanId}
          assignmentApproved={true}
        />
        {contractVehicleBudget && (
          <>
            <Section>
              <SectionTitle divider>
                Расчет бюджета по направления расходов
              </SectionTitle>
              <Content>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <Field label="Услуги легкового транспорта, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                              0}/expense-direction/car-services`
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.carServicesCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Прочие транспортные расходы, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                              0}/expense-direction/other-vehicle-services`
                          )
                        }
                      >
                        {formatRub(
                          contractVehicleBudget.otherVehicleServicesCost
                        )}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Услуги автотраспорта для ОКС, без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                              0}/expense-direction/acs-vehicle-services`
                          )
                        }
                      >
                        {formatRub(
                          contractVehicleBudget.acsVehicleServicesCost
                        )}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Услуги а/т со стороны по транспортировке грузов, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                              0}/expense-direction/cargo-services`
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.cargoServicesCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Услуги механизмов, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                              0}/expense-direction/mechanism-services`
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.mechanismServicesCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Текущий статус">
                      <StyledSpan>
                        {entityStatus[contractVehicleBudget.status]}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            <Section>
              <Content>
                <Filter
                  filter={filter}
                  applyFilter={this.apllyFilter}
                  cleanFilter={this.cleanFilter}
                />
              </Content>
              <ListTable
                isLoading={loading}
                style={{ overflowX: 'auto' }}
                styleContent={{ width: '2500px' }}
                data={assignmentLimits}
                header={listHeaderBudgetList()}
                renderRow={listRowBudgetList}
              />
            </Section>

            {contractVehicleBudget &&
              this.canApproved() &&
              [
                entityStatusEnum.created,
                entityStatusEnum.approvement,
                entityStatusEnum.editing
              ].includes(contractVehicleBudget.status) && (
                <Section>
                  <Content>
                    {[
                      entityStatusEnum.created,
                      entityStatusEnum.editing
                    ].includes(contractVehicleBudget.status) && (
                      <Button
                        type="primary"
                        onClick={this.handleChangeStatus(
                          entityStatusEnum.approvement
                        )}
                      >
                        На согласование
                      </Button>
                    )}
                    {entityStatusEnum.approvement ===
                      contractVehicleBudget.status && (
                      <>
                        <Button
                          type="primary"
                          onClick={this.handleChangeStatus(
                            entityStatusEnum.approved
                          )}
                          style={{ marginRight: '10px' }}
                        >
                          Утвердить
                        </Button>
                        <Button
                          onClick={this.handleChangeStatus(
                            entityStatusEnum.declined
                          )}
                          style={{ marginRight: '10px' }}
                        >
                          Отклонить
                        </Button>
                      </>
                    )}
                  </Content>
                </Section>
              )}
          </>
        )}
      </>
    );
  }
}

export default withUserAccess(FixedRegistrBudgetBudgetList);
