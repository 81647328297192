// @flow

import type { MaintenanceOperationGroup } from './../types';

import { fetchRequest } from './index';
// import type { FetchListParams } from './index';

export const addMaintenanceOperationGroup = async (
  maintenanceOperationGroup: MaintenanceOperationGroup
): Promise<MaintenanceOperationGroup> => {
  const added = await fetchRequest.post(
    '/maintenanceOperation',
    maintenanceOperationGroup
  );
  if (added) return added;
  throw new Error('Не удалось создать группу работ');
};

export const updateMaintenanceOperationGroup = async (
  maintenanceOperationGroup: MaintenanceOperationGroup
): Promise<MaintenanceOperationGroup> => {
  const updated = await fetchRequest.put(
    '/maintenanceOperation',
    maintenanceOperationGroup
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить группу работ');
};

export const fetchMaintenanceOperationGroups = async (
  params: any = {}
): Promise<MaintenanceOperationGroup> =>
  await fetchRequest.get('/maintenanceOperation', params);

export const fetchMaintenanceOperationGroup = async (
  id: number,
  params: any = {}
): Promise<MaintenanceOperationGroup> =>
  await fetchRequest.get(`/maintenanceOperation/${id}`, params);

export const deleteMaintenanceOperationGroup = async (
  id: number
): Promise<MaintenanceOperationGroup> => {
  const deleted = await fetchRequest.delete(`/maintenanceOperation/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить группу работ');
};

export default {
  fetchMaintenanceOperationGroups,
  addMaintenanceOperationGroup,
  fetchMaintenanceOperationGroup,
  updateMaintenanceOperationGroup,
  deleteMaintenanceOperationGroup
};
