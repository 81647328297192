// @flow
import React from 'react';
import moment from 'moment';

import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { navigate } from '@reach/router';
import { businessCalendarForBudgetApi } from '../../../lib/api';

const entryPointPath = '/admin/the-business-calendar-for-budget/';

const columns = [
  {
    title: 'Год',
    dataIndex: 'year'
  },
  {
    title: 'Месяц',
    dataIndex: 'month',
    render: month => moment(month + 1, 'M').format('MMMM')
  },
  {
    title: 'Количество дней в месяце',
    dataIndex: 'daysInMonth'
  },
  {
    title: 'Количество рабочих дней в месяце',
    dataIndex: 'workingDaysInMonth'
  },
  {
    title: 'Количество рабочих часов в месяце',
    dataIndex: 'workingHoursInMonth'
  },
  {
    title: 'Количество выходных дней в месяце',
    dataIndex: 'dayOffsInMonth'
  }
];

const breadCrumbs = [
  { to: '/', name: 'Главная' },
  { name: 'Справочники' },
  { name: 'Производственный календарь для бюджета' }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default () => {
  const canAdd = () => true;

  return (
    <Page>
      <CommonListPage
        crud={businessCalendarForBudgetApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Производственный календарь для бюджета',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canAdd()
        }}
      />
    </Page>
  );
};
