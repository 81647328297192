// @flow
import type { CurrentMaintenanceCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (
  currentMaintenanceCost: CurrentMaintenanceCost
) => ({
  ...currentMaintenanceCost,
  materialExpenseRate: convertFromRubToKop(
    currentMaintenanceCost.materialExpenseRate
  ),
  materialExpenseRateWithCoefficient: convertFromRubToKop(
    currentMaintenanceCost.materialExpenseRateWithCoefficient
  )
});

const convertDataFromServer = (
  currentMaintenanceCost: CurrentMaintenanceCost
) => ({
  ...currentMaintenanceCost,
  materialExpenseRate: convertFromKopToRub(
    currentMaintenanceCost.materialExpenseRate
  ),
  materialExpenseRateWithCoefficient: convertFromKopToRub(
    currentMaintenanceCost.materialExpenseRateWithCoefficient
  )
});

const currentMaintenanceCost = new CommonApi<CurrentMaintenanceCost>(
  'currentMaintenanceCost',
  'Стоимость текущего ремонта',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...currentMaintenanceCost
};
