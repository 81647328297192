// @flow
import React from 'react';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from './../../../../../../../components';
import type { FilterChildrenParams } from './../../../../../../../components/hoc/Filter';
import Field from './../../../../../../../components/card/Field';

const { VehicleModelSelect, VehicleSelect, YearSelect } = Selects;

export type FixedVehicleListFilterParams = {|
  'vehicle.vehicleModel.id'?: number,
  'vehicle.id'?: number,
  'vehicle.yearIssued'?: number
|};

type Props = {
  filter: FixedVehicleListFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <FilterContainer style={{ marginBottom: '16px' }}>
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter
      }: FilterChildrenParams<FixedVehicleListFilterParams>) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleModelSelect
                placeholder="Все модели ТС"
                size="small"
                value={values['vehicle.vehicleModel.id']}
                onChange={value =>
                  changeValue('vehicle.vehicleModel.id', value)
                }
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleSelect
                placeholder="Все ТС"
                size="small"
                value={values['vehicle.id']}
                onChange={value => changeValue('vehicle.id', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <YearSelect
                placeholder="Год"
                size="small"
                value={values['vehicle.yearIssued']}
                onChange={value => changeValue('vehicle.yearIssued', value)}
              />
            </Field>
          </FilterItem>

          <FilterButtonsContainer
            applyFilter={applyFilter}
            cleanFilter={cleanFilter}
          />
        </>
      )}
    </Filter>
  </FilterContainer>
);
