// @flow
import Button from 'antd/lib/button';
import React from 'react';
import styled from 'styled-components';

import { Card, Filter, Selects } from '../../../components';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { Spoiler } from '../../../components/ui';

import { ownerTypes } from '../../../lib/enum';
import { applyMaskToValue } from '../../../lib/helpers';
import type { NodeFilterType, Vehicle } from '../../../lib/types';

const { BrandsSelect, VehicleSelect } = Selects;
const { Field } = Card;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type OptionalEquipmentFilterParams = {|
  code?: string,
  'brand.id'?: number,
  isFree?: boolean,
  orgUnitId?: number,
  vehicleId?: number,
  nodeFilterType?: NodeFilterType,
|};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: OptionalEquipmentFilterParams,
};

const OptionalEquipmentFilter = ({
  filter,
  cleanFilter,
  applyFilter,
}: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter,
          }: FilterChildrenParams<OptionalEquipmentFilterParams>) => (
            <>
              <GridItem>
                <Field label="Марка">
                  <BrandsSelect
                    onChange={(value) => changeValue('brand.id', value)}
                    value={values['brand.id']}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Гос. номер">
                  <VehicleSelect
                    placeholder="Все ТС"
                    value={values.vehicleId}
                    optionTitle={(vehicle: Vehicle) =>
                      applyMaskToValue(
                        vehicle.licensePlate,
                        formatLicensePlateMask
                      )
                    }
                    filter={{
                      ownerType: ownerTypes.self,
                    }}
                    onChange={(value) => changeValue('vehicleId', value)}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default OptionalEquipmentFilter;
