// @flow
import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';

import type { Osago, UserAccess } from '../../../lib/types';
import { osagoApi } from '../../../lib/api';
import { insuranceClasses } from '../../../lib/enum';
import {
  applyMaskToValue,
  convertContractorToString,
  convertVehicleToString,
  formatDateRangeString
} from '../../../lib/helpers';

import type { AppState } from '../../../ducks/redux';

import { Header, SectionTitle } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { Icon } from '../../../components/ui';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { formatOsagoMask } from '../../../components/inputs/masked-inputs/OsagoInput';

import { ActInfo } from './../../Act';

import { Panel, SectionContent } from '../elements';
import { canHandleService } from '../lib';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  osago: Osago
};

class OsagoCard extends React.Component<Props, State> {
  state = {
    osago: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const osago = await osagoApi.fetchOsago(parseInt(id, 10));
      this.setState({ osago });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { osago } = this.state;

    const canHandle = canHandleService(userAccess);
    const osagoNumber = applyMaskToValue(osago.osagoNumber, formatOsagoMask);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={'/services/osagos'}>Список ОСАГО</Crumb>
              <Crumb>ОСАГО {osagoNumber}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() => navigate(`/services/osagos/${id}/edit`)}
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>ОСАГО {osagoNumber}</h1>
        </Panel>
        <Section>
          <SectionTitle divider>Данные ТС</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              {!!osago.vehicle ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(osago.vehicle)}
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Данные по ОСАГО</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              {!!osagoNumber ? (
                <GridItem>
                  <Field label="Серия и номер ОСАГО">{osagoNumber}</Field>
                </GridItem>
              ) : null}

              <GridItem>
                <Field label="Период действия ОСАГО">
                  {formatDateRangeString(
                    osago.osagoStartedDate,
                    osago.osagoFinishedDate,
                    'DD.MM.YYYY'
                  )}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Наименование страховщика">
                  {convertContractorToString(osago.contractor)}
                </Field>
              </GridItem>

              {!!osago.insuranceClass && (
                <GridItem>
                  <Field label="Класс на начало годового срока страхования">
                    {insuranceClasses[osago.insuranceClass]}
                  </Field>
                </GridItem>
              )}

              {!!osago.diagnosticCardNumber && (
                <GridItem>
                  <Field label="Серия и номер диагностической карты">
                    {osago.diagnosticCardNumber}
                  </Field>
                </GridItem>
              )}

              {!!osago.insuranceCasesCount && (
                <GridItem>
                  <Field label="Количество страховых случаев, по которым выплачена страховка">
                    {osago.insuranceCasesCount} шт
                  </Field>
                </GridItem>
              )}

              <GridItem>
                <Field label="Сумма оплаты, руб.">
                  {(osago.paymentAmount || 0).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                  })}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>

        {osago && osago.act && <ActInfo act={osago.act} />}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(OsagoCard);
