//@flow
import React, { useState } from 'react';
import * as yup from 'yup';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { CloseOutlined } from '@ant-design/icons';
import { FormikProps } from 'formik';
import moment from 'moment';
import styled from 'styled-components';

import { applyMaskToValue } from '../../../lib/helpers';
import type { BioTreatmentFacilityVolume } from '../../../lib/types';
import {
  licensePlatesValidate,
  licensePlateRegexp,
} from '../../../lib/validation';

import { InputNumber } from './../../../components/inputs';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { Form } from '../../../components';
import { Grid } from '../../../components/layout';
import { GridItem } from '../../../components/layout/Grid';
import { LicensePlateInput } from './../../../components/inputs/masked-inputs';

export const CloseIcon = styled(CloseOutlined)`
  cursor: pointer;
  font-size: 5px;
  background: #d9d9d9;
  border: 1px solid white;
  position: absolute;
  margin-top: -7px;
  margin-left: -2px;
  padding: 2px;
  border-radius: 50%;
`;
const TagContainer = styled.div`
  word-break: break-word;
`;
const Tag = styled.span`
  padding: 2px 5px;
  margin: 3px 2px;
  font-size: 12px;
  background: #d9d9d9;
  display: inline-block;
`;

type Props = {
  visible: boolean,
  onCancel: Function,
  onSubmit: Function,
  bioTreatmentFacilityVolume: ?BioTreatmentFacilityVolume,
};
export default (props: Props) => {
  const [licensePlate, setLicensePlate] = useState(null);
  const {
    visible,
    onCancel,
    onSubmit,
    bioTreatmentFacilityVolume = {},
  } = props;
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Форма внесения объемов"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="Enter" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" form="modal-form" htmlType="submit">
          Добавить
        </Button>,
      ]}
    >
      <Form
        initialValues={bioTreatmentFacilityVolume}
        onSubmit={onSubmit}
        validationSchema={yup.object().shape({
          licensePlates: licensePlatesValidate
            .min(1, 'Укажите гос.номер')
            .nullable(),
        })}
      >
        {(FormField, formikProps: FormikProps) => {
          const { setFieldValue, handleSubmit, values } = formikProps;
          return (
            <form onSubmit={handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Гос.номер(*нажмите Enter)"
                    name="licensePlates"
                    required
                  >
                    {({ value, name }) => (
                      <LicensePlateInput
                        placeholder="Добавьте номер и нажмите Enter"
                        value={licensePlate}
                        onChange={(e) => setLicensePlate(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                        onPressEnter={(e) => {
                          const licensePlates = Array.isArray(
                            values.licensePlates
                          )
                            ? values.licensePlates
                            : [];
                          const value = e.target.value
                            .replace(/\s+/g, '')
                            .replace(/_/g, '');
                          if (licensePlateRegexp().exec(value) !== null) {
                            setFieldValue(name, [...licensePlates, value]);
                            setLicensePlate(null);
                          }
                        }}
                      />
                    )}
                  </FormField>
                  <TagContainer>
                    {values.licensePlates?.map((licensePlate, index) => {
                      return (
                        <Tag>
                          {licensePlate}
                          <CloseIcon
                            onClick={() => {
                              const licensePlates = [...values.licensePlates];
                              licensePlates.splice(index, 1);
                              setFieldValue('licensePlates', [
                                ...licensePlates,
                              ]);
                            }}
                          />
                        </Tag>
                      );
                    })}
                  </TagContainer>
                </GridItem>

                <GridItem>
                  <FormField label="Объем, м3" name="volume" required>
                    {({ name, value }) => (
                      <InputNumber
                        fullWidth
                        placeholder="Введите объем"
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
