// @flow

import Button from 'antd/lib/button';
import Input from 'antd/lib/input/Input';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';
import CancelButton from '../../../../components/CancelButton';
import { InputNumber } from '../../../../components/inputs';
import { convertDateToString } from '../../../../containers/Vehicles/lib';

import type { Battery } from '../../../../lib/types';
import { Form, OrgUnitSelect } from './../../../../components';
import { Option, Select } from './../../../../components/ant/Select';
import { Section } from './../../../../components/layout';
import Grid, { GridItem } from './../../../../components/layout/Grid';

const capacities = [18, 55, 60, 66, 72, 77, 90, 100, 140, 190, 200];

const StyledInputNumber = styled(InputNumber)`
  width: 125px;
`;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type BlockedMdmFields = {
  capacity: boolean,
  voltage: boolean,
  name: boolean
};

type FormProps = {
  battery: $Shape<Battery>,
  onSubmit: Function,
  onCancel: Function,
  blockMdmFields: Function,
  blockedMdmFields: BlockedMdmFields,
  canEditOrgUnit: boolean,
  vehicleId: ?number
};

const InnerForm = ({
  onCancel,
  onSubmit,
  battery,
  blockedMdmFields,
  blockMdmFields,
  canEditOrgUnit,
  vehicleId
}: FormProps) => (
  <Form initialValues={battery} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        setFieldValue,
        handleBlur,
        dirty,
        isSubmitting
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {canEditOrgUnit && (
                  <GridItem>
                    <FormField
                      fast
                      label="Подразделение"
                      required
                      name="orgUnitId"
                    >
                      {({ value, name }) => (
                        <OrgUnitSelect
                          value={value || ''}
                          onChange={(orgUnitId: number) =>
                            setFieldValue(name, parseInt(orgUnitId, 10))
                          }
                          data-cy="orgUnitSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField label="Наименование" required name="name">
                    {field => (
                      <Input
                        {...field}
                        disabled={blockedMdmFields[field.name]}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Примечание" name="notation" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Заводской номер" name="factoryNumber" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка" name="brand">
                    {field => <Input {...field} data-cy="brandInput" />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="capacity"
                    label="Емкость батареи, А-ч"
                    required
                  >
                    {({ value, name }) => (
                      <Select
                        showSearch
                        optionFilterProp="children"
                        data-cy="capacitySelect"
                        value={value}
                        disabled={blockedMdmFields[name]}
                        onBlur={() => handleBlur({ target: { name } })}
                        onChange={(value: number) => setFieldValue(name, value)}
                        filterOption={(input, option) =>
                          `${option.props.children}`
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {capacities.map((value, index) => (
                          <Option key={index} value={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Напряжение (В)"
                    hasFeedback={false}
                    required
                    name="voltage"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        value={value}
                        disabled={blockedMdmFields[name]}
                        min={1}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Норматив эксплуатации, количество лет"
                    required
                    name="norm"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        disabled
                        value={value}
                        min={1}
                        max={100}
                        onChange={(value: number) =>
                          setFieldValue('norm', value)
                        }
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата выдачи" name="issuedDate" fast>
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                        // disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата установки"
                    name="installDate"
                    fast
                    required={!!vehicleId}
                  >
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                        // disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

export default InnerForm;
