//@flow
import React, { useCallback } from 'react';
import { Input } from 'antd';

import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../components/hoc/common/handbook/CommonFilter';

export type PddTicketParamsFilter = {
  search?: string
};

export const PddTicketFilter = (props: FilterProps<PddTicketParamsFilter>) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PddTicketParamsFilter>>) =>
      CommonFilter<PddTicketParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                value={values.search}
                onChange={e => changeValue('search', e.target.value)}
                placeholder="Наименование"
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PddTicketFilter;
