import { accessTypeEnum } from './../../../lib/enum';
import type { UserAccess } from './../../../lib/types';

export const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingSelfVehiclePlan
];

export const approvingAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingSelfVehiclePlan
];

export const approvingBudgetRepair = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingSelfVehiclePlanBudgetRepair
];

// VEH-1279
// добавление/копирование/редактирование плана перечня стс
export const canAdd = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

// утверждение предложения бюджета по ремонтам
export const canApprovingBudgetRepair = (userAccess: UserAccess[]) =>
  userAccess.some(access => approvingBudgetRepair.includes(access));

// редактирование вкладки кап. ремонта
export const canEditOverhaulRepair = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

// отправление на утверждение перечня стс
export const canApprovementVehiclePlan = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

// утверждение плана перечня стс и формирование предложения
export const canApprovingVehiclePlan = (userAccess: UserAccess[]) =>
  userAccess.some(access => approvingAccess.includes(access));

export default {
  canAdd,
  canApprovementVehiclePlan,
  canApprovingVehiclePlan,
  canEditOverhaulRepair,
  canApprovingBudgetRepair
};
