// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';

import Section from './../../components/layout/Section';
import type { AppState } from '../../ducks/redux';
import type { Journal } from '../../lib/types';
import { Table } from './../../components/ui';
import { navigate } from '@reach/router';
import { fetchJournals } from './../../ducks/journals';
import { formatDateTimeToString } from './../../lib/helpers';
import Filter from './components/Filter';
import { Header } from '../../components/layout';
import Button from 'antd/lib/button';
import { journalApi } from '../../lib/api';
import { notificationLoading } from '../../components/Notifications';

type Props = {
  journals: Journal[],
  fetchJournals: Function,
  totalCount: number,
  pageSize: number,
  page: number,
  location: any,
  filter: any
};

const DivEllipsis = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class JournalList extends Component<Props> {
  static defaultProps = {
    journals: []
  };

  columns = [
    {
      title: 'Дата создания',
      dataIndex: 'created',
      sorter: true,
      render: (created: Date) =>
        formatDateTimeToString(created, 'DD.MM.YYYY HH:mm', false)
    },
    {
      title: 'Пользователь',
      dataIndex: 'userName',
      sorter: true
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      sorter: true
    },
    {
      title: 'Событие',
      dataIndex: 'message',
      sorter: true,
      width: '500px',
      render: (message: string) => <DivEllipsis>{message}</DivEllipsis>
    }
  ];

  handleRowClick = (id: number) => {
    navigate(`/admin/journal/${id}`);
  };

  handlePrint = async () => {
    try {
      notificationLoading({
        key: 'print',
        message: 'Формирование файла для печати'
      });
      await journalApi.printJournal();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { journals, totalCount, pageSize, page, location } = this.props;
    return (
      <>
        <Header
          left={<h1>Журнал</h1>}
          right={
            parseInt(totalCount, 10) > 0 && (
              <Button type="primary" onClick={this.handlePrint}>
                Печать
              </Button>
            )
          }
        />
        <Section>
          <Filter />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.props.fetchJournals}
            columns={this.columns}
            data={journals}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      journals: state.journals.data,
      totalCount: state.journals.totalCount,
      pageSize: state.journals.pageSize,
      page: state.journals.page
    };
  },
  {
    fetchJournals
  }
)(JournalList);
