// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { pddTicketApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any, onFetched?: (data: any) => any };

export default ({
  filter = {},
  onFetched = () => {},
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      const data = await pddTicketApi.fetch({
        ...filter,
        ...params
      });
      await onFetched(data.data);
      return data;
    }}
    refetchParams={{ filter }}
    fetchSingle={pddTicketApi.get}
    notFoundText="Билеты ПДД не найдены"
    placeholder="Выберите билет из списка"
    renderOption={(pddTicket: any, Option) => (
      <Option key={pddTicket.id} value={pddTicket.id} ticket={pddTicket}>
        {pddTicket.idNumber}
      </Option>
    )}
    {...selectProps}
  />
);
