import {
    MaintenanceCostAllocationCard,
    MaintenanceCostAllocationForm,
    MaintenanceCostAllocationList,
} from '../../containers/MaintenanceCostAllocation';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MaintenanceCostAllocationList,
        path: '/admin/maintenance-cost-allocation',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MaintenanceCostAllocationCard,
        path: '/admin/maintenance-cost-allocation/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MaintenanceCostAllocationForm,
        path: '/admin/maintenance-cost-allocation/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MaintenanceCostAllocationForm,
        path: '/admin/maintenance-cost-allocation/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];