import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Title = styled.div`
  display: flex;
  justify-content: ${props => props.textPosition};
  font-size: 1.2em;
  font-weight: 900;
  color: #adb8c3;
`;

const Card = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.directionReverse ? 'column-reverse' : 'column'};
  align-items: stretch;
  background: white;
  border-radius: 0.25em;
  height: 100%;
  padding: 1em;
`;

type Props = {
  title: string,
  titlePosition: 'top' | 'bottom',
  textPosition: 'left' | 'center' | 'right',
  children?: ReactNode
};

export default ({
  titlePosition = 'top',
  textPosition = 'center',
  ...props
}: Props) => {
  return (
    <Card directionReverse={titlePosition === 'bottom'}>
      <Title
        directionReverse={titlePosition === 'bottom'}
        textPosition={textPosition}
      >
        {props.title}
      </Title>
      {props.children}
    </Card>
  );
};
