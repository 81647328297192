// @flow
import React from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';

import { Header, Section } from '../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import { Popconfirm, Icon, Operations } from './../../components/ui';
import Table from '../../components/ui/Table';

import { formatRub } from './../../lib/helpers';
import { sideServiceTariffApi } from '../../lib/api';
import {
  sideServiceType,
  accessTypeEnum,
  sideServiceTariffType,
} from '../../lib/enum';
import { formatDateTimeToString, getListInitialState } from '../../lib/helpers';
import type {
  SideServiceTariff,
  ListState,
  UserAccess,
  SideServiceType,
  SideServiceTariffType,
} from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

import type { SideServiceTariffFilterParams } from './components/Filter';
import Filter from './components/Filter';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<SideServiceTariff> & {
  filter: SideServiceTariffFilterParams,
  columns: Array<Object>,
};

export class SideServiceTariffList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: [
      {
        title: 'Подразделение',
        key: 'orgUnitName',
        dataIndex: 'orgUnitName',
      },
      {
        title: 'Тип услуги',
        key: 'sideServiceType',
        dataIndex: 'sideServiceType',
        render: (type: SideServiceType) => sideServiceType[type],
      },
      {
        title: 'Тип контракта услуги',
        key: 'sideServiceTariffType',
        dataIndex: 'sideServiceTariffType',
        render: (type: SideServiceTariffType) => sideServiceTariffType[type],
      },
      {
        title: 'Стоимость',
        dataIndex: 'price',
        width: '150',
        render: (price: number) => formatRub(price),
      },
      {
        title: 'Дата начала',
        dataIndex: 'startDate',
        width: '150',
        render: (date: string) =>
          date ? `${formatDateTimeToString(date, 'DD.MM.YYYY')}` : '-',
      },
      {
        title: 'Дата окончания',
        dataIndex: 'endDate',
        width: '150',
        render: (date: string) =>
          date ? `${formatDateTimeToString(date, 'DD.MM.YYYY')}` : '-',
      },
    ],
  };

  componentDidMount() {
    const { columns } = this.state;
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetch(page)
    );
    if (this.canAdd()) {
      this.setState({
        columns: [
          ...columns,
          {
            title: '',
            width: 80,
            dataIndex: 'operations',
            // eslint-disable-next-line no-unused-vars
            render: (text: string, sideServiceTariff: SideServiceTariff) => (
              <Operations>
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => this.delete(sideServiceTariff.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              </Operations>
            ),
          },
        ],
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingSideServiceTariff,
      ].includes(access)
    );

  delete = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await sideServiceTariffApi.delete(id);
      await this.fetch(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetch = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await sideServiceTariffApi.fetch({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: SideServiceTariffFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter, columns } =
      this.state;
    return (
      <>
        <Header
          left={<h1>Тарифы для услуг на сторону</h1>}
          right={
            this.canAdd() && (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate('/admin/sideServiceTariff/new')}
                >
                  Создать
                </Button>
              </>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={columns}
            data={data}
            fetch={this.fetch}
            fetchOnMount
            onRow={(row: SideServiceTariff) => ({
              onClick: () => navigate(`/admin/sideServiceTariff/${row.id}`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(SideServiceTariffList);
