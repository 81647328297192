// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { pddTestApi } from '../../../../lib/api';
import CommonListPage from '../../../../components/hoc/common/handbook/ListPage';
import Page from '../../../../components/hoc/common/handbook/PageContext';

import { getBreadCrumbsByUrl } from '../../../../lib/autoBreadcrumbs';
import type { PddGroup, PddTestType, UserAccess } from '../../../../lib/types';
import { pddTestEnum } from '../../../../lib/enum';

import { Filter } from './components';
import { withUserAccess } from '../../../withUserAccess';

type PageProps = {
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/pdd/test/register/';

const columns = [
  {
    title: 'Наименование теста',
    dataIndex: 'name',
    width: 300,
    render: (name: string) => (
      <div style={{ whiteSpace: 'break-spaces' }}>{name}</div>
    )
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    render: (type: PddTestType) => pddTestEnum[type]
  },
  {
    title: 'Тема ПДД',
    dataIndex: 'pddTestGroups',
    width: 400,
    render: (pddTestGroups?: PddGroup[]) => (
      <div style={{ whiteSpace: 'break-spaces' }}>
        {pddTestGroups?.map(el => el.pddGroup.name).join(', ')}
      </div>
    )
  },
  {
    title: 'Тест по билетам',
    dataIndex: 'withoutTickets',
    render: (withoutTickets: boolean) => (!withoutTickets ? 'Да' : 'Нет')
  },
  {
    title: 'Кол-во вопросов в тесте',
    dataIndex: 'questionsCount'
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess((props: PageProps) => {
  return (
    <Page>
      <CommonListPage
        crud={pddTestApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Тестирование ПДД',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}edit/${id}`),
          canDelete: true
        }}
        filter={Filter}
      />
    </Page>
  );
});
