import { BioTreatmentFacilityFacts } from '../containers/BioTreatmentFacilityFact';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: BioTreatmentFacilityFacts,
        path: '/bioTreatmentFacilityFact',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingBioTreatmentFacility,
            accessTypeEnum.handlingBioTreatmentFacility,
            accessTypeEnum.approvingBioTreatmentFacility,
            accessTypeEnum.addFactBioTreatmentFacility,
        ],
    },
]