// @flow
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import React, { useEffect, useState, useCallback } from 'react';
import { Menu, Dropdown, notification } from 'antd';
import styled from 'styled-components';

import type { UserAccess } from '../../lib/types';
import { bioTreatmentFacilityFactApi, notifyApi } from '../../lib/api';
import {
  bioTreatmentFacilityFactStatusEnum,
  accessTypeEnum,
  notificationTypeEnum,
} from '../../lib/enum';

const INIT_STATE = {
  bioTreatmentFacilityFact: {
    skip: null,
    timerId: null,
    delay: 600000,
    callback: async () => {
      try {
        await notifyApi.notification(
          notificationTypeEnum.bioTreatmentFacilityFact
        );
      } catch (e) {
        if (e.message !== 'Unexpected end of JSON input') {
          if (
            e.message.indexOf(
              'Имеются контаргенты у которых присутствуют не привязанные талоны.'
            ) + 1
          ) {
            return (
              <>
                Журнал въездов: {e.message}{' '}
                <Link to="/bioTreatmentFacilityFact?status=notPaid&status=notFound">
                  перейти
                </Link>
              </>
            );
          }
          return `Журнал въездов: ${e.message}`;
        }
      }
      return null;
    },
    canShow: (userAccess) =>
      userAccess.some((access) =>
        [accessTypeEnum.notifBio, accessTypeEnum.admin].includes(access)
      ),
  },
  expiringCoupons: {
    skip: null,
    timerId: null,
    delay: 500000,
    callback: async () => {
      try {
        await notifyApi.notification(notificationTypeEnum.expiringCoupons);
      } catch (e) {
        if (e.message !== 'Unexpected end of JSON input') {
          return `${e.message}`;
        }
      }
      return null;
    },
    canShow: (userAccess) =>
      userAccess.some((access) =>
        [
          accessTypeEnum.expiringCouponsNotification,
          accessTypeEnum.admin,
        ].includes(access)
      ),
  },
};
export default connect((state) => ({
  userAccess: state.auth.profile.access,
}))((props) => {
  const [state, setState] = useState<any>(null);
  const [timerId, setTimerId] = useState(null);
  const dontShow =
    (field: string) =>
    ({ key }) => {
      handleState(field, {
        skip: key,
      });
      notification.close(field);
    };
  const handleState = (key: string, value: any) => {
    setState((oldState) => {
      const newState = {
        ...oldState,
        [key]: {
          ...oldState[key],
          ...value,
        },
      };
      window.localStorage.setItem(
        'notify',
        JSON.stringify({
          ...newState,
          [key]: {
            ...newState[key],
            timerId: null,
          },
        })
      );
      return newState;
    });
  };
  const showNotif = async (key: string) => {
    const desc = await INIT_STATE[key].callback();
    if (desc) {
      notification.warn({
        key,
        duration: 0,
        description: <Description text={desc} dontShow={dontShow(key)} />,
      });
    }
  };
  const handleNotif = (key: string, skip: string) => {
    showNotif(key);
    handleState(key, { skip });
  };

  const checkNotif = () => {
    setTimerId(
      setInterval(() => {
        state &&
          Object.keys(state).forEach((key) => {
            if (!INIT_STATE[key].canShow(props.userAccess)) {
              return;
            }
            const skip = moment()
              .add(INIT_STATE[key].delay, 'milliseconds')
              .toISOString();
            if (state[key].skip === null) {
              handleNotif(key, skip);
            } else {
              let ms = moment().diff(moment(state[key].skip));
              if (ms > 0) {
                handleNotif(key, skip);
              }
            }
          });
      }, 5000)
    );
  };

  useEffect(() => {
    const state = window.localStorage.getItem('notify');
    if (state) {
      setState(JSON.parse(state));
    } else {
      setState(INIT_STATE);
    }
  }, []);
  useEffect(() => {
    timerId && clearInterval(timerId);
    checkNotif();
  }, [state, props.userAccess]);
  return <>{props.children}</>;
});

const StyledDiv = styled('div')`
  text-align: right;
  padding: 5px 20px 0px 20px;
`;
const StyledSpan = styled('span')`
  font-size: 12px;
  text-decoration: underline;
  color: #1e93ff;
  cursor: pointer;
`;

const Description = (props) => {
  return (
    <div>
      <div>{props.text}</div>
      <StyledDiv>
        <Dropdown
          overlay={
            <Menu onClick={props.dontShow}>
              <Menu.Item key={moment().add(1, 'hour').toISOString()}>
                1 час
              </Menu.Item>
              <Menu.Item key={moment().add(4, 'hour').toISOString()}>
                4 часа
              </Menu.Item>
              <Menu.Item key={moment().endOf('day').toISOString()}>
                сегодня
              </Menu.Item>
            </Menu>
          }
        >
          <StyledSpan>не показывать</StyledSpan>
        </Dropdown>
      </StyledDiv>
    </div>
  );
};
