// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportFuelConsumption,
  ReportFuelConsumptionParams
} from '../types/reportFuelConsumption';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportFuelConsumption) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage)
});

/**
 * Получение отчета
 */
export const fetchReportFuelConsumption = async (
  params: ReportFuelConsumptionParams
): Promise<ReportFuelConsumption[]> => {
  const report = await fetchRequest.get('/report/fuel-consumption', {
    ...params
  });
  return report.map(convertFromServer);
};

/**
 * Печать отчета
 */
export const printReportFuelConsumption = async (
  params: ReportFuelConsumptionParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/fuel-consumption/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportFuelConsumption,
  printReportFuelConsumption
};
