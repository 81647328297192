// @flow
import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

import { pddTestResultApi } from '../../../lib/api';
import type {
  Driver,
  UserAccess,
  PddTestStatusesType,
} from '../../../lib/types';
import {
  accessTypeEnum,
  pddTest,
  pddTestStatuses,
  pddTestStatusesEnum,
} from '../../../lib/enum';
import { convertDriverToString, formatDate } from '../../../lib/helpers';

import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from '../../withUserAccess';
import { ReloginButton } from '../Test/Training/components';
import Filter from './components/Filter';
import HeaderTabs from './components/HeaderTabs';
import ButtonPrintDate from './components/ButtonPrintDate';

const entryPointPath = '/pdd/results/';

export const COLUMNS = [
  {
    title: 'Дата тестирования',
    dataIndex: 'examinationDate',
    render: (date: string) => formatDate(date),
  },
  {
    title: 'ФИО водителя',
    dataIndex: 'driver',
    render: (driver: Driver) => convertDriverToString(driver),
  },
  {
    title: 'Правильных ответов',
    dataIndex: 'rightAnswersCount',
  },
  {
    title: 'Неправильных ответов',
    dataIndex: 'wrongAnswersCount',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (status: PddTestStatusesType) => (
      <span
        style={{
          color: pddTestStatusesEnum.passed === status ? '#219653' : '#E9180A',
        }}
      >
        {pddTestStatuses[status]}
      </span>
    ),
  },
  {
    title: 'Ссылка на результат',
    dataIndex: 'id',
    render: (id: number) => {
      return <Link to={`${entryPointPath}${id}`}>перейти</Link>;
    },
  },
];

type PageProps = {
  userAccess: UserAccess[],
};
export default withUserAccess((props: PageProps) => {
  const [canRelogin, setCanRelogin] = useState(false);
  useEffect(() => {
    setCanRelogin(
      props.userAccess.find((access) =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.reloginPddTest,
        ].includes(access)
      )
    );
  }, [props.userAccess]);
  return (
    <Page>
      <CommonListPage
        crud={pddTestResultApi}
        pageHeaderProps={{
          breadCrumbs: [{ to: '/', name: 'Главная' }, { name: 'Результаты' }],
          mainHeader: 'Результаты тестирований',
          rightHeader: canRelogin ? (
            <>
              <ButtonPrintDate /> <ReloginButton />
            </>
          ) : (
            <ButtonPrintDate />
          ),
        }}
        tableListProps={{
          columns: COLUMNS,
        }}
        subHeader={<HeaderTabs selectedTab="exam" />}
        filter={Filter}
        filterMixin={{
          'pddTest.type': pddTest.exam,
        }}
      />
    </Page>
  );
});
