// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from '../../components';
import { CustomInput } from '../../components/ui';
import type { FilterChildrenParams } from '../../components/hoc/Filter';
import type { Vehicle, VehicleStatus, VehicleType } from '../../lib/types';
import { ownerTypes, serviceTypeEnum } from '../../lib/enum';
import OrgUnitSelect from '../../components/OrgUnitSelect';
import Field from '../../components/card/Field';

const {
  VehicleTypeSelect,
  VehicleStatusSelect,
  VehicleSelect,
  BrandsSelect,
  VehicleModelSelect,
  ContractorSelect
} = Selects;

const Content = styled.div`
  padding: 16px;
`;

export type ContractVehicleFilterParams = $Shape<{
  'vehicle.nodeId': number,
  'vehicle.yearIssued': number,
  'vehicle.status': VehicleStatus | VehicleStatus[],
  'vehicle.vehicleModel.type': VehicleType,
  'vehicle.vehicleModel.id': number,
  'vehicle.vehicleModel.brand.id': number,
  'contractor.id': number,
  'vehicle.id': number,
  'vehicle.vin': string,
  type: any,
  contractorId: number
}>;

type Props = {
  filter: ContractVehicleFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ContractVehiclesFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <Content>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          applyFilter,
          showAdditional,
          handleAdditional,
          cleanFilter
        }: FilterChildrenParams<ContractVehicleFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone={true}>
                <VehicleStatusSelect
                  size="small"
                  placeholder="Все статусы ТС"
                  value={values['vehicle.status']}
                  onChange={value => changeValue('vehicle.status', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <VehicleTypeSelect
                  size="small"
                  placeholder="Все типы ТС"
                  value={values['vehicle.vehicleModel.type']}
                  onChange={value =>
                    changeValue('vehicle.vehicleModel.type', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <VehicleSelect
                  size="small"
                  placeholder="Все номера ТС"
                  value={values['vehicle.id']}
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  filter={{
                    ownerType: ownerTypes.contract,
                    status: values['vehicle.status'],
                    'vehicleModel.type': values['vehicle.vehicleModel.type']
                  }}
                  onChange={value => changeValue('vehicle.id', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <CustomInput
                  size="small"
                  placeholder="VIN"
                  value={values['vehicle.vin']}
                  onChange={({ target: { value } }) =>
                    changeValue('vehicle.vin', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <BrandsSelect
                  size="small"
                  placeholder="Все марки"
                  value={values['vehicle.vehicleModel.brand.id']}
                  onChange={value =>
                    changeValue('vehicle.vehicleModel.brand.id', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <VehicleModelSelect
                  size="small"
                  placeholder="Все модели ТС"
                  value={values['vehicle.vehicleModel.id']}
                  filter={{
                    'brand.id': values['vehicle.vehicleModel.brand.id']
                  }}
                  onChange={value =>
                    changeValue('vehicle.vehicleModel.id', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <OrgUnitSelect
                  size="small"
                  placeholder="Все подразделения"
                  onChange={value => changeValue('vehicle.nodeId', value)}
                  value={values['vehicle.nodeId']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone={true}>
                <ContractorSelect
                  size="small"
                  placeholder="Все подрядчики"
                  value={values['contractorId']}
                  onChange={value => changeValue('contractorId', value)}
                  filter={{
                    services: serviceTypeEnum.contractVehicles
                  }}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </Content>
);

export default ContractVehiclesFilter;
