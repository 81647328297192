// @flow

import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { Item as FormItem } from 'antd/lib/form';

import type {
  RepairOrderVehicle,
  MaintenanceOperation,
  RepairOrderOperation,
  FormErrors
} from './../../../lib/types';
import Selects from './../../../components/selects';
import { Icon as UiIcon } from '../../../components/ui';

const ItemCol = styled.div`
  ${props => css`
    ${props.width ? `width: ${props.width}px;` : `flex: ${props.flex || 1};`};
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: flex-start;

  > .col-item {
    padding: 0 8px;
    text-align: center;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const OperationCol = styled(ItemCol)`
  height: 32px;
`;

const StyledIcon = styled(UiIcon)`
  margin-top: 8px;
  cursor: pointer;
`;

const { VehicleSelect, MaintenanceOperationSelect } = Selects;

type Props = {
  onAdd?: Function
};

type RepairOrderAddRow = $Shape<RepairOrderVehicle>;

type State = {
  repairOrderVehicle: RepairOrderAddRow,
  errors: Object
};
/**
 * Строка редактирования мойки ТС
 */
export default class RepairOrderVehicleRow extends Component<Props, State> {
  state = {
    repairOrderVehicle: {},
    errors: {}
  };

  validate = (): FormErrors<RepairOrderVehicle> => {
    const { repairOrderVehicle } = this.state;
    var errors = {};
    if (!repairOrderVehicle.vehicleId) {
      errors.vehicleId = 'Обязательно для заполнения';
    }
    if (!repairOrderVehicle.operations) {
      errors.operations = 'Обязательно для заполнения';
    }
    this.setState({
      errors
    });
    return errors;
  };

  onChange = (repairOrderVehicle: RepairOrderAddRow) =>
    this.setState({
      repairOrderVehicle
    });

  render() {
    const { onAdd } = this.props;
    const { repairOrderVehicle, errors } = this.state;
    return (
      <Fragment>
        <Wrapper className="list-row-item">
          <ItemCol
            flex={1}
            className="col-item"
            style={{ maxWidth: 'calc(100% - 500px)' }}
          >
            <FormItem
              validateStatus={errors.vehicleId && 'error'}
              help={errors.vehicleId}
            >
              <VehicleSelect
                value={repairOrderVehicle.vehicleId}
                onChange={(vehicleId: string, selectedOption: Object) => {
                  const newRepairOrderVehicle: RepairOrderAddRow = {
                    ...repairOrderVehicle,
                    vehicleId: parseInt(vehicleId),
                    vehicle: selectedOption.props.vehicle
                  };
                  this.onChange(newRepairOrderVehicle);
                }}
              />
            </FormItem>
          </ItemCol>
          <ItemCol flex={1} className="col-item">
            <FormItem
              validateStatus={errors.type && 'error'}
              help={errors.type}
            >
              <MaintenanceOperationSelect
                onChange={(value: string, selectedOptions: Object[]) =>
                  this.onChange({
                    ...repairOrderVehicle,
                    operations: selectedOptions.map(option => {
                      const maintenanceOperation: MaintenanceOperation =
                        option.props.maintenanceoperation;
                      return {
                        operation: maintenanceOperation,
                        operationId: maintenanceOperation.id,
                        count: 1
                      };
                    })
                  })
                }
                value={
                  repairOrderVehicle &&
                  repairOrderVehicle.operations &&
                  repairOrderVehicle.operations.map(
                    (repairOrderOperation: RepairOrderOperation) =>
                      repairOrderOperation.operation.id
                  )
                }
                mode="multiple"
              />
            </FormItem>
          </ItemCol>
          <OperationCol width={35} className="col-item">
            {onAdd && (
              <StyledIcon
                type="plus"
                onClick={
                  isEmpty(errors) && !isEmpty(repairOrderVehicle)
                    ? () => {
                        onAdd(repairOrderVehicle);
                        this.setState({
                          repairOrderVehicle: {}
                        });
                      }
                    : null
                }
              />
            )}
          </OperationCol>
        </Wrapper>
      </Fragment>
    );
  }
}
