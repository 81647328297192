// @flow
import type {
  CalculationStatus,
  VehicleMonitoringCalculation,
  VehicleMonitoringCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  vehicleMonitoringCalculation: VehicleMonitoringCalculation
): VehicleMonitoringCalculation => ({
  ...vehicleMonitoringCalculation,
  sum: convertFromRubToKop(vehicleMonitoringCalculation.sum),
  months: vehicleMonitoringCalculation.months.map(
    convertDataToServerVehicleMonitoringCalculationMonth
  )
});

export const convertDataFromServer = (
  vehicleMonitoringCalculation: VehicleMonitoringCalculation
): VehicleMonitoringCalculation => ({
  ...vehicleMonitoringCalculation,
  sum: convertFromKopToRub(vehicleMonitoringCalculation.sum),
  months: vehicleMonitoringCalculation.months.map(
    convertDataFromServerVehicleMonitoringCalculationMonth
  )
});

export const convertDataToServerVehicleMonitoringCalculationMonth = (
  vehicleMonitoringCalculationMonth: VehicleMonitoringCalculationMonth
): VehicleMonitoringCalculationMonth => ({
  ...vehicleMonitoringCalculationMonth,
  cost: convertFromRubToKop(vehicleMonitoringCalculationMonth.cost)
});

export const convertDataFromServerVehicleMonitoringCalculationMonth = (
  vehicleMonitoringCalculationMonth: VehicleMonitoringCalculationMonth
): VehicleMonitoringCalculationMonth => ({
  ...vehicleMonitoringCalculationMonth,
  cost: convertFromKopToRub(vehicleMonitoringCalculationMonth.cost)
});

const basedUrl = 'vehicleMonitoringCalculation';
const vehicleMonitoringCalculation = new CommonApi<VehicleMonitoringCalculation>(
  basedUrl,
  'расчет потребности в замене GPS',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...vehicleMonitoringCalculation,
  calculate,
  changeStatus,
  print
};
