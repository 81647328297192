// @flow
import Icon from 'antd/lib/icon';
import React from 'react';
import { budgetSummaryStatusEnum } from '../../../../lib/enum';
import type { BranchBudgetSummary } from '../../../../lib/types';

import { TabItem, Tabs } from './../../../../components/ui';

type Props = {
  branchData: BranchBudgetSummary[],
  id: number,
};

export default (props: Props) => {
  return (
    <Tabs
      withRouter
      defaultTab={`/budget/summary/${props.id}/${props.branchData[0]?.orgUnitId}`}
    >
      {props.branchData.map((el) => {
        const StatusIcon =
          el.status === budgetSummaryStatusEnum.approvement ? (
            <Icon
              type="warning"
              style={{ color: 'red', margin: '0 0 2px 3px' }}
            />
          ) : el.status === budgetSummaryStatusEnum.approved ? (
            <Icon
              type="check-circle"
              style={{ color: 'green', margin: '0 0 2px 3px' }}
            />
          ) : null;
        return (
          <TabItem
            label={
              <>
                {el.orgUnitName} {StatusIcon}
              </>
            }
            key={el.orgUnitId}
            url={`/budget/summary/${props.id}/${el.orgUnitId}`}
          />
        );
      })}
    </Tabs>
  );
};
