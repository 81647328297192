// @flow
import notification from 'antd/lib/notification';
import React from 'react';
import { Header } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';

import { notificationLoading } from '../../../components/Notifications';
import { TabItem, Tabs } from '../../../components/ui';

import { branchBudgetSummaryApi, budgetAnalysisApi } from '../../../lib/api';
import { getPathWithHistoryParams } from '../../../lib/helpers';
import type { BranchBudgetSummary, BudgetAnalysis } from '../../../lib/types';

import Table from './components/Table';

type Props = {
  budgetAnalysisId: number
};
type State = {
  budgetAnalysis: ?BudgetAnalysis,
  branchBudgetSummary: ?BranchBudgetSummary,
  defaultTab: 'main' | 'bk' | 'bkf',
  loading: boolean
};
export default class extends React.Component<Props, State> {
  state = {
    budgetAnalysis: null,
    branchBudgetSummary: null,
    defaultTab: 'main',
    loading: false
  };
  componentDidMount() {
    this.getBudgetAnalysis(this.props.budgetAnalysisId);
  }

  changeTab = (defaultTab: 'main' | 'bk' | 'bkf') => () => {
    const { budgetAnalysis } = this.state;
    this.setState({ defaultTab }, () => {
      switch (defaultTab) {
        case 'bkf':
          !!budgetAnalysis?.budgetBkfId &&
            this.getBranchBudgetAnalysis(budgetAnalysis.budgetBkfId);
          break;
        case 'bk':
          !!budgetAnalysis?.budgetBkId &&
            this.getBranchBudgetAnalysis(budgetAnalysis.budgetBkId);
          break;
        default:
          !!budgetAnalysis?.mainBudgetId &&
            this.getBranchBudgetAnalysis(budgetAnalysis.mainBudgetId);
      }
    });
  };

  getBudgetAnalysis = async (id: number) => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting'
      });
      const budgetAnalysis = await budgetAnalysisApi.get(id);
      this.setState({ budgetAnalysis }, () =>
        this.getBranchBudgetAnalysis(budgetAnalysis.mainBudgetId)
      );
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    } finally {
      notification.close('getting');
    }
  };

  getBranchBudgetAnalysis = async (id: number): Promise<void> => {
    try {
      this.setState({ loading: true });
      const branchBudgetSummary = await branchBudgetSummaryApi.get(id);
      this.setState({ branchBudgetSummary });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { budgetAnalysisId } = this.props;
    const {
      budgetAnalysis,
      defaultTab,
      loading,
      branchBudgetSummary
    } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/budget/budget-analysis')}>
                Анализ бюджета
              </Crumb>
              <Crumb>Анализ бюджета №{budgetAnalysisId}</Crumb>
            </Breadcrumbs>
          }
        />
        {budgetAnalysis && (
          <>
            <Tabs defaultTab={defaultTab}>
              <TabItem
                tabKey="main"
                onClick={this.changeTab('main')}
                label="Расчетный годовой бюджет"
              />
              {!!budgetAnalysis.budgetBkId && (
                <TabItem
                  tabKey="bk"
                  onClick={this.changeTab('bk')}
                  label="Бюджет БК"
                />
              )}
              {!!budgetAnalysis.budgetBkfId && (
                <TabItem
                  tabKey="bkf"
                  onClick={this.changeTab('bkf')}
                  label="Бюджет БКФ"
                />
              )}
            </Tabs>
            <Table
              loading={loading}
              budgetAnalysisId={budgetAnalysisId}
              data={branchBudgetSummary?.budgetSummaryLineItems ?? []}
            />
          </>
        )}
      </>
    );
  }
}
