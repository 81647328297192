// @flow
import { fetchRequest } from './index';
import type { ContractVehicleBudget, ExpenseDirectionType } from './../types';
import CommonApi from './../commonApi';

import { convertFromRubToKop, convertFromKopToRub } from './../helpers';
import { convertDataFromServer as convertDataFromServerAssignment } from './assignmentLimit';

export const convertDataToServer = (
  сontractVehicleBudget: ContractVehicleBudget
) => ({
  ...сontractVehicleBudget,
  carServicesCost: convertFromRubToKop(сontractVehicleBudget.carServicesCost),
  otherVehicleServicesCost: convertFromRubToKop(
    сontractVehicleBudget.otherVehicleServicesCost
  ),
  acsVehicleServicesCost: convertFromRubToKop(
    сontractVehicleBudget.acsVehicleServicesCost
  ),
  cargoServicesCost: convertFromRubToKop(
    сontractVehicleBudget.cargoServicesCost
  ),
  mechanismServicesCost: convertFromRubToKop(
    сontractVehicleBudget.mechanismServicesCost
  ),
  assignmentLimits: сontractVehicleBudget.assignmentLimits
    ? [
        ...сontractVehicleBudget.assignmentLimits.map(assignmentLimit =>
          convertDataFromServerAssignment(assignmentLimit)
        )
      ]
    : undefined
});

export const convertDataFromServer = (
  сontractVehicleBudget: ContractVehicleBudget
) => ({
  ...сontractVehicleBudget,
  carServicesCost: convertFromKopToRub(сontractVehicleBudget.carServicesCost),
  otherVehicleServicesCost: convertFromKopToRub(
    сontractVehicleBudget.otherVehicleServicesCost
  ),
  acsVehicleServicesCost: convertFromKopToRub(
    сontractVehicleBudget.acsVehicleServicesCost
  ),
  cargoServicesCost: convertFromKopToRub(
    сontractVehicleBudget.cargoServicesCost
  ),
  mechanismServicesCost: convertFromKopToRub(
    сontractVehicleBudget.mechanismServicesCost
  )
});

export const getExpenseDirectionBudget = async (
  contractVehicleBudgetId: number,
  type: ExpenseDirectionType
): Promise<any> => {
  const data = await fetchRequest.get(
    `/${basedUrl}/getExpenseDirectionBudget/${contractVehicleBudgetId}/${type}`
  );
  if (data) return data;
  throw new Error(
    `Не удалось получить расшифровки бюджету по типа направления расходов`
  );
};

export const getByContractVehiclePlan = async (
  id: number
): Promise<ContractVehicleBudget> => {
  const contractVehicleBudget = await fetchRequest.get(
    `/${basedUrl}/getByContractVehiclePlan/${id}`
  );
  if (contractVehicleBudget)
    return convertDataFromServer(contractVehicleBudget);
  throw new Error(`Не удалось сменить статус`);
};

const basedUrl = 'contractVehicleBudget';
const contractVehicleBudget = new CommonApi<ContractVehicleBudget>(
  basedUrl,
  'бюджет НТС',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...contractVehicleBudget,
  getByContractVehiclePlan,
  getExpenseDirectionBudget
};
