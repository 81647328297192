// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { Option, Select } from './../ant/Select';

type AutoGeneratedProps = {
  // объект, который парсим для генерации списка
  autoType: any
} & SelectProps;

const AutoGeneratedEnumSelect = ({
                                   autoType,
                                   ...selectProps
                                 }: AutoGeneratedProps) => (
  <Select
    allowClear
    {...selectProps}
    value={selectProps.value || selectProps.defaultValue}
  >
    {Object.keys(autoType).map(key => (
      <Option key={key} value={key}>
        {autoType[key]}
      </Option>
    ))}
  </Select>
);

const AutoGeneratedMultipleEnumSelectT = ({
  autoType,
  ...selectProps
}: AutoGeneratedProps) => {
  return (
    <Select allowClear {...selectProps} mode="multiple" optionLabelProp="label">
      {Object.keys(autoType).map(key => (
        <Option key={key} value={key} label={autoType[key]}>
          {autoType[key]}
        </Option>
      ))}
    </Select>
  );
};

// Обертка для создания списков из объекта
export const AutoGeneratedMultipleEnumSelect = (props: AutoGeneratedProps) => {
  return function(otherProps: mixed) {
    return AutoGeneratedMultipleEnumSelectT({ ...props, ...otherProps });
  };
};

// Обертка для создания списков из объекта
export default (props: AutoGeneratedProps) => {
  return function(otherProps: mixed) {
    return AutoGeneratedEnumSelect({ ...props, ...otherProps });
  };
};
