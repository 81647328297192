//@flow
import { Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SectionContent } from '../../../../../components/hoc/common/components/elements';
import CommonForm from '../../../../../components/hoc/common/handbook/CommonForm';
import { SectionTitle } from '../../../../../components/layout';
import { Icon } from '../../../../../components/ui';
import { notificationLoading } from '../../../../../components/Notifications';

import {
  branchBudgetSummaryApi,
  budgetSummaryContractApi,
} from '../../../../../lib/api';
import {
  accessTypeEnum,
  branchBudgetSummaryCognosStatusEnum,
  budgetSummaryEnum,
  expenseDirectionCognosStatus,
} from '../../../../../lib/enum';
import { navigate } from '../../../../../lib/helpers';
import { anyNotificationWithFeedback } from '../../../../../lib/notificationWrapper';

import type {
  BranchBudgetSummary,
  BranchBudgetSummaryExpenseDirection,
  BranchBudgetSummaryLineItem,
  BudgetSummary,
  UserAccess,
} from '../../../../../lib/types';
import { withUserAccess } from '../../../../withUserAccess';

import ClarificationBudgetBranchTabs from '../../../details/components/BranchTabs';
import Table from '../../../details/components/Table';

import MainBudgetBranchTabs from '../BranchTabs';

import type { CognosContractRegisterFilterParams } from './Filter';
import Filter from './Filter';

const StyledIcon = styled(Icon)`
  padding-left: 10px;
  font-size: 16px;
`;
type Props = {
  summarydetails?: 'details' | 'summary',
  branchBudgetSummary: BranchBudgetSummary,
  budgetSummary: BudgetSummary,
  orgUnitId: number,
  userAccess: UserAccess[],
};

export default withUserAccess((props: Props) => {
  const [budgetSummaryLineItems, setBudgetSummaryLineItems] = useState([]);
  const [cognosContracts, setCognosContracts] = useState([]);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);

  const [coveredByContracts, setCoveredByContracts] = useState(false);

  const columns = [
    {
      dataIndex: 'budgetLineItemName',
      title: 'Статья бюджетной формы',
      key: 'budgetLineItemName',
      render: (
        budgetLineItemName: string,
        row: BranchBudgetSummaryLineItem
      ) => {
        return (
          <>
            {budgetLineItemName}
            {row.expenseDirections &&
              (row.coveredByContracts ? (
                <StyledIcon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="green"
                />
              ) : (
                <StyledIcon
                  type="exclamation-circle"
                  theme="twoTone"
                  twoToneColor="red"
                />
              ))}
          </>
        );
      },
    },
    {
      dataIndex: 'expenseDirectionName',
      title: 'Направление расходов',
      key: 'expenseDirectionName',
      onCell: (record, index) => ({
        onClick: () => {
          record.expenseDirectionId &&
            navigate(
              `/budget/${
                props.budgetSummary.type === budgetSummaryEnum.main
                  ? 'summary'
                  : 'details'
              }/${props.budgetSummary.id}/${props.orgUnitId}/contract/${
                record.id
              }/registry`
            );
        },
      }),
      render: (
        expenseDirectionName: string,
        row: BranchBudgetSummaryExpenseDirection
      ) => {
        return (
          <>
            {expenseDirectionName}
            {row.expenseDirectionId &&
              (row.coveredByContracts ? (
                <StyledIcon
                  type="check-circle"
                  theme="twoTone"
                  twoToneColor="green"
                />
              ) : (
                <StyledIcon
                  type="exclamation-circle"
                  theme="twoTone"
                  twoToneColor="red"
                />
              ))}
          </>
        );
      },
    },
    {
      dataIndex: 'cognosStatus',
      title: 'Статус ИСУ Бюджетирование',
      key: 'cognosStatus',
      width: 250,
      render: (cognosStatus) =>
        cognosStatus ? expenseDirectionCognosStatus[cognosStatus] : null,
    },
    {
      dataIndex: 'cognosContracts',
      title: 'Договора',
      key: 'cognosContracts',
      width: 300,
    },
  ];

  useEffect(() => {
    const fetch = async () => {
      let promises = [];

      let k = props?.branchBudgetSummary?.budgetSummaryLineItems?.forEach(
        (items: BranchBudgetSummaryLineItem) => {
          let s = items.expenseDirections?.forEach((expenseDirection) => {
            promises.push(
              budgetSummaryContractApi.fetch({
                branchBudgetSummaryExpenseDirectionId: expenseDirection.id,
              })
            );
          });
        }
      );

      let data = await Promise.all(promises);
      data = data?.flatMap((d) => [...d.data]);
      setCognosContracts(data);
      setBudgetSummaryLineItems(
        props?.branchBudgetSummary?.budgetSummaryLineItems?.map(
          (budgetSummaryLineItem) => ({
            ...budgetSummaryLineItem,
            expenseDirections: budgetSummaryLineItem.expenseDirections?.map(
              (expenseDirection) => ({
                ...expenseDirection,
                cognosContracts: data
                  .filter((d) => {
                    return (
                      d.branchBudgetSummaryExpenseDirectionId ===
                      expenseDirection.id
                    );
                  })
                  ?.map((d) => d.contractNumber)
                  ?.join(', '),
              })
            ),
          })
        )
      );
    };

    try {
      fetch().then();
    } catch (e) {
      notification.error({
        message: e.message,
      });
    }
  }, [props.branchBudgetSummary.budgetSummaryLineItems]);

  useEffect(() => {
    setCoveredByContracts(
      props.branchBudgetSummary?.budgetSummaryLineItems?.every(
        (item: BranchBudgetSummaryLineItem) => item.coveredByContracts
      )
    );
  }, [props.branchBudgetSummary]);

  useEffect(() => {
    setData(
      budgetSummaryLineItems
        ?.filter((budgetSummaryLineItem) => {
          return filter.budgetLineItemId
            ? budgetSummaryLineItem.budgetLineItemId === filter.budgetLineItemId
            : true;
        })
        .map((budgetSummaryLineItem) => ({
          ...budgetSummaryLineItem,
          expenseDirections: budgetSummaryLineItem.expenseDirections
            ?.filter((expenseDirection) => {
              return filter.expenseDirectionId
                ? expenseDirection.expenseDirectionId ===
                    filter.expenseDirectionId
                : true;
            })
            ?.filter((expenseDirection) => {
              return filter.status
                ? expenseDirection.cognosStatus === filter.status
                : true;
            })
            ?.filter((expenseDirection) => {
              return filter.cognosContractNumber
                ? expenseDirection.cognosContracts?.indexOf(
                    filter.cognosContractNumber
                  ) !== -1
                : true;
            }),
        }))
        .filter((budgetSummaryLineItem) => {
          return budgetSummaryLineItem.expenseDirections?.length > 0;
        })
    );
  }, [filter, budgetSummaryLineItems]);

  const canSending = () =>
    props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.sendingVehiclePlanBudgetToCognos,
      ].includes(access)
    );

  const applyFilter = (filter: CognosContractRegisterFilterParams) => {
    setFilter(filter);
  };

  const cleanFilter = () => {
    setFilter({});
  };

  return (
    <>
      <CommonForm data={props.branchBudgetSummary} useFooter={false}>
        {({ values }) => {
          return (
            <>
              <SectionTitle
                divider
                noWrapMe
                suffix={
                  coveredByContracts &&
                  canSending() && (
                    <>
                      {![
                        branchBudgetSummaryCognosStatusEnum.notProcessed,
                        branchBudgetSummaryCognosStatusEnum.error,
                        branchBudgetSummaryCognosStatusEnum.approved,
                        branchBudgetSummaryCognosStatusEnum.declined,
                      ].some(
                        (status) =>
                          status === props.branchBudgetSummary.cognosStatus
                      ) && (
                        <Button
                          onClick={async () => {
                            try {
                              notificationLoading({
                                message:
                                  'Получить статуc данных в ИСУ Бюджетирование...',
                                key: 'gettingStatus',
                                duration: 1,
                              });
                              const message = await branchBudgetSummaryApi.checkCognosStatus(
                                props.branchBudgetSummary.id
                              );
                              notification.info({
                                message,
                              });
                            } catch (e) {
                              notification.error({
                                message: e.message,
                              });
                            } finally {
                              notification.close('gettingStatus');
                            }
                          }}
                        >
                          Получить статус
                        </Button>
                      )}
                      {![
                        branchBudgetSummaryCognosStatusEnum.sended,
                        branchBudgetSummaryCognosStatusEnum.partiallyApproved,
                        branchBudgetSummaryCognosStatusEnum.approved,
                      ].some(
                        (status) =>
                          status === props.branchBudgetSummary.cognosStatus
                      ) && (
                        <Button
                          style={{ marginLeft: '16px' }}
                          onClick={() => {
                            anyNotificationWithFeedback(
                              async () => {
                                return await branchBudgetSummaryApi.exportToCognos(
                                  props.branchBudgetSummary.id
                                );
                              },
                              {
                                message:
                                  'Отправка данных в ИСУ Бюджетирование...',
                                duration: 0,
                              }
                            );
                          }}
                        >
                          Отправить в ИСУ Бюджетирование
                        </Button>
                      )}
                    </>
                  )
                }
              >
                {props.budgetSummary.type === budgetSummaryEnum.main ? (
                  <MainBudgetBranchTabs
                    id={props.budgetSummary.id}
                    orgUnitId={props.orgUnitId}
                    branchBudgetStatus={props.branchBudgetSummary.status}
                  />
                ) : (
                  <ClarificationBudgetBranchTabs
                    id={props.budgetSummary.id}
                    orgUnitId={props.orgUnitId}
                    distributionEconomyStatus={
                      props.branchBudgetSummary.distributionEconomyStatus
                    }
                    calculationEconomyStatus={
                      props.branchBudgetSummary.calculationEconomyStatus
                    }
                    proposalEconomyStatus={
                      props.branchBudgetSummary.proposalStatus
                    }
                  />
                )}
              </SectionTitle>
              <Filter
                applyFilter={applyFilter}
                cleanFilter={cleanFilter}
                cognosContracts={cognosContracts}
                filter={filter}
                noWrapMe
              />
              <SectionContent noWrapMe>
                <Table
                  data={data || []}
                  columns={columns}
                  loading={true}
                  defaultExpandAllRows
                />
              </SectionContent>
            </>
          );
        }}
      </CommonForm>
    </>
  );
});
