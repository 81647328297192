// @flow
import type { CalculationStatus, VehicleTaxCalculation } from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  vehicleTaxCalculation: VehicleTaxCalculation
) => ({
  ...vehicleTaxCalculation,
  taxRate: convertFromRubToKop(vehicleTaxCalculation.taxRate),
  tax: convertFromRubToKop(vehicleTaxCalculation.tax)
});

export const convertDataFromServer = (
  vehicleTaxCalculation: VehicleTaxCalculation
) => ({
  ...vehicleTaxCalculation,
  taxRate: convertFromKopToRub(vehicleTaxCalculation.taxRate),
  tax: convertFromKopToRub(vehicleTaxCalculation.tax)
});

const basedUrl = 'vehicleTaxCalculation';
const vehicleTaxCalculation = new CommonApi<VehicleTaxCalculation>(
  basedUrl,
  'Расчет транспортного налога',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

export default {
  ...vehicleTaxCalculation,
  calculate,
  changeStatus
};
