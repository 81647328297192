// @flow

import { Dispatch } from 'redux';

import type { FuelAndOil } from '../lib/types';
import { fuelAndOilApi } from './../lib/api';
import type { Action, AppState } from './redux';

export const FETCH_FUEL_AND_OIL = '/vehicles/fuelAndOil/fetch';
export const UPDATE_FUEL_AND_OIL = '/vehicles/fuelAndOil/update';
export const CLEAN_FUEL_AND_OIL = '/vehicles/fuelAndOil/clean';

export type FuelAndOilState = ?FuelAndOil;

const initialState: FuelAndOilState = null;

const reducer = (
  state: FuelAndOilState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case FETCH_FUEL_AND_OIL:
    case UPDATE_FUEL_AND_OIL:
      return { ...payload };
    case CLEAN_FUEL_AND_OIL:
      return null;
    default:
      return state;
  }
};

export const addFuelAndOil = (fuelAndOil: FuelAndOil) => async (
  dispatch: Dispatch
) => {
  await fuelAndOilApi.addFuelAndOil(fuelAndOil);
};

// Обновление данных
export const updateFuelAndOil = (fuelAndOil: FuelAndOil) => async (
  dispatch: Dispatch,
  getState: () => AppState
) => {
  let updated = await fuelAndOilApi.updateFuelAndOil(fuelAndOil);
  dispatch({
    type: UPDATE_FUEL_AND_OIL,
    payload: {
      ...updated
    }
  });
};

// Удаление
export const deleteFuelAndOil = (id: number) => async (dispatch: Dispatch) => {
  await fuelAndOilApi.deleteFuelAndOil(id);
};

// Подгрузка ГСМ
export const fetchFuelAndOil = (id: number) => async (dispatch: Dispatch) => {
  let fuelAndOil = await fuelAndOilApi.fetchFuelAndOil(id);
  dispatch({
    type: FETCH_FUEL_AND_OIL,
    payload: {
      ...fuelAndOil
    }
  });
};

export const cleanFuelAndOil = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_FUEL_AND_OIL
  });
};

export default reducer;
