//@flow
import React, { useState, useEffect, useContext } from 'react';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import { EllipsisOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

import { Table } from '../../../components/ant/table/Table';
import { Popconfirm, Operations, Dropdown } from '../../../components/ui';
import { notificationLoading } from '../../../components/Notifications';

import {
  bioTreatmentFacilityTalonStatus,
  bioTreatmentFacilityTalonStatusEnum,
} from '../../../lib/enum';
import { bioTreatmentFacilityTalonApi } from '../../../lib/api';
import type {
  UserAccess,
  BioTreatmentFacilityVolume,
  BioTreatmentFacilityContract,
  BioTreatmentFacilityTalon,
} from '../../../lib/types';
import { formatDateTimeToString, formatRub } from '../../../lib/helpers';

import { ContractContext } from '../Card';

import ModalBioTreatmentFacilityTalon from './ModalBioTreatmentFacilityTalon';
import ModalBioTreatmentFacilityFact from './ModalBioTreatmentFacilityFact';
import Facts, { INIT_MODAL_FACT } from './Facts';

export const INIT_MODAL_TALON = {
  show: false,
  bioTreatmentFacilityTalon: null,
  volumeId: null,
};
type Props = {
  userAccess: UserAccess[],
  volume: BioTreatmentFacilityVolume,
  contract: BioTreatmentFacilityContract,
  canAddFact: boolean,
  canApprovingTalon: boolean,
  canAddTalon: boolean,
  canEditFact: boolean,
  onExpand: () => void,
  expandedRowKeys: {
    show: boolean,
    talons: number[],
    volumes: number[],
  },
};
export default (props: Props) => {
  const context = useContext(ContractContext);
  const [state, setState] = useState({
    data: [],
    loading: false,
    refetchFacts: false,
  });

  const handleState = (field: string, value: any) => {
    setState((oldState) => {
      return {
        ...oldState,
        [field]: value,
      };
    });
  };

  const fetch = async (bioTreatmentFacilityVolumeId: number) => {
    handleState('loading', true);
    try {
      const { data } = await bioTreatmentFacilityTalonApi.fetch({
        bioTreatmentFacilityVolumeId,
        pageSize: undefined,
        page: undefined,
      });
      handleState('data', data);
    } catch (error) {
    } finally {
      handleState('loading', false);
    }
  };

  const print = async (id: number) => {
    printTalon(id);
  };

  const deleteTalon = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление талона...',
        key: 'removingTalon',
      });
      if (id) {
        await bioTreatmentFacilityTalonApi.delete(id);
        await fetch(props.volume?.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('removingTalon');
    }
  };

  const handleApproved = async (
    bioTreatmentFacilityTalon: BioTreatmentFacilityTalon
  ) => {
    try {
      notificationLoading({
        message: 'Утверждение талона...',
        key: 'approvedTalon',
      });
      if (bioTreatmentFacilityTalon) {
        await bioTreatmentFacilityTalonApi.update({
          ...bioTreatmentFacilityTalon,
          status: bioTreatmentFacilityTalonStatusEnum.approved,
        });
        await fetch(props.volume?.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('approvedTalon');
    }
  };

  useEffect(() => {
    if (props.volume?.id) {
      fetch(props.volume.id);
    }
  }, [props.volume]);

  useEffect(() => {
    if (context?.modalTalon?.volumeId === Number(props?.volume?.id)) {
      fetch(props?.volume?.id);
    }
  }, [context?.modalTalon?.volumeId]);

  return (
    <div style={{ margin: '-16px -16px 16px -16px' }}>
      <div style={{ fontSize: 16, padding: '16px' }}>Талоны</div>
      <Table
        pagination={false}
        loading={state.loading}
        columns={[
          {
            title: 'Дата',
            key: 'createdDate',
            dataIndex: 'createdDate',
            render: (date: string) =>
              date ? `${formatDateTimeToString(date, 'DD.MM.YYYY')}` : '-',
          },
          {
            title: 'Выдано талонов',
            key: 'issuedCoupons',
            dataIndex: 'issuedCoupons',
          },
          {
            title: 'Остаток талонов',
            width: '140px',
            key: 'leftoverCoupons',
            dataIndex: 'leftoverCoupons',
          },
          {
            title: 'Сумма',
            width: '140px',
            key: 'sum',
            dataIndex: 'sum',
            render: (sum: string) => formatRub(sum),
          },
          {
            title: 'Статус',
            width: '140px',
            key: 'status',
            dataIndex: 'status',
            render: (status: string) => bioTreatmentFacilityTalonStatus[status],
          },
          {
            title: '',
            width: 80,
            dataIndex: 'operations',
            // eslint-disable-next-line no-unused-vars
            render: (
              text: string,
              bioTreatmentFacilityTalon: BioTreatmentFacilityTalon
            ) => {
              const showOperations =
                (props.canAddFact &&
                  bioTreatmentFacilityTalon.status ===
                    bioTreatmentFacilityTalonStatusEnum.approved) ||
                bioTreatmentFacilityTalon.status ===
                  bioTreatmentFacilityTalonStatusEnum.closed ||
                (bioTreatmentFacilityTalon.status ===
                  bioTreatmentFacilityTalonStatusEnum.created &&
                  (props.canApprovingTalon || props.canAddTalon));
              return (
                showOperations && (
                  <Operations>
                    <Dropdown
                      overlayStyle={{ zIndex: '999' }}
                      overlay={
                        <Menu>
                          {bioTreatmentFacilityTalon.status ===
                            bioTreatmentFacilityTalonStatusEnum.approved &&
                            props.canAddFact && (
                              <>
                                <Menu.Item
                                  key="menuTalonAddFact"
                                  onClick={() => {
                                    context.handleModalFact({
                                      ...INIT_MODAL_FACT,
                                      show: true,
                                      volumeId: null,
                                      licensePlates:
                                        bioTreatmentFacilityTalon.licensePlates,
                                      bioTreatmentFacilityFact: {
                                        bioTreatmentFacilityTalonId:
                                          bioTreatmentFacilityTalon.id,
                                      },
                                    });
                                  }}
                                >
                                  Внести факт
                                </Menu.Item>
                                <Menu.Item
                                  key="menuTalonPrint"
                                  onClick={() =>
                                    print(bioTreatmentFacilityTalon.id)
                                  }
                                >
                                  Печать талона
                                </Menu.Item>
                              </>
                            )}
                          {bioTreatmentFacilityTalon.status ===
                            bioTreatmentFacilityTalonStatusEnum.closed && (
                            <>
                              <Menu.Item
                                key="menuTalonPrint"
                                onClick={() =>
                                  print(bioTreatmentFacilityTalon.id)
                                }
                              >
                                Печать талона
                              </Menu.Item>
                            </>
                          )}
                          {bioTreatmentFacilityTalon.status ===
                            bioTreatmentFacilityTalonStatusEnum.created && (
                            <>
                              {props.canAddTalon && (
                                <Menu.Item
                                  key="menuTalonEdit"
                                  onClick={() => {
                                    context.handleModalTalon({
                                      ...INIT_MODAL_TALON,
                                      show: true,
                                      bioTreatmentFacilityTalon,
                                    });
                                  }}
                                >
                                  Редактировать
                                </Menu.Item>
                              )}
                              {props.canApprovingTalon && (
                                <Menu.Item
                                  key="menuTalonApprove"
                                  onClick={() =>
                                    handleApproved(bioTreatmentFacilityTalon)
                                  }
                                >
                                  Утвердить
                                </Menu.Item>
                              )}
                            </>
                          )}
                          {props.canAddTalon && (
                            <Menu.Item
                              key="menuTalonDelete"
                              className="span-delete-color"
                            >
                              <Popconfirm
                                title="Вы действительно хотите удалить?"
                                okText="Да"
                                cancelText="Нет"
                                placement="bottomRight"
                                onConfirm={() => {
                                  deleteTalon(bioTreatmentFacilityTalon.id);
                                }}
                              >
                                Удалить
                              </Popconfirm>
                            </Menu.Item>
                          )}
                        </Menu>
                      }
                    >
                      <EllipsisOutlined
                        style={{ fontSize: 16, color: '#2770FF' }}
                      />
                    </Dropdown>
                  </Operations>
                )
              );
            },
          },
        ]}
        dataSource={
          state.data.map((talon) => ({
            ...talon,
            licensePlates: props.volume?.licensePlates ?? [],
            bioTreatmentFacilityVolume: props.volume,
            key: `${props.volume?.id}${talon.id}`,
          })) ?? []
        }
        expandable={{
          expandedRowRender: (talon: BioTreatmentFacilityTalon) => {
            return (
              <Facts
                refetch={state.refetchFacts}
                talon={talon}
                contract={props.contract}
                canEditFact={props.canEditFact}
              />
            );
          },
          onExpand: props.onExpand,
          expandedRowKeys: [...props.expandedRowKeys.talons],
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </div>
  );
};

export const printTalon = async (id: number) => {
  try {
    notificationLoading({
      message: 'Печать...',
      key: 'print',
    });
    if (id) {
      await bioTreatmentFacilityTalonApi.print(id);
    }
  } catch (error) {
    notification.error({
      message: 'Ошибка',
      description: error.message,
    });
  } finally {
    notification.close('print');
  }
};
