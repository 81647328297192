// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import set from 'lodash/set';
import get from 'lodash/get';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';

import { contractVehicleDailyBudgetApi } from '../../../lib/api';
import { Buttons, SectionContent } from './elements';
import { SpreadsheetTable } from '../../../components/ui';
import Spinner from '../../../components/Spinner';
import type { SpreadsheetTableColumn } from '../../../components/ui/SpreadsheetTable';
import { notificationLoading } from '../../../components/Notifications';
import type {
  DailyPlanning,
  DailyPlanningDate
} from '../../../lib/types/contractVehicleDailyBudget';
import { getDates } from '../lib';
import type { UserAccess } from '../../../lib/types';
import {
  accessTypeEnum,
  operationLimitGroupStatusEnum,
  vehicleTypes
} from '../../../lib/enum';
import type { AppState } from '../../../ducks/redux';
import { Day } from '../elements';
import Button from 'antd/lib/button';
import Section from '../../../components/layout/Section';
import { getStatus } from './lib';
import type { OperationLimitGroupStatus } from '../../../lib/types/operationLimitGroup';
import MonthFilter from './components/MonthFilter';
import type { MonthDailyBudgetFilterParams } from './components/MonthFilter';

const initialFilter: any = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type Props = {
  orgUnitId: number,
  userAccess: UserAccess[]
};

type State = {
  budgets: DailyPlanning[],
  // Неизменяемый план, нужен для возвращения предыдущих значений
  staticBudgets: DailyPlanning[],
  changedDateTimes: DailyPlanningDate[],
  monthColumns: SpreadsheetTableColumn[],
  loading: boolean,
  filter: MonthDailyBudgetFilterParams
};

/**
 * Планирование лимитных дней на год
 */
class MonthDailyBudgetTable extends React.Component<Props, State> {
  state = {
    budgets: [],
    staticBudgets: [],
    monthColumns: [],
    changedDateTimes: [],
    loading: false,
    filter: initialFilter
  };

  tableRef: any = null;

  async componentDidMount() {
    // Явно проставляем значения из queryString,
    // чтобы корректно работало очищение фильтра
    const {
      startDate = initialFilter.startDate,
      endDate = initialFilter.endDate
    } = qs.parse(window.location.search);
    // Делаем запрос на основе данных из query params
    this.setState(
      { filter: { startDate, endDate } },
      this.fetchContractVehicleDailyBudget
    );
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.orgUnitId !== this.props.orgUnitId) {
      await this.fetchContractVehicleDailyBudget();
    }
  }

  /**
   * Функция изменения значений лимитного дня
   * @param value Число (пробег, часы)
   * @param key Ключ
   * @param index Индекс лимита в массиве
   */
  onChangeDateValues = (value: number, key: string = '', index: number) => {
    const { budgets, changedDateTimes } = this.state;
    const item: DailyPlanning = { ...budgets[index] };
    value = parseFloat(value || 0);
    set(item, key, value);
    // Получаем ключ изменяемого дня для сохранения
    // его в список измененных дней, которые нужно будет отправить на сервер
    const datePath = key.replace(/(\.distance|\.hours)/g, '');
    const dateTime = get(item, datePath);
    const date = datePath.replace('dateTimes.', '');
    this.setState({
      budgets: Object.assign([...budgets], {
        [index]: item
      }),
      changedDateTimes: [
        ...changedDateTimes.filter(date => date.id !== dateTime.id),
        {
          contractVehicleId: item.vehicle.id,
          operationLimitId: item.timeLimit.id,
          // orgUnitId: employeeOrgUnitId,
          distance: 0,
          hours: 0,
          date,
          ...dateTime
        }
      ]
    });
  };

  /**
   * Функция сброса несохраненных значений
   */
  revertValues = () => {
    this.setState(
      prevState => ({
        // Клонируем неизменные значения
        // т.к. иначе будет некорректный сброс - из-за ссылок на объекты
        budgets: cloneDeep(prevState.staticBudgets),
        changedDateTimes: []
      }),
      () => {
        // После перерисовываем таблицу
        // иначе данные сбросятся только после сброка фокуса с текущей ячейки
        if (this.tableRef) {
          this.tableRef.forceUpdateGrids();
        }
      }
    );
  };

  /**
   * Является ли день выходным
   * @param day День
   */
  isWeekendDay = (day: string) => {
    const { budgets = [] } = this.state;
    if (!isEmpty(budgets)) {
      const { dateTimes } = budgets[0];
      return dateTimes[day] && dateTimes[day].isWeekend;
    }
  };

  /**
   * Генерирует колонки на основе приходящих с сервера данных
   * @param days Дни
   * @returns {Array<SpreadsheetTableColumn>} Массив колонок
   */
  getColumnsByMonth = (days: string[]) => {
    return days
      .sort((a, b) => new Date(a) - new Date(b))
      .map<SpreadsheetTableColumn>(day => {
        const isWeekend = this.isWeekendDay(day);
        return {
          header: {
            render: () => (
              <>
                {moment(day).format('DD MMM')}
                <Day isWeekend={isWeekend}>{moment(day).format('ddd')}</Day>
              </>
            )
          },
          columns: [
            {
              header: {
                title: 'Часы'
              },
              cell: {
                editable: this.canHandling(),
                compareWith: {
                  key: isWeekend ? 'timeLimit.hoursWeekend' : 'timeLimit.hours',
                  compare: (cellValue: number, compareValue: number) =>
                    cellValue !== compareValue
                      ? `Значение изменено. Значение согласно регламенту: ${compareValue}`
                      : null,
                  backgroundColor: '#fff3f1'
                },
                keyPath: `dateTimes.${day}.hours`
              },
              width: 60
            },
            {
              header: {
                title: 'Пробег'
              },
              cell: {
                editable: this.canHandling(),
                keyPath: `dateTimes.${day}.distance`,
                compareWith: {
                  key: isWeekend
                    ? 'timeLimit.distanceWeekend'
                    : 'timeLimit.distance',
                  compare: (cellValue: number, compareValue: number) =>
                    cellValue !== compareValue
                      ? `Значение изменено. Значение согласно регламенту: ${compareValue}`
                      : null,
                  backgroundColor: '#fff3f1'
                },
                cellStyle: {
                  background: 'rgba(196, 196, 196, 0.1)'
                }
              },
              width: 60
            }
          ]
        };
      });
  };

  columns = [
    {
      header: {
        title: 'ТС'
      },
      cell: {
        keyPath: 'vehicle.licensePlate',
        formatValue: (licensePlate: string, row: any) => (
          <p>
            {licensePlate} <br />
            {vehicleTypes[row.vehicle.type]}
          </p>
        )
      },
      width: 180
    },
    {
      header: {
        title: 'Группа'
      },
      cell: {
        keyPath: 'groupName'
      },
      width: 115
    },
    {
      header: {
        title: 'Закрепление'
      },
      columns: [
        {
          header: {
            title: 'Служба'
          },
          cell: {
            keyPath: 'timeLimit.orgUnitName',
            height: 50
          },
          width: 210
        },
        {
          header: {
            title: 'Назначение'
          },
          cell: {
            keyPath: 'timeLimit.targetName',
            formatValue: (value: any, rowValue: DailyPlanning) =>
              rowValue.timeLimit.type !== 'orgUnit' && value
          },
          width: 175
        }
      ]
    },
    {
      header: {
        title: 'Регламент'
      },
      columns: [
        {
          header: {
            title: 'Будние'
          },
          columns: [
            {
              header: {
                title: 'Часы'
              },
              cell: {
                keyPath: 'timeLimit.hours'
              },
              width: 60
            },
            {
              header: {
                title: 'Пробег'
              },
              cell: {
                keyPath: 'timeLimit.distance'
              },
              width: 60
            }
          ]
        },
        {
          header: {
            title: 'Выходные'
          },
          columns: [
            {
              header: { title: 'Часы' },
              cell: { keyPath: 'timeLimit.hoursWeekend' },
              width: 60
            },
            {
              header: {
                title: 'Пробег'
              },
              cell: {
                keyPath: 'timeLimit.distanceWeekend'
              },
              width: 60
            }
          ]
        }
      ]
    }
  ];

  handlePrint = async () => {
    const {
      filter: { startDate, endDate }
    } = this.state;
    try {
      notificationLoading({
        message: 'Загрузка файла...',
        key: 'print'
      });
      await contractVehicleDailyBudgetApi.print(startDate, endDate);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  /**
   * Сохранение лимитных дней
   */
  handleSave = async () => {
    try {
      const { changedDateTimes } = this.state;
      notificationLoading({
        message: 'Идет сохранение данных...',
        key: 'saving'
      });
      await Promise.all(
        changedDateTimes.map(async (value: DailyPlanningDate) => {
          if (value.id) {
            await contractVehicleDailyBudgetApi.updateDailyPlanningDate(value);
          } else {
            await contractVehicleDailyBudgetApi.createDailyPlanningDate(value);
          }
        })
      );
      await this.changeStatus(operationLimitGroupStatusEnum.draft);
      this.setState({ changedDateTimes: [] });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  fetchContractVehicleDailyBudget = async () => {
    const { filter } = this.state;
    const { orgUnitId } = this.props;
    try {
      this.setState({ loading: true });
      const budgets = await contractVehicleDailyBudgetApi.fetchDailyBudgets({
        ...filter,
        orgUnitId
      });
      const dates = getDates(filter.startDate, filter.endDate);
      this.setState(
        {
          budgets,
          staticBudgets: cloneDeep(budgets),
          loading: false
        },
        () => {
          this.setState({ monthColumns: this.getColumnsByMonth(dates) });
        }
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  applyFilter = (filter: MonthDailyBudgetFilterParams) =>
    this.setState({ filter }, this.fetchContractVehicleDailyBudget);

  cleanFilter = () =>
    this.setState(
      {
        filter: initialFilter
      },
      this.fetchContractVehicleDailyBudget
    );

  /**
   * Проверка на валидность года
   *
   * Нельзя менять статус года, который не является следующим
   */
  isValidMonth = () => {
    // const { endDate, startDate } = this.state.filter;
    // const startMonth = moment.utc(startDate).month();
    // const endMonth = moment.utc(endDate).month();
    return true;
    // Месяц в фильтре должен быть одним
    // if (startMonth !== endMonth) {
    //   return false;
    // } else {
    //   return moment(startDate).isAfter(new Date());
    // }
  };

  /**
   * Возможность редактирования пользователем
   */
  canHandling = () => {
    const status = getStatus(this.state.budgets, 'monthStatus');
    const yearStatus = getStatus(this.state.budgets, 'yearStatus');
    return (
      this.isValidMonth() &&
      yearStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.draft &&
      this.props.userAccess.some(access =>
        [accessTypeEnum.admin, accessTypeEnum.handlingMonthLimitsPlan].includes(
          access
        )
      )
    );
  };

  /**
   * Возможность отправки на утверждение пользователем
   */
  canSendToAgreeing = () => {
    const status = getStatus(this.state.budgets, 'monthStatus');
    const yearStatus = getStatus(this.state.budgets, 'yearStatus');
    return (
      this.isValidMonth() &&
      this.canHandling() &&
      yearStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.draft
    );
  };

  /**
   * Возможность утверждения пользователем
   */
  canApprove = () => {
    const status = getStatus(this.state.budgets, 'monthStatus');
    const yearStatus = getStatus(this.state.budgets, 'yearStatus');
    return (
      this.isValidMonth() &&
      status === operationLimitGroupStatusEnum.onAgreeing &&
      yearStatus === operationLimitGroupStatusEnum.approved &&
      this.props.userAccess.some(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.approvingMonthLimitsPlan
        ].includes(access)
      )
    );
  };

  changeStatus = async (status: OperationLimitGroupStatus) => {
    const { endDate } = this.state.filter;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const budgets = await Promise.all(
        this.state.budgets.map(
          async budget =>
            await contractVehicleDailyBudgetApi.updateBudgets({
              startDate: moment
                .utc(endDate)
                .startOf('month')
                .toISOString(),
              endDate: moment
                .utc(endDate)
                .endOf('month')
                .toISOString(),
              isYearStatus: false,
              limitId: budget.timeLimit.id,
              status
            })
        )
      );
      this.setState({ budgets });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleSendToAgreeing = async () => {
    await this.changeStatus(operationLimitGroupStatusEnum.onAgreeing);
    notification.success({
      message: 'Месячный план был отправлен на согласование'
    });
  };

  handleApprove = async () => {
    await this.changeStatus(operationLimitGroupStatusEnum.approved);
    notification.success({
      message: 'Месячный план был утвержден'
    });
  };

  render() {
    const {
      loading,
      budgets,
      filter,
      monthColumns,
      changedDateTimes
    } = this.state;

    const hasChangedValues = !isEmpty(changedDateTimes);
    const canSendToAgreeing = !hasChangedValues && this.canSendToAgreeing();
    const canHandling = hasChangedValues && this.canHandling();
    const canApprove = !hasChangedValues && this.canApprove();
    return (
      <>
        <Section>
          <SectionContent>
            <MonthFilter
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </SectionContent>
          {loading ? (
            <SectionContent>
              <Spinner isLoading />
            </SectionContent>
          ) : (
            <>
              <SpreadsheetTable
                fixedColumnCount={1}
                ref={table => (this.tableRef = table)}
                style={{ height: 'calc(100vh - 317px)' }}
                onChange={this.onChangeDateValues}
                data={budgets}
                notFoundText="Данных о бюджете нет"
                columns={[...this.columns, ...monthColumns]}
              />
            </>
          )}
        </Section>
        <Section>
          <SectionContent>
            <Buttons>
              <Button onClick={this.handlePrint}>Печать</Button>
              {hasChangedValues && (
                <Button type="primary" onClick={this.revertValues}>
                  Отменить правки
                </Button>
              )}
              {canHandling && (
                <Button type="primary" onClick={this.handleSave}>
                  Сохранить
                </Button>
              )}
              {canSendToAgreeing && (
                <Button type="primary" onClick={this.handleSendToAgreeing}>
                  На утверждение
                </Button>
              )}
              {canApprove && (
                <Button type="primary" onClick={this.handleApprove}>
                  Утвердить
                </Button>
              )}
            </Buttons>
          </SectionContent>
        </Section>
      </>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeOrgUnitId,
  userAccess: state.auth.profile.access
}))(MonthDailyBudgetTable);
