import {
    PddResultCard,
    PddResultExamList,
    PddResultList,
    PddResultTrainingList,
} from '../containers/pdd/Results';
import {
    PddTestTrainingCard,
    PddTestTrainingCardExam,
    PddTestTrainingList,
    PddTestTrainingResultsList,
} from '../containers/pdd/Test/Training';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: PddResultTrainingList,
        path: '/pdd/results/training',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.reloginPddTest,
        ],
    },
    {
        component: PddResultExamList,
        path: '/pdd/results/exam',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.reloginPddTest,
        ],
    },
    {
        component: PddResultList,
        path: '/pdd/results/training/:testId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.reloginPddTest,
        ],
    },
    {
        component: PddResultCard,
        path: '/pdd/results/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.reloginPddTest,
            accessTypeEnum.viewingPddTests,
        ],
    },
    {
        component: PddTestTrainingList,
        path: '/pdd/test/:examTraining/tests',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingPddTests,
        ],
    },
    {
        component: PddTestTrainingResultsList,
        path: '/pdd/test/:examTraining/results',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingPddTests,
        ],
    },
    {
        component: PddTestTrainingCard,
        path: '/pdd/test/:examTraining/test-exam',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingPddTests,
        ],
    },
    {
        component: PddTestTrainingCardExam,
        path: '/pdd/test/:examTraining/exam/:id/',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingPddTests,
        ],
    },
    {
        component: PddTestTrainingCardExam,
        path: '/pdd/test/:examTraining/exam/:id/:ticketId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingPddTests,
        ],
    },
];