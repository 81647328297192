import { CrewForm, CrewList } from '../../containers/Crew';
import { accessTypeEnum } from '../../lib/enum';
//   бригады
export default [
    {
        component: CrewList,
        path: '/admin/crew',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingCrew,
            accessTypeEnum.handlingCrew,
        ],
    },
    {
        component: CrewForm,
        path: '/admin/crew/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingCrew],
    },
    {
        component: CrewForm,
        path: '/admin/crew/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingCrew],
    },
];