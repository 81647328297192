import React, { Component } from 'react';

import notification from 'antd/lib/notification';
import type { FormikErrors } from 'formik';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';

import type { AppState } from '../../ducks/redux';
import {
  cleanTrip,
  verifyTrip,
  fetchTripForVerification,
  changeStatus,
} from '../../ducks/trip';
import {
  shiftApi,
  tripRangeApi,
  tripApi,
  routeApi,
  attachedEquipmentApi,
  tripAttachedEquipmentApi,
} from '../../lib/api';
import {
  tripStatusEnum,
  attachedEquipmentFuelTankTypeEnum,
} from '../../lib/enum';
import type {
  Trip,
  TripRange,
  AttachedEquipment,
  KilometrageInfo,
  TripStatus,
  TripAttachedEquipment,
  UserAccess,
} from '../../lib/types';
import { Panel } from './../../components/layout';
import {
  formatDateRangeString,
  convertFromMToKm,
  navigate,
  plus,
  minus,
  multipliedBy,
} from '../../lib/helpers';

import { directions } from '../../lib/gis';
import VerificationForm from './components/VerificationForm';
import { notificationLoading } from '../../components/Notifications';

type Props = {
  trip: Trip,
  fetchTripForVerification: (id: number, onCancel: () => any) => Promise<Trip>,
  verifyTrip: (trip: Trip) => void,
  cleanTrip: () => void,
  updateTrip: Function,
  tripId: number,
  employeeId: number,
  changeStatus: Function,
  userAccess: UserAccess[],
};

type State = {
  kilometragePopconfirmVisible: boolean,
  kilometrage: number,
  trip: Trip,
  actualRouteGeometry: any,
  shiftEmployees: any,
  vehicleAttachedEquipments: AttachedEquipment[],
  attachedEquipments: any,
};

export class TripVerification extends Component<Props, State> {
  state = {
    kilometragePopconfirmVisible: false,
    kilometrage: 0,
    trip: this.props.trip,
    actualRouteGeometry: null,
    shiftEmployees: {},
    vehicleAttachedEquipments: [],
    attachedEquipments: {},
  };

  async componentDidMount() {
    const { tripId, trip } = this.props;
    try {
      await this.props.cleanTrip();
      await this.props.fetchTripForVerification(
        parseInt(tripId, 10),
        this.handleCancel
      );
      // Если ТС заархивирована
      if (trip && trip.vehicle) {
        if (trip.vehicle.isDeleted) {
          await navigate(`/trips/self/${tripId}/card`);
          notification.error({
            message: 'Нельзя таксировать заархивированные ТС',
          });
        }
      }
      const shiftEmployees = await shiftApi.fetchShiftEmployeesByTripId(tripId);
      this.setState({
        shiftEmployees,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  /**
   * Проверка на соответствие количества маш. часов по счетчику маш. часов ТС
   * и суммы маш. часов навесного оборудования
   */
  engineWorkHoursIsValid = (trip: Trip) => {
    if (trip.machineHoursFuelConsumption) {
      const machineHoursFuelConsumption = parseFloat(
        trip.machineHoursFuelConsumption || 0
      );
      const vehicleMachineHoursAtStart = parseFloat(
        trip.vehicleMachineHoursAtStart || 0
      );
      const vehicleMachineHoursAtEnd = parseFloat(
        trip.vehicleMachineHoursAtEnd || 0
      );
      const workHours = vehicleMachineHoursAtEnd - vehicleMachineHoursAtStart;
      return machineHoursFuelConsumption <= workHours;
    }
    return true;
  };

  async changeStatus(status: TripStatus) {
    await this.props.changeStatus(parseInt(this.props.tripId, 10), status);
  }

  handleCancel = async () => {
    navigate(`/trips/self/${this.props.tripId}/card`);
  };

  handleSubmit = async (trip: Trip) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const { tripRanges = [] } = trip;
      const tripRangesAmount = sumBy(tripRanges, 'amount');
      const distance = parseFloat(
        (trip.odometerAtEnd - trip.odometerAtStart).toFixed(2)
      );
      if (tripRanges.length > 0 && distance !== tripRangesAmount) {
        notification.error({
          message: 'Ошибка',
          description:
            'Сумма отрезков маршрута с топливными коэффициентами должна совпадать со значением пробега',
        });
        return;
      }
      const res = await Promise.all(
        trip.attachedEquipments.map(async (tripAttachedEquipment) => {
          if (tripAttachedEquipment.id) {
            return await tripAttachedEquipmentApi.update({
              ...tripAttachedEquipment,
              trip: undefined,
              attachedEquipment: undefined,
            });
          } else {
            return await tripAttachedEquipmentApi.add({
              ...tripAttachedEquipment,
              trip: undefined,
              attachedEquipment: undefined,
            });
          }
        })
      );
      await this.props.verifyTrip({
        ...trip,
        attachedEquipments: res,
      });
      await this.changeStatus(tripStatusEnum.verified);
      await Promise.all(
        tripRanges.map((tripRange: TripRange) =>
          tripRangeApi.addTripRange({
            ...tripRange,
            sumFuelMultiplier: sumBy(tripRange.fuelMultipliers, 'value') || 0,
          })
        )
      );
      notification.success({
        message: 'Успешно',
        description: 'Таксировка прошла успешно',
      });
      navigate(`/trips/self/${this.props.tripId}/card`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  validate = (values: Trip) => {
    let errors: FormikErrors<Trip> = {};
    if (!values.odometerAtEnd) {
      errors.odometerAtEnd = 'Обязательное поле';
    } else if (values.odometerAtEnd < values.odometerAtStart) {
      errors.odometerAtEnd =
        'Пробег при выезде не может быть меньше фактического пробега';
    }
    if (!values.endDate) {
      errors.endDate = 'Обязательное поле';
    }
    if (!values.startMedicId) {
      errors.startMedicId = 'Обязательное поле';
    }
    if (!values.startMedicCheckupDate) {
      errors.startMedicCheckupDate = 'Обязательное поле';
    }
    if (!values.endMedicId) {
      errors.endMedicId = 'Обязательное поле';
    }
    if (!values.endMedicCheckupDate) {
      errors.endMedicCheckupDate = 'Обязательное поле';
    }

    if (!values.startMechanicId) {
      errors.startMechanicId = 'Обязательное поле';
    }
    if (!values.startTechCheckupDate) {
      errors.startTechCheckupDate = 'Обязательное поле';
    }
    if (!values.endMechanicId) {
      errors.endMechanicId = 'Обязательное поле';
    }
    if (!values.endTechCheckupDate) {
      errors.endTechCheckupDate = 'Обязательное поле';
    }
    const fuelAtEnd = parseFloat(values.fuelAtEnd);
    // Такая проверка нужна, чтоб убрать NaN
    if (fuelAtEnd < 0 || !fuelAtEnd) {
      errors.fuelAtEnd = 'Неверное количество топлива';
    }
    if (
      values.vehicle &&
      parseFloat(values.vehicle.vehicleModel.primaryEquipmentFuelConsumption) >
        0
    ) {
      const vehicleMachineHoursAtEnd = parseFloat(
        values.vehicleMachineHoursAtEnd
      );
      if (isNaN(vehicleMachineHoursAtEnd)) {
        errors.vehicleMachineHoursAtEnd = 'Обязательное поле';
      } else if (
        vehicleMachineHoursAtEnd < parseFloat(values.vehicleMachineHoursAtStart)
      ) {
        errors.vehicleMachineHoursAtEnd =
          'Значение не может быть меньше начального значения';
      }
    }
    return errors;
  };

  async componentDidUpdate(prevProps: Props) {
    if (this.props.trip && !prevProps.trip) {
      const trip = this.props.trip;
      let attachedEquipments = [];
      let tripAttachedEquipmentIds = new Set();
      if (trip && trip.vehicle) {
        tripAttachedEquipmentIds = new Set(
          trip?.attachedEquipments.map(
            (attached) => attached.attachedEquipmentId
          ) ?? []
        );
        attachedEquipments =
          await attachedEquipmentApi.fetchVehicleAttachedEquipments(
            trip.vehicleId
          );
      }
      attachedEquipments.forEach((attachedEquipment) => {
        if (!tripAttachedEquipmentIds.has(attachedEquipment.id)) {
          const isOwnTank =
            attachedEquipment.fuelTankType ===
            attachedEquipmentFuelTankTypeEnum.ownTank;
          trip.attachedEquipments.push({
            attachedEquipment,
            attachedEquipmentId: attachedEquipment.id,
            trip,
            tripId: trip.id,
            machineHoursAtStart: attachedEquipment.machineHours ?? 0,
            machineHoursAtEnd: attachedEquipment.machineHours ?? 0,
            fuelAmountAtStart: isOwnTank
              ? attachedEquipment.fuelRemaining ?? 0
              : undefined,
            fuelAmountAtEnd: isOwnTank
              ? attachedEquipment.fuelRemaining ?? 0
              : undefined,
            fuelIssued: isOwnTank ? 0 : undefined,
          });
        }
      });
      this.setState({ trip, attachedEquipments });
      if (trip.status === tripStatusEnum.verified) {
        navigate(`/trips/self/${this.props.tripId}/card`);
      }
    }
  }

  getInitialValues = (): $Shape<Trip> => {
    const { trip, shiftEmployees } = this.state;
    if (trip) {
      const clonedTrip = cloneDeep(trip);
      if (!trip.startMedicId && shiftEmployees.medic) {
        clonedTrip.startMedicId = shiftEmployees.medic.id;
      }
      if (!trip.startMechanicId && shiftEmployees.engineer) {
        clonedTrip.startMechanicId = shiftEmployees.engineer.id;
      }
      return this.calculateFuelConsumption(trip);
    }
    return {};
  };

  /** Подсчет расхода доп.оборудования */
  calculateAttachedEquipmentFuelConsumption = (
    values: TripAttachedEquipment
  ) => {
    return {
      fuelAmountAtEnd: minus(
        plus(values.fuelAmountAtStart, values.fuelIssued),
        multipliedBy(
          minus(values.machineHoursAtEnd, values.machineHoursAtStart),
          values?.attachedEquipment?.fuelConsumption ?? 0
        )
      ),
    };
  };

  /**
   * Подсчет расхода по машчасам
   *
   * ОФР - Общий фактический расход
   * ФРТС - Фактический расход ТС
   * ФРО - Фактический расход оборудования
   * МЧТС - Маш. часы ТС
   * МЧО - Маш. часы оборудования
   * НРТС - Нормативный расход ТС
   * НРО - Нормативный расход оборудования
   *
   * НПМЧ - Показания счетчика маш. часов при выезде (начальные)
   * КПМЧ - Показания счетчика маш. часов при возвращении (конечные)
   *
   * Формула:
   *   // Общий расход
   *   ОФР = ФРТС + ФРО;
   *   // Фактический расход ТС
   *   ФРТС = МЧТС * ФРТС;
   *   // Маш. часы ТС
   *   МЧТС = КПМЧ - НПМЧ;
   *   // Фактический расход оборудования
   *   ФРО = НРО * МЧО;
   */
  calculateWorkHoursFuelConsumption = (trip: Trip) => {
    const vehicleMachineHoursAtStart = parseFloat(
      trip.vehicleMachineHoursAtStart || 0
    );
    const vehicleMachineHoursAtEnd = parseFloat(
      trip.vehicleMachineHoursAtEnd || vehicleMachineHoursAtStart
    );
    const machineHoursFuelConsumption = parseFloat(
      trip.machineHoursFuelConsumption || 0
    );
    const attachedEquipmentMachineHours = parseFloat(
      trip.attachedEquipmentMachineHours || 0
    );
    const attachedEquipmentFuelConsumption = parseFloat(
      trip.attachedEquipmentFuelConsumption || 0
    );

    const machineHours = vehicleMachineHoursAtEnd - vehicleMachineHoursAtStart;

    // считаем сколько навесное оборудование без бака потратило топлива
    let attachedEquipmenFuel = 0;
    for (let i = 0; i < trip?.attachedEquipments?.length ?? 0; i++) {
      const { machineHoursAtEnd, machineHoursAtStart, attachedEquipment } =
        trip?.attachedEquipments[i];
      if (
        attachedEquipment?.fuelTankType ===
        attachedEquipmentFuelTankTypeEnum.vehicleTank
      ) {
        attachedEquipmenFuel = plus(
          attachedEquipmenFuel,
          multipliedBy(
            minus(machineHoursAtEnd, machineHoursAtStart),
            attachedEquipment?.fuelConsumption ?? 0
          )
        );
      }
    }
    return plus(
      plus(
        multipliedBy(machineHoursFuelConsumption, machineHours),
        multipliedBy(
          attachedEquipmentMachineHours,
          attachedEquipmentFuelConsumption
        )
      ),
      attachedEquipmenFuel
    );
  };

  /**
   * Подсчет расхода по пробегу с коэффициентами
   *
   *  ФР - Фактический расход
   *  НРТС - Нормативный расход ТС, л/1(!)км
   *  НПО - Начальные показания одометра
   *  КПО - Конечные показания одометра
   *  Д - Дистанция
   *  О - Отрезки рейса с длиной и суммарным коэффициентом
   *  ДО - Длина отрезка
   *  СКО - Суммарный коэффициент отрезка
   *  СКТС - Суммарный коэффициент ТС
   *
   *  Формула:
   *  Д = КПО - НПО
   *    При наличии О, ФР считается след. образом:
   *      ФР = ЦИКЛ(ФР + (НРТС * ДО * (1 + СКО)))
   *    При отсутствии О:
   *      ФР = НРТС + Д + (1 + СКТС)
   */
  calculateDistanceFuelConsumption = (trip: Trip) => {
    const distance = parseFloat(trip.odometerAtEnd - trip.odometerAtStart);
    if (!distance || distance < 0) {
      return 0;
    }
    const tripRanges = trip.tripRanges || [];
    // Переводим расход в л/км
    const primaryFuelConsumption =
      ((trip.vehicle && trip.vehicle.vehicleModel.primaryFuelConsumption) ||
        0) / 100;

    const sumFuelMultiplier = trip.sumFuelMultiplier
      ? 1 + trip.sumFuelMultiplier
      : 1;
    let fuelConsumption = primaryFuelConsumption * sumFuelMultiplier * distance;
    if (tripRanges.length) {
      tripRanges.forEach((tripRange: TripRange) => {
        fuelConsumption +=
          primaryFuelConsumption *
          tripRange.amount *
          tripRange.sumFuelMultiplier;
      });
      return fuelConsumption;
    }
    return fuelConsumption;
  };

  /**
   * Подсчет оставшегося уровня топлива
   *
   * ФР - Фактический расход (фактический расход по маш. часам + расход по пробегу)
   * В - Выдано ГСМ
   * НУТ - Начальный уровень топлива
   * КУТ - Конечный уровень топлива
   *
   * Формула:
   *  КУТ = НУТ + В - ФР
   */
  calculateFuelAtEnd = (trip: Trip) => {
    const actualFuelConsumption =
      parseFloat((trip.engineWorkFuelConsumption || 0).toFixed(2)) +
      parseFloat((trip.distanceFuelConsumption || 0).toFixed(2));
    const fuelAtEnd =
      (trip.fuelAtStart || 0) + (trip.issuedFuel || 0) - actualFuelConsumption;
    return parseFloat(fuelAtEnd.toFixed(2));
  };

  calculateFuelConsumption = (trip: Trip) => {
    const engineWorkFuelConsumption =
      this.calculateWorkHoursFuelConsumption(trip);
    const distanceFuelConsumption = this.calculateDistanceFuelConsumption(trip);
    return {
      ...trip,
      engineWorkFuelConsumption,
      distanceFuelConsumption,
      fuelAtEnd: this.calculateFuelAtEnd({
        ...trip,
        engineWorkFuelConsumption,
        distanceFuelConsumption,
      }),
    };
  };

  calculateRoute = async (trip: Trip) => {
    try {
      notificationLoading({
        message: 'Построение маршрута...',
        key: 'buildingRoute',
      });
      const actualWaypoints =
        (trip.actualRoute && trip.actualRoute.waypoints) || [];

      const actualRouteGeometry = await directions(actualWaypoints);
      if (actualRouteGeometry) {
        this.setState((prevState: State) => {
          const distanceAtStart = convertFromMToKm(
            actualRouteGeometry.distanceAtStart
          );
          const distanceAtEnd = convertFromMToKm(
            actualRouteGeometry.distanceAtEnd
          );
          return {
            actualRouteGeometry,
            trip: {
              ...trip,
              distanceAtStart,
              distanceAtEnd,
            },
          };
        });
        notification.success({
          message: 'Маршрут успешно построен',
        });
      }
      this.setState({
        actualRouteGeometry,
      });
      if (trip.actualRoute) {
        await routeApi.updateRoute(trip.actualRoute);
      }
      const attachedEquipments =
        trip?.attachedEquipments?.map(async (tripAttachedEquipment) => {
          return {
            // $FlowFixMe
            ...tripAttachedEquipment,
            trip: undefined,
            attachedEquipment: undefined,
          };
        }) ?? [];
      await tripApi.updateTrip({
        ...trip,
        attachedEquipments,
      });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при построении маршрута',
        description: err ? err.message : '',
      });
    } finally {
      notification.close('buildingRoute');
    }
  };

  /**
   * Получение данных о пробеге за маршрут из АвтоГРАФа
   */
  getKilometrage = async (): Promise<?KilometrageInfo> => {
    try {
      notificationLoading({
        message: 'Получение данных',
        key: 'getKilometrage',
      });
      const kilometrage = await tripApi.getTripKilometrage(this.props.tripId);
      return {
        ...kilometrage,
        monitoringDistance: convertFromMToKm(kilometrage.monitoringDistance),
      };
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('getKilometrage');
    }
  };

  render() {
    const { trip, actualRouteGeometry, shiftEmployees } = this.state;
    return (
      <>
        <Panel>
          <h1>Таксировка</h1>
          {trip && (
            <p>
              Путевой лист №{trip.idNumber}
              &nbsp;
              <span>{formatDateRangeString(trip.startDate, trip.endDate)}</span>
            </p>
          )}
          <VerificationForm
            userAccess={this.props.userAccess}
            trip={this.getInitialValues()}
            employeeId={this.props.employeeId}
            onCancel={this.handleCancel}
            onSubmit={this.handleSubmit}
            getKilometrage={this.getKilometrage}
            actualRouteGeometry={actualRouteGeometry}
            calculateRoute={this.calculateRoute}
            handleCancel={this.handleCancel}
            shiftEmployees={shiftEmployees}
            calculateWorkHoursFuelConsumption={
              this.calculateWorkHoursFuelConsumption
            }
            calculateDistanceFuelConsumption={
              this.calculateDistanceFuelConsumption
            }
            calculateFuelConsumption={this.calculateFuelConsumption}
            calculateFuelAtEnd={this.calculateFuelAtEnd}
            calculateAttachedEquipmentFuelConsumption={
              this.calculateAttachedEquipmentFuelConsumption
            }
          />
        </Panel>
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    trip: state.trip,
    tripId: parseInt(ownProps.tripId, 10),
    employeeId: state.auth.profile.employeeId,
    userAccess: state.auth.profile.access,
  }),
  {
    verifyTrip,
    cleanTrip,
    fetchTripForVerification,
    changeStatus,
  }
)(TripVerification);
