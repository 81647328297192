// @flow
import { Link } from '@reach/router';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';

import CommonListPage from '../../../../components/hoc/common/handbook/ListPage';
import Page from '../../../../components/hoc/common/handbook/PageContext';

import { pddTestApi, pddTestResultApi } from '../../../../lib/api';
import { getBreadCrumbsByUrl } from '../../../../lib/autoBreadcrumbs';
import { accessTypeEnum, pddTest } from '../../../../lib/enum';
import { formatSecondsToHumanTime, navigate } from '../../../../lib/helpers';
import type {
  PddGroup,
  PddTestResult,
  PddTestType,
  UserAccess,
} from '../../../../lib/types';
import { withUserAccess } from '../../../withUserAccess';

import { Filter, HeaderTabs } from './components';
import RightHeader from './components/RightHeader';

type PageProps = {
  examTraining: PddTestType,
  userAccess: UserAccess[],
};

export default withUserAccess((props: PageProps) => {
  const entryPointPath = `/pdd/test/${props.examTraining}/tests/`;
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

  const [canRelogin, setCanRelogin] = useState(false);
  const [canStartTest, setCanStartTest] = useState(false);

  const wrappedPddTestApi = {
    ...pddTestApi,
    fetch: async (params): Promise<any> =>
      await pddTestApi.fetch({ ...params, type: props.examTraining }),
  };

  const columns = [
    {
      title: '',
      dataIndex: 'pddTestResultId',
      key: 'id',
      render: (pddTestResultId: number, record) =>
        canStartTest &&
        (pddTestResultId ? (
          <Button
            onClick={async () => {
              const pddTestResult: PddTestResult = await pddTestResultApi.get(
                pddTestResultId
              );
              if (pddTestResult.pddTicketId) {
                await navigate(
                  `/pdd/test/${props.examTraining}/exam/${record.id}/${pddTestResult.pddTicketId}`
                );
              }
            }}
          >
            Повторить
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/pdd/test/${props.examTraining}/exam/${record.id}`)
            }
          >
            Начать тестирование
          </Button>
        )),
    },
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Тема обучения',
      dataIndex: 'pddTestGroups',
      width: 400,
      render: (pddTestGroups?: PddGroup[]) => (
        <div style={{ whiteSpace: 'break-spaces' }}>
          {pddTestGroups?.map((el) => el.pddGroup.name).join(', ')}
        </div>
      ),
    },
    {
      title: 'Наименование теста',
      width: 300,
      dataIndex: 'name',
    },
    {
      title: 'Время',
      dataIndex: 'examTime',
      render: (examTime: number) =>
        examTime > 0 ? formatSecondsToHumanTime(examTime) : '',
    },
    {
      title: 'Кол-во вопросов',
      dataIndex: 'questionsCount',
    },
    {
      title: 'Статус',
      dataIndex: 'pddTestResultId',
      render: (pddTestResultId?: number) => (pddTestResultId ? 'Пройден' : ''),
    },
    {
      title: 'Ссылка на результат',
      dataIndex: 'pddTestResultId',
      render: (pddTestResultId?: number) =>
        pddTestResultId ? (
          <Link to={`/pdd/results/${pddTestResultId}`}>Перейти</Link>
        ) : (
          ''
        ),
    },
  ];

  useEffect(() => {
    setCanRelogin(
      props.userAccess.find((access) =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.reloginPddTest,
        ].includes(access)
      )
    );
    setCanStartTest(
      props.userAccess.find((access) =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.viewingPddTests,
        ].includes(access)
      )
    );
  }, [props.userAccess]);

  useEffect(() => {
    if (props.examTraining === pddTest.exam) {
      navigate(`/pdd/test/exam/results`);
    }
  }, [props.examTraining]);

  return (
    <Page>
      <CommonListPage
        crud={wrappedPddTestApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader:
            props.examTraining === pddTest.training
              ? 'Тестирование в режиме обучения'
              : 'Экзамен',
          rightHeader: (
            <RightHeader
              examTraining={props.examTraining}
              canRelogin={canRelogin}
              canStartTest={canStartTest}
            />
          ),
        }}
        subHeader={
          <HeaderTabs
            selectedTab={
              props.examTraining === pddTest.training ? 'tests' : 'results'
            }
            examTraining={props.examTraining}
          />
        }
        tableListProps={{
          columns,
        }}
        filter={Filter}
      />
    </Page>
  );
});
