// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { budgetVersionApi } from './../../lib/api';
import Page from '../../components/hoc/common/handbook/PageContext';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import { withUserAccess } from './../withUserAccess';

import addAccessRight from './accessRight';

const entryPointPath = '/admin/budget-version/';

const columns = [
  {
    title: 'Наименование',
    dataIndex: 'name'
  },
  {
    title: 'Доступно для выбора пользователем при формирование предложений',
    dataIndex: 'isAvailableForSelect',
    render: available => (available ? 'Доступно' : 'Недоступно')
  }
];

const breadCrumbs = [
  { to: '/', name: 'Главная' },
  { name: 'Справочники' },
  { name: 'Версии бюджета' }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const canAdd = () =>
    userAccess.some(access => addAccessRight.includes(access));

  return (
    <Page>
      <CommonListPage
        crud={budgetVersionApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Версии бюджета',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canAdd()
        }}
      />
    </Page>
  );
});
