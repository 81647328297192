// @flow
import React from 'react';
import type { FormikProps } from 'formik';
import Button from 'antd/lib/button';
import styled from 'styled-components';

import { Form } from './../../../components';
import { Section, Footer } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { FormFieldType } from './../../../components/Form';
import CancelButton from './../../../components/CancelButton';
import Input from 'antd/lib/input';
import type { WashingVehicleType } from '../../../lib/types/washingPriceList';

const SectionContent = styled.div`
  padding: 16px;
`;

type FormProps = {
  washingVehicleType: ?WashingVehicleType,
  onSubmit: Function,
  onCancel: () => void
};

export default ({ washingVehicleType, onSubmit, onCancel }: FormProps) => {
  return (
    <Form initialValues={washingVehicleType} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, dirty } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Наименование типа ТС"
                      name="type"
                      required
                      fast
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите наименование типа ТС"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
