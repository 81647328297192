// @flow
import type { OverhaulType } from './../types';
import CommonApi from '../commonApi';

const overhaulType = new CommonApi<OverhaulType>(
  'overhaulType',
  'Виды работ по капитальному ремонту ТС'
);

export default {
  ...overhaulType
};
