// @flow
import type { MaintenanceOperationByModel } from './../types';
import CommonApi from './../commonApi';

const maintenanceOperationByModelApi = new CommonApi<MaintenanceOperationByModel>(
  'maintenanceOperationByModel',
  'Виды работ'
);

export default {
  ...maintenanceOperationByModelApi
};
