//@flow
import React, { useCallback } from 'react';
import { Input } from 'antd';

import { FilterItem } from '../../../../../components';
import Field from '../../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../../components/hoc/common/handbook/CommonFilter';
import Selects from '../../../../../components/selects';

const { PddGroupSelect, YesNoSelect } = Selects;

export type PddTestTrainingParamsFilter = {
  pddGroupId?: number,
  name?: string,
  hasResult?: boolean
};

export const PddTestTrainingFilter = (
  props: FilterProps<PddTestTrainingParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PddTestTrainingParamsFilter>>) =>
      CommonFilter<PddTestTrainingParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );

  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <PddGroupSelect
                size="small"
                value={values.pddGroupId}
                onChange={value => changeValue('pddGroupId', value)}
                placeholder="Тема обучения"
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                value={values.name}
                onChange={e => changeValue('name', e.target.value || undefined)}
                placeholder="Наименование теста"
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <YesNoSelect
                size="small"
                value={values.hasResult}
                onChange={value => changeValue('hasResult', value)}
                placeholder="Тест пройден"
                reset
                style={{ maxWidth: 200 }}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PddTestTrainingFilter;
