import React, { Component } from 'react';
import Form from './Form';

type Props = {
  regulationId: number
};

export default class extends Component<Props> {
  render() {
    return <Form {...this.props} readonly={true} />;
  }
}
