// @flow

import type { Dispatch } from 'redux';
import type { Action } from './redux';
import { fuelMultiplierApi as api } from './../lib/api';
import type { FuelMultiplier } from './../lib/types/fuelMultiplier';
import type { Vehicle } from './../lib/types';

export const FETCH_FUEL_MULTIPLIER = 'vehicles/fuelMultiplier/fetch';
export const ADD_FUEL_MULTIPLIER = 'vehicles/fuelMultiplier/add';
export const UPDATE_FUEL_MULTIPLIER = 'vehicles/fuelMultiplier/update';
export const DELETE_FUEL_MULTIPLIER = 'vehicles/fuelMultiplier/delete';
export const CLEAN_FUEL_MULTIPLIER = 'vehicles/fuelMultiplier/clean';

export type FuelMultiplierState = ?FuelMultiplier;

const reducer = (
  state: FuelMultiplierState = null,
  action: Action
): FuelMultiplierState => {
  switch (action.type) {
    case FETCH_FUEL_MULTIPLIER:
      return action.payload;
    case CLEAN_FUEL_MULTIPLIER:
    default:
      return null;
  }
};

export const addFuelMultiplier: Function = (
  multiplier: FuelMultiplier
): Function => async (dispatch: Dispatch): Promise<void> => {
  const created = await api.addFuelMultiplier(multiplier);

  dispatch({
    type: ADD_FUEL_MULTIPLIER,
    payload: created
  });
};

export const fetchFuelMultiplier: Function = (id: number): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const created = await api.fetchFuelMultiplier(id);

  dispatch({
    type: FETCH_FUEL_MULTIPLIER,
    payload: created
  });
};

export const updateFuelMultiplier: Function = (
  multiplier: FuelMultiplier
): Function => async (dispatch: Dispatch): Promise<void> => {
  const created = await api.updateFuelMultiplier(multiplier);

  dispatch({
    type: UPDATE_FUEL_MULTIPLIER,
    payload: created
  });
};

export const deleteFuelMultiplier: Function = (
  multiplier: FuelMultiplier
): Function => async (dispatch: Dispatch): Promise<void> => {
  const created = await api.deleteFuelMultiplier(parseInt(multiplier.id, 10));

  dispatch({
    type: DELETE_FUEL_MULTIPLIER,
    payload: created
  });
};

export const updateVehicleFuelMultiplier = (
  vehicle: Vehicle,
  multipliers: FuelMultiplier[]
) => async (dispatch: Dispatch) => {
  if (vehicle.fuelMultipliers) {
    for (let i = 0; i < vehicle.fuelMultipliers.length; i++) {
      let m = vehicle.fuelMultipliers[i];
      await api.deleteVehicleFuelMultiplier(vehicle.id, parseInt(m.id, 10));
    }
  }

  for (let i = 0; i < multipliers.length; i++) {
    let m = multipliers[i];
    await api.addVehicleFuelMultiplier(vehicle.id, parseInt(m.id, 10));
  }
};

export const cleanFuelMultiplier: Function = (): Function => async (
  dispatch: Dispatch
): Promise<void> =>
  dispatch({
    type: CLEAN_FUEL_MULTIPLIER
  });

export default reducer;
