import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { OrgUnitSelect, Selects } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import { FormField } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import CommonForm, {
  type CommonFormProps,
} from '../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { ApproveYesNoSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import { Popconfirm, Icon, ListTable } from '../../../components/ui';
import type {
  GpsServiceMonthsExclusion,
  GpsSubscriptionServiceCost,
  UserAccess,
} from '../../../lib/types';
import { approvingAccessRight } from '../accessRight';

import { isEmptyValue as isEmpty, withEmptyRow } from './../../../lib/helpers';

import { withUserAccess } from './../../withUserAccess';

const { VehicleTypeSelect, MonthsSelect } = Selects;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
const StyledFormField = styled(FormField)`
  margin: 0;
`;

export default withUserAccess(
  (
    props: CommonFormProps<$Shape<GpsSubscriptionServiceCost>> & {
      userAccess: UserAccess[],
    }
  ) => {
    const canApprove = () =>
      props.userAccess.some((access) => approvingAccessRight.includes(access));
    return (
      <CommonForm {...props}>
        {({ setFieldValue, values }) => {
          const data = withEmptyRow(values?.gpsServiceMonthsExclusions ?? []);
          const onDelete = (index: number) => {
            setFieldValue(
              'gpsServiceMonthsExclusions',
              values.gpsServiceMonthsExclusions.filter(
                (value, ind) => index !== ind
              )
            );
          };
          const columns = [
            {
              title: 'Тип ТС',
              key: 'vehicleType',
              dataIndex: 'vehicleType',
              render: (
                vehicleType: string,
                gps: GpsServiceMonthsExclusion,
                index: number
              ) => {
                return (
                  <StyledFormField
                    style={{}}
                    required={!isEmpty(gps)}
                    name={`gpsServiceMonthsExclusions[${index}].vehicleType`}
                  >
                    {({ name, value }) => (
                      <VehicleTypeSelect
                        name={name}
                        size="small"
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </StyledFormField>
                );
              },
            },
            {
              title: 'Месяцы',
              key: 'months',
              dataIndex: 'months',
              render: (
                months: string[],
                gps: GpsServiceMonthsExclusion,
                index: number
              ) => {
                return (
                  <StyledFormField
                    style={{ margin: 0 }}
                    required={!isEmpty(gps)}
                    name={`gpsServiceMonthsExclusions[${index}].months`}
                  >
                    {({ name, value }) => (
                      <MonthsSelect
                        multiple
                        size="small"
                        name={name}
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </StyledFormField>
                );
              },
            },
            {
              width: '20px',
              renderRecord: (gps: GpsServiceMonthsExclusion, index: number) => {
                return !isEmpty(gps) ? (
                  <Popconfirm
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => onDelete(index)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                ) : null;
              },
            },
          ];

          return (
            <>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Подразделение"
                    fast
                    required
                    name="orgUnitId"
                  >
                    {({ name, value }) => (
                      <OrgUnitSelect
                        value={value}
                        onlyBranches
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Стоимость обслуживания за единицу оборудования в месяц без НДС, руб"
                    fast
                    required
                    name="cost"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        min={0}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата начала" name="startDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата окончания" name="endDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        disabled={!values.startDate}
                        disabledDate={(date: string) =>
                          values.startDate
                            ? moment
                                .utc(date)
                                .startOf('day')
                                .isSameOrBefore(moment.utc(values.startDate))
                            : false
                        }
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {canApprove() && (
                  <GridItem>
                    <FormField label="Статус" fast required name="status">
                      {({ name, value }) => (
                        <ApproveYesNoSelect
                          name={name}
                          value={value}
                          onChange={(value) => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </Grid>
              <ListTable columns={columns} data={data} />
            </>
          );
        }}
      </CommonForm>
    );
  }
);
