import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import Icon from './ui/Icon';

const StyleContainer = styled.div`
  min-width: 275px;
  flex: 1 auto;
  text-align: right;
  & .ant-btn {
    margin: 0px;
  }
`;
const StyledIcon = styled(Icon)`
  position: relative;
  top: 2px;
`;

type Props = {
  applyFilter: (values: any) => Promise<void>,
  cleanFilter?: () => Promise<void>,
  handleAdditional?: () => Promise<void>,
  showAdditional?: boolean
};

export default class FilterButtonsContainer extends Component<Props> {
  render() {
    const {
      applyFilter,
      cleanFilter,
      handleAdditional,
      showAdditional
    } = this.props;
    return (
      <StyleContainer {...this.props}>
        <Button type="primary" size="small" onClick={applyFilter}>
          Применить
        </Button>
        {cleanFilter ? (
          <Button type="plain" size="small" onClick={cleanFilter}>
            Очистить
          </Button>
        ) : null}
        {handleAdditional ? (
          <Button
            style={{ width: '127px', textAlign: 'center', color: 'black' }}
            type="plain"
            size="small"
            onClick={handleAdditional}
          >
            {showAdditional ? 'Скрыть фильтры' : 'Дополнительно'}
            {showAdditional ? (
              <StyledIcon type="arrow-up" size="12" color="black" />
            ) : (
              <StyledIcon type="arrow-down" size="12" color="black" />
            )}
          </Button>
        ) : null}
      </StyleContainer>
    );
  }
}
