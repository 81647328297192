//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../components/hoc/common/handbook/CommonFilter';
import { Selects } from '../../../../components';

const { PddTestSelect } = Selects;

export type PddTestRegisterParamsFilter = {
  pddTestId?: number
};

export const PddTestRegisterFilter = (
  props: FilterProps<PddTestRegisterParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PddTestRegisterParamsFilter>>) =>
      CommonFilter<PddTestRegisterParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <PddTestSelect
                size="small"
                value={values.pddTestId}
                onChange={e => changeValue('pddTestId', e.target.value)}
                placeholder="Выберите тест"
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PddTestRegisterFilter;
