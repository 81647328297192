// @flow
import React, { useState, useCallback } from 'react';
import Input from 'antd/lib/input';

import { goBack } from '../../lib/helpers';
import { crewApi } from '../../lib/api';
import { type Crew } from '../../lib/types';

import CommonForm from '../../components/hoc/common/handbook/CommonForm';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { FormField } from '../../components/Form';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/crew';

export default (props: PageProps) => {
  const [data: Crew, setData] = useState({
    id: props.id
  });

  const breadCrumbs = [
    { to: '/', name: 'Главная' },
    { to: `${entryPointPath}`, name: 'Список бригад' },
    { name: props.id ? 'Бригада' : 'Новая бригада' }
  ];

  const onFetch = useCallback(async () => {
    !!props.id && setData(await crewApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: Crew) => {
    let id = payload.id;
    if (id) {
      await crewApi.update(payload);
    } else {
      let data = await crewApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}`);
  };

  const onCancel = async () => {
    await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Бригада'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CommonForm data={data} useFooter noWrapMe>
        {({ setFieldValue }) => {
          return (
            <>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Наименование" fast required name="name">
                    {({ name, value }) => (
                      <Input
                        value={value}
                        onChange={e => setFieldValue(name, e.target.value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </>
          );
        }}
      </CommonForm>
    </CommonCardPage>
  );
};
