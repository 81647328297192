// @flow
import queryString from 'query-string';

import { fetchRequest, initialFetchListParams } from './index';
import type { Tire } from '../types/index';
import type { FetchListParams, ListResponse } from './index';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromMToKm,
  convertFromSecondToHour
} from './../helpers';
import type { TireFilterParams } from '../../containers/Equipment/Tires/Filter';

export const convertDataFromServer = (tire: Tire) => ({
  ...tire,
  actualKilometrage: convertFromMToKm(tire.actualKilometrage),
  normKilometrage: convertFromMToKm(tire.normKilometrage),
  workHours: convertFromSecondToHour(tire.workHours),
  normWorkHours: convertFromSecondToHour(tire.normWorkHours),
  width: tire.width === null ? '-' : tire.width,
  profile: tire.profile === null ? '-' : tire.profile
});
const convertDataToServer = (tire: Tire) => ({
  ...tire,
  actualKilometrage: convertFromKmToM(tire.actualKilometrage),
  normKilometrage: convertFromKmToM(tire.normKilometrage),
  workHours: convertFromHourToSecond(tire.workHours),
  normWorkHours: convertFromHourToSecond(tire.normWorkHours),
  width: tire.width === '-' ? null : tire.width,
  profile: tire.profile === '-' ? null : tire.profile
});

export const addTire = async (tire: Tire): Promise<Tire> => {
  const added = await fetchRequest.post('/tire', convertDataToServer(tire));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать шину');
};

export const fetchTires = async (
  params: FetchListParams<TireFilterParams> = initialFetchListParams
): Promise<ListResponse<Tire>> => {
  const tires = await fetchRequest.get('/tire', {
    ...initialFetchListParams,
    ...params
  });
  return {
    ...tires,
    data: tires.data.map(convertDataFromServer)
  };
};

export const fetchTire = async (
  id: number,
  params: any = {}
): Promise<Tire> => {
  const tire = await fetchRequest.get(`/tire/${id}`, params);
  if (tire) return convertDataFromServer(tire);
  throw new Error('Не удалось');
};

export const updateTire = async (tire: Tire): Promise<Tire> => {
  const updated = await fetchRequest.put('/tire', convertDataToServer(tire));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить шину');
};

export const deleteTire = async (id: number): Promise<Tire> => {
  const deleted = await fetchRequest.delete(`/tire/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить шину');
};

export const setTireVehicle = async (
  tireId: number,
  vehicleId: number,
  installDate?: ?string,
  removeDate?: ?string
): Promise<Object> => {
  const updatedTire = await fetchRequest.post(
    `/tire/${tireId}/${vehicleId}?${queryString.stringify(
      { installDate, removeDate },
      { skipNull: true }
    )}`
  );
  return convertDataFromServer(updatedTire);
};

export const fetchVehicleTires = async (vehicleId: number): Promise<Tire[]> => {
  const tires = await fetchRequest.get(`/tire/${vehicleId}/vehicle`);
  return tires.map(convertDataFromServer);
};

export const updateTireVehicle = async (tire: Tire) => {
  const updatedTire = await fetchRequest.put(
    '/tire/',
    convertDataToServer(tire)
  );
  return convertDataFromServer(updatedTire);
};

export const fetchHistory = async (
  params: FetchListParams<any>,
  tireId: number
): Promise<ListResponse<Object>> =>
  await fetchRequest.get(`/tirehistory/${tireId}`);

export const fillReplaceDate = async (tire: Tire) => {
  const {
    id: tireId,
    vehicleId,
    normKilometrage,
    normWorkHours
  } = convertDataToServer(tire);
  if (!tireId || !vehicleId) return;
  const updatedTire = await fetchRequest.post(
    `/tire/${tireId}/${vehicleId}/fillReplaceDate?${queryString.stringify(
      { normKilometrage, normWorkHours },
      { skipNull: true }
    )}`
  );
  return convertDataFromServer(updatedTire);
};

export default {
  setTireVehicle,
  fetchTires,
  addTire,
  fetchVehicleTires,
  fetchTire,
  updateTire,
  deleteTire,
  updateTireVehicle,
  fetchHistory,
  fillReplaceDate
};
