import {
    VehicleTaxRateCard,
    VehicleTaxRateForm,
    VehicleTaxRateList,
} from '../../containers/VehicleTaxRate';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: VehicleTaxRateList,
        path: '/admin/vehicle-tax-rate',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: VehicleTaxRateCard,
        path: '/admin/vehicle-tax-rate/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: VehicleTaxRateForm,
        path: '/admin/vehicle-tax-rate/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: VehicleTaxRateForm,
        path: '/admin/vehicle-tax-rate/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];