// @flow
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { technicalFluidStatusEnum } from '../../../../lib/enum';
import type {
  ListState,
  OilsAndFluidsConsumptionPlanVehicle,
} from '../../../../lib/types';
import { Section } from './../../../../components/layout';
import { Table } from './../../../../components/ui';
import { InputNumber } from '../../../../components/inputs';
import Filter, { type OilsAndFluidsConsumptionPlanParams } from './Filter';
import { getListInitialState } from '../../../../lib/helpers';
import { vehicleApi, type FetchListParams } from './../../../../lib/api';
import type { Vehicle } from '../../../../lib/types';
import type { VehicleFilterParams } from '../../../Vehicles/Filter';

// В филиале в среднем 100 машин
const PAGE_SIZE = 100;

class PureCountInput extends React.PureComponent<any, any> {
  render() {
    return <CountInput {...this.props} />;
  }
}

class PureFilter extends React.PureComponent<any, any> {
  render() {
    return <Filter {...this.props} />;
  }
}

const CountInput = styled(InputNumber)`
  margin-right: 5px;
  width: 50px;

  input {
    text-align: center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

type Props = {
  vehicleOilsAndFluids: OilsAndFluidsConsumptionPlanVehicle[],
  onChange: Function,
  readonly: boolean,
};

type State = {
  vehicles: ListState<Vehicle> & {
    filter: OilsAndFluidsConsumptionPlanParams,
  },
  vehicleOilsAndFluids: OilsAndFluidsConsumptionPlanVehicle[],
};

export default class OilsAndFluidsConsumptionList extends Component<
  Props,
  State
> {
  state = {
    vehicles: {
      ...getListInitialState(),
      filter: {},
    },
    vehicleOilsAndFluids: this.props.vehicleOilsAndFluids,
  };

  static defaultProps = {
    vehicleOilsAndFluids: [],
  };

  componentDidMount() {
    this.fetchVehicles();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(prevProps.vehicleOilsAndFluids, this.props.vehicleOilsAndFluids)
    ) {
      this.setState({
        vehicleOilsAndFluids: this.props.vehicleOilsAndFluids,
      });
    }
  }

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle.licensePlate',
      width: 50,
    },
    {
      title: 'Марка',
      dataIndex: 'vehicle.vehicleModel.brandName',
      width: 60,
    },
    {
      title: 'Модель',
      dataIndex: 'vehicle.vehicleModel.name',
      width: 90,
    },
    {
      title: 'Год выпуска',
      dataIndex: 'vehicle.yearIssued',
      width: 50,
    },
    {
      title: 'Масло, л.',
      dataIndex: 'oilLiters',
      width: 40,
      render: (
        oilLiters: Number,
        record: OilsAndFluidsConsumptionPlanVehicle
      ) =>
        this.props.readonly ? (
          oilLiters
        ) : (
          <>
            <PureCountInput
              placeholder="0"
              value={oilLiters}
              onChange={(value) =>
                this.updateOilAndFluidConsumption('oilLiters', value, record)
              }
            />
          </>
        ),
    },
    {
      title: 'Антифриз, л.',
      dataIndex: 'antifreezeLiters',
      width: 40,
      render: (
        antifreezeLiters: Number,
        record: OilsAndFluidsConsumptionPlanVehicle
      ) =>
        this.props.readonly ? (
          antifreezeLiters
        ) : (
          <>
            <PureCountInput
              placeholder="0"
              value={antifreezeLiters}
              onChange={(value) =>
                this.updateOilAndFluidConsumption(
                  'antifreezeLiters',
                  value,
                  record
                )
              }
            />
          </>
        ),
    },
    {
      title: 'ЖБО, л.',
      dataIndex: 'windshieldWasherFluidLiters',
      width: 40,
      render: (
        windshieldWasherFluidLiters: Number,
        record: OilsAndFluidsConsumptionPlanVehicle
      ) =>
        this.props.readonly ? (
          windshieldWasherFluidLiters
        ) : (
          <>
            <PureCountInput
              placeholder="0"
              value={windshieldWasherFluidLiters}
              onChange={(value) =>
                this.updateOilAndFluidConsumption(
                  'windshieldWasherFluidLiters',
                  value,
                  record
                )
              }
            />
          </>
        ),
    },
    {
      title: 'Тормозная жидкость, л.',
      dataIndex: 'brakeFluidLiters',
      width: 40,
      render: (
        brakeFluidLiters: number,
        record: OilsAndFluidsConsumptionPlanVehicle
      ) =>
        this.props.readonly ? (
          brakeFluidLiters
        ) : (
          <>
            <PureCountInput
              placeholder="0"
              value={brakeFluidLiters}
              onChange={(value) =>
                this.updateOilAndFluidConsumption(
                  'brakeFluidLiters',
                  value,
                  record
                )
              }
            />
          </>
        ),
    },
  ];

  fetchVehicles = async (
    page: number = 1,
    params: FetchListParams<VehicleFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state.vehicles;
      this.updateVehiclesInfo({ loading: true });

      // $FlowFixMe Ругается на общие поля в filter and params
      const { data, totalCount } = await vehicleApi.fetchVehicles({
        ...filter,
        page,
        ...params,
        ownerType: 'self',
        pageSize: PAGE_SIZE,
      });
      this.updateVehiclesInfo({ data, totalCount, page });
    } catch (err) {
    } finally {
      this.updateVehiclesInfo({ loading: false });
    }
  };

  syncData = debounce(
    () => this.props.onChange(this.state.vehicleOilsAndFluids),
    500
  );

  cleanFilter = () =>
    this.updateVehiclesInfo({ filter: {} }, this.fetchVehicles);

  applyFilter = (filter: VehicleFilterParams) =>
    this.updateVehiclesInfo({ filter }, this.fetchVehicles);

  updateVehiclesInfo = (newValues: any, cb?: () => any) =>
    this.setState(
      (prevState: State) => ({
        vehicles: {
          ...prevState.vehicles,
          ...newValues,
        },
      }),
      cb
    );

  updateOilAndFluidConsumption = async (
    key: string,
    value: any,
    vehicleOil: OilsAndFluidsConsumptionPlanVehicle
  ) => {
    let vehicleOilsAndFluids = [...this.state.vehicleOilsAndFluids];
    const index = vehicleOilsAndFluids.findIndex(
      (item) => item.vehicleId === vehicleOil.vehicleId
    );
    if (index >= 0) {
      vehicleOilsAndFluids.splice(index, 1, {
        ...vehicleOilsAndFluids[index],
        [key]: value,
      });
    } else {
      vehicleOilsAndFluids = [
        ...vehicleOilsAndFluids,
        {
          ...vehicleOil,
          [key]: value,
        },
      ];
    }
    this.setState(
      {
        vehicleOilsAndFluids,
      },
      () => {
        this.syncData();
      }
    );
  };

  mapVehiclesToOilsAndFluidsConsumptionPlan = (vehicles: Vehicle[]) => {
    const { vehicleOilsAndFluids } = this.state;
    return vehicles.map<OilsAndFluidsConsumptionPlanVehicle>(
      (vehicle: Vehicle) => ({
        vehicleId: vehicle.id,
        oilLiters: 0,
        antifreezeLiters: 0,
        windshieldWasherFluidLiters: 0,
        brakeFluidLiters: 0,
        status: technicalFluidStatusEnum.draft,
        ...vehicleOilsAndFluids.find(
          (item: OilsAndFluidsConsumptionPlanVehicle) =>
            item.vehicleId === vehicle.id
        ),
        vehicle,
      })
    );
  };

  render() {
    const { vehicles } = this.state;
    const { filter, data, loading, page, totalCount } = vehicles;
    return (
      <Section>
        <PureFilter
          filter={filter}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
        />
        <Table
          columns={this.columns}
          scroll={{ y: 800 }}
          fetch={this.fetchVehicles}
          rowKey="vehicleId"
          data={this.mapVehiclesToOilsAndFluidsConsumptionPlan(data)}
          loading={loading}
          pagination={{
            page,
            totalCount,
            pageSize: PAGE_SIZE,
          }}
        />
      </Section>
    );
  }
}
