import {
    AttachedEquipmentCard,
    AttachedEquipmentsForm,
    AttachedEquipmentsList,
    BatteriesForm,
    BatteriesList,
    BatteryCard,
    OptionalEquipmentCard,
    OptionalEquipmentForm,
    OptionalEquipmentList,
    TireCard,
    TiresForm,
    TiresList,
} from '../containers/Equipment';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: BatteriesList,
        path: '/equipment/batteries',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: BatteriesForm,
        path: '/equipment/batteries/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
        component: BatteriesForm,
        path: '/equipment/batteries/new/vehicle/:vehicleId',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
        component: BatteriesForm,
        path: '/equipment/batteries/edit/:batteryId',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
        component: BatteryCard,
        path: '/equipment/batteries/:batteryId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: TiresList,
        path: '/equipment/tires',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: TiresForm,
        path: '/equipment/tires/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
        component: TiresForm,
        path: '/equipment/tires/new/vehicle/:vehicleId',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
        component: TiresForm,
        path: '/equipment/tires/edit/:tireId',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
        component: TireCard,
        path: '/equipment/tires/:tireId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: AttachedEquipmentsList,
        path: '/equipment/attached',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: AttachedEquipmentsForm,
        path: '/equipment/attached/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
        ],
    },
    {
        component: AttachedEquipmentsForm,
        path: '/equipment/attached/edit/:attachedEquipmentId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
        ],
    },
    {
        component: AttachedEquipmentCard,
        path: '/equipment/attached/:attachedEquipmentId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: OptionalEquipmentList,
        path: '/equipment/optional',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
    {
        component: OptionalEquipmentForm,
        path: '/equipment/optional/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
        ],
    },
    {
        component: OptionalEquipmentForm,
        path: '/equipment/optional/edit/:optionalId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
        ],
    },
    {
        component: OptionalEquipmentCard,
        path: '/equipment/optional/:optionalEquipmentId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEquipment,
            accessTypeEnum.viewingAllEquipments,
            accessTypeEnum.viewingBranchEquipments,
        ],
    },
];