// @flow

import type { TripRange } from './../types';

import { fetchRequest } from './index';
import { convertFromKmToM, convertFromMToKm } from './../helpers';

export const convertDataFromServer = (tripRange: TripRange) => ({
  ...tripRange,
  amount: convertFromMToKm(tripRange.amount)
});

const convertDataToServer = (tripRange: TripRange) => ({
  ...tripRange,
  amount: convertFromKmToM(tripRange.amount)
});

export const addTripRange = async (
  tripRange: TripRange
): Promise<TripRange> => {
  const added = await fetchRequest.post(
    '/tripRange',
    convertDataToServer(tripRange)
  );
  if (added) return convertDataFromServer(added);
  throw new Error(
    'Не удалось создать участок маршрута с указанным топливным коэффициентом'
  );
};

export default {
  addTripRange
};
