// @flow
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import moment from 'moment';
import useComponentSize from '@rehooks/component-size';

import {
  Wrapper,
  Row,
  Col,
  LeftCol,
  Footer,
  Delimiter,
  EmptyCol,
  FooterTimes,
  Layer,
  PlayerArea,
  PlayerTick,
  CurrentTick,
  PlayedTrack
} from './styles';
import type { MonitoringVehicleHistoryItem } from '../../../../../../../../../lib/types/monitoringVehicle';
import { TrackHistory } from '../trackHistory';
import type {
  MonitoringVehicleState,
  MonitoringVehicleStatus
} from '../../../../../../../../../lib/types';
import { TrackEvents } from '../trackEvents';
import { calculateTick, calculateTickToPx, getIntervals } from './utils';

type Props = {
  // Дата начала
  startDate: Date,
  // Дата окончания
  endDate: Date,
  // Инстанс проигрывателя трека
  playback: any,
  // Текущий тик времени вопроизведения
  currentTickTime: number,
  // Трек
  history: MonitoringVehicleHistoryItem[]
};

// Структура для отрисовки мини-карты
// промежутков со статусами и состояниями
export type Interval = {
  // Ширина в пикселях
  width: string,
  // Состояние авто на данном промежутке
  state: MonitoringVehicleState,
  // Статус авто на данном промежутке
  status: MonitoringVehicleStatus
};

/**
 * Сетка воспроизведения
 */
export const Grid = ({
  startDate,
  currentTickTime,
  playback,
  history,
  endDate
}: Props) => {
  const playerRef = useRef();
  const size = useComponentSize(playerRef);

  // Количество пикселей от левого края соотвествующие
  // времени воспроизведения
  const [tickX, setTickX] = useState(0);
  // Текущий тик
  const [tickTime, setTickTime] = useState(moment(startDate).unix());

  // Промежутки мини-карты состояний и статусов ТС
  const intervals = useMemo(() => getIntervals(history, size.width), [
    history,
    size.width
  ]);

  /**
   * Форматирование дат на сетке
   * Если в рамках одного дня - выводим время
   * Если в рамках нескольких дней - выводим день
   */
  const dateFormat = useMemo(() => {
    const daysDiff = moment(startDate).diff(endDate, 'days');
    return daysDiff === 0 ? 'HH:mm' : 'DD.MM';
  }, [startDate, endDate]);

  // Время начала, конца и середины маршрута
  const { start, middle, end } = useMemo(() => {
    const middleDate = (startDate.valueOf() + endDate.valueOf()) / 2;
    return {
      start: moment(startDate).format(dateFormat),
      end: moment(endDate).format(dateFormat),
      middle: moment(middleDate).format(dateFormat)
    };
  }, [startDate, endDate, dateFormat]);

  /**
   * Обработка нажатия на область плеера
   */
  const handlePlayerClick = useCallback(
    (e: SyntheticMouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const rect = (e.target: any).getBoundingClientRect();
      const x = e.clientX - rect.left;
      // Считаем сколько по времени прошло относительно пикселей
      const currTimeTick = calculateTick(x, startDate, endDate, size.width);
      playback.setCursor(currTimeTick);
      setTickTime(currTimeTick);
      setTickX(x);
    },
    [startDate, endDate, size.width, playback]
  );

  /**
   * Отрисовка текущего времени воспроизведения
   */
  const currentTick = useMemo(() => {
    const startUnix = moment(startDate).unix();
    const endUnix = moment(endDate).unix();
    return startUnix !== tickTime || endUnix !== tickTime ? (
      <CurrentTick x={tickX}>
        {moment.unix(tickTime).format(dateFormat)}
      </CurrentTick>
    ) : null;
  }, [startDate, endDate, tickTime, tickX, dateFormat]);

  /**
   * Обработка воспроизведения плеера
   * и изменения позиции метки воспроизведения
   */
  useEffect(() => {
    if (currentTickTime) {
      const newTickX = calculateTickToPx(
        currentTickTime,
        startDate,
        endDate,
        size.width
      );
      setTickX(newTickX);
      setTickTime(currentTickTime);
    }
  }, [currentTickTime, startDate, endDate, size.width]);

  return (
    <>
      <Wrapper>
        <Row>
          <LeftCol>Трек</LeftCol>
          <Col>
            <PlayerArea ref={playerRef} onClick={handlePlayerClick}>
              <Layer>
                <PlayedTrack width={tickX} />
                <TrackHistory intervals={intervals} />
              </Layer>
            </PlayerArea>
            <PlayerTick x={tickX} />
            <Delimiter />
          </Col>
        </Row>
        <Row>
          <LeftCol>События</LeftCol>
          <Col style={{ overflow: 'hidden' }}>
            <PlayerArea onClick={handlePlayerClick}>
              <Layer>
                <TrackEvents intervals={intervals} />
              </Layer>
            </PlayerArea>
            <Delimiter />
          </Col>
        </Row>
      </Wrapper>
      <Footer>
        <EmptyCol />
        <FooterTimes>
          <p>{start}</p>
          <p>{middle}</p>
          <p>{end}</p>
          {currentTick}
        </FooterTimes>
      </Footer>
    </>
  );
};
