// @flow
import React, { Component } from 'react';
import { navigate } from '@reach/router';

import { Tabs, TabItem } from '../../../components/ui';

type Props = {
  selectedTab: string
};

export default class HeaderTabs extends Component<Props> {
  render() {
    return (
      <Tabs defaultTab={this.props.selectedTab}>
        <TabItem
          tabKey="general"
          label="Общие работы"
          onClick={() => navigate(`/admin/maintenance-operation-groups`)}
        />
        <TabItem
          tabKey="byModel"
          label="Работы по модели"
          onClick={() => navigate(`/admin/maintenance-operations-by-brand`)}
        />
      </Tabs>
    );
  }
}
