// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import moment from 'moment';
import { connect } from 'react-redux';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';

import {
  osagoCalculationApi,
  osagoCalculationYearApi,
} from './../../../lib/api';
import {
  accessTypeEnum,
  osagoCalculationStatuses,
  osagoCalculationStatusEnum,
} from '../../../lib/enum';
import { formatDateTimeToString } from '../../../lib/helpers';
import type { UserAccess, OsagoCalculationYear } from '../../../lib/types';

import { notificationLoading } from '../../../components/Notifications';
import {
  Popconfirm,
  ButtonOperations,
  Table,
  Dropdown,
} from './../../../components/ui';
import { Section } from './../../../components/layout';
import Header from './../../../components/layout/Header';
import { InputNumber } from '../../../components/inputs';

import type { AppState } from '../../../ducks/redux';
import { clearData } from '../../../ducks/osagoCalculation';

import Fiter from './FilterYearList';

const columns = [
  {
    title: 'Год',
    dataIndex: 'year',
  },
  {
    title: 'Количество ТС',
    dataIndex: 'count',
  },
  {
    title: 'Дата создания',
    dataIndex: 'created',
    render: (date: string | moment) =>
      formatDateTimeToString(date, 'DD.MM.YYYY'),
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (status: string) => osagoCalculationStatuses[status],
  },
  {
    title: 'Комментарий',
    dataIndex: 'comment',
  },
];

const deleteOsagoCalculationYear = async (id: number) => {
  try {
    notificationLoading({
      message: 'Удаление записи...',
      key: 'saving',
    });
    await osagoCalculationYearApi.deleteOsagoCalculationYear(id);
    notification.success({
      message: 'Успешно удалено',
    });
  } catch (err) {
    notification.error({
      message: 'Ошибка',
      description: err.message,
    });
  } finally {
    notification.close('saving');
  }
};

const fetchData = async (setRows, setLoading, filter) => {
  try {
    const { data } = await osagoCalculationApi.fetchOsagoCalculationByYear({
      ...filter,
      orderBy: 'year',
    });
    setRows(data);
  } catch (err) {
    notification.error({
      message: 'Ошибка',
      description: err.message,
    });
  } finally {
    setLoading(false);
  }
};

const recalculationOsagoCalculationYear = async (id: number, year: number) => {
  try {
    notificationLoading({
      message: 'Перерасчет записи...',
      key: 'recalculation',
    });
    await osagoCalculationYearApi.recalculationOsagoCalculationYear({
      id,
      year,
    });
    notification.success({
      message: 'Успешно пересчитано',
    });
  } catch (err) {
    notification.error({
      message: 'recalculation',
      description: err.message,
    });
  } finally {
    notification.close('recalculation');
  }
};

type Props = {
  userAccess: UserAccess[],
  clearData: Function,
};

const YearsList = ({ userAccess, clearData }: Props) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(moment.utc().year());
  const [filter, setFilter] = useState({ ...qs.parse(window.location.search) });
  const operationsColumns = {
    title: '',
    width: '30px',
    dataIndex: 'id',
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (id, record: OsagoCalculationYear) => {
      return (
        <Dropdown
          key={record.id}
          overlay={
            <Menu>
              {record.status === osagoCalculationStatusEnum.draft &&
                !!record.id && (
                  <Menu.Item
                    onClick={() =>
                      !!record.id &&
                      recalculationOsagoCalculationYear(record.id, record.year)
                    }
                  >
                    Пересчитать
                  </Menu.Item>
                )}
              <Menu.Item>
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  placement="left"
                  onConfirm={async () => {
                    setLoading(true);
                    await deleteOsagoCalculationYear(parseInt(record.id, 10));
                    fetchData(setRows, setLoading, filter);
                  }}
                >
                  Удалить
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }
        >
          <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
        </Dropdown>
      );
    },
  };

  useEffect(() => {
    fetchData(setRows, setLoading, filter);
  }, [filter]);

  const canCreateOsagoCalculations = userAccess.some((access) =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.formationOsagoCalculations,
    ].includes(access)
  );

  return (
    <>
      <Header
        left={<h1>Расчет потребностей ОСАГО</h1>}
        right={
          canCreateOsagoCalculations && (
            <ButtonOperations>
              <Popconfirm
                title={
                  <>
                    <p>Выберите год для расчета потребностей ОСАГО</p>
                    <InputNumber
                      min={moment.utc().year()}
                      value={year}
                      onChange={(year) => setYear(year)}
                    />
                  </>
                }
                onConfirm={() => {
                  clearData();
                  navigate(`/budget/osago-calculations/new?year=${year}`);
                }}
              >
                <Button type="primary" data-cy="add">
                  Создать
                </Button>
              </Popconfirm>
            </ButtonOperations>
          )
        }
      />
      <Section>
        <Fiter
          filter={filter}
          applyFilter={setFilter}
          cleanFilter={() => setFilter({})}
        />
        <Table
          onRow={(record) => ({
            onClick: () => {
              navigate(
                `/budget/osago-calculations/list?&osagoCalculationYearId=${record.id}`
              );
            },
          })}
          data={rows}
          loading={loading}
          columns={[...columns, operationsColumns]}
        />
      </Section>
    </>
  );
};

export default connect(
  (state: AppState) => ({
    userAccess: state.auth.profile.access,
  }),
  { clearData }
)(YearsList);
