import {
    FederalHighwayFareForm,
    FederalHighwayFareList,
} from '../../containers/FederalHighwayFare';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: FederalHighwayFareList,
        path: '/admin/federal-highway-fare',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: FederalHighwayFareForm,
        path: '/admin/federal-highway-fare/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: FederalHighwayFareForm,
        path: '/admin/federal-highway-fare/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];