// @flow
import sumBy from 'lodash/sumBy';
import isNil from 'lodash/isNil';
import shortId from 'shortid';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

import type {
  FormErrors,
  OperationLimit,
  OperationLimitAggregationField,
  OperationLimitGroup
} from '../../../lib/types';
import {
  operationLimitAggregationFieldsEnum,
  operationLimitGroupStatusEnum,
  operationLimitTypeEnum
} from '../../../lib/enum';
import { operationLimitApi, operationLimitGroupApi } from '../../../lib/api';
import { withEmptyRow } from '../../../lib/helpers';
import { declineHours } from '../lib';

// Валидация лимитов
export const validateOperationLimit = (
  operationLimit: OperationLimit,
  group: OperationLimitGroup
): FormErrors<OperationLimit> => {
  const errors: FormErrors<OperationLimit> = {};
  const { type, hours, hoursWeekend } = operationLimit;
  const {
    hours: maxHours,
    hoursWeekend: maxHoursWeekend,
    usedInWeekends
  } = group;
  const getRequiredFields = () => {
    switch (type) {
      case operationLimitTypeEnum.ovb:
        return ['ovbName', 'orgUnitId', 'hours', 'distance'];
      case operationLimitTypeEnum.employee:
        return usedInWeekends
          ? [
              'jobTitleId',
              'distance',
              'hours',
              'hoursWeekend',
              'distanceWeekend'
            ]
          : ['jobTitleId', 'distance', 'hours'];
      case operationLimitTypeEnum.orgUnit:
        return usedInWeekends
          ? [
              'orgUnitId',
              'distance',
              'hours',
              'hoursWeekend',
              'distanceWeekend'
            ]
          : ['orgUnitId', 'distance', 'hours'];
      default:
        return [];
    }
  };
  getRequiredFields().forEach(key => {
    if (!operationLimit[key]) errors[key] = 'Обязательное поле';
  });
  if (hours > maxHours) {
    errors.hours = `Время должно быть не более ${declineHours(maxHours)}`;
  }
  if (hoursWeekend > maxHoursWeekend) {
    errors.hoursWeekend = `Время должно быть не более ${declineHours(
      maxHours
    )}`;
  }
  return errors;
};

export const aggregateBy = (
  limits: OperationLimit[],
  key: $Keys<OperationLimit>
) => {
  return parseFloat(
    (
      sumBy(limits, key) / limits.filter(limit => !isNil(limit[key])).length
    ).toFixed(2)
  );
};

export const aggregateLimits = (
  limits: OperationLimit[]
): { [key: OperationLimitAggregationField]: number } => {
  return Object.keys(operationLimitAggregationFieldsEnum).reduce(
    (obj, key) => ({
      ...obj,
      [(key: string)]: aggregateBy(limits, key)
    }),
    {}
  );
};

/**
 * Функция конвертации строки к числу или к пустой строке
 * @param value Строка
 */
export const toStringNumberLimitValue = (value: string) =>
  value.replace(/[^0-9.]/g, '') || '';

/**
 * Функция сохранения группы лимитов
 * @param group Группа
 * @param orgUnitId Прикрепляемое подразделение
 * @returns {Promise<void>}
 */
export const saveGroup = async (
  group: OperationLimitGroup,
  orgUnitId: number
): Promise<OperationLimitGroup> => {
  group = {
    ...group,
    timeLimitStatus: operationLimitGroupStatusEnum.draft,
    assignmentLimitStatus: operationLimitGroupStatusEnum.draft
  };
  if (group.id) {
    await operationLimitGroupApi.updateOperationLimitGroup(group);
  } else {
    group.orgUnitId = orgUnitId;
    const { id } = await operationLimitGroupApi.addOperationLimitGroup(group);
    group.id = id;
  }
  group.operationLimits = await Promise.all(
    group.operationLimits.map(async limit => {
      if (limit.id) {
        return await operationLimitApi.updateOperationLimit(limit);
      }
      limit.operationLimitGroupId = group.id;
      // Только для должностных лиц проставляем orgUnitId
      // У остальных он проставляется в регламенте
      if (limit.type === operationLimitTypeEnum.employee)
        limit.orgUnitId = group.orgUnitId;
      return await operationLimitApi.addOperationLimit(limit);
    })
  );
  return group;
};

/**
 * Является ли число NaN или null, undefined
 * @param value Число
 * @returns {boolean}
 */
const isNilOrNaN = (value: ?number) => isNil(value) || isNaN(value);

/**
 * Возвращает массив лимитов с пустым лимитом на конце
 */
export const withEmptyLimit = (
  limits: OperationLimit[],
  group: OperationLimitGroup
) => {
  const {
    id: operationLimitGroupId,
    orgUnitId,
    type,
    hoursWeekend,
    hours,
    distance,
    distanceWeekend
  } = group;
  return withEmptyRow(limits, {
    emptyRow: {
      type,
      operationLimitGroupId,
      hours: hours,
      distance,
      hoursWeekend: !isNilOrNaN(hoursWeekend) ? hoursWeekend : undefined,
      distanceWeekend: !isNilOrNaN(distanceWeekend)
        ? distanceWeekend
        : undefined,
      orgUnitId:
        type === operationLimitTypeEnum.employee ? orgUnitId : undefined,
      key: shortId.generate()
    },
    ignoredEmptyRowKeys: ['key']
  });
};

/**
 * Проверяет пустой ли это лимит
 * @param limit Лимит
 * @param group Группа лимитов
 */
export const isEmptyLimit = (
  limit: OperationLimit,
  group: OperationLimitGroup
) => {
  const {
    id: operationLimitGroupId,
    type,
    orgUnitId,
    hoursWeekend,
    hours,
    distance,
    distanceWeekend
  } = group;
  return isEqual(omit(limit, 'key'), {
    type,
    hours: hours,
    distance,
    hoursWeekend: !isNilOrNaN(hoursWeekend) ? hoursWeekend : undefined,
    distanceWeekend: !isNilOrNaN(distanceWeekend) ? distanceWeekend : undefined,
    orgUnitId: type === operationLimitTypeEnum.employee ? orgUnitId : undefined,
    operationLimitGroupId
  });
};
