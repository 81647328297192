// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { smallScaleMechanizationToolApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { SmallScaleMechanizationTool } from '../../lib/types';

export default (props: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите средство малой механизации"
    fetch={smallScaleMechanizationToolApi.fetch}
    fetchSingle={smallScaleMechanizationToolApi.get}
    notFoundText="Средства малой механизации не найдены"
    renderOption={(
      smallScaleMechanizatio: SmallScaleMechanizationTool,
      Option
    ) => (
      <Option value={smallScaleMechanizatio.id} key={smallScaleMechanizatio.id}>
        {smallScaleMechanizatio.name}
      </Option>
    )}
    {...props}
  />
);
