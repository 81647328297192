// @flow
import React from 'react';
import Tooltip from 'antd/lib/tooltip';

import Grid, { GridItem } from '../../../../components/layout/Grid';
import VehicleStatus from '../../../../components/VehicleStatus';
import type { MonitoringVehicle } from '../../../../lib/types/monitoringVehicle';
import { Wrapper, List, Item, Header, HeaderSide, Pagination } from './styles';
import { VehiclesSelect, Filters } from './elements';
import { Spoiler, Icon } from '../../../../components/ui';
import type { MonitoringFilterParams } from './elements';
import { applyMaskToValue } from '../../../../lib/helpers';
import { formatLicensePlateMask } from '../../../../components/inputs/masked-inputs/LicensePlateInput';
import { getStatusIcon } from '../../utils';

type Props = {
  handleActiveMonitoringVehicles: (
    wialonItemId: number,
    vehicle: MonitoringVehicle
  ) => void,
  activeMonitoringVehicles: Map<number, MonitoringVehicle>,
  monitoringVehicles: MonitoringVehicle[],
  fetchVehicles: (page: number) => Promise<any>,
  selectedMonitoringVehicle?: MonitoringVehicle,
  onSelect: (vehicle: MonitoringVehicle) => Promise<void>,
  applyFilter: (filters: MonitoringFilterParams) => Promise<void>,
  cleanFilter: () => void,
  // Пагинация
  pagination: {
    // Страница
    page: number,
    // Общее число записей
    totalCount: number,
    // Кол-во записей на странице
    pageSize: number,
    // Объект для роутинга страниц
    location: Location & { state: { page: number } }
  }
};

type State = {
  filters: MonitoringFilterParams,
  filtersExpanded: boolean
};

/**
 * Список ТС
 */
export class VehiclesList extends React.Component<Props, State> {
  state: State = {
    filters: {},
    filtersExpanded: false
  };

  handleActive = async (monitoringVehicle: MonitoringVehicle) => {
    monitoringVehicle.itemId &&
      this.props.handleActiveMonitoringVehicles(
        monitoringVehicle.itemId,
        monitoringVehicle
      );
  };

  handleSelect = async (monitoringVehicle: MonitoringVehicle) => {
    const { activeMonitoringVehicles } = this.props;
    if (monitoringVehicle.itemId) {
      if (!activeMonitoringVehicles.has(monitoringVehicle?.itemId ?? 0)) {
        this.handleActive(monitoringVehicle);
      }
      await this.props.onSelect(monitoringVehicle);
    }
  };

  handleSpoilerChange = (filtersExpanded: boolean) => {
    this.setState({ filtersExpanded });
  };

  handleFilterChange = async (filters: MonitoringFilterParams) => {
    this.setState({ filters });
    this.props.applyFilter(filters);
  };

  render() {
    const {
      monitoringVehicles,
      activeMonitoringVehicles,
      selectedMonitoringVehicle = {},
      pagination,
      fetchVehicles
    } = this.props;
    const { filtersExpanded, filters } = this.state;
    const { totalCount, location, page, pageSize } = pagination;
    return (
      <Wrapper>
        <Header>
          <HeaderSide>
            <VehiclesSelect
              vehicles={monitoringVehicles}
              onChange={this.handleSelect}
            />
          </HeaderSide>
          <HeaderSide right>
            <Spoiler
              label="Фильтр"
              defaultExpanded={filtersExpanded}
              showDivider={false}
              children={null}
              onChange={this.handleSpoilerChange}
            />
          </HeaderSide>
        </Header>
        <Filters
          values={filters}
          onChange={this.handleFilterChange}
          expanded={filtersExpanded}
          onClean={this.props.cleanFilter}
        />
        <List>
          {monitoringVehicles.map((monitoringVehicle: MonitoringVehicle) => {
            const { vehicle } = monitoringVehicle;
            const isActive =
              monitoringVehicle.vehicleId ===
              selectedMonitoringVehicle.vehicleId;
            const { type, color, title } = getStatusIcon(
              monitoringVehicle,
              isActive
            );
            return (
              <Item
                key={vehicle.id}
                active={isActive}
                onClick={async () => {
                  await this.handleSelect(monitoringVehicle);
                }}
              >
                <Grid cols={['20px', '138px', 'auto', '20px', '20px']}>
                  <GridItem
                    onClick={e => {
                      e.stopPropagation();
                      this.handleActive(monitoringVehicle);
                    }}
                  >
                    <VehicleStatus
                      status={
                        activeMonitoringVehicles.has(
                          monitoringVehicle?.itemId ?? 0
                        )
                          ? 'working'
                          : 'draft'
                      }
                      showLabel={false}
                    />
                  </GridItem>
                  <GridItem
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {vehicle.vehicleModel.brandName} {vehicle.vehicleModel.name}
                  </GridItem>
                  <GridItem>
                    {applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )}
                  </GridItem>
                  {monitoringVehicle.itemId ? (
                    <>
                      <GridItem>
                        <Tooltip
                          arrowPointAtCenter
                          mouseEnterDelay={0}
                          title={title}
                        >
                          <Icon type={type} color={color} />
                        </Tooltip>
                      </GridItem>
                      <GridItem align={{ vertical: 'center' }}>
                        <Tooltip
                          arrowPointAtCenter
                          mouseEnterDelay={0}
                          title={`${monitoringVehicle?.geoPosition?.lat ??
                            ''}, ${monitoringVehicle?.geoPosition?.lng ?? ''}`}
                        >
                          <Icon
                            type="pin"
                            color={isActive ? '#fff' : '#2770FF'}
                          />
                        </Tooltip>
                      </GridItem>
                    </>
                  ) : null}
                </Grid>
              </Item>
            );
          })}
        </List>
        <Pagination
          totalCount={parseInt(totalCount, 10)}
          pageSize={parseInt(pageSize, 10)}
          fetch={fetchVehicles}
          page={parseInt(page, 10)}
          location={location}
        />
      </Wrapper>
    );
  }
}
