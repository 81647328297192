import {
    VehicleModelForm,
    VehicleModelList,
    VehicleModelRegulationForm,
} from '../containers/VehicleModels';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: VehicleModelList,
        path: '/vehicle-models',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingBrandsAndVehicleModels,
        ],
    },
    {
        component: VehicleModelForm,
        path: '/vehicle-models/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingBrandsAndVehicleModels,
        ],
    },
    {
        component: VehicleModelForm,
        path: '/vehicle-models/:vehicleModelId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingBrandsAndVehicleModels,
        ],
    },
    {
        component: VehicleModelForm,
        path: '/vehicle-models/:vehicleModelId/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingBrandsAndVehicleModels,
        ],
    },
    {
        component: VehicleModelRegulationForm,
        path: '/vehicle-models/:vehicleModelId/maintenance/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingBrandsAndVehicleModels,
        ],
    },
];