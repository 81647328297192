// @flow

import type { StockPart } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken,
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

export const convertDataFromServer = (stockPart: StockPart): StockPart => ({
  ...stockPart,
  cost: convertFromKopToRub(stockPart.cost),
  sparePart: stockPart.sparePart
    ? {
        ...stockPart.sparePart,
        price: convertFromKopToRub(stockPart.sparePart.price),
      }
    : undefined,
});

export const convertDataToServer = (stockPart: StockPart): StockPart => ({
  ...stockPart,
  cost: convertFromRubToKop(stockPart.cost),
  sparePart: stockPart.sparePart
    ? {
        ...stockPart.sparePart,
        price: convertFromRubToKop(stockPart.sparePart.price),
      }
    : undefined,
});

export const fetchStockPart = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<StockPart>> => {
  const stockPart = await fetchRequest.get('/stockPart', {
    ...initialFetchListParams,
    ...params,
  });
  if (stockPart)
    return {
      ...stockPart,
      data: stockPart.data.map(convertDataFromServer),
    };
  throw new Error('Не удалось получить список запчастей со склада');
};

export const getStockPart = async (id: number): Promise<StockPart> => {
  const stockPart = await fetchRequest.get(`/stockPart/${id}`);
  if (stockPart) return convertDataFromServer(stockPart);
  throw new Error('Не удалось получить информацию по детали');
};

export const addStockPart = async (
  stockPart: StockPart
): Promise<StockPart> => {
  const added = await fetchRequest.post(
    `/stockPart`,
    convertDataToServer(stockPart)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось добавить запчасть на склад');
};

export const updateStockPart = async (
  stockPart: StockPart
): Promise<StockPart> => {
  const updeted = await fetchRequest.put(
    `/stockPart`,
    convertDataToServer(stockPart)
  );
  if (updeted) return convertDataFromServer(updeted);
  throw new Error('Не удалось отредактировать информацию о запчасти');
};

export const deleteStockPart = async (id: number): Promise<StockPart> => {
  const deleted = await fetchRequest.delete(`/stockPart/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалось запчасть');
};

export const subscribeParusStockParts = async (): Promise<any> => {
  const accounts = await fetchRequest.get(
    `/stockPart/parus/subscribe/stockParts`,
    {}
  );
  if (accounts) return accounts;
  throw new Error('Не удалось подписаться на обновления по складам!');
};

export const updateParusStockParts = async (params: any): Promise<any> => {
  const accounts = await fetchRequest.get(
    `/stockPart/parus/updateStockParts`,
    params
  );
  if (accounts) return accounts;
  throw new Error('Не удалось подписаться на обновления по складам!');
};

const print = async (filter: any) => {
  const print = await downloadRequestWithToken(`/stockPart/print`, filter);
  if (print) return print;
  throw new Error('Не удалось распечатать перечень материалов');
};

export default {
  print,
  fetchStockPart,
  getStockPart,
  addStockPart,
  updateStockPart,
  deleteStockPart,
  subscribeParusStockParts,
  updateParusStockParts,
};
