// @flow

import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';
import notification from 'antd/lib/notification';
import CloneDeep from 'lodash/cloneDeep';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { notificationLoading } from '../../../../components/Notifications';
import { AntTable, ButtonsRow, Icon } from '../../../../components/ui';

import { materialCalculationApi, vehiclePlanApi } from '../../../../lib/api';
import { calculationStatusEnum } from '../../../../lib/enum';
import { getListInitialState, multipliedBy, plus, toLocalStringRu } from '../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';
import type {
  ListState,
  MaterialCalculation,
  MaterialCalculationMonth,
  MdmNode,
  VehiclePlan,
} from '../../../../lib/types';

import { itogCalculation, MONTH } from '../lib';

import { Selects } from './../../../../components';
import { Section } from './../../../../components/layout';
import { headerPanel } from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};
type State = ListState<MaterialCalculation> & {
  vehiclePlan: ?VehiclePlan,
  oldData: MaterialCalculation[],
  deletedIds: number[],
  columns: any,
  touched: boolean
};

const { MdmSelect } = Selects;
const StyledMdmSelect = styled(MdmSelect)`
  position: relative;
  top: 6px;
  max-width: 267px !important;
`;
const Ellipsis = styled.div`
  width: 267px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    touched: false,
    deletedIds: [],
    columns: [
      {
        title: 'Maтериал',
        key: 'mtr',
        dataIndex: 'mtr',
        fixed: 'left',
        width: 300,
        render: (
          mtr: ?MdmNode,
          row: MaterialCalculation & { itog?: boolean },
          rowIndex: number
        ) => {
          return !!row.itog ? (
            'Итого:'
          ) : this.isDraft() ? (
            <StyledMdmSelect
              size="small"
              value={mtr?.name}
              disableNonePrice
              allowClear={false}
              excludeIds={this.state.data.map(d => d.mtrId)}
              onChange={(value, option) => {
                const { vehiclePlanId } = this.props;
                let { data } = this.state;
                if (data[rowIndex]) {
                  data[rowIndex].mtr = option?.props?.mdm;
                  data[rowIndex].mtrId = option?.props?.mdm.id;
                } else {
                  data = [
                    ...data,
                    {
                      mtr: option?.props?.mdm,
                      mtrId: option?.props?.mdm.id,
                      sum: 0,
                      vehiclePlanId,
                      months: this.emptyMonths()
                    }
                  ];
                }
                this.setState({ data, touched: true });
              }}
            />
          ) : (
            <Ellipsis> {mtr?.name} </Ellipsis>
          );
        }
      },
      ...MONTH.map((month, monthIndex) => ({
        title: month.title,
        children: [
          {
            title: 'Кол-во',
            key: `months[${monthIndex}].count`,
            dataIndex: `months[${monthIndex}].count`,
            width: 123,
            render: (
              count: number,
              row: MaterialCalculation,
              rowIndex: number
            ) =>
              count || count === 0 ? (
                this.isDraft() ? (
                  <InputNumber
                    size="small"
                    value={count}
                    min={0}
                    disabled={!this.isRangeMonths(monthIndex)}
                    onChange={value => {
                      let { data } = this.state;
                      const count = isNaN(+value) ? 0 : Math.floor(+value);
                      const cost = multipliedBy(count, +row.mtr.currentCost);
                      data[rowIndex].months[monthIndex].cost = cost;
                      data[rowIndex].months[monthIndex].count = count;
                      data[rowIndex].sum = data[rowIndex].months.reduce(
                        (sum, month) => {
                          return plus(sum, month.cost);
                        },
                        0
                      );
                      this.setState({ data, touched: true });
                    }}
                  />
                ) : (
                  count
                )
              ) : null
          },
          {
            title: 'Стоимость',
            key: `months[${monthIndex}].cost`,
            dataIndex: `months[${monthIndex}].cost`,
            width: 100,
            render: cost => (cost ? toLocalStringRu(cost) : null)
          }
        ]
      })),
      {
        title: 'Итог',
        key: 'sum',
        dataIndex: 'sum',
        width: 100,
        render: sum => (sum ? toLocalStringRu(sum) : null)
      },
      {
        title: '',
        key: 'del',
        width: 50,
        render: (
          val,
          row: MaterialCalculation & { itog?: boolean },
          rowIndex
        ) => {
          return isEmpty(val) ||
            !!row.itog !== false ? null : this.isDraft() ? (
            <Icon type="x" onClick={() => this.removeRow(rowIndex)} />
          ) : null;
        }
      }
    ]
  };

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  removeRow = (index: number) => {
    let { data, deletedIds } = this.state;
    if (data[index].id) {
      deletedIds = [...deletedIds, +data[index].id];
    }
    data.splice(index, 1);
    this.setState({ data, deletedIds, touched: true });
  };

  emptyMonths = (): MaterialCalculationMonth[] =>
    Array(12)
      .fill()
      .map((u, index) => ({ cost: 0, count: 0, month: ++index }));

  componentDidMount() {
    this.getData();
  }

  fetch = async () => {
    const { vehiclePlanId } = this.props;
    try {
      let { data } = await materialCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data, oldData: CloneDeep(data), touched: false });
    } catch (error) {
      notification.error({ message: 'Не удалось запросить данные' });
    }
  };

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  deleteMaterialCalculation = async (id: number) => {
    try {
      await materialCalculationApi.delete(id);
    } catch {
      notification.warning({ message: `Не удалось удалить запись ${id}` });
    }
  };

  handleSave = async () => {
    const { data, deletedIds } = this.state;
    this.setState({ loading: true });
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await materialCalculationApi.addOrUpdate(data);
      let deleting = [];
      deletedIds.forEach(id => {
        deleting.push(this.deleteMaterialCalculation(id));
      });
      await Promise.all(deleting);
      this.getData();
    } catch (error) {
      notification.warning({ message: 'Не удалось обновить данные' });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    const { oldData } = this.state;
    this.setState({ data: CloneDeep(oldData), touched: false, deletedIds: [] });
  };

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await materialCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.materialCalculationStatus ===
    calculationStatusEnum.draft;

  renderFooter = () => {
    return (
      <>
        <Button
          type="primary"
          size="small"
          onClick={this.handleSave}
          style={{ marginRight: '16px' }}
        >
          Сохранить
        </Button>
        <Button size="small" onClick={this.handleCancel}>
          Очистить все
        </Button>
      </>
    );
  };

  handlePrint = () => {
    printNotification(async () => {
      await materialCalculationApi.print(this.props.vehiclePlanId, {});
    });
  };

  render() {
    let { data, touched } = this.state;
    const { vehiclePlanId } = this.props;
    const { columns, loading } = this.state;
    data = this.isDraft()
      ? [...data, {}, { ...itogCalculation(data), itog: true }]
      : [...data, { ...itogCalculation(data), itog: true }];
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          title:
            'Расчет стоимости и потребности в материалах и инструментах (РЭН)'
        })}
        <Section>
          <AntTable
            rowKey="uid"
            loading={loading}
            pagination={false}
            data={data}
            columns={columns}
            bordered
            footer={touched && this.renderFooter}
            scroll={{
              x: 2700,
              y: 'calc(100vh - 432px)'
            }}
          />
        </Section>
        {
          <Section style={{ padding: '16px' }}>
            <ButtonsRow>
              {this.isDraft() && (
                <Button type="primary" onClick={this.changeStatus}>
                  Утвердить
                </Button>
              )}
              {!touched && this.state.data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Section>
        }
      </>
    );
  }
}
