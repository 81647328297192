// @flow
import React from 'react';
import { gpsSubscriptionServiceCostApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { formatDateTimeToString, navigate } from '../../lib/helpers';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';
import { approveTypes } from '../../lib/enum';
import { handlingAccessRight } from './accessRight';

const entryPointPath = '/admin/gps-subscription-service-cost/';

const columns = [
  {
    title: 'Подразделение',
    dataIndex: 'orgUnitName'
  },
  {
    title:
      'Стоимость обслуживания за единицу оборудования в месяц без НДС, руб',
    dataIndex: 'cost'
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: status => approveTypes[status]
  }
];

export default withUserAccess(({ userAccess }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);
  const canHandling = () =>
    userAccess.some(access => handlingAccessRight.includes(access));
  return (
    <Page>
      <CommonListPage
        crud={gpsSubscriptionServiceCostApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Стоимость абонентского обслуживания GPS',
          access: {
            add: canHandling() ? () => navigate(`${entryPointPath}new`) : null
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}edit/${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
