// @flow
import { CheckOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import type { moment } from 'moment';
import { DatePicker } from '../../../../components/ant/DatePicker';

import type { Tire } from '../../../../lib/types';
import { Card } from '../../../../components';
import RemoveEquipmentButton from './../RemoveEquipmentButton';
import { formatDate, getMomentValue, convertDateToString } from '../../lib';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import { Spoiler, Icon } from '../../../../components/ui';
import { InputNumber } from '../../../../components/inputs';
import TireMeasurementForm from '../../components/TireMeasurementForm.js';
import { EquipmentActions } from '../elements';
import isNil from 'lodash';
import type { Vehicle } from '../../../../lib/types';
import { seasonTypeTire } from '../../../../lib/enum';

const { Field } = Card;
const ButtonGroup = Button.Group;

const SpoilerContent = styled.div`
  padding: 16px;
  border-bottom: 1px solid #c0ccd7;
`;

type Props = {
  onChange: (tire: Tire) => void,
  onRemove: (tire: Tire) => void,
  tire: Tire,
  vehicle: Vehicle,
  editable: boolean,
  canAddFuelMeasurement: boolean,
};

type State = {
  editMode: boolean,
  tire: Tire,
};

class TireItem extends Component<Props, State> {
  state = {
    editMode: false,
    tire: this.props.tire,
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.tire, this.props.tire)) {
      this.setState({
        tire: this.props.tire,
      });
    }
  }

  onApply = async () => {
    await this.props.onChange(this.state.tire);
    this.setState({
      editMode: false,
    });
  };

  changeTire = (fieldName: string, value: any) => {
    this.setState((prevState) => ({
      tire: {
        ...prevState.tire,
        [fieldName]: value,
      },
    }));
  };

  onCancel = () =>
    this.setState({
      tire: this.props.tire,
      editMode: false,
    });

  getTireLabel(tire: $Shape<Tire>) {
    return [
      `${tire.name} ${tire.brand.name || ''}`,
      tire.factoryNumber && `Заводской номер: ${tire.factoryNumber}`,
    ]
      .filter((item) => !!item)
      .join(' | ');
  }

  render() {
    const { onRemove, editable, canAddFuelMeasurement } = this.props;
    const { tire = {}, editMode } = this.state;
    const {
      name,
      brand = {},
      code,
      actualKilometrage,
      normKilometrage,
      factoryNumber,
      batchNumber,
      treadDepth,
      installDate,
      seasonType,
    } = tire;
    return (
      <Spoiler
        label={this.getTireLabel(tire)}
        suffix={
          <EquipmentActions>
            <ButtonGroup>
              {editable &&
                (editMode ? (
                  <>
                    <Button
                      data-test="check"
                      onClick={this.onApply}
                      icon={<CheckOutlined />}
                    />
                    <Button data-test="close" onClick={this.onCancel}>
                      <Icon type="x" />
                    </Button>
                  </>
                ) : (
                  <Button
                    data-test="edit"
                    onClick={() => this.setState({ editMode: true })}
                  >
                    <Icon type="edit" />
                  </Button>
                ))}
            </ButtonGroup>
            {onRemove && (
              <RemoveEquipmentButton
                onConfirm={(removeDate: moment | string) =>
                  onRemove({
                    ...this.props.tire,
                    removeDate,
                  })
                }
              />
            )}
          </EquipmentActions>
        }
      >
        <SpoilerContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Наименование">{name}</Field>
            </GridItem>
            <GridItem>
              <Field label="Марка">{brand.name}</Field>
            </GridItem>
            <GridItem>
              <Field label="Тип шин">{seasonTypeTire[seasonType]}</Field>
            </GridItem>
            {!isNil(code) && (
              <GridItem>
                <Field label="Мнемокод">{code}</Field>
              </GridItem>
            )}
            {factoryNumber && (
              <GridItem>
                <Field label="Заводской номер">{factoryNumber}</Field>
              </GridItem>
            )}
            {batchNumber && (
              <GridItem>
                <Field label="Номер партии">{batchNumber}</Field>
              </GridItem>
            )}
            <GridItem>
              <Field label="Норматив километража">
                {editMode ? (
                  <InputNumber
                    value={normKilometrage}
                    min={2}
                    decimalSeparator=","
                    step={0.01}
                    onChange={(value: number) =>
                      this.changeTire('normKilometrage', value)
                    }
                  />
                ) : (
                  normKilometrage || '-'
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Норматив моточасов">
                {editMode ? (
                  <InputNumber
                    value={tire.normWorkHours}
                    min={2}
                    decimalSeparator=","
                    step={0.01}
                    onChange={(value: number) =>
                      this.changeTire('normWorkHours', value)
                    }
                  />
                ) : (
                  tire.normWorkHours || '-'
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Фактический пробег">
                {editMode ? (
                  <InputNumber
                    value={actualKilometrage}
                    min={2}
                    decimalSeparator=","
                    step={0.01}
                    onChange={(value: number) =>
                      this.changeTire('actualKilometrage', value)
                    }
                  />
                ) : (
                  actualKilometrage
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Моточасы">
                {editMode ? (
                  <InputNumber
                    value={tire.workHours}
                    min={2}
                    decimalSeparator=","
                    step={0.01}
                    onChange={(value: number) =>
                      this.changeTire('workHours', value)
                    }
                  />
                ) : (
                  tire.workHours || '-'
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Дата последней замены">
                {editMode ? (
                  <DatePicker
                    format="DD.MM.YYYY"
                    value={getMomentValue(installDate)}
                    onChange={(value: Object, dateString: string) =>
                      this.changeTire(
                        'installDate',
                        convertDateToString(value, dateString)
                      )
                    }
                  />
                ) : (
                  formatDate(installDate)
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Высота протектора, мм">{treadDepth}</Field>
            </GridItem>
            {canAddFuelMeasurement && (
              <GridItem fullWidth>
                <TireMeasurementForm tire={tire} vehicleId={tire.vehicleId} />
              </GridItem>
            )}
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}

export default TireItem;
