// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button/button';

import type { FetchListParams } from '../../../lib/api';

import type {
  VehicleTariff,
  ListState,
  UserAccess,
  RateType,
  TariffType,
  OperatingMode,
} from '../../../lib/types';

import { vehicleTariffApi } from '../../../lib/api';
import { tariffTypes, rateType, operatingModes } from '../../../lib/enum';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
} from '../../../lib/helpers';

import { notificationLoading } from '../../../components/Notifications';
import { Header, Section } from '../../../components/layout';
import { Popconfirm, Table, Icon, Operations } from '../../../components/ui';

import { withUserAccess } from '../../withUserAccess';
import addAccess from '../accessRight';
import { HeaderTabs } from './index';
import type { VehicleTariffType } from '../../../lib/types';
import type { Column } from '../../../components/ui/Table';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
  type: VehicleTariffType,
  extColumns: Column[],
};

type State = ListState<VehicleTariff> & {
  filter: {
    vehicleTariffType: VehicleTariffType,
  },
};

const COLUMNS = [
  {
    title: 'Год выпуска',
    dataIndex: 'productionYear',
  },
  {
    title: 'Подрядчик',
    dataIndex: 'contractor.company.name',
  },
  {
    title: 'Начало действия тарифа',
    dataIndex: 'startDate',
    width: '130px',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Окончание действия тарифа ',
    dataIndex: 'endDate',
    width: '130px',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Версия тарифа',
    dataIndex: 'version',
    width: '130px',
    render: (type: RateType) => rateType[type],
  },
  {
    title: 'Тип тарифа',
    dataIndex: 'tariffType',
    width: '130px',
    render: (type: TariffType) => tariffTypes[type],
  },
  {
    title: 'Основания для тарифа',
    dataIndex: 'tariffBases',
  },
  {
    title: 'Тариф (руб.) за 1 час эксплуатации постоянная часть',
    dataIndex: 'tariffFixed',
  },
  {
    title: 'Тариф (руб.) за 1 км пробега переменная часть',
    dataIndex: 'tariffDynamic',
  },
  {
    title: 'Норма списания на 1 час прогрева (л/час)',
    dataIndex: 'warmingNorm',
  },
  {
    title: 'Стоимость топлива за 1л(руб)',
    dataIndex: 'oilCost',
  },
  {
    title: 'Режим эксплуатации',
    dataIndex: 'operatingMode',
    render: (type: OperatingMode) => operatingModes[type],
  },
  {
    title: 'Тип ТС для ИСУ «Бюджетирование»',
    dataIndex: 'vehicleTypeISU',
  },
  {
    title: 'Лизинг',
    dataIndex: 'leasing',
    render: (leasing: boolean) => (leasing ? 'Да' : 'Нет'),
  },
  {
    title: 'Установлено ГБО',
    dataIndex: 'hasGas',
    render: (hasGas: boolean) => (hasGas ? 'Да' : 'Нет'),
  },
];

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export class VehicleTariffBrandList extends Component<Props, State> {
  columns: Object[];
  url: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...getListInitialState(),
      filter: {
        vehicleTariffType: props.type,
      },
    };
    this.columns = [...props.extColumns, ...COLUMNS];
    this.url = `/admin/vehicle-tariff/${props.type}`;
  }

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    const { type, extColumns } = this.props;
    this.setState({ filter: { ...filter, vehicleTariffType: type } }, () =>
      this.fetchVehicleTariff(page)
    );
    if (this.canAdd()) {
      this.columns = [
        ...extColumns,
        ...COLUMNS,
        {
          title: ' ',
          render: (text: string, record: VehicleTariff): any => (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () =>
                  await this.deleteVehicleTariff(record.id)
                }
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          ),
        },
      ];
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  deleteVehicleTariff = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await vehicleTariffApi.deleteVehicleTariff(id);
      await this.fetchVehicleTariff(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchVehicleTariff = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await vehicleTariffApi.fetchVehicleTariff({
      ...filter,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  render() {
    const { location, type } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Тарифы на НТС</h1>}
          right={
            canAdd && (
              <Link to={`${this.url}/new`}>
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <HeaderTabs selectedTab={type} />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () =>
                canAdd
                  ? navigate(`${this.url}/${record.id}/edit`)
                  : navigate(`${this.url}/${record.id}`),
            })}
            columns={this.columns}
            fetch={this.fetchVehicleTariff}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(VehicleTariffBrandList);
