// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import { monitoringPriceApi } from './../../lib/api';
import { formatDateTimeToString } from './../../lib/helpers';

import { Card } from './../../components';
import { Panel, Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';

import type { MonitoringPrice } from '../../lib/types';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  id: string
};

type State = {
  monitoringPrice: ?MonitoringPrice
};

class MonitoringPriceCard extends Component<Props, State> {
  state = {
    monitoringPrice: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.id, 10);
    if (id) {
      try {
        const wash = await monitoringPriceApi.getMonitoringPrice(id);
        this.setState({ monitoringPrice: wash });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  render() {
    const { monitoringPrice } = this.state;
    const { id } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/monitoring-price">
                Список прайсов мониторинга
              </Crumb>
              <Crumb to={`/admin/monitoring-price/${id}`}>
                Просмотр прайса мониторинга №{id}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{`Прайс мониторинга №${id}`}</h1>
        </StyledPanel>
        <Section>
          {monitoringPrice && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Дата начала">
                    {formatDateTimeToString(
                      monitoringPrice.startDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания">
                    {monitoringPrice.endDate
                      ? formatDateTimeToString(
                          monitoringPrice.endDate,
                          'DD.MM.YYYY'
                        )
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Филиал">{monitoringPrice.orgUnitName}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Стоимость">
                    {monitoringPrice.amountPayment.toLocaleString('ru-RU', {
                      style: 'currency',
                      currency: 'RUB'
                    })}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default MonitoringPriceCard;
