// @flow
import React, { useState, useEffect } from 'react';

import { pddTestResultApi } from '../../../lib/api';
import type { UserAccess, PddTestResult } from '../../../lib/types';
import { formatDate, navigate } from '../../../lib/helpers';
import { pddTest } from '../../../lib/enum';

import PageHeader from './../../../components/hoc/common/handbook/PageHeader';

import { withUserAccess } from './../../withUserAccess';
import Results from './components/Results';

type PageProps = {
  id: number,
  userAccess: UserAccess[],
  breadCrumbs?: any[]
};

export default withUserAccess((props: PageProps) => {
  const [data: PddTestResult, setData] = useState({});
  const [loading: boolean, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const result = await pddTestResultApi.get(props.id);
      // На беке должна быть фильтрация по юзеру
      if (result?.id) {
        setData(result);
      } else {
        navigate(-1);
      }
      setLoading(false);
    };
    fetch();
  }, [props.id]);

  return (
    <>
      <PageHeader
        breadCrumbs={
          props.breadCrumbs
            ? props.breadCrumbs
            : [
                { to: '/', name: 'Главная' },
                { to: '/pdd/results/training', name: 'Результаты' },
                {
                  name: `Просмотр ${
                    data?.pddTest?.type === pddTest.exam ? 'экзамена' : 'теста'
                  }`
                }
              ]
        }
        mainHeader={
          data?.pddTest?.type === pddTest.exam
            ? `Экзамен от ${formatDate(data.examinationDate)}`
            : `Тестирование от ${formatDate(data.examinationDate)}`
        }
      />
      <Results data={data} loading={loading} />
    </>
  );
});
