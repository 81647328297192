// @flow
import type { LoadUnloadCargo } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const createLoadUnloadCargo = async (
  loadUnloadCargo: LoadUnloadCargo
): Promise<LoadUnloadCargo> => {
  const added = await fetchRequest.post('/loadUnloadCargo', loadUnloadCargo);
  if (added) return added;
  throw new Error('Не удалось создать погрузку');
};

export const updateLoadUnloadCargo = async (
  loadUnloadCargo: LoadUnloadCargo
): Promise<LoadUnloadCargo> => {
  const updated = await fetchRequest.put('/loadUnloadCargo', loadUnloadCargo);
  if (updated) return updated;
  throw new Error('Не удалось обновить погрузку');
};

export const fetchLoadUnloadCargos = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<LoadUnloadCargo>> => {
  let nodeId = undefined;
  if (params.orgUnitId) {
    nodeId = params.orgUnitId;
    delete params.orgUnitId;
  }
  return await fetchRequest.get('/loadUnloadCargo', {
    ...initialFetchListParams,
    ...params,
    nodeId
  });
};

export const deleteLoadUnloadCargo = async (
  id: number
): Promise<LoadUnloadCargo> => {
  const deleted = await fetchRequest.delete(`/loadUnloadCargo/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить погрузку');
};

export const fetchLoadUnloadCargo = async (
  id: number,
  params: any = {}
): Promise<LoadUnloadCargo> => {
  const loadUnloadCargo = await fetchRequest.get(
    `/loadUnloadCargo/${id}`,
    params
  );
  if (loadUnloadCargo) return loadUnloadCargo;
  throw new Error('Не удалось загрузить погрузу');
};

export default {
  createLoadUnloadCargo,
  updateLoadUnloadCargo,
  fetchLoadUnloadCargos,
  deleteLoadUnloadCargo,
  fetchLoadUnloadCargo
};
