// @flow
import type { AssignmentLimitGroup } from './../types';
import CommonApi from './../commonApi';

const assignmentLimitGroup = new CommonApi<AssignmentLimitGroup>(
  'assignmentLimitGroup',
  'группы закрепления'
);

export default assignmentLimitGroup;
