import React, { useState } from 'react';
import notification from 'antd/lib/notification';

import { orderApi } from './../../../lib/api';
import { goBack } from './../../../lib/helpers';
import { OrgUnitSelect } from './../../../components';
import { Popconfirm } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

export default ({ order }) => {
  const [orgUnitId, setOrgUnitId] = useState(order.orgUnitId);
  return (
    <Popconfirm
      overlayStyle={{
        minWidth: '400px',
        zIndex: 2000,
      }}
      placement="left"
      title={
        <>
          <p>Выберите подразделение</p>
          <OrgUnitSelect
            dropdownStyle={{ zIndex: '2001' }}
            value={orgUnitId}
            onChange={(orgUnitId: number) => {
              setOrgUnitId(orgUnitId);
            }}
          />
        </>
      }
      onConfirm={async () => {
        try {
          notificationLoading({
            message: 'Перенаправление заявки...',
            key: 'upadeting',
          });
          await orderApi.changeDispatcherNode({
            id: order.id,
            dispatcherNodeId: orgUnitId,
          });
        } catch (error) {
          notification.error({
            message: 'Ошибка при загрузке заявки',
            description: error.message || 'Заявка не найдена',
          });
        } finally {
          notification.close('upadeting');
          goBack('/orders');
        }
      }}
    >
      Перенаправить заявку
    </Popconfirm>
  );
};
