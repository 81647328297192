// @flow

import React, { Component } from 'react';
import { FormikErrors } from 'formik';
import { connect } from 'react-redux';
import { navigate, Router } from '@reach/router';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type { UserAccess, VehicleModel } from './../../lib/types';
import { accessTypeEnum } from '../../lib/enum';

import { Panel } from './../../components/layout';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';
import { Tabs, TabItem } from './../../components/ui';

import type { AppState } from '../../ducks/redux';
import {
  cleanVehicleModel,
  fetchVehicleModel,
  updateVehicleModel,
  addVehicleModel
} from '../../ducks/vehicleModel';

import InnerForm from './components/InnerForm';
import InnerCard from './components/InnerCard';
import VehicleModelMaintenance from './VehicleModelMaintenance';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type State = {
  activeTab: string
};

type Props = {
  userAccess: UserAccess[],
  submit: (values: VehicleModel) => Promise<FormikErrors<VehicleModel> | null>,
  model: ?VehicleModel,
  cleanVehicleModel: Function,
  fetchVehicleModel: Function,
  vehicleModelId: number,
  updateVehicleModel: Function,
  addVehicleModel: Function
};

class VehicleModelForm extends Component<Props, State> {
  state: State = {
    activeTab: 'main'
  };

  async componentDidMount() {
    const { vehicleModelId } = this.props;
    await this.props.cleanVehicleModel();
    if (vehicleModelId) {
      try {
        await this.props.fetchVehicleModel(this.props.vehicleModelId);
      } catch (error) {
        navigate('/vehicle-models');
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  handleSubmit = async (values: VehicleModel) => {
    const { model, updateVehicleModel, addVehicleModel } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (model) {
        await updateVehicleModel({
          ...values,
          id: model.id
        });
      } else {
        await addVehicleModel(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/vehicle-models');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  canAdd = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBrandsAndVehicleModels
      ].includes(access)
    );

  render() {
    const { model, vehicleModelId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/vehicle-models">Модели ТС</Crumb>
              {model ? (
                <Crumb to={`/vehicle-models/${model.id}`}>
                  Модель ТС №{model.id}
                </Crumb>
              ) : (
                <Crumb to="/vehicle-models/new">Новая модель ТС</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{model ? `Модель ТС №${model.id}` : 'Новая модель ТС'}</h1>
        </StyledPanel>
        {model && (
          <Tabs withRouter>
            <TabItem
              url={`/vehicle-models/${model.id}/form`}
              activeUrl={[
                `/vehicle-models/${model.id}`,
                `/vehicle-models/${model.id}/form`
              ]}
              tabKey="main"
              label="Данные модели"
            />
            <TabItem
              url={`/vehicle-models/${model.id}/maintenance`}
              tabKey="maintenance"
              label="Техническое обслуживание"
            />
          </Tabs>
        )}
        <Router primary={false}>
          {this.canAdd() ? (
            <InnerForm
              path="/form"
              default
              vehicleModel={model}
              onSubmit={this.handleSubmit}
              onCancel={() => navigate('/vehicle-models')}
            />
          ) : (
            <InnerCard path="/form" vehicleModel={model} />
          )}
          {!!vehicleModelId && (
            <VehicleModelMaintenance
              path="/maintenance"
              vehicleModelId={vehicleModelId}
            />
          )}
        </Router>
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    userAccess: state.auth.profile.access,
    model: state.vehicleModel,
    vehicleModelId: parseInt(ownProps.vehicleModelId, 10)
  }),
  {
    fetchVehicleModel,
    cleanVehicleModel,
    updateVehicleModel,
    addVehicleModel
  }
)(VehicleModelForm);
