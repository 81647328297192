// @flow
import type { BioTreatmentFacilityFact } from '../types';
import CommonApi from '../commonApi';
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams } from './index';

const baseUrl = 'bioTreatmentFacilityFact';
const bioTreatmentFacilityFact = new CommonApi<BioTreatmentFacilityFact>(
  baseUrl,
  'учет БОС факт'
);

const list = async (params: FetchListParams<any> = initialFetchListParams) => {
  const list = await fetchRequest.get(
    `/${baseUrl}/listAccountingBos`,
    { ...initialFetchListParams, ...params }
  );
  if (list) {
    return list;
  }
  throw new Error('Не удалось получить список');
};

const assign = async (bioTreatmentFacilityFact: BioTreatmentFacilityFact) => {
  const fact = await fetchRequest.post(
    `/${baseUrl}/assign`,
    bioTreatmentFacilityFact
  );
  if (fact) {
    return fact;
  }
  throw new Error('Не удалось обновить талон');
};

const addToVolume = async (bioTreatmentFacilityFact: BioTreatmentFacilityFact, volumeId: number) => {
  const fact = await fetchRequest.post(
    `/${baseUrl}/addToVolume/${volumeId}`,
    bioTreatmentFacilityFact
  );
  if (fact) {
    return fact;
  }
  throw new Error('Не удалось создать факт');
}

const changeStatus = async (bioTreatmentFacilityFact: BioTreatmentFacilityFact) => {
  const list = await fetchRequest.put(
    `/${baseUrl}/changeStatus`,
    bioTreatmentFacilityFact
  );
  if (list) {
    return list;
  }
  throw new Error('Не удалось получить список');
};

export default { ...bioTreatmentFacilityFact, list, changeStatus, assign, addToVolume };
