// @flow
import { navigate } from '@reach/router';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React from 'react';

import { Header, Section } from '../../components/layout';
import { Popconfirm } from '../../components/ui';
import { notificationLoading } from '../../components/Notifications';
import { formatLicensePlateMask } from './../../components/inputs/masked-inputs/LicensePlateInput';

import {
  applyMaskToValue,
  formatDateTimeToString,
  getListInitialState,
  errorHandler,
} from './../../lib/helpers';
import { bioTreatmentFacilityTalonApi } from '../../lib/api';
import { accessTypeEnum, bioTreatmentFacilityFactStatus } from '../../lib/enum';
import type {
  BioTreatmentFacilityTalon,
  BioTreatmentFacilityFact,
  BioTreatmentFacilityFactStatusType,
  ListState,
  UserAccess,
} from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

import type { BioTreatmentFacilitiesFilterParams } from './components/Filter';
import BioTreatmentFacilityTalonFilterTable from './components/BioTreatmentFacilityTalonFilterTable';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<BioTreatmentFacilityTalon> & {
  filter: BioTreatmentFacilitiesFilterParams,
};
export class BioTreatmentFacilityTalons extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetch(page)
    );
  }

  fetch = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'savingTalon',
      });
      const data = await bioTreatmentFacilityTalonApi.fetch({
        ...filter,
        page,
      });
      this.setState({ ...data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingTalon');
      this.setState({ loading: false, page });
    }
  };

  applyFilter = (filter: BioTreatmentFacilitiesFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header left={<h1>Журнал талонов</h1>} />
        <Section>
          <BioTreatmentFacilityTalonFilterTable
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            data={data}
            fetch={this.fetch}
            loading={loading}
            page={page}
            pageSize={pageSize}
            totalCount={totalCount}
            location={location}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(BioTreatmentFacilityTalons);
