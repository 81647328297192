//@flow
import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Popover, Switch } from 'antd';
import type { PddAnswer } from '../../../../lib/types/pddTicket';
import { GridItem } from '../../../../components/layout';
import Grid from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';

type PddAnswerModalProps = {
  answer: PddAnswer,
  canBeRight: boolean,
  visible: boolean,
  onClose: () => any,
  onSave: (oldAnswer: PddAnswer, answer: PddAnswer) => any,
  onDelete: (answer: PddAnswer) => any
};

export const AnswerModal = (props: PddAnswerModalProps) => {
  const [answer, setAnswer] = useState(null);

  useEffect(() => {
    setAnswer(props.answer);
  }, [props.answer]);

  return (
    <Modal
      centered
      destroyOnClose
      footer={[
        !props.answer?.id ? (
          <Button key="back" onClick={() => props.onClose()}>
            Отмена
          </Button>
        ) : null,
        props.answer?.id ? (
          <Button
            key="back"
            type="danger"
            onClick={() => props.onDelete(props.answer)}
          >
            Удалить ответ
          </Button>
        ) : null,
        <Button
          disabled={!answer?.description}
          key="submit"
          type="primary"
          onClick={() => props.onSave(props.answer, answer)}
        >
          Сохранить
        </Button>
      ]}
      title={'Ответ на вопрос'}
      visible={props.visible}
      onCancel={props.onClose}
      width={500}
      height={500}
    >
      <Grid cols={1}>
        <GridItem cols={1}>
          <Field>
            <Input
              placeholder="Введите описание ответа"
              value={answer?.description}
              onChange={e =>
                // $FlowFixMe флоу считает что передается функция
                setAnswer({ ...answer, description: e.target.value })
              }
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field>
            {!props.canBeRight && !props.answer?.isRight ? (
              <Popover content="Нельзя поменять ответ, так как другой ответ уже является правильным">
                <Switch
                  disabled={!props.canBeRight && !props.answer?.isRight}
                  checkedChildren="Правильный"
                  unCheckedChildren="Неправильный"
                  checked={answer?.isRight}
                  // $FlowFixMe флоу считает что передается функция
                  onChange={value => setAnswer({ ...answer, isRight: value })}
                />
              </Popover>
            ) : (
              <Switch
                disabled={!props.canBeRight && !props.answer?.isRight}
                checkedChildren="Правильный"
                unCheckedChildren="Неправильный"
                checked={answer?.isRight}
                // $FlowFixMe флоу считает что передается функция
                onChange={value => setAnswer({ ...answer, isRight: value })}
              />
            )}
          </Field>
        </GridItem>
      </Grid>
    </Modal>
  );
};
