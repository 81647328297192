// @flow
import type { PassTariff } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataToServer = (passTariff: PassTariff): PassTariff => ({
  ...passTariff,
  excessRate: convertFromRubToKop(passTariff.excessRate),
  roadDamageRate: convertFromRubToKop(passTariff.roadDamageRate),
  springPassesTax: convertFromRubToKop(passTariff.springPassesTax),
  cargoPassesAgreementTax: convertFromRubToKop(
    passTariff.cargoPassesAgreementTax
  ),
  cargoPassesTax: convertFromRubToKop(passTariff.cargoPassesTax),
  blankPrice: convertFromRubToKop(passTariff.blankPrice)
});

export const convertDataFromServer = (passTariff: PassTariff): PassTariff => ({
  ...passTariff,
  excessRate: convertFromKopToRub(passTariff.excessRate),
  roadDamageRate: convertFromKopToRub(passTariff.roadDamageRate),
  springPassesTax: convertFromKopToRub(passTariff.springPassesTax),
  cargoPassesAgreementTax: convertFromKopToRub(
    passTariff.cargoPassesAgreementTax
  ),
  cargoPassesTax: convertFromKopToRub(passTariff.cargoPassesTax),
  blankPrice: convertFromKopToRub(passTariff.blankPrice)
});

const passTariff = new CommonApi<PassTariff>(
  'passTariff',
  'Тарифы на пропуска',
  convertDataToServer,
  convertDataFromServer
);

export default passTariff;
