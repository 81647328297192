// @flow
import type { WorkingHourContractPrice } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (
  workingHourContractPrice: WorkingHourContractPrice
) => ({
  ...workingHourContractPrice,
  price: convertFromRubToKop(workingHourContractPrice.price)
});

const convertDataFromServer = (
  workingHourContractPrice: WorkingHourContractPrice
) => ({
  ...workingHourContractPrice,
  price: convertFromKopToRub(workingHourContractPrice.price)
});

const workingHourContractPrice = new CommonApi<WorkingHourContractPrice>(
  'workingHourContractPrice',
  'Стоимость нормо-часа подрядным способом',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...workingHourContractPrice
};
