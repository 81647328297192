import React from 'react';
import { TabItem, Tabs } from './../../../../components/ui';

export default () => (
  <Tabs withRouter>
    <TabItem label="Детальная деф. ведомость" url="/budget/act/internal" />
    <TabItem
      label="Дефектная ведомость"
      url="/budget/act/internal/defective-statement"
    />
    <TabItem label="ОС-3" url="/budget/act/internal/os3" />
    <TabItem
      label="Акт оказания услуг на сторону "
      url="/budget/act/internal/outsourcing-act"
    />
    <TabItem
      label="Акт списания ГСМ"
      url="/budget/act/internal/fuels-and-oils-act"
    />
    <TabItem label="Акт списания масел" url="/budget/act/internal/oils-act" />
    <TabItem label="Акт списания МПЗ" url="/budget/act/internal/mpz" />
  </Tabs>
);
