// @flow
import { accessTypeEnum } from './../../lib/enum';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingBudgetVersion
];

export default addAccessRight;
