// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';

import type { ContractVehicleOrder } from './../../lib/types';
import { fetchContractVehicleOrder } from './../../ducks/contractVehicleOrder';
import Grid, { GridItem } from './../../components/layout/Grid';
import { Card } from './../../components';
import { ListTable } from './../../components/ui';
import {
  formatDateTimeToString,
  convertContractorToString,
  applyMaskToValue
} from './../../lib/helpers';
import { cleanContractVehicleOrder } from '../../ducks/contractVehicleOrder';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

const { Field } = Card;

type Props = {
  contractVehicleOrder: ContractVehicleOrder,
  contractVehicleOrderId: number,
  fetchContractVehicleOrder: Function,
  cleanContractVehicleOrder: Function
};

class ContractVehicleOrderCard extends Component<Props> {
  async componentDidMount() {
    const { contractVehicleOrderId } = this.props;
    await this.props.cleanContractVehicleOrder();
    try {
      if (contractVehicleOrderId) {
        await this.props.fetchContractVehicleOrder(contractVehicleOrderId);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { contractVehicleOrder } = this.props;
    if (!contractVehicleOrder) {
      return null;
    }
    return (
      <Grid gutter="16px">
        {contractVehicleOrder.vehicleBrandModel && (
          <GridItem>
            <Field label="Марка ТС">
              {contractVehicleOrder.vehicleBrandModel}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.contractor && (
          <GridItem>
            <Field label="Наименование подрядной организации">
              {convertContractorToString(contractVehicleOrder.contractor)}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.licensePlate && (
          <GridItem>
            <Field label="Гос. номер">
              {applyMaskToValue(
                contractVehicleOrder.licensePlate,
                formatLicensePlateMask
              )}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.orgUnitHeader && (
          <GridItem>
            <Field label="Руководитель">
              {contractVehicleOrder.orgUnitHeader}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.trailerBrandModel && (
          <GridItem>
            <Field label="Марка прицепа">
              {contractVehicleOrder.trailerBrandModel}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.trailerLicensePlate && (
          <GridItem>
            <Field label="Гос. номер прицепа">
              {applyMaskToValue(
                contractVehicleOrder.trailerLicensePlate,
                formatLicensePlateMask
              )}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.driver && (
          <GridItem>
            <Field label="Водитель">{contractVehicleOrder.driver}</Field>
          </GridItem>
        )}
        {contractVehicleOrder.contactEmployees && (
          <GridItem>
            <Field label="Контактные лица">
              {contractVehicleOrder.contactEmployees}
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.cargos && contractVehicleOrder.cargos.length > 0 && (
          <GridItem fullWidth>
            <Field label="Груз">
              <ListTable
                columns={[
                  {
                    title: 'Наименование',
                    key: 'name'
                  },
                  {
                    title: 'Количество мест, общ',
                    key: 'placeNumber'
                  },
                  {
                    title: 'Общий вес, кг',
                    key: 'totalWeight'
                  },
                  {
                    title: 'Вид упаковки',
                    key: 'packageType'
                  }
                ]}
                data={contractVehicleOrder.cargos}
              />
            </Field>
          </GridItem>
        )}
        {contractVehicleOrder.loadCargos &&
          contractVehicleOrder.loadCargos.length > 0 && (
            <GridItem fullWidth>
              <Field label="Погрузка">
                <ListTable
                  columns={[
                    {
                      title: 'Адрес',
                      key: 'address'
                    },
                    {
                      title: 'Начало',
                      key: 'startDateTime',
                      width: '130px',
                      render: (startDateTime: string) =>
                        formatDateTimeToString(startDateTime)
                    },
                    {
                      title: 'Конец',
                      key: 'endDateTime',
                      width: '130px',
                      render: (endDateTime: string) =>
                        formatDateTimeToString(endDateTime)
                    },
                    {
                      title: 'Контактные лица',
                      key: 'contactEmployees'
                    }
                  ]}
                  data={contractVehicleOrder.loadCargos}
                />
              </Field>
            </GridItem>
          )}
        {contractVehicleOrder.unloadCargos &&
          contractVehicleOrder.unloadCargos.length > 0 && (
            <GridItem fullWidth>
              <Field label="Выгрузка">
                <ListTable
                  columns={[
                    {
                      title: 'Адрес',
                      key: 'address'
                    },
                    {
                      title: 'Начало',
                      key: 'startDateTime',
                      width: '130px',
                      render: (startDateTime: string) =>
                        formatDateTimeToString(startDateTime)
                    },
                    {
                      title: 'Конец',
                      key: 'endDateTime',
                      width: '130px',
                      render: (endDateTime: string) =>
                        formatDateTimeToString(endDateTime)
                    },
                    {
                      title: 'Контактное лицо',
                      key: 'contactEmployees'
                    }
                  ]}
                  data={contractVehicleOrder.unloadCargos}
                />
              </Field>
            </GridItem>
          )}
      </Grid>
    );
  }
}

export default connect(
  state => ({
    contractVehicleOrder: state.contractVehicleOrder
  }),
  {
    fetchContractVehicleOrder,
    cleanContractVehicleOrder
  }
)(ContractVehicleOrderCard);
