// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { optionalEquipmentTypes } from '../../../lib/enum';
import { Select, Option } from '../../ant/Select';

export default (props: SelectProps) => (
  <Select {...props}>
    {Object.keys(optionalEquipmentTypes).map(key => (
      <Option value={key} key={key}>
        {optionalEquipmentTypes[key]}
      </Option>
    ))}
  </Select>
);
