// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { stockApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/stock/';

const columns = [
  {
    title: 'Служба',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Мнемокод склада',
    dataIndex: 'mnemocode'
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);
  return (
    <Page>
      <CommonListPage
        crud={stockApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Склады',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}edit/${id}`),
          canDelete: true
        }}
        filter={Filter}
      />
    </Page>
  );
});
