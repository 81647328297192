// @flow
import React, { useState, useEffect } from 'react';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import styled from 'styled-components';
import { navigate } from '@reach/router';

import { pddQuestionApi } from '../../../../lib/api';

import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';

import { getBreadCrumbsByUrl } from '../../../../lib/autoBreadcrumbs';
import type { PddQuestion, PddTestType } from '../../../../lib/types';
import type { PddAnswer } from '../../../../lib/types';

import Section, {
  SectionContent,
  SectionTitle
} from '../../../../components/layout/Section';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';

type InnerProps = {
  data: PddQuestion,
  getNextQuestion: (currentAnswer: PddAnswer) => any
};

type PageProps = {
  id: string,
  examTraining: PddTestType
};

const AnswerButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  // gap: 1rem;
  margin: -0.5rem; // так как gap не не поддерживается в safari 13.1
`;

const AnswerButton = styled(Button)`
  background: #f0f4f8;
  border-color: #f0f4f8;
  color: black;
  padding: 15px;
  height: auto;
  margin: 0.5rem;
  white-space: pre-line;
  min-width: 300px;

  &:hover {
    background: #ccddff;
    border-color: #ccddff;
    box-shadow: none;
    color: black;
  }

  &:disabled,
  &:disabled:hover {
    background: ${props => props.highlight};
    color: ${props => (props.highlight ? 'white' : 'auto')};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 0;

  @media (min-width: 1440px) {
    padding: 0 10% 0 10%;
  }

  @media (min-width: 2560px) {
    padding: 0 20% 0 20%;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  border-radius: 5px;
  object-fit: contain;
`;

// eslint-disable-next-line
const entryPointPath = '/pdd/test/training/exam/';

const InnerForm = (props: InnerProps) => {
  const [userAnswer, setUserAnswer] = useState();

  return <>
    <Section>
      {props.data.photo && (
        <ImageWrapper>
          <StyledImage src={props.data.photo.url} loading="lazy" alt="Фото" />
        </ImageWrapper>
      )}

      <SectionTitle>{props.data.question}</SectionTitle>

      <SectionContent>
        <AnswerButtonsRow>
          {props.data.answers?.map((answer: PddAnswer) => (
            <AnswerButton
              onClick={() => setUserAnswer(answer)}
              highlight={
                userAnswer === answer
                  ? answer.isRight
                    ? '#27ae60'
                    : '#eb2f22'
                  : !userAnswer?.isRight && answer.isRight
                  ? 'rgba(39, 174, 96, 0.5)'
                  : null
              }
              disabled={userAnswer}
            >
              {answer.description}
            </AnswerButton>
          ))}
        </AnswerButtonsRow>

        {!!userAnswer && (
          <>
            <SectionTitle>Поснение к ответу:</SectionTitle>
            <SectionContent>{props.data.answerNote}</SectionContent>
          </>
        )}

        {!!userAnswer && (
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              type="primary"
              onClick={() => {
                props.getNextQuestion(userAnswer);
              }}
            >
              Следующий вопрос <ArrowRightOutlined />
            </Button>
          </div>
        )}
      </SectionContent>
    </Section>
  </>;
};

type EndInnerFormProps = {
  totalAnswersCount: number,
  wrongAnswersCount: number
};

const EndInnerForm = (props: EndInnerFormProps) => {
  return (
    <>
      <Section>
        <SectionContent>
          <>Тестирование по всем темам ПДД завершено</>
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              type="primary"
              onClick={() => {
                navigate('/pdd/test/training/tests/');
              }}
              style={{ marginLeft: 10 }}
            >
              Вернуться к списку тестов
            </Button>
            <Button
              type="primary"
              onClick={() => {
                document.location.reload();
              }}
            >
              Повторить
            </Button>
          </div>
          <SectionTitle divider>Результаты</SectionTitle>
          <SectionContent>
            <Grid gutter="16px" cols={2}>
              <GridItem>
                <Field label="Правильных ответов">
                  {props.totalAnswersCount - props.wrongAnswersCount}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Неправильных ответов">
                  {props.wrongAnswersCount}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </SectionContent>
      </Section>
    </>
  );
};

export default (props: PageProps) => {
  const breadCrumbs = [
    ...getBreadCrumbsByUrl('/pdd/test/training/tests/'),
    {
      name: 'Тестирование'
    }
  ];

  const [data: PddQuestion, setData] = useState({});
  const [questionsNumbers, setQuestionsNumbers] = useState([]);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [wrongAnswersCount, setWrongAnswersCount] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await pddQuestionApi.getAllQuestionsId();
        setQuestionsNumbers(data);
        setTotalQuestionsCount(data.length);
        setCurrentQuestionNumber(1);
      } catch (e) {
        notification.error({ message: e.message });
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await pddQuestionApi.get(
          questionsNumbers[currentQuestionNumber - 1]
        );
        setData(data);
      } catch (e) {
        notification.error({ message: e.message });
      }
    };
    if (
      !!questionsNumbers &&
      !!currentQuestionNumber &&
      currentQuestionNumber <= totalQuestionsCount
    )
      fetch();
  }, [currentQuestionNumber, questionsNumbers, totalQuestionsCount]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader:
          currentQuestionNumber > totalQuestionsCount ? (
            'Тестирование завершено'
          ) : (
            <>
              Вопрос {currentQuestionNumber ?? <LoadingOutlined />} из{' '}
              {totalQuestionsCount}
            </>
          ),
        rightHeader: currentQuestionNumber <= totalQuestionsCount && (
          <Button
            onClick={() => {
              setTotalQuestionsCount(currentQuestionNumber - 1);
            }}
          >
            Показать результат
          </Button>
        )
      }}
      onFetch={() => {}}
    >
      {currentQuestionNumber > totalQuestionsCount ? (
        <EndInnerForm
          noWrapMe
          totalAnswersCount={totalQuestionsCount}
          wrongAnswersCount={wrongAnswersCount}
        />
      ) : (
        <InnerForm
          data={data}
          getNextQuestion={(currentAnswer: PddAnswer) => {
            if (!currentAnswer.isRight) {
              setWrongAnswersCount(wrongAnswersCount + 1);
            }
            setCurrentQuestionNumber(currentQuestionNumber + 1);
          }}
          noWrapMe
        />
      )}
    </CommonCardPage>
  );
};
