import { VehicleBudget } from '../../containers/budget/vehicle';
import { accessTypeEnum } from '../../lib/enum';

// бюджет СТС
export default [
    {
        component: VehicleBudget,
        path: '/budget/vehicle/*',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];