// @flow
import React from 'react';
import styled, { css } from 'styled-components';

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
`;
CalendarContainer.displayName = 'CalendarContainer';

export const CalendarBody = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, calc(100% / 7));
  // grid-template-columns: repeat(7, minmax(172px, 1fr));
  grid-template-rows: repeat(5, minmax(121px, 1fr));
  border-left: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
  overflow: auto;
`;
CalendarBody.displayName = 'CalendarBody';

type CalendarItemProps = {
  isToday: boolean,
  isElapsedDay: boolean
};

export const CalendarItem = styled(
  ({ isToday, ...props }: CalendarItemProps) => <div {...props} />
)`
  min-height: 121px;
  display: flex;
  position: relative;
  border-right: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  box-sizing: border-box;
  padding: 10px;
  background: white;
  font-size: 24px;
  ${({ isToday }) =>
    isToday &&
    css`
      border: 1px solid #0093fe;
    `};
  ${({ isElapsedDay }) =>
    isElapsedDay &&
    css`
      background: #eff1f4;
    `};
  ${({ currentMonth }) =>
    currentMonth &&
    css`
      &:hover {
        cursor: pointer;
        &::before {
          opacity: 1;
        }
      }
    `}
  &::before {
    content: '';
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 2;
  }
`;

export const CalendarHeader = styled.header`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, calc(100% / 7));
  // grid-template-columns: repeat(7, minmax(172px, 1fr));
`;

export const CalendarHeaderItem = styled.div`
  display: flex;
  align-items: center;
  margin: 15px;
  font-weight: bold;
`;
