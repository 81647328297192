// @flow
import { navigate } from '@reach/router';
import styled from 'styled-components';
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React from 'react';

import { Selects } from '../../components';
import { Header, Section } from '../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import Table from '../../components/ui/Table';
import { Popconfirm, Icon, Operations } from './../../components/ui';
import { formatLicensePlateMask } from './../../components/inputs/masked-inputs/LicensePlateInput';

import {
  applyMaskToValue,
  formatDateTimeToString,
  getListInitialState,
  errorHandler,
} from './../../lib/helpers';
import {
  bioTreatmentFacilityFactApi,
  bioTreatmentFacilityContractApi,
} from '../../lib/api';
import {
  accessTypeEnum,
  bioTreatmentFacilityFactStatus,
  bioTreatmentFacilityFactStatusEnum,
} from '../../lib/enum';
import type {
  BioTreatmentFacilityFact,
  BioTreatmentFacilityFactStatusType,
  ListState,
  UserAccess,
} from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

import type { BioTreatmentFacilitiesFilterParams } from './components/Filter';
import Filter from './components/Filter';
import ModalTalonSelect from './components/ModalTalonSelect';
import ModalImage from './components/ModalImage';

const { BioTreatmentFacilityFactStatusSelect } = Selects;

const StyledSpan = styled('span')`
  color: #2770ff;
  font-weight: 500;
`;
const StyledStatus = styled('div')`
  display: flex;
  align-items: baseline;
`;
const StyledBtn = styled(Button)`
  margin-left: 4px;
  border: none;
  color: #2770ff;
`;

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<BioTreatmentFacilityFact> & {
  filter: BioTreatmentFacilitiesFilterParams,
  columns: Array<Object>,
  modal: {
    show: boolean,
    fact: ?BioTreatmentFacilityFact,
  },
  editStatus: {
    id: number | null,
    value: string | null,
  },
};
const INIT_MODAL = { show: false, fact: null };
export class BioTreatmentFacilityFacts extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    editStatus: {
      id: null,
      status: null,
    },
    columns: [
      {
        title: 'Гос.номер',
        key: 'licensePlate',
        dataIndex: 'licensePlate',
        render: (licensePlate: string) =>
          applyMaskToValue(licensePlate, formatLicensePlateMask),
      },
      {
        title: 'Дата посещения',
        key: 'date',
        dataIndex: 'date',
        render: (date: string) =>
          formatDateTimeToString(date, 'DD.MM.YYYY HH:mm'),
      },
      {
        title: 'Статус',
        key: 'status',
        dataIndex: 'status',
        width: 200,
        render: (
          status: BioTreatmentFacilityFactStatusType,
          record: BioTreatmentFacilityFact
        ) => {
          return bioTreatmentFacilityFactStatus[status];
        },
      },
      {
        title: 'Объем',
        key: 'volume',
        dataIndex:
          'bioTreatmentFacilityTalon.bioTreatmentFacilityVolume.volume',
      },
      {
        title: 'Бос',
        key: 'bio',
        dataIndex: 'orgUnitName',
      },
      {
        title: 'Номер талона',
        key: 'bioTreatmentFacilityTalonId',
        dataIndex: 'bioTreatmentFacilityTalonId',
        onCell: (fact: BioTreatmentFacilityFact) => {
          return {
            onClick: () => {
              this.setState((oldState) => ({
                ...oldState,
                modal: {
                  show: true,
                  fact,
                },
              }));
            },
          };
        },
        render: (
          bioTreatmentFacilityTalonId: number,
          fact: BioTreatmentFacilityFact
        ) => {
          return (
            <StyledSpan>
              {bioTreatmentFacilityTalonId
                ? bioTreatmentFacilityTalonId
                : 'Выбрать'}{' '}
            </StyledSpan>
          );
        },
      },
      {
        title: 'Контрагент',
        key: 'companyName',
        dataIndex: 'companyName',
      },
      {
        title: 'Снимок',
        key: 'path',
        dataIndex: 'path',
        render: (imageUrl) => {
          return imageUrl ? (
            <ModalImage
              files={imageUrl ? [{ imageUrl, title: 'Снимок' }] : []}
            />
          ) : (
            '-'
          );
        },
      },
    ],
    modal: { ...INIT_MODAL },
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetch(page)
    );
  }

  fetch = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'savingTalon',
      });
      const data = await bioTreatmentFacilityFactApi.list({
        ...filter,
        page,
      });
      this.setState({ ...data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingTalon');
      this.setState({ loading: false, page });
    }
  };

  applyFilter = (filter: BioTreatmentFacilitiesFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  handleCancelModal = () => {
    this.setState((oldState) => ({ ...oldState, modal: { ...INIT_MODAL } }));
  };

  handleSubmitModal = async (
    fact: BioTreatmentFacilityFact,
    bioTreatmentFacilityContractId?: number
  ) => {
    const { page } = this.state;
    let companyName = null;
    let orgUnitName = null;
    try {
      notificationLoading({
        message: 'Сохраняем...',
        key: 'savingTalon',
      });
      if (bioTreatmentFacilityContractId) {
        const contract = await bioTreatmentFacilityContractApi.get(
          bioTreatmentFacilityContractId
        );
        companyName = contract?.company?.name ?? null;
        orgUnitName = contract?.organizationUnit?.name ?? null;
      }
      await bioTreatmentFacilityFactApi.assign({
        ...fact,
        companyName,
        orgUnitName,
      });
      await this.fetch(page);
      this.setState((oldState) => ({ ...oldState, modal: { ...INIT_MODAL } }));
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingTalon');
    }
  };

  handleCancelTalon = async (fact: BioTreatmentFacilityFact) => {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Отменяем талон...',
        key: 'canceledTalon',
      });
      await bioTreatmentFacilityFactApi.changeStatus({
        ...fact,
        status: bioTreatmentFacilityFactStatusEnum.canceled,
        companyName: null,
        orgUnitName: null,
      });
      await this.fetch(page);
      this.setState((oldState) => ({ ...oldState, modal: { ...INIT_MODAL } }));
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('canceledTalon');
    }
  };

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filter,
      columns,
      modal,
    } = this.state;
    return (
      <>
        <ModalTalonSelect
          visible={modal.show}
          onCancelTalon={this.handleCancelTalon}
          onCancel={this.handleCancelModal}
          onSubmit={this.handleSubmitModal}
          bioTreatmentFacilityFact={modal.fact}
        />
        <Header
          left={<h1>Журнал въездов</h1>}
          right={
            <Button onClick={() => this.fetch(this.state.page)}>
              Обновить данные
            </Button>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={columns}
            data={data}
            fetch={this.fetch}
            fetchOnMount
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(BioTreatmentFacilityFacts);
