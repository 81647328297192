// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import type { AttachedEquipment as AttachedEquipmentType } from './../../../lib/types';
import { AttachedEquipment as AttachedEquipmentComponents } from './../components';
import { attachedEquipmentApi } from './../../../lib/api';
import {
  resetVehicleStatus,
  setVehicleAttachedEquipments
} from './../../../ducks/vehicle';
import type { AppState } from '../../../ducks/redux';

const { EditableItem, AttachedEquipmentForm } = AttachedEquipmentComponents;

type Props = {
  items: Array<AttachedEquipmentType>,
  resetVehicleStatus: (vehicleId: number) => void,
  setVehicleAttachedEquipments: (vehicleId: number) => void,
  vehicleId: number,
  editable: boolean,
  employeeBranchOrgUnitId: number
};

type State = {
  availableAttachedEquipments: AttachedEquipmentType[],
  isLoading: boolean
};

export class AttachedEquipment extends Component<Props, State> {
  state = {
    availableAttachedEquipments: [],
    isLoading: false
  };

  async componentDidMount() {
    await this.fetchAvailableAttachedEquipments();
  }

  fetchAvailableAttachedEquipments = async (search?: string) => {
    if (this.props.editable) {
      this.setState({
        isLoading: true
      });
      let availableAttachedEquipments = [];
      const result = await attachedEquipmentApi.fetchAttachedEquipments({
        search,
        page: 1,
        pageSize: 50,
        isFree: true,
        orgUnitId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren'
      });

      if (result) {
        availableAttachedEquipments = result.data;
      }
      this.setState({
        availableAttachedEquipments,
        isLoading: false
      });
    }
  };

  onAdd = async (optionalEquipment: AttachedEquipmentType) => {
    await attachedEquipmentApi.updateAttachedEquipmentVehicle({
      ...optionalEquipment,
      vehicleId: this.props.vehicleId
    });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleAttachedEquipments(this.props.vehicleId);
    await this.fetchAvailableAttachedEquipments();
  };

  onEdit = async (optionalEquipment: AttachedEquipmentType) => {
    await attachedEquipmentApi.updateAttachedEquipmentVehicle(
      optionalEquipment
    );
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleAttachedEquipments(this.props.vehicleId);
  };

  onRemove = async (optionalEquipment: AttachedEquipmentType) => {
    await attachedEquipmentApi.updateAttachedEquipment({
      ...optionalEquipment,
      vehicleId: null
    });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleAttachedEquipments(this.props.vehicleId);
    await this.fetchAvailableAttachedEquipments();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableAttachedEquipments(value),
    500
  );

  render() {
    const { items = [], editable }: Props = this.props;
    const { availableAttachedEquipments, isLoading } = this.state;
    return (
      <>
        {items.map((item: AttachedEquipmentType) => {
          if (!item) {
            return null;
          }
          return (
            <EditableItem
              key={item.id}
              attachedEquipment={item}
              onChange={this.onEdit}
              onRemove={this.onRemove}
              editable={editable}
            />
          );
        })}
        {editable && (
          <AttachedEquipmentForm
            onSubmit={this.onAdd}
            availableEquipments={availableAttachedEquipments}
            handleSearch={this.handleSearch}
            fetchData={this.fetchAvailableAttachedEquipments}
            isLoading={isLoading}
          />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }),
  {
    resetVehicleStatus,
    setVehicleAttachedEquipments
  }
)(AttachedEquipment);
