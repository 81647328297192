// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import styled, { css } from 'styled-components';
import { Option, Select } from '../ant/Select';

export const yesNoValue = {
  yes: true,
  no: false,
};

const yesNo = {
  yes: 'Да',
  no: 'Нет',
};

type Props = SelectProps & {
  fullWidth?: boolean,
  reset?: boolean, // сбрасывать значение на undefined или нет
  optionText?: string,
};

const StyledSelect = styled(Select)`
  ${(props: Props) => css`
    max-width: ${props.fullWidth ? '100%' : '88px'};
  `};
`;

export default ({ value, reset, optionText, ...props }: Props) => {
  const { placeholder } = props;
  const valueSelect = value
    ? yesNo.yes
    : value === false
      ? yesNo.no
      : placeholder === undefined
        ? yesNo.no
        : undefined;
  return (
    <StyledSelect
      allowClear
      placeholder={placeholder ? placeholder : 'Нет'}
      {...props}
      value={valueSelect}
      onChange={(value) => {
        if (reset === true) {
          props.onChange &&
          props.onChange(value ? value === yesNo.yes : undefined);
        } else {
          props.onChange && props.onChange(value === yesNo.yes);
        }
      }}
    >
      {Object.keys(yesNo).map((key) => (
        <Option value={yesNo[key]} key={key}>
          {optionText ? `${optionText} (${yesNo[key]})` : yesNo[key]}
        </Option>
      ))}
    </StyledSelect>
  );
};