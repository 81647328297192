// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import InputNumber from 'antd/lib/input-number';
import sumBy from 'lodash/sumBy';
import Menu from 'antd/lib/menu';

import {
  Card,
  WaypointsForm,
  WaypointsViewer,
  TripMap,
} from './../../../components';
import Selects from './../../../components/selects';
import type {
  Trip,
  WayPoint,
  UserAccess,
  TripRange,
  TripStatus,
} from './../../../lib/types';
import { selectTrip } from './../../../ducks/selectors';
import {
  cleanTrip,
  fetchTrip,
  changeStatus,
  updateTrip,
} from './../../../ducks/trip';
import {
  vehicleTypes,
  orderObjectives,
  accessTypeEnum,
  ownerTypes,
  tripStatuses,
  attachedEquipmentFuelTankType,
  attachedEquipmentFuelTankTypeEnum,
  fuelTypes,
} from './../../../lib/enum';
import {
  tripApi,
  fuelMultiplierApi,
  attachedEquipmentApi,
  tripAttachedEquipmentApi,
} from './../../../lib/api';
import type { AppState } from './../../../ducks/redux';
import { tripStatusEnum } from './../../../lib/enum';
import {
  formatDateRangeString,
  calculateDateRangeString,
  formatDateTimeToString,
  convertFromMToKm,
  convertEmployeeToString,
  validateWaypointsOrder,
  navigate,
  getPathWithHistoryParams,
  minus,
  plus,
} from './../../../lib/helpers';
import {
  Panel,
  Section,
  SectionTitle,
  Divider,
} from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { directions } from './../../../lib/gis';
import { withUserAccess } from './../../withUserAccess';
import { applyMaskToValue } from './../../../lib/helpers';
import Header from './../../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { getMinMaxWaypointDateTime } from './../lib';
import { Dropdown } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { canShowEngineWorkHoursData } from '../../Vehicles/lib';

const { Field } = Card;
const { YesNoSelect } = Selects;

const Operations = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const StyledPanel = styled(Panel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
`;

const StyledGridItemTitle = styled(GridItem)`
  margin: 10px 0px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;
`;

const Content = styled.div`
  padding: 16px;
`;

export const StyledGridItem = styled(GridItem)`
  .field > .field__value {
    margin-bottom: 23px !important;
  }
`;

type Props = {
  trip: Trip,
  fetchTrip: Function,
  tripId: string,
  cleanTrip: () => void,
  updateTrip: Function,
  changeStatus: (tripId: number, status: TripStatus) => Promise<void>,
  userAccess: UserAccess[],
};

type State = {
  showSelectDriver: boolean,
  trip: Trip,
  touched: boolean,
  expectedRouteGeometry: any,
  loading: boolean,
};

export class TripCard extends Component<Props, State> {
  state = {
    showSelectDriver: false,
    trip: this.props.trip,
    touched: false,
    expectedRouteGeometry: null,
    loading: false,
  };

  async componentDidMount() {
    try {
      const { tripId } = this.props;
      await this.props.cleanTrip();
      if (tripId) {
        await this.props.fetchTrip(tripId);
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.trip, this.props.trip)) {
      this.setState({
        trip: this.props.trip,
      });
    }
  }

  openVerification = () =>
    navigate(`/trips/self/${this.props.tripId}/verification`);

  updateTrip = async () => {
    const { trip } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateTrip(trip);
      this.setState({
        touched: false,
      });
      notification.success({
        message: 'Успешно обновлено',
        description: 'Данные путевого листа были успешно обновлены',
      });
    } catch (err) {
      notification.error({
        message: err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  discardChanges = () =>
    this.setState({
      trip: this.props.trip,
      touched: false,
    });

  changeTripWaypoints = (waypoints: WayPoint[]) => {
    this.setState((prevState: State) => ({
      trip: {
        ...prevState.trip,
        expectedRoute: {
          ...prevState.trip.expectedRoute,
          waypoints,
        },
      },
      touched: true,
    }));
  };

  changeTripInfo = (fieldName: string, value: any) =>
    this.setState((prevState) => ({
      trip: {
        ...prevState.trip,
        [fieldName]: value,
      },
      touched: true,
    }));

  changeExpectedRouteInfo = (fieldName: string, value: any) =>
    this.setState((prevState) => ({
      trip: {
        ...prevState.trip,
        expectedRoute: {
          ...prevState.trip.expectedRoute,
          [fieldName]: value,
        },
      },
      touched: true,
    }));

  // Подсчитываем расстояние маршрута + расход топлива по плану
  calculateRoute = async () => {
    try {
      notificationLoading({
        message: 'Построение маршрута...',
        key: 'buildingRoute',
      });
      const { trip } = this.state;
      const expectedWaypoints =
        (trip.expectedRoute && trip.expectedRoute.waypoints) || [];

      const expectedRouteGeometry = await directions(expectedWaypoints);
      if (this.canEditTrip() && expectedRouteGeometry) {
        const vehicleFuelMultipliers =
          await fuelMultiplierApi.fetchVehicleFuelMultipliers(trip.vehicleId);
        const sumFuelMultipliers =
          1 + (sumBy(vehicleFuelMultipliers, 'value') || 0);
        const distance = parseFloat(
          convertFromMToKm(expectedRouteGeometry.distance).toFixed(2)
        );
        // Ожидаемый расход = пробег * расход л/1км * сумму коэф. ТС
        const expectedFuelConsumption = parseFloat(
          (
            distance *
            (trip.vehicle.vehicleModel.primaryFuelConsumption / 100) *
            sumFuelMultipliers
          ).toFixed(2)
        );
        this.setState((prevState: State) => {
          const trip = prevState.trip;
          const distanceAtStart = parseFloat(
            convertFromMToKm(expectedRouteGeometry.distanceAtStart)
          );
          const distanceAtEnd = parseFloat(
            convertFromMToKm(expectedRouteGeometry.distanceAtEnd)
          );
          return {
            trip: {
              ...trip,
              distanceAtStart,
              distanceAtEnd,
              expectedRoute: {
                ...trip.expectedRoute,
                distance,
              },
              expectedFuelConsumption,
              odometerAtEnd: trip.odometerAtStart + distance,
            },
          };
        }, this.updateTrip);
        notification.success({
          message: 'Маршрут успешно построен',
        });
      }
      this.setState({
        expectedRouteGeometry,
      });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при построении маршрута',
        description: err ? err.message : '',
      });
    } finally {
      notification.close('buildingRoute');
    }
  };

  canHandleTrip = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingTrip,
      ].includes(access)
    );

  addDropDownAction = () => {
    const { trip } = this.state;
    return (
      trip &&
      (trip.status === tripStatusEnum.draft ||
        trip.status === tripStatusEnum.created ||
        trip.status === tripStatusEnum.opened ||
        trip.status === tripStatusEnum.verification) &&
      this.canHandleTrip()
    );
  };

  canEditTrip = () => {
    const { trip } = this.state;
    return (
      trip &&
      (trip.status === tripStatusEnum.draft ||
        trip.status === tripStatusEnum.created) &&
      this.canHandleTrip()
    );
  };

  canVerify = () => {
    const { trip, userAccess } = this.props;
    if (trip && trip.vehicle) {
      if (trip.vehicle.isDeleted) return false;
    }
    return (
      trip &&
      (trip.status === tripStatusEnum.opened ||
        trip.status === tripStatusEnum.verification) &&
      userAccess.some((access) =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.handlingTrip,
        ].includes(access)
      )
    );
  };

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await tripApi.printTrip(this.props.trip.id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  handleImprint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await tripApi.imprintTrip(this.props.trip.id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  getHeaderTitle = function (trip: Trip) {
    let title = `Рейс №${trip.id}`;
    if (trip.idNumber) {
      title = `Путевой лист №${trip.idNumber || ''}`;
    }
    if (trip.vehicle && trip.vehicle.ownerType === ownerTypes.contract) {
      return title;
    }
    return `${title} по заявке №${trip.ordersId.join(', ')}`;
  };

  changeStatus = async (tripId: number, status: TripStatus) => {
    this.setState({ loading: true });
    try {
      notificationLoading({
        message: 'Смена статуса...',
        style: { zIndex: '99999' },
        key: 'changeStatus',
      });
      await this.props.changeStatus(tripId, status);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('changeStatus');
      this.setState({ loading: false });
    }
  };

  saveAttachedEquipments = async () => {
    const { trip } = this.state;
    let attachedEquipments = [];
    let tripAttachedEquipments = new Map();
    if (trip && trip.vehicle) {
      tripAttachedEquipments = new Map(
        trip?.attachedEquipments.map((attached) => [
          attached.attachedEquipmentId,
          attached,
        ]) ?? []
      );
      attachedEquipments =
        await attachedEquipmentApi.fetchVehicleAttachedEquipments(
          trip.vehicleId
        );
    }
    attachedEquipments.forEach((attachedEquipment, index) => {
      const isOwnTank =
        attachedEquipment.fuelTankType ===
        attachedEquipmentFuelTankTypeEnum.ownTank;
      const oldEquipment = tripAttachedEquipments.get(attachedEquipment.id);
      const newEquipment = {
        attachedEquipment,
        attachedEquipmentId: attachedEquipment.id,
        trip,
        tripId: trip.id,
        machineHoursAtStart: attachedEquipment.machineHours ?? 0,
        machineHoursAtEnd: attachedEquipment.machineHours ?? 0,
        fuelAmountAtStart: isOwnTank
          ? attachedEquipment.fuelRemaining ?? 0
          : undefined,
        fuelAmountAtEnd: isOwnTank
          ? attachedEquipment.fuelRemaining ?? 0
          : undefined,
        fuelIssued: isOwnTank ? 0 : undefined,
      };
      if (oldEquipment) {
        tripAttachedEquipments.set(attachedEquipment.id, {
          ...oldEquipment,
          ...newEquipment,
        });
      } else {
        tripAttachedEquipments.set(attachedEquipment.id, newEquipment);
      }
    });
    await Promise.all(
      Array.from(tripAttachedEquipments.values()).map(
        async (tripAttachedEquipment) => {
          if (tripAttachedEquipment.id) {
            return await tripAttachedEquipmentApi.update({
              ...tripAttachedEquipment,
              tripId: trip.id,
              trip: undefined,
              attachedEquipment: undefined,
            });
          } else {
            return await tripAttachedEquipmentApi.add({
              ...tripAttachedEquipment,
              tripId: trip.id,
              trip: undefined,
              attachedEquipment: undefined,
            });
          }
        }
      )
    );
  };

  render() {
    const { changeStatus, updateTrip } = this.props;
    const { expectedRouteGeometry, trip, touched, loading } = this.state;
    if (!trip) {
      return null;
    }
    const expectedWaypoints =
      (trip.expectedRoute && trip.expectedRoute.waypoints) || [];
    const canEdit = this.canEditTrip();
    const canHandle = this.canHandleTrip();
    const canVerify = this.canVerify();
    const addDropDownAction = this.addDropDownAction();
    const routeMinMaxDates = getMinMaxWaypointDateTime(expectedWaypoints);
    const canShowEngineHours = canShowEngineWorkHoursData(
      trip?.vehicle?.vehicleModel?.type
    );
    const machineHoursAndFuel = (trip?.attachedEquipments ?? []).reduce(
      (prev, current) => {
        return {
          machineHours: plus(
            prev.machineHours ?? 0,
            minus(
              current.machineHoursAtEnd ?? 0,
              current.machineHoursAtStart ?? 0
            )
          ),
          fuelIssued: plus(prev.fuelIssued ?? 0, current.fuelIssued ?? 0),
        };
      },
      { machineHours: null, fuelIssued: null }
    );
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/trips/self')}>
                Путевые листы собственных ТС
              </Crumb>
              <Crumb>{this.getHeaderTitle(trip)}</Crumb>
            </Breadcrumbs>
          }
          right={
            <Operations>
              {trip.status !== tripStatusEnum.draft &&
                trip.status !== tripStatusEnum.canceled && (
                  <Button onClick={this.handlePrint}>Печать</Button>
                )}
              {trip.status === tripStatusEnum.verified && (
                <Button onClick={this.handleImprint}>Впечатать</Button>
              )}
              {(canVerify ||
                (canHandle &&
                  [tripStatusEnum.draft, tripStatusEnum.created].includes(
                    trip.status
                  ))) && (
                <Button.Group>
                  {trip.status === tripStatusEnum.draft && canHandle && (
                    <Button
                      onClick={async () => {
                        if (
                          !validateWaypointsOrder(trip.expectedRoute.waypoints)
                        ) {
                          try {
                            this.setState({ loading: true });
                            await this.saveAttachedEquipments();
                            await updateTrip(trip);
                            await changeStatus(trip.id, tripStatusEnum.created);
                          } catch (error) {
                            notification.error({
                              message: 'Ошибка',
                              description: error.message,
                            });
                          } finally {
                            this.setState({ loading: false });
                          }
                        } else {
                          notification.error({
                            message: 'Произошла ошибка при обновлении',
                            description:
                              'Время прибытия в каждой точке должно быть больше времени отправления из предыдущей точки',
                          });
                        }
                      }}
                      disabled={loading}
                      loading={loading}
                      type="primary"
                      className="createButton"
                    >
                      Создать
                    </Button>
                  )}
                  {trip.status === tripStatusEnum.created && canHandle && (
                    <Button
                      className="startButton"
                      loading={loading}
                      disabled={loading}
                      onClick={async () => {
                        try {
                          this.setState({ loading: true });
                          await updateTrip(trip);
                          await changeStatus(trip.id, tripStatusEnum.opened);
                        } catch (error) {
                          notification.error({
                            message: 'Ошибка',
                            description: error.message,
                          });
                        } finally {
                          this.setState({ loading: false });
                        }
                      }}
                      type="primary"
                    >
                      Запустить в работу
                    </Button>
                  )}
                  {canVerify && (
                    <Button type="primary" onClick={this.openVerification}>
                      Таксировка
                    </Button>
                  )}
                </Button.Group>
              )}
              {addDropDownAction && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        data-test="cancelButton"
                        onClick={() =>
                          this.changeStatus(trip.id, tripStatusEnum.canceled)
                        }
                      >
                        Отменить рейс
                      </Menu.Item>
                      {canEdit && (
                        <Menu.Item
                          onClick={() =>
                            navigate(`/trips/self/${trip.id}/edit`)
                          }
                        >
                          Редактировать
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    type="primary"
                    data-test="actionsButton"
                    icon={<EllipsisOutlined />}
                  />
                </Dropdown>
              )}
            </Operations>
          }
        />
        <StyledPanel>
          <h1>{this.getHeaderTitle(trip)}</h1>
        </StyledPanel>

        <Section>
          <Content>
            <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
              <GridItem>
                <Field label="Статус">{tripStatuses[trip.status]}</Field>
              </GridItem>
              <GridItem>
                <Field label="Период выделения ТС">
                  {formatDateRangeString(trip.startDate, trip.endDate)}
                </Field>
              </GridItem>
              {!!trip.formationDate && (
                <GridItem>
                  <Field label="Дата формирования">
                    {trip.status !== tripStatusEnum.canceled &&
                      tripStatusEnum.draft &&
                      formatDateTimeToString(trip.formationDate)}
                  </Field>
                </GridItem>
              )}
              {!!trip.objective && (
                <GridItem>
                  <Field label="Цель поездки">
                    {orderObjectives[trip.objective]}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="С прицепом">
                  {trip.withTrailer ? 'Да' : 'Нет'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение службы">{trip.orgUnitName}</Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение (ФИО)">
                  {convertEmployeeToString(trip.employee)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Количество пассажиров">{trip.workersCount}</Field>
              </GridItem>
              <GridItem>
                <Field label="Тип ТС">
                  {trip.vehicle &&
                    trip.vehicle.vehicleModel &&
                    vehicleTypes[trip.vehicle.vehicleModel.type]}
                </Field>
              </GridItem>
              {trip.vehicle && trip.vehicle.vehicleModel && (
                <>
                  <GridItem>
                    <Field label="Марка ТС">
                      {trip.vehicle.vehicleModel.brandName}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Модель ТС">
                      {trip.vehicle.vehicleModel.name}
                    </Field>
                  </GridItem>
                </>
              )}
              <GridItem>
                <Field label="Государственный регистрационный знак">
                  {trip.vehicle
                    ? applyMaskToValue(
                        trip.vehicle.licensePlate,
                        formatLicensePlateMask
                      )
                    : trip.licensePlate}
                </Field>
              </GridItem>
              {trip.trailer && (
                <>
                  {trip.trailer.vehicleModel && (
                    <>
                      <GridItem>
                        <Field label="Марка прицепа">
                          {trip.trailer.vehicleModel.brandName}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Модель прицепа">
                          {trip.trailer.vehicleModel.name}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  {trip.trailer.licensePlate && (
                    <GridItem>
                      <Field label="Государственный регистрационный знак прицепа">
                        {applyMaskToValue(
                          trip.trailer.licensePlate,
                          formatLicensePlateMask
                        )}
                      </Field>
                    </GridItem>
                  )}
                </>
              )}
              {!!trip.driver && (
                <GridItem>
                  <Field label="Водитель">
                    {convertEmployeeToString(trip.driver.employee)}
                  </Field>
                </GridItem>
              )}
              {trip.vehicle && trip.vehicle.fuelCard && (
                <GridItem>
                  <Field label="Топливная карта">
                    {trip.vehicle.fuelCard.cardNumber}
                  </Field>
                </GridItem>
              )}
              {!!trip.totalWorkingTime && (
                <GridItem>
                  <Field label="Суммарное время работы">
                    {trip.totalWorkingTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.movementTime && (
                <GridItem>
                  <Field label="Время в движении">{trip.movementTime} ч.</Field>
                </GridItem>
              )}
              {!!trip.movementPrice && (
                <GridItem>
                  <Field label="Тариф в движении">
                    {trip.movementPrice} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.idleTime && (
                <GridItem>
                  <Field label="Время простоя">{trip.idleTime} ч.</Field>
                </GridItem>
              )}
              {!!trip.idlePrice && (
                <GridItem>
                  <Field label="Тариф простоя">{trip.idlePrice} руб.</Field>
                </GridItem>
              )}
              {!!trip.onHoldTime && (
                <GridItem>
                  <Field label="Время в ожидании">{trip.onHoldTime} ч.</Field>
                </GridItem>
              )}
              {!!trip.onHoldPrice && (
                <GridItem>
                  <Field label="Тариф в ожидании">
                    {trip.onHoldPrice} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.distanceByContractor && (
                <GridItem>
                  <Field label="Пробег">{trip.distanceByContractor} км.</Field>
                </GridItem>
              )}
              {!!trip.pricePerKm && (
                <GridItem>
                  <Field label="Тариф за км">{trip.pricePerKm} руб.</Field>
                </GridItem>
              )}
              {!!trip.totalPrice && (
                <GridItem>
                  <Field label="Сумма без НДС">{trip.totalPrice} руб.</Field>
                </GridItem>
              )}
              {!!trip.airHeaterTime && (
                <GridItem>
                  <Field label="Время работы воздушного отопителя">
                    {trip.airHeaterTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.airHeaterRate && (
                <GridItem>
                  <Field label="Время работы жидкостного подогревателя">
                    {trip.airHeaterRate} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.liquidHeaterTime && (
                <GridItem>
                  <Field label="Тариф для жидкостного подогревателя">
                    {trip.liquidHeaterTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.liquidHeaterRate && (
                <GridItem>
                  <Field label="Тариф для жидкостного подогревателя">
                    {trip.liquidHeaterRate} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.powerGeneratorTime && (
                <GridItem>
                  <Field label="Время работы силового отопителя">
                    {trip.powerGeneratorTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.powerGeneratorRate && (
                <GridItem>
                  <Field label="Тариф для силового генератора">
                    {trip.powerGeneratorRate} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.winchTime && (
                <GridItem>
                  <Field label="Время работы лебедки">
                    {trip.winchTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.winchRate && (
                <GridItem>
                  <Field label="Тариф для лебедки">{trip.winchRate} руб.</Field>
                </GridItem>
              )}
              {!!trip.trailerKilometrage && (
                <GridItem>
                  <Field label="Пробег с прицепом">
                    {trip.trailerKilometrage} км
                  </Field>
                </GridItem>
              )}
              {!!trip.trailerRate && (
                <GridItem>
                  <Field label="Тариф с прицепом">
                    {trip.trailerRate} руб.
                  </Field>
                </GridItem>
              )}
            </Grid>
          </Content>
          {/* Не показываем этот блок, пока ТС не уехало на заявку */}
          {trip.status !== tripStatusEnum.draft &&
            trip.status !== tripStatusEnum.created && (
              <>
                <Divider />
                <SectionTitle>Показания одометра</SectionTitle>
                <Content>
                  <Grid>
                    <GridItem>
                      <Field label="При выезде из гаража">
                        {trip.odometerAtStart}
                      </Field>
                    </GridItem>
                    {/* Показываем только после таксировки */}
                    {trip.status === tripStatusEnum.verified &&
                      parseFloat(trip.odometerAtEnd) > 0 && (
                        <GridItem>
                          <Field label="При заезде в гараж">
                            {trip.odometerAtEnd}
                          </Field>
                        </GridItem>
                      )}

                    {trip.monitoringNotation && (
                      <GridItem>
                        <Field label="Примечание">
                          {trip.monitoringNotation}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </Content>
              </>
            )}

          <Divider />
          <SectionTitle>Иное</SectionTitle>
          <Content>
            <Grid>
              <GridItem>
                <Field label="На сторону">
                  {[tripStatusEnum.draft, tripStatusEnum.created].includes(
                    trip.status
                  ) ? (
                    <YesNoSelect
                      value={trip.toOtherSide}
                      onChange={(value: boolean) => {
                        this.changeTripInfo('toOtherSide', value);
                      }}
                    />
                  ) : trip.toOtherSide ? (
                    'Да'
                  ) : (
                    'Нет'
                  )}
                </Field>
              </GridItem>
            </Grid>
          </Content>

          <Divider />
          <SectionTitle
            suffix={
              canEdit && (
                <Button type="primary" onClick={this.calculateRoute}>
                  Построить маршрут
                </Button>
              )
            }
          >
            Планируемый маршрут
          </SectionTitle>
          <Content>
            <Grid>
              <GridItem>
                <Field label="Суммарное время движения">
                  {calculateDateRangeString(
                    routeMinMaxDates.min,
                    routeMinMaxDates.max
                  )}
                </Field>
              </GridItem>
              <GridItem fullWidth>
                <Field>
                  {canEdit ? (
                    <WaypointsForm
                      disableDates={false}
                      waypoints={expectedWaypoints}
                      onChange={(waypoints: WayPoint[]) =>
                        this.changeTripWaypoints(waypoints)
                      }
                      appendRows={[
                        canEdit && expectedRouteGeometry && (
                          <TripMap
                            geoJSON={expectedRouteGeometry.geoJSON}
                            waypoints={trip.expectedRoute.waypoints}
                          />
                        ),
                      ]}
                    />
                  ) : (
                    <WaypointsViewer
                      waypoints={
                        trip.expectedRoute && trip.expectedRoute.waypoints
                      }
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem fullWidth>
                <Grid gutter="16px" cols={4} media={[{ size: 'lg', cols: 5 }]}>
                  <GridItem>
                    <Field label="Нулевой пробег от гаража до объекта, км">
                      {canEdit ? (
                        <InputNumber
                          name="distanceAtStart"
                          min={0}
                          decimalSeparator=","
                          step={0.01}
                          value={trip.distanceAtStart}
                          onChange={(value: number) =>
                            this.changeTripInfo('distanceAtStart', value)
                          }
                        />
                      ) : (
                        trip.distanceAtStart
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Предполагаемый пробег, км">
                      {canEdit ? (
                        <InputNumber
                          name="distance"
                          min={0}
                          decimalSeparator=","
                          step={0.01}
                          value={
                            trip &&
                            trip.expectedRoute &&
                            trip.expectedRoute.distance
                          }
                          onChange={(value: number) =>
                            this.changeExpectedRouteInfo('distance', value)
                          }
                        />
                      ) : (
                        trip.expectedRoute && trip.expectedRoute.distance
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Нулевой пробег от объекта до гаража, км">
                      {canEdit ? (
                        <InputNumber
                          name="distanceAtEnd"
                          min={0}
                          decimalSeparator=","
                          step={0.01}
                          value={trip.distanceAtEnd}
                          onChange={(value: number) =>
                            this.changeTripInfo('distanceAtEnd', value)
                          }
                        />
                      ) : (
                        trip.distanceAtEnd
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Расход топлива по нормативу, л">
                      {canEdit ? (
                        <InputNumber
                          name="expectedFuelConsumption"
                          min={0}
                          decimalSeparator=","
                          step={0.1}
                          value={trip.expectedFuelConsumption}
                          onChange={(value: number) =>
                            this.changeTripInfo(
                              'expectedFuelConsumption',
                              value
                            )
                          }
                        />
                      ) : (
                        trip.expectedFuelConsumption
                      )}
                    </Field>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Content>

          {touched &&
            [tripStatusEnum.created, tripStatusEnum.draft].includes(
              trip.status
            ) && (
              <Content>
                <Grid>
                  <GridItem fullWidth>
                    <Button.Group>
                      <Button type="primary" onClick={this.updateTrip}>
                        Применить изменения
                      </Button>
                      <Button onClick={this.discardChanges}>Отменить</Button>
                    </Button.Group>
                  </GridItem>
                </Grid>
              </Content>
            )}
          {parseInt(trip.actualRouteId, 10) > 0 && trip.actualRoute && (
            <>
              <Divider />
              <SectionTitle>Фактический маршрут</SectionTitle>
              <Content>
                <Grid>
                  <GridItem fullWidth>
                    <Field>
                      <WaypointsViewer waypoints={trip.actualRoute.waypoints} />
                    </Field>
                  </GridItem>
                  <GridItem fullWidth>
                    <Grid
                      gutter="16px"
                      cols={4}
                      media={[{ size: 'lg', cols: 5 }]}
                    >
                      {parseFloat(trip.distanceFuelConsumption) > 0 && (
                        <GridItem>
                          <Field label="Фактический расход топлива по пробегу, л">
                            {trip.distanceFuelConsumption.toFixed(2)}
                          </Field>
                        </GridItem>
                      )}
                      {parseFloat(trip.engineWorkFuelConsumption) > 0 && (
                        <GridItem>
                          <Field label="Фактический расход топлива по маш.часам, л">
                            {trip.engineWorkFuelConsumption.toFixed(2)}
                          </Field>
                        </GridItem>
                      )}

                      {parseFloat(trip.engineWorkFuelConsumption) > 0 &&
                        parseFloat(trip.distanceFuelConsumption) > 0 && (
                          <GridItem>
                            <Field label="Фактический расход топлива (общий), л">
                              {(
                                trip.engineWorkFuelConsumption +
                                trip.distanceFuelConsumption
                              ).toFixed(2)}
                            </Field>
                          </GridItem>
                        )}
                      {parseFloat(trip.fuelAtEnd) > 0 && (
                        <GridItem>
                          <Field label="Уровень топлива при заезде">
                            {trip.fuelAtEnd} л.
                          </Field>
                        </GridItem>
                      )}
                      <StyledGridItem>
                        <Field label="Выдано топлива">
                          {trip.issuedFuel || 0} л.
                        </Field>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>
                </Grid>
              </Content>
            </>
          )}
          {trip.tripRanges && trip.tripRanges.length > 0 && (
            <>
              <Divider />
              <SectionTitle>Топливные коэффициенты по отрезкам</SectionTitle>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Отрезок, км" />
                  </GridItem>
                  <GridItem>
                    <Field label="Коэффициент" />
                  </GridItem>
                  {trip.tripRanges.map((tripRange: TripRange) => (
                    <>
                      <GridItem key={tripRange.id}>
                        <Field label="">{tripRange.amount} км</Field>
                      </GridItem>
                      <GridItem>
                        <Field label="">{tripRange.sumFuelMultiplier}</Field>
                      </GridItem>
                    </>
                  ))}
                </Grid>
              </Content>
            </>
          )}
          {trip.odometerReaderEmployee && (
            <Content>
              <Grid>
                <GridItem>
                  <Field label="Работник проводивший таксировку">
                    {convertEmployeeToString(trip.odometerReaderEmployee)}
                  </Field>
                </GridItem>
              </Grid>
            </Content>
          )}
          {/* Показываем только, если прошла таксировка */}
          {trip.status === tripStatusEnum.verified && (
            <>
              {canShowEngineHours && (
                <>
                  <Divider />
                  <SectionTitle>Показания счетчика моточасов</SectionTitle>
                  <Content>
                    <Grid cols={4}>
                      <GridItem>
                        <Field label="При выезде, ч">
                          {trip.vehicleMachineHoursAtStart}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="При заезде, ч">
                          {trip.vehicleMachineHoursAtEnd}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Работа оборудования, ч">
                          {machineHoursAndFuel.machineHours}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Потрачено топливо, л">
                          {machineHoursAndFuel.fuelIssued}
                        </Field>
                      </GridItem>
                    </Grid>
                  </Content>
                </>
              )}
              <Divider />
              <SectionTitle>Навесное оборудование</SectionTitle>
              <Content>
                {trip.attachedEquipments.map(
                  ({ attachedEquipment, fuelAmountAtEnd, fuelIssued }) => {
                    return (
                      <Grid cols={5}>
                        <StyledGridItemTitle fullWidth>
                          {attachedEquipment?.name}
                          {attachedEquipment?.brandName
                            ? `(${attachedEquipment.brandName}}`
                            : ''}
                        </StyledGridItemTitle>
                        <GridItem>
                          <Field label="Тип бака">
                            {attachedEquipment?.fuelTankType &&
                              attachedEquipmentFuelTankType[
                                attachedEquipment.fuelTankType
                              ]}
                          </Field>
                        </GridItem>
                        {attachedEquipment?.fuelTankType ===
                          attachedEquipmentFuelTankTypeEnum.ownTank && (
                          <>
                            <GridItem>
                              <Field label="Тип топлива">
                                {attachedEquipment?.fuelType &&
                                  fuelTypes[attachedEquipment?.fuelType]}
                              </Field>
                            </GridItem>
                            <GridItem>
                              <Field label="Уровень топлива, при заезде">
                                {fuelAmountAtEnd}
                              </Field>
                            </GridItem>
                            <GridItem>
                              <Field label="Выдано топлива">{fuelIssued}</Field>
                            </GridItem>
                          </>
                        )}
                      </Grid>
                    );
                  }
                )}
              </Content>
            </>
          )}
          {(trip.startMedicCheckupDate ||
            trip.endMedicCheckupDate ||
            trip.medic) && (
            <>
              <Divider />
              <SectionTitle>Медосмотр</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <Field label="Дата и время предрейсового медосмотра">
                      {formatDateTimeToString(trip.startMedicCheckupDate)}
                    </Field>
                  </GridItem>
                  {trip.startMedic && (
                    <GridItem>
                      <Field label="ФИО медицинского работника предрейсового медосмотра">
                        {convertEmployeeToString(trip.startMedic)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.startMedicFullName && (
                    <GridItem>
                      <Field label="ФИО наемного медицинского работника предрейсового медосмотра">
                        {trip.startMedicFullName}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Дата и время послерейсового медосмотра">
                      {formatDateTimeToString(trip.endMedicCheckupDate)}
                    </Field>
                  </GridItem>
                  {trip.endMedic && (
                    <GridItem>
                      <Field label="ФИО медицинского работника послерейсового медосмотра">
                        {convertEmployeeToString(trip.endMedic)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.endMedicFullName && (
                    <GridItem>
                      <Field label="ФИО наемного медицинского работника послерейсового медосмотра">
                        {trip.endMedicFullName}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </>
          )}

          {(parseInt(trip.startMechanicId, 10) > 0 ||
            parseInt(trip.endMechanicId, 10) > 0) && (
            <>
              <SectionTitle>
                Контроль технического состояния машины
              </SectionTitle>
              <Content>
                <Grid>
                  {trip.startTechCheckupDate && (
                    <GridItem>
                      <Field label="Дата и время предрейсового технического осмотра">
                        {formatDateTimeToString(trip.startTechCheckupDate)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.startMechanic && (
                    <GridItem>
                      <Field label="ФИО контролера предрейсового технического осмотра">
                        {convertEmployeeToString(trip.startMechanic)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.endTechCheckupDate && (
                    <GridItem>
                      <Field label="Дата и время послерейсового технического осмотра">
                        {formatDateTimeToString(trip.endTechCheckupDate)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.endMechanic && (
                    <GridItem>
                      <Field label="ФИО контролера послерейсового технического осмотра">
                        {convertEmployeeToString(trip.endMechanic)}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </>
          )}

          {(trip.startGpmControllerCheckupDate ||
            trip.endGpmControllerCheckupDate) && (
            <>
              <Divider />
              <SectionTitle>Контроль ГПМ</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <Field label="Дата и время предрейсового осмотра">
                      {formatDateTimeToString(
                        trip.startGpmControllerCheckupDate
                      )}
                    </Field>
                  </GridItem>
                  {trip.startGpmController && (
                    <GridItem>
                      <Field label="ФИО контролера ГПМ предрейсового осмотра">
                        {convertEmployeeToString(trip.startGpmController)}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Дата и время послерейсового осмотра">
                      {formatDateTimeToString(trip.endGpmControllerCheckupDate)}
                    </Field>
                  </GridItem>
                  {trip.endGpmController && (
                    <GridItem>
                      <Field label="ФИО контролера ГПМ послерейсового осмотра">
                        {convertEmployeeToString(trip.endGpmController)}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </>
          )}
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    trip: selectTrip(state),
  }),
  {
    fetchTrip,
    cleanTrip,
    updateTrip,
    changeStatus,
  }
)(withUserAccess(TripCard));
