// @flow

import type { Dispatch } from 'redux';

import type { Employee } from './../lib/types';
import { jobTitleApi } from './../lib/api';
import type { Action, ListState, AppState } from './redux';
import { defaultPageSize } from './constants';
import type { FetchListParams } from './../lib/api';

export const FETCH_JOB_TITLES = 'vehicles/jobTitles/fetch';
export const SET_JOB_TITLES_FILTERS = 'vehicles/jobTitles/filters/set';
export const CLEAN_JOB_TITLES_FILTERS = 'vehicles/jobTitles/filters/clean';

export type JobTitlesFilter = {
  nodeId?: number
};

export type JobTitlesState = ListState<Employee> & {
  filters: JobTitlesFilter
};

const initialState: JobTitlesState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0,
  filters: {}
};

const reducer = (
  state: JobTitlesState = initialState,
  { type, payload }: Action
): JobTitlesState => {
  switch (type) {
    case FETCH_JOB_TITLES:
      return {
        ...state,
        ...payload
      };
    case SET_JOB_TITLES_FILTERS:
      return {
        ...state,
        filters: payload
      };
    case CLEAN_JOB_TITLES_FILTERS:
      return {
        ...state,
        filters: {}
      };
    default:
      return state;
  }
};

export const applyFilters = (filters: JobTitlesFilter) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_JOB_TITLES_FILTERS,
    payload: filters
  });
  dispatch(fetchJobTitles());
};

export const cleanFilters = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_JOB_TITLES_FILTERS
  });
  dispatch(fetchJobTitles());
};

export const fetchJobTitles: Function = (
  page: number = 1,
  params: FetchListParams<JobTitlesFilter> = {}
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const { filters, pageSize } = getState().jobTitles;
  const { data, totalCount } = await jobTitleApi.fetchJobTitles({
    ...filters,
    page,
    pageSize,
    ...params
  });
  dispatch({
    type: FETCH_JOB_TITLES,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export default reducer;
