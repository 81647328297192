// @flow

import React, { Component } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import _groupBy from 'lodash/groupBy';

import type {
  MaintenanceRegulation,
  MaintenanceWorkType,
  UserAccess
} from './../../lib/types';
import {
  maintenanceRegulationApi,
  maintenanceWorkTypeApi
} from '../../lib/api';
import { accessTypeEnum } from '../../lib/enum';
import { Section, SectionTitle } from './../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import TORegulationFormSpoiler from './components/TORegulationFormSpoiler';
import { withUserAccess } from '../withUserAccess';

const Content = styled.div`
  padding: 16px;
`;
const MaintenanceWorkTypeWrapper = styled.div`
  margin-bottom: 16px;
`;

type Props = {
  vehicleModelId: number,
  userAccess: UserAccess[]
};

type State = {
  regulations: MaintenanceRegulation[],
  groups: MaintenanceWorkType[],
  groupedRegulations: { [id: string]: MaintenanceRegulation[] }
};

class VehicleModelMaintenance extends Component<Props, State> {
  state: State = {
    regulations: [],
    groups: [],
    groupedRegulations: {}
  };

  async componentDidMount() {
    const { vehicleModelId } = this.props;
    try {
      const {
        data
      } = await maintenanceRegulationApi.fetchMaintenanceRegulation({
        vehicleModelId
      });
      this.setState({ regulations: data }, this.setWorkGroups);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  setWorkGroups = async () => {
    const { regulations } = this.state;
    const grouped = _groupBy(regulations, 'maintenanceWorkTypeId');
    const groups = await Promise.all(
      Object.keys(grouped).map(
        async id =>
          await maintenanceWorkTypeApi.getMaintenanceTypeWork(parseInt(id))
      )
    );
    this.setState({ groups, groupedRegulations: grouped });
  };

  handleSubmit = async (values: MaintenanceRegulation) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await maintenanceRegulationApi.updateMaintenanceRegulation(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  renderGroup = (id: string) => {
    const group = this.state.groups.find(v => v.id === parseInt(id));
    const regulations = this.state.groupedRegulations[id] || [];
    if (!group) return null;
    return (
      <>
        <SectionTitle>{group.name}</SectionTitle>
        {regulations.length > 0 && (
          <MaintenanceWorkTypeWrapper>
            {regulations.map(regulation => (
              <TORegulationFormSpoiler
                onSubmit={this.handleSubmit}
                readOnly={!this.canAdd()}
                vehicleModelRegulation={regulation}
              />
            ))}
          </MaintenanceWorkTypeWrapper>
        )}
      </>
    );
  };

  canAdd = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingUsersAndRoles
      ].includes(access)
    );

  render() {
    const { vehicleModelId } = this.props;
    const { groupedRegulations, groups } = this.state;
    const groupedIds = Object.keys(groupedRegulations);
    return (
      <Section>
        <Content>
          {this.canAdd() && (
            <Link to={`/vehicle-models/${vehicleModelId}/maintenance/new`}>
              <Button type="primary" data-cy="addModelRegulation">
                Добавить регламент ТО
              </Button>
            </Link>
          )}

          {groupedIds.length > 0 &&
            groups.length > 0 &&
            groupedIds.map(this.renderGroup)}
        </Content>
      </Section>
    );
  }
}

export default withUserAccess(VehicleModelMaintenance);
