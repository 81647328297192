// @flow

import React, { Component } from 'react';
import { type FormikProps } from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import type { MaintenanceOperation } from '../../lib/types';
import {
  addMaintenanceOperation,
  fetchMaintenanceOperation,
  updateMaintenanceOperation,
  clear
} from '../../ducks/maintenanceOperation';
import { Panel, Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import type { FormErrors } from '../../lib/types';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Form } from './../../components';
import { notificationLoading } from '../../components/Notifications';
import { InputNumber } from '../../components/inputs';

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  maintenanceOperation: MaintenanceOperation,
  updateMaintenanceOperation: Function,
  addMaintenanceOperation: Function,
  parentMaintenanceOperationGroupId: number,
  maintenanceOperationId: number,
  fetchMaintenanceOperation: Function,
  clear: Function
};

class MaintenanceOperationForm extends Component<Props> {
  async componentDidMount() {
    const { maintenanceOperationId } = this.props;
    await this.props.clear();
    try {
      if (maintenanceOperationId) {
        await this.props.fetchMaintenanceOperation(maintenanceOperationId);
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка загрузки вида работы',
        description: err.message
      });
      navigate('/admin/maintenance-operations/');
    }
  }

  render() {
    const {
      maintenanceOperation,
      parentMaintenanceOperationGroupId
    } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/maintenance-operation-groups">Виды работ</Crumb>
              {maintenanceOperation ? (
                <Crumb
                  to={`/admin/maintenance-operations/${maintenanceOperation.id}`}
                >
                  Вид работы "{maintenanceOperation.name}"
                </Crumb>
              ) : (
                <Crumb
                  to={`/admin/maintenance-operations/${parentMaintenanceOperationGroupId}/new`}
                >
                  Новый вид работы
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceOperation
              ? `Вид работы "${maintenanceOperation.name}"`
              : 'Новый вид работы'}
          </h1>
        </StyledPanel>
        <Form
          initialValues={maintenanceOperation}
          enableReinitialize
          validate={(values: MaintenanceOperation) => {
            let errors: FormErrors<MaintenanceOperation> = {};
            if (!values.name) {
              errors.name = 'Обязательное поле';
            }
            if (!values.hours) {
              errors.hours = 'Обязательное поле';
            }
            return errors;
          }}
          onSubmit={async values => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await this.props.updateMaintenanceOperation(values);
              } else {
                await this.props.addMaintenanceOperation(
                  values,
                  this.props.parentMaintenanceOperationGroupId
                );
              }
              notification.success({
                message: 'Успешно сохранено',
                description: 'Изменения успешно сохранены'
              });
              navigate('/admin/maintenance-operation-groups');
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
        >
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <Content>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Код работы"
                          hasFeedback
                          required
                          fast
                          name="code"
                        >
                          {({ value, name }) => (
                            <Input
                              name={name}
                              placeholder="Код работы"
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Наименование"
                          hasFeedback
                          required
                          fast
                          name="name"
                        >
                          {({ value, name }) => (
                            <Input
                              name={name}
                              placeholder="Наименование"
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Норма часы"
                          hasFeedback
                          required
                          fast
                          name="hours"
                        >
                          {({ value, name }) => (
                            <InputNumber
                              step={0.01}
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </Content>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Сохранить
                  </Button>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    maintenanceOperation: state.maintenanceOperation,
    maintenanceOperationId: parseInt(ownProps.maintenanceOperationId, 10)
  }),
  {
    updateMaintenanceOperation,
    addMaintenanceOperation,
    fetchMaintenanceOperation,
    clear
  }
)(MaintenanceOperationForm);
