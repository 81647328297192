// @flow
import { accessTypeEnum } from '../../../lib/enum';
import type { UserAccess } from '../../../lib/types';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingBudgetAnalysis
];

export const canAdd = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccessRight.includes(access));

export default {
  addAccessRight,
  canAdd
};
