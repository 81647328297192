// @flow
import React from 'react';
import moment from 'moment';
import notification from 'antd/lib/notification';

import type {
  ReportWeekendOrders,
  ReportWeekendOrdersParams,
} from '../../../lib/types/reportWeekendOrders';
import { reportWeekendOrdersApi } from '../../../lib/api';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { notificationLoading } from '../../../components/Notifications';

import Filter from './components/Filter';

const initialParams: ReportWeekendOrdersParams = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  params: ReportWeekendOrdersParams,
  data: ReportWeekendOrders[],
  loading: boolean,
  scrollY: number,
};

/**
 * Реестр путевых листов по автотранспортным услугам
 */
export class WeekendOrderList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    await this.fetchReport();
  }

  fetchReport = async (params: ReportWeekendOrdersParams = initialParams) => {
    try {
      this.setState({ loading: true });
      const data = await reportWeekendOrdersApi.fetchReportWeekendOrders(
        params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  applyParams = async () => await this.fetchReport(this.state.params);

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print',
      });
      await reportWeekendOrdersApi.printReportWeekendOrders(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: '№',
      dataIndex: 'orderId',
      width: 75,
    },
    {
      title: 'Сотрудник',
      dataIndex: 'employee',
      width: 200,
    },
    {
      title: 'ТС',
      dataIndex: 'vehicle',
      width: 150,
    },
    {
      title: 'Маршрут',
      dataIndex: 'route',
      width: 200,
    },
    {
      title: 'Период выделения',
      dataIndex: 'period',
      width: 150,
    },
    {
      title: 'Примечание',
      dataIndex: 'notation',
      width: 100,
    },
  ];

  render() {
    const { loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Реестр заявок на выходные дни</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={this.state.data}
            columns={this.columns}
            name="ReportsWeekendOrder"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 875,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default WeekendOrderList;
