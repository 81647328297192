// @flow
import Button from 'antd/lib/button';

import AntIcon from 'antd/lib/icon';
import notification from 'antd/lib/notification';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  BranchTabs,
  DistributionEconomyModal,
  DistributionEconomyWriteOffModal,
  InfoBranch
} from '.';
import Section, { SectionTitle } from '../../../../components/layout/Section';

import { notificationLoading } from '../../../../components/Notifications';
import { AntTable } from '../../../../components/ui';
import {
  branchBudgetSummaryApi,
  branchBudgetSummaryMonthApi,
  branchBudgetSummaryMonthRedistributionApi
} from '../../../../lib/api';
import {
  branchBudgetSummaryExpenseDirectionTypeEnum,
  entityStatusEnum
} from '../../../../lib/enum';

import {
  MONTH,
  toLocalStringRu,
  tooltipFullText
} from '../../../../lib/helpers';
import type {
  BranchBudgetSummary,
  BranchBudgetSummaryLineItem,
  UserAccess
} from '../../../../lib/types';
import { withUserAccess } from '../../../withUserAccess';
import { editDetailedBudgetAccessRight } from '../accessRight';

import { sumBudgetLineItemBudget } from '../lib';

const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;
const ChangeStatusButton = styled(Button)`
  margin-right: 16px;
`;
const StyledTable = styled(AntTable)``;

type Props = {
  id: number,
  orgUnitId: number,
  branchBudgetSummary: ?BranchBudgetSummary,
  setBranchBudgetSummary: Function,
  branchBudgetSummaryLineItem: BranchBudgetSummaryLineItem,
  expandedRows: string[],
  userAccess: UserAccess[]
};
export default withUserAccess((props: Props) => {
  const {
    id,
    orgUnitId,
    branchBudgetSummary,
    setBranchBudgetSummary,
    expandedRows
  } = props;
  const [localData, setData] = useState(branchBudgetSummary);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleWriteOffModal, setVisibleWriteOffModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedRowsLocal, setExpandedRows] = useState(expandedRows);
  const [
    branchBudgetSummaryLineItem,
    setBranchBudgetSummaryLineItem
  ] = useState({});
  const [monthIndex, setMonthIndex] = useState(null);
  const [monthToId, setMonthToId] = useState(null);

  const checkCell = (record: any, index: number) => {
    return (
      record.expenseDirectionId &&
      record.months[index].isOpenPeriod &&
      ((record.months[index].economyResidue > 0 &&
        localData?.distributionEconomyStatus !== entityStatusEnum.approved) ||
        (record.months[index].economyResidue !== record.months[index].economy &&
          record.months[index].writeOffSum === 0 &&
          record.months[index].economyResidue > 0))
    );
  };

  const checkCellWriteOff = (record: any, index: number) => {
    return (
      record.expenseDirectionId &&
      record.months[index].isOpenPeriod &&
      record.months[index].economyResidue < 0 &&
      localData?.distributionEconomyStatus !== entityStatusEnum.approved
    );
  };

  const COLUMNS = [
    {
      dataIndex: 'budgetLineItemName',
      title: 'Статья бюджетной формы',
      key: 'budgetLineItemName',
      width: 250,
      fixed: true,
      render: budgetLineItemName => (
        <WhiteSpace style={{ width: '95%' }}>
          {tooltipFullText(budgetLineItemName, 50)}
        </WhiteSpace>
      )
    },
    {
      dataIndex: 'expenseDirectionName',
      title: 'Направление расходов',
      key: 'expenseDirectionName',
      width: 225,
      fixed: true,
      render: expenseDirectionName => (
        <WhiteSpace>{tooltipFullText(expenseDirectionName, 50)}</WhiteSpace>
      )
    },
    {
      dataIndex: 'economy',
      title: <div>Доп. потребность</div>,
      key: 'itog',
      width: 170,
      fixed: true,
      render: economy => {
        return (
          <WhiteSpace>{toLocalStringRu(economy > 0 ? economy : 0)}</WhiteSpace>
        );
      }
    },
    {
      dataIndex: 'economyResidueFromClosedPeriod',
      fixed: true,
      title: (
        <div>
          Остаток экономии
          <br /> закрытого периода
        </div>
      ),
      key: 'economyResidueFromClosedPeriod',
      width: 170,
      render: (economy, record) => {
        return (
          <WhiteSpace>{toLocalStringRu(economy < 0 ? economy : 0)}</WhiteSpace>
        );
      }
    },
    {
      dataIndex: 'economyResidueFromOpenPeriod',
      fixed: true,
      title: (
        <div>
          Остаток экономии
          <br /> открытого периода
        </div>
      ),
      key: 'economyResidueFromOpenPeriod',
      width: 170,
      render: (economy, record) => {
        return (
          <WhiteSpace>{toLocalStringRu(economy < 0 ? economy : 0)}</WhiteSpace>
        );
      }
    },
    {
      dataIndex: 'fundsAllocation',
      title: <div>Распределено средств</div>,
      key: 'fundsAllocation',
      width: 170,
      fixed: true,
      render: (fundsAllocation, record) => {
        return toLocalStringRu(fundsAllocation);
      }
    },
    ...MONTH.map((month, index) => ({
      title: (
        <div>
          {month}
          <br />
          Экономия(-)/Потребность(+)
        </div>
      ),
      onCell: (record, rowIndex) => ({
        onClick: () => {
          if (canEdit()) {
            if (checkCell(record, index)) {
              setMonthIndex(index);
              setBranchBudgetSummaryLineItem(record);
              setMonthToId(record.months[index].id);
              setVisibleModal(true);
            }
            if (checkCellWriteOff(record, index)) {
              setMonthIndex(index);
              setBranchBudgetSummaryLineItem(record);
              setMonthToId(record.months[index].id);
              setVisibleWriteOffModal(true);
            }
          }
        }
      }),
      dataIndex: `months[${index}].economyResidue`,
      key: `months[${index}].economyResidue`,
      width: 200,
      render: (economyResidue, record) => ({
        props: {
          style: {
            background:
              checkCell(record, index) || checkCellWriteOff(record, index)
                ? '#e7f3ff'
                : undefined
          }
        },
        children: <WhiteSpace>{toLocalStringRu(economyResidue)}</WhiteSpace>
      })
    }))
  ];

  useEffect(() => {
    if (branchBudgetSummary) {
      const { budgetSummaryLineItems } = branchBudgetSummary;

      setData({
        ...branchBudgetSummary,
        budgetSummaryLineItems:
          sumBudgetLineItemBudget(budgetSummaryLineItems)?.map(
            budgetSummaryLineItem => ({
              ...budgetSummaryLineItem,
              // ищем элемент для подсветки
              type:
                budgetSummaryLineItem?.expenseDirections?.find(
                  expenseDirection =>
                    expenseDirection.type ===
                    branchBudgetSummaryExpenseDirectionTypeEnum.display
                ) !== undefined
                  ? branchBudgetSummaryExpenseDirectionTypeEnum.display
                  : null
            })
          ) ?? []
      });
    }
  }, [branchBudgetSummary]);

  const changeStatus = async () => {
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'change'
      });
      if (localData?.id) {
        await branchBudgetSummaryApi.changeStatus({
          id: localData.id,
          distributionEconomyStatus: entityStatusEnum.approved,
          status: entityStatusEnum.approvement
        });
        await setBranchBudgetSummary({
          ...localData,
          distributionEconomyStatus: entityStatusEnum.approved,
          status: entityStatusEnum.approvement
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('change');
    }
  };

  const getBranchBudgetSummaryLineItem = async () => {
    setLoading(true);
    try {
      if (branchBudgetSummary?.id) {
        const getBranchBudgetSummary = await branchBudgetSummaryApi.get(
          branchBudgetSummary.id
        );
        await setBranchBudgetSummary(getBranchBudgetSummary, expandedRowsLocal);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      setLoading(false);
    }
  };

  const saveResedue = async (data: any[], oldData: any[]) => {
    try {
      setVisibleModal(false);
      notificationLoading({
        message: 'Перераспределение бюджета...',
        key: 'save'
      });
      if (data.length) {
        await branchBudgetSummaryMonthRedistributionApi.addOrUpdate({
          monthToId,
          redistributions: [
            ...data.map(item => ({
              monthFromId: item.monthFromId,
              sum: item.sum
            })),
            ...oldData
          ]
        });
        await getBranchBudgetSummaryLineItem();
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('save');
    }
  };

  const saveWriteOff = async (data: { id: number, writeOff: number }) => {
    try {
      setVisibleWriteOffModal(false);
      notificationLoading({
        message: 'Перераспределение бюджета...',
        key: 'save'
      });
      await branchBudgetSummaryMonthApi.writeOff(data);
      await getBranchBudgetSummaryLineItem();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('save');
    }
  };

  const canEdit = () =>
    props.userAccess.some(access =>
      editDetailedBudgetAccessRight.includes(access)
    );

  return (
    <>
      <SectionTitle
        divider
        suffix={
          canEdit()
            ? localData?.distributionEconomyStatus !==
                entityStatusEnum.approved && (
                <>
                  <ChangeStatusButton type="primary" onClick={changeStatus}>
                    Сохранить
                  </ChangeStatusButton>
                </>
              )
            : null
        }
      >
        <BranchTabs
          id={id}
          orgUnitId={orgUnitId}
          calculationEconomyStatus={localData?.calculationEconomyStatus}
          distributionEconomyStatus={localData?.distributionEconomyStatus}
          proposalEconomyStatus={localData?.proposalStatus}
        />
      </SectionTitle>
      <InfoBranch branchBudgetSummary={localData} />
      <Section>
        <StyledTable
          loading={loading}
          columns={COLUMNS}
          data={localData?.budgetSummaryLineItems ?? []}
          childrenColumnName="expenseDirections"
          expandedRowKeys={expandedRowsLocal}
          onExpandedRowsChange={rows => setExpandedRows(rows)}
          expandRowByClick
          expandIconAsCell={false}
          expandIconColumnIndex={1}
          rowClassName={record =>
            record.type === branchBudgetSummaryExpenseDirectionTypeEnum.display
              ? 'ant-table-row-color-light-red'
              : record.rowId < 0
              ? 'ant-table-row-color-light-grey'
              : null
          }
          expandIcon={row =>
            !!row.record.expenseDirections && (
              <AntIcon
                type={row.expanded ? 'up' : 'down'}
                style={{
                  fontSize: '12px',
                  float: 'right'
                }}
              />
            )
          }
          bordered
          rowKey="id"
          style={{
            overflow: 'auto'
          }}
          scroll={{
            y: '500px',
            x: '2000'
          }}
          pagination={false}
        />
      </Section>
      <DistributionEconomyWriteOffModal
        visible={visibleWriteOffModal}
        branchBudgetSummaryLineItem={branchBudgetSummaryLineItem}
        onCancel={() => setVisibleWriteOffModal(false)}
        monthIndex={monthIndex}
        monthToId={monthToId}
        branchBudgetSummaryId={branchBudgetSummary?.id}
        onSave={saveWriteOff}
      />
      <DistributionEconomyModal
        visible={visibleModal}
        branchBudgetSummaryLineItem={branchBudgetSummaryLineItem}
        onCancel={() => setVisibleModal(false)}
        monthIndex={monthIndex}
        readOnly={
          localData?.distributionEconomyStatus === entityStatusEnum.approved
        }
        monthToId={monthToId}
        branchBudgetSummaryId={branchBudgetSummary?.id}
        onSave={saveResedue}
      />
    </>
  );
});
