// @flow

import React from 'react';

import { compareVehiclePlanMaintenanceType } from '../../../lib/helpers';
import type { VehicleModel } from './../../../lib/types';
import {
  vehicleTypes,
  driverQualifications,
  drivingLicenseCategories,
  fuelTypes,
  chassisConfigurations,
  engineTypes
} from './../../../lib/enum';

import { Card } from '../../../components';
import { Section, SectionTitle } from './../../../components/layout';
import { GridItem } from './../../../components/layout/Grid';
import {
  canShowOsagoNeeded,
  canShowAxlesNumber,
  canShowGrossWeight,
  canShowChassisConfiguration,
  canShowAxleLoad,
  canShowCarrying,
  canShowEngineData,
  canShowPassengerSeatsNumber,
  canShowEcologicClass,
  canShowVehicleCategory,
  canShowGpmData,
  canShowDrillingData,
  canShowFuelData,
  canShowSecondaryFuel
} from './../lib';
import { canShowEngineWorkHoursData } from './../../Vehicles/lib';
import {
  driverQualificationEnum,
  selfVehiclePlanVehicleTypeEnum
} from './../../../lib/enum';
import { Content, StyledGrid } from './InnerForm.elements';

const { Field } = Card;

type FormProps = {
  vehicleModel: ?VehicleModel
};

const InnerCard = ({ vehicleModel }: FormProps) => {
  return (
    <Section>
      <Content>
        <SectionTitle>Основное</SectionTitle>
        <StyledGrid gutter="16px">
          <GridItem>
            <Field label="Наименование модели">
              {vehicleModel?.name ?? '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Марка">{vehicleModel?.brandName ?? '-'}</Field>
          </GridItem>
          <GridItem>
            <Field label="Марка">
              {vehicleModel?.type ? vehicleTypes[vehicleModel.type] : '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Необходимая квалификация водителя">
              {vehicleModel?.driverQualification
                ? driverQualifications[vehicleModel.driverQualification]
                : '-'}
            </Field>
          </GridItem>
          {[
            driverQualificationEnum.driverLicense,
            driverQualificationEnum.tractorMachinistLicense
          ].includes(vehicleModel?.driverQualification) && (
            <GridItem>
              <Field label="Категория прав для ТС">
                {vehicleModel?.rightsCategory
                  ? vehicleModel.rightsCategory
                      .map(category => drivingLicenseCategories[category])
                      .join(', ')
                  : '-'}
              </Field>
            </GridItem>
          )}
        </StyledGrid>
        {canShowFuelData(vehicleModel?.type) && (
          <>
            <SectionTitle>Основное топливо</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <Field label="Вид топлива">
                  {vehicleModel?.primaryFuelType
                    ? fuelTypes[vehicleModel.primaryFuelType]
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Нормативный расход, л/100 км">
                  {vehicleModel?.primaryFuelConsumption ?? '-'}
                </Field>
              </GridItem>
              {canShowEngineWorkHoursData(vehicleModel?.type) && (
                <GridItem>
                  <Field label="Нормативный расход л/маш.час работы оборудования">
                    {vehicleModel?.primaryEquipmentFuelConsumption ?? '-'}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Объем топливного бака, л">
                  {vehicleModel?.primaryTankVolume ?? '-'}
                </Field>
              </GridItem>
            </StyledGrid>
            {canShowSecondaryFuel(vehicleModel?.type) && (
              <>
                <SectionTitle>Дополнительное топливо</SectionTitle>
                <StyledGrid gutter="16px">
                  <GridItem>
                    <Field label="Вид топлива">
                      {vehicleModel?.secondaryFuelType
                        ? fuelTypes[vehicleModel.secondaryFuelType]
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Нормативный расход, л/100 км">
                      {vehicleModel?.secondaryFuelConsumption ?? '-'}
                    </Field>
                  </GridItem>
                  {canShowEngineWorkHoursData(vehicleModel?.type) && (
                    <GridItem>
                      <Field label="Нормативный расход л/маш.час работы оборудования">
                        {vehicleModel?.secondaryEquipmentFuelConsumption ?? '-'}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Объем топливного бака, л">
                      {vehicleModel?.secondaryTankVolume ?? '-'}
                    </Field>
                  </GridItem>
                </StyledGrid>
              </>
            )}
          </>
        )}
        <SectionTitle>Шины</SectionTitle>
        <StyledGrid gutter="16px">
          <GridItem>
            <Field label="Количество автошин, шт">
              {vehicleModel?.wheelCount ?? '-'}
            </Field>
          </GridItem>
        </StyledGrid>
        <SectionTitle>Первичные данные</SectionTitle>
        <StyledGrid gutter="16px">
          {canShowChassisConfiguration(vehicleModel?.type) && (
            <GridItem>
              <Field label="Колесная формула">
                {vehicleModel?.chassisConfiguration
                  ? chassisConfigurations[vehicleModel?.chassisConfiguration]
                  : '-'}
              </Field>
            </GridItem>
          )}
          <GridItem>
            <Field label="Масса ТС без нагрузки, т">
              {vehicleModel?.unladenMass ?? '-'}
            </Field>
          </GridItem>
          {canShowGrossWeight(vehicleModel?.type) && (
            <GridItem>
              <Field label="Полная масса ТС, т">
                {vehicleModel?.grossWeight ?? '-'}
              </Field>
            </GridItem>
          )}
          {canShowAxlesNumber(vehicleModel?.type) && (
            <GridItem>
              <Field label="Количество осей, шт">
                {vehicleModel?.axlesNumber ?? '-'}
              </Field>
            </GridItem>
          )}
          {canShowAxleLoad(vehicleModel?.type) && (
            <GridItem>
              <Field label="Нагрузка на ось (перед/задн.), т">
                {vehicleModel?.axleLoad ?? '-'}
              </Field>
            </GridItem>
          )}
          {canShowCarrying(vehicleModel?.type) && (
            <GridItem>
              <Field label="Грузоподъемность, т">
                {vehicleModel?.carrying ?? '-'}
              </Field>
            </GridItem>
          )}
          <GridItem>
            <Field label="Габариты ТС, м">
              {vehicleModel?.dimensions ?? '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Разрешенная максимальная масса по ПТС, кг">
              {vehicleModel?.maximumAuthorizedMass ?? '-'}
            </Field>
          </GridItem>
          {canShowEngineData(vehicleModel?.type) && (
            <>
              <GridItem>
                <Field label="Тип двигателя">
                  {vehicleModel?.engineType
                    ? engineTypes[vehicleModel?.engineType]
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Рабочий объем двигателя, куб. см">
                  {vehicleModel?.engineCapacity ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Мощность двигателя, л.с.">
                  {vehicleModel?.enginePower ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Максимальная скорость (км/ч)">
                  {vehicleModel?.maxSpeed ?? '-'}
                </Field>
              </GridItem>
            </>
          )}
          {canShowPassengerSeatsNumber(vehicleModel?.type) && (
            <GridItem>
              <Field label="Кол-во пассажирский мест, шт">
                {vehicleModel?.passengerSeatsNumber ?? '-'}
              </Field>
            </GridItem>
          )}
          {canShowEcologicClass(vehicleModel?.type) && (
            <GridItem>
              <Field label="Экологический класс ТС">
                {vehicleModel?.ecologicClass ?? '-'}
              </Field>
            </GridItem>
          )}
          {canShowVehicleCategory(vehicleModel?.type) && (
            <GridItem>
              <Field label="Категория ТС">
                {vehicleModel?.category
                  ? vehicleModel?.category.toUpperCase()
                  : '-'}
              </Field>
            </GridItem>
          )}
        </StyledGrid>
        {canShowOsagoNeeded(vehicleModel?.type) && (
          <>
            <SectionTitle>Признаки ТС</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <Field label="Подлежит ОСАГО">
                  {vehicleModel?.category ? 'да' : 'нет'}
                </Field>
              </GridItem>
            </StyledGrid>
          </>
        )}
        {canShowGpmData(vehicleModel?.type) && (
          <>
            <SectionTitle>ГПМ</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <Field label="Грузоподъемность ГПМ">
                  {vehicleModel?.gpmCarrying ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Высота подъема">
                  {vehicleModel?.liftHeight ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Вылет стрелы">
                  {vehicleModel?.gpmHandlingRadius ?? '-'}
                </Field>
              </GridItem>
            </StyledGrid>
          </>
        )}
        {canShowDrillingData(vehicleModel?.type) && (
          <>
            <SectionTitle>Бурильная установка</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <Field label="Глубина бурения (до мм)">
                  {vehicleModel?.drillingDeep ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Диаметр бурения (до мм)">
                  {vehicleModel?.drillingDiameter ?? '-'}
                </Field>
              </GridItem>
            </StyledGrid>
          </>
        )}
        <SectionTitle>Тариф</SectionTitle>
        <StyledGrid gutter="16px">
          <GridItem>
            <Field label="Постоянная часть тарифа, руб.">
              {vehicleModel?.fixedRatePart ?? '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Постоянная часть тарифа (в пути), руб.">
              {vehicleModel?.fixedRateInTransit ?? '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Переменная часть тарифа, руб.">
              {vehicleModel?.fixedRateInDowntime ?? '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Стоимость прогрева, руб.">
              {vehicleModel?.warmingUpCost ?? '-'}
            </Field>
          </GridItem>
        </StyledGrid>
        {canShowFuelData(vehicleModel?.type) &&
          canShowSecondaryFuel(vehicleModel?.type) && (
            <>
              <SectionTitle>Тарифы для доп. топлива</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <Field label="Постоянная часть тарифа,, руб.">
                    {vehicleModel?.secondaryFixedRatePart ?? '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Постоянная часть тарифа (в пути), руб.">
                    {vehicleModel?.secondaryFixedRateInTransit ?? '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Переменная часть тарифа, руб.">
                    {vehicleModel?.secondaryFixedRateInDowntime ?? '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Стоимость прогрева, руб.">
                    {vehicleModel?.secondaryWarmingUpCost ?? '-'}
                  </Field>
                </GridItem>
              </StyledGrid>
            </>
          )}
        <SectionTitle>Техобслуживание</SectionTitle>
        <StyledGrid gutter="16px">
          <GridItem>
            <Field label="Трудоёмкость одного ТО (чел/час)">
              {vehicleModel?.workHours ?? '-'}
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Средние затраты на материалы ТО, руб">
              {vehicleModel?.materialsCost ?? '-'}
            </Field>
          </GridItem>
          {!!vehicleModel?.type &&
            compareVehiclePlanMaintenanceType(
              vehicleModel.type,
              selfVehiclePlanVehicleTypeEnum.kilometrage
            ) && (
              <GridItem>
                <Field label="Интервал проведения технического обслуживания (км)">
                  {vehicleModel?.kilometrageInterval ?? '-'}
                </Field>
              </GridItem>
            )}
          {!!vehicleModel?.type &&
            compareVehiclePlanMaintenanceType(
              vehicleModel.type,
              selfVehiclePlanVehicleTypeEnum.kilometrage
            ) && (
              <GridItem>
                <Field label="Интервал проведения технического обслуживания (моточас)">
                  {vehicleModel?.engineWorkInterval ?? '-'}
                </Field>
              </GridItem>
            )}
        </StyledGrid>
      </Content>
    </Section>
  );
};

export default InnerCard;
