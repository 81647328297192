// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Driver } from './../../lib/types';
import { driverApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import type { DriverFilterParams } from './../../containers/Driver/Filter';
import { convertEmployeeToString } from '../../lib/helpers';

export type DriverSelectProps = SelectProps & {
  filter?: DriverFilterParams,
  driverWithUsers?: boolean
};

export default (selectProps: DriverSelectProps) => (
  <AutocompleteSelect
    fetch={
      selectProps.driverWithUsers
        ? driverApi.driversWithUsers
        : driverApi.fetchDrivers
    }
    fetchSingle={driverApi.fetchDriver}
    notFoundText="Водители не найдены"
    placeholder="Выберите водителя"
    renderOption={(driver: Driver, Option) => (
      <Option key={driver.id} value={driver.id} driver={driver}>
        {convertEmployeeToString(driver.employee)}
      </Option>
    )}
    {...selectProps}
  />
);
