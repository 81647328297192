import {
    CompareExpenseDirectionCard,
    CompareExpenseDirectionForm,
    CompareExpenseDirectionList,
} from '../../containers/CompareExpenseDirection';
import { accessTypeEnum } from '../../lib/enum';

// сопоставление направления расходов
export default [
    {
        component: CompareExpenseDirectionList,
        path: '/admin/compare-expense-direction',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingCompareExpenseDirections,
            accessTypeEnum.handlingCompareExpenseDirections,
        ],
    },
    {
        component: CompareExpenseDirectionForm,
        path: '/admin/compare-expense-direction/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingCompareExpenseDirections,
        ],
    },
    {
        component: CompareExpenseDirectionForm,
        path: '/admin/compare-expense-direction/:compareExpenseDirectionId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingCompareExpenseDirections,
        ],
    },
    {
        component: CompareExpenseDirectionCard,
        path: '/admin/compare-expense-direction/:compareExpenseDirectionId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingCompareExpenseDirections,
        ],
    },
];