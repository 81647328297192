import { type ComponentType } from 'react';

type TabItemProps = {
  /**
   * Подпись вкладки
   */
  label: string,
  /**
   * Содержимое ярлыка
   */
  badge?: any,
  /**
   * Ссылка
   *
   * Удобно использовать в связке с withRouter (см. свойство у Tabs)
   *
   * Если он указан, то используется в качестве tabKey
   */
  url?: string,
  activeUrl?: string[],
  tabKey?: string,
  children?: ?Node,
  onClick?: (tabKey: string) => void
};

/**
 * Компонент обертка с информацией о вкладе и ее содержимым
 * Необходим для извлечения информации из props
 * Возвращает просто свое содержимое
 */
export const TabItem: ComponentType<TabItemProps> = ({
  children
}: TabItemProps) => (children != null ? children : null);
