import { flatMenu } from '../components/layout/Sidebar';

export const getBreadCrumbsByUrl = (url: string) => {
  let path = url;
  let res = [];
  while (path !== undefined) {
    res.unshift(flatMenu?.[path]);
    path = flatMenu?.[path]?.parent;
  }
  return res;
};
export const getBreadCrumbsByUrlForEdit = (url: string, id: number) => {
  return [
    ...getBreadCrumbsByUrl(url),
    ...(id
      ? [{ to: `${url}${id}`, name: id.toString() }, { name: 'Редактирование' }]
      : [{ name: 'Новая запись' }])
  ];
};
export const getBreadCrumbsByUrlForCard = (url: string, id?: number) => {
  const crumb = Number.isInteger(id)
    ? { to: `${url}${id}`, name: id.toString() }
    : {};
  return [...getBreadCrumbsByUrl(url), crumb];
};
