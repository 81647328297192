import {
    BudgetAnalysisCard,
    BudgetAnalysisForm,
    BudgetAnalysisInfo,
    BudgetAnalysisList,
} from '../../containers/budget/budgetAnalysis';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BudgetAnalysisList,
        path: '/budget/budget-analysis',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BudgetAnalysisInfo,
        path: '/budget/budget-analysis/:budgetAnalysisId/:branchBudgetSummaryExpenseDirectionId/:month',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BudgetAnalysisForm,
        path: '/budget/budget-analysis/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BudgetAnalysisCard,
        path: '/budget/budget-analysis/:budgetAnalysisId',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];