
import {
    CurrentMaintenanceCostCard,
    CurrentMaintenanceCostForm,
    CurrentMaintenanceCostList,
} from '../../containers/CurrentMaintenanceCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: CurrentMaintenanceCostList,
        path: '/admin/current-maintenance-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: CurrentMaintenanceCostCard,
        path: '/admin/current-maintenance-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: CurrentMaintenanceCostForm,
        path: '/admin/current-maintenance-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: CurrentMaintenanceCostForm,
        path: '/admin/current-maintenance-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];