// @flow
import type { Dispatch } from 'redux';
import type { Action } from './redux';
import type { MonitoringVehicle } from '../lib/types/monitoringVehicle';

export const SET_MONITORING_VEHICLES = 'vehicles/monitoring/set';

export type MonitoringState = {
  monitoringVehicles: MonitoringVehicle[]
};

const initialState = {
  monitoringVehicles: []
};

const reducer = (
  state: MonitoringState = initialState,
  { type, payload }: Action
): MonitoringState => {
  switch (type) {
    case SET_MONITORING_VEHICLES:
      return {
        ...state,
        monitoringVehicles: payload
      };
    default:
      return state;
  }
};

export const fetchMonitoringVehicles = () => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_MONITORING_VEHICLES,
    payload: []
  });
};

export const cleanMonitoringVehicles = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MONITORING_VEHICLES,
    payload: []
  });
};

export default reducer;
