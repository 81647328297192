import { ContractorsForm, ContractorsList } from '../../containers/Contractors';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: ContractorsList,
        path: '/admin/contractors',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingContractors,
            accessTypeEnum.handlingContractors,
        ],
    },
    {
        component: ContractorsForm,
        path: '/admin/contractors/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingContractors,
        ],
    },
    {
        component: ContractorsForm,
        path: '/admin/contractors/:contractorId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingContractors,
            accessTypeEnum.handlingContractors,
        ],
    },
];