import styled, { css } from 'styled-components';

export const Wrapper = styled.header`
  min-height: 50px;
  padding: 16px;
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: space-between;
`;
const HeaderSide = css`
  display: inline-flex;
  align-items: center;
`;
export const Left = styled.div`
  ${HeaderSide};
  transition: padding-left 0.3s;
`;

export const Right = styled.div`
  ${HeaderSide};
`;
