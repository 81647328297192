import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { VehicleWashingTypeToGroupsMap } from '../types/washingPriceList';

export const create = async (
  washingPrice: VehicleWashingTypeToGroupsMap
): Promise<void> => {
  const added = await fetchRequest.post(
    '/vehicleWashingTypeToGroupsMap',
    washingPrice
  );
  if (added) return added;
  throw new Error('Не удалось создать прейскурант');
};

export const fetchList = async (
  params: FetchListParams<> = initialFetchListParams
): Promise<ListResponse<VehicleWashingTypeToGroupsMap>> => {
  const data = await fetchRequest.get('/vehicleWashingTypeToGroupsMap', {
    ...initialFetchListParams,
    ...params
  });
  if (data) return data;
  throw new Error('Не удалось получить список прейскуранта');
};

export const update = async (
  washingPrice: VehicleWashingTypeToGroupsMap
): Promise<VehicleWashingTypeToGroupsMap> => {
  const updated = await fetchRequest.put(
    '/vehicleWashingTypeToGroupsMap',
    washingPrice
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить прейскурант');
};

export const fetchOne = async (
  id: number
): Promise<ListResponse<VehicleWashingTypeToGroupsMap>> => {
  let washingPrice = await fetchRequest.get(
    `/vehicleWashingTypeToGroupsMap/${id}`
  );
  if (washingPrice) return washingPrice;
  throw new Error('Не удалось загрузить прейскурант');
};

export const deleteMap = async (
  id: number
): Promise<VehicleWashingTypeToGroupsMap> => {
  const deleted = await fetchRequest.delete(
    `/vehicleWashingTypeToGroupsMap/${id}`
  );
  if (deleted) return deleted;
  throw new Error('Не удалось удалить прейскурант');
};

export default {
  fetchList,
  fetchOne,
  deleteMap,
  create,
  update
};
