// @flow

import React, { Component } from 'react';
import styled from 'styled-components';

import { Section, SectionTitle } from '../../../components/layout';
import OperationLimitGroup from './OperationLimitGroup';
import type { UserAccess } from '../../../lib/types';

const SectionContent = styled.div`
  padding: 16px;
`;

type Props = {
  employeeBranchOrgUnitId: number,
  userAccess: UserAccess[],
  orgUnitId: number
};

export default class TimeLimits extends Component<Props> {
  render() {
    const { orgUnitId } = this.props;

    return (
      <>
        <Section>
          <SectionTitle divider>Должностные лица</SectionTitle>
          <SectionContent>
            <OperationLimitGroup orgUnitId={orgUnitId} type="employee" />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>ОВБ</SectionTitle>
          <SectionContent>
            <OperationLimitGroup orgUnitId={orgUnitId} type="ovb" />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Службы</SectionTitle>
          <SectionContent>
            <OperationLimitGroup orgUnitId={orgUnitId} type="orgUnit" />
          </SectionContent>
        </Section>
      </>
    );
  }
}
