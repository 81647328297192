// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component, Fragment } from 'react';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';

import { tireApi, type FetchListParams } from './../../../lib/api';
import { accessTypeEnum, seasonTypeTire } from '../../../lib/enum';
import type { Tire, UserAccess, ListState, Vehicle } from '../../../lib/types';
import {
  applyMaskToValue,
  getListInitialState,
  setQueryParams
} from '../../../lib/helpers';

import type { AppState } from '../../../ducks/redux';
import { Table, Dropdown } from './../../../components/ui';
import { Panel, Section } from './../../../components/layout';
import Filter, { type TireFilterParams } from './Filter';
import Header from '../../../components/layout/Header';
import { Crumb } from '../../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../../components/layout/Breadcrumbs';
import NavigationTabs from '../components/NavigationTabs';
import { withUserAccess } from './../../withUserAccess';
import CopyTireButton from './components/CopyTireButton';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const StyledPanel = styled(Panel)`
  padding-bottom: 0;
  padding-top: 0;
`;
const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const canAddTireAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment
];

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

type State = ListState<Tire> & {
  filter: TireFilterParams
};

/** Вывод списка шин */
class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  getDefaultFilter = () => {
    const canViewAll = this.props.userAccess.some(access =>
      [accessTypeEnum.viewingAllEquipments].includes(access)
    );
    const defaultFilter:TireFilterParams = canViewAll
      ? {...null}
      : ({
          orgUnitId: this.props.employeeBranchOrgUnitId,
          nodeFilterType: 'branchAndChildren'
        });
    return defaultFilter;
  };

  state = {
    ...getListInitialState(),
    filter: this.getDefaultFilter()
  };

  dropdown = {};

  fetchTires = async (
    page: number = 1,
    params: FetchListParams<TireFilterParams> = {}
  ) => {
    const { filter } = this.state;
    const { isArchive, ...otherFilters } = filter;
    this.setState({ loading: true });
    const { data, totalCount } = await tireApi.fetchTires({
      ...otherFilters,
      isArchive: isArchive === 'all' ? null : isArchive,
      page,
      ...params,
      ...this.getDefaultFilter() // фильтр по оргюнитам в зависимости от клэймов
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  renderOverlay = (tire: Tire) => (
    <Menu>
      <Menu.Item>
        <CopyTireButton
          tire={tire}
          afterSubmit={() => {
            this.fetchTires(this.state.page);
            // $FlowFixMe тут id будет всегда
            if (this.dropdown[tire.id]) {
              this.dropdown[tire.id].onVisibleChange(false);
            }
          }}
        />
      </Menu.Item>
    </Menu>
  );

  getColumns = () => [
    { title: '№', dataIndex: 'id', sorter: true, width: 25 },
    {
      title: 'Марка',
      dataIndex: 'brand.name',
      sorter: true,
      sorterKey: 'brand.name'
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true,
      render: (name, record: Tire) => {
        return `${name} ${record.width}/${record.profile} ${record.diameter}`;
      }
    },
    { title: 'Заводской номер', dataIndex: 'factoryNumber', sorter: true },
    {
      title: 'Тип шины',
      dataIndex: 'seasonType',
      sorter: true,
      render: (type: string) => seasonTypeTire[type] || '-'
    },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle',
      render: (vehicle: ?Vehicle) =>
        vehicle?.licensePlate ? (
          <Link to={`/vehicles/${vehicle.id}`}>
            {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          '-'
        )
    },
    {
      title: 'Списано',
      dataIndex: 'isArchive',
      sorter: true,
      render: (isArchive: boolean) => (isArchive ? 'Да' : 'Нет')
    },
    {
      title: '',
      width: '30px',
      // Нужно для того, чтобы не отрабатывал onRow
      onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
      render: (record: Tire) => (
        <Dropdown
          // $FlowFixMe тут id будет всегда
          ref={dropdown => (this.dropdown[record.id] = dropdown)}
          overlay={this.renderOverlay(record)}
        >
          <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
        </Dropdown>
      )
    }
  ];

  handleRowClick = (id: number) => navigate(`/equipment/tires/${id}`);

  cleanFilter = () => this.setState({ filter: {...null} }, this.fetchTires);

  applyFilter = (filter: TireFilterParams) =>
    this.setState({ filter }, this.fetchTires);

  render() {
    const { location, userAccess } = this.props;

    const { data, totalCount, page, pageSize, loading, filter } = this.state;

    const canAdd = userAccess.some(access => canAddTireAccess.includes(access));

    return (
      <Fragment>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb>Шины</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <PanelTitle>
            <h1>Шины</h1>
            {canAdd && (
              <Link to="/equipment/tires/new">
                <Button type="primary" data-cy="addTire">
                  Создать
                </Button>
              </Link>
            )}
          </PanelTitle>
          <NavigationTabs defaultTab="tires" />
        </StyledPanel>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            data={data.map(tire => ({
              ...tire,
              key: tire.id
            }))}
            loading={loading}
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchTires}
            fetchOnMount
            columns={this.getColumns()}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </Fragment>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(withUserAccess(List));
