// @flow
import Button from 'antd/lib/button';

import AntIcon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BranchForm, BranchTabs, InfoBranch } from '.';
import Section, { SectionTitle } from '../../../../components/layout/Section';

import { notificationLoading } from '../../../../components/Notifications';
import { AntTable } from '../../../../components/ui';
import { TableHeader } from '../../../../components/ui/Table';
import {
  branchBudgetSummaryApi,
  branchBudgetSummaryMonthApi,
} from '../../../../lib/api';
import { entityStatusEnum } from '../../../../lib/enum';

import {
  MONTH,
  toLocalStringRu,
  tooltipFullText,
} from '../../../../lib/helpers';
import type {
  BranchBudgetSummary,
  BudgetSummary,
  UserAccess,
} from '../../../../lib/types';
import { withUserAccess } from '../../../withUserAccess';
import { editDetailedBudgetAccessRight } from '../accessRight';

import { sumBudgetLineItemBudget } from '../lib';

const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;
const ChangeStatusButton = styled(Button)`
  margin-right: 16px;
`;
const StyledInput = styled(Input)`
  width: 100%;
`;
const StyledTable = styled(AntTable)``;

type Props = {
  id: number,
  orgUnitId: number,
  budgetSummary?: BudgetSummary,
  branchBudgetSummary: ?BranchBudgetSummary,
  setBranchBudgetSummary: Function,
  onSubmit: Function,
  userAccess: UserAccess[],
};
export default withUserAccess((props: Props) => {
  const { id, orgUnitId, branchBudgetSummary, setBranchBudgetSummary } = props;
  const [localData, setData] = useState(branchBudgetSummary);
  const [months, setMonths] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [editMonth, setEditMonth] = useState({});
  const [editBudget, setEditBudget] = useState(false);

  const COLUMNS = [
    {
      dataIndex: 'budgetLineItemName',
      title: 'Статья бюджетной формы',
      key: 'budgetLineItemName',
      width: 250,
      fixed: true,
      render: (budgetLineItemName) => (
        <WhiteSpace style={{ width: '95%' }}>
          {tooltipFullText(budgetLineItemName, 35)}
        </WhiteSpace>
      ),
    },
    {
      dataIndex: 'expenseDirectionName',
      title: 'Направление расходов',
      key: 'expenseDirectionName',
      width: 250,
      fixed: true,
      render: (expenseDirectionName) => (
        <WhiteSpace>{tooltipFullText(expenseDirectionName, 35)}</WhiteSpace>
      ),
    },
    {
      dataIndex: 'newCalculation',
      title: 'Предложение на БК',
      key: 'newCalculation',
      width: 150,
      fixed: true,
      render: (newCalculation) => {
        return <WhiteSpace>{toLocalStringRu(newCalculation)}</WhiteSpace>;
      },
    },
    {
      dataIndex: 'economy',
      title: 'Доп. потребность',
      key: 'economy',
      width: 150,
      fixed: true,
      render: (economy) => {
        return economy > 0 ? (
          <WhiteSpace>{toLocalStringRu(economy)}</WhiteSpace>
        ) : (
          0
        );
      },
    },
    ...MONTH.map((month, index) => ({
      title: month,
      children: [
        {
          title: 'План',
          dataIndex: `months[${index}].plan`,
          key: `months[${index}].plan`,
          width: 150,
          render: (plan) => <WhiteSpace>{toLocalStringRu(plan)}</WhiteSpace>,
        },
        {
          title: 'Факт',
          dataIndex: `months[${index}].fact`,
          width: 120,
          render: (fact) => <WhiteSpace>{toLocalStringRu(fact)}</WhiteSpace>,
        },
        {
          title: 'Потребность',
          dataIndex: `months[${index}].need`,
          width: 120,
          render: (need) => <WhiteSpace>{toLocalStringRu(need)}</WhiteSpace>,
        },
        {
          title: (
            <TableHeader width="120px">Экономия(-)/ перерасход(+)</TableHeader>
          ),
          dataIndex: `months[${index}].economy`,
          width: 120,
          render: (economy) => (
            <WhiteSpace>{toLocalStringRu(economy)}</WhiteSpace>
          ),
        },
        {
          title: 'Причина отклонения',
          dataIndex: `months[${index}].reason`,
          onCell: (record, rowIndex) => ({
            onClick: () => {
              if (
                record.expenseDirectionId &&
                localData?.calculationEconomyStatus !==
                  entityStatusEnum.approved
              ) {
                setEditMonth({
                  month: record.months[index],
                });
                setVisibleModal(true);
              }
            },
          }),
          width: 180,
          render: (reason) => (
            <WhiteSpace>{tooltipFullText(reason)}</WhiteSpace>
          ),
        },
      ],
    })),
  ];

  useEffect(() => {
    if (branchBudgetSummary) {
      const { budgetSummaryLineItems } = branchBudgetSummary;
      setData({
        ...branchBudgetSummary,
        budgetSummaryLineItems:
          sumBudgetLineItemBudget(budgetSummaryLineItems) ?? [],
      });
    }
  }, [branchBudgetSummary]);

  const save = async (month: any) => {
    try {
      await branchBudgetSummaryMonthApi.update(month);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  const onMonthSave = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      let promise = [];
      months.forEach((month) => promise.push(save(month)));
      await Promise.all(promise);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  const changeStatus = async () => {
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'change',
      });
      if (localData?.id) {
        await branchBudgetSummaryApi.changeStatus({
          id: localData.id,
          calculationEconomyStatus: entityStatusEnum.approved,
        });
        await setBranchBudgetSummary({
          ...localData,
          calculationEconomyStatus: entityStatusEnum.approved,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('change');
    }
  };

  const canEdit = () =>
    props.userAccess.some((access) =>
      editDetailedBudgetAccessRight.includes(access)
    );

  return (
    <>
      <SectionTitle
        divider
        suffix={
          canEdit()
            ? (localData?.calculationEconomyStatus !==
                entityStatusEnum.approved && (
                <>
                  <ChangeStatusButton type="primary" onClick={changeStatus}>
                    Утвердить
                  </ChangeStatusButton>
                  <Button type="primary" onClick={onMonthSave}>
                    Сохранить
                  </Button>
                </>
              )) ||
              ((localData?.calculationEconomyStatus ===
                entityStatusEnum.created ||
                (localData?.calculationEconomyStatus ===
                  entityStatusEnum.approved &&
                  localData?.proposalStatus === entityStatusEnum.created)) && (
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      setEditBudget(true);
                    }}
                  >
                    Редактировать
                  </Button>
                </>
              ))
            : null
        }
      >
        <BranchTabs
          id={id}
          orgUnitId={orgUnitId}
          calculationEconomyStatus={localData?.calculationEconomyStatus}
          distributionEconomyStatus={localData?.distributionEconomyStatus}
          proposalEconomyStatus={localData?.proposalStatus}
        />
      </SectionTitle>
      {editBudget ? (
        <BranchForm
          orgUnitId={orgUnitId}
          branchBudgetSummary={localData}
          budgetSummary={props.budgetSummary}
          showTable={false}
          onSubmit={(data) => {
            props.onSubmit(data);
            setEditBudget(false);
          }}
        />
      ) : (
        <InfoBranch branchBudgetSummary={localData} />
      )}

      <Section>
        <StyledTable
          columns={COLUMNS}
          data={localData?.budgetSummaryLineItems ?? []}
          childrenColumnName="expenseDirections"
          rowClassName={(record) => {
            return record.rowId < 0 ? 'ant-table-row-color-light-grey' : null;
          }}
          expandRowByClick
          expandIconAsCell={false}
          expandIconColumnIndex={1}
          expandIcon={(row) =>
            !!row.record.expenseDirections && (
              <AntIcon
                type={row.expanded ? 'up' : 'down'}
                style={{
                  fontSize: '12px',
                  float: 'right',
                }}
              />
            )
          }
          bordered
          rowKey="id"
          style={{
            overflow: 'auto',
          }}
          scroll={{
            y: '500px',
            x: '2000',
          }}
          pagination={false}
        />
      </Section>
      <ReasonDeclineModal
        visible={visibleModal}
        reason={editMonth?.month?.reason ?? ''}
        onCancel={() => setVisibleModal(false)}
        onSave={(reason) => {
          // TODO фича основанная на ссылках в js на объекты
          editMonth.month.reason = reason;

          setMonths([...months, editMonth.month]);
          setVisibleModal(false);
        }}
      />
    </>
  );
});

type ModalProps = {
  visible: boolean,
  onCancel: Function,
  onSave: Function,
  reason: string,
};
const ReasonDeclineModal = ({
  visible,
  onCancel,
  onSave,
  reason,
}: ModalProps) => {
  const [reasonDecline, setReason] = useState(reason);
  useEffect(() => {
    setReason(reason);
  }, [reason]);
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Причина отклонения"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        onSave(reasonDecline);
      }}
    >
      <StyledInput
        value={reasonDecline}
        onChange={(e) => setReason(e.target.value)}
      />
    </Modal>
  );
};
