// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer,
} from '../../components';
import { CustomInput } from '../../components/ui';
import type { FilterChildrenParams } from '../../components/hoc/Filter';
import type {
  OwnerType,
  STSType,
  Vehicle,
  VehicleStatus,
  VehicleType,
} from '../../lib/types';
import { ownerTypes } from '../../lib/enum';
import OrgUnitSelect from '../../components/OrgUnitSelect';
import Field from '../../components/card/Field';
import { applyMaskToValue } from '../../lib/helpers';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

const {
  VehicleTypeSelect,
  VehicleStatusSelect,
  VehicleSelect,
  BrandsSelect,
  VehicleModelSelect,
  YesNoSelect,
  YearIssueSelect,
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type VehicleFilterParams = {
  nodeId?: number,
  yearIssued?: number,
  status?: VehicleStatus | VehicleStatus[],
  stsType?: STSType | STSType[],
  'vehicleModel.type'?: VehicleType,
  'vehicleModel.id'?: number,
  'vehicleModel.brand.id'?: number,
  'vehicleModel.osagoNeeded'?: boolean,
  id?: number,
  vin?: string,
  ownerType?: OwnerType,
  hasGps?: boolean,
  receiveWialonData?: boolean,
};

type Props = {
  filter: VehicleFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  hideOrgUnitId?: boolean,
};

export const VehiclesFilter = ({
  filter,
  cleanFilter,
  applyFilter,
  hideOrgUnitId = false,
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional,
        }: FilterChildrenParams<VehicleFilterParams>) => (
          <>
            {hideOrgUnitId === false ? (
              <FilterItem>
                <Field mBottomNone>
                  <OrgUnitSelect
                    placeholder="Все подразделения"
                    size="small"
                    onChange={(value) => changeValue('nodeId', value)}
                    value={values.nodeId}
                  />
                </Field>
              </FilterItem>
            ) : null}
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  placeholder="Все ТС"
                  size="small"
                  value={values.id}
                  optionTitle={(vehicle: Vehicle) =>
                    applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )
                  }
                  filter={{
                    ownerType: ownerTypes.self,
                    status: values.status,
                    'vehicleModel.type': values['vehicleModel.type'],
                  }}
                  onChange={(value) => changeValue('id', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  placeholder="Все типы ТС"
                  size="small"
                  value={values['vehicleModel.type']}
                  selectableGroup
                  onChange={(value) => changeValue('vehicleModel.type', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Наличие GPS"
                  size="small"
                  value={values.hasGps}
                  optionText="Наличиие GPS"
                  reset
                  fullWidth
                  onChange={(value) => changeValue('hasGps', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleStatusSelect
                  placeholder="Все статусы"
                  size="small"
                  value={values.status}
                  onChange={(value) => changeValue('status', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <CustomInput
                  size="small"
                  placeholder="VIN"
                  value={values.vin}
                  onChange={({ target: { value } }) =>
                    changeValue('vin', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <BrandsSelect
                  placeholder="Все марки"
                  size="small"
                  value={values['vehicleModel.brand.id']}
                  onChange={(value) =>
                    changeValue('vehicleModel.brand.id', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleModelSelect
                  placeholder="Все модели ТС"
                  size="small"
                  value={values['vehicleModel.id']}
                  filter={{
                    'brand.id': values['vehicleModel.brand.id'],
                    type: values['vehicleModel.type'],
                  }}
                  onChange={(value) => changeValue('vehicleModel.id', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YearIssueSelect
                  size="small"
                  value={values['yearIssued']}
                  onChange={(value) => changeValue('yearIssued', value)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default VehiclesFilter;
