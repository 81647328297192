// @flow
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { Component } from 'react';
import { Form } from '../../../../components';
import { StyledInputNumber } from '../../../../components/hoc/common/components/elements';

import { Grid, GridItem, Section } from '../../../../components/layout';
import { notificationLoading } from '../../../../components/Notifications';
import { AntTable, ButtonsRow } from '../../../../components/ui';
import { TableHeader } from '../../../../components/ui/Table';

import { cargoPassCalculationApi, vehiclePlanApi } from '../../../../lib/api';
import { calculationStatusEnum } from '../../../../lib/enum';
import { getListInitialState, plus, toLocalStringRu } from '../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';
import type { CargoPassCalculation, ListState, VehiclePlan } from '../../../../lib/types';

import type { VehicleListFilterParams } from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';

import { COLUMNS, filteredData, MONTH } from '../lib';

import { headerPanel } from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = ListState<CargoPassCalculation> & {
  vehiclePlan: ?VehiclePlan,
  columns: any,
  filter: VehicleListFilterParams,
  visible: boolean,
  cargoPassCalculation: ?CargoPassCalculation
};

export default class extends Component<Props, State> {
  onRow = (cargoPassCalculation: CargoPassCalculation) => {
    return {
      onClick: () => {
        this.isDraft() &&
          this.setState({
            visible: true,
            cargoPassCalculation: { ...cargoPassCalculation }
          });
      }
    };
  };

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  // дата после списания
  afterPlannedWriteoffDate = (
    monthIndex: number,
    plannedWriteoffDate?: ?string
  ) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan && plannedWriteoffDate) {
      const year = moment(vehiclePlan.date).get('year');
      return moment(`${year}-${monthIndex + 1}-01`).isAfter(
        plannedWriteoffDate,
        'month'
      );
    }
    return false;
  };

  isDraft = () =>
    this.state?.vehiclePlan?.cargoPassCalculationStatus ===
    calculationStatusEnum.draft;

  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    filter: {},
    columns: [
      ...COLUMNS,
      {
        title: (
          <TableHeader width="120px">
            Тариф за превышение допустимой нормы
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'excessRate',
        render: excessRate => toLocalStringRu(excessRate)
      },
      {
        title: <TableHeader width="120px">Тариф госпошлины</TableHeader>,
        dataIndex: 'taxRate',
        width: 152,
        render: taxRate => toLocalStringRu(taxRate)
      },
      {
        title: <TableHeader width="120px">Тариф согласования</TableHeader>,
        dataIndex: 'agreementRate',
        width: 152,
        render: excessRate => toLocalStringRu(excessRate)
      },
      {
        title: (
          <TableHeader width="120px">Планируемый пробег, км в день</TableHeader>
        ),
        width: 152,
        dataIndex: 'plannedKilometrage',
        render: plannedKilometrage => toLocalStringRu(plannedKilometrage)
      },
      {
        title: (
          <TableHeader width="120px">
            Планируемое кол-во рабочих дней в месяц
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'workDaysCount',
        render: workDaysCount => toLocalStringRu(workDaysCount)
      },
      ...MONTH.map((month, index) => ({
        title: month.title,
        dataIndex: 'month.isCalculationNeeded',
        children: [
          {
            title: 'Пропуск',
            dataIndex: `months[${index}].cost`,
            render: cost => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(cost)}
              </span>
            )
          },
          {
            title: 'Гос. пошлина',
            dataIndex: `months[${index}].tax`,
            render: tax => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(tax)}
              </span>
            )
          },
          {
            title: <TableHeader width="80px">Стоимость соглашения</TableHeader>,
            dataIndex: `months[${index}].agreementCost`,
            render: agreementCost => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(agreementCost)}
              </span>
            )
          },
          {
            title: <TableHeader width="80px">Стоимость бланка</TableHeader>,
            dataIndex: `months[${index}].blankPrice`,
            render: blankPrice => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(blankPrice)}
              </span>
            )
          },
          {
            title: 'Итого',
            dataIndex: `months[${index}].tax`,
            className: 'primary-background',
            render: (tax, row: CargoPassCalculation) => {
              const sum = plus(
                plus(tax, row.months[index].cost),
                plus(
                  row.months[index].agreementCost,
                  row.months[index].blankPrice
                )
              );
              return (
                <span
                  className={
                    this.isDraft() && this.isRangeMonths(index)
                      ? 'link-text'
                      : null
                  }
                >
                  {toLocalStringRu(sum)}
                </span>
              );
            }
          }
        ]
      })),
      {
        title: (
          <TableHeader width="120px">
            Итого годовая сумма в руб на пропуска. без НДС
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'passSum',
        render: passSum => toLocalStringRu(passSum)
      },
      {
        title: (
          <TableHeader width="130px">
            Итого годовая сумма в руб на пошлину руб. без НДС
          </TableHeader>
        ),
        width: 162,
        dataIndex: 'taxSum',
        render: taxSum => toLocalStringRu(taxSum)
      },
      {
        title: (
          <TableHeader width="130px">
            Итого годовая сумма в руб на согласование руб. без НДС
          </TableHeader>
        ),
        width: 162,
        dataIndex: 'agreementSum',
        render: agreementSum => toLocalStringRu(agreementSum)
      },
      {
        title: (
          <TableHeader width="130px">
            Итого годовая сумма в руб. на бланки, руб. без НДС
          </TableHeader>
        ),
        width: 162,
        dataIndex: 'blankSum',
        render: blankSum => toLocalStringRu(blankSum)
      },
      {
        title: (
          <TableHeader width="120px">
            Итого годовая сумма в руб на ТС
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'sum',
        render: sum => toLocalStringRu(sum)
      }
    ],
    visible: false,
    rowIndex: undefined,
    cargoPassCalculation: null
  };

  componentDidMount() {
    this.getData();
  }

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;
    try {
      let { data } = await cargoPassCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data });
    } catch (error) {
      notification.error({ message: error.message });
    }
  };

  handleSave = async (cargoPassCalculation: CargoPassCalculation) => {
    const { data } = this.state;
    this.setState({ loading: true });
    const rowIndex = data.findIndex(
      item => item.id === cargoPassCalculation.id
    );
    try {
      if (cargoPassCalculation && rowIndex !== -1) {
        let update = await cargoPassCalculationApi.calculation.update(
          cargoPassCalculation
        );
        data[rowIndex] = update;
        this.setState({ data });
      }
    } catch (error) {
      notification.warning({ message: 'Не удалось обновить данные' + error });
    } finally {
      this.setState({
        loading: false,
        visible: false,
        cargoPassCalculation: null
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      cargoPassCalculation: null
    });
  };

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await cargoPassCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    const { data } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      if (data.length === 0)
        await cargoPassCalculationApi.calculate(vehiclePlanId);
      else await cargoPassCalculationApi.updateCalculations(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  itogCalculation = (data: any) => {
    const itog = {
      // $FlowFixMe
      months: Array(12)
        .fill()
        .map(u => ({
          cost: 0,
          tax: 0,
          blankPrice: 0,
          agreementCost: 0,
          itog: 0,
          taxSum: 0,
          passSum: 0,
          agreementSum: 0
        })),
      sum: 0,
      blankSum: 0,
      agreementSum: 0,
      taxSum: 0,
      passSum: 0
    };
    data.forEach((item: any, index) => {
      itog.sum = plus(itog.sum, item.sum);
      itog.blankSum = plus(itog.blankSum, item.blankSum);
      itog.agreementSum = plus(itog.agreementSum, item.agreementSum);
      itog.taxSum = plus(itog.taxSum, item.taxSum);
      itog.passSum = plus(itog.passSum, item.passSum);
      item.months.forEach((month: any, index) => {
        itog.months[index].cost = plus(
          itog.months[index].cost,
          month.cost ?? 0
        );
        itog.months[index].tax = plus(itog.months[index].tax, month.tax ?? 0);
        itog.months[index].taxSum = plus(
          itog.months[index].taxSum,
          month.taxSum ?? 0
        );
        itog.months[index].blankPrice = plus(
          itog.months[index].blankPrice,
          month.blankPrice ?? 0
        );
        itog.months[index].passSum = plus(
          itog.months[index].passSum,
          month.passSum ?? 0
        );
        itog.months[index].agreementSum = plus(
          itog.months[index].agreementSum,
          month.agreementSum ?? 0
        );
        itog.months[index].agreementCost = plus(
          itog.months[index].agreementCost,
          month.agreementCost ?? 0
        );
        itog.months[index].itog = plus(
          itog.months[index].tax,
          plus(
            itog.months[index].cost,
            plus(
              itog.months[index].agreementCost,
              itog.months[index].blankPrice ?? 0
            )
          )
        );
      });
    });
    return itog;
  };

  applyFilter = (filter: VehicleListFilterParams) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  handlePrint = () => {
    printNotification(async () => {
      await cargoPassCalculationApi.print(
        this.props.vehiclePlanId,
        this.state.filter
      );
    });
  };

  render() {
    const { vehiclePlanId } = this.props;
    const {
      columns,
      visible,
      loading,
      data,
      filter,
      cargoPassCalculation
    } = this.state;

    const filtered = filteredData(data, filter);
    const itog = this.itogCalculation(filtered);
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          title: 'Пропуска негабарит',
          passTab: true
        })}
        <Section>
          <div style={{ padding: '16px 16px 0px' }}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            columns={columns}
            onRow={this.onRow}
            bordered
            scroll={{
              x: 8000,
              y: 'calc(100vh - 280px)'
            }}
          />
          <ModalCargoPass
            visible={visible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            isRangeMonths={this.isRangeMonths}
            cargoPassCalculation={cargoPassCalculation}
            afterPlannedWriteoffDate={this.afterPlannedWriteoffDate}
          />
        </Section>
        {
          <Section style={{ padding: '16px' }}>
            <ButtonsRow>
              {this.isDraft() && (
                <>
                  <Button type="primary" onClick={this.calculate}>
                    {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                  </Button>
                  <Button type="primary" onClick={this.changeStatus}>
                    Утвердить
                  </Button>
                </>
              )}
              {data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Section>
        }
      </>
    );
  }
}

type ModalCargoPassProps = {
  visible: boolean,
  handleSave: Function,
  handleCancel: Function,
  isRangeMonths: Function,
  cargoPassCalculation: ?CargoPassCalculation,
  afterPlannedWriteoffDate: Function
};

const ModalCargoPass = (props: ModalCargoPassProps) => {
  const {
    visible,
    handleSave,
    handleCancel,
    cargoPassCalculation,
    isRangeMonths,
    afterPlannedWriteoffDate
  } = props;
  const onOk = data => {
    handleSave(data);
  };
  const onCancel = () => {
    handleCancel();
  };

  return (
    <Modal
      forceRender
      destroyOnClose
      width={400}
      title="Редактирование"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" form="modal-form" htmlType="submit">
          Сохранить
        </Button>
      ]}
    >
      <Form initialValues={cargoPassCalculation} onSubmit={onOk}>
        {(FormField, formikProps: FormikProps) => {
          const { setFieldValue } = formikProps;
          return (
            <form onSubmit={formikProps.handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem fullWidth>
                  <FormField
                    label="Планируемый пробег, км в день"
                    fast
                    name="plannedKilometrage"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        min={0}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem fullWidth>
                  <FormField
                    label="Планируемое кол-во рабочих дней в месяц"
                    fast
                    name="workDaysCount"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        min={0}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>

                {
                  <FormField label="Необходимы пропуска:" fast name="months">
                    {({ value }) => {
                      return value
                        ? value.map((val, index) => {
                            return (
                              <GridItem fullWidth>
                                <Checkbox
                                  name={`months[${index}].isCalculationNeeded`}
                                  checked={val.isCalculationNeeded}
                                  disabled={
                                    !isRangeMonths(index) ||
                                    afterPlannedWriteoffDate(
                                      index,
                                      cargoPassCalculation
                                        ?.selfVehiclePlanVehicle.vehicle
                                        .plannedWriteoffDate ||
                                        cargoPassCalculation
                                          ?.selfVehiclePlanVehicle
                                          .plannedWriteoffDate
                                    )
                                  }
                                  onChange={e =>
                                    setFieldValue(
                                      `months[${index}].isCalculationNeeded`,
                                      e.target.checked
                                    )
                                  }
                                >
                                  {MONTH[index].title}
                                </Checkbox>
                              </GridItem>
                            );
                          })
                        : null;
                    }}
                  </FormField>
                }
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
