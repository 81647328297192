// @flow
import React from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import InnerForm from './InnerForm';
import { Panel } from './../../components/layout';
import Header from '../../components/layout/Header';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import {
  addFuelMultiplier,
  fetchFuelMultiplier,
  deleteFuelMultiplier,
  updateFuelMultiplier,
  cleanFuelMultiplier
} from '../../ducks/fuelMultiplier';
import { notificationLoading } from './../../components/Notifications';
import type { FuelMultiplier } from '../../lib/types/fuelMultiplier';

type Props = {
  multiplier: FuelMultiplier,
  fetchFuelMultiplier: (id: number) => Promise<void>,
  updateFuelMultiplier: (multiplier: FuelMultiplier) => Promise<void>,
  addFuelMultiplier: Function,
  cleanFuelMultiplier: Function,
  multiplierId: string
};

class FuelMultiplierForm extends React.Component<Props> {
  async componentDidMount() {
    await this.props.cleanFuelMultiplier();
    if (this.props.multiplierId) {
      try {
        await this.props.fetchFuelMultiplier(
          parseInt(this.props.multiplierId, 10)
        );
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/admin/fuel-multipliers');
      }
    }
  }

  onSubmit = async (multiplier: FuelMultiplier) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.multiplierId) {
        await this.props.updateFuelMultiplier(multiplier);
        notification.success({
          message: 'Успешное обновление',
          description: `Топливный коэффициент успешно обновлен`
        });

        navigate('/admin/fuel-multipliers');
      } else {
        await this.props.addFuelMultiplier(multiplier);
        notification.success({
          message: 'Успешное добавление',
          description: `Топливный коэффициент успешно добавлен`
        });

        navigate('/admin/fuel-multipliers');
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };
  onCancel = () => {
    navigate('/admin/fuel-multipliers');
  };
  render() {
    const { multiplier } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/fuel-multipliers">Топливные коэффициенты</Crumb>
              <Crumb>
                {multiplier
                  ? `Редактирование "${multiplier.name}"`
                  : 'Новый коэффициент'}
              </Crumb>
            </Breadcrumbs>
          }
        />

        <StyledPanel>
          <h1>
            {multiplier ? `${multiplier.name}` : 'Новый топливный коэффициент'}
          </h1>
        </StyledPanel>

        <InnerForm
          multiplier={multiplier}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const mapStateToProps = state => ({
  multiplier: state.fuelMultiplier
});

export default connect(
  mapStateToProps,
  {
    addFuelMultiplier,
    fetchFuelMultiplier,
    deleteFuelMultiplier,
    updateFuelMultiplier,
    cleanFuelMultiplier
  }
)(FuelMultiplierForm);
