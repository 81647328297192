// @flow
import React from 'react';

import { vehicleTaxMapApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { vehicleTypes } from '../../lib/enum';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';
import { navigate } from '../../lib/helpers';

const entryPointPath = '/admin/vehicle-tax-map/';

const columns = [
  {
    title: 'Наименование объекта налогообложения',
    dataIndex: 'name'
  },
  {
    title: 'Тип ТС',
    dataIndex: 'vehicleType',
    render: (vehicleType: string) => {
      return vehicleTypes[vehicleType];
    }
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  return (
    <Page>
      <CommonListPage
        crud={vehicleTaxMapApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Соответствие объекта налогообложения и категории ТС',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: true
        }}
        filter={Filter}
      />
    </Page>
  );
});
