// @flow

import { Dispatch } from 'redux';

import type { Action } from './redux';

export const SET_FILTER = '/vehicles/filters/setFilter';
export const CLEAN_FILTER = '/vehicles/filters/cleanFilter';

export type PersistFiltersState = any;

const initialState: PersistFiltersState = {};

export type PersistFilterPayload = {
  // Уникальный путь до фильтра (например, url страницы)
  path: string,
  // Значения, которые необходимо записать
  values: any
};

const reducer = (
  state: PersistFiltersState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case SET_FILTER:
      return {
        ...state,
        [payload.path]: { ...payload.values }
      };
    case CLEAN_FILTER:
      return {
        ...state,
        [payload]: {}
      };
    default:
      return state;
  }
};

/**
 * Проставление фильтра
 */
export const setFilter = (payload: PersistFilterPayload) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_FILTER,
    payload
  });
};

export default reducer;
