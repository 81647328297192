// @flow

import type { Offer } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

import { convertFromRubToKop, convertFromKopToRub } from './../helpers';

export const convertDataToServer = (offer: Offer) => ({
  ...offer,
  lastPrice: convertFromRubToKop(offer.lastPrice)
});
export const convertDataFromServer = (offer: Offer) => ({
  ...offer,
  lastPrice: convertFromKopToRub(offer.lastPrice)
});

export const addOffer = async (offer: Offer): Promise<Offer> => {
  const added = await fetchRequest.post('/offer', convertDataToServer(offer));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать предложение от поставщика');
};

export const updateOffer = async (offer: Offer): Promise<Offer> => {
  const updated = await fetchRequest.put('/offer', convertDataToServer(offer));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить предложение от поставщика');
};

export const fetchOffer = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Offer>> => {
  const offers = await fetchRequest.get('/offer', {
    ...initialFetchListParams,
    ...params
  });
  if (offers) {
    return {
      ...offers,
      data: offers.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список предложений');
};

export const getOffer = async (
  id: number,
  params: any = {}
): Promise<Offer> => {
  const offer = await fetchRequest.get(`/offer/${id}`, params);
  if (offer) return convertDataFromServer(offer);
  throw new Error('Не удалось загрузить предложение от поставщика');
};

export const deleteOffer = async (id: number): Promise<Offer> => {
  const deleted = await fetchRequest.delete(`/offer/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить предложение от поставщика');
};

export default {
  addOffer,
  updateOffer,
  fetchOffer,
  deleteOffer,
  getOffer
};
