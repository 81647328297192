// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { regulationApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await regulationApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={regulationApi.get}
    notFoundText="Регламенты не найдены"
    placeholder="Выберите регламент"
    renderOption={(regulation: any, Option) => (
      <Option key={regulation.id} value={regulation.id} regulation={regulation}>
        {`Регламент № ${regulation.id}`}
      </Option>
    )}
    {...selectProps}
  />
);
