// @flow
import React from 'react';
import Input from 'antd/lib/input';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import { Filter, Selects } from '../../../components';
import Spoiler from '../../../components/ui/Spoiler';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Field from '../../../components/card/Field';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';

const Footer = styled(GridItem).attrs({
  fullWidth: true
})`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const SpoilerContent = styled.div`
  padding: 16px;
`;
const { YesNoSelect } = Selects;

export type MdmFilterParams = {
  isArchive?: boolean,
  hasMnemocode?: boolean,
  mnemocode?: string,
  hasParentmnemocode?: boolean,
  parentMnemocode?: string
};

type Props = {
  filter: MdmFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

const MdmFilter = ({ filter, cleanFilter, applyFilter }: Props) => {
  return (
    <Spoiler label="Фильтр" defaultExpanded>
      <SpoilerContent>
        <Filter
          applyFilter={applyFilter}
          cleanFilter={cleanFilter}
          initialValues={filter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter
          }: FilterChildrenParams<MdmFilterParams>) => (
            <Grid gutter="16px">
              <GridItem>
                <Field label="Мнемокод">
                  <Input
                    value={values.mnemocode}
                    onChange={e => changeValue('mnemocode', e.target.value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Архив">
                  <YesNoSelect
                    placeholder=""
                    reset
                    fullWidth
                    value={values.isArchive}
                    onChange={(value: boolean) =>
                      changeValue('isArchive', value)
                    }
                  />
                </Field>
              </GridItem>
              <Footer>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button onClick={cleanFilter}>Очистить</Button>
              </Footer>
            </Grid>
          )}
        </Filter>
      </SpoilerContent>
    </Spoiler>
  );
};

export default MdmFilter;
