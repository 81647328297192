// @flow

import { fetchRequest, initialFetchListParams } from './index';
import type { Act, ActStatusType } from './../types';
import type { FetchListParams, ListResponse } from './index';
import { convertDataFromServer as convertDataFromServerIncomeSchedule } from './incomeSchedule';

export const convertDataFromServer = (act: Act): Act => ({
  ...act,
  incomeSchedule: act.incomeSchedule
    ? convertDataFromServerIncomeSchedule(act.incomeSchedule)
    : null
});

export const addAct = async (act: Act): Promise<Act> => {
  const added = await fetchRequest.post('/act', act);
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать акт');
};

export const updateAct = async (act: Act): Promise<Act> => {
  const updated = await fetchRequest.put('/act', act);
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить акт');
};

export const fetchActs = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Act>> => {
  const acts = await fetchRequest.get('/act', {
    ...initialFetchListParams,
    ...params
  });
  if (acts) {
    return {
      ...acts,
      data: acts.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список актов');
};

export const getAct = async (id: number, params: any = {}): Promise<Act> => {
  const act = await fetchRequest.get(`/act/${id}`, params);
  if (act) return convertDataFromServer(act);
  throw new Error('Не удалось загрузить акт');
};

export const deleteAct = async (id: number): Promise<Act> => {
  const deleted = await fetchRequest.delete(`/act/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить акт');
};

export const changeStatus = async (
  id: number,
  status: ActStatusType
): Promise<Act> => {
  const act = await fetchRequest.put(`/act/changeStatus`, { id, status });
  if (act) return convertDataFromServer(act);
  throw new Error('Не удалось изменит статус акта');
};

export default {
  changeStatus,
  addAct,
  updateAct,
  fetchActs,
  deleteAct,
  getAct
};
