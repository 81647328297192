// @flow

import type { Dispatch } from 'redux';

import type { ContractVehicle } from './../lib/types';
import { contractVehicleApi } from './../lib/api';
import type { Action, AppState } from './redux';

export const SET_CONTRACT_VEHICLE = 'vehicles/contractVehicle/set';
export const UPDATE_CONTRACT_VEHICLE = 'vehicles/contractVehicle/update';
export const CLEAN_CONTRACT_VEHICLE = 'vehicles/contractVehicle/clean';

export type ContractVehicleState = ?ContractVehicle;

const initialState: ContractVehicleState = null;

const reducer = (
  state: ContractVehicleState = initialState,
  { type, payload }: Action
): ContractVehicleState => {
  switch (type) {
    case SET_CONTRACT_VEHICLE:
    case UPDATE_CONTRACT_VEHICLE:
      return { ...payload };
    case CLEAN_CONTRACT_VEHICLE:
      return null;
    default:
      return state;
  }
};

export const addContractVehicle: Function = (
  contractVehicle: ContractVehicle
): Function => async (dispatch: Dispatch): Promise<void> => {
  const payload = await contractVehicleApi.addContractVehicle(contractVehicle);
  dispatch({
    type: SET_CONTRACT_VEHICLE,
    payload
  });
};

export const updateContractVehicle: Function = (
  contractVehicle: ContractVehicle
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const updated = await contractVehicleApi.updateContractVehicle(
    contractVehicle
  );
  dispatch({
    type: UPDATE_CONTRACT_VEHICLE,
    payload: {
      ...updated
    }
  });
};

export const fetchContractVehicle = (id: number) => async (
  dispatch: Dispatch
) => {
  let contractVehicle = await contractVehicleApi.fetchContractVehicle(id);
  dispatch({
    type: SET_CONTRACT_VEHICLE,
    payload: {
      ...contractVehicle
    }
  });
};

export const cleanContractVehicle = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAN_CONTRACT_VEHICLE
  });

export default reducer;
