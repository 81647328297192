// @flow

import type { ExpenseDirection } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addExpenseDirection = async (
  expenseDirection: ExpenseDirection
): Promise<ExpenseDirection> => {
  const added = await fetchRequest.post('/expenseDirection', expenseDirection);
  if (added) return added;
  throw new Error('Не удалось добавить новое направление расходов');
};

export const updateExpenseDirection = async (
  expenseDirection: ExpenseDirection
): Promise<ExpenseDirection> => {
  const updated = await fetchRequest.put('/expenseDirection', expenseDirection);
  if (updated) return updated;
  throw new Error('Не удалось обновить направление расходов');
};

export const getExpenseDirection = async (
  id: number,
  params: any = {}
): Promise<ExpenseDirection> => {
  let expenseDirection = await fetchRequest.get(
    `/expenseDirection/${id}`,
    params
  );
  if (expenseDirection) return expenseDirection;
  throw new Error('Не удалось загрузить направление расходов');
};

export const fetchExpenseDirection = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<ExpenseDirection>> =>
  await fetchRequest.get('/expenseDirection', {
    ...initialFetchListParams,
    ...params
  });

export const deleteExpenseDirection = async (
  id: number
): Promise<ExpenseDirection> => {
  const deleted = await fetchRequest.delete(`/expenseDirection/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить направление расходов');
};

export default {
  addExpenseDirection,
  updateExpenseDirection,
  fetchExpenseDirection,
  getExpenseDirection,
  deleteExpenseDirection
};
