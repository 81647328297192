// @flow

import React from 'react';
import type { Node } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  inverted?: Boolean,
  // Подпись поля
  label?: string,
  // Добавляет красную звездочку
  required?: boolean,
  // Убираем отступы снизу margin-bottom
  mBottomNone?: boolean,
  children: Node,
  labelStyle?: $Shape<CSSStyleDeclaration>,
  valueStyle?: $Shape<CSSStyleDeclaration>,
  wrapperStyle?: $Shape<CSSStyleDeclaration>
};

const Wrapper = styled.div.attrs({
  className: 'field'
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Label = styled.p.attrs({
  className: 'field__label'
})`
  color: #838d96;
  margin-bottom: 5px;
  ${({ inverted }) =>
    inverted &&
    css`
      color: #3d4042;
    `};
`;

const ValueMargin = styled.div.attrs({
  className: 'field__value'
})`
  margin-bottom: 23px;
  color: #3d4042;
  ${({ inverted }) =>
    inverted &&
    css`
      color: #838d96;
    `};
  ${({ none }) =>
    none &&
    css`
      margin-bottom: 0;
    `};
`;
const Required = styled.span`
  color: red;
  position: relative;
  top: -3px;
  right: -2px;
  font-size: 12px;
`;

/**
 * Поле карточки
 */
export default ({
  label,
  mBottomNone,
  labelStyle,
  valueStyle,
  wrapperStyle,
  children,
  required,
  inverted
}: Props) => {
  return (
    <Wrapper style={wrapperStyle}>
      {label && (
        <Label inverted={inverted} style={labelStyle}>
          {label} {required && <Required>*</Required>}
        </Label>
      )}
      <ValueMargin none={mBottomNone} inverted={inverted} style={valueStyle}>
        {children}
      </ValueMargin>
    </Wrapper>
  );
};
