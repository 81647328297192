// @flow
import { omit } from 'lodash';
import AutoGeneratedEnumSelect, {
  AutoGeneratedMultipleEnumSelect,
} from './AutoGeneratedEnumSelect';
import {
  approveTypes,
  authorizeTypes,
  briefingViews,
  businessDayTypes,
  certificationAreas,
  chassisConfigurations,
  contractVehicleType,
  driverStatuses,
  employeeCertificationViews,
  engineTypes,
  entityStatus,
  entityTypes,
  expenseDirectionTypes,
  fuelGroups,
  fuelTypes,
  fuelTypesCalculation,
  insuranceClasses,
  maintenanceOperationStatuses,
  maintenanceStatusesTypes,
  maintenanceTypeOfWork,
  maintenanceTypes,
  measureType,
  measureTypeFuelPrice,
  medicOwnerEnum,
  medicOwnerTypes,
  monthlyWashingPlanStatuses,
  monthsTypes,
  nomenclatureType,
  operatingModes,
  orderContractorTypes,
  orderDispatcherTypes,
  orderObjectives,
  orderTypeEnum,
  orderTypes,
  osagoCalculationStatuses,
  osagoTransportType,
  positions,
  pseudoNameTypes,
  rateType,
  repairPlanTypes,
  serviceOrderTypes,
  STSTypes,
  tariffTypes,
  trainingViews,
  vehicleIssueTypes,
  vehicleOvbTypes,
  washingStatusTypes,
  washingTypes,
  workHourPriceTypes,
  operationType,
  technicalFluidTypes,
  sparePartMaterialTypes,
  seasonTypeTire,
  pddTicketStatusesEnum,
  pddTestStatuses,
  taxRates,
  budgetSummaryStatus,
  vehiclePtsType,
  attachedEquipmentFuelTankType,
  sideServiceType,
  sideServiceTariffMeasure,
  bioTreatmentFacilityFactStatus,
  sideServiceTariffType,
} from '../../lib/enum';
import {
  tireDiameterEnum,
  tireProfileEnum,
  tireWidthEnum,
} from '../../lib/enum/tire';

export const SideServiceTariffTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: sideServiceTariffType,
});

export const BioTreatmentFacilityFactStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: bioTreatmentFacilityFactStatus,
});

export const SideServiceTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип услуги',
  autoType: sideServiceType,
});

export const SideServiceTariffMeasureSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите единицу учета',
  autoType: sideServiceTariffMeasure,
});

export const AttachedEquipmentFuelTankTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип бака',
  autoType: attachedEquipmentFuelTankType,
});

export const VehiclePtsTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип ПТС',
  autoType: vehiclePtsType,
});

export const TaxRateSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите ставку НДС',
  autoType: taxRates,
});
export const SeasonTypeTireSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип шины',
  autoType: seasonTypeTire,
});
export const OperationTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите способ выполнения',
  autoType: operationType,
});
export const AuthorizeTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите группу дефекта',
  autoType: authorizeTypes,
});
// Выпадающий список типов дня производственного календаря
export const BusinessDayTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип дня',
  autoType: businessDayTypes,
});
export const CertificationAreaSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите область аттестации',
  autoType: certificationAreas,
});
// Выпадающий список колесных формул привода
export const ChassisConfigurationSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите колесную формулу',
  autoType: chassisConfigurations,
});
export const ContractVehicleTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: contractVehicleType,
});
// Выпадающий список видов инструктажа
export const DriverBriefingViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: briefingViews,
});
export const DriverStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус водителя',
  autoType: driverStatuses,
});
// Выпадающий список видов инструктажа
export const EmployeeBriefingViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: briefingViews,
});
// Выпадающий список видов аттестаций
export const EmployeeCertificationViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: employeeCertificationViews,
});
// Выпадающий список типов двигателя
export const EngineTypeSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: engineTypes,
});
export const EntityStatusSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: entityStatus,
});
// Выпадающий список типов сущностей
export const EntityTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип события',
  autoType: entityTypes,
});
export const ExpenseDirectionTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип направления',
  autoType: expenseDirectionTypes,
});
// Выпадающий список типов топлива
export const FuelGroupSelect = AutoGeneratedEnumSelect({
  placeholder: 'Введите вид топлива',
  autoType: fuelGroups,
});
// Выпадающий список типов топлива
export const FuelTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид топлива',
  autoType: fuelTypes,
});
export const FuelTypeCalculationSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид топлива',
  autoType: fuelTypesCalculation,
});
export const InsuranceClassSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите класс годового срока страхования',
  autoType: insuranceClasses,
});
export const MaintenanceOperationStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: maintenanceOperationStatuses,
});
export const MaintenanceStatusesTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус ремонта',
  autoType: maintenanceStatusesTypes,
});
export const MaintenanceTypeOfWorkSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип для видов работ',
  autoType: maintenanceTypeOfWork,
});
export const MaintenanceTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: maintenanceTypes,
});
export const MeasureTypeFuelPriceSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: measureTypeFuelPrice,
});
export const MeasureTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: measureType,
});
// Выпадающий список видом мед. работников
export const MedicOwnerTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип мед. работника',
  value: medicOwnerEnum.own,
  autoType: medicOwnerTypes,
});
export const MonthlyWashingPlanStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: monthlyWashingPlanStatuses,
});
export const NomenclatureTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: nomenclatureType,
});
export const OperatingModeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите режим эксплуатации',
  autoType: operatingModes,
});
export const OrderContractorTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: orderContractorTypes,
});
export const OrderObjectiveSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите цель поездки',
  autoType: orderObjectives,
});
export const OrderTypeDispatcherSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид заявок',
  autoType: orderDispatcherTypes,
});
// Тип заявки "Дополнительная" является устаревшей и мы не должны ее показывать
export const OrderTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип заявки',
  autoType: omit(orderTypes, orderTypeEnum.additional),
});
export const OsagoCalculationStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: osagoCalculationStatuses,
});
export const OsagoTransportTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип транспорта',
  autoType: osagoTransportType,
});
export const PositionSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите обязанности сотрудника',
  autoType: positions,
});
export const RateTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: rateType,
});
export const RepairePlanSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: repairPlanTypes,
});
export const ServiceOrderTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: serviceOrderTypes,
});
export const STSTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип СТС',
  autoType: STSTypes,
});
export const TariffTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип тарифа',
  autoType: tariffTypes,
});
// Выпадающий список видов обучения
export const TrainingViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: trainingViews,
});
export const VehicleIssueTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите группу дефекта',
  autoType: vehicleIssueTypes,
});
export const VehicleOvbTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите режим работы ОВБ',
  autoType: vehicleOvbTypes,
});
export const WashingStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус мойки',
  autoType: washingStatusTypes,
});
export const WashingTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид мойки',
  autoType: washingTypes,
});
export const WorkHourPriceSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип нормо-часа',
  autoType: workHourPriceTypes,
});
export const ApproveYesNoSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: approveTypes,
});
export const PseudoNameSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите псевдо имя',
  autoType: pseudoNameTypes,
});
export const MonthsSelect = AutoGeneratedMultipleEnumSelect({
  placeholder: 'Выберите месяцы',
  autoType: monthsTypes,
});
export const TechnicalFluidTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип тех. жидкости',
  autoType: technicalFluidTypes,
});
export const TireDiameterSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите диаметр шины',
  autoType: tireDiameterEnum,
});
export const TireWidthSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите ширину шины',
  autoType: tireWidthEnum,
});
export const TireProfileSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите профиль шины',
  autoType: tireProfileEnum,
});
export const SparePartMaterialSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип материалов',
  autoType: sparePartMaterialTypes,
});
export const PddTicketStatusesSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: pddTicketStatusesEnum,
});
export const PddTestTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип теста',
  autoType: {
    training: 'Обучение',
  },
});
export const PddTestStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: pddTestStatuses,
});

export const BudgetSummaryStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: budgetSummaryStatus,
});
