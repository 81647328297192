// @flow

import { Dispatch } from 'redux';

import type { Role } from '../lib/types';
import { roleApi } from './../lib/api';
import type { Action, AppState, ListState } from './redux';
import { defaultPageSize } from './constants';
import type { FetchListParams } from './../lib/api';

export const SET_ROLES = '/vehicles/roles/set';

export type RolesState = ListState<Role>;

const initialState: RolesState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0
};

const reducer = (
  state: RolesState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case SET_ROLES:
      return { ...state, ...payload };
    default:
      return state;
  }
};

// Подгрузка данных
export const fetchRoles = (
  page: number = 1,
  params: FetchListParams<any> = {}
) => async (dispatch: Dispatch, getState: () => AppState) => {
  const { pageSize } = getState().roles;
  let { data, totalCount } = await roleApi.fetchRoles({
    page,
    pageSize,
    ...params
  });
  dispatch({
    type: SET_ROLES,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export default reducer;
