// @flow
import CommonApi from './../commonApi';
import type { WialonTrip } from '../types';
import { downloadRequestWithToken, fetchRequest } from './index';
import type { FetchListParams } from './index';
import {
  convertFromKmToM,
  convertFromMToKm,
  convertFromSecondToHour,
  convertFromHourToSecond,
} from '../helpers';
import { convertDataFromServer as convertDataFromServerTrip } from './trip';

const convertDataFromServer = (wialonTrip: WialonTrip) => ({
  ...wialonTrip,
  kilometrage: convertFromMToKm(wialonTrip.kilometrage),
  workHours: convertFromSecondToHour(wialonTrip.workHours),
  trip: wialonTrip.trip ? convertDataFromServerTrip(wialonTrip.trip) : null,
});

const convertDataToServer = (wialonTrip: WialonTrip) => ({
  ...wialonTrip,
  workHours: convertFromHourToSecond(wialonTrip.workHours),
  kilometrage: convertFromKmToM(wialonTrip.kilometrage),
});

const wialonTrip = new CommonApi<WialonTrip>(
  'wialonTrip',
  'Данные по пробегу wialon',
  convertDataToServer,
  convertDataFromServer
);

export const printTrip = async (id: number): Promise<boolean> => {
  const print = await downloadRequestWithToken(`/wialonTrip/${id}/print`);
  if (print) return print;
  throw new Error('Не удалось загрузить путевой лист');
};

export const print = async (params: FetchListParams<any>): Promise<boolean> => {
  const printed = await downloadRequestWithToken('/wialonTrip/print', {
    ...params,
  });
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const getWialonData = async (params: any): Promise<any> => {
  const wialonData = await fetchRequest.post(
    '/wialonTrip/getWialonData',
    params
  );
  if (wialonData) return convertDataFromServer(wialonData);
  throw new Error('Не удалось запросить данные');
};

export const updateWialonData = async (wialonTripId: number): Promise<any> => {
  const wialonData = await fetchRequest.put(
    `/wialonTrip/updateWialonData/${wialonTripId}`,
    {}
  );
  if (wialonData) return convertDataFromServer(wialonData);
  throw new Error('Не удалось запросить данные');
};

export const printDeviationReport = async (
  params: FetchListParams<any>
): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    '/wialonTrip/deviationReport/print',
    {
      ...params,
    }
  );
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export default {
  ...wialonTrip,
  printTrip,
  print,
  printDeviationReport,
  getWialonData,
  updateWialonData,
};
