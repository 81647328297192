// @flow

import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import { navigate } from '@reach/router';

import type { Vehicle } from '../../../../lib/types';
import { Card } from '../../../../components';
import { Section } from '../../../../components/layout';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { Icon, Slider } from '../../../../components/ui';
import SectionTitle from '../SectionTitle';
import {
  formatDateTimeToString,
  applyMaskToValue,
  toLocalString,
} from '../../../../lib/helpers';
import { driverQualifications, engineTypes } from './../../../../lib/enum';
import { canShowGpmData, prepareSliderItem } from './../../lib';
import { formatLicensePlateMask } from '../../../../components/inputs/masked-inputs/LicensePlateInput';
import { formatPTSMask } from '../../../../components/inputs/masked-inputs/PTSInput';
import { formatSTSMask } from '../../../../components/inputs/masked-inputs/STSInput';
import DriverLicenseExpired from '../DriverLicenseExpired';
import DriverInfoPopover from '../DriverInfoPopover';

const { Field } = Card;
const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Placeholder = styled.div`
  text-align: center;
  padding: 16px;
  color: #838d96;
`;

const formatDate = (date) => moment.utc(date).format('DD.MM.YYYY');

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  anchor: ?string,
  canHandleMaintenanceServices: boolean,
};

const isAccountantEmpty = function (vehicle: Vehicle) {
  const {
    initialCost,
    residualValue,
    residualValueUpdateDate,
    accountingRegistrationDate,
    accountingDeregistrationDate,
    restoreValue,
  } = vehicle;
  return (
    initialCost === null &&
    residualValue === null &&
    residualValueUpdateDate === null &&
    restoreValue === null &&
    accountingRegistrationDate === null &&
    accountingDeregistrationDate === null
  );
};

export default class MainTab extends Component<Props> {
  componentDidMount() {
    if (this.props.anchor) {
      scroller.scrollTo(this.props.anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }

  render() {
    const { vehicle, editable }: Props = this.props;

    return (
      <>
        <Element name="files/album">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/files/album`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Изображения
            </SectionTitle>
            {vehicle && (
              <SectionContent>
                {vehicle.files &&
                  (vehicle.files.length === 0 ? (
                    <Placeholder>Изображения отсутствуют</Placeholder>
                  ) : (
                    <Slider items={vehicle.files.map(prepareSliderItem)} />
                  ))}
              </SectionContent>
            )}
          </Section>
        </Element>
        <Element name="numbers">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/numbers`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Номера
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px" cols={3} lastItem={false}>
                <GridItem>
                  <Grid cols={1}>
                    {vehicle.vin && (
                      <GridItem>
                        <Field label="VIN номер">{vehicle.vin}</Field>
                      </GridItem>
                    )}
                    {!!vehicle.licensePlate && (
                      <GridItem>
                        <Field label="Гос. номер">
                          {applyMaskToValue(
                            vehicle.licensePlate,
                            formatLicensePlateMask
                          )}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.pts && (
                      <GridItem>
                        <Field label="Серия номер ПТС">
                          {applyMaskToValue(vehicle.pts, formatPTSMask)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.epts && (
                      <GridItem>
                        <Field label="Электронный ПТС">{vehicle.epts}</Field>
                      </GridItem>
                    )}
                    {vehicle.sts && (
                      <GridItem>
                        <Field label="Серия номер СТС">
                          {applyMaskToValue(vehicle.sts, formatSTSMask)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle?.vehicleModel?.rightsCategory?.length !== 0 && (
                      <GridItem>
                        <Field label="Категория прав">
                          {vehicle?.vehicleModel?.rightsCategory &&
                            vehicle.vehicleModel.rightsCategory
                              .join(', ')
                              .toLocaleUpperCase()}
                        </Field>
                      </GridItem>
                    )}
                    {!!vehicle.deviceId && (
                      <GridItem>
                        <Field label="ГЛОНАСС/GPS-трекер">
                          {vehicle.deviceId ?? '-'}
                        </Field>
                      </GridItem>
                    )}
                    {!!vehicle.deregistrationDate && (
                      <GridItem>
                        <Field label="Дата снятия с учета в ГИБДД">
                          {formatDate(vehicle.deregistrationDate)}
                        </Field>
                      </GridItem>
                    )}
                    {!!vehicle.typeStou && (
                      <GridItem>
                        <Field label="Тип ТС по СТОУ">{vehicle.typeStou}</Field>
                      </GridItem>
                    )}
                  </Grid>
                </GridItem>

                <GridItem>
                  <Grid cols={1}>
                    <GridItem>
                      <Field label="Получать данные из мониторинга">
                        {vehicle.receiveWialonData ? 'Да' : 'Нет'}
                      </Field>
                    </GridItem>
                    {vehicle.ptsDate && (
                      <GridItem>
                        <Field label="Дата выдачи ПТС">
                          {formatDate(vehicle.ptsDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.stsDate && (
                      <GridItem>
                        <Field label="Дата выдачи СТС">
                          {formatDate(vehicle.stsDate)}
                        </Field>
                      </GridItem>
                    )}
                    <GridItem>
                      <Field label="Подлежит ОСАГО">
                        {vehicle?.vehicleModel?.osagoNeeded ? 'Да' : 'Нет'}
                      </Field>
                    </GridItem>
                    {!!vehicle.advantumId && (
                      <GridItem>
                        <Field label="Идентификатор ТС в Адвантум">
                          {vehicle.advantumId}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                  {!!vehicle.driverQualification && (
                    <GridItem>
                      <Field label="Необходимая квалификация водителя">
                        {vehicle.driverQualification}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.vehicleModel && (
                    <>
                      {vehicle.vehicleModel.typeMdm && (
                        <GridItem>
                          <Field label="Тип ТС 1С.МДМ">
                            {vehicle.vehicleModel.typeMdm}
                          </Field>
                        </GridItem>
                      )}
                    </>
                  )}
                </GridItem>

                <GridItem>
                  <Grid cols={1}>
                    {vehicle.wialonVehicleId && (
                      <GridItem>
                        <Field label="Идентификатор ТС в мониторинге">
                          {vehicle.wialonVehicleId}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.garageNumber && (
                      <GridItem>
                        <Field label="Гаражный номер">
                          {vehicle.garageNumber}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.serialNumber && (
                      <GridItem>
                        <Field label="Серийный номер">
                          {vehicle.serialNumber ?? '-'}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.inventoryNumber && (
                      <GridItem>
                        <Field label="Инвентарный номер">
                          {vehicle.inventoryNumber}
                        </Field>
                      </GridItem>
                    )}
                    {!!vehicle.configFileName && (
                      <GridItem>
                        <Field label="Имя конфигурационного файла для АвтоГРАФ">
                          {vehicle.configFileName}
                        </Field>
                      </GridItem>
                    )}
                    {!!vehicle.mnemonicStou && (
                      <GridItem>
                        <Field label="Мнемокод ТС по СТОУ">
                          {vehicle.mnemonicStou}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </GridItem>
              </Grid>
            </SectionContent>
          </Section>
        </Element>
        <Element name="primary">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/primary`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Первичные данные
            </SectionTitle>
            <SectionContent>
              {vehicle &&
              vehicle.vehicleModel &&
              (vehicle.vehicleModel.unladenMass ||
                vehicle.vehicleModel.grossWeight ||
                vehicle.vehicleModel.axlesNumber ||
                vehicle.vehicleModel.axleLoad ||
                vehicle.vehicleModel.dimensions ||
                vehicle.vehicleModel.maximumAuthorizedMass ||
                vehicle.chassisNumber ||
                vehicle.vehicleModel.enginePower ||
                vehicle.bodyNumber ||
                vehicle.vehicleModel.passengerSeatsNumber ||
                vehicle.cabColor ||
                vehicle.vehicleModel.carrying ||
                vehicle.engineNumber ||
                vehicle.vehicleModel.engineType ||
                vehicle.vehicleModel.engineCapacity ||
                vehicle.commissioningDate ||
                vehicle.decommissioningDate ||
                vehicle.descommissionReason ||
                vehicle.class ||
                vehicle.vehicleModel.ecologicClass ||
                vehicle.basicModel ||
                vehicle.vehicleModel.category) ? (
                <Grid gutter="16px">
                  {!!vehicle.chassisNumber && (
                    <GridItem>
                      <Field label="Шасси (рама)">
                        {vehicle.chassisNumber}
                      </Field>
                    </GridItem>
                  )}

                  {!!vehicle.bodyNumber && (
                    <GridItem>
                      <Field label="Кузов (прицеп)">{vehicle.bodyNumber}</Field>
                    </GridItem>
                  )}
                  {!!vehicle.cabColor && (
                    <GridItem>
                      <Field label="Цвет кузова кабины">
                        {vehicle.cabColor}
                      </Field>
                    </GridItem>
                  )}
                  {vehicle.vehicleModel && (
                    <>
                      {!!vehicle.vehicleModel.grossWeight && (
                        <GridItem>
                          <Field label="Полная масса ТС">
                            {vehicle.vehicleModel.grossWeight} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.unladenMass && (
                        <GridItem>
                          <Field label="Масса ТС без нагрузки">
                            {vehicle.vehicleModel.unladenMass} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.axlesNumber && (
                        <GridItem>
                          <Field label="Количество осей">
                            {vehicle.vehicleModel.axlesNumber}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.axleLoad && (
                        <GridItem>
                          <Field label="Нагрузка на ось (перед/задн.)">
                            {vehicle.vehicleModel.axleLoad} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.carrying && (
                        <GridItem>
                          <Field label="Грузоподъемность">
                            {vehicle.vehicleModel.carrying} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.dimensions && (
                        <GridItem>
                          <Field label="Габариты ТС">
                            {vehicle.vehicleModel.dimensions} м
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.maximumAuthorizedMass && (
                        <GridItem>
                          <Field label="Разрешенная максимальная масса по ПТС">
                            {vehicle.vehicleModel.maximumAuthorizedMass} кг
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.engineType && (
                        <GridItem>
                          <Field label="Тип двигателя">
                            {engineTypes[vehicle.vehicleModel.engineType]}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.engineCapacity && (
                        <GridItem>
                          <Field label="Рабочий объем двигателя">
                            {vehicle.vehicleModel.engineCapacity} куб. см
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.enginePower && (
                        <GridItem>
                          <Field label="Мощность двигателя">
                            {vehicle.vehicleModel.enginePower} л.с.
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.maxSpeed && (
                        <GridItem>
                          <Field label="Максимальная скорость">
                            {vehicle.vehicleModel.maxSpeed} км/ч
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.ecologicClass && (
                        <GridItem>
                          <Field label="Экологический класс ТС">
                            {vehicle.vehicleModel.ecologicClass}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.category && (
                        <GridItem>
                          <Field label="Категория ТС">
                            {vehicle.vehicleModel.category === 'none'
                              ? 'Не указано'
                              : vehicle.vehicleModel.category}
                          </Field>
                        </GridItem>
                      )}
                    </>
                  )}
                  {!!vehicle.engineNumber && (
                    <GridItem>
                      <Field label="Двигатель №">{vehicle.engineNumber}</Field>
                    </GridItem>
                  )}

                  {!!vehicle.commissioningDate && (
                    <GridItem>
                      <Field label="Дата принятия ТС в эксплуатацию">
                        {formatDateTimeToString(
                          vehicle.commissioningDate,
                          'DD.MM.YYYY'
                        )}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.decommissioningDate && (
                    <GridItem>
                      <Field label="Дата списания ТС из эксплуатации">
                        {formatDateTimeToString(
                          vehicle.decommissioningDate,
                          'DD.MM.YYYY'
                        )}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.descommissionReason && (
                    <GridItem>
                      <Field label="Причина списания">
                        {vehicle.descommissionReason}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.class && (
                    <GridItem>
                      <Field label="Класс ТС">{vehicle.class}</Field>
                    </GridItem>
                  )}
                  {!!vehicle.basicModel && (
                    <GridItem>
                      <Field label="Базовая модель">{vehicle.basicModel}</Field>
                    </GridItem>
                  )}
                </Grid>
              ) : (
                <Placeholder>Данные не заполнены</Placeholder>
              )}
            </SectionContent>
          </Section>
        </Element>
        {canShowGpmData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
          <Element name="gpm">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable && (
                    <StyledIcon
                      onClick={() =>
                        navigate(`/vehicles/${vehicle.id}/edit/gpm`)
                      }
                      type="edit"
                      size={16}
                    />
                  )
                }
              >
                ГПМ
              </SectionTitle>
              {vehicle.gpmType ||
              vehicle.gpmModel ||
              vehicle.gpmRegistrationNumber ||
              vehicle.gpmFactoryNumber ||
              vehicle.vehicleModel.gpmCarrying ||
              vehicle.vehicleModel.liftHeight ||
              vehicle.vehicleModel.gpmHandlingRadius ||
              vehicle.lastExpertiseDate ||
              vehicle.lastSafetyDeviceMaintenanceDate ||
              vehicle.lastExpertisePlannedDate ||
              vehicle.lastSafetyDeviceMaintenancePlannedDate ? (
                <SectionContent>
                  <Grid gutter="16px">
                    {vehicle.gpmType && (
                      <GridItem>
                        <Field label="Тип">{vehicle.gpmType}</Field>
                      </GridItem>
                    )}
                    {vehicle.gpmModel && (
                      <GridItem>
                        <Field label="Модель">{vehicle.gpmModel}</Field>
                      </GridItem>
                    )}
                    {vehicle.gpmRegistrationNumber && (
                      <GridItem>
                        <Field label="Регистрационный номер">
                          {vehicle.gpmRegistrationNumber}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.gpmFactoryNumber && (
                      <GridItem>
                        <Field label="Заводской номер">
                          {vehicle.gpmFactoryNumber}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.vehicleModel && (
                      <>
                        {parseFloat(vehicle.vehicleModel.gpmCarrying) > 0 && (
                          <GridItem>
                            <Field label="Грузоподъемность">
                              {vehicle.vehicleModel.gpmCarrying}
                            </Field>
                          </GridItem>
                        )}
                        {parseFloat(vehicle.vehicleModel.liftHeight) > 0 && (
                          <GridItem>
                            <Field label="Высота подъема">
                              {vehicle.vehicleModel.liftHeight}
                            </Field>
                          </GridItem>
                        )}
                        {parseFloat(vehicle.vehicleModel.gpmHandlingRadius) >
                          0 && (
                          <GridItem>
                            <Field label="Вылет стрелы">
                              {vehicle.vehicleModel.gpmHandlingRadius}
                            </Field>
                          </GridItem>
                        )}
                      </>
                    )}
                    {vehicle.lastExpertiseDate && (
                      <GridItem>
                        <Field label="Дата последней экспертизы">
                          {formatDate(vehicle.lastExpertiseDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.lastSafetyDeviceMaintenanceDate && (
                      <GridItem>
                        <Field label="Дата последнего ТО приборов безопасности">
                          {formatDate(vehicle.lastSafetyDeviceMaintenanceDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.lastExpertisePlannedDate && (
                      <GridItem>
                        <Field label="Планируемая дата экспертизы">
                          {formatDate(vehicle.lastExpertisePlannedDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.lastSafetyDeviceMaintenancePlannedDate && (
                      <GridItem>
                        <Field label="Планируемая дата ТО приборов безопасности">
                          {formatDate(
                            vehicle.lastSafetyDeviceMaintenancePlannedDate
                          )}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </SectionContent>
              ) : (
                <Placeholder>Данные не заполнены</Placeholder>
              )}
            </Section>
          </Element>
        )}
        <Element name="driver">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/driver`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Водитель
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Закрепленный водитель">
                    {vehicle.driverName ?? 'Водитель не назначен'}
                    <DriverInfoPopover
                      driver={vehicle.driver}
                      vehicle={vehicle}
                    />
                    <DriverLicenseExpired driver={vehicle.driver} />
                  </Field>
                </GridItem>
                {vehicle.driver && (
                  <>
                    {vehicle.driver.qualificationDocuments &&
                      vehicle.driver.qualificationDocuments.map(
                        (document, index) => (
                          <GridItem key={index}>
                            <Field
                              label={
                                driverQualifications[document.qualification]
                              }
                            >
                              {document.documentNumber}
                            </Field>
                          </GridItem>
                        )
                      )}
                  </>
                )}
              </Grid>
            </SectionContent>
          </Section>
        </Element>
        <Element name="attachment">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/attachment`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Прикрепление
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Собственник">{vehicle.ownerOrgUnitName}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Закрепление ТС">{vehicle.orgUnitName}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Служба использования">
                    {vehicle.usingNodeName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Населенный пункт базирования ТС">
                    {vehicle.location &&
                      `${vehicle.location.name} ${vehicle.location.address}`}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Место регистрации ТС">
                    {vehicle.registrationPlace}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Бригада">{vehicle.crew?.name ?? '-'}</Field>
                </GridItem>
              </Grid>
            </SectionContent>
          </Section>
        </Element>
        <Element name="accountant">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/accountant`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Бухгалтерские данные
            </SectionTitle>
            <SectionContent>
              {isAccountantEmpty(vehicle) ? (
                <Placeholder>Данные не заполнены</Placeholder>
              ) : (
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Первоначальная стоимость">
                      {toLocalString(vehicle.initialCost)}
                      &nbsp;
                      {vehicle.initialCost !== null && '₽ (Без НДС)'}
                    </Field>
                  </GridItem>

                  <GridItem>
                    <Field label="Остаточная стоимость">
                      {toLocalString(vehicle.residualValue)}
                      &nbsp;
                      {vehicle.residualValue !== null && '₽ (Без НДС)'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата обновления остаточной стоимости">
                      {formatDateTimeToString(
                        vehicle.residualValueUpdateDate,
                        'DD.MM.YYYY'
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Восстановительная стоимость">
                      {toLocalString(vehicle.restoreValue)}
                      &nbsp;
                      {vehicle.restoreValue !== null && '₽ (Без НДС)'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата принятия к бухгалтерскому учету">
                      {formatDateTimeToString(
                        vehicle.accountingRegistrationDate,
                        'DD.MM.YYYY'
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата списания ТС с бухгалтерского учета">
                      {formatDateTimeToString(
                        vehicle.accountingDeregistrationDate,
                        'DD.MM.YYYY'
                      )}
                    </Field>
                  </GridItem>
                </Grid>
              )}
            </SectionContent>
          </Section>
        </Element>
      </>
    );
  }
}
