// @flow

import type { OperationLimitGroup } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromHourToSecond, convertFromSecondToHour } from '../helpers';
import { convertFromServer as convertOperationLimitFromServer } from './operationLimit';

const convertDataFromServer = (group: OperationLimitGroup) => ({
  ...group,
  hours: convertFromSecondToHour(parseFloat(group.hours)),
  hoursWeekend: convertFromSecondToHour(parseFloat(group.hoursWeekend)),
  operationLimits: group.operationLimits.map(convertOperationLimitFromServer)
});

const convertDataToServer = (group: OperationLimitGroup) => ({
  ...group,
  hours: convertFromHourToSecond(group.hours),
  hoursWeekend: convertFromHourToSecond(group.hoursWeekend)
});

export const addOperationLimitGroup = async (
  operationLimitGroup: OperationLimitGroup
): Promise<OperationLimitGroup> => {
  const added = await fetchRequest.post(
    '/operationLimitGroup',
    convertDataToServer(operationLimitGroup)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось добавить группу регламента для должности');
};

export const updateOperationLimitGroup = async (
  operationLimitGroup: OperationLimitGroup
): Promise<OperationLimitGroup> => {
  const updated = await fetchRequest.put(
    '/operationLimitGroup',
    convertDataToServer(operationLimitGroup)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить группу регламента для должности');
};
export const deleteOperationLimitGroup = async (
  id: number
): Promise<OperationLimitGroup> => {
  const deleted = await fetchRequest.delete(`/operationLimitGroup/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить группу регламента для должности');
};

export const fetchOperationLimitGroup = async (
  id: number,
  params: any = {}
): Promise<OperationLimitGroup> => {
  const operationLimitGroup = await fetchRequest.get(
    `/operationLimitGroup/${id}`,
    params
  );
  if (operationLimitGroup) return convertDataFromServer(operationLimitGroup);
  throw new Error('Не удалось получить группу регламента для должности');
};

export const fetchOperationLimitGroups = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<OperationLimitGroup>> => {
  const operationLimitGroups = await fetchRequest.get('/operationLimitGroup/', {
    ...initialFetchListParams,
    ...params,
    orderBy: 'created',
    byDescending: false
  });
  if (operationLimitGroups) {
    operationLimitGroups.data = operationLimitGroups.data.map(
      convertDataFromServer
    );
    return operationLimitGroups;
  }
  throw new Error('Не удалось получить список групп регламентов для должности');
};

export default {
  addOperationLimitGroup,
  updateOperationLimitGroup,
  deleteOperationLimitGroup,
  fetchOperationLimitGroup,
  fetchOperationLimitGroups
};
