// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { userApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { User } from '../../lib/types';

type Props = SelectProps & {
  showFio?: boolean
};

export default ({ showFio, ...props }: Props) => (
  <AutocompleteSelect
    placeholder="Выберите пользователя"
    fetch={userApi.fetchUsers}
    fetchSingle={userApi.fetchUser}
    notFoundText="Пользователи не найдены"
    renderOption={(user: User, Option) => (
      <Option value={user.id} key={user.id} user={user}>
        {showFio
          ? `${user.employee.lastname} ${user.employee.firstname} ${
              user.employee.middlename
            }`
          : user.userName}
      </Option>
    )}
    {...props}
  />
);
