// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type { UserAccess, VehicleModel, VehicleType } from './../../lib/types';
import { accessTypeEnum, vehicleTypes } from '../../lib/enum';

import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import type { sortOrder } from '../../components/ui/Table';
import { notificationLoading } from './../../components/Notifications';

import { fetchVehicleModels } from '../../ducks/vehicleModels';
import { deleteVehicleModel } from '../../ducks/vehicleModel';
import type { AppState } from '../../ducks/redux';
import Filter from './Filter';

type Props = {
  userAccess: UserAccess[],
  models: Array<VehicleModel>,
  fetchVehicleModels: Function,
  deleteVehicleModel: Function,
  totalCount: number,
  pageSize: number,
  page: number,
  location: Location & { state: { page: number } },
};

type State = {
  columns: Array<Object>,
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class VehicleModelList extends PureComponent<Props, State> {
  static defaultProps = {
    location: {},
    userAccess: [],
  };
  state = {
    columns: [
      {
        title: '№',
        dataIndex: 'id',
        sorter: true,
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Марка',
        dataIndex: 'brandName',
        sorter: true,
        sorterKey: 'brand.name',
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        sorter: true,
        render: (type: VehicleType) => vehicleTypes[type],
      },
      {
        title: 'Количество ТС',
        dataIndex: 'vehicleCount',
      },
    ],
  };

  componentDidMount() {
    const { columns } = this.state;
    if (this.canAdd()) {
      this.setState({
        columns: [
          ...columns,
          {
            title: '',
            width: 30,
            dataIndex: 'operations',
            // eslint-disable-next-line no-unused-vars
            render: (text: string, model: VehicleModel) => (
              <Operations>
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => this.deleteVehicleModel(model.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              </Operations>
            ),
          },
        ],
      });
    }
  }

  fetchVehicleModels = async (
    page: number,
    orderBy?: string,
    order?: sortOrder
  ) =>
    await this.props.fetchVehicleModels(page, {
      orderBy,
      byDescending: order === 'descend',
    });

  async deleteVehicleModel(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await this.props.deleteVehicleModel(id);
      await this.fetchVehicleModels(this.props.page);
      notification.success({
        message: 'Успешное удаление',
        description: `Модель с идентификатором ${id} была успешно удалена`,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  handleRowClick = (id: number) => navigate(`/vehicle-models/${id}`);

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBrandsAndVehicleModels,
      ].includes(access)
    );

  render() {
    const { models, totalCount, pageSize, page, location } = this.props;
    const { columns } = this.state;
    return (
      <>
        <Header
          left={<h1>Модели ТС</h1>}
          right={
            this.canAdd() && (
              <Link to="/vehicle-models/new">
                <Button type="primary" data-cy="addModel">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter />
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            fetch={this.props.fetchVehicleModels}
            columns={columns}
            data={models.map((model) => ({
              ...model,
              key: model.id,
            }))}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userAccess: state.auth.profile.access,
    models: state.vehicleModels.data,
    totalCount: state.vehicleModels.totalCount,
    pageSize: state.vehicleModels.pageSize,
    page: state.vehicleModels.page,
  }),
  {
    fetchVehicleModels,
    deleteVehicleModel,
  }
)(VehicleModelList);
