// @flow
import React from 'react';
import InputMask from 'react-input-mask';
import { InputProps } from 'antd/lib/input';
import Input from 'antd/lib/input';

type Props = InputProps & {
  /**
   * Маска
   * Указывается в виде строкового шаблона
   * Например, я 999 яя 999 - Описывает шаблон для гос. номеров
   * где я - это символ из [а-яА-Я], 9 - [0-9].
   * (см. initialFormatChars для просмотра заранее заданных шаблонных символов)
   *
   * Для расширения или переопределения таблицы шаблонных символов используется
   * свойство formatChars.
   */
  mask: string,
  /**
   * Символ, используемый в незаполненных позициях маски
   * @default _
   */
  maskChar?: string,
  /**
   * Функция преобразования при вводе
   * Например, приведение к заглавному написанию:
   * transform={(value: string) => value.toUpperCase()}
   * @param value Введенное значение
   */
  transform?: (value: string) => string,
  /**
   * Массив преобразований при вводе
   * Если необходимо последовательное преобразование
   */
  transformers?: ((value: string) => string)[],
  /**
   * Преобразовать в заглавные
   * @default false
   */
  upperCase?: boolean,
  /**
   * Преобразовать в строчные
   * @default false
   */
  lowerCase?: boolean,
  /**
   * Показывать маску?
   * @default true
   */
  showMask?: boolean,
  /**
   * Таблица подстановочных символов
   * с соответствующими им регулярками в виде строки
   * Например, нам нужен шаблон: я 555 яяя,
   * где 5 - это число [1-5], я - буква [к-я]
   * Тогда formatChars:
   * {
   *   '5': '[1-5]',
   *   'я': '[к-я]'
   * }
   */
  formatChars?: {
    [key: string]: string,
  },
  /**
   * Преобразование маски в зависимости от ввода
   * @return Маска
   */
  formatMask?: (value: string) => string,
  /**
   * Преобразование значения при сохранении в модель
   * Например, значение, которое в маске представляется как А 111 АА 116
   * можно сохранить как А111АА116:
   * (value: string) => value.replace(/\s+/g, '')
   * @param value Преобразованное значение
   */
  convertOnSave?: (value: string) => string,
};

type State = {
  value: ?string,
  mask: string,
  // Указание на то, что маска поменялась из-за вызова formatMask
  maskFormatted: boolean,
};

/**
 * Символы в маске
 * Все символы указывать в латинской раскладке
 */
const initialFormatChars = {
  '9': '[0-9]', // числа от 0 до 9
  l: '[A-Za-z]', // latin
  '*': '[A-Za-zА-Яа-я0-9]', // любые буквы и числа
  c: '[А-Яа-я]', // cyrillic
};

type InputMaskState = {
  value: string,
  selection: {
    start: number,
    end: number,
  },
};

export default class MaskInput extends React.Component<Props, State> {
  state = {
    value: null,
    mask: this.props.mask,
    maskFormatted: false,
  };

  static defaultProps = {
    maskChar: '_',
    formatChars: {},
    showMask: true,
    disabled: false,
  };

  input: ?HTMLInputElement;

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    let {
      target: { value, name },
    } = event;
    const { onChange, maskChar, convertOnSave } = this.props;
    /**
     * Удаляем maskChar из value
     * чтобы в значение модели не попадали символы маски
     */
    let clearedValue = value.replace(new RegExp(`${maskChar}`, 'g'), '');
    if (convertOnSave) {
      clearedValue = convertOnSave(clearedValue);
    }
    this.formatMaskByValue(clearedValue);
    this.setState({ value });
    if (onChange)
      onChange({
        ...event,
        target: { ...event.target, value: clearedValue, name },
      });
  };

  /**
   * Форматирование маски на основе значения
   */
  formatMaskByValue = (value: string) => {
    const { formatMask, mask } = this.props;
    // Если задана функция форматирования маски
    if (formatMask) {
      const formattedMask = formatMask(value);
      // если функция форматирования вернула undefined
      if (!formattedMask) {
        // то, применяем маску из пропсов
        this.setState({ mask, maskFormatted: false });
      } else if (formattedMask !== this.state.mask) {
        // иначе меняем на новую маску
        this.setState({ mask: formattedMask, maskFormatted: true });
      }
    }
  };

  /**
   * Функция, вызываемая перед применением
   * маски в введенному значению
   */
  beforeMaskedValueChange = (newState: InputMaskState) => {
    const { transform, transformers, upperCase, lowerCase } = this.props;
    let { value } = newState;
    const { selection } = newState;

    // Преобразуем значение с помощью transform
    if (transformers) {
      transformers.forEach((transform) => {
        value = transform(value);
      });
    } else if (transform) {
      value = transform(value);
    }

    if (upperCase) {
      value = value.toUpperCase();
    } else if (lowerCase) {
      value = value.toLowerCase();
    }

    return {
      value,
      selection,
    };
  };

  componentDidMount() {
    this.setState({ value: this.props.value });
  }

  componentWillUnmount() {
    this.setState({ value: null });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { mask, value, formatMask } = this.props;
    // Инициализация состояния значением из props
    if (this.state.value === undefined && !!value) {
      this.formatMaskByValue(value);
      this.setState({ value });
    }

    if (prevProps.value !== value) {
      this.setState({ value });
    }
    /**
     * Если задана функция форматирования маски
     */
    if (formatMask) {
      /**
       * Меняем маску только если маска не менялась с помощью formatMask
       */
      if (!this.state.maskFormatted && mask !== this.state.mask) {
        this.setState({ mask });
      }
    } else {
      /**
       * Если маска из пропсов поменялась
       */
      if (mask !== this.state.mask) {
        this.setState({ mask });
      }
    }
  }

  render() {
    const {
      formatChars,
      showMask,
      upperCase,
      lowerCase,
      convertOnSave,
      formatMask,
      transform,
      transformers,
      ...props
    } = this.props;
    const { mask } = this.state;
    return (
      <InputMask
        {...props}
        value={this.state.value || ''}
        mask={mask}
        formatChars={{ ...initialFormatChars, ...formatChars }}
        alwaysShowMask={showMask}
        onChange={this.handleChange}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
        autoComplete="off"
      >
        {(inputProps) => <Input {...inputProps} />}
      </InputMask>
    );
  }
}
