// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { Element, scroller } from 'react-scroll';
import { navigate } from '@reach/router';
import orderBy from 'lodash/orderBy';

import type { Vehicle } from '../../../../lib/types';
import { Section } from '../../../../components/layout';
import { Icon } from '../../../../components/ui';
import SectionTitle from '../SectionTitle';
import { fileTagEnum, fileTags } from '../../../../lib/enum';
import { prepareSliderItem } from '../../lib';
import Slider from '../../../../components/ui/Slider';

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Placeholder = styled.div`
  text-align: center;
  padding: 16px;
  color: #838d96;
`;

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  anchor: ?string
};

export default class FilesTab extends Component<Props> {
  componentDidMount() {
    if (this.props.anchor) {
      scroller.scrollTo(this.props.anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }

  render() {
    const { vehicle, editable }: Props = this.props;
    return (
      <>
        {[fileTagEnum.mainDocs, fileTagEnum.osago, fileTagEnum.repairActs].map(
          (fileTag, index) => (
            <Element key={index} name="files/mainDocs">
              <Section>
                <SectionTitle
                  divider
                  suffix={
                    editable && (
                      <StyledIcon
                        onClick={() =>
                          navigate(
                            `/vehicles/${vehicle.id}/edit/files/${fileTag}`
                          )
                        }
                        type="edit"
                        size={16}
                      />
                    )
                  }
                >
                  {fileTags[fileTag]}
                </SectionTitle>
                {vehicle && (
                  <SectionContent>
                    {vehicle.files &&
                      (vehicle.files.length === 0 ? (
                        <Placeholder>
                          {fileTags[fileTag]} отсутствуют
                        </Placeholder>
                      ) : (
                        <Slider
                          tableView
                          items={orderBy(vehicle.files, 'createdTime', ['desc'])
                            .filter(item => item.tag === fileTag)
                            .map(prepareSliderItem)}
                        />
                      ))}
                  </SectionContent>
                )}
              </Section>
            </Element>
          )
        )}
      </>
    );
  }
}
