// @flow
import React, { useState, useEffect } from 'react';
import Input from 'antd/lib/input';
import InputMask from 'react-input-mask';
import Button from 'antd/lib/button';
import styled from 'styled-components';

import type { FormikProps } from 'formik';
import * as yup from 'yup';
import type { moment } from 'moment';
import { DatePicker } from '../../../components/ant/DatePicker';

import { isVehicleTractor } from '../../../lib/helpers';
import { vehiclePtsTypeEnum } from '../../../lib/enum';
import type { Vehicle } from '../../../lib/types';
import {
  Selects,
  OrgUnitSelect,
  Form,
  CancelButton,
} from '../../../components';
import { Section, SectionTitle } from '../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { InputNumber } from '../../../components/inputs';
import {
  LicensePlateInput,
  VINInput,
  STSInput,
  PTSInput,
  OsagoInput,
  EPTSInput,
} from '../../../components/inputs/masked-inputs';
import {
  licensePlateValidate,
  osagoValidate,
  ptsValidate,
  stsValidate,
  vinValidate,
  eptsValidate,
} from '../../../lib/validation';
import {
  getDisabledDate,
  canShowWithTrailer,
  canShowOsagoData,
  canShowGpmData,
  canShowEngineWorkHoursData,
} from '../lib';

import VehicleDriverSelect from './VehicleDriverSelect';

const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const {
  VehicleModelSelect,
  YearIssueSelect,
  YesNoSelect,
  ContractorSelect,
  LocationSelect,
  CrewSelect,
  VehiclePtsTypeSelect,
} = Selects;

type Props = {
  vehicle: Vehicle,
  onSubmit: Function,
  onCancel: Function,
  changeDate: Function,
  formatDate: Function,
  employeeBranchOrgUnitId: number,
  sectionStyle?: any,
  hideFields?: string[],
};

export default ({
  onSubmit,
  vehicle,
  onCancel,
  changeDate,
  formatDate,
  sectionStyle = {},
  hideFields = [],
  employeeBranchOrgUnitId,
}: Props) => {
  const [vehiclePts, setVehiclePts] = useState(vehiclePtsTypeEnum.paper);
  useEffect(() => {
    setVehiclePts(
      vehicle?.epts ? vehiclePtsTypeEnum.electronic : vehiclePtsTypeEnum.paper
    );
  }, [vehicle]);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={vehicle}
      validationSchema={yup.object().shape({
        licensePlate: licensePlateValidate.nullable(),
        'osago.osagoNumber': yup
          .string()
          .when('vehicleModel.type', (vehicleType, schema) =>
            canShowOsagoData(vehicleType) ? osagoValidate.nullable() : schema
          ),
        vin: vinValidate.nullable(),
        sts: stsValidate.nullable(),
        pts: ptsValidate.nullable(),
        epts: eptsValidate.nullable(),
      })}
    >
      {(FormField, formikProps: FormikProps) => {
        let {
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          handleFocus,
          values,
          isSubmitting,
          dirty,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section style={sectionStyle}>
              <SectionTitle>Первичные данные</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem style={{ overflow: 'hidden' }}>
                  <FormField
                    fast
                    label="Модель ТС"
                    name="vehicleModelId"
                    required
                  >
                    {({ value, name }) => (
                      <VehicleModelSelect
                        value={value}
                        name={name}
                        onFocus={handleFocus}
                        data-cy="modelSelect"
                        onChange={async (value: number, option: Object) => {
                          setFieldValue(name, value);
                          if (option) {
                            setFieldValue(
                              'vehicleModel',
                              option.props.vehicleModel
                            );
                          } else {
                            setFieldValue('vehicleModel', null);
                          }
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Год выпуска"
                    name="yearIssued"
                    required
                  >
                    {({ value, name }) => (
                      <YearIssueSelect
                        id="yearIssued"
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={() => handleBlur({ target: { name } })}
                        data-cy="yearIssued"
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>
              <SectionTitle>Номера</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    label="Гос. номер"
                    name="licensePlate"
                    required={!values.unregistered}
                  >
                    {({ value, name }) => (
                      <LicensePlateInput
                        name={name}
                        value={value}
                        vehicleType={
                          values.vehicleModel && values.vehicleModel.type
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="VIN"
                    name="vin"
                    required={!values.serialNumber && !values.unregistered}
                  >
                    {({ value, name }) => (
                      <VINInput
                        name={name}
                        placeholder="1B6HL26Y0RW143905"
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Серийный номер"
                    name="serialNumber"
                    required={!values.unregistered && !values.vin}
                  >
                    {(field) => (
                      <Input {...field} placeholder="Введите серийный номер" />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Гаражный номер" name="garageNumber">
                    {({ value, name }) => (
                      <Input
                        placeholder="Введите гаражный номер"
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {canShowGpmData(
                  values.vehicleModel && values.vehicleModel.type
                ) && (
                  <>
                    <GridItem>
                      <FormField
                        label="Регистрационный номер подъемного сооружения"
                        required={!values.unregistered}
                        name="gpmRegistrationNumber"
                      >
                        {(field) => <Input {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Заводской номер ГПМ"
                        name="gpmFactoryNumber"
                        required={!values.unregistered}
                      >
                        {(field) => <Input {...field} />}
                      </FormField>
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <FormField
                    label="Серия и номер СТС"
                    name="sts"
                    required={!values.unregistered}
                  >
                    {({ value, name }) => (
                      <STSInput
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата выдачи СТС"
                    name="stsDate"
                    required={!values.unregistered}
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        data-cy="stsDate"
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(name, changeDate(value, dateString));
                        }}
                        disabledDate={(currentDate: moment) =>
                          getDisabledDate(currentDate)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Тип ПТС">
                    {() => (
                      <VehiclePtsTypeSelect
                        value={vehiclePts}
                        onChange={(value) => {
                          setVehiclePts(value);
                          if (value === vehiclePtsTypeEnum.paper) {
                            setFieldValue('epts', null);
                          } else {
                            setFieldValue('pts', null);
                          }
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem />
                {vehiclePts === vehiclePtsTypeEnum.paper ? (
                  <GridItem>
                    <FormField
                      fast
                      label="Серия и номер ПТС"
                      name="pts"
                      required={!values.unregistered}
                    >
                      {({ value, name }) => (
                        <PTSInput
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                ) : (
                  <GridItem>
                    <FormField
                      fast
                      label="ЭПТС"
                      name="epts"
                      required={!values.unregistered}
                    >
                      {({ value, name }) => (
                        <EPTSInput
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    label="Дата выдачи ПТС"
                    name="ptsDate"
                    required={!values.unregistered}
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        data-cy="ptsDate"
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(name, changeDate(value, dateString));
                        }}
                        disabledDate={(currentDate: moment) =>
                          getDisabledDate(currentDate)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цвет кузова кабины" name="cabColor" fast>
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Идентификатор ТС в мониторинге"
                    name="wialonVehicleId"
                    required={values.receiveWialonData}
                    fast
                  >
                    {(field) => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Получать данные из мониторинга"
                    name="receiveWialonData"
                    fast
                  >
                    {({ value, name }) => (
                      <YesNoSelect
                        value={value}
                        onChange={(value) => {
                          if (!!value && !!values.receiveGlonassData) {
                            setFieldValue('receiveGlonassData', false);
                          }
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>
              <SectionTitle>Признаки ТС</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField label="ТС с прицепом" name="withTrailer">
                    {({ value, name }) => (
                      <YesNoSelect
                        value={value}
                        name={name}
                        onChange={(value: boolean) =>
                          setFieldValue(name, value)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>
              <SectionTitle>Прикрепление</SectionTitle>
              <StyledGrid gutter="16px">
                {!hideFields.includes('stsType') && (
                  <GridItem style={{ overflow: 'hidden' }}>
                    <FormField
                      fast
                      label="Закрепление ТС"
                      required
                      name="orgUnitId"
                    >
                      {({ value, name }) => (
                        <OrgUnitSelect
                          value={value ? value : ''}
                          onChange={(value: number) => {
                            const id = parseInt(value, 10);
                            setFieldValue(name, id);
                          }}
                          data-cy="orgUnitSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    label="Место регистрации ТС"
                    name="registrationPlace"
                    required
                  >
                    {(fieldProps) => <Input {...fieldProps} />}
                  </FormField>
                </GridItem>
                <GridItem style={{ overflow: 'hidden' }}>
                  <FormField
                    fast
                    label="Населенный пункт базирования ТС"
                    required
                    name="locationId"
                  >
                    {({ value, name }) => (
                      <LocationSelect
                        placeholder="Выберите место стоянки"
                        onChange={(value: string) => setFieldValue(name, value)}
                        onFocus={handleFocus}
                        geolocationApi={false}
                        name={name}
                        filter={{
                          isDefault: true,
                          nodeId: employeeBranchOrgUnitId,
                          nodeFilterType: 'branchAndChildren',
                        }}
                        value={value}
                        data-cy="locationSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Служба использования"
                    name="usingNodeId"
                    required
                  >
                    {({ name, value }) => (
                      <OrgUnitSelect
                        onChange={(value) => setFieldValue(name, value)}
                        value={value}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem style={{ overflow: 'hidden' }}>
                  <FormField fast name="crewId" label="Бригада">
                    {({ value, name }) => (
                      <CrewSelect
                        placeholder="Выберите бригаду"
                        onChange={(value: string) => setFieldValue(name, value)}
                        value={value}
                        name={name}
                        data-cy="crewSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem style={{ overflow: 'hidden' }}>
                  <FormField fast name="driverId" label="Водитель">
                    {({ value, name }) => (
                      <VehicleDriverSelect
                        vehicle={null}
                        onChange={(value: string) => setFieldValue(name, value)}
                        value={value}
                        name={name}
                        data-cy="driverSelect"
                        filter={{
                          'employee.nodeId': employeeBranchOrgUnitId,
                          nodeFilterType: 'branchAndChildren',
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>

              {canShowOsagoData(
                values.vehicleModel && values.vehicleModel.type
              ) && (
                <>
                  <SectionTitle>ОСАГО</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Полис ОСАГО"
                        required={!values.unregistered}
                        name="osago.osagoNumber"
                      >
                        {({ value, name }) => (
                          <OsagoInput
                            value={value}
                            name={name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <Grid gutter="16px">
                        <GridItem>
                          <FormField
                            label="Дата начала действия"
                            required={!values.unregistered}
                            name="osago.osagoStartedDate"
                          >
                            {({ value, name }) => (
                              <DatePicker
                                format="DD.MM.YYYY"
                                value={formatDate(value)}
                                data-cy="osagoStartDate"
                                onChange={(
                                  value: Object,
                                  dateString: string
                                ) => {
                                  setFieldValue(
                                    name,
                                    changeDate(value, dateString)
                                  );
                                }}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            label="Дата окончания действия"
                            required={!values.unregistered}
                            name="osago.osagoFinishedDate"
                          >
                            {({ value, name }) => (
                              <DatePicker
                                format="DD.MM.YYYY"
                                value={formatDate(value)}
                                data-cy="osagoEndDate"
                                onChange={(
                                  value: Object,
                                  dateString: string
                                ) => {
                                  setFieldValue(
                                    name,
                                    changeDate(value, dateString)
                                  );
                                }}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      </Grid>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Диагностическая карта"
                        name="osago.diagnosticCardNumber"
                      >
                        {(field) => <Input {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Наименование страховщика"
                        required={!values.unregistered}
                        name="osago.contractorId"
                      >
                        {({ value, name }) => (
                          <ContractorSelect
                            name={name}
                            value={value}
                            onChange={(id: number) => setFieldValue(name, id)}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </StyledGrid>
                </>
              )}

              <SectionTitle>Пробег</SectionTitle>
              <StyledGrid gutter="16px" cols={5}>
                <GridItem>
                  <FormField
                    fast
                    label="Текущий пробег, км"
                    required
                    name="kilometrage"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        decimalSeparator=","
                        step={0.01}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Текущий пробег по ПЛ, км"
                    name="waybillKilometrage"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        decimalSeparator=","
                        step={0.01}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Пробег ТС на 1 Января текущего года, км"
                    name="beginningOfTheYearKilometrage"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        decimalSeparator=","
                        precision={2}
                        step={0.01}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата прохождения ТО-1" name="lastTo1Date">
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        data-cy="lastTo1Date"
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(name, changeDate(value, dateString));
                        }}
                        disabledDate={(currentDate: moment) =>
                          currentDate.isSameOrAfter(new Date())
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата прохождения ТО-2" name="lastTo2Date">
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        data-cy="lastTo2Date"
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(name, changeDate(value, dateString));
                        }}
                        disabledDate={(currentDate: moment) =>
                          currentDate.isSameOrAfter(new Date())
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                {canShowEngineWorkHoursData(
                  values.vehicleModel && values.vehicleModel.type
                ) && (
                  <GridItem>
                    <FormField
                      label="Показания счётчика моточасов, ч"
                      name="engineWorkHours"
                      defaultValue={0}
                      required={isVehicleTractor(values)}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          decimalSeparator=","
                          step={0.1}
                          precision={1}
                          value={value}
                          name={name}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </StyledGrid>
            </Section>
            <Footer>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                data-cy="save"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
