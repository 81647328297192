// @flow
import React, { useState, type Node, useEffect } from 'react';
import { navigate } from '@reach/router';

import {
  StyledBadge,
  StyledTabItem,
  TabContent,
  TabItems,
  Wrapper,
} from './Tabs.elements';
import { TabItem } from './TabItem';
import { hasExactMatch } from './lib';

type TabsProps = {
  /**
   * Ключ открытой по-умолчанию вкладки
   */
  defaultTab?: number | string,
  children: Node,
  rightElement?: Node,
  /**
   * Функция, вызываемая при смене вкладки
   */
  onChange?: (tabKey?: number | string) => any,
  /**
   * Фон у вкладок
   */
  background?: string,
  className?: string,
  type?: 'buttons' | 'primary',
  /**
   * Принудительный перерендер содержимого вкладок
   */
  forceRender?: boolean,
  /**
   * Синхронизирует tabKey и последнюю часть адреса в пути страницы
   *
   * Например, если tabKey=month, а адрес страницы /daily-budget/month
   * то такой таб будет выделяться как активный
   */
  withRouter?: boolean,
};

/**
 * Компонент вкладок
 */
const Tabs = ({
  defaultTab,
  children,
  background,
  className,
  type = 'primary',
  forceRender,
  rightElement,
  onChange,
  withRouter,
}: TabsProps) => {
  const exactMatch = withRouter && hasExactMatch(children);
  const [currentTab, setCurrentTab] = useState(
    // Когда используем синхронизацию с адресом,
    // то как ключ берем pathname из адреса
    withRouter ? window.location.pathname : defaultTab
  );

  useEffect(() => setCurrentTab(defaultTab), [defaultTab]);

  const changeTab = (key: string) => {
    setCurrentTab(key);
    if (onChange) {
      onChange(key);
    }
  };
  /**
   * Функция для разбора вкладок
   */
  const parseChildrens = (children: Node) => {
    const tabItems = [],
      tabContents = [];
    React.Children.forEach(children, (child, index) => {
      if (child && child.type === TabItem) {
        const {
          label,
          badge,
          children,
          onClick,
          tabKey,
          url,
          activeUrl,
        } = child.props;
        let active = false;
        if (withRouter) {
          // Если в списке табов есть полное совпадение url с pathname
          if (exactMatch) {
            if (Array.isArray(activeUrl)) {
              active = activeUrl.includes(window.location.pathname);
            } else active = window.location.pathname === url;
          } else {
            if (Array.isArray(activeUrl)) {
              active = activeUrl.includes(window.location.pathname);
            } else active = window.location.pathname.includes(url);
          }
        } else {
          // Если не указали таб по-умолчанию, то делаем активным самый первый
          active = currentTab ? tabKey === currentTab : index === 0;
        }
        tabItems.push({
          tabKey,
          label,
          badge,
          active,
          onClick,
          url,
        });
        tabContents.push({
          tabKey,
          url,
          children,
          active,
        });
      }
    });
    return {
      tabItems,
      tabContents,
    };
  };

  const { tabContents, tabItems } = parseChildrens(children);
  const contents = forceRender
    ? tabContents.filter((content) => content.active)
    : tabContents;
  return (
    <Wrapper className={className} type={type}>
      <TabItems background={background} type={type}>
        {tabItems.map((tabItem) => {
          const { active, tabKey, onClick, badge, label, url } = tabItem;
          const key = withRouter ? url : tabKey;
          return (
            <>
              <StyledTabItem
                active={active}
                tabKey={key}
                type={type}
                key={key}
                onClick={() => {
                  if (!active) {
                    onClick && onClick(key);
                    changeTab(key);
                    withRouter && navigate(url);
                  }
                }}
              >
                {label}
                {badge && <StyledBadge>{badge}</StyledBadge>}
              </StyledTabItem>
            </>
          );
        })}
        {rightElement && (
          <div style={{ marginLeft: 'auto' }}>{rightElement}</div>
        )}
      </TabItems>
      {contents.map((content) => (
        <TabContent
          path={content.tabKey || content.url}
          key={content.tabKey || content.url}
          active={content.active}
        >
          {content.children}
        </TabContent>
      ))}
    </Wrapper>
  );
};

export default Tabs;
