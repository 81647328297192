// @flow

import React, { PureComponent } from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';

import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import type { Company, ListState, UserAccess } from '../../lib/types';
import { accessTypeEnum } from '../../lib/enum';
import Header from '../../components/layout/Header';
import { companyApi } from '../../lib/api';
import qs from 'query-string';
import type { FetchListParams } from '../../lib/api';
import { getListInitialState, setQueryParams } from '../../lib/helpers';
import { notificationLoading } from './../../components/Notifications';
import { withUserAccess } from './../withUserAccess';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};

type State = ListState<Company>;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export class CompaniesList extends PureComponent<Props, State> {
  state = getListInitialState();

  async deleteCompany(id: number) {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await companyApi.deleteCompany(id);
      notification.success({
        message: 'Успешное удаление',
        description: `Юридическое лицо с идентификатором ${id} было успешно удалено`,
      });
      await this.fetchCompanies(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      sorter: true,
    },
  ];

  handleRowClick = (id: number) => navigate(`/admin/companies/${id}`);

  fetchCompanies = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    this.setState({ loading: true });
    const { data, totalCount } = await companyApi.fetchCompanies({
      page,
      ...{ ...params, isArchived: false },
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  canEditing = () =>
    this.props.userAccess.some((access) =>
      [accessTypeEnum.admin, accessTypeEnum.handlingCompanies].includes(access)
    );

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    await this.fetchCompanies(page);
  }

  render() {
    const { location } = this.props;
    const { data, page, totalCount, pageSize, loading } = this.state;
    const canEdit = this.canEditing();
    return (
      <>
        <Header
          left={<h1>Контрагенты</h1>}
          right={
            canEdit && (
              <Link to="/admin/companies/new">
                <Button type="primary" data-cy="add">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            columns={
              canEdit
                ? [
                    ...this.columns,
                    {
                      title: '',
                      dataIndex: 'operations',
                      // eslint-disable-next-line no-unused-vars
                      render: (text: string, model: Company) => (
                        <Operations>
                          <Popconfirm
                            title="Вы действительно хотите удалить?"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={() => this.deleteCompany(model.id)}
                          >
                            <StyledIcon type="x" />
                          </Popconfirm>
                        </Operations>
                      ),
                    },
                  ]
                : this.columns
            }
            loading={loading}
            data={data.map((model) => ({
              ...model,
              key: model.id,
            }))}
            fetch={this.fetchCompanies}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(CompaniesList);
