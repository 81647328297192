const arrayToObject = (obj, val) => {
  obj[val] = val;
  return obj;
};

const tireDiameterArray = [
  '12R',
  '12.5R',
  '13R',
  '14R',
  '14.5R',
  '15R',
  '16R',
  '16.9R',
  '17R',
  '18R',
  '19R',
  '19.5R',
  '20R',
  '21R',
  '22R',
  '22.5R',
  '23R',
  '24R',
  '38R',
  '12C',
  '13C',
  '14C',
  '15C',
  '16C',
  '16LT',
  '17C',
];
export const tireDiameterEnum = tireDiameterArray.reduce(arrayToObject, {});

export const tireWidthEnum = [
  '-',
  155,
  165,
  175,
  185,
  195,
  196,
  205,
  215,
  225,
  235,
  245,
  255,
  265,
  275,
  285,
  295,
  305,
  308,
  315,
  325,
  335,
  345,
  355,
  430,
].reduce(arrayToObject, {});

export const tireProfileEnum = [
  '-',
  20,
  25,
  28,
  30,
  35,
  40,
  45,
  50,
  55,
  60,
  65,
  70,
  75,
  80,
  85,
].reduce(arrayToObject, {});
