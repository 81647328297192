import { accessTypeEnum } from './../../lib/enum';

const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];

export const addContractAccess = [
  ...adminAccess,
  accessTypeEnum.handlingContracts
];
export const changeStatusAccess = [
  ...adminAccess,
  accessTypeEnum.approvingContracts
];
export const approvingStatusAccess = [
  ...adminAccess,
  accessTypeEnum.approvingContracts
];

export default {
  addContractAccess,
  changeStatusAccess,
  approvingStatusAccess
};
