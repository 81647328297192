// @flow
import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';

import { pddTestApi } from '../../../lib/api';
import type { PddGroup, PddTest, UserAccess } from '../../../lib/types';
import { accessTypeEnum, pddTest } from '../../../lib/enum';

import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from '../../withUserAccess';
import { ReloginButton } from '../Test/Training/components';
import Filter from './components/FilterTraining';
import HeaderTabs from './components/HeaderTabs';
import ButtonPrintDate from './components/ButtonPrintDate';

const entryPointPath = '/pdd/results/training/';

export const COLUMNS = [
  {
    title: 'Темы ПДД',
    dataIndex: 'pddTestGroups',
    render: (pddTestGroups?: PddGroup[]) => (
      <div style={{ whiteSpace: 'break-spaces' }}>
        {pddTestGroups?.map((el) => el.pddGroup.name).join(', ')}
      </div>
    ),
  },
  {
    title: 'Сдали',
    dataIndex: 'passedCount',
    render: (passedCount: number) => (
      <span style={{ color: '#27ae60' }}>{passedCount}</span>
    ),
  },
  {
    title: 'Не сдали',
    dataIndex: 'notPassedCount',
    render: (notPassedCount: number) => (
      <span style={{ color: '#eb2f22' }}>{notPassedCount}</span>
    ),
  },
  {
    title: 'Общее кол-во водителей',
    dataIndex: 'passedCount',
    render: (passedCount: number, record: PddTest) => (
      <span>{record.notPassedCount + record.passedCount}</span>
    ),
  },
  {
    title: 'Ссылка на результат',
    dataIndex: 'id',
    render: (id: number) => {
      return <Link to={`${entryPointPath}${id}`}>Перейти</Link>;
    },
  },
];

type PageProps = {
  userAccess: UserAccess[],
};
export default withUserAccess((props: PageProps) => {
  const [canRelogin, setCanRelogin] = useState(false);
  useEffect(() => {
    setCanRelogin(
      props.userAccess.find((access) =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.reloginPddTest,
        ].includes(access)
      )
    );
  }, [props.userAccess]);
  return (
    <Page>
      <CommonListPage
        crud={pddTestApi}
        pageHeaderProps={{
          breadCrumbs: [{ to: '/', name: 'Главная' }, { name: 'Результаты' }],
          mainHeader: 'Результаты тестирований',
          rightHeader: canRelogin ? (
            <>
              <ButtonPrintDate /> <ReloginButton />
            </>
          ) : (
            <ButtonPrintDate />
          ),
        }}
        tableListProps={{
          columns: COLUMNS,
        }}
        subHeader={<HeaderTabs selectedTab="training" />}
        filter={Filter}
        filterMixin={{
          type: pddTest.training,
        }}
      />
    </Page>
  );
});
