// @flow
import { fetchRequest } from './index';
import type { Assignment, EntityStatusType } from './../types';
import CommonApi from './../commonApi';

const basedUrl = 'assignment';
const assignment = new CommonApi<Assignment>(basedUrl, 'закрепления');

export const changeStatus = async (data: {
  id: number,
  status: EntityStatusType
}): Promise<Assignment> => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, data);
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export const getByContractVehiclePlan = async (
  id: number
): Promise<Assignment> => {
  const assignment = await fetchRequest.get(
    `/${basedUrl}/getByContractVehiclePlan/${id}`
  );
  if (assignment) return assignment;
  throw new Error(`Не удалось сменить статус`);
};

export default {
  ...assignment,
  changeStatus,
  getByContractVehiclePlan
};
