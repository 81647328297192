import {
    WorkingHourContractPriceCard,
    WorkingHourContractPriceForm,
    WorkingHourContractPriceList,
} from '../../containers/WorkingHourContractPrice';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: WorkingHourContractPriceList,
        path: '/admin/working-hour-contract-price',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WorkingHourContractPriceCard,
        path: '/admin/working-hour-contract-price/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WorkingHourContractPriceForm,
        path: '/admin/working-hour-contract-price/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WorkingHourContractPriceForm,
        path: '/admin/working-hour-contract-price/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];