// @flow
import type { RequestSection } from '../requests';
import RequestsController from '../requests';

export class Root {
  controller: RequestSection;

  constructor(controller: RequestsController) {
    this.controller = controller.getApiSection('');
  }
}
