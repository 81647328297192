// @flow
import type { FederalHighwayCalculationMonth } from './../types';
import CommonApi from '../commonApi';
import {
  convertFromKopToRub,
  convertFromRubToKop,
  convertFromKmToM,
  convertFromMToKm
} from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  federalHighwayCalculationMonth: FederalHighwayCalculationMonth
): FederalHighwayCalculationMonth => ({
  ...federalHighwayCalculationMonth,
  plannedKilometrage: convertFromKmToM(
    federalHighwayCalculationMonth.plannedKilometrage
  ),
  cost: convertFromRubToKop(federalHighwayCalculationMonth.cost)
});

export const convertDataFromServer = (
  federalHighwayCalculationMonth: FederalHighwayCalculationMonth
): FederalHighwayCalculationMonth => ({
  ...federalHighwayCalculationMonth,
  plannedKilometrage: convertFromMToKm(
    federalHighwayCalculationMonth.plannedKilometrage,
    2
  ),
  cost: convertFromKopToRub(federalHighwayCalculationMonth.cost)
});

const basedUrl = 'federalHighwayCalculationMonth';
const federalHighwayCalculationMonth = new CommonApi<FederalHighwayCalculationMonth>(
  basedUrl,
  'расчет проезда по федеральным трассам',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  data: FederalHighwayCalculationMonth
): Promise<FederalHighwayCalculationMonth> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculation`,
    convertDataToServer(data)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error(`Не удалось отредактировать`);
};

export default {
  ...federalHighwayCalculationMonth,
  updateCalculations
};
