// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import { navigate } from '@reach/router';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';

import type { Battery, UserAccess } from '../../../lib/types';
import { accessTypeEnum } from '../../../lib/enum';
import type { Profile } from './../../../ducks/auth';
import type { AppState } from '../../../ducks/redux';
import { Panel } from './../../../components/layout';
import Header from '../../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { withUserAccess } from './../../withUserAccess';
import { batteryApi } from '../../../lib/api';
import InnerForm, { type BlockedMdmFields } from './components/InnerForm';
import { notificationLoading } from './../../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  batteryId: number,
  vehicleId?: number,
  uri: string,
  profile: Profile,
  userAccess: UserAccess[]
};

type State = {
  blockedMdmFields: BlockedMdmFields,
  battery: ?Battery,
  confirmationVisible: boolean,
  loading: boolean
};

class BatteryForm extends Component<Props, State> {
  state = {
    blockedMdmFields: {
      voltage: false,
      capacity: false,
      name: false
    },
    battery: null,
    confirmationVisible: false,
    loading: false
  };

  async componentDidMount() {
    const { batteryId } = this.props;
    if (batteryId) {
      try {
        const battery = await batteryApi.fetchBattery(batteryId);
        this.setState({
          battery
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/equipment/batteries');
      }
    }
  }

  closeConfirmation = () =>
    this.setState({
      confirmationVisible: false
    });

  blockMdmFields = () =>
    this.setState({
      blockedMdmFields: {
        voltage: true,
        capacity: true,
        name: true
      }
    });

  onCancel = () => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/equipment`);
    } else if (this.props.batteryId) {
      navigate(`/equipment/batteries/${this.props.batteryId}`);
    } else navigate('/equipment/batteries');
  };

  addBattery = async ({ installDate, ...battery }: Battery) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'adding'
      });
      /**
       * Мы сначала создаем шину.
       * Затем, если нужно привязать ее, то привязываем
       * и обновляем данные по нормативному пробегу.
       *
       * Можно было и все одним запросом сделать, но так
       * проще понимать, что происходит
       */
      const addedBattery = await batteryApi.addBattery(battery);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (addedBattery && vehicleId) {
        await batteryApi.setBatteryVehicle(
          parseInt(addedBattery.id, 10),
          vehicleId,
          installDate
        );
      }
      const batteryId = parseInt(addedBattery.id, 10);

      notification.success({
        message: 'Успешное добавление',
        description: `АКБ добавлена`
      });
      if (this.props.vehicleId) {
        navigate(`/vehicles/${this.props.vehicleId}/equipment`);
      } else {
        navigate(`/equipment/batteries/${batteryId}`);
      }
    } finally {
      notification.close('adding');
    }
  };

  checkFactoryNumber = async () => {
    try {
      const { battery } = this.state;
      let hasSameFactoryNumber = false;
      if (battery) {
        if (battery.factoryNumber) {
          const existingBatteries = await batteryApi.fetchBatteries({
            factoryNumber: battery.factoryNumber
          });
          hasSameFactoryNumber =
            existingBatteries &&
            existingBatteries.data &&
            existingBatteries.data.length > 0;
        }
        if (hasSameFactoryNumber) {
          this.setState({
            confirmationVisible: true
          });
        } else {
          await this.addBattery(battery);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  onSubmit = async (battery: Battery) => {
    try {
      let batteryId = parseInt(battery.id, 10);
      if (batteryId > 0) {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving'
        });
        await batteryApi.updateBattery(battery);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        if (this.props.vehicleId) {
          navigate(`/vehicles/${this.props.vehicleId}/equipment`);
        } else {
          navigate(`/equipment/batteries/${batteryId}`);
        }
      } else {
        this.setState(
          {
            battery
          },
          this.checkFactoryNumber
        );
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  canEditOrgUnit = () =>
    this.props.userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.adminBranch].includes(access)
    );

  getInitialBattery = () => ({
    norm: 3,
    orgUnitId: this.props.profile.employeeBranchOrgUnitId
  });

  render() {
    const { battery, loading, confirmationVisible } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/equipment/batteries">АКБ</Crumb>
              {battery && battery.id ? (
                <>
                  <Crumb to={`/equipment/batteries/${battery.id}`}>
                    {battery.brand} {battery.name}
                  </Crumb>
                  <Crumb>Редактирование</Crumb>
                </>
              ) : (
                <Crumb>Новая АКБ</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{battery && battery.id ? `АКБ №${battery.id}` : 'Новая АКБ'}</h1>
        </StyledPanel>
        <InnerForm
          battery={battery || this.getInitialBattery()}
          blockMdmFields={this.blockMdmFields}
          blockedMdmFields={this.state.blockedMdmFields}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          canEditOrgUnit={this.canEditOrgUnit()}
          vehicleId={this.props.vehicleId}
        />
        {battery && (
          <Modal
            title="Добавление АКБ"
            visible={confirmationVisible}
            onOk={() => this.addBattery(battery)}
            onCancel={this.closeConfirmation}
            footer={[
              <Button key="back" onClick={this.closeConfirmation}>
                Не добавлять
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => this.addBattery(battery)}
              >
                Добавить
              </Button>
            ]}
          >
            <p>
              В системе найдена АКБ с заводским номером "{battery.factoryNumber}
              ". Вы действительно хотите добавить АКБ?
            </p>
          </Modal>
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  batteryId: parseInt(ownProps.batteryId, 10),
  profile: state.auth.profile
}))(withUserAccess(BatteryForm));
