// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../../components/ui';

type Props = {
  contractVehiclePlanId?: number,
  assignmentApproved?: boolean
};

export default ({
  contractVehiclePlanId,
  assignmentApproved = false
}: Props) => {
  const url = contractVehiclePlanId
    ? `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/card`
    : '/budget/contract-vehicle/fixed/new';
  return (
    <Tabs withRouter>
      <TabItem label="Перечень ТС" url={url} />
      {!!contractVehiclePlanId && (
        <TabItem
          label="Закрепление"
          url={`/budget/contract-vehicle/fixed/${contractVehiclePlanId}/assignments`}
        />
      )}
      {!!contractVehiclePlanId && assignmentApproved && (
        <TabItem
          label="Бюджет"
          url={`/budget/contract-vehicle/fixed/${contractVehiclePlanId}/budget`}
        />
      )}
    </Tabs>
  );
};
