import {
    LicensePlateChangeCostCard,
    LicensePlateChangeCostForm,
    LicensePlateChangeCostList,
} from '../../containers/LicensePlateChangeCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: LicensePlateChangeCostList,
        path: '/admin/license-plate-change-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: LicensePlateChangeCostCard,
        path: '/admin/license-plate-change-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: LicensePlateChangeCostForm,
        path: '/admin/license-plate-change-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: LicensePlateChangeCostForm,
        path: '/admin/license-plate-change-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];