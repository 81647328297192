// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { stageApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await stageApi.fetchStage({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={stageApi.getStage}
    notFoundText="Этапы не найдены"
    placeholder="Выберите этап"
    renderOption={(stage: any, Option) => (
      <Option key={stage.id} value={stage.id} stage={stage}>
        {`${stage.stageNumber} ${stage.expenseDirection.name}`}
      </Option>
    )}
    {...selectProps}
  />
);
