// @flow

import type { OperationLimit } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromHourToSecond, convertFromSecondToHour } from '../helpers';

export const convertFromServer = (limit: OperationLimit) => ({
  ...limit,
  hours: convertFromSecondToHour(limit.hours),
  hoursWeekend: convertFromSecondToHour(limit.hoursWeekend)
});

export const convertToServer = (limit: OperationLimit) => ({
  ...limit,
  hours: convertFromHourToSecond(limit.hours),
  hoursWeekend: convertFromHourToSecond(limit.hoursWeekend)
});

export const addOperationLimit = async (
  operationLimit: OperationLimit
): Promise<OperationLimit> => {
  const added = await fetchRequest.post(
    '/operationLimit',
    convertToServer(operationLimit)
  );
  if (added) return convertFromServer(added);
  throw new Error('Не удалось добавить регламент для должности');
};

export const updateOperationLimit = async (
  operationLimit: OperationLimit
): Promise<OperationLimit> => {
  const updated = await fetchRequest.put(
    '/operationLimit',
    convertToServer(operationLimit)
  );
  if (updated) return convertFromServer(updated);
  throw new Error('Не удалось обновить регламент для должности');
};
export const deleteOperationLimit = async (
  id: number
): Promise<OperationLimit> => {
  const deleted = await fetchRequest.delete(`/operationLimit/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить регламент для должности');
};

export const fetchOperationLimit = async (
  id: number,
  params: any = {}
): Promise<OperationLimit> => {
  const operationLimit = await fetchRequest.get(
    `/operationLimit/${id}`,
    params
  );
  if (operationLimit) return convertFromServer(operationLimit);
  throw new Error('Не удалось получить регламент для должности');
};

export const fetchOperationLimits = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<OperationLimit>> => {
  const operationLimits = await fetchRequest.get('/operationLimit/', {
    ...initialFetchListParams,
    ...params
  });
  if (operationLimits) {
    operationLimits.data = operationLimits.data.map(convertFromServer);
    return operationLimits;
  }
  throw new Error('Не удалось получить список регламенты для должности');
};

export default {
  addOperationLimit,
  updateOperationLimit,
  deleteOperationLimit,
  fetchOperationLimit,
  fetchOperationLimits
};
