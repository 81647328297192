// @flow

import notification from 'antd/lib/notification';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Card, Spinner } from '../../../../components';
import { Icon } from '../../../../components/ui';
import { Option, Select } from './../../../../components/ant/Select';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import type { Battery } from './../../../../lib/types';
import InstallEquipmentButton from './../InstallEquipmentButton';

const { Field } = Card;

const Wrapper = styled.div`
  padding: 16px;
`;
const AddButton = styled.span`
  cursor: pointer;
  color: #2770ff;
  display: flex;
  & .icon {
    margin-right: 5px;
  }
`;

type Props = {
  onSubmit: (battery: Battery) => void,
  availableBatteries: Battery[],
  handleSearch: (serachString: string) => void,
  fetchData: () => void,
  isLoading: boolean
};

type State = {
  selectedBatteryId?: ?number,
  visibleForm: boolean
};

class BatteryForm extends Component<Props, State> {
  static defaultProps = {
    availableBatteries: []
  };

  state = {
    selectedBatteryId: null,
    visibleForm: false
  };

  onBatterySelect = (selectedBatteryId: number) =>
    this.setState({
      selectedBatteryId
    });

  onAddClick = async (installDate: Date) => {
    const { selectedBatteryId } = this.state;
    const { availableBatteries } = this.props;
    const battery = availableBatteries.find(
      item => item.id === selectedBatteryId
    );
    if (battery) {
      await this.props.onSubmit({
        ...battery,
        installDate
      });
      this.setState({
        selectedBatteryId: null,
        visibleForm: false
      });
    } else {
      notification.error({
        message: 'Необходимо выбрать навесное оборудование из списка'
      });
    }
  };

  handleAddButtonClick = () => this.setState({ visibleForm: true });

  render() {
    const { selectedBatteryId, visibleForm } = this.state;
    const {
      availableBatteries,
      handleSearch,
      fetchData,
      isLoading
    } = this.props;
    return (
      <Wrapper>
        {visibleForm ? (
          <Grid gutter="16px">
            <GridItem>
              <Field label="АКБ">
                <Select
                  placeholder="Выберите АКБ"
                  value={selectedBatteryId}
                  onChange={this.onBatterySelect}
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  onBlur={() => fetchData()}
                  notFoundContent={
                    <Spinner
                      isLoading={isLoading}
                      notFoundText="АКБ не найдены"
                    />
                  }
                >
                  {availableBatteries.map((battery: Battery) => (
                    <Option
                      key={battery.id}
                      value={battery.id}
                    >{`${battery.name || ''} ${battery.brand || ''}`}</Option>
                  ))}
                </Select>
              </Field>
            </GridItem>
            <GridItem fullWidth>
              <InstallEquipmentButton
                onConfirm={this.onAddClick}
                disabled={!selectedBatteryId}
                disableDate={false}
              />
            </GridItem>
          </Grid>
        ) : (
          <AddButton onClick={this.handleAddButtonClick}>
            <Icon type="plus" />
            Добавить АКБ
          </AddButton>
        )}
      </Wrapper>
    );
  }
}

export default BatteryForm;
