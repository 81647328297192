import { UserCard, UserForm, UserList } from '../../containers/Users';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: UserList,
        path: '/admin/users',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingUsersAndRoles,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
    {
        component: UserForm,
        path: '/admin/users/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
    {
        component: UserCard,
        path: '/admin/users/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingUsersAndRoles,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
    {
        component: UserForm,
        path: '/admin/users/:userId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingUsersAndRoles,
        ],
    },
];