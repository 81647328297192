// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { budgetLineItemApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { BudgetLineItem } from '../../lib/types';

export default (props: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите статью"
    fetch={budgetLineItemApi.fetch}
    fetchSingle={budgetLineItemApi.get}
    notFoundText="Статьи не найдены"
    renderOption={(budgetLineItem: BudgetLineItem, Option) => (
      <Option value={budgetLineItem.id} key={budgetLineItem.id}>
        {budgetLineItem.mnemocode} {budgetLineItem.name}
      </Option>
    )}
    {...props}
  />
);
