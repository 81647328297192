// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportFuelCard,
  ReportFuelCardsParams
} from '../types/reportFuelCards';

/**
 * Получение отчета
 */
export const fetchReportFuelCards = async (
  params: ReportFuelCardsParams
): Promise<ReportFuelCard[]> => {
  return await fetchRequest.get('/report/fuelCard', {
    ...params
  });
};

/**
 * Печать отчета
 */
export const printReportFuelCards = async (
  params: ReportFuelCardsParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken('/report/fuelCard/print', {
    ...params
  });
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportFuelCards,
  printReportFuelCards
};
