// @flow

import type { Driver, FileInfo } from '../types';

import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { DriverFilterParams } from '../../containers/Driver/Filter';

export const addDriver = async (driver: Driver): Promise<Driver> => {
  const added = await fetchRequest.post('/driver', driver);
  if (added) return added;
  throw new Error('Не удалось создать водителя');
};

export const updateDriver = async (driver: Driver): Promise<Driver> => {
  const updated = await fetchRequest.put('/driver', driver);
  if (updated) return updated;
  throw new Error('Не удалось обновить водителя');
};

export const fetchDrivers = async (
  params: FetchListParams<DriverFilterParams> = initialFetchListParams
): Promise<ListResponse<Driver>> =>
  await fetchRequest.get('/driver', {
    ...initialFetchListParams,
    ...params
  });

export const fetchDriver = async (
  id: number,
  params: any = {}
): Promise<Driver> => {
  const driver = await fetchRequest.get(`/driver/${id}`, params);
  if (driver) return driver;
  throw new Error('Не удалось загрузить водителя');
};

export const deleteDriver = async (id: number): Promise<Driver> => {
  const deleted = await fetchRequest.delete(`/driver/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить водителя');
};

export const uploadPhoto = async (
  driverId: number,
  photo: FileInfo
): Promise<Driver> => {
  const added = await fetchRequest.post(`/driver/${driverId}/photo`, {
    driverId,
    photo
  });
  if (added) return added;
  throw new Error('Не удалось добавить фото водителя');
};

export const printTrips = async (
  params: FetchListParams<DriverFilterParams>
): Promise<boolean> => {
  const printed = await downloadRequestWithToken('/driver/trips/print', {
    ...params
  });
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const printDrivingRatings = async (
  params: FetchListParams<DriverFilterParams>
): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    '/driver/drivingRatings/print',
    {
      ...params
    }
  );
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const printDriverRegister = async (): Promise<boolean> => {
  const printed = await downloadRequestWithToken('/driver/report/print');
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const printDriverStats = async (driverId: number): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    `/driver/printDriverStats/${driverId}`
  );
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const printTestsHistory = async (driverId: number): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    `/driver/${driverId}/testsHistory/print`
  );
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const driversWithUsers = async (): Promise<Driver> => {
  const driver = await fetchRequest.get(`/driver/driversWithUsers`);
  if (driver) return driver;
  throw new Error('Не удалось загрузить водителей');
};

export default {
  addDriver,
  updateDriver,
  fetchDrivers,
  deleteDriver,
  fetchDriver,
  uploadPhoto,
  printTrips,
  printDrivingRatings,
  printDriverRegister,
  driversWithUsers,
  printDriverStats,
  printTestsHistory
};
