// @flow
import type { UserAccess } from '../../lib/types';
import { accessTypeEnum } from '../../lib/enum/userAccess';

export const canAdd = (userAccess: UserAccess[]) =>
  userAccess.some(access =>
    [
      accessTypeEnum.handlingTimeLimits,
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch
    ].includes(access)
  );

export const canApproving = (userAccess: UserAccess[]) =>
  userAccess.some(access =>
    [
      accessTypeEnum.approvingTimeLimits,
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch
    ].includes(access)
  );
