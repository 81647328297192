import { ChannelList, ChannelForm } from '../../containers/Channel';
import { accessTypeEnum } from '../../lib/enum';

export default [
  {
    component: ChannelList,
    path: '/admin/channel',
    access: [accessTypeEnum.admin],
  },
  {
    component: ChannelForm,
    path: '/admin/channel/new',
    access: [accessTypeEnum.admin],
  },
  {
    component: ChannelForm,
    path: '/admin/channel/:id',
    access: [accessTypeEnum.admin],
  },
];
