// @flow
import React from 'react';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import type { PddGroup } from '../../../../lib/types';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';
import { Input } from 'antd';

export default (props: CommonFormProps<$Shape<PddGroup>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Наименование темы" fast required name="name">
                  {({ name, value }) => (
                    <Input
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
