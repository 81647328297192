import { StockForm, StockList } from '../../containers/Stock';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: StockList,
        path: '/admin/stock',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: StockForm,
        path: '/admin/stock/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: StockForm,
        path: '/admin/stock/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];