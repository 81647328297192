// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Section } from '../../../components/layout';
import Header from '../../../components/layout/Header';
import { notificationLoading } from '../../../components/Notifications';
import {
  ButtonsRow,
  Dropdown,
  TabItem,
  Table,
  Tabs,
} from '../../../components/ui';

import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';
import { changeStatus } from './../../../ducks/order';
import type { OrderStatusOptions } from './../../../ducks/order';

import { type FetchListParams, orderApi } from '../../../lib/api';
import {
  accessTypeEnum,
  orderObjectives,
  orderStatuses,
  vehicleGroups,
  orderStatusEnum,
  vehicleTypes,
} from '../../../lib/enum';
import {
  formatDateRangeString,
  getListInitialState,
  setQueryParams,
  tooltipFullText,
} from '../../../lib/helpers';
import type { ListState, Order, UserAccess, Vehicle } from '../../../lib/types';

import { withUserAccess } from '../../withUserAccess';
import CopyOrderButton from '../components/CopyOrderButton';
import type { OrderFilterParams } from '../Filter';
import Filter from '../Filter';
import { canCopyOrder, canCancelOrder, canCancelGpmOrder } from '../lib';

export const orderTypes = {
  income: 'income',
  archive: 'archive',
};

export const canAddAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingOrder,
  accessTypeEnum.creatingOrder,
];

type Props = {
  userAccess: UserAccess[],
  employeeId: number,
  location: Location & { state: { page: number } },
  type: $Values<typeof orderTypes>,
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void,
  changeStatus: (id: number, options: OrderStatusOptions) => void,
};

type State = ListState<Order> & {
  filterPath: string,
};

export class OrderList extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };

  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname,
  };

  canAddOrder = () =>
    this.props.userAccess.some((access) => canAddAccess.includes(access));

  onCancel = async (order: Order) => {
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.cancelled,
        });
        notification.success({
          message: 'Заявка успешно отменена',
        });
        await this.fetch();
      } catch (error) {
        notification.error({
          message: error.message,
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  getColumns = () => {
    const { userAccess, employeeId } = this.props;
    let columns = [
      {
        title: '№',
        dataIndex: 'id',
        sorter: true,
        // Нужно для того, чтобы не отрабатывал onRow
        onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
        render: (id: number) => <Link to={`/orders/${id}/card`}>{id}</Link>,
      },
      {
        title: 'Период выделения',
        dataIndex: 'startDate',
        sorterKey: 'route.startDate',
        sorter: true,
        render: (text: string, record: Order): ?string =>
          formatDateRangeString(record.startDate, record.endDate),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        sorter: true,
        render: (status: string) => orderStatuses[status],
      },
      {
        title: 'Служба',
        dataIndex: 'orgUnitName',
        sorterKey: 'node.name',
        sorter: true,
      },
      {
        title: 'Примечание',
        dataIndex: 'notation',
        sorter: false,
        render: (notation: string) => tooltipFullText(notation),
      },
      {
        title: 'Тип',
        dataIndex: 'vehicleType',
        sorter: true,
        render: (text: string, record: Order): ?string =>
          vehicleTypes[text] || vehicleGroups[record.vehicleGroup],
      },
      {
        title: 'Цель поездки',
        dataIndex: 'objective',
        sorter: true,
        render: (text: string): ?string => orderObjectives[text],
      },
    ];

    if (this.props.type === orderTypes.archive) {
      columns.splice(2, 0, {
        title: 'Гос. номер',
        dataIndex: 'vehicle',
        render: (vehicle: Vehicle) =>
          vehicle && vehicle.licensePlate ? vehicle.licensePlate : null,
      });
    }
    const canCopy = canCopyOrder(this.props.userAccess);
    columns.push({
      title: '',
      width: '30px',
      dataIndex: 'id',
      // Нужно для того, чтобы не отрабатывал onRow
      onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
      render: (orderId: number, order: Order) => {
        const canCancel =
          this.props.type === orderTypes.income &&
          (canCancelOrder(order, userAccess, employeeId) ||
            canCancelGpmOrder(order, userAccess));
        return canCopy || canCancel ? (
          <Dropdown
            overlay={
              <Menu>
                {canCopy && (
                  <Menu.Item>
                    <CopyOrderButton orderId={orderId} />
                  </Menu.Item>
                )}
                {canCancel && (
                  <Menu.Item onClick={() => this.onCancel(order)}>
                    Отменить заявку
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
          </Dropdown>
        ) : null;
      },
    });

    return columns;
  };

  handleRowClick = (id: number) => navigate(`/orders/${id}/card`);

  handlePrint = async () => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath];
    try {
      notificationLoading({
        message: 'Файл для печати формируется',
        key: 'print',
      });
      await orderApi.print(
        {
          ...filter,
          isArchive: this.props.type === orderTypes.archive,
        },
        'РеестрЗаявок.xlsx'
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  fetchOrders = async (
    page: number = 1,
    params: FetchListParams<OrderFilterParams> = {}
  ) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({ loading: true });
    const { data, totalCount } = await orderApi.fetchOrders({
      page,
      isArchive: this.props.type === orderTypes.archive,
      ...filter,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  setPersistFilter = async (values: OrderFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchOrders();
  };

  applyFilter = async (values: OrderFilterParams) => {
    await this.setPersistFilter(values);
    await this.fetchOrders();
  };

  fetch = async () => {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchOrders(page);
  };

  async componentDidMount() {
    await this.fetch();
  }

  render() {
    const { location, userAccess } = this.props;
    const { data, totalCount, pageSize, page, loading, filterPath } =
      this.state;
    const filter = this.props.persistFilters[filterPath];
    const canAdd = this.canAddOrder();
    const handlingOrder = userAccess.includes(accessTypeEnum.handlingOrder);
    return (
      <>
        <Header
          left={<h1>Заявки на транспортные средства</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {canAdd && (
                <Link to="/orders/new">
                  <Button type="primary" data-cy="addOrder">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Tabs withRouter>
          <TabItem url="/orders" label="Входящие заявки" />
          <TabItem url="/orders/archive" label="Архив" />
        </Tabs>
        <Section>
          <Filter
            handlingOrder={handlingOrder}
            mode={this.props.type}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            loading={loading}
            fetch={this.fetchOrders}
            columns={this.getColumns()}
            customStyle={{
              overflowX: 'auto',
            }}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data.map((item) => ({ ...item, key: item.id }))}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters,
    employeeId: state.auth.profile.employeeId,
  }),
  {
    setFilter,
    changeStatus,
  }
)(withUserAccess(OrderList));
