// @flow

import type { IncomeSchedule } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

import { convertFromRubToKop, convertFromKopToRub } from './../helpers';

export const convertDataToServer = (incomeSchedule: IncomeSchedule) => ({
  ...incomeSchedule,
  sum: convertFromRubToKop(incomeSchedule.sum),
  sumWithTax: convertFromRubToKop(incomeSchedule.sumWithTax)
});
export const convertDataFromServer = (incomeSchedule: IncomeSchedule) => ({
  ...incomeSchedule,
  sum: convertFromKopToRub(incomeSchedule.sum),
  sumWithTax: convertFromKopToRub(incomeSchedule.sumWithTax)
});

export const addIncomeSchedule = async (
  incomeSchedule: IncomeSchedule
): Promise<IncomeSchedule> => {
  const added = await fetchRequest.post(
    '/incomeSchedule',
    convertDataToServer(incomeSchedule)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать график поступления');
};

export const updateIncomeSchedule = async (
  incomeSchedule: IncomeSchedule
): Promise<IncomeSchedule> => {
  const updated = await fetchRequest.put(
    '/incomeSchedule',
    convertDataToServer(incomeSchedule)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить график поступления');
};

export const fetchIncomeSchedule = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<IncomeSchedule>> => {
  const incomeShedules = await fetchRequest.get('/incomeSchedule', {
    ...initialFetchListParams,
    ...params
  });
  if (incomeShedules) {
    return {
      ...incomeShedules,
      data: incomeShedules.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось загрузить спиоск графиков поступлений');
};

export const getIncomeSchedule = async (
  id: number,
  params: any = {}
): Promise<IncomeSchedule> => {
  const offer = await fetchRequest.get(`/incomeSchedule/${id}`, params);
  if (offer) return convertDataFromServer(offer);
  throw new Error('Не удалось загрузить график поступлений');
};

export const deleteIncomeSchedule = async (
  id: number
): Promise<IncomeSchedule> => {
  const deleted = await fetchRequest.delete(`/incomeSchedule/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить график поступления');
};

export default {
  addIncomeSchedule,
  updateIncomeSchedule,
  fetchIncomeSchedule,
  deleteIncomeSchedule,
  getIncomeSchedule
};
