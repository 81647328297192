// @flow
import { accessTypeEnum } from '../../lib/enum';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingSelfVehiclePlanBudget
];

export const approvingAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.approvingSelfVehiclePlanBudget
];

export const handlingAccessRight = [...addAccessRight];

export default addAccessRight;
