// @flow
import React from 'react';

import { passTariffApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { formatDateTimeToString, navigate } from '../../lib/helpers';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/pass-tariff/';

const columns = [
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModel',
    render: model => {
      return `${model.brand.name} ${model.name}`;
    }
  },
  {
    title: 'Стоимость бланка, руб.',
    dataIndex: 'blankPrice'
  },
  {
    title:
      'Тариф за превышение допустимой нормы в весенний период без НДС, руб.',
    dataIndex: 'excessRate'
  },
  {
    title: 'Сумма тарифа компенсации за вред принесённый дорогам без НДС, руб.',
    dataIndex: 'roadDamageRate'
  },
  {
    title:
      'Ставка госпошлины за выдачу пропусков на весенний период без НДС, руб.',
    dataIndex: 'springPassesTax'
  },
  {
    title: 'Ставка госпошлины за выдачу пропусков (негабарит) без НДС, руб.',
    dataIndex: 'cargoPassesTax'
  },
  {
    title:
      'Ставка госпошлины за согласование пропусков (негабарит) без НДС, руб.',
    dataIndex: 'cargoPassesAgreementTax'
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  return (
    <Page>
      <CommonListPage
        crud={passTariffApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Тарифы на пропуска',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
