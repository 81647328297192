// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type { OrgUnitNode, UserAccess } from './../../lib/types';
import { goBack } from '../../lib/helpers';
import { accessTypeEnum } from '../../lib/enum';

import { Panel } from './../../components/layout';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';

import { selectOrgUnit } from './../../ducks/selectors';
import { updateOrgUnit, addOrgUnit } from './../../ducks/orgUnits';
import InnerForm from './components/InnerForm';
import InnerCard from './components/InnerCard';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  userAccess: UserAccess[],
  orgUnit: OrgUnitNode,
  updateOrgUnit: Function,
  addOrgUnit: Function,
  parentOrgUnitId: number
};

class OrgUnitForm extends Component<Props> {
  canAdd = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrgUnits
      ].includes(access)
    );
  render() {
    const { orgUnit, parentOrgUnitId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/org-units">Подразделения</Crumb>
              {orgUnit ? (
                <Crumb to={`/admin/org-units/${orgUnit.id}`}>
                  Подразделение №{orgUnit.id}
                </Crumb>
              ) : (
                <Crumb to={`/admin/org-units/${parentOrgUnitId}/new`}>
                  Новое подразделение
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {orgUnit ? `Подразделение №${orgUnit.id}` : 'Новое подразделение'}
          </h1>
        </StyledPanel>
        {this.canAdd() ? (
          <InnerForm
            orgUnit={
              orgUnit || {
                parentId: parentOrgUnitId
              }
            }
            onCancel={() => goBack('/admin/org-units')}
            onSubmit={async values => {
              try {
                notificationLoading({
                  message: 'Сохранение данных...',
                  key: 'saving'
                });
                if (values.id) {
                  await this.props.updateOrgUnit(values);
                } else {
                  await this.props.addOrgUnit(
                    values,
                    this.props.parentOrgUnitId
                  );
                }
                notification.success({
                  message: 'Успешно сохранено',
                  description: 'Изменения успешно сохранены'
                });
                navigate('/admin/org-units');
              } catch (error) {
                notification.error({
                  message: 'Ошибка',
                  description: error.message
                });
              } finally {
                notification.close('saving');
              }
            }}
          />
        ) : (
          <InnerCard
            orgUnit={
              orgUnit || {
                parentId: parentOrgUnitId
              }
            }
          />
        )}
      </>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    userAccess: state.auth.profile.access,
    orgUnit: selectOrgUnit(parseInt(ownProps.orgUnitId, 10))(state),
    parentOrgUnitId: parseInt(ownProps.parentOrgUnitId, 10)
  }),
  {
    updateOrgUnit,
    addOrgUnit
  }
)(OrgUnitForm);
