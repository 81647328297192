import {
    FuelMultiplierForm,
    FuelMultipliersList,
} from '../../containers/FuelMultipliers';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: FuelMultipliersList,
        path: '/admin/fuel-multipliers',
        access: [accessTypeEnum.admin],
    },
    {
        component: FuelMultiplierForm,
        path: '/admin/fuel-multipliers/new',
        access: [accessTypeEnum.admin],
    },
    {
        component: FuelMultiplierForm,
        path: '/admin/fuel-multipliers/:multiplierId',
        access: [accessTypeEnum.admin],
    },
];