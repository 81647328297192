// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import notification from 'antd/lib/notification';
import { Link } from '@reach/router';
import Button from 'antd/lib/button';
import { navigate } from '@reach/router';
import qs from 'query-string';

import type {
  BusinessDay,
  ListState,
  BusinessDayType,
} from './../../lib/types';
import {
  formatDateTimeToString,
  getListInitialState,
  setQueryParams,
} from './../../lib/helpers';
import { businessDayTypes } from './../../lib/enum';
import { businessDayApi } from './../../lib/api';
import { Panel, Section } from './../../components/layout';
import { Popconfirm, Table, Icon, Operations } from './../../components/ui';
import Header from './../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import type { FetchListParams } from './../../lib/api';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  location: Location & { state: { page: number } },
};

type State = ListState<BusinessDay>;

class BusinessCalendar extends Component<Props, State> {
  state = getListInitialState();

  fetchBusinessDays = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    this.setState({ loading: true });
    const { data, totalCount } = await businessDayApi.fetchBusinessDays({
      page,
      orderBy: 'date',
      byDescending: true,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  onDelete = async (id: number) => {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await businessDayApi.deleteBusinessDay(id);
      await this.fetchBusinessDays(page);
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      sorter: true,
      render: (date: string | moment) =>
        formatDateTimeToString(date, 'DD.MM.YYYY'),
    },
    {
      title: 'Тип',
      dataIndex: 'dayType',
      sorter: true,
      render: (dayType: BusinessDayType) => businessDayTypes[dayType],
    },
    {
      title: 'Описание',
      dataIndex: 'description',
    },
    {
      title: '',
      width: 30,
      render: (record: BusinessDay) =>
        isEmpty(record) ? null : (
          <Operations>
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => this.onDelete(parseInt(record.id))}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ),
    },
  ];

  handleRowClick = (id: number) => navigate(`/admin/business-calendar/${id}`);

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    await this.fetchBusinessDays(page);
  }

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb>Производственный календарь</Crumb>
            </Breadcrumbs>
          }
          right={
            <Link to={`/admin/business-calendar/new`}>
              <Button type="primary">Создать</Button>
            </Link>
          }
        />
        <StyledPanel>
          <h1>Производственный календарь</h1>
        </StyledPanel>
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            columns={this.columns}
            fetch={this.fetchBusinessDays}
            loading={loading}
            data={data.map((item) => ({ ...item, key: item.id }))}
            pagination={{
              page,
              totalCount,
              pageSize,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default BusinessCalendar;
