//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../components/hoc/common/handbook/CommonFilter';

import { Input } from 'antd';

export type SparePartsParamsFilter = {
  search?: string
};

export const SparePartsFilter = (
  props: FilterProps<SparePartsParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<SparePartsParamsFilter>>) =>
      CommonFilter<SparePartsParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                placeholder="Темы"
                value={values.search}
                onChange={e => changeValue('search', e.target.value)}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default SparePartsFilter;
