// @flow

import React, { Component } from 'react';
import qs from 'query-string';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {
  OrgUnitNode,
  Driver,
  UserAccess,
  ListState,
  Vehicle,
} from '../../lib/types';
import { accessTypeEnum } from '../../lib/enum';
import {
  applyMaskToValue,
  getListInitialState,
  setQueryParams,
  navigate,
} from '../../lib/helpers';
import { driverApi, type FetchListParams } from './../../lib/api';

import {
  Popconfirm,
  ButtonsRow,
  Icon,
  Operations,
  Table,
} from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import { notificationLoading } from '../../components/Notifications';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import { withUserAccess } from './../withUserAccess';
import { DriverRating } from './components/InnerForm.elements';
import PrintButton from './components/PrintButton';
import Filter, { type DriverFilterParams } from './Filter';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export const canAddDriverAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingDriver,
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<Driver> & {
  filter: DriverFilterParams,
};

class DriverList extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };

  state = {
    ...getListInitialState(),
    filter: {},
  };

  fetchDrivers = async (
    page: number = 1,
    params: FetchListParams<DriverFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await driverApi.fetchDrivers({
        ...filter,
        page,
        ...params,
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, async () => await this.fetchDrivers(page));
  }

  canAdd = () =>
    this.props.userAccess.some((access) => canAddDriverAccess.includes(access));

  async deleteDriver(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      const { page, pageSize } = this.state;
      await driverApi.deleteDriver(id);
      await this.fetchDrivers(page, { pageSize });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: 'ФИО',
      dataIndex: 'employeeId',
      sorter: true,
      sorterKey: 'employee.person.lastName',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: Driver) =>
        record.employee
          ? `${record.employee.lastname || ''} ${
              record.employee.firstname || ''
            } ${record.employee.middlename || ''}`
          : '',
    },
    {
      title: 'Закрепленное ТС',
      dataIndex: 'vehicles',
      stopPropagation: true,
      render: (vehicles: Vehicle[]) =>
        vehicles
          ? vehicles.map((vehicle: Vehicle, index) => (
              <Link to={`/vehicles/${vehicle.id}`}>
                {applyMaskToValue(
                  vehicle.licensePlate,
                  formatLicensePlateMask
                ) + (index !== vehicles.length - 1 ? ', ' : '')}
              </Link>
            ))
          : '-',
    },
    {
      title: 'Средний балл оценка вождения',
      sorter: true,
      dataIndex: 'drivingRating',
      render: (drivingRating: number) => <DriverRating value={drivingRating} />,
    },
    {
      title: ' ',
      width: 20,
      dataIndex: 'id',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: OrgUnitNode): any => (
        <Operations>
          {this.canAdd() && (
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await this.deleteDriver(record.id)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )}
        </Operations>
      ),
    },
  ];

  handleRowClick = (id: number) => navigate(`/admin/drivers/${id}`);

  cleanFilter = () => this.setState({ filter: {} }, this.fetchDrivers);

  applyFilter = (filter: DriverFilterParams) =>
    this.setState({ filter }, this.fetchDrivers);

  render() {
    const { location } = this.props;
    const { data, totalCount, page, pageSize, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Водители</h1>}
          right={
            <>
              <ButtonsRow>
                <PrintButton />
                {canAdd && (
                  <Link to={`/admin/drivers/new`}>
                    <Button type="primary" data-cy="addDriver">
                      Создать
                    </Button>
                  </Link>
                )}
              </ButtonsRow>
            </>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            defaultOrderBy="employee.person.lastName"
            defaultOrderDirection="ascend"
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            rowKey="id"
            loading={loading}
            columns={this.columns}
            data={data}
            fetch={this.fetchDrivers}
            fetchOnMount
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(DriverList);
