// @flow
import type { BranchBudgetSummaryExpenseDirection } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

const convertDataFromServer = (
  branchBudgetSummaryExpenseDirection: BranchBudgetSummaryExpenseDirection
) => ({
  ...branchBudgetSummaryExpenseDirection,
  months: branchBudgetSummaryExpenseDirection.months.map(month => ({
    ...month,
    writeOffSum: convertFromKopToRub(month.writeOffSum),
    plan: convertFromKopToRub(month.plan),
    need: convertFromKopToRub(month.need),
    fact: convertFromKopToRub(month.fact),
    economyResidue: convertFromKopToRub(month.economyResidue),
    economy: convertFromKopToRub(month.economy)
  })),
  sum: convertFromKopToRub(branchBudgetSummaryExpenseDirection.sum),
  totalNeed: convertFromKopToRub(branchBudgetSummaryExpenseDirection.totalNeed)
});

const convertDataToServer = (
  branchBudgetSummaryExpenseDirection: BranchBudgetSummaryExpenseDirection
) => ({
  ...branchBudgetSummaryExpenseDirection,
  months: branchBudgetSummaryExpenseDirection.months.map(month => ({
    ...month,
    writeOffSum: convertFromRubToKop(month.writeOffSum),
    plan: convertFromRubToKop(month.plan),
    need: convertFromRubToKop(month.need),
    fact: convertFromRubToKop(month.fact),
    economyResidue: convertFromRubToKop(month.economyResidue),
    economy: convertFromRubToKop(month.economy)
  })),
  approvedPlan: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.approvedPlan
  ),
  budgetChange: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.budgetChange
  ),
  coveredByClosedPeriod: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.coveredByClosedPeriod
  ),
  coveredByOpenPeriod: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.coveredByOpenPeriod
  ),
  economy: convertFromRubToKop(branchBudgetSummaryExpenseDirection.economy),
  economyResidue: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.economyResidue
  ),
  economyResidueFromClosedPeriod: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.economyResidueFromClosedPeriod
  ),
  economyResidueFromOpenPeriod: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.economyResidueFromOpenPeriod
  ),
  factSum: convertFromRubToKop(branchBudgetSummaryExpenseDirection.factSum),
  fundsAllocation: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.fundsAllocation
  ),
  newCalculation: convertFromRubToKop(
    branchBudgetSummaryExpenseDirection.newCalculation
  ),
  sum: convertFromRubToKop(branchBudgetSummaryExpenseDirection.sum),
  totalNeed: convertFromRubToKop(branchBudgetSummaryExpenseDirection.totalNeed)
});

const baseUrl = 'branchBudgetSummaryExpenseDirection';
const branchBudgetSummaryExpenseDirection = new CommonApi<BranchBudgetSummaryExpenseDirection>(
  baseUrl,
  'Направление расходов в сводах',
  convertDataToServer,
  convertDataFromServer
);

export default branchBudgetSummaryExpenseDirection;
