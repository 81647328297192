//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';
import VehicleIssueGroupSelect from '../../../components/selects/VehicleIssueGroupSelect';

export type OverhaulTypeParamsFilter = {
  vehicleModelId?: number,
  vehicleIssueGroupId?: number
};

export const OverhaulTypeFilter = (
  props: FilterProps<OverhaulTypeParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<OverhaulTypeParamsFilter>>) =>
      CommonFilter<OverhaulTypeParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleModelSelect
                size="small"
                value={values.vehicleModelId}
                onChange={async (value: number) =>
                  changeValue('vehicleModelId', value)
                }
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleIssueGroupSelect
                size="small"
                value={values.vehicleIssueGroupId}
                onChange={async (value: number) =>
                  changeValue('vehicleIssueGroupId', value)
                }
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default OverhaulTypeFilter;
