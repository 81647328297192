// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import Menu from 'antd/lib/menu';
import Button from 'antd/lib/button';

import type { AttachedEquipment, UserAccess } from '../../../lib/types';
import { attachedEquipmentApi } from '../../../lib/api';
import {
  attachedEquipmentTypes,
  accessTypeEnum,
  attachedEquipmentFuelTankType,
  attachedEquipmentFuelTankTypeEnum,
  fuelTypes,
} from '../../../lib/enum';
import {
  formatDateTimeToString,
  convertVehicleToString,
} from '../../../lib/helpers';
import { Card } from './../../../components';
import {
  Panel,
  Section,
  SectionTitle,
  Header,
} from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import {
  Popconfirm,
  Dropdown,
  ButtonOperations,
} from './../../../components/ui';
import type { DropdownType } from './../../../components/ui/Dropdown';
import HistoryTable from './../HistoryTable';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { withUserAccess } from './../../withUserAccess';
import AttachVehicleButton from './../components/AttachVehicleButton';
import RemoveEquipmentButton from './../../Vehicles/components/RemoveEquipmentButton';
import { notificationLoading } from './../../../components/Notifications';

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const { Field } = Card;

export const canEditAttachedEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingEquipment,
];

type Props = {
  attachedEquipmentId: number,
  userAccess: UserAccess[],
};

type State = {
  attachedEquipment: ?AttachedEquipment,
};

/** Карточка навесного оборудования */
export class AttachedEquipmentCard extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const { attachedEquipmentId } = this.props;
    try {
      const attachedEquipment =
        await attachedEquipmentApi.fetchAttachedEquipment(attachedEquipmentId);
      this.setState({
        attachedEquipment,
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    }
  }

  dropdown: ?DropdownType;

  deleteAttachedEquipment = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await attachedEquipmentApi.deleteAttachedEquipment(
        parseInt(this.props.attachedEquipmentId, 10)
      );
      navigate('/equipment/attached');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  setVehicle = async (
    vehicleId: ?number,
    installDate?: string,
    removeDate?: string
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const { attachedEquipment } = this.state;
      if (!attachedEquipment) {
        return;
      }
      let updatedAttachedEquipment;
      if (vehicleId) {
        updatedAttachedEquipment =
          await attachedEquipmentApi.setAttachedEquipmentVehicle(
            attachedEquipment.id,
            vehicleId,
            installDate
          );
      } else {
        updatedAttachedEquipment =
          await attachedEquipmentApi.updateAttachedEquipment({
            ...attachedEquipment,
            vehicleId,
            installDate,
            removeDate,
          });
      }
      this.setState({
        attachedEquipment: updatedAttachedEquipment,
      });
      notification.success({
        message: vehicleId ? 'ТС успешно закреплено' : 'ТС успешно откреплено',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { userAccess } = this.props;
    const { attachedEquipment } = this.state;
    const canEdit = userAccess.some((access) =>
      canEditAttachedEquipmentAccess.includes(access)
    );
    if (!attachedEquipment) {
      return null;
    }
    const canUnfixVehicle = parseInt(attachedEquipment.vehicleId, 10) > 0;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/equipment/attached">Навесное оборудование</Crumb>
              <Crumb>
                {attachedEquipment.brandName} {attachedEquipment.name}
              </Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <ButtonOperations>
                {canUnfixVehicle && (
                  <RemoveEquipmentButton
                    onConfirm={(removeDate: string) =>
                      this.setVehicle(
                        null,
                        attachedEquipment.installDate,
                        removeDate
                      )
                    }
                  >
                    Открепить ТС
                  </RemoveEquipmentButton>
                )}
                <AttachVehicleButton
                  onConfirm={this.setVehicle}
                  vehicleId={attachedEquipment.vehicleId}
                />

                <Dropdown
                  ref={(dropdown) => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() =>
                          navigate(
                            `/equipment/attached/edit/${attachedEquipment.id}`
                          )
                        }
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteAttachedEquipment}
                          onVisibleChange={(flag) =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className="openActionsDropdown"
                    type="primary"
                    icon={<EllipsisOutlined />}
                  />
                </Dropdown>
              </ButtonOperations>
            )
          }
        />
        <StyledPanel>
          <h1>
            {attachedEquipment.brandName} {attachedEquipment.name}
          </h1>
        </StyledPanel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование">{attachedEquipment.name}</Field>
              </GridItem>
              <GridItem>
                <Field label="Марка">{attachedEquipment.brandName}</Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">
                  {attachedEquipmentTypes[attachedEquipment.type]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Мнемокод">{attachedEquipment.code}</Field>
              </GridItem>
              {attachedEquipment.specifications && (
                <Field label="Характеристики">
                  {attachedEquipment.specifications}
                </Field>
              )}
              <GridItem>
                <Field label="Дата установки">
                  {formatDateTimeToString(
                    attachedEquipment.installDate,
                    'DD.MM.YYYY'
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата снятия">
                  {formatDateTimeToString(
                    attachedEquipment.removeDate,
                    'DD.MM.YYYY'
                  )}
                </Field>
              </GridItem>
              {attachedEquipment.inventoryNumber && (
                <GridItem>
                  <Field label="Инвентарный номер">
                    {attachedEquipment.inventoryNumber}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Показания счётчика моточасов">
                  {attachedEquipment.machineHours} ч.
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Нормативный расход топлива">
                  {attachedEquipment.fuelConsumption} л/маш. час
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип бака">
                  {attachedEquipment.fuelTankType
                    ? attachedEquipmentFuelTankType[
                        attachedEquipment.fuelTankType
                      ]
                    : '-'}
                </Field>
              </GridItem>
              {attachedEquipment.fuelTankType ===
                attachedEquipmentFuelTankTypeEnum.ownTank && (
                <>
                  <GridItem>
                    <Field label="Тип топлива">
                      {attachedEquipment.fuelType
                        ? fuelTypes[attachedEquipment.fuelType]
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Объем топливного бака, л">
                      {attachedEquipment?.tankVolume ?? '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Остаток топлива, л">
                      {attachedEquipment?.fuelRemaining ?? '-'}
                    </Field>
                  </GridItem>
                </>
              )}
              {attachedEquipment.vehicle && (
                <GridItem>
                  <Field label="Прикрепленное ТС">
                    {convertVehicleToString(attachedEquipment.vehicle)}
                  </Field>
                </GridItem>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>История</SectionTitle>
          <HistoryTable
            equipmentId={attachedEquipment.id}
            fetchHistory={attachedEquipmentApi.fetchHistory}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(AttachedEquipmentCard);
