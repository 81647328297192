// @flow

import moment from 'moment';
import flattenDeep from 'lodash/flattenDeep';
import uniq from 'lodash/uniq';

import type {
  VehicleGroup,
  VehicleType,
  FuelMultiplier,
  Vehicle,
  FileInfo,
  Driver,
  Contractor,
} from './../../lib/types';
import {
  formatDateTimeToString,
  convertContractorToString,
  formatDate as formatDateMultipliers,
} from './../../lib/helpers';
import { vehicleApi } from './../../lib/api';
import { vehicleGroupEnum, vehicleTypeEnum } from './../../lib/enum';
import { vehicleTypesPlainTree } from './../../lib/vehicleTypesTree';
// import { isImage } from '../../lib/helpers';

const formatString = 'DD.MM.YYYY';

export const COMMON_MAINTENANCE_COLUMNS = [
  {
    title: 'Сумма оплаты',
    key: 'paymentAmount',
    render: (sum: number) =>
      sum.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' }),
  },
  {
    title: 'Номер акта',
    key: 'act.actNumber',
  },
  {
    title: 'Дата акта',
    key: 'act.actDate',
    width: '150px',
    render: (value: string) => formatDateTimeToString(value, 'DD.MM.YYYY'),
  },
  {
    title: 'Контрагент',
    key: 'act.contract.contractor',
    render: (contractor: Contractor) => convertContractorToString(contractor),
  },
];

export const formatDate = function (date: ?(moment | string)): string {
  const momentDate = getMomentValue(date);
  if (momentDate) {
    return momentDate.format(formatString);
  }
  return '';
};

export const getMomentValue = function (value: ?moment) {
  return value ? moment.utc(value) : value;
};

export const convertDateToString = function (
  value: Object,
  dateString: string
): ?Date {
  if (value) {
    return moment.utc(dateString, formatString).toISOString();
  }
  return value;
};

/**
 * Функция для подготовки данных для слайдера файлов
 * @param file {FileInfo} Файл
 */
export const prepareSliderItem = (file: FileInfo) => ({
  title: file.fullName,
  contentType: file.contentType,
  imageUrl: file.contentType !== 'application/pdf' ? file.url : null,
  url: file.url,
  createdTime: file.createdTime,
});

/**
 * Валидация, минимальное значение 01.01.1980,
 * но не раньше года выпуска (если параметр был передан) и не позже текущего дня
 */
export const getDisabledDate = (currentDate: moment, yearIssued: ?number) => {
  return !moment(currentDate).isBetween(
    yearIssued
      ? moment.utc(yearIssued, 'YYYY')
      : moment.utc('01.01.1980', 'DD.MM.YYYY'),
    moment.utc()
  );
};

export const getDisabledPlannedDate = (currentDate: moment) =>
  moment(currentDate).isBefore(moment.utc('01.01.1980', 'DD.MM.YYYY'));

export const getVehicleGroup = (vehicleType?: ?VehicleType) => {
  let vehicleGroup: VehicleGroup = '';
  Object.keys(vehicleTypesPlainTree).forEach((key: string) => {
    if (vehicleTypesPlainTree[key].includes(vehicleType)) {
      vehicleGroup = key;
    }
  });
  return vehicleGroup;
};

/**
 * Логика по отображению поля "ТС с прицепом"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowWithTrailer = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle,
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.autolabs,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.atvs,
    vehicleGroupEnum.tractorsExcavators,
    vehicleGroupEnum.tankTrucks,
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению поля "Двигатель №"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowEngineNumber = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup !== vehicleGroupEnum.trailers;
};

/**
 * Логика по отображению полей связанных с моточасами
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowEngineWorkHoursData = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  if (vehicleGroup === vehicleGroupEnum.flatbedTrucks) {
    return [
      vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
      vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu,
    ].includes(vehicleType);
  }

  if (vehicleGroup === vehicleGroupEnum.otherTrucks) {
    return vehicleType === vehicleTypeEnum.avtomobylTiagachSKmu;
  }

  if (vehicleGroup === vehicleGroupEnum.utilityVehicles) {
    return vehicleType === vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu;
  }

  return [
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.bkmBgm,
    vehicleGroupEnum.atvs,
    vehicleGroupEnum.tractorsExcavators,
    vehicleGroupEnum.floatingTransport,
    vehicleGroupEnum.tankTrucks,
    vehicleGroupEnum.specialCarGroup,
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению полей связанных с водителем
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowDriverData = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  // Внимание, тут стоит НЕ(!), чтобы не описывать те виды, для которых можно показывать
  return ![
    vehicleGroupEnum.trailers,
    vehicleGroupEnum.atvs,
    vehicleGroupEnum.floatingTransport,
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению полей связанных с ОСАГО
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowOsagoData = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  // Ставлю отрицание, чтобы не прописывать все виды
  return ![
    vehicleGroupEnum.atvs,
    vehicleGroupEnum.trailers,
    vehicleGroupEnum.floatingTransport,
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению полей для ГПМ
 * для заполнения формы ГПМ ТС (отличается от формы модели ТС)
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowGpmData = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.flatbedTrucks) {
    return [
      vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
      vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu,
    ].includes(vehicleType);
  }

  if (vehicleGroup === vehicleGroupEnum.otherTrucks) {
    return vehicleType === vehicleTypeEnum.avtomobylTiagachSKmu;
  }

  if (vehicleGroup === vehicleGroupEnum.utilityVehicles) {
    return vehicleType === vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu;
  }

  return [vehicleGroupEnum.truckCranes, vehicleGroupEnum.carLifts].includes(
    vehicleGroup
  );
};

/**
 * Логика по отображению полей по АКБ
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowBatteryData = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  return (
    vehicleGroup !== vehicleGroupEnum.trailers &&
    vehicleType !== vehicleTypeEnum.trailers
  );
};

/**
 * Логика по отображению полей для Сертификации СИ
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowChangeToolsData = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  return vehicleGroup === vehicleGroupEnum.tankTrucks;
};

/**
 * Логика по отображению полей шин
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowTiresData = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  // Чтобы не заполнять все виды ТС, укажу только те, для которых НЕ нужно показывать поля шин
  return ![vehicleGroupEnum.atvs, vehicleGroupEnum.floatingTransport].includes(
    vehicleGroup
  );
};

/**
 * Логика по отображению топливных карт
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowFuelCards = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  // Чтобы не заполнять все виды ТС, укажу только те, для которых НЕ нужно показывать поля шин
  return ![vehicleGroupEnum.atvs, vehicleGroupEnum.floatingTransport].includes(
    vehicleGroup
  );
};

/*
 * Вернуть постоянные коэффициенты для ТС в зависимости
 * от времени года (лето, зима)
 * для заполнения формы.
 *
 * Год выпуска
 * @param yearIssued
 */
export const getVehicleConstMultipliers = async (vehicle: Vehicle): any => {
  const currentDate = moment();
  let multipliers = [];
  const sesonTypes = { offSeason: 'межсезонный', winter: 'зимний' };
  const seasonMultipliers = await vehicleApi.getSeasonMultipliers();

  for (let key in seasonMultipliers) {
    const { seasonType, ranges, multiplier } = seasonMultipliers[key];
    if (Array.isArray(ranges)) {
      let dateMultiplier = null;
      ranges.forEach(({ startDate, endDate }) => {
        if (
          currentDate.isSameOrAfter(moment(startDate)) &&
          currentDate.isBefore(moment(endDate))
        ) {
          multipliers.push({
            shouldUsedAlways: true,
            isWinterTime: false,
            value: multiplier,
            name: `Работа автотранспорта в ${
              sesonTypes[seasonType]
            } период с ${formatDateMultipliers(startDate, 'DD.MM')} по ${moment(
              endDate
            )
              .subtract(1, 'days')
              .format('DD.MM')}.`,
          });
        }
        dateMultiplier && multipliers.push(multipliers);
      });
    }
  }
  return multipliers;
};

/**
 * Логика по отображению поля "Диагностическая карта"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowDiagnosticCard = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle,
    ].includes(vehicleType);
  }
  return (
    vehicleGroup !== vehicleGroupEnum.atvs &&
    vehicleGroup !== vehicleGroupEnum.floatingTransport
  );
};

/**
 * Проверяет соответствие квалификаций водителя и ТС
 * @param driver Водитель
 * @param vehicle ТС
 * @returns {boolean} Результат проверки
 */
export const validateDriverQualification = (
  driver: ?Driver,
  vehicle: ?Vehicle
): boolean => {
  if (vehicle && driver) {
    const { driverQualification: vehicleModelDriverQualification } =
      vehicle.vehicleModel;
    const { qualificationDocuments } = driver;
    const driverQualifications = qualificationDocuments.map(
      (document) => document.qualification
    );
    return driverQualifications.includes(vehicleModelDriverQualification);
  }
  return false;
};

export const validateDriverLicenseCategory = (
  driver: ?Driver,
  vehicle: ?Vehicle
): boolean => {
  if (vehicle && driver) {
    const { rightsCategory = [] } = vehicle.vehicleModel;
    const { qualificationDocuments } = driver;
    const driverCategories = uniq(
      flattenDeep(qualificationDocuments.map((document) => document.categories))
    );
    return (
      rightsCategory &&
      rightsCategory.some((category) => driverCategories.includes(category))
    );
  }
  return false;
};
