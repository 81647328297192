// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import { Card } from './../../components';
import { vehicleGroups } from '../../lib/enum';
import Grid, { GridItem } from './../../components/layout/Grid';
import { Panel, Section } from './../../components/layout';
import Header from './../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';

import type { VehicleWashingTypeToGroupsMap } from '../../lib/types/washingPriceList';
import { vehicleWashingTypeToGroupsMapApi } from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  id: string
};

type State = {
  vehicleWashingTypeToGroupsMap: ?VehicleWashingTypeToGroupsMap
};

class WashingPriceListCard extends Component<Props, State> {
  state = {
    vehicleWashingTypeToGroupsMap: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.id, 10);
    if (id) {
      try {
        const vehicleWashingTypeToGroupsMap = await vehicleWashingTypeToGroupsMapApi.fetchOne(
          id
        );
        this.setState({ vehicleWashingTypeToGroupsMap });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  render() {
    const { vehicleWashingTypeToGroupsMap } = this.state;
    const { id } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/washing-vehicle-type-map">
                Список сопоставления типов ТС для моек
              </Crumb>
              <Crumb to={`/admin/washing-vehicle-type-map/edit/${id}`}>
                {`Сопоставление типа ТС для моек №${id}`}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{`Сопоставление типов ТС для моек №${id}`}</h1>
        </StyledPanel>
        <Section>
          {vehicleWashingTypeToGroupsMap && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Тип ТС">
                    {vehicleWashingTypeToGroupsMap.washingVehicleType}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Марка">
                    {vehicleWashingTypeToGroupsMap.brandName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Группа ТС">
                    {vehicleGroups[vehicleWashingTypeToGroupsMap.vehicleGroup]}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default WashingPriceListCard;
