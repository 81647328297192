// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';

import { Card, Selects } from './../../components';
import Grid, { GridItem } from './../../components/layout/Grid';
import { Spoiler } from './../../components/ui';
import OrgUnitSelect from '../../components/OrgUnitSelect';
import { Filter } from '../../components/hoc';
import type { FilterChildrenParams } from '../../components/hoc/Filter';

const { Field } = Card;
const { UserRoleSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type UsersFilterParams = {
  search?: string,
  'role.id'?: number,
  'employee.nodeId'?: number
};

type Props = {
  filter: UsersFilterParams,
  applyFilter: Function,
  cleanFilter: Function
};

const UserFilter = ({ filter, applyFilter, cleanFilter }: Props) => (
  <Spoiler label="Фильтр">
    <Content>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          applyFilter={applyFilter}
          cleanFilter={cleanFilter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter
          }: FilterChildrenParams<UsersFilterParams>) => (
            <>
              <GridItem>
                <Field label="ФИО">
                  <Input
                    placeholder="Введите ФИО сотрудника"
                    value={values.search}
                    onChange={({ target: { value } }) =>
                      changeValue('search', value)
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Роль">
                  <UserRoleSelect
                    value={values['role.id']}
                    onChange={value => changeValue('role.id', value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Подразделение">
                  <OrgUnitSelect
                    value={values['employee.nodeId']}
                    onChange={value => {
                      changeValue('employee.nodeId', value);
                    }}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </Content>
  </Spoiler>
);

export default UserFilter;
