// @flow

import type { Dispatch } from 'redux';

import type { Maintenance } from './../lib/types';
import { maintenanceApi } from './../lib/api';
import type { Action, ListState, AppState } from './redux';
import { defaultPageSize } from './constants';
import type { FetchListParams } from './../lib/api';

export const FETCH_MAINTENANCES = 'vehicles/maintenance/fetch';

export type MaintenancesState = ListState<Maintenance>;

const initialState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0,
  filters: {}
};

const reducer = (
  state: MaintenancesState = initialState,
  { type, payload }: Action
): MaintenancesState => {
  switch (type) {
    case FETCH_MAINTENANCES:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export const fetchMaintenances: Function = (
  page: number = 1,
  params: FetchListParams<any> = {}
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const { pageSize } = getState().maintenances;
  const { data, totalCount } = await maintenanceApi.fetchMaintenances({
    page,
    pageSize,
    ...params
  });
  dispatch({
    type: FETCH_MAINTENANCES,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export default reducer;
