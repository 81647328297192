// @flow
import React from 'react';
import styled from 'styled-components';

import type { NodeFilterType, Vehicle } from '../../lib/types';
import {
  Selects,
  Card,
  Filter,
  FilterContainer,
  FilterItem,
  FilterButtonsContainer
} from './../../components';
import OrgUnitSelect from '../../components/OrgUnitSelect';
import type { FilterChildrenParams } from '../../components/hoc/Filter';
import { applyMaskToValue } from '../../lib/helpers';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';
import { ownerTypes } from '../../lib/enum';
import { Slider } from 'antd';

const { DriverSelect, VehicleSelect } = Selects;
const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;

export type DriverFilterParams = {
  'employee.nodeId'?: number,
  nodeId?: number,
  id?: number,
  nodeFilterType?: NodeFilterType,
  vehicleId?: number,
  drivingRatingFrom?: number,
  drivingRatingTo?: number
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: DriverFilterParams
};

const DriverFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Content>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter,
          handleAdditional,
          showAdditional
        }: FilterChildrenParams<DriverFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <DriverSelect
                  size="small"
                  value={values.id}
                  onChange={(value: number) => changeValue('id', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onChange={value => changeValue('employee.nodeId', value)}
                  value={values['employee.nodeId']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  placeholder="Закрепленное ТС"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) =>
                    applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )
                  }
                  filter={{
                    ownerType: ownerTypes.self
                  }}
                  onChange={value => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Slider
                  size="small"
                  range
                  defaultValue={[0, 5]}
                  step={0.1}
                  max={5}
                  marks={{ '0': '0', '5': '5' }}
                  onAfterChange={value => {
                    changeValue('drivingRatingFrom', value[0]);
                    changeValue('drivingRatingTo', value[1]);
                  }}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </Content>
);

export default DriverFilter;
