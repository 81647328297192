// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import { OrgUnitSelect, Filter, Selects } from './../../components';
import { Spoiler } from './../../components/ui';
import Grid, { GridItem } from './../../components/layout/Grid';
import Field from '../../components/card/Field';
import type { NodeFilterType } from './../../lib/types';
import type { FilterChildrenParams } from './../../components/hoc/Filter';

const Content = styled(Grid)`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  margin-top: 16px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

const { YesNoSelect } = Selects;

export type LocationFilterParams = {
  nodeId?: number,
  isDefault?: boolean,
  nodeFilterType?: NodeFilterType
};

type Props = {
  applyFilter: (filters: LocationFilterParams) => void,
  cleanFilter: Function,
  filter: LocationFilterParams
};

const LocationFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Spoiler label="Фильтр">
    <Content cols={3} gutter="16px">
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<LocationFilterParams>) => (
          <>
            <GridItem>
              <Field label="Организация">
                <OrgUnitSelect
                  value={values['nodeId']}
                  onChange={(value: number) => changeValue('nodeId', value)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Является стоянкой">
                <YesNoSelect
                  allowClear
                  value={values['isDefault']}
                  onChange={(value: boolean) => changeValue('isDefault', value)}
                />
              </Field>
            </GridItem>
            <Buttons fullWidth>
              <Button type="primary" onClick={applyFilter}>
                Подтвердить
              </Button>
              <Button type="plain" onClick={cleanFilter}>
                Отменить
              </Button>
            </Buttons>
          </>
        )}
      </Filter>
    </Content>
  </Spoiler>
);

export default LocationFilter;
