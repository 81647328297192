// @flow

import React, { Component, Fragment } from 'react';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import moment from 'moment';
import styled from 'styled-components';

import type { Vehicle } from './../../lib/types';
import { cleanVehicle, updateVehicle, addVehicle } from './../../ducks/vehicle';
import { fetchOrgUnits } from './../../ducks/orgUnits';
import { fetchVehicle } from '../../ducks/vehicle';
import type { AppState } from '../../ducks/redux';
import InnerForm from './components/InnerForm';
import { Panel } from './../../components/layout';
import { formatDateTimeToISOString } from './../../lib/helpers';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { vehicleTypes, ownerTypes } from '../../lib/enum';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  vehicle: Vehicle,
  updateVehicle: Function,
  orgUnitId: ?number,
  vehicleId: ?number,
  vehicles: Array<Vehicle>,
  fetchVehicle: (id: ?number) => Promise<void>,
  fetchOrgUnits: Function,
  uri: string,
  cleanVehicle: Function,
  addVehicle: Function,
  employeeBranchOrgUnitId: number,
};

class VehicleForm extends Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    if (vehicleId) {
      try {
        await this.props.fetchVehicle(vehicleId);
      } catch (error) {
        navigate('/vehicles');
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  }

  formatDate = (value) => {
    return value ? moment.utc(value) : value;
  };

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        await this.props.updateVehicle(values);
      } else {
        await this.props.addVehicle({
          ...values,
          ownerType: ownerTypes.self,
        });
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate(`/vehicles/${this.props.vehicle.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { vehicle, employeeBranchOrgUnitId } = this.props;
    const vehicleId = parseInt(this.props.vehicleId, 10);
    return (
      <Fragment>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {vehicle ? (
                <Fragment>
                  <Crumb to={`/vehicles/${vehicle.id}`}>
                    {vehicle.vehicleModel &&
                      `${vehicleTypes[vehicle.vehicleModel.type]} ${
                        vehicle.vehicleModel.brandName
                      } ${vehicle.vehicleModel.name}`}
                    , {vehicle.yearIssued} ({vehicle.licensePlate})
                  </Crumb>
                  <Crumb to={`/vehicles/edit/${vehicle.id}`}>
                    Редактирование
                  </Crumb>
                </Fragment>
              ) : (
                <Crumb to="/vehicles/new">Новое транспортное средство</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{vehicle ? `Транспорт №${vehicle.id}` : 'Новый транспорт'}</h1>
        </StyledPanel>
        <InnerForm
          vehicle={vehicle}
          employeeBranchOrgUnitId={employeeBranchOrgUnitId}
          onSubmit={this.onSubmit}
          onCancel={() =>
            vehicleId
              ? navigate(`/vehicles/${vehicleId}`)
              : navigate('/vehicles')
          }
          formatDate={this.formatDate}
          changeDate={formatDateTimeToISOString}
        />
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10),
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  }),
  {
    updateVehicle,
    fetchOrgUnits,
    fetchVehicle,
    cleanVehicle,
    addVehicle,
  }
)(VehicleForm);
