// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';

import MaskInput from '../MaskInput';

/**
 * Маска VIN номера
 */
export default (props: InputProps) => (
  <MaskInput
    mask="*****************"
    formatChars={{
      '*': '[a-zA-Z0-9]'
    }}
    upperCase
    placeholder="1B6HL26Y0RW143905"
    convertOnSave={(value: string) => value || null}
    {...props}
  />
);
