// @flow
import React from 'react';
import moment from 'moment';
import notification from 'antd/lib/notification';

import { reportScheduleMaintenanceApi } from '../../../lib/api';
import type { ScheduleMaintenance } from '../../../lib/types';
import { vehicleTypes } from './../../../lib/enum';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { notificationLoading } from './../../../components/Notifications';
import Filter, { type ScheduleMaintenanceFilter } from './components/Filter';

const initialParams: ScheduleMaintenanceFilter = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  filter: ScheduleMaintenanceFilter,
  data: ScheduleMaintenance[],
  loading: boolean,
  scrollY: number,
};

/**
 * Отчет о таксировках
 */
export default class extends React.Component<{}, State> {
  state = {
    filter: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    await this.fetchMaintenanceSchedule();
  }

  fetchMaintenanceSchedule = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportScheduleMaintenanceApi.fetchScheduleMaintenance(
        this.state.filter
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyFilter = (filter: ScheduleMaintenanceFilter) => {
    this.setState({ filter: { ...filter } }, this.fetchMaintenanceSchedule);
  };

  cleanFilter = () =>
    this.setState(
      {
        filter: initialParams,
      },
      this.fetchMaintenanceSchedule
    );

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await reportScheduleMaintenanceApi.printScheduleMaintenance(
        this.state.filter
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Служба',
      key: 'branch',
      dataIndex: 'branch',
      width: 150,
    },
    {
      title: 'Тип ТС',
      key: 'type',
      dataIndex: 'type',
      width: 100,
      render: (type: string) => vehicleTypes[type],
    },
    {
      title: 'Гос номер',
      key: 'licensePlate',
      dataIndex: 'licensePlate',
      width: 150,
    },
    {
      title: 'ТО-2 последняя дата проведения',
      key: 'lastActDateTo2',
      dataIndex: 'lastActDateTo2',
      width: 150,
    },
    {
      title: 'ТО-2 очередная дата проведения',
      key: 'nextActDateTo2',
      dataIndex: 'nextActDateTo2',
      width: 150,
    },
    {
      title: 'Сезонное ТО дата проведения',
      key: 'lastActDateSeasonMaintenance',
      dataIndex: 'lastActDateSeasonMaintenance',
      width: 150,
    },
    {
      title: 'Сезонное ТО очередная дата проведения',
      key: 'nextActDateSeasonMaintenance',
      dataIndex: 'nextActDateSeasonMaintenance',
      width: 150,
    },
  ];

  render() {
    const { data, loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>График ТО</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            filter={this.state.filter}
            onExport={this.handleExport}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
            name="ReportsScheduleMaintenance"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1000,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}
