import act from './act';
import budgetAnalysis from './budgetAnalysis';
import contract from './contract';
import summary from './summary';
import details from './details';
import tax from './tax';
import contractVehicle from './contractVehicle';
import vehicle from './vehicle';
import execution from './execution';
import osagoCalculations from './osagoCalculations';
import monthlyWashingPlans from './monthlyWashingPlans';
import oilsAndFluidsConsumptionPlan from './oilsAndFluidsConsumptionPlan';

export default [
    ...oilsAndFluidsConsumptionPlan,
    ...monthlyWashingPlans,
    ...osagoCalculations,
    ...execution,
    ...vehicle,
    ...contractVehicle,
    ...tax,
    ...details,
    ...summary,
    ...act,
    ...budgetAnalysis,
    ...contract,
];