// @flow

import { navigate } from '@reach/router';
import React, { useCallback, useState } from 'react';

import { Card } from '../../components';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { GridItem } from '../../components/layout';
import Grid from '../../components/layout/Grid';

import { userApi } from '../../lib/api';
import { accessTypeEnum } from '../../lib/enum';
import { convertEmployeeToString } from '../../lib/helpers';
import type { User, UserAccess } from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

const { Field } = Card;

type PageProps = {
  id: ?number,
  userAccess: UserAccess[],
};
type InnerProps = {
  user: User,
};

const entryPointPath = '/admin/users/';

const InnerCard = (props: InnerProps) => {
  const { user } = props;
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Логин">{user.userName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Роль">{user.roleName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Сотрудник">
            {convertEmployeeToString(user.employee)}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Подразделение">{user.employee?.orgUnitName}</Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: User, setData] = useState({});

  const canEdit = props.userAccess.some((access) =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingUsersAndRoles,
    ].includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await userApi.deleteUser(data.id);
          await navigate(`${entryPointPath}`);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}${data.id}/edit`)
      : false,
  };

  const onFetch = useCallback(async () => {
    props.id && setData(await userApi.fetchUser(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: [
          { to: entryPointPath, name: 'Список пользователей' },
          { name: 'Карточка пользователя' },
        ],
        mainHeader: 'Пользователь',
        access,
      }}
      onFetch={onFetch}
    >
      <InnerCard user={data} />
    </CommonCardPage>
  );
});
