// @flow
import { EllipsisOutlined } from '@ant-design/icons';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';

import { selfRegulationsApi } from '../../lib/api';
import type { SelfVehicleRegulationStatus, UserAccess } from '../../lib/types';
import {
  selfRegulationStatuses,
  selfRegulationStatusesEnum,
} from '../../lib/enum/selfRegulationStatus';

import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import { Popconfirm, Table, Dropdown } from './../../components/ui';

import withUserAccess from '../withUserAccess/withUserAccess';

import { canAdd } from './accessRight';

const route = '/self-regulations';
const COLUMNS = [
  { title: 'Год', dataIndex: 'year' },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (value: SelfVehicleRegulationStatus) =>
      selfRegulationStatuses[value],
  },
];
type Props = {
  userAccess: UserAccess[],
};
export const SelfRegulationsList = withUserAccess(({ userAccess }: Props) => {
  const [data, setData] = useState([]);
  const dropdown = useRef();

  const handleCreateRegulations = useCallback(() => {
    navigate(`${route}/new`);
  }, []);

  const handleEdit = useCallback((id: number) => {
    navigate(`${route}/${id}`);
  }, []);

  async function fetchData() {
    const { data } = await selfRegulationsApi.fetch();
    setData(data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id: number) => {
    try {
      await selfRegulationsApi.delete(id);
      await fetchData();
      if (dropdown.current) {
        dropdown.current.onVisibleChange(false);
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  };
  const columns = canAdd(userAccess)
    ? [
        ...COLUMNS,
        {
          key: 'operations',
          dataIndex: 'id',
          width: '20px',
          stopPropagation: true,
          render: (id: number, regulation) => (
            <Dropdown
              ref={dropdown}
              overlay={
                <Menu>
                  {regulation.status !==
                    selfRegulationStatusesEnum.approved && (
                    <Menu.Item onClick={() => handleEdit(id)}>
                      Редактировать
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    <Popconfirm
                      zIndex={2000}
                      onConfirm={async () => await handleDelete(id)}
                      title="Вы уверены что хотите удалить группу?"
                    >
                      <p>Удалить</p>
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
            </Dropdown>
          ),
        },
      ]
    : [...COLUMNS];
  return (
    <>
      <Header
        left={<h1>Регламенты</h1>}
        right={
          canAdd(userAccess) && (
            <Button type="primary" onClick={handleCreateRegulations}>
              Создать
            </Button>
          )
        }
      />
      <Section>
        <Table
          data={data}
          onRow={(record) => ({
            onClick: () => navigate(`${route}/${record.id}`),
          })}
          columns={columns}
        />
      </Section>
    </>
  );
});

export default SelfRegulationsList;
