// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';

import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {
  OsagoCalculation,
  ListState,
  VehicleModel,
  Vehicle,
  VehicleStatus as VehicleStatusType,
} from '../../../lib/types';
import {
  osagoCalculationApi,
  osagoCalculationYearApi,
  vehicleApi,
  type FetchListParams,
} from './../../../lib/api';
import {
  getListInitialState,
  applyMaskToValue,
  getValueObject,
} from './../../../lib/helpers';
import { vehicleGroups, vehicleTypes, ownerTypes } from './../../../lib/enum';

import type { AppState } from './../../../ducks/redux';
import { Popconfirm, Icon, Operations, Table } from './../../../components/ui';
import { Section, SectionTitle, Footer } from './../../../components/layout';

import Header from './../../../components/layout/Header';
import { VehicleStatus } from './../../../components';
import VehicleFilter, {
  type VehicleFilterParams,
} from './../../Vehicles/Filter';
import {
  setSelectedVehicles,
  clearData,
  deleteOsagoCalculation,
  addFilterOsagoCalculation,
  addUnselectOsagoCalculation,
  setFilterOsagoCalculation,
  setUnselectOsagoCalculation,
} from './../../../ducks/osagoCalculation';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  padding: 10px;
`;

const { TextArea } = Input;

type Props = {
  location: Location & { state: { page: number } },
  selectedVehicleIds: number[],
  filter: VehicleFilterParams,
  unSelectedVehicleIds: any,
  freeVehicleOsagoCalculations: OsagoCalculation[],
  addUnselectOsagoCalculation: Function,
  setUnselectOsagoCalculation: Function,
  addFilterOsagoCalculation: Function,
  setFilterOsagoCalculation: Function,
  setSelectedVehicles: (selectedVehicleIds: number[]) => void,
  clearData: () => Promise<void>,
  deleteOsagoCalculation: (id: number) => Promise<void>,
  employeeBranchOrgUnitId: number,
};

// ListState<OsagoCalculation>
type State = {
  vehicles: ListState<Vehicle>,
  comment: ?string,
  year: number,
};

export class OsagoCalculationsList extends Component<Props, State> {
  state = {
    vehicles: {
      ...getListInitialState(),
    },
    comment: null,
    year: new Date().getFullYear(),
  };

  columns = [
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: true,
      render: (status: VehicleStatusType) => <VehicleStatus status={status} />,
    },
    {
      title: 'Гос. номер',
      sorter: true,
      dataIndex: 'licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Год выпуска',
      sorter: true,
      dataIndex: 'yearIssued',
    },
    {
      title: 'Марка',
      sorter: true,
      sorterKey: 'vehicleModel.brand.name',
      dataIndex: 'vehicleModel.brandName',
    },
    {
      title: 'Модель',
      sorter: true,
      dataIndex: 'vehicleModel.name',
    },
    {
      title: 'Тип',
      dataIndex: 'vehicleModel',
      sorter: true,
      render: (vehicleModel: ?VehicleModel): ?string =>
        vehicleModel && vehicleTypes[vehicleModel.type],
    },
    {
      title: 'Подразделение',
      sorter: true,
      dataIndex: 'orgUnitName',
      sorterKey: 'node.name',
      width: 400,
      breakByWidth: true,
    },
  ];

  osagoCalculationColumns = [
    {
      title: 'Тип/Модель',
      dataIndex: 'vehicleModel',
      render: (vehicleModel: VehicleModel, record: OsagoCalculation) => {
        if (vehicleModel) {
          return [vehicleModel.brandName, vehicleModel.name].join(' ').trim();
        }
        return record.vehicleType
          ? vehicleTypes[record.vehicleType]
          : vehicleGroups[record.vehicleGroup];
      },
    },
    {
      title: 'Год выпуска',
      dataIndex: 'yearIssued',
      sorter: true,
    },
    {
      title: 'Мощность двигателя',
      dataIndex: 'enginePower',
      render: (enginePower: number, record: OsagoCalculation) =>
        enginePower
          ? enginePower
          : getValueObject(record, 'vehicleModel.enginePower'),
    },
    {
      title: 'Кол-во пассажирских мест',
      dataIndex: 'seatsCount',
      render: (seatsCount: number, record: OsagoCalculation) => {
        if (!!seatsCount) {
          return `${seatsCount} шт`;
        }
        const passengerSeatsNumber = getValueObject(
          record,
          'vehicleModel.passengerSeatsNumber'
        );
        return passengerSeatsNumber ? `${passengerSeatsNumber} шт` : null;
      },
    },
    {
      title: 'Максимально разрешенная масса',
      dataIndex: 'maxWeight',
      render: (maxWeight: number, record: OsagoCalculation) => {
        if (!!maxWeight) {
          return `${maxWeight} кг`;
        }
        const maximumAuthorizedMass = getValueObject(
          record,
          'vehicleModel.maximumAuthorizedMass'
        );
        return maximumAuthorizedMass ? `${maximumAuthorizedMass} кг` : null;
      },
    },
    {
      title: '',
      width: '30px',
      render: (record: OsagoCalculation) => (
        <Operations>
          <Popconfirm
            title="Вы действительно хотите удалить?"
            okText="Да"
            cancelText="Нет"
            // $FlowFixMe поставил намеренно, т.к. id всегда задается в этом компоненте строкой
            onConfirm={() => this.props.deleteOsagoCalculation(record.id)}
          >
            <StyledIcon type="x" />
          </Popconfirm>
        </Operations>
      ),
    },
  ];

  fetchVehicles = async (
    page: number = 1,
    params: FetchListParams<VehicleFilterParams> = {}
  ) => {
    try {
      const { filter } = this.props;
      this.updateVehicleState({
        loading: true,
      });
      const { data, totalCount } = await vehicleApi.fetchVehicles({
        ...filter,
        page,
        'vehicleModel.osagoNeeded': true,
        ownerType: ownerTypes.self,
        ...params,
      });
      this.updateVehicleState({
        data,
        totalCount,
        page,
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      this.updateVehicleState({
        loading: false,
      });
    }
  };

  updateVehicleState = (data: Object, cb: any) =>
    this.setState(
      (prevState: State) => ({
        vehicles: {
          ...prevState.vehicles,
          ...data,
        },
      }),
      cb instanceof Function ? cb : () => {}
    );

  cleanVehicleFilter = async () => {
    await this.props.setFilterOsagoCalculation({});
    await this.props.setUnselectOsagoCalculation({});
    this.fetchVehicles();
  };

  applyVehicleFilter = async (filter: VehicleFilterParams) => {
    await this.props.setFilterOsagoCalculation(filter);
    await this.props.setUnselectOsagoCalculation({});
    this.updateVehicleState({ page: 1 }, this.fetchVehicles);
  };

  componentDidMount() {
    const { vehicles } = this.state;
    const { page, ...filter } = qs.parse(window.location.search);
    const { year } = qs.parse(this.props.location.search);
    this.props.addFilterOsagoCalculation(filter);
    this.setState(
      {
        vehicles: {
          ...vehicles,
        },
        year: year ? year : new Date().getFullYear(),
      },
      () => this.fetchVehicles(page)
    );
  }

  handleRowClick = (id: number) => {
    navigate(
      `/budget/osago-calculations/new/vehicle-free-calculation/${id}?year=${this.state.year}`
    );
  };

  goBack = async () => {
    await this.props.clearData();
    navigate('/budget/osago-calculations');
  };

  save = async () => {
    const { year } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const {
        unSelectedVehicleIds,
        freeVehicleOsagoCalculations,
        employeeBranchOrgUnitId,
        filter,
      } = this.props;
      const { comment } = this.state;
      const osagoCalculationYear =
        await osagoCalculationYearApi.addOsagoCalculationYear({
          year,
          comment,
        });
      await osagoCalculationApi.addVehicles(
        {
          osagoCalculationYearId: osagoCalculationYear.id,
          osagoCalculationIds: Object.keys(unSelectedVehicleIds).map((id) =>
            parseInt(id)
          ),
        },
        {
          ...filter,
          'vehicleModel.osagoNeeded': true,
          ownerType: ownerTypes.self,
        }
      );
      await Promise.all(
        freeVehicleOsagoCalculations.map(
          ({ id, ...osagoCalculation }: OsagoCalculation) =>
            osagoCalculationApi.addOsagoCalculation({
              ...osagoCalculation,
              osagoCalculationYearId: osagoCalculationYear.id,
              orgUnitId: employeeBranchOrgUnitId,
            })
        )
      );
      notification.success({
        message: 'Успешно',
        description: 'Расчеты созданы',
      });

      this.goBack();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  onSelect = (record: Vehicle, selected: boolean) => {
    let { unSelectedVehicleIds } = this.props;
    if (!selected) {
      unSelectedVehicleIds[record['id']] = record;
    } else {
      delete unSelectedVehicleIds[record['id']];
    }
    this.props.addUnselectOsagoCalculation(unSelectedVehicleIds);
  };

  onSelectAll = (selected: boolean, selectedRecord: any[]) => {
    let { unSelectedVehicleIds } = this.props;
    let { vehicles } = this.state;
    if (selected) {
      selectedRecord.forEach(
        (item) =>
          unSelectedVehicleIds[item['id']] &&
          delete unSelectedVehicleIds[item['id']]
      );
    } else {
      vehicles.data.forEach(
        (item) => (unSelectedVehicleIds[item['id']] = item)
      );
    }
    this.props.addUnselectOsagoCalculation(unSelectedVehicleIds);
  };

  setSelectedRowKeys = () => {
    const { unSelectedVehicleIds } = this.props;
    const { vehicles } = this.state;
    // $FlowFixMe
    return vehicles.data
      .map((item: any, index: number) => {
        if (unSelectedVehicleIds[item.id]) {
          return false;
        }
        return index;
      })
      .filter((item) => item !== false);
  };

  render() {
    const { freeVehicleOsagoCalculations, filter } = this.props;
    const { vehicles, comment, year } = this.state;
    const selectedVehicleIds = this.setSelectedRowKeys();

    return (
      <>
        <Header
          left={
            <h1>Расчет потребностей ОСАГО {year ? `на ${year} год` : null}</h1>
          }
        />
        <Section>
          <SectionTitle divider>Текущие ТС</SectionTitle>
          <VehicleFilter
            filter={filter}
            cleanFilter={this.cleanVehicleFilter}
            applyFilter={this.applyVehicleFilter}
          />
          <Table
            columns={this.columns}
            rowSelection={{
              selectedRowKeys: selectedVehicleIds,
              onSelect: this.onSelect,
              onSelectAll: this.onSelectAll,
            }}
            fetch={this.fetchVehicles}
            data={vehicles.data}
            loading={vehicles.loading}
            pagination={{
              page: vehicles.page,
              totalCount: vehicles.totalCount,
              pageSize: vehicles.pageSize,
            }}
          />
        </Section>
        <Section>
          <SectionTitle
            divider
            suffix={
              <Link
                to={`/budget/osago-calculations/new/vehicle-free-calculation?year=${year}`}
              >
                <Button type="primary" data-cy="add">
                  Добавить новый ТС
                </Button>
              </Link>
            }
          >
            Планируемые ТС
          </SectionTitle>
          {/* <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            rowKey="id"
            columns={this.osagoCalculationColumns}
            data={freeVehicleOsagoCalculations.map((item) => ({
              ...item,
              key: item.id,
            }))} */}
          />
        </Section>
        <Section>
          <SectionTitle divider>Комментарий</SectionTitle>
          <StyledDiv>
            <TextArea
              value={comment || ''}
              onChange={(e) => this.setState({ comment: e.target.value })}
              autosize={{ minRows: 2, maxRows: 6 }}
            />
          </StyledDiv>
        </Section>
        <Footer>
          <Popconfirm
            title={
              <p>
                Вы действительно хотите отменить? <br />
                Все несохраненные изменения будут потеряны
              </p>
            }
            okText="Да"
            cancelText="Нет"
            onConfirm={this.goBack}
          >
            <Button>Отменить</Button>
          </Popconfirm>
          <Button type="primary" onClick={this.save}>
            Сохранить
          </Button>
        </Footer>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    filter: state.osagoCalculation.filter,
    unSelectedVehicleIds: state.osagoCalculation.unSelectedVehicleIds,
    selectedVehicleIds: state.osagoCalculation.selectedVehicleIds,
    freeVehicleOsagoCalculations: state.osagoCalculation.freeVehicleItems,
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  }),
  {
    setSelectedVehicles,
    clearData,
    addFilterOsagoCalculation,
    addUnselectOsagoCalculation,
    setFilterOsagoCalculation,
    setUnselectOsagoCalculation,
    deleteOsagoCalculation,
  }
)(OsagoCalculationsList);
