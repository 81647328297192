import { JobTitleForm, JobTitleList } from '../../containers/JobTitle';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: JobTitleForm,
        path: '/admin/job-titles/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingJobTitles,
        ],
    },
    {
        component: JobTitleForm,
        path: '/admin/job-titles/:jobTitleId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingJobTitles,
        ],
    },
    {
        component: JobTitleList,
        path: '/admin/job-titles',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingAllJobTitles,
            accessTypeEnum.viewingJobTitles,
        ],
    },
];