// @flow
import type { BudgetLineItem } from './../types';
import CommonApi from './../commonApi';

const budgetLineItem = new CommonApi<BudgetLineItem>(
  'budgetLineItem',
  'статья бюджетной формы'
);

export default budgetLineItem;
