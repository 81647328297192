// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import moment from 'moment';
import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Input from 'antd/lib/input';
import { DatePicker, RangePicker } from '../../../components/ant/DatePicker';

import type { Pass } from '../../../lib/types';
import { passApi } from './../../../lib/api';
import { formatDateTimeToISOString, goBack } from '../../../lib/helpers';

import { Selects, Form, CancelButton } from './../../../components';
import {
  Panel,
  Section,
  Header,
  SectionTitle
} from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { notificationLoading } from '../../../components/Notifications';
import { InputNumber } from '../../../components/inputs';

const { VehicleSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  pass: Pass,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  pass,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={pass} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        values
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          {showVehicleSelect && (
            <Section>
              <SectionTitle divider>Данные ТС</SectionTitle>
              <Content>
                <Grid cols={3}>
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
          )}
          <Section>
            <SectionTitle divider>Данные пропуска</SectionTitle>
            <Content>
              <Grid gutter="16px" cols={4}>
                <GridItem>
                  <FormField label="Наименование пропуска" required name="name">
                    {fieldProps => <Input {...fieldProps} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Номер пропуска" required name="number">
                    {fieldProps => <Input {...fieldProps} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата выдачи"
                    required
                    name="maintenanceDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : undefined}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                        name={name}
                        disabledDate={(currentDate: moment) =>
                          moment(currentDate).isBefore(
                            moment.utc('01.01.1980', 'DD.MM.YYYY')
                          )
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="startDate" required label="Период действия">
                    {({ name, value }) => (
                      <RangePicker
                        format="DD.MM.YYYY"
                        placeholder={['Начало', 'Конец']}
                        value={[
                          values.startDate
                            ? moment.utc(values.startDate)
                            : null,
                          values.endDate ? moment.utc(values.endDate) : null
                        ]}
                        onChange={(value, dateString) => {
                          const [startDate, endDate] = value;
                          const [startDateString, endDateString] = dateString;
                          setFieldValue(
                            'startDate',
                            formatDateTimeToISOString(
                              startDate,
                              startDateString
                            )
                          );
                          setFieldValue(
                            'endDate',
                            formatDateTimeToISOString(endDate, endDateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Стоимость пропуска, руб."
                    name="paymentAmountPass"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        name={name}
                        value={value}
                        onChange={(value: number) => {
                          setFieldValue(
                            'paymentAmount',
                            value + values.paymentAmountTax
                          );
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Стоимость госпошлины, руб."
                    name="paymentAmountTax"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        name={name}
                        value={value}
                        onChange={(value: number) => {
                          setFieldValue(
                            'paymentAmount',
                            value + values.paymentAmountPass
                          );
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Стоимость итого, руб."
                    name="paymentAmount"
                  >
                    {({ name, value }) => (
                      <InputNumber disabled name={name} value={value} />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>

          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  passId: ?number,
  vehicleId: ?number
};

type State = {
  pass: $Shape<Pass>
};

class PassForm extends Component<Props, State> {
  state = {
    pass: {}
  };

  async componentDidMount() {
    try {
      const passId = parseInt(this.props.passId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (passId) {
        const pass = await passApi.fetchPass(passId);
        this.setState({
          pass
        });
      } else if (vehicleId) {
        this.setState({
          pass: {
            vehicleId
          }
        });
      } else {
        this.setState({
          pass: {
            paymentAmount: 0,
            paymentAmountPass: 0,
            paymentAmountTax: 0
          }
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.passId) => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/docuemntation/pass`);
    } else {
      goBack(`/services/pass/${id || ''}`);
    }
  };

  onSubmit = async (pass: Pass) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const passId = parseInt(this.props.passId, 10);
      if (passId) {
        await passApi.updatePass(pass);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(passId);
      } else {
        const added = await passApi.addPass(pass);

        notification.success({
          message: 'Успешное добавление',
          description: `Пропуск успешно добавлен`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const passId = parseInt(this.props.passId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { pass } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {vehicleId > 0 ? (
                <Crumb to={`/vehicles/${vehicleId}`}>ТС</Crumb>
              ) : (
                <Crumb to={'/services/pass'}>Список пропусков</Crumb>
              )}
              {passId ? (
                <Crumb>Редактирование пропуска</Crumb>
              ) : (
                <Crumb>Новый пропуск</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{passId ? `Пропуск №${pass.number || ''}` : 'Новый пропуск'}</h1>
        </StyledPanel>
        <InnerForm
          pass={pass}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect(pass.id || passId)}
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default PassForm;
