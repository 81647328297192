// @flow
import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';

import type { Vehicle, VehicleForSelection } from './../../lib/types';
import { Tabs, TabItem } from '../ui';
import Grid, { GridItem } from '../layout/Grid';

import {
  TabWrapper,
  VehicleCard,
  VehicleInfo,
  Status,
  Wrapper,
  Header,
  VehicleCardWrapper,
  SearchInput,
  SpoilerContent,
} from './VehicleSelection.elements';
import Spinner from '../Spinner';
import Spoiler from '../ui/Spoiler';
import { mapLatToCyr, applyMaskToValue } from '../../lib/helpers';
import { formatLicensePlateMask } from '../inputs/masked-inputs/LicensePlateInput';
import { ownerTypes } from '../../lib/enum';

type Props = {
  // Список ТС
  vehicles: VehicleForSelection[],
  // Только свои или наемные ТС
  onlyType?: 'self' | 'contract',
  // id выбранной ТС
  selected: ?number,
  // Функция выбора ТС
  onSelect: (vehicle: VehicleForSelection) => void,
  editable: boolean,
  loading?: boolean,
};

type State = {
  tabKey: 'self' | 'contract',
  searchString: string,
  filteredVehicles: VehicleForSelection[],
};

/**
 * Компонент рисует сетку из машин, которые доступны для выбора
 * Используется при назначении свободного ТС на заявку
 */
export default class VehicleSelection extends Component<Props, State> {
  static defaultProps = {
    vehicles: [],
    editable: false,
  };

  state = {
    tabKey: this.props.onlyType ? this.props.onlyType : 'self',
    searchString: '',
    filteredVehicles: this.props.vehicles,
  };

  getDefaultTab = () => {
    const { vehicles, selected: id, onlyType } = this.props;
    const finded: Vehicle = find(vehicles, { id });
    if (finded) {
      switch (finded.ownerType) {
        case ownerTypes.contract:
          return 'contract';
        case ownerTypes.self:
          return 'self';
        default:
          return onlyType;
      }
    }
    return 'self';
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.vehicles, this.props.vehicles)) {
      this.debounceFilterVehicles(this.state.searchString);
      this.setState({ tabKey: this.getDefaultTab() });
    }
  }

  renderCard = (vehicle: VehicleForSelection) => {
    const { editable } = this.props;
    const canEdit = editable && !vehicle.disabled;
    return (
      <GridItem key={vehicle.id}>
        <VehicleCard
          className={`vehicle-${vehicle.id}`}
          key={vehicle.id}
          isActive={vehicle.id === this.props.selected}
          onClick={canEdit ? () => this.props.onSelect(vehicle) : null}
          editable={canEdit}
          disabled={vehicle.disabled}
        >
          <VehicleInfo>
            <Status
              status={vehicle.isBusy === 'busy' ? 'busy' : vehicle.status}
              showLabel={false}
            />
            <div className="vehicle-info">
              {vehicle.vehicleModel && (
                <p>
                  {vehicle.vehicleModel.brandName} {vehicle.vehicleModel.name}
                  {vehicle.trailers && vehicle.trailers.length > 0 && (
                    <>
                      <br />
                      (с прицепом)
                    </>
                  )}
                  {parseInt(vehicle.drivingVehicleId, 10) > 0 && (
                    <>
                      <br /> (закреплен за ТС)
                    </>
                  )}
                </p>
              )}
              <p>
                {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
              </p>
            </div>
          </VehicleInfo>
        </VehicleCard>
      </GridItem>
    );
  };

  handleTabChange = (tabKey: 'self' | 'contract') => this.setState({ tabKey });

  /**
   * Фильтрация ТС по гос. номеру
   */
  filterVehicles = async (searchString: string) => {
    let filteredVehicles = this.props.vehicles;

    if (searchString) {
      searchString = mapLatToCyr(searchString).toLowerCase();
      filteredVehicles = filteredVehicles.filter(
        ({ licensePlate }: Vehicle) =>
          licensePlate && licensePlate.toLowerCase().includes(searchString)
      );
    }
    this.setState({
      filteredVehicles,
    });
  };

  debounceFilterVehicles = debounce(this.filterVehicles, 500);

  onSearchVehicle = (e: any) => {
    const searchString = e.target.value;
    this.setState(
      {
        searchString,
      },
      () => this.debounceFilterVehicles(this.state.searchString)
    );
  };

  render() {
    const { onlyType, loading } = this.props;
    const { searchString, filteredVehicles, tabKey } = this.state;
    // const { free, busy } = groupBy(
    //   filteredVehicles.filter(vehicle => vehicle.ownerType === tabKey),
    //   'isBusy'
    // );
    // TODO: вернуть после изменений на беке
    const busy = [];
    const free = filteredVehicles.filter(
      (vehicle) => vehicle.ownerType === tabKey
    );

    const hasBusy = busy && busy.length;
    const hasFree = free && free.length;
    return (
      <Wrapper>
        <Header>
          <p>Транспортные средства</p>
          <TabWrapper>
            <div>
              {onlyType === undefined && (
                <Tabs
                  type="buttons"
                  background="transparent"
                  defaultTab={tabKey}
                  onChange={this.handleTabChange}
                >
                  <TabItem tabKey="self" label="Собственные" />
                  {/*<TabItem tabKey="contract" label="Наемные" />*/}
                </Tabs>
              )}
            </div>
            <SearchInput
              withtabs={(!onlyType).toString()}
              onChange={this.onSearchVehicle}
              value={searchString}
              placeholder="Поиск по гос. номеру"
              size="small"
            />
          </TabWrapper>
        </Header>
        <Spinner isLoading={loading}>
          {!hasFree && !hasBusy && (
            <p
              style={{
                margin: '10px',
                opacity: '0.4',
              }}
            >
              Подходящие ТС не найдены
            </p>
          )}
          {!!hasBusy && !!hasFree && (
            <Spoiler defaultExpanded label="Свободные">
              <SpoilerContent>
                <Grid
                  gutter="8px"
                  rowGutter="8px"
                  cols={2}
                  media={[
                    {
                      size: 'md',
                      cols: 3,
                    },
                    {
                      size: 'lg',
                      cols: 5,
                    },
                  ]}
                >
                  {free.map(this.renderCard)}
                </Grid>
              </SpoilerContent>
            </Spoiler>
          )}

          {!hasBusy && !!hasFree && (
            <VehicleCardWrapper>
              <Grid
                gutter="8px"
                rowGutter="8px"
                cols={2}
                media={[
                  {
                    size: 'md',
                    cols: 3,
                  },
                  {
                    size: 'lg',
                    cols: 5,
                  },
                ]}
              >
                {free.map(this.renderCard)}
              </Grid>
            </VehicleCardWrapper>
          )}

          {!!hasBusy && (
            <Spoiler defaultExpanded label="Занятые">
              <SpoilerContent>
                <Grid
                  gutter="8px"
                  rowGutter="8px"
                  cols={2}
                  media={[
                    {
                      size: 'md',
                      cols: 3,
                    },
                    {
                      size: 'lg',
                      cols: 5,
                    },
                  ]}
                >
                  {busy.map(this.renderCard)}
                </Grid>
              </SpoilerContent>
            </Spoiler>
          )}
        </Spinner>
      </Wrapper>
    );
  }
}
