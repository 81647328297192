import { accessTypeEnum } from './../../../lib/enum';
import type { UserAccess } from './../../../lib/types';

export const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingBranchVehicleTaxPlans,
  accessTypeEnum.handlingAllVehicleTaxPlans
];

// добавление/копирование/редактирование
export const canAdd = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

export default { canAdd };
