// @flow
import { fetchRequest } from './index';
import type { CalculationStatus, OverhaulCalculation } from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import {
  convertDataFromServer as convertDataFromServerMonth,
  convertDataToServer as convertDataToServerMonth
} from './overhaulCalculationMonth';

export const convertDataToServer = (
  overhaulCalculation: OverhaulCalculation
) => ({
  ...overhaulCalculation,
  sum: convertFromRubToKop(overhaulCalculation.sum),
  contractMaterialsSum: convertFromRubToKop(
    overhaulCalculation.contractMaterialsSum
  ),
  internalMaterialsSum: convertFromRubToKop(
    overhaulCalculation.internalMaterialsSum
  ),
  fullMaterialsSum: convertFromRubToKop(overhaulCalculation.fullMaterialsSum),
  contractOperationsSum: convertFromRubToKop(
    overhaulCalculation.contractOperationsSum
  ),
  internalOperationsSum: convertFromRubToKop(
    overhaulCalculation.internalOperationsSum
  ),
  fullOperationsSum: convertFromRubToKop(overhaulCalculation.fullOperationsSum),
  months: overhaulCalculation.months
    ? [...overhaulCalculation.months.map(convertDataToServerMonth)]
    : []
});

export const convertDataFromServer = (
  overhaulCalculation: OverhaulCalculation
) => ({
  ...overhaulCalculation,
  sum: convertFromKopToRub(overhaulCalculation.sum),
  contractMaterialsSum: convertFromKopToRub(
    overhaulCalculation.contractMaterialsSum
  ),
  internalMaterialsSum: convertFromKopToRub(
    overhaulCalculation.internalMaterialsSum
  ),
  fullMaterialsSum: convertFromKopToRub(overhaulCalculation.fullMaterialsSum),
  contractOperationsSum: convertFromKopToRub(
    overhaulCalculation.contractOperationsSum
  ),
  internalOperationsSum: convertFromKopToRub(
    overhaulCalculation.internalOperationsSum
  ),
  fullOperationsSum: convertFromKopToRub(overhaulCalculation.fullOperationsSum),
  months: overhaulCalculation.months
    ? [...overhaulCalculation.months.map(convertDataFromServerMonth)]
    : []
});

const basedUrl = 'overhaulCalculation';
const overhaulCalculation = new CommonApi<OverhaulCalculation>(
  basedUrl,
  'Расчет плана кап. ремонтов',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  vehiclePlanId: number
): Promise<OverhaulCalculation> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

export default {
  ...overhaulCalculation,
  changeStatus,
  calculate,
  updateCalculations
};
