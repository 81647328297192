// @flow
import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { settingApi } from '../../lib/api';
import CommonListPage from '../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from '../withUserAccess';

import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/setting/';

const columns = [
  {
    title: 'Ключ',
    dataIndex: 'key',
  },
  {
    title: 'Значение',
    dataIndex: 'value',
  },
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {};

export default withUserAccess(({ userAccess }) => {
  useEffect(() => {
    fetch();
  }, []);
  const fetch = async () => {
    const fields = [
      'WialonApiBaseUrl',
      'WialonApiToken',
      'WialonReportId',
      'WialonBaseUrl',
    ];
    const data = await Promise.all(
      fields.map(async (settingKey) => {
        return await settingApi.fetch({ settingKey });
      })
    );
    return {
      data,
      totalCount: data.length,
    };
  };
  return (
    <Page>
      <CommonListPage
        crud={{
          fetch,
          add: () => {},
          delete: () => {},
          get: () => {},
          update: () => {},
        }}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Настройки системы',
          access,
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
        }}
      />
    </Page>
  );
});
