// @flow
import { InfoCircleOutlined } from '@ant-design/icons';
import React from 'react';
import moment from 'moment';

import type {
  Driver,
  DrivingLicenseCategory,
  TractorDrivingLicenseCategory
} from '../../../lib/types';
import { Popover } from '../../../components/ui';

import {
  driverQualifications,
  drivingLicenseCategories,
  tractorDrivingLicenseCategories
} from '../../../lib/enum';

import { convertEmployeeToString } from '../../../lib/helpers';

type Props = {
  // Водитель
  driver?: Driver
};

const licenseCategories = {
  ...drivingLicenseCategories,
  ...tractorDrivingLicenseCategories
};

/**
 * Инфо водителя
 */
function renderCategories(
  categories: Array<DrivingLicenseCategory & TractorDrivingLicenseCategory>
) {
  categories = categories.filter(category => licenseCategories[category]);

  if (categories.length === 0) {
    return null;
  }

  return (
    <div>Категории: {categories.map(c => licenseCategories[c] + ' ')}</div>
  );
}

export const DriverInfoPopover = ({ driver }: Props) => {
  if (!driver || !driver.employee) {
    return null;
  }
  if (driver) {
    const { qualificationDocuments } = driver;

    return (
      <Popover
        overlayStyle={{ zIndex: 9999 }}
        width={200}
        title={
          <a
            onClick={e => e.stopPropagation()}
            href={`/admin/drivers/${driver.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {convertEmployeeToString(driver.employee)}
          </a>
        }
        content={
          <>
            {qualificationDocuments.map((doc, i) => (
              <div key={i}>
                {driverQualifications[doc.qualification]} {doc.documentNumber}
                {doc.documentEndDate && (
                  <div>
                    Срок действия:&nbsp;
                    {moment(doc.documentEndDate).format('DD.MM.YYYY')}
                  </div>
                )}
                {renderCategories(doc.categories)}
                {i !== qualificationDocuments.length - 1 && <hr />}
              </div>
            ))}
          </>
        }
      >
        <InfoCircleOutlined
          style={{
            cursor: 'help',
            color: '#0000FF',
            marginLeft: '8px'
          }}
        />
      </Popover>
    );
  }
  return null;
};

export default DriverInfoPopover;
