// @flow
import type {
  BatteryCalculation,
  BatteryCalculationMonth,
  CalculationStatus
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  batteryCalculation: BatteryCalculation
): BatteryCalculation => ({
  ...batteryCalculation,
  sum: convertFromRubToKop(batteryCalculation.sum),
  months: batteryCalculation.months.map(
    convertDataToServerBatteryCalculationMonth
  )
});

export const convertDataFromServer = (
  batteryCalculation: BatteryCalculation
): BatteryCalculation => ({
  ...batteryCalculation,
  sum: convertFromKopToRub(batteryCalculation.sum),
  months: batteryCalculation.months.map(
    convertDataFromServerBatteryCalculationMonth
  )
});

export const convertDataToServerBatteryCalculationMonth = (
  batteryCalculationMonth: BatteryCalculationMonth
): BatteryCalculationMonth => ({
  ...batteryCalculationMonth,
  cost: convertFromRubToKop(batteryCalculationMonth.cost)
});

export const convertDataFromServerBatteryCalculationMonth = (
  batteryCalculationMonth: BatteryCalculationMonth
): BatteryCalculationMonth => ({
  ...batteryCalculationMonth,
  cost: convertFromKopToRub(batteryCalculationMonth.cost)
});

const basedUrl = 'batteryCalculation';
const batteryCalculation = new CommonApi<BatteryCalculation>(
  basedUrl,
  'расчет стоимости замены АКБ',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const print = await downloadRequestWithToken(`/batteryCalculation/print`, {
    ...filter,
    vehiclePlanId
  });
  if (print) return print;
  throw new Error('Не удалось распечатать месячный план');
};

export default {
  ...batteryCalculation,
  print,
  calculate,
  changeStatus
};
