// @flow

import React, { PureComponent } from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';

import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import type {
  Contractor,
  ListState,
  ServiceType,
  UserAccess,
} from '../../lib/types';

import { Filter } from './components';
import Header from '../../components/layout/Header';
import { serviceTypes } from '../../lib/enum/serviceTypes';
import type { FetchListParams } from '../../lib/api';
import { accessTypeEnum } from '../../lib/enum';
import { contractorApi } from '../../lib/api';
import { getListInitialState, setQueryParams } from '../../lib/helpers';
import type { ContractorFilterParams } from './Filter';
import { notificationLoading } from './../../components/Notifications';
import { withUserAccess } from './../withUserAccess';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};

type State = ListState<Contractor> & {
  filter: ContractorFilterParams,
};

export class ContractorsList extends PureComponent<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  async deleteContractor(id: number) {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await contractorApi.deleteContractor(id);
      notification.success({
        message: 'Успешное удаление',
        description: `Подрядчик с идентификатором ${id} был успешно удален`,
      });
      await this.fetchContractors(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
    },

    {
      title: 'Юридическое лицо',
      dataIndex: 'company.name',
      sorterKey: 'companyId',
      sorter: true,
    },
    {
      title: 'Виды услуг',
      dataIndex: 'services',
      sorter: true,
      render: (services: ServiceType[]) =>
        services !== undefined && (
          <ul>
            {services.map((serviceType) => (
              <li>{serviceTypes[serviceType]}</li>
            ))}
          </ul>
        ),
    },
  ];

  canEditing = () =>
    this.props.userAccess.some((access) =>
      [accessTypeEnum.admin, accessTypeEnum.handlingContractors].includes(
        access
      )
    );

  fetchContractors = async (
    page: number = 1,
    params: FetchListParams<ContractorFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await contractorApi.fetchContractors({
      ...filter,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  cleanFilter = () => this.setState({ filter: {} }, this.fetchContractors);

  applyFilter = (filter: ContractorFilterParams) =>
    this.setState({ filter }, this.fetchContractors);

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.fetchContractors(page));
  }

  handleRowClick = (id: number) => navigate(`/admin/contractors/${id}`);

  render() {
    const { location } = this.props;
    const { data, totalCount, page, pageSize, loading, filter } = this.state;
    const canEdit = this.canEditing();
    return (
      <>
        <Header
          left={<h1>Подрядчики</h1>}
          right={
            canEdit && (
              <Link to="/admin/contractors/new">
                <Button type="primary" data-cy="add">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            columns={
              canEdit
                ? [
                    ...this.columns,
                    {
                      title: '',
                      dataIndex: 'operations',
                      // eslint-disable-next-line no-unused-vars
                      render: (text: string, model: Contractor) => (
                        <Operations>
                          <Popconfirm
                            title="Вы действительно хотите удалить?"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={() => this.deleteContractor(model.id)}
                          >
                            <StyledIcon type="x" />
                          </Popconfirm>
                        </Operations>
                      ),
                    },
                  ]
                : this.columns
            }
            data={data.map((model) => ({
              ...model,
              key: model.id,
            }))}
            fetch={this.fetchContractors}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(ContractorsList);
