// @flow
import React, { type Node, type ComponentType } from 'react';
import styled from 'styled-components';

import Divider, { DividerColorEnum, type DividerType } from './Divider';

const Header = styled.div.attrs({
  className: 'section__header'
})`
  padding: 13px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.section`
  border-radius: 3px;
  margin: 16px;
  background: white;
  overflow: hidden;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
`;

type SectionTitleProps = {
  divider?: boolean,
  dividerType?: DividerType,
  dividerColor?: $Keys<typeof DividerColorEnum>,
  children: Node,
  suffix?: Node,
  className?: string
};
export const SectionTitle: ComponentType<SectionTitleProps> = ({
  children,
  divider,
  dividerColor,
  dividerType,
  suffix,
  className
}: SectionTitleProps) => (
  <TitleWrapper className={className}>
    <Header>
      <div>{children}</div>
      <div>{suffix}</div>
    </Header>
    {divider && <Divider type={dividerType} color={dividerColor} />}
  </TitleWrapper>
);

export const SectionContent = styled.div`
  padding: 16px;
`;

export default Section;
