// @flow

import type { MaintenanceWorkType } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const fetchMaintenanceTypeWork = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<MaintenanceWorkType>> => {
  const maintenanceWorkType = await fetchRequest.get('/maintenanceWorkType', {
    ...initialFetchListParams,
    ...params
  });
  if (maintenanceWorkType) return maintenanceWorkType;
  throw new Error('Не удалось получить список видов работ ремонтов');
};

export const getMaintenanceTypeWork = async (
  id: number
): Promise<MaintenanceWorkType> => {
  const maintenanceWorkType = await fetchRequest.get(
    `/maintenanceWorkType/${id}`
  );
  if (maintenanceWorkType) return maintenanceWorkType;
  throw new Error('Не удалось получить информацию по виду работ для ремонта');
};

export const addMaintenanceTypeWork = async (
  maintenanceWorkType: MaintenanceWorkType
): Promise<MaintenanceWorkType> => {
  const added = await fetchRequest.post(
    `/maintenanceWorkType`,
    maintenanceWorkType
  );
  if (added) return added;
  throw new Error('Не удалось добавить вид работ для ремонта');
};

export const updateMaintenanceTypeWork = async (
  maintenanceWorkType: MaintenanceWorkType
): Promise<MaintenanceWorkType> => {
  const updeted = await fetchRequest.put(
    `/maintenanceWorkType`,
    maintenanceWorkType
  );
  if (updeted) return updeted;
  throw new Error('Не удалось отредактировать информацию');
};

export const deleteMaintenanceTypeWork = async (
  id: number
): Promise<MaintenanceWorkType> => {
  const deleted = await fetchRequest.delete(`/maintenanceWorkType/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить запись');
};

export default {
  fetchMaintenanceTypeWork,
  getMaintenanceTypeWork,
  addMaintenanceTypeWork,
  updateMaintenanceTypeWork,
  deleteMaintenanceTypeWork
};
