import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal } from 'antd';
import Button from 'antd/lib/button';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { DatePicker } from '../../../components/ant/DatePicker';

import { driverApi } from '../../../lib/api';
import { printNotification } from '../../../lib/notificationWrapper';

const DriverReportModal = (props: {
  visible: boolean,
  onClose: Function,
  onOk: (startDate, endDate: moment) => {}
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Modal
      okText={'Печать'}
      cancelText={'Отмена'}
      visible={props.visible}
      maskClosable
      destroyOnClose
      closable={false}
      title={'Введите период отбора'}
      style={{ top: 20, right: 20, position: 'absolute' }}
      okButtonProps={{ disabled: !(startDate || endDate) }}
      onOk={() => props.onOk(startDate, endDate)}
      onCancel={props.onClose}
    >
      <>
        <DatePicker
          placeholder="Дата начала"
          format="DD.MM.YYYY"
          value={startDate}
          onChange={value =>
            value
              ? setStartDate(
                  moment(value)
                    .utc()
                    .startOf('day')
                )
              : setStartDate(value)
          }
          disabledDate={date =>
            endDate
              ? moment
                  .utc(date)
                  .startOf('day')
                  .isAfter(moment.utc(endDate))
              : false
          }
        />
        <DatePicker
          placeholder="Дата окончания"
          format="DD.MM.YYYY"
          value={endDate}
          onChange={value =>
            value
              ? setEndDate(
                  moment(value)
                    .utc()
                    .endOf('day')
                )
              : setEndDate(value)
          }
          disabledDate={date =>
            startDate
              ? moment
                  .utc(date)
                  .startOf('day')
                  .isBefore(moment.utc(startDate))
              : false
          }
        />
      </>
    </Modal>
  );
};

const DriverQualityReportModal = (props: {
  visible: boolean,
  onClose: Function,
  onOk: (date: moment) => {}
}) => {
  const [date, setDate] = useState(null);

  return (
    <Modal
      okText={'Печать'}
      cancelText={'Отмена'}
      visible={props.visible}
      maskClosable
      destroyOnClose
      closable={false}
      title={'Введите дату отчета'}
      style={{ top: 20, right: 20, position: 'absolute' }}
      okButtonProps={{ disabled: !date }}
      onOk={() => props.onOk(date)}
      onCancel={props.onClose}
    >
      <>
        <DatePicker.MonthPicker
          placeholder="Дата отчета"
          format="MMM YYYY"
          value={date}
          onChange={value =>
            value
              ? setDate(
                  moment(value)
                    .utc()
                    .startOf('month')
                )
              : setDate(value)
          }
        />
      </>
    </Modal>
  );
};

export default () => {
  const [driverReportModalVisible, setDriverReportModalVisible] = useState(
    false
  );
  const [
    driverQualityReportModalVisible,
    setDriverQualityReportModalVisible
  ] = useState(false);

  const printDriversReport = (startDate, endDate: moment) => {
    printNotification(async () => {
      await driverApi.printTrips({
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString()
      });
      setDriverReportModalVisible(false);
    });
  };

  const printDriverQualityReport = async (date: moment) => {
    printNotification(async () => {
      await driverApi.printDrivingRatings({
        date: moment(date).toISOString()
      });
      setDriverQualityReportModalVisible(false);
    });
  };

  const printDriverRegister = async () => {
    printNotification(async () => {
      await driverApi.printDriverRegister();
    });
  };

  const handleMenuClick = useCallback(async ({ key }) => {
    switch (key) {
      case 'printDriversReport':
        setDriverReportModalVisible(true);
        break;
      case 'printDriverQualityReport':
        setDriverQualityReportModalVisible(true);
        break;
      case 'printDriverRegister':
        await printDriverRegister();
        break;
      default:
        break;
    }
  }, []);

  const menu = useCallback(
    () => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="printDriversReport">Отчет по водителям</Menu.Item>
        <Menu.Item key="printDriverQualityReport">
          Отчет качества вождения
        </Menu.Item>
        <Menu.Item key="printDriverRegister">
          Печать реестра водителей
        </Menu.Item>
      </Menu>
    ),
    [handleMenuClick]
  );

  return <>
    <Dropdown overlay={menu}>
      <Button>
        Отчеты
        <DownOutlined />
      </Button>
    </Dropdown>
    <DriverReportModal
      visible={driverReportModalVisible}
      onClose={() => setDriverReportModalVisible(false)}
      onOk={printDriversReport}
    />
    <DriverQualityReportModal
      visible={driverQualityReportModalVisible}
      onClose={() => setDriverQualityReportModalVisible(false)}
      onOk={printDriverQualityReport}
    />
  </>;
};
