// @flow
import styled, { css } from 'styled-components';

export const OptionContent = styled.span.attrs(props => ({
  className: 'optionContent'
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const OptionLeft = styled.div`
  ${({ width }) =>
    css`
      width: ${width || '100%'};
    `}
  width: 100%;
`;
export const OptionRight = styled.div`
  ${({ width }) =>
    css`
      width: ${width || '100%'};
    `}
`;
