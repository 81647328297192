import React from 'react';
import styled, { css } from 'styled-components';

export const Col = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
`;

export const LeftCol = styled.div`
  border-right: 1px solid #c0ccd7;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 6px;
  align-items: center;
  width: 60px;
  box-sizing: border-box;
  font-size: 10px;
`;

export const EmptyCol = styled(LeftCol)`
  border: none;
`;

export const Row = styled.div`
  height: 21px;
  display: flex;
  & ${Col} {
    border-right: 1px solid #c0ccd7;
    &:last-child {
      border-right: none;
    }
  }
`;

export const Footer = styled.footer`
  height: 21px;
  display: flex;
`;

export const Wrapper = styled.div`
  border: 1px solid #c0ccd7;
  margin-top: 22px;
  & ${Row} {
    border-bottom: 1px solid #c0ccd7;
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const Delimiter = styled.div`
  height: 100%;
  width: 1px;
  position: absolute;
  z-index: -1;
  transform: translateX(-50%);
  background: #c0ccd7;
`;

export const Layer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export const FooterTimes = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  font-size: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const PlayerArea = styled.div`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const PlayerTick = styled(({ x, ...props }) => <div {...props} />)`
  height: 42px;
  position: absolute;
  z-index: 2;
  ${props => css`
    left: ${props.x}px;
  `};
  top: 0;
  width: 2px;
  background: #3d4042;
  &::before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: -10px;
    left: -4px;
    border: 2px solid #3d4042;
  }
`;

export const CurrentTick = styled(({ x, ...props }) => <p {...props} />)`
  position: absolute;
  background: white;
  z-index: 2;
  ${props =>
    props.x &&
    css`
      left: ${props.x + 60}px;
    `};
`;

export const PlayedTrack = styled(({ width, ...props }) => <div {...props} />)`
  background: #c0ccd7;
  height: 5px;
  position: absolute;
  left: 0;
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `};
`;
