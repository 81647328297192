// @flow
import React from 'react';

import TripListByType from '../components/TripListByType';
import { tripTypeEnum } from '../../../lib/enum';

type Props = {
  location?: (Location & { state: { page: number } }) | any
};

const IncomeTripList = ({ location = {} }: Props) => (
  <TripListByType location={location} tripType={tripTypeEnum.income} />
);

export default IncomeTripList;
