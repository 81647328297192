// @flow
import Button from 'antd/lib/button';
import React from 'react';
import styled from 'styled-components';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { ownerTypes } from '../../../lib/enum';
import { applyMaskToValue } from '../../../lib/helpers';
import { Card, Filter, Selects } from './../../../components';
import { Option, Select } from './../../../components/ant/Select';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Grid, { GridItem } from './../../../components/layout/Grid';

import { Spoiler } from './../../../components/ui';

import type { NodeFilterType, Vehicle } from './../../../lib/types';

const { BrandsSelect, VehicleSelect } = Selects;
const { Field } = Card;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;

  & .ant-btn {
    margin: 0 10px;
  }
`;

export type TireFilterParams = {|
  code?: string,
  'brand.id'?: number,
  vehicleId?: number,
  isFree?: boolean,
  orgUnitId?: number,
  nodeFilterType?: NodeFilterType,
  factoryNumber?: string,
  isArchive?: ?boolean
|};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: TireFilterParams
};

const TireFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter
          }: FilterChildrenParams<TireFilterParams>) => (
            <>
              <GridItem>
                <Field label="Марка">
                  <BrandsSelect
                    onChange={value => changeValue('brand.id', value)}
                    value={values['brand.id']}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Гос. номер">
                  <VehicleSelect
                    placeholder="Все ТС"
                    value={values.vehicleId}
                    optionTitle={(vehicle: Vehicle) =>
                      applyMaskToValue(
                        vehicle.licensePlate,
                        formatLicensePlateMask
                      )
                    }
                    filter={{
                      ownerType: ownerTypes.self
                    }}
                    onChange={value => changeValue('vehicleId', value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Статус шин">
                  <Select
                    allowClear
                    placeholder="Выберите статус шин"
                    onChange={value => changeValue('isArchive', value)}
                    value={values.isArchive}
                  >
                    <Option value="true">Списанные</Option>
                    <Option value="false">Несписанные</Option>
                    <Option value="all">Все</Option>
                  </Select>
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default TireFilter;
