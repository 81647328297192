import {
    InspectionGibddCostCard,
    InspectionGibddCostForm,
    InspectionGibddCostList,
} from '../../containers/InspectionGibddCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: InspectionGibddCostList,
        path: '/admin/inspection-gibdd-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: InspectionGibddCostCard,
        path: '/admin/inspection-gibdd-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: InspectionGibddCostForm,
        path: '/admin/inspection-gibdd-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: InspectionGibddCostForm,
        path: '/admin/inspection-gibdd-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];