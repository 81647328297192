// @flow

import React, { useCallback } from 'react';
import {
  Filter,
  FilterContainer,
  FilterButtonsContainer
} from '../../../../components';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import { SectionContent } from '../components/elements';

export type FilterProps<T> = {
  filter: T,
  cleanFilter: Function,
  applyFilter: Function,
  additionalFilter?: boolean
} & { children: any };

export const CommonFilter = <T>(props: FilterProps<T>) => {
  const resolvedChildren = useCallback(child => {
    try {
      return React.Children.only(child).type === React.Fragment
        ? child.props.children
        : child;
    } catch {
      return child;
    }
  }, []);

  return (
    <SectionContent>
      <FilterContainer>
        <Filter
          cleanFilter={props.cleanFilter}
          applyFilter={props.applyFilter}
          initialValues={props.filter}
          additionalFilter={props.additionalFilter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter,
            showAdditional,
            handleAdditional
          }: FilterChildrenParams<T>) => (
            <>
              {resolvedChildren(props.children?.(values, changeValue))}
              <FilterButtonsContainer
                applyFilter={applyFilter}
                cleanFilter={cleanFilter}
                showAdditional={showAdditional}
                handleAdditional={props.additionalFilter && handleAdditional}
              />
            </>
          )}
        </Filter>
      </FilterContainer>
    </SectionContent>
  );
};

export default CommonFilter;
