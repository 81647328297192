// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import Input from 'antd/lib/input';
import FormItem from 'antd/lib/form/FormItem';

import type {
  MaintenanceOperation,
  MaintenancePartAndConsumables,
} from '../../../lib/types';
import { stockPartApi } from '../../../lib/api';
import type { MaintenanceOperationStatus } from '../../../lib/enum';
import { maintenanceOperationStatuses } from '../../../lib/enum';
import {
  isEmptyValue,
  toFixed,
  multipliedBy,
  formatRub,
} from '../../../lib/helpers';

import { Selects } from './../../../components';
import YesNoSelect from '../../../components/selects/YesNoSelect';
import { InputNumber } from '../../../components/inputs';
import type { StockPart } from '../../../lib/types';

const { MaintenanceOperationStatusSelect, StockPartSelect } = Selects;

const StyledSum = styled.div`
  text-align: right;
  padding: 10px 0px;
  span {
    font-weight: 500;
  }
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;
const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;

const required = (value, record = {}) => {
  if (!isEmpty(record)) {
    return isEmptyValue(value) ? 'error' : 'validating';
  }
  return 'validating';
};

export const operationColumns = (
  onChange: Function,
  readOnly: boolean = false
) => {
  return [
    {
      title: 'Код',
      key: 'code',
      width: '80px',
      render: (value: string, record: MaintenanceOperation, index: number) => {
        if (readOnly) {
          return value;
        }
        return <Input disabled value={value} placeholder="Код работы" />;
      },
    },
    {
      title: 'Наименование',
      key: 'name',
      render: (name: string, record: MaintenanceOperation, index: number) => {
        if (readOnly) {
          return name;
        }
        return (
          <StyledFormItem validateStatus={required(name, record)}>
            <Input
              name="name"
              onChange={(e) => onChange(index, 'name', e.target.value)}
              value={name}
              placeholder="Наименование работы"
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Выезд',
      key: 'isFieldWork',
      width: '70px',
      render: (
        isFieldWork: boolean,
        record: MaintenanceOperation,
        index: number
      ) => {
        if (readOnly) {
          return isFieldWork ? 'Да' : 'Нет';
        } else {
          return (
            <StyledFormItem>
              <YesNoSelect
                onChange={(value) => onChange(index, 'isFieldWork', value)}
                value={!!isFieldWork}
                placeholder="Признак выезда"
              />
            </StyledFormItem>
          );
        }
      },
    },
    {
      title: 'Количество',
      key: 'count',
      width: '50px',
      render: (count: number, record: MaintenanceOperation, index: number) => {
        if (readOnly) {
          return count;
        } else {
          return (
            <StyledFormItem validateStatus={required(count, record)}>
              <StyledInputNumber
                name="count"
                onChange={(count) => onChange(index, 'count', count)}
                value={count}
              />
            </StyledFormItem>
          );
        }
      },
    },
    {
      title: 'Нормочасы',
      key: 'hours',
      width: '50px',
      render: (hours: number, record: MaintenanceOperation, index: number) => {
        if (readOnly) {
          return <>{hours}</>;
        } else {
          return (
            <StyledInputNumber
              disabled
              name="number"
              onChange={(value) => onChange(index, 'hours', value)}
              value={hours}
            />
          );
        }
      },
    },
    {
      title: 'Цена',
      key: 'price',
      width: '80px',
      render: (price: number, record: MaintenanceOperation, index: number) => {
        if (readOnly) {
          const itogPrice = multipliedBy(price, record.hours);
          return <>{formatRub(itogPrice)}</>;
        } else {
          return (
            <StyledInputNumber
              disabled
              name="price"
              onChange={(price) => onChange(index, 'price', price)}
              value={price}
            />
          );
        }
      },
    },
    {
      title: 'Сумма',
      key: 'sum',
      width: '80px',
      render: (sum: number, record: MaintenanceOperation, index: number) => {
        const sumOperation = multipliedBy(
          multipliedBy(record.hours, record.count),
          record.price
        );
        if (readOnly) {
          return <>{isNaN(sumOperation) ? '-' : formatRub(sumOperation)}</>;
        } else {
          return (
            <StyledInputNumber
              name="sum"
              disabled
              value={isNaN(sumOperation) ? null : sumOperation}
            />
          );
        }
      },
    },
    // {
    //   title: 'Статус',
    //   width: '130px',
    //   style: {
    //     overflow: 'hidden',
    //   },
    //   key: 'status',
    //   render: (
    //     status: MaintenanceOperationStatus,
    //     record: MaintenanceOperation,
    //     index: number
    //   ) => {
    //     if (readOnly) {
    //       return maintenanceOperationStatuses[status];
    //     } else {
    //       return (
    //         <MaintenanceOperationStatusSelect
    //           name="status"
    //           onChange={(value) => onChange(index, 'status', value)}
    //           value={status}
    //         />
    //       );
    //     }
    //   },
    // },
  ];
};

export const partColumns = (
  onChange: Function,
  readOnly: boolean,
  vehicleModelId: number = 0
) => {
  return [
    {
      title: 'Код',
      width: '120px',
      key: 'stockPartCode',
      dataIndex: 'stockPart',
      render: (stockPart?: StockPart) => {
        if (readOnly) {
          return stockPart?.sparePart
            ? stockPart?.sparePart.catalogNumber
            : stockPart?.number;
        }
        return (
          <Input
            disabled
            value={
              stockPart?.sparePart
                ? stockPart?.sparePart.catalogNumber
                : stockPart?.number
            }
          />
        );
      },
    },
    {
      title: 'Деталь',
      key: 'stockPart',
      style: {
        overflow: 'hidden',
        minWidth: '100px',
      },
      render: (
        stockPart?: StockPart,
        record: MaintenancePartAndConsumables,
        index: number
      ) => {
        if (readOnly) {
          return stockPart?.name;
        }
        return (
          <StyledFormItem validateStatus={required(record.stockPartId, record)}>
            <StockPartSelect
              fullInfo
              name="stockPartId"
              value={record.stockPartId}
              vehicleModelId={vehicleModelId}
              fetch={async (params) => {
                const allVehicles = await stockPartApi.fetchStockPart({
                  ...params,
                  'sparePart.allVehicles': true,
                });
                const vehiclesModels = await stockPartApi.fetchStockPart({
                  ...params,
                  vehicleModelId,
                });
                return { data: [...allVehicles.data, ...vehiclesModels.data] };
              }}
              onChange={(value, option) => {
                if (value) {
                  onChange(index, 'stockPart', option.props.part);
                } else {
                  onChange(index, 'stockPart', undefined);
                }
              }}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Стоимость ед. (руб. без НДС)',
      width: '80px',
      key: 'cost',
      dataIndex: 'stockPart',
      render: (
        stockPart?: StockPart,
        record: MaintenancePartAndConsumables
      ) => {
        if (readOnly) {
          return stockPart?.cost ? formatRub(stockPart?.cost) : '-';
        }
        return <Input disabled value={stockPart?.cost} />;
      },
    },
    {
      title: 'Ед. измерения',
      width: '50px',
      key: 'alias',
      dataIndex: 'stockPart',
      render: (stockPart?: StockPart) => {
        if (readOnly) {
          return stockPart?.sparePart ? stockPart?.sparePart.unit?.alias : null;
        }
        return (
          <Input
            disabled
            value={
              stockPart?.sparePart ? stockPart?.sparePart.unit?.alias : null
            }
          />
        );
      },
    },
    {
      title: 'Количество',
      key: 'count',
      width: '60px',
      render: (
        count: number,
        record: MaintenancePartAndConsumables,
        index: number
      ) => {
        return readOnly ? (
          count
        ) : (
          <StyledFormItem validateStatus={required(count, record)}>
            <StyledInputNumber
              max={record.stockPart?.count}
              name="count"
              onChange={(value) => onChange(index, 'count', value)}
              value={count}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Сумма',
      key: 'sum',
      dataIndex: 'count',
      width: '80px',
      render: (count: number, record: MaintenancePartAndConsumables) => {
        if (readOnly) {
          const cost = record.stockPart?.cost;
          return cost !== undefined
            ? formatRub(multipliedBy(count, cost))
            : '-';
        }
        let sum = undefined;
        if (record && record.stockPart) {
          sum = record.count
            ? multipliedBy(record.count, record.stockPart.cost || 0)
            : 0;
        }
        return <Input disabled value={sum} />;
      },
    },
    // {
    //   title: 'Статус',
    //   width: '130px',
    //   style: {
    //     overflow: 'hidden',
    //   },
    //   key: 'status',
    //   render: (
    //     status: MaintenanceOperationStatus,
    //     record: MaintenancePartAndConsumables,
    //     index: number
    //   ) =>
    //     readOnly ? (
    //       maintenanceOperationStatuses[status]
    //     ) : (
    //         <MaintenanceOperationStatusSelect
    //           name="status"
    //           onChange={(value) => onChange(index, 'status', value)}
    //           value={status}
    //         />
    //       ),
    // },
  ];
};

export const itog = (sum: number, sumContractor: number) => (
  <StyledSum>
    <span>Итого:</span> {sum > 0 ? formatRub(sum) : '-'}
  </StyledSum>
);
