// @flow
import React from 'react';
import Button from 'antd/lib/button';
import CancelButton from '../../../CancelButton';
import styled from 'styled-components';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/**
 * Пропсы низа страницы
 */
export type PageFooterProps = {
  // индикатор процесса отправки
  isSubmitting?: boolean,
  // данные изменились
  dirty?: boolean,
  onCancel?: Function,
  disableSubmitButton?: (values: any) => boolean,
  values?: any
};

export default (props: PageFooterProps) => {
  return (
    <Footer>
      <Button
        disabled={
          (props.disableSubmitButton &&
            props.disableSubmitButton(props.values)) ||
          props.isSubmitting
        }
        loading={props.isSubmitting}
        type="primary"
        htmlType="submit"
        data-cy="save"
      >
        Сохранить
      </Button>
      <CancelButton dirty={!!props.dirty} onClick={props.onCancel}>
        Отменить
      </CancelButton>
    </Footer>
  );
};
