// @flow
import type { WashingFrequency } from './../types';
import CommonApi from './../commonApi';

const washingFrequency = new CommonApi<WashingFrequency>(
  'washingFrequency',
  'Периодичность преведения моек ТС'
);

export default {
  ...washingFrequency
};
