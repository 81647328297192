import { EllipsisOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Menu from 'antd/lib/menu';

import notification from 'antd/lib/notification';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../components/ant/DatePicker';
import { InputNumber } from '../../../components/inputs';
import { notificationLoading } from '../../../components/Notifications';
import { Dropdown, ListTable } from '../../../components/ui';
import { expenseDirectionTypes } from '../../../lib/enum';

import { IncomeSchedule } from './';

import { Selects } from './../../../components';
import { Section, SectionTitle } from './../../../components/layout';

import { incomeScheduleApi, stageApi } from './../../../lib/api';
import {
  convertEmployeeToString,
  formatDate,
  isEmptyValue as isEmpty,
  withEmptyRow,
  withoutEmptyRow,
} from './../../../lib/helpers';
import type { ExpenseDirectionType, Stage } from './../../../lib/types';

const { ExpenseDirectionSelect, EmployeeSelect, ExpenseDirectionTypeSelect } =
  Selects;
const FormItem = Form.Item;
type Props = {
  contractId: number,
  stages: Stage[],
  readOnly: boolean,
  fetchStages: Function,
};
type State = {
  touched: boolean,
  handleSubmitting: boolean,
  stages: Stage[],
  deleteStages: Stage[],
  // выбранный этап для добавления графиков
  activeStage: ?Stage,
};

const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Content = styled.div`
  padding: 16px;
`;
const Footer = styled.div`
  margin-top: 10px;
  .ant-btn {
    margin-right: 10px;
  }
`;

export default class extends React.Component<Props, State> {
  state = {
    touched: false,
    handleSubmitting: false,
    stages: this.props.stages,
    deleteStages: [],
    activeStage: null,
  };

  componentDidUpdate(prevProps: Props) {
    const { stages } = this.props;
    if (!isEqual(prevProps.stages, stages)) {
      this.setState({ stages });
    }
  }

  async onChange(index: number, key: $Keys<Stage>, value: any) {
    let stages = withEmptyRow([...this.state.stages]);
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    stages.splice(index, 1, {
      ...stages[index],
      [(key: string)]: value,
    });
    await this.setState({ stages });
  }

  onDelete(index: number) {
    const { stages, deleteStages, activeStage } = this.state;
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    if (activeStage && stages[index].id === activeStage.id) {
      this.setState({ activeStage: null });
    }
    this.setState({
      stages: stages.filter((value, id) => id !== index),
      deleteStages: [...deleteStages, stages[index]],
    });
  }

  saveUpdateStage = async (stage: Stage) => {
    try {
      if (stage.id) {
        return await stageApi.updateStage(stage);
      } else {
        return await stageApi.addStage(stage);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
      return undefined;
    }
  };

  deleteStages = async () => {
    const { deleteStages } = this.state;
    try {
      await deleteStages.forEach(async (stage) => {
        if (stage.id) {
          await stageApi.deleteStage(stage.id);
        }
      });
      this.setState({ deleteStages: [] });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  // проверим заполнены ли все обязательные поля
  validate = (stages: Stage[]) => {
    stages.forEach((stage, index) => {
      [
        'stageNumber',
        'expenseDirectionId',
        'expenseDirectionType',
        'accountNumber',
        'startDate',
        'endDate',
        'stageSum',
        'stageSumWithTaxes',
      ].forEach((key) => {
        if (isEmpty(stage[key])) {
          throw new Error('Заполните все поля в этапах');
        }
      });
    });
  };

  handleCancel = () => {
    this.setState({
      stages: this.props.stages,
      touched: false,
      handleSubmitting: false,
      deleteStages: [],
    });
  };

  handleSubmit = async () => {
    const { contractId } = this.props;
    const stages = withoutEmptyRow(this.state.stages);
    let promises = [];
    this.setState({ handleSubmitting: true });
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      this.validate(stages);
      // сохраним этапы
      stages.forEach((stage) => {
        promises.push(this.saveUpdateStage({ ...stage, contractId }));
      });
      const hasError = await Promise.all(promises).then((stages) => {
        return stages.includes(undefined);
      });
      await this.deleteStages();

      this.props.fetchStages();
      this.setState({
        touched: hasError,
        handleSubmitting: hasError,
      });
      if (hasError) {
        notification.error({
          message: 'Ошибка',
          description: 'Не удалось сохранить все этапы',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  fetchIncomeSchedules = async () => {
    const { activeStage } = this.state;
    try {
      const incomeSchedules = await incomeScheduleApi.fetchIncomeSchedule({
        stageId: activeStage?.id,
      });
      this.setState(
        {
          activeStage: {
            ...activeStage,
            incomeSchedules,
          },
        },
        () => this.props.fetchStages()
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  validateRequired(field: string, index: number) {
    const { handleSubmitting, stages } = this.state;
    if (handleSubmitting && !isEmpty(stages[index])) {
      return isEmpty(stages[index][field]) ? 'error' : 'validating';
    }
    return 'validating';
  }

  columns = [
    {
      title: 'Номер этапа',
      width: '140px',
      key: 'stageNumber',
      dataIndex: 'stageNumber',
      render: (stageNumber: string, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          stageNumber
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('stageNumber', index)}
          >
            <Input
              placeholder="Номер этапа"
              onChange={(e) =>
                this.onChange(index, 'stageNumber', e.target.value)
              }
              value={stageNumber}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Направление расходов',
      style: {
        overflow: 'hidden',
      },
      key: 'expenseDirectionId',
      dataIndex: 'expenseDirectionId',
      render: (expenseDirectionId: number, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          stage?.expenseDirection?.name ?? '-'
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('expenseDirectionId', index)}
          >
            <ExpenseDirectionSelect
              key={`expenseDirectionKey${index}`}
              optionKey={`expenseDirectionOptionKey${index}`}
              onChange={(value) =>
                this.onChange(index, 'expenseDirectionId', value)
              }
              value={expenseDirectionId}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Тип направления',
      style: {
        overflow: 'hidden',
      },
      key: 'expenseDirectionType',
      dataIndex: 'expenseDirectionType',
      render: (
        expenseDirectionType: ExpenseDirectionType,
        stage: Stage,
        index: number
      ) => {
        const { readOnly } = this.props;
        return readOnly ? (
          expenseDirectionType ? (
            expenseDirectionTypes[expenseDirectionType]
          ) : (
            '-'
          )
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired(
              'expenseDirectionType',
              index
            )}
          >
            <ExpenseDirectionTypeSelect
              key={`expenseDirectionType${index}`}
              onChange={(value) =>
                this.onChange(index, 'expenseDirectionType', value)
              }
              value={expenseDirectionType}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: '№ л/с',
      width: '140px',
      key: 'accountNumber',
      dataIndex: 'accountNumber',
      render: (accountNumber: string, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          accountNumber
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('accountNumber', index)}
          >
            <Input
              placeholder="Номер лицевого"
              onChange={(e) =>
                this.onChange(index, 'accountNumber', e.target.value)
              }
              value={accountNumber}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Ответственный сотрудник',
      style: {
        overflow: 'hidden',
      },
      key: 'employeeId',
      dataIndex: 'employeeId',
      render: (employeeId: number, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          convertEmployeeToString(stage.employee)
        ) : (
          <EmployeeSelect
            placeholder="Сотрудник"
            key={`employeeId${index}`}
            onChange={(value) => this.onChange(index, 'employeeId', value)}
            value={employeeId}
          />
        );
      },
    },
    {
      title: 'Дата начала',
      width: '115px',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (startDate: string, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatDate(startDate)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('startDate', index)}
          >
            <DatePicker
              placeholder="Дата"
              format="DD.MM.YYYY"
              value={startDate ? moment(startDate) : startDate}
              onChange={async (value) => {
                await this.onChange(
                  index,
                  'startDate',
                  moment.utc(value).startOf('day').toISOString()
                );
                this.onChange(index, 'endDate', undefined);
              }}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Дата окончания',
      width: '115px',
      key: 'endDate',
      dataIndex: 'endDate',
      render: (endDate: string, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatDate(endDate)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('endDate', index)}
          >
            <DatePicker
              placeholder="Дата"
              format="DD.MM.YYYY"
              disabled={stage.startDate ? false : true}
              disabledDate={(date: string) =>
                stage.startDate
                  ? moment
                      .utc(date)
                      .startOf('day')
                      .isSameOrBefore(moment.utc(stage.startDate))
                  : false
              }
              value={endDate ? moment(endDate) : endDate}
              onChange={(value) =>
                this.onChange(
                  index,
                  'endDate',
                  moment.utc(value).startOf('day').toISOString()
                )
              }
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Cумма (без НДС)',
      key: 'stageSum',
      width: '115px',
      dataIndex: 'stageSum',
      render: (stageSum: string, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          stageSum
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('stageSum', index)}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Сумма"
              onChange={(value) => this.onChange(index, 'stageSum', value)}
              value={stageSum}
            />
          </StyledFormItem>
        );
      },
    },
    {
      title: 'Cумма (c НДС)',
      key: 'stageSumWithTaxes',
      width: '115px',
      dataIndex: 'stageSumWithTaxes',
      render: (stageSumWithTaxes: string, stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          stageSumWithTaxes
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('stageSumWithTaxes', index)}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Сумма"
              onChange={(value) =>
                this.onChange(index, 'stageSumWithTaxes', value)
              }
              value={stageSumWithTaxes}
            />
          </StyledFormItem>
        );
      },
    },
    {
      width: '20px',
      renderRecord: (stage: Stage, index: number) => {
        const { readOnly } = this.props;
        return !isEmpty(stage) ? (
          <Operations key={stage.id}>
            <Dropdown
              overlayStyle={{ zIndex: '999' }}
              overlay={
                <Menu>
                  {!readOnly && (
                    <Menu.Item onClick={() => this.onDelete(index)}>
                      Удалить
                    </Menu.Item>
                  )}
                  {!!stage.id && (
                    <Menu.Item
                      onClick={() =>
                        this.setState({ activeStage: { ...stage } })
                      }
                    >
                      График поступлений
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
            </Dropdown>
          </Operations>
        ) : null;
      },
    },
  ];

  getIncomeSchedule = () => {
    const { readOnly } = this.props;
    const { activeStage } = this.state;
    return (
      <IncomeSchedule
        readOnly={readOnly}
        stageId={activeStage?.id}
        incomeSchedules={activeStage?.incomeSchedules}
        fetchIncomeSchedules={this.fetchIncomeSchedules}
      />
    );
  };

  render() {
    const { readOnly } = this.props;
    const { stages, touched, activeStage } = this.state;
    const data = readOnly ? withoutEmptyRow(stages) : withEmptyRow(stages);
    return (
      <>
        <Section>
          <SectionTitle divider>Этапы</SectionTitle>
          <Content>
            <ListTable columns={this.columns} data={data} />
            {touched && (
              <Footer>
                <Button onClick={this.handleSubmit} type="primary">
                  Сохранить
                </Button>
                <Button onClick={this.handleCancel}>Отменить</Button>
              </Footer>
            )}
          </Content>
        </Section>
        {activeStage && (
          <Section>
            <SectionTitle divider>
              График поступлений ({activeStage.stageNumber})
            </SectionTitle>
            <Content>{this.getIncomeSchedule()}</Content>
          </Section>
        )}
      </>
    );
  }
}
