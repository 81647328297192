// @flow
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import type { BranchBudgetSummaryMonthRedistribution } from '../types';
import CommonApi from './../commonApi';
import { fetchRequest } from './index';

const convertDataFromServer = (
  month: BranchBudgetSummaryMonthRedistribution
) => {
  return {
    ...month,
    sum: convertFromKopToRub(month.sum)
  };
};

const convertDataToServer = (month: BranchBudgetSummaryMonthRedistribution) => {
  return {
    ...month,
    sum: convertFromRubToKop(month.sum)
  };
};

const baseUrl = 'branchBudgetSummaryMonthRedistribution';
export const addOrUpdate = async (
  budget: any
): Promise<BranchBudgetSummaryMonthRedistribution> => {
  const added = await fetchRequest.post(`/${baseUrl}/addOrUpdate`, {
    ...budget,
    redistributions:
      budget?.redistributions?.map(redistribution => ({
        ...redistribution,
        sum: convertFromRubToKop(redistribution?.sum ?? 0)
      })) ?? []
  });
  if (added) {
    return added;
  }
  throw new Error('Не удалось создать новую марку');
};
const branchBudgetSummaryMonthRedistribution = new CommonApi<BranchBudgetSummaryMonthRedistribution>(
  baseUrl,
  'Перераспределение к уточнению в бюджете',
  convertDataToServer,
  convertDataFromServer
);

export default { ...branchBudgetSummaryMonthRedistribution, addOrUpdate };
