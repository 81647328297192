// @flow
import React, { type ComponentType, useState } from 'react';
import type { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { InputNumber } from '../../../components/inputs';

import type {
  Order,
  WayPoint,
  Employee,
  BusinessDay,
  Cargo,
  OrderObjective,
} from './../../../lib/types';
import {
  positionEnum,
  waypointTypeEnum,
  nodeFilterTypeEnum,
  orderTypeEnum,
} from './../../../lib/enum';
import { validateWaypointsOrder } from '../../../lib/helpers';
import { WaypointsForm, Selects, Form } from './../../../components';
import { Section, Footer } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { ContractVehicle } from '../../../lib/types';
import FreeContractVehicleSelection from '../../../components/vehicleSelection/FreeContractVehicleSelection';
import { SectionTitle } from '../../../components/layout';
import type { FormFieldProps } from '../../../components/Form';
import type { Profile } from '../../../ducks/auth';
import {
  lulechnikVehicleTypes,
  riggerVehicleTypes,
  gpmVehicleTypes,
  vehicleGroupEnum,
  safetyCarLiftTypes,
  safetyTechnicianTypes,
} from '../../../lib/enum/vehicleTypes';
import { validateWaypoint } from '../../../components/Waypoints/lib';
import type { VehicleGroupAndType } from './../../../components/selects/VehicleTypeSelect';
import { needShowWithTrailer, validateEmployee } from './../lib';
import CancelButton from '../../../components/CancelButton';
import type { FreeContractVehicle } from '../../../lib/types/contractVehicle';
import type { WaypointsType } from '../../../components/Waypoints/WaypointsForm';
import { isOrderWeekend, showHasCargosField } from '../lib';
import { orderObjectivesEnum } from '../../../lib/enum';
import CargosForm from './CargosForm';

const SectionContent = styled.div`
  padding: 16px;
`;

const {
  EmployeeSelect,
  OrderTypeSelect,
  VehicleTypeSelect,
  OrderObjectiveSelect,
  YesNoSelect,
} = Selects;

type FormProps = {
  freeContractVehicles: FreeContractVehicle[],
  onSubmit: Function,
  getOffsetHours: (order: Order) => number,
  order: ?Order,
  profile: Profile,
  onCancel: () => void,
  businessCalendar: BusinessDay[],
};

export default ({
  onSubmit,
  freeContractVehicles = [],
  order,
  getOffsetHours,
  profile,
  onCancel,
  businessCalendar,
}: FormProps) => {
  const [positionFilter, setPositionFilter] = useState(
    positionEnum.safetyTechnician
  );
  // устанавливаем фильтр по лицам ответственным за безопасное проведение работ
  const checkPositionsFilter = (type: ?string, group: ?string) => {
    if (
      vehicleGroupEnum.carLifts === group ||
      safetyCarLiftTypes.includes(type)
    ) {
      setPositionFilter(positionEnum.safetyCarLift);
    }
    if (
      vehicleGroupEnum.truckCranes === group ||
      safetyTechnicianTypes.includes(type)
    ) {
      setPositionFilter(positionEnum.safetyTechnician);
    }
  };

  const filteredContractVehicles =
    order && order.vehicleType
      ? freeContractVehicles.filter(
          (contractVehicleRequest) =>
            get(
              contractVehicleRequest,
              'contractVehicle.vehicle.vehicleModel.type'
            ) === order.vehicleType
        )
      : freeContractVehicles;
  // проверяем гпм или не гпм по типам и группам
  const checkIsGpm = (value) => {
    // Если подходящий это группа
    if (vehicleGroupEnum[value])
      return [
        vehicleGroupEnum.bkmBgm,
        vehicleGroupEnum.truckCranes,
        vehicleGroupEnum.carLifts,
      ].includes(value);
    return gpmVehicleTypes.includes(value);
  };

  return (
    <Form onSubmit={onSubmit} initialValues={order || {}}>
      {(FormField: ComponentType<FormFieldProps>, formikProps: FormikProps) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          setTouched,
          values,
          errors,
          dirty,
          isSubmitting,
        } = formikProps;
        // Тип заявки для блокировки дат
        const waypointsType: WaypointsType = values.isBusinessTrip
          ? 'business'
          : values.type === orderTypeEnum.emergency
          ? 'emergency'
          : 'standart';
        const showHasCargos = showHasCargosField(values);
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionTitle divider>Назначение</SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Тип заявки" name="type" required fast>
                      {({ name, value }) => (
                        <OrderTypeSelect
                          name={name}
                          value={value}
                          onChange={(type: string) => {
                            setFieldValue(name, type);
                            setFieldValue('isBusinessTrip', undefined);
                            setFieldValue('businessTripOrderNumber', undefined);
                            setFieldValue(
                              'businessTripDecreeNumber',
                              undefined
                            );
                          }}
                          onBlur={() =>
                            handleBlur({
                              target: { name },
                            })
                          }
                          data-cy="typeSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Цель поездки"
                      name="objective"
                      required
                      fast
                    >
                      {({ name, value }) => (
                        <OrderObjectiveSelect
                          onChange={(objective: OrderObjective) => {
                            setFieldValue(name, objective);
                            setFieldValue(
                              'hasCargos',
                              objective === orderObjectivesEnum.shipping
                            );
                          }}
                          onBlur={() =>
                            handleBlur({
                              target: { name },
                            })
                          }
                          data-cy="objectiveSelect"
                          name={name}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="В распоряжение (ФИО)"
                      name="employeeId"
                      required
                      fast
                    >
                      {({ name, value }) => (
                        <EmployeeSelect
                          name={name}
                          data-cy="employeeSelect"
                          onChange={(employeeId: string) =>
                            setFieldValue(name, employeeId)
                          }
                          onBlur={() =>
                            handleBlur({
                              target: { name },
                            })
                          }
                          filter={{
                            nodeId: profile.employee.orgUnitId,
                            nodeFilterType: 'branchAndChildren',
                          }}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Примечание"
                      name="notation"
                      fast
                      required={values.withTrailer}
                    >
                      {(field) => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  {values.type === orderTypeEnum.standard && (
                    <GridItem fullWidth>
                      <Grid gutter="16px" cols={3}>
                        <GridItem>
                          <FormField label="Командировка" name="isBusinessTrip">
                            {({ value, name }) => (
                              <YesNoSelect
                                value={value}
                                name={name}
                                disabled={
                                  values.type === orderTypeEnum.emergency
                                }
                                onBlur={() => handleBlur({ target: { name } })}
                                onChange={(value: boolean) =>
                                  setFieldValue(name, value)
                                }
                              />
                            )}
                          </FormField>
                        </GridItem>
                        {values.isBusinessTrip && (
                          <GridItem>
                            <FormField
                              label="Номер заявки на командировку"
                              name="businessTripOrderNumber"
                              required
                            >
                              {(field) => <Input {...field} />}
                            </FormField>
                          </GridItem>
                        )}
                        {values.isBusinessTrip && (
                          <GridItem>
                            <FormField
                              label="Номер приказа на командировку"
                              name="businessTripDecreeNumber"
                            >
                              {(field) => <Input {...field} />}
                            </FormField>
                          </GridItem>
                        )}
                      </Grid>
                    </GridItem>
                  )}
                  {showHasCargos && (
                    <GridItem>
                      <FormField
                        label="Требуется перевозка груза"
                        name="hasCargos"
                      >
                        {({ value, name }) => (
                          <YesNoSelect
                            value={value}
                            name={name}
                            onBlur={() => handleBlur({ target: { name } })}
                            onChange={(value: boolean) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle divider>Транспортное средство</SectionTitle>
              <SectionContent>
                <Grid gutter="16px" cols={4}>
                  <GridItem span={2}>
                    <FormField
                      label="Тип ТС"
                      name="vehicleType"
                      required={!values.vehicleType && !values.vehicleGroup}
                      fast
                    >
                      {({ name, value }) => (
                        <VehicleTypeSelect
                          resultAsObject
                          onChange={(vehicleType: VehicleGroupAndType) => {
                            const { group, type } = vehicleType;
                            setFieldValue(name, type);
                            setFieldValue('vehicleGroup', group);
                            checkPositionsFilter(type, group);
                            // Если подходящий тип ТС, то проставляем флаг, что заявка ГПМ
                            const isGpm = checkIsGpm(type) || checkIsGpm(group);
                            setFieldValue('isGpm', isGpm);
                          }}
                          selectableGroup
                          onBlur={() =>
                            handleBlur({
                              target: { name },
                            })
                          }
                          value={value || values.vehicleGroup}
                          data-cy="vehicleTypeSelect"
                          name={name}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Количество пассажиров"
                      required
                      hasFeedback={false}
                      name="workersCount"
                      fast
                    >
                      {({ name, value }) => (
                        <InputNumber
                          name={name}
                          value={value}
                          onChange={(count: number) =>
                            setFieldValue(name, count)
                          }
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="С прицепом" name="withTrailer" fast>
                      {({ value, name }) => (
                        <YesNoSelect
                          value={value}
                          name={name}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: boolean) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {(checkIsGpm(values.vehicleType) ||
                    checkIsGpm(values.vehicleGroup)) && (
                    <GridItem>
                      <FormField label="Использовать как ГПМ" name="isGpm" fast>
                        {({ value, name }) => (
                          <YesNoSelect
                            value={value}
                            name={name}
                            onBlur={() => handleBlur({ target: { name } })}
                            onChange={(value: boolean) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {filteredContractVehicles.length > 0 && (
                    <GridItem fullWidth>
                      <FormField name="vehicleId" label="">
                        {({ name, value }) => (
                          <FreeContractVehicleSelection
                            contractVehicles={filteredContractVehicles}
                            onSelect={(contractVehicle: ContractVehicle) => {
                              const {
                                vehicle: {
                                  vehicleModel: { type },
                                },
                              } = contractVehicle;
                              setFieldValue(name, contractVehicle.id);
                              setFieldValue('vehicleType', type);
                            }}
                            selected={value}
                            isWeekend={
                              values.route &&
                              isOrderWeekend(
                                values.route.waypoints,
                                businessCalendar
                              )
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle divider>Задание</SectionTitle>
              <SectionContent>
                <FormField
                  required
                  hasFeedback={false}
                  name="route.waypoints"
                  // выключаем подсветку ошибки для поля, если есть точки
                  validateStatus={(value: WayPoint[] = [], error: string) =>
                    !!value.length ? null : error && 'error'
                  }
                  renderHelp={(help: string) => (
                    <p style={{ color: 'red', fontSize: 12 }}>{help}</p>
                  )}
                  validate={(waypoints: WayPoint[]) => {
                    const errors = waypoints
                      .map((waypoint) => validateWaypoint(waypoint))
                      .filter((errors) => !isEmpty(errors));
                    const waypointsOrderErrors =
                      validateWaypointsOrder(waypoints);
                    if (waypointsOrderErrors) return waypointsOrderErrors;
                    if (!waypoints.length) {
                      return 'Задание обязательно для заполнения';
                    } else if (errors.length) {
                      return 'Обнаружены ошибки в маршрутном задании';
                    }
                  }}
                >
                  {({ name, value }) => (
                    <WaypointsForm
                      orderType={values.type}
                      waypoints={value}
                      hasErrors={!!errors[name]}
                      type={waypointsType}
                      editTypes={[waypointTypeEnum.transit]}
                      onChange={(waypoints: WayPoint[]) => {
                        setFieldValue(name, waypoints);
                        setTouched([name]);
                      }}
                      offsetHours={getOffsetHours(values)}
                    />
                  )}
                </FormField>
              </SectionContent>
            </Section>
            {values.hasCargos && (
              <Section>
                <SectionTitle divider>Груз</SectionTitle>
                <SectionContent>
                  <Grid>
                    <GridItem fullWidth>
                      <FormField name="cargos" fast>
                        {({ value, name }) => (
                          <CargosForm
                            cargos={value}
                            onChange={(cargos: Cargo[]) =>
                              setFieldValue(name, cargos)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </SectionContent>
              </Section>
            )}
            {values.isGpm && (
              <Section>
                <SectionTitle divider>ГПМ</SectionTitle>
                <SectionContent>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Главный инженер"
                        required
                        fast
                        name="mainEngineerId"
                      >
                        {({ value, name }) => (
                          <EmployeeSelect
                            name={name}
                            onChange={(mainEngineerId: string) =>
                              setFieldValue(name, mainEngineerId)
                            }
                            value={value}
                            filter={{
                              positions: [positionEnum.engineer],
                              nodeId: profile.employee.orgUnitId,
                              nodeFilterType:
                                nodeFilterTypeEnum.branchAndChildren,
                            }}
                            onBlur={() =>
                              handleBlur({
                                target: { name },
                              })
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Владелец ГПМ"
                        name="gpmOwnerId"
                        required
                        fast
                      >
                        {({ name, value }) => (
                          <EmployeeSelect
                            name={name}
                            onChange={(gpmOwnerId: string) =>
                              setFieldValue(name, gpmOwnerId)
                            }
                            filter={{
                              nodeId: profile.employee.orgUnitId,
                              nodeFilterType:
                                nodeFilterTypeEnum.branchAndChildren,
                            }}
                            onBlur={() =>
                              handleBlur({
                                target: { name },
                              })
                            }
                            value={value}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Характер работы"
                        name="natureOfWork"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Объект (№ цеха, участка)"
                        name="object"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Лица, ответственные за безопасное проведение работ"
                        name="safetyResponsibles"
                        required
                        validate={(value: Employee[]) => {
                          if (value.length > 8) {
                            return 'Ответственных за безопасное проведение работ не может быть больше 8';
                          }
                          if (value.length === 0) {
                            return 'Обязательное поле';
                          }
                        }}
                      >
                        {({ name, value }) => {
                          return (
                            <EmployeeSelect
                              name={name}
                              disabledEmployee={(employee) =>
                                validateEmployee(employee, positionFilter)
                              }
                              warning={(employee) =>
                                validateEmployee(employee, positionFilter) &&
                                'Истек срок разрешительных документов'
                              }
                              onChange={(
                                empValue: string,
                                selectedOptions: Object[]
                              ) =>
                                setFieldValue(
                                  name,
                                  selectedOptions.map(
                                    (option) => option.props.employee
                                  )
                                )
                              }
                              value={(value || []).map(
                                (employee: Employee) => employee.id
                              )}
                              mode="multiple"
                              filter={{
                                positions: positionFilter,
                                nodeId: profile.employee.orgUnitId,
                                nodeFilterType:
                                  nodeFilterTypeEnum.branchAndChildren,
                              }}
                            />
                          );
                        }}
                      </FormField>
                    </GridItem>
                    {(riggerVehicleTypes.includes(values.vehicleType) ||
                      vehicleGroupEnum.bkmBgm === values.vehicleGroup ||
                      vehicleGroupEnum.truckCranes === values.vehicleGroup) && (
                      <GridItem>
                        <FormField
                          label="Рабочие стропальщики"
                          name="riggers"
                          fast
                          required
                          validate={(riggers: Employee[]) => {
                            if (riggers && riggers.length > 8) {
                              return 'Рабочих стропальщиков не может быть больше 8';
                            }
                            if (riggers && riggers.length === 0) {
                              return 'Обязательное поле';
                            }
                          }}
                        >
                          {({ name, value }) => (
                            <EmployeeSelect
                              name={name}
                              disabledEmployee={(employee) =>
                                validateEmployee(employee, positionEnum.rigger)
                              }
                              warning={(employee) =>
                                validateEmployee(
                                  employee,
                                  positionEnum.rigger
                                ) && 'Истек срок разрешительных документов'
                              }
                              onChange={(
                                empValue: string,
                                selectedOptions: Object[]
                              ) =>
                                setFieldValue(
                                  name,
                                  selectedOptions.map(
                                    (option) => option.props.employee
                                  )
                                )
                              }
                              value={(value || []).map(
                                (employee: Employee) => employee.id
                              )}
                              mode="multiple"
                              filter={{
                                positions: [positionEnum.rigger],
                                nodeId: profile.employee.orgUnitId,
                                nodeFilterType:
                                  nodeFilterTypeEnum.branchAndChildren,
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    {(lulechnikVehicleTypes.includes(values.vehicleType) ||
                      vehicleGroupEnum.carLifts === values.vehicleGroup) && (
                      <GridItem>
                        <FormField
                          label="Рабочие люльки"
                          name="secondaryRiggers"
                          fast
                          validate={(secondaryRiggers: Employee[]) => {
                            if (
                              secondaryRiggers &&
                              secondaryRiggers.length > 8
                            ) {
                              return 'Рабочих люльки не может быть больше 8';
                            }
                          }}
                        >
                          {({ name, value }) => (
                            <EmployeeSelect
                              name={name}
                              disabledEmployee={(employee) =>
                                validateEmployee(
                                  employee,
                                  positionEnum.lulechnik
                                )
                              }
                              warning={(employee) =>
                                validateEmployee(
                                  employee,
                                  positionEnum.lulechnik
                                ) && 'Истек срок разрешительных документов'
                              }
                              onChange={(
                                empValue: string,
                                selectedOptions: Object[]
                              ) =>
                                setFieldValue(
                                  name,
                                  selectedOptions.map(
                                    (option) => option.props.employee
                                  )
                                )
                              }
                              value={(value || []).map(
                                (employee: Employee) => employee.id
                              )}
                              mode="multiple"
                              filter={{
                                positions: [positionEnum.lulechnik],
                                nodeId: profile.employee.orgUnitId,
                                nodeFilterType:
                                  nodeFilterTypeEnum.branchAndChildren,
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <FormField
                        label="Наличие проекта производства работ"
                        name="project"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Сведения о линиях электропередач, воздушной электросети, контактных проводах городского транспорта и др."
                        name="powerLinesInfo"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Напряжение (В)" name="voltage" fast>
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Расстояние по горизонтали и вертикали (м)"
                        name="vhDistance"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Cведения об охранной зоне"
                        name="safetyZone"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Cведения о наличие разрешения организации, эксплатирующей линию электропередачи"
                        name="permitInfo"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Cведения о наличии наряда-допуска"
                        name="admission"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Технологическая карта"
                        name="technologyMap"
                        fast
                      >
                        {(fieldProps) => <Input {...fieldProps} />}
                      </FormField>
                    </GridItem>
                  </Grid>
                </SectionContent>
              </Section>
            )}
            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                data-cy="save"
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
