// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { batteryApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import type { BatteryFilterParams } from './../../containers/Equipment/Batteries/Filter';

type Props = SelectProps & { filter: BatteryFilterParams };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await batteryApi.fetchBatteries({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={batteryApi.fetchBattery}
    notFoundText="АКБ не найдены"
    placeholder="Выберите АКБ"
    renderOption={(battery: any, Option) => (
      <Option key={battery.id} value={battery.id} battery={battery}>
        {battery.name}
      </Option>
    )}
    {...selectProps}
  />
);
