// @flow
import CommonApi from '../commonApi';
import type { FuelAndOilMechanism } from '../types';

const basedUrl = 'fuelAndOilMechanism';
const fuelAndOilMechanism = new CommonApi<FuelAndOilMechanism>(
  basedUrl,
  'расчет гсм топливо, средства малой механизации'
);

export default {
  ...fuelAndOilMechanism
};
