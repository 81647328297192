// @flow
import React from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { navigate } from '@reach/router';

import type {
  MeasuringDeviceCertification,
  UserAccess
} from '../../../lib/types';
import { measuringDeviceCertificationApi } from '../../../lib/api';
import { Header } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { canHandleService } from '../lib';
import type { AppState } from '../../../ducks/redux';
import { Icon } from '../../../components/ui';
import { Panel, SectionContent } from '../elements';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, { GridItem } from '../../../components/layout/Grid';
import {
  convertVehicleToString,
  formatDateTimeToString
} from '../../../lib/helpers';

import { ActInfo } from './../../Act';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  measuringDeviceCertification: MeasuringDeviceCertification
};

class MeasuringDeviceCertificationCard extends React.Component<Props, State> {
  state = {
    measuringDeviceCertification: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const measuringDeviceCertification = await measuringDeviceCertificationApi.fetchMeasuringDeviceCertification(
        parseInt(id, 10)
      );
      this.setState({ measuringDeviceCertification });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { measuringDeviceCertification } = this.state;

    const canHandle = canHandleService(userAccess);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={'/services/measuring-device-certifications'}>
                Список сертификаций средств измерений
              </Crumb>
              <Crumb>Сертификация средств измерений №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() =>
                  navigate(
                    `/services/measuring-device-certifications/${id}/edit`
                  )
                }
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Сертификация средств измерений №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              {measuringDeviceCertification.vehicle !== null ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(
                      measuringDeviceCertification.vehicle
                    )}
                  </Field>
                </GridItem>
              ) : null}

              {!!measuringDeviceCertification.maintenanceDate && (
                <GridItem>
                  <Field label="Дата сертификации">
                    {formatDateTimeToString(
                      measuringDeviceCertification.maintenanceDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              )}

              {!!measuringDeviceCertification.nextActDate && (
                <GridItem>
                  <Field label="Следующая дата сертификации">
                    {formatDateTimeToString(
                      measuringDeviceCertification.nextActDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              )}

              <GridItem>
                <Field label="Сумма оплаты, руб.">
                  {(
                    measuringDeviceCertification.paymentAmount || 0
                  ).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                  })}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {measuringDeviceCertification && measuringDeviceCertification.act && (
          <ActInfo act={measuringDeviceCertification.act} />
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(MeasuringDeviceCertificationCard);
