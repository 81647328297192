// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { federalHighwayFareApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { formatDateTimeToString } from '../../lib/helpers';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/federal-highway-fare/';

const columns = [
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  },
  {
    title: 'Стоимость за километр без НДС, руб',
    dataIndex: 'cost'
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

  return (
    <Page>
      <CommonListPage
        crud={federalHighwayFareApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Стоимость проезда по федеральным трассам',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}edit/${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
