// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import type moment from 'moment';
import { Element, scroller } from 'react-scroll';

import type {
  Vehicle,
  FuelAndOil,
  FuelMultiplier,
  Employee,
} from '../../../../lib/types';
import { getVehicleConstMultipliers } from './../../lib';
import { fuelTypes } from '../../../../lib/enum/index';
import { Section } from '../../../../components/layout/index';
import { Card } from '../../../../components';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { navigate } from '@reach/router';
import { Icon, ListTable } from '../../../../components/ui';
import FuelCard from '../../containers/FuelCard';
import SectionTitle from '../SectionTitle';
import {
  formatDateTimeToString,
  convertEmployeeToString,
} from './../../../../lib/helpers';
import { VehicleFuelsAndOilsList } from './../../containers/VehicleFuelAndOils';
import { canShowFuelData } from './../../../VehicleModels/lib';

const { Field } = Card;

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  vehicle: Vehicle,
  fuelsAndOils: FuelAndOil[],
  editable: boolean,
  canAddFuelMeasurement: boolean,
  anchor: ?string,
};
export default ({
  vehicle,
  fuelsAndOils,
  editable,
  canAddFuelMeasurement,
  anchor,
}: Props) => {
  const [constantFuelMultipliers, setConstantFuelMultipliers] = useState([]);
  const isDualFuelEngine =
    vehicle.vehicleModel && !!vehicle.vehicleModel.secondaryFuelType;

  const setFuelMultipliers = async () => {
    const multiple =
      vehicle && vehicle.fuelMultipliers
        ? vehicle.fuelMultipliers.filter(
          (item: FuelMultiplier) => item.shouldUsedAlways
        )
        : [];
    setConstantFuelMultipliers([
      ...multiple,
      ...(await getVehicleConstMultipliers(vehicle)),
    ]);
  };

  useEffect(() => {
    if (anchor) {
      scroller.scrollTo(anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  });

  useEffect(() => {
    setFuelMultipliers();
  }, [vehicle]);

  return (
    <>
      {canShowFuelData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
        <Element name="fuel">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/fuel`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Топливо
            </SectionTitle>
            {isDualFuelEngine && <SectionTitle>Основное топливо</SectionTitle>}
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Вид топлива">
                    {fuelTypes[vehicle.vehicleModel.primaryFuelType]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Уровень топлива">
                    {vehicle.primaryFuelLevel || '0'} л.&nbsp;
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Объем топливного бака">
                    {vehicle.vehicleModel.primaryTankVolume}
                    &nbsp;
                    {vehicle.vehicleModel.primaryTankVolume !== null && 'л.'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Расход по нормативу">
                    {vehicle.vehicleModel.primaryFuelConsumption}
                    &nbsp;
                    {vehicle.vehicleModel.primaryFuelConsumption !== null &&
                      'л/100 км'}
                  </Field>
                </GridItem>
                {!!vehicle.vehicleModel.primaryEquipmentFuelConsumption && (
                  <GridItem>
                    <Field label="Норма расх. топл. на л/машчас работы оборудования">
                      {vehicle.vehicleModel.primaryEquipmentFuelConsumption}
                    </Field>
                  </GridItem>
                )}
              </Grid>
            </SectionContent>
            {isDualFuelEngine && (
              <>
                <SectionTitle>Дополнительное топливо</SectionTitle>
                <SectionContent>
                  <Grid gutter="16px">
                    <GridItem>
                      {vehicle.vehicleModel.secondaryFuelType && (
                        <Field label="Вид топлива">
                          {fuelTypes[vehicle.vehicleModel.secondaryFuelType]}
                        </Field>
                      )}
                    </GridItem>
                    {!isEmpty(vehicle.secondaryFuelLevel) ? (
                      <GridItem>
                        <Field label="Уровень топлива">
                          {vehicle.secondaryFuelLevel} л
                        </Field>
                      </GridItem>
                    ) : null}
                    <GridItem>
                      <Field label="Объем топливного бака">
                        {vehicle.vehicleModel.secondaryTankVolume}
                        &nbsp;
                        {vehicle.vehicleModel.secondaryTankVolume !== null &&
                          'л.'}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Расход по нормативу">
                        {vehicle.vehicleModel.secondaryFuelConsumption}
                        &nbsp;
                        {vehicle.vehicleModel.secondaryFuelConsumption !==
                          null && 'л/100 км'}
                      </Field>
                    </GridItem>
                    {parseFloat(
                      vehicle.vehicleModel.secondaryEquipmentFuelConsumption
                    ) > 0 && (
                        <GridItem>
                          <Field label="Норма расх. топл. на л/машчас работы оборудования">
                            {
                              vehicle.vehicleModel
                                .secondaryEquipmentFuelConsumption
                            }
                          </Field>
                        </GridItem>
                      )}
                  </Grid>
                </SectionContent>
              </>
            )}
            <SectionTitle>Фактический расход</SectionTitle>
            <SectionContent>
              <Grid cols={2}>
                {constantFuelMultipliers.length > 0 && (
                  <GridItem>
                    <Field label="Расход с учетом постоянных коэффициентов">
                      {
                        // Такая обертка нужна только для того, чтобы округлять значения и не показывать нули после toFixed(),
                        // т.е. чтобы вместо 2.00 отображалось все-таки 2
                        parseFloat(
                          (
                            (1 +
                              (sumBy(constantFuelMultipliers, 'value') || 0)) *
                            (vehicle.vehicleModel.primaryFuelConsumption || 0)
                          ).toFixed(2)
                        )
                      }
                      &nbsp; л/100км
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field label="Коэффициенты поправок к расходу топлива">
                    {constantFuelMultipliers.length
                      ? constantFuelMultipliers.map((m) => (
                        <span>
                          {m.name} x{m.value} <br />
                        </span>
                      ))
                      : '0%'}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
            <FuelCard vehicle={vehicle} editable={false} />
          </Section>
        </Element>
      )}
      <Element name="oils-technical-liquids">
        <Section>
          <SectionTitle divider>
            Масла и тех. жидкости на текущий месяц
          </SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem fullWidth>
                <VehicleFuelsAndOilsList vehicleId={vehicle.id} />
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      </Element>
      {canShowFuelData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
        <Section>
          <SectionTitle divider>Контрольные замеры уровня топлива</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem fullWidth>
                <ListTable
                  columns={[
                    {
                      title: 'Дата',
                      dataIndex: 'date',
                      key: 'date',
                      render: (date: string | moment) =>
                        formatDateTimeToString(date, 'DD.MM.YYYY'),
                    },
                    {
                      title: 'Предыдущее значение, л',
                      key: 'oldPrimaryFuelLevel',
                      dataIndex: 'oldPrimaryFuelLevel',
                    },
                    {
                      title: 'Новое значение, л',
                      key: 'newPrimaryFuelLevel',
                      dataIndex: 'newPrimaryFuelLevel',
                    },
                    {
                      title: 'Документ-основание',
                      key: 'document',
                      dataIndex: 'document',
                    },
                    {
                      title: 'Сотрудник',
                      key: 'gauger',
                      dataIndex: 'gauger',
                      render: (gauger: Employee) =>
                        convertEmployeeToString(gauger),
                    },
                  ]}
                  data={vehicle.fuelMeasurements}
                />
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      )}
    </>
  );
};
