// @flow

import type { Stage } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

import { convertFromRubToKop, convertFromKopToRub } from './../helpers';
import {
  convertDataFromServer as incomeScheduleConverDataFromServer,
  convertDataToServer as incomeScheduleConverDataToServer
} from './incomeSchedule';

export const convertDataToServer = (stage: Stage): Stage => ({
  ...stage,
  stageSum: convertFromRubToKop(stage.stageSum),
  incomeSchedules: stage.incomeSchedules
    ? stage.incomeSchedules.map(incomeScheduleConverDataToServer)
    : []
});
export const convertDataFromServer = (stage: Stage): Stage => ({
  ...stage,
  stageSum: convertFromKopToRub(stage.stageSum),
  incomeSchedules: stage.incomeSchedules
    ? stage.incomeSchedules.map(incomeScheduleConverDataFromServer)
    : []
});

export const addStage = async (stage: Stage): Promise<Stage> => {
  const added = await fetchRequest.post('/stage', convertDataToServer(stage));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать этап');
};

export const updateStage = async (stage: Stage): Promise<Stage> => {
  const updated = await fetchRequest.put('/stage', convertDataToServer(stage));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить этап');
};

export const fetchStage = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Stage>> => {
  const stages = await fetchRequest.get('/stage', {
    ...initialFetchListParams,
    ...params
  });
  if (stages) {
    return {
      ...stages,
      data: stages.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список Этапов');
};

export const getStage = async (
  id: number,
  params: any = {}
): Promise<Stage> => {
  const stage = await fetchRequest.get(`/stage/${id}`, params);
  if (stage) return convertDataFromServer(stage);
  throw new Error('Не удалось загрузить этап');
};

export const deleteStage = async (id: number): Promise<Stage> => {
  const deleted = await fetchRequest.delete(`/stage/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить этап');
};

export default {
  addStage,
  updateStage,
  fetchStage,
  deleteStage,
  getStage
};
