// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { inspectionGtnCostApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { approveTypes, vehicleTypes } from '../../lib/enum';
import { formatDateTimeToString } from '../../lib/helpers';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/inspection-gtn-cost/';

const columns = [
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Стоимость за один осмотр (работа)',
    dataIndex: 'cost'
  },
  {
    title: 'Стоимость за один осмотр (пошлина)',
    dataIndex: 'tax'
  },
  {
    title: 'Тип ТС',
    dataIndex: 'vehicleType',
    render: (vehicleType: string) => {
      return vehicleTypes[vehicleType];
    }
  },
  {
    title: 'Периодичность прохождения ТО (мес.)',
    dataIndex: 'maintenanceIntervals'
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: status => approveTypes[status]
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

  return (
    <Page>
      <CommonListPage
        crud={inspectionGtnCostApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Стоимость и периодичность ТО Гостехнадзор',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
