// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { bioTreatmentFacilityTalonApi } from './../../lib/api';
import { formatDateTimeToString } from './../../lib/helpers';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async (params) => {
      return await bioTreatmentFacilityTalonApi.fetch({
        ...filter,
        ...params,
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={bioTreatmentFacilityTalonApi.get}
    notFoundText="Талоны не найдены"
    placeholder="Выберите талон"
    renderOption={(talon: any, Option) => (
      <Option key={talon.id} value={talon.id} talon={talon}>
        № {talon.id} от{' '}
        {formatDateTimeToString(talon.createdDate, 'DD.MM.YYYY')}
      </Option>
    )}
    {...selectProps}
  />
);
