// @flow

import type { Dispatch } from 'redux';

import type { MaintenanceOperationGroup } from './../lib/types';
import { maintenanceOperationGroupApi } from '../lib/api';
import type { Action } from './redux';
import { SET_MAINTENANCE_OPERATION_GROUPS } from './maintenanceOperationGroups';

export const SET_MAINTENANCE_OPERATION_GROUP =
  'vehicles/maintenance-operation-group/set';
const CLEAR_MAINTENANCE_OPERATION_GROUP =
  'vehicles/maintenance-operation-group/clear';

const initialState: ?MaintenanceOperationGroup = null;

export const replaceMaintenanceOperationGroupInTree = (
  node: MaintenanceOperationGroup,
  maintenanceOperationGroup: MaintenanceOperationGroup
): MaintenanceOperationGroup => {
  let currentNode = { ...node };
  if (node.id === maintenanceOperationGroup.id) {
    currentNode = {
      ...currentNode,
      ...maintenanceOperationGroup
    };
  }
  let children = currentNode.children ? [...currentNode.children] : [];
  children = children.map<MaintenanceOperationGroup>(
    (child: MaintenanceOperationGroup) =>
      replaceMaintenanceOperationGroupInTree(child, maintenanceOperationGroup)
  );
  return {
    ...currentNode,
    children: children.length ? children : []
  };
};

const addMaintenanceOperationGroupToTree = (
  node: MaintenanceOperationGroup,
  parentId: number,
  maintenanceOperationGroup: MaintenanceOperationGroup
) => {
  let children = node.children ? [...node.children] : [];
  children = children.map(child =>
    addMaintenanceOperationGroupToTree(
      child,
      parentId,
      maintenanceOperationGroup
    )
  );
  if (node.id === parentId) {
    children = [...children, maintenanceOperationGroup];
  }
  return {
    ...node,
    children: children.length ? children : null
  };
};

const removeFromTree = (
  node: MaintenanceOperationGroup,
  maintenanceOperationGroupId: number
) => {
  let children = node.children ? [...node.children] : [];
  children = children
    .filter(child => child.id !== maintenanceOperationGroupId)
    .map(child => removeFromTree(child, maintenanceOperationGroupId));
  return {
    ...node,
    children: children.length ? children : null
  };
};

const reducer = (
  state: ?MaintenanceOperationGroup = initialState,
  { type, payload }: Action
): ?MaintenanceOperationGroup => {
  switch (type) {
    case SET_MAINTENANCE_OPERATION_GROUP:
      return payload;
    case CLEAR_MAINTENANCE_OPERATION_GROUP:
      return initialState;
    default:
      return state;
  }
};

export const clear = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_OPERATION_GROUP
  });

export const addMaintenanceOperationGroup: Function = (
  maintenanceOperationGroup: MaintenanceOperationGroup,
  parentId: number
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const addedMaintenanceOperationGroup = await maintenanceOperationGroupApi.addMaintenanceOperationGroup(
    {
      ...maintenanceOperationGroup,
      parentId
    }
  );
  const state = getState();
  const addedMaintenanceOperationGroups = addMaintenanceOperationGroupToTree(
    state.maintenanceOperationGroups,
    parentId,
    addedMaintenanceOperationGroup
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUPS,
    payload: addedMaintenanceOperationGroups
  });
};

export const updateMaintenanceOperationGroup: Function = (
  orgUnit: MaintenanceOperationGroup
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const updatedMaintenanceOperationGroup = await maintenanceOperationGroupApi.updateMaintenanceOperationGroup(
    orgUnit
  );
  const state = getState();
  const maintenanceOperationGroups = replaceMaintenanceOperationGroupInTree(
    state.maintenanceOperationGroups,
    updatedMaintenanceOperationGroup
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUPS,
    payload: maintenanceOperationGroups
  });
};

export const deleteMaintenanceOperationGroup: Function = (
  id: number
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  await maintenanceOperationGroupApi.deleteMaintenanceOperationGroup(id);
  const state = getState();
  const maintenanceOperationGroup = removeFromTree(
    state.maintenanceOperationGroups,
    id
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUPS,
    payload: maintenanceOperationGroup
  });
};

export const fetchMaintenanceOperationGroup: Function = (
  id: number
): Function => async (dispatch: Dispatch): Promise<void> => {
  const maintenanceOperationGroup = await maintenanceOperationGroupApi.fetchMaintenanceOperationGroup(
    id
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUP,
    payload: maintenanceOperationGroup
  });
};

export default reducer;
