// @flow
import React, { useState, useEffect, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';

import type { SpreadsheetTableColumn } from '../index';
import {
  computeColumnsWidth,
  getDataColumns,
  getRowsCount,
  treeToFlattenGrid
} from '../lib';
import {
  HeaderCell,
  HeaderCellText,
  HeaderVirtualizedGrid,
  HeaderVirtualizedItem
} from '../elements';
import { createGlobalStyle } from 'styled-components';

type Props = {
  // Колонки
  columns: SpreadsheetTableColumn[],
  // Ширина шапки
  width?: number,
  // Высота шапки
  height?: number,
  // Свойства gridProps из React Virtualized
  gridProps?: any,
  // Количество первых закрепленных колонок
  // React Virtualized позволяет закреплять только N-первых колонок
  fixedColumnCount?: number
};

/**
 * Прячет скролы у шапки
 *
 * У React-virtualized в MultiGrid баг с невозможностью
 * назначения стилей для основного контейнера
 */
const OverrideReactVirtualizedBug = createGlobalStyle`
  .spreadsheet__table-header .BottomLeftGrid_ScrollWrapper + .ReactVirtualized__Grid {
    overflow-x: hidden !important;
  }
`;

/**
 * Шапка таблицы
 */
const TableHeader = ({
  columns = [],
  width,
  height,
  gridProps = {},
  fixedColumnCount = 0
}: Props) => {
  const [gridItems, setGridItems] = useState([]);

  const grid = useRef(null);

  // Подсчитываем общую ширину для каждого родительского столбца
  const widths = computeColumnsWidth(columns);
  const rowsCount = getRowsCount(columns);
  useEffect(() => {
    const { current } = grid;
    setGridItems(
      columns.map(column => {
        return {
          column,
          items: treeToFlattenGrid([column], rowsCount)
        };
      })
    );
    if (current) {
      current.recomputeGridSize();
    }
  }, [columns, height, rowsCount]);

  const getColumnWidth = ({ index }) => widths[index] || 0;

  // Ячейка шапки
  const renderHeaderCell = (
    column: SpreadsheetTableColumn,
    key: number,
    gridItem: {
      gridColumnStart: string,
      gridColumnEnd: string,
      gridRowStart: string,
      gridRowEnd: string
    },
    isDeepLeaf: boolean
  ) => {
    const {
      gridColumnStart,
      gridColumnEnd,
      gridRowStart,
      gridRowEnd
    } = gridItem;
    const {
      header: { title, render = (value: any) => value, style }
    } = column;
    return (
      <HeaderCell
        key={key}
        isLeaf={isEmpty(column.columns)}
        isDeepLeaf={isDeepLeaf}
        grid={{
          gridColumnStart,
          gridColumnEnd,
          gridRowStart,
          gridRowEnd
        }}
      >
        <HeaderCellText style={style} isDeepLeaf={isDeepLeaf}>
          {render(title)}
        </HeaderCellText>
      </HeaderCell>
    );
  };

  return (
    // Проверка потому что React Virtualized
    // почему-то для MultiGrid падает с ошибкой, что width неопеределен
    // при этом для того же Grid такой ошибки нет
    !!width && (
      <div className="spreadsheet__table-header">
        <HeaderVirtualizedGrid
          width={width}
          fixedColumnCount={fixedColumnCount}
          height={height}
          rowCount={1}
          styleTopRightGrid={{
            overflow: 'hidden'
          }}
          styleBottomRightGrid={{
            overflow: 'hidden'
          }}
          styleBottomLeftGrid={{
            overflow: 'hidden'
          }}
          styleTopLeftGrid={{
            overflow: 'hidden'
          }}
          style={{
            overflow: 'hidden',
            outline: 'none'
          }}
          enableFixedColumnScroll
          hideTopRightGridScrollbar
          hideBottomLeftGridScrollbar
          ref={grid}
          rowHeight={height}
          columnWidth={getColumnWidth}
          columnCount={gridItems.length}
          cellRenderer={({ columnIndex, key, style }) => {
            const { column, items } = gridItems[columnIndex];
            const { columns = [] } = column;
            const widths = getDataColumns(columns)
              .map(col => !!col.width && `${col.width}px`)
              .join(' ');
            return (
              <HeaderVirtualizedItem key={key} style={style} widths={widths}>
                {items.map((item, index) => {
                  const { column, gridColumn, gridRow, isDeepLeaf } = item;
                  return renderHeaderCell(
                    column,
                    index,
                    {
                      gridColumnStart: gridColumn.start,
                      gridColumnEnd: gridColumn.end,
                      gridRowStart: gridRow.start,
                      gridRowEnd: gridRow.end
                    },
                    isDeepLeaf
                  );
                })}
              </HeaderVirtualizedItem>
            );
          }}
          {...gridProps}
        />
        <OverrideReactVirtualizedBug />
      </div>
    )
  );
};

export default TableHeader;
