// @flow
import React from 'react';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { budgetAnalysisApi } from '../../../lib/api';

import { navigate } from '../../../lib/helpers';
import { withUserAccess } from '../../withUserAccess';

import { canAdd } from './accessRight';
import Filter from './components/Filter';

const entryPointPath = '/budget/budget-analysis/';
const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Расчетный годовой бюджет',
    dataIndex: 'mainBudget.name'
  },
  {
    title: 'Бюджет БК',
    dataIndex: 'budgetBk.name'
  },
  {
    title: 'Бюджет БКФ',
    dataIndex: 'budgetBkf.name'
  }
];

export default withUserAccess(({ userAccess }) => {
  const access = {
    add: canAdd(userAccess) ? () => navigate(`${entryPointPath}new`) : undefined
  };
  return (
    <Page>
      <CommonListPage
        crud={budgetAnalysisApi}
        pageHeaderProps={{
          breadCrumbs: [
            { to: '/', name: 'Главная' },
            { name: 'Анализ затрат' }
          ],
          mainHeader: 'Анализ затрат',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
