// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import qs from 'query-string';

import type { OrgUnitNode, Employee, ListState } from '../../lib/types';
import { positions as positionsTranslate } from './../../lib/enum';
import {
  Popconfirm,
  Icon,
  Operations,
  Table,
  ButtonOperations,
} from './../../components/ui';
import { Section } from './../../components/layout';
import Filter from './Filter';
import Header from '../../components/layout/Header';
import type { Position } from '../../lib/types';
import type { EmployeesFilterParams } from './Filter';
import { getListInitialState, setQueryParams } from '../../lib/helpers';
import type { FetchListParams } from '../../lib/api';
import { employeeApi } from '../../lib/api';
import { notificationLoading } from '../../components/Notifications';

type Props = {
  location: Location & { state: { page: number } },
};

type State = ListState<Employee> & {
  filter: EmployeesFilterParams,
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class EmployeeList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  static defaultProps = {
    location: {},
  };

  async deleteEmployee(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await employeeApi.deleteEmployee(id);
      await this.fetchEmployees(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: 'ФИО',
      dataIndex: 'name',
      sorter: true,
      sorterKey: 'person.lastName',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: Employee) =>
        `${record.lastname || ''} ${record.firstname || ''} ${
          record.middlename || ''
        }`,
    },
    {
      title: 'Табельный номер',
      dataIndex: 'personNumber',
      sorter: true,
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
      sorterKey: 'node.name',
      sorter: true,
    },
    {
      title: 'Должность',
      dataIndex: 'positions',
      sorter: true,
      render: (positions: Position[]) =>
        positions && (
          <ul>
            {positions.map((position, index) => (
              <li key={index}>{positionsTranslate[position]}</li>
            ))}
          </ul>
        ),
    },
    {
      title: ' ',
      dataIndex: 'id',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: OrgUnitNode): any => (
        <Operations>
          {!(record.children && record.children.length) && (
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await this.deleteEmployee(record.id)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )}
        </Operations>
      ),
    },
  ];

  fetchEmployees = async (
    page: number = 1,
    params: FetchListParams<EmployeesFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await employeeApi.fetchEmployees({
      ...filter,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  cleanFilter = () => this.setState({ filter: {} }, this.fetchEmployees);

  applyFilter = (filter: EmployeesFilterParams) =>
    this.setState({ filter }, this.fetchEmployees);

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.fetchEmployees(page));
  }

  handleRowClick = (id: number) => navigate(`/admin/employees/${id}`);

  // handlePrint = async () => {
  //   try {
  //     notificationLoading({
  //       message: 'Формирование файла для печати',
  //       key: 'print'
  //     });
  //     await employeeApi.print();
  //   } catch (error) {
  //     notification.error({
  //       message: 'Ошибка',
  //       description: error.message
  //     });
  //   } finally {
  //     notification.close('print');
  //   }
  // };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Сотрудники</h1>}
          right={
            <ButtonOperations>
              {/*<Button onClick={this.handlePrint}>Экспорт</Button>*/}
              <Button
                type="primary"
                data-cy="addEmployee"
                onClick={() => navigate('/admin/employees/new')}
              >
                Создать
              </Button>
            </ButtonOperations>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            rowKey="id"
            fetch={this.fetchEmployees}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            locale={{
              emptyText: 'Нет сотрудников',
            }}
          />
        </Section>
      </>
    );
  }
}

export default EmployeeList;
