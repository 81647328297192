// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import { Link, navigate } from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import type {
  InspectionGibdd,
  ListState,
  UserAccess,
  Vehicle,
} from './../../../lib/types';
import { Section } from './../../../components/layout';
import { Popconfirm, Table, Icon, ButtonsRow } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';
import Header from './../../../components/layout/Header';
import {
  getListInitialState,
  setQueryParams,
  convertVehicleToString,
  formatDateTimeToString,
} from './../../../lib/helpers';
import { inspectionGibddApi, type FetchListParams } from './../../../lib/api';
import { withUserAccess } from './../../withUserAccess';

import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';

import { canHandleService, ACT_COLUMNS } from '../lib';

import InspectionGibddFilter from './Filter';
import type { InspectionGibddFilterParams } from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void,
};

type State = ListState<InspectionGibdd> & {
  filterPath: string,
};

export const COLUMNS = [
  {
    title: 'Марка',
    sorter: true,
    dataIndex: 'vehicle',
    render: (vehicle: Vehicle) => convertVehicleToString(vehicle),
  },
  {
    title: 'Дата обслуживания',
    dataIndex: 'maintenanceDate',
    width: '150px',
    render: (value: string) => formatDateTimeToString(value, 'DD.MM.YYYY'),
  },
  {
    title: 'Следующая дата обслуживания',
    dataIndex: 'nextActDate',
    width: '150px',
    render: (value: string) => formatDateTimeToString(value, 'DD.MM.YYYY'),
  },
  {
    title: 'Сумма оплаты в руб. без НДС',
    dataIndex: 'paymentAmount',
  },
];

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };
  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname,
  };
  columns = [...COLUMNS, ...ACT_COLUMNS];

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteInspectionGibdd(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
      });
    }
    await this.fetchInspectionGibddList(page);
  }

  deleteInspectionGibdd = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await inspectionGibddApi.deleteInspectionGibdd(id);
      await this.fetchInspectionGibddList(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchInspectionGibddList = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    try {
      this.setState({ loading: true });
      const { data, totalCount } =
        await inspectionGibddApi.fetchInspectionsGibdd({
          ...filter,
          page,
          ...params,
        });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleRowClick = (id: number) =>
    navigate(`/services/inspections-gibdd/${id}`);

  canAdd = () => canHandleService(this.props.userAccess);

  setPersistFilter = async (values: InspectionGibddFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };

  applyFilter = async (filter: InspectionGibddFilterParams = {}) => {
    await this.setPersistFilter(filter);
    await this.fetchInspectionGibddList();
  };

  cleanFilter = this.applyFilter;

  handlePrint = async () => {
    try {
      const filter = this.props.persistFilters[this.state.filterPath];
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print',
      });
      await inspectionGibddApi.printReport(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filterPath } =
      this.state;
    const filter = this.props.persistFilters[filterPath];
    return (
      <>
        <Header
          left={<h1>Технические осмотры ГИБДД</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canAdd() && (
                <Link to="/services/inspections-gibdd/new">
                  <Button type="primary" data-cy="add">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section>
          <InspectionGibddFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            fetch={this.fetchInspectionGibddList}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            loading={loading}
            columns={this.columns}
            data={data.map((item) => ({
              ...item,
              key: item.id,
            }))}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters,
  }),
  {
    setFilter,
  }
)(withUserAccess(List));
