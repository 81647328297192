// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import { Link } from '@reach/router';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type {
  OrgUnitNode,
  Location as LocationType,
  UserAccess,
  ListState,
} from './../../lib/types';
import { accessTypeEnum } from './../../lib/enum';
import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Filter, { type LocationFilterParams } from './Filter';
import Header from '../../components/layout/Header';
import { withUserAccess } from './../withUserAccess';
import { getListInitialState, setQueryParams } from './../../lib/helpers';
import { locationApi, type FetchListParams } from './../../lib/api';
import { notificationLoading } from './../../components/Notifications';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export const canHandleLocationAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingLocation,
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<LocationType> & {
  filter: LocationFilterParams,
};

class LocationList extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };

  state = {
    ...getListInitialState(),
    filter: {},
  };

  fetchLocations = async (
    page: number = 1,
    params: FetchListParams<LocationFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await locationApi.fetchLocations({
        ...filter,
        page,
        ...params,
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  async deleteLocation(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      const { page, pageSize } = this.state;
      await locationApi.deleteLocation(id);
      await this.fetchLocations(page, { pageSize });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  canHandle = () =>
    this.props.userAccess.some((access) =>
      canHandleLocationAccess.includes(access)
    );

  columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      sorter: true,
    },
    {
      title: 'Является стоянкой',
      dataIndex: 'isDefault',
      sorter: true,
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: LocationType) =>
        record.isDefault ? 'Да' : '',
    },
    {
      title: ' ',
      dataIndex: 'id',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: OrgUnitNode): any => (
        <Operations>
          {this.canHandle() && (
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await this.deleteLocation(record.id)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )}
        </Operations>
      ),
    },
  ];

  handleRowClick = (id: number) => navigate(`/admin/locations/${id}`);

  cleanFilter = () => this.setState({ filter: {} }, this.fetchLocations);

  applyFilter = (filter: LocationFilterParams) =>
    this.setState({ filter }, this.fetchLocations);

  render() {
    const { location } = this.props;
    const { data, totalCount, page, pageSize, loading, filter } = this.state;
    const canHandle = this.canHandle();
    return (
      <>
        <Header
          left={<h1>Объекты</h1>}
          right={
            canHandle && (
              <Link to={`/admin/locations/new`}>
                <Button type="primary" data-cy="addLocation">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            loading={loading}
            rowKey="id"
            onRow={
              canHandle
                ? (record) => ({
                    onClick: () => this.handleRowClick(record.id),
                  })
                : null
            }
            fetch={this.fetchLocations}
            fetchOnMount
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(LocationList);
