/* @flow */

import React from 'react';
import { Redirect } from '@reach/router';

export default class Logout extends React.Component<{}, {}> {
  componentDidMount() {
    localStorage.clear();
  }
  render() {
    return <Redirect to="/login" noThrow />;
  }
}
