import React from 'react';
import { toLocalStringRu, tooltipFullText } from '../../../../lib/helpers';

export const whiteSpaceWrapColumn = (data: string) => {
  return (
    <div style={{ whiteSpace: 'pre-line', overflow: 'auto' }}>
      {toLocalStringRu(data)}
    </div>
  );
};

export const NAME_MONTH_SUM = [
  { title: 'Январь', id: 1 },
  { title: 'Февраль', id: 2 },
  { title: 'Март', id: 3 },
  { title: 'Итого за I кв.', id: 13 },
  { title: 'Апрель', id: 4 },
  { title: 'Май', id: 5 },
  { title: 'Июнь', id: 6 },
  { title: 'Итого за II кв.', id: 14 },
  { title: 'Июль', id: 7 },
  { title: 'Август', id: 8 },
  { title: 'Сентябрь', id: 9 },
  { title: 'Итого за III кв.', id: 15 },
  { title: 'Октябрь', id: 10 },
  { title: 'Ноябрь', id: 11 },
  { title: 'Декабрь', id: 12 },
  { title: 'Итого за IV кв.', id: 16 }
];

const columns = [
  {
    dataIndex: 'budgetLineItemName',
    title: 'Статья бюджетной формы',
    key: 'budgetLineItemName',
    // width: 250,
    // fixed: true,
    render: whiteSpaceWrapColumn
  },
  {
    dataIndex: 'expenseDirectionName',
    title: 'Направление расходов',
    key: 'expenseDirectionName',
    // width: 250,
    // fixed: true,
    render: (expenseDirectionName: string) => (
      <>{whiteSpaceWrapColumn(expenseDirectionName)}</>
    )
  },
  {
    dataIndex: 'budgetChange',
    title: 'Изменение бюджета Итого',
    key: 'budgetChange',
    render: whiteSpaceWrapColumn
  },
  {
    dataIndex: 'newCalculation',
    title: 'Предложение в бюджет итого',
    key: 'newCalculation',
    render: whiteSpaceWrapColumn
  },
  {
    dataIndex: 'note',
    title: 'Комментарии филиала',
    key: 'note',
    width: 200,
    render: (note: string) =>
      tooltipFullText(
        note,
        30,
        note?.split(';').map(comment => (
          <>
            {comment}
            <br />
            <br />
          </>
        )) ?? null
      )
  }
  // ...NAME_MONTH_SUM.map((col, index) => ({
  //   dataIndex: `months`,
  //   title: col.title,
  //   key: 'months',
  //   width: 120,
  //   render: months => {
  //     return whiteSpaceWrapColumn(
  //       months.find(month => month.month === col.id)?.plan
  //     );
  //   }
  // }))
];

export default columns;
