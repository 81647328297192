// @flow

import type { MaintenanceOperationPrice } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromRubToKop, convertFromKopToRub } from './../helpers';

export const convertDataToServer = (data: MaintenanceOperationPrice) => ({
  ...data,
  workingHourCost: convertFromRubToKop(data.workingHourCost)
});

export const convertDataFromServer = (data: MaintenanceOperationPrice) => ({
  ...data,
  workingHourCost: convertFromKopToRub(data.workingHourCost)
});

export const fetchMaintenanceOperationPrice = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<MaintenanceOperationPrice>> => {
  const maintenanceOperationPrice = await fetchRequest.get(
    '/maintenanceOperationPrice',
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (maintenanceOperationPrice) {
    return {
      ...maintenanceOperationPrice,
      data: maintenanceOperationPrice.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить прейскурант по видy работ ремонтов');
};

export const getMaintenanceOperationPrice = async (
  id: number
): Promise<MaintenanceOperationPrice> => {
  const maintenanceOperationPrice = await fetchRequest.get(
    `/maintenanceOperationPrice/${id}`
  );
  if (maintenanceOperationPrice)
    return convertDataFromServer(maintenanceOperationPrice);
  throw new Error(
    'Не удалось получить информацию по стоимости виду работ для ремонта'
  );
};

export const addMaintenanceOperationPrice = async (
  maintenanceOperationPrice: MaintenanceOperationPrice
): Promise<MaintenanceOperationPrice> => {
  const added = await fetchRequest.post(
    `/maintenanceOperationPrice`,
    convertDataToServer(maintenanceOperationPrice)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось стоимость вида работ');
};

export const updateMaintenanceOperationPrice = async (
  maintenanceOperationPrice: MaintenanceOperationPrice
): Promise<MaintenanceOperationPrice> => {
  const updeted = await fetchRequest.put(
    `/maintenanceOperationPrice`,
    convertDataToServer(maintenanceOperationPrice)
  );
  if (updeted) return convertDataFromServer(updeted);
  throw new Error('Не удалось отредактировать информацию');
};

export const deleteMaintenanceOperationPrice = async (
  id: number
): Promise<MaintenanceOperationPrice> => {
  const deleted = await fetchRequest.delete(`/maintenanceOperationPrice/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить запись');
};

export const getActualMaintenanceOperationPrice = async (orgUnitId: number) => {
  const actualByBranch = await fetchRequest.get(
    `/maintenanceOperationPrice/${orgUnitId}/getActualByBranch`
  );
  if (actualByBranch) return convertDataFromServer(actualByBranch);
  throw new Error('Нет актуального прайслиста за нормо-час работ');
};

export default {
  getActualMaintenanceOperationPrice,
  fetchMaintenanceOperationPrice,
  getMaintenanceOperationPrice,
  addMaintenanceOperationPrice,
  updateMaintenanceOperationPrice,
  deleteMaintenanceOperationPrice
};
