// @flow

import React, { Component } from 'react';

import Grid, { GridItem } from '../../layout/Grid';
import type { ContractVehicle } from '../../../lib/types';
import type { FreeContractVehicle } from '../../../lib/types/contractVehicle';
import {
  Header,
  Status,
  VehicleCard,
  VehicleCardWrapper,
  VehicleInfo,
  Wrapper
} from './elements';
import {
  NotWeekendVehicleWarning,
  UsedWeekendsVehicleWarning
} from './WarningWeekend';

type Props = {
  contractVehicles: FreeContractVehicle[],
  selected: ?number,
  onSelect: (vehicle: ContractVehicle) => void,
  // Является ли заявка выходным днем
  isWeekend: boolean
};

/**
 * Компонент рисует сетку из машин, которые доступны для выбора
 * Используется при назначении наемного ТС при создании заявки
 */
export default class FreeContractVehicleSelection extends Component<Props> {
  static defaultProps = {
    vehicles: [],
    editable: false
  };

  showWarning = (freeContractVehicle: FreeContractVehicle) => {
    const {
      totalWeekendDays,
      usedWeekends,
      usedInWeekends
    } = freeContractVehicle;
    if (this.props.isWeekend) {
      if (!usedInWeekends) {
        return <NotWeekendVehicleWarning />;
      } else if (totalWeekendDays > 0 && usedWeekends === totalWeekendDays) {
        return (
          <UsedWeekendsVehicleWarning
            total={totalWeekendDays}
            used={usedWeekends}
          />
        );
      }
    }
  };

  renderCard = (freeContractVehicle: FreeContractVehicle) => {
    const {
      contractVehicle,
      usedDistance,
      totalMonthDistance
    } = freeContractVehicle;
    const { vehicle } = contractVehicle;
    return (
      <GridItem key={vehicle.id}>
        <VehicleCard
          className={`vehicle-${vehicle.id}`}
          key={vehicle.id}
          isActive={contractVehicle.id === this.props.selected}
          onClick={() => this.props.onSelect(contractVehicle)}
        >
          <VehicleInfo>
            <Status status={vehicle.status} showLabel={false} />
            <div className="vehicle-info">
              {vehicle.vehicleModel && (
                <p>
                  {vehicle.vehicleModel.brandName} {vehicle.vehicleModel.name}
                </p>
              )}
              <p>{vehicle.licensePlate}</p>
            </div>
            {this.showWarning(freeContractVehicle)}
          </VehicleInfo>
          <p>{totalMonthDistance - usedDistance} км</p>
        </VehicleCard>
      </GridItem>
    );
  };

  render() {
    const { contractVehicles } = this.props;
    return (
      <Wrapper>
        <Header>
          <p>Транспортные средства</p>
        </Header>
        <VehicleCardWrapper>
          <Grid
            gutter="8px"
            rowGutter="8px"
            cols={2}
            media={[
              {
                size: 'md',
                cols: 3
              },
              {
                size: 'lg',
                cols: 5
              }
            ]}
          >
            {contractVehicles.map(this.renderCard)}
          </Grid>
        </VehicleCardWrapper>
      </Wrapper>
    );
  }
}
