// @flow
import React from 'react';
import InnerForm from '../components/InnerForm';
import { vehicleTariffEnum } from '../../../lib/enum';

type Props = {
  vehicleTariffId: number
};

export const VehicleTariffBrandForm = ({ vehicleTariffId }: Props) => {
  return (
    <>
      <InnerForm
        vehicleTariffId={vehicleTariffId}
        type={vehicleTariffEnum.byBrand}
      />
    </>
  );
};

export default VehicleTariffBrandForm;
