// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { vehicleTaxRateApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps;

export default (selectProps: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await vehicleTaxRateApi.fetch({
        ...params
      });
    }}
    fetchSingle={vehicleTaxRateApi.get}
    notFoundText="Объекты налогообложения не найдены"
    placeholder="Выберите объект налогообложения"
    renderOption={(vehicleTaxRate: any, Option) => (
      <Option
        key={vehicleTaxRate.id}
        value={vehicleTaxRate.id}
        vehicleTaxRate={vehicleTaxRate}
      >
        {vehicleTaxRate.name}
      </Option>
    )}
    {...selectProps}
  />
);
