// @flow
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import {
  EllipsisOutlined,
  DownOutlined,
  CaretDownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import type {
  BioTreatmentFacilityContract,
  BioTreatmentFacilityVolume,
  BioTreatmentFacilityTalon,
  BioTreatmentFacilityFact,
  UserAccess,
} from '../../lib/types';
import {
  bioTreatmentFacilityContractApi,
  bioTreatmentFacilityTalonApi,
  bioTreatmentFacilityFactApi,
  bioTreatmentFacilityVolumeApi,
} from '../../lib/api';
import {
  bioTreatmentFacilityTalonStatusEnum,
  bioTreatmentFacilityTalonStatus,
  accessTypeEnum,
  bioTreatmentFacilityFactStatusEnum,
  sideServiceTariffType,
} from '../../lib/enum';
import {
  applyMaskToValue,
  formatDateTimeToString,
  formatRub,
} from '../../lib/helpers';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

import { Card } from '../../components';
import {
  Icon,
  ListTable,
  Operations,
  Dropdown,
  Popconfirm,
} from '../../components/ui';
import { notificationLoading } from '../../components/Notifications';
import { Header, Panel, Section, SectionTitle } from '../../components/layout';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Table } from '../../components/ant/table/Table';

import ModalImage from '../BioTreatmentFacilityFact/components/ModalImage';

import ModalBioTreatmentFacilityTalon from './components/ModalBioTreatmentFacilityTalon';
import ModalBioTreatmentFacilityFact from './components/ModalBioTreatmentFacilityFact';
import ModalBioTreatmentFacilityVolume from './components/ModalBioTreatmentFacilityVolume';
import Talons, { INIT_MODAL_TALON } from './components/Talons';
import { INIT_MODAL_FACT } from './components/Facts';

import { withUserAccess } from '../withUserAccess';

const { Field } = Card;

const OperationIcon = styled(Icon)`
  cursor: pointer;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Content = styled.div`
  padding: 16px;
`;
const StyledSpan = styled.span`
  text-decoration: underline;
  font-size: 12px;
  color: #2770ff;
  cursor: pointer;
`;

export const ContractContext = React.createContext<any>({
  modalFact: { ...INIT_MODAL_FACT },
  modalTalon: { ...INIT_MODAL_TALON },
});

type Props = {
  id?: number,
  userAccess: UserAccess[],
};

type State = {
  bioTreatmentFacilityContract: ?BioTreatmentFacilityContract,
  bioTreatmentFacilityFact: ?BioTreatmentFacilityFact,
  showModal: boolean,
  columns: Array<Object>,
  expandedRowKeys: {
    show: boolean,
    talons: number[],
    volumes: number[],
  },
  modalTalon: {
    show: boolean,
    bioTreatmentFacilityTalon: ?BioTreatmentFacilityTalon,
    volumeId: ?number,
  },
  modalFact: {
    show: boolean,
    bioTreatmentFacilityFact: ?BioTreatmentFacilityFact,
    licensePlates: string[],
    volumeId: ?string,
    talonId: ?number,
  },
  modalVolume: {
    show: boolean,
    bioTreatmentFacilityVolume: ?BioTreatmentFacilityVolume,
  },
};

const INIT_MODAL_VOLUME = {
  show: false,
  bioTreatmentFacilityVolume: null,
};
export class Form extends React.Component<Props, State> {
  state = {
    bioTreatmentFacilityContract: null,
    bioTreatmentFacilityFact: {},
    showModal: false,
    modalTalon: { ...INIT_MODAL_TALON },
    modalFact: { ...INIT_MODAL_FACT },
    modalVolume: INIT_MODAL_VOLUME,
    expandedRowKeys: {
      show: false,
      volumes: [],
      talons: [],
    },
    columns: [
      {
        title: 'Гос.номер',
        key: 'licensePlates',
        dataIndex: 'licensePlates',
        render: (licensePlates: string[]) => (
          <div style={{ whiteSpace: ' break-spaces' }}>
            {licensePlates
              ?.map((licensePlate) =>
                applyMaskToValue(licensePlate, formatLicensePlateMask)
              )
              ?.join(', ') ?? ''}
          </div>
        ),
      },
      {
        title: 'Объемы',
        width: '140px',
        key: 'volume',
        dataIndex: 'volume',
      },
      {
        title: '',
        width: 80,
        dataIndex: 'operations',
        // eslint-disable-next-line no-unused-vars
        render: (
          text: string,
          bioTreatmentFacilityVolume: BioTreatmentFacilityVolume
        ) => {
          return (
            <Operations>
              <Dropdown
                overlayStyle={{ zIndex: '999' }}
                overlay={
                  <Menu>
                    {this.canAddTalon() && this.checkDateContract() && (
                      <Menu.Item
                        onClick={() => {
                          this.setState({
                            modalTalon: {
                              ...INIT_MODAL_TALON,
                              show: true,
                              bioTreatmentFacilityTalon: {
                                bioTreatmentFacilityVolume:
                                  bioTreatmentFacilityVolume,
                                bioTreatmentFacilityVolumeId:
                                  bioTreatmentFacilityVolume.id,
                              },
                            },
                          });
                        }}
                      >
                        Новый талон
                      </Menu.Item>
                    )}
                    {this.canAddFact() && (
                      <Menu.Item
                        onClick={() => {
                          this.showAddFactFromVolume(
                            bioTreatmentFacilityVolume
                          );
                        }}
                      >
                        Внести факт
                      </Menu.Item>
                    )}
                    {bioTreatmentFacilityVolume.talons.length === 0 &&
                      this.canAddVolume() && (
                        <Menu.Item
                          onClick={() => {
                            this.setState({
                              modalVolume: {
                                show: true,
                                bioTreatmentFacilityVolume,
                              },
                            });
                          }}
                        >
                          Редактировать
                        </Menu.Item>
                      )}
                    {this.canAddVolume() && (
                      <Menu.Item className="span-delete-color">
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={() => {
                            this.deleteVolume(bioTreatmentFacilityVolume);
                          }}
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
              </Dropdown>
            </Operations>
          );
        },
      },
    ],
  };

  async componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { id } = this.props;
    try {
      notificationLoading({
        message: 'Ожидание ответа...',
        key: 'fetch',
      });
      if (id) {
        const bioTreatmentFacilityContract =
          await bioTreatmentFacilityContractApi.get(id);
        this.setState({ bioTreatmentFacilityContract });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('fetch');
    }
  };

  showAddFactFromVolume = (
    bioTreatmentFacilityVolume: BioTreatmentFacilityVolume
  ) => {
    try {
      this.setState({
        modalFact: {
          ...INIT_MODAL_FACT,
          show: true,
          licensePlates: bioTreatmentFacilityVolume.licensePlates,
          volumeId: bioTreatmentFacilityVolume.id,
        },
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  deleteVolume = async (
    bioTreatmentFacilityVolume: BioTreatmentFacilityVolume
  ) => {
    try {
      notificationLoading({
        message: 'Удаление объема...',
        key: 'removingVolume',
      });
      if (Array.isArray(bioTreatmentFacilityVolume?.talons)) {
        bioTreatmentFacilityVolume.talons.forEach((talon) => {
          if (talon.status !== bioTreatmentFacilityTalonStatusEnum.created) {
            throw new Error(
              'Нельзя удалить объемы есть талоны в статусе утвержден или закрыт'
            );
          }
        });
      }
      if (bioTreatmentFacilityVolume?.id) {
        await bioTreatmentFacilityVolumeApi.delete(
          bioTreatmentFacilityVolume.id
        );
        await this.fetch();
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('removingVolume');
    }
  };

  expandedTalons = (record: any) => {
    const { expandedRowKeys } = this.state;
    return (
      <Talons
        onExpand={(expanded, record) => {
          if (expanded) {
            this.setState({
              expandedRowKeys: {
                ...expandedRowKeys,
                talons: [...expandedRowKeys.talons, record.key],
              },
            });
          } else {
            this.setState({
              expandedRowKeys: {
                ...expandedRowKeys,
                talons: expandedRowKeys.talons.filter(
                  (key) => key !== record.key
                ),
              },
            });
          }
          return expanded;
        }}
        expandedRowKeys={expandedRowKeys}
        userAccess={this.props.userAccess}
        volume={record}
        canAddFact={this.canAddFact()}
        canApprovingTalon={this.canApprovingTalon()}
        canAddTalon={this.canAddTalon()}
        canEditFact={this.canEditFact()}
        contract={this.state.bioTreatmentFacilityContract ?? {}}
      />
    );
  };

  handleSubmitTalon = async (values: BioTreatmentFacilityTalon) => {
    try {
      notificationLoading({
        message: 'Сохранение талона...',
        key: 'savingTalon',
      });
      let talon = null;
      if (values.id) {
        talon = await bioTreatmentFacilityTalonApi.update({
          ...values,
          leftoverCoupons: values.issuedCoupons,
        });
      } else {
        talon = await bioTreatmentFacilityTalonApi.add({
          ...values,
        });
      }
      this.setState({
        modalTalon: {
          ...INIT_MODAL_FACT,
          volumeId: talon?.bioTreatmentFacilityVolumeId ?? null,
        },
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingTalon');
      this.setState({ modalTalon: { ...INIT_MODAL_TALON } });
    }
  };

  handleSubmitAprovedTalon = async (values: BioTreatmentFacilityTalon) => {
    try {
      notificationLoading({
        message: 'Сохранение и утверждение талона...',
        key: 'savingTalon',
      });
      let talon = null;
      if (values.id) {
        await bioTreatmentFacilityTalonApi.update({
          ...values,
          status: bioTreatmentFacilityTalonStatusEnum.approved,
        });
      } else {
        const talon = await bioTreatmentFacilityTalonApi.add({
          ...values,
        });
        await bioTreatmentFacilityTalonApi.update({
          ...talon,
          status: bioTreatmentFacilityTalonStatusEnum.approved,
        });
      }
      this.setState({
        modalTalon: {
          ...INIT_MODAL_FACT,
          volumeId: talon?.bioTreatmentFacilityVolumeId ?? null,
        },
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingTalon');
      this.setState({ modalTalon: { ...INIT_MODAL_TALON } });
    }
  };

  handleSubmitFact = async (values: BioTreatmentFacilityFact) => {
    const {
      bioTreatmentFacilityContract,
      modalFact: { volumeId },
    } = this.state;
    const companyName = bioTreatmentFacilityContract?.company?.name ?? null;
    const orgUnitName =
      bioTreatmentFacilityContract?.organizationUnit?.name ?? null;
    const fact = {
      ...values,
      status: bioTreatmentFacilityFactStatusEnum.redeemedOperator,
      orgUnitName,
      companyName,
    };
    let result = null;
    try {
      notificationLoading({
        message: 'Вносим факт...',
        key: 'savingFact',
      });
      if (values.id) {
        result = await bioTreatmentFacilityFactApi.update(fact);
      } else {
        if (volumeId) {
          result = await bioTreatmentFacilityFactApi.addToVolume(
            fact,
            volumeId
          );
        } else {
          result = await bioTreatmentFacilityFactApi.add(fact);
        }
      }
      this.setState({
        modalFact: {
          ...INIT_MODAL_FACT,
          talonId: result?.bioTreatmentFacilityTalonId ?? null,
        },
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingFact');
      this.setState({ modalFact: { ...INIT_MODAL_FACT } });
    }
  };

  handleSubmitVolume = async (values: BioTreatmentFacilityVolume) => {
    try {
      notificationLoading({
        message: 'Сохраняем объем...',
        key: 'savingVolume',
      });
      if (values.id) {
        await bioTreatmentFacilityVolumeApi.update(values);
      } else {
        await bioTreatmentFacilityVolumeApi.add({
          ...values,
          bioTreatmentFacilityId: this.props.id,
        });
      }
      await this.fetch();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('savingVolume');
      this.setState({
        modalVolume: {
          ...INIT_MODAL_VOLUME,
        },
      });
    }
  };

  canAddVolume = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBioTreatmentFacility,
      ].includes(access)
    );

  canApprovingTalon = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingBioTreatmentFacility,
      ].includes(access)
    );

  checkDateContract = () => {
    const { bioTreatmentFacilityContract } = this.state;
    if (
      bioTreatmentFacilityContract?.startDate &&
      bioTreatmentFacilityContract?.endDate
    ) {
      const compareDate = moment();
      const startDate = moment(bioTreatmentFacilityContract?.startDate);
      const endDate = moment(bioTreatmentFacilityContract?.endDate);
      return compareDate.isBetween(startDate, endDate);
    }
    return true;
  };

  canAddTalon = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBioTreatmentFacility,
      ].includes(access)
    );

  canEditFact = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.editFactBioTreatmentFacility,
      ].includes(access)
    );

  canAddFact = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.addFactBioTreatmentFacility,
      ].includes(access)
    );

  expandedAll = () => {
    let {
      bioTreatmentFacilityContract,
      expandedRowKeys,
      expandedRowKeys: { show, volumes, talons },
    } = this.state;
    if (!show && Array.isArray(bioTreatmentFacilityContract?.volumes)) {
      bioTreatmentFacilityContract.volumes.forEach((volume) =>
        volumes.push(volume.id)
      );
      bioTreatmentFacilityContract.volumes
        .flatMap(({ talons }) => talons)
        .forEach((talon) =>
          talons.push(`${talon.bioTreatmentFacilityVolumeId}${talon.id}`)
        );
    } else {
      volumes = [];
      talons = [];
    }
    this.setState({
      expandedRowKeys: {
        ...expandedRowKeys,
        show: !show,
        volumes,
        talons,
      },
    });
  };

  render() {
    const { id } = this.props;
    const {
      bioTreatmentFacilityContract,
      showModal,
      columns,
      bioTreatmentFacilityFact,
      modalTalon,
      modalFact,
      modalVolume,
      expandedRowKeys,
    } = this.state;
    return (
      <ContractContext.Provider
        value={{
          modalFact,
          modalTalon,
          handleModalFact: (modalFact: any) => {
            this.setState({
              modalFact,
            });
          },
          handleModalTalon: (modalTalon: any) => {
            this.setState({
              modalTalon,
            });
          },
        }}
      >
        <ModalBioTreatmentFacilityFact
          visible={modalFact.show}
          licensePlates={modalFact.licensePlates}
          onCancel={() => {
            this.setState({ modalFact: { ...INIT_MODAL_FACT } });
          }}
          onSubmit={this.handleSubmitFact}
          bioTreatmentFacilityFact={modalFact.bioTreatmentFacilityFact}
        />
        <ModalBioTreatmentFacilityTalon
          visible={modalTalon.show}
          onCancel={() => {
            this.setState({ modalTalon: { ...INIT_MODAL_TALON } });
          }}
          organizationUnitId={bioTreatmentFacilityContract?.organizationUnitId}
          sideServiceTariffType={
            bioTreatmentFacilityContract?.sideServiceTariffType
          }
          onSubmit={this.handleSubmitTalon}
          onSubmitApprove={this.handleSubmitAprovedTalon}
          bioTreatmentFacilityTalon={modalTalon.bioTreatmentFacilityTalon}
          canApprovingTalon={this.canApprovingTalon()}
        />
        <ModalBioTreatmentFacilityVolume
          visible={modalVolume.show}
          onCancel={() => {
            this.setState({
              modalVolume: {
                ...INIT_MODAL_VOLUME,
              },
            });
          }}
          onSubmit={this.handleSubmitVolume}
          bioTreatmentFacilityVolume={modalVolume.bioTreatmentFacilityVolume}
        />
        <Header
          left={
            <>
              <Breadcrumbs>
                <Crumb to="/">Главная</Crumb>
                <Crumb to="/bioTreatmentFacilityContract">
                  Список учета БОС
                </Crumb>
                {id && <Crumb>БОС №{id}</Crumb>}
              </Breadcrumbs>
            </>
          }
          right={
            this.canAddVolume() &&
            this.checkDateContract() && (
              <Button
                onClick={() => {
                  this.setState({
                    modalVolume: {
                      show: true,
                      bioTreatmentFacilityVolume: {
                        bioTreatmentFacilityContractId: id,
                      },
                    },
                  });
                }}
              >
                Добавить объем
              </Button>
            )
          }
        />
        <StyledPanel>
          <h1>
            {id && `БОС №${id}`}
            {id && (
              <OperationIcon
                onClick={() => {
                  navigate(`/bioTreatmentFacilityContract/${id}/edit`);
                }}
                type="edit"
                size={16}
              />
            )}
          </h1>
        </StyledPanel>
        <Section>
          <Content>
            <Grid cols={3}>
              <GridItem>
                <Field label="Подразделение">
                  {bioTreatmentFacilityContract?.organizationUnit?.name ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Контрагент">
                  {bioTreatmentFacilityContract?.company?.name ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Номер договора">
                  {bioTreatmentFacilityContract?.contractNumber ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип контракта услуги">
                  {bioTreatmentFacilityContract?.sideServiceTariffType
                    ? sideServiceTariffType[
                        bioTreatmentFacilityContract?.sideServiceTariffType
                      ]
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата начала">
                  {bioTreatmentFacilityContract?.startDate
                    ? formatDateTimeToString(
                        bioTreatmentFacilityContract.startDate,
                        'DD.MM.YYYY'
                      )
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата окончания">
                  {bioTreatmentFacilityContract?.endDate
                    ? formatDateTimeToString(
                        bioTreatmentFacilityContract.endDate,
                        'DD.MM.YYYY'
                      )
                    : '-'}
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <StyledSpan onClick={this.expandedAll}>
                {expandedRowKeys.show ? 'Скрыть все' : 'Раскрыть все'}
              </StyledSpan>
            }
          >
            Объемы
          </SectionTitle>
          <Table
            rowClassName={() => {
              return 'background-light-blue-important';
            }}
            pagination={false}
            columns={columns}
            dataSource={
              bioTreatmentFacilityContract?.volumes.map((volume) => ({
                ...volume,
                key: volume.id,
              })) ?? []
            }
            expandable={{
              expandedRowRender: this.expandedTalons,
              onExpand: (expanded, record) => {
                if (expanded) {
                  this.setState({
                    expandedRowKeys: {
                      ...expandedRowKeys,
                      volumes: [...expandedRowKeys.volumes, record.key],
                    },
                  });
                } else {
                  this.setState({
                    expandedRowKeys: {
                      ...expandedRowKeys,
                      volumes: expandedRowKeys.volumes.filter(
                        (key) => key !== record.key
                      ),
                    },
                  });
                }
                return expanded;
              },
              expandedRowKeys: [...expandedRowKeys.volumes],
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <UpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <DownOutlined onClick={(e) => onExpand(record, e)} />
                ),
            }}
          />
        </Section>
      </ContractContext.Provider>
    );
  }
}

export default withUserAccess(Form);
