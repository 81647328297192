import React from 'react';
import Input from 'antd/lib/input/Input';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { Okei } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';

export default (props: CommonFormProps<$Shape<Okei>>) => {
  return (
    <CommonForm {...props}>
      {() => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Код" required name="code" fast>
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Наименование единицы измерения"
                  required
                  name="name"
                  fast
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Условное обозначение"
                  required
                  name="alias"
                  fast
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
