// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number
};

export default ({ vehiclePlanId }: Props) => {
  return (
    <Tabs withRouter>
      <TabItem
        label="Текущий ремонт"
        url={`/budget/vehicle/${vehiclePlanId}/current-repair`}
      />
      <TabItem
        label="Капитальный ремонт"
        url={`/budget/vehicle/${vehiclePlanId}/overhaul-repair`}
      />
      <TabItem
        label="Бюджет по ремонтам"
        url={`/budget/vehicle/${vehiclePlanId}/budget-repair`}
      />
    </Tabs>
  );
};
