// @flow

import type { VehicleFuelAndOil } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addVehicleFuelAndOil = async (
  vehicleFuelsAndOils: VehicleFuelAndOil
): Promise<VehicleFuelAndOil> => {
  const added = await fetchRequest.post(
    '/vehicleFuelsAndOils',
    vehicleFuelsAndOils
  );
  if (added) return added;
  throw new Error('Не удалось привязать ГСМ к ТС');
};

export const updateVehicleFuelAndOil = async (
  vehicleFuelsAndOils: VehicleFuelAndOil
): Promise<VehicleFuelAndOil> => {
  const updated = await fetchRequest.put(
    '/vehicleFuelsAndOils',
    vehicleFuelsAndOils
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить ГСМ у ТС');
};

export const fetchVehicleFuelsAndOils = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<VehicleFuelAndOil>> =>
  await fetchRequest.get('/vehicleFuelsAndOils', {
    ...initialFetchListParams,
    ...params
  });

export const deleteVehicleFuelAndOil = async (
  id: number
): Promise<VehicleFuelAndOil> => {
  const deleted = await fetchRequest.delete(`/vehicleFuelsAndOils/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось отвязать ГСМ у ТС');
};

export default {
  addVehicleFuelAndOil,
  updateVehicleFuelAndOil,
  fetchVehicleFuelsAndOils,
  deleteVehicleFuelAndOil
};
