import { Modal } from 'antd';
import Button from 'antd/lib/button';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { MonthPicker } from '../../../../components/ant/DatePicker';

import { oilsAndFluidsConsumptionPlanApi } from '../../../../lib/api';
import { printNotification } from '../../../../lib/notificationWrapper';

const ReportModal = (props: {
  visible: boolean,
  onClose: Function,
  onOk: (date: moment) => {}
}) => {
  const [date, setDate] = useState(null);

  return (
    <Modal
      okText={'Печать'}
      cancelText={'Отмена'}
      visible={props.visible}
      maskClosable
      destroyOnClose
      closable={false}
      title={'Отчет по остаткам'}
      style={{ top: 20, right: 20, position: 'absolute' }}
      okButtonProps={{ disabled: !date }}
      onOk={() => props.onOk(date)}
      onCancel={props.onClose}
    >
      <>
        <MonthPicker
          placeholder="Период плана"
          format="MMM YYYY"
          value={date}
          onChange={value =>
            value
              ? setDate(
              moment(value)
                .utc()
                .startOf('month'),
              )
              : setDate(value)
          }
        />
      </>
    </Modal>
  );
};

export default () => {
  const [reportModalVisible, setReportModalVisible] = useState(false);

  const print = async (date: moment) => {
    printNotification(async () => {
      await oilsAndFluidsConsumptionPlanApi.print({
        date: moment(date).toISOString()
      });
      setReportModalVisible(false);
    });
  };

  const handlePrintClick = useCallback(async ({ key }) => {
    setReportModalVisible(true);
  }, []);

  return (
    <>
      <Button onClick={handlePrintClick}>Отчет по остаткам</Button>
      <ReportModal
        visible={reportModalVisible}
        onClose={() => setReportModalVisible(false)}
        onOk={print}
      />
    </>
  );
};
