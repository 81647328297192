// @flow
import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input';

import type { JobTitle } from '../../../lib/types/jobTitle';
import Form from '../../../components/Form';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import OrgUnitSelect from '../../../components/OrgUnitSelect';
import Button from 'antd/lib/button';
import CancelButton from '../../../components/CancelButton';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  jobTitle: JobTitle,
  onCancel: Function,
  onSubmit: Function
};

export default ({ jobTitle, onCancel, onSubmit }: Props) => (
  <Form initialValues={jobTitle} onSubmit={onSubmit}>
    {(
      FormField: FormFieldType,
      {
        setFieldValue,
        handleSubmit,
        handleBlur,
        values,
        dirty,
        isSubmitting
      }: FormikProps
    ) => (
      <form onSubmit={handleSubmit}>
        <StyledSection>
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Название должности" required fast name="name">
                {field => <Input {...field} />}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Подразделение" required fast name="orgUnitId">
                {({ name, value }) => (
                  <OrgUnitSelect
                    value={value}
                    onlyBranches
                    name={name}
                    onBlur={() => handleBlur({ target: { name } })}
                    onChange={(value: number) => setFieldValue(name, value)}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        </StyledSection>
        <Footer>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {values.id ? 'Сохранить' : 'Создать'}
          </Button>
          <CancelButton dirty={dirty} onClick={onCancel}>
            Отменить
          </CancelButton>
        </Footer>
      </form>
    )}
  </Form>
);
