// @flow
import { Button } from 'antd';

import Modal from 'antd/lib/modal';
import { FormikProps } from 'formik';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Form } from '../../../../components';
import { StyledInputNumber } from '../../../../components/hoc/common/components/elements';
import { Grid, GridItem } from '../../../../components/layout';
import Selects from '../../../../components/selects';
import { fileTagEnum } from '../../../../lib/enum';

import type { AdditionalCalculation, VehiclePlan } from '../../../../lib/types';
import Uploader from '../../../Vehicles/components/Uploader';

import type { ModalProps } from '../components/Common';
import { MONTH } from '../lib';

const { ExpenseDirectionSelect } = Selects;

export const ModalAdditionalCalculation = (
  props: ModalProps<AdditionalCalculation> & { vehiclePlan: VehiclePlan }
) => {
  const {
    visible,
    data,
    handleSave,
    handleCancel,
    isRangeMonths,
    readOnly,
    vehiclePlan
  } = props;
  return (
    <Modal
      destroyOnClose
      width={600}
      title="Добавление новой дополнительной потребности"
      visible={visible}
      onCancel={handleCancel}
      footer={
        readOnly
          ? null
          : [
              <Button key="back" onClick={handleCancel}>
                Отмена
              </Button>,
              <Button
                key="submit"
                type="primary"
                form="modal-form"
                htmlType="submit"
              >
                Сохранить
              </Button>
            ]
      }
    >
      <Form initialValues={data} onSubmit={handleSave}>
        {(FormField, formikProps: FormikProps) => {
          const { setFieldValue } = formikProps;
          return (
            <form onSubmit={formikProps.handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem fullWidth>
                  <FormField
                    label="Наименование направления расходов"
                    name="expenseDirectionId"
                    required
                  >
                    {({ name, value }) => (
                      <ExpenseDirectionSelect
                        name={name}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        disabled={readOnly}
                        filter={{
                          startDate: vehiclePlan.startDate,
                          endDate: vehiclePlan.endDate,
                          isAdditionalNeed: true
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>

                {MONTH.map((month, index) => {
                  return (
                    <GridItem key={index}>
                      <FormField
                        label={`${month.title}(руб. без НДС)`}
                        name={`months[${index}].cost`}
                        key={index}
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            min={0}
                            value={value}
                            disabled={
                              (isRangeMonths && !isRangeMonths(index)) ||
                              readOnly
                            }
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  );
                })}
                <GridItem fullWidth>
                  <FormField label="Файл обоснования" required name="file">
                    {({ value, name }) => (
                      <Uploader
                        readOnly={readOnly}
                        value={orderBy(value ? [value] : [], 'createdTime', [
                          'desc'
                        ])}
                        onChange={files => {
                          setFieldValue(name, files[0]);
                          setFieldValue('fileId', files[0].id);
                        }}
                        tag={fileTagEnum.all}
                        listType="text"
                        allowEmptyList
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
