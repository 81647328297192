// @flow

import React, { Component } from 'react';
import InputNumber from 'antd/lib/input-number';
import styled, { css } from 'styled-components';
import sumBy from 'lodash/sumBy';
import Button from 'antd/lib/button';
import debounce from 'lodash/debounce';
import notification from 'antd/lib/notification';

import type {
  FuelMultiplier,
  TripRange,
  FuelMultiplierFilter,
} from '../../lib/types/';
import { Icon, ListTable } from '../../components/ui';
import { fuelMultiplierApi, vehicleApi } from '../../lib/api';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Selects, Card } from './../../components';
import { getVehicleConstMultipliers } from './../Vehicles/lib';
import { withEmptyRow } from '../../lib/helpers';

const { FuelMultipliersSelect } = Selects;
const { Field } = Card;

const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: red;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  ${(props) =>
    props.type === 'primary' &&
    css`
      margin-bottom: 20px;
    `};
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const LeftDistanceInfo = styled.p`
  ${({ color = 'initial' }) =>
    css`
      color: ${color};
    `};
  font-size: 10px;
  padding-top: 10px;
`;

type Props = {
  vehicleId: number,
  tripId: number,
  distance: number,
  onChange: (sumFuelMultiplier: ?number, tripRanges: TripRange[]) => void,
};

type State = {
  vehicleFuelMultipliers: FuelMultiplier[],
  vehicleSumFuelMultipliers: number,
  tripRanges: TripRange[],
  tripRangeActive: boolean,
};

/**
 * Применение топливных коэффициентов для трипов
 */
class TripRangeForm extends Component<Props, State> {
  state = {
    vehicleFuelMultipliers: [],
    tripRanges: [],
    vehicleSumFuelMultipliers: 0,
    tripRangeActive: false,
  };

  static defaultProps = {
    distance: 0,
  };

  emptyRow = {
    tripId: this.props.tripId,
    fuelMultipliers: [],
  };

  async componentDidMount() {
    await this.fetchFuelMultipliers();
  }

  fetchFuelMultipliers = async () => {
    try {
      const vehicle = await vehicleApi.fetchVehicle(this.props.vehicleId);
      let vehicleFuelMultipliers =
        await fuelMultiplierApi.fetchVehicleFuelMultipliers(
          this.props.vehicleId
        );

      const constMultipliers = await getVehicleConstMultipliers(vehicle);

      vehicleFuelMultipliers = [...vehicleFuelMultipliers, ...constMultipliers];

      const vehicleSumFuelMultipliers =
        sumBy(vehicleFuelMultipliers, 'value') || 0;

      this.setState(
        {
          vehicleFuelMultipliers,
          vehicleSumFuelMultipliers,
        },
        this.updateResults
      );
    } catch (err) {
      notification.error({
        message: 'Ошибка при получении списка коэффициентов',
        description: err.message,
      });
    }
  };

  toggleTripRangeView = () =>
    this.setState(
      (prevState: State) => ({
        tripRangeActive: !prevState.tripRangeActive,
      }),
      this.updateResults
    );

  onChange = (
    index: number,
    key: $Keys<TripRange>,
    value: any,
    record: TripRange
  ) =>
    this.setState((prevState) => {
      const tripRanges = [...prevState.tripRanges];
      tripRanges[index] = {
        ...record,
        [(key: string)]: value,
      };
      return {
        tripRanges,
      };
    });

  removeTripRange = (index: number) =>
    this.setState(() => {
      const tripRanges = [...this.state.tripRanges];
      tripRanges.splice(index, 1);
      return {
        tripRanges,
      };
    }, this.updateResults);

  updateResults = debounce(async () => {
    const { tripRanges, vehicleSumFuelMultipliers, tripRangeActive } =
      this.state;
    if (tripRangeActive) {
      this.props.onChange(
        vehicleSumFuelMultipliers,
        tripRanges.map((tripRange: TripRange) => ({
          ...tripRange,
          sumFuelMultiplier: sumBy(tripRange.fuelMultipliers, 'value'),
        }))
      );
    } else {
      this.props.onChange(vehicleSumFuelMultipliers, []);
    }
  }, 500);

  render() {
    const { tripRanges, tripRangeActive, vehicleFuelMultipliers } = this.state;
    const { distance } = this.props;
    const leftDistance: number = distance - sumBy(tripRanges, 'amount');
    return (
      <StyledGrid gutter="16px">
        <GridItem fullWidth>
          {vehicleFuelMultipliers.length > 0 && (
            <Field label="Постоянные коэффициенты">
              {vehicleFuelMultipliers
                .map((item: FuelMultiplier) => `${item.name} (x${item.value})`)
                .join(', ')}
            </Field>
          )}
        </GridItem>
        <GridItem fullWidth>
          <StyledButton
            type={tripRangeActive ? 'primary' : 'default'}
            onClick={this.toggleTripRangeView}
          >
            {tripRangeActive
              ? 'Отменить разбиение по участкам'
              : 'Разбить по участкам'}
          </StyledButton>

          {tripRangeActive && (
            <ListTable
              columns={[
                {
                  title: 'Пробег, км',
                  key: 'amount',
                  width: '210px',
                  render: (
                    amount: string,
                    record: TripRange,
                    index: number
                  ) => (
                    <>
                      <InputNumber
                        value={amount}
                        step={0.1}
                        decimalSeparator=","
                        min={0}
                        onChange={(value: number) =>
                          this.onChange(index, 'amount', value, record)
                        }
                        onBlur={this.updateResults}
                      />
                      &nbsp; км.
                      {index === tripRanges.length - 1 && (
                        <>
                          {leftDistance > 0 && (
                            <LeftDistanceInfo color="green">
                              Осталось {Math.abs(leftDistance).toFixed(2)} км
                            </LeftDistanceInfo>
                          )}
                          {leftDistance < 0 && (
                            <LeftDistanceInfo color="red">
                              Превышено {Math.abs(leftDistance).toFixed(2)} км
                            </LeftDistanceInfo>
                          )}
                        </>
                      )}
                    </>
                  ),
                },
                {
                  title: 'Коэффициенты',
                  key: 'fuelMultipliers',
                  style: {
                    overflow: 'hidden',
                  },
                  render: (
                    fuelMultipliers: FuelMultiplier[] = [],
                    record: TripRange,
                    index: number
                  ) => (
                    <FuelMultipliersSelect
                      filter={
                        ({
                          shouldUsedAlways: false,
                        }: FuelMultiplierFilter)
                      }
                      placeholder="Выберите топливный коэффициент"
                      onChange={(value: Object, options: Object[]) =>
                        this.onChange(
                          index,
                          'fuelMultipliers',
                          options.map((option) => option.props.multiplier),
                          record
                        )
                      }
                      mode="multiple"
                      defaultActiveFirstOption={false}
                      notFoundContent="Доступных коэффициентов не найдено"
                      onBlur={this.updateResults}
                      value={fuelMultipliers.map(
                        (fuelMultiplier: FuelMultiplier) => fuelMultiplier.id
                      )}
                      renderOption={(
                        fuelMultiplier: FuelMultiplier,
                        Option
                      ) => (
                        <Option
                          key={fuelMultiplier.id}
                          value={fuelMultiplier.id}
                          multiplier={fuelMultiplier}
                          disabled={fuelMultiplier.shouldUsedAlways}
                        >
                          {fuelMultiplier.name} (x
                          {fuelMultiplier.value})
                        </Option>
                      )}
                    />
                  ),
                },
                {
                  width: '20px',
                  renderRecord: (record: TripRange, index: number) => (
                    <Operations>
                      <StyledIcon
                        onClick={() => this.removeTripRange(index)}
                        type="x"
                      />
                    </Operations>
                  ),
                },
              ]}
              data={
                leftDistance <= 0
                  ? tripRanges
                  : withEmptyRow(tripRanges, {
                      emptyRow: this.emptyRow,
                    })
              }
            />
          )}
        </GridItem>
      </StyledGrid>
    );
  }
}

export default TripRangeForm;
