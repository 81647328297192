// @flow

import type { Dispatch } from 'redux';

import type { Trip, WayPoint, TripStatus } from '../lib/types';
import { fuelCardApi, routeApi, tripApi } from '../lib/api';
import type { Action, AppState } from './redux';
import { tripStatusEnum, waypointTypeEnum } from '../lib/enum';

export const FETCH_TRIP = 'vehicles/trip/fetch';
export const CLEAN_TRIP = 'vehicles/trip/clean';

export type TripState = ?Trip;

const initialState: TripState = null;

const reducer = (
  state: TripState = initialState,
  { type, payload }: Action
): TripState => {
  switch (type) {
    case FETCH_TRIP:
      return { ...payload };
    case CLEAN_TRIP:
      return null;
    default:
      return state;
  }
};

export const addTrip: Function =
  (trip: Trip): Function =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<Trip> => {
    const addedRoute = await routeApi.addRoute(trip.expectedRoute);
    const saved: Trip = await tripApi.addTrip({
      ...trip,
      expectedRouteId: addedRoute.id,
    });

    if (getState().trip !== null) {
      dispatch({
        type: FETCH_TRIP,
        payload: {
          ...saved,
        },
      });
    }
    return saved;
  };

export const updateTrip: Function =
  (trip: Trip): Function =>
  async (dispatch: Dispatch, getState: () => AppState): Promise<Trip> => {
    if (trip.expectedRoute) {
      await routeApi.updateRoute(trip.expectedRoute);
    }
    if (trip.actualRoute) {
      const startWaypoint = trip.actualRoute.waypoints.find(
        (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.start
      );
      let endWaypoint;
      if (trip.actualRoute) {
        endWaypoint = trip.actualRoute.waypoints.find(
          (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.end
        );
      }
      if (startWaypoint && startWaypoint.departureDateTime) {
        trip.startDate = startWaypoint.departureDateTime;
      }
      if (endWaypoint && endWaypoint.arrivedDateTime) {
        trip.endDate = endWaypoint.arrivedDateTime;
      }
    } else if (trip.expectedRoute) {
      const startWaypoint = trip.expectedRoute.waypoints.find(
        (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.start
      );
      const endWaypoint = trip.expectedRoute.waypoints.find(
        (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.end
      );
      if (startWaypoint && startWaypoint.departureDateTime) {
        trip.startDate = startWaypoint.departureDateTime;
      }
      if (endWaypoint && endWaypoint.arrivedDateTime) {
        trip.endDate = endWaypoint.arrivedDateTime;
      }
    }
    const updated: Trip = await tripApi.updateTrip(trip);
    if (trip.expectedRouteId) {
      // Тянем все наши роуты
      updated.expectedRoute = await routeApi.fetchRoute(trip.expectedRouteId);
    }
    const actualRouteId = parseInt(updated.actualRouteId);
    if (actualRouteId) {
      updated.actualRoute = await routeApi.fetchRoute(actualRouteId);
    }

    // Обновляем стейт, если только в нем что-то есть,
    // т.к. обновление ПЛ (путевого листа) может происходит
    // не только в форме и карточке ПЛ
    if (getState().trip !== null) {
      dispatch({
        type: FETCH_TRIP,
        payload: {
          ...updated,
        },
      });
    }
    return updated;
  };

export const verifyTrip =
  (trip: Trip) => async (dispatch: Dispatch, getState: () => AppState) => {
    if (trip.actualRoute) {
      await routeApi.updateRoute(trip.actualRoute);
    }
    await tripApi.updateTrip(trip);
  };

export const fetchTrip: Function =
  (id: number): Function =>
  async (dispatch: Dispatch): Promise<Trip> => {
    const trip: Trip = await tripApi.fetchTrip(id);
    // Тянем все наши роуты
    if (trip.expectedRouteId) {
      trip.expectedRoute = await routeApi.fetchRoute(trip.expectedRouteId);
    }
    const actualRouteId = parseInt(trip.actualRouteId);
    if (actualRouteId) {
      trip.actualRoute = await routeApi.fetchRoute(actualRouteId);
    }
    // тянем топливную карту
    if (trip.vehicle) {
      trip.vehicle.fuelCard = (
        await fuelCardApi.fetchVehicleFuelCards(trip.vehicle.id)
      )[0];
    }
    dispatch({
      type: FETCH_TRIP,
      payload: trip,
    });
    return trip;
  };

export const fetchTripForVerification: Function =
  (id: number, onDisableVerifyFn?: Function): Function =>
  async (dispatch: Dispatch): Promise<Trip> => {
    const trip: Trip = await tripApi.fetchTrip(id);
    if (
      trip.status !== tripStatusEnum.opened &&
      trip.status !== tripStatusEnum.verification
    ) {
      if (typeof onDisableVerifyFn === 'function') {
        onDisableVerifyFn();
      }
      return trip;
    }
    if (trip.status !== tripStatusEnum.verification) {
      await tripApi.changeStatus({
        id: trip.id,
        status: tripStatusEnum.verification,
      });
    }
    // Тянем все наши роуты
    trip.expectedRoute = await routeApi.fetchRoute(trip.expectedRouteId);

    if (trip.actualRouteId) {
      trip.actualRoute = await routeApi.fetchRoute(trip.actualRouteId);
    } else {
      // Клонируем роут для таксировки, если он еще не назначен
      trip.actualRoute = await routeApi.cloneRoute(trip.expectedRouteId);
      trip.actualRouteId = trip.actualRoute.id;
      await tripApi.updateTrip(trip);
    }

    dispatch({
      type: FETCH_TRIP,
      payload: trip,
    });
    return trip;
  };

export const cleanTrip =
  (): Function =>
  (dispatch: Dispatch): void =>
    dispatch({
      type: CLEAN_TRIP,
    });

export const changeStatus =
  (id: number, status: TripStatus): Function =>
  async (dispatch: Dispatch) => {
    const trip = await tripApi.changeStatus({ id, status });
    return await dispatch(fetchTrip(trip.id));
  };

export default reducer;
