// @flow
import type { FuelsAct } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub } from '../helpers';

export const convertDataFromServer = (fuelsAct: FuelsAct) => ({
  ...fuelsAct,
  sum: convertFromKopToRub(fuelsAct.sum)
});

const fuelsAct = new CommonApi<FuelsAct>(
  'fuelsAct',
  'акт списания топлива',
  undefined,
  convertDataFromServer
);

export default {
  ...fuelsAct
};
