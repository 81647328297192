// @flow
import React, { type Node } from 'react';
import { Link as RLink } from '@reach/router';
import styled from 'styled-components';

type Props = {
  children?: Node
};

export default ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

type CrumbProps = {
  to?: string,
  children: Node
};

export const Crumb = ({ to = '', children }: CrumbProps) => (
  <StyledLink to={to}>{children}</StyledLink>
);

const Wrapper = styled.div`
  display: block;
`;

const StyledLink = styled(RLink)`
  color: #3d4042;
  font-size: 14px;
  &::after {
    content: '';
    display: inline-block;
    margin: 0 5px;
    width: 8px;
    height: 8px;
    background: url('/img/icons/arrow-right-12--disabled.svg');
    background-size: cover;
  }
  &:last-of-type {
    color: #adb8c3;
    cursor: default;
    pointer-events: none;
    &::after {
      display: none;
    }
  }
`;
