// @flow
import notification from 'antd/lib/notification';
import * as Leaflet from 'leaflet';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { Map as LeafletMap, Marker, TileLayer } from 'react-leaflet';
import { geocode, getCurrentPosition, MAPBOX_API_KEY } from '../../lib/gis';

import type { WayPoint } from '../../lib/types/index';

const LeafletBox = { height: 264, width: '100%', minWidth: 600 }; // для лифлета нужна явная высота
const bboxKey = 'bbox';

type Props = {
  onPickWaypoint: (?WayPoint) => void,
  waypoint: WayPoint
};

type State = {
  isLoading: boolean,
  modalVisible: boolean,
  waypoint: ?WayPoint,
  bbox: ?string
};

/*
  Рисует кнопку, по нажатию которой открывается модалкой с картой
  Пользователь может выбрать точку на карту, по координатам с помощью реверсивного
  геокодинга будет найден адрес. Когда пользователь жмет ок, вызывается функция колбэк с выбранным вейпойнтом
 */
class WaypointPicker extends React.Component<Props, State> {
  state = {
    modalVisible: false,
    waypoint: this.props.waypoint,
    isLoading: false,
    bbox: localStorage.getItem(bboxKey)
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.waypoint, this.props.waypoint)) {
      this.setState({
        waypoint: this.props.waypoint
      });
    }
  }

  async componentDidMount() {
    if (!localStorage.getItem(bboxKey)) {
      try {
        const {
          coords: { latitude, longitude }
        } = await getCurrentPosition();
        const bbox = Leaflet.latLng(latitude, longitude)
          .toBounds(500)
          .toBBoxString();
        localStorage.setItem(bboxKey, bbox);
      } catch {
        notification.error({
          message: 'Не удалось определить ваше местоположение'
        });
      }
    }
  }

  onMapClick = async (event: {
    latlng: { lat: number, lng: number, toBounds: Function }
  }) => {
    // Когда пользователь кликает на точку карты, мы получаем координаты Let + Long
    // Перевод координат в адрес называется обратным геокодингом, для это используется
    // сервис Google maps platform и в этом методе он и вызывается
    const { lat, lng } = event.latlng;

    localStorage.setItem(bboxKey, event.latlng.toBounds(500).toBBoxString());

    let address = '';

    this.setState(
      (state, props) => {
        return {
          isLoading: true,
          waypoint: {
            ...state.waypoint,
            address,
            latitude: lat,
            longitude: lng,
            radius: 1
          }
        };
      },
      async () => {
        try {
          address = await geocode(lat, lng);
        } catch (error) {
          address = 'Ошибка. Не удалось определить адрес точки.';
        } finally {
          this.setState(
            {
              isLoading: false,
              // $FlowFixMe
              waypoint: {
                ...this.state.waypoint,
                address,
                name: address
              }
            },
            () => {
              this.props.onPickWaypoint(this.state.waypoint);
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <div>
        <div className="leaflet-container" style={LeafletBox}>
          <Map
            onMapClick={this.onMapClick}
            bbox={this.state.bbox}
            waypoint={this.state.waypoint || {}}
          />
        </div>
      </div>
    );
  }
}

/*
  Компонент врапает лифлетовскую карту
 */
const Map = ({
  onMapClick,
  waypoint,
  bbox
}: {
  waypoint: WayPoint,
  onMapClick: Function,
  bbox: any
}) => {
  const isValidWaypoint =
    !isEmpty(waypoint) && !!waypoint.latitude && !!waypoint.longitude;

  /**
   * Конвертирует bbox строку в bounds
   * @param bbox Строка вида 'southwest_lng,southwest_lat,northeast_lng,northeast_lat'
   */
  function convertBBoxToBounds(bbox: string) {
    if (bbox) {
      const bboxArray = bbox.split(',');
      return Leaflet.latLngBounds(
        [bboxArray[1], bboxArray[0]],
        [bboxArray[3], bboxArray[2]]
      );
    }
  }

  const center = isValidWaypoint
    ? [waypoint.latitude, waypoint.longitude]
    : [55.791278, 49.115734];

  return (
    <LeafletMap
      leaflet={{}} // хз что это, но помечено как обязательно поле
      onClick={onMapClick}
      bounds={isValidWaypoint ? null : convertBBoxToBounds(bbox)}
      style={LeafletBox}
      center={center}
      zoom={14}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id="mapbox/streets-v11"
        accessToken={MAPBOX_API_KEY}
      />
      {isValidWaypoint && (
        <Marker position={[waypoint.latitude, waypoint.longitude]} />
      )}
    </LeafletMap>
  );
};
export default WaypointPicker;
