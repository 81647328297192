// @flow
import React from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import { TabItem } from '../../../components/ui';
import Index from '../../../components/ui/Tabs/Tabs';

const StyledTabs = styled(Index)`
  & .tabs__item:first-child {
    margin-left: 0;
  }
`;

type Props = {
  defaultTab: 'optional' | 'attached' | 'tires' | 'batteries',
};

export const NavigationTabs = ({ defaultTab }: Props) => (
  <StyledTabs defaultTab={defaultTab}>
    <TabItem
      tabKey="attached"
      label="Навесное оборудование"
      onClick={() => navigate('/equipment/attached', true)}
    />
    <TabItem
      tabKey="optional"
      label="Дополнительное оборудование"
      onClick={() => navigate('/equipment/optional')}
    />
    <TabItem
      tabKey="tires"
      label="Шины"
      onClick={() => navigate('/equipment/tires')}
    />
    <TabItem
      tabKey="batteries"
      label="АКБ"
      onClick={() => navigate('/equipment/batteries')}
    />
  </StyledTabs>
);

export default NavigationTabs;
