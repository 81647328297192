// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import { DatePicker, RangePicker } from '../../../../components/ant/DatePicker';
import { OrgUnitSelect, Selects } from './../../../../components';
import Field from './../../../../components/card/Field';

import {
  Grid,
  Header,
  Section,
  SectionTitle,
  TopPanel,
} from './../../../../components/layout';
import Breadcrumbs, {
  Crumb,
} from './../../../../components/layout/Breadcrumbs';
import { GridItem } from './../../../../components/layout/Grid';
import { notificationLoading } from './../../../../components/Notifications';
import { vehicleApi, vehiclePlanApi } from './../../../../lib/api';
import { ownerTypes, vehicleStatusEnum, STSEnum } from './../../../../lib/enum';
import {
  formatDateTimeToISOString,
  getPathWithHistoryParams,
  getValueObject,
  isEmptyValue,
  navigate,
} from './../../../../lib/helpers';

import type {
  SelfVehiclePlanVehicle,
  Vehicle,
  VehiclePlan,
  VehicleStatus,
} from './../../../../lib/types';

import type { VehicleTypeData } from './../lib';
import ModalPlannedVehicle from './components/ModalPlannedVehicle';

import ModalVehicle from './components/ModalVehicle';

import VehicleSection from './components/VehicleSection';

type VehicleParams = {
  data: SelfVehiclePlanVehicle[],
  loading: boolean,
  modal: boolean,
};
type Props = {
  type: ?('clone' | 'edit'),
  location: Location & { state: { page: number } },
  vehiclePlanId: ?$Shape<number>,
  selfVehicles: ?$Shape<Map<number, SelfVehiclePlanVehicle>>,
  plannedVehicles: ?$Shape<Map<number, SelfVehiclePlanVehicle>>,
};
type State = {
  vehiclePlan: ?$Shape<VehiclePlan>,
  selfVehicles: VehicleParams,
  plannedVehicles: VehicleParams,
};

const { BudgetVersionSelect } = Selects;
const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const VEHICLE_STATUS = [
  vehicleStatusEnum.draft,
  vehicleStatusEnum.working,
  vehicleStatusEnum.reserved,
  vehicleStatusEnum.onRepair,
];
const PLANNED_VEHICLE_STATUS = [
  vehicleStatusEnum.draft,
  vehicleStatusEnum.working,
  vehicleStatusEnum.reserved,
  vehicleStatusEnum.onRepair,
];
class BudgetVehicleForm extends Component<Props, State> {
  emptyVehicleParams = {
    data: [],
    loading: false,
    modal: false,
  };

  state = {
    selfVehicles: { ...this.emptyVehicleParams },
    plannedVehicles: { ...this.emptyVehicleParams },
    vehiclePlan: null,
  };

  componentDidMount = () => {
    const { vehiclePlanId } = this.props;
    vehiclePlanId && this.cloneEditVehiclePlan(vehiclePlanId);
  };

  cloneEditVehiclePlan = async (vehiclePlanId: number) => {
    const { type } = this.props;
    this.onChangeVehicle('selfVehicles')('loading', true);
    this.onChangeVehicle('plannedVehicles')('loading', true);
    try {
      // $FlowFixMe
      const vehiclePlan = await this.getContractVehiclePlan(vehiclePlanId);
      this.setState({
        vehiclePlan:
          type === 'clone'
            ? {
                date: vehiclePlan.date,
                orgUnitId: vehiclePlan.orgUnitId,
                plannedVehicles: [
                  ...vehiclePlan.plannedVehicles.map((planned) => ({
                    ...planned,
                    id: undefined,
                  })),
                ],
                selfVehicles: [
                  ...vehiclePlan.selfVehicles.map((self) => ({
                    ...self,
                    id: undefined,
                  })),
                ],
              }
            : { ...vehiclePlan },
      });
      this.fetch('selfVehicles')(vehiclePlan.orgUnitId, VEHICLE_STATUS);
      this.fetch('plannedVehicles')(
        vehiclePlan.orgUnitId,
        PLANNED_VEHICLE_STATUS
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.onChangeVehicle('selfVehicles')('loading', false);
      this.onChangeVehicle('plannedVehicles')('loading', false);
    }
  };

  getContractVehiclePlan = async (
    vehiclePlanId: number
  ): Promise<?VehiclePlan> => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting',
      });
      return await vehiclePlanApi.get(vehiclePlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('getting');
    }
  };

  fetch = (selectedType: VehicleTypeData) => async (
    orgUnitId: number,
    status: VehicleStatus[]
  ) => {
    if (orgUnitId) {
      this.onChangeVehicle(selectedType)('loading', true);
      try {
        // $FlowFixMe
        const { data } = await vehicleApi.fetchVehicles({
          nodeId: orgUnitId,
          status,
          stsType:
            selectedType === 'selfVehicles'
              ? [STSEnum.exploited, 0]
              : [STSEnum.forBudget, 1],
          page: undefined,
          pageSize: undefined,
          ownerType: ownerTypes.self,
        });
        this.onChangeVehicle(selectedType)(
          'data',
          data.map((vehicle) => ({
            vehicle,
            vehicleId: vehicle.id,
            plannedWriteoffDate: vehicle.plannedWriteoffDate,
            plannedPurchaseDate: vehicle.plannedPurchaseDate,
          }))
        );
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      } finally {
        this.onChangeVehicle(selectedType)('loading', false);
      }
    }
  };

  onChangeVehicle = (selectedType: VehicleTypeData) => (
    key: string,
    value: any
  ) => {
    this.setState((prevState) => ({
      // $FlowFixMe
      [selectedType]: {
        ...prevState[selectedType],
        [key]: value,
      },
    }));
  };

  onChangeVehiclePlan = async (key: string, value: any) => {
    await this.setState(
      (prevState) => {
        return {
          vehiclePlan: {
            ...prevState.vehiclePlan,
            [key]: value,
          },
        };
      },
      () => {
        if (key === 'orgUnitId') {
          // сбрасываем выбранные ТС
          this.setState(
            (prevState) => {
              return {
                vehiclePlan: {
                  ...prevState.vehiclePlan,
                  selfVehicles: [],
                  plannedVehicles: [],
                },
                selfVehicles: { ...this.emptyVehicleParams },
                plannedVehicles: {
                  ...this.emptyVehicleParams,
                },
              };
            },
            () => {
              if (value) {
                this.fetch('selfVehicles')(value, VEHICLE_STATUS);
                this.fetch('plannedVehicles')(value, PLANNED_VEHICLE_STATUS);
              }
            }
          );
        }
      }
    );
  };

  validation = (vehiclePlan: VehiclePlan) => {
    const fields = {
      startDate: 'дату начала планируемого периода',
      endDate: 'дату окончания планируемого периода',
      date: 'дату формирования',
      orgUnitId: 'филиал',
      budgetVersionId: 'версию бюджета',
      selfVehicles: 'ТС согласно инвентарной картотеке',
    };
    Object.keys(fields).forEach((field) => {
      if (isEmptyValue(vehiclePlan[field]))
        throw new Error(`Выберите ${fields[field]}`);
    });
  };

  handleVehiclePlan = async () => {
    let { vehiclePlan } = this.state;
    const { selfVehicles, plannedVehicles, type } = this.props;
    if (vehiclePlan) {
      try {
        vehiclePlan.selfVehicles = selfVehicles
          ? Array.from(selfVehicles.values())
          : [];
        vehiclePlan.plannedVehicles = plannedVehicles
          ? Array.from(plannedVehicles.values())
          : [];
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        this.validation(vehiclePlan);
        if (type === 'edit') {
          await vehiclePlanApi.update(vehiclePlan);
        } else {
          vehiclePlan = await vehiclePlanApi.add(vehiclePlan);
        }
        if (vehiclePlan.id) {
          navigate(`/budget/vehicle/${vehiclePlan.id}/card`);
        }
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  hideModal = (selectedType: VehicleTypeData) => () => {
    this.onChangeVehicle(selectedType)('modal', false);
  };

  addVehicle = async (vehicle: Vehicle) => {
    const { selfVehicles } = this.state;
    if (
      selfVehicles.data.findIndex((self) => {
        return self.vehicleId === vehicle.id;
      }) === -1
    ) {
      this.onChangeVehicle('selfVehicles')('data', [
        ...selfVehicles.data,
        { vehicle, vehicleId: vehicle.id },
      ]);
    }
    this.hideModal('selfVehicles')();
  };

  addNewVehicle = async (vehicle: Vehicle) => {
    this.hideModal('plannedVehicles')();
    this.onChangeVehicle('plannedVehicles')('data', [
      ...this.state.plannedVehicles.data,
      { vehicle, vehicleId: vehicle.id },
    ]);
  };

  render() {
    const { vehiclePlan, selfVehicles, plannedVehicles } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/budget/vehicle')}>
                Бюджет СТС
              </Crumb>
              <Crumb>Перечень СТС</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Планируемый перечень ТС</h1>
        </TopPanel>

        <Section>
          <SectionTitle divider>Период и версия бюджета</SectionTitle>
          <Content>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <Field label="Планируемый период">
                  <RangePicker
                    size="small"
                    format="DD.MM.YYYY"
                    placeholder={['Начало', 'Конец']}
                    value={[
                      vehiclePlan?.startDate
                        ? moment.utc(vehiclePlan.startDate)
                        : null,
                      vehiclePlan?.endDate
                        ? moment.utc(vehiclePlan.endDate)
                        : null,
                    ]}
                    onChange={(value, dateString) => {
                      const [startDate, endDate] = value;
                      const [startDateString, endDateString] = dateString;
                      this.onChangeVehiclePlan(
                        'startDate',
                        formatDateTimeToISOString(startDate, startDateString)
                      );
                      this.onChangeVehiclePlan(
                        'endDate',
                        formatDateTimeToISOString(endDate, endDateString)
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Версия бюджета">
                  <BudgetVersionSelect
                    size="small"
                    filter={{ IsAvailableForSelect: true }}
                    value={vehiclePlan?.budgetVersionId}
                    onChange={(budgetVersionId: number) => {
                      this.onChangeVehiclePlan(
                        'budgetVersionId',
                        budgetVersionId
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата формирования">
                  <DatePicker
                    size="small"
                    format="DD.MM.YYYY"
                    value={vehiclePlan?.date ? moment(vehiclePlan.date) : null}
                    onChange={(value: string) =>
                      this.onChangeVehiclePlan('date', value)
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Филилал">
                  <OrgUnitSelect
                    onlyBranches
                    size="small"
                    value={getValueObject(vehiclePlan, 'orgUnitId')}
                    onChange={(orgUnitId) =>
                      this.onChangeVehiclePlan('orgUnitId', orgUnitId)
                    }
                  />
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    this.onChangeVehicle('selfVehicles')('modal', true)
                  }
                  disabled={!vehiclePlan?.orgUnitId}
                >
                  Добавить ТС
                </Button>
              </>
            }
          >
            ТС согласно инвентарной картотеке
          </SectionTitle>
          <Content>
            <VehicleSection
              key={'selfVehicles'}
              vehiclePlanType="selfVehicles"
              data={selfVehicles.data}
              loading={selfVehicles.loading}
              onChange={this.onChangeVehiclePlan}
              selectedVehicle={vehiclePlan?.selfVehicles}
            />
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    this.onChangeVehicle('plannedVehicles')('modal', true)
                  }
                  disabled={!vehiclePlan?.orgUnitId}
                >
                  Добавить новый ТС
                </Button>
              </>
            }
          >
            ТС согласно плану обновления
          </SectionTitle>
          <Content>
            <VehicleSection
              key={'plannedVehicles'}
              vehiclePlanType="plannedVehicles"
              data={plannedVehicles.data}
              loading={plannedVehicles.loading}
              onChange={this.onChangeVehiclePlan}
              selectedVehicle={vehiclePlan?.plannedVehicles}
            />
          </Content>
        </Section>

        {
          <>
            <ModalVehicle
              visible={selfVehicles.modal}
              onCancel={this.hideModal('selfVehicles')}
              addVehicle={this.addVehicle}
              orgUnitId={vehiclePlan?.orgUnitId}
            />
            <ModalPlannedVehicle
              visible={plannedVehicles.modal}
              onCancel={this.hideModal('plannedVehicles')}
              addVehicle={this.addNewVehicle}
              orgUnitId={vehiclePlan?.orgUnitId}
            />
          </>
        }
        <Footer>
          <Button onClick={this.handleVehiclePlan} type="primary">
            Сформировать
          </Button>
        </Footer>
      </>
    );
  }
}

export default connect((state) => ({
  selfVehicles: state.vehiclePlan.selfVehicles,
  plannedVehicles: state.vehiclePlan.plannedVehicles,
}))(BudgetVehicleForm);
