// @flow

import type { Dispatch } from 'redux';

import type { RepairOrder } from './../lib/types';
// TODO: вернуть, когда будет готов сервер
// import { repairOrderApi } from '../lib/api';
import type { Action } from './redux';
import { ownerTypes, fuelTypeEnum } from './../lib/enum';

export const CLEAR_REPAIR_ORDER = 'vehicles/repair-order/clear';
export const SET_REPAIR_ORDER = 'vehicles/repair-order/set';

export type RepairOrderState = ?RepairOrder;

const initialState: RepairOrderState = null;

const reducer = (
  state: RepairOrderState = initialState,
  { type, payload }: Action
): RepairOrderState => {
  switch (type) {
    case SET_REPAIR_ORDER:
      return payload;
    case CLEAR_REPAIR_ORDER:
      return initialState;
    default:
      return state;
  }
};

export const clear = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_REPAIR_ORDER
  });

export const addRepairOrder: Function = (
  repairOrder: RepairOrder
): Function => async (dispatch: Dispatch): Promise<void> => {
  // TODO: заменить
  // const addedRepairOrder = await repairOrderApi.addRepairOrder(repairOrder);
  const addedRepairOrder = repairOrder;
  dispatch({
    type: SET_REPAIR_ORDER,
    payload: addedRepairOrder
  });
};

export const updateRepairOrder: Function = (
  repairOrder: RepairOrder
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  // TODO: заменить
  // const updatedRepairOrder = await repairOrderApi.updateRepairOrder(
  //   repairOrder
  // );
  const updatedRepairOrder = repairOrder;
  dispatch({
    type: SET_REPAIR_ORDER,
    payload: updatedRepairOrder
  });
};

export const deleteRepairOrder: Function = (id: number): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {};
// {
//   const serviceOrders = getState().serviceOrders;
//   const deletedOrder = serviceOrders.data.find(
//     wahingOrder => wahingOrder.id === id
//   );
//   if (deletedOrder)
//     dispatch({
//       type: FETCH_SERVICE_ORDERS,
//       payload: {
//         ...serviceOrders,
//         data: serviceOrders.data.filter(wahingOrder => wahingOrder.id !== id),
//         totalCount: serviceOrders.totalCount - 1
//       }
//     });
//   return deletedOrder;
// };
// TODO: заменить
// repairOrderApi.deleteRepairOrder(id);

export const fetchRepairOrder: Function = (id: number): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const mock = {
    id: 2,
    contractNumber: '545',
    contractorId: 1,
    vehicles: [
      {
        vehicleId: 1,
        vehicle: {
          parentOrgUnitId: 0,
          parentOrgUnitName: null,
          orgUnitId: 1,
          orgUnitName: 'Главный',
          licensePlate: '2525',
          vehicleModelId: 1,
          vehicleModel: {
            name: 'клинцы',
            configuration: 'кук',
            brandId: 1,
            brandName: 'маз',
            type: 'jibCrane',
            fuelConsumption: 13,
            fuelsAndOils: [],
            technologyMap: null,
            id: 1,
            unladenMass: 0,
            grossWeight: 0,
            axleLoad: null,
            dimensions: null,
            maximumAuthorizedMass: 0,
            enginePower: 0,
            passengerSeatsNumber: 0
          },
          status: 'draft',
          yearIssued: 2010,
          vin: '3GNBABFW0BS506525',
          primaryFuelType: fuelTypeEnum.dieselFuel,
          kilometrage: 11,
          normKilometrage: 21,
          averageKilometrage: 0,
          averageDailyKilometrage: 0,
          kilometrageFromLastOverhaul: 0,
          pts: '14',
          ptsDate: '2018-11-19T00:00:00',
          sts: '2332',
          stsDate: '2018-11-19T00:00:00',
          registrationPlace: 'зжхзжх',
          primaryFuelConsumption: 10,
          primaryEquipmentFuelConsumption: 8,
          fuelLevel: 0,
          osago: '245545',
          osagoStartedDate: '2018-11-19T00:00:00',
          osagoFinishedDate: '2018-11-20T00:00:00',
          driverId: 1,
          driver: {
            employee: {
              orgUnitId: 2,
              orgUnitName: 'КЭС',
              firstname: 'Иван',
              lastname: 'Зинин',
              middlename: 'Абрамович',
              birthdate: '0001-01-01T00:00:00',
              personId: 2,
              isDriver: true,
              isUser: true,
              avatarUrl: null,
              avatarId: null,
              position: 'driver',
              protocolNumber: null,
              protocolDate: null,
              orderNumber: null,
              orderDate: null,
              documentNumber: null,
              orgUnitMnemonic: '1221',
              parentOrgUnitMnemonic: null,
              orgUnitInn: null,
              orgUnitKpp: null,
              orgUnitOgrn: null,
              orgUnitAddress: null,
              orgUnitPhone: null,
              id: 2
            },
            orgUnitId: 2,
            orgUnitName: 'КЭС',
            status: 'free',
            licenseNumber: '55656536',
            employeeId: 2,
            orgUnitMnemonic: null,
            parentOrgUnitMnemonic: null,
            orgUnitInn: null,
            orgUnitKpp: null,
            orgUnitOgrn: null,
            orgUnitAddress: null,
            orgUnitPhone: null,
            id: 1
          },
          locationId: 1,
          location: {
            orgUnitId: 2,
            orgUnitName: 'КЭС',
            name: 'прропро',
            longitude: 49.11090173150742,
            latitude: 55.79166295935427,
            radius: 100,
            address:
              'ул. Мусы Джалиля, 7, Казань, Респ. Татарстан, Россия, 420111',
            isDefault: true,
            orgUnitMnemonic: '1221',
            parentOrgUnitMnemonic: null,
            orgUnitInn: null,
            orgUnitKpp: null,
            orgUnitOgrn: null,
            orgUnitAddress: null,
            orgUnitPhone: null,
            id: 1
          },
          images: [],
          axlesNumber: 0,
          chassisNumber: null,
          bodyNumber: null,
          initialCost: 0,
          residualValue: 0,
          partOfCostsMaintenanceFromInitialCost: 0,
          maintenanceCosts: 0,
          overhaulCosts: 0,
          outputRatio: 0,
          totalRepairCosts: 0,
          lastMaintenanceDate: '0001-01-01T00:00:00',
          nextMaintenancePlannedDate: '0001-01-01T00:00:00',
          maintenanceType: 0,
          overhaulDate: '0001-01-01T00:00:00',
          nextOverhaulPlannedDate: '0001-01-01T00:00:00',
          lastExpertiseDate: '0001-01-01T00:00:00',
          lastExpertisePlannedDate: '0001-01-01T00:00:00',
          certificationChangeToolsLastDate: '0001-01-01T00:00:00',
          certificationChangeToolsPlannedDate: '0001-01-01T00:00:00',
          insuranceClass: 0,
          insuranceCasesCount: 0,
          diagnosticCard: null,
          orgUnitMnemonic: null,
          parentOrgUnitMnemonic: null,
          orgUnitInn: null,
          orgUnitKpp: null,
          orgUnitOgrn: null,
          orgUnitAddress: null,
          orgUnitPhone: null,
          inventoryId: 1,
          inventoryNumber: '58745',
          costPerHourForCompanies: 0,
          validPassName: null,
          passDiscontinuedDate: '0001-01-01T00:00:00',
          ownerType: ownerTypes.self,
          passNumber: null,
          passCost: 0,
          contractorId: 0,
          id: 1
        },
        operations: [
          {
            operation: {
              name: 'Почесать что-то',
              hours: 1,
              group: null,
              groupId: 1,
              id: 1
            },
            operationId: 1,
            count: 1
          }
        ]
      }
    ]
  };
  const repairOrder = mock; // await repairOrderApi.fetchRepairOrder(id);
  dispatch({
    type: SET_REPAIR_ORDER,
    payload: repairOrder
  });
};

export default reducer;
