// @flow

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';

import type {
  OptionalEquipment,
  ListState,
  UserAccess,
  Vehicle
} from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';

import { Table } from './../../../components/ui';
import { Panel, Section } from './../../../components/layout';
import Filter, { type OptionalEquipmentFilterParams } from './Filter';
import Header from '../../../components/layout/Header';
import { Crumb } from '../../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../../components/layout/Breadcrumbs';
import NavigationTabs from '../components/NavigationTabs';
import { withUserAccess } from './../../withUserAccess';
import { optionalEquipmentApi, type FetchListParams } from './../../../lib/api';
import { accessTypeEnum } from './../../../lib/enum';
import {
  applyMaskToValue,
  getListInitialState,
  setQueryParams
} from './../../../lib/helpers';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const StyledPanel = styled(Panel)`
  padding-bottom: 0;
  padding-top: 0;
`;
const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const canAddOptionalEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment
];

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

type State = ListState<OptionalEquipment> & {
  filter: OptionalEquipmentFilterParams
};

/** Вывод списка шин */
class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  getDefaultFilter = () => {
    const canViewAll = this.props.userAccess.some(access =>
      [accessTypeEnum.viewingAllEquipments].includes(access)
    );
    const defaultFilter:OptionalEquipmentFilterParams = canViewAll
      ? {...null}
      : ({
          orgUnitId: this.props.employeeBranchOrgUnitId,
          nodeFilterType: 'branchAndChildren'
        });
    return defaultFilter;
  };

  state = {
    ...getListInitialState(),
    filter: this.getDefaultFilter()
  };

  fetchOptionalEquipments = async (
    page: number = 1,
    params: FetchListParams<OptionalEquipmentFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const {
      data,
      totalCount
    } = await optionalEquipmentApi.fetchOptionalEquipments({
      ...filter,
      page,
      ...params,
      ...this.getDefaultFilter() // фильтр по оргюнитам в зависимости от клэймов
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  columns = [
    { title: '№', dataIndex: 'id', sorter: true, width: 25 },
    {
      title: 'Марка',
      dataIndex: 'brandName',
      sorter: true,
      sorterKey: 'brand.name'
    },
    { title: 'Наименование', dataIndex: 'name', sorter: true },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle',
      render: (vehicle: ?Vehicle) =>
        vehicle?.licensePlate ? (
          <Link to={`/vehicles/${vehicle.id}`}>
            {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          '-'
        )
    }
  ];

  handleRowClick = (id: number) => navigate(`/equipment/optional/${id}`);

  cleanFilter = () =>
    this.setState({ filter: {...null} }, this.fetchOptionalEquipments);

  applyFilter = (filter: OptionalEquipmentFilterParams) =>
    this.setState({ filter }, this.fetchOptionalEquipments);

  render() {
    const { location, userAccess } = this.props;
    const { data, totalCount, page, pageSize, loading, filter } = this.state;

    const canAdd = userAccess.some(access =>
      canAddOptionalEquipmentAccess.includes(access)
    );

    return (
      <Fragment>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb>Доп. оборудование</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <PanelTitle>
            <h1>Дополнительное оборудование</h1>
            {canAdd && (
              <Link to="/equipment/optional/new">
                <Button data-cy="addOptional" type="primary">
                  Создать
                </Button>
              </Link>
            )}
          </PanelTitle>
          <NavigationTabs defaultTab="optional" />
        </StyledPanel>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            loading={loading}
            data={data.map(optional => ({
              ...optional,
              key: optional.id
            }))}
            fetch={this.fetchOptionalEquipments}
            fetchOnMount
            columns={this.columns}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </Fragment>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(withUserAccess(List));
