// @flow
import type { BioTreatmentFacility } from '../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams,
} from './index';
import type { FetchListParams, ListResponse } from './index';

const convertDataToServer = (bioTreatmentFacility: BioTreatmentFacility) => ({
  ...bioTreatmentFacility,
  sum: convertFromRubToKop(bioTreatmentFacility.sum),
});
const convertDataFromServer = (bioTreatmentFacility: BioTreatmentFacility) => ({
  ...bioTreatmentFacility,
  sum: convertFromKopToRub(bioTreatmentFacility.sum),
});
const baseUrl = 'bioTreatmentFacility';
const bioTreatmentFacility = new CommonApi<BioTreatmentFacility>(
  baseUrl,
  'учет БОС',
  convertDataToServer,
  convertDataFromServer
);

export const changeStatus = async (
  id: number,
  status: string
): Promise<BioTreatmentFacility> => {
  const bioTreatmentFacility = await fetchRequest.put(
    `/${baseUrl}/changeStatus`,
    {
      id,
      status,
    }
  );
  if (bioTreatmentFacility) return convertDataFromServer(bioTreatmentFacility);
  throw new Error('Не удалось изменит статус');
};

const print = async (id: number) => {
  const print = await downloadRequestWithToken(`/${baseUrl}/${id}/print`);
  if (print) return print;
  throw new Error('Не удалось распечатать');
};

export const list = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<BioTreatmentFacility>> => {
  const bioTreatmentFacilities = await fetchRequest.get(`/${baseUrl}/list`, {
    ...initialFetchListParams,
    ...params,
  });
  if (bioTreatmentFacilities) {
    return {
      ...bioTreatmentFacilities,
      data: bioTreatmentFacilities.data.map(convertDataFromServer),
    };
  }
  throw new Error('Не удалось загрузить список учета БОС');
};

export default { ...bioTreatmentFacility, print, changeStatus, list };
