// @flow

import type { Company } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addCompany = async (company: Company): Promise<Company> => {
  const added = await fetchRequest.post('/company', company);
  if (added) return added;
  throw new Error('Не удалось создать компанию');
};

export const updateCompany = async (company: Company): Promise<Company> => {
  const updated = await fetchRequest.put('/company', company);
  if (updated) return updated;
  throw new Error('Не удалось обновить компанию');
};

export const fetchCompanies = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Company>> =>
  await fetchRequest.get('/company', {
    ...initialFetchListParams,
    ...params
  });

export const fetchCompany = async (
  id: number,
  params: any = {}
): Promise<Company> => {
  const company = await fetchRequest.get(`/company/${id}`, params);
  if (company) return company;
  throw new Error('Не удалось загрузить компанию');
};

export const deleteCompany = async (id: number): Promise<Company> => {
  const deleted = await fetchRequest.delete(`/company/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить компанию');
};

export default {
  addCompany,
  updateCompany,
  fetchCompanies,
  deleteCompany,
  fetchCompany
};
