// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { SideServiceTariff } from '../../lib/types';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { Header, Panel } from '../../components/layout';
import InnerForm from './components/InnerForm';
import { goBack } from './../../lib/helpers';
import { notificationLoading } from './../../components/Notifications';
import { sideServiceTariffApi } from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id?: number,
};

type State = {
  sideServiceTariff: ?SideServiceTariff,
};

export class Form extends React.Component<Props, State> {
  state = {
    sideServiceTariff: null,
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      if (id) {
        const sideServiceTariff = await sideServiceTariffApi.get(id);
        this.setState({ sideServiceTariff });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  handleSubmit = async (values: SideServiceTariff) => {
    let sideServiceTariff = null;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        sideServiceTariff = await sideServiceTariffApi.update(values);
      } else {
        sideServiceTariff = await sideServiceTariffApi.add({
          ...values,
          leftoverCoupons: values.issuedСoupons,
          sum: 10000,
        });
      }
      navigate(`/admin/sideServiceTariff/${sideServiceTariff.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/admin/sideServiceTariff');

  render() {
    const { sideServiceTariff } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/sideServiceTariff">
                Тариф для услуг на сторону
              </Crumb>
              {sideServiceTariff ? (
                <Crumb>
                  Тариф для услуг на сторону №{sideServiceTariff.id}
                </Crumb>
              ) : (
                <Crumb>Новая запись </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {sideServiceTariff
              ? `Тариф для услуг на сторону №${sideServiceTariff.id}`
              : 'Добавление новой записи'}
          </h1>
        </StyledPanel>
        <InnerForm
          sideServiceTariff={sideServiceTariff}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default Form;
