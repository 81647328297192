// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import { inspectionGibddApi } from './../../../../../lib/api';
import { formatDate } from './../../../../../lib/helpers';

import { Table } from './../../../../../components/ui';
import { Header, Section, Panel } from './../../../../../components/layout';
import Breadcrumbs, {
  Crumb
} from './../../../../../components/layout/Breadcrumbs';

import {
  INSPECTION_GIBDD_COLUMNS as COLUMNS,
  Filter
} from './../../../../services/inspectionsGibdd';

import { GroupListTable } from './../../lib';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

export default class extends GroupListTable<any, any> {
  fetch = async (page: number = 1) => {
    const params = {
      ...super.fetch(page)
    };
    try {
      const data = await inspectionGibddApi.fetchInspectionsGibdd({
        ...params,
        ...this.getDate(params.period)
      });
      this.setState({ ...data, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const { period } = filter;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/execution/inspections-gibdd">ТО ГИБДД</Crumb>
              <Crumb>Сравнение данных</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>ТО ГИБДД за {formatDate(period, 'MMMM YYYY')}</h1>
        </StyledPanel>
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            fetch={this.fetch}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            loading={loading}
            columns={COLUMNS}
            data={data}
          />
        </Section>
      </>
    );
  }
}
