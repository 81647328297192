// @flow
import type moment from 'moment';

import {
  accessTypeEnum,
  actStatusEnum,
  approveEnum,
  attachedEquipmentTypes,
  authorizeTypeEnum,
  briefingViewEnum,
  businessDayTypeEnum,
  certificationAreaEnum,
  certificationViewEnum,
  chassisConfigurations,
  contractDocumentTypeEnum,
  contractVehicleRequestStatusesEnum,
  contractVehicleTypeEnum,
  defectiveStatementStatusEnum,
  driverQualificationEnum,
  driverStatuses,
  drivingLicenseCategoryEnum,
  employeeDocumentTypeEnum,
  engineTypeEnum,
  entityStatusEnum,
  entityTypesEnum,
  expenseDirectionTypeEnum,
  fileTagEnum,
  fuelCardVehicleTypes,
  fuelGroups,
  fuelTypes,
  insuranceClasses,
  loadUnloadTypes,
  maintenanceDocumentTypeEnum,
  maintenanceFileTagEnum,
  maintenanceMatrixValidActionEnum,
  maintenanceStatusesTypes,
  maintenanceTypeEnum,
  maintenanceTypeOfWorkEnum,
  measureTypeEnum,
  measureTypeFuelPriceEnum,
  monitoringVehicleStatesEnum,
  monitoringVehicleStatusesEnum,
  monthlyWashingPlanStatusEnum,
  nodeFilterTypeEnum,
  nomenclatureTypeEnum,
  operatingModes,
  operationLimitAggregationFieldsEnum,
  operationLimitTypeEnum,
  optionalEquipmentTypes,
  orderContractorTypeEnum,
  orderObjectivesEnum,
  orderStatusEnum,
  orderTypes,
  orgUnitTypes,
  osagoCalculationStatusEnum,
  osagoTransportType,
  ownerTypes,
  positionEnum,
  pseudoNameEnum,
  quartersEnum,
  rateTypeEnum,
  regulationLimitTypeEnum,
  repairPlanEnum,
  serviceOrderStatusEnum,
  serviceTypeEnum,
  STSEnum,
  tariffTypes,
  tractorDrivingLicenseCategoryEnum,
  trainingViewEnum,
  tripStatusEnum,
  tripTypeEnum,
  vehicleIssueTypeEnum,
  vehicleOvbTypes,
  vehicleStatusEnum,
  vehicleTariffEnum,
  vehicleTypeEnum,
  washingTypes,
  waypointTypeEnum,
  workHourPriceEnum,
  passTypeEnum,
  calculationStatusEnum,
  selfVehiclePlanVehicleTypeEnum,
  operationTypeEnum,
  vehiclePlanStatusEnum,
  budgetSummaryStatusEnum,
  technicalFluidTypesEnum,
  seasonTypeTireEnum,
  pddTestStatusesEnum,
  pddTicketStatusesEnum,
  technicalFluidStatusEnum,
  sparePartMaterialTypesEnum,
  pddTestEnum,
  budgetAnalysisTypesEnum,
  budgetSummaryEnum,
  expenseDirectionDisplayRuleTypeEnum,
  budgetLineItemBudgetEnum,
  expenseDirectionCognosStatusEnum,
  ISUBudgetingDataTypeEnum,
  cognosContractStatusEnum,
  attachedEquipmentFuelTankTypeEnum,
  sideServiceTypeEnum,
  sideServiceTariffMeasureEnum,
  bioTreatmentFacilityFactStatusEnum,
  sideServiceTariffTypeEnum,
  notificationTypeEnum,
} from '../enum';

import type { Company } from './company';
import type { Trip, TripAttachedEquipment } from './trip';
import type { EmployeeDocument } from './employeeDocument';
import type { JobTitle } from './jobTitle';
import { vehicleGroupEnum } from '../enum';

import type { Order } from './order';
import { monthsEnum } from '../enum';
import { tireDiameterEnum, tireProfileEnum, tireWidthEnum } from '../enum/tire';

export type NotificationType = $Keys<typeof notificationTypeEnum>;
export type AttachedEquipmentFuelTankType = $Keys<
  typeof attachedEquipmentFuelTankTypeEnum
>;
export type ExpenseDirectionCognosStatus = $Keys<
  typeof expenseDirectionCognosStatusEnum
>;
export type ISUBudgetingDataTypeType = $Keys<typeof ISUBudgetingDataTypeEnum>;
export type CognosContractStatusType = $Keys<typeof cognosContractStatusEnum>;

export type { Channel } from './channel';

export type {
  CognosContract,
  CognosContractCoefMonth,
  CognosContractMonths,
} from './cognosContract';

export type {
  BioTreatmentFacility, // BioTreatmentFacilityFact,
} from './bioTreatmentFacility';
export type {
  BioTreatmentFacilityContract,
  BioTreatmentFacilityVolume,
  BioTreatmentFacilityTalon,
  BioTreatmentFacilityFact,
  BioTreatmentFacilityContractReport,
} from './bioTreatmentFacilityContract';
export type { Setting } from './setting';
export type { BudgetAnalysis } from './budgetAnalysis';
export type { TripDrivingQuality } from './tripDrivingQuality';
export type { Crew } from './crew';
export type { WriteOffAct, WriteOffActPartAndConsumbles } from './writeOffAct';
export type { RepairBudget } from './repairBudget';
export type { ContractVehiclePlanVehicle } from './contractVehiclePlanVehicle';
export type { ContractVehiclePlan } from './contractVehiclePlan';
export type { Contract } from './contract';
export type { MdmNode } from './mdm';
export type { CompareExpenseDirection } from './compareExpenseDirection';
export type {
  ExpenseDirection,
  ExpenseDirectionInfo,
} from './expenseDirection';
export type { StockPart } from './stockPart';
export type { MonitoringPrice } from './monitoringPrice';
export type { VehicleIssueGroup } from './vehicleIssueGroup';
export type {
  OsagoKprMultiplier,
  OsagoGeneralMultiplier,
  OsagoBaseRateMultiplier,
  OsagoBranchMultiplier,
  OsagoPowerMultiplier,
} from './osagoMultiplier';
export type { User } from './user';
export type {
  Vehicle,
  VehicleForSelection,
  MaintenanceInfoForVehicle,
} from './vehicle';
export type { AttachedEquipment } from './attachedEquipment';
export type { OptionalEquipment } from './optionalEquipment';
export type { Oil } from './oil';
export type { Tire } from './tire';
export type { Osago } from './osago';
export type { Battery } from './battery';
export type { Equipments } from './equipments';
export type {
  VehicleModel,
  VehicleModelFilter,
  MaintenanceRegulation,
  MaintenanceRegulationMaterial,
  MaintenanceRegulationOperation,
  VehicleModelOperationGroup,
  VehicleModelStockPart,
} from './vehicleModel';
export type { FuelAndOil } from './fuelAndOil';
export type { Journal } from './journal';
export type { Company } from './company';
export type { Contractor } from './contractor';
export type {
  ContractVehicle,
  ContractVehicleAnalysis,
} from './contractVehicle';
export type {
  MaintenanceOperationGroup,
  MaintenanceOperation,
  MaintenanceOperationByModel,
} from './maintenanceOperation';
export type { Maintenance } from './maintenance';
export type { WashingOrder } from './washingOrder';
export type { PassOrder } from './passOrder';
export type { VehicleIssue } from './vehicleIssue';
export type {
  RepairOrder,
  RepairOrderVehicle,
  RepairOrderOperation,
} from './repairOrder';
export type { MaintenancePartAndConsumables } from './maintenancePartAndConsumables';
export type { Role } from './role';
export type { Washing } from './washing';
export type { DriverBriefing } from './driverBriefing';
export type { Training } from './training';
export type { EmployeeCertification } from './employeeCertification';
export type { EmployeeBriefing } from './employeeBriefing';
export type { LearningSchedule } from './learningSchedule';
export type { ContractVehicleOrder } from './contractVehicleOrder';
export type { FuelCard, FuelCardVehicle } from './fuelCard';
export type { Driver, DriverQualificationDocument } from './driver';
export type { Pass } from './pass';
export type { FuelMultiplier, FuelMultiplierFilter } from './fuelMultiplier';
export type { Trip, TripRange, TripAttachedEquipment } from './trip';
export type { AttachedEquipmentWorkHour } from './attachedEquipment';
export type { BusinessDay } from './businessDay';
export type { BusinessCalendarForBudget } from './businessCalendarForBudget';
export type { InspectionGibdd } from './inspectionGibdd';
export type { InspectionGtn } from './inspectionGtn';
export type { ExpertiseServiceGpm } from './expertiseServiceGpm';
export type { MeasuringDeviceCertification } from './measuringDeviceCertification';
export type { DriverInspection } from './driverInspection';
export type { Platon } from './platon';
export type { VehicleMonitoring } from './vehicleMonitoring';
export type { LicensePlateChange } from './licensePlateChange';
export type { VehicleFuelAndOil } from './vehicleFuelAndOil';
export type { OperationLimit } from './operationLimit';
export type { OperationLimitGroup } from './operationLimitGroup';
export type { ContractVehicleDailyBudget } from './contractVehicleDailyBudget';
export type { Stou } from './stou';
export type {
  OsagoCalculation,
  OsagoCalculationYear,
} from './osagoCalculation';
export type {
  MonthlyWashingPlan,
  WashingPlanVehicle,
  WashingPlanVehicleSummary,
  WashingPlanBalance,
} from './monthlyWashingPlan';
export type { WashingPriceList } from './washingPriceList';
export type { Order, Cargo, LoadUnloadCargo } from './order';
export type { DetailedDefectiveStatement } from './detailedDefectiveStatement';
export type { MaintenanceWorkType } from './maintenanceWorkType';
export type { MaintenanceOperationPrice } from './maintenanceOperationPrice';
export type { MaintenanceMatrix } from './maintenanceMatrix';
export type { Stage } from './stage';
export type { Offer } from './offer';
export type { IncomeSchedule } from './incomeSchedule';
export type { Act } from './act';
export type { MaintenanceDocument } from './maintenanceDocument';
export type { OutsourcingAct } from './outsourcingAct';
export type { FuelsAndOilsAct } from './fuelsAndOilsAct';
export type { ScheduleMaintenance } from './scheduleMaintenance';
export type { FuelPrice } from './fuelPrice';
export type { BudgetVersion } from './budgetVersion';
export type { VehicleTariff } from './vehicleTariff';
export type { Regulation } from './regulation';
export type { RegulationLimit } from './regulationLimit';
export type { RegulationLimitGroup } from './regulationLimitGroup';
export type { Assignment } from './assignment';
export type {
  AssignmentLimit,
  AssignmentMonth,
  BudgetDetail,
  BudgetDetailMonth,
} from './assignmentLimit';
export type { AssignmentLimitGroup } from './assignmentLimitGroup';
export type { ContractVehicleBudget } from './contractVehicleBudget';
export type {
  VehiclePlan,
  SelfVehiclePlanVehicleMonth,
  SelfVehiclePlanVehicle,
} from './vehiclePlan';
export type { Okei } from './okei';
export type { FederalHighwayFare } from './federalHighwayFare';
export type {
  GpsSubscriptionServiceCost,
  GpsServiceMonthsExclusion,
} from './gpsSubscriptionServiceCost';
export type { MeasuringDeviceCertificationCost } from './measuringDeviceCertificationCost';
export type { InspectionGibddCost } from './inspectionGibddCost';
export type { InspectionGtnCost } from './inspectionGtnCost';
export type { LicensePlateChangeCost } from './licensePlateChangeCost';
export type { Stock } from './stock';
export type { ExpertiseServiceGpmCost } from './expertiseServiceGpmCost';
export type { WashingFrequency } from './washingFrequency';
export type {
  BatteryCalculation,
  BatteryCalculationMonth,
} from './batteryCalculation';
export type {
  GpmMaintenanceCalculation,
  GpmMaintenanceCalculationMonth,
} from './gpmMaintenanceCalculation';
export type {
  MeasuringDeviceCertificationCalculation,
  MeasuringDeviceCertificationCalculationMonth,
} from './measuringDeviceCertificationCalculation';
export type {
  VehicleMonitoringCalculation,
  VehicleMonitoringCalculationMonth,
} from './vehicleMonitoringCalculation';
export type {
  InspectionGtnCalculation,
  InspectionGtnCalculationMonth,
} from './inspectionGtnCalculation';
export type {
  InspectionGibddCalculation,
  InspectionGibddCalculationMonth,
} from './inspectionGibddCalculation';
export type {
  DriverInspectionCalculation,
  DriverInspectionCalculationMonth,
} from './driverInspectionCalculation';
export type {
  WashingCalculation,
  WashingCalculationMonth,
} from './washingCalculation';
export type { PassCalculation, PassCalculationMonth } from './passCalculation';
export type {
  MaterialCalculation,
  MaterialCalculationMonth,
} from './materialCalculation';
export type {
  LicensePlateChangeCalculation,
  LicensePlateChangeCalculationMonth,
} from './licensePlateChangeCalculation';
export type {
  FederalHighwayCalculation,
  FederalHighwayCalculationMonth,
} from './federalHighwayCalculation';
export type { TripInspectionCost } from './tripInspectionCost';
export type { TripInspectionFrequency } from './tripInspectionFrequency';
export type {
  OverhaulType,
  OperationToOverhaulType,
  MtrToOverhaulType,
} from './overhaulType';
export type {
  OverhaulCalculation,
  OverhaulCalculationMonth,
  OverhaulCalculationMonthOperation,
} from './overhaulCalculation';
export type { WorkingHourContractPrice } from './workingHourContractPrice';
export type { CurrentMaintenanceCost } from './currentMaintenanceCost';
export type { MaintenanceCostAllocationTR } from './maintenanceCostAllocationTR';
export type { MaintenanceCostAllocation } from './maintenanceCostAllocation';
export type { VehicleTaxRate, PowerPeriodTaxRate } from './vehicleTaxRate';
export type { VehicleTaxMap } from './vehicleTaxMap';
export type { BudgetsForAnalysis } from './budgetsForAnalysis';
export type { WorkingHourSelfPrice } from './workingHourSelfPrice';
export type { PassTariff } from './passTariff';
export type {
  SpringPassCalculation,
  SpringPassCalculationMonth,
} from './springPassCalculation';
export type {
  CargoPassCalculation,
  CargoPassCalculationMonth,
} from './cargoPassCalculation';
export type {
  CurrentRepairCalculation,
  CurrentRepairCalculationMonth,
} from './currentRepairCalculation';
export type {
  FuelAndOilCalculation,
  FuelAndOilCalculationMonth,
  FuelAndOilMechanism,
  FuelAndOilMechanismMonth,
  FuelAndOilCalculationType,
} from './fuelAndOilCalculation';
export type {
  MaintenanceOperationContractCalculation,
  MaintenanceOperationContractCalculationMonth,
} from './maintenanceOperationContractCalculation';
export type { TireCalculation, TireCalculationMonth } from './tireCalculation';
export type { VehicleTaxCalculation } from './vehicleTaxCalculation';
export type { VehicleTaxPlanVehicle } from './vehicleTaxPlanVehicle';
export type { BudgetLineItem } from './budgetLineItem';
export type { TechnicalFluid } from './technicalFluid';
export * from './technicalFluid';
export type {
  OilsAndFluidsConsumptionPlan,
  OilsAndFluidsConsumptionPlanVehicle,
} from './oilsAndFluidsConsumptionPlan';
export type {
  VehiclePlanBudgetExpenseDirection,
  VehiclePlanBudget,
} from './vehiclePlanBudget';
export type {
  MaintenanceScheduleVehicle,
  MaintenanceSchedule,
} from './maintenanceScheduleVehicle';
export type * from './kasko';
export type * from './dashboard';
export type { PddGroup } from './pddGroup';
export type { PddTest } from './pddTest';
export type {
  BudgetSummary,
  BranchBudgetSummary,
  BranchBudgetSummaryExpenseDirection,
  BranchBudgetSummaryLineItem,
  BranchBudgetSummaryMonth,
} from './budgetSummary';
export type { FuelsAct } from './fuelsAct';
export type { OilsAct } from './oilsAct';
export type {
  PddTicket,
  PddQuestion,
  PddAnswer,
  PddTicketQuestion,
} from './pddTicket';
export type { PddTestResult, PddTestResultAnswer } from './pddTestResult';
export type { SpareParts } from './spareParts';
export type { WialonTrip } from './wialonTrip';
export type {
  AdditionalCalculation,
  AdditionalCalculationMonth,
} from './additionalCalculation';
export type { SmallScaleMechanizationTool } from './smallScaleMechanizationTool';
export type { VehicleTaxPlan } from './vehicleTaxPlan';
export type { RegistrationPlace } from './registrationPlace';
export type {
  DisplayExpenseDirection,
  ExpensesDistribution,
} from './expensesDistribution';
export type { BranchBudgetSummaryMonthRedistribution } from './branchBudgetSummaryMonthRedistribution';
export type { SideServiceTariff } from './sideServiceTariff';
/*###TYPES_INDEX*/

export type SideServiceTariffMeasure = $Keys<
  typeof sideServiceTariffMeasureEnum
>;
export type SideServiceType = $Keys<typeof sideServiceTypeEnum>;
export type SeasonTypeTire = $Keys<typeof seasonTypeTireEnum>;
export type VehicleMaintenancePlanType = $Keys<
  typeof selfVehiclePlanVehicleTypeEnum
>;
export type BudgetAnalysisTypes = $Keys<typeof budgetAnalysisTypesEnum>;
export type OperationType = $Keys<typeof operationTypeEnum>;
export type SelfVehiclePlanVehicleType = $Keys<
  typeof selfVehiclePlanVehicleTypeEnum
>;
export type CalculationStatus = $Keys<typeof calculationStatusEnum>;
export type PassType = $Keys<typeof passTypeEnum>;
export type ExpenseDirectionType = $Keys<typeof expenseDirectionTypeEnum>;
export type ContractVehicleType = $Keys<typeof contractVehicleTypeEnum>;
export type OsagoCalculationStatus = $Keys<typeof osagoCalculationStatusEnum>;
export type MaintenanceDocumentType = $Keys<typeof maintenanceDocumentTypeEnum>;
export type NomenclatureType = $Keys<typeof nomenclatureTypeEnum>;
export type MeasureType = $Keys<typeof measureTypeEnum>;
export type ContractDocumentType = $Keys<typeof contractDocumentTypeEnum>;
export type EntityStatusType = $Keys<typeof entityStatusEnum>;
export type VehiclePlanStatus = $Keys<typeof vehiclePlanStatusEnum>;
export type ActStatusType = $Keys<typeof actStatusEnum>;
export type {
  SelfVehicleRegulation,
  SelfVehicleRegulationStatus,
  SelfVehicleRegulationVehicle,
} from './selfVehicleRegulation';

export type SideServiceTariffType = $Keys<typeof sideServiceTariffTypeEnum>;

export type BioTreatmentFacilityFactStatusType = $Keys<
  typeof bioTreatmentFacilityFactStatusEnum
>;

export type ExpenseDirectionDisplayRuleType = $Keys<
  typeof expenseDirectionDisplayRuleTypeEnum
>;

export type MaintenanceMatrixValidActionType = $Keys<
  typeof maintenanceMatrixValidActionEnum
>;

export type TechnicalFluidType = $Keys<typeof technicalFluidTypesEnum>;

export type MaintenanceTypeOfWorkType = $Keys<typeof maintenanceTypeOfWorkEnum>;

export type OrgUnitType = $Keys<typeof orgUnitTypes>;

// тип тарифа для прейскуранта ГСМ
export type RateType = $Keys<typeof rateTypeEnum>;
// ед. измерения для прейскуранта ГСМ
export type MeasureTypeFuelPrice = $Keys<typeof measureTypeFuelPriceEnum>;

// Статусы для дефектных ведомостей
export type DefectiveStatementStatusType = $Keys<
  typeof defectiveStatementStatusEnum
>;

// тип тарифа для НТС
export type VehicleTariffType = $Keys<typeof vehicleTariffEnum>;

// Типы сущностей для системного журнала
export type EntityType = $Keys<typeof entityTypesEnum>;

// Должность
export type Position = $Keys<typeof positionEnum>;

// Тип фильтра оргюнита (подробности см. в enum)
export type NodeFilterType = $Keys<typeof nodeFilterTypeEnum>;

// Типы точки
export type WaypointType = $Keys<typeof waypointTypeEnum>;
/**
 * Ключ для изменения и валидации WayPoint
 *
 * number - в случае для промежуточных точек
 * startWaypoint - в случае стартовой точки
 * endWaypoitn - в случае конечной точки
 */
export type WaypointKey = 'startWaypoint' | number | 'endWaypoint';

export type OrderStatus = $Keys<typeof orderStatusEnum>;

export type OrderObjective = $Keys<typeof orderObjectivesEnum>;

export type VehicleType = $Keys<typeof vehicleTypeEnum>;

export type VehicleGroup = $Keys<typeof vehicleGroupEnum>;

export type OrderType = $Keys<typeof orderTypes>;

// Клэймы пользователей
export type UserAccess = $Keys<typeof accessTypeEnum>;

// Класс на начало годового срока страхования
export type InsuranceClass = $Keys<typeof insuranceClasses>;

// Тип ТС: свой/наёмный
export type OwnerType = $Keys<typeof ownerTypes>;

// Статус ТС в мониторинге
export type MonitoringVehicleStatus = $Keys<
  typeof monitoringVehicleStatusesEnum
>;

// Состояние ТС в мониторинге
export type MonitoringVehicleState = $Keys<typeof monitoringVehicleStatesEnum>;

// Вид топлива
export type FuelGroup = $Keys<typeof fuelGroups>;

// Тип топлива
export type FuelType = $Keys<typeof fuelTypes>;

// Тип тарифа
export type TariffType = $Keys<typeof tariffTypes>;

// Версия тарифа
export type OperatingMode = $Keys<typeof operatingModes>;

// Статус запроса НТС
export type ContractVehicleRequestStatus = $Keys<
  typeof contractVehicleRequestStatusesEnum
>;

// Тип вкладки для страницы списка ПЛ
export type TripType = $Keys<typeof tripTypeEnum>;

// Статусы заявок на обслуживание
export type ServiceOrderStatus = $Keys<typeof serviceOrderStatusEnum>;

// Статусы ТС
export type VehicleStatus = $Keys<typeof vehicleStatusEnum>;

// Тип дня в производственном календаре
export type BusinessDayType = $Keys<typeof businessDayTypeEnum>;

// Виды работ
export type VehicleIssueType = $Keys<typeof vehicleIssueTypeEnum>;

// Вид мойки
export type WashingType = $Keys<typeof washingTypes>;

// Статус ПЛ
export type TripStatus = $Keys<typeof tripStatusEnum>;

// Статус водителя
export type DriverStatus = $Keys<typeof driverStatuses>;

export type ServiceType = $Keys<typeof serviceTypeEnum>;

// Тип навесного оборудования
export type AttachedEquipmentType = $Keys<typeof attachedEquipmentTypes>;

// Тип ТС для ОВБ (оперативной выездной бригады)
export type VehicleOvbType = $Keys<typeof vehicleOvbTypes>;

// Тип (погрузка или выгрузка)
export type LoadUnloadCargoType = $Keys<typeof loadUnloadTypes>;

export type DrivingLicenseCategory = $Keys<typeof drivingLicenseCategoryEnum>;
export type TractorDrivingLicenseCategory = $Keys<
  typeof tractorDrivingLicenseCategoryEnum
>;

export type BriefingView = $Keys<typeof briefingViewEnum>;

export type EmployeeCertificationView = $Keys<typeof certificationViewEnum>;

// Типы документов для сотрудника
export type EmployeeDocumentType = $Keys<typeof employeeDocumentTypeEnum>;

// Тип операции по топливной карте: привязка, отвязка
export type FuelCardVehicleType = $Keys<typeof fuelCardVehicleTypes>;

export type RepairPlan = $Keys<typeof repairPlanEnum>;

export type MaintenanceType = $Keys<typeof maintenanceTypeEnum>;

export type MaintenanceStatusesType = $Keys<typeof maintenanceStatusesTypes>;

export type OrderContractorType = $Keys<typeof orderContractorTypeEnum>;

export type OptionalEquipmentType = $Keys<typeof optionalEquipmentTypes>;

export type TrainingView = $Keys<typeof trainingViewEnum>;

export type AuthorizeType = $Keys<typeof authorizeTypeEnum>;

export type ChassisConfiguration = $Keys<typeof chassisConfigurations>;

export type EngineType = $Keys<typeof engineTypeEnum>;

export type OperationLimitType = $Keys<typeof operationLimitTypeEnum>;

export type DriverQualification = $Keys<typeof driverQualificationEnum>;

export type MonthlyWashingPlanStatus = $Keys<
  typeof monthlyWashingPlanStatusEnum
>;

export type Quarter = $Keys<typeof quartersEnum>;

export type CertificationArea = $Keys<typeof certificationAreaEnum>;

export type OsagoTransportType = $Keys<typeof osagoTransportType>;

export type RegulationLimitType = $Keys<typeof regulationLimitTypeEnum>;

export type WorkHourPriceType = $Keys<typeof workHourPriceEnum>;

export type STSType = $Keys<typeof STSEnum>;

export type ApproveType = $Keys<typeof approveEnum>;

export type PseudoNameType = $Keys<typeof pseudoNameEnum>;

export type MonthType = $Keys<typeof monthsEnum>;

export type PddTestStatusesType = $Keys<typeof pddTestStatusesEnum>;

export type PddTicketStatusesType = $Keys<typeof pddTicketStatusesEnum>;

export type TechnicalFluidStatusType = $Keys<typeof technicalFluidStatusEnum>;

export type TireDiameterType = $Keys<typeof tireDiameterEnum>;

export type TireWidthType = $Keys<typeof tireWidthEnum>;

export type TireProfileType = $Keys<typeof tireProfileEnum>;

export type SparePartMaterialType = $Keys<typeof sparePartMaterialTypesEnum>;

export type PddTestType = $Keys<typeof pddTestEnum>;

export type BudgetSummaryStatusType = $Keys<typeof budgetSummaryStatusEnum>;

export type BudgetSummaryType = $Keys<typeof budgetSummaryEnum>;

export type BudgetLineItemBudgetType = $Keys<typeof budgetLineItemBudgetEnum>;

export type ExecutionBudgetGroupedList = {
  documentNumber: string,
  branchId: number,
  branchName: string,
  period: string,
  contractor: string,
  actSum: number,
  factSum: number,
};
// Марка
export type Brand = {
  id: number,
  name: string,
};

// Оргструктура
export type OrgUnitNode = {
  name: string,
  id: number,
  code: string,
  key?: string,
  type?: OrgUnitType,
  children?: ?Array<OrgUnitNode>,
  parentId?: number,
  mnemonic: string,
  company: Company,
  companyId: number,
  departmentType?: string,
  receiveContractsData: boolean,
};

export type KilometrageInfo = {
  monitoringDistance: number, // дистанция
  monitoringMachineHours: number, // машчасы
};

// Сотрудник
export type Employee = {
  id: number,
  firstname: string,
  lastname: string,
  middlename: string,
  positions: Position[],
  // чел может водить технику, а должность офицально иметь другую
  positionTitle: string,
  birthdate: Date,
  orgUnitId: number,
  orgUnitName: string,
  email?: string,
  phoneNumber?: string,
  // № протокола о проверке знаний
  protocolNumber: number,
  // Дата протокола проверки знаний
  protocolDate: string,
  // № удостоверения
  documentNumber: number,
  // № приказа о назначении
  orderNumber: number,
  // Дата приказа
  orderDate: number,
  documents: EmployeeDocument[],
  // Документ стропальщика
  riggerDocument: EmployeeDocument,
  // Документ рабочего люльки
  lulechnikDocument: EmployeeDocument,
  // Документ безопасника
  safetyTechnicianDocument: EmployeeDocument,
  // Документ специалиста, ответственный за безопасное производство работ с применением подъёмников (вышек)
  safetyCarLiftDocument: EmployeeDocument,
  // Документ специалиста, ответственный за содержание ПС в работоспособном состоянии
  controllerGpmDocument: EmployeeDocument,
  // Документ машиниста подъемника (вышки)
  carLiftOperatorDocument: EmployeeDocument,
  // Документ машиниста крана (крановщик)
  truckCranesMachinistDocument: EmployeeDocument,
  // Документ оператора (машинист) крана-манипулятора
  manipulatorCranesOperatorDocument: EmployeeDocument,

  jobTitle: ?JobTitle,
  jobTitleId: ?number,
  // Табельный номер
  personNumber: string,
  // Водитель, привязанный к данному пользователю
  driverId: number,
};

export type EmployeeFilter = {
  isDriver?: boolean,
  isUser?: boolean,
  positions?: Position[],
  nodeId?: number,
  nodeFilterType?: NodeFilterType,
};

export type EmployeeBranchPosition = {
  positionTitle: string,
  orgUnitId: number,
  orgUnitName: string,
};

// Объекты
export type Location = {
  id: number,
  name: string,
  longitude: number,
  latitude: number,
  radius: number,
  address: string,
  isDefault: boolean,
  orgUnitId: number,
  // Google Place Id, для извлечения подробной инфы о точке
  googlePlaceId?: string,
};

export type WayPoint = {
  id?: ?number,
  name?: ?string,
  arrivedDateTime?: string,
  departureDateTime?: string,
  arrivedOdometer?: ?number,
  departureOdometer?: ?number,
  location?: ?Location,
  locationId?: ?number,
  longitude: ?number,
  latitude: ?number,
  radius: ?number,
  address: ?string,
  type?: WaypointType,
  key?: WaypointKey,
  notation?: string,
};

export type Route = {
  id: number,
  waypoints: Array<WayPoint>,
  distance: number,
  startDate?: Date,
  endDate?: Date,
};

export type Shift = {
  id: number,
  date: Date,
  medicFullName: ?string, // Фио наемного мед работника
  medicId?: ?number,
  medic?: Employee,
  engineerId?: number,
  engineer?: Employee,
  // Контроллер ГПМ
  gpmController?: Employee,
  gpmControllerId?: number,
  trips: Trip[],
  orders: Order[],
  // Диспетчер
  dispatcher: ?Employee,
  dispatcherId: number,
};

export type ShiftListItem = {
  date: Date,
  orgUnits: OrgUnitNode[],
  ordersCount: number,
  tripsCount: number,
  tripsOpenCount: number,
};

// Учетные данные для логина
export type Credentials = {
  userName: string,
  password: string,
};

// Объект ошибок валидации в форме
export type FormErrors<Type> = {
  [key: $Keys<Type>]: any,
};

/**
 * Объект Touched формы
 */
export type FormTouched<Type> = {
  [key: $Keys<Type>]: boolean,
};

/**
 * Объект Touched формы
 */
export type Response<Type> = Promise<Type | Array<Type> | void>;

export type FileTag = $Keys<typeof fileTagEnum>;
export type MaintenanceFileTag = $Keys<typeof maintenanceFileTagEnum>;

export type FileInfo = {
  id: number,
  fullName: string,
  url: string,
  contentType: string,
  tag: FileTag,
  createdTime: string,
};

export type FuelMeasurement = {
  newPrimaryFuelLevel: number,
  oldPrimaryFuelLevel?: number,
  date: string | moment,
  // Документ-основание
  document: string,
};

export type TireMeasurement = {
  newTreadDepthValue: number,
  oldTreadDepthValue?: number,
  vehicleId: number,
  date: string | moment,
  // Документ-основание
  document: string,
};

// Стейт списков
export type ListState<T> = {
  data: T[],
  page: number,
  pageSize: number,
  totalCount: number,
  totalPages: number,
  loading: boolean,
};

// Лист дерева типов ТС
export type VehicleGroupLeaf = {
  title: string,
  value: VehicleType,
  key: VehicleType,
  props?: any,
};

// Родительский узел дерева типов ТС
export type VehicleGroupParent = VehicleGroupLeaf & {
  children: VehicleGroupLeaf[],
};

// Узел дерева типов ТС
export type VehicleGroupNode = VehicleGroupLeaf | VehicleGroupParent;

// "Плоское" дерево видов ТС
export type VehicleTypesPlainTree = {
  [key: VehicleGroup]: VehicleType[],
};

// Агрегируемое поле лимита
export type OperationLimitAggregationField = $Keys<
  typeof operationLimitAggregationFieldsEnum
>;
