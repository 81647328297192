// @flow
import React from 'react';
import styled from 'styled-components';

const BurgerStripe = styled.div`
  width: 24px;
  height: 2px;
  background: #2770ff;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Burger = styled.div`
  cursor: pointer;
`;

type Props = {
  onClick: Function,
  className?: string
};

const MenuBar = ({ onClick, className }: Props) => (
  <Burger onClick={onClick} className={className}>
    <BurgerStripe />
    <BurgerStripe />
    <BurgerStripe />
  </Burger>
);

export default MenuBar;
