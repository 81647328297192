// @flow
import { Link as ReachLink } from '@reach/router';
import React, { type ComponentType, Fragment } from 'react';
import styled, { css } from 'styled-components';
import type { Profile } from '../../ducks/auth';
import { accessTypeEnum } from '../../lib/enum';
import { convertEmployeeToString, isDevelopment } from '../../lib/helpers';
import { Menu, MenuItem } from '../ui';

import Divider from './Divider';

/**
 * Обертка сайдбара
 * Является областью по которой
 * перемещается основное содержимое
 */
const Wrapper = styled.aside`
  background: white;
  width: 200px;
  height: 100vh;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  box-shadow: 6px 0 10px rgba(0, 0, 0, 0.07);
  ${(props) =>
    !props.expanded &&
    css`
      & * {
        opacity: 0;
      }
      width: 0;
      padding: 0;
    `};
`;

/**
 * Основное содержимое сайдбара
 * Зафиксировано и двигается по обертке Wrapper
 */
const Content = styled.div`
  position: sticky;
  box-sizing: border-box;
  padding-top: 10px;
  top: 50px;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 30px;
`;
const ProfileWrapper = styled.div`
  background: #e4ebf2;
  padding: 8px 16px;
`;
const ProfileEmployee = styled.p`
  font-weight: 500;
  margin-bottom: ${({ marginBottom = 8 }) => marginBottom}px;
`;
const ProfileRole = styled.p`
  color: #98a3ad;
  font-size: 12px;
`;
const ProfileBottomBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

type LinkProps = {
  to: string,
  replace?: boolean,
};

// Роли, для которых всегда есть доступ к основным разделам
const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];
// клеймы для простомотра прейскурантов
const priceDirectory = [
  accessTypeEnum.viewingAllPriceLists,
  accessTypeEnum.viewingPriceLists,
  accessTypeEnum.handlingPriceLists,
  accessTypeEnum.approvingPriceLists,
];
// клеймы для просмотра ремонтов
const maintenance = [
  accessTypeEnum.approvingMaintenances,
  accessTypeEnum.creatingMaintenances,
  accessTypeEnum.viewingMaintenances,
];
// клеймы для ролей и пользователей
const usersAndRoles = [
  accessTypeEnum.viewingUsersAndRoles,
  accessTypeEnum.handlingUsersAndRoles,
];
// клеймы для модели и марки тс
const brandsAndVehicleModels = [
  accessTypeEnum.handlingBrandsAndVehicleModels,
  accessTypeEnum.viewingBrandsAndVehicleModels,
];
// клеймы для подразделений
const orgUnits = [
  accessTypeEnum.handlingOrgUnits,
  accessTypeEnum.viewingOrgUnits,
];
// клеймы для плана расхода тех.жидкостей
const oilsAndFluidsConsumptionPlan = [
  accessTypeEnum.viewingOilsAndFluidsConsumptionPlan,
  accessTypeEnum.handlingOilsAndFluidsConsumptionPlan,
];
// клеймы для журнала талонов на тех.жидкости
const fluidsTickets = [
  accessTypeEnum.viewingFluidsTickets,
  accessTypeEnum.handlingFluidsTickets,
];
// клеймы для запасных частей
const spareParts = [
  accessTypeEnum.viewingSparePart,
  accessTypeEnum.handlingSparePart,
];
const crew = [accessTypeEnum.viewingCrew, accessTypeEnum.handlingCrew];

const LogoutIcon = (props) => (
  <svg width={12} height={12} viewBox="0 0 612 612" {...props}>
    <g fill="#2770FF">
      <path d="M222.545 333.818h319.909l-69.545 111.273h55.637L612 306l-83.454-139.091h-55.637l69.545 111.273H222.545z" />
      <path d="M0 612h445.091V431.182h-55.637v125.181H55.636V55.636h333.818v125.182h55.637V0H0z" />
    </g>
  </svg>
);

/**
 * Определяем атрибут active
 * для ссылок с частично совпадающим URL
 */
const Link = (props: LinkProps) => (
  <ReachLink
    {...props}
    getProps={({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { active: 'active' } : null
    }
  />
);

/**
 * Раздел меню в сайдбаре
 */
const styleLink = `
  display: block;
  color: #3d4042;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 16px;
  transition: background 0.1s;
  position: relative;
  &:hover {
    color: white;
    background: #2770ff;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:focus {
    text-decoration: none;
  }
  &[active] {
    color: white;
    background: #2770ff;
  }
`;

const StyledLink: ComponentType<LinkProps> = styled(Link)`
  ${styleLink}
`;

const StyledA: ComponentType<LinkProps> = styled('a')`
  ${styleLink}
`;

const StyledDivider = styled(Divider)`
  margin: 16px;
`;

type Props = {
  /**
   * Раскрытость сайдбара
   */
  expanded: boolean,
  profile: Profile,
};

const mainLinks = [
  {
    link: '/dashboard',
    text: 'Главная',
    access: [...adminAccess, accessTypeEnum.viewingDashboard],
    cy: 'main',
  },
  {
    link: '/vehicles',
    text: 'Транспорт',
    access: [
      ...adminAccess,
      accessTypeEnum.creatingVehicle,
      accessTypeEnum.handlingOrder,
      accessTypeEnum.viewingVehicleBranch,
      accessTypeEnum.viewingVehicleServiceOnly,
      accessTypeEnum.viewingAllVehicles,
      accessTypeEnum.approvingVehicle,
    ],
    cy: 'vehicles',
  },
  {
    link: '/orders',
    text: 'Заявки',
    access: [
      ...adminAccess,
      accessTypeEnum.creatingOrder,
      accessTypeEnum.approvingGpmOrder,
      accessTypeEnum.handlingOrder,
    ],
    cy: 'orders',
  },
  {
    link: '/shifts',
    text: 'Наряды',
    access: [
      ...adminAccess,
      accessTypeEnum.creatingOrder,
      accessTypeEnum.handlingOrder,
    ],
    cy: 'shifts',
  },
  {
    isGroup: true,
    label: 'Учет тех. жидкостей',
    access: [...adminAccess, ...oilsAndFluidsConsumptionPlan, ...fluidsTickets],
    items: [
      {
        link: '/budget/oils-and-fluids-consumption-plan',
        text: 'План расхода тех.жидкостей',
        access: [...adminAccess, ...oilsAndFluidsConsumptionPlan],
      },
      {
        link: '/technical-fluid/tickets/',
        text: 'Журнал учёта талонов на тех.жидкость',
        access: [...adminAccess, ...fluidsTickets],
      },
    ],
  },
  {
    link: '/trips/self/',
    text: 'Реестр путевых листов',
    access: [
      ...adminAccess,
      // accessTypeEnum.handlingTrip,
      accessTypeEnum.handlingOrder,
    ],
  },
  {
    link: '/wialonTrips',
    text: 'Поездки',
    access: [...adminAccess, accessTypeEnum.viewingTrips],
    cy: 'wialonTrips',
  },
  {
    isGroup: true,
    showDivider: false,
    access: [
      ...adminAccess,
      accessTypeEnum.viewingMaintenanceServices,
      accessTypeEnum.viewingAllMaintenanceServices,
      accessTypeEnum.handlingMaintenanceServices,
    ],
    label: 'Обслуживание',
    items: [
      {
        link: '/services/washings',
        text: 'Мойки',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/pass',
        text: 'Пропуска',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/osagos',
        text: 'ОСАГО',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/inspections-gibdd',
        text: 'Технический осмотр ГИБДД',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/inspections-gtn',
        text: 'Технический осмотр ГТН',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/expertises-services-gpm',
        text: 'Экспертиза ПС/ТО приборов безопасности ПС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/measuring-device-certifications',
        text: 'Тарировка цистерн',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/driver-inspections',
        text: 'Осмотр водителей',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/platons',
        text: 'Платон',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/vehicles-monitoring',
        text: 'Услуги по мониторингу ТС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/license-plate-changes',
        text: 'Замена гос. номеров',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
    ],
  },
  {
    isGroup: true,
    showDivider: false,
    label: 'БОС',
    access: [
      ...adminAccess,
      accessTypeEnum.viewingBioTreatmentFacility,
      accessTypeEnum.handlingBioTreatmentFacility,
      accessTypeEnum.approvingBioTreatmentFacility,
      accessTypeEnum.addFactBioTreatmentFacility,
    ],
    items: [
      {
        link: '/bioTreatmentFacilityContract',
        text: 'Учет БОС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingBioTreatmentFacility,
          accessTypeEnum.handlingBioTreatmentFacility,
          accessTypeEnum.approvingBioTreatmentFacility,
          accessTypeEnum.addFactBioTreatmentFacility,
        ],
      },
      {
        link: '/bioTreatmentFacilityReport',
        text: 'Отчет',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingBioTreatmentFacility,
          accessTypeEnum.handlingBioTreatmentFacility,
          accessTypeEnum.approvingBioTreatmentFacility,
          accessTypeEnum.addFactBioTreatmentFacility,
        ],
      },
      {
        link: '/bioTreatmentFacilityFact',
        text: 'Журнал въезда',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingBioTreatmentFacility,
          accessTypeEnum.handlingBioTreatmentFacility,
          accessTypeEnum.approvingBioTreatmentFacility,
          accessTypeEnum.addFactBioTreatmentFacility,
        ],
      },
    ],
    cy: 'bioTreatmentFacility',
  },
  {
    isGroup: true,
    showDivider: false,
    label: 'Бюджет',
    access: [
      ...adminAccess,
      accessTypeEnum.viewingOsagoCalculation,
      accessTypeEnum.viewingAllWashingPlans,
      accessTypeEnum.viewingWashingPlans,
      accessTypeEnum.formationOsagoCalculations,
      accessTypeEnum.formationWashingPlans,
      accessTypeEnum.approvingWashingPlans,
      accessTypeEnum.viewingAllBudgetExecutions,
      accessTypeEnum.viewingBudgetExecutions,
      accessTypeEnum.viewingContractVehicleBudget,
      accessTypeEnum.viewingAllContractVehicleBudgets,
      accessTypeEnum.handlingBranchVehicleTaxPlans,
      accessTypeEnum.handlingAllVehicleTaxPlans,
      accessTypeEnum.viewingBranchVehicleTaxPlans,
      accessTypeEnum.viewingAllVehicleTaxPlans,
      accessTypeEnum.handlingDetailedBudget,
      accessTypeEnum.handlingBranchDetailedBudget,
      accessTypeEnum.viewingDetailedBudget,
      accessTypeEnum.approvingBranchDetailedBudget,
      accessTypeEnum.approvingDetailedBudget,
      accessTypeEnum.viewingDetailedBudget,
      accessTypeEnum.viewingContracts,
      accessTypeEnum.viewingAllContracts,
      accessTypeEnum.handlingContracts,
      accessTypeEnum.viewingExternalActs,
      accessTypeEnum.viewingAllExternalActs,
      accessTypeEnum.handlingExternalActs,
      accessTypeEnum.viewingInternalActs,
      accessTypeEnum.viewingAllInternalActs,
      accessTypeEnum.handlingInternalActs,
    ],
    items: [
      {
        link: '/budget/tax',
        text: 'Налог',
        access: [
          accessTypeEnum.handlingBranchVehicleTaxPlans,
          accessTypeEnum.handlingAllVehicleTaxPlans,
          accessTypeEnum.viewingBranchVehicleTaxPlans,
          accessTypeEnum.viewingAllVehicleTaxPlans,
          ...adminAccess,
        ],
        cy: '',
      },
      {
        link: '/budget/osago-calculations',
        text: 'ОСАГО',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingOsagoCalculation,
          accessTypeEnum.formationOsagoCalculations,
        ],
        cy: '',
      },
      {
        link: '/budget/monthly-washing-plans',
        text: 'Мойки',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingAllWashingPlans,
          accessTypeEnum.viewingWashingPlans,
          accessTypeEnum.formationWashingPlans,
          accessTypeEnum.approvingWashingPlans,
        ],
      },
      {
        link: '/budget/vehicle',
        text: 'Бюджет ТС',
        access: [...adminAccess],
        cy: '',
      },
      {
        link: '/budget/budget-analysis',
        text: 'Анализ затрат',
        access: [...adminAccess],
        cy: '',
      },
      {
        link: '/budget/summary/',
        text: 'Основной бюджет',
        access: [
          ...adminAccess,
          // accessTypeEnum.handlingDetailedBudget,
          // accessTypeEnum.handlingBranchDetailedBudget,
          // accessTypeEnum.viewingDetailedBudget,
          // accessTypeEnum.approvingBranchDetailedBudget,
          // accessTypeEnum.approvingDetailedBudget
        ],
        cy: '',
      },
      {
        link: '/budget/details/', // + new Date().getFullYear(),
        text: 'Уточнения в бюджет',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingDetailedBudget,
          accessTypeEnum.handlingBranchDetailedBudget,
          accessTypeEnum.viewingDetailedBudget,
          accessTypeEnum.approvingBranchDetailedBudget,
          accessTypeEnum.approvingDetailedBudget,
        ],
        cy: '',
      },
      {
        link: '/budget/act',
        text: 'Реестр первичных документов',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingExternalActs,
          accessTypeEnum.viewingAllExternalActs,
          accessTypeEnum.handlingExternalActs,
          accessTypeEnum.viewingInternalActs,
          accessTypeEnum.viewingAllInternalActs,
          accessTypeEnum.handlingInternalActs,
        ],
        cy: 'act',
      },
      {
        link: '/budget/contract',
        text: 'Реестр договоров',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingContracts,
          accessTypeEnum.viewingAllContracts,
          accessTypeEnum.handlingContracts,
        ],
        cy: 'contract',
      },
    ],
  },
  {
    isGroup: true,
    label: 'Ремонты и ТО',
    access: [...adminAccess, ...maintenance],
    cy: 'maintenances',
    items: [
      {
        link: '/maintenances/schedule',
        text: 'График ТО',
        access: [...adminAccess, ...maintenance],
      },
      {
        link: '/maintenances/journal',
        text: 'Журнал ТО',
        access: [...adminAccess, ...maintenance],
      },
    ],
  },
  {
    link: '/admin/drivers',
    text: 'Водители',
    access: [
      ...adminAccess,
      accessTypeEnum.handlingDriver,
      accessTypeEnum.viewingAllDrivers,
    ],
    cy: 'drivers',
  },
  // {
  //   link: '/monitoring',
  //   text: 'Мониторинг',
  //   access: [...adminAccess],
  //   cy: 'monitoring',
  // },
  {
    link: '/self-regulations',
    text: 'Регламенты',
    showProduction: false,
    access: [
      ...adminAccess,
      accessTypeEnum.approvingTimeLimits,
      accessTypeEnum.handlingTimeLimits,
      accessTypeEnum.viewingTimeLimits,
      accessTypeEnum.viewingBranchTimeLimits,
    ],
    cy: 'trips',
  },
  {
    isGroup: true,
    label: 'ПДД',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingPddTests,
      accessTypeEnum.reloginPddTest,
    ],
    showProduction: false,
    items: [
      {
        link: '/pdd/results/training',
        text: 'Результаты тестирований',
        access: [...adminAccess, accessTypeEnum.reloginPddTest],
        cy: 'main',
      },
      {
        link: '/pdd/test/training/tests/',
        text: 'Обучение',
        access: [...adminAccess, accessTypeEnum.viewingPddTests],
        cy: 'pdd-test-training',
      },
      {
        link: '/pdd/test/exam/tests/',
        text: 'Экзамен',
        access: [...adminAccess, accessTypeEnum.viewingPddTests],
        cy: 'pdd-test-exam',
      },
      {
        link: '/driverRedirect/',
        text: 'Личная карточка',
        access: [...adminAccess, accessTypeEnum.viewingPddTests],
        cy: 'main',
      },
    ],
  },
  {
    link: '/wialonMonitoring',
    type: 'blank-link',
    text: 'Мониторинг',
    access: [...adminAccess, accessTypeEnum.wialonPage],
    cy: 'wialon',
  },
  {
    isGroup: true,
    showDivider: false,
    label: 'Отчеты',
    access: [...adminAccess, accessTypeEnum.viewingReports],
    items: [
      {
        link: '/report/drivers',
        text: 'Водители',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/fuels-and-oils',
        text: 'Расход ГСМ',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/fuel-cards',
        text: 'Топливные карты',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/vehicle-works',
        text: 'Работа ТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/write-off-vehicles',
        text: 'Рекомендуемые к списанию/замене ТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/taxed-trips',
        text: 'Таксированные путевые листы',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/gpm-order',
        text: 'Учёт заявок ГПМ',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/vehicle-output-ratio',
        text: 'Коэффициент выхода ТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/business-trip',
        text: 'Командировки',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/weekend-orders',
        text: 'Реестр заявок на выходные дни',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/schedule-maintenance',
        text: 'График ТО',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
    ],
  },
  {
    isGroup: true,
    showDivider: true,
    label: 'Администрирование',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingEquipment,
      accessTypeEnum.handlingEmployees,
      accessTypeEnum.viewingAllEquipments,
      accessTypeEnum.viewingBranchEquipments,
      accessTypeEnum.handlingDriver,
      accessTypeEnum.viewingAllDrivers,
      accessTypeEnum.viewingSystemJournal,
      accessTypeEnum.handlingLocation,
      accessTypeEnum.viewingAllLocations,
      accessTypeEnum.viewingBranchLocations,
      accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
      accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
      accessTypeEnum.viewingWashingVehicleTypes,
      accessTypeEnum.handlingWashingVehicleTypes,
      accessTypeEnum.viewingOsagoMultipliers,
      accessTypeEnum.handlingOsagoMultipliers,
      accessTypeEnum.viewingJobTitles,
      accessTypeEnum.viewingAllJobTitles,
      accessTypeEnum.viewingMaintenanceWorkTypes,
      accessTypeEnum.handlingMaintenanceWorkTypes,
      accessTypeEnum.handlingCompareExpenseDirections,
      accessTypeEnum.viewingCompareExpenseDirections,
      accessTypeEnum.handlingExpenseDirections,
      accessTypeEnum.viewingExpenseDirections,
      accessTypeEnum.handlingVehicleIssueGroups,
      accessTypeEnum.viewingVehicleIssueGroups,
      accessTypeEnum.viewingStockParts,
      accessTypeEnum.viewingAllStockParts,
      accessTypeEnum.handlingStockParts,
      accessTypeEnum.viewingMaintenanceOperationGroups,
      accessTypeEnum.handlingMaintenanceOperationGroups,
      accessTypeEnum.viewingMaintenanceMatrix,
      accessTypeEnum.handlingMaintenanceMatrix,
      accessTypeEnum.viewingContracts,
      accessTypeEnum.viewingAllContracts,
      accessTypeEnum.viewingExternalActs,
      accessTypeEnum.viewingAllExternalActs,
      accessTypeEnum.viewingInternalActs,
      accessTypeEnum.viewingAllInternalActs,
      accessTypeEnum.viewingBudgetVersion,
      accessTypeEnum.viewingVehicleTariff,
      accessTypeEnum.viewingCompanies,
      accessTypeEnum.handlingCompanies,
      accessTypeEnum.viewingContractors,
      accessTypeEnum.handlingContractors,
      accessTypeEnum.viewingSideServiceTariff,
      accessTypeEnum.handlingSideServiceTariff,
      ...brandsAndVehicleModels,
      ...usersAndRoles,
      ...orgUnits,
      ...spareParts,
      ...crew,
      ...priceDirectory,
    ],
    items: [
      {
        link: '/admin/channel',
        text: 'Каналы БОС',
        access: [...adminAccess],
        cy: 'channel',
      },
      {
        link: '/admin/org-units',
        text: 'Подразделения',
        access: [...adminAccess, ...orgUnits],
        cy: 'orgUnits',
      },
      {
        link: '/admin/users',
        text: 'Пользователи',
        access: [...adminAccess, ...usersAndRoles],
        cy: 'users',
      },
      {
        link: '/admin/roles',
        text: 'Роли',
        access: [...adminAccess, ...usersAndRoles],
        cy: 'roles',
      },
      {
        link: '/admin/employees',
        text: 'Сотрудники',
        access: [...adminAccess, accessTypeEnum.handlingEmployees],
        cy: 'employees',
      },
      {
        link: '/admin/locations',
        text: 'Объекты',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingLocation,
          accessTypeEnum.viewingAllLocations,
          accessTypeEnum.viewingBranchLocations,
        ],
        cy: 'locations',
      },
      {
        link: '/admin/journals',
        text: 'Системный журнал',
        access: [...adminAccess, accessTypeEnum.viewingSystemJournal],
        cy: 'journals',
      },
      {
        link: '/admin/fuel-cards',
        text: 'Топливные карты',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingFuelCard,
          accessTypeEnum.viewingAllFuelCards,
          accessTypeEnum.viewingBranchFuelCards,
        ],
        cy: 'fuel-cards',
      },
      {
        isGroup: true,
        showDivider: false,
        showProduction: false,
        label: 'ПДД',
        access: [...adminAccess, accessTypeEnum.handlingPddTests],
        items: [
          {
            link: '/admin/pdd/test/register/',
            text: 'Тестирование ПДД',
            access: [...adminAccess, accessTypeEnum.handlingPddTests],
            cy: 'pdd-test-register',
          },
          {
            link: '/admin/pdd/questions/',
            text: 'Вопросы ПДД',
            access: [...adminAccess, accessTypeEnum.handlingPddQuestions],
            cy: 'pdd-question',
          },
          {
            link: '/admin/pdd/groups/',
            text: 'Темы ПДД',
            access: [...adminAccess, accessTypeEnum.handlingPddQuestions],
            cy: 'pdd-tickets',
          },
        ],
      },
      {
        isGroup: true,
        showDivider: false,
        label: 'Оборудование',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingEquipment,
          accessTypeEnum.viewingAllEquipments,
          accessTypeEnum.viewingBranchEquipments,
        ],
        items: [
          {
            link: '/equipment/attached',
            text: 'Навесное',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'attached',
          },
          {
            link: '/equipment/optional',
            text: 'Дополнительное',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'optional',
          },
          {
            link: '/equipment/tires',
            text: 'Шины',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'tires',
          },
          {
            link: '/equipment/batteries',
            text: 'АКБ',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'batteries',
          },
        ],
      },
      {
        isGroup: true,
        showDivider: false,
        label: 'Справочники',
        access: [
          accessTypeEnum.admin,
          accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
          accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
          accessTypeEnum.viewingWashingVehicleTypes,
          accessTypeEnum.handlingWashingVehicleTypes,
          accessTypeEnum.viewingOsagoMultipliers,
          accessTypeEnum.handlingOsagoMultipliers,
          accessTypeEnum.viewingJobTitles,
          accessTypeEnum.viewingAllJobTitles,
          accessTypeEnum.viewingMaintenanceWorkTypes,
          accessTypeEnum.handlingMaintenanceWorkTypes,
          accessTypeEnum.handlingCompareExpenseDirections,
          accessTypeEnum.viewingCompareExpenseDirections,
          accessTypeEnum.handlingExpenseDirections,
          accessTypeEnum.viewingExpenseDirections,
          accessTypeEnum.handlingVehicleIssueGroups,
          accessTypeEnum.viewingVehicleIssueGroups,
          accessTypeEnum.viewingStockParts,
          accessTypeEnum.viewingAllStockParts,
          accessTypeEnum.handlingStockParts,
          accessTypeEnum.viewingMaintenanceOperationGroups,
          accessTypeEnum.handlingMaintenanceOperationGroups,
          accessTypeEnum.newFeaturesDemo,
          accessTypeEnum.viewingContracts,
          accessTypeEnum.viewingAllContracts,
          accessTypeEnum.viewingExternalActs,
          accessTypeEnum.viewingAllExternalActs,
          accessTypeEnum.viewingInternalActs,
          accessTypeEnum.viewingAllInternalActs,
          accessTypeEnum.viewingBudgetVersion,
          accessTypeEnum.viewingVehicleTariff,
          accessTypeEnum.viewingCompanies,
          accessTypeEnum.handlingCompanies,
          accessTypeEnum.viewingContractors,
          accessTypeEnum.handlingContractors,
          ...brandsAndVehicleModels,
          ...spareParts,
          ...crew,
        ],
        items: [
          {
            link: '/admin/crew',
            text: 'Бригады',
            access: [...adminAccess, ...crew],
            cy: 'crew',
          },
          {
            link: '/admin/maintenance-work-type',
            text: 'Виды работ по обслуживанию ТС',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingMaintenanceWorkTypes,
              accessTypeEnum.handlingMaintenanceWorkTypes,
            ],
            cy: 'maintenance-type-work',
          },
          {
            link: '/admin/compare-expense-direction',
            text: 'Сопоставление расходов и видов работ',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingCompareExpenseDirections,
              accessTypeEnum.viewingCompareExpenseDirections,
            ],
            cy: 'compare-expense-direction',
          },
          {
            link: '/admin/expense-direction',
            text: 'Направление расходов',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingExpenseDirections,
              accessTypeEnum.viewingExpenseDirections,
              accessTypeEnum.handlingContracts,
            ],
            cy: 'expense-direction',
          },
          {
            link: '/admin/stock-part',
            text: 'Перечень материалов',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingStockParts,
              accessTypeEnum.viewingAllStockParts,
              accessTypeEnum.handlingStockParts,
            ],
            cy: 'stock-part',
          },
          {
            link: '/admin/osago-multiplier/general',
            text: 'Коэффициенты ОСАГО',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingOsagoMultipliers,
              accessTypeEnum.handlingOsagoMultipliers,
            ],
            cy: 'washing-price',
          },
          {
            link: '/admin/vehicle-issue-group',
            text: 'Группы дефектов',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingVehicleIssueGroups,
              accessTypeEnum.viewingVehicleIssueGroups,
            ],
            cy: 'vehicle-issue-group',
          },
          {
            link: '/admin/washing-vehicle-types',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingWashingVehicleTypes,
              accessTypeEnum.handlingWashingVehicleTypes,
            ],
            text: 'Типы ТС для моек',
          },
          {
            link: '/admin/washing-vehicle-type-map',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
              accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
            ],
            text: 'Сопоставление типов ТС для моек',
          },
          {
            link: '/admin/brands',
            text: 'Марки',
            access: [accessTypeEnum.admin, ...brandsAndVehicleModels],
            cy: 'brands',
          },
          {
            link: '/vehicle-models',
            text: 'Модели',
            access: [accessTypeEnum.admin, ...brandsAndVehicleModels],
            cy: 'vehicleModels',
          },
          {
            link: '/admin/fuels-and-oils',
            text: 'ГСМ',
            access: [accessTypeEnum.admin],
            cy: 'fuelsAndOils',
          },
          {
            link: '/admin/companies',
            text: 'Контрагенты',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingCompanies,
              accessTypeEnum.handlingCompanies,
              accessTypeEnum.viewingContractors,
              accessTypeEnum.handlingContractors,
            ],
            cy: 'companies',
          },
          {
            link: '/admin/contractors',
            text: 'Подрядчики',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingContractors,
              accessTypeEnum.handlingContractors,
            ],
            cy: 'contractors',
          },
          {
            link: '/admin/fuel-multipliers',
            text: 'Коэффициенты',
            access: [accessTypeEnum.admin],
            cy: 'fuelMultipliers',
          },
          {
            link: '/admin/maintenance-operation-groups',
            text: 'Перечень работ по ремонту ТС',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingMaintenanceOperationGroups,
              accessTypeEnum.handlingMaintenanceOperationGroups,
            ],
            cy: 'maintenanceOperationGroups',
          },
          {
            link: '/admin/mdm',
            text: 'МТРиО',
            access: [accessTypeEnum.admin, accessTypeEnum.handlingContracts],
            cy: 'mdm',
          },
          {
            link: '/admin/business-calendar',
            text: 'Производственный календарь',
            access: [accessTypeEnum.admin],
            cy: 'businessCalendar',
          },
          {
            link: '/admin/the-business-calendar-for-budget',
            text: 'Производственный календарь для бюджета',
            access: [accessTypeEnum.admin],
            cy: 'businessCalendarForBudget',
          },
          {
            link: '/admin/budget-version',
            text: 'Версии бюджета',
            access: [accessTypeEnum.admin, accessTypeEnum.viewingBudgetVersion],
            cy: 'budgetVersion',
          },
          {
            link: '/admin/okei',
            text: 'ОКЕИ',
            access: [accessTypeEnum.admin],
            cy: 'okei',
          },
          {
            link: '/admin/measuring-device-certification-cost/',
            text: 'Стоимость тарировки',
            access: [accessTypeEnum.admin],
            cy: 'measuring-device-certification-cost',
          },
          {
            link: '/admin/inspection-gibdd-cost/',
            text: 'Стоимость ТО ГИБДД',
            access: [accessTypeEnum.admin],
            cy: 'inspection-gibdd-cost',
          },
          {
            link: '/admin/spare-parts/',
            text: 'Запасные части',
            access: [accessTypeEnum.admin, ...spareParts],
            cy: 'spare-parts',
          },
          {
            isGroup: true,
            showDivider: false,
            label: 'Для бюджета СТС',
            access: [...adminAccess],
            items: [
              {
                link: '/admin/working-hour-contract-price/',
                text: 'Стоимость нормо-часа',
                access: [accessTypeEnum.admin],
                cy: 'working-hour-contract-price',
              },
              {
                link: '/admin/working-hour-self-price/',
                text: 'Стоимость нормо-часа хоз. способом',
                access: [accessTypeEnum.admin],
                cy: 'working-hour-self-price',
              },
              {
                link: '/admin/washing-frequency/',
                text: 'Периодичность моек',
                access: [accessTypeEnum.admin],
                cy: 'washing-frequency',
              },
              {
                link: '/admin/expertise-service-gpm-cost/',
                text: 'Стоимость ТО ГПМ',
                access: [accessTypeEnum.admin],
                cy: 'expertise-service-gpm-cost',
              },
              {
                link: '/admin/gps-subscription-service-cost/',
                text: 'Стоимость GPS',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                ],
                cy: 'gps-subscription-service-cost',
              },
              {
                link: '/admin/inspection-gtn-cost/',
                text: 'Стоимость ТО Гостехнадзор',
                access: [accessTypeEnum.admin],
                cy: 'inspection-gtn-cost',
              },
              {
                link: '/admin/license-plate-change-cost/',
                text: 'Стоимость замены гос.номеров',
                access: [accessTypeEnum.admin],
                cy: 'license-plate-change-cost',
              },
              {
                link: '/admin/trip-inspection-cost/',
                text: 'Стоимость осмотров',
                access: [accessTypeEnum.admin],
                cy: 'trip-inspection-cost',
              },
              {
                link: '/admin/trip-inspection-frequency/',
                text: 'Периодичность осмотров',
                access: [accessTypeEnum.admin],
                cy: 'trip-inspection-frequency',
              },
              {
                link: '/admin/overhaul-types/',
                text: 'Виды работ по капремонту',
                access: [accessTypeEnum.admin],
                cy: 'overhaul-types',
              },
              {
                link: '/admin/current-maintenance-cost/',
                text: 'Стоимость ремонта',
                access: [accessTypeEnum.admin],
                cy: 'current-maintenance-cost',
              },
              {
                link: '/admin/maintenance-cost-allocation-tr/',
                text: 'Затраты на ТР',
                access: [accessTypeEnum.admin],
                cy: 'maintenance-cost-allocation-tr',
              },
              {
                link: '/admin/maintenance-cost-allocation/',
                text: 'Затраты на ТО',
                access: [accessTypeEnum.admin],
                cy: 'maintenance-cost-allocation',
              },
              {
                link: '/admin/vehicle-tax-rate/',
                text: 'Налоговые коэффициенты',
                access: [accessTypeEnum.admin],
                cy: 'vehicle-tax-rate',
              },
              {
                link: '/admin/vehicle-tax-map/',
                text: 'Сопоставление налогов и типов ТС',
                access: [accessTypeEnum.admin],
                cy: 'vehicle-tax-map',
              },
              // {
              //   link: '/admin/budgets-for-analysis/',
              //   text: 'Бюджеты для анализа',
              //   access: [accessTypeEnum.admin],
              //   cy: 'budgets-for-analysis'
              // },
              {
                link: '/admin/pass-tariff/',
                text: 'Тарифы на пропуска',
                access: [accessTypeEnum.admin],
                cy: 'pass-tariff',
              },
              {
                link: '/admin/small-scale-mechanization/',
                text: 'Средства малой механизации',
                access: [accessTypeEnum.admin],
                cy: 'small-scale-mechanization',
              },
            ],
          },
        ],
      },
      {
        isGroup: true,
        showDivider: false,
        label: 'Прейскуранты',
        access: [
          ...adminAccess,
          ...priceDirectory,
          accessTypeEnum.viewingSideServiceTariff,
          accessTypeEnum.handlingSideServiceTariff,
        ],
        items: [
          {
            link: '/admin/sideServiceTariff',
            text: 'Тарифы для услуг на сторону',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingSideServiceTariff,
              accessTypeEnum.handlingSideServiceTariff,
            ],
            cy: 'sideServiceTariff',
          },
          {
            link: '/admin/fuel-price',
            text: 'ГСМ',
            access: [
              ...adminAccess,
              accessTypeEnum.newFeaturesDemo,
              ...priceDirectory,
            ],
            cy: 'fuel-price',
          },
          {
            link: '/admin/maintenance-operation-price',
            text: 'Стоимость нормо-часа',
            access: [
              ...adminAccess,
              accessTypeEnum.newFeaturesDemo,
              ...priceDirectory,
            ],
            cy: 'maintenance-operation-price',
          },
          {
            link: '/admin/washing-price-list',
            text: 'Мойки',
            access: [...adminAccess, ...priceDirectory],
            cy: 'washing-price',
          },
          {
            link: '/admin/monitoring-price',
            text: 'Мониторинг',
            access: [...adminAccess, ...priceDirectory],
            cy: 'monitoring-price',
          },
          {
            link: '/admin/federal-highway-fare/',
            text: 'Стоимость пропусков',
            access: [...adminAccess, ...priceDirectory],
            cy: 'federal-highway-fare',
          },
        ],
      },
    ],
  },
  {
    link: '/instructions',
    text: 'Инструкции',
    access: Object.keys(accessTypeEnum),
    cy: 'instructions',
  },
  {
    link: '/admin/setting',
    text: 'Настройки',
    access: [accessTypeEnum.admin],
    cy: 'setting',
  },
];

const getFlatMenu = (list: any, parentName: string) => {
  let res = {};
  list.forEach((el) => {
    if (el.isGroup) {
      res[el.label] = {
        name: el.label,
        parent: parentName,
      };
      res = { ...res, ...getFlatMenu(el.items, el.label) };
    } else {
      res[el.link] = {
        name: el.text,
        to: el.link,
        parent: parentName,
      };
    }
  });
  return res;
};

export const flatMenu = {
  '/': { name: 'Главная', to: '/' },
  ...getFlatMenu(mainLinks, '/'),
};

const renderMenu = (userAccess, links, parentKey) => {
  return (
    links
      // TODO: Убрать это, когда допилим обслуживание
      .filter((v) => !!v)
      .map((item: any, index) => {
        return userAccess.some((access) => item.access.includes(access)) &&
          !(item.showProduction === false && isDevelopment !== true) ? (
          item.isGroup ? (
            <Fragment key={`menu-group-${index}`}>
              {item.showDivider && <StyledDivider color="light" />}
              <Menu label={item.label}>
                {renderMenu(userAccess, item.items, `menu-group-${index}`)}
              </Menu>
            </Fragment>
          ) : parentKey ? (
            <MenuItem
              data-cy={item.cy}
              to={item.link}
              key={`${parentKey}/link-${index}`}
            >
              {item.text}
            </MenuItem>
          ) : item?.type === 'blank-link' ? (
            <StyledA href={item.link} target={'_blank'}>
              {item.text}
            </StyledA>
          ) : (
            <StyledLink data-cy={item.cy} to={item.link} key={`link-${index}`}>
              {item.text}
            </StyledLink>
          )
        ) : null;
      })
  );
};

/**
 * Компонент боковой левой колонки сайта
 */
const Sidebar = ({ expanded, profile }: Props) => {
  const { access: userAccess } = profile;
  // Для продакшена нам не нужно показывать текущую роль
  const showRoleName = process.env.NODE_ENV !== 'production';
  return (
    <Wrapper expanded={expanded}>
      <Content>{renderMenu(userAccess, mainLinks)}</Content>
      <ProfileWrapper style={{ display: expanded ? 'block' : 'none' }}>
        {showRoleName ? (
          <>
            <ProfileEmployee>
              {convertEmployeeToString(profile.employee)}
            </ProfileEmployee>
            <ProfileBottomBlock>
              <ProfileRole>{profile.roleName}</ProfileRole>
              <a href="/logout" title="Выход">
                <LogoutIcon />
              </a>
            </ProfileBottomBlock>
          </>
        ) : (
          <ProfileBottomBlock>
            <ProfileEmployee marginBottom={0}>
              {convertEmployeeToString(profile.employee)}
            </ProfileEmployee>
            <a href="/logout" title="Выход">
              <LogoutIcon />
            </a>
          </ProfileBottomBlock>
        )}
      </ProfileWrapper>
    </Wrapper>
  );
};

export default Sidebar;
