// @flow

import type { FuelMeasurement } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addFuelMeasurement = async (
  measurement: FuelMeasurement
): Promise<FuelMeasurement> => {
  const added = await fetchRequest.post('/fuelMeasurement', measurement);
  if (added) return added;
  throw new Error('Не удалось создать замер толпива');
};

export const fetchFuelMeasurements = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<FuelMeasurement>> =>
  await fetchRequest.get('/FuelMeasurement', {
    ...initialFetchListParams,
    ...params
  });

export const fetchFuelMeasurement = async (
  id: number
): Promise<FuelMeasurement> => {
  const measurement = await fetchRequest.get(`/fuelMeasurement/${id}`);
  if (measurement) return measurement;
  throw new Error('Не удалось загрузить коэффициент');
};

export const fetchVehicleFuelMeasurements = async (
  vehicleId: number
): Promise<FuelMeasurement[]> => {
  const result = await fetchRequest.get('/fuelMeasurement', { vehicleId });
  if (result) {
    return result.data || [];
  } else {
    throw new Error('Не удалось получить топливные коэффициенты');
  }
};

export default {
  addFuelMeasurement,
  fetchFuelMeasurements,
  fetchFuelMeasurement,
  fetchVehicleFuelMeasurements
};
