// @flow
import { fetchRequest } from './index';
import type { PassCalculation, PassCalculationMonth } from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataToServer = (
  passCalculation: PassCalculation
): PassCalculation => ({
  ...passCalculation,
  sum: convertFromRubToKop(passCalculation.sum),
  months: passCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  passCalculation: PassCalculation
): PassCalculation => ({
  ...passCalculation,
  sum: convertFromKopToRub(passCalculation.sum),
  months: passCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  passCalculationMonth: PassCalculationMonth
): PassCalculationMonth => ({
  ...passCalculationMonth,
  excessRate: convertFromRubToKop(passCalculationMonth.excessRate),
  cost: convertFromRubToKop(passCalculationMonth.cost),
  rate: convertFromRubToKop(passCalculationMonth.rate)
});

export const convertDataFromServerMonth = (
  passCalculationMonth: PassCalculationMonth
): PassCalculationMonth => ({
  ...passCalculationMonth,
  excessRate: convertFromKopToRub(passCalculationMonth.excessRate),
  cost: convertFromKopToRub(passCalculationMonth.cost),
  rate: convertFromKopToRub(passCalculationMonth.rate)
});

const basedUrl = 'passCalculation';
const passCalculation = new CommonApi<PassCalculation>(
  basedUrl,
  'расчет потребности в пропусках',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  data: PassCalculation
): Promise<PassCalculation> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations`,
    data
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

export default {
  ...passCalculation,
  updateCalculations
};
