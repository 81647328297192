// @flow
import moment from 'moment';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React from 'react';

import { Header, Section } from '../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import Table from '../../components/ui/Table';

import {
  formatDateTimeToString,
  getListInitialState,
} from './../../lib/helpers';
import { bioTreatmentFacilityContractApi } from '../../lib/api';
import { accessTypeEnum } from '../../lib/enum';
import type {
  BioTreatmentFacilityContractReport,
  ListState,
  UserAccess,
} from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

import type { BioTreatmentFacilitiesReportFilterParams } from './components/FilterReport';
import Filter from './components/FilterReport';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<BioTreatmentFacilityContractReport> & {
  filter: BioTreatmentFacilitiesReportFilterParams,
  columns: Array<Object>,
};

export class BioTreatmentFacilitiesReport extends React.Component<
  Props,
  State
> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: [
      {
        title: 'Контрагент',
        key: 'companyName',
        dataIndex: 'company.name',
      },
      {
        title: 'Адрес',
        key: 'address',
        dataIndex: 'company.address',
      },
      {
        title: 'Телефон',
        key: 'phone',
        dataIndex: 'company.phone',
      },
      {
        title: 'Кол-во талонов',
        key: 'talonCount',
        dataIndex: 'talonCount',
      },
    ],
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetch(page)
    );
  }

  fetch = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await bioTreatmentFacilityContractApi.report({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: BioTreatmentFacilitiesReportFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter, columns } =
      this.state;
    return (
      <>
        <Header left={<h1>Отчет по оставшимся талонам БОС</h1>} />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={columns}
            data={data}
            fetch={this.fetch}
            fetchOnMount
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(BioTreatmentFacilitiesReport);
