// @flow
import styled from 'styled-components';

export const EquipmentActions = styled.div`
  & > * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const List = styled.ul`
  padding-left: 16px;
  list-style: disc;
`;
