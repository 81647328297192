// @flow
import React, { useState } from 'react';
import Button from 'antd/lib/button';

import type {
  AssignmentLimitGroup,
  AssignmentLimit
} from './../../../../../../../lib/types';
import { Row, Spoiler } from './../../../../../../../components/ui';

import AssignmentLimitRow from './AssignmentLimitRow';

type Props = {
  // Редактируемая ли группа
  editable: boolean,
  // Группа лимитов
  group: AssignmentLimitGroup,
  // Индекс текущей группы
  index: number,
  // Функция для изменения значения группы по ключу в массиве
  onChange: (
    index: number,
    name: $Keys<AssignmentLimitGroup>,
    value: any
  ) => void,
  // Функция для сохранения группы на бэке
  onSave: (group: AssignmentLimitGroup, index: number) => Promise<void>
};

/**
 * Компонент строки для групп лимитов в закреплении
 */
export default ({ group, index, onChange, onSave, editable }: Props) => {
  const [hasChanges, setHasChanges] = useState(false);

  const handleChangeLimit = (
    limitIndex: number,
    name: $Keys<AssignmentLimit>,
    value: any
  ) => {
    const { assignmentLimit } = group;
    const copy = [...assignmentLimit];
    copy[limitIndex][name] = value;

    onChange(index, 'assignmentLimit', copy);
    setHasChanges(true);
  };

  const handleSave = async () => {
    await onSave(group, index);
    setHasChanges(false);
  };
  return (
    <>
      <Spoiler
        label={group.regulationLimitGroup.name}
        labelStyles={{
          background: '#F0F4F8',
          color: '#838D96',
          height: '45px'
        }}
        defaultExpanded
        iconPlacement="left"
      >
        {group.assignmentLimit.map((limit, index) => (
          <AssignmentLimitRow
            editable={editable}
            onChange={handleChangeLimit}
            limit={limit}
            index={index}
            key={index}
          />
        ))}
        {editable && hasChanges && (
          <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleSave} size="small" type="primary">
              Сохранить изменения
            </Button>
          </Row>
        )}
      </Spoiler>
    </>
  );
};
