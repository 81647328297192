// @flow

import React, { Component, Fragment } from 'react';
import styled, { css } from 'styled-components';
import clone from 'lodash/clone';

import type {
  RepairOrderVehicle,
  RepairOrderOperation
} from './../../../lib/types';
import RepairOrderVehicleEditRow from './RepairOrderVehicleEditRow';
import RepairOrderVehicleAddRow from './RepairOrderVehicleAddRow';
import { Icon as UiIcon } from '../../../components/ui';

type Props = {
  vehicles: RepairOrderVehicle[],
  errors: RepairOrderVehicle[],
  onChange: Function
};

/**
 * Компонент отображает список прикрепленных ТС для мойки
 */
class WashingOrderVehiclesForm extends Component<Props> {
  static defaultProps = {
    errors: [],
    vehicles: []
  };

  changeVehicle = (vehicle: RepairOrderVehicle) => {
    const vehicles: RepairOrderVehicle[] = [
      ...this.props.vehicles.filter(
        (vhcl: RepairOrderVehicle) => vhcl.id !== vehicle.id
      ),
      vehicle
    ];
    this.props.onChange(vehicles);
  };

  removeVehicle = (vehicle: RepairOrderVehicle) =>
    this.props.onChange(
      this.props.vehicles.filter(
        (vhcl: RepairOrderVehicle) => vhcl.id !== vehicle.id
      )
    );

  deleteOperation = (
    vehicleId: number,
    deleteOperation: RepairOrderOperation
  ) => {
    const vehicles = this.props.vehicles.map(
      (repairOrderVehicle: RepairOrderVehicle) => {
        if (repairOrderVehicle.vehicleId === vehicleId) {
          const vehicle = clone(repairOrderVehicle, true);
          vehicle.operations = vehicle.operations.filter(
            (repairOrderOperation: RepairOrderOperation) =>
              repairOrderOperation.operationId !== deleteOperation.operationId
          );
          return vehicle;
        }
        return repairOrderVehicle;
      }
    );

    this.props.onChange(vehicles);
  };

  changeVehicleOperationCount = (
    vehicleId: number,
    updatedRepairOrderOperation: RepairOrderOperation
  ) => {
    const vehicles = this.props.vehicles.map(
      (repairOrderVehicle: RepairOrderVehicle) => {
        if (repairOrderVehicle.vehicleId === vehicleId) {
          const vehicle = clone(repairOrderVehicle, true);
          vehicle.operations = vehicle.operations.map(
            (repairOrderOperation: RepairOrderOperation) => {
              if (
                repairOrderOperation.operationId ===
                updatedRepairOrderOperation.operationId
              ) {
                return updatedRepairOrderOperation;
              }
              return repairOrderOperation;
            }
          );
        }
        return repairOrderVehicle;
      }
    );

    this.props.onChange(vehicles);
  };

  addVehicle = (vehicle: RepairOrderVehicle) => {
    const existingVehicle = this.props.vehicles.find(
      (veh: RepairOrderVehicle) => veh.id === vehicle.id
    );
    if (existingVehicle) {
      let updatedVehicle = { ...existingVehicle };
      vehicle.operations = vehicle.operations.map(
        (operation: RepairOrderOperation) => {
          const existingOperation = updatedVehicle.operations.find(
            (vehicleOperation: RepairOrderOperation) =>
              vehicleOperation.operation.id === operation.operation.id
          );
          if (existingOperation) {
            return {
              ...existingOperation,
              count: existingOperation.count + 1
            };
          }
          return {
            ...operation,
            count: 1
          };
        }
      );
      this.props.onChange([...this.props.vehicles, updatedVehicle]);
    } else {
      this.props.onChange([
        ...this.props.vehicles,
        {
          ...vehicle,
          operations: vehicle.operations.map(
            (operation: RepairOrderOperation) => ({
              ...operation,
              count: 1
            })
          )
        }
      ]);
    }
  };

  render() {
    const { vehicles } = this.props;
    return (
      <Fragment>
        <Table>
          {vehicles.length > 0 && (
            <Header>
              <HeaderItem flex={1}>Виды работ</HeaderItem>
              <HeaderItem flex={1}>Количество</HeaderItem>
              <HeaderItem width={27} />
            </Header>
          )}
          <Body>
            {vehicles.map(
              (repairOrderVehicle: RepairOrderVehicle, index: number) => (
                <Fragment key={repairOrderVehicle.vehicleId}>
                  <RowHeader>
                    <HeaderItem flex={1}>
                      {repairOrderVehicle.vehicle && (
                        <Fragment>
                          {repairOrderVehicle.vehicle.vehicleModel && (
                            <span>
                              {`${
                                repairOrderVehicle.vehicle.vehicleModel
                                  .brandName
                              } `}
                              {`${
                                repairOrderVehicle.vehicle.vehicleModel.name
                              } `}
                            </span>
                          )}
                          <span>{repairOrderVehicle.vehicle.licensePlate}</span>
                        </Fragment>
                      )}
                    </HeaderItem>
                    <HeaderItem width={27}>
                      <StyledIcon
                        type="x"
                        onClick={() => this.removeVehicle(repairOrderVehicle)}
                      />
                    </HeaderItem>
                  </RowHeader>
                  {repairOrderVehicle.operations.map(
                    (repairOperation: RepairOrderOperation) => (
                      <Row key={repairOperation.operation.id} bordered>
                        <RepairOrderVehicleEditRow
                          repairOrderOperation={repairOperation}
                          onDelete={(
                            repairOrderOperation: RepairOrderOperation
                          ) =>
                            this.deleteOperation(
                              repairOrderVehicle.vehicleId,
                              repairOrderOperation
                            )
                          }
                          onChange={(
                            repairOrderOperation: RepairOrderOperation
                          ) =>
                            this.changeVehicleOperationCount(
                              repairOrderVehicle.vehicleId,
                              repairOrderOperation
                            )
                          }
                        />
                      </Row>
                    )
                  )}
                </Fragment>
              )
            )}
            <Header>
              <HeaderItem flex={1}>ТС</HeaderItem>
              <HeaderItem flex={1}>Виды работ</HeaderItem>
              <HeaderItem width={27} />
            </Header>
            <Row className="waypoint-item">
              <RepairOrderVehicleAddRow onAdd={this.addVehicle} />
            </Row>
          </Body>
        </Table>
      </Fragment>
    );
  }
}

const Table = styled.div`
  width: 100%;
`;

const Header = styled.div`
  background: #c0ccd7;
  width: 100%;
  display: flex;
  font-size: 14px;
  padding: 11px 16px;
`;

const Body = styled.div`
  border: 1px solid #c0ccd7;

  > .waypoint-item:last-child {
    border-bottom: 0;
  }
`;

const HeaderItem = styled.div`
  ${props => css`
    ${props.width ? `width: ${props.width}px;` : `flex: ${props.flex || 1};`};
  `};
`;

export const Row = styled.div`
  padding: 16px;
  ${props => props.bordered && 'border-bottom: 1px solid #e4ebf2;'};
`;

const RowHeader = styled(Header)`
  width: 100%;
  background: #f0f4f8;
  color: #838d96;
  padding: 11px 15px;
`;

const StyledIcon = styled(UiIcon)`
  margin-top: 8px;
  cursor: pointer;
`;

export default WashingOrderVehiclesForm;
