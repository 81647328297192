// @flow
import Button from 'antd/lib/button/button';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { RangePicker } from '../../../../components/ant/DatePicker';
import Field from '../../../../components/card/Field';
import { StyledInput } from '../../../../components/hoc/common/components/elements';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import Selects from '../../../../components/selects';

import Spoiler from '../../../../components/ui/Spoiler';
import { ownerTypes } from '../../../../lib/enum';
import { formatDateTimeToISOString } from '../../../../lib/helpers';
import type { ReportBusinessTripParams } from '../../../../lib/types/reportBusinessTrips';

const {
  VehicleModelSelect,
  EmployeeSelect,
  VehicleSelect,
  VehicleOwnerTypeSelect,
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  onChangeFilter: (key: $Keys<any>, value: any) => void,
  params: ReportBusinessTripParams,
  cleanParams: () => void,
  applyParams: () => Promise<any>,
  fetchData: () => Promise<any>,
  onExport: Function
};

/**
 * Фильтр с параметрами
 */
export default class Filter extends React.Component<Props> {
  render() {
    const { params, onExport } = this.props;
    return (
      <Spoiler defaultExpanded label="Параметры">
        <SpoilerContent>
          <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
            <GridItem>
              <Field label="Отчетный период">
                <RangePicker
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    params.startDate ? moment.utc(params.startDate) : null,
                    params.endDate ? moment.utc(params.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    this.props.onChangeFilter(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    this.props.onChangeFilter(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="ТС">
                <VehicleSelect
                  filter={{
                    ownerType: ownerTypes.self
                  }}
                  value={params.vehicleId}
                  onChange={id => this.props.onChangeFilter('vehicleId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип ТС">
                <VehicleOwnerTypeSelect
                  value={params.ownerType}
                  placeholder="Собственные и наемные ТС"
                  onChange={type =>
                    this.props.onChangeFilter('ownerType', type)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Модель ТС">
                <VehicleModelSelect
                  value={params.vehicleModelId}
                  onChange={id =>
                    this.props.onChangeFilter('vehicleModelId', id)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Сотрудник">
                <EmployeeSelect
                  value={params.employeeId}
                  onChange={(id, option) => {
                    this.props.onChangeFilter('employeeId', id);
                    this.props.onChangeFilter(
                      'firstName',
                      option.props?.employee.firstname
                    );
                    this.props.onChangeFilter(
                      'lastName',
                      option.props?.employee.lastname
                    );
                    this.props.onChangeFilter(
                      'middleName',
                      option.props?.employee.middlename
                    );
                    this.props.onChangeFilter(
                      'personNumber',
                      option.props?.employee.personNumber
                    );
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Номер заявки на командировку">
                <StyledInput
                  value={params.businessTripOrderNumber}
                  onChange={e =>
                    this.props.onChangeFilter(
                      'businessTripOrderNumber',
                      e.target.value
                    )
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Мнемокод филиала">
                <StyledInput
                  value={params.mnemocode}
                  onChange={e =>
                    this.props.onChangeFilter('mnemocode', e.target.value)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Табельный номер сторудника">
                <StyledInput
                  value={params.personNumber}
                  onChange={e =>
                    this.props.onChangeFilter('personNumber', e.target.value)
                  }
                />
              </Field>
            </GridItem>
            <Footer fullWidth>
              <Button type="primary" onClick={onExport}>
                Экспорт
              </Button>
              <Button type="primary" onClick={this.props.applyParams}>
                Применить
              </Button>
              <Button type="secondary" onClick={this.props.cleanParams}>
                Очистить
              </Button>
              <Button type="primary" onClick={this.props.fetchData}>
                Запросить данные
              </Button>
            </Footer>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}
