// @flow
import React from 'react';
import notification from 'antd/lib/notification';

import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportGpmOrder,
  ReportGpmOrderParams,
} from '../../../lib/types/reportGpmOrder';
import { reportGpmOrderApi } from '../../../lib/api';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { notificationLoading } from '../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

import Filter from './components/Filter';

const initialParams: ReportGpmOrderParams = {};

type State = {
  params: ReportGpmOrderParams,
  loading: boolean,
  data: ReportGpmOrder[],
  scrollY: number,
};

/**
 * Отчет об учете заявок ГПМ
 */
export class GpmOrderList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    loading: false,
    data: [],
    scrollY: window.innerHeight - 450,
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  async componentDidMount() {
    await this.fetchReportGpmOrders();
  }

  fetchReportGpmOrders = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportGpmOrderApi.fetchReportGpmOrder(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyParams = this.fetchReportGpmOrders;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print',
      });
      await reportGpmOrderApi.printReportGpmOrder(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      width: 100,
    },
    {
      title: 'Номер записи',
      dataIndex: 'idNumber',
      width: 100,
    },
    {
      title: 'Тип ТС',
      dataIndex: 'vehicleType',
      width: 100,
      render: (type: string, record: ReportGpmOrder) =>
        type || record.vehicleGroup,
    },
    {
      title: 'Госномер',
      dataIndex: 'licensePlate',
      width: 150,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Номер заявки',
      dataIndex: 'orderId',
      width: 100,
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
      width: 100,
    },
    {
      title: 'Объект, адрес',
      dataIndex: 'object',
      width: 200,
    },
    {
      title: 'Заявитель',
      dataIndex: 'claimant',
      width: 150,
    },
    {
      title: 'Примечание',
      dataIndex: 'notation',
      width: 150,
      render: (value: string) => value || '-',
    },
  ];

  render() {
    const { data, loading, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Отчет об учёте ГПМ</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={data}
            columns={this.columns}
            name="ReportsGpmOrder"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1150,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default GpmOrderList;
