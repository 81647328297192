//@flow
import { Button, Result } from 'antd';
import React from 'react';
import { navigate } from '../lib/helpers';

const AccessDenied = () => (
  <Result
    status="403"
    title="Доступ к странице запрещен"
    extra={
      <Button type="primary" onClick={() => navigate('/')}>
        Перейти на главную страницу
      </Button>
    }
  />
);

export default AccessDenied;
