// @flow

import React from 'react';
import Tree from 'antd/lib/tree';

import type { OrgUnitNode } from './../lib/types/index';

const { TreeNode } = Tree;

type Props = {
  tree: OrgUnitNode,
  onSelect: Function,
  defaultExpandAll: boolean,
  defaultSelectedKeys: ?Array<string>
};

const renderNode = (node: OrgUnitNode) => {
  if (node.children && node.children.length) {
    return (
      <TreeNode key={node.id} title={node.name}>
        {node.children.map(child => renderNode(child))}
      </TreeNode>
    );
  }
  return <TreeNode key={node.id} title={node.name} isLeaf />;
};

const OrgUnitTree = ({ tree, onSelect, defaultSelectedKeys }: Props) => (
  <Tree
    defaultExpandAll
    onSelect={onSelect}
    defaultSelectedKeys={defaultSelectedKeys}
  >
    {renderNode(tree)}
  </Tree>
);
export default OrgUnitTree;
