// @flow
import type { TripInspectionFrequency } from './../types';
import CommonApi from './../commonApi';

const tripInspectionFrequency = new CommonApi<TripInspectionFrequency>(
  'tripInspectionFrequency',
  'Периодичность проведения предрейсовых/послерейсовых осмотров'
);

export default {
  ...tripInspectionFrequency
};
