// @flow
import React, { useState, Fragment } from 'react';
import { Button, Divider, Input, Menu, Popconfirm } from 'antd';
import styled from 'styled-components';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import type { PddTicket } from '../../../../lib/types';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';
import { Selects } from '../../../../components';
import { SectionContent, SectionTitle } from '../../../../components/layout';
import Section from '../../../../components/layout/Section';
import { PhotoModal } from './Modal';
import { Dropdown, Icon } from '../../../../components/ui';
import Table from '../../../../components/ui/Table';
import { AnswerModal } from './AnswerModal';
import { UploadFile } from 'antd/lib/upload/interface';
import { fileApi } from '../../../../lib/api';
import type { PddAnswer } from '../../../../lib/types/pddTicket';

const { PddGroupSelect } = Selects;
const { TextArea } = Input;

const StyledDivider = styled(Divider)`
  margin: 0 0 20px 0;
`;

const AnswersTableSection = ({ values, setFieldValue }) => {
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [answer, setAnswer] = useState(null);

  const deleteAnswer = (answer: PddAnswer) => {
    let newAnswers = values.answers || [];
    newAnswers = newAnswers.filter(el => {
      return answer !== el;
    });
    setFieldValue('answers', newAnswers);
    setShowAnswerModal(false);
  };

  return (
    <Section>
      <SectionTitle
        divider
        suffix={
          <Button
            onClick={() => {
              setAnswer({
                description: '',
                isRight: false
              });
              setShowAnswerModal(true);
            }}
          >
            Добавить ответ
          </Button>
        }
      >
        Варианты ответов
      </SectionTitle>
      <SectionContent>
        <Table
          columns={[
            {
              title: 'Описание ответа',
              dataIndex: 'description',
              width: '90%',
              render: description => (
                <div style={{ whiteSpace: 'pre-line' }}>{description}</div>
              )
            },
            {
              title: 'Правильный ответ',
              dataIndex: 'isRight',
              render: (isRight: boolean) => (isRight ? 'Да' : 'Нет')
            },
            {
              title: '',
              dataIndex: 'id',
              stopPropagation: true,
              width: 50,
              render: (id: number, record: PddAnswer) => (
                <Popconfirm
                  title="Вы уверены, что хотите удалить ответ?"
                  onConfirm={async () => deleteAnswer(record)}
                >
                  <Icon type="x" />
                </Popconfirm>
              )
            }
          ]}
          data={values.answers}
          onRow={(record: PddAnswer) => {
            return {
              onClick: () => {
                setAnswer(record);
                setShowAnswerModal(true);
              }
            };
          }}
        />
        <AnswerModal
          canBeRight={
            !values.answers
              ? true
              : values.answers?.every(item => !item.isRight)
          }
          visible={showAnswerModal}
          onClose={() => setShowAnswerModal(false)}
          onSave={(oldAnswer: PddAnswer, answer: PddAnswer) => {
            let newAnswers = values.answers || [];
            if (oldAnswer.description) {
              newAnswers = newAnswers.map(el => {
                if (el === oldAnswer) {
                  return answer;
                }
                return el;
              });
            } else {
              newAnswers.push(answer);
            }
            setFieldValue('answers', newAnswers);
            setShowAnswerModal(false);
          }}
          onDelete={deleteAnswer}
          answer={answer}
        />
      </SectionContent>
    </Section>
  );
};

export default (props: CommonFormProps<$Shape<PddTicket>>) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Fragment noWrapMe>
              <Section>
                <SectionTitle divider>Описание вопроса</SectionTitle>
                <SectionContent>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField label="Тема ПДД" fast required name="groupId">
                        {({ name, value }) => (
                          <PddGroupSelect
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>

                  <StyledDivider />
                  <FormField label="Вопрос" fast required name="question">
                    {({ name, value }) => (
                      <TextArea
                        placeholder="Введите описание вопроса"
                        autoSize
                        value={value}
                        onChange={e => setFieldValue(name, e.target.value)}
                      />
                    )}
                  </FormField>
                  <StyledDivider />
                  <FormField
                    label="Пояснение к ответу"
                    fast
                    required
                    name="answerNote"
                  >
                    {({ name, value }) => (
                      <TextArea
                        placeholder="Введите пояснение к ответу"
                        autoSize
                        value={value}
                        onChange={e => setFieldValue(name, e.target.value)}
                      />
                    )}
                  </FormField>
                  <StyledDivider />
                  <SectionTitle
                    suffix={
                      values.photoId && (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                onClick={() => {
                                  setShowModal(true);
                                }}
                              >
                                Загрузить {values.photoId ? 'другое ' : ''}фото
                              </Menu.Item>
                              <Menu.Item>
                                <Popconfirm
                                  title="Вы действительно хотите удалить?"
                                  okText="Да"
                                  cancelText="Нет"
                                  onConfirm={async () => {
                                    await fileApi.deleteFile(values.photoId);
                                    setFieldValue('photo', null);
                                    setFieldValue('photoId', null);
                                  }}
                                >
                                  Удалить фото
                                </Popconfirm>
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <Icon
                            style={{ fontSize: 16, color: '#2770FF' }}
                            type="ellipsis"
                          />
                        </Dropdown>
                      )
                    }
                  >
                    {values.photoId ? (
                      'Фото'
                    ) : (
                      <Button
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        +Добавить фото
                      </Button>
                    )}
                  </SectionTitle>
                  {values.photo && (
                    <img
                      src={values.photo.url}
                      alt="Фото"
                      style={{
                        height: '100%',
                        width: '100%'
                      }}
                    />
                  )}
                  <PhotoModal
                    visible={showModal}
                    onClose={() => setShowModal(false)}
                    onUploaded={(file: UploadFile, localImageUrl: string) => {
                      setFieldValue('photo', {
                        ...file,
                        url: localImageUrl
                      });
                      setFieldValue('photoId', file?.id);
                    }}
                    photoId={values?.photoId}
                  />
                </SectionContent>
              </Section>

              <AnswersTableSection
                values={values}
                setFieldValue={setFieldValue}
              />
            </Fragment>
          </>
        );
      }}
    </CommonForm>
  );
};
