// @flow
import CommonApi from '../commonApi';
import type { PddQuestion, PddTest } from '../types';
import { fetchRequest } from './index';

const pddTest = new CommonApi<PddTest>('pddTest', 'Тесты ПДД');

export const generateExam = async (): Promise<PddQuestion[]> => {
  const data = await fetchRequest.get(`/pddTest/generateExam`);
  if (data) return data;

  throw new Error('Не удалось получить вопросы для экзамена!');
};

export default {
  ...pddTest,
  generateExam
};
