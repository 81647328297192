// @flow
import type { OilsAndFluidsConsumptionPlanVehicle } from './../types';
import CommonApi from '../commonApi';
import { fetchRequest } from './';

const basedUrl = '/oilsAndFluidsConsumptionPlanVehicle';
const oilsAndFluidsConsumptionPlanVehicle = new CommonApi<OilsAndFluidsConsumptionPlanVehicle>(
  basedUrl,
  'план расхода для ТС'
);

export default {
  ...oilsAndFluidsConsumptionPlanVehicle,
  vehiclePlan: async (
    vehicleId: number,
    period: string
  ): Promise<OilsAndFluidsConsumptionPlanVehicle> => {
    const result: OilsAndFluidsConsumptionPlanVehicle = await fetchRequest.get(
      `${basedUrl}/${vehicleId}/${period}`
    );
    if (result) return result;
    throw new Error('Не удалось загрузить план расхода для ТС');
  }
};
