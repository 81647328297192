// @flow
import omit from 'lodash/omit';

import type { ContractVehicle, ContractVehicleAnalysis } from './../types';

import { fetchRequest, initialFetchListParams, vehicleApi } from './index';
import type { FetchListParams, ListResponse } from './index';
import {
  convertFromKmToM,
  convertFromMToKm,
  convertFromHourToSecond,
  convertFromSecondToHour
} from '../helpers';
import type { ContractVehicleFilterParams } from '../../containers/ContractVehicles/Filter';
import { convertDataFromServer as convertVehicleFromServer } from './vehicle';
import type { FreeContractVehicle } from '../types/contractVehicle';

export const convertDataFromServer = (contractVehicle: ContractVehicle) => ({
  ...contractVehicle,
  kilometrageLimitWorkWeek: convertFromMToKm(
    contractVehicle.kilometrageLimitWorkWeek
  ),
  kilometrageLimitWeekend: convertFromMToKm(
    contractVehicle.kilometrageLimitWeekend
  ),
  workWeekHours: convertFromSecondToHour(contractVehicle.workWeekHours),
  workWeekendHours: convertFromSecondToHour(contractVehicle.workWeekendHours),
  vehicle: convertVehicleFromServer(contractVehicle.vehicle)
});

export const convertDataToServer = (contractVehicle: ContractVehicle) => ({
  ...contractVehicle,
  kilometrageLimitWorkWeek: convertFromKmToM(
    contractVehicle.kilometrageLimitWorkWeek
  ),
  kilometrageLimitWeekend: convertFromKmToM(
    contractVehicle.kilometrageLimitWeekend
  ),
  workWeekHours: convertFromHourToSecond(contractVehicle.workWeekHours),
  workWeekendHours: convertFromHourToSecond(contractVehicle.workWeekendHours)
});

export const addContractVehicle = async (
  contractVehicle: ContractVehicle
): Promise<ContractVehicle> => {
  const addedVehicle = await vehicleApi.addVehicle(contractVehicle.vehicle);
  contractVehicle.vehicleId = addedVehicle.id;
  const added = await fetchRequest.post(
    '/contractVehicle',
    omit(convertDataToServer(contractVehicle), 'vehicle')
  );
  if (added) return added;
  throw new Error('Не удалось создать наемное ТС');
};

export const updateContractVehicle = async (
  contractVehicle: ContractVehicle
): Promise<ContractVehicle> => {
  let updated = await fetchRequest.put(
    '/contractVehicle',
    convertDataToServer(contractVehicle)
  );

  if (updated) {
    updated = convertDataFromServer(updated);
    updated.vehicle = await vehicleApi.updateVehicle(contractVehicle.vehicle);
    return updated;
  }

  throw new Error('Не удалось обновить наемное ТС');
};

export const fetchContractVehicles = async (
  params: FetchListParams<ContractVehicleFilterParams> = initialFetchListParams
): Promise<ListResponse<ContractVehicle>> => {
  const vehicles = await fetchRequest.get('/contractVehicle', {
    ...initialFetchListParams,
    ...params
  });
  return {
    ...vehicles,
    data: vehicles.data.map(convertDataFromServer)
  };
};

export const fetchContractVehicleAnalysis = async (
  filter: any
): Promise<ContractVehicleAnalysis[]> => {
  const analysis = await fetchRequest.get('/contractVehicle/analysis', filter);
  if (analysis) return analysis;
  throw new Error('Не удалось получить данные анализа по наемным ТС');
};

export const fetchContractVehicle = async (
  id: number,
  params: any = {}
): Promise<ContractVehicle> => {
  let contractVehicle: ContractVehicle = await fetchRequest.get(
    `/contractVehicle/${id}`,
    params
  );
  if (contractVehicle) {
    contractVehicle = convertDataFromServer(contractVehicle);
    contractVehicle.vehicle = await vehicleApi.fetchVehicle(
      contractVehicle.vehicleId
    );
    return contractVehicle;
  }
  throw new Error('Не удалось загрузить наемное ТС');
};

export const deleteContractVehicle = async (
  id: number
): Promise<ContractVehicle> => {
  const deleted = await fetchRequest.delete(`/contractVehicle/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить наемное ТС');
};

export const reserveByEmployee = async (data: Object): Promise<void> =>
  await fetchRequest.post('/contractVehicle/reserve-by-employee', data);

export const reserveByOvb = async (data: Object): Promise<void> =>
  await fetchRequest.post('/contractVehicle/reserve-by-ovb', data);

export const freeVehicles = async (): Promise<FreeContractVehicle[]> =>
  await fetchRequest.get('/contractVehicle/freeVehicles');

export default {
  addContractVehicle,
  updateContractVehicle,
  fetchContractVehicles,
  deleteContractVehicle,
  fetchContractVehicle,
  reserveByEmployee,
  reserveByOvb,
  fetchContractVehicleAnalysis,
  freeVehicles
};
