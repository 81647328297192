import ExpenseDirection from '../../containers/expenseDirection';
import { accessTypeEnum } from '../../lib/enum';

// направление расходов
export default [
    {
        component: ExpenseDirection,
        path: '/admin/expense-direction/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingExpenseDirections,
            accessTypeEnum.handlingExpenseDirections,
            accessTypeEnum.handlingContracts,
        ],
    },
];