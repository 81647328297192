import React from 'react';
import styled, { css } from 'styled-components';
import ReactSlick from 'react-slick';
import Modal from 'antd/lib/modal';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Icon from './Icon';

const ArrowStyle = css`
  background: white;
  border-radius: 2px;
  width: 24px !important;
  height: 24px !important;
  &::before {
    content: none !important;
  }
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: white !important;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}
`;
export const NextArrow = styled(
  ({ visible, currentSlide, slideCount, ...props }) => (
    <div {...props}>
      <Icon type="arrow-next" size={12} />
    </div>
  )
)`
  ${ArrowStyle};
  &.slick-next {
    right: 16px;
  }
`;
export const PrevArrow = styled(
  ({ visible, currentSlide, slideCount, ...props }) => (
    <div {...props}>
      <Icon type="arrow-next" size={12} />
    </div>
  )
)`
  ${ArrowStyle};
  &.slick-prev {
    left: 16px;
  }
  & .icon {
    transform: rotate(180deg);
  }
`;
export const StyledSlide = styled.div`
  cursor: pointer;
  width: 300px;
  height: 200px;
  & img {
    width: 100%;
    height: 100%;
  }
`;
export const ModalImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const StyledReactSlick = styled(ReactSlick)`
  margin: -8px;
  .container {
    margin: 0 auto;
    padding: 40px;
    width: 80%;
    color: #333;
    background: #419be0;
  }

  h3 {
    background: blue;
    color: #fff;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
  }
  .variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1.08);
  }
  .center h3 {
    opacity: 0.8;
    transition: all 300ms ease;
  }
  .content {
    padding: 20px;
    margin: auto;
    width: 90%;
  }
  .slick-slide .image {
    padding: 10px;
  }
  .slick-slide img {
    display: block;
    padding: 8px;
  }
  .slick-slide img.slick-loading {
    border: 0;
  }
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    filter: grayscale(100%);
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }
  @media (max-width: 768px) {
    h3 {
      font-size: 24px;
    }
    .center {
      margin-left: -40px;
      margin-right: -40px;
    }
    .center .slick-center h3 {
      color: #e67e22;
      opacity: 1;
      transform: scale(1);
    }
    .center h3 {
      opacity: 0.8;
      transform: scale(0.95);
      transition: all 300ms ease;
    }
  }
  .slick-vertical .slick-slide {
    height: 180px;
  }
  & .slick-track {
    display: flex;
    margin: 0;
    width: 100%;
  }
`;
export const StyledModal = styled(Modal)`
  width: 100%;
  display: block !important;
  & .ant-modal-body {
    position: relative;
  }
`;
const ModalArrow = css`
  width: 50px !important;
  height: 50px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
  }
`;
export const ModalPrevArrow = styled(PrevArrow)`
  ${ModalArrow}
  left: -66px;
`;
export const ModalNextArrow = styled(NextArrow)`
  ${ModalArrow}
  right: -66px;
`;
export const ImageButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const SlideImage = styled(({ src, title, ...props }) => (
  <div {...props} />
))`
  padding: 8px;
  position: relative;
  ${({ src }) =>
    src &&
    css`
      background: url(${src});
      background-clip: content-box;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
      width: 100%;
    `}
  ${({ title }) =>
    title &&
    css`
    &::after {
      content: "${title}";
      position: absolute;
      // с учетом padding
      bottom: 8px;
      left: 8px;
      right: 8px;
      color: white;
      padding: 8px;
      background: rgba(0,0,0,.5);
    }
  `}
`;
