// @flow
import styled from 'styled-components';
import { GridItem as UIGridItem } from '../../../../../../../../../components/layout/Grid';

export const ControlButton = styled.button`
  background: transparent;
  border: none;
  padding: 5px;
  cursor: pointer;
  outline: none;
  margin-left: 16px;
`;

export const GridItem = styled(UIGridItem)`
  display: flex;
  align-items: center;
`;
