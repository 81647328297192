// @flow

import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';

import notification from 'antd/lib/notification';

import CloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import Field from '../../../../components/card/Field';
import { GridItem } from '../../../../components/layout/Grid';

import { AntTable } from '../../../../components/ui';
import { vehiclePlanApi, washingCalculationApi, washingCalculationMonthApi } from '../../../../lib/api';
import { calculationStatusEnum } from '../../../../lib/enum';

import type { VehicleListFilterParams } from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';

import { COLUMNS, filteredData, itogCalculation, MONTH } from '../lib';
import { Grid, Section } from './../../../../components/layout';
import { notificationLoading } from './../../../../components/Notifications';
import { getListInitialState, toLocalStringRu } from './../../../../lib/helpers';

import type { ListState, VehiclePlan, WashingCalculation } from './../../../../lib/types';

import { headerPanel } from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};
type State = ListState<WashingCalculation> & {
  vehiclePlan: ?VehiclePlan,
  filter: VehicleListFilterParams,
  visible: boolean,
  monthIndex: ?number,
  washingCalculation: ?WashingCalculation
};

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    vehiclePlan: null,
    visible: false,
    monthIndex: null,
    washingCalculation: null
  };

  onCell = (monthIndex: number) => (washingCalculation: WashingCalculation) => {
    return {
      onClick:
        this.isDraft() &&
        this.isRangeMonths(monthIndex) &&
        !this.afterPlannedWriteoffDate(
          monthIndex,
          washingCalculation?.selfVehiclePlanVehicle?.vehicle
            ?.plannedWriteoffDate ||
            washingCalculation?.selfVehiclePlanVehicle?.plannedWriteoffDate
        )
          ? () => {
              this.setState({
                washingCalculation: CloneDeep(washingCalculation),
                monthIndex,
                visible: true
              });
            }
          : undefined
    };
  };

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  // дата после списания
  afterPlannedWriteoffDate = (
    monthIndex: number,
    plannedWriteoffDate?: ?string
  ) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan && plannedWriteoffDate) {
      const year = moment(vehiclePlan.date).get('year');
      return moment(`${year}-${monthIndex + 1}-01`).isAfter(
        plannedWriteoffDate,
        'month'
      );
    }
    return false;
  };

  checkLink = (monthIndex: number, washingCalculation: WashingCalculation) => {
    return (
      this.isRangeMonths(monthIndex) &&
      this.isDraft() &&
      !this.afterPlannedWriteoffDate(
        monthIndex,
        washingCalculation?.selfVehiclePlanVehicle?.vehicle
          ?.plannedWriteoffDate ||
          washingCalculation?.selfVehiclePlanVehicle?.plannedWriteoffDate
      )
    );
  };

  columns = [
    ...COLUMNS,
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      children: [
        {
          title: 'Наружная',
          dataIndex: `months[${monthIndex}].bodyCount`,
          widht: 100,
          onCell: this.onCell(monthIndex),
          render: (count: number, washingCalculation: WashingCalculation) => (
            <span
              className={
                this.checkLink(monthIndex, washingCalculation)
                  ? 'link-text'
                  : null
              }
            >
              {count}
            </span>
          )
        },
        {
          title: 'Салон',
          dataIndex: `months[${monthIndex}].interiorCount`,
          widht: 100,
          onCell: this.onCell(monthIndex),
          render: (count: number, washingCalculation: WashingCalculation) => (
            <span
              className={
                this.checkLink(monthIndex, washingCalculation)
                  ? 'link-text'
                  : null
              }
            >
              {count}
            </span>
          )
        },
        {
          title: 'Двигатель',
          dataIndex: `months[${monthIndex}].engineCount`,
          widht: 100,
          onCell: this.onCell(monthIndex),
          render: (count: number, washingCalculation: WashingCalculation) => (
            <span
              className={
                this.checkLink(monthIndex, washingCalculation)
                  ? 'link-text'
                  : null
              }
            >
              {count}
            </span>
          )
        },
        {
          title: 'Стоимость',
          dataIndex: `months[${monthIndex}].cost`,
          widht: 100,
          className: 'primary-background',
          render: (cost: number) => toLocalStringRu(cost)
        }
      ]
    })),
    {
      title: 'Итог',
      width: 100,
      key: 'sum',
      dataIndex: 'sum',
      render: (sum: number) => toLocalStringRu(sum)
    }
  ];

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;
    try {
      let { data } = await washingCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  handleCancel = () => {
    this.setState({
      washingCalculation: null,
      visible: false,
      monthIndex: null
    });
  };

  handleSubmit = async (washingCalculation: WashingCalculation) => {
    const { monthIndex, data } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      if (monthIndex || (monthIndex === 0 && data)) {
        await washingCalculationMonthApi.updateCalculations(
          washingCalculation.months[monthIndex]
        );
        const rowIndex = data.findIndex(
          item => item.id === washingCalculation.id
        );
        if (rowIndex !== -1) {
          data[rowIndex] = washingCalculation;
          this.setState({ data });
        }
      }
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
      this.handleCancel();
    }
  };

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await washingCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    const { data } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      if (data.length === 0)
        await washingCalculationApi.calculate(vehiclePlanId);
      else await washingCalculationApi.updateCalculations(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.washingCalculationStatus ===
    calculationStatusEnum.draft;

  applyFilter = (filter: VehicleListFilterParams) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  render() {
    const { vehiclePlanId } = this.props;
    const {
      data,
      loading,
      filter,
      visible,
      monthIndex,
      washingCalculation
    } = this.state;

    const filtered = filteredData(data, filter);
    const itog = itogCalculation(filtered);

    return (
      <>
        {headerPanel({
          title: 'Расчёт потребности в мойке ТС',
          vehiclePlanId
        })}
        <Section>
          <div style={{ padding: '16px 16px 0px' }}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            columns={this.columns}
            bordered
            scroll={{
              x: 5700,
              y: 'calc(100vh - 340px)'
            }}
          />
        </Section>
        {this.isDraft() && (
          <Section style={{ padding: '16px' }}>
            <Button type="primary" onClick={this.calculate}>
              {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              type="primary"
              onClick={this.changeStatus}
            >
              Утвердить
            </Button>
          </Section>
        )}

        <ModalWashing
          visible={visible}
          monthIndex={monthIndex || 0}
          washingCalculation={washingCalculation}
          handleSave={this.handleSubmit}
          handleCancel={this.handleCancel}
        />
      </>
    );
  }
}

type ModalWashingProps = {
  visible: boolean,
  handleSave: Function,
  handleCancel: Function,
  monthIndex: number,
  washingCalculation: ?WashingCalculation
};
const ModalWashing = (props: ModalWashingProps) => {
  const {
    visible,
    handleSave,
    handleCancel,
    monthIndex,
    washingCalculation
  } = props;
  const [localWashingCalculation, setMaterial] = useState(washingCalculation);
  const onOk = () => {
    handleSave(washingCalculation);
  };
  const onCancel = () => {
    handleCancel();
  };

  useEffect(() => {
    if (washingCalculation) {
      setMaterial({ ...washingCalculation });
    }
  }, [washingCalculation]);

  return (
    <Modal
      destroyOnClose
      width={800}
      title="Редактирование количества моек"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Grid gutter="16px">
        <GridItem>
          <Field label="Наружние">
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              value={localWashingCalculation?.months[monthIndex].bodyCount || 0}
              onChange={count => {
                if (localWashingCalculation) {
                  localWashingCalculation.months[
                    monthIndex
                  ].bodyCount = Math.round(+count);
                  setMaterial({ ...localWashingCalculation });
                }
              }}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Салон">
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              value={
                localWashingCalculation?.months[monthIndex].interiorCount || 0
              }
              onChange={count => {
                if (localWashingCalculation) {
                  localWashingCalculation.months[
                    monthIndex
                  ].interiorCount = Math.round(+count);
                  setMaterial({ ...localWashingCalculation });
                }
              }}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Двигатель">
            <InputNumber
              min={0}
              style={{ width: '100%' }}
              value={
                localWashingCalculation?.months[monthIndex].engineCount || 0
              }
              onChange={count => {
                if (localWashingCalculation) {
                  localWashingCalculation.months[
                    monthIndex
                  ].engineCount = Math.round(+count);
                  setMaterial({ ...localWashingCalculation });
                }
              }}
            />
          </Field>
        </GridItem>
      </Grid>
    </Modal>
  );
};
