import ContractVehicleWorkPlans from '../containers/ContractVehicleWorkPlans';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: ContractVehicleWorkPlans,
        path: '/contract-vehicle-work-plans/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.approvingTimeLimits,
            accessTypeEnum.handlingTimeLimits,
            accessTypeEnum.viewingTimeLimits,
            accessTypeEnum.viewingBranchTimeLimits,
            accessTypeEnum.approvingLimitAssignment,
            accessTypeEnum.handlingLimitAssignment,
            accessTypeEnum.updatingDailyBudgets,
            accessTypeEnum.viewingLimitAssignment,
            accessTypeEnum.viewingAllLimitAssignment,
            accessTypeEnum.approvingYearLimitsPlan,
            accessTypeEnum.handlingYearLimitsPlan,
            accessTypeEnum.viewingBranchYearLimitsPlan,
            accessTypeEnum.viewingYearLimitsPlan,
            accessTypeEnum.approvingMonthLimitsPlan,
            accessTypeEnum.handlingMonthLimitsPlan,
            accessTypeEnum.viewingBranchMonthLimitsPlan,
            accessTypeEnum.viewingMonthLimitsPlan,
        ],
    },
];