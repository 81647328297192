import { ContractVehicleOrderForm } from '../containers/ContractVehicleOrder';
import { OrderCard, OrderForm, OrderList } from '../containers/Orders';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: OrderForm,
        path: '/orders/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: OrderForm,
        path: '/orders/edit/:orderId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: OrderForm,
        path: '/orders/:copyOrderId/copy',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: OrderList,
        path: '/orders/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.approvingGpmOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: OrderCard,
        path: '/orders/:orderId/card',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.creatingOrder,
            accessTypeEnum.approvingGpmOrder,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: ContractVehicleOrderForm,
        path: '/orders/:orderId/contract-vehicle-order/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
        ],
    },
    {
        component: ContractVehicleOrderForm,
        path: '/orders/:orderId/contract-vehicle-order/:contractVehicleOrderId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOrder,
        ],
    },
];