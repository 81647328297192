// @flow

import React from 'react';
import { FormField } from '../../../../components/Form';

import CommonForm, { type CommonFormProps } from '../../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { budgetSummaryStatusEnum } from '../../../../lib/enum';

import type { BudgetAnalysis } from '../../../../lib/types';
import { Selects } from './../../../../components';

const { BranchBudgetSummarySelect } = Selects;
export default (props: CommonFormProps<$Shape<BudgetAnalysis>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  required
                  fast
                  label="Расчетный годовой бюджет"
                  name="mainBudgetId"
                >
                  {({ value, name }) => (
                    <BranchBudgetSummarySelect
                      value={value}
                      name={name}
                      filter={{ status: budgetSummaryStatusEnum.approved }}
                      onChange={(value: number, option: any) => {
                        setFieldValue(name, value);
                        setFieldValue(
                          'mainBudget',
                          option?.props?.branchBudgetSummary
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField fast label="Бюджет БК" name="budgetBkId">
                  {({ value, name }) => (
                    <BranchBudgetSummarySelect
                      value={value}
                      name={name}
                      filter={{ status: budgetSummaryStatusEnum.approved }}
                      onChange={(value: number, option: any) => {
                        setFieldValue(name, value);
                        setFieldValue(
                          'budgetBk',
                          option?.props?.branchBudgetSummary
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField fast label="Бюджет БКФ" name="budgetBkfId">
                  {({ value, name }) => (
                    <BranchBudgetSummarySelect
                      value={value}
                      name={name}
                      filter={{ status: budgetSummaryStatusEnum.approved }}
                      onChange={(value: number, option: any) => {
                        setFieldValue(name, value);
                        setFieldValue(
                          'budgetBkf',
                          option?.props?.branchBudgetSummary
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
