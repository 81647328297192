// @flow
import { WarningOutlined } from '@ant-design/icons';
import React from 'react';
import uniq from 'lodash/uniq';
import flattenDeep from 'lodash/flattenDeep';

import type { Driver, Vehicle } from '../../../lib/types';
import { Popover } from '../../../components/ui';
import {
  validateDriverLicenseCategory,
  validateDriverQualification
} from '../lib';
import { Divider } from '../../../components/layout';
import { Bold } from '../../../components/typography';
import {
  driverQualifications,
  drivingLicenseCategories,
  drivingLicenseCategoryEnum,
  tractorDrivingLicenseCategories,
  tractorDrivingLicenseCategoryEnum
} from '../../../lib/enum';
import { List } from './elements';

type Props = {
  // Водитель
  driver?: Driver,
  // Проверяемое ТС
  vehicle?: Vehicle
};

const licenseCategories = {
  ...drivingLicenseCategories,
  ...tractorDrivingLicenseCategories
};

/**
 * Компонент индикации соответствия квалификации и категории водителя
 * к заданному ТС
 *
 * Просто иконка с выводящейся плашкой о несоответствии водителя к ТС
 */
export const DriverWarningIndicator = ({ driver, vehicle }: Props) => {
  if (vehicle && driver) {
    const { qualificationDocuments } = driver;
    const {
      driverQualification: vehicleDriverQualification,
      rightsCategory
    } = vehicle.vehicleModel;
    const qualifications = qualificationDocuments.map(
      document => document.qualification
    );
    const driverRightCategories = uniq(
      flattenDeep(
        qualificationDocuments
          .filter(doc => doc.qualification === vehicleDriverQualification)
          .map(document => document.categories)
      ).filter(
        category =>
          drivingLicenseCategoryEnum[category] ||
          tractorDrivingLicenseCategoryEnum[category]
      )
    );
    // Проверка на соответствие квалификаций
    const hasErrorByDriverQualification = !validateDriverQualification(
      driver,
      vehicle
    );
    // Проверка на соответствие категорий прав
    const hasErrorByLicenseCategory = !validateDriverLicenseCategory(
      driver,
      vehicle
    );
    if (!hasErrorByLicenseCategory && !hasErrorByDriverQualification)
      return null;
    return (
      <Popover
        overlayStyle={{ zIndex: 9999 }}
        width={200}
        title="Внимание!"
        content={
          <>
            {hasErrorByDriverQualification ? (
              <>
                <p>
                  Квалификации водителя не соответствуют требуемым для данного
                  ТС
                </p>
                <Divider color="light" style={{ margin: '8px 0' }} />
                <Bold>Документы водителя:</Bold>
                {!!qualifications.length ? (
                  <List>
                    {qualifications.filter(Boolean).map((value, key) => (
                      <li key={key}>{driverQualifications[value]}</li>
                    ))}
                  </List>
                ) : (
                  <p>Нет документов</p>
                )}
                {vehicleDriverQualification ? (
                  <p>
                    <Bold>Требуемые документы:</Bold>
                    <br />
                    {driverQualifications[vehicleDriverQualification]}
                  </p>
                ) : (
                  <Bold>
                    Для данной модели ТС не указана требуемая квалификация
                    водителя
                  </Bold>
                )}
              </>
            ) : (
              hasErrorByLicenseCategory && (
                <>
                  <p>
                    Категории прав водителя не соответствуют требуемым для
                    данного ТС
                  </p>
                  <Bold>Категории прав водителя:</Bold>
                  {!!driverRightCategories.length ? (
                    <List>
                      {driverRightCategories
                        .filter(Boolean)
                        .map((value, key) => (
                          <li key={key}>{licenseCategories[value]}</li>
                        ))}
                    </List>
                  ) : (
                    <p>Нет категорий прав</p>
                  )}
                  {rightsCategory && !!rightsCategory.length ? (
                    <>
                      <Bold>Требуемые категории прав:</Bold>
                      <br />
                      <List>
                        {rightsCategory.filter(Boolean).map((value, key) => (
                          <li key={key}>{licenseCategories[value]}</li>
                        ))}
                      </List>
                    </>
                  ) : (
                    <Bold>
                      Для данной модели ТС не указаны требуемые категории прав
                    </Bold>
                  )}
                </>
              )
            )}
          </>
        }
      >
        <WarningOutlined
          style={{
            cursor: 'help',
            color: '#FAAD14',
            marginLeft: '8px'
          }}
        />
      </Popover>
    );
  }
  return null;
};

export default DriverWarningIndicator;
