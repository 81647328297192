//@flow
import React from 'react';
import CommonListPage from '../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import type { AppState } from '../../ducks/redux';
import { connect } from 'react-redux';
import {
  maintenanceOperationGroupApi,
  maintenanceOperationApi
} from '../../lib/api';
import { HeaderTabs } from './components';
import { navigate } from '@reach/router';
import type { UserAccess } from './../../lib/types';
import { accessTypeEnum } from './../../lib/enum';

const columns = [
  { title: 'Код работы', dataIndex: 'code', key: 'code' },
  { title: 'Наименование', dataIndex: 'name', key: 'name' },
  { title: 'Норма-часы', dataIndex: 'hours', key: 'hours' }
];
type Props = {
  userAccess: UserAccess[]
};
const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingMaintenanceOperationGroups
];
const access = {
  add: () => navigate(`/admin/maintenance-operations/new/`)
};
const MaintenanceOperationsTable = ({ userAccess }: Props) => {
  const onFetch = async (params: any) => {
    return await maintenanceOperationGroupApi.fetchMaintenanceOperationGroups(
      params
    );
  };
  const onDelete = async (id: number) => {
    return await maintenanceOperationApi.deleteMaintenanceOperation(id);
  };
  const canAdd = () => userAccess.some(access => addAccess.includes(access));
  return (
    <Page>
      <CommonListPage
        pageHeaderProps={{
          mainHeader: 'Перечень работ по ремонту ТС',
          access
        }}
        subHeader={<HeaderTabs selectedTab="general" />}
        tableListProps={{
          columns,
          onSelect: (id: number) =>
            navigate(`/admin/maintenance-operations/${id}`),
          canDelete: canAdd()
        }}
        oldCrud={{ onFetch, onDelete }}
      />
    </Page>
  );
};

export default connect((state: AppState) => ({
  maintenanceOperationGroups: state.maintenanceOperationGroups,
  userAccess: state.auth.profile.access
}))(MaintenanceOperationsTable);
