// @flow
import type { TripInspectionCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (tripInspectionCost: TripInspectionCost) => ({
  ...tripInspectionCost,
  cost: convertFromRubToKop(tripInspectionCost.cost)
});

const convertDataFromServer = (tripInspectionCost: TripInspectionCost) => ({
  ...tripInspectionCost,
  cost: convertFromKopToRub(tripInspectionCost.cost)
});

const tripInspectionCost = new CommonApi<TripInspectionCost>(
  'tripInspectionCost',
  'Стоимость предрейсовых/послерейсовых осмотров',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...tripInspectionCost
};
