import {
    ContractCard,
    ContractForm,
    ContractList,
} from '../../containers/Contract';
import { accessTypeEnum } from '../../lib/enum';

// реестр договоров
export default [
    {
        component: ContractList,
        path: '/budget/contract',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingContracts,
            accessTypeEnum.viewingAllContracts,
            accessTypeEnum.handlingContracts,
        ],
    },
    {
        component: ContractForm,
        path: '/budget/contract/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingContracts,
        ],
    },
    {
        component: ContractCard,
        path: '/budget/contract/:contractId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingContracts,
            accessTypeEnum.viewingAllContracts,
        ],
    },
    {
        component: ContractForm,
        path: '/budget/contract/:contractId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingContracts,
        ],
    },
];