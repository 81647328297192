import { SettingForm, SettingList } from '../../containers/Setting';
import { accessTypeEnum } from '../../lib/enum';

export default [
    // настройки системы
    {
        component: SettingList,
        path: '/admin/setting',
        access: [accessTypeEnum.admin],
    },
    {
        component: SettingForm,
        path: '/admin/setting/:id',
        access: [accessTypeEnum.admin],
    },
];