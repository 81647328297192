import { WialonCard } from '../containers/Wialon';
import { accessTypeEnum } from '../lib/enum';

export default [
    {
        component: WialonCard,
        path: '/wialonMonitoring',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.wialonPage,
        ],
    },
];