// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { okeiApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';

const entryPointPath = '/admin/okei/';

const columns = [
  {
    title: 'Код',
    dataIndex: 'code'
  },
  {
    title: 'Наименование единицы измерения',
    dataIndex: 'name'
  },
  {
    title: 'Условное обозначение',
    dataIndex: 'alias'
  }
];

const breadCrumbs = [
  { to: '/', name: 'Главная' },
  { name: 'Справочники' },
  { name: 'ОКЕИ' }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  return (
    <Page>
      <CommonListPage
        crud={okeiApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Общероссийский классификатор единиц измерения',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
