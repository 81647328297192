// @flow
import polyline from '@mapbox/polyline';
import orderBy from 'lodash/orderBy';
import last from 'lodash/last';
import qs from 'query-string';

import type { WayPoint, Location } from './types';

import { proxyApi } from './api';

// В этом файле функции для работы с геоданными.

const GOOGLE_MAPS_API_KEY = `${
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
}`;

/**
 * тайлы(подложка) карты используется мапбоксовская,
 * потому что мне она кажеся симпатичнее чам OSM
 * АПИ кей может протухнуть когда-нибудь
 */
export const MAPBOX_API_KEY = `${process.env.REACT_APP_MAPBOX_API_KEY || ''}`;

/*
  Принимает широту и долготу, возвращает адрес строкой
 */
export const geocode = async (lat: number, long: number): Promise<string> => {
  const REVERSE_GEOCODING_QUERY = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&language=ru&key=${GOOGLE_MAPS_API_KEY}`;

  const response = await fetch(REVERSE_GEOCODING_QUERY);

  if (response.ok) {
    const body = await response.json();

    if (body.status !== 'OK') {
      throw new Error(`Server operation status ${body.status}`);
    }
    /**
     * Гугл на предосталяет набор элементов адреса,
     * но нам нужны только улица (фильтруем "Unnamed road"), дом, субъект, город/район субъекта
     *
     * Если ничего из вышеперечисленного у нас нет, то выводим хотя бы страну.
     *
     * Если же и страны у нас нет, то выводим то, что гугл нам предоставил
     * в качестве комбинированной строки
     */

    var address_components = body.results[0].address_components;
    address_components = address_components.filter(
      (address) => !address.types.includes('postal_code')
    );
    var route = address_components.find(
      (item) =>
        item.types.includes('route') &&
        item.short_name.toLowerCase() !== 'unnamed road'
    );
    var streetNumber = address_components.find((item) =>
      item.types.includes('street_number')
    );
    var city = address_components.find((item) =>
      item.types.includes('administrative_area_level_2')
    );
    var district = address_components.find((item) =>
      item.types.includes('administrative_area_level_1')
    );
    var country = address_components.find((item) =>
      item.types.includes('country')
    );
    let customAddressComponents = [route, streetNumber, city, district].filter(
      (item) => !!item
    );
    if (!customAddressComponents.length && !!country) {
      // Делаем так, потому что в стране short_name вместо "Россия" содержит "RU"
      customAddressComponents = [{ ...country, short_name: country.long_name }];
    }
    if (customAddressComponents.length) {
      return customAddressComponents.map((item) => item.short_name).join(', ');
    }
    return body.results[0].formatted_address;
  } else {
    throw Error('Geocoding server is not availiable');
  }
};

export type Direction = {
  // дистаниция в метрах
  distance: number,
  // геометрия в geoJSON
  geoJSON: any,
  // нулевой пробег до объекта
  distanceAtStart: number,
  // нулевой пробег до места стоянки
  distanceAtEnd: number,
  // boundung box - прямоугольник вокруг маршрута
  bounds?: {
    northeast: {
      lat: number,
      long: number,
    },
    southwest: {
      lat: number,
      long: number,
    },
  },
};

// Обращается к Directions API от Google Maps Platform и строит маршрут по графу дорог
// дока https://developers.google.com/maps/documentation/directions/intro
export const directions = async (waypoints: WayPoint[]): Promise<Direction> => {
  if (waypoints.length < 2) {
    throw new Error('At least two wayoints required');
  }

  if (waypoints.length >= 10) {
    throw new Error('Maps platform allows max 10 waypoint');
  }

  // нам важен порядок точек, чтобы построить правильные ребра графа
  const orderedWaypoint = orderBy(waypoints, [
    'departureDateTime',
    'arrivedDateTime',
  ]);

  const origin = `${orderedWaypoint[0].latitude},${orderedWaypoint[0].longitude}`;
  const destination = `${
    orderedWaypoint[orderedWaypoint.length - 1].latitude
  },${orderedWaypoint[orderedWaypoint.length - 1].longitude}`;

  const transitPoints = [];

  if (orderedWaypoint.length > 2) {
    // если точек больше чем 2, то из промежуточных нужно сделать отедельный параметр запрос
    for (let i = 1; i <= orderedWaypoint.length - 2; i++) {
      transitPoints.push(
        `${orderedWaypoint[i].latitude},${orderedWaypoint[i].longitude}`
      );
    }
  }

  const DIRECTIONS_API_QUERY = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=${GOOGLE_MAPS_API_KEY}`;
  const DIRECTIONS_API_WITH_WAYPOINT_QUERY = `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&waypoints=${transitPoints.join(
    '|'
  )}&key=${GOOGLE_MAPS_API_KEY}`;

  let query;

  if (waypoints.length === 2) {
    query = DIRECTIONS_API_QUERY;
  } else {
    query = DIRECTIONS_API_WITH_WAYPOINT_QUERY;
  }

  const response = await proxyApi.proxy(query);

  if (response.status === 'ZERO_RESULTS') {
    throw new Error('Проверьте доступность подъезда к геокоординатам');
  }
  if (response.status !== 'OK') {
    throw new Error(`Server operation status ${response.status}`);
  }
  const route = response.routes[0];
  const distance = route.legs.reduce((sum, r) => sum + r.distance.value, 0);
  const distanceAtStart = route.legs[0].distance.value;
  const distanceAtEnd = last(route.legs).distance.value;
  // У гугла свой формат для передачи геометрий, используем мапбоксовский декодер
  const geoJSON = polyline.toGeoJSON(route.overview_polyline.points);
  return {
    distance,
    distanceAtStart,
    distanceAtEnd,
    geoJSON,
  };
};

export const getGooglePlaces = async (address: string): Promise<Location[]> => {
  // Опции запроса
  const requestOptions = qs.stringify({
    key: GOOGLE_MAPS_API_KEY,
    language: 'ru',
    input: address,
    // Радиус поиска мест
    location: '55.3808455416306,50.43205871583283',
    radius: 200000,
    // Поиск строго в этом радиусе и location
    strictbounds: true,
    // Ограничиваем выборку по России
    components: `country:ru`,
  });
  const response = await proxyApi.proxy(
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?${requestOptions}`
  );
  return response.predictions.map((item) => {
    const { description: address, place_id: googlePlaceId } = item;
    return {
      address,
      name: address,
      googlePlaceId,
      radius: 1,
    };
  });
};

export const getPlace = async (placeId: string) => {
  const requestOptions = qs.stringify({
    key: GOOGLE_MAPS_API_KEY,
    placeid: placeId,
    language: 'ru',
    fields: 'geometry',
  });
  const response = await proxyApi.proxy(
    `https://maps.googleapis.com/maps/api/place/details/json?${requestOptions}`
  );
  const place = response.result;
  return place.geometry.location;
};

/**
 * Промисифицированная функция получения геолокации
 */
export const getCurrentPosition = async (): Promise<Position> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
