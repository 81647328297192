import React, { useState } from 'react';
import { Header } from '../../components/layout';
import { Router } from '@reach/router';
import DailyBudgets from './DailyBudget/DailyBudgets';
import TimeLimits from './TimeLimit/TimeLimits';
import { TabItem, Tabs } from '../../components/ui';
import Assignments from './Assignment/Assignments';
import AnalysisTable from './Analysis/AnalysisTable';
import { connect } from 'react-redux';
import type { AppState } from '../../ducks/redux';
import type { UserAccess } from '../../lib/types';
import { showAssignments, showDailyBudgets, showTimeLimits } from './lib';
import OrgUnitSelect from '../../components/OrgUnitSelect';
import { accessTypeEnum } from '../../lib/enum';

type Props = {
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

const WorkPlans = ({ userAccess, employeeBranchOrgUnitId }: Props) => {
  const canViewTimeLimits = showTimeLimits(userAccess);
  const canShowAssignments = showAssignments(userAccess);
  const canDailyBudgets = showDailyBudgets(userAccess);
  const [orgUnitId, setOrgUnitId] = useState(employeeBranchOrgUnitId);

  const isAdmin = userAccess.some(access =>
    [accessTypeEnum.admin, accessTypeEnum.adminBranch].includes(access)
  );

  return (
    <>
      <Header
        left={<h1>План работ НТС</h1>}
        right={
          isAdmin && (
            <OrgUnitSelect
              style={{ width: 200 }}
              value={orgUnitId}
              onlyBranches
              onChange={orgUnitId => setOrgUnitId(orgUnitId)}
            />
          )
        }
      />
      <Tabs withRouter>
        {canViewTimeLimits && (
          <TabItem
            label="Регламент"
            url="/contract-vehicle-work-plans/time-limit"
          />
        )}
        {canShowAssignments && (
          <TabItem
            label="Закрепление"
            url="/contract-vehicle-work-plans/assignments"
          />
        )}
        {canDailyBudgets && (
          <TabItem
            label="Планирование"
            url="/contract-vehicle-work-plans/daily-budgets"
          />
        )}
        <TabItem label="Анализ" url="/contract-vehicle-work-plans/analysis" />
      </Tabs>
      <Router>
        {canViewTimeLimits && (
          <TimeLimits orgUnitId={orgUnitId} path="time-limit" />
        )}
        {canShowAssignments && (
          <Assignments orgUnitId={orgUnitId} path="assignments" />
        )}
        {canDailyBudgets && (
          <DailyBudgets orgUnitId={orgUnitId} path="daily-budgets/*" />
        )}
        <AnalysisTable orgUnitId={orgUnitId} path="analysis" />
      </Router>
    </>
  );
};

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access,
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(WorkPlans);
