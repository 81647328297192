// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { pddGroupApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await pddGroupApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={pddGroupApi.get}
    notFoundText="Темы ПДД не найдены"
    placeholder="Выберите тему ПДД"
    renderOption={(pddGroup: any, Option) => (
      <Option key={pddGroup.id} value={pddGroup.id} group={pddGroup}>
        {pddGroup.name}
      </Option>
    )}
    {...selectProps}
  />
);
