import React from 'react';
import styled from 'styled-components';
import { InputNumber } from 'antd';

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
export default props =>
  props.fullWidth ? (
    <StyledInputNumber min={0} {...props} />
  ) : (
    <InputNumber min={0} {...props} />
  );
