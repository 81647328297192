// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { channelApi } from '../../lib/api';
import type { Channel } from '../../lib/types';
import { formatDateTimeToString } from '../../lib/helpers';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps;

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={channelApi.fetch}
    refetchParams={{ filter }}
    fetchSingle={channelApi.get}
    notFoundText="Каналы не найдены"
    placeholder="Выберите канал"
    renderOption={(channel: Channel, Option) => (
      <Option key={channel.id} value={channel.id} channel={channel}>
        {channel.channelId}
      </Option>
    )}
    {...selectProps}
  />
);
