// @flow
import queryString from 'query-string';
import type { OsagoCalculation } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

export const convertDataFromServer = (osagoCalculation: OsagoCalculation) => ({
  ...osagoCalculation,
  curInsuranceSum: convertFromKopToRub(osagoCalculation.curInsuranceSum),
  minInsuranceSum: convertFromKopToRub(osagoCalculation.minInsuranceSum),
  maxInsuranceSum: convertFromKopToRub(osagoCalculation.maxInsuranceSum),
  currentBaseRate: convertFromKopToRub(osagoCalculation.currentBaseRate),
  minBaseRate: convertFromKopToRub(osagoCalculation.minBaseRate),
  maxBaseRate: convertFromKopToRub(osagoCalculation.maxBaseRate)
});

export const convertDataToServer = (osagoCalculation: OsagoCalculation) => ({
  ...osagoCalculation,
  currentBaseRate: convertFromRubToKop(osagoCalculation.currentBaseRate),
  minBaseRate: convertFromRubToKop(osagoCalculation.minBaseRate),
  maxBaseRate: convertFromRubToKop(osagoCalculation.maxBaseRate)
});

export const addOsagoCalculation = async (
  osagoCalculation: $Shape<OsagoCalculation>
): Promise<OsagoCalculation> => {
  const added = await fetchRequest.post(
    '/osagoCalculation',
    convertDataToServer(osagoCalculation)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать расчет стоимости ОСАГО');
};

export const updateOsagoCalculation = async (
  osagoCalculation: OsagoCalculation
): Promise<OsagoCalculation> => {
  const updated = await fetchRequest.put(
    '/osagoCalculation',
    convertDataToServer(osagoCalculation)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить расчет стоимости ОСАГО');
};

export const deleteOsagoCalculation = async (
  id: number
): Promise<OsagoCalculation> => {
  const deleted = await fetchRequest.delete(`/osagoCalculation/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить расчет стоимости ОСАГО');
};

export const fetchOsagoCalculation = async (
  id: number,
  params: any = {}
): Promise<OsagoCalculation> => {
  const osagoCalculation = await fetchRequest.get(
    `/osagoCalculation/${id}`,
    params
  );
  if (osagoCalculation) return convertDataFromServer(osagoCalculation);
  throw new Error('Не удалось получить расчет стоимости ОСАГО');
};

export const fetchOsagoCalculations = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<OsagoCalculation>> => {
  const osagoCalculations = await fetchRequest.get(`/osagoCalculation`, {
    ...initialFetchListParams,
    ...params
  });
  if (osagoCalculations) {
    return {
      ...osagoCalculations,
      data: osagoCalculations.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список расчетов стоимости ОСАГО');
};

type OsagoAddVehicles = {
  osagoCalculationYearId: number,
  osagoCalculationIds: number[]
};
export const addVehicles = async (
  data: OsagoAddVehicles,
  params: any = {}
): Promise<any> => {
  const query = params ? queryString.stringify(params) : '';
  const added = await fetchRequest.post(
    `/osagoCalculation/addVehicles?${query}`,
    data
  );
  if (added) return added;
  throw new Error('Не удалось добавить ТС в расчет стоимости ОСАГО');
};

export const print = async (
  osagoCalculationYearId: number
): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    `/osagoCalculation/print/${osagoCalculationYearId}`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export const fetchOsagoCalculationByYear = async (
  params: any = {}
): Promise<ListResponse<OsagoCalculation>> => {
  const yearGroups = await fetchRequest.get('/osagoCalculationYear', {
    ...params
  });

  if (yearGroups) {
    return yearGroups;
  }

  throw new Error('Не удалось получить расчеты ОСАГО');
};

export default {
  addOsagoCalculation,
  updateOsagoCalculation,
  deleteOsagoCalculation,
  fetchOsagoCalculation,
  fetchOsagoCalculations,
  fetchOsagoCalculationByYear,
  addVehicles,
  print
};
