import { DashboardCard } from '../containers/Dashboard';
import { accessTypeEnum } from '../lib/enum';

//   страница руководителя
export default [
    {
        component: DashboardCard,
        path: '/dashboard',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingDashboard,
        ],
    },
];