// @flow
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Modal from 'antd/lib/modal';
import InputNumber from 'antd/lib/input-number';

import {
  toLocalStringRu,
  MONTH,
  tooltipFullText,
  plus
} from '../../../../lib/helpers';
import type { BranchBudgetSummaryLineItem } from '../../../../lib/types';

import Field from '../../../../components/card/Field';
import { AntTable } from '../../../../components/ui';
import Grid, { GridItem } from '../../../../components/layout/Grid';

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;
const StyledTable = styled(AntTable)``;

type Props = {
  visible: boolean,
  onCancel: Function,
  onSave: Function,
  monthIndex: ?number,
  branchBudgetSummaryId: number,
  branchBudgetSummaryLineItem: BranchBudgetSummaryLineItem,
  monthToId: ?number,
  readOnly: boolean
};
export default ({
  visible,
  onCancel,
  onSave,
  branchBudgetSummaryLineItem,
  branchBudgetSummaryId,
  monthIndex,
  monthToId
}: Props) => {
  const [budget, setBudget] = useState(branchBudgetSummaryLineItem);
  const [writeOff, setWriteOff] = useState(0);

  useEffect(() => {
    setBudget(branchBudgetSummaryLineItem);
  }, [branchBudgetSummaryLineItem, monthIndex]);

  return (
    <Modal
      destroyOnClose
      width={1200}
      title="Покрытие потребности"
      visible={visible}
      onCancel={() => {
        setWriteOff(0);
        onCancel();
      }}
      onOk={() => {
        onSave({
          id: monthToId,
          writeOff
        });
      }}
    >
      <>
        <StyledTable
          columns={[
            {
              title: (
                <div>
                  Статья бюджетной формы
                  <br />
                  откуда производится перенос
                </div>
              ),
              dataIndex: 'budgetLineItemName',
              render: budgetLineItemName => (
                <WhiteSpace>
                  {tooltipFullText(budgetLineItemName, 35)}
                </WhiteSpace>
              )
            },
            {
              title: (
                <div>
                  Направление расходов
                  <br />
                  откуда производится перенос
                </div>
              ),
              dataIndex: 'expenseDirectionName',
              render: expenseDirectionName => (
                <WhiteSpace>
                  {tooltipFullText(expenseDirectionName, 35)}
                </WhiteSpace>
              )
            },
            {
              title: (
                <div>
                  Месяц с которого
                  <br />
                  производится снятие
                </div>
              ),
              dataIndex: 'months',
              render: months => MONTH[monthIndex]
            },
            {
              title: <div>Итого снято</div>,
              dataIndex: 'months',
              key: 'economyResidue',
              render: (months, record) =>
                toLocalStringRu(plus(months[monthIndex].writeOffSum, writeOff))
            }
          ]}
          data={[budget]}
          pagination={false}
        />
        <Grid
          style={{
            marginTop: 16,
            paddingTop: 16,
            borderTop: '1px solid #c0ccd7'
          }}
          gutter="16px"
        >
          <GridItem>
            <Field>
              <StyledInputNumber
                placeholder="Укажите сумму переноса"
                value={writeOff}
                min={0}
                onChange={writeOff => {
                  if (
                    Math.abs(budget?.months[monthIndex]?.economy ?? 0) >=
                    writeOff
                  ) {
                    setWriteOff(writeOff);
                  }
                }}
              />
            </Field>
          </GridItem>
        </Grid>
      </>
    </Modal>
  );
};
