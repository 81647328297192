// @flow

import { navigate } from '@reach/router';
import React, { useCallback, useState } from 'react';

import { Card } from '../../components';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { GridItem } from '../../components/layout';
import Grid from '../../components/layout/Grid';

import { roleApi } from '../../lib/api';
import { accessTypeEnum, accessTypes } from '../../lib/enum';
import type { Role, UserAccess } from '../../lib/types';

import { withUserAccess } from '../withUserAccess';

const { Field } = Card;

type PageProps = {
  id: ?number,
  userAccess: UserAccess[],
};
type InnerProps = {
  data: Role,
};

const entryPointPath = '/admin/roles/';

const InnerCard = (props: InnerProps) => {
  const { data } = props;
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Логин">{data.name}</Field>
        </GridItem>
        <GridItem>
          <Field label="Доступы">
            {data.access &&
              data.access.map((access) => accessTypes[access]).join(', ')}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: Role, setData] = useState({});

  const canEdit = props.userAccess.some((access) =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingUsersAndRoles,
    ].includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await roleApi.deleteRole(data.id);
          await navigate(`${entryPointPath}`);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}${data.id}/edit`)
      : false,
  };

  const onFetch = useCallback(async () => {
    props.id && setData(await roleApi.fetchRole(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: [
          { to: entryPointPath, name: 'Список ролей' },
          { name: 'Карточка роли' },
        ],
        mainHeader: 'Роль',
        access,
      }}
      onFetch={onFetch}
    >
      <InnerCard data={data} />
    </CommonCardPage>
  );
});
