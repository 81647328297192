// @flow

import { journalApi } from '../lib/api';
import { defaultPageSize } from './constants';
import type { Journal, EntityType } from './../lib/types';
import type { FetchListParams } from '../lib/api';
import type { Action, AppState, ListState } from './redux';
import { type Dispatch } from 'redux';

export const FETCH_JOURNALS = 'vehicles/journals/fetch';
export const SET_FILTER = 'vehicles/journals/set-filter';
export const CLEAN_FILTER = 'vehicles/journals/clean-filter';

export type JournalFilter = {
  entityType?: EntityType,
  entityId?: number,
  startDate?: string,
  endDate?: string,
  userName?: string,
  type?: string,
  search?: string
};

export type JournalsState = ListState<Journal> & { filters: JournalFilter };

const initialState: JournalsState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0,
  filters: {}
};

const reducer = (
  state: JournalsState = initialState,
  { type, payload }: Action
): JournalsState => {
  switch (type) {
    case FETCH_JOURNALS:
      return { ...state, ...payload };
    case SET_FILTER:
      return {
        ...state,
        filters: payload
      };
    case CLEAN_FILTER:
      return {
        ...state,
        filters: {}
      };
    default:
      return state;
  }
};

export const fetchJournals = (
  page: number = 1,
  params: FetchListParams<JournalFilter> = {}
) => async (dispatch: Dispatch, getState: () => AppState): Promise<void> => {
  const { filters, pageSize } = getState().journals;
  const { data, totalCount } = await journalApi.fetchJournals({
    ...filters,
    page,
    pageSize,
    orderBy: 'created',
    byDescending: true,
    ...params
  });
  dispatch({
    type: FETCH_JOURNALS,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export const applyFilters = (filters: JournalFilter) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_FILTER,
    payload: filters
  });
  dispatch(fetchJournals());
};

export const cleanFilters = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch({
    type: CLEAN_FILTER
  });
  dispatch(fetchJournals());
};

export default reducer;
