import {
    MeasuringDeviceCertificationCostCard,
    MeasuringDeviceCertificationCostForm,
    MeasuringDeviceCertificationCostList,
} from '../../containers/MeasuringDeviceCertificationCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MeasuringDeviceCertificationCostList,
        path: '/admin/measuring-device-certification-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MeasuringDeviceCertificationCostCard,
        path: '/admin/measuring-device-certification-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MeasuringDeviceCertificationCostForm,
        path: '/admin/measuring-device-certification-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: MeasuringDeviceCertificationCostForm,
        path: '/admin/measuring-device-certification-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];