// @flow

import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import notification from 'antd/lib/notification';

import type {
  SelfVehiclePlanVehicle,
  VehiclePlanStatus,
} from './../../../../../lib/types';
import { vehiclePlanStatusEnum } from './../../../../../lib/enum';
import { vehiclePlanApi } from './../../../../../lib/api';

import { notificationLoading } from './../../../../../components/Notifications';

import type { VehicleTypeData } from './../../lib';
import InnerTable from './InnerTable';
import InnerTableReadOnly from './InnerTableReadOnly';

type Props = {
  // тс согласно ИК
  data: SelfVehiclePlanVehicle[],
  loading: boolean,
  vehiclePlanType?: VehicleTypeData,
  readonly?: boolean,
  vehiclePlanStatus?: VehiclePlanStatus,
  selectedVehicle?: ?(SelfVehiclePlanVehicle[]),
};
type State = {
  localLoading: boolean,
  localData: SelfVehiclePlanVehicle[],
};

export default class extends Component<Props, State> {
  state = {
    localLoading: false,
    localData: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        localData: this.props.data,
      });
    }
  }

  handleSubmit = async (data: SelfVehiclePlanVehicle[]) => {
    this.setState({ localLoading: true });
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await vehiclePlanApi.updateVehicles(data);
      this.setState({ localData: data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
      this.setState({ localLoading: false });
    }
  };

  render() {
    const {
      data,
      loading,
      vehiclePlanType,
      readonly,
      vehiclePlanStatus,
      selectedVehicle,
    } = this.props;
    const { localLoading, localData } = this.state;
    return readonly ? (
      <InnerTableReadOnly
        data={localData}
        loading={loading || localLoading}
        readonly={[
          vehiclePlanStatusEnum.approvement,
          vehiclePlanStatusEnum.approved,
          vehiclePlanStatusEnum.declined,
        ].includes(vehiclePlanStatus)}
        handleSubmit={this.handleSubmit}
      />
    ) : (
      <>
        <InnerTable
          vehiclePlanType={vehiclePlanType}
          data={data}
          loading={loading}
          selectedVehicle={selectedVehicle}
        />
      </>
    );
  }
}
