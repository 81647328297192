// @flow
import React, { useCallback, useMemo, useState } from 'react';
import Select from 'antd/lib/select';

import { convertVehicleToString } from '../../../../../lib/helpers';
import type { MonitoringVehicle } from '../../../../../lib/types/monitoringVehicle';

const { Option } = Select;

type Props = {
  vehicles: MonitoringVehicle[],
  onChange: (monitoringVehicle: MonitoringVehicle) => void
};

export const VehiclesSelect = ({ vehicles, onChange }: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = useCallback(
    (value: number) => {
      const newValue = (vehicles.find(vehicle => vehicle.id === value): any);
      onChange(newValue);
      setValue(value);
    },
    [onChange, vehicles]
  );

  const renderedOptions = useMemo(() => {
    return vehicles.map(vehicle => {
      return (
        <Option key={vehicle.id} value={vehicle.id}>
          {convertVehicleToString(vehicle.vehicle)}
        </Option>
      );
    });
  }, [vehicles]);

  return (
    <Select
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) => {
        return (
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      placeholder="Гос. номер"
      value={value}
      onChange={handleChange}
    >
      {renderedOptions}
    </Select>
  );
};
