// TODO: Выпилить в дальнейшем это

export const oldVehicleTypes = {
  bus: 'Автобус', // HOZU //zvks
  trailers: 'Прицепы/полуприцепы',
  motorcycles: 'Мототехника',
  specialCar: 'Спец. техника', // HOZU //zvks
  manipulator: 'Манипулятор', //zvks
  jibCrane: 'Стреловой кран',
  carLift: 'Автоподъемник',
};

export const vehicleTypes = {
  ...oldVehicleTypes,
  // Автокран
  avtokranGPDo24T: 'Автокран г/п до 24 т',
  avtokranGPOt25TDo49T: 'Автокран г/п от 25 т до 49 т.',
  avtokranGPSvyshe50T: 'Автокран г/п свыше 50 т.',
  // Автобус пассажирский
  avtobusMalyi1124Mest: 'Автобус (малый) 11-24 мест',
  avtobusOsoboMalyi910Mest: 'Автобус (особо малый) 9-10 мест',
  avtobusSrednyi2530Mest: 'Автобус (средний), 25-30 мест',
  avtobusBolshoiSvyshe30Mest: 'Автобус (большой) свыше 30 мест',
  // Автобус специальный
  avtobusUral6x6: 'Автобус Урал, 6х6',
  avtobusFordTransit4x2: 'Автобус Ford-Transit, 4х2',
  avtobusFordTransit4x4: 'Автобус Ford-Transit, 4х4',
  avtobusPaz4x2: 'Автобус Паз, 4х2',
  avtobusPaz4x4: 'Автобус Паз, 4х4',
  avtobusKamAz4x4: 'Автобус КамАЗ, 4х4',
  avtobusKamAz6x6: 'Автобус КамАЗ, 6х6',
  avtobusGazel4x2: 'Автобус Газель, 4х2',
  avtobusGazel4x4: 'Автобус Газель, 4х4',
  avtobusNefAz4x4: 'Автобус НефАЗ, 4х4',
  avtobusNefAz6x6: 'Автобус НефАЗ, 6х6',
  avtobusUaz4x4: 'Автобус Уаз, 4х4',
  avtobusFiatDucato4x2: 'Автобус Fiat-DUCATO, 4х2',
  avtobusGaz4x4: 'Автобус Газ, 4х4',
  // Автоподъемники
  agpVyletStrelyDo28M: 'АГП, вылет стрелы до 28 м',
  agpVyletStrelySvyshe28M: 'АГП, вылет стрелы свыше 28 м',
  agpRpnVyletStrelyDo28M: 'АГП РПН, вылет стрелы до 28 м',
  agpRpnVyletStrelySvyshe28M: 'АГП РПН, вылет стрелы свыше 28 м',
  // БКМ, БГМ
  bgmNaTraktore: 'БГМ на тракторе',
  bkmNaAvtomobyle: 'БКМ на автомобиле',
  bkmNaTraktore: 'БКМ на тракторе',
  bkmRpnNaAvtomobyle: 'БКМ РПН на автомобиле',
  bkmRpnNaTraktore: 'БКМ РПН на тракторе',
  // Мотовездеходы и болотоходы
  bolotoxod: 'Болотоход',
  kvadrotsykl: 'Квадроцикл',
  snegoxod: 'Снегоход',
  // Грузовой бортовой
  gruzovoiBortovoiKlassaN1Do3Tn: 'Грузовой бортовой класса N1 (до 3 тн)',
  gruzovoiBortovoiKlassaN2Do7Tn: 'Грузовой бортовой класса N2 (до 7 тн)',
  gruzovoiBortovoiKlassaN3Svyshe7Tn: 'Грузовой бортовой класса N3 (свыше 7 тн)',
  gruzovoiBortovoiN2Do7TnSKmu: 'Грузовой бортовой класса N2 (до 7 тн) с КМУ',
  gruzovoiBortovoiN3Svyshe7TnSKmu:
    'Грузовой бортовой класса N3 (свыше 7 тн) с КМУ',
  // Самосвал
  samosvalKlassaN1Do3Tn: 'Самосвал класса N1 (до 3 тн)',
  samosvalKlassaN2Do7Tn: 'Самосвал класса N2 (до 7 тн)',
  samosvalKlassaN3Svyshe7Tn: 'Самосвал класса N3 (свыше 7 тн)',
  // Грузовой прочий
  avtomobylTiagachBezKmu: 'Автомобиль тягач без КМУ',
  avtomobylTiagachSKmu: 'Автомобиль тягач с КМУ ',
  evakuatorDo3Tn: 'Эвакуатор (до 3 тн)',
  evakuatorDo7Tn: 'Эвакуатор  (до 7 тн)',
  evakuatorSvyshe7Tn: 'Эвакуатор (свыше 7 тн)',
  avtomobylDliaPerevozkyOpasnyxGruzov:
    'Автомобиль для перевозки опасных грузов',
  avtomobylKommunalnyi: 'Автомобиль коммунальный',
  // Грузовой фургон
  gruzovoiFurgonKlassaN1Do3Tn: 'Грузовой фургон класса N1 (до 3 тн)',
  gruzovoiFurgonKlassaN2Do7Tn: 'Грузовой фургон класса N2 (до 7 тн)',
  gruzovoiFurgonKlassaN3Svyshe7Tn: 'Грузовой фургон класса N3 (свыше 7 тн)',
  // Грузопассажирские
  uazFermerSGruzovoiPloshchadkoi: 'Уаз-фермер (с грузовой площадкой)',
  uazFurgonTselnometallycheskyi: 'Уаз-фургон (цельнометаллический)',
  gazelFermerSGruzovoiPloshchadkoi: 'Газель-фермер  (с грузовой площадкой)',
  gazelFurgonTselnometallycheskyi: 'Газель-фургон (цельнометаллический)',
  gazFermerSGruzovoiPloshchadkoi: 'Газ-фермер (с грузовой площадкой)',
  gazFurgonTselnometallycheskyi: 'Газ-фургон (цельнометаллический)',
  kamAzFermerSGruzovoiPloshchadkoi: 'КамАЗ-фермер (с грузовой площадкой)',
  kamAzFurgonTselnometallycheskyi: 'КамАЗ-фургон (цельнометаллический)',
  fordTransitFurgonTselnometallycheskyi:
    'Ford Transit-фургон (цельнометаллический)',
  gruzopassazhyrskyiAvtomobylSKmu: 'Грузопассажирский автомобиль с КМУ',
  // Плав-средства
  gydrotsykl: 'Гидроцикл',
  lodkaSMotorom: 'Лодка с мотором',
  lodkaVesla: 'Лодка (весла)',
  // Цистерны
  avtomobylMaslovozVDo5M3: 'Автомобиль масловоз, V до 5 м3',
  avtomobylMaslovozVSvyshe5M3: 'Автомобиль масловоз, V свыше 5 м3',
  avtomobylAssenyzatorVDo5M3: 'Автомобиль  ассенизатор, V до 5 м3',
  avtomobylAssenyzatorVSvyshe5M3: 'Автомобиль  ассенизатор, V свыше 5 м3',
  //Легковые
  car: 'Легковой',
  legkovoiPolnopryvodnyi: 'Легковой полноприводный',
  // Прицепы
  prytsepLegkovoiGPDo750Kg: 'Прицеп легковой (г/п до 750 кг)',
  prytsepAvtomobylnyi: 'Прицеп автомобильный',
  prytsepTraktornyi: 'Прицеп тракторный',
  prytsepSKmu: 'Прицеп с КМУ',
  pPrytsepBezKmu: 'П/прицеп без КМУ',
  pPrytsepSKmu: 'П/прицеп с КМУ',
  prytsepRospusk: 'Прицеп роспуск',
  prytsepDliaDgu: 'Прицеп для ДГУ',
  prytsepKabelnyi: 'Прицеп кабельный',
  prytsepYzmelchytel: 'Прицеп измельчитель',
  prytsepKompressor: 'Прицеп компрессор',
  prytsepTsysterna: 'Прицеп цистерна',
  prytsepGenerator: 'Прицеп генератор',
  // Трактора, Экскаваторы
  traktorTiagach: 'Трактор тягач',
  traktorPum: 'Трактор ПУМ',
  traktorBuldozer: 'Трактор бульдозер',
  ekskavatorVKovshaDo05M3: 'Экскаватор, V - ковша до 0,5 м3',
  ekskavatorVKovshaSvyshe05M3: 'Экскаватор, V - ковша свыше 0,5 м3',
  // АВТОЛАБОРАТОРИИ
  etlNaShassyUaz: 'ЭТЛ на шасси УАЗ',
  etlNaShassyGaz4x4: 'ЭТЛ на шасси Газ, 4х4',
  etlNaShassyGaz4x2: 'ЭТЛ на шасси Газ, 4х2',
  etlNaShassyGazel4x4: 'ЭТЛ на шасси Газель,4х4',
  etlNaShassyGazel4x2: 'ЭТЛ на шасси Газель,4х2',
  etlNaShassyKamAz6x6: 'ЭТЛ на шасси КамАЗ, 6х6',
  etlNaShassyKamAz4x4: 'ЭТЛ на шасси КамАЗ, 4х4',
  etlNaShassyFordTransit4x4: 'ЭТЛ на шасси Ford-Transit, 4х4',
  etlNaShassyFordTransit4x2: 'ЭТЛ на шасси Ford-Transit, 4х2',
  undefined: 'Не определено',
  createdByFirstBit: 'Создано Энерготранс/КТС',
  // Грузовой
  cargo: 'Грузовой', // HOZU //zvks
  tractor: 'Трактор', // HOZU //zvkx

  emergency: 'Аварийная', //zvks
  cargoPassenger: 'Грузопассажирский', //zvks
  onboard: 'Бортовой', //zvks
  tiagach: 'Тягач', //zvks
  vacuum: 'Ваккуумная', //zvks
  ilosos: 'Илосос', //zvks
  hydromachine: 'Гидромашина', //zvks
  truckCrane: 'Автокран', //zvks
  compressor: 'Компрессор', //zvks
  weldingMachine: 'Сварочный аппарат', //zvks
  dieselGenerator: 'Дизельный генератор', //zvks
  excavator: 'Экскаватор', //zvks
  canalWashing: 'Каналопромыв', //zvks
  semitrailer: 'Полуприцеп', //zvks
  van: 'Фургон', //zvks
  dumpTruck: 'Самосвал', //zvks
};

export const oldVehicleTypeEnum = {
  bus: 'bus', // HOZU
  trailers: 'trailers',
  motorcycles: 'motorcycles',
  specialCar: 'specialCar', // HOZU
  manipulator: 'manipulator',
  jibCrane: 'jibCrane',
  carLift: 'carLift',
};

export const vehicleTypeEnum = {
  ...oldVehicleTypeEnum,
  // Автокран
  avtokranGPDo24T: 'avtokranGPDo24T',
  avtokranGPOt25TDo49T: 'avtokranGPOt25TDo49T',
  avtokranGPSvyshe50T: 'avtokranGPSvyshe50T',
  // Автобус пассажирский
  avtobusMalyi1124Mest: 'avtobusMalyi1124Mest',
  avtobusOsoboMalyi910Mest: 'avtobusOsoboMalyi910Mest',
  avtobusSrednyi2530Mest: 'avtobusSrednyi2530Mest',
  avtobusBolshoiSvyshe30Mest: 'avtobusBolshoiSvyshe30Mest',
  // Автобус специальный
  avtobusUral6x6: 'avtobusUral6x6',
  avtobusFordTransit4x2: 'avtobusFordTransit4x2',
  avtobusFordTransit4x4: 'avtobusFordTransit4x4',
  avtobusPaz4x2: 'avtobusPaz4x2',
  avtobusPaz4x4: 'avtobusPaz4x4',
  avtobusKamAz4x4: 'avtobusKamAz4x4',
  avtobusKamAz6x6: 'avtobusKamAz6x6',
  avtobusGazel4x2: 'avtobusGazel4x2',
  avtobusGazel4x4: 'avtobusGazel4x4',
  avtobusNefAz4x4: 'avtobusNefAz4x4',
  avtobusNefAz6x6: 'avtobusNefAz6x6',
  avtobusUaz4x4: 'avtobusUaz4x4',
  avtobusFiatDucato4x2: 'avtobusFiatDucato4x2',
  avtobusGaz4x4: 'avtobusGaz4x4',
  // Автоподъемники
  agpVyletStrelyDo28M: 'agpVyletStrelyDo28M',
  agpVyletStrelySvyshe28M: 'agpVyletStrelySvyshe28M',
  agpRpnVyletStrelyDo28M: 'agpRpnVyletStrelyDo28M',
  agpRpnVyletStrelySvyshe28M: 'agpRpnVyletStrelySvyshe28M',
  // БКМ, БГМ
  bgmNaTraktore: 'bgmNaTraktore',
  bkmNaAvtomobyle: 'bkmNaAvtomobyle',
  bkmNaTraktore: 'bkmNaTraktore',
  bkmRpnNaAvtomobyle: 'bkmRpnNaAvtomobyle',
  bkmRpnNaTraktore: 'bkmRpnNaTraktore',
  // Мотовездеходы и болотоходы
  bolotoxod: 'bolotoxod',
  kvadrotsykl: 'kvadrotsykl',
  snegoxod: 'snegoxod',
  // Грузовой бортовой
  gruzovoiBortovoiKlassaN1Do3Tn: 'gruzovoiBortovoiKlassaN1Do3Tn',
  gruzovoiBortovoiKlassaN2Do7Tn: 'gruzovoiBortovoiKlassaN2Do7Tn',
  gruzovoiBortovoiKlassaN3Svyshe7Tn: 'gruzovoiBortovoiKlassaN3Svyshe7Tn',
  gruzovoiBortovoiN2Do7TnSKmu: 'gruzovoiBortovoiN2Do7TnSKmu',
  gruzovoiBortovoiN3Svyshe7TnSKmu: 'gruzovoiBortovoiN3Svyshe7TnSKmu',
  // Самосвал
  samosvalKlassaN1Do3Tn: 'samosvalKlassaN1Do3Tn',
  samosvalKlassaN2Do7Tn: 'samosvalKlassaN2Do7Tn',
  samosvalKlassaN3Svyshe7Tn: 'samosvalKlassaN3Svyshe7Tn',
  // Грузовой прочий
  avtomobylTiagachBezKmu: 'avtomobylTiagachBezKmu',
  avtomobylTiagachSKmu: 'avtomobylTiagachSKmu',
  evakuatorDo3Tn: 'evakuatorDo3Tn',
  evakuatorDo7Tn: 'evakuatorDo7Tn',
  evakuatorSvyshe7Tn: 'evakuatorSvyshe7Tn',
  avtomobylDliaPerevozkyOpasnyxGruzov: 'avtomobylDliaPerevozkyOpasnyxGruzov',
  avtomobylKommunalnyi: 'avtomobylKommunalnyi',
  // Грузовой фургон
  gruzovoiFurgonKlassaN1Do3Tn: 'gruzovoiFurgonKlassaN1Do3Tn',
  gruzovoiFurgonKlassaN2Do7Tn: 'gruzovoiFurgonKlassaN2Do7Tn',
  gruzovoiFurgonKlassaN3Svyshe7Tn: 'gruzovoiFurgonKlassaN3Svyshe7Tn',
  // Грузопассажирские
  uazFermerSGruzovoiPloshchadkoi: 'uazFermerSGruzovoiPloshchadkoi',
  uazFurgonTselnometallycheskyi: 'uazFurgonTselnometallycheskyi',
  gazelFermerSGruzovoiPloshchadkoi: 'gazelFermerSGruzovoiPloshchadkoi',
  gazelFurgonTselnometallycheskyi: 'gazelFurgonTselnometallycheskyi',
  gazFermerSGruzovoiPloshchadkoi: 'gazFermerSGruzovoiPloshchadkoi',
  gazFurgonTselnometallycheskyi: 'gazFurgonTselnometallycheskyi',
  kamAzFermerSGruzovoiPloshchadkoi: 'kamAzFermerSGruzovoiPloshchadkoi',
  kamAzFurgonTselnometallycheskyi: 'kamAzFurgonTselnometallycheskyi',
  fordTransitFurgonTselnometallycheskyi:
    'fordTransitFurgonTselnometallycheskyi',
  gruzopassazhyrskyiAvtomobylSKmu: 'gruzopassazhyrskyiAvtomobylSKmu',
  // Плав-средства
  gydrotsykl: 'gydrotsykl',
  lodkaSMotorom: 'lodkaSMotorom',
  lodkaVesla: 'lodkaVesla',
  // Цистерны
  avtomobylMaslovozVDo5M3: 'avtomobylMaslovozVDo5M3',
  avtomobylMaslovozVSvyshe5M3: 'avtomobylMaslovozVSvyshe5M3',
  avtomobylAssenyzatorVDo5M3: 'avtomobylAssenyzatorVDo5M3',
  avtomobylAssenyzatorVSvyshe5M3: 'avtomobylAssenyzatorVSvyshe5M3',
  //Легковые
  car: 'car', // HOZU
  legkovoiPolnopryvodnyi: 'legkovoiPolnopryvodnyi',
  // Прицепы
  prytsepLegkovoiGPDo750Kg: 'prytsepLegkovoiGPDo750Kg',
  prytsepAvtomobylnyi: 'prytsepAvtomobylnyi',
  prytsepTraktornyi: 'prytsepTraktornyi',
  prytsepSKmu: 'prytsepSKmu',
  pPrytsepBezKmu: 'pPrytsepBezKmu',
  pPrytsepSKmu: 'pPrytsepSKmu',
  prytsepRospusk: 'prytsepRospusk',
  prytsepDliaDgu: 'prytsepDliaDgu',
  prytsepKabelnyi: 'prytsepKabelnyi',
  prytsepYzmelchytel: 'prytsepYzmelchytel',
  prytsepKompressor: 'prytsepKompressor',
  prytsepTsysterna: 'prytsepTsysterna',
  prytsepGenerator: 'prytsepGenerator',
  // Трактора, Экскаваторы
  traktorTiagach: 'traktorTiagach',
  traktorPum: 'traktorPum',
  traktorBuldozer: 'traktorBuldozer',
  ekskavatorVKovshaDo05M3: 'ekskavatorVKovshaDo05M3',
  ekskavatorVKovshaSvyshe05M3: 'ekskavatorVKovshaSvyshe05M3',
  // АВТОЛАБОРАТОРИИ
  etlNaShassyUaz: 'etlNaShassyUaz',
  etlNaShassyGaz4x4: 'etlNaShassyGaz4x4',
  etlNaShassyGaz4x2: 'etlNaShassyGaz4x2',
  etlNaShassyGazel4x4: 'etlNaShassyGazel4x4',
  etlNaShassyGazel4x2: 'etlNaShassyGazel4x2',
  etlNaShassyKamAz6x6: 'etlNaShassyKamAz6x6',
  etlNaShassyKamAz4x4: 'etlNaShassyKamAz4x4',
  etlNaShassyFordTransit4x4: 'etlNaShassyFordTransit4x4',
  etlNaShassyFordTransit4x2: 'etlNaShassyFordTransit4x2',
  //
  cargo: 'cargo', // HOZU
  tractor: 'tractor', // HOZU

  emergency: 'emergency', //zvks
  cargoPassenger: 'cargoPassenger', //zvks
  onboard: 'onboard', //zvks
  tiagach: 'tiagach', //zvks
  vacuum: 'vacuum', //zvks
  ilosos: 'ilosos', //zvks
  hydromachine: 'hydromachine', //zvks
  truckCrane: 'truckCrane', //zvks
  compressor: 'compressor', //zvks
  weldingMachine: 'weldingMachine', //zvks
  dieselGenerator: 'dieselGenerator', //zvks
  excavator: 'excavator', //zvks
  canalWashing: 'canalWashing', //zvks
  semitrailer: 'semitrailer', //zvks
  van: 'van', //zvks
  dumpTruck: 'dumpTruck', //zvks
};

/**
 * Список типов ТС, на которых работают люлечники
 */
export const lulechnikVehicleTypes = [
  vehicleTypeEnum.agpVyletStrelyDo28M,
  vehicleTypeEnum.agpVyletStrelySvyshe28M,
  vehicleTypeEnum.agpRpnVyletStrelyDo28M,
  vehicleTypeEnum.agpRpnVyletStrelySvyshe28M,
  vehicleTypeEnum.truckCrane,
];

/**
 * Список типов ТС, на которых работают стропальщики
 */
export const riggerVehicleTypes = [
  vehicleTypeEnum.avtokranGPDo24T,
  vehicleTypeEnum.avtokranGPOt25TDo49T,
  vehicleTypeEnum.avtokranGPSvyshe50T,
  vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu,
  vehicleTypeEnum.avtomobylTiagachSKmu,
  vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
  vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu,
  vehicleTypeEnum.bkmRpnNaAvtomobyle,
  vehicleTypeEnum.bkmRpnNaTraktore,
];

/**
 * Список типов ТС бкм, бгм которые считать как ГПМ
 */
export const bkmBgmIsGPM = [
  vehicleTypeEnum.bkmRpnNaAvtomobyle,
  vehicleTypeEnum.bkmRpnNaTraktore,
];

/**
 * Список ГПМ типов ТС
 */
export const gpmVehicleTypes = [
  ...lulechnikVehicleTypes,
  ...riggerVehicleTypes,
  ...bkmBgmIsGPM,
];

/**
 * Список типов ТС, которые являются прицепами
 */
export const trailerVehicleTypes = [
  vehicleTypeEnum.prytsepLegkovoiGPDo750Kg,
  vehicleTypeEnum.prytsepAvtomobylnyi,
  vehicleTypeEnum.prytsepTraktornyi,
  vehicleTypeEnum.prytsepSKmu,
  vehicleTypeEnum.pPrytsepBezKmu,
  vehicleTypeEnum.pPrytsepSKmu,
  vehicleTypeEnum.prytsepRospusk,
  vehicleTypeEnum.prytsepDliaDgu,
  vehicleTypeEnum.prytsepKabelnyi,
  vehicleTypeEnum.prytsepYzmelchytel,
  vehicleTypeEnum.prytsepKompressor,
  vehicleTypeEnum.prytsepTsysterna,
  vehicleTypeEnum.prytsepGenerator,
];

/**
 * Виды ТС
 */
export const vehicleGroups = {
  // old enums для совместимости, чтобы не выпиливать из всего проекта
  cars: 'Легковые',
  specialBuses: 'Автобусы специальные',
  passengerBuses: 'Автобусы пассажирские',
  truckCranes: 'Автокраны',
  bkmBgm: 'БКМ, БГМ',
  carLifts: 'Автоподъемники',
  trailers: 'Прицепы',
  utilityVehicles: 'Грузопассажирские а/м',
  autolabs: 'Автолаборатории',
  tractorsExcavators: 'Трактора, экскаваторы',
  atvs: 'Мотовездеходы и болотоходы',
  floatingTransport: 'Плав-средства',
  tippers: 'Самосвалы',
  flatbedTrucks: 'Грузовые бортовые а/м',
  cargoVans: 'Грузовые фургоны',
  otherTrucks: 'Прочие грузовые а/м',
  tankTrucks: 'Цистерны',

  //HOZU Enum
  carGroup: 'Легковой',
  busGroup: 'Автобусы',
  cargoGroup: 'Грузовой',
  specialCarGroup: 'Спецтехника',
  trailerGroup: 'Прицепы', //zvks
};

export const vehicleGroupEnum = {
  // old enums для совместимости, чтобы не выпиливать из всего проекта
  cars: 'cars',
  specialBuses: 'specialBuses',
  passengerBuses: 'passengerBuses',
  truckCranes: 'truckCranes',
  bkmBgm: 'bkmBgm',
  carLifts: 'carLifts',
  trailers: 'trailers',
  utilityVehicles: 'utilityVehicles',
  autolabs: 'autolabs',
  tractorsExcavators: 'tractorsExcavators',
  atvs: 'atvs',
  floatingTransport: 'floatingTransport',
  tippers: 'tippers',
  flatbedTrucks: 'flatbedTrucks',
  cargoVans: 'cargoVans',
  otherTrucks: 'otherTrucks',
  tankTrucks: 'tankTrucks',

  //HOZU Enum, суффикс group необходим чтобы ти и название группы не сопадали
  carGroup: 'carGroup', //zvks
  busGroup: 'busGroup', //zvks
  cargoGroup: 'cargoGroup', //zvks
  specialCarGroup: 'specialCarGroup', //zvks
  trailerGroup: 'trailerGroup', //zvks
};

/**
 * Список типов ТС автокранов
 */
export const avtokranTypes = [
  vehicleTypeEnum.avtokranGPDo24T,
  vehicleTypeEnum.avtokranGPOt25TDo49T,
  vehicleGroupEnum.avtokranGPSvyshe50T,
];

/**
 * Список типов ТС и групп, на которых работают специалист
 * ответственный за безопасное производство работ
 * с применением подъемников (вышек)
 */
export const safetyCarLiftTypes = [
  vehicleTypeEnum.agpVyletStrelyDo28M,
  vehicleTypeEnum.agpVyletStrelySvyshe28M,
  vehicleTypeEnum.agpRpnVyletStrelyDo28M,
  vehicleTypeEnum.agpRpnVyletStrelySvyshe28M,
];

/**
 * Список типов ТС и групп, на которых работают
 * специалист, ответственный за безопасное производство
 * работ с применением автокрана (крана-манипулятора)
 */
export const safetyTechnicianTypes = [
  vehicleTypeEnum.avtokranGPDo24T,
  vehicleTypeEnum.avtokranGPOt25TDo49T,
  vehicleTypeEnum.avtokranGPSvyshe50T,
  vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
  vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu,
  vehicleTypeEnum.avtomobylTiagachSKmu,
  vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu,
];
