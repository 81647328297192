// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { RangePicker } from '../../../components/ant/DatePicker';

import { Filter, FilterButtonsContainer, FilterContainer, FilterItem } from './../../../components';
import Field from './../../../components/card/Field';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import OrgUnitSelect from './../../../components/OrgUnitSelect';

import { formatDateTimeToISOString } from './../../../lib/helpers';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type MaintenanceOperationPriceFilterParams = {
  orgUnitId?: number,
  operationId?: number,
  startDate?: string,
  endDate?: string
};

type Props = {
  filter: MaintenanceOperationPriceFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const MaintenanceOperationPriceFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<MaintenanceOperationPriceFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  placeholder="Выберите филиал"
                  onlyBranches
                  size="small"
                  onChange={value => changeValue('orgUnitId', value)}
                  value={values.orgUnitId}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <RangePicker
                format="DD.MM.YYYY"
                size="small"
                placeholder={['Начало', 'Конец']}
                value={[
                  values.startDate ? moment.utc(values.startDate) : null,
                  values.endDate ? moment.utc(values.endDate) : null
                ]}
                onChange={(value, dateString) => {
                  const [startDate, endDate] = value;
                  const [startDateString, endDateString] = dateString;
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(startDate, startDateString)
                  );
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(endDate, endDateString)
                  );
                }}
              />
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MaintenanceOperationPriceFilter;
