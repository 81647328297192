// @flow

import { navigate } from '@reach/router';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import { DatePicker } from '../../components/ant/DatePicker';
import CancelButton from '../../components/CancelButton';
import type { FormFieldType } from '../../components/Form';
import { SectionTitle } from '../../components/layout';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import { PhoneInput } from '../../components/inputs/masked-inputs';
import JobTitleSelect from '../../components/selects/JobTitleSelect';
import type { AppState } from '../../ducks/redux';
import { positionEnum, positions } from '../../lib/enum';
import { formatDateTimeToISOString, goBack } from '../../lib/helpers';
import { emailValidate } from '../../lib/validation';
import { Form, OrgUnitSelect, Selects } from './../../components';
import { Panel, Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import { notificationLoading } from './../../components/Notifications';
import { addEmployee, cleanEmployee, fetchEmployee, updateEmployee } from './../../ducks/employee';

import type { Employee } from './../../lib/types';

const { PositionSelect, CertificationAreaSelect } = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const StyledSectionTitle = styled(SectionTitle)`
  .section__header {
    padding-left: 0;
  }
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  employee: Employee,
  onValidationError: Function
};

const InnerForm = ({
  onSubmit,
  onCancel,
  employee,
  onValidationError
}: FormProps) => (
  <Form
    onSubmit={onSubmit}
    initialValues={employee}
    validationSchema={yup.object().shape({
      email: emailValidate.nullable()
    })}
  >
    {(FormField: FormFieldType, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        values,
        dirty,
        isSubmitting,
        isValid
      } = formikProps;
      const { positions: pos = [] } = values;
      const isRigger = pos.includes(positionEnum.rigger);
      const isSafetyTechnician = pos.includes(positionEnum.safetyTechnician);
      const isLulechnik = pos.includes(positionEnum.lulechnik);
      const isSafetyCarLift = pos.includes(positionEnum.safetyCarLift);
      const isControllerGpm = pos.includes(positionEnum.gpmController);
      const isCarLiftOperator = pos.includes(positionEnum.carLiftOperator);
      const isManipulatorCranesOperator = pos.includes(
        positionEnum.manipulatorCranesOperator
      );
      const isTruckCranesMachinist = pos.includes(
        positionEnum.truckCranesMachinist
      );
      return (
        <form
          onSubmit={e => {
            if (onValidationError !== undefined && !isValid)
              onValidationError();

            handleSubmit(e);
          }}
        >
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Фамилия" required fast name="lastname">
                    {field => (
                      <Input {...field} placeholder="Введите фамилию" />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Имя" required fast name="firstname">
                    {field => <Input {...field} placeholder="Введите имя" />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Отчество" required fast name="middlename">
                    {field => (
                      <Input {...field} placeholder="Введите отчество" />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Подразделение"
                    required
                    fast
                    name="orgUnitId"
                  >
                    {({ value, name }) => (
                      <OrgUnitSelect
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        onBlur={() => handleBlur({ target: { name } })}
                        data-cy="orgUnitSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Обязанности" required fast name="positions">
                    {({ value = [], name }) => (
                      <PositionSelect
                        mode="multiple"
                        onChange={(value: any) =>
                          setFieldValue(name, value.map(value => value.key))
                        }
                        onBlur={() => handleBlur({ target: { name } })}
                        value={value.map(key => ({
                          key,
                          label: positions[key]
                        }))}
                        labelInValue
                        data-cy="positionSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="positionTitle"
                    fast
                    label="Название должности"
                  >
                    {field => (
                      <Input
                        {...field}
                        placeholder="Введите название должности"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="jobTitleId"
                    fast
                    label="Должность для лимитов"
                  >
                    {({ value, name }) => (
                      <JobTitleSelect
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Эл. почта" fast name="email">
                    {field => (
                      <Input {...field} placeholder="example@email.com" />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Номер телефона" fast name="phoneNumber">
                    {field => (
                      <PhoneInput
                        {...field}
                        placeholder="Введите номер телефона"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="personNumber" fast label="Табельный номер">
                    {field => (
                      <Input {...field} placeholder="Введите табельный номер" />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
              {isRigger && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы стропальщика
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="riggerDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="riggerDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="riggerDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="riggerDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      required
                      fast
                      name="riggerDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}
              {isLulechnik && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы рабочего люльки
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="lulechnikDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="lulechnikDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="lulechnikDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="lulechnikDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      required
                      fast
                      name="lulechnikDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}
              {isSafetyTechnician && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы специалиста ответственный за безопасное
                      производство работ с применением автокрана
                      (крана-манипулятора)
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="safetyTechnicianDocument.documentNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № протокола" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="safetyTechnicianDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="safetyTechnicianDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="safetyTechnicianDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      required
                      fast
                      name="safetyTechnicianDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Область аттестации"
                      fast
                      required
                      name="safetyTechnicianDocument.certificationAreas"
                    >
                      {({ value, name }) => (
                        <CertificationAreaSelect
                          mode="multiple"
                          onChange={(value: any) => setFieldValue(name, value)}
                          onBlur={() => handleBlur({ target: { name } })}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ протокола проверки знаний"
                      fast
                      required
                      name="safetyTechnicianDocument.protocolNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № протокола проверки знаний"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}

              {isSafetyCarLift && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы специалиста ответственный за безопасное
                      производство работ с применением подъемников (вышек)
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="safetyCarLiftDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="safetyCarLiftDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="safetyCarLiftDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="safetyCarLiftDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      fast
                      required
                      name="safetyCarLiftDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Область аттестации"
                      fast
                      required
                      name="safetyCarLiftDocument.certificationAreas"
                    >
                      {({ value, name }) => (
                        <CertificationAreaSelect
                          mode="multiple"
                          onChange={(value: any) =>
                            setFieldValue(
                              name,
                              isEmpty(value) ? undefined : value
                            )
                          }
                          onBlur={() => handleBlur({ target: { name } })}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ протокола проверки знаний"
                      fast
                      required
                      name="safetyCarLiftDocument.protocolNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № протокола проверки знаний"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}

              {isControllerGpm && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы специалиста ответственного за содержание ПС в
                      работоспособном состоянии
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="controllerGpmDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="controllerGpmDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="controllerGpmDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="controllerGpmDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      fast
                      required
                      name="controllerGpmDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Область аттестации"
                      fast
                      required
                      name="controllerGpmDocument.certificationAreas"
                    >
                      {({ value, name }) => (
                        <CertificationAreaSelect
                          mode="multiple"
                          onChange={(value: any) =>
                            setFieldValue(
                              name,
                              isEmpty(value) ? undefined : value
                            )
                          }
                          onBlur={() => handleBlur({ target: { name } })}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ протокола проверки знаний"
                      fast
                      required
                      name="controllerGpmDocument.protocolNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № протокола проверки знаний"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}

              {isCarLiftOperator && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы машиниста подъемника (вышки)
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="сarLiftOperatorDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="сarLiftOperatorDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="сarLiftOperatorDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="сarLiftOperatorDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      fast
                      required
                      name="сarLiftOperatorDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ протокола проверки знаний"
                      fast
                      required
                      name="сarLiftOperatorDocument.protocolNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № протокола проверки знаний"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}

              {isManipulatorCranesOperator && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы оператора(машинист) крана-манипулятора
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="manipulatorCranesOperatorDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="manipulatorCranesOperatorDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="manipulatorCranesOperatorDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="manipulatorCranesOperatorDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      fast
                      required
                      name="manipulatorCranesOperatorDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ протокола проверки знаний"
                      fast
                      required
                      name="manipulatorCranesOperatorDocument.protocolNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № протокола проверки знаний"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}

              {isTruckCranesMachinist && (
                <Grid gutter="16px" cols={3}>
                  <GridItem fullWidth>
                    <StyledSectionTitle>
                      Документы машиниста крана(крановщик)
                    </StyledSectionTitle>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ удостоверения"
                      fast
                      required
                      name="truckCranesMachinistDocument.documentNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № удостоверения"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата проверки знаний"
                      fast
                      name="truckCranesMachinistDocument.protocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ приказа о назначении"
                      fast
                      required
                      name="truckCranesMachinistDocument.orderNumber"
                    >
                      {field => (
                        <Input {...field} placeholder="Введите № приказа" />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      label="Дата приказа о назначении"
                      fast
                      name="truckCranesMachinistDocument.orderDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата следующей проверки знаний"
                      fast
                      required
                      name="truckCranesMachinistDocument.nextProtocolDate"
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="№ протокола проверки знаний"
                      fast
                      required
                      name="truckCranesMachinistDocument.protocolNumber"
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите № протокола проверки знаний"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  employee: Employee,
  fetchEmployee: Function,
  cleanEmployee: Function,
  updateEmployee: Function,
  addEmployee: Function,
  employeeId: ?number
};

class EmployeeForm extends Component<Props> {
  async componentDidMount() {
    const { employeeId } = this.props;
    this.props.cleanEmployee();
    if (employeeId) {
      try {
        await this.props.fetchEmployee(employeeId);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  render() {
    const { employee } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/employees">Сотрудники</Crumb>
              {employee ? (
                <Crumb to={`/admin/employees/${employee.id}`}>
                  Сотрудник №{employee.id}
                </Crumb>
              ) : (
                <Crumb to="/admin/employee/new">Новое подразделение</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{employee ? `Сотрудник №${employee.id}` : 'Новый сотрудник'}</h1>
        </StyledPanel>
        <InnerForm
          employee={employee}
          onCancel={() => goBack('/admin/employees')}
          onSubmit={async values => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await this.props.updateEmployee(values);
                goBack('/admin/employees');
              } else {
                await this.props.addEmployee(values);
                navigate('/admin/employees');
              }
              notification.success({
                message: 'Успешно сохранено',
                description: 'Изменения успешно сохранены'
              });
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
          onValidationError={() => {
            notification.error({
              message: 'Ошибка',
              description: 'Заполните все поля'
            });
          }}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    employee: state.employee,
    employeeId: parseInt(props.employeeId, 10)
  }),
  {
    fetchEmployee,
    cleanEmployee,
    updateEmployee,
    addEmployee
  }
)(EmployeeForm);
