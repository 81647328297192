// @flow

import type { ScheduleMaintenance } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams } from './index';

export const fetchScheduleMaintenance = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ScheduleMaintenance[]> => {
  const scheduleMaintenance = await fetchRequest.get(
    '/report/scheduleMaintenance',
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (scheduleMaintenance) return scheduleMaintenance;
  throw new Error('Не удалось получить отчет по графику ТО');
};

export const printScheduleMaintenance = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/scheduleMaintenance/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default { fetchScheduleMaintenance, printScheduleMaintenance };
