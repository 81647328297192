// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { budgetSummaryApi } from './../../lib/api';
import type { BudgetSummary } from '../../lib/types';
import { budgetSummaryInfoString } from '../../lib/helpers';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any };

export default ({ filter = {}, ...selectProps }: Props) => {
  return (
    <AutocompleteSelect
      fetch={async params => {
        return await budgetSummaryApi.fetch({
          ...params,
          ...filter
        });
      }}
      refetchParams={{ filter }}
      fetchSingle={budgetSummaryApi.get}
      notFoundText="Бюджеты не найдены"
      placeholder="Выберите сводный бюджет"
      renderOption={(budgetSummary: BudgetSummary, Option) => (
        <Option
          key={budgetSummary.id}
          value={budgetSummary.id}
          vehiclePlan={budgetSummary}
        >
          {budgetSummaryInfoString(budgetSummary)}
        </Option>
      )}
      {...selectProps}
    />
  );
};
