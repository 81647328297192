// @flow
import React from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';

import type { Pass, UserAccess } from '../../../lib/types';
import { passApi } from '../../../lib/api';
import {
  convertVehicleToString,
  formatDateTimeToString,
  formatDateRangeString
} from '../../../lib/helpers';

import { Header, SectionTitle } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';

import type { AppState } from '../../../ducks/redux';

import { Icon } from '../../../components/ui';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, { GridItem } from '../../../components/layout/Grid';

import { ActInfo } from './../../Act';

import { canHandleService } from '../lib';
import { Panel, SectionContent } from '../elements';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  pass: Pass
};

class PassCard extends React.Component<Props, State> {
  state = {
    pass: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const pass = await passApi.fetchPass(parseInt(id, 10));
      this.setState({ pass });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { pass } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={'/services/pass'}>Список пропусков</Crumb>
              <Crumb>Пропуск {pass.number}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() => navigate(`/services/pass/${id}/edit`)}
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Пропуск {pass.number}</h1>
        </Panel>
        <Section>
          <SectionTitle divider>Данные ТС</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              {pass.vehicle !== null ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(pass.vehicle)}
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Данные пропуска</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование пропуска">{pass.name}</Field>
              </GridItem>
              <GridItem>
                <Field label="Номер пропуска">{pass.number}</Field>
              </GridItem>
              <GridItem>
                <Field label="Дата выдачи">
                  {formatDateTimeToString(pass.maintenanceDate, 'DD.MM.YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Период действия">
                  {formatDateRangeString(
                    pass.startDate,
                    pass.endDate,
                    'D MMMM YYYY'
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Стоимость пропуска, руб.">
                  {pass.paymentAmountPass || 0}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Стоимость госпошлины, руб.">
                  {pass.paymentAmountTax || 0}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Стоимость итого, руб.">
                  {pass.paymentAmount || 0}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {pass && pass.act && <ActInfo act={pass.act} />}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(PassCard);
