import {
    WorkingHourSelfPriceCard,
    WorkingHourSelfPriceForm,
    WorkingHourSelfPriceList,
} from '../../containers/WorkingHourSelfPrice';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: WorkingHourSelfPriceList,
        path: '/admin/working-hour-self-price',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WorkingHourSelfPriceCard,
        path: '/admin/working-hour-self-price/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WorkingHourSelfPriceForm,
        path: '/admin/working-hour-self-price/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WorkingHourSelfPriceForm,
        path: '/admin/working-hour-self-price/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];