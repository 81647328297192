// @flow

import React, { Component } from 'react';
import styled from 'styled-components';

import { Section, SectionTitle } from './../../../../../../components/layout';
import RegulationLimitGroup from './RegulationLimitGroup';
import Button from 'antd/lib/button';
import type {
  EntityStatusType,
  Regulation,
  UserAccess
} from '../../../../../../lib/types';
import { canApprove, canHandling, canSendToAgreeing } from '../../lib';
import {
  entityStatus,
  entityStatusEnum,
  operationLimitGroupStatusEnum,
  regulationLimitTypeEnum
} from '../../../../../../lib/enum';
import { notificationLoading } from '../../../../../../components/Notifications';
import notification from 'antd/lib/notification';
import { withUserAccess } from '../../../../../withUserAccess';
import { StatusButton } from './components/elements';

const Operations = styled.div`
  display: inline-flex;
  align-items: center;
  & > * {
    margin-right: 16px;
  }
  & > *:last-child {
    margin-right: 0;
  }
`;

const SectionContent = styled.div`
  padding: 16px;
`;

type Props = {
  userAccess: UserAccess[],
  readonly: boolean,
  changeStatus: Function
} & Regulation;

type State = {
  isSending: boolean,
  groupsStatus: Object,
  allowChangeStatus: boolean
};

class TimeLimits extends Component<Props, State> {
  state = {
    isSending: false,
    groupsStatus: {},
    allowChangeStatus: false
  };

  changeStatus = async (newStatus: EntityStatusType) => {
    try {
      this.setState({ isSending: true });
      notificationLoading({
        message: `Смена статуса(${entityStatus[this.props.status]} -> ${
          entityStatus[newStatus]
        })`,
        key: 'saving'
      });
      this.props.changeStatus && (await this.props.changeStatus(newStatus));

      notification.success({
        message: 'Статус успешно сменен'
      });
    } catch (e) {
      notification.error({
        message: `Произошла ошибка при смене статуса(${
          entityStatus[this.props.status]
        } -> ${entityStatus[newStatus]})`
      });
    } finally {
      this.setState({ isSending: false });
      notification.close('saving');
    }
  };

  regLimitStatusChange = ({ type, status }, initUpdate: boolean = false) => {
    this.setState(
      {
        groupsStatus: {
          ...this.state.groupsStatus,
          [regulationLimitTypeEnum[type]]: status
        }
      },
      () => {
        this.setState({
          allowChangeStatus:
            Object.keys(this.state.groupsStatus).length === 4 &&
            Object.values(this.state.groupsStatus).reduce(
              (s, el) => s && el === operationLimitGroupStatusEnum.approved,
              true
            )
        });

        if (!initUpdate) {
          this.props.changeStatus && this.props.changeStatus(this.props.status);
        }
      }
    );
  };

  render() {
    const { isSending, allowChangeStatus } = this.state;
    const { readonly, userAccess } = this.props;
    const regulation: Regulation = this.props;
    return (
      <>
        <Section>
          <SectionTitle divider>Должностные лица</SectionTitle>
          <SectionContent>
            <RegulationLimitGroup
              readonly={readonly}
              type="employee"
              regulation={regulation}
              statusChangeCallback={this.regLimitStatusChange}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>ОВБ</SectionTitle>
          <SectionContent>
            <RegulationLimitGroup
              readonly={readonly}
              type="ovb"
              regulation={regulation}
              statusChangeCallback={this.regLimitStatusChange}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Службы</SectionTitle>
          <SectionContent>
            <RegulationLimitGroup
              readonly={readonly}
              type="orgUnit"
              regulation={regulation}
              statusChangeCallback={this.regLimitStatusChange}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Разовые заявки</SectionTitle>
          <SectionContent>
            <RegulationLimitGroup
              readonly={readonly}
              type="oneOffRequests"
              regulation={regulation}
              statusChangeCallback={this.regLimitStatusChange}
            />
          </SectionContent>
        </Section>
        {!readonly && regulation.status !== entityStatusEnum.approved && (
          <Section>
            <SectionContent>
              <Operations>
                {canHandling(userAccess) &&
                  canApprove(userAccess, regulation.status) &&
                  allowChangeStatus && (
                    <>
                      <Button
                        disabled={isSending}
                        loading={isSending}
                        dirty={false}
                        onClick={() =>
                          this.changeStatus(entityStatusEnum.declined)
                        }
                      >
                        На редактирование
                      </Button>
                      <Button
                        disabled={isSending}
                        loading={isSending}
                        type="primary"
                        htmlType="submit"
                        data-cy="save"
                        onClick={() =>
                          this.changeStatus(entityStatusEnum.approved)
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                {canHandling(userAccess) &&
                  canSendToAgreeing(userAccess, regulation.status) && (
                    <StatusButton
                      withWarn={!this.state.allowChangeStatus}
                      onClick={() =>
                        this.changeStatus(entityStatusEnum.approvement)
                      }
                      loading={isSending}
                      htmlType="submit"
                      data-cy="save"
                      content="Необходимо утвердить все регламенты"
                      size=""
                    >
                      На утверждение
                    </StatusButton>
                  )}
              </Operations>
            </SectionContent>
          </Section>
        )}
      </>
    );
  }
}

export default withUserAccess(TimeLimits);
