// @flow

import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { DatePicker } from '../ant/DatePicker';
import Popover from '../ui/Popover';
import { formatDateTimeToISOString } from './../../lib/helpers';

import TimePicker from './TimePicker';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 125px;
  margin-right: 8px;
`;

type Props = {
  // Значение
  value?: moment,
  // Функция изменения значения
  onChange: (date: string) => void,
  // Функция блокировки дат
  // Если вернулась строка - это будет текстом пояснения блокирования
  disabledDate?: (currentDate: moment) => string | boolean,
  // Функция блокировки времени
  disabledTime?: Function,
  // Заблокированное поле
  disabled?: boolean,
  onChangeDisabled?: boolean,
  style?: HTMLStyleElement,
  waypointName?: string,
};

export default class DateTimePicker extends Component<Props> {
  getMomentValue(value: ?moment) {
    return value ? moment.utc(value) : value;
  }

  render() {
    const {
      onChange,
      disabledDate,
      disabledTime,
      disabled,
      onChangeDisabled,
      waypointName,
    } = this.props;
    const momentValue = this.getMomentValue(this.props.value);
    return (
      <Wrapper>
        <StyledDatePicker
          style={{
            width: '250px',
          }}
          placeholder="Дата"
          disabledDate={disabledDate}
          format="DD.MM.YYYY"
          value={momentValue}
          disabled={disabled || !waypointName}
          dateRender={(currentDate: moment) => {
            const antElement = (
              <div className="ant-calendar-date">{currentDate.date()}</div>
            );
            if (disabledDate) {
              const disabledMessage = disabledDate(currentDate);
              if (typeof disabledMessage === 'string') {
                return (
                  <Popover
                    zIndex={2000}
                    title="Внимание!"
                    width={130}
                    content={disabledMessage}
                  >
                    {antElement}
                  </Popover>
                );
              }
              return antElement;
            }
            return antElement;
          }}
          onChange={(value: Object, dateString: string) => {
            const date = moment(formatDateTimeToISOString(value, dateString));
            if (momentValue) {
              date
                .add(momentValue.hour(), 'hour')
                .add(momentValue.minute(), 'minute');
            }
            onChange(moment.utc(date).toISOString());
          }}
        />
        <TimePicker
          onChangeDisabled={onChangeDisabled}
          value={momentValue}
          disabledTime={disabledTime}
          waypointName={waypointName}
          onChange={(value) => {
            onChange(moment.utc(value).toISOString());
          }}
        />
      </Wrapper>
    );
  }
}
