//@flow
import React from 'react';
import CommonListPage from '../../components/hoc/common/handbook/ListPage';
import { maintenanceOperationByModelApi } from '../../lib/api';
import { HeaderTabs, Filter } from './components';
import Page from '../../components/hoc/common/handbook/PageContext';

const columns = [
  {
    title: 'Модель УАТ',
    dataIndex: 'modelUat'
  },
  {
    title: 'Модель Автософт',
    dataIndex: 'modelAutosoft'
  },
  {
    title: 'Наименование работ',
    dataIndex: 'operationName'
  },
  {
    title: 'Трудоёмкость н/ч',
    dataIndex: 'contractorWorkHours'
  },
  {
    title: 'Id работы',
    dataIndex: 'operationCode'
  }
];

export default () => {
  return (
    <Page>
      <CommonListPage
        pageHeaderProps={{
          mainHeader: 'Перечень работ по ремонту ТС'
        }}
        subHeader={<HeaderTabs selectedTab="byModel" />}
        tableListProps={{
          columns
        }}
        filter={Filter}
        crud={maintenanceOperationByModelApi}
      />
    </Page>
  );
};
