// @flow
import type { RequestSection } from '../requests';
import RequestsController from '../requests';

export class Core {
  controller: RequestSection;

  constructor(controller: RequestsController) {
    this.controller = controller.getApiSection('core');
  }

  /**
   * Запросить данные аккаунта
   * @param type Тип данных
   */
  async getAccountData(type: 1 | 2) {
    return await this.controller.fetch('get_account_data', { type });
  }

  async searchItem(id: number) {
    return await this.controller.fetch('search_item', {
      id,
      flags: 4194304
    });
  }

  /**
   * Поиск виалоновского элемента по id
   * @param wialonVehicleId ID элемента
   */
  async searchItems(wialonVehicleId: number) {
    return await this.controller.fetch('search_items', {
      spec: {
        itemsType: 'avl_unit',
        propName: 'sys_unique_id',
        propValueMask: wialonVehicleId,
        sortType: 'sys_name'
      },
      force: 1,
      flags: 1,
      from: 0,
      to: 0
    });
  }
}
