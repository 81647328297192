// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { MaintenanceMatrix } from '../../lib/types';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import { Header, Panel } from '../../components/layout';
import InnerForm from './components/InnerForm';
import { goBack } from './../../lib/helpers';
import { notificationLoading } from './../../components/Notifications';
import { maintenanceMatrixApi } from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  maintenanceMatrixId?: number
};

type State = {
  maintenanceMatrix: ?MaintenanceMatrix
};

export class MaintenanceMatrixForm extends React.Component<Props, State> {
  state = {
    maintenanceMatrix: null
  };

  async componentDidMount() {
    const { maintenanceMatrixId } = this.props;
    try {
      if (maintenanceMatrixId) {
        const maintenanceMatrix = await maintenanceMatrixApi.getMaintenanceMatrix(
          maintenanceMatrixId
        );
        this.setState({ maintenanceMatrix });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: MaintenanceMatrix) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await maintenanceMatrixApi.updateMaintenanceMatrix(values);
      } else {
        await maintenanceMatrixApi.addMaintenanceMatrix(values);
      }
      navigate(`/admin/maintenance-matrix`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/admin/maintenance-matrix');

  render() {
    const { maintenanceMatrix } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/maintenance-matrix">Матрица ремонтов</Crumb>
              {maintenanceMatrix ? (
                <Crumb>Матрица №{maintenanceMatrix.id}</Crumb>
              ) : (
                <Crumb>Новая матрица</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceMatrix
              ? `Матрица №${maintenanceMatrix.id}`
              : 'Новая матрица'}
          </h1>
        </StyledPanel>
        <InnerForm
          maintenanceMatrix={maintenanceMatrix}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default MaintenanceMatrixForm;
