import {
    MaintenanceOperarionPriceList,
    MaintenanceOperationPriceCard,
    MaintenanceOperationPriceForm,
} from '../../containers/MaintenanceOperationPrice';
import { accessTypeEnum } from '../../lib/enum';

// прейскурант на с/budget/budget-analysis cтоимость видов работ по филиалам
export default [
    {
        component: MaintenanceOperarionPriceList,
        path: '/admin/maintenance-operation-price',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
    {
        component: MaintenanceOperationPriceForm,
        path: '/admin/maintenance-operation-price/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: MaintenanceOperationPriceForm,
        path: '/admin/maintenance-operation-price/:maintenanceOperationPriceId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: MaintenanceOperationPriceCard,
        path: '/admin/maintenance-operation-price/:maintenanceOperationPriceId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
];