// @flow
import Input from 'antd/lib/input';
import moment from 'moment';
import React from 'react';

import { Filter, FilterButtonsContainer, FilterContainer, FilterItem } from '../../../../components';
import { RangePicker } from '../../../../components/ant/DatePicker';
import Field from '../../../../components/card/Field';
import { SectionContent } from '../../../../components/hoc/common/components/elements';
import Selects from '../../../../components/selects';
import { formatDateTimeToISOString } from '../../../../lib/helpers';
import type { ExpenseDirectionType } from '../../../../lib/types';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';

const { ExpenseDirectionTypeSelect } = Selects;

export type ExpenseDirectionFilterParams = {
  search?: string,
  cognosId?: string,
  budgetLineItemId?: string,
  budgetLineItemName?: string,
  startDate?: string | Date,
  endDate?: string | Date,
  type?: ExpenseDirectionType
};

type Props = {
  filter: ExpenseDirectionFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const StockPartFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<ExpenseDirectionFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Название"
                  size="small"
                  onChange={e =>
                    changeValue('search', e.target.value || undefined)
                  }
                  value={values.search}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Ид. статьи бюдж. формы"
                  size="small"
                  onChange={e =>
                    changeValue('budgetLineItemId', e.target.value || undefined)
                  }
                  value={values.budgetLineItemId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Наименование статьи бюдж. формы"
                  size="small"
                  onChange={e =>
                    changeValue(
                      'budgetLineItemName',
                      e.target.value || undefined
                    )
                  }
                  value={values.budgetLineItemName}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <ExpenseDirectionTypeSelect
                  value={values.type}
                  onChange={value => changeValue('type', value)}
                  size="small"
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Ид. направления расходов"
                  size="small"
                  onChange={e =>
                    changeValue('cognosId', e.target.value || undefined)
                  }
                  value={values.cognosId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      startDate
                        ? formatDateTimeToISOString(startDate, startDateString)
                        : undefined
                    );
                    changeValue(
                      'endDate',
                      endDate
                        ? formatDateTimeToISOString(endDate, endDateString)
                        : undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);

export default StockPartFilter;
