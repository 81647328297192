import { WarningOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import VehicleStatus from '../../VehicleStatus';

export const VehicleCard = styled.div`
  position: relative;
  width: 100%;
  min-height: 56px;
  background: ${({ isActive }) => (isActive ? '#bed8f3' : '#F0F4F8')};
  border-radius: 3px;
  padding: 13px 8px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  display: flex;
  height: 100%;
  box-shadow: ${({ isActive }) => (isActive ? '0px 2px 4px #C0CCD7' : 'none')};
  &:hover {
    box-shadow: 0px 2px 4px #c0ccd7;
    cursor: pointer;
  }
  > p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  border: 1px solid #c0ccd7;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  background: #f0f4f8;
`;

export const VehicleCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px;
`;

export const VehicleInfo = styled.div`
  display: flex;
  & .vehicle-info {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 12px;
    padding: 2px 0;
  }
`;

export const Status = styled(VehicleStatus)`
  align-items: flex-start;
`;

export const WarningIcon = styled(WarningOutlined)`
  color: #ffc53d;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 13px;
`;
