// @flow
import { LoadingOutlined } from '@ant-design/icons';
import React, { type Node } from 'react';
import notification from 'antd/lib/notification';

type NotificationConfig = {
  className?: string,
  description?: string,
  message?: string,
  duration?: number,
  icon?: Node,
  key?: string,
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
  style?: any,
  onClose?: () => void | (() => Promise<void>),
  onClick?: () => void | (() => Promise<void>)
};

export const notificationLoading = (config: NotificationConfig) => {
  notification.open({
    duration: 0,
    ...config,
    icon: <LoadingOutlined style={{ color: '#286EF6' }} />
  });
};
