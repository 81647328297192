// @flow

import React, { Component } from 'react';
import { Link } from '@reach/router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import {
  vehicleTypes,
  tripStatuses,
  vehicleGroups,
  ownerTypes,
  tripTypeEnum
} from './../../../lib/enum';
import { formatDateRangeString, setQueryParams } from './../../../lib/helpers';
import Filter from './Filter';
import { Table } from './../../../components/ui';
import type { TripFilterParams } from './Filter';
import type { ListState, Vehicle, Trip, TripType } from './../../../lib/types';
import {
  getListInitialState,
  applyMaskToValue,
  navigate
} from './../../../lib/helpers';
import type { FetchListParams } from './../../../lib/api';
import { tripApi } from './../../../lib/api';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import type { AppState } from '../../../ducks/redux';
import { setFilter } from '../../../ducks/persistFilters';
import type { PersistFilterPayload } from '../../../ducks/persistFilters';

type Props = {
  location: any,
  tripType: TripType,
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<Trip> & {
  filterPath: string
};

export const COLUMNS = [
  {
    title: '№',
    dataIndex: 'idNumber',
    sorter: true,
    width: 25
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    sorter: true,
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (status: string, record: Trip) => (
      <Link to={`/trips/self/${record.id}/card`}>{tripStatuses[status]}</Link>
    )
  },
  {
    title: 'Тип',
    dataIndex: 'vehicleType',
    sorter: true,
    render: (text: string, record: Trip): ?string =>
      vehicleTypes[text] || vehicleGroups[record.vehicleGroup]
  },
  {
    title: 'Служба',
    sorter: true,
    sorterKey: 'node.name',
    dataIndex: 'orgUnitName'
  },

  {
    title: 'Период выделения',
    dataIndex: 'startDate',
    sorterKey: 'expectedRoute.startDate',
    sorter: true,
    render: (
      text: string, // eslint-disable-line no-unused-vars
      record: Trip
    ): string => formatDateRangeString(record.startDate, record.endDate)
  },
  {
    title: 'ТС',
    dataIndex: 'vehicle',
    sorter: true,
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (vehicle: Vehicle, trip: Trip) => {
      if (vehicle) {
        const licensePlate =
          vehicle.licensePlate &&
          applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask);
        if (vehicle.isDeleted) return licensePlate;
        return vehicle.ownerType === ownerTypes.self ? (
          <Link to={`/vehicles/${vehicle.id}`}>{licensePlate}</Link>
        ) : (
          // $FlowFixMe
          <Link to={`/vehicles/contract/${trip.contractVehicleId}`}>
            {licensePlate}
          </Link>
        );
      }
      return null;
    }
  }
];

class TripListByType extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };

  getColumns = () => {
    return [...COLUMNS];
  };

  handleRowClick = (id: number) => navigate(`/trips/self/${id}/card`);

  fetchTrips = async (
    page: number = 1,
    params: FetchListParams<TripFilterParams> = {}
  ) => {
    const { tripType } = this.props;
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({ loading: true });
    if (isEmpty(params)) {
      params = { orderBy: 'expectedRoute.startDate', byDescending: true };
    }
    const { data, totalCount } = await tripApi.fetchTrips({
      ...filter,
      page,
      ...params,
      'vehicle.ownerType': ownerTypes.self,
      isArchive: tripType === tripTypeEnum.archive
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  setPersistFilter = async (values: TripFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchTrips();
  };

  applyFilter = async (filter: TripFilterParams) => {
    await this.setPersistFilter(filter);
    await this.fetchTrips();
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchTrips(page);
  }

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filterPath
    } = this.state;
    const filter = this.props.persistFilters[filterPath];
    return (
      <>
        <Filter
          filter={filter}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
          type={this.props.tripType}
        />
        <Table
          columns={this.getColumns()}
          onRow={record => ({
            onClick: () => this.handleRowClick(record.id)
          })}
          defaultOrderBy={'expectedRoute.startDate'}
          fetch={this.fetchTrips}
          loading={loading}
          pagination={{
            page,
            pageSize,
            totalCount,
            location
          }}
          data={data.map(item => ({ ...item, key: item.id }))}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(TripListByType);
