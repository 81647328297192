// @flow
import type { WorkingHourSelfPrice } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (workingHourSelfPrice: WorkingHourSelfPrice) => ({
  ...workingHourSelfPrice,
  price: convertFromRubToKop(workingHourSelfPrice.price)
});

const convertDataFromServer = (workingHourSelfPrice: WorkingHourSelfPrice) => ({
  ...workingHourSelfPrice,
  price: convertFromKopToRub(workingHourSelfPrice.price)
});

const workingHourSelfPrice = new CommonApi<WorkingHourSelfPrice>(
  'workingHourSelfPrice',
  'Стоимость нормо-часа хоз. способом',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...workingHourSelfPrice
};
