import React from 'react';
import moment from 'moment';

import Modal from 'antd/lib/modal';

import { formatDateTimeToISOString } from './../../../../../../../lib/helpers';
import { contractVehicleTypeEnum } from './../../../../../../../lib/enum';

import ContractVehicleInnerForm from './../../../../../../ContractVehicles/components/InnerForm';

type Props = {
  visible: boolean,
  orgUnitId: number,
  onCancel: Function,
  addContractVehicle: Function
};
export default (props: Props) => {
  const { visible, onCancel, orgUnitId, addContractVehicle } = props;
  const formatDate = value => {
    return value ? moment.utc(value) : value;
  };
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Добавление нового ТС"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <ContractVehicleInnerForm
        vehicle={{
          type: contractVehicleTypeEnum.fixedPlan,
          vehicle: {
            orgUnitId,
            usingNodeId: orgUnitId
          }
        }}
        hideFields={['type', 'orgUnitId', 'usingNodeId']}
        onCancel={onCancel}
        onSubmit={addContractVehicle}
        formatDate={formatDate}
        changeDate={formatDateTimeToISOString}
        sectionStyle={{ boxShadow: 'none', margin: '0px' }}
      />
    </Modal>
  );
};
