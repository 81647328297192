// @flow
import React, { type Node } from 'react';
import { TabItem } from './TabItem';

export const hasExactMatch = (children: Node) => {
  const exactMatch = [];
  React.Children.forEach(children, child => {
    if (child && child.type === TabItem) {
      const { url } = child.props;
      if (window.location.pathname === url) exactMatch.push(url);
    }
  });
  return !!exactMatch.length;
};
