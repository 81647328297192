// @flow

import React, { Component } from 'react';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import moment from 'moment';
import styled from 'styled-components';
import qs from 'query-string';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';

import type { Order, WayPoint, Company, BusinessDay } from './../../lib/types';
import type { AppState } from '../../ducks/redux';
import InnerForm from './components/InnerForm';

import { Panel } from './../../components/layout';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { orderTypeEnum } from '../../lib/enum';
import type { Profile } from '../../ducks/auth';
import { notificationLoading } from '../../components/Notifications';
import type { FreeContractVehicle } from '../../lib/types/contractVehicle';
import { businessDayApi, orderApi, orderGpmApi, routeApi } from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  order: Order,
  profile: Profile,
  orgUnitId: ?number,
  locations: Array<Company>,
  orderId: ?number,
  copyOrderId?: number,
};

type State = {
  order: ?Order,
  freeContractVehicles: FreeContractVehicle[],
  businessCalendar: BusinessDay[],
};

class OrderForm extends Component<Props, State> {
  state = {
    freeContractVehicles: [],
    businessCalendar: [],
    order: null,
  };

  async componentDidMount() {
    const { orderId, copyOrderId } = this.props;
    await this.fetchBusinessCalendar();
    try {
      if (orderId) {
        await this.fetchOrder(orderId);
      } else if (copyOrderId) {
        notificationLoading({
          message: 'Идет копирование заявки...',
          key: 'copyLoading',
        });
        const { date } = qs.parse(window.location.search);
        await this.copyOrder(copyOrderId, new Date(date));
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('copyLoading');
    }
  }

  fetchOrder = async (orderId: number) => {
    const order: Order = await orderApi.fetchOrder(orderId);
    const route = await routeApi.fetchRoute(order.routeId);

    this.setState({
      order: {
        ...order,
        route: {
          ...route,
          waypoints: orderBy(
            route.waypoints,
            ['arrivedDateTime', 'departureDateTime'],
            ['asc', 'asc']
          ),
        },
      },
    });
  };

  copyOrder = async (orderId: number, date: Date) => {
    const order = await orderApi.fetchOrder(orderId);
    const copy = pick(order, [
      'type',
      'objective',
      'employeeId',
      'notation',
      'isBusinessTrip',
      'businessTripOrderNumber',
      'businessTripDecreeNumber',
      'vehicleType',
      'vehicleGroup',
      'workersCount',
      'withTrailer',
      'route.waypoints',
      // ГПМ поля
      'isGpm',
      'mainEngineerId',
      'gpmOwnerId',
      'vehicleModelId',
      'natureOfWork',
      'object',
      'safetyResponsibles',
      'riggers',
      'secondaryRiggers',
      'project',
      'powerLinesInfo',
      'voltage',
      'vhDistance',
      'safetyZone',
      'permitInfo',
      'admission',
    ]);

    this.setState({
      order: {
        ...copy,
        route: {
          waypoints: order.waypoints
            ? order.waypoints.map((waypoint) => {
                const month = moment(date).month();
                const day = moment(date).date();
                const year = moment(date).year();
                // Заменяем день, год и месяц
                waypoint.arrivedDateTime = moment
                  .utc(waypoint.arrivedDateTime)
                  .set({ month, date: day, year })
                  .toISOString();
                waypoint.departureDateTime = moment
                  .utc(waypoint.departureDateTime)
                  .set({ month, date: day, year })
                  .toISOString();
                return omit(waypoint, 'id');
              })
            : [],
        },
      },
    });
  };

  addOrder = async (order: Order) => {
    const addedRoute = await routeApi.addRoute(order.route);
    const newOrder = {
      ...order,
      routeId: addedRoute.id,
    };
    const addedOrder = order.isGpm
      ? await orderGpmApi.addGpmOrder(newOrder)
      : await orderApi.addOrder(newOrder);
    this.setState(
      { order: addedOrder },
      () => this.state.order && navigate(`/orders/${this.state.order.id}/card`)
    );
  };

  updateOrder = async (order: Order) => {
    const route = await routeApi.updateRoute(order.route);
    const updatedOrder: Order = order.isGpm
      ? await orderGpmApi.updateGpmOrder(order)
      : await orderApi.updateOrder(order);
    const newOrder = {
      ...updatedOrder,
      route,
    };
    this.setState(
      { order: newOrder },
      () => this.state.order && navigate(`/orders/${this.state.order.id}/card`)
    );
  };

  checkIsBusinessTrip = function (values: Order) {
    const { route = {} } = values;
    const { waypoints = [] } = route;
    const minDateTime = moment.min(
      waypoints.map((waypoint: WayPoint) =>
        moment.utc(waypoint.arrivedDateTime)
      )
    );
    const maxDateTime = moment.max(
      waypoints.map((waypoint: WayPoint) =>
        moment.utc(waypoint.departureDateTime)
      )
    );
    return maxDateTime.isAfter(minDateTime, 'day');
  };

  submitForm = async (values: Order) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (this.props.orderId) {
        await this.updateOrder(values);
      } else {
        await this.addOrder(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  redirect = () => {
    if (this.props.orderId) {
      navigate(`/orders/${this.props.orderId}/card`);
    } else navigate('/orders');
  };

  /**
   * Ограничение в количестве часов,
   * на которые мы можем задать маршрут
   */
  getOffsetHours = (order: Order) => {
    if (order.isBusinessTrip) {
      return -1 * moment().diff(moment().add(-1, 'year'), 'hours');
    }
    if (order.type === orderTypeEnum.emergency) {
      return -48;
    } else {
      return 0;
    }
  };

  fetchBusinessCalendar = async () => {
    try {
      const {
        data: businessCalendar,
      } = await businessDayApi.fetchBusinessDays({ pageSize: 0 });
      this.setState({ businessCalendar });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  render() {
    const { profile, orderId } = this.props;
    const { freeContractVehicles, businessCalendar, order } = this.state;

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/orders">Заявки</Crumb>
              <Crumb to="/orders/new">Новая заявка</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{orderId ? `Заявка №${orderId}` : 'Новая заявка'}</h1>
        </StyledPanel>
        <InnerForm
          freeContractVehicles={freeContractVehicles}
          order={order}
          profile={profile}
          getOffsetHours={this.getOffsetHours}
          onCancel={this.redirect}
          businessCalendar={businessCalendar}
          onSubmit={this.submitForm}
        />
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  orderId: parseInt(ownProps.orderId),
  copyOrderId: parseInt(ownProps.copyOrderId),
  profile: state.auth.profile,
  orgUnitId: parseInt(ownProps.orgUnitId, 10),
}))(OrderForm);
