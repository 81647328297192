// @flow
import { Dispatch } from 'redux';

import type { Action, AppState, ListState } from './redux';
import { defaultPageSize } from './constants';
import type { VehicleModel } from '../lib/types';
import { vehicleModelApi } from '../lib/api';
import type { FetchListParams } from '../lib/api';

export const FETCH_VEHICLE_MODELS = 'vehicles/models/fetch';
export const DELETE_VEHICLE_MODEL = 'vehicles/models/delete';
export const SET_VEHICLE_MODELS_FILTERS = '/vehicles/models/filters/set';
export const CLEAN_VEHICLE_MODELS_FILTERS = '/vehicles/models/filters/clean';

export type VehicleModelsFilter = {
  'brand.id'?: number,
  type?: string,
  search?: string,
  onlyBranchModels?: boolean
};

export type VehicleModelsState = ListState<VehicleModel> & {
  filters: VehicleModelsFilter
};

const initialState: VehicleModelsState = {
  data: [],
  page: 1,
  pageSize: defaultPageSize,
  totalCount: 0,
  filters: {}
};

const reducer = (
  state: VehicleModelsState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case FETCH_VEHICLE_MODELS:
      return { ...state, ...payload };
    case DELETE_VEHICLE_MODEL:
      return {
        ...state,
        data: (state.data.filter(model => model.id !== payload): VehicleModel[])
      };
    case SET_VEHICLE_MODELS_FILTERS:
      return {
        ...state,
        filters: payload
      };
    case CLEAN_VEHICLE_MODELS_FILTERS:
      return {
        ...state,
        filters: {}
      };
    default:
      return state;
  }
};

export const fetchVehicleModels = (
  page: number = 1,
  params: FetchListParams<VehicleModelsFilter> = {}
) => async (dispatch: Dispatch, getState: () => AppState) => {
  const { filters, pageSize } = getState().vehicleModels;
  let { data, totalCount } = await vehicleModelApi.fetchVehicleModels({
    ...filters,
    page,
    pageSize,
    ...params
  });
  dispatch({
    type: FETCH_VEHICLE_MODELS,
    payload: {
      data,
      totalCount,
      page,
      pageSize
    }
  });
};

export const applyFilters = (filters: VehicleModelsFilter) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_VEHICLE_MODELS_FILTERS,
    payload: filters
  });
  dispatch(fetchVehicleModels());
};

export const cleanFilters = () => async (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_VEHICLE_MODELS_FILTERS
  });
  dispatch(fetchVehicleModels());
};

export default reducer;
