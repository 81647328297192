// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  Selects,
} from '../../../components';
import Field from '../../../components/card/Field';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';

const { CompanySelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type BioTreatmentFacilitiesReportFilterParams = {
  companyId?: number,
  showExpiringCoupons?: boolean,
};

type Props = {
  filter: BioTreatmentFacilitiesReportFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
};

export const ContractFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams<BioTreatmentFacilitiesFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <CompanySelect
                  size="small"
                  onChange={(value) => changeValue('companyId', value)}
                  value={values.companyId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Checkbox
                  checked={values.showExpiringCoupons}
                  onChange={(e) => {
                    changeValue(
                      'showExpiringCoupons',
                      e?.target?.checked ? true : undefined
                    );
                  }}
                >
                  Заканчиваются талоны
                </Checkbox>
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ContractFilter;
