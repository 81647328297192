// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { driverQualificationEnum, drivingLicenseCategories, tractorDrivingLicenseCategories } from '../../lib/enum';
import type { DriverQualification } from '../../lib/types';
import { Option, Select } from './../ant/Select';

type Props = SelectProps & {
  // Квалификации водителя, на основе которой отпределяются категории прав
  driverQualification?: DriverQualification,
  showAllCategories?: boolean
};

const isTractor = (driverQualification: DriverQualification) => {
  return (
    driverQualification === driverQualificationEnum.tractorMachinistLicense
  );
};

// Выпадающий список категорий прав
export default ({
  type,
  value,
  onChange,
  driverQualification,
  multiple,
  showAllCategories,
  ...props
}: Props) => {
  let categories: any;
  if (!showAllCategories) {
    categories = isTractor(driverQualification)
      ? tractorDrivingLicenseCategories
      : drivingLicenseCategories;
  } else {
    let tractorCategories = {};
    Object.keys(tractorDrivingLicenseCategories).forEach(
      (el: string) =>
        (tractorCategories[
          el
        ] = `${tractorDrivingLicenseCategories[el]}(Трактор)`)
    );
    categories = {
      ...drivingLicenseCategories,
      ...tractorCategories
    };
  }
  return (
    <Select
      allowClear
      mode={multiple ? 'multiple' : 'default'}
      value={
        multiple
          ? (value || []).map(key => ({
              key,
              label: categories[key]
            }))
          : value
      }
      labelInValue={multiple}
      onChange={value => {
        multiple ? onChange(value.map(value => value.key)) : onChange(value);
      }}
      {...props}
    >
      {Object.keys(categories).map(category => (
        <Option value={category} key={category}>
          {categories[category]}
        </Option>
      ))}
    </Select>
  );
};
