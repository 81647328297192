// @flow

import type { VehicleGroup, VehicleType } from './../../lib/types';
import { vehicleGroupEnum, vehicleTypeEnum } from './../../lib/enum';
import { vehicleTypesPlainTree } from './../../lib/vehicleTypesTree';

export const getVehicleGroup = (vehicleType: VehicleType) => {
  let vehicleGroup: VehicleGroup = '';
  Object.keys(vehicleTypesPlainTree).forEach((key: string) => {
    if (vehicleTypesPlainTree[key].includes(vehicleType)) {
      vehicleGroup = key;
      return;
    }
  });
  return vehicleGroup;
};

/**
 * Логика по отображению поля "Категория прав для ТС"
 * для заполнения формы
 *
 * ХЗ, зачем эти проверки нужны
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowRightsCategory = (vehicleType: VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bgmNaTraktore,
      vehicleTypeEnum.bkmNaTraktore,
      vehicleTypeEnum.bkmRpnNaTraktore
    ].includes(vehicleType);
  }

  if (vehicleGroup === vehicleGroupEnum.tractorsExcavators) {
    return vehicleTypesPlainTree[vehicleGroupEnum.tractorsExcavators].includes(
      vehicleType
    );
  }
  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению поля "Обязательно ОСАГО"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowOsagoNeeded = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return [
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.bkmBgm,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.cars,
    vehicleGroupEnum.tractorsExcavators,
    vehicleGroupEnum.autolabs,

    vehicleGroupEnum.carGroup,
    vehicleGroupEnum.busGroup,
    vehicleGroupEnum.cargoGroup,
    vehicleGroupEnum.specialCarGroup
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению поля "Количество осей"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowAxlesNumber = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup !== vehicleGroupEnum.floatingTransport;
};

/**
 * Логика по отображению поля "Полная масса ТС, т"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowGrossWeight = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup !== vehicleGroupEnum.floatingTransport;
};

/**
 * Логика по отображению поля "Колесная формула"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowChassisConfiguration = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return (
    vehicleGroup !== vehicleGroupEnum.floatingTransport &&
    vehicleGroup !== vehicleGroupEnum.trailers
  );
};

/**
 * Логика по отображению поля "Нагрузка на ось"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowAxleLoad = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению поля "Грузоподъемность"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowCarrying = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.trailers,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению полей, касаемо двигателя
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowEngineData = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup !== vehicleGroupEnum.trailers;
};

/**
 * Логика по отображению поля "Кол-во пассажирский мест, шт"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowPassengerSeatsNumber = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }

  if (vehicleGroup === vehicleGroupEnum.atvs) {
    return [vehicleTypeEnum.bolotoxod].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs,
    vehicleGroupEnum.floatingTransport
  ].includes(vehicleGroup);
};

/**
 * Логика по обязательности заполнения поля "Кол-во пассажирский мест, шт"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const isRequiredPassengerSeatsNumber = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }

  if (vehicleGroup === vehicleGroupEnum.otherTrucks) {
    // Тягачи НЕ(!) должны быть обязательными для заполнения
    return ![
      vehicleTypeEnum.avtomobylTiagachBezKmu,
      vehicleTypeEnum.avtomobylTiagachSKmu
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs,
    vehicleGroupEnum.floatingTransport
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению поля "Экологический класс ТС"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowEcologicClass = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению поля "Категория ТС"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowVehicleCategory = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.cars,
    vehicleGroupEnum.specialBuses,
    vehicleGroupEnum.passengerBuses,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.tippers,
    vehicleGroupEnum.flatbedTrucks,
    vehicleGroupEnum.cargoVans,
    vehicleGroupEnum.otherTrucks,
    vehicleGroupEnum.trailers,
    vehicleGroupEnum.utilityVehicles,
    vehicleGroupEnum.autolabs,

    vehicleGroupEnum.carGroup,
    vehicleGroupEnum.busGroup,
    vehicleGroupEnum.cargoGroup,
    vehicleGroupEnum.specialCarGroup
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению полей для ГПМ
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowGpmData = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.flatbedTrucks) {
    return [
      vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
      vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu
    ].includes(vehicleType);
  }

  if (vehicleGroup === vehicleGroupEnum.otherTrucks) {
    return vehicleType === vehicleTypeEnum.avtomobylTiagachSKmu;
  }

  if (vehicleGroup === vehicleGroupEnum.utilityVehicles) {
    return vehicleType === vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu;
  }

  if (vehicleGroup === vehicleGroupEnum.trailers) {
    return [vehicleTypeEnum.prytsepSKmu, vehicleTypeEnum.pPrytsepSKmu].includes(
      vehicleType
    );
  }

  return [
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.bkmBgm
  ].includes(vehicleGroup);
};

/**
 * Логика по отображению полей для бурильной установки
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowDrillingData = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup === vehicleGroupEnum.bkmBgm;
};

/**
 * Логика по отображению полей по топливу
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowFuelData = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  return (
    vehicleGroup !== vehicleGroupEnum.trailers &&
    vehicleType !== vehicleTypeEnum.trailers
  );
};

/**
 * Логика по отображению поля "Дополнительный вид топлива"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowSecondaryFuel = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);
  // (!) Чтобы не прописывать весь список, ставлю отрицание и пишу список запрещенных
  return ![vehicleGroupEnum.atvs, vehicleGroupEnum.floatingTransport].includes(
    vehicleGroup
  );
};

/**
 * Логика по обязательности заполнения полей
 * "Вид топлива"
 * "Норма расх. топлива на 1 маш/час работы"
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const isRequiredAddPrimaryFuelType = (vehicleType?: ?VehicleType) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  return vehicleGroup !== vehicleGroupEnum.floatingTransport;
};

/**
 * Логика по обязательности заполнения поля "Нормативный расход, л/100 км"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const isRequiredAddPrimaryFuelConsumption = (
  vehicleType?: ?VehicleType
) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.bkmBgm) {
    return [
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaAvtomobyle
    ].includes(vehicleType);
  }
  return ![
    vehicleGroupEnum.atvs,
    vehicleGroupEnum.tractorsExcavators,
    vehicleGroupEnum.floatingTransport
  ].includes(vehicleGroup);
};

/**
 * Логика по обязательности заполнения поля "Нормативный расход л/маш.час работы оборудования для основного вида топлива"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const isRequiredAddPrimaryEquipmentFuelConsumption = (
  vehicleType?: ?VehicleType
) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  return ![vehicleGroupEnum.atvs, vehicleGroupEnum.floatingTransport].includes(
    vehicleGroup
  );
};

/**
 * Логика по отображению поля "Нормативный расход л/маш.час работы оборудования для основного вида топлива"
 * для заполнения формы
 *
 * Тип ТС
 * @param {VehicleType} vehicleType
 */
export const canShowPrimaryEquipmentFuelConsumption = (
  vehicleType?: ?VehicleType
) => {
  if (!vehicleType) {
    return false;
  }
  const vehicleGroup = getVehicleGroup(vehicleType);

  if (vehicleGroup === vehicleGroupEnum.trucks) {
    return [
      vehicleTypeEnum.amKlassaN1STsysternoi,
      vehicleTypeEnum.amKlassaN2STsysternoi,
      vehicleTypeEnum.amKlassaN3STsysternoi
    ].includes(vehicleType);
  }

  return [
    vehicleGroupEnum.truckCranes,
    vehicleGroupEnum.trucksKmu,
    vehicleGroupEnum.bkmBgm,
    vehicleGroupEnum.carLifts,
    vehicleGroupEnum.autolabs,
    vehicleGroupEnum.tractors
  ].includes(vehicleGroup);
};
