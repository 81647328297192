// @flow

import type { Employee, EmployeeBranchPosition } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { EmployeesFilterParams } from '../../containers/Employees/Filter';

export const addEmployee = async (employee: Employee): Promise<Employee> => {
  const added = await fetchRequest.post('/employee', employee);
  if (added) return added;
  throw new Error('Не удалось создать сотрудника');
};

export const updateEmployee = async (employee: Employee): Promise<Employee> => {
  const updated = await fetchRequest.put('/employee', employee);
  if (updated) return updated;
  throw new Error('Не удалось обновить сотрудника');
};

export const fetchEmployee = async (
  id: number,
  params: any = {}
): Promise<Employee> => {
  let employee = await fetchRequest.get(`/employee/${id}`, params);
  if (employee) return employee;
  throw new Error('Не удалось загрузить сотрудника');
};

export const fetchEmployees = async (
  params: FetchListParams<EmployeesFilterParams> = initialFetchListParams
): Promise<ListResponse<Employee>> =>
  await fetchRequest.get('/employee', {
    ...initialFetchListParams,
    ...params
  });

export const deleteEmployee = async (id: number): Promise<Employee> => {
  const deleted = await fetchRequest.delete(`/employee/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить сотрудника');
};

export const print = async () => {
  const printed = await downloadRequestWithToken('/employee/print');
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export const fetchBranchEmployeesPositions = async (
  branchId: number
): Promise<EmployeeBranchPosition[]> => {
  return await fetchRequest.get(`/employee/branchEmployees/${branchId}`, {});
};

export default {
  addEmployee,
  updateEmployee,
  fetchEmployees,
  fetchEmployee,
  deleteEmployee,
  print,
  fetchBranchEmployeesPositions
};
