// @flow

import { Link, navigate } from '@reach/router';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatOsagoMask } from '../../../components/inputs/masked-inputs/OsagoInput';
import { TableHeader } from '../../../components/ui/Table';
import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';
import { canHandleService } from '../lib';
import { Section } from './../../../components/layout';
import Header from './../../../components/layout/Header';
import { notificationLoading } from './../../../components/Notifications';
import { Popconfirm, ButtonsRow, Icon, Table } from './../../../components/ui';
import { type FetchListParams, osagoApi } from './../../../lib/api';
import {
  applyMaskToValue,
  convertContractorToString,
  convertVehicleToString,
  formatDateTimeToString,
  getListInitialState,
  setQueryParams,
} from './../../../lib/helpers';

import type {
  Contractor,
  ListState,
  Osago,
  UserAccess,
  Vehicle,
} from './../../../lib/types';
import { withUserAccess } from './../../withUserAccess';

import type { OsagoFilterParams } from './Filter';
import OsagoFilter from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void,
};
type State = ListState<Osago> & {
  filterPath: string,
};

export const COLUMNS = [
  {
    title: 'Марка',
    sorter: true,
    dataIndex: 'vehicle',
    width: 300,
    breakByWidth: true,
    render: (vehicle: Vehicle) => convertVehicleToString(vehicle),
  },
  {
    title: 'Серия и номер ОСАГО',
    dataIndex: 'osagoNumber',
    width: 150,
    render: (osago: string) => applyMaskToValue(osago, formatOsagoMask),
  },
  {
    title: 'Дата окончания',
    dataIndex: 'osagoFinishedDate',
    width: 120,
    render: (value: string) => formatDateTimeToString(value, 'DD.MM.YYYY'),
  },
  {
    title: 'Наименование страховщика',
    sorter: true,
    dataIndex: 'contractor',
    width: 200,
    breakByWidth: true,
    render: (contractor: Contractor) => convertContractorToString(contractor),
  },
  {
    title: 'Серия и номер диагностической карты',
    dataIndex: 'diagnosticCardNumber',
    width: 150,
    breakByWidth: true,
  },
  {
    title: <TableHeader width="100px">Сумма оплаты в руб. без НДС</TableHeader>,
    dataIndex: 'amountPayment',
    width: 100,
    breakByWidth: true,
  },
];

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };

  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname,
  };

  columns = [...COLUMNS];

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteOsago(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
      });
    }
    await this.fetchOsagoList(page);
  }

  deleteOsago = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await osagoApi.deleteOsago(id);
      await this.fetchOsagoList(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchOsagoList = async (page: number = 1, params: FetchListParams<> = {}) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({ loading: true });
    const { data, totalCount } = await osagoApi.fetchOsagos({
      ...filter,
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  handleRowClick = (id: number) => navigate(`/services/osagos/${id}`);

  canAdd = () => canHandleService(this.props.userAccess);

  setPersistFilter = async (values: OsagoFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };

  applyFilter = async (filter: OsagoFilterParams) => {
    await this.setPersistFilter(filter);
    await this.fetchOsagoList();
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchOsagoList();
  };

  handlePrint = async () => {
    try {
      const filter = this.props.persistFilters[this.state.filterPath];
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print',
      });
      await osagoApi.printReport(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  //TODO: переделать, временное решения для проверки
  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filterPath } =
      this.state;
    const filter = this.props.persistFilters[filterPath];
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100vh',
        }}
      >
        <Header
          left={<h1>Полисы ОСАГО</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canAdd() && (
                <Link to="/services/osagos/new">
                  <Button type="primary" data-cy="add">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'auto',
          }}
        >
          <OsagoFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            style={
              {
                // flex: '1 1 auto',
              }
            }
          />
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            fetch={this.fetchOsagoList}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            loading={loading}
            columns={this.columns}
            data={data.map((item) => ({
              ...item,
              key: item.id,
            }))}
            scroll={{
              x: 'auto',
              y: 'auto',
            }}
            style={{
              position: 'relative',
              flex: '1',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            sticky
          />
        </Section>
      </div>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters,
  }),
  {
    setFilter,
  }
)(withUserAccess(List));
