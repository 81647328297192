import _cloneDeep from 'lodash/cloneDeep';
import type { MonitoringVehicleHistoryItem } from '../../../lib/types/monitoringVehicle';

export const getLinesGroups = (
  history: MonitoringVehicleHistoryItem[] = []
) => {
  const intervals = [];
  const emptyInterval = {
    status: null,
    coords: []
  };
  let lastInterval = _cloneDeep(emptyInterval);
  history.forEach((item, index) => {
    const nextItem = history[index + 1];
    if (!nextItem || (nextItem && nextItem.status !== item.status)) {
      const {
        geoPosition: { lat, lng }
      } = item;
      if (nextItem) {
        const {
          geoPosition: { lat, lng }
        } = nextItem;
        lastInterval.coords.push([lat, lng]);
      }
      lastInterval.coords.push([lat, lng]);
      intervals.push(lastInterval);
      lastInterval = _cloneDeep(emptyInterval);
      return;
    }
    if (item.status === nextItem.status) {
      if (!lastInterval.status) lastInterval.status = item.status;
      const {
        geoPosition: { lat, lng }
      } = item;
      lastInterval.coords.push([lat, lng]);
    }
  });
  return intervals;
};
