//@flow
import { LoadingOutlined, UserAddOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Upload, notification } from 'antd';
import {
  RcFile,
  UploadChangeParam,
  UploadFile
} from 'antd/lib/upload/interface';
import styled from 'styled-components';

import type { FileTag } from '../../lib/types';
import { getBase64 } from '../../lib/helpers';
import { apiUrl } from '../../lib/api';
import tokenManager from '../../lib/tokenManager';

type ImageUploaderProps = {
  tag: FileTag,
  onUploaded: (file: UploadFile, localImageUrl: string) => any,
  url?: string,
  name?: 'file' | 'photo'
};

const StyledUpload = styled(Upload)`
  .ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
  }
`;
export const ImageUploader = (props: ImageUploaderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();

  const beforeUpload = (file: RcFile) => {
    const isCorrectSize = file.size / 1024 / 1024 < 10;
    !isCorrectSize &&
      notification.error({ message: 'Файл должен быть менее 10MB!' });

    return isCorrectSize;
  };

  const onChange = async (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }

    if (info.file.status === 'done') {
      setIsLoading(false);
      setImageUrl(await getBase64(info.file.originFileObj));
      props.onUploaded &&
        (await props.onUploaded(
          info.file.response,
          await getBase64(info.file.originFileObj)
        ));
      return;
    }

    if (info.file.status === 'error') {
      notification.error({
        message: 'Не удалось загрузить файл',
        description: info.file.error
      });
    }
  };

  const UploadButton = () => (
    <div>
      {isLoading ? <LoadingOutlined /> : <UserAddOutlined />}
      <div className="ant-upload-text">
        {isLoading ? 'Идет загрузка...' : 'Загрузить фото'}
      </div>
    </div>
  );

  return (
    <StyledUpload
      accept=".jpeg,.jpg,.png,.gif,.webp,.svg"
      action={props.url || `${apiUrl}/file`}
      beforeUpload={beforeUpload}
      data={{ tag: props.tag }}
      listType="picture-card"
      headers={{
        Authorization: `Bearer ${tokenManager.getToken()}`
      }}
      showUploadList={false}
      onChange={onChange}
      name={props.name || 'photo'}
    >
      {!imageUrl ? (
        <UploadButton />
      ) : (
        <div>
          <img
            src={imageUrl}
            alt=""
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      )}
    </StyledUpload>
  );
};
