// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import moment from 'moment';
import notification from 'antd/lib/notification';
import isEqual from 'lodash/isEqual';
import Input from 'antd/lib/input';
import { DatePicker } from '../../../components/ant/DatePicker';

import Grid, { GridItem } from '../../../components/layout/Grid';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import type {
  TireMeasurement as TireMeasurementType,
  Tire,
} from './../../../lib/types';
import { tireMeasurementApi } from '../../../lib/api';
import { fetchVehicle } from '../../../ducks/vehicle';
import { Card } from './../../../components';
import { notificationLoading } from '../../../components/Notifications';
import { InputNumber } from '../../../components/inputs';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const { Field } = Card;
const { TextArea } = Input;
type Props = {
  tire: Tire,
  vehicleId: number,
  fetchVehicle: Function,
  onSuccessSubmit?: () => Promise<void>,
};

type State = {
  modalVisible: boolean,
  tireMeasurement: TireMeasurementType,
};

class TireMeasurementForm extends Component<Props, State> {
  state = {
    modalVisible: false,
    tireMeasurement: {
      date: moment().toISOString(),
      vehicleId: this.props.vehicleId,
      newTreadDepthValue: (this.props.tire && this.props.tire.treadDepth) || 0,
      document: '',
    },
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.tire, this.props.tire)) {
      this.setState((prevState) => ({
        tireMeasurement: {
          ...prevState.tireMeasurement,
          newTreadDepthValue: this.props.tire.treadDepth || 0,
        },
      }));
    }
  }

  hideModal = () =>
    this.setState({
      modalVisible: false,
    });

  onSubmit = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const { onSuccessSubmit } = this.props;
      await tireMeasurementApi.addTireMeasurement({
        ...this.state.tireMeasurement,
        tireId: this.props.tire.id,
      });
      if (this.props.vehicleId) {
        await this.props.fetchVehicle(this.props.vehicleId);
      }
      notification.success({
        message: 'Контрольный замер зафиксирован',
      });
      if (onSuccessSubmit instanceof Function) {
        await onSuccessSubmit();
      }
      this.hideModal();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  onChange = (key: string, value: any) =>
    this.setState((prevState) => ({
      tireMeasurement: {
        ...prevState.tireMeasurement,
        [key]: value,
      },
    }));

  disabledDate = (value: string | moment) => moment().isBefore(moment(value));

  render() {
    const { tireMeasurement } = this.state;
    const { newTreadDepthValue, date, document } = tireMeasurement;
    return (
      <>
        <Button onClick={() => this.setState({ modalVisible: true })}>
          Контрольный замер высоты протектора
        </Button>
        <Modal
          title="Контрольный замер высоты протектора шины"
          visible={this.state.modalVisible}
          onOk={this.onSubmit}
          onCancel={this.hideModal}
        >
          <ModalContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Высота протектора, мм">
                  <InputNumber
                    onChange={(value) =>
                      this.onChange('newTreadDepthValue', value)
                    }
                    value={newTreadDepthValue}
                    precision={0}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата замера">
                  <DatePicker
                    disabledDate={this.disabledDate}
                    format="DD.MM.YYYY"
                    value={date ? moment.utc(date) : date}
                    onChange={(value: Object, dateString: string) => {
                      this.onChange(
                        'date',
                        formatDateTimeToISOString(value, dateString)
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem fullWidth>
                <Field label="Документ-основание">
                  <TextArea
                    onChange={(e) => this.onChange('document', e.target.value)}
                    value={document}
                  />
                </Field>
              </GridItem>
            </Grid>
          </ModalContent>
        </Modal>
      </>
    );
  }
}
export default connect(null, {
  fetchVehicle,
})(TireMeasurementForm);
