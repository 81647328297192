import React, { useCallback, useState } from 'react';

import { budgetVersionApi } from '../../lib/api';
import type { BudgetVersion } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { goBack } from '../../lib/helpers';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/budget-version/';

export default (props: PageProps) => {
  const [data: BudgetVersion, setData] = useState({ id: props.id });

  const breadCrumbs = [
    { to: '/', name: 'Главная' },
    { name: 'Справочники' },
    { to: `${entryPointPath}`, name: 'Версии бюджета' },
    ...(data.id
      ? [
          { to: `${entryPointPath}${data.id}`, name: data.id.toString() },
          { name: 'Редактирование' }
        ]
      : [{ name: 'Новая запись' }])
  ];

  const onFetch = useCallback(
    async (payload: ?any) => {
      props.id && setData(await budgetVersionApi.get(props.id));
    },
    [props.id]
  );

  const onSubmit = async (payload: BudgetVersion) => {
    let id = payload.id;
    if (id) {
      await budgetVersionApi.update(payload);
    } else {
      let data = await budgetVersionApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}${id}`);
  };

  const onCancel = async (payload: BudgetVersion) => {
    if (data.id) {
      await goBack(`${entryPointPath}${data.id}`);
    } else await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Версии бюджета'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
