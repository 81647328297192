// @flow
import React from 'react';
import styled from 'styled-components';

import { formatDateTimeToString } from './../../lib/helpers';
import { maintenanceOperationPriceApi } from './../../lib/api';
import type { MaintenanceOperationPrice } from './../../lib/types';

import { Card } from './../../components';
import Grid, { GridItem } from './../../components/layout/Grid';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Header, Panel, Section } from '../../components/layout';
import { workHourPriceTypes } from '../../lib/enum';

type Props = {
  maintenanceOperationPriceId: ?number
};
type State = {
  maintenanceOperationPrice: ?MaintenanceOperationPrice
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

export default class extends React.Component<Props, State> {
  state = {
    maintenanceOperationPrice: null
  };

  async componentDidMount() {
    const { maintenanceOperationPriceId } = this.props;
    if (maintenanceOperationPriceId) {
      const maintenanceOperationPrice = await maintenanceOperationPriceApi.getMaintenanceOperationPrice(
        maintenanceOperationPriceId
      );
      this.setState({ maintenanceOperationPrice });
    }
  }

  render() {
    const { maintenanceOperationPrice } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/maintenance-operation-price">
                Справочник прейскурантов стоимости нормо-часа
              </Crumb>
              {maintenanceOperationPrice && (
                <Crumb>
                  Прейскурант стоимости нормо-часа №
                  {maintenanceOperationPrice.id}
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceOperationPrice &&
              `Деталь №${maintenanceOperationPrice.id}`}
          </h1>
        </StyledPanel>
        <Section>
          {maintenanceOperationPrice && (
            <SectionContent>
              <GridItem>
                <Field label="Вид нормочаса">
                  {
                    workHourPriceTypes[
                      maintenanceOperationPrice.workingHourPriceType
                    ]
                  }
                </Field>
              </GridItem>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Дата начала">
                    {formatDateTimeToString(
                      maintenanceOperationPrice.startDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания">
                    {maintenanceOperationPrice.endDate
                      ? formatDateTimeToString(
                          maintenanceOperationPrice.endDate,
                          'DD.MM.YYYY'
                        )
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Филиал">
                    {maintenanceOperationPrice.orgUnitName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Стоимость">
                    {maintenanceOperationPrice.workingHourCost.toLocaleString(
                      'ru-RU',
                      {
                        style: 'currency',
                        currency: 'RUB'
                      }
                    )}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}
