// @flow
import React, { useState } from 'react';
import Input from 'antd/lib/input';

type Props = {
  handleBlur: Function,
  value: ?string
};

export default ({ handleBlur, value }: Props) => {
  const [val, setValue] = useState(value);
  return (
    <Input
      value={val}
      onBlur={() => handleBlur(val)}
      onChange={e => setValue(e.target.value)}
    />
  );
};
