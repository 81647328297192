import styled from 'styled-components';
import { GridItem } from '../../../../../../components/layout';

export const Wrapper = styled.footer`
  min-height: 40px;
  border-top: 1px solid #c0ccd7;
  display: flex;
  align-items: center;
`;

export const LeftSide = styled(GridItem)`
  border-right: 1px solid #c0ccd7;
  margin-right: 10px;
`;
