// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { sparePartsApi } from './../../lib/api';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';
import type { VehicleModel, SpareParts } from '../../lib/types';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import addAccessRight from './accessRight';

const entryPointPath = '/admin/spare-parts/';

const columns = [
  {
    title: 'Название',
    dataIndex: 'name',
    className: 'vertical-align-top',
  },
  {
    title: 'Каталожный номер',
    dataIndex: 'catalogNumber',
    className: 'vertical-align-top',
  },
  {
    title: 'Стоимость руб. с НДС',
    width: '100px',
    dataIndex: 'price',
    className: 'vertical-align-top',
  },
  {
    title: 'Единица измерения',
    width: '100px',
    dataIndex: 'unit.alias',
    className: 'vertical-align-top',
  },
  {
    title: 'Применяемость',
    dataIndex: 'vehicleModels',
    width: '400px',
    className: 'vertical-align-top',
    render: (vehicleModels: VehicleModel[], record: SpareParts) => {
      const data = vehicleModels
        .map(
          (vehicleModel: VehicleModel) =>
            `${vehicleModel.name} ${vehicleModel.brandName}`
        )
        .join(', ');
      return record.allVehicles ? (
        'Все модели'
      ) : (
        <div style={{ whiteSpace: 'pre-wrap' }}>{data}</div>
      );
    },
  },
  {
    title: 'Примечание',
    width: '300px',
    dataIndex: 'note',
    className: 'vertical-align-top',
  },
];

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const access = {
  add: () => navigate(`${entryPointPath}new`),
};

export default withUserAccess(({ userAccess }) => {
  const canAdd = () =>
    userAccess.some((access) => addAccessRight.includes(access));
  return (
    <Page>
      <CommonListPage
        crud={sparePartsApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Запасные части',
          access: canAdd() ? access : undefined,
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canAdd(),
        }}
        filter={Filter}
      />
    </Page>
  );
});
