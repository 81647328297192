// @flow
import { LinkOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';

type Props = {
  onClick: Function
};

const LinkIcon = styled(({ onClick }: Props) => (
  <LinkOutlined
    onClick={e => {
      e.stopPropagation();
      onClick && onClick();
    }}
  />
))`
  transition: background 0.3s;
  color: #2770ff;
  background: #f0f4f8;
  border-radius: 5px;
  padding: 5px;
  &:hover {
    background: #e4ebf2;
  }
`;

export default LinkIcon;
