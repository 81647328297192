// @flow

import React from 'react';
import { navigate } from '@reach/router';
import { oilsAndFluidsConsumptionPlanApi } from './../../../lib/api';
import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../../withUserAccess';
import { formatDateTimeToString } from '../../../lib/helpers';
import { default as Filter } from './components/ListFilter';
import type { TechnicalFluidStatusType } from '../../../lib/types';
import { accessTypeEnum, technicalFluidStatusTypes } from '../../../lib/enum';
import PrintButton from './components/PrintButton';

const entryPointPath = '/budget/oils-and-fluids-consumption-plan/';

const columns = [
  {
    title: 'Период проведения',
    dataIndex: 'period',
    render: (month: string) => formatDateTimeToString(month, 'MMMM YYYY')
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (status: TechnicalFluidStatusType) =>
      technicalFluidStatusTypes[status]
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const canAdd = () =>
    userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOilsAndFluidsConsumptionPlan
      ].includes(access)
    );
  return (
    <Page>
      <CommonListPage
        crud={oilsAndFluidsConsumptionPlanApi}
        pageHeaderProps={{
          mainHeader: 'План расхода масел и тех.жидкостей',
          access: canAdd() ? access : undefined,
          rightHeader: <PrintButton />
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}edit/${id}`),
          canDelete: canAdd()
        }}
        filter={Filter}
        filterMixin={{ orderBy: 'period', byDescending: 'true' }}
      />
    </Page>
  );
});
