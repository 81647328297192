// @flow
import type {
  CalculationStatus,
  TireCalculation,
  TireCalculationMonth
} from './../types';

import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  tireCalculation: TireCalculation
): TireCalculation => ({
  ...tireCalculation,
  sum: convertFromRubToKop(tireCalculation.sum),
  months: tireCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  tireCalculation: TireCalculation
): TireCalculation => ({
  ...tireCalculation,
  sum: convertFromKopToRub(tireCalculation.sum),
  months: tireCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  tireCalculationMonth: TireCalculationMonth
): TireCalculationMonth => ({
  ...tireCalculationMonth,
  cost: convertFromRubToKop(tireCalculationMonth.cost)
});

export const convertDataFromServerMonth = (
  tireCalculationMonth: TireCalculationMonth
): TireCalculationMonth => ({
  ...tireCalculationMonth,
  cost: convertFromKopToRub(tireCalculationMonth.cost)
});

const basedUrl = 'tireCalculation';
const tireCalculation = new CommonApi<TireCalculation>(
  basedUrl,
  'расчет автошин',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

export default {
  ...tireCalculation,
  calculate,
  changeStatus
};
