// @flow
import React from 'react';
import { navigate } from '@reach/router';
import { overhaulTypeApi } from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from './../withUserAccess';

import { Filter } from './components';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const entryPointPath = '/admin/overhaul-types/';

const columns = [
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModelName'
  },
  {
    title: 'Виды работ по капитальному ремонту',
    dataIndex: 'vehicleIssueGroupName'
  },
  {
    title: 'Кол-во каждой запасной части',
    dataIndex: 'mtrs',
    render: mtrs => {
      return mtrs?.reduce((sum, el) => sum + el.count, 0);
    }
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

  return (
    <Page>
      <CommonListPage
        crud={overhaulTypeApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Виды работ по капитальному ремонту ТС',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: true
        }}
        filter={Filter}
      />
    </Page>
  );
});
