// @flow

import type { Dispatch } from 'redux';

import type { Contractor } from './../lib/types';
import { contractorApi } from './../lib/api';
import type { Action, AppState } from './redux';

export const FETCH_CONTRACTOR = 'vehicles/contractor/fetch';
export const UPDATE_CONTRACTOR = 'vehicles/contractor/update';
export const CLEAN_CONTRACTOR = 'vehicles/contractor/clean';

export type ContractorState = ?Contractor;

const initialState: ContractorState = null;

const reducer = (
  state: ContractorState = initialState,
  { type, payload }: Action
): ContractorState => {
  switch (type) {
    case FETCH_CONTRACTOR:
    case UPDATE_CONTRACTOR:
      return { ...payload };
    case CLEAN_CONTRACTOR:
      return null;
    default:
      return state;
  }
};

export const addContractor: Function = (
  contractor: Contractor
): Function => async (dispatch: Dispatch): Promise<void> => {
  await contractorApi.addContractor(contractor);
};

export const updateContractor: Function = (
  contractor: Contractor
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const updated = await contractorApi.updateContractor(contractor);
  dispatch({
    type: UPDATE_CONTRACTOR,
    payload: {
      ...updated
    }
  });
};

export const fetchContractor = (id: number) => async (dispatch: Dispatch) => {
  let contractor = await contractorApi.fetchContractor(id);
  dispatch({
    type: FETCH_CONTRACTOR,
    payload: {
      ...contractor
    }
  });
};

export const cleanContractor = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAN_CONTRACTOR
  });

export default reducer;
