import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { WashingPriceList } from './../types';
import { convertFromRubToKop, convertFromKopToRub } from './../helpers';

export const convertDataToServer = (data: WashingPriceList) => ({
  ...data,
  washingPrices: data.washingPrices.map(value => ({
    ...value,
    bodyPrice: convertFromRubToKop(value.bodyPrice),
    interiorPrice: convertFromRubToKop(value.interiorPrice),
    enginePrice: convertFromRubToKop(value.enginePrice)
  }))
});

export const convertDataFromServer = (data: WashingPriceList) => ({
  ...data,
  washingPrices: data.washingPrices.map(value => ({
    ...value,
    bodyPrice: convertFromKopToRub(value.bodyPrice),
    interiorPrice: convertFromKopToRub(value.interiorPrice),
    enginePrice: convertFromKopToRub(value.enginePrice)
  }))
});

export const addWashingPrice = async (
  washingPrice: WashingPriceList
): Promise<void> => {
  const added = await fetchRequest.post(
    '/washingPriceList',
    convertDataToServer(washingPrice)
  );
  if (added) return added;
  throw new Error('Не удалось создать прейскурант');
};

export const fetchWashingPrices = async (
  params: FetchListParams<> = initialFetchListParams
): Promise<ListResponse<WashingPriceList>> => {
  const prices = await fetchRequest.get('/washingPriceList', {
    ...initialFetchListParams,
    ...params
  });
  if (prices) {
    return {
      ...prices,
      data: prices.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список прейскуранта');
};

export const updateWashingPrice = async (
  washingPrice: WashingPriceList
): Promise<WashingPriceList> => {
  const updated = await fetchRequest.put(
    '/washingPriceList',
    convertDataToServer(washingPrice)
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить прейскурант');
};

export const fetchWashingPrice = async (
  id: number
): Promise<ListResponse<WashingPriceList>> => {
  let washingPrice = await fetchRequest.get(`/washingPriceList/${id}`);
  if (washingPrice) return convertDataFromServer(washingPrice);
  throw new Error('Не удалось загрузить прейскурант');
};

export const deleteWashingPrice = async (
  id: number
): Promise<WashingPriceList> => {
  const deleted = await fetchRequest.delete(`/washingPriceList/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить прейскурант');
};

export default {
  fetchWashingPrices,
  fetchWashingPrice,
  deleteWashingPrice,
  addWashingPrice,
  updateWashingPrice
};
