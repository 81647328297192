import {
    PddTestRegisterForm,
    PddTestRegisterList,
} from '../../containers/pdd/Test/Register';
import { PddGroupForm, PddGroupList } from '../../containers/pdd/Group';
import { PddTicketForm, PddTicketList } from '../../containers/pdd/Ticket';
import {
    PddQuestionCard,
    PddQuestionForm,
    PddQuestionList,
} from '../../containers/pdd/Question';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: PddGroupList,
        path: '/admin/pdd/groups',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddGroupForm,
        path: '/admin/pdd/groups/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddGroupForm,
        path: '/admin/pdd/groups/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddTicketList,
        path: '/admin/pdd/tickets',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddTicketForm,
        path: '/admin/pdd/tickets/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddTicketForm,
        path: '/admin/pdd/tickets/edit/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddTicketForm,
        path: '/admin/pdd/tickets/edit/:id/:pddTestId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddTicketForm,
        path: '/admin/pdd/tickets/new/:pddTestId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddQuestionList,
        path: '/admin/pdd/questions',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddQuestionForm,
        path: '/admin/pdd/questions/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddQuestionForm,
        path: '/admin/pdd/questions/edit/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddQuestionCard,
        path: '/admin/pdd/questions/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddQuestions,
        ],
    },
    {
        component: PddTestRegisterList,
        path: '/admin/pdd/test/register',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddTestRegisterForm,
        path: '/admin/pdd/test/register/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
    {
        component: PddTestRegisterForm,
        path: '/admin/pdd/test/register/edit/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingPddTests,
        ],
    },
];