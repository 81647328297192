// @flow
import React, { useMemo } from 'react';
import Tooltip from 'antd/lib/tooltip';

import { Wrapper, LeftSide } from './styles';
import Grid, { GridItem } from '../../../../../../components/layout/Grid';
import { Icon } from '../../../../../../components/ui';
import type { Driver } from '../../../../../../lib/types';
import { convertDriverToString } from '../../../../../../lib/helpers';
import { getStatusIcon } from '../../../../utils';
import type { MonitoringVehicle } from '../../../../../../lib/types/monitoringVehicle';
import { Signal } from '../';

type Props = {
  driver: Driver,
  vehicle: MonitoringVehicle
};

export const Footer = ({ driver, vehicle }: Props) => {
  const { type, color, title } = getStatusIcon(vehicle);

  const cols = useMemo(() => {
    return new Array(6).fill('32px');
  }, []);

  return (
    <Wrapper>
      <Grid cols={['1fr', '300px']} style={{ height: '100%' }}>
        <LeftSide>
          <Grid cols={cols} gutter="14px" style={{ padding: 16 }}>
            <GridItem align={{ vertical: 'center', horizontal: 'center' }}>
              <Tooltip title={title}>
                <Icon type={type} size={16} color={color} />
              </Tooltip>
            </GridItem>
            <Tooltip title="Активных спутников">
              <GridItem align={{ vertical: 'center', horizontal: 'center' }}>
                <Icon
                  type="satellite"
                  size={16}
                  color="#27AE60"
                  style={{ marginRight: 5 }}
                />
                <span
                  style={{ fontWeight: 'bold', fontSize: 10, color: '#3D4042' }}
                >
                  {vehicle.satellitesCount}
                </span>
              </GridItem>
            </Tooltip>
            <Tooltip title="Уровень сигнала">
              <GridItem align={{ vertical: 'center', horizontal: 'center' }}>
                <Signal level={vehicle.signalLevel} />
              </GridItem>
            </Tooltip>
            <Tooltip title="Вольтаж">
              <GridItem align={{ vertical: 'center', horizontal: 'center' }}>
                <Icon type="voltage" size={16} color="#27AE60" />
                <span
                  style={{ fontWeight: 'bold', fontSize: 10, color: '#3D4042' }}
                >
                  {vehicle.voltage}
                </span>
              </GridItem>
            </Tooltip>
            <Tooltip
              title={`(${vehicle?.geoPosition?.lat ?? ''}, ${vehicle
                ?.geoPosition?.lng ?? ''})`}
            >
              <GridItem align={{ vertical: 'center', horizontal: 'center' }}>
                <Icon type="pin" size={16} />
              </GridItem>
            </Tooltip>
          </Grid>
        </LeftSide>
        <GridItem align={{ vertical: 'center' }}>
          <Icon
            type="user"
            style={{ marginRight: 10 }}
            size={16}
            color="black"
          />
          {convertDriverToString(driver, 'Неизвестно')}
        </GridItem>
      </Grid>
    </Wrapper>
  );
};
