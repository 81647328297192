// @flow

import React, { Component } from 'react';
import { inspectionGtnCalculationApi } from '../../../../lib/api';
import { plus, toLocalStringRu } from '../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';
import { COLUMNS, MONTH } from '../lib';
import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
};

export default class extends Component<Props, {}> {
  columns = [
    ...COLUMNS,
    ...MONTH.map((month, index) => ({
      title: month.title,
      width: '150',
      dataIndex: `months[${index}].cost`,
      render: (cost: number) => toLocalStringRu(cost),
    })),
    {
      title: 'Пошлина',
      dataIndex: 'taxSum',
      render: (taxCost: number) => toLocalStringRu(taxCost),
    },
    {
      title: 'Работа',
      dataIndex: 'workSum',
      render: (workCost: number) => toLocalStringRu(workCost),
    },
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) => toLocalStringRu(sum),
    },
  ];

  itogCalculation = (data: any): any => {
    const itog = {
      months: Array(12)
        .fill()
        .map(() => ({ cost: 0 })),
      sum: 0,
      taxSum: 0,
      workSum: 0,
    };
    data.forEach((item: any) => {
      itog.sum = plus(itog.sum, item.sum);
      itog.taxSum = plus(itog.taxSum, item.taxSum);
      itog.workSum = plus(itog.workSum, item.workSum);
      item.months.forEach((month: any, index) => {
        if (index < 12)
          itog.months[index].cost = plus(itog.months[index].cost, month.cost);
      });
    });
    return itog;
  };

  render() {
    const { location, vehiclePlanId } = this.props;
    return (
      <Common
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={inspectionGtnCalculationApi}
        calcScrollY={400}
        statusField={'inspectionGtnCalculationStatus'}
        title={'Планирование ТО Гостехнадзор'}
        columns={this.columns}
        itogCalculation={this.itogCalculation}
        handlePrint={(filter) => {
          printNotification(async () => {
            await inspectionGtnCalculationApi.print(vehiclePlanId, filter);
          });
        }}
      />
    );
  }
}
