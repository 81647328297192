// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { FuelPrice } from './../../../lib/types';
import { fuelPriceApi } from './../../../lib/api';
import { goBack } from './../../../lib/helpers';

import { Crumb } from './../../../components/layout/Breadcrumbs';
import Breadcrumbs from './../../../components/layout/Breadcrumbs';
import { Header, Panel } from './../../../components/layout';
import InnerForm from './components/InnerForm';
import { notificationLoading } from './../../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  fuelPriceId: number
};

type State = {
  fuelPrice: ?FuelPrice
};

export class FuelPriceForm extends React.Component<Props, State> {
  state = {
    fuelPrice: null
  };

  async componentDidMount() {
    const { fuelPriceId } = this.props;
    try {
      if (fuelPriceId) {
        const fuelPrice = await fuelPriceApi.getFuelPrice(fuelPriceId);
        this.setState({ fuelPrice });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: FuelPrice) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await fuelPriceApi.updateFuelPrice(values);
      } else {
        await fuelPriceApi.addFuelPrice(values);
      }
      navigate(`/admin/fuel-price`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/admin/stock-part');

  render() {
    const { fuelPrice } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/fuel-price">Перечень прейскурантов ГСМ</Crumb>
              {fuelPrice ? (
                <Crumb>Прейскурант ГСМ №{fuelPrice.id}</Crumb>
              ) : (
                <Crumb>Новый прейскурант ГСМ</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {fuelPrice
              ? `Прейскурант ГСМ №${fuelPrice.id}`
              : 'Новый прейскурант ГСМ'}
          </h1>
        </StyledPanel>
        <InnerForm
          fuelPrice={fuelPrice}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default FuelPriceForm;
