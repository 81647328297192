// @flow
import React, { useState } from 'react';
import Input from 'antd/lib/input';

import type {
  FormErrors,
  OperationLimit,
  OperationLimitGroup,
  VehicleOvbType,
} from '../../../../lib/types';
import { operationLimitTypeEnum, vehicleOvbTypes } from '../../../../lib/enum';
import { convertJobTitleToString } from '../../../../lib/helpers';
import { Selects } from '../../../../components';
import { Popconfirm } from '../../../../components/ui';
import { InputNumber } from '../../../../components/inputs';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';

import { formatLimitValue } from '../../lib';
import { Cell, Row } from '../../elements';

import { validateOperationLimit } from '../lib';
import { DeleteCell, DeleteIcon, FormItem, getGridTemplate } from './elements';

const { JobTitleSelect, VehicleOvbTypeSelect } = Selects;

type Props = {
  operationLimit: OperationLimit,
  // Функция изменения значения
  onChange: Function,
  // Функция удаления значения
  onDelete: Function,
  // Индекс строки
  index: number,
  // Является ли эта строка пустой
  isEmptyRow: boolean,
  // Является ли редактируемым
  editable: boolean,
  // Группа, к которой относится лимит
  group: OperationLimitGroup,
};

export default ({
  operationLimit,
  onChange,
  onDelete,
  index,
  isEmptyRow,
  editable,
  group,
}: Props) => {
  const [errors, setErrors] = useState<FormErrors<OperationLimit>>({});

  const validate = (operationLimit: OperationLimit) => {
    // Если это не пустая строка, то валидируем
    if (!isEmptyRow) {
      const errors = validateOperationLimit(operationLimit, group);
      setErrors(errors);
    }
  };

  const handleChangeValue = (value: $Shape<OperationLimit>) => {
    const copy = { ...operationLimit, ...value };
    validate(copy);
    onChange(copy, index);
  };

  return (
    <Row style={{ padding: 0 }} media={getGridTemplate(operationLimit.type)}>
      {operationLimit.type === operationLimitTypeEnum.employee && (
        <Cell style={{ overflow: 'hidden' }}>
          {editable ? (
            <FormItem
              name="jobTitleId"
              validateStatus={errors.jobTitleId && 'error'}
              help={errors.jobTitleId}
              hasFeedback
            >
              <JobTitleSelect
                size="small"
                placeholder="Выберите должность"
                value={operationLimit.jobTitleId}
                onChange={(jobTitleId) =>
                  handleChangeValue({
                    jobTitleId,
                  })
                }
              />
            </FormItem>
          ) : (
            convertJobTitleToString(operationLimit.jobTitle)
          )}
        </Cell>
      )}
      {operationLimit.type === operationLimitTypeEnum.ovb && (
        <>
          <Cell>
            {editable ? (
              <FormItem
                name="ovbName"
                validateStatus={errors.ovbName && 'error'}
                help={errors.ovbName}
                hasFeedback
              >
                <Input
                  value={operationLimit.ovbName}
                  size="small"
                  placeholder="Наименование ОВБ"
                  onChange={({ target: { value: ovbName } }) =>
                    handleChangeValue({
                      ovbName,
                    })
                  }
                />
              </FormItem>
            ) : (
              operationLimit.ovbName
            )}
          </Cell>
          <Cell style={{ overflow: 'hidden' }}>
            {editable ? (
              <FormItem
                name="ovbType"
                validateStatus={errors.ovbType && 'error'}
                help={errors.ovbType}
                hasFeedback
              >
                <VehicleOvbTypeSelect
                  size="small"
                  value={operationLimit.ovbType}
                  placeholder="Выберите тип ОВБ"
                  onChange={(ovbType) => {
                    const getHours = (ovbType: VehicleOvbType) => {
                      switch (ovbType) {
                        case 'forEightHour':
                          return 8;
                        case 'forTwelveHour':
                          return 12;
                        case 'forWholeDay':
                        default:
                          return 24;
                      }
                    };
                    const hours = getHours(ovbType);
                    handleChangeValue({
                      ovbType,
                      hours,
                      hoursWeekend: hours,
                    });
                  }}
                />
              </FormItem>
            ) : (
              vehicleOvbTypes[operationLimit.ovbType]
            )}
          </Cell>
        </>
      )}
      {operationLimit.type !== operationLimitTypeEnum.employee && (
        <Cell style={{ overflow: 'hidden' }}>
          {editable ? (
            <FormItem
              name="orgUnitId"
              validateStatus={errors.orgUnitId && 'error'}
              help={errors.orgUnitId}
            >
              <OrgUnitSelect
                size="small"
                value={operationLimit.orgUnitId}
                placeholder="Выберите службу"
                onChange={(orgUnitId) =>
                  handleChangeValue({
                    orgUnitId,
                  })
                }
              />
            </FormItem>
          ) : (
            operationLimit.orgUnitName
          )}
        </Cell>
      )}
      <Cell>
        {editable ? (
          <FormItem
            name="hours"
            validateStatus={errors.hours && 'error'}
            help={errors.hours}
          >
            <InputNumber
              max={group.hours}
              size="small"
              placeholder="Часы"
              value={operationLimit.hours}
              onBlur={() => validate(operationLimit)}
              disabled={operationLimit.type === operationLimitTypeEnum.ovb}
              onChange={(hours) => {
                handleChangeValue({
                  hours,
                });
              }}
              min={1}
            />
          </FormItem>
        ) : (
          formatLimitValue('hours', operationLimit.hours)
        )}
      </Cell>
      {operationLimit.type !== operationLimitTypeEnum.ovb && (
        <Cell>
          {editable ? (
            <FormItem
              name="hoursWeekend"
              validateStatus={errors.hoursWeekend && 'error'}
              help={errors.hoursWeekend}
            >
              <InputNumber
                disabled={!group.usedInWeekends}
                placeholder="Часы"
                max={group.hoursWeekend}
                size="small"
                value={operationLimit.hoursWeekend}
                onBlur={() => validate(operationLimit)}
                onChange={(hoursWeekend) =>
                  handleChangeValue({
                    hoursWeekend,
                  })
                }
                min={1}
              />
            </FormItem>
          ) : (
            formatLimitValue('hoursWeekend', operationLimit.hoursWeekend)
          )}
        </Cell>
      )}
      <Cell>
        {editable ? (
          <FormItem
            name="distance"
            validateStatus={errors.distance && 'error'}
            help={errors.distance}
          >
            <InputNumber
              placeholder="Км"
              size="small"
              value={operationLimit.distance}
              onBlur={() => validate(operationLimit)}
              onChange={(value) => {
                handleChangeValue({
                  distance: value,
                  distanceWeekend:
                    operationLimit.type === operationLimitTypeEnum.ovb
                      ? value
                      : operationLimit.distanceWeekend,
                });
              }}
              min={1}
            />
          </FormItem>
        ) : (
          formatLimitValue('distance', operationLimit.distance)
        )}
      </Cell>
      {operationLimit.type !== operationLimitTypeEnum.ovb && (
        <Cell>
          {editable ? (
            <FormItem
              name="distanceWeekend"
              validateStatus={errors.distanceWeekend && 'error'}
              help={errors.distanceWeekend}
            >
              <InputNumber
                placeholder="Км"
                size="small"
                disabled={!group.usedInWeekends}
                value={operationLimit.distanceWeekend}
                onBlur={() => validate(operationLimit)}
                min={1}
                onChange={(distanceWeekend) =>
                  handleChangeValue({
                    distanceWeekend,
                  })
                }
              />
            </FormItem>
          ) : (
            formatLimitValue('distanceWeekend', operationLimit.distanceWeekend)
          )}
        </Cell>
      )}
      {editable && (
        <DeleteCell>
          {!isEmptyRow &&
            (operationLimit.id ? (
              <Popconfirm
                onConfirm={async () => await onDelete(index)}
                title="Вы уверены, что хотите удалить лимит?"
              >
                <DeleteIcon />
              </Popconfirm>
            ) : (
              <DeleteIcon onClick={async () => await onDelete(index)} />
            ))}
        </DeleteCell>
      )}
    </Row>
  );
};
