// @flow
import React from 'react';

import notification from 'antd/lib/notification';

import { passApi } from './../../../../../lib/api';
import { Header, Section } from './../../../../../components/layout';
import { GroupListTable } from './../../lib';

import { Tabs, Filter, type ExecutionBudgetFilterParams } from '../';

export default class extends GroupListTable<any, ExecutionBudgetFilterParams> {
  fetch = async (page: number = 1) => {
    const params = {
      ...super.fetch(page)
    };
    try {
      const data = await passApi.groupedList(params);
      this.setState({ ...data, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        <Header left={<h1>Исполнение бюджета</h1>} />
        <Tabs />
        <Section>
          {this.renderFilter(Filter)}
          {this.renderTable()}
        </Section>
      </>
    );
  }
}
