// @flow
import React, { useEffect, useState, type Node } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import Breadcrumbs, { Crumb } from '../../../layout/Breadcrumbs';
import { Header, Panel } from '../../../layout';
import { Icon, Popconfirm } from '../../../ui';
import { notificationLoading } from '../../../Notifications';
import ButtonsRow from '../../../ui/ButtonsRow';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const OperationIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
  width: 16px;
  height: 16px;
  & img {
    width: 100%;
  }
`;

/**
 * Ссылка вверху страницы
 */
type CrumbLink = {
  // гиперссылка
  to?: string,
  // наименование
  name: string,
};

/**
 * Разрешения на действие, если поле не инициализировано, то доступ запрещен, соответствующий объект-манимулятор не отображается
 */
type AccessProps = {
  edit?: Function,
  add?: Function,
  delete?: Function,
};

/**
 * Пропсы заголовка страницы
 */
export type PageHeaderProps = {
  access?: AccessProps,
  breadCrumbs?: Array<CrumbLink>,
  // заголовок страницы
  mainHeader?: string | Node,
  // дополнительный левый header
  leftHeader?: any,
  // дополнительный правый header
  rightHeader?: any,
};

export default (props: PageHeaderProps) => {
  // Хук удаления текущей записи
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    if (deleting) {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
    } else {
      notification.close('deleting');
    }
    return () => {
      notification.close('deleting');
    };
  }, [deleting]);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      // props.access.delete && (await props.access.delete());
      // eslint-disable-next-line no-unused-expressions
      props.access?.delete?.();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    setDeleting(false);
  };

  const breadCrumbs =
    props.breadCrumbs &&
    props.breadCrumbs.map((el, i) =>
      el?.to ? (
        <Crumb to={el.to} key={i}>
          {el.name}
        </Crumb>
      ) : null
    );

  const header = (
    <h1>
      {props.mainHeader}
      {props.access && props.access.edit && (
        <OperationIcon onClick={props.access.edit} type="edit" size={16} />
      )}
    </h1>
  );

  return (
    <>
      <Header
        left={
          <>
            {breadCrumbs && <Breadcrumbs>{breadCrumbs}</Breadcrumbs>}
            {!breadCrumbs && header}
            {props.leftHeader}
          </>
        }
        right={
          <ButtonsRow>
            {props.rightHeader}
            {props.access && props.access.add && (
              <Button
                data-cy="addStou"
                type="primary"
                onClick={props.access.add}
              >
                Создать
              </Button>
            )}
            {props.access && props.access.delete && (
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={handleDelete}
              >
                <OperationIcon type="trash" size={16} />
              </Popconfirm>
            )}
          </ButtonsRow>
        }
      />
      {breadCrumbs && <StyledPanel>{header}</StyledPanel>}
    </>
  );
};
