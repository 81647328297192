//@flow
import moment from 'moment';
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import Field from '../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../components/hoc/common/handbook/CommonFilter';
import OrgUnitSelect from '../../../components/OrgUnitSelect';
import { ApproveYesNoSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import type { ApproveType } from '../../../lib/types';

export type MeasuringDeviceCertificationCostParamsFilter = {
  nodeId?: number,
  startDate?: string,
  endDate?: string,
  status?: ApproveType
};

export const MeasuringDeviceCertificationCostFilter = (
  props: FilterProps<MeasuringDeviceCertificationCostParamsFilter>
) => {
  const Filter = useCallback(
    (
      filterProps: $Shape<
        FilterProps<MeasuringDeviceCertificationCostParamsFilter>
      >
    ) =>
      CommonFilter<MeasuringDeviceCertificationCostParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter additionalFilter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                size="small"
                onlyBranches
                onChange={value => changeValue('nodeId', value)}
                value={values.nodeId}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ApproveYesNoSelect
                size="small"
                value={values.status}
                onChange={value => changeValue('status', value)}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default MeasuringDeviceCertificationCostFilter;
