// @flow
import type { PowerPeriodTaxRate, VehicleTaxRate } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataPowerPeriodToServer = (
  powerPeriodTaxRate: PowerPeriodTaxRate
) => ({
  ...powerPeriodTaxRate,
  taxRate: convertFromRubToKop(powerPeriodTaxRate.taxRate)
});

const convertDataPowerPeriodFromServer = (
  powerPeriodTaxRate: PowerPeriodTaxRate
) => ({
  ...powerPeriodTaxRate,
  taxRate: convertFromKopToRub(powerPeriodTaxRate.taxRate)
});

const convertDataToServer = (vehicleTaxRate: VehicleTaxRate) => ({
  ...vehicleTaxRate,
  powerPeriodTaxRates: vehicleTaxRate.powerPeriodTaxRates?.map(
    convertDataPowerPeriodToServer
  )
});

const convertDataFromServer = (vehicleTaxRate: VehicleTaxRate) => ({
  ...vehicleTaxRate,
  powerPeriodTaxRates: vehicleTaxRate.powerPeriodTaxRates?.map(
    convertDataPowerPeriodFromServer
  )
});

const vehicleTaxRate = new CommonApi<VehicleTaxRate>(
  'vehicleTaxRate',
  'Налоговые коэффициенты для ТС',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...vehicleTaxRate
};
