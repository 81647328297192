//@flow
import moment from 'moment';
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import Field from '../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../components/hoc/common/handbook/CommonFilter';
import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';

import { formatDateTimeToISOString } from '../../../lib/helpers';

export type CurrentMaintenanceCostParamsFilter = {
  vehicleModelId?: number,
  startDate?: string,
  endDate?: string
};

export const CurrentMaintenanceCostFilter = (
  props: FilterProps<CurrentMaintenanceCostParamsFilter>,
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<CurrentMaintenanceCostParamsFilter>>) =>
      CommonFilter<CurrentMaintenanceCostParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleModelSelect
                size="small"
                value={values.vehicleModelId}
                filter={{
                  returnDeleted: false
                }}
                onChange={async (value: number) =>
                  changeValue('vehicleModelId', value)
                }
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default CurrentMaintenanceCostFilter;
