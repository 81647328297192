// @flow

import React from 'react';
import { Router } from '@reach/router';

import {
  ExpertiseServiceGpmList,
  ExpertiseServiceGpmMonthList,
  InspectionsGibddList,
  InspectionsGibddMonthList,
  InspectionsGtnList,
  InspectionsGtnMonthList,
  LicensePlateChangeList,
  LicensePlateChangeMonthList,
  MaintenanceList,
  MaintenanceMonthList,
  MaintenanceCompare,
  MeasuringDeviceCertificationsList,
  MeasuringDeviceCertificationsMonthList,
  PassList,
  PassMonthList,
  PlatonList,
  PlatonMonthList,
  VehicleMonitoringList,
  VehicleMonitoringMonthList,
  WashingList,
  WashingMonthList
} from './components';

export default () => (
  <>
    <Router>
      <ExpertiseServiceGpmList path="/expertise-service-gpm" />
      <ExpertiseServiceGpmMonthList path="/expertise-service-gpm/month" />

      <InspectionsGibddList path="/inspections-gibdd" />
      <InspectionsGibddMonthList path="/inspections-gibdd/month" />

      <InspectionsGtnList path="/inspections-gtn" />
      <InspectionsGtnMonthList path="/inspections-gtn/month" />

      <LicensePlateChangeList path="/license-plate-change" />
      <LicensePlateChangeMonthList path="/license-plate-change/month" />

      <MaintenanceList path="/" />
      <MaintenanceMonthList path="/maintenance/month" />
      <MaintenanceCompare path="/maintenance/compare" />

      <MeasuringDeviceCertificationsList path="/measuring-device-certifications" />
      <MeasuringDeviceCertificationsMonthList path="/measuring-device-certifications/month" />

      <PassList path="/pass" />
      <PassMonthList path="/pass/month" />

      <PlatonList path="/platon" />
      <PlatonMonthList path="/platon/month" />

      <VehicleMonitoringList path="/vehicle-monitoring" />
      <VehicleMonitoringMonthList path="/vehicle-monitoring/month" />

      <WashingList path="/washing" />
      <WashingMonthList path="/washing/month" />
    </Router>
  </>
);
