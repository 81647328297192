import React from 'react';
import styled from 'styled-components';
import Spin from 'antd/lib/spin';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ({ isLoading, children = null }) =>
  isLoading ? (
    <SpinnerWrapper>
      <Spin />
    </SpinnerWrapper>
  ) : (
    children
  );
