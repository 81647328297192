import {
    OilsAndFluidsConsumptionPlanForm,
    OilsAndFluidsConsumptionPlanList,
} from '../../containers/budget/oilsAndFluidsConsumptionPlan';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: OilsAndFluidsConsumptionPlanList,
        path: '/budget/oils-and-fluids-consumption-plan',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingOilsAndFluidsConsumptionPlan,
            accessTypeEnum.handlingOilsAndFluidsConsumptionPlan,
        ],
    },
    {
        component: OilsAndFluidsConsumptionPlanForm,
        path: '/budget/oils-and-fluids-consumption-plan/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingOilsAndFluidsConsumptionPlan,
        ],
    },
    {
        component: OilsAndFluidsConsumptionPlanForm,
        path: '/budget/oils-and-fluids-consumption-plan/edit/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingOilsAndFluidsConsumptionPlan,
            accessTypeEnum.handlingOilsAndFluidsConsumptionPlan,
        ],
    },
];