import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import Button from 'antd/lib/button';
import React, { useCallback } from 'react';

import { driverApi } from '../../../lib/api';
import { printNotification } from '../../../lib/notificationWrapper';

export default (props: { driverId: number }) => {
  const printDriverStats = useCallback(async () => {
    printNotification(async () => {
      await driverApi.printDriverStats(props.driverId);
    });
  }, [props.driverId]);

  const printTestsHistory = useCallback(async () => {
    printNotification(async () => {
      await driverApi.printTestsHistory(props.driverId);
    });
  }, [props.driverId]);

  const handleMenuClick = useCallback(
    async ({ key }) => {
      switch (key) {
        case 'printDriverStats':
          await printDriverStats();
          break;
        case 'printTestsHistory':
          await printTestsHistory();
          break;
        default:
          break;
      }
    },
    [printDriverStats, printTestsHistory]
  );

  const menu = useCallback(
    () => (
      <Menu onClick={handleMenuClick}>
        <Menu.Item key="printDriverStats">
          Результаты тестирований по темам ПДД
        </Menu.Item>
        <Menu.Item key="printTestsHistory">История тестирований</Menu.Item>
      </Menu>
    ),
    [handleMenuClick]
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <Button>
          Печать
          <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
