import { ExecutionBudget } from '../../containers/budget/executionBudget';
import { accessTypeEnum } from '../../lib/enum';

// Исполнение бюджета
export default [
    {
        component: ExecutionBudget,
        path: '/budget/execution/*',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.newFeaturesDemo,
            accessTypeEnum.viewingBudgetExecutions,
            accessTypeEnum.viewingAllBudgetExecutions,
        ],
    },
];