// @flow
import React, { useCallback, useState } from 'react';
import Button from 'antd/lib/button';

import { Wrapper } from './styles';
import Grid, { GridItem } from '../../../../../../components/layout/Grid';
import { Checkbox, Footer } from './styles';

export type MonitoringFilterParams = {
  stopped?: boolean,
  moving?: boolean,
  connected?: boolean,
  disconnected?: boolean
};

type Props = {
  expanded: boolean,
  onChange: (filters: MonitoringFilterParams) => void,
  onClean: () => void
};

export const Filters = ({ expanded, onClean, onChange }: Props) => {
  const [filters, setFilters] = useState<MonitoringFilterParams>({});

  const handleChange = (key: $Keys<MonitoringFilterParams>) => (
    value: SyntheticInputEvent<HTMLInputElement>
  ) => {
    const copy = { ...filters };
    copy[key] = value.target.checked;
    onChange(copy);
    setFilters(copy);
  };

  const handleClean = useCallback(() => {
    onClean();
    setFilters({});
  }, [onClean]);

  return (
    <Wrapper expanded={expanded}>
      <Grid>
        <GridItem>
          <Checkbox checked={filters.moving} onChange={handleChange('moving')}>
            Движется
          </Checkbox>
        </GridItem>
        <GridItem>
          <Checkbox
            checked={filters.connected}
            onChange={handleChange('connected')}
          >
            На связи
          </Checkbox>
        </GridItem>
        <GridItem>
          <Checkbox
            checked={filters.stopped}
            onChange={handleChange('stopped')}
          >
            Стоит
          </Checkbox>
        </GridItem>
        <GridItem>
          <Checkbox
            checked={filters.disconnected}
            onChange={handleChange('disconnected')}
          >
            Не на связи
          </Checkbox>
        </GridItem>
      </Grid>
      <Footer>
        <Button type="primary" size="small" onClick={handleClean}>
          Очистить
        </Button>
      </Footer>
    </Wrapper>
  );
};
