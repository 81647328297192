import admin from './admin';
import budget from './budget';
import sideServiceTariff from './sideServiceTariff';
import bioTreatmentFacilityTalon from './bioTreatmentFacilityTalon';
import bioTreatmentFacilityFact from './bioTreatmentFacilityFact';
import bioTreatmentFacilityContract from './bioTreatmentFacilityContract';
import bioTreatmentFacilityReport from './bioTreatmentFacilityReport';
import wialonMonitoring from './wialonMonitoring';
import monitoring from './monitoring';
import dashboard from './dashboard';
import distanceTracking from './distanceTracking';
import maintenances from './maintenances';
import services from './services';
import equipment from './equipment';
import vehicleModels from './vehicleModels';
import vehicles from './vehicles';
import orders from './orders';
import shifts from './shifts';
import trips from './trips';
import wialonTrips from './wialonTrips';
import report from './report';
import instructions from './instructions';
import contractVehicleWorkPlans from './contractVehicleWorkPlans';
import selfRegulations from './selfRegulations';
import technicalFluid from './technicalFluid';
import pdd from './pdd';

export default [
  //
  ...admin,
  ...budget,
  //
  ...maintenances,
  ...pdd,
  ...technicalFluid,
  ...selfRegulations,
  ...contractVehicleWorkPlans,
  ...instructions,
  ...report,
  ...wialonTrips,
  ...trips,
  ...shifts,
  ...orders,
  ...vehicles,
  ...vehicleModels,
  ...equipment,
  ...dashboard,
  ...services,
  ...distanceTracking,
  ...monitoring,
  ...wialonMonitoring,
  ...sideServiceTariff,
  ...bioTreatmentFacilityTalon,
  ...bioTreatmentFacilityFact,
  ...bioTreatmentFacilityContract,
  ...bioTreatmentFacilityReport,
];
