// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Location } from '../../lib/types';
import { locationApi } from '../../lib/api';
import { getGooglePlaces, getPlace } from '../../lib/gis';
import AutocompleteSelect from './AutocompleteSelect';
import type { LocationFilterParams } from './../../containers/Locations/Filter';

type Props = SelectProps & {
  filter?: LocationFilterParams,
  // Наименование поля, в котором лежит значение
  valueField?: string,
  // Выводить результаты из geolocation api
  geolocationApi?: boolean,
};

export default ({
  valueField = 'id',
  geolocationApi = true,
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    fetch={async (params = {}) => {
      let locationsList = await locationApi.fetchLocations({
        ...selectProps.filter,
        ...params,
      });
      if (geolocationApi && params.search) {
        const locationsFromGoogle = await getGooglePlaces(params.search);
        locationsList.data = [...locationsList.data, ...locationsFromGoogle];
      }
      return locationsList;
    }}
    fetchSingle={locationApi.fetchLocation}
    notFoundText="Объекты не найдены"
    renderOption={(location: Location, Option) => {
      return (
        <Option
          value={location[valueField] || location.name}
          key={location.id || location.name}
          location={location}
        >
          {location.name}
        </Option>
      );
    }}
    {...selectProps}
    onChange={async (value: string, option: any) => {
      const { onChange, onSelect } = selectProps;
      if (option) {
        const { location } = option.props;
        if (location.googlePlaceId) {
          const { lat, lng } = await getPlace(location.googlePlaceId);
          location.latitude = lat;
          location.longitude = lng;
        }
        if (onSelect) onSelect(value, option);
      }
      if (onChange) onChange(value, option);
    }}
  />
);
