// @flow
import * as React from 'react';
import notification from 'antd/lib/notification';

export default class ErrorHandler extends React.Component<{
  children: React.Node
}> {
  componentDidCatch(error: Error) {
    notification.error({
      message: 'Ошибка',
      description: error.message
    });
  }

  render() {
    return this.props.children;
  }
}
