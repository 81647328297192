// @flow
import FormItem from 'antd/lib/form/FormItem';

import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import CloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { operationType } from '../../../../../../lib/enum';

import { Selects } from './../../../../../../components';
import { notificationLoading } from './../../../../../../components/Notifications';
import { Icon, ListTable } from './../../../../../../components/ui';

import { overhaulCalculationMonthApi } from './../../../../../../lib/api';
import { isEmptyValue, withEmptyRow } from './../../../../../../lib/helpers';
import type { OverhaulCalculationMonth, OverhaulCalculationMonthOperation } from './../../../../../../lib/types';

const { OverhaulTypeSelect, OperationTypeSelect } = Selects;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const OperationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;

type Props = {
  visible: boolean,
  readonly: boolean,
  handleSave: Function,
  handleCancel: Function,
  overhaulCalculationMonth: ?$Shape<OverhaulCalculationMonth>
};
export default (props: Props) => {
  const {
    visible,
    handleSave,
    handleCancel,
    overhaulCalculationMonth,
    readonly
  } = props;
  const [operations, setOperations] = useState([]);

  const validate = (operations: OverhaulCalculationMonthOperation[]) => {
    operations.forEach((operation, index) => {
      ['overhaulTypeId', 'operationType'].forEach(key => {
        if (isEmptyValue(operation[key])) {
          throw new Error('Заполните все поля');
        }
      });
    });
  };

  const validateRequired = (field: string, index: number) => {
    if (!isEmpty(operations[index])) {
      return isEmptyValue(operations[index][field]) ? 'error' : 'validating';
    }
    return 'validating';
  };

  const setField = (name: string, value: number, index: number) => {
    if (operations[index]) {
      operations[index][name] = value;
    } else {
      operations[index] = {
        [name]: value
      };
    }
    setOperations(CloneDeep(operations));
  };

  const deleteRow = (index: number) => {
    operations.splice(index, 1);
    setOperations(CloneDeep(operations));
  };

  const columns = [
    {
      title: 'Вид работ',
      renderRecord: (
        operation: OverhaulCalculationMonthOperation,
        index: number
      ) => {
        return readonly ? (
          `${operation?.overhaulType?.vehicleIssueGroupName || ''}`
        ) : (
          <StyledFormItem
            validateStatus={validateRequired('overhaulTypeId', index)}
          >
            <OverhaulTypeSelect
              value={operation.overhaulTypeId}
              onChange={overhaulTypeId => {
                setField('overhaulTypeId', overhaulTypeId, index);
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Сопособ выполнения',
      width: '150px',
      renderRecord: (
        operation: OverhaulCalculationMonthOperation,
        index: number
      ) => {
        return readonly ? (
          operation?.operationType ? (
            operationType[operation?.operationType]
          ) : (
            ''
          )
        ) : (
          <StyledFormItem
            validateStatus={validateRequired('operationType', index)}
          >
            <OperationTypeSelect
              value={operation.operationType}
              onChange={operationType => {
                setField('operationType', operationType, index);
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      width: '20px',
      renderRecord: (
        record: OverhaulCalculationMonthOperation,
        index: number
      ) =>
        !isEmpty(record) && !readonly ? (
          <OperationButtons>
            <StyledIcon
              type="x"
              onClick={() => {
                deleteRow(index);
              }}
            />
          </OperationButtons>
        ) : null
    }
  ];

  useEffect(() => {
    overhaulCalculationMonth?.operations &&
      setOperations(CloneDeep(overhaulCalculationMonth.operations));
  }, [overhaulCalculationMonth]);

  const onOk = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных стоимости...',
        key: 'saving'
      });
      // $FlowFixMe
      validate(operations);
      const data = await overhaulCalculationMonthApi.update({
        ...overhaulCalculationMonth,
        operations
      });
      handleSave(data);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };
  const onCancel = () => {
    handleCancel();
  };

  return (
    <Modal
      destroyOnClose
      width={800}
      title={`${readonly ? 'Просмотр' : 'Редактирование'} видов работ`}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <ListTable
        columns={columns}
        data={readonly ? operations : withEmptyRow(operations)}
      />
    </Modal>
  );
};
