import { MdmForm, MdmList } from '../../containers/Mdm';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MdmList,
        path: '/admin/mdm',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingContracts,
        ],
    },
    {
        component: MdmForm,
        path: '/admin/mdm/:mdmGuid',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];