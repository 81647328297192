// @flow

import {
  PushpinFilled,
  PushpinOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, List, Popover } from 'antd';
import React from 'react';
import styled from "styled-components";
import { Space } from '../Space';
import type { CustomColumnType } from './Table';

const SettingOutlinedStyled = styled(SettingOutlined)`
  font-size: 1.1rem;
  margin: 0.5rem;
  
  & svg:hover {
    fill: var(--focused-item-color);
  }
`

type TableToolbarCustomProps = {
  columns: CustomColumnType[],
  onColumnsChange: (changedColumn: CustomColumnType, valueChanged: any) => void,
  onColumnsReset: () => void,
};

export const TableToolbar = (props: TableToolbarCustomProps) => {
  return (
    <Space align="end" style={{ flexDirection: 'row-reverse' }}>
      <Popover
        arrowPointAtCenter
        trigger="click"
        content={
          <>
            <List
              size="small"
              dataSource={props.columns}
              renderItem={(item: CustomColumnType) => {
                return (
                  <List.Item>
                    <Checkbox
                      checked={item.visible}
                      onChange={() => {
                        props.onColumnsChange(item, { visible: !item.visible });
                      }}
                    >
                      {item.title}
                    </Checkbox>
                    {item.fixed ? (
                      <PushpinFilled
                        onClick={() =>
                          props.onColumnsChange(item, { fixed: !item.fixed })
                        }
                      />
                    ) : (
                      <PushpinOutlined
                        onClick={() =>
                          props.onColumnsChange(item, { fixed: !item.fixed })
                        }
                      />
                    )}
                  </List.Item>
                );
              }}
            />
            <Button onClick={() => props.onColumnsReset()}>
              Сбросить по умолчанию
            </Button>
          </>
        }
      >
        <SettingOutlinedStyled
        />
      </Popover>
    </Space>
  );
};
