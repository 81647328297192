// @flow
import React, { useState, useEffect } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import Button from 'antd/lib/button';
import Divider from 'antd/lib/divider';
import Input from 'antd/lib/input';
import { FormikProps } from 'formik';
import moment from 'moment';
import styled from 'styled-components';

import type {
  BioTreatmentFacilityContract,
  BioTreatmentFacilityVolume,
} from '../../../lib/types';
import { multipliedBy, applyMaskToValue } from '../../../lib/helpers';
import { sideServiceTariffApi } from '../../../lib/api';
import { sideServiceTypeEnum } from '../../../lib/enum';
import {
  licensePlatesValidate,
  licensePlateRegexp,
} from '../../../lib/validation';

import {
  Popconfirm,
  Icon,
  Operations,
  Popover,
} from './../../../components/ui';
import Table from '../../../components/ui/Table';
import { DatePicker } from '../../../components/ant/DatePicker';
import { formatLicensePlateMask } from './../../../components/inputs/masked-inputs/LicensePlateInput';
import { InputNumber } from './../../../components/inputs';
import CancelButton from './../../../components/CancelButton';
import type { FormFieldType } from './../../../components/Form';
import Form from './../../../components/Form';
import { Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import Selects from './../../../components/selects';
import { OrgUnitSelect } from './../../../components';
import { LicensePlateInput } from './../../../components/inputs/masked-inputs';

import ModalBioTreatmentFacilityVolume from './ModalBioTreatmentFacilityVolume';

const { CompanySelect, SideServiceTariffTypeSelect } = Selects;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const TagContainer = styled.div`
  word-break: break-word;
`;
const Tag = styled.span`
  padding: 2px 5px;
  margin: 3px 2px;
  font-size: 12px;
  background: #d9d9d9;
  display: inline-block;
`;

type Props = {
  bioTreatmentFacilityContract: ?BioTreatmentFacilityContract,
  onCancel: Function,
  onSubmit: Function,
};

export default ({
  bioTreatmentFacilityContract,
  onCancel,
  onSubmit,
}: Props) => {
  const [showModalVolume, setShowModalVolume] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editVolume, setEditVolume] = useState(null);
  const [price, setPrice] = useState(null);
  const [errorSideServiceTariff, setError] = useState(null);
  const handleSubmit = async (
    bioTreatmentFacilityContract: BioTreatmentFacilityContract
  ) => {
    onSubmit(bioTreatmentFacilityContract);
  };
  const getCurrent = async (values) => {
    const { organizationUnitId, sideServiceTariffType } = values;
    try {
      const sideServiceTariff = await sideServiceTariffApi.current({
        sideServiceType: sideServiceTypeEnum.wasteDump,
        organizationUnitId,
        sideServiceTariffType,
      });
      if (sideServiceTariff?.price) {
        setPrice(sideServiceTariff.price);
      } else {
        setPrice(false);
      }
      setError(null);
    } catch (e) {
      setError(e.message);
      setPrice(false);
    }
  };
  return (
    <Form initialValues={bioTreatmentFacilityContract} onSubmit={handleSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting,
        }: FormikProps
      ) => {
        const onSubmitVolume = (volume: BioTreatmentFacilityVolume) => {
          if (editIndex !== null) {
            values?.volumes && (values.volumes[editIndex] = volume);
            setFieldValue('volumes', [...(values?.volumes ?? [])]);
          } else {
            setFieldValue('volumes', [...(values?.volumes ?? []), volume]);
          }
          setShowModalVolume(false);
        };
        const onDeleteVolume = (index: number) => {
          setFieldValue('volumes', [
            ...(values?.volumes.filter((_, idx) => index !== idx) ?? []),
          ]);
        };
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <Grid gutter="16px" cols={3}>
                <GridItem>
                  <FormField
                    label="Подразделение"
                    name="organizationUnitId"
                    required
                  >
                    {({ name, value }) => (
                      <OrgUnitSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                          getCurrent({ ...values, [name]: value });
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Контрагент" name="companyId" required>
                    {({ name, value }) => (
                      <CompanySelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Договор" name="contractNumber" required>
                    {(field) => (
                      <Input placeholder="Введите номер" {...field} />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Тип контракта услуги"
                    name="sideServiceTariffType"
                    required
                  >
                    {({ name, value }) => (
                      <SideServiceTariffTypeSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                          getCurrent({ ...values, [name]: value });
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата начала работ"
                    fast
                    name="startDate"
                    required
                  >
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата окончания работ"
                    name="endDate"
                    required
                  >
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        disabled={values.startDate ? false : true}
                        disabledDate={(date: string) =>
                          values.startDate
                            ? moment
                                .utc(date)
                                .startOf('day')
                                .isSameOrBefore(moment.utc(values.startDate))
                            : false
                        }
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </StyledSection>

            {!values?.id && (
              <StyledSection>
                <SectionTitle
                  divider
                  suffix={
                    <Button
                      onClick={() => {
                        setShowModalVolume(true);
                        setEditVolume(null);
                        setEditIndex(null);
                      }}
                    >
                      Добавить
                    </Button>
                  }
                >
                  Объемы
                </SectionTitle>
                <Table
                  onRow={(volume, index) => ({
                    onClick: () => {
                      setShowModalVolume(true);
                      setEditVolume(volume);
                      setEditIndex(index);
                    },
                  })}
                  columns={[
                    {
                      title: 'Гос.номер',
                      key: 'licensePlates',
                      dataIndex: 'licensePlates',
                      render: (licensePlates: string[]) => (
                        <div style={{ whiteSpace: ' break-spaces' }}>
                          {licensePlates
                            ?.map((licensePlate) =>
                              applyMaskToValue(
                                licensePlate,
                                formatLicensePlateMask
                              )
                            )
                            ?.join(', ') ?? ''}
                        </div>
                      ),
                    },
                    {
                      title: 'Объемы',
                      key: 'volume',
                      dataIndex: 'volume',
                    },
                    {
                      title: '',
                      width: 80,
                      dataIndex: 'operations',
                      // eslint-disable-next-line no-unused-vars
                      render: (
                        text: string,
                        bioTreatmentFacilityContract: BioTreatmentFacilityContract,
                        index: number
                      ) => {
                        return (
                          <Operations>
                            <Popconfirm
                              title="Вы действительно хотите удалить?"
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={() => onDeleteVolume(index)}
                            >
                              <StyledIcon type="x" />
                            </Popconfirm>
                          </Operations>
                        );
                      },
                    },
                  ]}
                  data={values?.volumes ?? []}
                />
              </StyledSection>
            )}

            <ModalBioTreatmentFacilityVolume
              visible={showModalVolume}
              onCancel={() => setShowModalVolume(false)}
              onSubmit={onSubmitVolume}
              bioTreatmentFacilityVolume={editVolume}
            />
            <Footer>
              <div>
                {price || price === null ? (
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    onClick={handleSubmit}
                  >
                    {values.id ? 'Сохранить' : 'Создать'}
                  </Button>
                ) : (
                  <Popover
                    content={
                      errorSideServiceTariff ||
                      'Отсутствует запись в справочнике тарифов услуг на сторону'
                    }
                  >
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onClick={handleSubmit}
                      icon={<WarningOutlined style={{ color: '#faad14' }} />}
                      type="primary"
                    >
                      {values.id ? 'Сохранить' : 'Создать'}
                    </Button>
                  </Popover>
                )}
              </div>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
