import { accessTypeEnum } from './../../../lib/enum';

export const addPlanAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.formationWashingPlans, // формирование плана моек
  accessTypeEnum.approvingWashingPlans // согласование плана
];

// дополнительные права на проставления прав талона на мойку из плана
export const talonPlanAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingMaintenanceServices
];

export const formatPlanAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.formationWashingPlans,
  accessTypeEnum.approvingWashingPlans
];

export const approvePlanAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingWashingPlans
];
