// @flow

import { fetchRequest, initialFetchListParams } from './';
import type { VehicleModel } from '../types';
import type { FetchListParams, ListResponse } from './';
import type { VehicleModelsFilter } from '../../ducks/vehicleModels';

import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

/**
 * API методы для работы с моделями ТС
 */

export const convertDataFromServer = (vehicleModel: VehicleModel) => ({
  ...vehicleModel,
  fixedRatePart: convertFromKopToRub(vehicleModel.fixedRatePart),
  fixedRateInTransit: convertFromKopToRub(vehicleModel.fixedRateInTransit),
  fixedRateInDowntime: convertFromKopToRub(vehicleModel.fixedRateInDowntime),
  warmingUpCost: convertFromKopToRub(vehicleModel.warmingUpCost),
  secondaryFixedRatePart: convertFromKopToRub(
    vehicleModel.secondaryFixedRatePart
  ),
  secondaryFixedRateInTransit: convertFromKopToRub(
    vehicleModel.secondaryFixedRateInTransit
  ),
  secondaryFixedRateInDowntime: convertFromKopToRub(
    vehicleModel.secondaryFixedRateInDowntime
  ),
  secondaryWarmingUpCost: convertFromKopToRub(
    vehicleModel.secondaryWarmingUpCost
  ),
  materialsCost: convertFromKopToRub(vehicleModel.materialsCost)
});

export const convertDataToServer = (vehicleModel: VehicleModel) => ({
  ...vehicleModel,
  fixedRatePart: convertFromRubToKop(vehicleModel.fixedRatePart),
  fixedRateInTransit: convertFromRubToKop(vehicleModel.fixedRateInTransit),
  fixedRateInDowntime: convertFromRubToKop(vehicleModel.fixedRateInDowntime),
  warmingUpCost: convertFromRubToKop(vehicleModel.warmingUpCost),
  secondaryFixedRatePart: convertFromRubToKop(
    vehicleModel.secondaryFixedRatePart
  ),
  secondaryFixedRateInTransit: convertFromRubToKop(
    vehicleModel.secondaryFixedRateInTransit
  ),
  secondaryFixedRateInDowntime: convertFromRubToKop(
    vehicleModel.secondaryFixedRateInDowntime
  ),
  secondaryWarmingUpCost: convertFromRubToKop(
    vehicleModel.secondaryWarmingUpCost
  ),
  materialsCost: convertFromRubToKop(vehicleModel.materialsCost)
});

export const fetchVehicleModels = async (
  params: FetchListParams<VehicleModelsFilter> = initialFetchListParams
): Promise<ListResponse<VehicleModel>> => {
  let models = await fetchRequest.get('/vehicleModel', {
    ...initialFetchListParams,
    ...params
  });
  if (models)
    return {
      ...models,
      data: models.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось загрузить модели ТС');
};

export const fetchVehicleModel = async (
  id: number,
  params: any = {}
): Promise<VehicleModel> => {
  let model = await fetchRequest.get(`/vehicleModel/${id}`, params);
  if (model) return convertDataFromServer(model);
  throw new Error('Не удалось загрузить модель ТС');
};

export const deleteVehicleModel = async (id: number): Promise<VehicleModel> => {
  let deleted = await fetchRequest.delete(`/vehicleModel/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить модель ТС');
};

export const addVehicleModel = async (
  model: VehicleModel
): Promise<VehicleModel> => {
  let added = await fetchRequest.post(
    '/vehicleModel',
    convertDataToServer(model)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать модель ТС');
};

export const updateVehicleModel = async (
  model: VehicleModel
): Promise<VehicleModel> => {
  let updated = await fetchRequest.put(
    '/vehicleModel',
    convertDataToServer(model)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить модель ТС');
};

export default {
  fetchVehicleModels,
  fetchVehicleModel,
  addVehicleModel,
  updateVehicleModel,
  deleteVehicleModel
};
