// @flow
import { type SelectProps } from 'antd/lib/select';
import React from 'react';
import { quarters, quartersEnum } from '../../../../lib/enum';

import { Option, Select } from './../../../../components/ant/Select';

type Props = SelectProps;

export default (props: Props) => {
  return (
    <Select allowClear mode="multiple" {...props}>
      {Object.keys(quartersEnum).map(quarter => {
        return (
          <Option key={quarter} value={quartersEnum[quarter]}>
            {quarters[quarter]}
          </Option>
        );
      })}
    </Select>
  );
};
