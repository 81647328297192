// @flow
import type { RequestSection } from '../requests';
import RequestsController from '../requests';

export class Unit {
  controller: RequestSection;

  constructor(controller: RequestsController) {
    this.controller = controller.getApiSection('unit');
  }

  /**
   * Значения датчиков из последнего сообщения
   */
  async calcLastMessage(params: {
    // ID объекта
    unitId: number,
    // массив идентификаторов датчиков (необязательный параметр: если не указан,
    // либо указан пустой массив, то вернутся значения всех датчиков)
    sensors?: number[],
    // флаги (необязательный): 1 - рассчитывать значение датчика по последнему валидному значению параметра
    flags?: number
  }) {
    return await this.controller.fetch('calc_last_message', params);
  }
}
