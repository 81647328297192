// @flow
import React, { Component } from 'react';
import { navigate } from '@reach/router';

import { Tabs, TabItem } from '../../../../components/ui';

type Props = {
  selectedTab: string
};

export default class HeaderTabs extends Component<Props> {
  render() {
    return (
      <Tabs defaultTab={this.props.selectedTab}>
        <TabItem
          tabKey="training"
          label="Обучение"
          onClick={() => navigate(`/pdd/results/training/`)}
        />
        <TabItem
          tabKey="exam"
          label="Экзамен"
          onClick={() => navigate(`/pdd/results/exam/`)}
        />
      </Tabs>
    );
  }
}
