// @flow

import React, { Component } from 'react';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import type { Brand } from './../../lib/types';
import { Panel, Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import brandApi from '../../lib/api/brands';
import type { FormFieldType } from '../../components/Form';
import Form from '../../components/Form';
import { notificationLoading } from './../../components/Notifications';
import CancelButton from '../../components/CancelButton';
import { goBack } from '../../lib/helpers';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Content = styled.div`
  padding: 16px;
`;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  brand: ?Brand,
  onSubmit: Function,
  onCancel: Function
};

const InnerForm = ({ brand, onSubmit, onCancel }: FormProps) => (
  <Form initialValues={brand} onSubmit={onSubmit}>
    {(FormField: FormFieldType, formikProps: FormikProps) => {
      const { handleSubmit, dirty, isSubmitting } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Наименование марки" required name="name">
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              data-cy="save"
              htmlType="submit"
              className="login-form-button"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  brandId: number
};

type State = {
  brand: ?Brand
};

class BrandForm extends Component<Props, State> {
  state = {
    brand: null
  };

  async componentDidMount() {
    const brandId = parseInt(this.props.brandId, 10);
    if (brandId) {
      try {
        await this.fetchBrand(brandId);
      } catch (error) {
        navigate('/admin/brands');
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  handleSubmit = async (values: Brand) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await brandApi.updateBrand(values);
      } else {
        await brandApi.addBrand(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/brands');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  fetchBrand = async (id: number) => {
    try {
      const brand = await brandApi.fetchBrand(id);
      this.setState({ brand });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { brand } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/brands">Марки</Crumb>
              {brand ? (
                <Crumb to={`/admin/brands/${brand.id}`}>
                  Марка №{brand.id}
                </Crumb>
              ) : (
                <Crumb to="/admin/brands/new">Новая марка</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{brand ? `Марка №${brand.id}` : 'Новая марка'}</h1>
        </StyledPanel>
        <InnerForm
          brand={brand}
          onSubmit={this.handleSubmit}
          onCancel={() => goBack('/admin/brands')}
        />
      </>
    );
  }
}

export default BrandForm;
