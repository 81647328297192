// @flow
import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import moment from 'moment';

import { Button, Popconfirm, DatePicker } from 'antd';
import notification from 'antd/lib/notification';

import { osagoMultiplier } from '../../../lib/api';
import type { FetchListParams } from '../../../lib/api';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
  formatDateTimeToISOString
} from '../../../lib/helpers';
import type {
  OsagoGeneralMultiplier,
  ListState,
  UserAccess
} from './../../../lib/types';

import { Header, Section } from '../../../components/layout';
import { notificationLoading } from '../../../components/Notifications';
import { Icon, Operations, Table } from '../../../components/ui';
import { HeaderTabs } from '../components';

import { withUserAccess } from './../../withUserAccess';
import canAddOsagoMultiplier from './../accessRight';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[]
};
type State = ListState<OsagoGeneralMultiplier> & {
  // Хранится значение для проверки пересечения до создания документа
  startDate: moment
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
class OsagoGeneralMultiplierForm extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    startDate: moment.utc(new Date()).startOf('day')
  };

  fetchOsagoGeneralMultiplier = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    this.setState({ loading: true });
    const {
      data,
      totalCount
    } = await osagoMultiplier.fetchOsagoGeneralMultiplier({
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  async componentDidMount() {
    try {
      this.fetchOsagoGeneralMultiplier();
      if (this.canAdd()) {
        this.columns.push({
          title: '',
          align: 'right',
          stopPropagation: true,
          width: 50,
          render: record => (
            <Operations>
              <Popconfirm
                overlayStyle={{
                  zIndex: 2000
                }}
                placement="left"
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () =>
                  await this.deleteGeneralMultiplier(record.id)
                }
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  deleteGeneralMultiplier = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await osagoMultiplier.deleteOsagoGeneralMultiplier(id);
      await this.fetchOsagoGeneralMultiplier(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50
    },
    {
      title: 'Начало',
      key: 'startDate',
      width: '130px',
      render: (record: any) =>
        formatDateTimeToString(record.startDate, 'DD.MM.YYYY')
    },
    {
      title: 'Окончание',
      key: 'endDate',
      width: '130px',
      render: (record: any) =>
        formatDateTimeToString(record.endDate, 'DD.MM.YYYY')
    },
    {
      title: 'КБМ',
      dataIndex: 'kbm',
      sorter: true,
      width: 50
    },
    {
      title: 'КО',
      dataIndex: 'ko',
      sorter: true,
      width: 50
    },
    {
      title: 'КВС',
      dataIndex: 'kvc',
      sorter: true,
      width: 50
    },
    {
      title: 'КП',
      dataIndex: 'kp',
      sorter: true,
      width: 50
    },
    {
      title: 'КС',
      dataIndex: 'kc',
      sorter: true,
      width: 50
    },
    {
      title: 'КН',
      dataIndex: 'kn',
      sorter: true,
      width: 50
    }
  ];

  canAdd = () =>
    this.props.userAccess.some(access =>
      canAddOsagoMultiplier.includes(access)
    );

  onConfirmCheck = async () => {
    try {
      const fetchParams = {
        startDate: formatDateTimeToISOString({}, moment(this.state.startDate))
      };
      const res = await osagoMultiplier.fetchDateIntersectionMultiplier(
        'general',
        fetchParams
      );
      if (!res) {
        navigate(`/admin/osago-multiplier/general/new`, {
          state: fetchParams
        });
      } else {
        notification.warning({
          message: 'Ошибка',
          description: 'Выбранная дата уже входит в существующий диапазон!'
        });
      }
    } catch (error) {
      notification.warning({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, startDate } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Общие коэффициенты ОСАГО</h1>}
          right={
            canAdd && (
              <Popconfirm
                title={
                  <>
                    <p>Дата начала действия</p>
                    <DatePicker
                      format="DD.MM.YYYY"
                      value={startDate}
                      onChange={(value: moment, dateString: string) => {
                        this.setState({
                          startDate: value
                        });
                      }}
                    />
                  </>
                }
                onConfirm={this.onConfirmCheck}
              >
                <Link to="/admin/osago-multiplier/general/new">
                  <Button type="primary">Создать</Button>
                </Link>
              </Popconfirm>
            )
          }
        />
        <HeaderTabs selectedTab="general" />
        <Section>
          <Table
            onRow={record => ({
              onClick: () =>
                canAdd
                  ? navigate(
                      `/admin/osago-multiplier/general/edit/${record.id}`
                    )
                  : null
            })}
            columns={this.columns}
            fetch={this.fetchOsagoGeneralMultiplier}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(OsagoGeneralMultiplierForm);
