// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import { Spoiler } from '../../components/ui/index';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components/index';
import { Selects, Filter } from '../../components';
import ContractServiceSelect from '../../components/selects/ContractServiceSelect';
import type { FilterChildrenParams } from '../../components/hoc/Filter';
import type { ServiceType } from '../../lib/types';

const { Field } = Card;
const { CompanySelect, ContractorSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type ContractorFilterParams = {
  services?: ServiceType[],
  'company.id'?: number,
  'generalContractor.id'?: number
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: ContractorFilterParams
};

const ContractorFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter
          }: FilterChildrenParams<ContractorFilterParams>) => (
            <>
              <GridItem>
                <Field label="Юридическое лицо">
                  <CompanySelect
                    value={values['company.id']}
                    onChange={value => changeValue('company.id', value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Генеральный подрядчик">
                  <ContractorSelect
                    value={values['generalContractor.id']}
                    onChange={value =>
                      changeValue('generalContractor.id', value)
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Виды услуг">
                  <ContractServiceSelect
                    mode="multiple"
                    value={values.services}
                    onChange={value => changeValue('services', value)}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default ContractorFilter;
