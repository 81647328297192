// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import { Link, navigate } from '@reach/router';
import { Divider } from 'antd';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Card } from '../../../components';
import { Panel, Section, SectionTitle } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Header from '../../../components/layout/Header';
import { notificationLoading } from '../../../components/Notifications';
import {
  Popconfirm,
  ButtonOperations,
  Dropdown,
  ListTable,
} from '../../../components/ui';
import type { DropdownType } from '../../../components/ui/Dropdown';

import { tireApi, tireMeasurementApi, vehicleApi } from '../../../lib/api';
import { accessTypeEnum, seasonTypeTire } from '../../../lib/enum';
import {
  convertEmployeeToString,
  convertVehicleToString,
  formatDateTimeToString,
} from '../../../lib/helpers';
import type {
  Employee,
  Tire,
  TireMeasurement,
  UserAccess,
  Vehicle,
} from '../../../lib/types';

import RemoveEquipmentButton from '../../Vehicles/components/RemoveEquipmentButton';
import TireMeasurementForm from '../../Vehicles/components/TireMeasurementForm';
import { withUserAccess } from '../../withUserAccess';
import HistoryTable from '../HistoryTable';

import AttachVehicleButton, {
  type AdditionalData,
} from './components/AttachVehicleButton';
import CopyTireButton from './components/CopyTireButton';

const StyledDivider = styled(Divider)`
  margin: 0 0 20px 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const { Field } = Card;

export const canEditTireAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingEquipment,
];

export const canAddMeasurementAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.gager,
];

type Props = {
  tireId: number,
  userAccess: UserAccess[],
};

type State = {
  tireMeasurements: TireMeasurement[],
  tire: ?Tire,
  vehicle: ?Vehicle,
};

/** Карточка шины */
export class TireCard extends Component<Props, State> {
  state = {
    tireMeasurements: [],
    tire: null,
    vehicle: null,
  };

  dropdown: ?DropdownType;

  async componentDidMount() {
    await this.fetchTire();
  }

  fetchTire = async () => {
    try {
      const tire = await tireApi.fetchTire(this.props.tireId);
      const tireMeasurements =
        await tireMeasurementApi.fetchTireMeasurementsByTireId(
          this.props.tireId
        );
      this.setState(
        {
          tireMeasurements,
          tire,
        },
        async () => {
          const { tire } = this.state;
          if (tire && tire.vehicleId) {
            this.setState({
              vehicle: await vehicleApi.fetchVehicle(tire.vehicleId),
            });
          }
        }
      );
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  };

  deleteTire = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await tireApi.deleteTire(parseInt(this.props.tireId, 10));
      navigate('/equipment/tires/');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  writeOffTire = async () => {
    try {
      notificationLoading({
        message: 'Списание...',
        key: 'writingOff',
      });
      if (this.state.tire) {
        await tireApi.updateTire({
          ...this.state.tire,
          vehicleId: null,
          isArchive: true,
          removeDate: moment().utc().toISOString(),
        });
        notification.success({
          message: 'Успешно',
          description: 'Шина успешно списана',
        });
        navigate('/equipment/tires/');
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      notification.close('writingOff');
    }
  };

  setVehicle = async (
    vehicleId: ?number,
    additionalData: AdditionalData,
    removeDate?: string
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const { tire } = this.state;
      const { installDate, removeDate, normKilometrage, normWorkHours } =
        additionalData;
      if (!tire) {
        return;
      }
      let updatedTire;
      if (vehicleId) {
        updatedTire = await tireApi.setTireVehicle(
          this.props.tireId,
          vehicleId,
          installDate,
          removeDate
        );
        updatedTire = await tireApi.fillReplaceDate({
          ...updatedTire,
          normKilometrage,
          normWorkHours,
        });
      } else {
        updatedTire = await tireApi.updateTire({
          ...tire,
          vehicleId,
          installDate,
          removeDate,
        });
      }
      this.setState({
        tire: updatedTire,
        vehicle: updatedTire?.vehicle,
      });
      notification.success({
        message: vehicleId ? 'ТС успешно закреплено' : 'ТС успешно откреплено',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  canAddMeasurement = () =>
    this.props.userAccess.some((access) =>
      canAddMeasurementAccess.includes(access)
    );

  render() {
    const { userAccess } = this.props;
    const { tire, vehicle } = this.state;
    const canEdit = userAccess.some((access) =>
      canEditTireAccess.includes(access)
    );
    const { tireMeasurements } = this.state;
    if (!tire) {
      return null;
    }
    const tireId = parseInt(tire.id);
    const canUnfixVehicle = parseInt(tire.vehicleId, 10) > 0;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb>Администрирование</Crumb>
              <Crumb>Оборудование</Crumb>
              <Crumb to="/equipment/tires">Шины</Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <ButtonOperations>
                {!tire.isArchive && (
                  <Popconfirm
                    title="Вы действительно хотите списать шину?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={this.writeOffTire}
                    placement="bottomRight"
                  >
                    <Button type="primary">Списать</Button>
                  </Popconfirm>
                )}
                {canUnfixVehicle && (
                  <RemoveEquipmentButton
                    onConfirm={(removeDate: string) =>
                      this.setVehicle(
                        null,
                        {
                          installDate: tire.installDate,
                          normKilometrage: tire.normKilometrage,
                          normWorkHours: tire.normWorkHours,
                        },
                        removeDate
                      )
                    }
                  >
                    Открепить ТС
                  </RemoveEquipmentButton>
                )}
                {!tire.isArchive && (
                  <AttachVehicleButton
                    tire={tire}
                    onConfirm={this.setVehicle}
                    vehicleId={tire.vehicleId}
                    text={`Закрепить ${tire.vehicleId ? 'другое' : 'за'} ТС`}
                  />
                )}
                <Dropdown
                  ref={(dropdown) => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <CopyTireButton
                          tire={tire}
                          afterSubmit={() => {
                            if (this.dropdown) {
                              this.dropdown.onVisibleChange(false);
                            }
                          }}
                        />
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        onClick={() =>
                          navigate(`/equipment/tires/edit/${tireId}`)
                        }
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteTire}
                          onVisibleChange={(flag) =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className="openActionsDropdown"
                    type="primary"
                    icon={<EllipsisOutlined />}
                  />
                </Dropdown>
              </ButtonOperations>
            )
          }
        />
        <StyledPanel>
          <h1>
            {tire.brand.name} {tire.name} {tire.width}/{tire.profile}{' '}
            {tire.diameter} ({seasonTypeTire[tire.seasonType]})
          </h1>
        </StyledPanel>
        <Section>
          <SectionTitle divider>Основные данные</SectionTitle>
          <SectionContent>
            <Grid gutter="16px" cols={5}>
              <GridItem fullWidth>
                <Field label="Установновлена на ТС">
                  {!!vehicle ? (
                    <Link to={`/vehicles/${vehicle.id}`}>
                      {convertVehicleToString(vehicle)}
                    </Link>
                  ) : (
                    'Не установлена'
                  )}
                </Field>
              </GridItem>

              <GridItem fullWidth>
                <StyledDivider />
              </GridItem>

              <GridItem>
                <Field label="Инвентарный номер">
                  {tire.inventoryNumber || '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Складской номер">{tire.stockNumber || '-'}</Field>
              </GridItem>
              <GridItem>
                <Field label="Заводской номер">
                  {tire.factoryNumber || '-'}
                </Field>
              </GridItem>

              <GridItem fullWidth>
                <StyledDivider />
              </GridItem>

              <GridItem>
                <Field label="Фактический пробег">
                  {tire.actualKilometrage
                    ? `${tire.actualKilometrage} км`
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Норматив пробега для списания">
                  {tire.normKilometrage ? `${tire.normKilometrage} км` : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата списания по пробегу">
                  {tire.mileageReplaceDate
                    ? formatDateTimeToString(
                        tire.mileageReplaceDate,
                        'DD.MM.YYYY'
                      )
                    : '-'}
                </Field>
              </GridItem>

              <GridItem fullWidth>
                <StyledDivider />
              </GridItem>

              <GridItem>
                <Field label="Фактическая наработка моточасов">
                  {tire.workHours ? `${tire.workHours} ч` : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Норматив наработки моточасов">
                  {tire.normWorkHours ? `${tire.normWorkHours} ч` : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата списания по наработке м/ч">
                  {tire.machineHoursReplaceDate
                    ? formatDateTimeToString(
                        tire.machineHoursReplaceDate,
                        'DD.MM.YYYY'
                      )
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата списания по времен. износу">
                  {tire.timeWearReplaceDate
                    ? formatDateTimeToString(
                        tire.timeWearReplaceDate,
                        'DD.MM.YYYY'
                      )
                    : '-'}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle
            divider
            suffix={
              <TireMeasurementForm
                tire={tire}
                vehicleId={tire.vehicleId}
                onSuccessSubmit={this.fetchTire}
              />
            }
          >
            Контрольные замеры высоты протектора
          </SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem fullWidth>
                <ListTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Дата',
                      key: 'date',
                      render: (date: string | moment) =>
                        formatDateTimeToString(date, 'DD.MM.YYYY'),
                    },
                    {
                      title: 'Предыдущее значение, мм',
                      key: 'oldTreadDepthValue',
                    },
                    {
                      title: 'Новое значение, мм',
                      key: 'newTreadDepthValue',
                    },
                    {
                      title: 'Документ-основание',
                      key: 'document',
                    },
                    {
                      title: 'Сотрудник',
                      key: 'gauger',
                      render: (gauger: Employee) =>
                        convertEmployeeToString(gauger),
                    },
                  ]}
                  data={tireMeasurements}
                />
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>История</SectionTitle>
          {tireId > 0 && (
            <HistoryTable
              equipmentId={tireId}
              fetchHistory={tireApi.fetchHistory}
            />
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(TireCard);
