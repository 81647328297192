// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';

const {
  MaintenanceWorkTypeSelect,
  RepairePlanSelect,
  OrderContractorTypeSelect
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type MaintenanceMatrixFilterParams = {
  maintenanceWorkTypeId?: number,
  repairPlan?: string,
  orderContractorType?: string
};

type Props = {
  filter: MaintenanceMatrixFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const MaintenanceMatrixFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<MaintenanceMatrixFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <MaintenanceWorkTypeSelect
                  placeholder="Выберите тип ремонта"
                  size="small"
                  onChange={value =>
                    changeValue('maintenanceWorkTypeId', value)
                  }
                  value={values.maintenanceWorkTypeId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RepairePlanSelect
                  placeholder="График работ"
                  size="small"
                  onChange={value => changeValue('repairPlan', value)}
                  value={values['repairPlan']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrderContractorTypeSelect
                  placeholder="Способ выполнения"
                  size="small"
                  onChange={value => changeValue('orderContractorType', value)}
                  value={values['orderContractorType']}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MaintenanceMatrixFilter;
