// @flow
import type { RegistrationPlace } from '../types';
import CommonApi from './../commonApi';

const registrationPlace = new CommonApi<RegistrationPlace>(
  'registrationPlace',
  'место регистрации'
);

export default {
  ...registrationPlace
};
