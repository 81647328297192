// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import { Spoiler } from './../../../components/ui';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { Card, Filter, Selects } from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import { ownerTypes } from './../../../lib/enum';
import type { VehicleType, Vehicle } from './../../../lib/types';

const { Field } = Card;
const { VehicleSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type OsagoCalculationFilterParams = {
  // year?: string,
  osagoCalculationYearId?: number,
  vehicleGroup?: string,
  vehicleType?: string,
  vehicleId?: Number,
  'vehicleModel.type'?: VehicleType,
  'vehicleModel.id'?: number,
  'vehicleModel.brand.id'?: number
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: OsagoCalculationFilterParams
};

const OsagoCalculationFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter
          }: FilterChildrenParams<OsagoCalculationFilterParams>) => (
            <>
              <GridItem>
                <Field label="Гос. номер">
                  <VehicleSelect
                    value={values.vehicleId}
                    optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                    filter={{
                      ownerType: ownerTypes.self,
                      'vehicleModel.type': values['vehicleModel.type']
                    }}
                    onChange={value => changeValue('vehicleId', value)}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default OsagoCalculationFilter;
