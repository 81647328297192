import {
    BaseRateMultiplier,
    OsagoBranchMultiplier,
    OsagoGeneralMultiplier,
    OsagoKprMultiplier,
    OsagoPowerMultiplier,
} from '../../containers/osagoMultiplier';
import { accessTypeEnum } from '../../lib/enum';

//коэффициенты осаго
export default [
    {
        component: OsagoPowerMultiplier.OsagoPowerMultiplierList,
        path: '/admin/osago-multiplier/power',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoPowerMultiplier.OsagoPowerMultiplierForm,
        path: '/admin/osago-multiplier/power/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoPowerMultiplier.OsagoPowerMultiplierForm,
        path: '/admin/osago-multiplier/power/edit/:osagoPowerMultiplierId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoGeneralMultiplier.OsagoGeneralMultiplierList,
        path: '/admin/osago-multiplier/general',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoGeneralMultiplier.OsagoGeneralMultiplierForm,
        path: '/admin/osago-multiplier/general/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoGeneralMultiplier.OsagoGeneralMultiplierForm,
        path: '/admin/osago-multiplier/general/edit/:osagoGeneralMultiplierId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoKprMultiplier.OsagoKprMultiplierList,
        path: '/admin/osago-multiplier/kpr',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoKprMultiplier.OsagoKprMultiplierForm,
        path: '/admin/osago-multiplier/kpr/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoKprMultiplier.OsagoKprMultiplierForm,
        path: '/admin/osago-multiplier/kpr/edit/:osagoKprMultiplierId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoBranchMultiplier.OsagoBranchMultiplierList,
        path: '/admin/osago-multiplier/branch',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoBranchMultiplier.OsagoBranchMultiplierForm,
        path: '/admin/osago-multiplier/branch/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: OsagoBranchMultiplier.OsagoBranchMultiplierForm,
        path: '/admin/osago-multiplier/branch/edit/:osagoBranchMultiplierId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: BaseRateMultiplier.BaseRateMultiplierList,
        path: '/admin/osago-multiplier/base-rate',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: BaseRateMultiplier.BaseRateMultiplierForm,
        path: '/admin/osago-multiplier/base-rate/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
    {
        component: BaseRateMultiplier.BaseRateMultiplierForm,
        path: '/admin/osago-multiplier/base-rate/edit/:osagoBaseRateMultiplierId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingOsagoMultipliers,
            accessTypeEnum.handlingOsagoMultipliers,
        ],
    },
];