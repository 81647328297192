import styled from 'styled-components';
import Section from './Section';

export { default as Header } from './Header';
export { default as Sidebar } from './Sidebar';
export { default as Divider } from './Divider';
export { default as Panel } from './Panel';
export { TopPanel } from './Panel';
export { default as Section, SectionTitle, SectionContent } from './Section';
export { default as Grid, GridItem } from './Grid';

export const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
