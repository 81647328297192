// @flow

import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import styled from 'styled-components';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button/button';

import { monitoringPriceApi } from './../../lib/api';
import type { FetchListParams } from './../../lib/api';
import type { ListState } from './../../lib/types';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
} from './../../lib/helpers';

import { notificationLoading } from './../../components/Notifications';
import { Header, Section } from './../../components/layout';
import { Popconfirm, Table, Operations, Icon } from './../../components/ui';
import type { MonitoringPrice, UserAccess } from '../../lib/types';

import { withUserAccess } from './../withUserAccess';
import canAddMonitoringPriceAccess from './accessRight';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<MonitoringPrice>;

export class MonitoringPriceList extends Component<Props, State> {
  state = getListInitialState();

  canAdd = () =>
    this.props.userAccess.some((access) =>
      canAddMonitoringPriceAccess.includes(access)
    );

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50,
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName',
      sorter: true,
      width: 150,
    },
    {
      title: 'Стоимость',
      dataIndex: 'amountPayment',
      sorter: true,
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      width: '150',
      render: (startDate: string) => {
        return `${formatDateTimeToString(startDate, 'DD.MM.YYYY')}`;
      },
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      width: '150',
      render: (endDate: string) => {
        return endDate
          ? `${formatDateTimeToString(endDate, 'DD.MM.YYYY')}`
          : null;
      },
    },
  ];

  deleteMonitoringPrices = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await monitoringPriceApi.deleteMonitoringPrice(id);
      await this.fetchMonitoringPrice(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchMonitoringPrice = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    this.setState({ loading: true });
    const { data, totalCount } = await monitoringPriceApi.fetcMonitoringPrice({
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    await this.fetchMonitoringPrice(page);
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        width: '30px',
        stopPropagation: true,
        render: (record: any) => (
          <Operations>
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () =>
                await this.deleteMonitoringPrices(record.id)
              }
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ),
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      canAddMonitoringPriceAccess.includes(access)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Список прайсов мониторинга</h1>}
          right={
            canAdd && (
              <Link to="/admin/monitoring-price/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/monitoring-price/${record.id}/edit`)
                  : navigate(`/admin/monitoring-price/${record.id}`),
            })}
            columns={this.columns}
            fetch={this.fetchMonitoringPrice}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MonitoringPriceList);
