// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { fuelAndOilApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { FuelAndOil } from '../../lib/types';
import {
  fuelAndOilGroup,
  fuelAndOilSubType,
  fuelAndOilSubView,
  fuelAndOilView,
  fuelAndOilType
} from '../../lib/enum';

type Props = SelectProps & {
  filter?: {
    view?: string[],
    isTrailer?: boolean
  },
  disabledValues: string[]
};

const FuelAndOilSelect = ({
  filter = {},
  disabledValues,
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    fetch={async params =>
      await fuelAndOilApi.fetchFuelsAndOils({ ...params, ...filter })
    }
    fetchSingle={fuelAndOilApi.fetchFuelAndOil}
    notFoundText="ГСМ не найдено"
    renderOption={(fuelAndOil: FuelAndOil, Option) => (
      <Option
        value={fuelAndOil.id}
        key={fuelAndOil.id}
        disabled={disabledValues.includes(fuelAndOil.id)}
        fuelandoil={fuelAndOil}
      >
        {fuelAndOil.name} | {fuelAndOilView[fuelAndOil.view]} |&nbsp;
        {fuelAndOilSubView[fuelAndOil.subView]}
        {fuelAndOil.type && ` | ${fuelAndOilType[fuelAndOil.type]}`}
        {fuelAndOil.subType && ` | ${fuelAndOilSubType[fuelAndOil.subType]}`}
        {fuelAndOil.group && ` | ${fuelAndOilGroup[fuelAndOil.group]}`}
      </Option>
    )}
    {...selectProps}
  />
);

FuelAndOilSelect.defaultProps = {
  disabledValues: []
};

export default FuelAndOilSelect;
