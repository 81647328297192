// @flow
import type { SpareParts } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (spareParts: SpareParts) => ({
  ...spareParts,
  price: convertFromRubToKop(spareParts.price),
  catalogNumber: spareParts.catalogNumber || '',
});

const convertDataFromServer = (spareParts: SpareParts) => ({
  ...spareParts,
  price: convertFromKopToRub(spareParts.price),
});

const spareParts = new CommonApi<SpareParts>(
  'sparePart',
  'Запасные части',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...spareParts,
};
