// @flow
import React, { useState, useEffect } from 'react';
import { SelectProps } from 'antd/lib/select';
import TreeSelect from '../ui/TreeSelect';
import { employeeApi } from '../../lib/api';

import notification from 'antd/lib/notification';
import type { EmployeeBranchPosition } from '../../lib/types';

type Props = {
  branchId: number,
  filter?: any,
  optionKey?: string
} & SelectProps;

export default (props: Props) => {
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [value] = useState();

  const fetchData = async () => {
    try {
      const d = await employeeApi.fetchBranchEmployeesPositions(props.branchId);
      setRawData(d);

      const buildTree = (data: EmployeeBranchPosition[]) => {
        return data
          .reduce(
            (result, employeeBranchPosition: EmployeeBranchPosition, ind) => {
              let index = result.findIndex(
                el => el.value === employeeBranchPosition.positionTitle
              );

              if (index === -1) {
                index =
                  result.push({
                    id: ind + 1,
                    pId: 0,
                    value: employeeBranchPosition.positionTitle,
                    title: employeeBranchPosition.positionTitle || '',
                    selectable: false
                  }) - 1;
              }
              return result;
            },
            []
          )
          .sort((a, b) =>
            a.title.toLowerCase() > b.title.toLowerCase() ? 1 : 0
          );
      };

      setData(buildTree(d));
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  const loadData = async (node: any) => {
    const fData = rawData
      .filter(
        (el: EmployeeBranchPosition) => el.positionTitle === node.props.title
      )
      .map((el:EmployeeBranchPosition, index) => ({
        id: node.props.id * 1000 + index + 1,
        pId: node.props.id,
        value: el,
        title: `${el.positionTitle || ''}(${el.orgUnitName})`,
        isLeaf: true
      }));

    setData(data.concat(fData));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <TreeSelect
      treeDataSimpleMode
      value={value}
      placeholder="Выберите должность"
      treeData={data}
      loadData={loadData}
      treeNodeFilterProp="title"
      showSearch
      allowClear
      {...props}
    />
  );
};
