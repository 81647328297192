import {
    BudgetSummaryForm,
    BudgetSummaryList,
} from '../../containers/budget/summary';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BudgetSummaryList,
        path: '/budget/summary',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetSummaryForm,
        path: '/budget/summary/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetSummaryForm,
        path: '/budget/summary/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetSummaryForm,
        path: '/budget/summary/:id/:orgUnitId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
    {
        component: BudgetSummaryForm,
        path: '/budget/summary/:id/:orgUnitId/:type',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingDetailedBudget,
            accessTypeEnum.handlingBranchDetailedBudget,
            accessTypeEnum.viewingDetailedBudget,
            accessTypeEnum.approvingBranchDetailedBudget,
            accessTypeEnum.approvingDetailedBudget,
        ],
    },
];