import React from 'react';
import styled, { css } from 'styled-components';

import { Header } from './../../../../components/layout/Header';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import {
  Cell as ListCell,
  Row as ListRow
} from './../../../../components/ui/ListTable';
import Popover from './../../../../components/ui/Popover';

/**
 * Элементы используемые в табличках в Регламенте и закреплениях
 */
export const ListHeader = styled(Grid)`
  background: #e4ebf2;
  border-radius: 2px 2px 0px 0px;
`;
export const HeaderCell = styled.div`
  padding: 8px 16px;
  color: #838d96;
  font-size: 12px;
`;
export const HeaderBoldCell = styled(HeaderCell)`
  font-weight: 500;
  color: #3d4042;
  font-size: 14px;
`;
export const Row = styled(ListRow)`
  padding: 0;
  border-bottom: 1px solid #e4ebf2;
`;
export const Cell = styled(ListCell)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  min-height: 45px;
  box-sizing: border-box;
`;
export const VehicleInfo = styled.div`
  line-height: 1.5;
  & p {
    margin-bottom: 5px;
  }
`;
export const Bold = styled.b`
  font-weight: 500;
`;
export const Day = styled(({ isWeekend, children, ...props }) =>
  isWeekend ? (
    <Popover content="Выходной день">
      <span {...props}> ({children})</span>
    </Popover>
  ) : (
    <span {...props}> ({children})</span>
  )
)`
  ${({ isWeekend }) =>
    isWeekend &&
    css`
      color: red;
      cursor: help;
    `};
  text-transform: capitalize;
`;

export const FilterButtons = styled(GridItem)`
  display: flex;
  align-items: center;
`;

export const ActionsHeader = styled(Header)`
  background: #f0f4f8;
`;
