// @flow

import React from 'react';
import { Router } from '@reach/router';

import { withUserAccess } from './../../withUserAccess';

import {
  addAccessRightContractVehicleBudget,
  addAccessRightWorkPlans
} from './fixed/accessRight';

import {
  FixedRegistrBudgetAssignments,
  FixedRegistrBudgetBudgetList,
  FixedRegistrBudgetList,
  FixedRegistrBudgetVehicleCard,
  FixedRegistrBudgetExpenseDirectionCard,
  FixedRegistrBudgetVehicleListCard,
  FixedRegistrBudgetVehicleListForm,
  FixedWorkPlansCard,
  FixedWorkPlansForm,
  FixedWorkPlansList
} from './fixed';

export default withUserAccess(({ userAccess }) => {
  const canAddContractVehicleBudget = userAccess.some(access =>
    addAccessRightContractVehicleBudget.includes(access)
  );
  const canAddWorkPlans = userAccess.some(access =>
    addAccessRightWorkPlans.includes(access)
  );
  return (
    <>
      <Router>
        <FixedRegistrBudgetList path="/fixed" />
        {canAddContractVehicleBudget && (
          <FixedRegistrBudgetVehicleListForm path="/fixed/new" />
        )}
        {canAddContractVehicleBudget && (
          <FixedRegistrBudgetVehicleListForm path="/fixed/:contractVehiclePlanId/clone" />
        )}
        <FixedRegistrBudgetVehicleListCard path="/fixed/:contractVehiclePlanId/card" />
        <FixedRegistrBudgetAssignments path="/fixed/:contractVehiclePlanId/assignments" />
        <FixedRegistrBudgetBudgetList path="/fixed/:contractVehiclePlanId/budget" />
        <FixedRegistrBudgetExpenseDirectionCard path="/fixed/:contractVehiclePlanId/budget/:contractVehicleBudgetId/expense-direction/:type" />
        <FixedRegistrBudgetVehicleCard path="/fixed/:assignmentLimitId/budget/vehicle" />

        <FixedWorkPlansList path="/work-plans" />
        <FixedWorkPlansCard path="/work-plans/:regulationId/card" />
        {canAddWorkPlans && <FixedWorkPlansForm path="/work-plans/new" />}
      </Router>
    </>
  );
});
