// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import Icon from '../Icon.js';

export const TableWrapper = styled.div`
  width: 100%;
`;

export const EmptyText = styled.p`
  text-align: center;
  padding: 16px 0;
`;
export const HeaderCell = styled(({ grid, isLeaf, isDeepLeaf, ...props }) => (
  <div {...props} />
))`
  background: #e4ebf2;
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  min-height: 35px;
  justify-content: center;
  border-right: 1px solid #c0ccd7;
  ${({ isDeepLeaf }) =>
    isDeepLeaf &&
    css`
      &:not(:last-child) {
        // border-right: none;
      }
    `}
  ${({ isLeaf }) =>
    !isLeaf &&
    css`
      border-bottom: 1px solid #c0ccd7;
    `}
  padding: 0 5px;
  box-sizing: border-box;
  ${({ grid }) =>
    grid &&
    css`
      grid-column-start: ${grid.gridColumnStart};
      grid-column-end: ${grid.gridColumnEnd};
      grid-row-start: ${grid.gridRowStart};
      grid-row-end: ${grid.gridRowEnd};
    `}
`;

export const HeaderCellText = styled(
  ({ sticky, isDeepLeaf, center, ...props }) => <p {...props} />
)`
  color: #4c4c4c;
  font-size: 12px;
  font-weight: bold;
  ${({ isDeepLeaf }) =>
    isDeepLeaf &&
    css`
      color: #838d96;
      font-size: 11px;
      font-weight: normal;
    `}
  ${props =>
    props.center &&
    css`
      width: 100%;
      text-align: center;
    `}
  ${props =>
    props.sticky &&
    css`
      position: sticky;
      margin: 0 32px;
      width: auto;
      left: 16px;
      text-align: left;
    `}
`;
HeaderCellText.defaultProps = {
  center: true
};

export const Cell = styled(({ index, ...props }) => <div {...props} />)`
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #838383;
  position: relative;
`;

export const HeaderWrapper = styled(({ height, ...props }) => (
  <div {...props} />
))`
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;

export const HeaderVirtualizedGrid = styled(MultiGrid)`
  overflow: hidden !important;
  outline: none;

  & .ReactVirtualized__Grid__innerScrollContainer {
    display: grid;
  }
`;

export const HeaderVirtualizedItem = styled(({ widths, ...props }) => (
  <div {...props} />
)).attrs(() => ({
  style: ({ left, top }) => ({
    left,
    top
  })
}))`
  display: grid;
  ${({ widths }) =>
    widths &&
    css`
      grid-template-columns: ${widths};
    `}
`;

export const ArrowLeft = styled(props => <Icon type="small-arrow-left" />)``;
export const ArrowRight = styled(ArrowLeft)`
  transform: rotate(-180deg);
`;
