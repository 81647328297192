import React from 'react';
import { Router } from '@reach/router';

import { withUserAccess } from '../../withUserAccess';

import List from './VehicleList/List';
import Form from './VehicleList/Form';
import Card from './VehicleList/Card';

import MeasuringDeviceCertifications from './Budget/MeasuringDeviceCertifications';
import LicensePlateChange from './Budget/LicensePlateChange';
import DriverInspection from './Budget/DriverInspection';
import InspectionsGibdd from './Budget/InspectionsGibdd';
import InspectionsGtn from './Budget/InspectionsGtn';
import GpmMaintenance from './Budget/GpmMaintenance';
import FederalHighway from './Budget/FederalHighway';
import Battaries from './Budget/Batteries';
import Material from './Budget/Material';
import Washing from './Budget/Washing';
import Gps from './Budget/Gps';
import SpringPass from './Budget/SpringPass';
import CargoPass from './Budget/CargoPass';
import Maintenance from './OperationBudget/Maintenance';
import Tire from './OperationBudget/Tire';
import FuelsAndOils from './OperationBudget/FuelsAndOils/List';
import Mechanisms from './OperationBudget/Mechanisms/List';

import OverhaulRepair from './Repair/OverhaulRepair/Card';
import CurrentRepair from './Repair/CurrentRepair/Card';
import BudgetRepair from './Repair/BudgetRepair/Card';

import Additional from './Additional/List';

import BudgetItogList from './BudgetItog/List';

export default withUserAccess(({ userAccess }) => {
  return (
    <>
      <Router>
        <Form path="/new" />
        <List path="/" />
        <Card path="/:vehiclePlanId/card" />
        <Form path="/:vehiclePlanId/card/:type" />

        <MeasuringDeviceCertifications path="/:vehiclePlanId/measuring-device-certifications" />
        <LicensePlateChange path="/:vehiclePlanId/license-plate-change" />
        <DriverInspection path="/:vehiclePlanId/driver-inspection" />
        <InspectionsGibdd path="/:vehiclePlanId/inspections-gibdd" />
        <GpmMaintenance path="/:vehiclePlanId/gpm-maintenance" />
        <InspectionsGtn path="/:vehiclePlanId/inspections-gtn" />
        <FederalHighway path="/:vehiclePlanId/federal-highway" />
        <Battaries path="/:vehiclePlanId/batteries" />
        <Material path="/:vehiclePlanId/material" />
        <Washing path="/:vehiclePlanId/washing" />
        <Gps path="/:vehiclePlanId/gps" />

        <SpringPass path="/:vehiclePlanId/spring-pass" />
        <CargoPass path="/:vehiclePlanId/cargo-pass" />

        <OverhaulRepair path="/:vehiclePlanId/overhaul-repair" />
        <CurrentRepair path="/:vehiclePlanId/current-repair" />
        <BudgetRepair path="/:vehiclePlanId/budget-repair" />

        <FuelsAndOils path="/:vehiclePlanId/fuels-and-oils" />
        <Mechanisms path="/:vehiclePlanId/mechanisms" />
        <Tire path="/:vehiclePlanId/tire" />
        <Maintenance path="/:vehiclePlanId/maintenance" />

        <Additional path="/:vehiclePlanId/additional" />

        <BudgetItogList path="/:vehiclePlanId/itog" />
      </Router>
    </>
  );
});
