// @flow

import React, { type Node } from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Vehicle, OwnerType } from './../../../../lib/types';
import { vehicleApi, monthlyWashingPlanApi } from './../../../../lib/api';
import AutocompleteSelect from './../../../../components/selects/AutocompleteSelect';
import { convertVehicleToString } from './../../../../lib/helpers';

type Props = SelectProps & {
  // вывод имени в Option
  optionTitle?: (vehicle: Vehicle) => Node,
  monthlyWashingPlanId: number,
  filter?: {
    ownerType?: OwnerType
  },
  disabledValues?: number[]
};

const defaultOptionName = (vehicle: Vehicle) => convertVehicleToString(vehicle);

export default ({
  optionTitle = defaultOptionName,
  monthlyWashingPlanId,
  filter = {},
  disabledValues = [],
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    placeholder="Выберите ТС"
    fetch={async params => {
      const plan = await monthlyWashingPlanApi.fetchAllWashingPlanVehicles({
        ...params,
        washingPlanId: monthlyWashingPlanId
      });
      return {
        ...plan,
        data: plan.data.map(item => item.vehicle)
      };
    }}
    refetchParams={{ filter }}
    fetchSingle={vehicleApi.fetchVehicle}
    notFoundText="ТС не найдены"
    renderOption={(vehicle: Vehicle, Option) => (
      <Option
        key={vehicle.id}
        value={vehicle.id}
        vehicle={vehicle}
        disabled={disabledValues.includes(vehicle.id)}
      >
        {optionTitle(vehicle)}
      </Option>
    )}
    {...selectProps}
  />
);
