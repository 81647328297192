// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input/Input';

import { Spoiler } from './../../components/ui';
import Grid, { GridItem } from './../../components/layout/Grid';
import { Card, Filter } from './../../components';
import type { FilterChildrenParams } from './../../components/hoc/Filter';

const { Field } = Card;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type StouFilterParams = {
  mnemonic?: string
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: StouFilterParams
};

const StouFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter
          }: FilterChildrenParams<StouFilterParams>) => (
            <>
              <GridItem>
                <Field label="Мнемокод">
                  <Input
                    value={values.mnemonic}
                    onChange={e => changeValue('mnemonic', e.target.value)}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default StouFilter;
