// @flow
import { Dispatch } from 'redux';

import type { Action } from './redux';
import type { MdmNode } from '../lib/types/mdm';
import { mdmApi } from '../lib/api';

export const FETCH_MDM_NODE = 'vehicles/mdm-node/fetch';
export const CLEAN_MDM_NODE = 'vehicles/mdm-node/clean';
export const ADD_MDM_NODE = 'vehicles/mdm-node/add';
export const UPDATE_MDM_NODE = 'vehicles/mdm-node/update';

export type MdmNodeState = ?MdmNode;

const initialState: MdmNodeState = null;

const reducer = (
  state: MdmNodeState = initialState,
  { type, payload }: Action
): MdmNodeState => {
  switch (type) {
    case FETCH_MDM_NODE:
    case ADD_MDM_NODE:
    case UPDATE_MDM_NODE:
      return payload;
    case CLEAN_MDM_NODE:
      return initialState;
    default:
      return state;
  }
};

export const fetchMdmNode = (guid: number) => async (dispatch: Dispatch) => {
  const mdmNode: MdmNode = await mdmApi.fetchMdmNode(guid);
  dispatch({
    type: FETCH_MDM_NODE,
    payload: mdmNode
  });
};

export const cleanMdmNode = () => (dispatch: Dispatch) =>
  dispatch({ type: CLEAN_MDM_NODE });

export const addMdmNode = (node: MdmNode) => async (dispatch: Dispatch) => {
  const added = await mdmApi.addMdmNode(node);
  dispatch({
    type: ADD_MDM_NODE,
    payload: added
  });
};

export const updateMdmNode = (node: MdmNode) => async (dispatch: Dispatch) => {
  const updated = await mdmApi.updateMdmNode(node);
  dispatch({
    type: UPDATE_MDM_NODE,
    payload: updated
  });
};

export default reducer;
