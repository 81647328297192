// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';
import isEqual from 'lodash/isEqual';

import type { VehicleModel, VehicleModelFilter } from '../../lib/types';
import { vehicleModelApi } from '../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.filter, nextProps.filter) &&
  prevProps.value === nextProps.value &&
  isEqual(prevProps.search, nextProps.search);

type SelectType = SelectProps & {
  filter?: VehicleModelFilter,
  optimal?: boolean
};

export default React.memo<SelectType>(
  ({ filter, ...selectProps }: SelectType) => (
    <AutocompleteSelect
      placeholder="Выберите модель ТС"
      fetch={async params =>
        await vehicleModelApi.fetchVehicleModels({ ...params, ...filter })
      }
      fetchSingle={vehicleModelApi.fetchVehicleModel}
      refetchParams={{ filter }}
      renderOption={(model: VehicleModel, Option) => (
        <Option value={model.id} key={model.id} vehicleModel={model}>
          {model.brandName} {model.name}
        </Option>
      )}
      {...selectProps}
    />
  ),
  areEqual
);
