// @flow
import React, { useState, useCallback } from 'react';

import { navigate } from '../../../../lib/helpers';
import { pddTestApi } from '../../../../lib/api';

import { type PddTest } from '../../../../lib/types';
import { getBreadCrumbsByUrlForCard } from '../../../../lib/autoBreadcrumbs';

import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { notification } from 'antd';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/pdd/test/register/';

export const submitPddTest = async (payload: PddTest): Promise<?number> => {
  if (!payload.pddTestGroups) {
    notification.error({
      message: 'Добавьте минимум 1 тему ПДД'
    });
    return null;
  }
  let id = payload.id;
  if (id) {
    await pddTestApi.update(payload);
  } else {
    let data = await pddTestApi.add(payload);
    id = data.id;
  }
  return id;
};

export default (props: PageProps) => {
  const [data: PddTest, setData] = useState({
    id: props.id,
    addQuestions: true,
    withoutTickets: true,
    type: 'training',
    examTime: 0
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, props.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await pddTestApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: PddTest) => {
    const id = await submitPddTest(payload);
    if (id) await navigate(`${entryPointPath}`);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Тестирования  ПДД'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
