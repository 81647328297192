// @flow

import type { Dispatch } from 'redux';

import type { MaintenanceOperationGroup } from './../lib/types';
import { maintenanceOperationGroupApi } from '../lib/api';
import type { Action } from './redux';

export const SET_MAINTENANCE_OPERATION_GROUPS =
  'vehicles/maintenance-operation-group/set';

const initialState: ?MaintenanceOperationGroup = null;

const reducer = (
  state: ?MaintenanceOperationGroup = initialState,
  { type, payload }: Action
): ?MaintenanceOperationGroup => {
  switch (type) {
    case SET_MAINTENANCE_OPERATION_GROUPS:
      return payload;
    default:
      return state;
  }
};

export const fetchMaintenanceOperationGroups: Function = (): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const maintenanceOperationGroups = await maintenanceOperationGroupApi.fetchMaintenanceOperationGroups();
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUPS,
    payload: {
      ...maintenanceOperationGroups
    }
  });
};

export default reducer;
