// @flow
import type { VehicleTariff } from './../types';

import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import CommonApi from './../commonApi';

const convertDataToServer = (vehicleTariff: VehicleTariff) => ({
  ...vehicleTariff,
  tariffFixed: convertFromRubToKop(vehicleTariff.tariffFixed),
  tariffDynamic: convertFromRubToKop(vehicleTariff.tariffDynamic),
  oilCost: convertFromRubToKop(vehicleTariff.oilCost)
});

const convertDataFromServer = (vehicleTariff: VehicleTariff) => ({
  ...vehicleTariff,
  tariffFixed: convertFromKopToRub(vehicleTariff.tariffFixed),
  tariffDynamic: convertFromKopToRub(vehicleTariff.tariffDynamic),
  oilCost: convertFromKopToRub(vehicleTariff.oilCost)
});

const vehicleTariff = new CommonApi<VehicleTariff>(
  'vehicleTariff',
  'тарифы на НТС',
  convertDataToServer,
  convertDataFromServer
);

export default {
  addVehicleTariff: vehicleTariff.add,
  updateVehicleTariff: vehicleTariff.update,
  deleteVehicleTariff: vehicleTariff.delete,
  getVehicleTariff: vehicleTariff.get,
  fetchVehicleTariff: vehicleTariff.fetch
};
