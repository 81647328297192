// @flow
import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

import { pddQuestionApi } from '../../../lib/api';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';

import { getBreadCrumbsByUrlForCard } from '../../../lib/autoBreadcrumbs';
import type { PddQuestion } from '../../../lib/types';
import Section, {
  SectionContent,
  SectionTitle
} from '../../../components/layout/Section';
import Table from '../../../components/ui/Table';

import { Card } from '../../../components';
import { navigate } from '@reach/router';
const { Field } = Card;
type InnerProps = {
  data: PddQuestion
};

type PageProps = {
  id: string
};

const StyledDivider = styled(Divider)`
  margin: 0 0 20px 0;
`;

const entryPointPath = '/admin/pdd/questions/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Section>
        <SectionTitle divider>Описание вопроса</SectionTitle>
        <SectionContent>
          <Field label="Темы ПДД">{props.data.group?.name}</Field>

          <StyledDivider />
          <Field label="Вопрос">{props.data.question}</Field>
          <StyledDivider />
          <Field label="Поснение к ответу">{props.data.answerNote}</Field>
          <StyledDivider />
          {props.data.photoId && (
            <>
              <SectionTitle>Фото</SectionTitle>
              <img
                src={props.data.photo.url}
                alt="Фото"
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </>
          )}
        </SectionContent>
      </Section>

      <Section>
        <SectionTitle divider>Варианты ответов</SectionTitle>
        <SectionContent>
          <Table
            columns={[
              {
                title: 'Описание ответа',
                dataIndex: 'description',
                width: '90%',
                render: description => (
                  <div style={{ whiteSpace: 'pre-line' }}>{description}</div>
                )
              },
              {
                title: 'Правильный ответ',
                dataIndex: 'isRight',
                render: (isRight: boolean) => (isRight ? 'Да' : 'Нет')
              }
            ]}
            data={props.data.answers}
          />
        </SectionContent>
      </Section>
    </>
  );
};

export default (props: PageProps) => {
  const [data: PddQuestion, setData] = useState([]);

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath);

  const access = {
    // $FlowFixMe
    edit: async () => await navigate(`${entryPointPath}edit/${data.id}`)
  };
  const onFetch = useCallback(async () => {
    setData(await pddQuestionApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: `Вопрос ${props.id}`,
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} noWrapMe />
    </CommonCardPage>
  );
};
