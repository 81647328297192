// @flow

import React, { useState } from 'react';
import notification from 'antd/lib/notification';

import washingApi from './../../../../lib/api/washing';
import { washingStatusEnum } from './../../../../lib/enum';
import type {
  Washing,
  WashingType,
  WashingPlanVehicle,
} from './../../../../lib/types';
import { notificationLoading } from './../../../../components/Notifications';
import Selects from './../../../../components/selects';
import { Popconfirm } from './../../../../components/ui';

const { WashingTypeSelect } = Selects;

type TalonPopover = WashingPlanVehicle & {
  date: string,
};

type Props = {
  washingPlanVehicle: TalonPopover,
  fetchMonthlyWashingPlan: Function,
};

const addTalon = async (
  washingPlanVehicle: TalonPopover,
  type: WashingType
): Promise<?Washing> => {
  try {
    notificationLoading({
      message: 'Обновление данных',
      key: 'saving',
    });
    const vehicleId = washingPlanVehicle.vehicle
      ? washingPlanVehicle.vehicle.id
      : false;
    if (vehicleId) {
      const savedWashing = await washingApi.addWashing({
        vehicleId,
        date: washingPlanVehicle.date,
        state: washingStatusEnum.scheduled,
        factDate: null,
        paymentAmount: washingPlanVehicle[`${type}Price`],
        type,
      });
      if (savedWashing) {
        notificationLoading({
          message: 'Загрузка файла...',
          key: 'print',
        });
        const id = savedWashing.id ? savedWashing.id : false;
        if (id) await washingApi.printTicket(id);
      }
    }
  } catch (err) {
    notification.error({
      message: 'Ошибка',
      description: err.message,
    });
  } finally {
    notification.close('saving');
    notification.close('print');
  }
};

export default (props: Props) => {
  const [type, setWashingType] = useState(null);
  return (
    <Popconfirm
      overlayStyle={{
        zIndex: 2000,
        width: 250,
      }}
      placement="left"
      title={
        <>
          <p>Укажите тип мойки</p>
          <WashingTypeSelect
            dropdownStyle={{ zIndex: '2001' }}
            onChange={(type: any) => {
              setWashingType(type);
            }}
          />
        </>
      }
      onConfirm={async () => {
        if (type === null) {
          return;
        }
        await addTalon(props.washingPlanVehicle, type);
        props.fetchMonthlyWashingPlan();
      }}
    >
      Талон на мойку
    </Popconfirm>
  );
};
