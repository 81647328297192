import type { Action } from './redux';
import { Dispatch } from 'redux';
import _merge from 'lodash/merge';

import { authApi } from '../lib/api';

const SET_STORE = 'vehicles/wialonStore/setStore';
const FETCH_WIALON_TOKEN = 'vehicles/wialonStore/fetchWialonToken';

export type WialonStoreState = {
  accessToken: ?string
};

const initialState: WialonStoreState = {
  accessToken: null
};

const reducer = (
  state: WialonStoreState = initialState,
  action: Action
): WialonStoreState => {
  const { type, payload } = action;
  switch (type) {
    case SET_STORE:
      return _merge({ ...state }, { ...payload });
    case FETCH_WIALON_TOKEN:
      return {
        ...state,
        accessToken: payload
      };
    default:
      return state;
  }
};

export const setStore = (value: $Shape<WialonStoreState>) => (
  dispatch: Dispatch
) => dispatch({ type: SET_STORE, payload: value });

export const fetchWialonToken = () => async (dispatch: Dispatch) => {
  const wialonToken = await authApi.getWialonToken();
  dispatch({ type: FETCH_WIALON_TOKEN, payload: wialonToken });
};

export default reducer;
