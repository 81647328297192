// @flow

import { Divider } from 'antd';
import Button from 'antd/lib/button';

import Input from 'antd/lib/input/Input';
import { FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';
import CancelButton from '../../../../components/CancelButton';
import { InputNumber } from '../../../../components/inputs';
import {
  TireDiameterSelect,
  TireProfileSelect,
  TireWidthSelect,
} from '../../../../components/selects/AutoGeneratedEnumSelects';
import BrandsSelect from '../../../../components/selects/BrandsSelect';
import { getMomentValue, isVehicleTractor } from '../../../../lib/helpers';

import type { Tire, Vehicle } from '../../../../lib/types';
import { convertDateToString } from '../../../Vehicles/lib';

import { Form, Selects } from './../../../../components';
import { Section } from './../../../../components/layout';
import Grid, { GridItem } from './../../../../components/layout/Grid';

const { SeasonTypeTireSelect } = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 16px;
`;

type FormProps = {
  tire: $Shape<Tire>,
  onCancel: Function,
  onSubmit: Function,
  vehicle: ?Vehicle,
  vehicleId?: number
};

const InnerForm = ({
  onSubmit,
  onCancel,
  tire,
  vehicleId,
  vehicle
}: FormProps) => (
  <Form initialValues={tire} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps<Tire>) => {
      const {
        handleSubmit,
        setFieldValue,
        values,
        dirty,
        isSubmitting
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px" cols={4}>
                <GridItem>
                  <FormField label="Марка шины" name="brandId" required>
                    {({ value, name }) => (
                      <BrandsSelect
                        value={value}
                        onChange={(brandId: number) =>
                          setFieldValue(name, brandId)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип шины" name="seasonType" required>
                    {({ name, value }) => (
                      <SeasonTypeTireSelect
                        name={name}
                        value={value}
                        onChange={(type: string) => setFieldValue(name, type)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem span={2}>
                  <FormField label="Наименование шины" required name="name">
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Диаметр" name="diameter" required>
                    {({ name, value }) => (
                      <TireDiameterSelect
                        value={value}
                        onChange={(value: string) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Ширина" name="width" required>
                    {({ name, value }) => (
                      <TireWidthSelect
                        value={value}
                        onChange={(value: string) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Профиль" name="profile" required>
                    {({ name, value }) => (
                      <TireProfileSelect
                        value={value}
                        onChange={(value: string) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Высота протектора, мм"
                    name="treadDepth"
                    defaultValue={0}
                    hasFeedback={false}
                  >
                    {({ value, name }) => (
                      <InputNumber
                        onChange={(value: number) => setFieldValue(name, value)}
                        value={value}
                        name={name}
                        disabled={values.id}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem fullWidth>
                  <Divider />
                </GridItem>

                <GridItem>
                  <FormField label="Инвентарный номер" name="inventoryNumber">
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Складской номер" name="stockNumber">
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Заводской номер" name="factoryNumber">
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem fullWidth>
                  <Divider />
                </GridItem>
                <GridItem>
                  <FormField
                    label="Фактический пробег"
                    name="actualKilometrage"
                    required={!values.workHours}
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        decimalSeparator=","
                        step={0.01}
                        hasFeedback={false}
                        onChange={(actualKilometrage: number) =>
                          setFieldValue(name, actualKilometrage)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Фактическая наработка моточасов"
                    name="workHours"
                    required={!values.actualKilometrage}
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        decimalSeparator=","
                        step={0.01}
                        hasFeedback={false}
                        onChange={(workHours: number) =>
                          setFieldValue(name, workHours)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                {/*Блок нужен если шина создается из карточки и ТС*/}
                {vehicleId && !tire.id && (
                  <>
                    <GridItem fullWidth>
                      <Divider />
                    </GridItem>
                    <GridItem>
                      {isVehicleTractor(vehicle) ? (
                        <FormField
                          label="Норматив моточасов без учета износа"
                          name="normWorkHours"
                          hasFeedback={false}
                          required
                        >
                          {({ name, value }) => (
                            <InputNumber
                              value={value}
                              min={2}
                              decimalSeparator=","
                              step={0.01}
                              onChange={(normWorkHours: number) =>
                                setFieldValue(name, normWorkHours)
                              }
                            />
                          )}
                        </FormField>
                      ) : (
                        <FormField
                          label="Норматив километража без учета износа"
                          name="normKilometrage"
                          hasFeedback={false}
                          required
                        >
                          {({ name, value }) => (
                            <InputNumber
                              value={value}
                              min={2}
                              decimalSeparator=","
                              step={0.01}
                              onChange={(normKilometrage: number) =>
                                setFieldValue(name, normKilometrage)
                              }
                            />
                          )}
                        </FormField>
                      )}
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Дата установки"
                        hasFeedback={false}
                        name="installDate"
                        required
                      >
                        {({ name, value }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            allowClear={false}
                            value={getMomentValue(value)}
                            name={name}
                            onChange={(value: Object, dateString: string) =>
                              setFieldValue(
                                name,
                                convertDateToString(value, dateString)
                              )
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </>
                )}
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

export default InnerForm;
