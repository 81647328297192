// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';
import { connect } from 'react-redux';

import notification from 'antd/lib/notification';
import { Table } from '../../components/ant/table/Table';

import { accessTypeEnum, orgUnitTypes } from './../../lib/enum';
import type { OrgUnitNode, UserAccess } from './../../lib/types';
import { Section } from './../../components/layout';
import { Popconfirm, Icon, Operations } from './../../components/ui';
import Header from '../../components/layout/Header';
import { notificationLoading } from './../../components/Notifications';

import { selectOrgUnits } from './../../ducks/selectors';
import type { AppState } from '../../ducks/redux';
import { deleteOrgUnit, fetchOrgUnits } from './../../ducks/orgUnits';

type Props = {
  orgUnits: OrgUnitNode,
  userAccess: UserAccess[],
  deleteOrgUnit: Function,
  fetchOrgUnits: Function,
};
type State = {
  columns: Array<Object>,
};
const StyledIcon = styled(Icon)`
  color: #1890ff;
  margin: 0 5px;
  cursor: pointer;
`;

class OrgUnitTable extends Component<Props, State> {
  state = {
    columns: [
      {
        title: 'Наименование',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Мнемокод',
        dataIndex: 'mnemonic',
        key: 'mnemonic',
      },
      {
        title: 'Тип',
        dataIndex: 'departmentType',
        key: 'departmentType',
      },
      {
        title: 'Вид',
        dataIndex: 'type',
        key: 'type',
        render: (text: string) => orgUnitTypes[text],
      },
    ],
  };

  async componentDidMount() {
    const { columns } = this.state;
    await this.props.fetchOrgUnits();
    this.setState({
      columns: [
        ...columns,
        {
          title: ' ',
          dataIndex: 'id',
          className: 'operations',
          // eslint-disable-next-line no-unused-vars
          render: (text: string, record: OrgUnitNode): any => (
            <Operations>
              <Link data-cy="edit" to={`/admin/org-units/${record.id}`}>
                <StyledIcon type="edit" />
              </Link>
              {this.canAdd() && (
                <Link data-cy="add" to={`/admin/org-units/${record.id}/new`}>
                  <StyledIcon type="plus" />
                </Link>
              )}
              {!(record.children && record.children.length) && this.canAdd() && (
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={async () => await this.deleteOrgUnit(record.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              )}
            </Operations>
          ),
        },
      ],
    });
  }

  async deleteOrgUnit(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await this.props.deleteOrgUnit(id);
      await this.props.fetchOrgUnits();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrgUnits,
      ].includes(access)
    );

  render() {
    const { orgUnits } = this.props;
    const { columns } = this.state;
    return (
      <>
        <Header left={<h1>Подразделения</h1>} />
        <Section>
          <Table
            defaultExpandAllRows={false}
            columns={columns}
            dataSource={orgUnits ? [orgUnits] : null}
            pagination={false}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userAccess: state.auth.profile.access,
    orgUnits: selectOrgUnits(state),
  }),
  {
    deleteOrgUnit,
    fetchOrgUnits,
  }
)(OrgUnitTable);
