// @flow
import React, { useState } from 'react';
import { Popconfirm, Icon } from '../../../components/ui';

import {
  formatDateRangeString,
  formatDateTimeToString,
  navigate,
} from '../../../lib/helpers';
import { budgetSummaryApi } from '../../../lib/api';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { withUserAccess } from '../../withUserAccess';

import { getBreadCrumbsByUrl } from '../../../lib/autoBreadcrumbs';
import type {
  BudgetSummary,
  BudgetSummaryStatusType,
  BudgetVersion,
} from '../../../lib/types';
import {
  budgetSummaryEnum,
  budgetSummaryStatus,
  budgetSummaryStatusEnum,
} from '../../../lib/enum';

import { Filter } from './components';
import {
  addDetailedBudgetAccessRight,
  approveDetailedBudgetAccessRight,
} from '../details/accessRight';

const entryPointPath = '/budget/summary/';

export default withUserAccess(({ userAccess, employeeBranchOrgUnitId }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);
  const [reFetch, setReFetch] = useState(false);

  const canAdd = () =>
    userAccess.some((access) => addDetailedBudgetAccessRight.includes(access));

  const canApprove = () =>
    userAccess.some((access) =>
      approveDetailedBudgetAccessRight.includes(access)
    );

  const onDelete = async (id: number) => {
    try {
      await budgetSummaryApi.delete(id);
      await setReFetch(true);
    } catch (error) {
    } finally {
      setReFetch(false);
    }
  };

  const columns = [
    {
      title: '№ бюджета',
      dataIndex: 'id',
    },
    {
      title: 'Версия бюджета',
      dataIndex: 'budgetVersion',
      render: (budgetVersion?: BudgetVersion) => budgetVersion?.name ?? '-',
    },
    {
      title: 'Дата формирования',
      dataIndex: 'date',
      render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY'),
    },
    {
      title: 'Планируемый период',
      dataIndex: 'startPlannedDate',
      render: (startPlannedDate: string, row: BudgetSummary) =>
        `${formatDateRangeString(row.startDate, row.endDate, 'DD.MM.YYYY')}`,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: BudgetSummaryStatusType) => budgetSummaryStatus[status],
    },
    {
      stopPropagation: true,
      width: 20,
      dataIndex: 'id',
      render: (id: number, record: any) =>
        record.status !== budgetSummaryStatusEnum.approved &&
        canAdd() && (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => await onDelete(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
    },
  ];

  return (
    <Page>
      <CommonListPage
        crud={budgetSummaryApi}
        reFetch={reFetch}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Основной бюджет',
          access: {
            add: canAdd() && (() => navigate(`${entryPointPath}new`)),
          },
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) =>
            navigate(
              canApprove()
                ? `${entryPointPath}${id}/-1`
                : `${entryPointPath}${id}/${employeeBranchOrgUnitId}`
            ),
        }}
        filter={Filter}
        filterMixin={{ type: budgetSummaryEnum.main }}
      />
    </Page>
  );
});
