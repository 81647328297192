//@flow
import React, { useCallback } from 'react';
import moment from 'moment';

import { FilterItem } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import { formatDateTimeToISOString } from '../../../lib/helpers';
import VehicleTypeSelect from '../../../components/selects/VehicleTypeSelect';
import type { VehicleGroupAndType } from '../../../components/selects/VehicleTypeSelect';
import { vehicleTypeEnum } from '../../../lib/enum';

export type TripInspectionFrequencyParamsFilter = {
  vehicleType?: vehicleTypeEnum,
  startDate?: string,
  endDate?: string
};

export const TripInspectionFrequencyFilter = (
  props: FilterProps<TripInspectionFrequencyParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<TripInspectionFrequencyParamsFilter>>) =>
      CommonFilter<TripInspectionFrequencyParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleTypeSelect
                size="small"
                resultAsObject
                onChange={({ type }: VehicleGroupAndType) => {
                  changeValue('vehicleType', type);
                }}
                value={values.vehicleType}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default TripInspectionFrequencyFilter;
