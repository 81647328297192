// @flow

import type { CompareExpenseDirection } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const addCompareExpenseDirection = async (
  compareExpenseDirection: CompareExpenseDirection
): Promise<CompareExpenseDirection> => {
  const added = await fetchRequest.post(
    '/compareExpenseDirection',
    compareExpenseDirection
  );
  if (added) return added;
  throw new Error('Не удалось добавить новое направление расходов');
};

export const updateCompareExpenseDirection = async (
  compareExpenseDirection: CompareExpenseDirection
): Promise<CompareExpenseDirection> => {
  const updated = await fetchRequest.put(
    '/compareExpenseDirection',
    compareExpenseDirection
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить направление расходов');
};

export const getCompareExpenseDirection = async (
  id: number,
  params: any = {}
): Promise<CompareExpenseDirection> => {
  let compareExpenseDirection = await fetchRequest.get(
    `/compareExpenseDirection/${id}`,
    params
  );
  if (compareExpenseDirection) return compareExpenseDirection;
  throw new Error('Не удалось загрузить направление расходов');
};

export const fetchCompareExpenseDirection = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<CompareExpenseDirection>> =>
  await fetchRequest.get('/compareExpenseDirection', {
    ...initialFetchListParams,
    ...params
  });

export const deleteCompareExpenseDirection = async (
  id: number
): Promise<CompareExpenseDirection> => {
  const deleted = await fetchRequest.delete(`/compareExpenseDirection/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить направление расходов');
};

export default {
  addCompareExpenseDirection,
  updateCompareExpenseDirection,
  fetchCompareExpenseDirection,
  getCompareExpenseDirection,
  deleteCompareExpenseDirection
};
