// @flow

import React, { Component } from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type { ContractVehiclePlan } from './../../../../../../lib/types';
import { contractVehiclePlanApi } from '../../../../../../lib/api';
import {
  formatDateRangeString,
  formatDate
} from './../../../../../../lib/helpers';

import {
  Header,
  TopPanel,
  Section,
  SectionTitle,
  Grid
} from './../../../../../../components/layout';
import { GridItem } from './../../../../../../components/layout/Grid';
import Breadcrumbs, {
  Crumb
} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';

import Tabs from './../components/Tabs';
import InnerTable from './components/InnerTable';

type Props = {
  contractVehiclePlanId: number
};
type State = {
  contractVehiclePlan: ?ContractVehiclePlan
};

const Content = styled.div`
  padding: 16px;
`;

export default class extends Component<Props, State> {
  state = {
    contractVehiclePlan: null
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    try {
      const { contractVehiclePlanId } = this.props;
      const contractVehiclePlan = await contractVehiclePlanApi.get(
        contractVehiclePlanId
      );
      this.setState({ contractVehiclePlan });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { contractVehiclePlanId } = this.props;
    const { contractVehiclePlan } = this.state;
    if (contractVehiclePlan === null) {
      return null;
    }
    const contractVehicles =
      contractVehiclePlan && contractVehiclePlan.contractVehicles
        ? contractVehiclePlan.contractVehicles
        : [];
    const plannedContractVehicles =
      contractVehiclePlan && contractVehiclePlan.plannedContractVehicles
        ? contractVehiclePlan.plannedContractVehicles
        : [];
    return (
      <>
        {contractVehiclePlan && (
          <>
            <Header
              left={
                <Breadcrumbs>
                  <Crumb to="/">Главная</Crumb>
                  <Crumb to="/budget/contract-vehicle/fixed">Наемные ТС</Crumb>
                  <Crumb>Закрепленные НТС</Crumb>
                </Breadcrumbs>
              }
            />
            <TopPanel>
              <h1>Планируемый перечень ТС</h1>
            </TopPanel>
            <Tabs contractVehiclePlanId={contractVehiclePlanId} />

            <Section>
              <SectionTitle divider>Период и версия бюджета</SectionTitle>
              <Content>
                <Grid gutter="16px" cols={4}>
                  <GridItem>
                    <Field label="Планируемый период">
                      <>
                        {formatDateRangeString(
                          contractVehiclePlan.startDate,
                          contractVehiclePlan.endDate,
                          'DD.MM.YYYY'
                        )}
                      </>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Версия бюджета">
                      <>
                        {contractVehiclePlan &&
                          contractVehiclePlan.budgetVersion &&
                          contractVehiclePlan.budgetVersion.name}
                      </>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата формирования">
                      <>{formatDate(contractVehiclePlan.date)}</>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Регламент">
                      <>Регламент {contractVehiclePlan.regulationId}</>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Филилал">
                      <>{contractVehiclePlan.orgUnitName}</>
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            <Section>
              <SectionTitle divider>
                ТС согласно инвентарной картотеке
              </SectionTitle>
              <Content>
                <InnerTable data={contractVehicles} />
              </Content>
            </Section>

            <Section>
              <SectionTitle divider>ТС согласно плану обновления</SectionTitle>
              <Content>
                <InnerTable data={plannedContractVehicles} />
              </Content>
            </Section>
          </>
        )}
      </>
    );
  }
}
