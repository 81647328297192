import {
    WashingPriceListCard,
    WashingPriceListForm,
    WashingPriceListTable,
} from '../../containers/WashingPriceList';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: WashingPriceListForm,
        path: '/admin/washing-price-list/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: WashingPriceListForm,
        path: '/admin/washing-price-list/:washingPriceListId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: WashingPriceListForm,
        path: '/admin/washing-price-list/:copyWashingPriceListId/copy',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: WashingPriceListTable,
        path: '/admin/washing-price-list',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
    {
        component: WashingPriceListCard,
        path: '/admin/washing-price-list/:washingPriceListId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
];