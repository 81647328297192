// @flow

import React, { Component } from 'react';

import { gpmMaintenanceCalculationApi } from '../../../../lib/api';
import { toLocalStringRu } from '../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';
import { COLUMNS, MONTH } from '../lib';

import Common from './components/Common';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

export default class extends Component<Props, {}> {
  columns = [
    ...COLUMNS,
    ...MONTH.map((month: any, monthIndex: number) => ({
      title: month.title,
      key: `${monthIndex}`,
      children: [
        {
          title: 'Экспертиза',
          // eslint-disable-next-line no-template-curly-in-string
          key: 'months[${monthIndex}].expertiseCost',
          dataIndex: `months[${monthIndex}].expertiseCost`,
          render: (cost: number) => toLocalStringRu(cost)
        },
        {
          title: 'ТО',
          // eslint-disable-next-line no-template-curly-in-string
          key: 'months[${monthIndex}].maintenanceCost',
          dataIndex: `months[${monthIndex}].maintenanceCost`,
          render: (cost: number) => toLocalStringRu(cost)
        },
        {
          title: 'Общая',
          className: 'primary-background',
          // eslint-disable-next-line no-template-curly-in-string
          key: 'months[${monthIndex}].cost',
          dataIndex: `months[${monthIndex}].cost`,
          render: (cost: number) => toLocalStringRu(cost)
        }
      ]
    })),
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) => toLocalStringRu(sum)
    }
  ];
  render() {
    const { location, vehiclePlanId } = this.props;
    return (
      <>
        <Common
          location={location}
          vehiclePlanId={vehiclePlanId}
          columns={this.columns}
          scrollX={5000}
          crud={gpmMaintenanceCalculationApi}
          statusField={'gpmMaintenanceCalculationStatus'}
          title={'План расходов на экспертизу и ТО ПБ ГПМ'}
          handlePrint={filter => {
            printNotification(async () => {
              await gpmMaintenanceCalculationApi.print(vehiclePlanId, filter);
            });
          }}
        />
      </>
    );
  }
}
