// @flow
import type {
  MaintenanceOperation,
  MaintenancePartAndConsumables
} from './../../../lib/types';

export const sumOperations = (
  operations: MaintenanceOperation[],
  isContractor: boolean
) => {
  let sum = 0;
  let sumContractor = 0;
  operations.forEach((operation: MaintenanceOperation) => {
    sum += operation.hours * operation.count * operation.price;
    if (isContractor) {
      sumContractor +=
        operation.contractorHours * operation.count * operation.contractorPrice;
    }
  });
  return { sum, sumContractor };
};

export const sumPartsAndConsumbles = (
  parts: MaintenancePartAndConsumables[]
) => {
  let sum = 0;
  parts.forEach((part: MaintenancePartAndConsumables) => {
    const stockPartCost = part.stockPart.cost || 0;
    sum += part.count * stockPartCost;
  });
  return { sum, sumContractor: sum };
};
