// @flow
import React, { useState, useCallback } from 'react';

import { goBack } from '../../../lib/helpers';
import { technicalFluidApi } from '../../../lib/api';
import { type TechnicalFluid } from '../../../lib/types';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import { TicketsInnerForm } from './../components';
import { getBreadCrumbsByUrlForEdit } from '../../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/technical-fluid/tickets/';

export default (props: PageProps) => {
  const [data: TechnicalFluid, setData] = useState({
    id: props.id,
    date: new Date().toISOString()
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, props.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await technicalFluidApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: TechnicalFluid) => {
    let id = payload.id;
    if (id) {
      await technicalFluidApi.update(payload);
    } else {
      let data = await technicalFluidApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}${id}`);
  };

  const onCancel = async (payload: TechnicalFluid) => {
    if (data.id) {
      await goBack(`${entryPointPath}${data.id}`);
    } else await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: data.id
          ? `Талон на выдачу тех. жидкости №${data.id}`
          : 'Новый талон на выдачу тех. жидкости'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <TicketsInnerForm data={data} noWrapMe />
    </CommonCardPage>
  );
};
