// @flow

import React, { Component } from 'react';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { regulationApi } from '../../../../../lib/api';
import type {
  EntityStatusType,
  Regulation,
  UserAccess
} from '../../../../../lib/types';
import { entityStatus, entityStatusEnum } from '../../../../../lib/enum';

import {
  Grid,
  Header,
  Section,
  TopPanel
} from './../../../../../components/layout';
import { OrgUnitSelect } from './../../../../../components';
import { GridItem } from './../../../../../components/layout/Grid';
import { Icon } from '../../../../../components/ui';
import Breadcrumbs, {
  Crumb
} from './../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../components/card/Field';

import { notificationLoading } from '../../../../../components/Notifications';
import { withUserAccess } from '../../../../withUserAccess';
import { canHandling } from '../lib';

import { handlingAccessRightWorkPlans } from './../accessRight';

import TimeLimit from './TimeLimit/TimeLimits';

type Props = {
  userAccess: UserAccess[],
  regulationId: number,
  readonly: boolean
};

type State = {
  workPlans: Regulation,
  readonly: boolean
};

const Content = styled.div`
  padding: 16px;
`;

const OperationIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
  width: 16px;
  height: 16px;
  & img {
    width: 100%;
  }
`;

class Form extends Component<Props, State> {
  state = {
    workPlans: {
      id: this.props.regulationId,
      orgUnitId: null,
      orgUnitName: null,
      status: entityStatusEnum.created
    },
    readonly: this.props.readonly
  };

  componentDidMount = () => {
    if (this.props.regulationId) {
      this.fetchData();
    }
  };

  fetchData = async () => {
    try {
      if (this.state.workPlans.id) {
        const data = await regulationApi.get(this.state.workPlans.id);
        this.setState({ workPlans: data });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  changeStatus = async (newStatus: EntityStatusType) => {
    const data = await regulationApi.changeStatus.update({
      ...this.state.workPlans,
      status: newStatus
    });
    this.setState({ workPlans: data });
  };

  createRegulation = async () => {
    try {
      notificationLoading({
        message: 'Создание регламента...',
        key: 'creating'
      });
      let data = await regulationApi.add({
        orgUnitId: this.state.workPlans.orgUnitId
      });
      this.setState({ workPlans: data }, async () => {
        await this.changeStatus(entityStatusEnum.created);
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('creating');
    }
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      handlingAccessRightWorkPlans.includes(access)
    );

  render() {
    const { workPlans, readonly } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/contract-vehicle/work-plans">
                Регламенты НТС
              </Crumb>
              <Crumb>Регламент</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>
            Регламент работы НТС
            {readonly &&
              this.canEdit() &&
              canHandling(this.props.userAccess) && (
                <OperationIcon
                  onClick={() => this.setState({ readonly: false })}
                  type="edit"
                  size={16}
                />
              )}
          </h1>
        </TopPanel>

        <Section>
          <Content>
            <Grid gutter="16px">
              {!workPlans?.id && (
                <>
                  <GridItem>
                    <Field label="Подразделение" mBottomNone>
                      <OrgUnitSelect
                        value={workPlans?.orgUnitId}
                        onlyBranches
                        onChange={orgUnitId =>
                          this.setState(prevState => {
                            return {
                              workPlans: { ...prevState.workPlans, orgUnitId }
                            };
                          })
                        }
                      />
                    </Field>
                  </GridItem>
                  <GridItem
                    style={{
                      display: 'flex',
                      'align-items': 'center',
                      'justify-content': 'right'
                    }}
                  >
                    <Button
                      type="primary"
                      disabled={!workPlans.orgUnitId}
                      onClick={this.createRegulation}
                    >
                      Создать
                    </Button>
                  </GridItem>
                </>
              )}
              {!!workPlans?.id && (
                <>
                  <GridItem>
                    <Field label="Подразделение" mBottomNone>
                      {workPlans.orgUnitName}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Статус" mBottomNone>
                      {entityStatus[workPlans.status]}
                    </Field>
                  </GridItem>
                </>
              )}
            </Grid>
          </Content>
        </Section>

        {!!workPlans?.id && (
          <TimeLimit
            {...workPlans}
            readonly={readonly}
            changeStatus={this.changeStatus}
          />
        )}
      </>
    );
  }
}

export default withUserAccess(Form);
