// @flow
import type { AssignmentLimit } from './../types';
import CommonApi from './../commonApi';

import { convertFromKopToRub } from './../helpers';

export const convertDataFromServer = (assignmentLimit: AssignmentLimit) => ({
  ...assignmentLimit,
  sum: convertFromKopToRub(assignmentLimit.sum),
  assignmentMonths: [
    ...assignmentLimit.assignmentMonths.map(assignmentMonth => {
      return {
        ...assignmentMonth,
        sum: convertFromKopToRub(assignmentMonth.sum)
      };
    })
  ]
});
const assignmentLimit = new CommonApi<AssignmentLimit>(
  'assignmentLimit',
  'лимит закрепления',
  undefined,
  convertDataFromServer
);

export default assignmentLimit;
