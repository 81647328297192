import {
    MonitoringPriceCard,
    MonitoringPriceForm,
    MonitoringPriceList,
} from '../../containers/MonitoringPrice';
import { accessTypeEnum } from '../../lib/enum';

//прайсы для мониторинга
export default [
    {
        component: MonitoringPriceList,
        path: '/admin/monitoring-price',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
        ],
    },
    {
        component: MonitoringPriceForm,
        path: '/admin/monitoring-price/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: MonitoringPriceForm,
        path: '/admin/monitoring-price/:id/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
    {
        component: MonitoringPriceCard,
        path: '/admin/monitoring-price/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingAllPriceLists,
            accessTypeEnum.viewingPriceLists,
            accessTypeEnum.handlingPriceLists,
            accessTypeEnum.approvingPriceLists,
        ],
    },
];