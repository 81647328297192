import React from 'react';
import styled, { css } from 'styled-components';
import { monitoringVehicleStatesEnum } from '../../../../../../../../../lib/enum';

export const Wrapper = styled.div`
  height: 13px;
  width: 100%;
  display: flex;
  pointer-events: none;
`;

const stateColor = {
  stopped: '#E9180A',
  parking: '#2770FF',
  undef: '#C0CCD7'
};

export const Event = styled(({ state, ...props }) => <div {...props} />)`
  height: 13px;
  pointer-events: none;
  border-radius: 2px;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 0 2px;
  box-sizing: border-box;
  font-weight: bold;
  color: white;
  user-select: none;
  ${props =>
    props.state !== monitoringVehicleStatesEnum.inmove &&
    css`
      background: ${stateColor[props.state]};
    `};
`;
