// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  Selects,
} from '../../../components';
import { RangePicker } from '../../../components/ant/DatePicker';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import type { Vehicle } from '../../../lib/types';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import { serviceTypeEnum } from '../../../lib/enum';
import Field from '../../../components/card/Field';

const { VehicleSelect, ContractorSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type InspectionGibddFilterParams = {
  contractorId?: number,
  vehicleId?: number,
  startDate?: string | moment,
  endDate?: string | moment,
  nextStartDate?: string,
  nextEndDate?: string
};

type Props = {
  style?: any,
  filter: InspectionGibddFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const InspectionGibddFilter = ({
  style,
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent style={{ ...style }}>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<InspectionGibddFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  onChange={(value: number) => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value => changeValue('contractorId', value)}
                  value={values.contractorId}
                  filter={{
                    services: serviceTypeEnum.technicalInspection
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Период', 'проведения']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Дата', 'след. ТО']}
                  value={[
                    values.nextStartDate
                      ? moment.utc(values.nextStartDate)
                      : null,
                    values.nextEndDate ? moment.utc(values.nextEndDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [nextStartDate, nextEndDate] = value;
                    const [nextStartDateString, nextEndDateString] = dateString;
                    changeValue(
                      'nextStartDate',
                      formatDateTimeToISOString(
                        nextStartDate,
                        nextStartDateString
                      )
                    );
                    changeValue(
                      'nextEndDate',
                      formatDateTimeToISOString(nextEndDate, nextEndDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default InspectionGibddFilter;
