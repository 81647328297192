// @flow
import React from 'react';

import type { UserAccess } from '../../../lib/types';

import { vehicleTariffEnum } from '../../../lib/enum';

import { withUserAccess } from '../../withUserAccess';
import { InnerList } from '../components';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

const COLUMNS = [
  {
    title: 'Марка',
    dataIndex: 'vehicleModel.brandName'
  },
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModel.name'
  },
  {
    title: 'Тип ТС',
    dataIndex: 'vehicleModel.typeMdm'
  }
];
export const VehicleTariffBrandList = (props: Props) => {
  return (
    <>
      <InnerList
        {...props}
        type={vehicleTariffEnum.byBrand}
        extColumns={COLUMNS}
      />
    </>
  );
};

export default withUserAccess(VehicleTariffBrandList);
