// @flow
import React from 'react';

import { TabItem } from '../../../components/ui';
import { Router } from '@reach/router';
import MonthDailyBudgetTable from './MonthDailyBudgetTable';
import YearDailyBudgetTable from './YearDailyBudgetTable';
import { StyledTabs } from './elements';
import { connect } from 'react-redux';
import type { AppState } from '../../../ducks/redux';
import type { UserAccess } from '../../../lib/types';
import { showMonthPlan, showYearPlan } from './lib';

type Props = {
  userAccess: UserAccess[],
  orgUnitId: number
};

/**
 * Планирование лимитных дней на год и месяц
 */
const DailyBudgets = ({ userAccess, orgUnitId }: Props) => {
  const canViewYearPlan = showYearPlan(userAccess);
  const canViewMonthPlan = showMonthPlan(userAccess);

  return (
    <>
      <StyledTabs background="#F0F4F8" defaultTab="year" withRouter>
        {canViewYearPlan && (
          <TabItem
            label="Годовой план"
            url="/contract-vehicle-work-plans/daily-budgets"
          />
        )}
        {canViewMonthPlan && (
          <TabItem
            label="Месячный план"
            url="/contract-vehicle-work-plans/daily-budgets/month/"
          />
        )}
      </StyledTabs>
      <Router>
        {canViewYearPlan && (
          <YearDailyBudgetTable orgUnitId={orgUnitId} path="/" />
        )}
        {canViewMonthPlan && (
          <MonthDailyBudgetTable orgUnitId={orgUnitId} path="month" />
        )}
      </Router>
    </>
  );
};

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access
}))(DailyBudgets);
