// @flow
import queryString from 'query-string';
import type { VehicleMonitoring, ExecutionBudgetGroupedList } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { VehicleMonitoringFilterParams } from '../../containers/services/vehicleMonitoring/Filter';

export const convertDataFromServer = (
  vehicleMonitoring: VehicleMonitoring
) => ({
  ...vehicleMonitoring,
  paymentAmount: convertFromKopToRub(vehicleMonitoring.paymentAmount),
  act: vehicleMonitoring.act
    ? actConvertDataFromServer(vehicleMonitoring.act)
    : undefined
});

export const convertDataToServer = (vehicleMonitoring: VehicleMonitoring) => ({
  ...vehicleMonitoring,
  paymentAmount: convertFromRubToKop(vehicleMonitoring.paymentAmount)
});

export const getVehicleCount = async (): Promise<number> => {
  const count = fetchRequest.get(`/vehicleMonitoring/vehiclesCount`);
  if (count) return count;
  throw new Error('Не удалось получить кол-во ТС');
};

export const addVehicleMonitoring = async (
  vehicleMonitoring: VehicleMonitoring
): Promise<VehicleMonitoring> => {
  const added = await fetchRequest.post(
    '/vehicleMonitoring',
    convertDataToServer(vehicleMonitoring)
  );
  if (added) return added;
  throw new Error('Не удалось создать услугу по мониторингу');
};

export const updateVehicleMonitoring = async (
  vehicleMonitoring: VehicleMonitoring
): Promise<VehicleMonitoring> => {
  const updated = await fetchRequest.put(
    '/vehicleMonitoring',
    convertDataToServer(vehicleMonitoring)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить услугу по мониторингу');
};
export const deleteVehicleMonitoring = async (
  id: number
): Promise<VehicleMonitoring> => {
  const deleted = await fetchRequest.delete(`/vehicleMonitoring/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить услугу по мониторингу');
};

export const fetchVehicleMonitoring = async (
  id: number,
  params: any = {}
): Promise<VehicleMonitoring> => {
  const vehicleMonitoring = await fetchRequest.get(
    `/vehicleMonitoring/${id}`,
    params
  );
  if (vehicleMonitoring) return convertDataFromServer(vehicleMonitoring);
  throw new Error('Не удалось получить услугу по мониторингу');
};

export const fetchVehicleVehicleMonitorings = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<VehicleMonitoring>> => {
  const vehicleMonitorings = await fetchRequest.get(
    `/vehicleMonitoring/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (vehicleMonitorings) {
    return {
      ...vehicleMonitorings,
      data: vehicleMonitorings.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список услуг по мониторингу');
};

export const fetchVehicleMonitorings = async (
  params: FetchListParams<VehicleMonitoring> = initialFetchListParams
): Promise<ListResponse<VehicleMonitoring>> => {
  const vehicleMonitorings = await fetchRequest.get(`/vehicleMonitoring`, {
    ...initialFetchListParams,
    ...params
  });
  if (vehicleMonitorings) {
    return {
      ...vehicleMonitorings,
      data: vehicleMonitorings.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список услуг по мониторингу');
};
export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<VehicleMonitoring>> => {
  const vehicleMonitoring = await fetchRequest.put(
    `/vehicleMonitoring/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (vehicleMonitoring) {
    return {
      ...vehicleMonitoring,
      data: vehicleMonitoring.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за списоком монторинга ТС');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/vehicleMonitoring/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/vehicleMonitoring/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const printReport = async (
  params: VehicleMonitoringFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/vehicleMonitoring/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  groupedList,
  getTotalSum,
  updateAct,
  getVehicleCount,
  addVehicleMonitoring,
  updateVehicleMonitoring,
  deleteVehicleMonitoring,
  fetchVehicleMonitoring,
  fetchVehicleVehicleMonitorings,
  fetchVehicleMonitorings,
  printReport
};
