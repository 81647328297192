// @flow

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { JobTitle } from '../types/jobTitle';
import type { JobTitlesFilter } from '../../ducks/jobTitles';

// Получить список должностей
export const fetchJobTitles = async (
  params: FetchListParams<JobTitlesFilter> = initialFetchListParams
): Promise<ListResponse<JobTitle>> =>
  await fetchRequest.get('/jobTitle', {
    ...initialFetchListParams,
    ...params
  });

// Получить должность
export const fetchJobTitle = async (id: number) => {
  const jobTitle = await fetchRequest.get(`/jobTitle/${id}`);
  if (jobTitle) return jobTitle;
  throw new Error('Не удалость загрузить должность');
};

export const deleteJobTitle = async (id: number) => {
  const deleted = await fetchRequest.delete(`/jobTitle/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить должность');
};

export const updateJobTitle = async (jobTitle: JobTitle) => {
  const updated = await fetchRequest.put('/jobTitle', jobTitle);
  if (updated) return updated;
  throw new Error('Не удалось обновить должность');
};

export const addJobTitle = async (jobTitle: JobTitle) => {
  const added = await fetchRequest.post('/jobTitle', jobTitle);
  if (added) return added;
  throw new Error('Не удалось добавить должность');
};

export default {
  fetchJobTitles,
  fetchJobTitle,
  deleteJobTitle,
  updateJobTitle,
  addJobTitle
};
