// @flow
import { fetchRequest, initialFetchListParams } from './';
import type { FetchListParams, ListResponse } from './';
import type { MdmNode } from '../types/mdm';
import type { MdmFilterParams } from '../../containers/Mdm/components/Filter';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

export const convertDataToServer = (mdm: MdmNode) => {
  return {
    ...mdm,
    currentCost: mdm.currentCost ? convertFromRubToKop(mdm.currentCost) : null
  };
};
export const convertDataFromServer = (mdm: MdmNode) => {
  return {
    ...mdm,
    currentCost: mdm.currentCost ? convertFromKopToRub(mdm.currentCost) : null
  };
};

/**
 * Получение списка МДМ корневых записей
 */
export const fetchMdmList = async (
  params: FetchListParams<MdmFilterParams> = initialFetchListParams
): Promise<ListResponse<MdmNode>> => {
  const mdm = await fetchRequest.get('/mdm', {
    ...initialFetchListParams,
    ...params
  });
  if (mdm)
    return {
      ...mdm,
      data: mdm.data.map(convertDataFromServer)
    };
  throw new Error('не удалось получить список');
};

/**
 * Обновление МДМ записи
 */
export const updateMdmNode = async (mdmNode: MdmNode): Promise<MdmNode> => {
  const updated = await fetchRequest.put('/mdm', convertDataToServer(mdmNode));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить МТРиО запись');
};

/**
 * Добавление МДМ записи
 */
export const addMdmNode = async (mdmNode: MdmNode): Promise<MdmNode> => {
  const added = await fetchRequest.post('/mdm', convertDataToServer(mdmNode));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось добавить МТРиО запись');
};

/**
 * Получение МДМ записи
 */
export const fetchMdmNode = async (
  guid: number,
  params: any = {}
): Promise<MdmNode> => {
  const node = await fetchRequest.get(`/mdm/${guid}`, params);
  if (node) return convertDataFromServer(node);
  throw new Error('Не удалось получить МТРиО запись');
};

export default {
  fetchMdmList,
  updateMdmNode,
  fetchMdmNode,
  addMdmNode
};
