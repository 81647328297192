import {
    InspectionGtnCostCard,
    InspectionGtnCostForm,
    InspectionGtnCostList,
} from '../../containers/InspectionGtnCost';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: InspectionGtnCostList,
        path: '/admin/inspection-gtn-cost',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: InspectionGtnCostCard,
        path: '/admin/inspection-gtn-cost/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: InspectionGtnCostForm,
        path: '/admin/inspection-gtn-cost/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: InspectionGtnCostForm,
        path: '/admin/inspection-gtn-cost/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];