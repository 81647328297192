// @flow
import React, { Component } from 'react';

import Table from './../../components/ui/Table';
import {
  formatDateTimeToString,
  getListInitialState,
  setQueryParams,
  applyMaskToValue
} from './../../lib/helpers';
import { vehicleTypes } from './../../lib/enum';
import type { ListState, FuelCardVehicle } from './../../lib/types';
import { fuelCardApi, type FetchListParams } from './../../lib/api';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

type Props = {
  fuelCardId: number,
  location: Location & { state: { page: number } }
};

type State = ListState<FuelCardVehicle>;

export class FuelCardHistory extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState()
  };

  fetchFuelCardHistory = async (
    page: number = 1,
    params: FetchListParams<{}> = {}
  ) => {
    try {
      this.setState({ loading: true });

      const { data, totalCount } = await fuelCardApi.fetchFuelCardHistory(
        this.props.fuelCardId,
        {
          page,
          ...params
        }
      );
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
    } finally {
      this.setState({ loading: false });
    }
  };

  columns = [
    {
      title: 'Дата прикрепления',
      dataIndex: 'installDate',
      render: (installDate: string) => formatDateTimeToString(installDate)
    },
    {
      title: 'Тип',
      dataIndex: 'vehicle.vehicleModel.type',
      render: (vehicleType: string) => vehicleTypes[vehicleType]
    },
    {
      title: 'Марка',
      dataIndex: 'vehicle.vehicleModel.brandName'
    },
    {
      title: 'Модель',
      dataIndex: 'vehicle.vehicleModel.name'
    },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle.licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    }
  ];

  render() {
    const { location } = this.props;
    const { data, page, pageSize, loading, totalCount } = this.state;
    return (
      <Table
        loading={loading}
        data={data}
        rowKey="installDate"
        fetch={this.fetchFuelCardHistory}
        fetchOnMount
        columns={this.columns}
        pagination={{
          page,
          pageSize,
          totalCount,
          location
        }}
      />
    );
  }
}

export default FuelCardHistory;
