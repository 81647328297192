// @flow
import { navigate } from '@reach/router';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import shortid from 'shortid';
import styled from 'styled-components';
import { Form, Selects } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import { Select } from '../../../components/ant/Select';
import CancelButton from '../../../components/CancelButton';
import { InputNumber } from '../../../components/inputs';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';
import { notificationLoading } from '../../../components/Notifications';
import type { VehicleGroupAndType } from '../../../components/selects/VehicleTypeSelect';
import { addOsagoCalculation, updateOsagoCalculation } from '../../../ducks/osagoCalculation';
import type { AppState } from '../../../ducks/redux';
import {
  drivingLicenseCategories,
  drivingLicenseCategoryEnum,
  tractorDrivingLicenseCategories,
  vehicleGroupEnum,
} from '../../../lib/enum';
import {
  getRightCategoryByVehicleGroup,
  vehicleTypesRightCategoryEnum,
} from '../../../lib/enum/vehicleTypesRightCategory';
import { formatDateTimeToISOString, isVehicleTypeTractor } from '../../../lib/helpers';
import type { DrivingLicenseCategory, OsagoCalculation } from '../../../lib/types';

import { Panel, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';

const {
  VehicleTypeSelect,
  YesNoSelect,
  YearIssueSelect,
  VehicleModelSelect,
} = Selects;

const Content = styled.div`
  padding: 16px 0;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  osagoCalculation: $Shape<OsagoCalculation>
};

const InnerForm = ({ onSubmit, onCancel, osagoCalculation }: FormProps) => (
  <Form initialValues={osagoCalculation} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        handleFocus,
        setFieldValue,
        values,
        dirty,
        isSubmitting
      } = formikProps;
      const rightCategories: DrivingLicenseCategory[] = !!values.vehicleGroup
        ? getRightCategoryByVehicleGroup(values.vehicleGroup)
        : !!values.vehicleType
        ? [vehicleTypesRightCategoryEnum[values.vehicleType]]
        : [];
      const isTractor = isVehicleTypeTractor(values.vehicleType);
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <StyledGrid gutter="16px" cols={3}>
                <GridItem>
                  <FormField
                    label="Тип ТС"
                    name="vehicleType"
                    required={
                      !values.vehicleType &&
                      !values.vehicleGroup &&
                      !values.vehicleModelId
                    }
                  >
                    {({ name, value }) => (
                      <VehicleTypeSelect
                        // Если выбрали модель, то не должны заполнять тип ТС, он уже есть
                        disabled={!!values.vehicleModelId}
                        resultAsObject
                        excludeGroups={[
                          vehicleGroupEnum.floatingTransport,
                          vehicleGroupEnum.trailers,
                          vehicleGroupEnum.atvs
                        ]}
                        onChange={(vehicleType: VehicleGroupAndType) => {
                          const { group, type } = vehicleType;
                          setFieldValue('maxWeight', undefined);
                          setFieldValue('enginePower', undefined);
                          setFieldValue('seatsCount', undefined);
                          // Для тракторов/экскаваторов
                          if (isVehicleTypeTractor(type)) {
                            setFieldValue('licenseCategory', undefined);
                            setFieldValue(name, type);
                            setFieldValue('vehicleGroup', group);
                            return;
                          }
                          if (!type && !group) {
                            setFieldValue('licenseCategory', undefined);
                            setFieldValue(name, undefined);
                            setFieldValue('vehicleGroup', undefined);
                            return;
                          }
                          if (!!type) {
                            setFieldValue(
                              'licenseCategory',
                              vehicleTypesRightCategoryEnum[type]
                            );
                            setFieldValue(name, type);
                            setFieldValue('vehicleGroup', undefined);
                          }
                          if (!!group) {
                            setFieldValue('vehicleGroup', group);
                            setFieldValue(
                              'licenseCategory',
                              getRightCategoryByVehicleGroup(group)[0]
                            );
                          }
                        }}
                        onBlur={() =>
                          handleBlur({
                            target: { name }
                          })
                        }
                        value={value || values.vehicleGroup}
                        data-cy="vehicleTypeSelect"
                        name={name}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Планируемая дата покупки ТС"
                    name="purchaseDate"
                    fast
                    required
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment.utc(value) : value}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Категория прав ТС"
                    name="licenseCategory"
                    required={
                      (!!values.vehicleType || !!values.vehicleGroup) &&
                      !isTractor
                    }
                  >
                    {({ value, name }) => (
                      <Select
                        name={name}
                        value={value}
                        disabled={
                          (!values.vehicleType || !values.vehicleGroup) &&
                          (isTractor ||
                            !!values.vehicleModelId ||
                            !rightCategories.length)
                        }
                        onChange={value => setFieldValue(name, value)}
                      >
                        {rightCategories.map((category: any) => (
                          <Select.Option value={category} key={category}>
                            {drivingLicenseCategories[category] ||
                              tractorDrivingLicenseCategories[category]}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Наличие прицепа"
                    name="withTrailer"
                    defaultValue={false}
                    fast
                  >
                    {({ value, name }) => (
                      <YesNoSelect
                        value={value}
                        name={name}
                        onChange={(value: boolean) =>
                          setFieldValue(name, value)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Год выпуска"
                    name="yearIssued"
                    required
                  >
                    {({ value, name }) => (
                      <YearIssueSelect
                        id="yearIssued"
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={() => handleBlur({ target: { name } })}
                        data-cy="yearIssued"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Модель ТС"
                    name="vehicleModelId"
                    required={
                      !values.vehicleType &&
                      !values.vehicleGroup &&
                      !values.vehicleModelId
                    }
                  >
                    {({ value, name }) => (
                      <VehicleModelSelect
                        placeholder="Выберите модель"
                        disabled={!!values.vehicleType || !!values.vehicleGroup}
                        value={value}
                        name={name}
                        onFocus={handleFocus}
                        data-cy="modelSelect"
                        onChange={async (value: number, option: any) => {
                          setFieldValue(name, value);
                          setFieldValue(
                            'vehicleModel',
                            option ? option.props.vehicleModel : undefined
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.licenseCategory === drivingLicenseCategoryEnum.b && (
                  <GridItem>
                    <FormField
                      label="Мощность двигателя, л.с."
                      name="enginePower"
                      hasFeedback={false}
                      required
                    >
                      {({ value, name }) => (
                        <InputNumber
                          name={name}
                          value={value}
                          decimalSeparator=","
                          max={999}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {values.licenseCategory === drivingLicenseCategoryEnum.c && (
                  <GridItem>
                    <FormField
                      label="Максимальная разрешенная масса, кг"
                      name="maxWeight"
                      hasFeedback={false}
                      required
                    >
                      {({ value, name }) => (
                        <InputNumber
                          name={name}
                          value={value}
                          decimalSeparator=","
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {values.licenseCategory === drivingLicenseCategoryEnum.d && (
                  <GridItem>
                    <FormField
                      label="Кол-во пассажирских мест, шт"
                      name="seatsCount"
                      hasFeedback={false}
                      required
                    >
                      {({ value, name }) => (
                        <InputNumber
                          name={name}
                          value={value}
                          decimalSeparator=","
                          max={999}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </StyledGrid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  osagoCalculationId: number,
  addOsagoCalculation: (osagoCalculation: OsagoCalculation) => void,
  updateOsagoCalculation: (osagoCalculation: OsagoCalculation) => void,
  osagoCalculation?: OsagoCalculation,
  location: Location
};

class OsagoCalculationForm extends Component<Props> {
  onCancel = () => navigate('/budget/osago-calculations/new');

  onSubmit = async (osagoCalculation: OsagoCalculation) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (osagoCalculation.id) {
        await this.props.updateOsagoCalculation(osagoCalculation);
      } else {
        await this.props.addOsagoCalculation({
          ...osagoCalculation,
          id: shortid.generate()
        });
      }
      const { year } = qs.parse(this.props.location.search);
      navigate(`/budget/osago-calculations/new?year=${year}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { osagoCalculationId, osagoCalculation = {} } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget">Бюджет</Crumb>
              <Crumb to={'/budget/osago-calculations'}>
                Список расчетов стоимости ОСАГО
              </Crumb>
              {osagoCalculationId ? (
                <Crumb>Редактирование</Crumb>
              ) : (
                <Crumb>Новый расчет стоимости ОСАГО</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoCalculation
              ? 'Расчет стоимости ОСАГО'
              : 'Новый расчет стоимости ОСАГО'}
          </h1>
        </StyledPanel>
        <InnerForm
          osagoCalculation={osagoCalculation}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    osagoCalculationId: props.osagoCalculationId,
    osagoCalculation: props.osagoCalculationId
      ? state.osagoCalculation.freeVehicleItems.find(
          (item: OsagoCalculation) => item.id === props.osagoCalculationId
        )
      : {}
  }),
  {
    addOsagoCalculation,
    updateOsagoCalculation
  }
)(OsagoCalculationForm);
