// @flow
import React, { useState, useCallback } from 'react';
import { navigate } from '@reach/router';

import { washingFrequencyApi } from '../../lib/api';
import type { UserAccess, WashingFrequency } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';

import { withUserAccess } from './../withUserAccess';

import { addAccessRight } from './accessRight';
import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';
import { monthsTypes } from '../../lib/enum';

const { Field } = Card;

type InnerProps = {
  data: WashingFrequency
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/washing-frequency/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Тип ТС для мойки">{props.data.vehicleType?.type}</Field>
        </GridItem>
        <GridItem>
          <Field label="Количество наружных моек в месяц">
            {props.data.externalWashingCount}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Количество проведения моек салона в год">
            {props.data.internalWashingCount}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Месяцы проведения моек салона">
            {props.data.internalWashingMonths
              ?.map(el => monthsTypes[el])
              .join()}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Количество проведения моек двигателя в год">
            {props.data.engineWashingCount}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Месяцы проведения моек двигателя">
            {props.data.engineWashingMonths?.map(el => monthsTypes[el]).join()}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: WashingFrequency, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await washingFrequencyApi.delete(data.id);
          await navigate(`${entryPointPath}`);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await washingFrequencyApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Периодичность проведения моек',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
