// @flow
import React from 'react';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';

import { outsourcingActApi, fuelsActApi, oilsActApi } from './../../../lib/api';
import { goBack } from './../../../lib/helpers';
import type { OutsourcingAct, FuelsAct, OilsAct } from './../../../lib/types';

import { Header } from './../../../components/layout';
import { Crumb } from './../../../components/layout/Breadcrumbs';
import Breadcrumbs from './../../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../../components/Notifications';

import OutSourceInnerForm from './components/OutSourceInnerForm';
import FuelsAndOilsInnerForm from './components/FuelsAndOilsInnerForm';

type Props = {
  type: string,
  id: number,
};

type State = {
  outsourcingAct: OutsourcingAct,
};

export class ActForm extends React.Component<Props, State> {
  state = {
    outsourcingAct: {},
  };

  componentDidMount() {
    if (this.props.id && this.isOutsourceAct()) {
      this.getOutsourcingAct();
    }
  }

  getOutsourcingAct = async () => {
    const { id } = this.props;
    try {
      notificationLoading({
        message: 'Загрузка акта...',
        key: 'loading',
      });
      const outsourcingAct = await outsourcingActApi.getOutsourcingAct(id);
      this.setState({ outsourcingAct });
    } catch (error) {
      notification.error({
        message: error.message,
      });
    } finally {
      notification.close('loading');
    }
  };

  addUpdateOutsourcingAct = async (outsourcingAct: OutsourcingAct) => {
    if (outsourcingAct.id) {
      return await outsourcingActApi.updateOutsourcingAct(outsourcingAct);
    }
    return await outsourcingActApi.addOutsourcingAct(outsourcingAct);
  };

  addUpdateOilsAct = async (oilsAct: OilsAct) => {
    if (oilsAct.id) {
      return await oilsActApi.update(oilsAct);
    }
    return await oilsActApi.add(oilsAct);
  };

  addUpdateFuelsAndOilsAct = async (fuelsAct: FuelsAct) => {
    if (fuelsAct.id) {
      return await fuelsActApi.update(fuelsAct);
      // return await fuelsAndOilsActApi.updateFuelsAndOilsAct(fuelsAndOilsAct);
    }
    return await fuelsActApi.add(fuelsAct);
    // return await fuelsAndOilsActApi.addFuelsAndOilsAct(fuelsAndOilsAct);
  };

  isOutsourceAct = () => this.props.type === 'outsourcing-act';

  getRouteByType = () => {
    let route = 'oils-act';
    switch (this.props.type) {
      case 'outsourcing-act':
        route = 'outsourcing-act';
        break;
      case 'fuels-and-oils-act':
        route = 'fuels-and-oils-act';
        break;
      default:
    }
    return route;
  };

  handleSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      switch (this.props.type) {
        case 'outsourcing-act':
          await this.addUpdateOutsourcingAct(values);
          break;
        case 'fuels-and-oils-act':
          await this.addUpdateFuelsAndOilsAct(values);
          break;
        default:
          await this.addUpdateOilsAct(values);
      }
      navigate(`/budget/act/internal/${this.getRouteByType()}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () =>
    goBack(
      `/budget/act/internal/${
        this.isOutsourceAct() ? 'outsourcing-act' : 'fuels-and-oils-act'
      }`
    );

  render() {
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb
                to={`/budget/act/internal/${
                  this.isOutsourceAct()
                    ? 'outsourcing-act'
                    : 'fuels-and-oils-act'
                }`}
              >
                Реестр первичных документов
              </Crumb>
              <Crumb>Новый акт</Crumb>
            </Breadcrumbs>
          }
        />
        {this.isOutsourceAct() && (
          <OutSourceInnerForm
            outsourcingAct={this.state.outsourcingAct}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        )}
        {!this.isOutsourceAct() && (
          <FuelsAndOilsInnerForm
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        )}
      </>
    );
  }
}

export default ActForm;
