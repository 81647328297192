import {
    BusinessCalendarForBudget,
    BusinessCalendarForBudgetForm,
} from '../../containers/BusinessCalendar/ForBudget';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BusinessCalendarForBudget,
        path: '/admin/the-business-calendar-for-budget',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BusinessCalendarForBudgetForm,
        path: '/admin/the-business-calendar-for-budget/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BusinessCalendarForBudgetForm,
        path: '/admin/the-business-calendar-for-budget/:dayId',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];