// @flow
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type {
  MaintenancePartAndConsumables,
  OrderContractorType
} from './../../../../lib/types';
import { withEmptyRow, withoutEmptyRow } from './../../../../lib/helpers';
import { orderContractorTypeEnum } from './../../../../lib/enum';

import { saveParts } from './../../../../ducks/detailedDefectiveStatement';

import { Icon, ListTable } from './../../../../components/ui';
import Grid, { GridItem } from './../../../../components/layout/Grid';

import { partColumns, itog } from './../elements';
import { sumPartsAndConsumbles } from './../lib';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const OperationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  saveParts: Function,
  parts: MaintenancePartAndConsumables[],
  // признак берем значения из StockPart
  orderContractorType: OrderContractorType,
  vehicleModelId: number
};

type State = {
  touched: boolean,
  columns: any[],
  parts: MaintenancePartAndConsumables[]
};

/**
 * Запчасти и расходные материалы
 */
class PartsAndConsumables extends React.Component<Props, State> {
  static defaultProps = {
    detailedDefectiveStatement: null
  };

  state = {
    touched: false,
    columns: [],
    parts: []
  };

  componentDidMount() {
    const columns = [
      ...partColumns(this.onChange, false, this.props.vehicleModelId),
      {
        width: '20px',
        renderRecord: (record: MaintenancePartAndConsumables, index: number) =>
          !isEmpty(record) ? (
            <OperationButtons>
              <StyledIcon onClick={() => this.onDelete(index)} type="x" />
            </OperationButtons>
          ) : null
      }
    ];
    this.setState({ columns, parts: this.props.parts });
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.parts, prevProps.parts)) {
      this.setState({ parts: this.props.parts });
    }
  }

  onChange = (
    index: number,
    key: $Keys<MaintenancePartAndConsumables>,
    value: any
  ) => {
    let parts: MaintenancePartAndConsumables[] = withEmptyRow([
      ...this.state.parts
    ]);
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    switch (key) {
      case 'stockPart':
        let obj: MaintenancePartAndConsumables = {
          ...parts[index],
          [(key: string)]: value,
          stockPartId: value ? value.id : 0
        };

        parts.splice(index, 1, obj);
        break;
      default:
        parts.splice(index, 1, {
          ...parts[index],
          [(key: string)]: value
        });
    }
    this.setState({ parts });
  };

  onDelete(index: number) {
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    this.setState({
      parts: this.state.parts.filter((value, id) => id !== index)
    });
  }

  handleSubmit = async () => {
    const { saveParts, orderContractorType } = this.props;
    const { parts } = this.state;
    try {
      withoutEmptyRow(parts).forEach(part => {
        if (!part.count) {
          throw new Error(`Заполните поле количество`);
        }
        switch (orderContractorType) {
          case orderContractorTypeEnum.internal:
            if (!part.stockPartId) throw new Error('Выберите материал');
            break;
          default:
            break;
        }
      });
      await saveParts(withoutEmptyRow(parts));
      this.setState({ touched: false });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { columns, touched, parts } = this.state;
    const data = withEmptyRow(parts);
    const { sum, sumContractor } = sumPartsAndConsumbles(withoutEmptyRow(data));
    return (
      <>
        <ListTable columns={columns} data={data} />
        {itog(sum, sumContractor)}
        {touched && (
          <Grid style={{ marginTop: '10px' }}>
            <GridItem fullWidth>
              <Button type="primary" onClick={this.handleSubmit}>
                Сохранить
              </Button>
            </GridItem>
          </Grid>
        )}
      </>
    );
  }
}

export default connect(() => ({}), { saveParts })(PartsAndConsumables);
