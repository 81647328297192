// @flow
import type { BioTreatmentFacilityVolume } from '../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams,
} from './index';
import type { FetchListParams, ListResponse } from './index';
import {
  convertDataToServer as convertDataToServerTalon,
  convertDataFromServer as convertDataFromServerTalon,
} from './bioTreatmentFacilityTalon';

export const convertDataToServer = (
  bioTreatmentFacilityVolume: BioTreatmentFacilityVolume
) => ({
  ...bioTreatmentFacilityVolume,
  talons:
    //$FlowFixMe
    bioTreatmentFacilityVolume?.talons?.map(convertDataFromServerTalon) ?? [],
});
export const convertDataFromServer = (
  bioTreatmentFacilityVolume: BioTreatmentFacilityVolume
) => ({
  ...bioTreatmentFacilityVolume,
  talons:
    //$FlowFixMe
    bioTreatmentFacilityVolume?.talons?.map(convertDataFromServerTalon) ?? [],
});

const baseUrl = 'bioTreatmentFacilityVolume';
const bioTreatmentFacilityVolume = new CommonApi<BioTreatmentFacilityVolume>(
  baseUrl,
  'учет БОС',
  convertDataToServer,
  convertDataFromServer
);

export default { ...bioTreatmentFacilityVolume };
