// @flow
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { Option, Select } from './../../../../components/ant/Select';
import { formatDateTimeToString } from './../../../../lib/helpers';

import type { DetailedDefectiveStatement } from './../../../../lib/types';

type Props = {
  maintenanceId: number,
  onChange: Function,
  detailedDefectiveStatement: ?(DetailedDefectiveStatement[])
};

/**
 * Работы
 */
export default class OperationsReadOnly extends React.Component<Props> {
  render() {
    const { onChange, detailedDefectiveStatement } = this.props;
    return isEmpty(detailedDefectiveStatement) ? null : (
      <Select
        onChange={onChange}
        style={{
          width: '310px',
          position: 'relative',
          top: '10px',
          marginRight: '10px'
        }}
        allowClear
        placeholder="Отклоненные дефектные ведомости"
      >
        {Array.isArray(detailedDefectiveStatement) &&
          detailedDefectiveStatement.map(detailedDefectiveStatement => (
            <Option
              data={detailedDefectiveStatement}
              key={detailedDefectiveStatement.id}
              value={detailedDefectiveStatement.id}
            >
              {`Дефектная ведомость от ${formatDateTimeToString(
                detailedDefectiveStatement.statusChangeDate
              )}`}
            </Option>
          ))}
      </Select>
    );
  }
}
