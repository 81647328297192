// @flow
import type { VehicleTaxMap } from './../types';
import CommonApi from './../commonApi';

const vehicleTaxMap = new CommonApi<VehicleTaxMap>(
  'vehicleTaxMap',
  'Соответствие объекта налогообложения и категории ТС'
);

export default {
  ...vehicleTaxMap
};
