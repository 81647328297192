import React from 'react';
import { TabItem, Tabs } from './../../../../components/ui';

export default () => (
  <Tabs withRouter>
    <TabItem label="Ремонт и ТО" url="/budget/execution" />
    <TabItem label="Мойка" url="/budget/execution/washing" />
    <TabItem label="Пропуска" url="/budget/execution/pass" />
    <TabItem
      label="Экспертиза/ТО ГПМ"
      url="/budget/execution/expertise-service-gpm"
    />
    <TabItem label="ТО ГИБДД" url="/budget/execution/inspections-gibdd" />
    <TabItem label="ТО ГТН" url="/budget/execution/inspections-gtn" />
    <TabItem
      label="Сертификация СИ"
      url="/budget/execution/measuring-device-certifications"
    />
    <TabItem label="Замена ГРЗ" url="/budget/execution/license-plate-change" />
    <TabItem label="Платон" url="/budget/execution/platon" />
    <TabItem label="Мед.осмотры" url="/budget/execution/driver-inspections" />
    <TabItem label="Мониторинг" url="/budget/execution/vehicle-monitoring" />
  </Tabs>
);
