// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

const Operations = styled.div`
  text-align: right;
`;

type Props = {
  children: Node
};
/**
 * Компонент для отображения иконок операций в таблицах
 */
export default ({ children }: Props) => (
  <Operations onClick={e => e.stopPropagation()}>{children}</Operations>
);
