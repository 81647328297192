// @flow
import React from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import isEqual from 'lodash/isEqual';

import { Header, Section } from '../../../../components/layout';
import Selects from '../../../../components/selects';
import { getFilterByVehicleModelType } from './lib';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../../ducks/vehicle';
import type { Vehicle, VehicleFuelAndOil } from '../../../../lib/types';
import { Operations, ListTable, Icon } from '../../../../components/ui';
import { vehicleFuelAndOilApi } from './../../../../lib/api';
import { notificationLoading } from './../../../../components/Notifications';
import { withEmptyRow } from '../../../../lib/helpers';

const StyledSection = styled(Section)`
  padding: 16px 0;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { FuelAndOilSelect } = Selects;

type Props = {
  vehicleId: number,
  vehicle: Vehicle,
  cleanVehicle: Function,
  fetchVehicle: Function
};

type State = {
  vehicleFuelsAndOils: VehicleFuelAndOil[],
  vehicleFuelsAndOilsToDelete: VehicleFuelAndOil[],
  isSubmitting: boolean
};

class VehicleFuelsEndOilsForm extends React.Component<Props, State> {
  state = {
    vehicleFuelsAndOils: [],
    vehicleFuelsAndOilsToDelete: [],
    isSubmitting: false
  };

  emptyVehicleFuelAndOil = {
    vehicleId: this.props.vehicleId
  };

  async componentDidMount() {
    const { vehicleId } = this.props;
    try {
      await this.props.cleanVehicle();
      await this.props.fetchVehicle(vehicleId);
      const vehicleFuelsAndOils = await vehicleFuelAndOilApi.fetchVehicleFuelsAndOils(
        {
          pageSize: 0,
          vehicleId
        }
      );
      this.setState({
        vehicleFuelsAndOils: vehicleFuelsAndOils.data
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onChange(index: number, value: $Shape<VehicleFuelAndOil>) {
    let vehicleFuelsAndOils = withEmptyRow(
      [...this.state.vehicleFuelsAndOils],
      { emptyRow: this.emptyVehicleFuelAndOil }
    );
    vehicleFuelsAndOils.splice(index, 1, value);
    this.setState({
      vehicleFuelsAndOils
    });
  }

  onDelete(index: number) {
    this.setState(prevState => {
      const deletedElement = prevState.vehicleFuelsAndOils.find(
        (value, id) => id === index
      );
      let vehicleFuelsAndOilsToDelete = [
        ...prevState.vehicleFuelsAndOilsToDelete
      ];
      if (deletedElement && deletedElement.id) {
        vehicleFuelsAndOilsToDelete = [
          ...vehicleFuelsAndOilsToDelete,
          deletedElement
        ];
      }
      return {
        vehicleFuelsAndOils: prevState.vehicleFuelsAndOils.filter(
          (value, id) => id !== index
        ),
        vehicleFuelsAndOilsToDelete
      };
    });
  }

  onSubmit = async () => {
    try {
      this.setState({ isSubmitting: true });
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const { vehicleId } = this.props;
      await Promise.all(
        this.state.vehicleFuelsAndOils.map(
          (vehicleFuelAndOil: VehicleFuelAndOil) =>
            vehicleFuelAndOil.id
              ? vehicleFuelAndOilApi.updateVehicleFuelAndOil(vehicleFuelAndOil)
              : vehicleFuelAndOilApi.addVehicleFuelAndOil(vehicleFuelAndOil)
        )
      );
      await Promise.all(
        this.state.vehicleFuelsAndOilsToDelete.map(
          (vehicleFuelAndOil: VehicleFuelAndOil) =>
            vehicleFuelAndOilApi.deleteVehicleFuelAndOil(vehicleFuelAndOil.id)
        )
      );
      notification.success({
        message: 'Успешно сохранено',
        description: 'Данные успешно сохранены'
      });
      navigate(`/vehicles/${vehicleId}/fuel-and-oils/oils-technical-liquids`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.setState({ isSubmitting: false });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(
      `/vehicles/${this.props.vehicleId}/fuel-and-oils/oils-technical-liquids`
    );
  };

  render() {
    const { vehicle = {} } = this.props;
    const { vehicleFuelsAndOils, isSubmitting } = this.state;
    if (!vehicle) return null;
    return (
      <>
        <Header left={vehicle && <h1>ГСМ {vehicle.licensePlate}</h1>} />
        <StyledSection>
          <ListTable
            columns={[
              {
                title: 'ГСМ',
                key: 'fuelsAndOilsId',
                style: {
                  overflow: 'hidden'
                },
                render: (
                  fuelsAndOilsId: number,
                  record: VehicleFuelAndOil,
                  index: number
                ) => (
                  <FuelAndOilSelect
                    onChange={(value, option?: any) => {
                      if (option) {
                        const {
                          props: { fuelandoil }
                        } = option;
                        this.onChange(index, {
                          ...record,
                          view: fuelandoil.view,
                          fuelsAndOilsId: value
                        });
                      } else this.onChange(index, {});
                    }}
                    disabledValues={vehicleFuelsAndOils.map(
                      (vehicleFuelAndOil: VehicleFuelAndOil) =>
                        vehicleFuelAndOil.fuelsAndOilsId
                    )}
                    value={fuelsAndOilsId}
                    filter={getFilterByVehicleModelType(
                      vehicle.vehicleModel && vehicle.vehicleModel.type
                    )}
                  />
                )
              },
              {
                width: '20px',
                renderRecord: (record: VehicleFuelAndOil, index: number) =>
                  isEqual(record, this.emptyVehicleFuelAndOil) ? null : (
                    <Operations>
                      <StyledIcon
                        onClick={() => this.onDelete(index)}
                        type="x"
                      />
                    </Operations>
                  )
              }
            ]}
            data={withEmptyRow(vehicleFuelsAndOils, {
              emptyRow: this.emptyVehicleFuelAndOil
            })}
          />
        </StyledSection>
        <Footer>
          <Button
            type="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={this.onSubmit}
          >
            Сохранить
          </Button>
          <Button onClick={this.handleCancel}>Отменить</Button>
        </Footer>
      </>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(ownProps.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(VehicleFuelsEndOilsForm);
