// @flow

import { LabeledValue } from 'antd/lib/select';
import React, { Component } from 'react';
import MaskedInput from 'antd-mask-input';
import moment from 'moment';
import range from 'lodash/range';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';

const initialTimeRange = [];

range(24).forEach((hour) => {
  range(60).forEach((minutes) => {
    const time = `${hour < 10 ? `0${hour}` : hour}:${
      minutes < 10 ? `0${minutes}` : minutes
    }`;
    initialTimeRange.push({
      label: time,
      value: time,
    });
  });
});

type Props = {
  value?: moment,
  onChangeDisabled?: boolean,
  disabledTime?: (dateTime: string) => boolean,
  onChange: Function,
  waypointName?: string,
};

type State = {
  value: ?moment,
  timeRange: LabeledValue[],
};

const convertData = (datetime: ?moment) =>
  datetime ? moment.utc(datetime, 'HH:mm').format('HH:mm') : '';

export default class DateTimePicker extends Component<Props, State> {
  state = {
    value: convertData(this.props.value),
    timeRange: initialTimeRange,
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({
        value: convertData(this.props.value),
      });
    }
  }

  onSelect = (value: any) => {
    this.setState(
      (prevState) => ({
        value,
        timeRange: orderBy(prevState.timeRange, (time: LabeledValue) =>
          this.toValidTime(time.value).valueOf()
        ),
      }),
      this.applyChange
    );
  };

  toValidTime = (timeValue: ?string): moment => {
    let time = moment.utc(timeValue, 'HH:mm', true);
    return time.isValid() ? time : 'Invalid date';
  };

  handleSearch = (value: string) => {
    if (
      moment.utc(value, 'HH:mm', true).isValid() ||
      moment.utc(value, 'H:mm', true).isValid()
    )
      this.setState({
        timeRange: !value
          ? initialTimeRange
          : [{ label: value, value }, ...initialTimeRange],
      });
  };

  applyChange = () => {
    const { value: timeValue } = this.state;
    const { value: dateValue } = this.props;
    let time = this.toValidTime(timeValue);
    if (time.isValid()) {
      const fullDateTime = moment
        .utc(dateValue)
        .startOf('day')
        .add(time.hour(), 'hour')
        .add(time.minute(), 'minute');
      this.props.onChange(fullDateTime);
    } else {
      this.setState({
        value: convertData(dateValue),
      });
    }
  };

  render() {
    const { value } = this.state;
    const { waypointName } = this.props;
    return (
      <MaskedInput
        placeholder="Время"
        mask="11:11"
        disabled={!waypointName}
        style={{ width: 100 }}
        value={value}
        onChange={(e) => {
          this.setState({ value: e.target.value });
        }}
        onBlur={() => {
          const res = value
            ? /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.exec(value)
            : null;
          if (res !== null) {
            this.setState({ value: res.input }, this.applyChange);
          } else {
            this.setState({ value: convertData(this.props.value) }); // convertData(this.props.value) });
          }
        }}
      />
      // <AutoComplete
      //   disabled={isNil(value)}
      //   placeholder="Время"
      //   value={value}
      //   options={timeRange}
      //   style={{ width: 100 }}
      //   onSearch={this.handleSearch}
      //   onChange={(value: string) => {
      //     if (onChangeDisabled !== true) {
      //       this.setState({
      //         value,
      //       });
      //     }
      //   }}
      //   onSelect={this.onSelect}
      // >
      //   {timeRange.map((value, key) => (
      //     <AutoComplete.Option
      //       disabled={(() => {
      //         if (disabledTime) {
      //           let time = moment.utc(value, 'HH:mm', true);
      //           if (!time.isValid()) time = moment.utc(value, 'H:mm', true);
      //           return disabledTime(time);
      //         }
      //         return false;
      //       })()}
      //       value={value}
      //       key={key}
      //     >
      //       {convertData(value)}
      //     </AutoComplete.Option>
      //   ))}
      // </AutoComplete>
    );
  }
}
