// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects } from '../../components';
import { RangePicker } from '../../components/ant/DatePicker';
import Field from '../../components/card/Field';
import type { FilterChildrenParams } from './../../components/hoc/Filter';

import { ownerTypes } from './../../lib/enum';
import { formatDateTimeToISOString } from './../../lib/helpers';
import type { FuelGroup, NodeFilterType } from './../../lib/types';

const { FuelGroupSelect, VehicleSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type FuelCardFilterParams = {|
  fuelGroup?: FuelGroup,
  vehicleId?: number,
  nodeId?: number,
  nodeFilterType?: NodeFilterType,
  startDate?: string | moment,
  endDate?: string | moment
|};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: FuelCardFilterParams,
  style?: any
};

const FuelCardFilter = ({ style, filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent style={{ ...style }}>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<FuelCardFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <FuelGroupSelect
                  size="small"
                  value={values['fuelGroup']}
                  onChange={type => changeValue('fuelGroup', type)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values['vehicleId']}
                  filter={{
                    ownerType: ownerTypes.self
                  }}
                  onChange={id => changeValue('vehicleId', id)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default FuelCardFilter;
