import {
    FuelCardsCard,
    FuelCardsForm,
    FuelCardsList,
} from '../../containers/FuelCards';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: FuelCardsForm,
        path: '/admin/fuel-cards/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingFuelCard],
    },
    {
        component: FuelCardsCard,
        path: '/admin/fuel-cards/:fuelCardId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingFuelCard,
            accessTypeEnum.viewingAllFuelCards,
            accessTypeEnum.viewingBranchFuelCards,
        ],
    },
    {
        component: FuelCardsForm,
        path: '/admin/fuel-cards/:fuelCardId/edit',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingFuelCard],
    },
    {
        component: FuelCardsList,
        path: '/admin/fuel-cards',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingFuelCard,
            accessTypeEnum.viewingAllFuelCards,
            accessTypeEnum.viewingBranchFuelCards,
        ],
    },
];