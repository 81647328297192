// @flow
import React, { type ComponentType } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import styled from 'styled-components';

import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { Company, UserAccess } from '../../../lib/types';
import { accessTypeEnum } from '../../../lib/enum';
import Form from '../../../components/Form';
import type { FormFieldProps } from '../../../components/Form';
import { withUserAccess } from './../../withUserAccess';
import { FormikProps } from 'formik';

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  company: Company,
  onSubmit: Function,
  onCancel: Function,
  userAccess: UserAccess[],
};

const InnerForm = ({ onSubmit, company, onCancel, userAccess }: FormProps) => {
  const canEdit = userAccess.some((access) =>
    [accessTypeEnum.admin, accessTypeEnum.handlingCompanies].includes(access)
  );
  return (
    <Form onSubmit={onSubmit} initialValues={company || {}}>
      {(FormField: ComponentType<FormFieldProps>, formikProps: FormikProps) => {
        const { handleSubmit, handleChange, handleBlur, dirty, isSubmitting } =
          formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Наименование" name="name" required>
                      {({ value, name }) => (
                        <Input
                          disabled={!canEdit}
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Адрес" name="address" required>
                      {({ value, name }) => (
                        <Input
                          disabled={!canEdit}
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Телефон" name="phone" required>
                      {({ value, name }) => (
                        <Input
                          disabled={!canEdit}
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="ИНН" name="inn">
                      {({ value, name }) => (
                        <Input
                          disabled={!canEdit}
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="КПП" name="kpp">
                      {({ value, name }) => (
                        <Input
                          disabled={!canEdit}
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="ОГРН" name="ogrn">
                      {({ value, name }) => (
                        <Input
                          disabled={!canEdit}
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
            {canEdit && (
              <Footer>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="primary"
                  data-cy="save"
                  htmlType="submit"
                >
                  Сохранить
                </Button>
                <Button dirty={dirty} onClick={onCancel}>
                  Отменить
                </Button>
              </Footer>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default withUserAccess(InnerForm);
