// @flow
import type { OilsAct } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub } from '../helpers';

export const convertDataFromServer = (oilsAct: OilsAct) => ({
  ...oilsAct,
  sum: convertFromKopToRub(oilsAct.sum)
});

const oilsAct = new CommonApi<OilsAct>(
  'oilsAct',
  'акт списания масел',
  undefined,
  convertDataFromServer
);

export default {
  ...oilsAct
};
