//@flow
import Input from 'antd/lib/input';
import React, { useCallback } from 'react';

import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../../components/hoc/common/handbook/CommonFilter';

export type CostAnalisysParamsFilter = {
  name?: string
};

export const CostAnalisysFilter = (
  props: FilterProps<CostAnalisysParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<CostAnalisysParamsFilter>>) =>
      CommonFilter<CostAnalisysParamsFilter>({ ...filterProps, ...props }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => {
        return (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  size="small"
                  placeholder="Наименование анализа"
                  onChange={e => changeValue('name', e.target.value)}
                  value={values.name}
                />
              </Field>
            </FilterItem>
          </>
        );
      }}
    </Filter>
  );
};
export default CostAnalisysFilter;
