// @flow
import React, { useEffect } from 'react';
import qs from 'query-string';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import { budgetSummaryApi } from '../../../lib/api';

import { getBreadCrumbsByUrl } from '../../../lib/autoBreadcrumbs';
import { budgetSummaryEnum, budgetSummaryStatus } from '../../../lib/enum';

import { formatDateTimeToString, navigate, setQueryParams } from '../../../lib/helpers';
import type {
  BudgetSummaryStatusType,
  BudgetVersion
} from '../../../lib/types';
import { withUserAccess } from '../../withUserAccess';
import {
  addDetailedBudgetAccessRight,
  approveDetailedBudgetAccessRight
} from './accessRight';

import { Filter } from './components/';

const entryPointPath = '/budget/details/';

const columns = [
  {
    title: 'Версия бюджета',
    dataIndex: 'budgetVersion',
    render: (budgetVersion?: BudgetVersion) => budgetVersion?.name ?? '-'
  },
  {
    title: 'Дата формирования',
    dataIndex: 'date',
    render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY')
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: (status: BudgetSummaryStatusType) => budgetSummaryStatus[status]
  }
];

export default withUserAccess(({ userAccess, employeeBranchOrgUnitId }) => {
  const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

  const canAdd = () =>
    userAccess.some(access => addDetailedBudgetAccessRight.includes(access));

  const canApprove = () =>
    userAccess.some(access =>
      approveDetailedBudgetAccessRight.includes(access)
    );

  useEffect(() => {
    const { year } = qs.parse(window.location.search);
    if (!year) {
      setQueryParams({
        year: new Date().getFullYear()
      });
    }
  }, []);

  return (
    <Page>
      <CommonListPage
        crud={budgetSummaryApi}
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: 'Уточнения в бюджет',
          access: {
            add: canAdd() && (() => navigate(`${entryPointPath}new`))
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) =>
            navigate(
              canApprove()
                ? `${entryPointPath}${id}/-1`
                : `${entryPointPath}${id}/${employeeBranchOrgUnitId}`
            )
        }}
        filter={Filter}
        filterMixin={{ type: budgetSummaryEnum.clarification }}
      />
    </Page>
  );
});
