import { EmployeeForm, EmployeeList } from '../../containers/Employees';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: EmployeeList,
        path: '/admin/employees',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEmployees,
        ],
    },
    {
        component: EmployeeForm,
        path: '/admin/employees/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEmployees,
        ],
    },
    {
        component: EmployeeForm,
        path: '/admin/employees/:employeeId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingEmployees,
        ],
    },
];