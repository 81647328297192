// @flow
import React from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';

import { Header, Section, SectionTitle } from '../../../components/layout';
import Selects from '../../../components/selects';
import { notificationLoading } from './../../../components/Notifications';

import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import { updateVehicleFuelMultiplier } from '../../../ducks/fuelMultiplier';
import type { Vehicle, FuelMultiplierFilter } from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { canShowSecondaryFuel } from '../../VehicleModels/lib';
import { canShowFuelCards } from './../lib';
import { Form } from '../../../components';

import FuelCards from './FuelCard';
import CancelButton from '../../../components/CancelButton';
const FuelMultipliersSelect = Selects.FuelMultipliersSelect;

const StyledSection = styled(Section)`
  padding: 16px 0;
`;
const SectionContent = styled.div`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  updateVehicleFuelMultiplier: Function,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class Fuel extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicleFuelMultiplier(
        this.props.vehicle,
        values.fuelMultipliers
      );
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/fuel-and-oils/fuel`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/fuel-and-oils/fuel`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {} } = this.props;
    if (!vehicle) return null;
    return (
      <>
        <Header left={vehicle && <h1>Топливо {vehicle.licensePlate}</h1>} />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              values,
              dirty
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <SectionTitle>Основное топливо</SectionTitle>
                  <SectionContent>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Уровень топлива, л"
                          name="primaryFuelLevel"
                        >
                          {({ value, name }) => (
                            <Input
                              name={name}
                              disabled
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                  {canShowSecondaryFuel(
                    values.vehicleModel && values.vehicleModel.type
                  ) && (
                    <>
                      <SectionTitle>Дополнительное топливо</SectionTitle>
                      <SectionContent>
                        <Grid gutter="16px">
                          <GridItem>
                            <FormField
                              label="Уровень топлива, л"
                              name="secondaryFuelLevel"
                            >
                              {({ value, name }) => (
                                <Input
                                  name={name}
                                  disabled
                                  value={value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </SectionContent>
                    </>
                  )}
                  <SectionContent>
                    <Grid>
                      <GridItem fullWidth>
                        <FormField
                          label="Топливные коэффициенты"
                          name="fuelMultipliers"
                        >
                          {props => {
                            const { value, name, ...otherProps } = props;
                            return (
                              <FuelMultipliersSelect
                                {...otherProps}
                                filter={
                                  ({
                                    shouldUsedAlways: true
                                  }: FuelMultiplierFilter)
                                }
                                onChange={(
                                  value: string,
                                  selectedOptions: Object[]
                                ) => {
                                  setFieldValue(
                                    'fuelMultipliers',
                                    selectedOptions.map(
                                      option => option.props.multiplier
                                    )
                                  );
                                }}
                                onBlur={() =>
                                  handleBlur({
                                    target: { name }
                                  })
                                }
                                value={(value || []).map(
                                  multiplier => multiplier.id
                                )}
                                mode="multiple"
                                data-cy="userAccessTypesSelect"
                              />
                            );
                          }}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                  {(canShowFuelCards(
                    vehicle.vehicleModel && vehicle.vehicleModel.type
                  ) && <FuelCards vehicle={vehicle} editable={true} />) ||
                    (vehicle.fuelCard && (
                      <FuelCards vehicle={vehicle} editable={true} />
                    ))}
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle,
    updateVehicleFuelMultiplier
  }
)(Fuel);
