// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type { FetchListParams } from '../../lib/api';
import { brandApi } from '../../lib/api';
import type { Brand, ListState, UserAccess } from './../../lib/types';
import { getListInitialState, setQueryParams } from '../../lib/helpers';

import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import { notificationLoading } from './../../components/Notifications';

import { withUserAccess } from '../withUserAccess';
import { accessTypeEnum } from '../../lib/enum';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<Brand> & {
  columns: Array<Object>,
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class BrandList extends PureComponent<Props, State> {
  state = {
    ...getListInitialState(),
    columns: [
      {
        title: '№',
        dataIndex: 'id',
        sorter: true,
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        sorter: true,
      },
    ],
  };

  static defaultProps = {
    location: {},
    userAccess: [],
  };

  fetchBrands = async (page: number = 1, params: FetchListParams<any> = {}) => {
    this.setState({ loading: true });
    const { data, totalCount } = await brandApi.fetchBrands({
      page,
      ...params,
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  async deleteBrand(id: number) {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await brandApi.deleteBrand(id);
      notification.success({
        message: 'Успешное удаление',
        description: `Марка с идентификатором ${id} была успешно удалена`,
      });
      await this.fetchBrands(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  handleRowClick = (id: number) => navigate(`/admin/brands/${id}`);

  async componentDidMount() {
    const { columns } = this.state;
    const { page } = qs.parse(window.location.search);
    await this.fetchBrands(page);
    if (this.canAdd()) {
      this.setState({
        columns: [
          ...columns,
          {
            title: '',
            width: 80,
            dataIndex: 'operations',
            // eslint-disable-next-line no-unused-vars
            render: (text: string, brand: Brand) => (
              <Operations>
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => this.deleteBrand(brand.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              </Operations>
            ),
          },
        ],
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBrandsAndVehicleModels,
      ].includes(access)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, columns } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Марки</h1>}
          right={
            canAdd && (
              <Link to="/admin/brands/new">
                <Button type="primary" data-cy="addBrand">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () => canAdd && this.handleRowClick(record.id),
            })}
            columns={columns}
            data={data.map((brand) => ({
              ...brand,
              key: brand.id,
            }))}
            fetch={this.fetchBrands}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(BrandList);
