// @flow

import type { MaintenanceRegulation } from '../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKmToM, convertFromMToKm, convertFromSecondToHour, convertFromHourToSecond } from '../helpers';

const convertToServer = (maintenanceRegulation: MaintenanceRegulation) => {
  return {
    ...maintenanceRegulation,
    kilometrage: convertFromKmToM(maintenanceRegulation.kilometrage),
    workEngineHours: convertFromHourToSecond(maintenanceRegulation?.workEngineHours ?? 0),
  };
};

const convertFromServer = (maintenanceRegulation: MaintenanceRegulation) => {
  return {
    ...maintenanceRegulation,
    kilometrage: convertFromMToKm(maintenanceRegulation.kilometrage),
    workEngineHours: convertFromSecondToHour(maintenanceRegulation?.workEngineHours ?? 0),
  };
};

export const fetchMaintenanceRegulation = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<MaintenanceRegulation>> => {
  const maintenanceRegulation = await fetchRequest.get(
    '/maintenanceRegulation',
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (maintenanceRegulation)
    return {
      ...maintenanceRegulation,
      data: maintenanceRegulation.data.map(convertFromServer)
    };
  throw new Error('Не удалось получить список регламентов ТО');
};

export const getMaintenanceRegulation = async (
  id: number
): Promise<MaintenanceRegulation> => {
  const maintenanceRegulation = await fetchRequest.get(
    `/maintenanceRegulation/${id}`
  );
  if (maintenanceRegulation) return convertFromServer(maintenanceRegulation);
  throw new Error('Не удалось получить информацию по регламенту ТО');
};

export const addMaintenanceRegulation = async (
  maintenanceRegulation: MaintenanceRegulation
): Promise<MaintenanceRegulation> => {
  const added = await fetchRequest.post(
    `/maintenanceRegulation`,
    convertToServer(maintenanceRegulation)
  );
  if (added) return convertFromServer(added);
  throw new Error('Не удалось добавить регламент ТО');
};

export const updateMaintenanceRegulation = async (
  maintenanceRegulation: MaintenanceRegulation
): Promise<MaintenanceRegulation> => {
  const updated = await fetchRequest.put(
    `/maintenanceRegulation`,
    convertToServer(maintenanceRegulation)
  );
  if (updated) return convertFromServer(updated);
  throw new Error('Не удалось отредактировать регламент ТО');
};

export const deleteMaintenanceRegulation = async (
  id: number
): Promise<MaintenanceRegulation> => {
  const deleted = await fetchRequest.delete(`/maintenanceRegulation/${id}`);
  if (deleted) return convertFromServer(deleted);
  throw new Error('Не удалось удалить регламент ТО');
};

export default {
  fetchMaintenanceRegulation,
  getMaintenanceRegulation,
  addMaintenanceRegulation,
  updateMaintenanceRegulation,
  deleteMaintenanceRegulation
};
