import {
    MaintenanceTypeWorkForm,
    MaintenanceTypeWorkList,
} from '../../containers/MaintenanceWorkType';
import { accessTypeEnum } from '../../lib/enum';
// виды работ по обслуживанию тс
export default [
    {
        component: MaintenanceTypeWorkList,
        path: '/admin/maintenance-work-type',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingMaintenanceWorkTypes,
            accessTypeEnum.handlingMaintenanceWorkTypes,
        ],
    },
    {
        component: MaintenanceTypeWorkForm,
        path: '/admin/maintenance-work-type/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingMaintenanceWorkTypes,
        ],
    },
    {
        component: MaintenanceTypeWorkForm,
        path: '/admin/maintenance-work-type/:maintenanceWorkTypeId/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingMaintenanceWorkTypes,
        ],
    },
];