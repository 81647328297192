// @flow
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

import type {
  LoadUnloadCargo,
  LoadUnloadCargoType,
  Order,
  VehicleForSelection
} from './../types';

import {
  cargoApi,
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams,
  loadUnloadCargoApi,
  routeApi
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { OrderFilterParams } from '../../containers/Orders/Filter';
import type { Cargo } from '../types';
import { loadUnloadTypeEnum, orderObjectivesEnum } from '../enum';

const mapDataFromServer = (order: Order) => {
  const { objective, cargos, loadUnloadCargos } = order;
  const hasCargos = !!(
    objective === orderObjectivesEnum.shipping ||
    cargos.length ||
    loadUnloadCargos.length
  );
  return {
    ...order,
    hasCargos,
    loadCargos: loadUnloadCargos.filter(
      ({ type }) => type === loadUnloadTypeEnum.load
    ),
    unloadCargos: loadUnloadCargos.filter(
      ({ type }) => type === loadUnloadTypeEnum.unload
    )
  };
};

export const saveCargo = async (cargo: Cargo): Promise<Cargo> => {
  if (cargo.id) return await cargoApi.updateCargo(cargo);
  return await cargoApi.createCargo(cargo);
};

export const saveLoadUnloadCargo = async (loadUnloadCargo: LoadUnloadCargo) => {
  if (loadUnloadCargo.id)
    return await loadUnloadCargoApi.updateLoadUnloadCargo(loadUnloadCargo);
  return await loadUnloadCargoApi.createLoadUnloadCargo(loadUnloadCargo);
};

const waypointToLoadUnloadCargo = (
  waypoint: any,
  payload: {
    type: LoadUnloadCargoType,
    orderId: number
  }
): LoadUnloadCargo => ({
  ...payload,
  address: waypoint.address || waypoint.name,
  startDateTime: waypoint.arrivedDateTime,
  endDateTime: waypoint.departureDateTime,
  contactEmployees: waypoint.notation
});

export const getLoadUnloadCargosByOrder = (order: Order) => {
  const {
    route: { waypoints },
    id: orderId
  } = order;

  const [firstWaypoint, ...otherWaypoints] = waypoints;

  const loadCargo: LoadUnloadCargo = waypointToLoadUnloadCargo(firstWaypoint, {
    type: loadUnloadTypeEnum.load,
    orderId
  });

  const unloadCargos: LoadUnloadCargo[] = otherWaypoints.map(waypoint =>
    waypointToLoadUnloadCargo(waypoint, {
      type: loadUnloadTypeEnum.unload,
      orderId
    })
  );

  return {
    load: [loadCargo],
    unload: [...unloadCargos]
  };
};

export const addOrder = async (order: Order): Promise<Order> => {
  const { cargos } = order;
  const added = await fetchRequest.post('/order', order);
  const { id: orderId } = added;

  await Promise.all(
    reject(cargos, isEmpty).map(
      async cargo =>
        await saveCargo({
          ...cargo,
          orderId
        })
    )
  );

  if (added) return added;
  throw new Error('Не удалось создать заявку');
};

export const updateOrder = async (order: Order): Promise<Order> => {
  const { cargos } = order;
  const updated = await fetchRequest.put('/order', order);

  const { id: orderId } = updated;

  await Promise.all(
    reject(cargos, isEmpty).map(
      async cargo =>
        await saveCargo({
          ...cargo,
          orderId
        })
    )
  );

  if (updated) return updated;
  throw new Error('Не удалось обновить заявку');
};

export const fetchOrders = async (
  params: FetchListParams<OrderFilterParams> = initialFetchListParams
): Promise<ListResponse<Order>> => {
  let nodeId = undefined;
  if (params.orgUnitId) {
    nodeId = params.orgUnitId;
    delete params.orgUnitId;
  }
  return await fetchRequest.get('/order', {
    ...initialFetchListParams,
    ...params,
    nodeId
  });
};

export const print = async (filters: OrderFilterParams, fileName?: string) => {
  const print = await downloadRequestWithToken(
    '/order/print',
    filters,
    fileName
  );
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export const deleteOrder = async (id: number): Promise<Order> => {
  const deleted = await fetchRequest.delete(`/order/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить заявку');
};

export const changeStatus = async (parameters: Object): Promise<Order> => {
  const order = await fetchRequest.post('/order/changeStatus', parameters);
  if (order) {
    return order;
  }
  throw new Error('Не удалось обновить статус заявки');
};

export const fetchOrder = async (
  id: number,
  params: any = {}
): Promise<Order> => {
  const order = await fetchRequest.get(`/order/${id}`, params);
  const route = await routeApi.fetchRoute(order.routeId);
  if (order) {
    return mapDataFromServer({
      ...order,
      route
    });
  }
  throw new Error('Не удалось загрузить заявку');
};

export const fetchOrderFreeVehicles = async (
  orderId: number
): Promise<VehicleForSelection[]> => {
  const vehicles = await fetchRequest.get(`/order/${orderId}/freeVehicles`);
  if (vehicles) {
    return vehicles;
  }
  throw new Error('Не удалось загрузить список доступных ТС');
};

export const changeDispatcherNode = async (changeDispatcherNode: {
  id: number,
  dispatcherNodeId: number
}): Promise<Order> => {
  const order = await fetchRequest.post(
    `/order/changeDispatcherNode`,
    changeDispatcherNode
  );
  if (order) return order;
  throw new Error('Не удалось перенаправить заявку');
};

export default {
  changeDispatcherNode,
  addOrder,
  updateOrder,
  print,
  fetchOrders,
  deleteOrder,
  changeStatus,
  fetchOrder,
  fetchOrderFreeVehicles
};
