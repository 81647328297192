//@flow
import moment from 'moment';
import React, { useCallback } from 'react';
import { FilterItem } from '../../../../components';
import { MonthPicker } from '../../../../components/ant/DatePicker';
import Field from '../../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../../components/hoc/common/handbook/CommonFilter';

import { formatDateTimeToISOString } from '../../../../lib/helpers';

export type OilsAndFluidsConsumptionPlanParamsFilter = {
  startDate?: string,
  endDate?: string
};

export const OilsAndFluidsConsumptionPlanFilter = (
  props: FilterProps<OilsAndFluidsConsumptionPlanParamsFilter>,
) => {
  const Filter = useCallback(
    (
      filterProps: $Shape<FilterProps<OilsAndFluidsConsumptionPlanParamsFilter>>
    ) =>
      CommonFilter<OilsAndFluidsConsumptionPlanParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <MonthPicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'01.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <MonthPicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={value => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(
                      value,
                      value?.endOf('month')?.format('DD.MM.YYYY')
                    )
                  );
                }}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default OilsAndFluidsConsumptionPlanFilter;
