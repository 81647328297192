// @flow
import React, { Component } from 'react';
import notification from 'antd/lib/notification';
import qs from 'query-string';

import {
  getListInitialState,
  plus,
  toLocalStringRu,
} from '../../../../../lib/helpers';
import { COLUMNS, MONTH } from '../../lib';
import { calculationStatusEnum, fuelTypes } from '../../../../../lib/enum';
import type {
  FuelAndOilCalculation,
  ListState,
  VehiclePlan,
} from '../../../../../lib/types';
import {
  fuelsAndOilsCalculationApi,
  vehiclePlanApi,
} from '../../../../../lib/api';
import { AntTable } from '../../../../../components/ui';
import { Section } from '../../../../../components/layout';

import type { ParamsFilter } from '../components/Filter';
import Filter from '../components/Filter';
import { headerPanel } from '../../components/Common';

import { ModalFuelAndOilCalculation } from './FuelsAndOilsModal';
import Button from 'antd/lib/button';
import { notificationLoading } from '../../../../../components/Notifications';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
};

type State = ListState<FuelAndOilCalculation> & {
  columns: any,
  vehiclePlan: ?VehiclePlan,
  visible: boolean,
  rowIndex?: number,
  monthIndex: number,
  data: ?(FuelAndOilCalculation[]),
  dataRow?: FuelAndOilCalculation,
  filter: ParamsFilter,
};

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    columns: [
      ...COLUMNS,
      {
        title: 'Наименование топлива',
        dataIndex: `months[0].fuel.type`,
        width: '160px',
        render: (type) => {
          return fuelTypes[type] || '-';
        },
      },
      ...MONTH.map((month, index) => ({
        title: month.title,
        children: [
          {
            title: 'Топливо',
            children: [
              {
                title: 'Кол., л',
                dataIndex: `months[${index}].fuel.count`,
                width: '120px',
                render: (count: number) => {
                  return toLocalStringRu(count) || '-';
                },
                onCell: (fuelAndOilCalculation, rowIndex) => {
                  return this.onCell(fuelAndOilCalculation, rowIndex, index);
                },
              },
              {
                title: 'Стоимость',
                dataIndex: `months[${index}].fuel.cost`,
                width: '120px',
                render: (cost: number) => {
                  return cost ? toLocalStringRu(cost) : '-';
                },
                onCell: (fuelAndOilCalculation, rowIndex) => {
                  return this.onCell(fuelAndOilCalculation, rowIndex, index);
                },
              },
            ],
          },
          {
            title: 'Масло',
            children: [
              {
                title: 'Стоимость',
                dataIndex: `months[${index}].oilsSum`,
                width: '120px',
                onCell: (fuelAndOilCalculation, rowIndex) => {
                  return this.onCell(fuelAndOilCalculation, rowIndex, index);
                },
                render: (oilsSum) => {
                  const cost = toLocalStringRu(oilsSum);
                  return cost ? cost : '-';
                },
              },
            ],
          },
        ],
      })),
      {
        title: 'Итого, год',
        children: [
          {
            title: 'Топливо',
            children: [
              {
                title: 'Кол., л',
                dataIndex: `fuelCountSum`,
                width: '120px',
                render: (count: number) => {
                  return toLocalStringRu(count) || '-';
                },
              },
              {
                title: 'Стоимость',
                dataIndex: `fuelCostSum`,
                width: '120px',
                render: (cost: number) => {
                  return cost ? toLocalStringRu(cost) : '-';
                },
              },
            ],
          },
          {
            title: 'Масло',
            children: [
              {
                title: 'Стоимость',
                dataIndex: `sum`,
                width: '120px',
                render: (oilsSum) => {
                  const cost = toLocalStringRu(oilsSum);
                  return cost ? cost : '-';
                },
              },
            ],
          },
        ],
      },
    ],
    vehiclePlan: null,
    filter: {},
    visible: false,
    rowIndex: undefined,
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.getData());
  }

  onCell = (
    fuelAndOilCalculation: FuelAndOilCalculation,
    rowIndex: number,
    monthIndex: number
  ) => {
    return {
      onClick: () => {
        this.setState({
          rowIndex,
          visible: true,
          dataRow: { ...fuelAndOilCalculation },
          monthIndex: monthIndex,
        });
      },
    };
  };

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
    this.setState({ vehiclePlan });
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;

    const itogCalculation = (data: any) => {
      const itog = {
        months: Array(12)
          .fill()
          .map(() => ({ fuel: { count: 0, cost: 0 }, oilsSum: 0 })),
        sum: 0,
        fuelCountSum: 0,
        fuelCostSum: 0,
      };
      data.forEach((item: any) => {
        itog.sum = plus(item.sum || 0, itog.sum);
        itog.fuelCountSum = plus(item.fuelCountSum || 0, itog.fuelCountSum);
        itog.fuelCostSum = plus(item.fuelCostSum || 0, itog.fuelCostSum);
        item.months.forEach((month: any, index) => {
          itog.months[index].fuel.cost = plus(
            itog.months[index].fuel.cost,
            month.fuel.cost || 0
          );
          itog.months[index].fuel.count = plus(
            itog.months[index].fuel.count,
            month.fuel.count || 0
          );
          itog.months[index].oilsSum = plus(
            itog.months[index].oilsSum,
            month.oilsSum || 0
          );
        });
      });
      return itog;
    };

    try {
      let { data } = await fuelsAndOilsCalculationApi.fetch({
        ...this.state.filter,
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }

      if (data.length) {
        data = [...data, itogCalculation(data)];
      }

      this.setState({ data });
    } catch (e) {
      notification.error({ message: 'Не удалось запросить данные' });
    }
  };

  handleSave = async (fuelAndOilCalculation: FuelAndOilCalculation) => {
    const { data, rowIndex = 0 } = this.state;
    this.setState({ loading: true });
    try {
      if (fuelAndOilCalculation && Number.isInteger(rowIndex)) {
        data[rowIndex] = await fuelsAndOilsCalculationApi.updateCalculations(
          fuelAndOilCalculation
        );
        this.setState({ data });
      }
    } catch (error) {
      notification.warning({ message: 'Не удалось обновить данные' + error });
    } finally {
      this.setState({
        loading: false,
        visible: false,
        rowIndex: undefined,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      rowIndex: undefined,
    });
  };

  applyFilter = (filter: any) => {
    this.setState({ filter }, async () => {
      await this.fetch();
    });
  };

  cleanFilter = async () => {
    this.setState({ filter: {} }, async () => {
      await this.fetch();
    });
  };

  isDraft = () =>
    this.state?.vehiclePlan?.fuelAndOilCalculationStatus ===
    calculationStatusEnum.draft;

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await fuelsAndOilsCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await fuelsAndOilsCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные' });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { vehiclePlanId } = this.props;
    const { columns, visible, loading, data, dataRow, monthIndex } = this.state;
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          title: 'ГСМ',
          passTab: true,
        })}
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={this.state.filter}
          />
          <AntTable
            tableLayout="fixed"
            loading={loading}
            data={[...data]}
            columns={columns}
            bordered
            scroll={{
              x: 5000,
              y: 'calc(100vh - 400px)',
            }}
          />
          <ModalFuelAndOilCalculation
            visible={visible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            data={dataRow}
            itogData={!dataRow?.id ? data.filter((row) => !!row.id) : undefined}
            monthIndex={monthIndex}
          />
        </Section>
        {this.isDraft() && (
          <Section style={{ padding: '16px' }}>
            <Button type="primary" onClick={this.calculate}>
              Рассчитать
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              type="primary"
              onClick={this.changeStatus}
            >
              Утвердить
            </Button>
          </Section>
        )}
      </>
    );
  }
}
