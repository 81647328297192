// @flow
import React from 'react';
import { Input } from 'antd';

import CommonForm, {
  type CommonFormProps,
} from '../../../components/hoc/common/handbook/CommonForm';
import type { SpareParts } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';

import OkeiSelect from '../../../components/selects/OkeiSelect';
import { SparePartMaterialSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import { InputNumber } from '../../../components/inputs';
import Selects from './../../../components/selects';
import VehicleModelList from './VehicleModelList';

const { YesNoSelect } = Selects;

export default (props: CommonFormProps<$Shape<SpareParts>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Название" required name="name">
                  {(field) => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Каталожный номер" name="catalogNumber">
                  {(field) => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Стоимость, руб. без НДС"
                  required
                  name="price"
                >
                  {({ name, value }) => (
                    <InputNumber
                      value={value}
                      onChange={(value) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Единица измерения" name="unitId" required>
                  {({ name, value }) => (
                    <OkeiSelect
                      placeholder="Выберите ед. измерения"
                      value={value}
                      onChange={(value) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Примечание" name="note">
                  {(field) => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тип материала" name="type">
                  {({ name, value }) => (
                    <SparePartMaterialSelect
                      value={value}
                      onChange={(value) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem fullWidth>
                <FormField label="Для всех ТС" name="allVehicles">
                  {({ name, value }) => (
                    <YesNoSelect
                      value={value}
                      allowClear={false}
                      onChange={(value) => {
                        setFieldValue(name, value);
                        setFieldValue('vehicleModels', null);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              {!values.allVehicles && (
                <GridItem fullWidth>
                  <FormField
                    label="Применяемость"
                    name="vehicleModels"
                    required
                  >
                    {({ name, value }) => (
                      <VehicleModelList
                        value={value}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
