//@flow
import React, { useCallback } from 'react';
import { Input } from 'antd';
import styled from 'styled-components';

import { FilterItem } from '../../../../../components';
import Field from '../../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../../components/hoc/common/handbook/CommonFilter';
import type { PddTestType } from '../../../../../lib/types';
import Selects from '../../../../../components/selects';

const { PddTestTypeSelect, PddGroupSelect, YesNoSelect } = Selects;

const YesNoSelectStyled = styled(YesNoSelect)`
  min-width: 200px;
`;

export type PddTestRegisterParamsFilter = {
  name?: string,
  type?: PddTestType,
  groupId?: number,
  withTickets?: boolean, // нужен что бы несловить баг yesNoSelect
  withoutTickets?: boolean
};

export const PddTestRegisterFilter = (
  props: FilterProps<PddTestRegisterParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PddTestRegisterParamsFilter>>) =>
      CommonFilter<PddTestRegisterParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );

  return (
    <Filter additionalFilter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                value={values.name}
                onChange={e => changeValue('name', e.target.value)}
                placeholder="Наименование теста"
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <PddTestTypeSelect
                size="small"
                value={values.type}
                onChange={value => changeValue('type', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <PddGroupSelect
                size="small"
                value={values.groupId}
                onChange={value => changeValue('groupId', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <YesNoSelectStyled
                size="small"
                value={
                  typeof values.withoutTickets === 'undefined'
                    ? undefined
                    : // необхожимо так как между перерендерами возвращается стрговое или булевое поле
                      String(values.withoutTickets) !== 'true'
                }
                onChange={value => {
                  changeValue(
                    'withoutTickets',
                    typeof value === 'undefined' ? undefined : !value
                  );
                }}
                placeholder="Тест по билетам"
                reset
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PddTestRegisterFilter;
