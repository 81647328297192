// @flow
import React from 'react';
import moment from 'moment';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportDriver,
  ReportDriversParams,
} from '../../../lib/types/reportDrivers';
import { reportDriversApi } from '../../../lib/api';
import type { AppState } from '../../../ducks/redux';

import Header from '../../../components/layout/Header';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import { notificationLoading } from '../../../components/Notifications';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';

import Filter from './components/Filter';

const initialFilter: ReportDriversParams = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  filter: ReportDriversParams,
  loading: boolean,
  data: ReportDriver[],
  scrollY: number,
};

type Props = {
  employeeBranchOrgUnitId: number,
};

/**
 * Отчет о водителях
 */
export class DriversList extends React.Component<Props, State> {
  state = {
    filter: initialFilter,
    loading: false,
    data: [],
    scrollY: window.innerHeight - 450,
  };

  async componentDidMount() {
    const { ...filter } = qs.parse(window.location.search);
    this.setState(
      { filter: isEmpty(filter) ? initialFilter : filter },
      this.fetchReportDrivers
    );
  }

  applyFilter = (filter: ReportDriversParams) =>
    this.setState({ filter }, this.fetchReportDrivers);

  cleanFilter = () =>
    this.setState({ filter: initialFilter }, this.fetchReportDrivers);

  fetchReportDrivers = async () => {
    const { filter } = this.state;
    try {
      this.setState({ loading: true });
      const data = await reportDriversApi.fetchReportDrivers(filter);
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла отчета...',
        key: 'print',
      });
      await reportDriversApi.printReportDrivers(this.state.filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      fixed: true,
      width: 200,
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
      width: 150,
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      width: 150,
      render: (value: string) =>
        value && (
          <ul>
            {value.split(',').map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        ),
    },
    {
      title: 'Марка',
      dataIndex: 'brandName',
      width: 150,
      render: (value: string) =>
        value && (
          <ul>
            {value.split(',').map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        ),
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      width: 150,
      render: (value: string) =>
        value && (
          <ul>
            {value.split(',').map((licensePlate, index) => (
              <li key={index}>
                {applyMaskToValue(licensePlate, formatLicensePlateMask)}
              </li>
            ))}
          </ul>
        ),
    },
    {
      title: 'Кол-во выписанных ПЛ (план)',
      dataIndex: 'plannedTripsCount',
      width: 100,
      render: (value: number) => `${value} шт.`,
    },
    {
      title: 'Кол-во выписанных ПЛ (факт)',
      dataIndex: 'actualTripsCount',
      width: 100,
      render: (value: number) => `${value} шт.`,
    },
    {
      title: 'Количество отработанных часов',
      dataIndex: 'workedTime',
      width: 100,
      render: (workTime: number) => `${workTime} ч.`,
    },
  ];

  render() {
    const { filter, data, loading, scrollY } = this.state;
    const { employeeBranchOrgUnitId } = this.props;
    return (
      <PageFlexColumnWrapper>
        <Header
          left={<h1>Отчет о водителях</h1>}
          right={
            <Button type="primary" onClick={this.handleExport}>
              Экспорт
            </Button>
          }
        />
        <TableFlexColumnWrapper>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            employeeBranchOrgUnitId={employeeBranchOrgUnitId}
          />
          <Table
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
            rowKey="driverId"
            name="ReportsDrivers"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1100,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
}))(DriversList);
