// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker, RangePicker } from '../../../components/ant/DatePicker';

import { Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects } from './../../../components';
import Field from './../../../components/card/Field';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';

import { formatDateTimeToISOString } from './../../../lib/helpers';

const {
  MaintenanceStatusesTypeSelect,
  MaintenanceWorkTypeSelect,
  VehicleSelect,
  VehicleModelSelect,
  RepairePlanSelect,
  ExpenseDirectionTreeSelect,
  OrderContractorTypeSelect,
  YesNoSelect,
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type MaintenanceFilterParams = {
  status?: string,
  maintenanceWorkTypeId?: number,
  vehicleId?: number,
  created?: string,
  'vehicle.vehicleModel.id'?: number,
  expenseDirectionId?: number,
  repairPlan?: string,
  orderContractorType?: string,
  startDate?: string,
  endDate?: string,
  isOverdue?: boolean
};

type Props = {
  filter: MaintenanceFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  style?: any
};

export const MaintenanceFilter = ({
  style,
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent style={{ ...style }}>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<MaintenanceFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <MaintenanceStatusesTypeSelect
                  placeholder="Выберите статус"
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <MaintenanceWorkTypeSelect
                  placeholder="Выберите тип ремонта"
                  size="small"
                  onChange={value =>
                    changeValue('maintenanceWorkTypeId', value)
                  }
                  value={values.maintenanceWorkTypeId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  placeholder="Выберите ТС"
                  size="small"
                  onChange={value => changeValue('vehicleId', value)}
                  value={values.vehicleId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleModelSelect
                  placeholder="Выберите модель ТС"
                  size="small"
                  onChange={value =>
                    changeValue('vehicle.vehicleModel.id', value)
                  }
                  value={values['vehicle.vehicleModel.id']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RepairePlanSelect
                  placeholder="График работ"
                  size="small"
                  onChange={value => changeValue('repairPlan', value)}
                  value={values['repairPlan']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrderContractorTypeSelect
                  placeholder="Способ выполнения"
                  size="small"
                  onChange={value => changeValue('orderContractorType', value)}
                  value={values['orderContractorType']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <ExpenseDirectionTreeSelect
                  placeholder="Направление расходов"
                  size="small"
                  onChange={value => changeValue('expenseDirectionId', value)}
                  value={values['expenseDirectionId']}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  value={values.created && moment.utc(values.created)}
                  placeholder="Дата создания карточки"
                  onChange={(value: Object, dateString: string) =>
                    changeValue(
                      'created',
                      formatDateTimeToISOString(value, dateString)
                    )
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Наличие просроченных"
                  size="small"
                  value={values.isOverdue}
                  optionText="Наличие просроченных"
                  reset
                  fullWidth
                  onChange={(value: boolean) => changeValue('isOverdue', value)}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MaintenanceFilter;
