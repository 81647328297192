import React, { Component } from 'react';
import qs from 'query-string';

import type { ListState } from './../../lib/types';
import { getListInitialState } from './../../lib/helpers';

import { Table } from './../../components/ui';

import { getValueObject } from './../../lib/helpers';

export type ListTableProps = {
  location: Location & { state: { page: number } },
  unSelected: any,
  onSelectAll: Function,
  onSelect: Function,
  applyFilter: Function,
  cleanFilter: Function,
  cleanFilter: Function,
  actId: number,
  readOnly: boolean,
  actSum: number
};

type Props = ListTableProps;
type State<T> = ListState<T> & {
  filter: any,
  totalSum: number
};

export class ListTable<T> extends Component<Props, State<T>> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filter: {},
    totalSum: 0
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(this.props.location.search);
    this.props.applyFilter(filter);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetch(page)
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.readOnly !== this.props.readOnly) {
      this.fetch();
    }
  }

  fetch(page: number = 1) {
    const { actId, readOnly } = this.props;
    const { filter } = this.state;
    this.setState({ loading: true });
    let params = { page };
    if (readOnly) {
      params = { ...params, actId };
    } else {
      params = { ...params, ...filter, actId: 'null' };
    }
    return params;
  }

  applyFilter = (filter: any) => {
    this.props.applyFilter(filter);
    this.setState({ filter }, () => this.fetch(this.state.page));
  };

  cleanFilter = () => {
    this.props.cleanFilter();
    this.setState({ filter: {} }, () => this.fetch(this.state.page));
  };

  onSelectAll = (selected: boolean, selectedItem: any[], fieldId = 'id') => {
    this.props.onSelectAll(this.state.data, selected, selectedItem, fieldId);
  };

  getData(field: string = 'id') {
    const { unSelected } = this.props;
    const { data } = this.state;
    return data
      .map((item: any, index: number) => {
        if (unSelected[item[field]]) {
          return false;
        }
        return index;
      })
      .filter(item => item !== false);
  }

  getSumUnSelected(field = 'paymentAmount') {
    const { unSelected } = this.props;
    let sumPayment = 0;
    Object.keys(unSelected).forEach(key => {
      const payment = getValueObject(unSelected[key], field);
      if (payment) {
        sumPayment += payment;
      }
    });
    return sumPayment;
  }

  renderFilter(Filter: any) {
    const { readOnly } = this.props;
    const { filter } = this.state;
    return (
      !readOnly && (
        <Filter
          filter={filter}
          applyFilter={this.applyFilter}
          cleanFilter={this.cleanFilter}
          style={{ padding: '0px 0px 16px 0px' }}
        />
      )
    );
  }

  renderFooterTable = () => {
    const { totalSum } = this.state;
    const { actSum } = this.props;
    const sumUnSelected = this.getSumUnSelected();
    const sum = totalSum - sumUnSelected;
    return (
      <span style={sum > actSum ? { color: 'red' } : {}}>
        Общая сумма:{' '}
        {sum.toLocaleString('ru-RU', {
          style: 'currency',
          currency: 'RUB'
        })}
      </span>
    );
  };

  renderTable(columns) {
    const { location, onSelect, readOnly } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    const selectedRowKeys = this.getData();
    return (
      <Table
        rowSelection={
          !readOnly && {
            selectedRowKeys,
            // $FlowFixMe
            onSelect: (...[record, selected]) => onSelect(record, selected),
            onSelectAll: (...[selected, selectedRows]) =>
              this.onSelectAll(selected, selectedRows)
          }
        }
        fetch={this.fetch}
        pagination={{
          page,
          pageSize,
          totalCount,
          location
        }}
        footer={() => this.renderFooterTable()}
        loading={loading}
        columns={columns}
        data={data}
      />
    );
  }
}
