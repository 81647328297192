// @flow
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import moment, { Moment } from 'moment';
import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { DatePicker } from '../../../components/ant/DatePicker';
import Field from '../../../components/card/Field';

import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import { notificationLoading } from '../../../components/Notifications';

import {
  saveDetailedDefectiveStatement,
  setDetailedDefectiveStatement,
} from '../../../ducks/detailedDefectiveStatement';
import { setMaintenance } from '../../../ducks/maintenance';
import type {
  DetailedDefectiveStatement,
  Maintenance,
  UserAccess,
} from '../../../lib/types';
import { Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { Dropdown } from './../../../components/ui';
import { showWorkEngineHours } from './../../../lib/helpers';

import {
  detailedDefectiveStatementApi,
  maintenanceApi,
} from './../../../lib/api';

import {
  DeclineDetailedDefectiveStatementSelect,
  Operations,
  OperationsReadOnly,
  PartsAndConsumables,
  PartsAndConsumablesReadOnly,
} from './components';

const Content = styled.div`
  padding: 16px;
`;
const ContentTitle = styled.h2`
  margin: 16px 0;
  font-weight: 500;
`;
const StyledButtonDiv = styled.div`
  button {
    margin-left: 10px;
  }
`;

type Props = {
  userAccess: UserAccess[],
  maintenance: Maintenance,
  setMaintenance: Function,
  maintenanceOperationPrice: number,
  detailedDefectiveStatement: DetailedDefectiveStatement,
  changeStatus: Function,
  saveDetailedDefectiveStatement: Function,
};
type State = {
  declinedDetailedDefectiveStatement: ?(DetailedDefectiveStatement[]),
  // выбранная отклоненная дет. деф. ведомость
  declined: ?DetailedDefectiveStatement,
};

class DetailedDefectiveStatementCard extends Component<Props, State> {
  state = {
    declinedDetailedDefectiveStatement: [],
    declined: null,
  };

  componentDidMount() {
    this.getDeclined();
  }

  // получим отклоненные дет. деф. ведомости
  getDeclined = async () => {
    const { maintenance } = this.props;
    const declined =
      await detailedDefectiveStatementApi.fetchDetailedDefectiveStatement({
        maintenanceId: maintenance.id,
        returnDeleted: true,
        isDeleted: true,
      });
    this.setState({ declinedDetailedDefectiveStatement: declined.data });
  };

  onChangeDeclineDetailedDefectiveStatement = async (
    value: any,
    option: any
  ) => {
    let declined = null;
    if (value !== undefined) {
      declined = option.props.data;
    }
    this.setState({ declined });
  };

  handlePrint = async ({ key }: any) => {
    const { detailedDefectiveStatement } = this.props;
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      if (detailedDefectiveStatement) {
        switch (key) {
          case 'print':
            await detailedDefectiveStatementApi.printDetailedDefectiveStatement(
              detailedDefectiveStatement.id
            );
            break;
          case 'printOs3':
            await detailedDefectiveStatementApi.printOs3FormDetailedDefectiveStatement(
              detailedDefectiveStatement.id
            );
            break;
          case 'printWriteOffAct':
            await detailedDefectiveStatementApi.printWriteOffActDetailedDefectiveStatement(
              detailedDefectiveStatement.id
            );
            break;
          default:
            break;
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  // кнопки для дет. дефектной ведомости
  getSuffixDetailedDefectiveStatement = () => {
    const { maintenance, detailedDefectiveStatement } = this.props;
    const { declined } = this.state;
    const { declinedDetailedDefectiveStatement } = this.state;
    const addPrint = false;
    const addPrintOs3 = false;
    const addPrintWriteOffAct = false;
    return (
      <>
        {maintenance && (
          <StyledButtonDiv>
            <DeclineDetailedDefectiveStatementSelect
              detailedDefectiveStatement={declinedDetailedDefectiveStatement}
              onChange={this.onChangeDeclineDetailedDefectiveStatement}
              maintenanceId={maintenance.id}
            />
            {detailedDefectiveStatement &&
              !declined &&
              detailedDefectiveStatement.id !== 0 &&
              [addPrint, addPrintOs3, addPrintWriteOffAct].includes(true) && (
                <Dropdown
                  overlay={
                    <Menu onClick={this.handlePrint}>
                      {addPrint && (
                        <Menu.Item key={'print'}>Дефектная ведомость</Menu.Item>
                      )}
                      {addPrintOs3 && (
                        <Menu.Item key={'printOs3'}>Форма ОС-3</Menu.Item>
                      )}
                      {addPrintWriteOffAct && (
                        <Menu.Item key={'printWriteOffAct'}>
                          Акт о списании материально-производственных запасов
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button type="primary">Печатные формы</Button>
                </Dropdown>
              )}
          </StyledButtonDiv>
        )}
      </>
    );
  };

  getOperationComponent = () => {
    const { declined } = this.state;
    const {
      detailedDefectiveStatement,
      maintenance,
      maintenanceOperationPrice,
    } = this.props;
    if (declined) {
      return (
        <OperationsReadOnly
          detailedDefectiveStatement={declined || detailedDefectiveStatement}
          orderContractorType={maintenance.orderContractorType}
        />
      );
    }
    return (
      <Operations
        orderContractorType={maintenance.orderContractorType}
        maintenanceOperationPrice={maintenanceOperationPrice}
        maintenanceId={maintenance.id}
        vehicleModelId={maintenance.vehicle.vehicleModelId}
        operations={[...(detailedDefectiveStatement?.operations ?? [])]}
      />
    );
  };

  getPartsComponent = () => {
    const { declined } = this.state;
    const { detailedDefectiveStatement, maintenance } = this.props;
    if (declined)
      return (
        <PartsAndConsumablesReadOnly
          orderContractorType={maintenance.orderContractorType}
          detailedDefectiveStatement={declined || detailedDefectiveStatement}
        />
      );
    return (
      <PartsAndConsumables
        workType={maintenance.maintenanceWorkType.workType}
        orderContractorType={maintenance.orderContractorType}
        maintenanceId={maintenance.id}
        parts={[...(detailedDefectiveStatement?.parts ?? [])]}
        vehicleModelId={maintenance.vehicle.vehicleModelId}
      />
    );
  };

  savePeriod = async (
    startDate: string,
    endDate: string,
    kilometrage: ?number,
    workEngineHours: ?number
  ) => {
    const {
      maintenance,
      detailedDefectiveStatement,
      saveDetailedDefectiveStatement,
      setMaintenance,
    } = this.props;
    try {
      notificationLoading({
        message: 'Сохрание периода...',
        key: 'saving',
      });
      if (detailedDefectiveStatement) {
        await saveDetailedDefectiveStatement({
          ...detailedDefectiveStatement,
          startDate,
          endDate,
        });
        if (kilometrage || workEngineHours) {
          const updateMaintenance = await maintenanceApi.updateMaintenance({
            ...maintenance,
            kilometrage,
            workEngineHours,
          });
          setMaintenance(updateMaintenance);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при сохранении периода',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { maintenance, detailedDefectiveStatement } = this.props;

    return (
      detailedDefectiveStatement && (
        <Section>
          <SectionTitle
            divider
            suffix={this.getSuffixDetailedDefectiveStatement()}
          >
            Детальная дефектная ведомость
          </SectionTitle>
          <Content>
            {maintenance && (
              <>
                <PeriodDetailedDefectiveStatement
                  detailedDefectiveStatement={detailedDefectiveStatement}
                  maintenance={maintenance}
                  savePeriod={this.savePeriod}
                />
                <ContentTitle style={{ marginTop: 0 }}>Работы</ContentTitle>
                {this.getOperationComponent()}
                <ContentTitle>Расходники и запчасти</ContentTitle>
                {this.getPartsComponent()}
              </>
            )}
          </Content>
        </Section>
      )
    );
  }
}

function PeriodDetailedDefectiveStatement(props) {
  const { detailedDefectiveStatement, savePeriod, maintenance } = props;
  const { startDate, endDate } = detailedDefectiveStatement;
  const {
    kilometrage,
    workEngineHours: initWorkEngineHours,
    vehicle: { kilometrage: vehicleKilometrage },
  } = maintenance;
  const [dirty, setDirty] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: startDate ? moment(startDate) : startDate,
    endDate: endDate ? moment(endDate) : endDate,
  });
  const [kilometers, setKilometrage] = useState(kilometrage);
  const [workEngineHours, setWorkEngineHours] = useState(initWorkEngineHours);

  useEffect(() => {
    const {
      kilometrage,
      workEngineHours,
      vehicle: { kilometrage: vehicleKilometrage },
    } = maintenance;
    const { startDate, endDate } = detailedDefectiveStatement;
    if (kilometrage) {
      setKilometrage(Math.floor(kilometrage));
    } else {
      setKilometrage(Math.floor(vehicleKilometrage));
    }
    setWorkEngineHours(workEngineHours);
    setDateRange({
      startDate: startDate ? moment(startDate) : startDate,
      endDate: endDate ? moment(endDate) : endDate,
    });
  }, [maintenance, detailedDefectiveStatement]);

  return (
    <>
      <Grid style={{ marginBottom: '16px' }} cols={2}>
        <Field label="Период выполнения работ">
          <Grid cols={2} gutter="16px">
            <GridItem>
              <DatePicker
                placeholder="Дата начала"
                value={dateRange.startDate}
                format="DD MMMM YYYY"
                disabledDate={(date: string) =>
                  dateRange.endDate
                    ? moment
                        .utc(date)
                        .startOf('day')
                        .isAfter(moment.utc(dateRange.endDate).startOf('day'))
                    : false
                }
                onChange={(startDate: string) => {
                  setDateRange({ ...dateRange, startDate });
                  !dirty && setDirty(true);
                }}
              />
            </GridItem>
            <GridItem>
              <DatePicker
                placeholder="Дата окончания"
                value={dateRange.endDate}
                format="DD MMMM YYYY"
                disabled={!dateRange.startDate}
                disabledDate={(date: string) =>
                  dateRange.startDate
                    ? moment
                        .utc(date)
                        .startOf('day')
                        .isBefore(
                          moment.utc(dateRange.startDate).startOf('day')
                        )
                    : false
                }
                onChange={(endDate: string) => {
                  setDateRange({ ...dateRange, endDate });
                  !dirty && setDirty(true);
                }}
              />
            </GridItem>
          </Grid>
        </Field>
        <Grid cols={2} style={{ marginLeft: '16px' }}>
          {showWorkEngineHours(maintenance.vehicle) ? (
            <Field label="Показания моточасов при окончании работ">
              <GridItem>
                <StyledInputNumber
                  disabled={dateRange.endDate === null}
                  placeholder="Показания моточасов при окончании работ"
                  min={initWorkEngineHours}
                  value={workEngineHours}
                  onChange={(value: number) => {
                    setWorkEngineHours(value);
                    !dirty && setDirty(true);
                  }}
                />
              </GridItem>
            </Field>
          ) : (
            <Field label="Показания одометра при окончании работ">
              <GridItem>
                <StyledInputNumber
                  disabled={dateRange.endDate === null}
                  placeholder="Показания одометра при окончании работ"
                  value={kilometers}
                  onChange={(value: number) => {
                    setKilometrage(value);
                    !dirty && setDirty(true);
                  }}
                />
              </GridItem>
            </Field>
          )}
        </Grid>
        {dirty && !!dateRange.startDate && !!dateRange.endDate && (
          <GridItem fullWidth style={{ marginTop: '10px' }}>
            <Button
              type="primary"
              onClick={() => {
                savePeriod(
                  moment.utc(dateRange.startDate).startOf('day').toISOString(),
                  moment.utc(dateRange.endDate).startOf('day').toISOString(),
                  kilometers,
                  workEngineHours
                );
                setDirty(false);
              }}
            >
              Сохранить
            </Button>
          </GridItem>
        )}
      </Grid>
    </>
  );
}

export default connect(
  (state) => ({
    userAccess: state.auth.profile.access,
    detailedDefectiveStatement: state.detailedDefectiveStatement,
    maintenance: state.maintenance,
  }),
  {
    setDetailedDefectiveStatement,
    saveDetailedDefectiveStatement,
    setMaintenance,
  }
)(DetailedDefectiveStatementCard);
