// @flow
import { navigate } from '@reach/router';
import { DatePicker, Radio } from 'antd';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import qs from 'query-string';
import React, { useState } from 'react';

import { GridItem, Header, Section } from '../../components/layout';
import Grid from '../../components/layout/Grid';
import { notificationLoading } from '../../components/Notifications';
import Table from '../../components/ui/Table';
import { Popconfirm } from '../../components/ui';

import { contractApi } from '../../lib/api';
import {
  contractDocumentType,
  contractDocumentTypeEnum,
  entityStatus,
} from '../../lib/enum';
import {
  formatDateTimeToISOString,
  formatDateTimeToString,
  getListInitialState,
} from '../../lib/helpers';
import type {
  Contract,
  ContractDocumentType,
  Employee,
  EntityStatusType,
  ListState,
  Stage,
  UserAccess,
} from '../../lib/types';

import { withUserAccess } from '../withUserAccess';
import { addContractAccess } from './accessRight';

import type { ContractFilterParams } from './components/Filter';
import Filter from './components/Filter';

const { RangePicker } = DatePicker;

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<Contract> & {
  filter: ContractFilterParams,
};

export class ContractList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [
    {
      title: '№  документа',
      key: 'documentNumber',
      dataIndex: 'documentNumber',
      render: (documentNumber: string, record: Contract) =>
        documentNumber ? documentNumber : record.accountNumber,
    },
    {
      title: 'Тип документа',
      key: 'documentType',
      dataIndex: 'documentType',
      render: (type: ContractDocumentType) => contractDocumentType[type],
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      width: '150',
      render: (startDate: string) =>
        startDate ? `${formatDateTimeToString(startDate, 'DD.MM.YYYY')}` : '-',
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      width: '150',
      render: (endDate: string) =>
        endDate ? `${formatDateTimeToString(endDate, 'DD.MM.YYYY')}` : '-',
    },
    {
      title: 'Филиал',
      dataIndex: 'branchName',
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
    },
    {
      title: 'Куратор',
      dataIndex: 'employee',
      render: (employee: Employee) =>
        employee
          ? `${employee.lastname} ${employee.firstname.charAt(
              0
            )}. ${employee.middlename.charAt(0)}.`
          : '-',
    },
    {
      title: 'Контрагент',
      dataIndex: 'contractor.company.name',
      render: (contractorName: string) =>
        contractorName ? contractorName : '-',
    },
    {
      title: 'Сумма, без НДС',
      dataIndex: 'stages',
      render: (stages: Stage[]) => {
        let sum = 0;
        stages.forEach((stage: Stage) => (sum += stage.stageSum));
        return sum;
      },
    },
    {
      title: 'Статус',
      dataIndex: 'contractStatus',
      render: (status: EntityStatusType) => entityStatus[status],
    },
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetchContract(page)
    );
  }

  canAdd = () =>
    this.props.userAccess.some((access) => addContractAccess.includes(access));

  deleteContract = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await contractApi.deleteContract(id);
      await this.fetchContract(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchContract = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await contractApi.fetchContracts({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  onConfirmRequest = async (data: any) => {
    const [startDate, endDate] = data.dateRange;
    const documentType = data.docType;
    try {
      try {
        notificationLoading({
          message: 'Запрос данных',
          key: 'request',
        });
        const data = await contractApi.updateParusContracts({
          startDate,
          endDate,
          documentType,
        });
        if (data) {
          notification.success({ message: data.status });
        } else {
          notification.error('Ошибка запросы');
        }
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message,
        });
      }
    } finally {
      notification.close('request');
    }
  };

  applyFilter = (filter: ContractFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetchContract(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetchContract(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр договоров</h1>}
          right={
            this.canAdd() && (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate('/budget/contract/new')}
                >
                  Создать
                </Button>
              </>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchContract}
            fetchOnMount
            onRow={(row: Contract) => ({
              onClick: () => navigate(`/budget/contract/${row.id}`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

type RequestPopupProps = {
  onConfirm?: ({ dateRange: string[], docType: ContractDocumentType }) => any,
};

// eslint-disable-next-line
const RequestPopup = (props: RequestPopupProps) => {
  const [dateRange, setDateRange] = useState([
    moment.utc().startOf('day'),
    moment.utc().endOf('day'),
  ]);
  const [docType, setDocType] = useState(contractDocumentTypeEnum.contract);
  return (
    <Popconfirm
      title={
        <Grid>
          <GridItem fullWidth>
            <p>За период</p>
          </GridItem>
          <GridItem fullWidth>
            <RangePicker
              format="DD.MM.YYYY"
              value={dateRange}
              onChange={(date) => {
                setDateRange(date);
              }}
            />
          </GridItem>
          <GridItem fullWidth>
            <Radio.Group
              value={docType}
              onChange={(e) => setDocType(e.target.value)}
            >
              <Radio value={contractDocumentTypeEnum.contract}>
                По договорам
              </Radio>
              <Radio value={contractDocumentTypeEnum.account}>
                По лицевым счетам
              </Radio>
            </Radio.Group>
          </GridItem>
        </Grid>
      }
      onConfirm={() => {
        // eslint-disable-next-line
        const res = props.onConfirm?.({
          dateRange: [
            formatDateTimeToISOString(
              dateRange[0],
              moment(dateRange[0]).format('DD.MM.YYYY HH:mm')
            ),
            formatDateTimeToISOString(
              dateRange[1],
              moment(dateRange[1]).format('DD.MM.YYYY HH:mm')
            ),
          ],
          docType,
        });
      }}
    >
      <Button>Запросить данные</Button>
    </Popconfirm>
  );
};

export default withUserAccess(ContractList);
