import { BioTreatmentFacilityContractReport } from '../containers/BioTreatmentFacilityContract';
import { accessTypeEnum } from '../lib/enum';

export default [
  {
    component: BioTreatmentFacilityContractReport,
    path: '/bioTreatmentFacilityReport',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBioTreatmentFacility,
      accessTypeEnum.handlingBioTreatmentFacility,
      accessTypeEnum.approvingBioTreatmentFacility,
      accessTypeEnum.addFactBioTreatmentFacility,
    ],
  },
];
