// @flow
import React, { useState, useCallback } from 'react';

import { goBack } from '../../../lib/helpers';
import { pddGroupApi } from '../../../lib/api';

import { type PddGroup } from '../../../lib/types';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrlForEdit } from '../../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/pdd/groups/';

export default (props: PageProps) => {
  const [data: PddGroup, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, props.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await pddGroupApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: PddGroup) => {
    let id = payload.id;
    if (id) {
      await pddGroupApi.update(payload);
    } else {
      let data = await pddGroupApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}${id}`);
  };

  const onCancel = async () => {
    await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Темы ПДД'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
