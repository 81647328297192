// @flow
import React, { useMemo } from 'react';

import { Wrapper, Pie } from './styles';
import type { Interval } from '../grid';

type Props = {
  intervals: Interval[]
};

/**
 * Мини-карта статусов трека
 */
export const TrackHistory = ({ intervals }: Props) => {
  const pies = useMemo(() => {
    return intervals.map(({ width, status }, index) => (
      <Pie key={index} style={{ width }} status={status} />
    ));
  }, [intervals]);

  return <Wrapper>{pies}</Wrapper>;
};
