// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number
};

export default ({ vehiclePlanId }: Props) => (
  <Tabs withRouter>
    <TabItem
      label="Пропуска весенний период"
      url={`/budget/vehicle/${vehiclePlanId}/spring-pass`}
    />
    <TabItem
      label="Пропуска негабарит"
      url={`/budget/vehicle/${vehiclePlanId}/cargo-pass`}
    />
  </Tabs>
);
