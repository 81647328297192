import { fetchRequest } from './index';

// В чем прикол, у Directions API нет КОРСа! Те из js нельзя к нему обратиться,
// поэтому у нас тут есть типа прокси, который с сервера отправляет на гугл запросы
export const proxy = async query => {
  return await fetchRequest.post('/proxy', {
    url: query
  });
};

export default {
  proxy
};
