// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import { navigate } from '@reach/router';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Panel, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { InspectionGibdd } from '../../../lib/types';
import { Selects, Form } from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { formatDateTimeToISOString, goBack } from '../../../lib/helpers';
import { inspectionGibddApi } from './../../../lib/api';
import { notificationLoading } from '../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import { InputNumber } from '../../../components/inputs';

const { VehicleSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  inspectionGibdd: InspectionGibdd,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  inspectionGibdd,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={inspectionGibdd} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const { handleSubmit, handleBlur, setFieldValue, dirty } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {showVehicleSelect && (
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    label="Дата осмотра"
                    name="maintenanceDate"
                    required
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Следующая дата обслуживания"
                    name="nextActDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                  >
                    {({ name, value }) => (
                      <InputNumber
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  inspectionGibddId: ?number,
  vehicleId: ?number
};

type State = {
  inspectionGibdd: $Shape<InspectionGibdd>
};

class InspectionGibddForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const inspectionGibddId = parseInt(this.props.inspectionGibddId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (inspectionGibddId) {
        const inspectionGibdd = await inspectionGibddApi.fetchInspectionGibdd(
          inspectionGibddId
        );
        this.setState({
          inspectionGibdd
        });
      } else if (vehicleId) {
        this.setState({
          inspectionGibdd: {
            vehicleId
          }
        });
      } else {
        this.setState({
          inspectionGibdd: {}
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.inspectionGibddId) => {
    if (this.props.vehicleId) {
      // Делаем именно переход, т.к. нам нужен скролл до блока с тех. осмотрами ГИБДД
      navigate(
        `/vehicles/${this.props.vehicleId}/maintenance/inspections-gibdd`
      );
    } else {
      goBack('/services/inspections-gibdd');
    }
  };

  onSubmit = async (inspectionGibdd: InspectionGibdd) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const inspectionGibddId = parseInt(this.props.inspectionGibddId, 10);
      if (inspectionGibddId) {
        await inspectionGibddApi.updateInspectionGibdd(inspectionGibdd);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(inspectionGibddId);
      } else {
        const added = await inspectionGibddApi.addInspectionGibdd(
          inspectionGibdd
        );

        notification.success({
          message: 'Успешное добавление',
          description: `Технический осмотр ГИБДД успешно добавлен`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const inspectionGibddId = parseInt(this.props.inspectionGibddId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { inspectionGibdd } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {vehicleId > 0 ? (
                <Crumb
                  to={`/vehicles/${vehicleId}/maintenance/inspections-gibdd`}
                >
                  ТС
                </Crumb>
              ) : (
                <Crumb to={'/services/inspections-gibdd'}>
                  Список технических осмотров ГИБДД
                </Crumb>
              )}
              {inspectionGibddId ? (
                <Crumb>Редактирование техническиго осмотра ГИБДД</Crumb>
              ) : (
                <Crumb>Новый технический осмотр ГИБДД</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {inspectionGibddId
              ? `Технический осмотр ГИБДД №${inspectionGibddId}`
              : 'Новый технический осмотр ГИБДД'}
          </h1>
        </StyledPanel>
        <InnerForm
          inspectionGibdd={inspectionGibdd}
          onSubmit={this.onSubmit}
          onCancel={() =>
            this.redirect(inspectionGibdd.id || inspectionGibddId)
          }
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default InspectionGibddForm;
