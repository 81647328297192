// @flow

import type {
  VehicleGroup,
  VehicleGroupLeaf,
  VehicleGroupNode,
  VehicleGroupParent,
  VehicleType,
  VehicleTypesPlainTree,
} from './types';
import {
  vehicleGroupEnum,
  vehicleGroups,
  vehicleTypeEnum,
  vehicleTypes,
} from './enum/vehicleTypes';
import { selfVehiclePlanVehicleTypeEnum } from './enum';

/**
 * Возвращает родительский узел (вид ТС) в виде родителя для TreeSelect
 * @param value Вид ТС
 * @param children Дети
 */
export const getVehicleGroupParent = (
  value: VehicleGroup,
  children: VehicleGroupLeaf[]
): VehicleGroupParent => ({
  title: vehicleGroups[value],
  value,
  key: value,
  children,
});

/**
 * Возвращает типы для вида ТС в виде детей для TreeSelect
 * @param vehicleTypes[] Массив энумок типов ТС
 */
export const getVehicleGroupChildren = (
  types: VehicleType[]
): VehicleGroupLeaf[] => {
  return types
    .sort((a: VehicleType, b: VehicleType) =>
      // Проверяем, чтобы не было ошибок, cannot get localeCompare from undefined
      // В случае, если vehicleTypes[a] undefined, то отдаем -1, что равнозначно результату vehicleTypes[a].localeCompare(undefined)
      vehicleTypes[a] ? vehicleTypes[a].localeCompare(vehicleTypes[b]) : -1
    )
    .map((value) => ({
      title: vehicleTypes[value],
      value,
      key: value,
    }));
};

/**
 * Дерево типов ТС
 */
export const vehicleTypesTree: VehicleGroupParent[] = [
  getVehicleGroupParent(
    vehicleGroupEnum.carGroup,
    getVehicleGroupChildren([vehicleTypeEnum.car])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.busGroup,
    getVehicleGroupChildren([vehicleTypeEnum.bus])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.cargoGroup,
    getVehicleGroupChildren([
      vehicleTypeEnum.cargo,
      vehicleTypeEnum.emergency,
      vehicleTypeEnum.cargoPassenger,
      vehicleTypeEnum.onboard,
      vehicleTypeEnum.tiagach,
      vehicleTypeEnum.van,
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.specialCarGroup,
    getVehicleGroupChildren([
      vehicleTypeEnum.specialCar,
      vehicleTypeEnum.tractor,
      vehicleTypeEnum.vacuum,
      vehicleTypeEnum.ilosos,
      vehicleTypeEnum.hydromachine,
      vehicleTypeEnum.manipulator,
      vehicleTypeEnum.truckCrane,
      vehicleTypeEnum.excavator,
      vehicleTypeEnum.canalWashing,
      vehicleTypeEnum.dumpTruck,
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.trailerGroup,
    getVehicleGroupChildren([
      vehicleTypeEnum.compressor,
      vehicleTypeEnum.weldingMachine,
      vehicleTypeEnum.dieselGenerator,
      vehicleTypeEnum.semitrailer,
    ])
  ),
].sort((a: VehicleGroupNode, b: VehicleGroupNode) =>
  (vehicleTypes[a.value] || vehicleGroups[a.value]).localeCompare(
    vehicleTypes[b.value] || vehicleGroups[b.value]
  )
);

/**
 * "Плоское" представление дерева видов ТС
 * Имеет вид:
 * {
 *   "buses": [ vehicleTypesEnum.bus, ... ],
 *   ...
 * }
 */
export const vehicleTypesPlainTree: VehicleTypesPlainTree =
  vehicleTypesTree.reduce(
    (acc: VehicleTypesPlainTree, cur: VehicleGroupParent) => ({
      ...acc,
      [cur.value]: cur.children.map((node) => node.value),
    }),
    {}
  );

/**
 *   Сопоставление группы типа тс с показателем по которому предполагается техобслуживание
 *
 */
export const vehicleGroupsPlanMaintenanceType = {
  car: selfVehiclePlanVehicleTypeEnum.kilometrage,
  bus: selfVehiclePlanVehicleTypeEnum.kilometrage,
  cargo: selfVehiclePlanVehicleTypeEnum.kilometrage,
  specialCar: selfVehiclePlanVehicleTypeEnum.all,
};
