// @flow
import queryString from 'query-string';
import type { Osago } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';

import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import type { OsagoFilterParams } from '../../containers/services/osago/Filter';

export const convertDataFromServer = (osago: Osago) => ({
  ...osago,
  act: osago.act ? actConvertDataFromServer(osago.act) : undefined,
  paymentAmount: convertFromKopToRub(osago.paymentAmount)
});

export const convertDataToServer = (osago: Osago) => ({
  ...osago,
  paymentAmount: convertFromRubToKop(osago.paymentAmount)
});

export const addOsago = async (osago: Osago): Promise<Osago> => {
  const added = await fetchRequest.post('/osago', convertDataToServer(osago));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать ОСАГО');
};

export const updateOsago = async (osago: Osago): Promise<Osago> => {
  const updated = await fetchRequest.put('/osago', convertDataToServer(osago));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить ОСАГО');
};

export const deleteOsago = async (id: number): Promise<Osago> => {
  const deleted = await fetchRequest.delete(`/osago/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить ОСАГО');
};

export const fetchOsago = async (
  id: number,
  params: any = {}
): Promise<Osago> => {
  const osago = await fetchRequest.get(`/osago/${id}`, params);
  if (osago) return convertDataFromServer(osago);
  throw new Error('Не удалось получить ОСАГО');
};

export const fetchVehicleOsagos = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Osago>> => {
  const osagos = await fetchRequest.get(`/osago/vehicle/${vehicleId}`, {
    ...initialFetchListParams,
    ...params
  });
  if (osagos) {
    return {
      ...osagos,
      data: osagos.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список ОСАГО');
};

export const fetchOsagos = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Osago>> => {
  const osagos = await fetchRequest.get(`/osago`, {
    ...initialFetchListParams,
    ...params
  });
  if (osagos) {
    return {
      ...osagos,
      data: osagos.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список ОСАГО');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<Osago>> => {
  const osagos = await fetchRequest.put(
    `/osago/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (osagos) {
    return {
      ...osagos,
      data: osagos.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за списоком осаго');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/osago/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const printReport = async (
  params: OsagoFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/osago/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  getTotalSum,
  updateAct,
  addOsago,
  updateOsago,
  deleteOsago,
  fetchOsago,
  fetchOsagos,
  fetchVehicleOsagos,
  printReport
};
