import React from 'react';
import { GridItem } from '../../../../../../components/layout';
import Field from '../../../../../../components/card/Field';
import Grid from '../../../../../../components/layout/Grid';
import type { MonitoringVehicle } from '../../../../../../lib/types/monitoringVehicle';
import {
  monitoringVehicleStatesEnum,
  monitoringVehicleStatusesEnum
} from '../../../../../../lib/enum';
import { Footer } from '../footer';

type Props = {
  monitoringVehicle: MonitoringVehicle
};

const getSpeedLabel = (vehicle: MonitoringVehicle): string => {
  const { speed, status, state } = vehicle;
  if (
    status === monitoringVehicleStatusesEnum.nonactive ||
    status === monitoringVehicleStatusesEnum.nosignal
  ) {
    return 'Нет данных';
  } else if (status === monitoringVehicleStatusesEnum.active) {
    if (state === monitoringVehicleStatesEnum.inmove) {
      return `${speed} км/ч`;
    } else if (state === monitoringVehicleStatesEnum.parking) {
      return 'Стоит на парковке';
    } else if (state === monitoringVehicleStatesEnum.stopped) {
      return 'Стоит';
    } else if (state === monitoringVehicleStatesEnum.undef) {
      return 'Нет данных';
    }
  }
  return '';
};

/**
 * Вкладка информация по ТС
 */
export const VehicleTab = ({ monitoringVehicle }: Props) => {
  return (
    <>
      <Grid padding="16px" cols={5} gutter="24px" rowGutter="24px">
        <GridItem>
          <Field label="Адрес:">{monitoringVehicle.address}</Field>
        </GridItem>
        <GridItem>
          <Field label="Скорость:">{getSpeedLabel(monitoringVehicle)}</Field>
        </GridItem>
        <GridItem>
          <Field label="Устройство:">{monitoringVehicle.device}</Field>
        </GridItem>
        <GridItem>
          <Field label="ID:">
            {/* $FlowFixMe */}
            {monitoringVehicle.vehicle.wialonVehicleId}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="SIM:">{monitoringVehicle.sim}</Field>
        </GridItem>
      </Grid>
      <Footer
        vehicle={monitoringVehicle}
        driver={monitoringVehicle.vehicle.driver}
      />
    </>
  );
};
