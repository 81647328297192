// @flow
import type { Dashboard } from '../types';
import { convertFromKopToRub } from '../helpers';
import { fetchRequest } from './index';

const convertDataFromServer = (dashboard: Dashboard): Dashboard => ({
  ...dashboard,
  fuelsAndLiquidsSum: convertFromKopToRub(dashboard.fuelsAndLiquidsSum),
  repairsSum: convertFromKopToRub(dashboard.repairsSum),
  maintenancesSum: convertFromKopToRub(dashboard.maintenancesSum)
});

const months = [
  {
    name: 'Янв',
    uv: 0,
    pv: 0
  },
  {
    name: 'Фев',
    uv: 5,
    pv: 6
  },
  {
    name: 'Мар',
    uv: 7,
    pv: 5
  },
  {
    name: 'Апр',
    uv: 6,
    pv: 9
  },
  {
    name: 'Май',
    uv: 1,
    pv: 6
  },
  {
    name: 'Июн',
    uv: 10,
    pv: 1
  },
  {
    name: 'Июл',
    uv: 15,
    pv: 15
  },
  {
    name: 'Авг',
    uv: 18,
    pv: 18
  },
  {
    name: 'Сен',
    uv: 15,
    pv: 8
  },
  {
    name: 'Окт',
    uv: 16,
    pv: 10
  },
  {
    name: 'Ноя',
    uv: 9,
    pv: 10
  },
  {
    name: 'Дек',
    uv: 19,
    pv: 19
  }
];

export const get = async (params: any = {}): Promise<Dashboard> => {
  const dashboard = await fetchRequest.get(`/dashboard`, params);
  if (dashboard) return convertDataFromServer({ ...dashboard, months });
  throw new Error('Не удалось запросить данные аналитики');
};

export default {
  get
};
