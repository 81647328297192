// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  CalculationStatus,
  SpringPassCalculation,
  SpringPassCalculationMonth,
  VehiclePlan
} from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { convertFromKmToM, convertFromMToKm } from '../helpers';
import type { CommonApiMethod } from './../commonApi';

type springPassCalculationApiMethod = $Shape<
  CommonApiMethod & {
    calculation: CommonApiMethod
  }
>;

export const convertDataToServer = (
  springPassCalculation: SpringPassCalculation
): SpringPassCalculation => ({
  ...springPassCalculation,
  plannedKilometrage: convertFromKmToM(
    springPassCalculation.plannedKilometrage
  ),
  passSum: convertFromRubToKop(springPassCalculation.passSum),
  taxSum: convertFromRubToKop(springPassCalculation.taxSum),
  sum: convertFromRubToKop(springPassCalculation.sum),
  excessRate: convertFromRubToKop(springPassCalculation.excessRate),
  taxRate: convertFromRubToKop(springPassCalculation.taxRate),
  months: springPassCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  springPassCalculation: SpringPassCalculation
): SpringPassCalculation => ({
  ...springPassCalculation,
  plannedKilometrage: convertFromMToKm(
    springPassCalculation.plannedKilometrage,
    2
  ),
  passSum: convertFromKopToRub(springPassCalculation.passSum),
  taxSum: convertFromKopToRub(springPassCalculation.taxSum),
  sum: convertFromKopToRub(springPassCalculation.sum),
  excessRate: convertFromKopToRub(springPassCalculation.excessRate),
  taxRate: convertFromKopToRub(springPassCalculation.taxRate),
  months: springPassCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  springPassCalculationMonth: SpringPassCalculationMonth
): SpringPassCalculationMonth => ({
  ...springPassCalculationMonth,
  cost: convertFromRubToKop(springPassCalculationMonth.cost),
  tax: convertFromRubToKop(springPassCalculationMonth.tax)
});

export const convertDataFromServerMonth = (
  springPassCalculationMonth: SpringPassCalculationMonth
): SpringPassCalculationMonth => ({
  ...springPassCalculationMonth,
  cost: convertFromKopToRub(springPassCalculationMonth.cost),
  tax: convertFromKopToRub(springPassCalculationMonth.tax)
});

let basedUrl = 'springPassCalculation';
export const updateCalculations = async (
  vehiclePlanId: number
): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const springPassCalculation: springPassCalculationApiMethod = new CommonApi<SpringPassCalculation>(
  basedUrl,
  'Пропуска весенний период',
  convertDataToServer,
  convertDataFromServer
);

springPassCalculation.calculation = new CommonApi<SpringPassCalculation>(
  `${basedUrl}/updateCalculation`,
  'Пропуска весенний период',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...springPassCalculation,
  calculate,
  changeStatus,
  updateCalculations,
  print
};
