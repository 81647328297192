// @flow
import React from 'react';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from './../../../../../../../components';
import type { FilterChildrenParams } from './../../../../../../../components/hoc/Filter';
import Field from './../../../../../../../components/card/Field';

const {
  VehicleModelSelect,
  ExpenseDirectionTreeSelect,
  ContractVehicleSelect
} = Selects;

export type FixedRegistBudgetBudgetFilterParams = {
  'contractVehicle.vehicle.vehicleModel.id'?: number,
  contractVehicleId?: number,
  'regulationLimit.regulationLimitGroup.expenseDirectionId'?: number
};

type Props = {
  filter: FixedRegistBudgetBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <FilterContainer>
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter
      }: FilterChildrenParams<FixedRegistBudgetBudgetFilterParams>) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleModelSelect
                placeholder="Все модели ТС"
                size="small"
                value={values['contractVehicle.vehicle.vehicleModel.id']}
                onChange={value =>
                  changeValue('contractVehicle.vehicle.vehicleModel.id', value)
                }
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ContractVehicleSelect
                placeholder="Все ТС"
                size="small"
                value={values.contractVehicleId}
                onChange={value => changeValue('contractVehicleId', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ExpenseDirectionTreeSelect
                placeholder="Направление расходов"
                size="small"
                value={
                  values[
                    'regulationLimit.regulationLimitGroup.expenseDirectionId'
                  ]
                }
                onChange={value =>
                  changeValue(
                    'regulationLimit.regulationLimitGroup.expenseDirectionId',
                    value
                  )
                }
              />
            </Field>
          </FilterItem>

          <FilterButtonsContainer
            applyFilter={applyFilter}
            cleanFilter={cleanFilter}
          />
        </>
      )}
    </Filter>
  </FilterContainer>
);
