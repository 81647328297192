import { accessTypeEnum } from './../../lib/enum';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.creatingMaintenances
];
export const editAccessRight = [
  ...addAccessRight,
  accessTypeEnum.approvingMaintenances
];
// согласование карточки работ
export const canApprovingMaintenance = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingMaintenances
];

export default {
  addAccessRight,
  editAccessRight,
  canApprovingMaintenance
};
