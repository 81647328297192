//@flow
import React from 'react';
import { FormikProps } from 'formik';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import styled from 'styled-components';

import type { Channel } from '../../../lib/types';

import { OrgUnitSelect } from './../../../components';
import type { FormFieldType } from './../../../components/Form';
import Form from './../../../components/Form';
import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  channel: ?Channel,
  onCancel: Function,
  onSubmit: Function,
  loading: boolean,
};

export const InnerForm = ({ channel, onCancel, onSubmit, loading }: Props) => {
  return (
    <Form initialValues={channel} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting,
        }: FormikProps
      ) => {
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <Grid gutter="16px" cols={2}>
                <FormField label="Канал" name="channelId" required>
                  {(field) => <Input placeholder="Введите ид канала" {...field} />}
                </FormField>

                <GridItem>
                  <FormField
                    label="Подразделение"
                    name="organizationUnitId"
                    required
                  >
                    {({ name, value }) => (
                      <OrgUnitSelect
                        value={value}
                        onChange={(value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </StyledSection>
            <Footer>
              <div>
                <Button
                  disabled={isSubmitting || loading}
                  loading={isSubmitting}
                  type="primary"
                  onClick={handleSubmit}
                >
                  {values.id ? 'Сохранить' : 'Создать'}
                </Button>
              </div>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
