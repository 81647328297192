// @flow
import { CheckOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import isNil from 'lodash';
import isEqual from 'lodash/isEqual';
import type { moment } from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Card } from '../../../../components';
import { DatePicker } from '../../../../components/ant/DatePicker';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { Icon, Spoiler } from '../../../../components/ui';

import type { Battery } from '../../../../lib/types';
import { convertDateToString, formatDate, getMomentValue } from '../../lib';
import { EquipmentActions } from '../elements';
import RemoveEquipmentButton from '../RemoveEquipmentButton';

const { Field } = Card;
const ButtonGroup = Button.Group;

const SpoilerContent = styled.div`
  padding: 16px;
  border-bottom: 1px solid #c0ccd7;
`;

type Props = {
  onChange: (battery: Battery) => void,
  onRemove: (battery: Battery) => void,
  battery: Battery,
  editable: boolean,
};

type State = {
  editMode: boolean,
  battery: Battery,
};

/**
 * Компонент отображает информацию по присоединенной к ТС
 * батарее, с возможностью ее отредактировать и удалить
 */
class EditableItem extends Component<Props, State> {
  state = {
    editMode: false,
    battery: this.props.battery,
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.battery, this.props.battery)) {
      this.setState({
        battery: this.props.battery,
      });
    }
  }

  // Применение изменений по АКБ
  onApply = async () => {
    await this.props.onChange(this.state.battery);
    this.setState({
      editMode: false,
    });
  };

  changeBattery = (fieldName: string, value: any) => {
    this.setState((prev) => ({
      battery: {
        ...prev.battery,
        [fieldName]: value,
      },
    }));
  };

  // Откат изменений до первоначальных
  onCancel = () =>
    this.setState({
      battery: this.props.battery,
      editMode: false,
    });

  getBatteryLabel(battery: $Shape<Battery>) {
    return [
      `${battery.name || ''} ${battery.brand || ''}`,
      battery.factoryNumber && `Заводской номер: ${battery.factoryNumber}`,
    ]
      .filter((item) => !!item)
      .join(' | ');
  }

  render() {
    const { onRemove, editable } = this.props;
    const { battery, editMode } = this.state;
    const {
      name,
      brand,
      voltage,
      capacity,
      installDate,
      replaceDate,
      norm,
      factoryNumber,
      batchNumber,
      issuedDate,
      notation,
    } = battery;
    return (
      <Spoiler
        key={battery.id}
        label={this.getBatteryLabel(battery)}
        suffix={
          <EquipmentActions>
            {editable && (
              <ButtonGroup>
                {editMode ? (
                  <>
                    <Button
                      data-test="check"
                      onClick={this.onApply}
                      icon={<CheckOutlined />}
                    />
                    <Button data-test="close" onClick={this.onCancel}>
                      <Icon type="x" />
                    </Button>
                  </>
                ) : (
                  <Button
                    data-test="edit"
                    onClick={() => this.setState({ editMode: true })}
                  >
                    <Icon type="edit" />
                  </Button>
                )}
              </ButtonGroup>
            )}
            {onRemove && (
              <RemoveEquipmentButton
                onConfirm={(removeDate: string | moment) =>
                  onRemove({
                    ...this.props.battery,
                    removeDate,
                  })
                }
              />
            )}
          </EquipmentActions>
        }
      >
        <SpoilerContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Наименование">{name}</Field>
            </GridItem>
            {!isNil(notation) && (
              <GridItem>
                <Field label="Примечание">{notation}</Field>
              </GridItem>
            )}
            <GridItem>
              <Field label="Марка">{brand}</Field>
            </GridItem>
            <GridItem>
              <Field label="Напряжение (В)">{voltage}</Field>
            </GridItem>
            <GridItem>
              <Field label="Норматив эксплуатации, количество лет">
                {norm}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Ёмкость, ампер/час">{capacity}</Field>
            </GridItem>
            {factoryNumber && (
              <GridItem>
                <Field label="Заводской номер">{factoryNumber}</Field>
              </GridItem>
            )}
            {batchNumber && (
              <GridItem>
                <Field label="Номер партии">{batchNumber}</Field>
              </GridItem>
            )}
            {issuedDate && (
              <GridItem>
                <Field label="Дата выдачи">{formatDate(issuedDate)}</Field>
              </GridItem>
            )}
            <GridItem>
              <Field label="Дата установки">
                {editMode ? (
                  <DatePicker
                    format="DD.MM.YYYY"
                    value={getMomentValue(installDate)}
                    onChange={(value: Object, dateString: string) =>
                      this.changeBattery(
                        'installDate',
                        convertDateToString(value, dateString)
                      )
                    }
                  />
                ) : (
                  formatDate(installDate)
                )}
              </Field>
            </GridItem>
            {!editMode && (
              <GridItem>
                <Field label="Дата планируемой замены">
                  {replaceDate ? formatDate(replaceDate) : null}
                </Field>
              </GridItem>
            )}
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}

export default EditableItem;
