// @flow
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';

import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';

import { orgUnitApi, selfRegulationsApi } from '../../lib/api';
import type { UserAccess } from '../../lib/types';

import { Section, SectionContent } from './../../components/layout';
import { Selects } from './../../components';
import Header from '../../components/layout/Header';
import { Table } from './../../components/ui';
import Grid, { GridItem } from './../../components/layout/Grid';
import { InputNumber } from '../../components/inputs';

import withUserAccess from '../withUserAccess/withUserAccess';
import { canAdd } from './accessRight';

const Total = styled.div`
  background: #f0f4f8;
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const TotalTitle = styled.p`
  color: #3d4042;
  text-transform: uppercase;
`;

const TotalValue = styled.p`
  color: #3d4042;
`;

const { YearSelect } = Selects;

type Props = {
  userAccess: UserAccess[]
};

export const SelfRegulationsForm = withUserAccess(({ userAccess }: Props) => {
  const [year, setYear] = useState();
  const [orgUnits, setOrgUnits] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [values, setValues] = useState({});
  const [dirty, setDirty] = useState(false);

  const handleCreateRegulations = useCallback(async () => {
    try {
      const result = await selfRegulationsApi.add({
        year,
        orgUnits: values
      });
      navigate(`/self-regulations/${result.id}`);
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    }
  }, [year, values]);

  const rowSelection = useMemo(
    () => ({
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows);
      },
      getCheckboxProps: record => ({
        name: record.name
      }),
      selectedRowKeys: selectedRows.map(({ key }) => key)
    }),
    [selectedRows]
  );

  useEffect(() => {
    async function fetchOrgUnits() {
      const orgUnits = (await orgUnitApi.getUsingNodes()).map(v => ({
        ...v,
        children: undefined
      }));
      const values = orgUnits.reduce(
        (acc, orgUnit) => ({ ...acc, [orgUnit.id]: 140 }),
        {}
      );
      setValues(values);
      setOrgUnits(orgUnits);
      setSelectedRows(orgUnits);
    }
    fetchOrgUnits();
  }, []);

  const handleChange = useCallback(
    (key: string, value: any) => {
      const dt = _cloneDeep(values);
      _set(dt, key, value);
      setValues(dt);
      setDirty(true);
    },
    [values]
  );

  const isSelected = (id: number) => !!selectedRows.find(v => v.id === id);

  const totalValue = useMemo(
    () => Object.keys(values).reduce((acc, key) => acc + (values[key] || 0), 0),
    [values]
  );

  const hasErrors = useMemo(
    () => !year || selectedRows.some(v => !values[v.id]),
    [year, selectedRows, values]
  );

  return (
    <>
      <Header
        left={<h1>Добавление нового регламена</h1>}
        right={
          canAdd(userAccess) && (
            <Button
              disabled={hasErrors}
              type="primary"
              onClick={handleCreateRegulations}
            >
              Сохранить
            </Button>
          )
        }
      />
      <Section>
        <SectionContent style={{ position: 'relative' }}>
          <Grid>
            <GridItem>
              <Form.Item
                validateStatus={dirty && !year && 'error'}
                help={dirty && !year && 'Обязательное поле'}
              >
                <YearSelect
                  value={year}
                  onChange={value => {
                    setYear(value);
                    setDirty(true);
                  }}
                />
              </Form.Item>
            </GridItem>
            <GridItem fullWidth>
              <Table
                columns={[
                  {
                    dataIndex: 'name',
                    title: 'Наименование подразделения использования'
                  },
                  {
                    dataIndex: 'id',
                    width: '150px',
                    title: 'Пробег в день, км',
                    render: (id: number) => {
                      const selected = isSelected(id);
                      return (
                        <Form.Item
                          validateStatus={
                            selected && dirty && !values[id] && 'error'
                          }
                          help={
                            selected &&
                            dirty &&
                            !values[id] &&
                            'Обязательное поле'
                          }
                        >
                          <InputNumber
                            disabled={!selected}
                            style={{ width: '100%' }}
                            value={values[id]}
                            placeholder="км"
                            onChange={value => handleChange(`${id}`, value)}
                          />
                        </Form.Item>
                      );
                    }
                  }
                ]}
                rowSelection={rowSelection}
                data={orgUnits}
              />
            </GridItem>
          </Grid>
          <Total>
            <TotalTitle>Итого:</TotalTitle>
            <TotalValue>{totalValue} км</TotalValue>
          </Total>
        </SectionContent>
      </Section>
    </>
  );
});

export default SelfRegulationsForm;
