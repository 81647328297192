// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import isNil from 'lodash/isNil';

import type { Battery, UserAccess } from '../../../lib/types';
import { batteryApi } from '../../../lib/api';
import {
  declineNumber,
  formatDateTimeToString,
  convertVehicleToString,
} from '../../../lib/helpers';
import { accessTypeEnum } from './../../../lib/enum';
import { Card } from './../../../components';
import { Panel, Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import HistoryTable from './../HistoryTable';
import Header from '../../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { withUserAccess } from './../../withUserAccess';
import {
  Popconfirm,
  Dropdown,
  ButtonOperations,
} from './../../../components/ui';
import type { DropdownType } from './../../../components/ui/Dropdown';
import AttachVehicleButton from './../components/AttachVehicleButton';
import RemoveEquipmentButton from './../../Vehicles/components/RemoveEquipmentButton';
import CopyBatteryButton from './components/CopyBatteryButton';
import { notificationLoading } from './../../../components/Notifications';

const SectionContent = styled.div`
  padding: 16px;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const { Field } = Card;

export const canEditBatteryAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingEquipment,
];

type Props = {
  batteryId: number,
  userAccess: UserAccess[],
};

type State = {
  battery: ?Battery,
};

/** Карточка АКБ */
export class BatteryCard extends Component<Props, State> {
  state = {};

  dropdown: ?DropdownType;

  async componentDidMount() {
    const { batteryId } = this.props;
    try {
      const battery = await batteryApi.fetchBattery(batteryId);
      this.setState({ battery });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка',
        description: err && err.message,
      });
    }
  }

  deleteBattery = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await batteryApi.deleteBattery(this.props.batteryId);
      navigate('/equipment/batteries');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  setVehicle = async (
    vehicleId: ?number,
    installDate?: string,
    removeDate?: string
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const { battery } = this.state;
      if (!battery) {
        return;
      }
      let updatedBattery;
      if (vehicleId) {
        updatedBattery = await batteryApi.setBatteryVehicle(
          this.props.batteryId,
          vehicleId,
          installDate,
          removeDate
        );
      } else {
        updatedBattery = await batteryApi.updateBattery({
          ...battery,
          vehicleId,
          installDate,
          removeDate,
        });
      }
      this.setState({
        battery: updatedBattery,
      });
      notification.success({
        message: vehicleId ? 'ТС успешно закреплено' : 'ТС успешно откреплено',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { batteryId, userAccess } = this.props;
    const { battery } = this.state;
    const canEdit = userAccess.some((access) =>
      canEditBatteryAccess.includes(access)
    );
    if (!battery) {
      return null;
    }
    const canUnfixVehicle = parseInt(battery.vehicleId, 10) > 0;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/equipment/batteries">АКБ</Crumb>
              <Crumb>
                {battery.brand} {battery.name}
              </Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <ButtonOperations>
                {canUnfixVehicle && (
                  <RemoveEquipmentButton
                    onConfirm={(removeDate: string) =>
                      this.setVehicle(null, battery.installDate, removeDate)
                    }
                  >
                    Открепить ТС
                  </RemoveEquipmentButton>
                )}
                <AttachVehicleButton
                  disableDate={false}
                  onConfirm={this.setVehicle}
                  vehicleId={battery.vehicleId}
                />
                <Dropdown
                  ref={(dropdown) => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <CopyBatteryButton
                          battery={battery}
                          afterSubmit={() => {
                            if (this.dropdown) {
                              this.dropdown.onVisibleChange(false);
                            }
                          }}
                        />
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        onClick={() =>
                          navigate(`/equipment/batteries/edit/${batteryId}`)
                        }
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteBattery}
                          onVisibleChange={(flag) =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className="openActionsDropdown"
                    type="primary"
                    icon={<EllipsisOutlined />}
                  />
                </Dropdown>
              </ButtonOperations>
            )
          }
        />
        <StyledPanel>
          <h1>
            {battery.brand} {battery.name}
          </h1>
        </StyledPanel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование">{battery.name}</Field>
              </GridItem>
              {!isNil(battery.notation) && (
                <GridItem>
                  <Field label="Примечание">{battery.notation}</Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Марка">{battery.brand}</Field>
              </GridItem>
              <GridItem>
                <Field label="Напряжение (В)">{battery.voltage}</Field>
              </GridItem>
              <GridItem>
                <Field label="Ёмкость">{battery.capacity} ампер/час</Field>
              </GridItem>
              <GridItem>
                <Field label="Дата выдачи">
                  {formatDateTimeToString(battery.issuedDate, 'DD.MM.YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата установки">
                  {formatDateTimeToString(battery.installDate, 'DD.MM.YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата планируемой замены">
                  {battery.removeDate
                    ? formatDateTimeToString(battery.replaceDate, 'DD.MM.YYYY')
                    : ''}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Норматив эксплуатации">
                  {[
                    battery.norm,
                    declineNumber(battery.norm, 'год', 'года', 'лет'),
                  ].join(' ')}
                </Field>
              </GridItem>
              {!isNil(battery.batchNumber) && (
                <GridItem>
                  <Field label="Номер партии">{battery.batchNumber}</Field>
                </GridItem>
              )}
              {!isNil(battery.factoryNumber) && (
                <GridItem>
                  <Field label="Заводской номер">{battery.factoryNumber}</Field>
                </GridItem>
              )}
              {!isNil(battery.vehicle) && (
                <GridItem>
                  <Field label="Прикрепленное ТС">
                    {battery.vehicle?.id ? (
                      <Link to={`/vehicles/${battery.vehicle?.id}`}>
                        {convertVehicleToString(battery.vehicle)}
                      </Link>
                    ) : (
                      convertVehicleToString(battery.vehicle)
                    )}
                  </Field>
                </GridItem>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>История</SectionTitle>
          {batteryId > 0 && (
            <HistoryTable
              equipmentId={batteryId}
              fetchHistory={batteryApi.fetchHistory}
            />
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(BatteryCard);
