// @flow
import React, { useCallback, useMemo } from 'react';

import type { MonitoringVehicleSignal } from '../../../../../../lib/types/monitoringVehicle';
import { SignalLevel, Wrapper } from './styles';

type Props = {
  level: MonitoringVehicleSignal
};

export const Signal = ({ level }: Props) => {
  const isActive = useCallback(
    (index: number) => {
      return index <= level - 1;
    },
    [level]
  );

  const signalLevels = useMemo(() => {
    return ['4px', '5px', '7px', '8px', '10px', '12px'].map((height, index) => (
      <SignalLevel height={height} active={isActive(index)} />
    ));
  }, [isActive]);

  return <Wrapper>{signalLevels}</Wrapper>;
};
