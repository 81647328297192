// @flow

import React from 'react';

import notification from 'antd/lib/notification';

import { osagoApi } from './../../../../../lib/api';
import type { Osago } from './../../../../../lib/types';

import Filter from './../../../../services/osago/Filter';
import { OSAGO_COLUMNS } from './../../../../services/osago';

import { ListTable } from './../../../lib';

export default class extends ListTable<Osago> {
  fetch = async (page: number = 1) => {
    const params = super.fetch(page);
    try {
      const data = await osagoApi.fetchOsagos(params);
      const totalSum = await osagoApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(OSAGO_COLUMNS)}
      </>
    );
  }
}
