// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';

import MaskInput from '../MaskInput';

export const formatPhoneMask = () => '+7 (999) 999-99-99';

/**
 * Маска номера телефона
 */
export default (props: InputProps) => (
  <MaskInput mask="+7 (999) 999-99-99" {...props} />
);
