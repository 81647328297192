// @flow
import React from 'react';

import notification from 'antd/lib/notification';

import type {
  AssignmentLimitGroup,
  RegulationLimitType
} from './../../../../../../lib/types';
import { regulationLimitTypeEnum } from './../../../../../../lib/enum';
import { assignmentLimitApi } from './../../../../../../lib/api';

import ListTable from './../../../../../../components/ui/ListTable';
import Spinner from './../../../../../../components/Spinner';
import { notificationLoading } from './../../../../../../components/Notifications';

import AssignmentGroupRow from './components/AssignmentGroupRow';
import {
  EmployeeHeader,
  ListTableOvbHeader,
  OrgUnitHeader
} from './components/elements';

type Props = {
  type: RegulationLimitType,
  // Группы лимитов
  groups: AssignmentLimitGroup[],
  // Функция изменения значения
  onChangeGroups: (groups: AssignmentLimitGroup[]) => void,
  // Функция изменения закрепления
  updateAssignment: Function,
  // Флаг, указывающий процесс загрузки
  loading: boolean,
  editing: boolean
};

/**
 * Компонент отрисовки групп лимитов для закрепления
 */
export default class AssignmentGroups extends React.Component<Props> {
  handleChange = (
    index: number,
    name: $Keys<AssignmentLimitGroup>,
    value: any
  ) => {
    const groups = [...this.props.groups];
    groups[index][name] = value;
    this.props.onChangeGroups(groups);
  };

  handleSaveGroup = async (group: AssignmentLimitGroup, index: number) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await Promise.all(
        group.assignmentLimit.map(
          async limit => await assignmentLimitApi.update(limit)
        )
      );
      this.props.updateAssignment();
      notification.success({
        message: 'Лимиты были успешно зафиксированы'
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  renderHeader = (type: RegulationLimitType) => {
    switch (type) {
      case regulationLimitTypeEnum.employee:
        return <EmployeeHeader />;
      case regulationLimitTypeEnum.orgUnit:
        return <OrgUnitHeader />;
      case regulationLimitTypeEnum.ovb:
        return <ListTableOvbHeader />;
      default:
        return <OrgUnitHeader />;
    }
  };

  getTypeForNotification = () => {
    switch (this.props.type) {
      case 'employee':
        return 'должностных групп';
      case 'orgUnit':
        return 'служб';
      case 'ovb':
      default:
        return 'ОВБ';
    }
  };

  render() {
    const { groups, loading, type, editing } = this.props;

    return (
      <Spinner isLoading={loading}>
        <ListTable
          style={{ overflowX: 'auto' }}
          styleContent={{ width: '1800px' }}
          data={groups}
          header={this.renderHeader(type)}
          renderRow={(group: AssignmentLimitGroup, index: number) => (
            <AssignmentGroupRow
              group={group}
              onSave={this.handleSaveGroup}
              editable={editing}
              onChange={this.handleChange}
              index={index}
              key={index}
            />
          )}
        />
      </Spinner>
    );
  }
}
