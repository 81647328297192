import { LocationForm, LocationList } from '../../containers/Locations';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: LocationList,
        path: '/admin/locations',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingLocation,
            accessTypeEnum.viewingAllLocations,
            accessTypeEnum.viewingBranchLocations,
        ],
    },
    {
        component: LocationForm,
        path: '/admin/locations/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingLocation,
        ],
    },
    {
        component: LocationForm,
        path: '/admin/locations/:locationId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingLocation,
        ],
    },
];