// @flow
import React, { useState, useCallback } from 'react';

import { goBack, navigate } from '../../../lib/helpers';
import { pddTicketApi } from '../../../lib/api';

import { type PddTest } from '../../../lib/types';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import InnerForm from './components/InnerForm';

type PageProps = {
  id: number,
  pddTestId?: number
};

const entryPointPath = '/admin/pdd/tickets/';

export default (props: PageProps) => {
  const [data: PddTest, setData] = useState({});

  const onFetch = useCallback(async () => {
    !!props.id && setData(await pddTicketApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: PddTest) => {
    let id = payload.id;
    if (id) {
      await pddTicketApi.update(payload);
    } else {
      let data = await pddTicketApi.add(payload);
      id = data.id;
    }
    props.pddTestId
      ? await navigate(`/admin/pdd/test/register/edit/${props.pddTestId}`)
      : await goBack(`${entryPointPath}${id}`);
  };

  const onCancel = async () => {
    props.pddTestId
      ? await navigate(`/admin/pdd/test/register/edit/${props.pddTestId}`)
      : await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: props.pddTestId
          ? [
              {
                to: '/',
                name: 'Главная'
              },
              {
                to: '/admin/pdd/test/register/',
                name: 'Список ПДД'
              },
              {
                to: `/admin/pdd/test/register/edit/${props.pddTestId}`,
                name: 'ПДД'
              },
              {
                name: 'Билет'
              }
            ]
          : [
              {
                to: '/',
                name: 'Главная'
              },
              {
                to: '/admin/pdd/tickets',
                name: 'Список билетов'
              },
              { name: 'Билет' }
            ],
        mainHeader: 'Билет  ПДД'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} pddTestId={props.pddTestId} useFooter noWrapMe />
    </CommonCardPage>
  );
};
