// @flow
import type { Brand } from '../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

// Добавление марки
export const addBrand = async (brand: Brand): Promise<Brand> => {
  const added = await fetchRequest.post('/brand', brand);
  if (added) {
    return added;
  }
  throw new Error('Не удалось создать новую марку');
};

// Получение списка марок
export const fetchBrands = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Brand>> => {
  let brands = await fetchRequest.get('/brand', {
    ...initialFetchListParams,
    ...params
  });
  if (brands) return brands;
  throw new Error('Не удалось загрузить марки');
};

// Получение марки по id
export const fetchBrand = async (
  id: number,
  params: any = {}
): Promise<Brand> => {
  const brand = await fetchRequest.get(`/brand/${id}`, params);
  if (brand) return brand;
  throw new Error('Не удалось загрузить марку');
};

// Обновление марки
export const updateBrand = async (brand: Brand): Promise<Brand> => {
  const updated = await fetchRequest.put('/brand', brand);
  if (updated) return updated;
  throw new Error('Не удалось обновить марку');
};

// Удаление
export const deleteBrand = async (id: number): Promise<Brand> => {
  const deleted = await fetchRequest.delete(`/brand/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить марку');
};

export default {
  addBrand,
  fetchBrands,
  fetchBrand,
  updateBrand,
  deleteBrand
};
