// @flow
import uniq from 'lodash/uniq';

import { vehicleTypeEnum } from './vehicleTypes';
import { drivingLicenseCategoryEnum } from './index';
import type { DrivingLicenseCategory, VehicleGroup } from '../types';
import { vehicleTypesPlainTree } from '../vehicleTypesTree';

/**
 * Енумка соответствия типов ТС к категории прав ТС
 * @type {
 *   [key: VehicleType]: DrivingLicenseCategory
 * }
 */
export const vehicleTypesRightCategoryEnum = {
  [vehicleTypeEnum.bus]: drivingLicenseCategoryEnum.d,
  [vehicleTypeEnum.car]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.cargo]: drivingLicenseCategoryEnum.c,
  [vehicleTypeEnum.specialCar]: drivingLicenseCategoryEnum.c
};

/**
 * Возвращает список категорий прав ТС для данной группы ТС
 *
 * Берет все категории прав типов ТС, относящихся к этой группе
 * и возвращает все уникальные категории прав
 *
 * @param vehicleGroup
 */
export const getRightCategoryByVehicleGroup = (
  vehicleGroup: VehicleGroup
): DrivingLicenseCategory[] => {
  const vehicleTypes = vehicleTypesPlainTree[vehicleGroup];
  const rightCategories = vehicleTypes.map(
    type => vehicleTypesRightCategoryEnum[type]
  );
  return uniq(rightCategories);
};
