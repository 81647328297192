//@flow
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';

import type { PddGroup } from '../../../../../lib/types';
import { GridItem } from '../../../../../components/layout';
import Grid from '../../../../../components/layout/Grid';
import Field from '../../../../../components/card/Field';
import Selects from '../../../../../components/selects';
import type { PddTestGroup } from '../../../../../lib/types/pddTest';
import { InputNumber } from '../../../../../components/inputs';

const { PddGroupSelect } = Selects;

type PddGroupModalProps = {
  group: PddTestGroup,
  visible: boolean,
  onClose: () => any,
  onSave: (oldGroup: PddGroup, group: PddGroup) => any,
  onDelete: (group: PddGroup) => any
};

export const GroupModal = (props: PddGroupModalProps) => {
  const [group, setGroup] = useState(null);

  useEffect(() => {
    setGroup(props.group);
  }, [props.group]);
  return (
    <Modal
      centered
      destroyOnClose
      footer={[
        !props.group?.id ? (
          <Button key="back" onClick={() => props.onClose()}>
            Отмена
          </Button>
        ) : null,
        props.group?.id ? (
          <Button
            key="back"
            type="danger"
            onClick={() => props.onDelete(props.group)}
          >
            Удалить тему
          </Button>
        ) : null,
        <Button
          disabled={!group?.pddGroupId}
          key="submit"
          type="primary"
          onClick={() => props.onSave(props.group, group)}
        >
          Сохранить
        </Button>
      ]}
      title={'Прикрепление темы ПДД'}
      visible={props.visible}
      onCancel={props.onClose}
      width={500}
      height={500}
    >
      <Grid cols={1}>
        <GridItem>
          <Field>
            <PddGroupSelect
              value={group?.pddGroupId}
              onChange={(value, option) => {
                // $FlowFixMe флоу считает что передается функция
                setGroup({
                  ...group,
                  pddGroupId: value,
                  pddGroup: option.props.group
                });
              }}
            />
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Кол-во вопросов в билете">
            <InputNumber
              value={group?.questionCount}
              // $FlowFixMe флоу считает что передается функция
              onChange={value => setGroup({ ...group, questionCount: value })}
            />
          </Field>
        </GridItem>
      </Grid>
    </Modal>
  );
};
