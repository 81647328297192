// @flow
import React from 'react';
import { Button } from 'antd';
import notification from 'antd/lib/notification';

import type { UserAccess } from '../../lib/types';
import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';

import { contractApi, stockPartApi } from '../../lib/api';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import Field from '../../components/card/Field';

import { withUserAccess } from './../withUserAccess';

type PageProps = {
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/subscriptions/';

export default withUserAccess((props: PageProps) => {
  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath);

  const fetchWrapper = async (fetcher: () => any) => {
    try {
      const data = await fetcher();
      notification.info({ message: data.status });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  const fetchContracts = async () => {
    return await fetchWrapper(contractApi.subscribeParusContracts);
  };

  const fetchAccounts = async () => {
    return await fetchWrapper(contractApi.subscribeParusAccounts);
  };

  const fetchStockParts = async () => {
    return await fetchWrapper(stockPartApi.subscribeParusStockParts);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Подписки'
      }}
      onFetch={() => {}}
    >
      <>
        <Grid gutter="16px" cols={3}>
          <GridItem>
            <Field label="Договора">
              <Button onClick={fetchContracts}>
                Подписаться на обновления
              </Button>
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Лицевой счет">
              <Button onClick={fetchAccounts}>Подписаться на обновления</Button>
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Склады">
              <Button onClick={fetchStockParts}>
                Подписаться на обновления
              </Button>
            </Field>
          </GridItem>
        </Grid>
      </>
    </CommonCardPage>
  );
});
