// @flow

export const fuelOilsTypes = {
  motorOil: 'Моторное масло',
  transmissionOil: 'Трансмиссионное масло',
  specialOil: 'Специальные масла и жидкости',
  autoGreases: 'Пластичные смазки'
};

export const fuelOilsEnum = {
  motorOil: 'motorOil',
  transmissionOil: 'transmissionOil',
  specialOil: 'specialOil',
  autoGreases: 'autoGreases'
};

export const fuelTypes = {
  dieselFuel: 'ДТ',
  ai80: 'АИ-80',
  ai92: 'АИ-92',
  ai95: 'АИ-95',
  propaneButane: 'Пропан-Бутан',
  methane: 'Метан',
  ...fuelOilsTypes
};

export const fuelTypeEnum = {
  dieselFuel: 'dieselFuel',
  ai80: 'ai80',
  ai92: 'ai92',
  ai95: 'ai95',
  propaneButane: 'propaneButane',
  methane: 'methane',
  ...fuelOilsEnum
};

export const fuelTypesCalculation = {
  dieselFuel: 'ДТ',
  ai80: 'АИ-80',
  ai92: 'АИ-92',
  ai95: 'АИ-95'
};
