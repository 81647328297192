// @flow
import type { BudgetVersion } from './../types';
import CommonApi from './../commonApi';

const budgetVersion = new CommonApi<BudgetVersion>(
  'budgetVersion',
  'Версии бюджета'
);

export default {
  ...budgetVersion
};
