//@flow
import { default as FormItem } from 'antd/lib/form/FormItem';
import styled from 'styled-components';

const StyledFormItem = styled(FormItem)`
  flex-direction: column;
  
    margin-bottom: 14px;
  
  .ant-form-item-label {
    display: flex;
    height: 22px;
    align-items: center;
  }
  
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
    margin: 0;
  }

  .ant-form-item-label .ant-form-item-required::after {
    content: '*';
    color: #f5222d;
  }

  .ant-form-item-label > label {
    height: 17px;
  }

  .ant-form-item-children-icon .anticon-close-circle svg {
    display: none;
  }

  .ant-form-item-children-icon .anticon-close-circle::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: red;
    mask-image: url('/img/icons/x-12.svg');
  }
  
  .ant-form-item-explain-error {
    font-size: 9px;
  }
`;

export { StyledFormItem as FormItem };
