// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import moment from 'moment';
import { navigate } from '@reach/router';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Panel, Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { Kasko } from './../../../lib/types';
import { Selects, Form } from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../../components/layout/Breadcrumbs';
import { formatDateTimeToISOString } from './../../../lib/helpers';
import { kaskoApi } from './../../../lib/api';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import { SectionTitle } from '../../../components/layout';
import { ownerTypes } from '../../../lib/enum';

const { VehicleSelect, ContractorSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  kasko: $Shape<Kasko>,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  kasko,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={kasko} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        values,
        dirty
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          {showVehicleSelect && (
            <Section>
              <SectionTitle divider>Данные ТС</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          filter={{
                            ownerType: ownerTypes.self
                          }}
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
          )}
          <Section>
            <SectionTitle divider>Данные по КАСКО</SectionTitle>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Серия и номер КАСКО"
                    name="kaskoNumber"
                    required
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата начала действия КАСКО"
                    fast
                    name="startDate"
                    required
                    validate={value =>
                      values.endDate &&
                      moment(value).isAfter(values.endDate) &&
                      'Дата начала действия КАСКО не может быть позже даты окончания'
                    }
                  >
                    {({ value, name }) => (
                      <DatePicker
                        value={value ? moment(value) : undefined}
                        format={'DD.MM.YYYY'}
                        onChange={(value, dateString) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата окончания действия КАСКО"
                    fast
                    name="endDate"
                    required
                    validate={value =>
                      values.startDate &&
                      moment(value).isBefore(values.startDate) &&
                      'Дата окончания действия КАСКО не может быть раньше даты начала'
                    }
                  >
                    {({ value, name }) => (
                      <DatePicker
                        value={value ? moment(value) : undefined}
                        format={'DD.MM.YYYY'}
                        onChange={(value, dateString) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    required
                    label="Наименование страховщика"
                    name="contractorId"
                  >
                    {({ name, value }) => (
                      <ContractorSelect
                        name={name}
                        value={value}
                        onChange={(contractorId: number) =>
                          setFieldValue(name, contractorId)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  kaskoId: ?number,
  vehicleId: ?number
};

type State = {
  kasko: ?$Shape<Kasko>
};

class KaskoForm extends Component<Props, State> {
  state = {
    kasko: null
  };

  async componentDidMount() {
    try {
      const kaskoId = parseInt(this.props.kaskoId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (kaskoId) {
        const kasko = await kaskoApi.get(kaskoId);
        this.setState({
          kasko
        });
      } else if (vehicleId) {
        this.setState({
          kasko: {
            vehicleId
          }
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.kaskoId) => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/insurance`);
    }
  };

  onSubmit = async (kasko: Kasko) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const kaskoId = parseInt(this.props.kaskoId, 10);
      if (kaskoId) {
        await kaskoApi.update(kasko);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(kaskoId);
      } else {
        const added = await kaskoApi.add(kasko);

        notification.success({
          message: 'Успешное добавление',
          description: `КАСКО успешно добавлено`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const kaskoId = parseInt(this.props.kaskoId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { kasko } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              {vehicleId > 0 && <Crumb to={`/vehicles/${vehicleId}`}>ТС</Crumb>}
              {kaskoId ? (
                <Crumb>Редактирование КАСКО</Crumb>
              ) : (
                <Crumb>Новый полис КАСКО</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {kaskoId
              ? `КАСКО ${(kasko ? kasko.kaskoNumber : '') || ''}`
              : 'Новый полис КАСКО'}
          </h1>
        </StyledPanel>
        <InnerForm
          kasko={{
            id: 0,
            kaskoNumber: '',
            ...kasko
          }}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect((kasko && kasko.id) || kaskoId)}
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default KaskoForm;
