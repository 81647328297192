import React from 'react';
import { Button } from 'antd';

import { pddTest } from '../../../../../lib/enum';
import { navigate } from '../../../../../lib/helpers';
import type { PddTestType } from '../../../../../lib/types';

import { ReloginButton } from './index';

type Props = {
  examTraining: PddTestType,
  canStartTest: boolean,
  canRelogin: boolean
};
export default ({ examTraining, canStartTest, canRelogin }: Props) => (
  <>
    {examTraining === pddTest.training && canStartTest && (
      <>
        <Button
          type="primary"
          onClick={() => navigate('/pdd/test/training/test-exam/')}
        >
          Тренировочное тестирование
        </Button>
      </>
    )}
    {examTraining === pddTest.exam && canStartTest && (
      <>
        <Button
          type="primary"
          onClick={() => navigate('/pdd/test/exam/exam/0')}
        >
          Начать экзамен
        </Button>
      </>
    )}
    {canRelogin && <ReloginButton />}
  </>
);
