import React from 'react';
import styled from 'styled-components';
import Form from 'antd/lib/form';

import { Icon } from '../../../../components/ui';
import type { OperationLimitType } from '../../../../lib/types';
import { GridItem } from '../../../../components/layout';
import {
  operationLimitTypeEnum,
  operationLimitTypes
} from '../../../../lib/enum';
import { Cell, ListHeader, HeaderBoldCell, HeaderCell } from '../../elements';

// Разметка таблицы лимитов
export const getGridTemplate = (type: OperationLimitType) => {
  switch (type) {
    case operationLimitTypeEnum.employee:
    case operationLimitTypeEnum.orgUnit:
      return [
        {
          size: '(max-width: 1399px)',
          gutter: 0,
          cols: ['300px', '1fr', '1fr', '1fr', '1fr', '50px']
        },
        {
          size: '(min-width: 1400px)',
          gutter: 0,
          cols: ['400px', '1fr', '1fr', '1fr', '1fr', '50px']
        }
      ];
    case operationLimitTypeEnum.ovb:
    default:
      return [
        {
          size: '(max-width: 1399px)',
          gutter: 0,
          cols: ['200px', '130px', '280px', '1fr', '1fr', '50px']
        },
        {
          size: '(min-width: 1400px)',
          gutter: 0,
          cols: ['300px', '160px', '380px', '1fr', '1fr', '50px']
        }
      ];
  }
};

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  width: 100%;
`;

export const DeleteIcon = styled(Icon).attrs(props => ({
  type: 'trash'
}))`
  cursor: pointer;
`;

export const DeleteCell = styled(Cell)`
  justify-content: center;
`;

export const ListTableHeader = ({ type }: { type: OperationLimitType }) => (
  <ListHeader media={getGridTemplate(type)} rows={2}>
    <GridItem
      cellUnion={{
        gridRow: 'span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>{operationLimitTypes[type]}</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: 'span 2'
      }}
    >
      <HeaderBoldCell>Время использования (часы)</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7',
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '2',
        gridRow: `2`
      }}
    >
      <HeaderCell>Будние дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{
        borderTop: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '3',
        gridRow: `2`
      }}
    >
      <HeaderCell>Выходные дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{}}
      cellUnion={{
        gridColumn: '4 / span 3'
      }}
    >
      <HeaderBoldCell>Пробег (км)</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderRight: '1px solid #c0ccd7',
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '4',
        gridRow: `2`
      }}
    >
      <HeaderCell>Будние дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: '5 / span 2',
        gridRow: `2`
      }}
    >
      <HeaderCell>Выходные дни</HeaderCell>
    </GridItem>
  </ListHeader>
);

export const ListTableOvbHeader = () => (
  <ListHeader media={getGridTemplate(operationLimitTypeEnum.ovb)}>
    <GridItem>
      <HeaderBoldCell>ОВБ</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Тип ОВБ</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Служба</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Время использования (часы)</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Пробег (км)</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const QuestionIcon = styled(Icon).attrs(() => ({
  type: 'question'
}))`
  cursor: help;
  margin-left: 8px;
`;

export const AddButton = styled(({ children, ...props }) => (
  <button {...props}>
    <Icon type="plus" style={{ marginRight: 8 }} />
    {children}
  </button>
))`
  background: none;
  border: none;
  color: #2770ff;
  font-size: 12px;
  outline: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
