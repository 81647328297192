// @flow
import React, { Component } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import moment from 'moment';

import { DatePicker } from '../../../components/ant/DatePicker';
import { Card } from './../../../components';
import { Popconfirm } from './../../../components/ui';
import { getDisabledEqipmentInstallRemoveDate } from './../../../containers/Equipment/lib';

const { Field } = Card;
const PopconfirmTitleWrapper = styled.div``;
const ErrorText = styled.p`
  color: red;
  margin: 5px 0;
`;

const getMomentValue = (value: ?moment) => (value ? moment.utc(value) : value);

const convertDateToString = (
  value: Object,
  dateString: string
): string | moment => {
  if (value) {
    return moment.utc(dateString, 'DD.MM.YYYY').toISOString();
  }
  return value;
};

type PopconfirmTitleProps = {
  onChange: (value: Object, dateString: string) => void,
  dateRemove?: string | moment,
  error?: string,
};

const PopconfirmTitle = ({
  onChange,
  dateRemove,
  error,
}: PopconfirmTitleProps) => (
  <PopconfirmTitleWrapper>
    <Field label="Дата снятия">
      <DatePicker
        format="DD.MM.YYYY"
        value={getMomentValue(dateRemove)}
        onChange={onChange}
        disabledDate={getDisabledEqipmentInstallRemoveDate}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Field>
    <p>Подтверждаете снятие оборудования?</p>
  </PopconfirmTitleWrapper>
);

type Props = {
  onConfirm: (removeDate: string | moment) => void | Promise<void>,
  children?: any,
};

type State = {
  removeDate: string | moment,
  visible: boolean,
  errors: Object,
};

const initialState = {
  removeDate: moment(),
  visible: false,
  errors: {},
};

export default class RemoveEquipmentButton extends Component<Props, State> {
  state = initialState;

  confirm = () => {
    if (moment(this.state.removeDate).isValid) {
      this.props.onConfirm(this.state.removeDate);
      this.setState(initialState);
    } else {
      this.setState({
        errors: {
          removeDate: 'Неверная дата снятия оборудования',
        },
      });
    }
  };

  showPopover = () =>
    this.setState({
      visible: true,
    });

  hidePopover = () => this.setState(initialState);

  render() {
    const { removeDate, visible, errors } = this.state;
    const { children } = this.props;
    return (
      <Popconfirm
        icon={null}
        title={
          <PopconfirmTitle
            onChange={(value: Object, dateString: string) =>
              this.setState({
                removeDate: convertDateToString(value, dateString),
              })
            }
            dateRemove={removeDate}
            error={errors.removeDate}
          />
        }
        visible={visible}
        okText="Да"
        placement="left"
        cancelText="Нет"
        onConfirm={this.confirm}
        onCancel={this.hidePopover}
        overlayClassName="without-icon"
      >
        <Button onClick={this.showPopover}>
          <>{children ? children : 'Снять'}</>
        </Button>
      </Popconfirm>
    );
  }
}
