// @flow
import React from 'react';
import { Input } from './elements';

type Props = {
  // Функция для изменения значения
  onChange: (value: ?string) => void,
  // Значение
  value: any,
  // Редактируемая ли ячейка
  editable?: boolean,
  // В фокусе ли ячейка
  focused?: boolean,
  // Функция, вызываемая при фокусе
  onFocus?: Function,
  // Стили ячейки
  style?: $Shape<CSSStyleDeclaration>,
  revertValue?: Function
};

/**
 * Компонент ячейки таблицы
 */
export class CellInput extends React.Component<Props> {
  render() {
    const { editable, focused, onFocus, style, value = '' } = this.props;

    return editable ? (
      <Input
        focused={focused}
        css={style}
        value={value}
        onFocus={onFocus}
        onChange={({ target: { value } }) => this.props.onChange(value)}
      />
    ) : (
      value
    );
  }
}

export default CellInput;
