// @flow
import notification from 'antd/lib/notification';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import React, {
  type Node,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Option, Select } from '../ant/Select';

import { Spinner } from './../../components';
import { isNumberValue } from './../../lib/helpers';

type Props = SelectProps & {
  /**
   * Функция, для подгрузки данных списка.
   *
   * По-умолчанию, когда список только появился на странице,
   * подгружается 50 записей.
   */
  fetch: () => Promise<any>,
  /**
   * Функция для подгрузки одного значения.
   *
   * Используется для добавления с список значения,
   * id которого пришел в value и которое отсутствует
   * с списке подгруженных по-умолчанию.
   */
  fetchSingle: (id: number, params: any) => Promise<any>,
  /**
   * Имя ключа, для которого выбирается значение
   * @default id
   */
  valueKey: string,
  /**
   * Текст сообщения при отсутствии данных
   * @default Нет данных
   */
  notFoundText: string,
  /**
   * Функция для отображения компонента списка (Option)
   * @param item Элемент списка
   * @param Option Компонент списка
   */
  renderOption: (item: any, Option: Option) => Node,
  /**
   * Подгружать ли данные при фокусе на селекте
   * @default false
   */
  fetchOnFocus: boolean,
  /**
   * Пропсы, при изменении которых необходима переподгрузка данных
   */
  refetchParams?: any,
  /**
   * Функция вызываемая при изменении значения
   * @param value Значение по ключу valueKey
   * @param optionPayload Данные из data
   */
  onChange?: (value: any, optionPayload: any) => void,
};

/**
 * Ассинхронный селект с возможностью поиска.
 */
const AutocompleteSelect = (props: Props) => {
  let mounted = useRef(true); //контроль того, что компонент не удален во время асинхронной операции
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async (search: string) => {
    setIsLoading(true);
    // отправляем запрос с ограничением в 50 результатов
    const { data } = await props.fetch({
      search,
      page: 1,
      pageSize: 50,
    });
    setData(data);
    setIsLoading(false);
  };

  /**
   * Функция для подгрузки данных
   *
   * Если value не указан, то подгружаются первые 50 записей
   * иначе подгружается 50 + 1 запись, связанная с value
   *
   * @param value Текущее значение селекта, которое нужно подгрузить
   */
  const fetchData = useCallback(
    async (value?: number) => {
      try {
        const { disabled, fetchSingle, fetch } = props;
        if (!disabled && mounted.current) {
          if (mounted.current) setIsLoading(true);
          const { data } = await fetch({
            page: 1,
            pageSize: 50,
          });
          // Проп fetchSingle обязателен
          if (typeof fetchSingle !== 'function')
            throw new Error('Prop fetchSingle must be a function');
          // Если value - число
          if (typeof value === 'number') {
            // Запрашиваем запись
            const loadedValue = await fetchSingle(value, {
              returnDeleted: true,
            });
            const isValueLoaded = !isNil(loadedValue);
            // И добавляем эту запись, если она существует
            if (mounted.current)
              setData(
                isValueLoaded ? uniqBy([loadedValue, ...data], 'id') : data
              );
          } else {
            if (mounted.current) setData(data);
          }
          if (mounted.current) setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    },
    [props]
  );

  const handleFocus = async (e: any) => {
    const { onFocus, fetchOnFocus } = props;
    if (onFocus) {
      onFocus(e);
    }
    if (fetchOnFocus) {
      await fetchData();
    }
  };

  const handleChange = async (value: any, option: any) => {
    const { onChange } = props;
    if (value === undefined) await fetchData();
    if (onChange) {
      onChange(value, option);
    }
  };

  const handleSearch = debounce(fetch, 500);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.disabled) {
      fetchData(props.value);
    }
    // вернуть props.value если что-то не так
    // eslint-disable-next-line
  }, [props.refetchParams]);

  const {
    notFoundText = 'Не найдено',
    renderOption,
    value,
    refetchParams,
    fetchSingle,
    fetchOnFocus,
    valueKey,
    fetch: ss,
    ...selectProps
  } = props;

  const val = value
    ? isNumberValue(value)
      ? parseFloat(value)
      : value
    : value;
  return (
    <Select
      showSearch
      onSearch={handleSearch}
      filterOption={false}
      defaultActiveFirstOption={false}
      notFoundContent={<Spinner isLoading={isLoading}>{notFoundText}</Spinner>}
      defaultValue={null}
      loading={isLoading}
      allowClear
      {...selectProps}
      value={selectProps.mode === 'multiple' && val === undefined ? [] : val}
      onFocus={handleFocus}
      onChange={handleChange}
    >
      {isLoading
        ? null
        : data?.map((item) => renderOption(item, Option)) ?? null}
    </Select>
  );
};

AutocompleteSelect.defaultProps = {
  notFoundText: 'Не найдено',
  fetchOnFocus: false,
  valueKey: 'id',
  refetchParams: {},
};

export default AutocompleteSelect;
