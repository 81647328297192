//@flow
import React, { useState, useEffect, useContext } from 'react';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import {
  EllipsisOutlined,
  DownOutlined,
  CaretDownOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { Table } from '../../../components/ant/table/Table';
import {
  Popconfirm,
  Icon,
  ListTable,
  Operations,
  Dropdown,
} from '../../../components/ui';
import { formatLicensePlateMask } from './../../../components/inputs/masked-inputs/LicensePlateInput';
import { notificationLoading } from '../../../components/Notifications';

import {
  bioTreatmentFacilityTalonStatus,
  accessTypeEnum,
  bioTreatmentFacilityTalonStatusEnum,
  bioTreatmentFacilityFactStatusEnum,
} from '../../../lib/enum';
import { bioTreatmentFacilityFactApi } from '../../../lib/api';
import type {
  BioTreatmentFacilityVolume,
  BioTreatmentFacilityContract,
  BioTreatmentFacilityTalon,
  BioTreatmentFacilityFact,
} from '../../../lib/types';
import {
  applyMaskToValue,
  formatDateTimeToString,
  formatRub,
} from '../../../lib/helpers';

import ModalBioTreatmentFacilityFact from './ModalBioTreatmentFacilityFact';
import ModalImage from '../../BioTreatmentFacilityFact/components/ModalImage';

import { ContractContext } from '../Card';

export const INIT_MODAL_FACT = {
  show: false,
  bioTreatmentFacilityFact: null,
  licensePlates: [],
  volumeId: null,
  talonId: null,
};
type Props = {
  talon: BioTreatmentFacilityTalon,
  contract: BioTreatmentFacilityContract,
  canEditFact: boolean,
};
export default (props: Props) => {
  const [state, setState] = useState({
    data: [],
    loading: false,
  });

  const context = useContext(ContractContext);

  const handleState = (field: string, value: any) => {
    setState((oldState) => {
      return {
        ...oldState,
        [field]: value,
      };
    });
  };

  const fetch = async (bioTreatmentFacilityTalonId: number) => {
    handleState('loading', true);
    try {
      const { data } = await bioTreatmentFacilityFactApi.list({
        bioTreatmentFacilityTalonId,
        pageSize: undefined,
        page: undefined,
      });
      handleState('data', data);
    } catch (error) {
    } finally {
      handleState('loading', false);
    }
  };

  useEffect(() => {
    if (props?.talon?.id) {
      fetch(props?.talon?.id);
    }
  }, []);

  useEffect(() => {
    if (context?.modalFact?.talonId === Number(props?.talon?.id)) {
      fetch(props?.talon?.id);
    }
  }, [context?.modalFact?.talonId]);

  return (
    <div style={{ margin: '-16px -16px 16px -16px' }}>
      <div style={{ fontSize: 16, padding: '16px' }}>История посещений</div>
      <Table
        pagination={false}
        loading={state.loading}
        onRow={(bioTreatmentFacilityFact: BioTreatmentFacilityFact) => ({
          onClick: () => {
            props.canEditFact &&
              context.handleModalFact({
                ...INIT_MODAL_FACT,
                show: true,
                volumeId: null,
                licensePlates: bioTreatmentFacilityFact.licensePlates,
                bioTreatmentFacilityFact,
              });
          },
        })}
        columns={[
          {
            title: '№',
            width: '100px',
            key: 'id',
            dataIndex: 'id',
          },
          {
            title: 'Гос.номер',
            key: 'licensePlate',
            dataIndex: 'licensePlate',
            render: (licensePlate) =>
              applyMaskToValue(licensePlate, formatLicensePlateMask),
          },
          {
            title: 'Дата посещения',
            key: 'date',
            dataIndex: 'date',
            render: (date: string) =>
              formatDateTimeToString(date, 'DD.MM.YYYY HH:mm'),
          },
          {
            title: 'Снимок',
            key: 'path',
            dataIndex: 'path',
            stopPropagation: true,
            onCell: () => ({
              onClick: (e) => {
                e.stopPropagation();
              },
            }),
            render: (imageUrl) => {
              return imageUrl ? (
                <ModalImage
                  files={imageUrl ? [{ imageUrl, title: 'Снимок' }] : []}
                />
              ) : (
                '-'
              );
            },
          },
        ]}
        dataSource={
          state.data?.map((fact) => ({
            ...fact,
            licensePlates: props.talon?.licensePlates ?? [],
          })) ?? []
        }
      />
    </div>
  );
};
