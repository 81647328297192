// @flow
import styled from 'styled-components';

import { Panel } from './../../components/layout';

export const CardPanel = styled(Panel)`
  display: flex;
  padding-top: 0;
  justify-content: space-between;
`;

export const CarInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const CarInfo = styled.div`
  display: flex;
  flex-direction: column;
  & > h1 {
    margin-bottom: 12px;
  }
`;

export const SectionContent = styled.div`
  padding: 16px;
`;

export const Operations = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Image = styled.img`
  object-fit: cover;
  max-width: 212px;
  width: 100%;
  height: auto;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
