// @flow
import React, { Component } from 'react';
import notification from 'antd/lib/notification';
import qs from 'query-string';

import { COLUMNS, MONTH } from '../lib';
import {
  maintenanceOperationContractCalculationApi,
  vehiclePlanApi
} from '../../../../lib/api';
import type {
  ListState,
  MaintenanceOperationContractCalculation,
  VehiclePlan
} from '../../../../lib/types';
import {
  getListInitialState,
  plus,
  toLocalStringRu
} from '../../../../lib/helpers';
import { Section } from '../../../../components/layout';
import { AntTable } from '../../../../components/ui';
import type { ParamsFilter } from './components/Filter';
import Filter from './components/Filter';
import { headerPanel } from '../components/Common';
import { TableHeader } from '../../../../components/ui/Table';
import { notificationLoading } from '../../../../components/Notifications';
import { calculationStatusEnum } from '../../../../lib/enum';
import Button from 'antd/lib/button';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = ListState<MaintenanceOperationContractCalculation> & {
  columns: any,
  visible: boolean,
  maintenanceOperationContractCalculation: ?MaintenanceOperationContractCalculation,
  filter: ParamsFilter,
  vehiclePlan: ?VehiclePlan
};

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    columns: [
      ...COLUMNS,
      ...MONTH.map((month, index) => ({
        title: month.title,
        children: [
          {
            title: (
              <TableHeader>
                Трудоёмкость одного технического обслуживания
              </TableHeader>
            ),
            dataIndex: `months[${index}].workEffort`,
            width: '190px',
            render: workEffort => toLocalStringRu(workEffort)
          },
          {
            title: (
              <TableHeader>
                Стоимость материалов в месяц, руб. без НДС
              </TableHeader>
            ),
            dataIndex: `months[${index}].materialSum`,
            width: '180px',
            render: materialSum => toLocalStringRu(materialSum)
          },
          {
            title: <TableHeader width="100px">Итого, руб. без НДС</TableHeader>,
            dataIndex: `months[${index}].cost`,
            width: '120px',
            render: cost => toLocalStringRu(cost)
          }
        ]
      })),
      {
        title: 'Трудоёмкость ТО итого',
        dataIndex: `workEffort`,
        width: '180px',
        render: workEffort => toLocalStringRu(workEffort)
      },
      {
        title: (
          <TableHeader>
            Сумма в год на материалы подрядом, руб. без НДС
          </TableHeader>
        ),
        dataIndex: `materialSum`,
        width: '180px',
        render: materialSum => toLocalStringRu(materialSum)
      },
      {
        title: (
          <TableHeader width="200px">
            Затраты за услуги выполнения работы подрядным способом без запасных
            частей и материалов, руб. без НДС
          </TableHeader>
        ),
        dataIndex: `materialLessSum`,
        width: '220px',
        render: materialLessSum => toLocalStringRu(materialLessSum)
      },
      {
        title: (
          <TableHeader>Всего планируемые затраты, руб. без НДС</TableHeader>
        ),
        dataIndex: `sum`,
        width: '200px',
        render: sum => toLocalStringRu(sum)
      }
    ],
    filter: {}
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, this.getData);
  }

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  fetch = async () => {
    const { vehiclePlanId } = this.props;

    const itogCalculation = (data: any) => {
      const itog = {
        months: Array(12)
          .fill()
          .map(() => ({ cost: 0 })),
        sum: 0
      };
      data.forEach((item: any) => {
        itog.sum = plus(item.sum || 0, itog.sum);
        item.months.forEach((month: any, index) => {
          itog.months[index].cost = plus(
            itog.months[index].cost,
            month.cost || 0
          );
        });
      });
      return itog;
    };

    try {
      let { data } = await maintenanceOperationContractCalculationApi.fetch({
        ...this.state.filter,
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      if (data.length) {
        data = [...data, itogCalculation(data)];
      }

      this.setState({ data });
    } catch (e) {
      notification.error({ message: 'Не удалось запросить данные' });
    }
  };

  applyFilter = (filter: any) => {
    this.setState({ filter }, async () => {
      await this.fetch();
    });
  };

  cleanFilter = async () => {
    this.setState({ filter: {} }, async () => {
      await this.fetch();
    });
  };

  changeStatus = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await maintenanceOperationContractCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.calculationDone
      );
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await maintenanceOperationContractCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({ message: 'Не удалось обновить данные данные' });
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.maintenanceOperationContractCalculationStatus ===
    calculationStatusEnum.draft;

  render() {
    const { vehiclePlanId } = this.props;
    const { columns, loading, data } = this.state;
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          title: 'Техобслуживание',
          passTab: true
        })}
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={this.state.filter}
          />
          <AntTable
            loading={loading}
            data={[...data]}
            columns={columns}
            bordered
            scroll={{
              x: 6000,
              y: 'calc(100vh - 400px)'
            }}
          />
        </Section>
        {this.isDraft() && (
          <Section style={{ padding: '16px' }}>
            <Button type="primary" onClick={this.calculate}>
              Рассчитать
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              type="primary"
              onClick={this.changeStatus}
            >
              Утвердить
            </Button>
          </Section>
        )}
      </>
    );
  }
}
