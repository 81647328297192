import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;
export const Content = styled.div`
  display: flex;
  width: 100%;
`;
export const MapWrapper = styled.div`
  width: calc(100% - 330px);
  position: relative;
`;
export const Sidebar = styled.div`
  width: 40px;
  background: #3d4042;
  box-sizing: border-box;
  padding: 8px;
`;
export const BackButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  border-radius: 4px;
  background: white;
  cursor: pointer;
`;
export const Frame = styled.iframe`
  height: 100vh;
  width: 100%;
`;
export const FrameContainer = styled.div`
  position: relative;
  width: 100%;
`;
