// @flow

import React from 'react';
import { Router } from '@reach/router';

import { ExternalActList, ExternalActForm, ExternalActCard } from './external';
import {
  InternalActList,
  InternalActForm,
  MpzActList,
  MpzActForm,
} from './internal';

export { ActInfo } from './external';
export { default as Tabs } from './components/Tabs';
export type { ActFilterParams } from './external/components';

export default () => (
  <>
    <Router>
      <ExternalActList path="/" />
      <ExternalActForm path="/new" />
      <ExternalActForm path="/:actId/edit" />
      <ExternalActCard path="/:actId" />

      <MpzActList path="/internal/mpz" />
      <MpzActForm path="/internal/mpz/new/:action" />
      <InternalActList path="/internal" />
      <InternalActList path="/internal/:actType" />
      <InternalActForm
        path="/internal/outsourcing-act/new"
        type="outsourcing-act"
      />
      <InternalActForm
        path="/internal/outsourcing-act/:id/edit"
        type="outsourcing-act"
      />
      <InternalActForm path="/internal/oils-act/new" type="oils-act" />
      <InternalActForm
        path="/internal/fuels-and-oils-act/new"
        type="fuels-and-oils-act"
      />
      <InternalActForm
        path="/internal/fuels-and-oils-act/:id/edit"
        type="fuels-and-oils-act"
      />
    </Router>
  </>
);
