// @flow
import Button from 'antd/lib/button';

import Input from 'antd/lib/input';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';

import type { FormFieldType } from '../../../../components/Form';
import { StyledInputNumber } from '../../../../components/hoc/common/components/elements';

import type { OutsourcingAct } from './../../../../lib/types';
import { tripStatusEnum } from '../../../../lib/enum';
import CancelButton from './../../../../components/CancelButton';
import Form from './../../../../components/Form';
import { Section } from './../../../../components/layout';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import Selects from './../../../../components/selects';

const { RateTaxesSelect, TripSelect, CompanySelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  onCancel: Function,
  onSubmit: Function,
  outsourcingAct: OutsourcingAct,
};

export default ({ onCancel, onSubmit, outsourcingAct = {} }: Props) => {
  return (
    <Form initialValues={outsourcingAct} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting,
        }: FormikProps
      ) => {
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <Grid gutter="16px" cols={2}>
                <GridItem>
                  <FormField
                    label="Номер документа"
                    name="documentNumber"
                    required
                  >
                    {({ name, value }) => {
                      return (
                        <Input
                          placeholder="Введите номер"
                          name={name}
                          value={value}
                          onChange={(e) => {
                            setFieldValue(name, e.target.value);
                          }}
                        />
                      );
                    }}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="documentDate"
                    label="Дата составления акта"
                    required
                  >
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="tripId" label="Выберите путевой" required>
                    {({ name, value }) => (
                      <TripSelect
                        filter={{
                          isArchive: true,
                          status: tripStatusEnum.verified,
                          toOtherSide: true,
                        }}
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="workHoursPrice"
                    label="Стоимость 1 маш/час (без  НДС)"
                    required
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        value={value}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    name="workHours"
                    label="Кол-во отработанных часов"
                    required
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        value={value}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="tax" label="Ставка НДС" required>
                    {({ name, value }) => (
                      <RateTaxesSelect
                        value={value}
                        onChange={(value) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Номер договора"
                    name="contractNumber"
                    required
                  >
                    {({ name, value }) => (
                      <Input
                        name={name}
                        value={value}
                        onChange={(e) => setFieldValue(name, e.target.value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата договора" name="contractDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment.utc(value).startOf('day').toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Контрагент" name="companyId" required>
                    {({ name, value }) => (
                      <CompanySelect
                        name={name}
                        value={value}
                        onChange={(companyId: number) =>
                          setFieldValue(name, companyId)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </StyledSection>

            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {values.id ? 'Сохранить' : 'Создать'}
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
