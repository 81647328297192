// @flow
import { fetchRequest, initialFetchListParams } from '.';
import type { FetchListParams, ListResponse } from './';
import type { Stou } from '../types/stou';
import type { StouFilterParams } from '../../containers/Stou/Filter';

/**
 * Получение списка СТОУ
 */
export const fetchStouList = async (
  params: FetchListParams<StouFilterParams> = initialFetchListParams
): Promise<ListResponse<Stou>> =>
  await fetchRequest.get('/stou', {
    ...initialFetchListParams,
    ...params
  });

/**
 * Обновление СТОУ записи
 */
export const updateStou = async (stou: Stou): Promise<Stou> => {
  const updated = await fetchRequest.put('/stou', stou);
  if (updated) return updated;
  throw new Error('Не удалось обновить СТОУ запись');
};

/**
 * Добавление СТОУ записи
 */
export const addStou = async (stou: Stou): Promise<Stou> => {
  const added = await fetchRequest.post('/stou', stou);
  if (added) return added;
  throw new Error('Не удалось добавить СТОУ запись');
};

// Удалить навесное оборудование по id
export const deleteStou = async (id: number): Promise<Stou> =>
  await fetchRequest.delete(`/stou/${id}`);

/**
 * Получение СТОУ записи
 */
export const fetchStou = async (
  id: number,
  params: any = {}
): Promise<Stou> => {
  const stou = await fetchRequest.get(`/stou/${id}`, params);
  if (stou) return stou;
  throw new Error('Не удалось получить СТОУ запись');
};

export default {
  fetchStouList,
  updateStou,
  fetchStou,
  addStou,
  deleteStou
};
