// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import notification from 'antd/lib/notification';

import type { Tire } from './../../../lib/types';
import { Tires as TiresComponents } from './../components';
import { resetVehicleStatus, setVehicleTires } from './../../../ducks/vehicle';
import { tireApi } from './../../../lib/api';
import type { AppState } from '../../../ducks/redux';
import { notificationLoading } from './../../../components/Notifications';
import type { Vehicle } from '../../../lib/types';

const { TiresForm, EditableItem } = TiresComponents;

type Props = {
  items: Array<Tire>,
  resetVehicleStatus: (vehicleId: number) => void,
  setVehicleTires: (vehicleId: number) => void,
  vehicleId: number,
  vehicle: Vehicle,
  editable: boolean,
  canAddFuelMeasurement: boolean,
  employeeBranchOrgUnitId: number,
};

type State = {
  availableTires: Tire[],
  isLoading: boolean,
};

export class Tires extends Component<Props, State> {
  state = {
    availableTires: [],
    isLoading: false,
  };

  async componentDidMount() {
    await this.fetchAvailableTires();
  }

  fetchAvailableTires = async (search?: string) => {
    if (this.props.editable) {
      this.setState({
        isLoading: true,
      });
      let availableTires = [];
      const result = await tireApi.fetchTires({
        search,
        page: 1,
        pageSize: 50,
        isFree: true,
        orgUnitId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren',
        isArchive: false,
      });

      if (result) {
        availableTires = result.data;
      }
      this.setState({
        availableTires,
        isLoading: false,
      });
    }
  };

  onAdd = async (tire: Tire) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const updatedTire = await tireApi.updateTireVehicle({
        ...tire,
        vehicleId: this.props.vehicleId,
      });
      await tireApi.fillReplaceDate({
        ...updatedTire,
      });
      await this.props.resetVehicleStatus(this.props.vehicleId);
      await this.props.setVehicleTires(this.props.vehicleId);
      await this.fetchAvailableTires();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  onEdit = async (tire: Tire) => {
    await tireApi.updateTireVehicle(tire);
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleTires(this.props.vehicleId);
  };

  onRemove = async (tire: Tire) => {
    await tireApi.updateTire({ ...tire, vehicleId: null });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleTires(this.props.vehicleId);
    await this.fetchAvailableTires();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableTires(value),
    500
  );

  render() {
    const {
      items = [],
      editable,
      canAddFuelMeasurement,
      vehicle,
    }: Props = this.props;
    const { availableTires, isLoading } = this.state;
    return (
      <>
        {items.map((item: Tire) => {
          if (!item) {
            return null;
          }
          return (
            <EditableItem
              key={item.id}
              tire={item}
              vehicle={vehicle}
              onChange={this.onEdit}
              onRemove={editable ? this.onRemove : null}
              editable={false} // TODO: нужны требования, но пока не можем редактировать шины в инвентарной карточке ТС
              canAddFuelMeasurement={canAddFuelMeasurement}
            />
          );
        })}
        {editable && (
          <TiresForm
            onSubmit={this.onAdd}
            vehicle={vehicle}
            handleSearch={this.handleSearch}
            fetchData={this.fetchAvailableTires}
            isLoading={isLoading}
            availableTires={availableTires}
          />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  }),
  {
    resetVehicleStatus,
    setVehicleTires,
  }
)(Tires);
