import { InstructionList } from '../containers/Instructions';
import { accessTypeEnum } from '../lib/enum';
import type { UserAccess } from '../lib/types';

export default [
    {
        component: InstructionList,
        path: '/instructions',
        access: (Object.keys(accessTypeEnum): UserAccess[]),
      },
];