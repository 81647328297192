// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { VehicleTariff, VehicleTariffType } from '../../../lib/types';
import { vehicleTariffApi } from '../../../lib/api';
import { goBack } from '../../../lib/helpers';

import { Crumb } from '../../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../../components/layout/Breadcrumbs';
import { Header, Panel } from '../../../components/layout';
import InnerFields from './InnerFields';
import { notificationLoading } from '../../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  vehicleTariffId: number,
  type: VehicleTariffType
};

type State = {
  vehicleTariff: ?VehicleTariff
};

export class VehicleTariffBrandForm extends React.Component<Props, State> {
  url: string;
  constructor(props: Props) {
    super(props);
    this.state = {
      vehicleTariff: null
    };
    this.url = `/admin/vehicle-tariff/${props.type}`;
  }

  async componentDidMount() {
    const { vehicleTariffId } = this.props;
    try {
      if (vehicleTariffId) {
        const vehicleTariff = await vehicleTariffApi.getVehicleTariff(
          vehicleTariffId
        );
        this.setState({ vehicleTariff });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: VehicleTariff) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });

      if (values.id) {
        await vehicleTariffApi.updateVehicleTariff(values);
      } else {
        await vehicleTariffApi.addVehicleTariff({
          ...values,
          vehicleTariffType: this.props.type
        });
      }
      navigate(this.url);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack(this.url);

  render() {
    const { vehicleTariff } = this.state;
    const { type } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={this.url}>Перечень тарифов на НТС</Crumb>
              {vehicleTariff ? (
                <Crumb>Тариф на НТС №{vehicleTariff.id}</Crumb>
              ) : (
                <Crumb>Новый тариф на НТС</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {vehicleTariff
              ? `Тариф на НТС №${vehicleTariff.id}`
              : 'Новый тариф на НТС'}
          </h1>
        </StyledPanel>
        <InnerFields
          vehicleTariffType={type}
          vehicleTariff={vehicleTariff}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default VehicleTariffBrandForm;
