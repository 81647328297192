// @flow
import type { FuelsAndOilsAct } from './../types';
import CommonApi from './../commonApi';
import {
  convertFromKopToRub,
  convertFromMToKm,
  convertFromSecondToHour
} from './../helpers';

export const convertDataFromServer = (fuelsAndOilsAct: FuelsAndOilsAct) => ({
  ...fuelsAndOilsAct,
  sum: convertFromKopToRub(fuelsAndOilsAct.sum),
  kilometrage: convertFromMToKm(fuelsAndOilsAct.kilometrage),
  workHours: convertFromSecondToHour(fuelsAndOilsAct.workHours),
  equipmentWorkHours: convertFromSecondToHour(
    fuelsAndOilsAct.equipmentWorkHours
  )
});

const fuelsAndOilsAct = new CommonApi<FuelsAndOilsAct>(
  'fuelsAndOilsAct',
  'акт списания ГСМ',
  undefined,
  convertDataFromServer
);

export default {
  deleteFuelsAndOilsAct: fuelsAndOilsAct.delete,
  addFuelsAndOilsAct: fuelsAndOilsAct.add,
  updateFuelsAndOilsAct: fuelsAndOilsAct.update,
  getFuelsAndOilsAct: fuelsAndOilsAct.get,
  fetchFuelsAndOilsAct: fuelsAndOilsAct.fetch
};
