// @flow
import Button from 'antd/lib/button';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../../components/ant/DatePicker';
import type { FormFieldType } from '../../../../components/Form';
import { StyledInputNumber } from '../../../../components/hoc/common/components/elements';

import type { FuelPrice } from '../../../../lib/types';

import { Form } from './../../../../components';
import CancelButton from './../../../../components/CancelButton';
import { Section } from './../../../../components/layout';
import Grid, { GridItem } from './../../../../components/layout/Grid';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  fuelPrice: ?FuelPrice,
  onCancel: Function,
  onSubmit: Function
};

export default ({ fuelPrice, onCancel, onSubmit }: Props) => {
  return (
    <Form initialValues={fuelPrice} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px" cols={3}>
              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. АИ-80, руб. без НДС"
                      fast
                      required
                      name="ai80Price"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. АИ-92, руб. без НДС"
                      fast
                      required
                      name="ai92Price"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. АИ-95, руб. без НДС"
                      fast
                      required
                      name="ai95Price"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. ДТ, руб. без НДС"
                      fast
                      required
                      name="dieselFuelPrice"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. Моторное масло, руб. без НДС"
                      fast
                      required
                      name="motorOilPrice"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Норма расхода Моторного масла на 100 л топлива"
                      fast
                      required
                      name="motorOilConsumptionRate"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. Трансмиссионное масло, руб. без НДС"
                      fast
                      required
                      name="transmissionOilPrice"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Норма расхода Трансмиссионного масла на 100 л топлива"
                      fast
                      required
                      name="transmissionOilConsumptionRate"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 кг. Пластичные смазки, руб. без НДС"
                      fast
                      required
                      name="autoGreasePrice"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Норма расхода Пластичной смазки на 100 л топлива"
                      fast
                      required
                      name="autoGreaseConsumptionRate"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Цена 1 л. Специальные масла и жидкости, руб. без НДС"
                      fast
                      required
                      name="specialOilPrice"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Норма расхода Специальные масла и жидкости на 100 л топлива"
                      fast
                      required
                      name="specialOilConsumptionRate"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дата начала действия"
                  name="startDate"
                  required
                >
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дата окончания действия"
                  name="endDate"
                  required
                >
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.startDate}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {values.id ? 'Сохранить' : 'Создать'}
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
};
