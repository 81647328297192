import React, { useState } from 'react';
import styled from 'styled-components';

import type { FileInfo } from './../../../../lib/types';

import ModalSlider from './../../../components/ui/ModalSlider';

const StyledSpan = styled('span')`
  color: #2770ff;
  font-weight: 500;
  cursor: pointer;
`;

type Props = {
  files: FileInfo[],
};

const ModalImage = ({ noStyle, files, vehicleIssueId }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const style = {
    fontSize: '13px',
    color: '#2770ff',
    top: '8px',
    left: '5px',
    position: 'relative',
    cursor: 'pointer',
  };
  return (
    <>
      {showModal && (
        <ModalSlider
          show={showModal}
          handleModalVisible={setShowModal}
          items={files}
          disableDownload
        />
      )}
      <StyledSpan onClick={() => setShowModal(true)}>Просмотр</StyledSpan>
    </>
  );
};

export default ModalImage;
