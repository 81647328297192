// @flow
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';

import InputNumber from 'antd/lib/input-number';
import Popover from 'antd/lib/popover';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AntTable } from './../../../../../components/ui';
import {
  selfVehiclePlanVehicleTypeEnum,
  vehicleStatuses,
  vehicleTypes,
} from './../../../../../lib/enum';
import { formatDate, toFixed } from './../../../../../lib/helpers';

import type {
  SelfVehiclePlanVehicle,
  VehicleModel,
  VehicleType,
} from './../../../../../lib/types';

import Filter from './../../components/FilterVehicleList';

import { MONTH } from './../../lib';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const LicensePlate = styled.div`
  flex: 1;
`;
const IconWarn = styled(ExclamationCircleOutlined)`
  font-size: 13px;
  position: relative;
  margin-left: 10px;
  top: 4px;
`;

type Props = {
  data: SelfVehiclePlanVehicle[],
  loading: boolean,
  readonly: boolean,
  handleSubmit: Function,
};

export default (props: Props) => {
  const { data, loading, readonly } = props;
  const [touched, setTouched] = useState(false);
  const [localData, setData] = useState([]);
  const [filter, setFilter] = useState({});

  const onChange = (
    field: 'kilometrage' | 'workHours',
    selfVehiclePlanVehicleId: number,
    monthIndex: number,
    value: number
  ) => {
    const rowIndex = localData.findIndex((selfVehiclePlanVehicle) => {
      return selfVehiclePlanVehicle.id === selfVehiclePlanVehicleId;
    });
    if (rowIndex !== -1 && localData[rowIndex].months[monthIndex]) {
      localData[rowIndex].months[monthIndex][field] = value;
      setData(cloneDeep(localData));
    }
  };

  const columns = [
    {
      title: 'Тип ТС',
      width: 150,
      fixed: 'left',
      dataIndex: 'vehicle.vehicleModel.type'.split('.'),
      render: (type: VehicleType) => vehicleTypes[type],
    },
    {
      title: 'Maрка и модель',
      fixed: 'left',
      width: 200,
      dataIndex: 'vehicle.vehicleModel'.split('.'),
      render: (record: VehicleModel) => `${record.brandName} ${record.name}`,
    },
    {
      title: 'Госномер',
      fixed: 'left',
      width: 125,
      dataIndex: 'vehicle.licensePlate'.split('.'),
      render: (licensePlate: string, record: SelfVehiclePlanVehicle) => (
        <Container>
          <LicensePlate>{licensePlate}</LicensePlate>
          {record.plannedWriteoffDate && (
            <Popover
              content={
                <>
                  Планируемая дата списания:{' '}
                  {formatDate(record.plannedWriteoffDate)}
                </>
              }
              placement="right"
            >
              <IconWarn twoToneColor="#faad14" />
            </Popover>
          )}
        </Container>
      ),
    },
    {
      title: 'Год выпуска',
      width: 100,
      dataIndex: 'vehicle.yearIssued'.split('.'),
    },
    {
      title: 'Текущий статус',
      width: 120,
      dataIndex: 'vehicle.status'.split('.'),
      render: (status) => vehicleStatuses[status],
    },
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      children: [
        {
          title: 'Километраж',
          dataIndex: ['months', monthIndex, 'kilometrage'],
          render: (
            kilometrage: number,
            selfVehiclePlanVehicle: SelfVehiclePlanVehicle
          ) => {
            const canShow = [
              selfVehiclePlanVehicleTypeEnum.all,
              selfVehiclePlanVehicleTypeEnum.kilometrage,
            ].includes(selfVehiclePlanVehicle.type);
            if (readonly) {
              return canShow ? kilometrage : '-';
            }
            return canShow ? (
              <InputNumber
                size="small"
                min={0}
                value={toFixed(kilometrage)}
                onChange={(kilometrage) => {
                  setTouched(true);
                  onChange(
                    'kilometrage',
                    selfVehiclePlanVehicle.id,
                    monthIndex,
                    +kilometrage || 0
                  );
                }}
              />
            ) : (
              '-'
            );
          },
        },
        {
          title: 'Моточасы',
          dataIndex: ['months', monthIndex, 'workHours'],
          render: (
            workHours: number,
            selfVehiclePlanVehicle: SelfVehiclePlanVehicle
          ) => {
            const canShow = [
              selfVehiclePlanVehicleTypeEnum.all,
              selfVehiclePlanVehicleTypeEnum.workHours,
            ].includes(selfVehiclePlanVehicle.type);
            if (readonly) {
              return canShow ? workHours : '-';
            }
            return canShow ? (
              <InputNumber
                size="small"
                min={0}
                value={toFixed(workHours)}
                onChange={(workHours) => {
                  setTouched(true);
                  onChange(
                    'workHours',
                    selfVehiclePlanVehicle.id,
                    monthIndex,
                    +workHours || 0
                  );
                }}
              />
            ) : (
              '-'
            );
          },
        },
      ],
    })),
  ];

  const handleSubmit = () => {
    setTouched(false);
    props.handleSubmit(localData);
  };

  const handleCancel = () => {
    setTouched(false);
    setData(cloneDeep(data));
  };

  const applyFilter = (filter) => setFilter(filter);

  const cleanFilter = () => setFilter({});

  useEffect(() => {
    setData(cloneDeep(data));
  }, [data]);

  return (
    <>
      <Filter
        filter={filter}
        showHideEmpty={false}
        vehicleType={true}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      />
      <AntTable
        columns={columns}
        data={localData
          .filter((selfVehicle) =>
            filter.vehicleId ? filter.vehicleId === selfVehicle.vehicleId : true
          )
          .filter((selfVehicle) =>
            filter.type
              ? filter.type === selfVehicle.vehicle.vehicleModel.type
              : true
          )
          .filter((selfVehicle) =>
            filter.vehicleModelId
              ? filter.vehicleModelId === selfVehicle.vehicle.vehicleModelId
              : true
          )
          .filter((selfVehicle) =>
            filter.yearIssued
              ? filter.yearIssued === selfVehicle.vehicle.yearIssued
              : true
          )}
        loading={loading}
        scroll={{
          x: '4500px',
        }}
        footer={
          touched &&
          (() => (
            <>
              <Button type="primary" size="small" onClick={handleSubmit}>
                Сохранить
              </Button>
              <Button
                style={{ marginLeft: '10px' }}
                size="small"
                onClick={handleCancel}
              >
                Отмена
              </Button>
            </>
          ))
        }
      />
    </>
  );
};
