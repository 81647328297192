//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../components';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import WashingVehicleTypeSelect from '../../../components/selects/WashingVehicleTypeSelect';

export type WashingFrequencyParamsFilter = {
  vehicleTypeId: number
};

export const WashingFrequencyFilter = (
  props: FilterProps<WashingFrequencyParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<WashingFrequencyParamsFilter>>) =>
      CommonFilter<WashingFrequencyParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <WashingVehicleTypeSelect
                size="small"
                value={values.orgUnitId}
                onChange={value => changeValue('vehicleType.id', value)}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default WashingFrequencyFilter;
