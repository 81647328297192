// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { RangePicker } from '../../../../../components/ui';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../../../components';
import Field from './../../../../../components/card/Field';
import type { FilterChildrenParams } from './../../../../../components/hoc/Filter';
import { formatDateTimeToISOString } from './../../../../../lib/helpers';

import type { EntityStatusType } from './../../../../../lib/types';

const { EntityStatusSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type WorkPlansFilterParams = {
  status?: EntityStatusType,
  startDate?: string,
  endDate?: string,
  branchId?: number,
  orgUnitId?: number,
  contractorId?: number,
};

type Props = {
  filter: WorkPlansFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams<WorkPlansFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  onlyBranches
                  size="small"
                  placeholder="Подразделение"
                  onChange={(value) => changeValue('orgUnitId', value)}
                  value={values.orgUnitId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  startDate={
                    values.startDate ? moment.utc(values.startDate) : null
                  }
                  endDate={values.endDate ? moment.utc(values.endDate) : null}
                  onChange={changeValue}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={(value) => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);
