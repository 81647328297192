//@flow
import React from 'react';
import styled from 'styled-components';
import Spin from 'antd/lib/spin';

/*
  Эта штука открывает iframe с адвантумом, где трафик идет через наш сервер
*/

// $FlowFixMe
export const proxyUrl = `${process.env.REACT_APP_API_URL}/api/monitoring/`; // слэш в конце обязательно

type Props = {};

type State = {
  isLoading: boolean,
};

class AdvantumHack extends React.Component<Props, State> {
  iframeRef = undefined;

  constructor(props: Props) {
    super(props);
    this.iframeRef = React.createRef<{}>();

    this.state = {
      isLoading: true,
    };
  }
  componentDidMount() {
    window.addEventListener('message', this.onMessage);
  }
  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }
  onMessage = () => {
    this.setState({ isLoading: false });
  };
  render() {
    return (
      <Container>
        {this.state.isLoading ? (
          <Overlay>
            <Spin size="large" />
          </Overlay>
        ) : null}
        <Frame id="monitoring-frame" src={proxyUrl} ref={this.iframeRef} />
      </Container>
    );
  }
}

const Frame = styled.iframe`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;

const Overlay = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`;

const Container = styled.div`
  position: relative;
`;

export default AdvantumHack;
