// @flow
import queryString from 'query-string';
import type { Platon, ExecutionBudgetGroupedList } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { PlatonFilterParams } from '../../containers/services/platon/Filter';

export const convertDataFromServer = (platon: Platon) => ({
  ...platon,
  paymentAmount: convertFromKopToRub(platon.paymentAmount),
  act: platon.act ? actConvertDataFromServer(platon.act) : undefined
});

export const convertDataToServer = (platon: Platon) => ({
  ...platon,
  paymentAmount: convertFromRubToKop(platon.paymentAmount)
});

export const addPlaton = async (platon: Platon): Promise<Platon> => {
  const added = await fetchRequest.post('/platon', convertDataToServer(platon));
  if (added) return added;
  throw new Error('Не удалось создать Платон');
};

export const updatePlaton = async (platon: Platon): Promise<Platon> => {
  const updated = await fetchRequest.put(
    '/platon',
    convertDataToServer(platon)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить Платон');
};
export const deletePlaton = async (id: number): Promise<Platon> => {
  const deleted = await fetchRequest.delete(`/platon/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить Платон');
};

export const fetchPlaton = async (
  id: number,
  params: any = {}
): Promise<Platon> => {
  const platon = await fetchRequest.get(`/platon/${id}`, params);
  if (platon) return convertDataFromServer(platon);
  throw new Error('Не удалось получить Платон');
};

export const fetchVehiclePlatons = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Platon>> => {
  const platons = await fetchRequest.get(`/platon/vehicle/${vehicleId}`, {
    ...initialFetchListParams,
    ...params
  });
  if (platons) {
    return {
      ...platons,
      data: platons.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список Платонов');
};

export const fetchPlatons = async (
  params: FetchListParams<Platon> = initialFetchListParams
): Promise<ListResponse<Platon>> => {
  const platon = await fetchRequest.get('/platon', {
    ...initialFetchListParams,
    ...params
  });
  if (platon) {
    return {
      ...platon,
      data: platon.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список Платонов');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<Platon>> => {
  const platon = await fetchRequest.put(
    `/platon/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (platon) {
    return {
      ...platon,
      data: platon.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за списоком');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/platon/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(`/platon/groupedList`, params);
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const printReport = async (
  params: PlatonFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/platon/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  groupedList,
  getTotalSum,
  updateAct,
  addPlaton,
  updatePlaton,
  deletePlaton,
  fetchPlaton,
  fetchVehiclePlatons,
  fetchPlatons,
  printReport
};
