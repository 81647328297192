// @flow

import { Dispatch } from 'redux';

import type { Role } from '../lib/types';
import { roleApi } from './../lib/api';
import type { Action, AppState } from './redux';

const SET_ROLE = '/vehicles/role/set';
const CLEAN_ROLE = '/vehicles/role/clean';

export type RoleState = ?Role;

const initialState: RoleState = null;

const reducer = (
  state: RoleState = initialState,
  { type, payload }: Action
) => {
  switch (type) {
    case SET_ROLE:
      return { ...payload };
    case CLEAN_ROLE:
      return null;
    default:
      return state;
  }
};

export const addRole = (role: Role) => async (dispatch: Dispatch) => {
  const payload = await roleApi.addRole(role);
  dispatch({
    type: SET_ROLE,
    payload
  });
};

export const updateRole = (role: Role) => async (
  dispatch: Dispatch,
  getState: () => AppState
) => await roleApi.updateRole(role);

// Удаление роли
export const deleteRole = (id: number) => async (dispatch: Dispatch) =>
  await roleApi.deleteRole(id);

// Подгрузка конкретной роли
export const fetchRole = (id: number) => async (dispatch: Dispatch) => {
  let role = await roleApi.fetchRole(id);
  dispatch({
    type: SET_ROLE,
    payload: {
      ...role
    }
  });
};

export const cleanRole = () => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_ROLE
  });
};

export default reducer;
