// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportGpmOrder,
  ReportGpmOrderParams
} from '../types/reportGpmOrder';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportGpmOrder) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage)
});

/**
 * Получение отчета
 */
export const fetchReportGpmOrder = async (
  params: ReportGpmOrderParams
): Promise<ReportGpmOrder[]> => {
  const report = await fetchRequest.get('/report/gpmOrder', {
    ...params
  });
  return report.map(convertFromServer);
};

/**
 * Печать отчета
 */
export const printReportGpmOrder = async (
  params: ReportGpmOrderParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken('/report/gpmOrder/print', {
    ...params
  });
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportGpmOrder,
  printReportGpmOrder
};
