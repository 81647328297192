// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { stockPartApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { StockPart } from '../../lib/types';

type Props = SelectProps & {
  fullInfo: boolean,
  vehicleModelId?: number,
  fetch: Function,
};

export default ({
  fetch,
  fullInfo = false,
  vehicleModelId = null,
  ...selectProps
}: Props) => {
  return (
    <AutocompleteSelect
      placeholder="Выберите деталь"
      fetch={(params) =>
        fetch
          ? fetch(params)
          : stockPartApi.fetchStockPart({
              ...params,
              vehicleModelId,
            })
      }
      fetchSingle={stockPartApi.getStockPart}
      notFoundText="Детали не найдены"
      renderOption={(stockPart: StockPart, Option) => (
        <Option value={stockPart.id} key={stockPart.id} part={stockPart}>
          {stockPart.sparePart
            ? fullInfo
              ? `${stockPart.sparePart.name} кол-во ${stockPart.count} цена за ед. ${stockPart.sparePart.price} руб. без НДС`
              : stockPart.sparePart.name
            : fullInfo
            ? `${stockPart.name} кол-во ${stockPart.count} цена за ед. ${stockPart.cost} руб. без НДС`
            : stockPart.name}
        </Option>
      )}
      {...selectProps}
    />
  );
};
