// @flow

import React, { Component } from 'react';
import notification from 'antd/lib/notification';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import type { MaintenanceRegulation } from './../../lib/types';
import { Panel } from './../../components/layout';
import { maintenanceRegulationApi } from '../../lib/api';
import TORegulationForm from './components/TORegulationForm';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  regulationId?: number,
  vehicleModelId: number
};

type State = {
  regulation: $Shape<MaintenanceRegulation>
};

export default class VehicleModelRegulationForm extends Component<
  Props,
  State
> {
  state: State = {
    regulation: {}
  };

  async componentDidMount() {
    const { vehicleModelId, regulationId } = this.props;
    if (vehicleModelId) {
      try {
        if (regulationId) {
          const regulation = await maintenanceRegulationApi.getMaintenanceRegulation(
            regulationId
          );
          this.setState({ regulation });
        } else {
          this.setState({ regulation: { vehicleModelId } });
        }
      } catch (error) {
        navigate(`/vehicle-models/${vehicleModelId}`);
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  handleSubmit = async (values: MaintenanceRegulation) => {
    const { vehicleModelId } = this.props;
    const { regulation } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      if (regulation.id) {
        await maintenanceRegulationApi.updateMaintenanceRegulation(values);
      } else {
        await maintenanceRegulationApi.addMaintenanceRegulation(values);
      }
      navigate(`/vehicle-models/${vehicleModelId}/maintenance`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { vehicleModelId } = this.props;
    const { regulation } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/vehicle-models">Модели ТС</Crumb>
              {!!vehicleModelId && (
                <Crumb to={`/vehicle-models/${vehicleModelId}`}>
                  Модель ТС №{vehicleModelId}
                </Crumb>
              )}
              {!!regulation ? (
                <Crumb>Регламент ТО №{regulation.id}</Crumb>
              ) : (
                <Crumb>Новый регламент ТО</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {regulation
              ? `Регламент ТО №${regulation.id || ''}`
              : 'Новый регламент ТО'}
          </h1>
        </StyledPanel>
        <TORegulationForm
          vehicleModelRegulation={regulation}
          onSubmit={this.handleSubmit}
          onCancel={() =>
            navigate(`/vehicle-models/${vehicleModelId}/maintenance`)
          }
        />
      </>
    );
  }
}
