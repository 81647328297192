// @flow
import React, { useState } from 'react';
import Button from 'antd/lib/button';

import type {
  OperationLimit,
  OperationLimitGroup
} from '../../../../lib/types';
import AssignmentLimitRow from './AssignmentLimitRow';
import { Row, Spoiler } from '../../../../components/ui';

type Props = {
  // Редактируемая ли группа
  editable: boolean,
  // Группа лимитов
  group: OperationLimitGroup,
  // Индекс текущей группы
  index: number,
  // Функция для изменения значения группы по ключу в массиве
  onChange: (
    index: number,
    name: $Keys<OperationLimitGroup>,
    value: any
  ) => void,
  // Функция для сохранения группы на бэке
  onSave: (group: OperationLimitGroup, index: number) => Promise<void>
};

/**
 * Компонент строки для групп лимитов в закреплении
 */
export default ({ group, index, onChange, onSave, editable }: Props) => {
  const [hasChanges, setHasChanges] = useState(false);

  const handleChangeLimit = (
    limitIndex: number,
    name: $Keys<OperationLimit>,
    value: any
  ) => {
    const { operationLimits } = group;
    const copy = [...operationLimits];
    copy[limitIndex][name] = value;

    onChange(index, 'operationLimits', copy);
    setHasChanges(true);
  };

  const handleSave = async () => {
    await onSave(group, index);
    setHasChanges(false);
  };

  return (
    <>
      <Spoiler
        label={group.name}
        labelStyles={{
          background: '#F0F4F8',
          color: '#838D96',
          height: '45px'
        }}
        defaultExpanded
        iconPlacement="left"
      >
        {group.operationLimits.map((limit, index) => (
          <AssignmentLimitRow
            editable={editable}
            onChange={handleChangeLimit}
            limit={limit}
            index={index}
            key={index}
          />
        ))}
        {editable && hasChanges && (
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleSave} size="small" type="primary">
              Сохранить изменения
            </Button>
          </Row>
        )}
      </Spoiler>
    </>
  );
};
