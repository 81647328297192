import {
    OsagoCalculationForm,
    OsagoCalculationFreeVehicleForm,
    OsagoCalculationList,
    OsagoCalculationListForm,
    OsagoCalulationsByYearList,
} from '../../containers/budget/osagoCalculations';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: OsagoCalulationsByYearList,
        path: '/budget/osago-calculations',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingOsagoCalculation,
            accessTypeEnum.formationOsagoCalculations,
        ],
    },
    {
        component: OsagoCalculationList,
        path: '/budget/osago-calculations/list',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingOsagoCalculation,
            accessTypeEnum.formationOsagoCalculations,
        ],
    },
    {
        component: OsagoCalculationListForm,
        path: '/budget/osago-calculations/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.formationOsagoCalculations,
        ],
    },
    {
        component: OsagoCalculationFreeVehicleForm,
        path: '/budget/osago-calculations/new/vehicle-free-calculation',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.formationOsagoCalculations,
        ],
    },
    {
        component: OsagoCalculationFreeVehicleForm,
        path: '/budget/osago-calculations/new/vehicle-free-calculation/:osagoCalculationId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.formationOsagoCalculations,
        ],
    },
    {
        component: OsagoCalculationForm,
        path: '/budget/osago-calculations/:osagoCalculationId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.formationOsagoCalculations,
        ],
    },
];