//@flow
import { Select, TreeSelect } from 'antd';
import styled, { css } from 'styled-components';

const { Option } = Select;

const commonStyles = css`
  width: 100%;

  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: var(--border-hover-item-color);
  }
  
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--border-item-color);
  }
  
  .ant-select-arrow .anticon-down svg {
    display: none;
  }

  .ant-select-arrow .anticon-down::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url('/img/icons/arrow-down-12.svg');
  }

  .ant-select-arrow .anticon-search svg {
    display: none;
  }

  .ant-select-arrow .anticon-search::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url('/img/icons/arrow-up-12.svg');
  }

  .ant-select-clear .anticon-close-circle svg {
    display: none;
  }

  .ant-select-clear .anticon-close-circle::before {
    content: '';
    display: block;
    width: 16px;
    height: 14px;
    background: url('/img/icons/x-12.svg') 50% 50% / 12px 12px no-repeat;
  }

  .ant-form-item-has-error & .ant-select-arrow .anticon-down::before {
    background: var(--error-item-color);
    mask-image: url('/img/icons/arrow-down-12.svg');
  }

  .ant-form-item-has-error & .ant-select-arrow .anticon-search::before {
    background: var(--error-item-color);
    mask-image: url('/img/icons/arrow-up-12.svg');
  }

  .ant-form-item-has-error & .ant-select-close::before {
    background: var(--error-item-color);
    mask-image: url('/img/icons/x-12.svg');
  }
`;

const StyledSelect = styled(Select)`
  ${commonStyles}

  .ant-select-focused .ant-select-selection {
    box-shadow: 0 0 0 2px #a1c1ff;
    border-color: #2770ff;
    margin: 0 2px;
  }
`;

const StyledTreeSelect = styled(TreeSelect)`
  ${commonStyles}
`;

export { StyledSelect as Select, StyledTreeSelect as TreeSelect, Option };
