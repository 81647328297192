// @flow
import type { Regulation } from './../types';
import CommonApi, { type CommonApiMethod } from './../commonApi';

type regulationApiMethod = $Shape<
  CommonApiMethod & {
    changeStatus: CommonApiMethod
  }
>;

const regulation: regulationApiMethod = new CommonApi<Regulation>(
  'regulation',
  'регламент НТС'
);

regulation.changeStatus = new CommonApi<Regulation>(
  'regulation/changeStatus',
  'смена статуса регламента НТС'
);

export default regulation;
