// @flow
import React, { type Node, type ComponentType } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  fontSize?: string,
  children: Node
};

const B: ComponentType<Props> = styled(({ fontSize, ...props }) => (
  <b {...props} />
))`
  font-weight: 700;
  ${({ fontSize = 'inherit' }: Props) => css`
    font-size: ${fontSize};
  `}
`;

export default B;
