// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type { ReportDriver, ReportDriversParams } from '../types/reportDrivers';

/**
 * Получение отчета
 */
export const fetchReportDrivers = async (
  params: ReportDriversParams
): Promise<ReportDriver[]> => {
  return await fetchRequest.get('/report/driver-work', {
    ...params
  });
};

/**
 * Печать отчета
 */
export const printReportDrivers = async (
  params: ReportDriversParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/driver-work/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportDrivers,
  printReportDrivers
};
