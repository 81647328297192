// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table } from '../../components/ant/table/Table';
import Section from './../../components/layout/Section';
import Grid, { GridItem } from './../../components/layout/Grid';
import Field from '../../components/card/Field';
import type { AppState } from '../../ducks/redux';
import type { Journal } from '../../lib/types';
import { formatDateTimeToString } from '../../lib/helpers';
import { fetchJournal } from './../../ducks/journal';

const Content = styled.div`
  padding: 16px;
`;

type Props = {
  journal: Journal,
  journalId: number,
  fetchJournal: Function
};

class JournalCard extends Component<Props> {
  static defaultProps = {
    journal: []
  };

  async componentDidMount() {
    const { journalId } = this.props;
    await this.props.fetchJournal(journalId);
  }

  columns = [
    {
      title: 'Свойство',
      dataIndex: 'name',
      // eslint-disable-next-line no-unused-vars
      render: (name: string) => name
    },
    {
      title: 'Старое значение',
      dataIndex: 'oldValue',
      render: (oldValue: Object) => oldValue || 'none'
    },
    {
      title: 'Новое значение',
      dataIndex: 'newValue',
      render: (newValue: Object) => newValue || 'none'
    }
  ];

  render() {
    const { journal } = this.props;
    if (!journal) {
      return null;
    }
    const propChanges =
      journal.changes &&
      Object.keys(journal.changes).map(x => ({
        name: x,
        ...journal.changes[x]
      }));
    return (
      <Section>
        <Content>
          <Grid>
            <GridItem fullWidth={true}>
              <Field label="Пользователь:">
                {`${journal.userName} · ${formatDateTimeToString(
                  journal.created,
                  'DD.MM.YYYY HH:mm',
                  false
                )}`}
              </Field>
            </GridItem>
            <GridItem fullWidth={true}>
              <Field label="Событие:">{journal.message}</Field>
            </GridItem>
          </Grid>
        </Content>
        {!!propChanges.length && (
          <Table
            columns={this.columns}
            dataSource={propChanges}
            pagination={false}
            rowKey="name"
          />
        )}
      </Section>
    );
  }
}

export default connect(
  (state: AppState) => {
    return {
      journal: state.journal
    };
  },
  {
    fetchJournal
  }
)(JournalCard);
