/* @flow */
import { Router } from '@reach/router';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';

import ruRU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import React from 'react';

import { Provider } from 'react-redux';

import AppLayout from './AppLayout';
import './assets/styles/index.css';
import { Notify } from './components/hoc';
import { Logout } from './components';
import { Login } from './containers/Login';
import { initStore } from './ducks/redux';

const { store, persistStore } = initStore();

const App = () => (
  <Provider store={store}>
    <ConfigProvider locale={ruRU}>
      <Notify>
        <Router>
          <AppLayout default path='/' />
          <Login path='/login' />
          <Logout path='/logout' />
        </Router>
      </Notify>
    </ConfigProvider>
  </Provider>
);

export { store, persistStore };

export default App;
