// @flow
import Button from 'antd/lib/button';

import AntIcon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BranchTabs, InfoBranch } from '.';
import Section, { SectionTitle } from '../../../../components/layout/Section';

import { notificationLoading } from '../../../../components/Notifications';
import { AntTable, ButtonsRow } from '../../../../components/ui';
import { TableHeader } from '../../../../components/ui/Table';
import {
  branchBudgetSummaryApi,
  branchBudgetSummaryExpenseDirectionApi
} from '../../../../lib/api';
import { entityStatusEnum } from '../../../../lib/enum';

import {
  navigate,
  toLocalStringRu,
  tooltipFullText
} from '../../../../lib/helpers';
import type { BranchBudgetSummary, UserAccess } from '../../../../lib/types';
import { withUserAccess } from '../../../withUserAccess';
import { DeclineReasonModal } from '../../summary/components';
import {
  approveDetailedBudgetBranchAccessRight,
  editDetailedBudgetAccessRight
} from '../accessRight';

import { sumBudgetLineItemBudget } from '../lib';

const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;
const StyledInput = styled(Input)`
  width: 100%;
`;
const StyledTable = styled(AntTable)``;

type Props = {
  id: number,
  orgUnitId: number,
  branchBudgetSummary: ?BranchBudgetSummary,
  setBranchBudgetSummary: Function,
  expandedRows: string[],
  userAccess: UserAccess[]
};
export default withUserAccess((props: Props) => {
  const {
    id,
    orgUnitId,
    branchBudgetSummary,
    setBranchBudgetSummary,
    expandedRows
  } = props;
  const [localData, setData] = useState(branchBudgetSummary);
  const [expandedRowsLocal, setExpandedRows] = useState(expandedRows);
  const [branchBudgetSummaryExpenseDirection, setExpenseDirection] = useState({
    note: ''
  });
  const [visibleModal, setVisibleModal] = useState(false);
  const [declineReasonModalVisible, setDeclineReasonModalVisible] = useState(
    false
  );

  const COLUMNS = [
    {
      dataIndex: 'budgetLineItemName',
      title: 'Статья бюджетной формы',
      key: 'budgetLineItemName',
      width: 250,
      fixed: true,
      render: budgetLineItemName => (
        <WhiteSpace style={{ width: '95%' }}>
          {tooltipFullText(budgetLineItemName, 35)}
        </WhiteSpace>
      )
    },
    {
      dataIndex: 'expenseDirectionName',
      title: 'Направление расходов',
      key: 'expenseDirectionName',
      width: 250,
      fixed: true,
      render: expenseDirectionName => (
        <WhiteSpace>{tooltipFullText(expenseDirectionName, 35)}</WhiteSpace>
      )
    },
    {
      dataIndex: 'economy',
      title: 'Доп. потребность',
      key: 'economy',
      render: economy =>
        economy > 0 ? <WhiteSpace>{toLocalStringRu(economy)}</WhiteSpace> : 0
    },
    {
      dataIndex: 'coveredByOpenPeriod',
      title: (
        <TableHeader width="120px">Экономия открытого периода</TableHeader>
      ),
      key: 'coveredByOpenPeriod',
      render: plan =>
        plan < 0 ? <WhiteSpace>{toLocalStringRu(plan)}</WhiteSpace> : 0
    },
    {
      dataIndex: 'coveredByClosedPeriod',
      title: (
        <TableHeader width="120px">Экономия закрытого периода</TableHeader>
      ),
      key: 'coveredByClosedPeriod',
      render: plan =>
        plan < 0 ? <WhiteSpace>{toLocalStringRu(plan)}</WhiteSpace> : 0
    },
    {
      dataIndex: 'budgetChange',
      title: 'Изменение бюджета',
      key: 'budgetChange',
      render: budgetChange => (
        <WhiteSpace>{toLocalStringRu(budgetChange)}</WhiteSpace>
      )
    },
    {
      dataIndex: 'note',
      title: 'Комментарий',
      key: 'note',
      onCell: (record, rowIndex) => ({
        onClick: () => {
          if (
            record.expenseDirectionId &&
            branchBudgetSummary?.proposalStatus !== entityStatusEnum.approved
          ) {
            setExpenseDirection(record);
            setVisibleModal(true);
          }
        }
      }),
      render: note => <WhiteSpace>{toLocalStringRu(note)}</WhiteSpace>
    },
    {
      dataIndex: 'source',
      title: 'Источник покрытия',
      key: 'source',
      render: source => <WhiteSpace>{tooltipFullText(source, 50)}</WhiteSpace>
    }
  ];

  useEffect(() => {
    if (branchBudgetSummary) {
      const { budgetSummaryLineItems } = branchBudgetSummary;

      setData({
        ...branchBudgetSummary,
        budgetSummaryLineItems:
          sumBudgetLineItemBudget(budgetSummaryLineItems) ?? []
      });
    }
  }, [branchBudgetSummary]);

  const changeStatus = async (status: string, params?: any) => {
    const { id, orgUnitId } = props;
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'change'
      });
      if (localData?.id) {
        const branchBudgetSummary = await branchBudgetSummaryApi.changeStatus({
          id: localData.id,
          distributionEconomyStatus:
            status === entityStatusEnum.created
              ? entityStatusEnum.created
              : localData.distributionEconomyStatus,
          proposalStatus: status,
          status:
            status === entityStatusEnum.approved
              ? entityStatusEnum.approved
              : localData.status
        });
        await setBranchBudgetSummary({
          ...localData,
          ...branchBudgetSummary
        });
        if (status === entityStatusEnum.created) {
          navigate(`/budget/details/${id}/${orgUnitId}/distribution-economy`);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('change');
    }
  };

  const saveNote = async (note: string) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await branchBudgetSummaryExpenseDirectionApi.update({
        ...branchBudgetSummaryExpenseDirection,
        note
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  const canEdit = () =>
    props.userAccess.some(access =>
      editDetailedBudgetAccessRight.includes(access)
    );

  const canApproveBranch = () =>
    props.userAccess.some(access =>
      approveDetailedBudgetBranchAccessRight.includes(access)
    );

  return (
    <>
      <SectionTitle
        divider
        suffix={
          canEdit() ? (
            <ButtonsRow>
              {localData?.proposalStatus === entityStatusEnum.created && (
                <Button
                  type="primary"
                  onClick={() => changeStatus(entityStatusEnum.approvement)}
                >
                  На согласование
                </Button>
              )}
              {canApproveBranch() &&
                localData?.proposalStatus === entityStatusEnum.approvement && (
                  <>
                    <Button
                      type="primary"
                      onClick={() => changeStatus(entityStatusEnum.approved)}
                    >
                      Согласовать
                    </Button>
                    <Button
                      type="primary"
                      onClick={() => setDeclineReasonModalVisible(true)}
                    >
                      Отклонить
                    </Button>
                  </>
                )}
              {localData?.proposalStatus === entityStatusEnum.declined && (
                <Button
                  type="primary"
                  onClick={() => changeStatus(entityStatusEnum.created)}
                >
                  Редактировать
                </Button>
              )}
            </ButtonsRow>
          ) : null
        }
      >
        <BranchTabs
          id={id}
          orgUnitId={orgUnitId}
          calculationEconomyStatus={localData?.calculationEconomyStatus}
          distributionEconomyStatus={localData?.distributionEconomyStatus}
          proposalEconomyStatus={localData?.proposalStatus}
        />
      </SectionTitle>
      <InfoBranch branchBudgetSummary={localData} proposal />
      <Section>
        <StyledTable
          columns={COLUMNS}
          data={localData?.budgetSummaryLineItems ?? []}
          childrenColumnName="expenseDirections"
          expandRowByClick
          expandIconAsCell={false}
          expandIconColumnIndex={1}
          expandedRowKeys={expandedRowsLocal}
          onExpandedRowsChange={rows => setExpandedRows(rows)}
          rowClassName={record => {
            return record.rowId < 0 ? 'ant-table-row-color-light-grey' : null;
          }}
          expandIcon={row =>
            !!row.record.expenseDirections && (
              <AntIcon
                type={row.expanded ? 'up' : 'down'}
                style={{
                  fontSize: '12px',
                  float: 'right'
                }}
              />
            )
          }
          bordered
          rowKey="id"
          style={{
            overflow: 'auto'
          }}
          scroll={{
            y: '500px',
            x: '2000'
          }}
          pagination={false}
        />
      </Section>
      <NoteModal
        visible={visibleModal}
        note={branchBudgetSummaryExpenseDirection?.note ?? ''}
        onCancel={() => setVisibleModal(false)}
        onSave={note => {
          saveNote(note);
          // TODO фича основанная на ссылках в js на объекты
          branchBudgetSummaryExpenseDirection.note = note;
          setVisibleModal(false);
        }}
      />
      <DeclineReasonModal
        visible={declineReasonModalVisible}
        handleOk={async ({ declineReason }) => {
          setDeclineReasonModalVisible(false);
          changeStatus(entityStatusEnum.declined, { declineReason });
        }}
        handleCancel={() => {
          setDeclineReasonModalVisible(false);
        }}
      />
    </>
  );
});

type ModalProps = {
  visible: boolean,
  onCancel: Function,
  onSave: Function,
  note: string
};
const NoteModal = ({ visible, onCancel, onSave, note }: ModalProps) => {
  const [editNote, setNote] = useState(note);
  useEffect(() => {
    setNote(note);
  }, [note]);
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Причина отклонения"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        onSave(editNote);
      }}
    >
      <StyledInput value={editNote} onChange={e => setNote(e.target.value)} />
    </Modal>
  );
};
