// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';
import { Option, Select } from './../ant/Select';

const notificationTypes = [
  'Создание путевого листа',
  'Обновление путевого листа',
  'Изменение статуса путевого листа',
  'Обновление ТС',
  'Создание ТС',
  'Удаление ТС',
  'Списание ТС',
  'создание модели ТС мдм',
  'обновление модели ТС мдм',
  'ошибка обновления модели ТС мдм',
  'создание НТС',
  'обновление НТС',
  'создание заявки',
  'Обработка заявки',
  'изменение заявки',
  'заявка подверждена диспетчером',
  'заявка подтверждена главным инженером',
  'подтверждение заявки главным инженером',
  'удаление заявки',
  'отмена заявки',
  'отмена заявки НТС',
  'запрос на сервис автографа',
  'создание рейса "Энерготранс"/"КТС"',
  'обновление рейса "Энерготранс"/"КТС"',
  'создание НТС "Энерготранс"/"КТС"',
  'обновление НТС "Энерготранс"/"КТС"',
  'создание модели ТС "Энерготранс"/"КТС"',
  'обновление модели ТС "Энерготранс"/"КТС"',
  'создание бренда "Энерготранс"/"КТС"',
  'обновление бренда "Энерготранс"/"КТС"',
  'Создание тс "Энерготранс"/"КТС"',
  'Создание рейса "Энерготранс"/"КТС"',
  'Таксировка "Энерготранс"/"КТС"',
  'обновление МДМ /mdr.asmx',
  'обновление СТОУ /stou.asmx',
  'Загрузка данных из отчета системы мониторинга"АвтоГРАФ"',
  'Добавление контракта',
  'Обновление контракта',
  'Добавление объема',
  'Обновление объема',
  'Обновление факта',
  'Добавление факта',
  'Добавление талона',
  'Обновление талона',
  'Добавление навесного оборудования',
  'Обновление навесного оборудования',
  'Удаление навесного оборудования',
];

// Выпадающий список типов сообщение
export default (props: SelectProps) => (
  <Select allowClear showSearch placeholder="Выберите тип события" {...props}>
    {notificationTypes.map(type => (
      <Option value={type} key={type}>
        {type}
      </Option>
    ))}
  </Select>
);
