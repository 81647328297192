import {
    MaintenanceOperationForm,
} from '../../containers/MaintenanceOperations';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: MaintenanceOperationForm,
        path: '/admin/maintenance-operations/:maintenanceOperationId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingMaintenanceOperationGroups,
        ],
    },
    {
        component: MaintenanceOperationForm,
        path: '/admin/maintenance-operations/:parentMaintenanceOperationGroupId/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingMaintenanceOperationGroups,
        ],
    },
];