// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../components/ui';

export default () => (
  <Tabs withRouter>
    <TabItem label="Реестр бюджетов" url="/budget/contract-vehicle/fixed" />
    <TabItem label="Регламенты" url="/budget/contract-vehicle/work-plans" />
  </Tabs>
);
