// @flow
import queryString from 'query-string';
import type {
  LicensePlateChange,
  ExecutionBudgetGroupedList
} from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { LicensePlateChangeFilterParams } from '../../containers/services/licensePlateChange/Filter';

export const convertDataFromServer = (
  licensePlateChange: LicensePlateChange
) => ({
  ...licensePlateChange,
  paymentAmount: convertFromKopToRub(licensePlateChange.paymentAmount),
  act: licensePlateChange.act
    ? actConvertDataFromServer(licensePlateChange.act)
    : undefined
});

export const convertDataToServer = (
  licensePlateChange: LicensePlateChange
) => ({
  ...licensePlateChange,
  paymentAmount: convertFromRubToKop(licensePlateChange.paymentAmount)
});

export const addLicensePlateChange = async (
  licensePlateChange: LicensePlateChange
): Promise<LicensePlateChange> => {
  const added = await fetchRequest.post(
    '/licensePlateChange',
    convertDataToServer(licensePlateChange)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать замену гос. номера');
};

export const updateLicensePlateChange = async (
  licensePlateChange: LicensePlateChange
): Promise<LicensePlateChange> => {
  const updated = await fetchRequest.put(
    '/licensePlateChange',
    convertDataToServer(licensePlateChange)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить замену гос. номера');
};
export const deleteLicensePlateChange = async (
  id: number
): Promise<LicensePlateChange> => {
  const deleted = await fetchRequest.delete(`/licensePlateChange/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить замену гос. номера');
};

export const fetchLicensePlateChange = async (
  id: number,
  params: any = {}
): Promise<LicensePlateChange> => {
  const licensePlateChange = await fetchRequest.get(
    `/licensePlateChange/${id}`,
    params
  );
  if (licensePlateChange) return convertDataFromServer(licensePlateChange);
  throw new Error('Не удалось получить замену гос. номера');
};

export const fetchVehicleLicensePlateChanges = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<LicensePlateChange>> => {
  const licensePlateChangees = await fetchRequest.get(
    `/licensePlateChange/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (licensePlateChangees) {
    return {
      ...licensePlateChangees,
      data: licensePlateChangees.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список замен гос. номеров');
};

export const fetchLicensePlateChanges = async (
  params: FetchListParams<LicensePlateChange> = initialFetchListParams
): Promise<ListResponse<LicensePlateChange>> => {
  const licensePlateChanges = await fetchRequest.get(`/licensePlateChange`, {
    ...initialFetchListParams,
    ...params
  });
  if (licensePlateChanges) {
    return {
      ...licensePlateChanges,
      data: licensePlateChanges.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список замен гос. номеров');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<LicensePlateChange>> => {
  const licensePlateChange = await fetchRequest.put(
    `/licensePlateChange/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (licensePlateChange) {
    return {
      ...licensePlateChange,
      data: licensePlateChange.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за списоком замен гос. номеров');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/licensePlateChange/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/licensePlateChange/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const printReport = async (
  params: LicensePlateChangeFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/licensePlateChange/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  groupedList,
  getTotalSum,
  updateAct,
  addLicensePlateChange,
  updateLicensePlateChange,
  deleteLicensePlateChange,
  fetchLicensePlateChange,
  fetchVehicleLicensePlateChanges,
  fetchLicensePlateChanges,
  printReport
};
