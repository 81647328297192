//@flow
import { DatePicker } from 'antd';
import styled, { css } from 'styled-components';

const commonStyles = css`
  width: 100%;
  border-color: var(--border-item-color);
  
  &:hover {
    border-color: var(--border-hover-item-color);
  }
  
  &.ant-picker-small {
    height: var(--small-item-height);
  }
  
  &.ant-picker-small input {
    font-size: 13px;
  }
  
  .ant-picker-suffix .anticon-calendar svg {
    display: none;
  }

  .ant-picker-suffix .anticon-calendar::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    background: url('/img/icons/calendar-16.svg') no-repeat;
    background-size: cover;
  }

  .ant-picker-clear .anticon-close-circle svg {
    display: none;
  }

  .ant-picker-clear .anticon-close-circle::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url('/img/icons/x-12.svg') 50% 50% / 12px 12px no-repeat;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  ${commonStyles}
`;

const { RangePicker, MonthPicker } = StyledDatePicker;

const StyledRangePicker = styled(RangePicker)`
  ${commonStyles}
`;

const StyledMonthPicker = styled(MonthPicker)`
  ${commonStyles}
`;

export {
  StyledRangePicker as RangePicker,
  StyledDatePicker as DatePicker,
  StyledMonthPicker as MonthPicker,
};
