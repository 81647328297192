// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import { FormikProps } from 'formik';
import Divider from 'antd/lib/divider';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { DatePicker } from '../../../components/ant/DatePicker';

import type { Washing, Vehicle, WashingPlanBalance } from '../../../lib/types';
import {
  washingTypeEnum,
  ownerTypes,
  washingStatusEnum,
  washingTypes
} from './../../../lib/enum';
import {
  applyMaskToValue,
  convertVehicleToString,
  formatDateTimeToISOString
} from './../../../lib/helpers';

import { Selects, Form, Card } from './../../../components';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { Section, SectionTitle } from './../../../components/layout';
import type { FormFieldType } from './../../../components/Form';
import CancelButton from './../../../components/CancelButton';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const { Field } = Card;
const { MonthPicker } = DatePicker;
const { VehicleSelect, WashingTypeSelect, DriverSelect } = Selects;
const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FooterSide = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const bePositive = (num: number) => {
  // это штобы вместо отрицательных остатков выводить 0
  return num >= 0 ? num : 0;
};

type FormProps = {
  washing: ?$Shape<Washing>,
  onSubmit: Function,
  planData: ?WashingPlanBalance,
  showVehicleSelect: boolean,
  fetchPlanData: (washing: Washing) => Promise<void>,
  handleSaveAndPrint: Function,
  onCancel: () => void
};

export default ({
  fetchPlanData,
  washing,
  onSubmit,
  onCancel,
  planData,
  showVehicleSelect,
  handleSaveAndPrint
}: FormProps) => {
  return (
    <Form initialValues={washing} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const {
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
          validateForm,
          dirty
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            {(planData || showVehicleSelect) && (
              <Section>
                <SectionTitle divider>Данные ТС</SectionTitle>
                <Content>
                  <Grid gutter="16px">
                    {values.id
                      ? convertVehicleToString(values.vehicle)
                      : showVehicleSelect && (
                          <GridItem>
                            <FormField
                              label="Гос. номер"
                              required
                              name="vehicleId"
                            >
                              {({ name, value }) => (
                                <VehicleSelect
                                  value={value}
                                  name={name}
                                  optionTitle={(vehicle: Vehicle) =>
                                    applyMaskToValue(
                                      vehicle.licensePlate,
                                      formatLicensePlateMask
                                    )
                                  }
                                  onBlur={() =>
                                    handleBlur({ target: { name } })
                                  }
                                  filter={{
                                    ownerType: ownerTypes.self
                                  }}
                                  onChange={(value: number) => {
                                    setFieldValue(name, value);
                                    fetchPlanData({ ...values, [name]: value });
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        )}
                  </Grid>
                  {planData && (
                    <>
                      <Divider />
                      <Grid cols={3}>
                        <GridItem>
                          <Field label="Остаток наружных моек">
                            {planData.vehicleBodyCount > 0
                              ? planData.vehicleBodyCount
                              : 0}
                          </Field>
                        </GridItem>
                        <GridItem>
                          <Field label="Остаток моек салона">
                            {planData.vehicleInteriorCount > 0
                              ? planData.vehicleInteriorCount
                              : 0}
                          </Field>
                        </GridItem>
                        <GridItem>
                          <Field label="Остаток моек ДВС">
                            {planData.vehicleEngineCount > 0
                              ? planData.vehicleEngineCount
                              : 0}
                          </Field>
                        </GridItem>
                        <GridItem>
                          <Field label="Остаток суммы на ТС">
                            {bePositive(
                              planData.vehiclePlanSum - planData.vehicleFactSum
                            ).toLocaleString('ru-RU')}
                            &nbsp;&#8381;
                          </Field>
                        </GridItem>
                        <GridItem>
                          <Field label="Остаток суммы по филиалу">
                            {bePositive(
                              planData.planSum - planData.factSum
                            ).toLocaleString('ru-RU')}
                            &nbsp;&#8381;
                          </Field>
                        </GridItem>
                        {/* TODO это всегда ноль или пофиксить или убрать */}
                        {/* <GridItem>
                          <Field label="Остаток суммы в подразделении">
                            0 &nbsp;&#8381;
                          </Field>
                        </GridItem> */}
                      </Grid>
                    </>
                  )}
                </Content>
              </Section>
            )}

            <Section>
              <SectionTitle divider>Данные талона</SectionTitle>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    {values.id ? (
                      <Field label="Месяц">
                        {moment.utc(values.date).format('MMMM YYYY')}
                      </Field>
                    ) : (
                      <FormField label="Месяц" name="date" required>
                        {({ name, value }) => (
                          <MonthPicker
                            value={value ? moment(value) : undefined}
                            format={'MMMM YYYY'}
                            placeholder="Выберите дату"
                            onChange={value => {
                              setFieldValue('factDate', null);
                              const date = moment
                                .utc(value)
                                .startOf('month')
                                .toISOString();
                              setFieldValue(name, date);
                              fetchPlanData({ ...values, [name]: date });
                            }}
                          />
                        )}
                      </FormField>
                    )}
                  </GridItem>
                  <GridItem>
                    {values.id ? (
                      <Field label="Тип мойки">
                        {washingTypes[values.type]}
                      </Field>
                    ) : (
                      <FormField
                        label="Тип мойки"
                        required
                        name="type"
                        defaultValue={washingTypeEnum.body}
                      >
                        {({ name, value }) => (
                          <WashingTypeSelect
                            name={name}
                            value={value}
                            onBlur={() => handleBlur({ target: { name } })}
                            onChange={(value: string) => {
                              setFieldValue(name, value);
                              fetchPlanData({ ...values, [name]: value });
                            }}
                          />
                        )}
                      </FormField>
                    )}
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            {!isEmpty(washing) && (
              <Section>
                <SectionTitle divider>Факт от водителя</SectionTitle>
                <Content>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField label="Дата мойки" required name="factDate">
                        {({ name, value }) => (
                          <DatePicker
                            value={value ? moment(value) : undefined}
                            format={'DD.MM.YYYY'}
                            placeholder='Выберите дату'
                            disabled={!values.date}
                            disabledDate={(currentDate: moment) =>
                              moment.utc(currentDate).month() !==
                              moment.utc(values.date).month()
                            }
                            onChange={(value, dateString) => {
                              const date = formatDateTimeToISOString(
                                value,
                                dateString
                              );
                              setFieldValue(name, date);
                              if (isEmpty(date)) {
                                setFieldValue(
                                  'state',
                                  washingStatusEnum['scheduled']
                                );
                              } else {
                                setFieldValue(
                                  'state',
                                  washingStatusEnum['done']
                                );
                              }
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Водитель"
                        defaultValue={values.vehicle && values.vehicle.driverId}
                        name="driverId"
                        required
                      >
                        {({ name, value }) => (
                          <DriverSelect
                            name={name}
                            value={value}
                            onChange={driverId => {
                              setFieldValue(name, driverId);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </Content>
              </Section>
            )}

            <Footer>
              <FooterSide>
                <Button type="primary" htmlType="submit" data-cy="save">
                  Сохранить
                </Button>
                <Button
                  type="primary"
                  onClick={async () => {
                    if (isEmpty(await validateForm())) {
                      await handleSaveAndPrint(values);
                    }
                  }}
                  data-cy="save"
                >
                  Сохранить и печать
                </Button>
              </FooterSide>
              <FooterSide>
                <CancelButton dirty={dirty} onClick={onCancel}>
                  Отменить
                </CancelButton>
              </FooterSide>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
