// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import notification from 'antd/lib/notification';

import { setQueryParams } from '../../lib/helpers';

const AdditionalFilterContainer = styled.div.attrs({
  className: 'additional__filter__container',
})`
  ${() => {
    return css`
      margin-top: 10px;
      display: flex;
      width: 100%;
    `;
  }}
`;

export type FilterChildrenParams<T> = {
  values: T,
  showAdditional: boolean,
  changeValue: (key: $Keys<T>, value: any) => void,
  handleAdditional: () => Promise<void>,
  applyFilter: () => Promise<void>,
  cleanFilter: () => Promise<void>,
};

type Props = {
  initialValues?: any,
  // использовать доп. фильтры или нет
  additionalFilter?: boolean,
  // показаны или скрыты доп. фильтры
  showAdditional?: boolean,
  children: <T>(params: FilterChildrenParams<T>) => React$Element<any>,
  applyFilter: (values: any) => Promise<void>,
  cleanFilter?: () => Promise<void>,
  // Применять фильтр сразу после изменения значения
  applyOnChange?: boolean,
  withOutQuery?: boolean,
};

type State = {
  values: any,
  showAdditional: boolean,
};

/**
 * HOC для фильтров
 *
 * Хранит локальный стейт для значений фильтров до их применения
 * Пробрасывает результаты фильтрации в location браузера
 */
export default class Filter extends React.Component<Props, State> {
  state = {
    values: this.props.initialValues || {},
    showAdditional: false,
  };

  componentDidMount() {
    this.setState({ showAdditional: !isEmpty(this.state.values) });
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
      this.setState({
        values: this.props.initialValues,
        showAdditional: !isEmpty(this.props.initialValues),
      });
    }
  }

  changeValue = (name: string, value: any) => {
    const { applyOnChange } = this.props;
    this.setState(
      (prevState: State) => ({
        values: {
          ...prevState.values,
          [name]: value,
        },
      }),
      applyOnChange ? this.applyFilter : undefined
    );
  };

  applyFilter = async () => {
    const { values } = this.state;
    try {
      if (!this.props.withOutQuery) {
        setQueryParams({ ...values, page: undefined });
      }
      await this.props.applyFilter(values);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  cleanFilter = async () => {
    const { withOutQuery } = this.props;
    const { values } = this.state;
    try {
      !withOutQuery && setQueryParams(
        Object.keys(values).reduce(
          (acc, key: string) => ({ ...acc, [key]: undefined }),
          { page: undefined }
        )
      );
      this.setState({ values: this.props.initialValues });
      if (this.props.cleanFilter) await this.props.cleanFilter();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  handleAdditional = async () => {
    const { showAdditional } = this.state;
    this.setState({ showAdditional: !showAdditional });
  };

  mainFilter(filterElements: any) {
    const count = React.Children.count(filterElements);
    return count > 4
      ? React.Children.toArray(
        React.Children.map(filterElements, (child, index) => {
          return index < 3 || index === count - 1 ? child : null;
        })
      )
      : null;
  }

  additionalFilter(filterElements: any) {
    const { showAdditional } = this.state;
    const count = React.Children.count(filterElements);

    return count > 4 && showAdditional ? (
      <AdditionalFilterContainer>
        {React.Children.map(filterElements, (child, index) => {
          return index > 2 && index !== count - 1 ? child : null;
        })}
      </AdditionalFilterContainer>
    ) : null;
  }

  render() {
    const { children, additionalFilter } = this.props;
    const { values, showAdditional } = this.state;
    const childs = children({
      values,
      showAdditional,
      handleAdditional: this.handleAdditional,
      changeValue: this.changeValue,
      applyFilter: this.applyFilter,
      cleanFilter: this.cleanFilter,
    });
    return (
      <>
        {additionalFilter ? (
          <>
            {this.mainFilter(childs.props.children)}
            {this.additionalFilter(childs.props.children)}
          </>
        ) : (
            childs
          )}
      </>
    );
  }
}
