import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import Button from 'antd/lib/button';

import { authApi, tokenWrapper } from '../../../../../lib/api';
import Selects from '../../../../../components/selects';

const { DriverSelect } = Selects;

const PddReloginModal = (props: {
  visible: boolean,
  onClose: Function,
  onOk: (employeeId: number) => {}
}) => {
  const [driver, setDriver] = useState(null);

  return (
    <Modal
      okText={'Сменить пользователя'}
      cancelText={'Отмена'}
      visible={props.visible}
      maskClosable
      destroyOnClose
      closable={false}
      title={'Выберите водителя для тестирования'}
      style={{ top: 20, right: 20, position: 'absolute' }}
      okButtonProps={{ disabled: !driver }}
      onOk={() => props.onOk(driver.employee.id)}
      onCancel={props.onClose}
    >
      <>
        <DriverSelect
          value={driver?.id}
          driverWithUsers
          onChange={(driverId, option) => {
            setDriver(option.props.driver);
          }}
        />
      </>
    </Modal>
  );
};

export default () => {
  const [pddReloginModalVisible, setPddReloginModalVisible] = useState(false);

  const relogin = useCallback(async (employeeId: number) => {
    const result = await authApi.getEmployeeToken(employeeId);
    if (result) {
      const { accessToken } = result;
      tokenWrapper.setToken(accessToken);
      document.location.replace('/');
    } else {
      throw new Error('login failed');
    }
  }, []);

  const handleClick = useCallback(async () => {
    setPddReloginModalVisible(true);
  }, []);

  return (
    <>
      <Button onClick={handleClick}>Сменить пользователя</Button>
      <PddReloginModal
        visible={pddReloginModalVisible}
        onClose={() => setPddReloginModalVisible(false)}
        onOk={relogin}
      />
    </>
  );
};
