export const selfRegulationStatusesEnum = {
  created: 'created',
  approvement: 'approvement',
  approved: 'approved',
  declined: 'declined',
  editing: 'editing',
  closed: 'closed'
};

export const selfRegulationStatuses = {
  created: 'Черновик',
  approvement: 'На утверждении',
  approved: 'Утвержден',
  declined: 'Отклонен',
  editing: 'На редактировании',
  closed: 'Закрыт'
};
