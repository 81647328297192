// @flow
import qs from 'query-string';

import type { PddQuestion } from '../types';
import CommonApi from './../commonApi';
import { fetchRequest } from './index';

const pddQuestion = new CommonApi<PddQuestion>('pddQuestion', 'Вопросы ПДД');

export const getAllQuestionsId = async (): Promise<number[]> => {
  const data = await fetchRequest.get(`/pddQuestion/getAllQuestionsId`);
  if (data) return data;

  throw new Error('Не удалось получить вопросы для тестирования!');
};

export const getAdditionalQuestions = async (
  groupIds?: number[],
  pddTestId?: number,
  ppdQuestionIds?: number[]
): Promise<PddQuestion[]> => {
  const data = await fetchRequest.get(
    `/pddQuestion/getAdditionalQuestions?${qs.stringify(
      { groupIds, pddTestId, ppdQuestionIds },
      { arrayFormat: 'index' }
    )}`
  );
  if (data) return data;

  throw new Error('Не удалось получить вопросы для тестирования!');
};
export default {
  ...pddQuestion,
  getAllQuestionsId,
  getAdditionalQuestions
};
