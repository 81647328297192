//@flow
import React, { useCallback } from 'react';
import moment from 'moment';
import { DatePicker } from '../../../components/ant/DatePicker';

import { FilterItem } from '../../../components';
import Field from '../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../components/hoc/common/handbook/CommonFilter';

import { formatDateTimeToISOString } from '../../../lib/helpers';
import Selects from '../../../components/selects';
import type { TechnicalFluidType, VehicleType } from '../../../lib/types';
import { TechnicalFluidTypeSelect } from '../../../components/selects/AutoGeneratedEnumSelects';

const { VehicleSelect, VehicleTypeSelect, YearIssueSelect } = Selects;

export type TechnicalFluidParamsFilter = {
  date?: string,
  vehicleId?: string,
  vehicleType?: VehicleType,
  yearIssued?: number,
  fluidType?: TechnicalFluidType
};

export const TechnicalFluidFilter = (
  props: FilterProps<TechnicalFluidParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<TechnicalFluidParamsFilter>>) =>
      CommonFilter<TechnicalFluidParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter additionalFilter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <DatePicker
                size="small"
                placeholder="Дата создания"
                value={values.date ? moment(values.date) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'date',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleSelect
                size="small"
                value={values.vehicleId}
                onChange={value => changeValue('vehicleId', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem key={4}>
            <Field mBottomNone>
              <VehicleTypeSelect
                size="small"
                value={values.vehicleType}
                onChange={value => changeValue('vehicleType', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <YearIssueSelect
                size="small"
                value={values.yearIssued}
                onChange={value => changeValue('yearIssued', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <TechnicalFluidTypeSelect
                size="small"
                value={values.fluidType}
                onChange={value => changeValue('fluidType', value)}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default TechnicalFluidFilter;
