import type {
  WashingPlanVehicleSummary,
  WashingPlanVehicle
} from './../../../lib/type';

const reducer = (
  accumulator: WashingPlanVehicleSummary,
  currentValue: WashingPlanVehicleSummary
) => ({
  bodyCount: accumulator.bodyCount + (currentValue.bodyCount || 0),
  bodyPrice: accumulator.bodyPrice + (currentValue.bodyPrice || 0),
  interiorCount: accumulator.interiorCount + (currentValue.interiorCount || 0),
  interiorPrice: accumulator.interiorPrice + (currentValue.interiorPrice || 0),
  engineCount: accumulator.engineCount + (currentValue.engineCount || 0),
  enginePrice: accumulator.enginePrice + (currentValue.enginePrice || 0),
  totalPrice:
    accumulator.totalPrice +
    (parseFloat(currentValue.bodyCount * currentValue.bodyPrice) || 0) +
    (parseFloat(currentValue.interiorCount * currentValue.interiorPrice) || 0) +
    (parseFloat(currentValue.engineCount * currentValue.enginePrice) || 0)
});

export const calculateSummaryPlan = (
  washingPlaneVehicles: WashingPlanVehicle[]
): WashingPlanVehicleSummary => {
  const data = [
    {
      bodyCount: 0,
      bodyPrice: 0,
      interiorCount: 0,
      interiorPrice: 0,
      engineCount: 0,
      enginePrice: 0,
      totalPrice: 0
    },
    ...washingPlaneVehicles
  ];
  const washingPlanVehicleSummary = data.map(
    ({
      bodyCount,
      bodyPrice,
      interiorCount,
      interiorPrice,
      engineCount,
      enginePrice
    }: WashingPlanVehicle) =>
      ({
        bodyCount,
        bodyPrice,
        interiorCount,
        interiorPrice,
        engineCount,
        enginePrice,
        totalPrice: 0
      }: WashingPlanVehicleSummary)
  );

  return washingPlanVehicleSummary.reduce(reducer);
};
