import React from 'react';
import { Router } from '@reach/router';
import Orders, { orderTypes } from './components/Orders';

const IncomeOrders = props => <Orders type={orderTypes.income} {...props} />;
const ArchiveOrders = props => <Orders type={orderTypes.archive} {...props} />;

export default () => (
  <Router>
    <IncomeOrders path="/" />
    <ArchiveOrders path="archive" />
  </Router>
);
