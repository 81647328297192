// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { sparePartsApi } from '../../lib/api';
import type { SpareParts } from '../../lib/types';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & {
  vehicleModelId: number,
  allVehicles: boolean,
};

export default ({
  vehicleModelId,
  allVehicles,
  filter,
  ...selectProps
}: Props) => {
  return (
    <AutocompleteSelect
      placeholder="Выберите запчасть"
      fetch={async (params) => {
        if (allVehicles) {
          const all = await sparePartsApi.fetch({
            ...filter,
            ...params,
            'sparePart.allVehicles': true,
          });
          const vehiclesModels = await sparePartsApi.fetch({
            ...filter,
            ...params,
            vehicleModelId,
          });
          return { data: [...all.data, ...vehiclesModels.data] };
        }
        return sparePartsApi.fetch({ vehicleModelId, ...filter, ...params });
      }}
      fetchSingle={sparePartsApi.get}
      notFoundText="Запчасти не найдены"
      renderOption={(sparePart: SpareParts, Option) => (
        <Option value={sparePart.id} key={sparePart.id} part={sparePart}>
          {sparePart.name}{' '}
          {sparePart.allVehicles ? (
            ''
          ) : sparePart.vehicleModels ? (
            <>
              (
              <span style={{ color: '#2770ff' }}>
                {sparePart.vehicleModels
                  .map((model) => `${model.brandName} ${model.name}`)
                  .join(', ')}{' '}
              </span>
              )
            </>
          ) : null}
        </Option>
      )}
      {...selectProps}
    />
  );
};
