// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button/button';
import { Link, navigate } from '@reach/router';

import type { AppState } from '../../ducks/redux';
import { Table } from '../../components/ui';
import { Header, Section } from '../../components/layout';
import type {
  FuelGroup,
  Vehicle,
  UserAccess,
  FuelCard,
  ListState
} from '../../lib/types';
import { fuelGroups, accessTypeEnum } from '../../lib/enum';
import { fuelCardApi, type FetchListParams } from '../../lib/api';
import { getListInitialState, setQueryParams } from '../../lib/helpers';
import Filter, { type FuelCardFilterParams } from './Filter';
import { withUserAccess } from './../withUserAccess';

const canAddAccess = [accessTypeEnum.admin, accessTypeEnum.handlingEquipment];

type Props = {
  fuelCards: FuelCard[],
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

type State = ListState<FuelCard> & {
  filter: FuelCardFilterParams
};

export const COLUMNS = [
  {
    title: '№',
    dataIndex: 'id',
    sorter: true,
    width: 20
  },
  {
    title: 'ТС',
    dataIndex: 'vehicle',
    width: 200,
    breakByWidth: true,
    render: (vehicle: Vehicle, record: FuelCard) => {
      const { modelName, brandName, licensePlate } = record;
      return [brandName, modelName, licensePlate && `(${licensePlate})`]
        .join(' ')
        .trim();
    }
  },
  {
    title: 'Номер карты',
    dataIndex: 'cardNumber',
    sorter: true
  },
  {
    title: 'Вид топлива',
    dataIndex: 'fuelGroup',
    sorter: true,
    render: (group: FuelGroup) => fuelGroups[group]
  }
];

export class FuelCardsList extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  getDefaultFilter = () => {
    const canViewAll = this.props.userAccess.some(access =>
      [accessTypeEnum.viewingAllEquipments].includes(access)
    );
    const defaultFilter = canViewAll
      ? {}
      : ({
          nodeId: this.props.employeeBranchOrgUnitId,
          nodeFilterType: 'branchAndChildren'
        }: FuelCardFilterParams);
    return defaultFilter;
  };

  state = {
    ...getListInitialState(),
    filter: this.getDefaultFilter()
  };

  columns = [...COLUMNS];

  fetchFuelCards = async (
    page: number = 1,
    params: FetchListParams<FuelCardFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await fuelCardApi.fetchFuelCards({
        ...filter,
        page,
        ...params,
        ...this.getDefaultFilter() // фильтр по оргюнитам в зависимости от клэймов
      });
      setQueryParams({ page });
      this.setState({ loading: false, data, totalCount, page });
    } catch (err) {
    } finally {
      this.setState({ loading: false });
    }
  };

  handleRowClick = async (id: number) => navigate(`/admin/fuel-cards/${id}`);

  cleanFilter = () => this.setState({ filter: {...null} }, this.fetchFuelCards);

  applyFilter = (filter: FuelCardFilterParams) =>
    this.setState({ filter }, this.fetchFuelCards);

  render() {
    const { location, userAccess } = this.props;

    const { data, totalCount, page, pageSize, loading, filter } = this.state;
    const canAdd = userAccess.some(access => canAddAccess.includes(access));
    return (
      <>
        <Header
          left={<h1>Топливные карты</h1>}
          right={
            canAdd && (
              <Link to="/admin/fuel-cards/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={(record: FuelCard) => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            loading={loading}
            data={data}
            columns={this.columns}
            fetch={this.fetchFuelCards}
            fetchOnMount
            rowKey="id"
            pagination={{
              location,
              page,
              pageSize,
              totalCount
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(withUserAccess(FuelCardsList));
