// @flow
import type { BudgetsForAnalysis } from './../types';
import CommonApi from './../commonApi';

const budgetsForAnalysis = new CommonApi<BudgetsForAnalysis>(
  'budgetForAnalysis',
  'Бюджеты для анализа'
);

export default {
  ...budgetsForAnalysis
};
