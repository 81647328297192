import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { Spinner } from '../../components';
import { TopPanel } from '../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import type { AppState } from '../../ducks/redux';
import { cleanTrip, fetchTrip, updateTrip, addTrip } from '../../ducks/trip';
import { routeApi, orderApi } from '../../lib/api';
import {
  tripStatusEnum,
  waypointTypeEnum,
  orderTypeEnum,
  orderStatusEnum,
} from '../../lib/enum';
import { directions } from '../../lib/gis';
import { getPathWithHistoryParams, navigate } from '../../lib/helpers';
import type { Vehicle, WayPoint } from '../../lib/types';
import InnerForm from './components/InnerForm';

type Props = {
  tripId?: number,
  updateTrip: () => void,
  addTrip: () => void,
  fetchTrip: () => void,
  cleanTrip: () => void,
};

const TripForm = (props: Props) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [trip, setTrip] = useState({});
  const [geometry, setGeometry] = useState();

  useEffect(() => {
    //  подгрузка данных
    const fetch = async () => {
      if (props.tripId) {
        setTrip(await props.fetchTrip(props.tripId));
      } else {
        setTrip({
          expectedRoute: {
            waypoints: [],
          },
        });
      }
      setPageLoading(false);
    };
    fetch().then();
  }, [props, props.tripId]);

  useEffect(() => {
    // const fetch = async () => {
    //   try {
    //     // const freeAllVehicles = await tripApi.fetchTripFreeVehicles(trip.id);
    //     const { data: freeAllVehicles } = await vehicleApi.fetchVehicles({});
    //     const orders = {};
    //     // const orders = await Promise.all(
    //     //   trip.ordersId.map(async (id) => await orderApi.fetchOrder(id))
    //     // );
    //     let freeVehicles = [];
    //     let freeTrailers = [];
    //     freeAllVehicles.forEach((vehicle: Vehicle) => {
    //       if (
    //         trip.withTrailer &&
    //         trailerVehicleTypes.includes(vehicle.vehicleModel.type)
    //       ) {
    //         freeTrailers.push({
    //           ...vehicle,
    //           disabled: false,
    //         });
    //       } else {
    //         freeVehicles.push(vehicle);
    //         /**
    //          * Если у нас заявка на ТС с прицепом и к ТС прикреплены прицепы,
    //          * то мы их добавляем, как прицепы, которые можно выбрать, но ставим отметку,
    //          * что они заблокированы для выбора.
    //          * Блокируем мы их потому, что прицеп можно выбрать только в связке ТС + прицеп.
    //          * Выбрать прикрепленный прицеп к другому ТС нельзя, для этого нужно произвести
    //          * манипуляции по откреплению прицепа в инвентарной карточке
    //          */
    //         if (trip.withTrailer) {
    //           const vehicleTrailers = vehicle.trailers.map<Vehicle>(
    //             (item: Vehicle) => ({
    //               ...item,
    //               disabled: true,
    //             })
    //           );
    //           freeTrailers.push(...vehicleTrailers);
    //         }
    //       }
    //     });
    //     const { trailers, vehicles } = getSelectionVehicles({
    //       selectedTrailer: trip.trailer,
    //       selectedVehicle: trip.vehicle,
    //       trailers: freeTrailers,
    //       vehicles: freeVehicles,
    //       withTrailer: trip.withTrailer,
    //     });
    //     setFreeVehicles(vehicles);
    //     setFreeTrailers(trailers);
    //     setOrders(orders);
    //   } catch (error) {
    //     notification.error({
    //       message: 'Ошибка',
    //       description: error.message,
    //     });
    //   }
    // };
    // // if (trip?.id) {
    // fetch().then();
    // // }
  }, [trip.id, trip.ordersId, trip.trailer, trip.vehicle, trip.withTrailer]);

  // Проставляем новые точки стоянки взамен старых, если такие присутствуют в ТС
  const vehicleChanged = async (
    vehicle: Vehicle,
    expectedWaypoints: Array<WayPoint>
  ): Promise<{
    driverId?: ?number,
    expectedWaypoints: Array<WayPoint>,
  }> => {
    const result = {};
    if (parseInt(vehicle.driverId) > 0) {
      result.driverId = vehicle.driverId;
    }
    const transitWaypoints = expectedWaypoints.filter(
      (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.transit
    );
    if (vehicle.location) {
      const { latitude, longitude, name, radius, address } = vehicle.location;
      const waypoint = { latitude, longitude, name, radius, address };
      result.expectedWaypoints = [
        { ...waypoint, type: waypointTypeEnum.start },
        ...transitWaypoints,
        { ...waypoint, type: waypointTypeEnum.end },
      ];
    } else {
      result.expectedWaypoints = transitWaypoints;
    }
    if (vehicle.trailers && vehicle.trailers.length > 0) {
      result.trailer = vehicle.trailers[0];
      result.trailerId = result.trailer.id;
    } else {
      result.trailer = null;
      result.trailerId = null;
    }
    return result;
  };

  const onSubmit = async (values: any) => {
    notificationLoading({
      message: 'Сохранение данных...',
      key: 'saving',
    });
    try {
      const addedRoute = await routeApi.addRoute(values.route);
      let order = await orderApi.addOrder({
        employeeId: values.employeeId,
        hasCargos: false,
        isGpm: false,
        objective: values.objective,
        route: values.route,
        workersCount: values.workersCount,
        type: orderTypeEnum.standard,
        vehicleType: values?.vehicle?.vehicleModel?.type,
        routeId: addedRoute.id,
      });
      await orderApi.updateOrder({
        ...order,
        vehicleId: values.vehicleId,
      });
      await orderApi.changeStatus({
        id: order.id,
        status: orderStatusEnum.approved,
        vehicleId: values?.vehicle?.id,
      });
      navigate('/trips/self/', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  if (pageLoading || !trip) {
    return <Spinner isLoading />;
  } else {
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/trips/self/')}>
                Путевые листы
              </Crumb>
              <Crumb>
                {trip.id
                  ? trip.status === tripStatusEnum.draft
                    ? 'Заявка'
                    : `Путевой лист №${parseInt(trip.idNumber) || ''}`
                  : 'Новый путевой лист'}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          {trip.id ? (
            <h1>Путевой лист №{trip.id}</h1>
          ) : (
            <h1>Новый путевой лист</h1>
          )}
        </TopPanel>
        <InnerForm
          data={{
            ...trip,
            // Если у нас имеется авто, но нет пробега на старте, то записываем это значение
            odometerAtStart:
              parseFloat(trip.vehicleId) > 0 && !trip.odometerAtStart
                ? trip.vehicle.waybillKilometrage
                : trip.odometerAtStart,
          }}
          onCancel={() => navigate('/trips/self/', true)}
          onVehicleChange={vehicleChanged}
          freeVehicles={[]}
          freeTrailers={[]}
          onCalculateGeometry={async (formTrip) => {
            const directionsResponse = await directions(
              formTrip.expectedRoute.waypoints
            );
            setGeometry(directionsResponse);
            return directionsResponse;
          }}
          // isSubmitting={isSubmitting}
          orders={[]}
          tripGeometry={geometry}
          employeeBranchOrgUnitId={props.employeeBranchOrgUnitId}
          onSubmit={onSubmit}
        />
      </>
    );
  }
};

export default connect(
  (state: AppState, ownProps: Props) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  }),
  {
    updateTrip,
    addTrip,
    fetchTrip,
    cleanTrip,
  }
)(TripForm);
