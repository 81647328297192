// @flow
import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import qs from 'query-string';
import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';

import type {
  ListState,
  UserAccess,
  EntityStatusType,
  ContractVehiclePlan,
} from './../../../../../lib/types';
import { contractVehiclePlanApi } from './../../../../../lib/api';
import { entityStatus } from './../../../../../lib/enum';
import {
  getListInitialState,
  formatDateRangeString,
  formatDateTimeToString,
} from './../../../../../lib/helpers';

import { Header, TopPanel, Section } from './../../../../../components/layout';
import {
  Popconfirm,
  Table,
  Operations,
  Dropdown,
} from './../../../../../components/ui';
import { notificationLoading } from './../../../../../components/Notifications';
import Breadcrumbs, {
  Crumb,
} from './../../../../../components/layout/Breadcrumbs';

import { withUserAccess } from './../../../../withUserAccess';

import type { RegistrBudgetFilterParams } from './../components';
import { MainTabs, Filter } from './../components';
import { addAccessRightContractVehicleBudget } from './../accessRight';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};
type State = ListState<ContractVehiclePlan> & {
  filter: RegistrBudgetFilterParams,
};

class RegistrBudgetList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        width: '20px',
        render: (contractVehiclePlan: ContractVehiclePlan, index: number) => {
          const contractVehiclePlanId = contractVehiclePlan.id || 0;
          return (
            <Operations key={index}>
              <Dropdown
                overlayStyle={{ zIndex: '999' }}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() =>
                        navigate(
                          `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/clone`
                        )
                      }
                    >
                      Копировать перечень ТС
                    </Menu.Item>
                    <Menu.Item>
                      <Popconfirm
                        title="Вы действительно хотите удалить?"
                        okText="Да"
                        cancelText="Нет"
                        placement="bottomRight"
                        onConfirm={() =>
                          this.deleteRegistrBudget(contractVehiclePlanId)
                        }
                      >
                        Удалить бюджет НТС
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <EllipsisOutlined style={{ fontSize: 16, color: '#2770FF' }} />
              </Dropdown>
            </Operations>
          );
        },
      });
    }
    this.setState(
      {
        filter: { ...filter },
        page,
      },
      () => this.fetchRegistrBudget(page)
    );
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName',
    },
    {
      title: 'Планируемый период',
      dataIndex: 'startDate',
      render: (text: string, record: any): ?string =>
        formatDateRangeString(record.startDate, record.endDate),
    },
    {
      title: 'Версия бюджета',
      dataIndex: 'budgetVersion.name',
    },
    {
      title: 'Дата формирования',
      dataIndex: 'date',
      width: '150',
      render: (created: string) =>
        `${formatDateTimeToString(created, 'DD.MM.YYYY')}`,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: EntityStatusType) =>
        status ? entityStatus[status] : '-',
    },
  ];

  deleteRegistrBudget = async (id: number = 1) => {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await contractVehiclePlanApi.delete(id);
      this.fetchRegistrBudget(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchRegistrBudget = async (page: number = 1) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await contractVehiclePlanApi.fetch({
      ...filter,
      page,
    });
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: RegistrBudgetFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchRegistrBudget(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchRegistrBudget(page)
    );
  };

  canAdd = () =>
    this.props.userAccess.some((access) =>
      addAccessRightContractVehicleBudget.includes(access)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb>Бюджет</Crumb>
            </Breadcrumbs>
          }
          right={
            this.canAdd() && (
              <Button
                type="primary"
                onClick={() => navigate('/budget/contract-vehicle/fixed/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <TopPanel>
          <h1>Закрепленные НТС</h1>
        </TopPanel>
        <MainTabs />
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchRegistrBudget}
            fetchOnMount
            onRow={(row: any) => ({
              onClick: () =>
                navigate(`/budget/contract-vehicle/fixed/${row.id}/card`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(RegistrBudgetList);
