// @flow
import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import Button from 'antd/lib/button';
import Divider from 'antd/lib/divider';

import type { MaintenanceMatrix } from '../../../lib/types';
import Form from '../../../components/Form';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import Selects from './../../../components/selects';

const {
  MaintenanceWorkTypeSelect,
  OrderContractorTypeSelect,
  RepairePlanSelect,
  MaintenanceMatrixValidActionSelect
} = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  maintenanceMatrix: ?MaintenanceMatrix,
  onCancel: Function,
  onSubmit: Function
};

export default ({ maintenanceMatrix, onCancel, onSubmit }: Props) => (
  <Form initialValues={maintenanceMatrix} onSubmit={onSubmit}>
    {(
      FormField: FormFieldType,
      {
        setFieldValue,
        handleSubmit,
        handleBlur,
        values,
        dirty,
        isSubmitting
      }: FormikProps
    ) => (
      <form onSubmit={handleSubmit}>
        <StyledSection>
          <Grid gutter="16px">
            <GridItem>
              <FormField
                label="Виды работ"
                fast
                name="maintenanceWorkTypeId"
                required
              >
                {({ name, value }) => (
                  <MaintenanceWorkTypeSelect
                    disabled={!!values.id}
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Способ выполнения"
                fast
                name="orderContractorType"
                required
              >
                {({ name, value }) => (
                  <OrderContractorTypeSelect
                    disabled={!!values.id}
                    value={value}
                    placeholder="Выберите способ"
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="График работ" fast name="repairPlan" required>
                {({ name, value }) => (
                  <RepairePlanSelect
                    disabled={!!values.id}
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>

          <Divider />

          <Grid gutter="16px">
            <GridItem>
              <FormField
                label="Подрядчик и договор"
                fast
                name="contractorRequired"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    requiredAction
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Обязательность заполнения неисправностей"
                fast
                name="descriptionRequired"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    requiredAction
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Обязательность заполнения дефектной ведомости"
                fast
                name="defectiveStatementRequired"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    requiredAction
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Обязательность загрузки файлов"
                fast
                name="filesRequired"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    requiredAction
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>

          <Divider />

          <Grid gutter="16px">
            <GridItem>
              <FormField
                label="Возможность согласования дефектной ведомости"
                fast
                name="approvingDefectiveStatementAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможность согласования детальной дефектной ведомости"
                fast
                name="approvingDetailedStatementAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможность согласования карточки ремонта"
                fast
                name="approvingMaintenanceAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>

          <Divider />

          <Grid gutter="16px">
            <GridItem>
              <FormField
                label="Возможность печати дефектного акта"
                fast
                name="printDefectiveActAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможность печати дефектной ведомости"
                fast
                name="printDefectiveStatementAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможность печати детальной дефектной ведомости"
                fast
                name="printDetailedDefectiveStatementAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможности печати акта на проведение ремонта хоз. способом"
                fast
                name="printRepairActAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможность печати Формы ОС-3"
                fast
                name="printFormOS3Allowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Возможности печати акта списания МПЗ"
                fast
                name="printActOffMPZAllowed"
                required
              >
                {({ name, value }) => (
                  <MaintenanceMatrixValidActionSelect
                    value={value}
                    onChange={value => {
                      setFieldValue(name, value);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        </StyledSection>

        <Footer>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {values.id ? 'Сохранить' : 'Создать'}
          </Button>
          <CancelButton dirty={dirty} onClick={onCancel}>
            Отменить
          </CancelButton>
        </Footer>
      </form>
    )}
  </Form>
);
