// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import qs from 'query-string';
import { Link, navigate } from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import type {
  ContractVehicle,
  ListState,
  OrgUnitNode,
  UserAccess,
  Vehicle,
  VehicleModel,
  VehicleStatus as VehicleStatusType
} from './../../lib/types';
import { accessTypeEnum, vehicleTypes } from './../../lib/enum';
import { Section } from './../../components/layout';
import VehicleStatus from '../../components/VehicleStatus';
import Filter from './Filter';
import { withUserAccess } from './../withUserAccess';
import { TabItem, Tabs, Table } from '../../components/ui';
import Header from '../../components/layout/Header';
import type { ContractVehicleFilterParams } from './Filter';
import { getListInitialState, setQueryParams } from '../../lib/helpers';
import type { FetchListParams } from '../../lib/api';
import { contractVehicleApi } from '../../lib/api';
import { setFilter } from '../../ducks/persistFilters';
import type { AppState } from '../../ducks/redux';
import type { PersistFilterPayload } from '../../ducks/persistFilters';

type Props = {
  contractVehicles: Array<Vehicle>,
  deleteContractVehicle: Function,
  fetchContractVehicles: Function,
  userAccess: UserAccess[],
  orgUnitId: number,
  currentOrgUnit: OrgUnitNode,
  fetchContractVehicles: Function,
  totalCount: number,
  pageSize: number,
  page: number,
  location: Location & { state: { page: number } },
  filter: ContractVehicleFilterParams,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<ContractVehicle>;

export const canAddAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.creatingVehicle
];

const filterPath = window.location.pathname;

export class List extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  static defaultProps = {
    location: {}
  };

  canAddVehicles = () =>
    this.props.userAccess.some(role => canAddAccess.includes(role));

  columns = [
    {
      title: 'Статус',
      dataIndex: 'vehicle.status',
      sorter: true,
      render: (status: VehicleStatusType) => <VehicleStatus status={status} />
    },
    {
      title: 'Гос. номер',
      sorter: true,
      dataIndex: 'vehicle.licensePlate',
      // Нужно для того, чтобы не отрабатывал onRow
      onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
      render: (licensePlate: string, record: Vehicle) => (
        <Link to={`/vehicles/contract/${record.id}`}>{licensePlate}</Link>
      )
    },
    {
      title: 'Год выпуска',
      sorter: true,
      dataIndex: 'vehicle.yearIssued'
    },
    {
      title: 'Марка',
      sorter: true,
      dataIndex: 'vehicle.vehicleModel.brandName'
    },
    {
      title: 'Модель',
      sorter: true,
      dataIndex: 'vehicle.vehicleModel.name'
    },
    {
      title: 'Тип',
      sorter: true,
      dataIndex: 'vehicle.vehicleModel',
      render: (contractVehiclesModel: ?VehicleModel): ?string =>
        contractVehiclesModel && vehicleTypes[contractVehiclesModel.type]
    },
    {
      title: 'Подрядчик',
      sorter: true,
      dataIndex: 'contractor.company.name'
    },
    {
      title: 'Подразделение',
      sorter: true,
      dataIndex: 'vehicle.orgUnitName'
    }
  ];

  handleRowClick = (id: number) => navigate(`/vehicles/contract/${id}`);

  fetchContractVehicles = async (
    page: number = 1,
    params: FetchListParams<ContractVehicleFilterParams> = {}
  ) => {
    const { filter } = this.props;
    this.setState({ loading: true });
    const { data, totalCount } = await contractVehicleApi.fetchContractVehicles(
      {
        ...filter,
        page,
        ...params
      }
    );
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  setPersistFilter = async (values: ContractVehicleFilterParams) => {
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchContractVehicles();
  };

  applyFilter = async (values: ContractVehicleFilterParams) => {
    await this.setPersistFilter(values);
    await this.fetchContractVehicles();
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchContractVehicles(page);
  }

  render() {
    const { location, filter } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Транспорт</h1>}
          right={
            this.canAddVehicles() && (
              <Link to="/vehicles/contract/new">
                <Button type="primary" data-cy="add">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Tabs defaultTab="contract">
          <TabItem
            tabKey="own"
            label="Собственные"
            onClick={() => navigate('/vehicles')}
          />
          <TabItem tabKey="contract" label="Наемные" />
        </Tabs>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchContractVehicles}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            loading={loading}
            columns={this.columns}
            data={data.map(item => ({
              ...item,
              key: item.id
            }))}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filter: state.persistFilters[filterPath] || {}
  }),
  {
    setFilter
  }
)(withUserAccess(List));
