// @flow
import React, { Component, Fragment } from 'react';
import Button from 'antd/lib/button';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import styled from 'styled-components';

import type {
  AttachedEquipment,
  UserAccess,
  ListState,
  Vehicle,
} from '../../../lib/types';
import Filter, { type AttachedEquipmentFilterParams } from './Filter';
import type { AppState } from '../../../ducks/redux';
import { Table } from './../../../components/ui';
import { Section } from './../../../components/layout';
import Header from '../../../components/layout/Header';
import { Crumb } from '../../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../../components/layout/Breadcrumbs';
import { Panel } from '../../../components/layout';
import NavigationTabs from '../components/NavigationTabs';
import { withUserAccess } from './../../withUserAccess';
import { accessTypeEnum } from './../../../lib/enum';
import { attachedEquipmentApi, type FetchListParams } from './../../../lib/api';
import {
  getListInitialState,
  setQueryParams,
  applyMaskToValue,
} from './../../../lib/helpers';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';

const StyledPanel = styled(Panel)`
  padding-bottom: 0;
  padding-top: 0;
`;
const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const canAddAttachedEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment,
];

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number,
};

type State = ListState<AttachedEquipment> & {
  filter: AttachedEquipmentFilterParams,
};

/** Вывод списка навесного оборудования */
class List extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };

  getDefaultFilter = () => {
    const canViewAll = this.props.userAccess.some((access) =>
      [accessTypeEnum.viewingAllEquipments].includes(access)
    );
    const defaultFilter: AttachedEquipmentFilterParams = canViewAll
      ? { ...null }
      : {
          orgUnitId: this.props.employeeBranchOrgUnitId,
          nodeFilterType: 'branchAndChildren',
        };
    return defaultFilter;
  };

  state = {
    ...getListInitialState(),
    filter: this.getDefaultFilter(),
  };

  fetchAttachedEquipments = async (
    page: number = 1,
    params: FetchListParams<AttachedEquipmentFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } =
      await attachedEquipmentApi.fetchAttachedEquipments({
        ...filter,
        page,
        ...params,
        ...this.getDefaultFilter(), // фильтр по оргюнитам в зависимости от клэймов
      });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  columns = [
    { title: '№', dataIndex: 'id', sorter: true, width: 25 },
    { title: 'Мнемокод', dataIndex: 'code', sorter: true },
    { title: 'Наименование', dataIndex: 'name', sorter: true },
    {
      title: 'Марка',
      dataIndex: 'brandName',
      sorter: true,
      sorterKey: 'brand.name',
    },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle',
      render: (vehicle: ?Vehicle) =>
        vehicle?.licensePlate ? (
          <Link to={`/vehicles/${vehicle.id}`}>
            {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          '-'
        ),
    },
    {
      title: 'Характеристики',
      dataIndex: 'specifications',
      width: 350,
      breakByWidth: true,
    },
  ];

  handleRowClick = (id: number) => navigate(`/equipment/attached/${id}`);

  cleanFilter = () =>
    this.setState({ filter: { ...null } }, this.fetchAttachedEquipments);

  applyFilter = (filter: AttachedEquipmentFilterParams) =>
    this.setState({ filter }, this.fetchAttachedEquipments);

  render() {
    const { location, userAccess } = this.props;
    const { data, totalCount, page, pageSize, loading, filter } = this.state;

    const canAdd = userAccess.some((access) =>
      canAddAttachedEquipmentAccess.includes(access)
    );
    return (
      <Fragment>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb>Навесное оборудование</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <PanelTitle>
            <h1>Навесное оборудование</h1>
            {canAdd && (
              <Link to="/equipment/attached/new">
                <Button data-cy="addAttached" type="primary">
                  Создать
                </Button>
              </Link>
            )}
          </PanelTitle>
          <NavigationTabs defaultTab="attached" />
        </StyledPanel>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            loading={loading}
            data={data.map((tire) => ({
              ...tire,
              key: tire.id,
            }))}
            fetch={this.fetchAttachedEquipments}
            fetchOnMount
            columns={this.columns}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </Fragment>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
}))(withUserAccess(List));
