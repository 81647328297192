// @flow
import React, { useCallback, useState } from 'react';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import { budgetAnalysisApi } from '../../../lib/api';

import { navigate } from '../../../lib/helpers';
import type { BudgetAnalysis } from '../../../lib/types';
import InnerForm from './components/InnerForm';

type PageProps = {
  id: number
};

const entryPointPath = '/budget/budget-analysis/';

export default (props: PageProps) => {
  const [data: BudgetAnalysis, setData] = useState({
    id: props.id
  });

  const onFetch = useCallback(async () => {
    !!props.id && setData(await budgetAnalysisApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: BudgetAnalysis) => {
    let id = payload.id;
    if (id) {
      await budgetAnalysisApi.update(payload);
    } else {
      let data = await budgetAnalysisApi.add(payload);
      id = data.id;
    }
    await navigate(`${entryPointPath}`);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: [
          { to: '/', name: 'Главная' },
          { to: `${entryPointPath}`, name: 'Анализ затрат' },
          { name: 'Новый анализ' }
        ],
        mainHeader: 'Анализ затрат'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
