import styled from 'styled-components';
import Upload from 'antd/lib/upload';

export const FilesWrapper = styled.div`
  width: 100%;
`;
export const ModalImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const StyledUpload = styled(Upload)`
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 150px;
    height: 150px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: block;
    position: absolute;
    bottom: 0;
    background: #fffc;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
`;
