// @flow

import { EllipsisOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { Link, navigate } from '@reach/router';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';

import type {
  Maintenance,
  VehicleIssue,
  DetailedDefectiveStatement,
  UserAccess,
  MaintenanceOperationPrice,
} from '../../lib/types';
import {
  vehicleTypes,
  maintenanceStatusesTypes,
  repairPlanTypes,
  orderContractorTypes,
  maintenanceStatusesEnum,
  orderContractorTypeEnum,
} from '../../lib/enum';
import {
  maintenanceApi,
  detailedDefectiveStatementApi,
  maintenanceOperationPriceApi,
  vehicleIssueApi,
} from '../../lib/api';

import {
  setDetailedDefectiveStatement,
  saveDetailedDefectiveStatement,
} from '../../ducks/detailedDefectiveStatement';
import { setMaintenance } from '../../ducks/maintenance';

import { Card } from './../../components';
import { Panel, Section, SectionTitle } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import Header from '../../components/layout/Header';
import {
  applyMaskToValue,
  convertContractorToString,
  showWorkEngineHours,
} from '../../lib/helpers';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Dropdown, Popover } from '../../components/ui';
import { notificationLoading } from '../../components/Notifications';
import { formatLicensePlateMask } from '../../components/inputs/masked-inputs/LicensePlateInput';

import { VehicleIssuesReadOnly, VehicleIssues } from './VehicleIssues';
import { DetailedDefectiveStatementCard } from './DetailedDefectiveStatememt';
import { addAccessRight, canApprovingMaintenance } from './accessRight';

const { Field } = Card;

const StyledPanel = styled(Panel)`
  padding-top: 0;
  & > h1 {
    margin-bottom: 16px;
  }
`;
const Content = styled.div`
  padding: 16px;
`;
const StyledButtonDiv = styled.div`
  button {
    margin-left: 10px;
  }
`;

type Props = {
  userAccess: UserAccess[],
  maintenanceId: number,
  maintenance: Maintenance,
  setDetailedDefectiveStatement: Function,
  saveDetailedDefectiveStatement: Function,
  setMaintenance: Function,
  // актуалная дефектная веломость
  detailedDefectiveStatement: ?DetailedDefectiveStatement,
};

type State = {
  vehicleIssues: VehicleIssue[],
  // стоимость норма часа для филиала
  maintenanceOperationPrice: ?MaintenanceOperationPrice,
};

export class MaintenanceCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      vehicleIssues: [],
      maintenanceOperationPrice: null,
      parts: [],
      operations: [],
    };
  }

  getDetailedDefectiveStatement = async (id?: number) => {
    const { maintenance, setDetailedDefectiveStatement } = this.props;
    const maintenanceId = id ? id : maintenance ? maintenance.id : null;
    const detailedDefectiveStatement = maintenanceId
      ? await detailedDefectiveStatementApi.getActualDetailedDefectiveStatement(
          maintenanceId
        )
      : null;
    setDetailedDefectiveStatement(detailedDefectiveStatement);
  };

  // актуальная стоимость работы по филиалу за нормо час
  getActualMaintenanceOperationPrice = async () => {
    const { maintenance } = this.props;
    try {
      if (maintenance) {
        const maintenanceOperationPrice =
          await maintenanceOperationPriceApi.getActualMaintenanceOperationPrice(
            maintenance.orgUnitId
          );
        this.setState({ maintenanceOperationPrice });
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  };

  // кнопки печать для дефектной ведомости
  getSuffixDefectiveStatement = () => {
    const { maintenance } = this.props;
    return (
      <>
        {maintenance && (
          <Button
            style={{ marginLeft: '10px', position: 'relative', top: '-2px' }}
            onClick={() => this.handlePrint({ key: 'printMaintenance' })}
          >
            Печать
          </Button>
        )}
      </>
    );
  };

  withOutEmpty = (data: any[]): Array<any> =>
    data ? data.filter((item) => !isEmpty(item)) : [];

  async componentDidMount() {
    const { maintenanceId, setMaintenance } = this.props;
    if (maintenanceId) {
      try {
        const maintenance = await maintenanceApi.fetchMaintenance(
          maintenanceId
        );
        await this.getDetailedDefectiveStatement(maintenanceId);
        // кладем maintenance в redux это нужно чтобы брать значение
        // maintenanceWorkType.workType
        setMaintenance(maintenance);
        this.setState(
          {
            vehicleIssues: maintenance.vehicleIssues,
          },
          () => {
            this.getActualMaintenanceOperationPrice();
          }
        );
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message,
        });
      }
    }
  }

  componentWillUnmount() {
    const { setMaintenance, setDetailedDefectiveStatement } = this.props;
    setMaintenance(null);
    setDetailedDefectiveStatement(null);
  }

  onChange = (stateKey: string) => (value: any[]) => {
    this.setState({ [stateKey]: value });
  };

  // сохраняем карточку работ и дефектную ведомость
  saveMaintenance = async (callback?: Function) => {
    const { maintenance, setMaintenance } = this.props;
    const { vehicleIssues } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const issues = this.withOutEmpty(vehicleIssues);
      const updatedMaintenance = await maintenanceApi.updateMaintenance({
        ...maintenance,
        vehicleIssues: issues,
      });
      setMaintenance(updatedMaintenance);
      this.setState(
        {
          vehicleIssues: updatedMaintenance.vehicleIssues,
        },
        () => (callback ? callback() : null)
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handlePrint = async ({ key, vehicleIssueId }: any) => {
    const { maintenance } = this.props;
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      if (maintenance) {
        switch (key) {
          case 'printMaintenance':
            await maintenanceApi.printMaintenance(maintenance.id);
            break;
          case 'printVehicleIssue':
            await vehicleIssueApi.printVehicleIssue(vehicleIssueId);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  changeStatus =
    (stateKey: string) =>
    async ({ key }: any) => {
      const { setMaintenance, maintenance } = this.props;
      const { vehicleIssues } = this.state;
      if (maintenance) {
        try {
          notificationLoading({
            message: 'Смена статуса...',
            key: 'saving',
          });

          const data = await maintenanceApi.changeStatusMaintenance(
            maintenance.id,
            key
          );
          setMaintenance({ ...data, status: key });
          if (key === maintenanceStatusesEnum.approved) {
            try {
              await maintenanceApi.changeCountStockPart(maintenance.id, key);
              await maintenanceApi.fillNextToDate(data);
              notification.success({
                message: 'День следующего ТО',
                description: 'День следующего ТО успешно установлен',
              });
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message,
              });
            } finally {
              navigate(`/maintenances/journal/read/${maintenance.id}`);
            }
          }
        } catch (error) {
          notification.error({
            message: 'Ошибка',
            description: error.message,
          });
        } finally {
          notification.close('saving');
        }
      }
    };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccessRight.includes(access));

  canApprovingMaintenance = () =>
    this.props.userAccess.some((access) =>
      canApprovingMaintenance.includes(access)
    );

  // проверим показывать или нет валидацию
  checkShowApproving() {
    return this.props.maintenance?.status !== maintenanceStatusesEnum.approved;
  }

  render() {
    const { detailedDefectiveStatement, maintenance } = this.props;
    const { vehicleIssues, maintenanceOperationPrice } = this.state;
    if (!maintenance || isEmpty(maintenance)) {
      return null;
    }
    const dateRangeExists =
      !!detailedDefectiveStatement?.startDate &&
      !!detailedDefectiveStatement?.endDate;
    const kilometrage = !!maintenance?.kilometrage;
    const workEngineHours = !!maintenance?.workEngineHours;
    const isWorkEngineHours = showWorkEngineHours(maintenance?.vehicle);
    const canApprove =
      !dateRangeExists || (isWorkEngineHours ? !workEngineHours : !kilometrage);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Транспорт</Crumb>
              <Crumb to="/maintenances/journal">Ремонт</Crumb>
              {maintenance && <Crumb>Карточка работ №{maintenance.id}</Crumb>}
            </Breadcrumbs>
          }
          right={
            <StyledButtonDiv>
              {maintenance &&
                this.canAdd() &&
                maintenance.orderContractorType ===
                  orderContractorTypeEnum.contractor &&
                maintenanceStatusesEnum.approved !== maintenance.status && (
                  <Button type="primary">
                    <Link to={`/maintenances/journal/${maintenance.id}/order`}>
                      Создать заявку
                    </Link>
                  </Button>
                )}
              {maintenance &&
                this.checkShowApproving() &&
                this.canApprovingMaintenance() && (
                  <Dropdown
                    overlay={
                      <Menu onClick={this.changeStatus('maintenance')}>
                        {maintenance.status ===
                          maintenanceStatusesEnum.draft && (
                          <Menu.Item key={maintenanceStatusesEnum.inProgress}>
                            В работу
                          </Menu.Item>
                        )}
                        {maintenance.status ===
                          maintenanceStatusesEnum.inProgress && (
                          <Menu.Item
                            disabled={canApprove}
                            key={maintenanceStatusesEnum.approved}
                          >
                            {canApprove ? (
                              <Popover
                                content={`Необходимо заполнить дату начала и окончания ремонта и ${
                                  isWorkEngineHours ? 'моточасы' : 'километраж'
                                } `}
                              >
                                Утвердить
                              </Popover>
                            ) : (
                              'Утвердить'
                            )}
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                  >
                    <Button>
                      <EllipsisOutlined
                        style={{ fontSize: 16, color: '#2770FF' }}
                      />
                    </Button>
                  </Dropdown>
                )}
            </StyledButtonDiv>
          }
        />
        <StyledPanel>
          <h1>{maintenance && <>Карточка работ №{maintenance.id}</>}</h1>
          {maintenance && (
            <Grid gutter="16px" cols={7}>
              <GridItem>
                <Field label="Статус">
                  {maintenance.status
                    ? maintenanceStatusesTypes[maintenance.status]
                    : ''}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">
                  {maintenance.maintenanceWorkType.name}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="График работ">
                  {repairPlanTypes[maintenance.repairPlan]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Способ выполнения">
                  {orderContractorTypes[maintenance.orderContractorType]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Планируемая дата проведения работ">
                  {moment(maintenance.maintenancePlannedDate).format(
                    'DD.MM.YYYY'
                  ) ?? '-'}
                </Field>
              </GridItem>
              {maintenance.executor && (
                <GridItem>
                  <Field label="Исполнитель">{maintenance.executor.name}</Field>
                </GridItem>
              )}
              {maintenance.contractor && (
                <GridItem>
                  <Field label="Подрядчик">
                    {convertContractorToString(maintenance.contractor)}
                  </Field>
                </GridItem>
              )}
              {maintenance.contractNumber && (
                <GridItem>
                  <Field label="Договор">{maintenance.contractNumber}</Field>
                </GridItem>
              )}
              {maintenance.expenseDirection && (
                <GridItem>
                  <Field label="Направление расходов">
                    {maintenance.expenseDirection.name}
                  </Field>
                </GridItem>
              )}

              {maintenance.description && (
                <GridItem fullWidth style={{ marginTop: '10px' }}>
                  <Field label="Примечание">{maintenance.description}</Field>
                </GridItem>
              )}
            </Grid>
          )}
        </StyledPanel>
        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <Content>
            <Grid cols={3} gutter="16">
              {maintenance &&
                maintenance.vehicle &&
                maintenance.vehicle.vehicleModel && (
                  <>
                    <GridItem>
                      <Field label="Тип">
                        {vehicleTypes[maintenance.vehicle.vehicleModel.type]}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Марка">
                        {maintenance.vehicle.vehicleModel.brandName}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Модель">
                        {maintenance.vehicle.vehicleModel.name}
                      </Field>
                    </GridItem>
                  </>
                )}
              <GridItem>
                {maintenance && maintenance.vehicle.licensePlate && (
                  <Field label="Государственный регистрационный знак">
                    {applyMaskToValue(
                      maintenance.vehicle.licensePlate,
                      formatLicensePlateMask
                    )}
                  </Field>
                )}
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          {maintenance && (
            <>
              <SectionTitle
                divider
                // suffix={this.getSuffixDefectiveStatement()}
              >
                Дефектная ведомость
              </SectionTitle>
              <Content>
                <VehicleIssues
                  maintenanceWorkType={maintenance.maintenanceWorkType}
                  handlePrint={this.handlePrint}
                  handleSubmit={this.saveMaintenance}
                  maintenanceId={maintenance.id}
                  vehicleId={maintenance.vehicleId}
                  onChange={this.onChange('vehicleIssues')}
                  vehicleIssues={vehicleIssues}
                  filesRequired={false}
                  print={false}
                />
              </Content>
            </>
          )}
        </Section>

        <DetailedDefectiveStatementCard
          maintenanceOperationPrice={maintenanceOperationPrice}
          changeStatus={this.changeStatus}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    userAccess: state.auth.profile.access,
    maintenance: state.maintenance,
    detailedDefectiveStatement: state.detailedDefectiveStatement,
  }),
  {
    setDetailedDefectiveStatement,
    saveDetailedDefectiveStatement,
    setMaintenance,
  }
)(MaintenanceCard);
