// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { vehicleIssueGroupApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { VehicleIssueGroup } from '../../lib/types';

export default (props: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите группу дефектов"
    fetch={vehicleIssueGroupApi.fetchVehicleIssueGroup}
    fetchSingle={vehicleIssueGroupApi.getVehicleIssueGroup}
    notFoundText="Пользователи не найдены"
    renderOption={(vehicleIssueGroup: VehicleIssueGroup, Option) => (
      <Option
        value={vehicleIssueGroup.id}
        key={vehicleIssueGroup.id}
        vehicleIssueGroup={vehicleIssueGroup}
      >
        {vehicleIssueGroup.name}
      </Option>
    )}
    {...props}
  />
);
