// @flow

import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import type { AuthState } from './auth';
import orgUnits from './orgUnits';
import employee from './employee';
import vehicle from './vehicle';
import order from './order';
import shift from './shift';
import trip from './trip';
import wialonTrip from './wialonTrip';
import vehicleModel from './vehicleModel';
import vehicleModels from './vehicleModels';
import fuelAndOil from './fuelAndOil';
import fuelsAndOils from './fuelsAndOils';
import sidebar from './sidebar';
import journals from './journals';
import journal from './journal';
import company from './company';
import contractor from './contractor';
import contractors from './contractors';
import contractVehicle from './contractVehicle';
import maintenanceOperationGroups from './maintenanceOperationGroups';
import maintenanceOperationGroup from './maintenanceOperationGroup';
import maintenanceOperation from './maintenanceOperation';
import maintenance from './maintenance';
import maintenances from './maintenances';
import repairOrder from './repairOrder';
import role from './role';
import roles from './roles';
import monitoring from './monitoring';
import contractVehicleOrder, {
  type ContractVehicleOrderState
} from './contractVehicleOrder';
import { default as mdmNode, type MdmNodeState } from './mdmNode';
import fuelMultiplier from './fuelMultiplier';

import type {
  OrgUnitNode,
  MaintenanceOperationGroup,
  MaintenanceOperation
} from '../lib/types';
import type { VehicleState } from './vehicle';
import type { OrderState } from './order';
import type { ShiftState } from './shift';
import type { TripState } from './trip';
import type { TripState as WialonTripState } from './wialonTrip';
import type { VehicleModelState } from './vehicleModel';
import type { VehicleModelsState } from './vehicleModels';
import type { EmployeeState } from './employee';
import type { FuelAndOilState } from './fuelAndOil';
import type { FuelsAndOilsState } from './fuelsAndOils';
import type { JournalsState } from './journals';
import type { JournalState } from './journal';
import type { CompanyState } from './company';
import type { ContractorsState } from './contractors';
import type { ContractorState } from './contractor';
import type { ContractVehicleState } from './contractVehicle';
import type { SidebarState } from './sidebar';
import type { MaintenanceState } from './maintenance';
import type { MaintenancesState } from './maintenances';
import type { RepairOrderState } from './repairOrder';
import type { RoleState } from './role';
import type { RolesState } from './roles';
import type { MonitoringState } from './monitoring';
import type { FuelMultiplierState } from './fuelMultiplier';
import jobTitle, { type JobTitleState } from './jobTitle';
import jobTitles, { type JobTitlesState } from './jobTitles';
import osagoCalculation, {
  type OsagoCalculationState
} from './osagoCalculation';
import vehiclePlan, { type VehiclePlanState } from './vehiclePlan';
import vehicleTaxPlan  from './vehicleTaxPlan';
import detailedDefectiveStatement from './detailedDefectiveStatement';
import persistFilters, { type PersistFiltersState } from './persistFilters';
import wialonStore, { type WialonStoreState } from './wialonStore';

// Стейт списков
export type ListState<T> = {
  data: T[],
  page: number,
  pageSize: number,
  totalCount: number
};

export type Action = {
  type: string,
  payload: any
};

// Стейт приложения
export type AppState = {
  auth: AuthState,
  employee: EmployeeState,
  orgUnits: OrgUnitNode,
  vehicle: VehicleState,
  order: OrderState,
  shift: ShiftState,
  trip: TripState,
  wialonTrip: WialonTripState,
  vehicleModel: VehicleModelState,
  vehicleModels: VehicleModelsState,
  fuelAndOil: FuelAndOilState,
  fuelsAndOils: FuelsAndOilsState,
  journals: JournalsState,
  journal: JournalState,
  company: CompanyState,
  contractor: ContractorState,
  contractors: ContractorsState,
  contractVehicle: ContractVehicleState,
  sidebar: SidebarState,
  maintenanceOperationGroups: MaintenanceOperationGroup,
  maintenanceOperationGroup: MaintenanceOperationGroup,
  maintenanceOperation: MaintenanceOperation,
  maintenance: MaintenanceState,
  maintenances: MaintenancesState,
  repairOrder: RepairOrderState,
  role: RoleState,
  roles: RolesState,
  monitoring: MonitoringState,
  contractVehicleOrder: ContractVehicleOrderState,
  mdmNode: MdmNodeState,
  fuelMultiplier: FuelMultiplierState,
  jobTitle: JobTitleState,
  jobTitles: JobTitlesState,
  osagoCalculation: OsagoCalculationState,
  persistFilters: PersistFiltersState,
  vehiclePlan: VehiclePlanState,
  wialonStore: WialonStoreState
};

const rootReducer = combineReducers({
  auth,
  orgUnits,
  employee,
  vehicle,
  order,
  shift,
  wialonTrip,
  trip,
  vehicleModel,
  vehicleModels,
  fuelAndOil,
  fuelsAndOils,
  journals,
  journal,
  company,
  contractor,
  contractors,
  contractVehicle,
  sidebar,
  maintenanceOperationGroups,
  maintenanceOperationGroup,
  maintenanceOperation,
  maintenance,
  maintenances,
  repairOrder,
  role,
  roles,
  monitoring,
  contractVehicleOrder,
  mdmNode,
  fuelMultiplier,
  jobTitle,
  jobTitles,
  osagoCalculation,
  vehiclePlan,
  vehicleTaxPlan,
  detailedDefectiveStatement,
  persistFilters,
  wialonStore
});

const defaultCfg = {
  key: 'primary',
  storage,
  whitelist: ['persistFilters', 'wialonStore']
};

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initStore = (persistCfg: Object = {}) => {
  const cfg = Object.assign(defaultCfg, persistCfg);
  const persistedReducer = persistReducer(cfg, rootReducer);
  const store = createStore(
    persistedReducer,
    composeEnhancer(applyMiddleware(thunk, logger))
  );

  const persistLocalStorageKey = `persist:${cfg.key}`;

  const persistor = persistStore(store);
  return {
    store,
    persistor,
    persistStore: JSON.parse(
      window.localStorage.getItem(persistLocalStorageKey)
    )
  };
};
