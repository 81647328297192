// @flow
import React, { useState, useCallback } from 'react';

import { goBack } from '../../../lib/helpers';
import { pddQuestionApi } from '../../../lib/api';
import { type PddQuestion } from '../../../lib/types';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrlForEdit } from '../../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/pdd/questions/';

export default (props: PageProps) => {
  const [data: PddQuestion, setData] = useState({
    id: props.id,
    answers: null
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, props.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await pddQuestionApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: PddQuestion) => {
    let id = payload.id;
    if (id) {
      await pddQuestionApi.update(payload);
    } else {
      let data = await pddQuestionApi.add(payload);
      id = data.id;
    }
    await goBack(`${entryPointPath}/${id}`);
  };

  const onCancel = async () => {
    await goBack(`${entryPointPath}`);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: props.id ? `Вопрос ${props.id}` : 'Новый вопрос ПДД'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
