// @flow

import type { Dispatch } from 'redux';

import type { OsagoCalculation } from './../lib/types';
import type { Action } from './redux';

export const ADD_OSAGO_CALCULATION = 'vehicles/osago-calculations/add';
export const UPDATE_OSAGO_CALCULATION = 'vehicles/osago-calculations/update';
export const DELETE_OSAGO_CALCULATION = 'vehicles/osago-calculations/delete';
export const SET_SELECTED_VEHICLES =
  'vehicles/osago-calculations/set-selected-vehicles';
export const ADD_FILTER_OSAGO_CALCULATION =
  'vehicles/osago-calculations/filter/add';
export const ADD_UNSELECT_OSAGO_CALCULATION =
  'vehicles/osago-calculations/unselect/add';
export const SET_FILTER_OSAGO_CALCULATION =
  'vehicles/osago-calculations/filter/set';
export const SET_UNSELECT_OSAGO_CALCULATION =
  'vehicles/osago-calculations/unselect/set';
export const CLEAR = 'vehicles/osago-calculations/clear';

export type OsagoCalculationState = {
  freeVehicleItems: OsagoCalculation[],
  selectedVehicleIds: number[],
  filter: any,
  unSelectedVehicleIds: any
};

const defaultState: OsagoCalculationState = {
  selectedVehicleIds: [],
  freeVehicleItems: [],
  filter: {},
  unSelectedVehicleIds: {}
};

const reducer = (
  state: OsagoCalculationState = defaultState,
  { type, payload }: Action
): OsagoCalculationState => {
  switch (type) {
    case SET_UNSELECT_OSAGO_CALCULATION:
      return {
        ...state,
        unSelectedVehicleIds: payload.unSelectedVehicleIds
      };
    case ADD_UNSELECT_OSAGO_CALCULATION:
      return {
        ...state,
        unSelectedVehicleIds: {
          ...state.unSelectedVehicleIds,
          ...payload.unSelectedVehicleIds
        }
      };
    case SET_FILTER_OSAGO_CALCULATION:
      return {
        ...state,
        filter: payload.filter
      };
    case ADD_FILTER_OSAGO_CALCULATION:
      return {
        ...state,
        filter: { ...state.filter, ...payload.filter }
      };
    case ADD_OSAGO_CALCULATION:
      return {
        ...state,
        freeVehicleItems: [...state.freeVehicleItems, payload.osagoCalculation]
      };
    case DELETE_OSAGO_CALCULATION:
      return {
        ...state,
        freeVehicleItems: state.freeVehicleItems.filter(
          (item: OsagoCalculation) => item.id !== payload.id
        )
      };
    case SET_SELECTED_VEHICLES:
      return {
        ...state,
        selectedVehicleIds: payload.selectedVehicleIds
      };
    case UPDATE_OSAGO_CALCULATION:
      const freeVehicleItems = [...state.freeVehicleItems];
      const index = freeVehicleItems.findIndex(
        (item: OsagoCalculation) => item.id === payload.osagoCalculation.id
      );
      if (index >= 0) {
        freeVehicleItems.splice(index, 1, {
          ...freeVehicleItems[index],
          ...payload.osagoCalculation
        });
        return {
          ...state,
          freeVehicleItems
        };
      }
      return state;
    case CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export const setFilterOsagoCalculation = (filter: any) => async (
  dispatch: Dispatch
): Promise<any> =>
  dispatch({
    type: SET_FILTER_OSAGO_CALCULATION,
    payload: {
      filter
    }
  });

export const addFilterOsagoCalculation = (filter: any) => async (
  dispatch: Dispatch
): Promise<any> =>
  dispatch({
    type: ADD_FILTER_OSAGO_CALCULATION,
    payload: {
      filter
    }
  });

export const setUnselectOsagoCalculation = (
  unSelectedVehicleIds: any
) => async (dispatch: Dispatch): Promise<any> =>
  dispatch({
    type: SET_UNSELECT_OSAGO_CALCULATION,
    payload: {
      unSelectedVehicleIds
    }
  });

export const addUnselectOsagoCalculation = (
  unSelectedVehicleIds: any
) => async (dispatch: Dispatch): Promise<any> =>
  dispatch({
    type: ADD_UNSELECT_OSAGO_CALCULATION,
    payload: {
      unSelectedVehicleIds
    }
  });

export const addOsagoCalculation = (
  osagoCalculation: OsagoCalculation
) => async (dispatch: Dispatch): Promise<OsagoCalculation> =>
  dispatch({
    type: ADD_OSAGO_CALCULATION,
    payload: {
      osagoCalculation
    }
  });

export const updateOsagoCalculation = (
  osagoCalculation: OsagoCalculation
) => async (dispatch: Dispatch): Promise<OsagoCalculation> =>
  dispatch({
    type: UPDATE_OSAGO_CALCULATION,
    payload: {
      osagoCalculation
    }
  });

export const setSelectedVehicles = (selectedVehicleIds: number[]) => async (
  dispatch: Dispatch
): Promise<void> =>
  dispatch({
    type: SET_SELECTED_VEHICLES,
    payload: {
      selectedVehicleIds
    }
  });

export const deleteOsagoCalculation = (id: number) => async (
  dispatch: Dispatch
): Promise<void> =>
  dispatch({
    type: DELETE_OSAGO_CALCULATION,
    payload: {
      id
    }
  });

export const clearData = () => async (dispatch: Dispatch): Promise<void> =>
  dispatch({
    type: CLEAR
  });

export default reducer;
