import styled from 'styled-components';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { Icon } from '../../../../components/ui';

export const Wrapper = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  margin: 6px;
`;
export const Header = styled(Grid)`
  background: #3d4042;
  color: white;
  padding: 0 16px;
  min-height: 40px;
`;
export const HeaderItem = styled(GridItem)`
  display: flex;
  align-items: center;
`;
export const Content = styled.main``;
export const CloseIcon = styled(Icon).attrs(() => ({
  type: 'x',
  size: 12,
  color: 'white'
}))`
  position: absolute;
  right: 14px;
  top: 8px;
  cursor: pointer;
`;
