// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { tripApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await tripApi.fetchTrips({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={tripApi.fetchTrip}
    notFoundText="Путевые не найдены"
    placeholder="Выберите путевой"
    renderOption={(trip: any, Option) => (
      <Option key={trip.id} value={trip.id} trip={trip}>
        {trip.idNumber}
      </Option>
    )}
    {...selectProps}
  />
);
