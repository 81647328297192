// @flow
import type { WriteOffAct } from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (writeOffAct: WriteOffAct): WriteOffAct => ({
  ...writeOffAct,
  sum: convertFromRubToKop(writeOffAct.sum)
});

export const convertDataFromServer = (
  writeOffAct: WriteOffAct
): WriteOffAct => ({
  ...writeOffAct,
  sum: convertFromKopToRub(writeOffAct.sum)
});

const basedUrl = 'writeOffAct';
const writeOffAct = new CommonApi<WriteOffAct>(
  basedUrl,
  'акт списания МПЗ',
  convertDataToServer,
  convertDataFromServer
);

export const addSeparateAct = async (
  data: WriteOffAct
): Promise<WriteOffAct> => {
  const writeOffAct = await fetchRequest.post(`/${basedUrl}/separateAct`, data);
  if (writeOffAct) {
    return convertDataFromServer(writeOffAct);
  }
  throw new Error('Не удалось сформировать акт списания МПЗ');
};

export default {
  ...writeOffAct,
  addSeparateAct
};
