// @flow
import Button from 'antd/lib/button';
import moment from 'moment';
import React from 'react';

import { Filter } from '../../../../components';
import { MonthPicker } from '../../../../components/ant/DatePicker';
import Field from '../../../../components/card/Field';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import { InputNumber } from '../../../../components/inputs';
import Grid, { GridItem } from '../../../../components/layout/Grid';

import { FilterButtons } from '../../elements';

export type MonthDailyBudgetFilterParams = $Shape<{
  startDate: string,
  endDate: string
}>;

type Props = {
  filter: MonthDailyBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ContractVehicleDailyBudgetFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <Grid
    gutter="16px"
    media={[
      {
        size: 'md',
        cols: 3
      },
      {
        size: 'lg',
        cols: ['200px', '100px', '1fr', '1fr']
      }
    ]}
  >
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter
      }: FilterChildrenParams<any>) => (
        <>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="Месяц">
              <MonthPicker
                size="small"
                format="MMMM YYYY"
                placeholder="Выберите месяц"
                value={moment(values.startDate)}
                onChange={value => {
                  const startDate = moment
                    .utc(value || undefined)
                    .startOf('month');
                  const endDate = moment.utc(value || undefined).endOf('month');
                  changeValue('startDate', startDate.toISOString());
                  changeValue('endDate', endDate.toISOString());
                  changeValue('mode', 'byMonth');
                }}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="Год">
              <InputNumber
                size="small"
                min={2018}
                value={moment.utc(values.endDate).year()}
                onChange={(value: number) => {
                  const date = moment
                    .utc()
                    .month(moment(values.startDate).month())
                    .year(value);
                  changeValue('startDate', date.startOf('month').toISOString());
                  changeValue('endDate', date.endOf('month').toISOString());
                }}
              />
            </Field>
          </GridItem>
          <FilterButtons>
            <Button type="primary" onClick={applyFilter}>
              Применить
            </Button>
            <Button type="plain" onClick={cleanFilter}>
              Очистить
            </Button>
          </FilterButtons>
        </>
      )}
    </Filter>
  </Grid>
);

export default ContractVehicleDailyBudgetFilter;
