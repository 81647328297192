// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { expenseDirectionApi } from './../../lib/api';
import type { ExpenseDirection } from './../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any, optionKey?: string };

export default class extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.value !== this.props.value;
  }

  render() {
    const { filter, optionKey, ...selectProps } = this.props;
    return (
      <AutocompleteSelect
        fetch={async params => {
          return await expenseDirectionApi.fetchExpenseDirection({
            ...filter,
            ...params
          });
        }}
        refetchParams={{ filter }}
        fetchSingle={expenseDirectionApi.fetchExpenseDirection}
        notFoundText="Наименование не найдено"
        placeholder="Выберите наименование расхода"
        renderOption={(expenseDirection: ExpenseDirection, Option) => (
          <Option
            key={`${optionKey}${expenseDirection.id}`}
            value={expenseDirection.id}
          >
            {expenseDirection.name}
          </Option>
        )}
        {...selectProps}
      />
    );
  }
}
