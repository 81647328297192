// @flow

import type {
  Maintenance,
  Vehicle,
  MaintenanceInfoForVehicle,
} from './../types';
import type { VehicleWithWashingPrices } from './../types/vehicle';

import type { FetchListParams, ListResponse } from './index';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams,
} from './index';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromKopToRub,
  convertFromMToKm,
  convertFromRubToKop,
  convertFromSecondToHour,
} from './../helpers';
import type { VehicleFilterParams } from '../../containers/Vehicles/Filter';
import { convertDataFromServer as convertVehicleModelFromServer } from './vehicleModel';
import type { MaintenanceSchedule } from '../types';

export const convertDataFromServer = (
  vehicle: Vehicle | VehicleWithWashingPrices
) => ({
  ...vehicle,
  kilometrage: convertFromMToKm(vehicle.kilometrage),
  beginningOfTheYearKilometrage: convertFromMToKm(
    vehicle.beginningOfTheYearKilometrage
  ),
  waybillKilometrage: convertFromMToKm(vehicle.waybillKilometrage),
  averageKilometrage: convertFromMToKm(vehicle.averageKilometrage),
  averageDailyKilometrage: convertFromMToKm(vehicle.averageDailyKilometrage),
  kilometrageFromLastOverhaul: convertFromMToKm(
    vehicle.kilometrageFromLastOverhaul
  ),
  normKilometrage: convertFromMToKm(vehicle.normKilometrage),
  initialCost: convertFromKopToRub(vehicle.initialCost),
  residualValue: convertFromKopToRub(vehicle.residualValue),
  partOfCostsMaintenanceFromInitialCost: convertFromKopToRub(
    vehicle.partOfCostsMaintenanceFromInitialCost
  ),
  maintenanceCosts: convertFromKopToRub(vehicle.maintenanceCosts),
  overhaulCosts: convertFromKopToRub(vehicle.overhaulCosts),
  totalRepairCosts: convertFromKopToRub(vehicle.totalRepairCosts),
  costPerHourForCompanies: convertFromKopToRub(vehicle.costPerHourForCompanies),
  outputRatio: parseFloat((vehicle.outputRatio || 0).toFixed(2)),
  engineWorkHours: convertFromSecondToHour(vehicle.engineWorkHours),
  averageEngineWorkHours: convertFromSecondToHour(
    vehicle.averageEngineWorkHours
  ),
  normEngineWorkHours: convertFromSecondToHour(vehicle.normEngineWorkHours),
  averageDailyEngineWorkHours: convertFromSecondToHour(
    vehicle.averageDailyEngineWorkHours
  ),
  engineWorkHoursFromLastOverhaul: convertFromSecondToHour(
    vehicle.engineWorkHoursFromLastOverhaul
  ),
  vehicleModel: convertVehicleModelFromServer(vehicle.vehicleModel),
  osago: vehicle.osago
    ? {
      ...vehicle.osago,
      paymentAmount: vehicle.osago
        ? convertFromKopToRub(vehicle.osago.paymentAmount)
        : 0,
    }
    : vehicle.osago,
  maintenance: vehicle.maintenance?.map((item: Maintenance) => ({
    ...item,
    totalSum: convertFromKopToRub(item.totalSum),
  })),
});

const convertDataToServer = (vehicle: Vehicle) => ({
  ...vehicle,
  kilometrage: convertFromKmToM(vehicle.kilometrage),
  beginningOfTheYearKilometrage: convertFromKmToM(
    vehicle.beginningOfTheYearKilometrage
  ),
  waybillKilometrage: convertFromKmToM(vehicle.waybillKilometrage),
  averageKilometrage: convertFromKmToM(vehicle.averageKilometrage),
  averageDailyKilometrage: convertFromKmToM(vehicle.averageDailyKilometrage),
  kilometrageFromLastOverhaul: convertFromKmToM(
    vehicle.kilometrageFromLastOverhaul
  ),
  normKilometrage: convertFromKmToM(vehicle.normKilometrage),
  initialCost: convertFromRubToKop(vehicle.initialCost),
  residualValue: convertFromRubToKop(vehicle.residualValue),
  partOfCostsMaintenanceFromInitialCost: convertFromRubToKop(
    vehicle.partOfCostsMaintenanceFromInitialCost
  ),
  maintenanceCosts: convertFromRubToKop(vehicle.maintenanceCosts),
  overhaulCosts: convertFromRubToKop(vehicle.overhaulCosts),
  totalRepairCosts: convertFromRubToKop(vehicle.totalRepairCosts),
  costPerHourForCompanies: convertFromRubToKop(vehicle.costPerHourForCompanies),
  engineWorkHours: convertFromHourToSecond(vehicle.engineWorkHours),
  averageEngineWorkHours: convertFromHourToSecond(
    vehicle.averageEngineWorkHours
  ),
  normEngineWorkHours: convertFromHourToSecond(vehicle.normEngineWorkHours),
  averageDailyEngineWorkHours: convertFromHourToSecond(
    vehicle.averageDailyEngineWorkHours
  ),
  engineWorkHoursFromLastOverhaul: convertFromHourToSecond(
    vehicle.engineWorkHoursFromLastOverhaul
  ),
  osago: {
    ...vehicle.osago,
    paymentAmount: vehicle.osago
      ? convertFromRubToKop(vehicle.osago.paymentAmount)
      : 0,
  },
});

export const addVehicle = async (vehicle: Vehicle): Promise<Vehicle> => {
  const added = await fetchRequest.post(
    '/vehicle',
    convertDataToServer(vehicle)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать ТС');
};

export const updateVehicle = async (vehicle: Vehicle): Promise<Vehicle> => {
  const updated = await fetchRequest.put(
    '/vehicle',
    convertDataToServer(vehicle)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить ТС');
};

export const fetchVehicles = async (
  params: FetchListParams<VehicleFilterParams> = initialFetchListParams
): Promise<ListResponse<Vehicle>> => {
  const vehicles = await fetchRequest.get('/vehicle', {
    ...initialFetchListParams,
    ...params,
  });
  return {
    ...vehicles,
    data: vehicles.data.map(convertDataFromServer),
  };
};

export const fetchVehiclesForWashingPlan = async (
  params: FetchListParams<VehicleFilterParams> = initialFetchListParams,
  monthDate: string
): Promise<ListResponse<VehicleWithWashingPrices>> => {
  const vehicles = await fetchRequest.get(`/vehicle/${monthDate}/prices`, {
    ...initialFetchListParams,
    ...params,
  });
  return {
    ...vehicles,
    data: vehicles.data.map(convertDataFromServer),
  };
};

export const fetchVehicle = async (
  id: number,
  params: any = {}
): Promise<Vehicle> => {
  const vehicle = await fetchRequest.get(`/vehicle/${id}`, params);
  if (vehicle) return convertDataFromServer(vehicle);
  throw new Error('Не удалось загрузить ТС');
};

export const deleteVehicle = async (id: number): Promise<Vehicle> => {
  const deleted = await fetchRequest.delete(`/vehicle/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить ТС');
};

export const setDrivingVehicle = async (
  id: number,
  drivingVehicleId: ?number
) => {
  const updated = await fetchRequest.post(
    `/vehicle/${id}/drivingVehicle/${drivingVehicleId || ''}`
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось удалить ТС');
};

export const updateFromHozu = async (vehicleId: number): Promise<Vehicle> => {
  const updated = await fetchRequest.put(
    `/vehicle/updateFromHozu/${vehicleId}`
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить данные по ТС');
};

export const print = async (
  params: FetchListParams<VehicleFilterParams>
): Promise<boolean> => {
  const printed = await downloadRequestWithToken('/vehicle/print', {
    ...params,
  });
  if (printed) return printed;
  throw new Error('Не удалось распечатать отчет');
};

export const getMaintenanceSchedule = async (
  date: string,
  params: any = {}
): Promise<MaintenanceSchedule[]> => {
  const vehicle = await fetchRequest.get(
    `/vehicle/getMaintenanceSchedule/${date}`,
    params
  );
  if (vehicle) return vehicle;
  throw new Error('Не удалось загрузить график');
};

export const getMaintenanceScheduleByDate = async (
  date: string,
  params: any = {}
): Promise<MaintenanceSchedule[]> => {
  const vehicles = await fetchRequest.get(
    `/vehicle/getMaintenanceScheduleByDate/${date}`,
    params
  );
  if (vehicles)
    return vehicles.map((vehicle: any) => ({
      ...vehicle,
      kilometrage: vehicle.kilometrage
        ? convertFromMToKm(vehicle.kilometrage)
        : vehicle.kilometrage,
      plannedKilometrage: vehicle.plannedKilometrage
        ? convertFromMToKm(vehicle.plannedKilometrage)
        : vehicle.plannedKilometrage,
    }));
  throw new Error('Не удалось загрузить график');
};

export const getTechnicalFluidByDate = async (
  vehicleId: number,
  date: string
): Promise<{
  oil: number,
  antifreeze: number,
  windshieldWasherFluid: number,
}> => {
  const technicalFluid = await fetchRequest.get(
    `/vehicle/${vehicleId}/getTechnicalFluidByDate/${date}`
  );
  if (technicalFluid) return technicalFluid;
  throw new Error('Не удалось посчитать жидкости по талонам');
};

export const fillNextToDate = async (vehicle: Vehicle): Promise<Vehicle> => {
  const updated = await fetchRequest.put('/vehicle/nextToDate/fill', vehicle);
  if (updated) return updated;
  throw new Error('Не удалось установить день ТО');
};

export const getMaintenanceInfoForVehicle = async (
  vehicleId: number
): Promise<MaintenanceInfoForVehicle> => {
  const info = await fetchRequest.get(
    `/vehicle/getMaintenanceInfoForVehicle/${vehicleId}`
  );
  if (info)
    return {
      ...info,
      lastTo1Kilometrage: Math.ceil(convertFromMToKm(info.lastTo1Kilometrage)),
      lastTo1WorkEngineHours: Math.ceil(convertFromSecondToHour(info?.lastTo1WorkEngineHours ?? 0)),
      nextTo1PlannedKilometrage: Math.ceil(convertFromMToKm(
        info.nextTo1PlannedKilometrage
      )),
      nextTo1PlannedWorkEngineHours: Math.ceil(convertFromSecondToHour(info?.nextTo1PlannedWorkEngineHours ?? 0)),
      lastTo2Kilometrage: Math.ceil(convertFromMToKm(info.lastTo2Kilometrage)),
      lastTo2WorkEngineHours: Math.ceil(convertFromSecondToHour(info?.lastTo2WorkEngineHours ?? 0)),
      nextTo2PlannedKilometrage: Math.ceil(convertFromMToKm(
        info.nextTo2PlannedKilometrage
      )),
      nextTo2PlannedWorkEngineHours: Math.ceil(convertFromSecondToHour(info?.nextTo2PlannedWorkEngineHours ?? 0)),
    };
  throw new Error('Не удалось получить информацию по датам и пробегам ТО');
};

const getVehicles = async (params: any) => {
  const getVehicles = await fetchRequest.get(
    `/vehicle/vehicleTaxPlan/getVehicles`,
    params
  );
  if (getVehicles) return getVehicles;
  throw new Error('Не удалось получить данные по машинам');
};

const getSeasonMultipliers = async () => {
  const seasonMultipliers = await fetchRequest.get(
    `/vehicle/seasonMultipliers`
  );
  if (seasonMultipliers) return seasonMultipliers;
  throw new Error('Не удалось получить данные по сезонным коэффициентам');
};

export default {
  getSeasonMultipliers,
  getMaintenanceInfoForVehicle,
  getTechnicalFluidByDate,
  addVehicle,
  getVehicles,
  updateVehicle,
  fetchVehicles,
  deleteVehicle,
  fetchVehicle,
  setDrivingVehicle,
  print,
  fetchVehiclesForWashingPlan,
  updateFromHozu,
  getMaintenanceScheduleByDate,
  getMaintenanceSchedule,
  fillNextToDate,
};
