// @flow

import React, { Component } from 'react';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { type FormikProps } from 'formik';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import { Form } from './../../components';
import type { Credentials, FormErrors } from './../../lib/types';
import type { goBack } from './../../lib/helpers';
import { login } from './../../ducks/auth';

type Props = {
  submit: Function,
  values: Credentials,
  handleChange: Function,
  handleBlur: Function,
  errors: Credentials,
  touched: Credentials,
  handleSubmit: Function,
  login: Function,
};

class LoginForm extends Component<Props> {
  render() {
    return (
      <Form
        validate={(values: Credentials) => {
          let errors: FormErrors<Credentials> = {};
          if (!values.userName) {
            errors.userName = 'Обязательно для заполнения';
          }
          if (!values.password) {
            errors.password = 'Обязательно для заполнения';
          }

          return errors;
        }}
        onSubmit={async (values: Credentials, { setErrors }) => {
          try {
            await this.props.login(values);
            navigate(window.history?.state?.uri ?? '/');
          } catch (err) {
            setErrors({
              userName: null,
              password: 'Неверные логин или пароль',
            });
          }
        }}
      >
        {(FormField, formikProps: FormikProps) => {
          const { handleSubmit, isSubmitting } = formikProps;
          return (
            <StyledForm onSubmit={handleSubmit}>
              <Wrapper>
                <FormField label="E-mail" name="userName">
                  {(field) => (
                    <Input
                      {...field}
                      placeholder="Введите e-mail"
                      size="large"
                    />
                  )}
                </FormField>
                <FormField label="Пароль" name="password">
                  {(field) => (
                    <Input
                      {...field}
                      placeholder="Введите пароль"
                      size="large"
                      type="password"
                    />
                  )}
                </FormField>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  data-cy="login"
                  style={{ marginTop: 10 }}
                >
                  Войти
                </Button>
              </Wrapper>
            </StyledForm>
          );
        }}
      </Form>
    );
  }
}

export default connect(null, {
  login,
})(LoginForm);

const StyledForm = styled.form`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`;

const Wrapper = styled.div`
  width: 100%;
`;
