// @flow
import React from 'react';

import { ListTable } from '../../../../components/ui';

import type {
  DetailedDefectiveStatement,
  OrderContractorType,
} from '../../../../lib/types';
import { itog, partColumns } from '../elements';

import { sumPartsAndConsumbles } from '../lib';

type Props = {
  orderContractorType: OrderContractorType,
  detailedDefectiveStatement: DetailedDefectiveStatement,
};

/**
 * Запчасти и расходные материалы
 */
export default class PartsAndConsumables extends React.Component<Props> {
  static defaultProps = {
    detailedDefectiveStatement: null,
  };

  render() {
    const { detailedDefectiveStatement } = this.props;
    const data = detailedDefectiveStatement.parts;
    const { sum, sumContractor } = sumPartsAndConsumbles(data);
    return (
      <>
        <ListTable columns={partColumns(undefined, true)} data={data} />
        {itog(sum, sumContractor)}
      </>
    );
  }
}
