// @flow
import type { BioTreatmentFacilityContract } from '../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams,
} from './index';
import type { FetchListParams, ListResponse } from './index';
import {
  convertDataToServer as convertDataToServerVolume,
  convertDataFromServer as convertDataFromServerVolume,
} from './bioTreatmentFacilityVolume';

const convertDataToServer = (
  bioTreatmentFacility: BioTreatmentFacilityContract
) => ({
  ...bioTreatmentFacility,
  volumes: bioTreatmentFacility?.volumes?.map(convertDataToServerVolume) ?? [],
});
const convertDataFromServer = (
  bioTreatmentFacility: BioTreatmentFacilityContract
) => ({
  ...bioTreatmentFacility,
  volumes:
    bioTreatmentFacility?.volumes?.map(convertDataFromServerVolume) ?? [],
});

const baseUrl = 'bioTreatmentFacilityContract';
const bioTreatmentFacilityContract =
  new CommonApi<BioTreatmentFacilityContract>(
    baseUrl,
    'учет БОС',
    convertDataToServer,
    convertDataFromServer
  );

const list = async (params: FetchListParams<any> = initialFetchListParams) => {
  const list = await fetchRequest.get(`/${baseUrl}/list`, {
    ...initialFetchListParams,
    ...params,
  });
  if (list) {
    return list;
  }
  throw new Error('Не удалось получить список');
};

const print = async (filter: any) => {
  const printed = await downloadRequestWithToken(`/${baseUrl}/print`, {
    ...filter,
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

const printYear = async (year: number) => {
  const printed = await downloadRequestWithToken(
    `/${baseUrl}/printYear?year=${year}`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

const report = async (
  params: FetchListParams<any> = initialFetchListParams
) => {
  const list = await fetchRequest.get(`/${baseUrl}/report`, {
    ...initialFetchListParams,
    ...params,
  });
  if (list) {
    return list;
  }
  throw new Error('Не удалось получить список');
};

export default {
  ...bioTreatmentFacilityContract,
  print,
  list,
  printYear,
  report,
};
