// @flow
import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import type { StockPart } from '../../../lib/types';
import Form from '../../../components/Form';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import Selects from './../../../components/selects';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import { multipliedBy, formatRub } from '../../../lib/helpers';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const { SparePartSelect } = Selects;

type Props = {
  stockPart: ?StockPart,
  onCancel: Function,
  onSubmit: Function,
};

export default ({ stockPart, onCancel, onSubmit }: Props) => {
  return (
    <Form initialValues={stockPart} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          values,
          dirty,
          isSubmitting,
        }: FormikProps
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px">
              <GridItem>
                {values.id && !values.sparePartId ? (
                  <FormField required label="Наименование детали" name="name">
                    {(field) => <Input {...field} />}
                  </FormField>
                ) : (
                  <FormField
                    required
                    label="Наименование детали"
                    name="sparePartId"
                  >
                    {({ name, value }) => (
                      <SparePartSelect
                        value={value}
                        onChange={(value, option) => {
                          setFieldValue(name, value);
                          setFieldValue('sparePart', option.props.part);
                          setFieldValue('name', option.props.part.name);
                          setFieldValue('cost', option.props.part.price);
                          setFieldValue(
                            'number',
                            option.props.part.catalogNumber
                          );
                        }}
                      />
                    )}
                  </FormField>
                )}
              </GridItem>
              <GridItem>
                {values.id && !values.sparePartId ? (
                  <FormField required label="Номер детали" name="name">
                    {(field) => <Input {...field} />}
                  </FormField>
                ) : (
                  <FormField
                    label="Номер детали"
                    name="sparePart.catalogNumber"
                  >
                    {(field) => <Input disabled value={field.value} />}
                  </FormField>
                )}
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px">
                  <GridItem>
                    {values.id && !values.sparePartId ? (
                      <FormField
                        label="Стоимость ед. в руб. без НДС"
                        required
                        fast
                        name="cost"
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            onChange={(value) => {
                              setFieldValue(name, value);
                            }}
                          />
                        )}
                      </FormField>
                    ) : (
                      <FormField
                        label="Стоимость ед. в руб. без НДС"
                        name="sparePart.price"
                      >
                        {(field) => (
                          <div>
                            {formatRub(
                              values.cost ? values.cost : field.value ?? 0
                            )}
                          </div>
                        )}
                      </FormField>
                    )}
                  </GridItem>
                  {values.sparePart && (
                    <GridItem>
                      <FormField
                        label="Ед. измерения"
                        name="sparePart.unit.name"
                      >
                        {(field) => <Input disabled value={field.value} />}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField label="Количество" required fast name="count">
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          onChange={(value) => {
                            setFieldValue(name, value);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {!!values.cost && !!values.count && (
                    <GridItem fullWidth>
                      Сумма:{' '}
                      {formatRub(multipliedBy(values.count, values.cost))} руб.
                      без НДС
                    </GridItem>
                  )}
                </Grid>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {values.id ? 'Сохранить' : 'Создать'}
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
};
