// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import { RangePicker } from '../../../../components/ant/DatePicker';

import Spoiler from '../../../../components/ui/Spoiler';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import moment from 'moment';
import { formatDateTimeToISOString } from '../../../../lib/helpers';


const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  onChangeFilter: (key: $Keys<any>, value: any) => void,
  params: any,
  cleanParams: () => void,
  applyParams: () => Promise<any>,
  onExport: Function
};

/**
 * Фильтр с параметрами
 */
export default class Filter extends React.Component<Props> {
  render() {
    const { params, onExport } = this.props;
    return (
      <Spoiler defaultExpanded label="Параметры">
        <SpoilerContent>
          <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
            <GridItem>
              <Field label="Филиал">
                <OrgUnitSelect
                  value={params.orgUnitId}
                  onChange={id => this.props.onChangeFilter('orgUnitId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Отчетный период">
                <RangePicker
                  format="DD.MM.YYYY HH:mm"
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm')
                    ]
                  }}
                  placeholder={['Начало', 'Конец']}
                  value={[
                    params.startDate ? moment.utc(params.startDate) : null,
                    params.endDate ? moment.utc(params.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    this.props.onChangeFilter(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    this.props.onChangeFilter(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </GridItem>
            <Footer fullWidth>
              <Button type="primary" onClick={onExport}>
                Экспорт
              </Button>
              <Button type="primary" onClick={this.props.applyParams}>
                Применить
              </Button>
              <Button type="secondary" onClick={this.props.cleanParams}>
                Очистить
              </Button>
            </Footer>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}
