// @flow

import React from 'react';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import type { FormikProps } from 'formik';

import { Selects, Form } from '../../../components';
import type { VehicleModel } from '../../../lib/types';
import { Section, SectionTitle } from './../../../components/layout';
import { GridItem } from '../../../components/layout';
import {
  canShowOsagoNeeded,
  canShowAxlesNumber,
  canShowGrossWeight,
  canShowChassisConfiguration,
  getVehicleGroup,
  canShowAxleLoad,
  canShowCarrying,
  canShowEngineData,
  canShowPassengerSeatsNumber,
  isRequiredPassengerSeatsNumber,
  canShowEcologicClass,
  canShowVehicleCategory,
  canShowGpmData,
  canShowDrillingData,
  canShowFuelData,
  canShowSecondaryFuel,
  isRequiredAddPrimaryFuelType,
  isRequiredAddPrimaryFuelConsumption,
  isRequiredAddPrimaryEquipmentFuelConsumption
} from '../lib';
import { canShowEngineWorkHoursData } from '../../Vehicles/lib';
import {
  driverQualificationEnum,
  selfVehiclePlanVehicleTypeEnum,
  vehicleGroupEnum
} from '../../../lib/enum';
import CancelButton from '../../../components/CancelButton';
import { InputNumber } from '../../../components/inputs';
import {
  Footer,
  Content,
  StyledGrid,
  StyledInputNumber
} from './InnerForm.elements';
import { compareVehiclePlanMaintenanceType } from '../../../lib/helpers';

const {
  BrandsSelect,
  VehicleTypeSelect,
  DriverLicenseCategorySelect,
  ChassisConfigurationSelect,
  YesNoSelect,
  EngineTypeSelect,
  VehicleCategorySelect,
  FuelTypeSelect,
  DriverQualificationSelect
} = Selects;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  vehicleModel: ?VehicleModel
};

const InnerForm = ({ onSubmit, onCancel, vehicleModel }: FormProps) => (
  <Form initialValues={vehicleModel} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        values,
        dirty,
        isSubmitting
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <SectionTitle>Основное</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    label="Наименование модели"
                    required
                    name="name"
                    fast
                  >
                    {fieldProps => <Input {...fieldProps} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="brandId" label="Марка" required fast>
                    {({ name, value }) => (
                      <BrandsSelect
                        name={name}
                        value={value}
                        onChange={(brandId: number) =>
                          setFieldValue(name, brandId)
                        }
                        onBlur={() => handleBlur({ target: { name } })}
                        data-cy="brandSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип ТС" required fast name="type">
                    {({ name, value }) => (
                      <VehicleTypeSelect
                        name={name}
                        value={value}
                        onChange={(type: string) => setFieldValue(name, type)}
                        onBlur={() => handleBlur({ target: { name } })}
                        data-cy="typeSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    required
                    label="Необходимая квалификация водителя"
                    name="driverQualification"
                    fast
                  >
                    {({ value, name }) => (
                      <DriverQualificationSelect
                        exclude={[
                          driverQualificationEnum.bkmMachinistLicense,
                          driverQualificationEnum.dopogDriverLicense,
                          driverQualificationEnum.skziDriverMap
                        ]}
                        onChange={(value: string) => setFieldValue(name, value)}
                        onBlur={() => handleBlur({ target: { name } })}
                        value={value}
                        name={name}
                      />
                    )}
                  </FormField>
                </GridItem>
                {[
                  driverQualificationEnum.driverLicense,
                  driverQualificationEnum.tractorMachinistLicense
                ].includes(values.driverQualification) && (
                  <GridItem>
                    <FormField
                      label="Категория прав для ТС"
                      name="rightsCategory"
                      required
                    >
                      {({ name, value }) => (
                        <DriverLicenseCategorySelect
                          driverQualification={values.driverQualification}
                          name={name}
                          value={value}
                          multiple
                          onChange={(rightsCategory: string) =>
                            setFieldValue(name, rightsCategory)
                          }
                          placeholder="Выберите категорию прав"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </StyledGrid>
              {canShowFuelData(values.type) && (
                <>
                  <SectionTitle>Основное топливо</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Вид топлива"
                        required={isRequiredAddPrimaryFuelType(values.type)}
                        name="primaryFuelType"
                      >
                        {({ value, name }) => (
                          <FuelTypeSelect
                            name={name}
                            value={value}
                            onChange={(value: string) =>
                              setFieldValue(name, value)
                            }
                            onBlur={() => handleBlur({ target: { name } })}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Нормативный расход, л/100 км"
                        required={isRequiredAddPrimaryFuelConsumption(
                          values.type
                        )}
                        name="primaryFuelConsumption"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            max={99}
                            decimalSeparator=","
                            step={0.1}
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {canShowEngineWorkHoursData(values.type) && (
                      <GridItem>
                        <FormField
                          label="Нормативный расход л/маш.час работы оборудования"
                          name="primaryEquipmentFuelConsumption"
                          required={isRequiredAddPrimaryEquipmentFuelConsumption(
                            values.type
                          )}
                        >
                          {({ value, name }) => (
                            <InputNumber
                              max={99}
                              decimalSeparator=","
                              step={0.1}
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <FormField
                        label="Объем топливного бака, л"
                        required
                        hasFeedback={false}
                        name="primaryTankVolume"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            max={999}
                            decimalSeparator=","
                            step={0.1}
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </StyledGrid>
                  {canShowSecondaryFuel(values.type) && (
                    <>
                      <SectionTitle>Дополнительное топливо</SectionTitle>
                      <StyledGrid gutter="16px">
                        <GridItem>
                          <FormField
                            label="Вид топлива"
                            name="secondaryFuelType"
                          >
                            {({ value, name }) => (
                              <FuelTypeSelect
                                name={name}
                                value={value}
                                onChange={(value: string) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={() => handleBlur({ target: { name } })}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            label="Нормативный расход, л/100 км"
                            name="secondaryFuelConsumption"
                            fast
                          >
                            {({ value, name }) => (
                              <InputNumber
                                max={99}
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        {canShowEngineWorkHoursData(values.type) && (
                          <GridItem>
                            <FormField
                              label="Нормативный расход л/маш.час работы оборудования"
                              name="secondaryEquipmentFuelConsumption"
                              fast
                            >
                              {({ value, name }) => (
                                <InputNumber
                                  max={99}
                                  decimalSeparator=","
                                  step={0.1}
                                  value={value}
                                  name={name}
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        )}
                        <GridItem>
                          <FormField
                            label="Объем топливного бака, л"
                            fast
                            name="secondaryTankVolume"
                          >
                            {({ value, name }) => (
                              <InputNumber
                                max={999}
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      </StyledGrid>
                    </>
                  )}
                </>
              )}
              <SectionTitle>Шины</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    label="Количество автошин, шт"
                    name="wheelCount"
                    fast
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        step={1}
                        precision={0}
                        value={value}
                        onChange={(wheelCount: number) =>
                          setFieldValue(name, wheelCount)
                        }
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>
              <SectionTitle>Первичные данные</SectionTitle>
              <StyledGrid gutter="16px">
                {canShowChassisConfiguration(values.type) && (
                  <GridItem>
                    <FormField
                      label="Колесная формула"
                      fast
                      name="chassisConfiguration"
                      required={
                        getVehicleGroup(values.type) !== vehicleGroupEnum.atvs
                      }
                    >
                      {({ name, value }) => (
                        <ChassisConfigurationSelect
                          name={name}
                          value={value}
                          chassisConfiguration
                          onChange={(chassisConfiguration: string) =>
                            setFieldValue(name, chassisConfiguration)
                          }
                          data-cy="сhassisСonfigurationSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    label="Масса ТС без нагрузки, т"
                    name="unladenMass"
                    fast
                    validate={(value: number) => {
                      if (
                        canShowGrossWeight(values.type) &&
                        value > values.grossWeight
                      ) {
                        return 'Масса ТС без нагрузки не может быть больше полной массы ТС';
                      }
                    }}
                  >
                    {({ value, name }) => (
                      <StyledInputNumber
                        max={50}
                        decimalSeparator=","
                        name={name}
                        placeholder="Введите массу ТС без нагрузки"
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {canShowGrossWeight(values.type) && (
                  <GridItem>
                    <FormField
                      label="Полная масса ТС, т"
                      validate={(value: number) => {
                        if (value < values.unladenMass) {
                          return 'Полная масса ТС не может быть меньше массы ТС без нагрузки';
                        }
                      }}
                      fast
                      name="grossWeight"
                      required
                    >
                      {({ value, name }) => (
                        <StyledInputNumber
                          name={name}
                          decimalSeparator=","
                          max={80}
                          placeholder="Введите полную массу ТС"
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {canShowAxlesNumber(values.type) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Количество осей, шт"
                      name="axlesNumber"
                      required={
                        getVehicleGroup(values.type) !== vehicleGroupEnum.atvs
                      }
                    >
                      {({ value, name }) => (
                        <StyledInputNumber
                          placeholder="Введите количество осей"
                          name={name}
                          decimalSeparator=","
                          precision={0}
                          max={10}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {canShowAxleLoad(values.type) && (
                  <GridItem>
                    <FormField
                      label="Нагрузка на ось (перед/задн.), т"
                      name="axleLoad"
                      fast
                    >
                      {({ value, name }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          decimalSeparator=","
                          precision={1}
                          step={0.1}
                          max={15}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {canShowCarrying(values.type) && (
                  <GridItem>
                    <FormField
                      label="Грузоподъемность, т"
                      name="carrying"
                      fast
                      required
                    >
                      {({ value, name }) => (
                        <StyledInputNumber
                          max={40}
                          value={value}
                          decimalSeparator=","
                          step={0.1}
                          precision={1}
                          name={name}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField label="Габариты ТС, м" name="dimensions" fast>
                    {fieldProps => (
                      <Input {...fieldProps} placeholder="23.8x23.6x18.9" />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Разрешенная максимальная масса по ПТС, кг"
                    name="maximumAuthorizedMass"
                    fast
                    required
                  >
                    {({ value, name }) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        decimalSeparator=","
                        precision={0}
                        max={99999}
                        onChange={value => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {canShowEngineData(values.type) && (
                  <>
                    <GridItem>
                      <FormField
                        label="Тип двигателя"
                        name="engineType"
                        required={
                          getVehicleGroup(values.type) !==
                          vehicleGroupEnum.floatingTransport
                        }
                      >
                        {({ name, value }) => (
                          <EngineTypeSelect
                            name={name}
                            value={value}
                            onChange={(type: string) =>
                              setFieldValue(name, type)
                            }
                            onBlur={() => handleBlur({ target: { name } })}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        required={
                          getVehicleGroup(values.type) !==
                          vehicleGroupEnum.floatingTransport
                        }
                        label="Рабочий объем двигателя, куб. см"
                        name="engineCapacity"
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            max={20000}
                            value={value}
                            decimalSeparator=","
                            step={0.01}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Мощность двигателя, л.с."
                        name="enginePower"
                        required={
                          getVehicleGroup(values.type) !==
                          vehicleGroupEnum.floatingTransport
                        }
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            decimalSeparator=","
                            max={999}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Максимальная скорость (км/ч)"
                        name="maxSpeed"
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            max={250}
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </>
                )}
                {canShowPassengerSeatsNumber(values.type) && (
                  <GridItem>
                    <FormField
                      label="Кол-во пассажирский мест, шт"
                      name="passengerSeatsNumber"
                      required={isRequiredPassengerSeatsNumber(values.type)}
                    >
                      {({ value, name }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          precision={0}
                          max={70}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {canShowEcologicClass(values.type) && (
                  <GridItem>
                    <FormField
                      label="Экологический класс ТС"
                      name="ecologicClass"
                      fast
                      required
                    >
                      {({ value, name }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          precision={0}
                          min={2}
                          max={5}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {canShowVehicleCategory(values.type) && (
                  <GridItem>
                    <FormField
                      label="Категория ТС"
                      name="category"
                      fast
                      required
                    >
                      {({ name, value }) => (
                        <VehicleCategorySelect
                          name={name}
                          value={value}
                          onChange={(type: string) => setFieldValue(name, type)}
                          onBlur={() => handleBlur({ target: { name } })}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </StyledGrid>

              {canShowOsagoNeeded(values.type) && (
                <>
                  <SectionTitle>Признаки ТС</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField label="Подлежит ОСАГО" name="osagoNeeded" fast>
                        {({ value, name }) => (
                          <YesNoSelect
                            value={value}
                            name={name}
                            onChange={(value: boolean) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </StyledGrid>
                </>
              )}

              {canShowGpmData(values.type) && (
                <>
                  <SectionTitle>ГПМ</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Грузоподъемность ГПМ"
                        required
                        name="gpmCarrying"
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            precision={1}
                            max={999}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Высота подъема"
                        name="liftHeight"
                        required
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            precision={1}
                            max={99}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="gpmHandlingRadius"
                        label="Вылет стрелы"
                        required
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            precision={1}
                            max={99}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </StyledGrid>
                </>
              )}
              {canShowDrillingData(values.type) && (
                <>
                  <SectionTitle>Бурильная установка</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Глубина бурения (до мм)"
                        required
                        name="drillingDeep"
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            precision={0}
                            max={99999}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Диаметр бурения (до мм)"
                        name="drillingDiameter"
                        required
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            max={9999}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </StyledGrid>
                </>
              )}
              <SectionTitle>Тариф</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Постоянная часть тарифа, руб."
                    name="fixedRatePart"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Постоянная часть тарифа (в пути), руб"
                    name="fixedRateInTransit"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Переменная часть тарифа, руб"
                    name="fixedRateInDowntime"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Стоимость прогрева, руб"
                    name="warmingUpCost"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>

              {canShowFuelData(values.type) &&
                canShowSecondaryFuel(values.type) && (
                  <>
                    <SectionTitle>Тарифы для доп. топлива</SectionTitle>
                    <StyledGrid gutter="16px">
                      <GridItem>
                        <FormField
                          fast
                          label="Постоянная часть тарифа, руб."
                          name="secondaryFixedRatePart"
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          fast
                          label="Постоянная часть тарифа (в пути), руб"
                          name="secondaryFixedRateInTransit"
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          fast
                          label="Переменная часть тарифа, руб"
                          name="secondaryFixedRateInDowntime"
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          fast
                          label="Стоимость прогрева, руб"
                          name="secondaryWarmingUpCost"
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </StyledGrid>
                  </>
                )}
              <SectionTitle>Техобслуживание</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Трудоёмкость одного ТО (чел/час)"
                    name="workHours"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Средние затраты на материалы ТО, руб"
                    name="materialsCost"
                  >
                    {({ value, name }) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                {!!values.type &&
                  compareVehiclePlanMaintenanceType(
                    values.type,
                    selfVehiclePlanVehicleTypeEnum.kilometrage
                  ) && (
                    <GridItem>
                      <FormField
                        fast
                        label="Интервал проведения технического обслуживания (км)"
                        name="kilometrageInterval"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                {!!values.type &&
                  compareVehiclePlanMaintenanceType(
                    values.type,
                    selfVehiclePlanVehicleTypeEnum.workHours
                  ) && (
                    <GridItem>
                      <FormField
                        fast
                        label="Интервал проведения технического обслуживания (моточас)"
                        name="engineWorkInterval"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            value={value}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
              </StyledGrid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

export default InnerForm;
