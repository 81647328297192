// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Form, Selects } from './../../../components';
import type { FormFieldType } from './../../../components/Form';
import { Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { maintenanceApi, maintenanceMatrixApi } from './../../../lib/api';
import {
  maintenanceMatrixValidActionEnum,
  orderContractorTypeEnum,
  ownerTypes,
  repairPlanEnum,
  serviceTypeEnum,
} from './../../../lib/enum';

import type { Maintenance, OrderContractorType, RepairPlan } from './../../../lib/types';

const {
  VehicleSelect,
  RepairePlanSelect,
  OrderContractorTypeSelect,
  MaintenanceWorkTypeSelect,
  ContractorSelect
} = Selects;
const TextArea = Input.TextArea;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  initialValue: Maintenance
};

type Params = {
  maintenanceWorkTypeId: ?number,
  orderContractorType: ?OrderContractorType,
  repairPlan: ?RepairPlan
};

export default ({ onSubmit, initialValue }: FormProps) => {
  const [maintenanceMatrix, setMaintenanceMatrix] = useState(null);
  // отображать или нет подрядчика
  const showContractor = (maintenance: Maintenance) => {
    return (
      maintenance.orderContractorType === orderContractorTypeEnum.contractor
    );
  };
  // выводим предупреждение если нет направления расхода
  const checkExpenseDirection = async (params: Params) => {
    if (
      !!params.maintenanceWorkTypeId &&
      params.orderContractorType &&
      params.repairPlan
    ) {
      try {
        await maintenanceApi.getMaintenanceExpenseDirection(params);
      } catch (error) {
        notification.warn({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  };
  return (
    <Form initialValues={initialValue} enableReinitialize onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const {
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values
        } = formikProps;
        // очищаем значения для пошагового заполнения первичных данных
        if (
          values.vehicleId === undefined &&
          values.maintenanceWorkTypeId !== undefined
        ) {
          setFieldValue('type', undefined);
        }
        if (
          values.maintenanceWorkTypeId === undefined &&
          values.repairPlan !== undefined
        ) {
          setFieldValue('description', undefined);
          setFieldValue('repairPlan', undefined);
          setFieldValue('maintenancePlannedDate', undefined);
        }
        if (
          values.repairPlan === undefined &&
          values.orderContractorType !== undefined
        ) {
          setFieldValue('orderContractorType', undefined);
          setFieldValue('contractNumber', undefined);
          setFieldValue('contractorId', undefined);
          setFieldValue('executor', undefined);
          setFieldValue('executorId', undefined);
        }
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionTitle divider>Первичные данные</SectionTitle>
              <Content>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Транспортное средства"
                      required
                      fast
                      name="vehicleId"
                    >
                      {({ value, name }) => (
                        <VehicleSelect
                          disabled={values.id}
                          value={value}
                          filter={{
                            ownerType: ownerTypes.self
                          }}
                          onChange={(vehicleId: number) =>
                            setFieldValue(name, vehicleId)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {values.vehicleId && (
                    <GridItem>
                      <FormField
                        label="Вид работ"
                        required
                        fast
                        name="maintenanceWorkTypeId"
                      >
                        {({ value, name }) => (
                          <MaintenanceWorkTypeSelect
                            value={value}
                            onChange={(id: number) => {
                              setFieldValue(name, id);
                              const params = {
                                maintenanceWorkTypeId: id,
                                orderContractorType: values.orderContractorType,
                                repairPlan: values.repairPlan
                              };
                              checkExpenseDirection(params);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {values.maintenanceWorkTypeId && (
                    <GridItem>
                      <FormField
                        label="График работ"
                        required
                        fast
                        name="repairPlan"
                      >
                        {({ value, name }) => (
                          <RepairePlanSelect
                            value={value}
                            onChange={(type: string) => {
                              setFieldValue(name, type);
                              const params = {
                                maintenanceWorkTypeId:
                                  values.maintenanceWorkTypeId,
                                orderContractorType: values.orderContractorType,
                                repairPlan: value
                              };
                              checkExpenseDirection(params);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {values.repairPlan && (
                    <GridItem>
                      <FormField
                        label="Планируемая дата проведения работ"
                        required
                        fast
                        name="maintenancePlannedDate"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            name={name}
                            value={value ? moment(value) : value}
                            format="DD MMMM YYYY"
                            onChange={(value: string) => {
                              setFieldValue(
                                name,
                                moment
                                  .utc(value)
                                  .startOf('day')
                                  .toISOString()
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {values.maintenancePlannedDate && (
                    <GridItem>
                      <FormField
                        label="Способ выполнения"
                        required
                        name="orderContractorType"
                      >
                        {({ value, name }) => (
                          <OrderContractorTypeSelect
                            name={name}
                            value={value}
                            onChange={value => {
                              setFieldValue(name, value);
                              const params = {
                                maintenanceWorkTypeId:
                                  values.maintenanceWorkTypeId,
                                orderContractorType: value,
                                repairPlan: values.repairPlan
                              };
                              checkExpenseDirection(params);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {showContractor(values) && (
                    <>
                      <GridItem>
                        <FormField
                          label="Подрядчик"
                          required={
                            maintenanceMatrix !== null &&
                            maintenanceMatrix.contractorRequired ===
                            maintenanceMatrixValidActionEnum.required
                          }
                          name="contractorId"
                        >
                          {({ value, name }) => (
                            <ContractorSelect
                              name={name}
                              value={value}
                              filter={{
                                services: serviceTypeEnum.maintenance
                              }}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Договор"
                          required={
                            maintenanceMatrix !== null &&
                            maintenanceMatrix.contractorRequired ===
                            maintenanceMatrixValidActionEnum.required
                          }
                          name="contractNumber"
                        >
                          {({ value, name }) => (
                            <Input
                              name={name}
                              value={value}
                              onChange={e => {
                                setFieldValue(name, e.target.value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <FormField
                      label="Неисправности"
                      fast
                      name="description"
                    >
                      {field => <TextArea {...field} />}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Сохранить
              </Button>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
