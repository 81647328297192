// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { RangePicker } from '../../../../components/ui';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  Selects,
} from './../../../../components';
import Field from './../../../../components/card/Field';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import { formatDateTimeToISOString } from './../../../../lib/helpers';

import type { EntityStatusType, ServiceType } from './../../../../lib/types';

const { ContractServiceSelect, EntityStatusSelect, ContractorSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ActFilterParams = {
  'stage.contract.services'?: ServiceType,
  status?: EntityStatusType,
  'stage.contract.contractorId'?: number,
  startDate?: string,
  endDate?: string,
};

type Props = {
  filter: ActFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional,
        }: FilterChildrenParams<ActFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <ContractServiceSelect
                  size="small"
                  onChange={(value) =>
                    changeValue('stage.contract.services', value)
                  }
                  value={values['stage.contract.services']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={(value) => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={(value) =>
                    changeValue('stage.contract.contractorId', value)
                  }
                  value={values['stage.contract.contractorId']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  startDate={
                    values.startDate ? moment.utc(values.startDate) : null
                  }
                  endDate={values.endDate ? moment.utc(values.endDate) : null}
                  onChange={changeValue}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
