// @flow
import React from 'react';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';
import { DatePicker } from '../../../components/ant/DatePicker';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import type { Vehicle } from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Form from '../../../components/Form';
import { formatDateTimeToISOString } from './../../../lib/helpers';
import { canShowEngineNumber, getDisabledDate } from './../lib';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class Primary extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/documentation/primary`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/documentation/primary`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {} } = this.props;
    return (
      <>
        <Header
          left={vehicle && <h1>Первичные данные {vehicle.licensePlate}</h1>}
        />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              handleChange,
              values,
              setFieldValue,
              dirty
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField
                        label="Шасси (рама) №"
                        name="chassisNumber"
                        fast
                      >
                        {({ value, name }) => (
                          <Input
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Кузов (прицеп) №"
                        name="bodyNumber"
                        fast
                      >
                        {({ value, name }) => (
                          <Input
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {canShowEngineNumber(
                      values.vehicleModel && values.vehicleModel.type
                    ) && (
                      <GridItem>
                        <FormField label="Двигатель №" name="engineNumber" fast>
                          {field => <Input {...field} />}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <FormField
                        fast
                        label="Дата принятия ТС в эксплуатацию"
                        name="commissioningDate"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Дата списания ТС из эксплуатации"
                        name="decommissioningDate"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Причина списания"
                        name="descommissionReason"
                        fast
                      >
                        {field => <Input {...field} />}
                      </FormField>
                    </GridItem>
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(Primary);
