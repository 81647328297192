// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import { FuelCards as FuelCardsComponents } from './../components';
import { fuelCardApi } from './../../../lib/api';
import { setVehicleFuelCard, resetVehicleStatus } from '../../../ducks/vehicle';
import type { Vehicle, FuelCard as FuelCardType } from '../../../lib/types';
import type { AppState } from './../../../ducks/redux';

const { EditableItem, FuelCardForm } = FuelCardsComponents;

type Props = {
  setVehicleFuelCard: Function,
  resetVehicleStatus: Function,
  vehicle: Vehicle,
  editable: boolean,
  employeeBranchOrgUnitId: number
};

type State = {
  availableFuelCards: FuelCardType[],
  isLoading: boolean
};

export class FuelCard extends Component<Props, State> {
  state = {
    availableFuelCards: [],
    isLoading: false
  };

  async componentDidMount() {
    await this.fetchAvailableFuelCards();
  }

  fetchAvailableFuelCards = async (search?: string) => {
    this.setState({
      isLoading: true
    });
    let availableFuelCards = [];
    const result = await fuelCardApi.fetchFuelCards({
      search,
      page: 1,
      pageSize: 50,
      nodeId: this.props.employeeBranchOrgUnitId,
      nodeFilterType: 'branchAndChildren'
    });

    if (result) {
      availableFuelCards = result.data;
    }
    this.setState({
      availableFuelCards: availableFuelCards,
      isLoading: false
    });
  };

  // Обновление топливной карты в ТС
  updateFuelCardVehicle = async (fuelCard: FuelCardType) => {
    const updatedFuelCard = await fuelCardApi.updateFuelCard(fuelCard);
    const { vehicleId } = updatedFuelCard;
    await this.props.resetVehicleStatus(vehicleId);
    await this.props.setVehicleFuelCard(vehicleId);
  };

  onAdd = async (fuelCard: FuelCardType) => {
    await this.updateFuelCardVehicle({
      ...fuelCard,
      vehicleId: this.props.vehicle.id
    });
    await this.fetchAvailableFuelCards();
  };

  onEdit = async (fuelCard: FuelCardType) =>
    await this.updateFuelCardVehicle(fuelCard);

  onRemove = async (fuelCard: FuelCardType) => {
    const { vehicle } = this.props;
    await fuelCardApi.updateFuelCard({
      ...fuelCard,
      vehicleId: null
    });
    await this.props.resetVehicleStatus(vehicle.id);
    await this.props.setVehicleFuelCard(vehicle.id);
    await this.fetchAvailableFuelCards();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableFuelCards(value),
    500
  );

  render() {
    const { editable, vehicle }: Props = this.props;
    const { availableFuelCards, isLoading } = this.state;
    if (!vehicle) return null;
    return vehicle.fuelCard ? (
      <EditableItem
        key={vehicle.fuelCard.id}
        fuelCard={vehicle.fuelCard}
        onChange={this.onEdit}
        onRemove={this.onRemove}
        editable={editable}
      />
    ) : editable ? (
      <FuelCardForm
        onSubmit={this.onAdd}
        vehicle={vehicle}
        availableFuelCards={availableFuelCards}
        handleSearch={this.handleSearch}
        fetchData={this.fetchAvailableFuelCards}
        isLoading={isLoading}
      />
    ) : null;
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }),
  {
    setVehicleFuelCard,
    resetVehicleStatus
  }
)(FuelCard);
