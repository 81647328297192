import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import { FormikProps } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import moment from 'moment';
import React from 'react';
import * as yup from 'yup';
import { Form, Selects } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import CancelButton from '../../../components/CancelButton';
import type { FormFieldType } from '../../../components/Form';
import { GridItem, Section } from '../../../components/layout';
import Grid from '../../../components/layout/Grid';
import { LicenseNumberInput, PhoneInput } from '../../../components/inputs/masked-inputs';
import DriverQualificationSelect from '../../../components/selects/DriverQualificationSelect';
import {
  driverQualificationEnum,
  drivingLicenseCategories,
  drivingLicenseCategoryEnum,
  tractorDrivingLicenseCategories,
  tractorDrivingLicenseCategoryEnum,
} from '../../../lib/enum';
import { formatDateTimeToISOString, withEmptyRow } from '../../../lib/helpers';

import type { Driver, DriverQualificationDocument, EmployeeFilter } from '../../../lib/types';
import { licenseNumberValidate } from '../../../lib/validation';
import { Select } from './../../../components/ant/Select';
import { Content, Footer, Operations, StyledIcon, StyledListTable } from './InnerForm.elements';

const { EmployeeSelect } = Selects;

type Props = {
  driver: Driver,
  onSubmit: Function,
  onCancel: Function,
  employeeBranchOrgUnitId: number
};

const InnerForm = ({
  driver,
  onSubmit,
  onCancel,
  employeeBranchOrgUnitId
}: Props) => (
  <Form
    onSubmit={onSubmit}
    initialValues={driver}
    validationSchema={yup.object().shape({
      licenseNumber: licenseNumberValidate.nullable()
    })}
  >
    {(FormField: FormFieldType, formikProps: FormikProps) => {
      let {
        handleSubmit,
        handleBlur,
        setFieldValue,
        handleFocus,
        dirty,
        values,
        isSubmitting
      } = formikProps;
      const { qualificationDocuments = [] } = values;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField fast label="Сотрудник" name="employeeId" required>
                    {({ value, name }) => (
                      <EmployeeSelect
                        placeholder="Выберите сотрудника"
                        onChange={(value: number) => setFieldValue(name, value)}
                        onFocus={handleFocus}
                        onBlur={() => handleBlur({ target: { name } })}
                        name={name}
                        value={value}
                        data-cy="employeeSelect"
                        filter={
                          ({
                            nodeId: employeeBranchOrgUnitId,
                            nodeFilterType: 'branchAndChildren'
                          }: EmployeeFilter)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Контактный номер" name="phoneNumber">
                    {field => <PhoneInput {...field} />}
                  </FormField>
                </GridItem>
                <GridItem fullWidth>
                  <StyledListTable
                    columns={[
                      {
                        title: 'Квалификация',
                        key: 'qualification',
                        style: {
                          overflow: 'hidden'
                        },
                        render: (
                          qualification: string,
                          record: DriverQualificationDocument,
                          index: number
                        ) => (
                          <FormField
                            name={`qualificationDocuments[${index}].qualification`}
                            required={
                              // Обязательное поле, только если какое-нибудь
                              // другое значение заполнено
                              !isEmpty(
                                get(values, `qualificationDocuments[${index}]`)
                              )
                            }
                            hasFeedback={false}
                            fast
                          >
                            {({ name, value }) => (
                              <DriverQualificationSelect
                                onChange={(value: string) => {
                                  setFieldValue(name, value);
                                  if (value !== get(values, name)) {
                                    setFieldValue(
                                      `qualificationDocuments[${index}].categories`,
                                      undefined
                                    );
                                  }
                                }}
                                value={value}
                                name={name}
                                onBlur={() => handleBlur({ target: { name } })}
                                disabledValues={qualificationDocuments.map(
                                  (
                                    qualificationDocument: DriverQualificationDocument
                                  ) => qualificationDocument.qualification
                                )}
                              />
                            )}
                          </FormField>
                        )
                      },
                      {
                        title: 'Номер документа',
                        key: 'documentNumber',
                        render: (
                          documentNumber: number,
                          record: DriverQualificationDocument,
                          index: number
                        ) => (
                          <FormField
                            name={`qualificationDocuments[${index}].documentNumber`}
                            required={
                              !isEmpty(
                                get(values, `qualificationDocuments[${index}]`)
                              )
                            }
                          >
                            {({ name, value, ...field }) =>
                              record.qualification ===
                              driverQualificationEnum.driverLicense ? (
                                <LicenseNumberInput
                                  name={name}
                                  value={value}
                                  onChange={({ target: { value } }) =>
                                    setFieldValue(name, value)
                                  }
                                />
                              ) : (
                                <Input name={name} value={value} {...field} />
                              )
                            }
                          </FormField>
                        )
                      },
                      {
                        title: 'Класс (категория)',
                        key: 'categories',
                        render: (
                          categories: string,
                          record: DriverQualificationDocument,
                          index: number
                        ) => {
                          const required = [
                            driverQualificationEnum.tractorMachinistLicense,
                            driverQualificationEnum.driverLicense
                          ].includes(record.qualification);
                          return (
                            <FormField
                              name={`qualificationDocuments[${index}].categories`}
                              hasFeedback={false}
                              required={required}
                            >
                              {({ name, value }) => {
                                const categories = Object.keys(
                                  record.qualification ===
                                    driverQualificationEnum.tractorMachinistLicense
                                    ? tractorDrivingLicenseCategoryEnum
                                    : drivingLicenseCategoryEnum
                                );
                                const categoryTranslate: any =
                                  record.qualification ===
                                  driverQualificationEnum.tractorMachinistLicense
                                    ? tractorDrivingLicenseCategories
                                    : drivingLicenseCategories;
                                return (
                                  <Select
                                    mode="multiple"
                                    allowClear
                                    name={name}
                                    disabled={
                                      !record.qualification ||
                                      ![
                                        driverQualificationEnum.driverLicense,
                                        driverQualificationEnum.tractorMachinistLicense
                                      ].includes(record.qualification)
                                    }
                                    value={value}
                                    onChange={value =>
                                      setFieldValue(name, value)
                                    }
                                    onBlur={() =>
                                      handleBlur({ target: { name } })
                                    }
                                  >
                                    {categories.map(category => (
                                      <Select.Option
                                        key={category}
                                        value={category}
                                      >
                                        {categoryTranslate[category]}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                );
                              }}
                            </FormField>
                          );
                        }
                      },
                      {
                        title: 'Дата окончания срока действия',
                        width: '220px',
                        key: 'documentEndDate',
                        render: (
                          documentEndDate: string | moment,
                          record: DriverQualificationDocument,
                          index: number
                        ) => (
                          <FormField
                            name={`qualificationDocuments[${index}].documentEndDate`}
                          >
                            {({ name, value }) => (
                              <DatePicker
                                disabled={[
                                  driverQualificationEnum.armCraneMachinistLicense,
                                  driverQualificationEnum.craneVehicleMachinistLicense,
                                  driverQualificationEnum.liftMachinistLicense
                                ].includes(record.qualification)}
                                format="DD.MM.YYYY"
                                value={value ? moment.utc(value) : value}
                                name={name}
                                onChange={(
                                  value: Object,
                                  dateString: string
                                ) => {
                                  setFieldValue(
                                    name,
                                    formatDateTimeToISOString(value, dateString)
                                  );
                                }}
                              />
                            )}
                          </FormField>
                        )
                      },
                      {
                        width: '20px',
                        renderRecord: (record: DriverQualificationDocument) => {
                          return !isEmpty(record) ? (
                            <Operations>
                              <StyledIcon
                                onClick={() =>
                                  setFieldValue(
                                    'qualificationDocuments',
                                    without(
                                      values.qualificationDocuments,
                                      record
                                    )
                                  )
                                }
                                type="x"
                              />
                            </Operations>
                          ) : null;
                        }
                      }
                    ]}
                    data={withEmptyRow(qualificationDocuments, {
                      maxLength: Object.keys(driverQualificationEnum).length
                    })}
                  />
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

export default InnerForm;
