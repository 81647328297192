// @flow
import React from 'react';
import { navigate } from '@reach/router';

import notification from 'antd/lib/notification';

import { actApi } from './../../../lib/api';
import { goBack } from './../../../lib/helpers';
import type { Act } from './../../../lib/types';

import { Header } from './../../../components/layout';
import { Crumb } from './../../../components/layout/Breadcrumbs';
import Breadcrumbs from './../../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../../components/Notifications';

import { InnerForm } from './components';

type Props = {
  actId: number,
};
type State = {
  act: ?Act,
};

export class ActForm extends React.Component<Props, State> {
  state = {
    act: null,
  };

  componentDidMount = async () => {
    const { actId } = this.props;
    try {
      if (actId) {
        const act = await actApi.getAct(actId);
        this.setState({ act });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  handleSubmit = async (values: Act) => {
    let act = null;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        act = await actApi.updateAct(values);
      } else {
        act = await actApi.addAct(values);
      }
      navigate(`/budget/act/${act.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => goBack('/budget/act');

  render() {
    const { act } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/act">Реестр первичных документов</Crumb>
              {act ? <Crumb>Акт №{act.id}</Crumb> : <Crumb>Новый акт</Crumb>}
            </Breadcrumbs>
          }
        />
        <InnerForm
          act={act}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default ActForm;
