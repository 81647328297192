// @flow
import type { RegulationLimitGroup } from './../types';
import CommonApi from './../commonApi';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromMToKm,
  convertFromSecondToHour
} from '../helpers';

const convertDataToServer = (regulationLimitGroup: RegulationLimitGroup) => ({
  ...regulationLimitGroup,
  hours: convertFromHourToSecond(regulationLimitGroup.hours),
  hoursWeekend: convertFromHourToSecond(regulationLimitGroup.hoursWeekend),
  distance: convertFromKmToM(regulationLimitGroup.distance),
  distanceWeekend: convertFromKmToM(regulationLimitGroup.distanceWeekend)
});

const convertDataFromServer = (regulationLimitGroup: RegulationLimitGroup) => {
  let regulationLimits = regulationLimitGroup.regulationLimits || {};
  regulationLimits = regulationLimits.map(el => {
    return {
      ...el,
      hours: convertFromSecondToHour(el.hours),
      hoursWeekend: convertFromSecondToHour(el.hoursWeekend),
      distance: convertFromMToKm(el.distance),
      distanceWeekend: convertFromMToKm(el.distanceWeekend)
    };
  });
  return {
    ...regulationLimitGroup,
    hours: convertFromSecondToHour(regulationLimitGroup.hours),
    hoursWeekend: convertFromSecondToHour(regulationLimitGroup.hoursWeekend),
    distance: convertFromMToKm(regulationLimitGroup.distance),
    distanceWeekend: convertFromMToKm(regulationLimitGroup.distanceWeekend),
    regulationLimits
  };
};

const regulationLimitGroup = new CommonApi<RegulationLimitGroup>(
  'regulationLimitGroup',
  'регламент групп НТС',
  convertDataToServer,
  convertDataFromServer
);
export default regulationLimitGroup;
