// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link, navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type { Role, UserAccess } from './../../lib/types';
import { accessTypeEnum, accessTypes } from './../../lib/enum';

import { Popconfirm, Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import type { sortOrder } from '../../components/ui/Table';
import { notificationLoading } from './../../components/Notifications';

import type { AppState } from '../../ducks/redux';
import { fetchRoles } from '../../ducks/roles';
import { deleteRole } from '../../ducks/role';

type Props = {
  roles: Array<Role>,
  userAccess: UserAccess[],
  fetchRoles: Function,
  deleteRole: Function,
  totalCount: number,
  pageSize: number,
  page: number,
  location: Location & { state: { page: number } },
};
type State = {
  columns: Array<Object>,
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export class RoleList extends PureComponent<Props, State> {
  static defaultProps = {
    location: {},
    userAccess: [],
  };

  state = {
    columns: [
      {
        title: '№',
        dataIndex: 'id',
        sorter: true,
        width: 50,
      },
      {
        title: 'Наименование',
        dataIndex: 'name',
        sorter: true,
      },
      {
        title: 'Доступы',
        dataIndex: 'access',
        render: (access: UserAccess[]) =>
          access.map((accessItem) => accessTypes[accessItem]).join(', '),
      },
    ],
  };

  fetchRoles = async (page: number, orderBy?: string, order?: sortOrder) =>
    await this.props.fetchRoles(page, {
      orderBy,
      byDescending: order === 'descend',
    });

  async deleteRole(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await this.props.deleteRole(id);
      await this.fetchRoles(this.props.page);
      notification.success({
        message: 'Успешное удаление',
        description: `Роль с идентификатором ${id} была успешно удалена`,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  componentDidMount() {
    const { columns } = this.state;
    if (this.canAdd()) {
      this.setState({
        columns: [
          ...columns,
          {
            title: '',
            dataIndex: 'operations',
            width: 50,
            // eslint-disable-next-line no-unused-vars
            render: (text: string, role: Role) => (
              <Operations>
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => this.deleteRole(role.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              </Operations>
            ),
          },
        ],
      });
    }
  }

  handleRowClick = (id: number) => navigate(`/admin/roles/${id}`);

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingUsersAndRoles,
      ].includes(access)
    );

  render() {
    const { roles, totalCount, pageSize, page, location } = this.props;
    const { columns } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Роли</h1>}
          right={
            canAdd && (
              <Link to="/admin/roles/new">
                <Button type="primary" data-cy="addRole">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Section>
          <Table
            onRow={(record) => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            fetch={this.props.fetchRoles}
            fetchOnMount
            columns={columns}
            data={roles}
            rowKey="id"
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userAccess: state.auth.profile.access,
    roles: state.roles.data,
    totalCount: state.roles.totalCount,
    pageSize: state.roles.pageSize,
    page: state.roles.page,
  }),
  {
    fetchRoles,
    deleteRole,
  }
)(RoleList);
