// @flow
import queryString from 'query-string';

import { fetchRequest, initialFetchListParams } from './index';
import type { Battery } from '../types/index';
import type { FetchListParams, ListResponse } from './index';
import type { BatteryFilterParams } from '../../containers/Equipment/Batteries/Filter';

export const addBattery = async (battery: Battery): Promise<Battery> => {
  const added = await fetchRequest.post('/battery', battery);
  if (added) {
    return added;
  }
  throw new Error('Не удалось создать АКБ');
};

export const updateBattery = async (battery: Battery): Promise<Battery> => {
  const updated = await fetchRequest.put('/battery', battery);
  if (updated) {
    return updated;
  }
  throw new Error('Не удалось обновить АКБ');
};

export const fetchBatteries = async (
  params: FetchListParams<BatteryFilterParams> = initialFetchListParams
): Promise<ListResponse<Battery>> =>
  await fetchRequest.get('/battery', {
    ...initialFetchListParams,
    ...params,
  });

export const fetchBattery = async (
  id: number,
  params: any = {}
): Promise<Battery> => {
  const battery = await fetchRequest.get(`/battery/${id}`, params);
  if (battery) {
    return battery;
  }
  throw new Error('Не удалось загрузить АКБ');
};

export const deleteBattery = async (id: number): Promise<Battery> => {
  const deleted = await fetchRequest.delete(`/battery/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить АКБ');
};

export const setBatteryVehicle = async (
  batteryId: number,
  vehicleId: number,
  installDate?: string,
  removeDate?: string
) =>
  await fetchRequest.post(
    `/battery/${batteryId}/${vehicleId}?${queryString.stringify(
      { installDate, removeDate },
      { skipNull: true }
    )}`
  );

export const fetchVehicleBatteries = async (vehicleId: number) =>
  await fetchRequest.get(`/battery/${vehicleId}/vehicle`);

export const fetchHistory = async (
  params: FetchListParams<any>,
  batteryId: number
): Promise<ListResponse<Object>> =>
  await fetchRequest.get(`/batteryhistory/${batteryId}`);

export default {
  setBatteryVehicle,
  fetchVehicleBatteries,
  fetchBatteries,
  fetchBattery,
  addBattery,
  deleteBattery,
  updateBattery,
  fetchHistory,
};
