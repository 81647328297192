import {
    VehicleIssueGroupForm,
    VehicleIssueGroupList,
} from '../../containers/VehicleIssueGroup';
import { accessTypeEnum } from '../../lib/enum';

//группы дефектов
export default [
    {
        component: VehicleIssueGroupList,
        path: '/admin/vehicle-issue-group',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingVehicleIssueGroups,
            accessTypeEnum.handlingVehicleIssueGroups,
        ],
    },
    {
        component: VehicleIssueGroupForm,
        path: '/admin/vehicle-issue-group/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingVehicleIssueGroups],
    },
    {
        component: VehicleIssueGroupForm,
        path: '/admin/vehicle-issue-group/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingVehicleIssueGroups],
    },
];