// @flow

import React, { Component } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import type { ExpenseDirection } from '../../../lib/types';
import { expenseDirectionApi } from '../../../lib/api';
import { goBack } from '../../../lib/helpers';

import { Panel } from '../../../components/layout';
import Header from '../../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import { notificationLoading } from '../../../components/Notifications';

import InnerForm from './components/InnerForm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  expenseDirectionId: ?string
};

type State = {
  expenseDirection: ?ExpenseDirection
};

class ExpenseDirectionForm extends Component<Props, State> {
  state = {
    expenseDirection: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.expenseDirectionId, 10);
    if (id) {
      try {
        const expenseDirection = await expenseDirectionApi.getExpenseDirection(
          id
        );
        this.setState({ expenseDirection });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  onSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.expenseDirectionId) {
        await expenseDirectionApi.updateExpenseDirection(values);
      } else {
        await expenseDirectionApi.addExpenseDirection(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/expense-direction');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => goBack('/admin/expense-direction');

  render() {
    const { expenseDirection } = this.state;
    const { expenseDirectionId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/expense-direction">Список раcходов</Crumb>
              {expenseDirectionId ? (
                <Crumb
                  to={`/admin/expense-direction/${expenseDirectionId}/edit`}
                >
                  Наименование расхода №{expenseDirectionId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/expense-direction/new`}>
                  Новое наименование расхода
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {expenseDirectionId
              ? `Наименование расхода №${expenseDirectionId}`
              : `Новое наименование расхода`}
          </h1>
        </StyledPanel>
        <InnerForm
          expenseDirection={expenseDirection}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default ExpenseDirectionForm;
