import {
    WashingVehicleTypeMapCard,
    WashingVehicleTypeMapForm,
    WashingVehicleTypeMapList,
} from '../../containers/WashingVehicleTypeMap';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: WashingVehicleTypeMapList,
        path: '/admin/washing-vehicle-type-map',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
            accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
        ],
    },
    {
        component: WashingVehicleTypeMapForm,
        path: '/admin/washing-vehicle-type-map/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
        ],
    },
    {
        component: WashingVehicleTypeMapForm,
        path: '/admin/washing-vehicle-type-map/:id/edit',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
        ],
    },
    {
        component: WashingVehicleTypeMapCard,
        path: '/admin/washing-vehicle-type-map/:id',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.adminBranch,
            accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
            accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
        ],
    },
];