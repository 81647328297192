import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import type { RegulationLimitType } from './../../../../../../../lib/types';
import { SHORT_MONTH } from './../../../../../../../lib/helpers';
import { GridItem } from './../../../../../../../components/layout';
import {
  regulationLimitTypeEnum,
  regulationLimitTypes
} from './../../../../../../../lib/enum';
import { ListHeader, HeaderCell, HeaderBoldCell } from './../../../elements';

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

// Функция возвращает разметку грида для строк
export const getGridTemplate = (type: RegulationLimitType) => {
  switch (type) {
    case regulationLimitTypeEnum.employee:
    case regulationLimitTypeEnum.orgUnit:
    case regulationLimitTypeEnum.ovb:
    default:
      return [
        {
          size: '(max-width: 1799px)',
          cols: ['120px', '300px', ...Array(24).fill('1fr')]
        },
        {
          size: '(min-width: 1800px)',
          cols: ['120px', '400px', ...Array(24).fill('1fr')]
        }
      ];
  }
};

const months = SHORT_MONTH;
const commonCells = (startFrom: number) => (
  <>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: 'span 24'
      }}
    >
      <HeaderBoldCell>Сопоставление</HeaderBoldCell>
    </GridItem>
    {months.map((month, index) => {
      return (
        <>
          <GridItem
            style={{
              borderLeft: '1px solid #c0ccd7',
              borderTop: '1px solid #c0ccd7'
            }}
            cellUnion={{
              gridColumn: `span 2`,
              gridRow: `2`
            }}
          >
            <HeaderCell>{month}</HeaderCell>
          </GridItem>
          <GridItem
            style={{
              borderLeft: '1px solid #c0ccd7',
              borderTop: '1px solid #c0ccd7'
            }}
            cellUnion={{
              gridColumn: `span 1`,
              gridRow: `3`
            }}
          >
            <HeaderCell>Буд</HeaderCell>
          </GridItem>
          <GridItem
            style={{
              borderLeft: '1px solid #c0ccd7',
              borderTop: '1px solid #c0ccd7'
            }}
            cellUnion={{
              gridColumn: `span 1`,
              gridRow: `3`
            }}
          >
            <HeaderCell>Вых</HeaderCell>
          </GridItem>
        </>
      );
    })}
  </>
);

export const EmployeeHeader = () => (
  <ListHeader
    style={{ minWidth: '1800px' }}
    media={getGridTemplate(regulationLimitTypeEnum.employee)}
    rows={3}
  >
    <GridItem
      cellUnion={{
        gridColumn: 1,
        gridRow: '1 / span 3'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>Должность</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: 2,
        gridRow: '1 / span 3'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    {commonCells(3)}
  </ListHeader>
);

export const OrgUnitHeader = () => (
  <ListHeader
    style={{ minWidth: '1800px' }}
    media={getGridTemplate(regulationLimitTypeEnum.orgUnit)}
    rows={3}
  >
    <GridItem
      cellUnion={{
        gridColumn: 1,
        gridRow: '1 / span 3'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>Служба</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: 2,
        gridRow: '1 / span 3'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    {commonCells(3)}
  </ListHeader>
);

export const ListTableHeader = ({ type }: { type: RegulationLimitType }) => (
  <ListHeader cols={getGridTemplate(type)} rows={2}>
    <GridItem
      cellUnion={{
        gridColumn: '1 / span 1',
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>{regulationLimitTypes[type]}</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const ListTableOvbHeader = () => (
  <ListHeader
    style={{ minWidth: '1800px' }}
    media={getGridTemplate(regulationLimitTypeEnum.orgUnit)}
    rows={3}
  >
    <GridItem
      cellUnion={{
        gridColumn: 1,
        gridRow: '1 / span 3'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ОВБ</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: 2,
        gridRow: '1 / span 3'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    {commonCells(3)}
  </ListHeader>
);

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
