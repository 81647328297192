// @flow

import React, { Component } from 'react';
import type { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import type { MaintenanceOperationGroup } from './../../lib/types';

import {
  addMaintenanceOperationGroup,
  fetchMaintenanceOperationGroup,
  updateMaintenanceOperationGroup,
  clear
} from './../../ducks/maintenanceOperationGroup';
import { Panel, Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import type { FormErrors } from '../../lib/types';
import Header from '../../components/layout/Header';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import { Form } from './../../components';
import { notificationLoading } from './../../components/Notifications';

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  maintenanceOperationGroup: MaintenanceOperationGroup,
  updateMaintenanceOperationGroup: Function,
  addMaintenanceOperationGroup: Function,
  parentMaintenanceOperationGroupId: number,
  maintenanceOperationGroupId: number,
  fetchMaintenanceOperationGroup: Function,
  clear: Function
};

class MaintenanceOperationGroupForm extends Component<Props> {
  async componentDidMount() {
    const { maintenanceOperationGroupId } = this.props;
    await this.props.clear();
    try {
      if (maintenanceOperationGroupId) {
        await this.props.fetchMaintenanceOperationGroup(
          this.props.maintenanceOperationGroupId
        );
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка при загрузке группы работ',
        description: err.message
      });
    }
  }

  render() {
    const {
      maintenanceOperationGroup,
      parentMaintenanceOperationGroupId
    } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/maintenance-operation-groups">Виды работ</Crumb>
              {maintenanceOperationGroup ? (
                <Crumb
                  to={`/admin/maintenance-operation-groups/${
                    maintenanceOperationGroup.id
                  }`}
                >
                  Группа работ "{maintenanceOperationGroup.code}"
                </Crumb>
              ) : (
                <Crumb
                  to={`/admin/maintenance-operation-groups/${parentMaintenanceOperationGroupId}/new`}
                >
                  Новая группа работ
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceOperationGroup
              ? `Группа работ "${maintenanceOperationGroup.code}"`
              : 'Новая группа работ'}
          </h1>
        </StyledPanel>
        <Form
          initialValues={maintenanceOperationGroup}
          enableReinitialize
          validate={(values: MaintenanceOperationGroup) => {
            let errors: FormErrors<MaintenanceOperationGroup> = {};
            if (!values.code) {
              errors.code = 'Обязательное поле';
            }
            return errors;
          }}
          onSubmit={async values => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await this.props.updateMaintenanceOperationGroup(values);
              } else {
                await this.props.addMaintenanceOperationGroup(
                  values,
                  this.props.parentMaintenanceOperationGroupId
                );
              }
              navigate('/admin/maintenance-operation-groups');
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
        >
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleChange,
              handleBlur,
              isSubmitting
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <Content>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Наименование"
                          hasFeedback
                          required
                          fast
                          name="code"
                        >
                          {({ value, name }) => (
                            <Input
                              name={name}
                              placeholder="Наименование"
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </Content>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Сохранить
                  </Button>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    maintenanceOperationGroup: state.maintenanceOperationGroup,
    maintenanceOperationGroupId: parseInt(
      ownProps.maintenanceOperationGroupId,
      10
    )
  }),
  {
    addMaintenanceOperationGroup,
    fetchMaintenanceOperationGroup,
    updateMaintenanceOperationGroup,
    clear
  }
)(MaintenanceOperationGroupForm);
