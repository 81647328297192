// @flow
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from '../../components/ant/DatePicker';
import moment from 'moment';

import OrgUnitSelect from '../../components/OrgUnitSelect';
import { formatDateTimeToISOString } from '../../lib/helpers';
import {
  FilterContainer,
  FilterItem,
  Filter,
  FilterButtonsContainer
} from '../../components';
import type { FilterChildrenParams } from '../../components/hoc/Filter';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ShiftFilterParams = {
  date?: Date,
  nodeId?: number,
  isArchive?: boolean,
  orderBy?: string
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: ShiftFilterParams
};

const ShiftFilter = ({ filter, applyFilter, cleanFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer gutter="16px">
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<ShiftFilterParams>) => (
          <>
            <FilterItem>
              <DatePicker
                size="small"
                value={values.date ? moment(values.date) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'date',
                    formatDateTimeToISOString(value, dateString),
                  );
                }}
              />
            </FilterItem>
            <FilterItem>
              <OrgUnitSelect
                size="small"
                onChange={value => changeValue('nodeId', value)}
                value={values.nodeId}
              />
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ShiftFilter;
