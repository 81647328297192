// @flow
import { Link } from '@reach/router';
import notification from 'antd/lib/notification';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';

import { notificationLoading } from '../../../components/Notifications';
import { budgetAnalysisApi } from '../../../lib/api';
import { Section, SectionTitle } from './../../../components/layout';
import { Table } from './../../../components/ui';
import {
  budgetAnalysisTypes,
  budgetAnalysisTypesEnum,
} from './../../../lib/enum';

import { formatDate, MONTH, toLocalStringRu } from './../../../lib/helpers';

const Content = styled.div`
  padding: 0px;
`;

type Props = {
  branchBudgetSummaryExpenseDirectionId: number,
  budgetAnalysisId: number,
  month: number,
};
export default ({
  branchBudgetSummaryExpenseDirectionId,
  month,
  budgetAnalysisId,
}: Props) => {
  const [data, setData] = useState([]);

  const fetch = useCallback(async () => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting',
      });
      const data = await budgetAnalysisApi.getExpenseDirectionInfo({
        branchBudgetSummaryExpenseDirectionId,
        month,
      });
      setData(data);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    } finally {
      notification.close('getting');
    }
  }, [branchBudgetSummaryExpenseDirectionId, month]);

  useEffect(() => {
    fetch();
  }, [branchBudgetSummaryExpenseDirectionId, fetch, month]);

  const link = (id: number, type: string) => {
    switch (type) {
      case budgetAnalysisTypesEnum.externalAct:
        return <Link to={`/budget/act/${id}`}>{id}</Link>;
      case budgetAnalysisTypesEnum.maintenance:
        return <Link to={`/maintenances/${id}`}>{id}</Link>;
      default:
        return id;
    }
  };

  return (
    <>
      <Header
        left={
          <Breadcrumbs>
            <Crumb to="/">Главная</Crumb>
            <Crumb to="/budget/budget-analysis">Анализ затрат</Crumb>
            <Crumb to={`/budget/budget-analysis/${budgetAnalysisId}`}>
              Анализ бюджета № {budgetAnalysisId}
            </Crumb>
            <Crumb>Детализация</Crumb>
          </Breadcrumbs>
        }
      />
      <Section>
        <SectionTitle>Детализация за {MONTH[month - 1]}</SectionTitle>
        <Content>
          <Table
            data={data}
            rowKey="id"
            columns={[
              {
                title: 'Номер документа',
                dataIndex: 'id',
                width: 100,
                render: (id: number, record: any) => link(id, record.type),
              },
              {
                title: 'Дата формирования',
                dataIndex: 'date',
                render: (date) => formatDate(date),
              },
              {
                title: 'Сумма по документу',
                dataIndex: 'sum',
                render: (sum) => toLocalStringRu(sum),
              },
              {
                title: 'Тип документа',
                dataIndex: 'type',
                render: (type) => budgetAnalysisTypes[type],
              },
            ]}
          />
        </Content>
      </Section>
    </>
  );
};
