// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  CalculationStatus,
  LicensePlateChangeCalculation,
  LicensePlateChangeCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

export const convertDataToServer = (
  licensePlateChangeCalculation: LicensePlateChangeCalculation
): LicensePlateChangeCalculation => ({
  ...licensePlateChangeCalculation,
  sum: convertFromRubToKop(licensePlateChangeCalculation.sum),
  months: licensePlateChangeCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  licensePlateChangeCalculation: LicensePlateChangeCalculation
): LicensePlateChangeCalculation => ({
  ...licensePlateChangeCalculation,
  sum: convertFromKopToRub(licensePlateChangeCalculation.sum),
  months: licensePlateChangeCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  licensePlateChangeCalculationMonth: LicensePlateChangeCalculationMonth
): LicensePlateChangeCalculationMonth => ({
  ...licensePlateChangeCalculationMonth,
  cost: convertFromRubToKop(licensePlateChangeCalculationMonth.cost)
});

export const convertDataFromServerMonth = (
  licensePlateChangeCalculationMonth: LicensePlateChangeCalculationMonth
): LicensePlateChangeCalculationMonth => ({
  ...licensePlateChangeCalculationMonth,
  cost: convertFromKopToRub(licensePlateChangeCalculationMonth.cost)
});

const basedUrl = 'licensePlateChangeCalculation';
const materialCalculation = new CommonApi<LicensePlateChangeCalculation>(
  basedUrl,
  'расчет потребности в замене гос. номеров',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  data: LicensePlateChangeCalculation[]
): Promise<LicensePlateChangeCalculation> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations`,
    data
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...materialCalculation,
  updateCalculations,
  changeStatus,
  calculate,
  print
};
