// @flow

import React, { Component } from 'react';

import type { VehicleTariff, ListState, UserAccess } from '../../../lib/types';

import { vehicleTariffEnum } from '../../../lib/enum';

import { withUserAccess } from '../../withUserAccess';
import { InnerList } from '../components';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<VehicleTariff>;

const COLUMNS = [
  {
    title: 'Тип ТС',
    dataIndex: 'vehicleTypeTariff'
  }
];

export class VehicleTariffTypeList extends Component<Props, State> {
  render() {
    return (
      <>
        <InnerList
          {...this.props}
          type={vehicleTariffEnum.byType}
          extColumns={COLUMNS}
        />
      </>
    );
  }
}

export default withUserAccess(VehicleTariffTypeList);
