// @flow
import React from 'react';
import { connect } from 'react-redux';
import notification from 'antd/lib/notification';
import { navigate } from '@reach/router';

import { canHandleService } from '../lib';
import type { LicensePlateChange, UserAccess } from '../../../lib/types';
import { licensePlateChangeApi } from '../../../lib/api';
import {
  convertVehicleToString,
  formatDateTimeToString
} from '../../../lib/helpers';

import { Header } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import type { AppState } from '../../../ducks/redux';
import { Icon } from '../../../components/ui';
import { Panel, SectionContent } from '../elements';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, { GridItem } from '../../../components/layout/Grid';

import { ActInfo } from './../../Act';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  licensePlateChange: LicensePlateChange
};

class LicensePlateChangeCard extends React.Component<Props, State> {
  state = {
    licensePlateChange: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const licensePlateChange = await licensePlateChangeApi.fetchLicensePlateChange(
        parseInt(id, 10)
      );
      this.setState({ licensePlateChange });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { licensePlateChange } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={'/services/license-plate-changes'}>
                Список замен гос. номеров
              </Crumb>
              <Crumb>Замена гос. номера №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() =>
                  navigate(`/services/license-plate-changes/${id}/edit`)
                }
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Замена гос. номера №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              {licensePlateChange.vehicle !== null ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(licensePlateChange.vehicle)}
                  </Field>
                </GridItem>
              ) : null}

              {!!licensePlateChange.maintenanceDate && (
                <GridItem>
                  <Field label="Дата замены">
                    {formatDateTimeToString(
                      licensePlateChange.maintenanceDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              )}

              <GridItem>
                <Field label="Сумма оплаты, руб.">
                  {(licensePlateChange.paymentAmount || 0).toLocaleString(
                    'ru-RU',
                    {
                      style: 'currency',
                      currency: 'RUB'
                    }
                  )}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {licensePlateChange && licensePlateChange.act && (
          <ActInfo act={licensePlateChange.act} />
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(LicensePlateChangeCard);
