import {
    MaintenanceMatrixCard,
    MaintenanceMatrixForm,
    MaintenanceMatrixList,
} from '../../containers/MaintenanceMatrix';
import { accessTypeEnum } from '../../lib/enum';

// матрица ремонтов
export default [
    {
        component: MaintenanceMatrixList,
        path: '/admin/maintenance-matrix',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingMaintenanceMatrix,
            accessTypeEnum.handlingMaintenanceMatrix,
        ],
    },
    {
        component: MaintenanceMatrixCard,
        path: '/admin/maintenance-matrix/:maintenanceMatrixId',
        access: [accessTypeEnum.admin, accessTypeEnum.viewingMaintenanceMatrix],
    },
    {
        component: MaintenanceMatrixForm,
        path: '/admin/maintenance-matrix/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingMaintenanceMatrix],
    },
    {
        component: MaintenanceMatrixForm,
        path: '/admin/maintenance-matrix/:maintenanceMatrixId/edit',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingMaintenanceMatrix],
    },
];