// @flow
import { EditTwoTone } from '@ant-design/icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import { Link } from '@reach/router';

import type { Osago } from './../../../lib/types';
import { Popconfirm, Icon, ListTable } from './../../../components/ui';
import {
  applyMaskToValue,
  formatDateTimeToString,
} from './../../../lib/helpers';
import { osagoApi } from '../../../lib/api';
import { notificationLoading } from './../../../components/Notifications';
import { formatOsagoMask } from '../../../components/inputs/masked-inputs/OsagoInput';
import type { Contractor } from '../../../lib/types';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(EditTwoTone)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean,
};

type State = {
  loading: boolean,
  osagos: Osago[],
};

class OsagoList extends Component<Props, State> {
  state = {
    loading: false,
    osagos: [],
  };

  async componentDidMount() {
    await this.fetchOsagos();
  }

  fetchOsagos = async () => {
    try {
      this.setState({
        loading: true,
      });
      const osagos = await osagoApi.fetchVehicleOsagos(this.props.vehicleId);
      this.setState({
        osagos: osagos.data,
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  onDelete = async (osago: Osago) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      if (osago.id) {
        await osagoApi.deleteOsago(osago.id);
        notification.success({
          message: `ОСАГО успешно удален`,
        });
        await this.fetchOsagos();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete } = this.props;
    const { osagos, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          {
            title: 'Серия и номер ОСАГО',
            key: 'osagoNumber',
            render: (osagoNumber: string) =>
              applyMaskToValue(osagoNumber, formatOsagoMask),
          },
          {
            title: 'Дата окончания',
            key: 'osagoFinishedDate',
            render: (value: string) =>
              formatDateTimeToString(value, 'DD.MM.YYYY'),
          },
          {
            title: 'Сумма оплаты',
            key: 'paymentAmount',
            render: (value: string) => (value || 0) + ' руб.',
          },
          {
            title: 'Наименование страховщика',
            key: 'contractor',
            render: (contractor: Contractor) =>
              (contractor && contractor.company.name) || '-',
          },
          {
            title: 'Серия и номер диагностической карты',
            key: 'diagnosticCardNumber',
          },
          {
            width: '20px',
            renderRecord: (record: Osago) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/vehicles/${this.props.vehicleId}/osagos/${parseInt(
                      record.id
                    )}`}
                  >
                    <StyledAntIcon />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null,
          },
        ]}
        data={osagos}
      />
    );
  }
}

export default OsagoList;
