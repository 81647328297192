// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import type { Driver } from '../../lib/types';
import type { AppState } from '../../ducks/redux';
import { Panel } from './../../components/layout';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import { getPathWithHistoryParams, navigate } from '../../lib/helpers';
import { driverApi } from './../../lib/api';
import { notificationLoading } from '../../components/Notifications';
import InnerForm from './components/InnerForm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  driverId: ?number,
  employeeBranchOrgUnitId: number
};

type State = {
  driver?: Driver
};

class DriverForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    const { driverId } = this.props;
    try {
      if (driverId) {
        const driver = await driverApi.fetchDriver(driverId);
        this.setState({
          driver
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      await navigate('/admin/drivers', true);
    }
  }

  onSubmit = async (driver: Driver) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      let data;
      if (driver.id) {
        data = await driverApi.updateDriver(driver);
      } else {
        data = await driverApi.addDriver(driver);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      await navigate(`/admin/drivers/${data.id}`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { employeeBranchOrgUnitId } = this.props;
    const { driver } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to={getPathWithHistoryParams('/admin/drivers')}>
                Водители
              </Crumb>
              {driver ? (
                <Crumb to={`/admin/drivers/${driver.id}`}>
                  Водитель №{driver.id}
                </Crumb>
              ) : (
                <Crumb to="/admin/drivers/new">Новый водитель</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{driver ? `Водитель №${driver.id}` : 'Новый водитель'}</h1>
        </StyledPanel>
        <InnerForm
          onSubmit={this.onSubmit}
          onCancel={() => navigate('/admin/drivers', true)}
          driver={driver}
          employeeBranchOrgUnitId={employeeBranchOrgUnitId}
        />
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  driverId: parseInt(ownProps.driverId, 10),
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(DriverForm);
