// @flow
import React from 'react';

import type { PropsHeader } from '../lib';
import { commonHeaderPanel } from '../lib';

import OperationBudgetTab from '../OperationBudget/components/Tabs';

export const headerPanel = (props: PropsHeader) => {
  const { title, vehiclePlanId } = props;
  return (
    <>
      {commonHeaderPanel({ title, vehiclePlanId })}
      <OperationBudgetTab vehiclePlanId={props.vehiclePlanId} />
    </>
  );
};
export type ModalProps<T> = {
  visible: boolean,
  monthIndex?: number,
  handleSave?: Function,
  handleCancel?: Function,
  data?: T,
  isRangeMonths?: Function,
  readOnly?: boolean
};
