// @flow

import type { Order } from './../types';

import { fetchRequest, downloadRequestWithToken } from './index';

export const addGpmOrder = async (order: Order): Promise<Order> => {
  const added = await fetchRequest.post('/order-gpm', order);
  if (added) return added;
  throw new Error('Не удалось создать заявку');
};

export const updateGpmOrder = async (order: Order): Promise<Order> => {
  const updated = await fetchRequest.put('/order-gpm', order);
  if (updated) return updated;
  throw new Error('Не удалось обновить заявку');
};

export const printGpmOrder = async (id: number): Promise<boolean> => {
  const printed = await downloadRequestWithToken(`/order-gpm/${id}/print`);
  if (printed) return printed;
  throw new Error('Не удалось напечатать заявку');
};

export default {
  addGpmOrder,
  updateGpmOrder,
  printGpmOrder
};
