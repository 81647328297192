import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { WashingVehicleType } from '../types/washingPriceList';

export const create = async (
  washingVehicleType: WashingVehicleType
): Promise<void> => {
  const added = await fetchRequest.post(
    '/washingVehicleType',
    washingVehicleType
  );
  if (added) return added;
  throw new Error('Не удалось создать тип ТС для моек');
};

export const fetchList = async (
  params: FetchListParams<> = initialFetchListParams
): Promise<ListResponse<WashingVehicleType>> => {
  const prices = await fetchRequest.get('/washingVehicleType', {
    ...initialFetchListParams,
    ...params
  });
  if (prices) return prices;
  throw new Error('Не удалось получить список типов ТС для моек');
};

export const update = async (
  washingVehicleType: WashingVehicleType
): Promise<WashingVehicleType> => {
  const updated = await fetchRequest.put(
    '/washingVehicleType',
    washingVehicleType
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить тип ТС для моек');
};

export const fetchOne = async (id: number): Promise<WashingVehicleType> => {
  let washingVehicleType = await fetchRequest.get(`/washingVehicleType/${id}`);
  if (washingVehicleType) return washingVehicleType;
  throw new Error('Не удалось загрузить тип ТС для моек');
};

export const deleteVehicleType = async (
  id: number
): Promise<WashingVehicleType> => {
  const deleted = await fetchRequest.delete(`/washingVehicleType/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить тип ТС для моек');
};

export default {
  fetchList,
  fetchOne,
  deleteVehicleType,
  create,
  update
};
