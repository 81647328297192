import {
    BudgetsForAnalysisCard,
    BudgetsForAnalysisForm,
    BudgetsForAnalysisList,
} from '../../containers/BudgetsForAnalysis';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BudgetsForAnalysisList,
        path: '/admin/budgets-for-analysis',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BudgetsForAnalysisCard,
        path: '/admin/budgets-for-analysis/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BudgetsForAnalysisForm,
        path: '/admin/budgets-for-analysis/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: BudgetsForAnalysisForm,
        path: '/admin/budgets-for-analysis/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];