// @flow
import React from 'react';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import {
  regulationLimitTypeEnum,
  entityStatusEnum
} from './../../../../../../lib/enum';
import {
  assignmentLimitGroupApi,
  contractVehiclePlanApi,
  assignmentApi
} from './../../../../../../lib/api';
import type {
  ContractVehiclePlan,
  Assignment,
  AssignmentLimitGroup,
  EntityStatusType,
  RegulationLimitType,
  UserAccess
} from './../../../../../../lib/types';

import {
  Section,
  SectionTitle,
  Header,
  TopPanel
} from './../../../../../../components/layout';
import Breadcrumbs, {
  Crumb
} from './../../../../../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../../../../../components/Notifications';
import { withUserAccess } from './../../../../../withUserAccess';

import Tabs from './../components/Tabs';
import {
  approveAccessRightContractVehicleBudget,
  handlingAccessRightContractVehicleBudget
} from './../../accessRight';

import AssignmentGroups from './AssignmentGroups';

const SectionContent = styled.div`
  padding: 16px;
`;

type Props = {
  contractVehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = {
  // перечень тс
  contractVehiclePlan: ?ContractVehiclePlan,
  // закрепление
  assignment: ?Assignment,
  // Группы лимитов служб
  orgUnit: AssignmentLimitGroup[],
  // Группы лимитов должностным лицам
  employee: AssignmentLimitGroup[],
  // Группа лимитов ОВБ
  ovb: AssignmentLimitGroup[],
  // Группа лимитов разовых заявок
  oneOffRequests: AssignmentLimitGroup[],
  // Флаг состояния загрузки
  loading: boolean
};

class Assignments extends React.Component<Props, State> {
  state = {
    contractVehiclePlan: null,
    assignment: null,
    orgUnit: [],
    employee: [],
    ovb: [],
    oneOffRequests: [],
    loading: false
  };

  async componentDidMount() {
    await this.fetch();
  }

  /**
   * Создает функцию изменения куска стейта по типу лимитов
   * @param type Тип лимитов
   */
  createChangeGroupsHandler = (type: RegulationLimitType) => (
    groups: AssignmentLimitGroup[]
  ) => {
    this.setState({ [(type: string)]: groups });
  };

  getContractVehiclePlan = async () => {
    const { contractVehiclePlanId } = this.props;
    return await contractVehiclePlanApi.get(contractVehiclePlanId);
  };

  getAssignment = async (
    contractVehiclePlanId: number
  ): Promise<Assignment> => {
    return await assignmentApi.getByContractVehiclePlan(contractVehiclePlanId);
  };

  // редактируем assignmet для отображении записи с системном журнале
  updateAssignment = () => {
    assignmentApi.update(this.state.assignment);
  };

  /**
   * Подгрузка групп лимитов по типам
   */
  fetch = async () => {
    this.setState({ loading: true });

    const contractVehiclePlan = await this.getContractVehiclePlan();
    const assignment = await this.getAssignment(contractVehiclePlan.id);
    await Promise.all(
      Object.keys(regulationLimitTypeEnum).map(async type => {
        const { data: groups } = await assignmentLimitGroupApi.fetch({
          assignmentId: assignment.id,
          'regulationLimitGroup.type': type,
          pageSize: 0
        });
        this.setState({ [(type: string)]: groups });
      })
    );
    this.setState({
      loading: false,
      contractVehiclePlan,
      assignment
    });
  };

  handleChangeStatus = (status: EntityStatusType) => async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.state.assignment) {
        const assignment = await assignmentApi.changeStatus({
          ...this.state.assignment,
          status
        });
        this.setState({ assignment });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  canApproved = () =>
    this.props.userAccess.some(access =>
      approveAccessRightContractVehicleBudget.includes(access)
    );

  canEditing = () =>
    this.props.userAccess.some(access =>
      handlingAccessRightContractVehicleBudget.includes(access)
    );

  render() {
    const {
      ovb,
      employee,
      orgUnit,
      oneOffRequests,
      loading,
      assignment
    } = this.state;
    const { contractVehiclePlanId } = this.props;
    const editing =
      ![entityStatusEnum.approved, entityStatusEnum.declined].includes(
        assignment && assignment.status
      ) && this.canEditing();
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/budget/contract-vehicle/fixed">
                Закрепленные НТС
              </Crumb>
              <Crumb>Перечень НТС</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Планируемый перечень ТС</h1>
        </TopPanel>
        <Tabs
          contractVehiclePlanId={contractVehiclePlanId}
          assignmentId={(assignment && assignment.id) || undefined}
          assignmentApproved={
            !!(assignment && assignment.status === entityStatusEnum.approved)
          }
        />

        <Section>
          <SectionTitle divider>Должностные лица</SectionTitle>
          <SectionContent>
            <AssignmentGroups
              editing={editing}
              groups={employee}
              loading={loading}
              updateAssignment={this.updateAssignment}
              onChangeGroups={this.createChangeGroupsHandler(
                regulationLimitTypeEnum.employee
              )}
              type={regulationLimitTypeEnum.employee}
            />
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle divider>Службы</SectionTitle>
          <SectionContent>
            <AssignmentGroups
              editing={editing}
              groups={orgUnit}
              loading={loading}
              updateAssignment={this.updateAssignment}
              onChangeGroups={this.createChangeGroupsHandler(
                regulationLimitTypeEnum.orgUnit
              )}
              type={regulationLimitTypeEnum.orgUnit}
            />
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle divider>Разовые заявки</SectionTitle>
          <SectionContent>
            <AssignmentGroups
              editing={editing}
              groups={oneOffRequests}
              loading={loading}
              updateAssignment={this.updateAssignment}
              onChangeGroups={this.createChangeGroupsHandler(
                regulationLimitTypeEnum.oneOffRequests
              )}
              type={regulationLimitTypeEnum.oneOffRequests}
            />
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle divider>ОВБ</SectionTitle>
          <SectionContent>
            <AssignmentGroups
              editing={editing}
              groups={ovb}
              loading={loading}
              updateAssignment={this.updateAssignment}
              onChangeGroups={this.createChangeGroupsHandler(
                regulationLimitTypeEnum.ovb
              )}
              type={regulationLimitTypeEnum.ovb}
            />
          </SectionContent>
        </Section>

        {assignment &&
          this.canApproved() &&
          [
            entityStatusEnum.created,
            entityStatusEnum.approvement,
            entityStatusEnum.editing
          ].includes(assignment.status) && (
            <Section>
              <SectionContent>
                {[entityStatusEnum.created, entityStatusEnum.editing].includes(
                  assignment.status
                ) && (
                  <Button
                    type="primary"
                    onClick={this.handleChangeStatus(
                      entityStatusEnum.approvement
                    )}
                  >
                    На согласование
                  </Button>
                )}
                {entityStatusEnum.approvement === assignment.status && (
                  <>
                    <Button
                      type="primary"
                      onClick={this.handleChangeStatus(
                        entityStatusEnum.approved
                      )}
                      style={{ marginRight: '10px' }}
                    >
                      Утвердить
                    </Button>
                    <Button
                      onClick={this.handleChangeStatus(
                        entityStatusEnum.declined
                      )}
                      style={{ marginRight: '10px' }}
                    >
                      Отклонить
                    </Button>
                  </>
                )}
              </SectionContent>
            </Section>
          )}
      </>
    );
  }
}

export default withUserAccess(Assignments);
