//@flow
import moment from 'moment';
import React, { useCallback } from 'react';

import { FilterItem, Selects } from '../../../../components';
import { RangePicker } from '../../../../components/ant/DatePicker';
import Field from '../../../../components/card/Field';
import { CommonFilter, type FilterProps } from '../../../../components/hoc/common/handbook/CommonFilter';

import { formatDateTimeToISOString } from '../../../../lib/helpers';

const { DriverSelect, PddTestStatusSelect } = Selects;

export type SparePartsParamsFilter = {
  driverId?: number,
  startDate?: string,
  endDate?: string,
  status?: string
};

export const SparePartsFilter = (
  props: FilterProps<SparePartsParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<SparePartsParamsFilter>>) =>
      CommonFilter<SparePartsParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <DriverSelect
                size="small"
                placeholder="Водитель"
                value={values.driverId}
                onChange={(driverId: number) =>
                  changeValue('driverId', driverId)
                }
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <PddTestStatusSelect
                size="small"
                value={values.status}
                onChange={(status: string) => changeValue('status', status)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <RangePicker
                size="small"
                format="DD.MM.YYYY"
                placeholder={['Начало', 'Конец']}
                value={[
                  values.startDate ? moment.utc(values.startDate) : null,
                  values.endDate ? moment.utc(values.endDate) : null
                ]}
                onChange={(value, dateString) => {
                  const [startDate, endDate] = value;
                  const [startDateString, endDateString] = dateString;
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(startDate, startDateString)
                  );
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(endDate, endDateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default SparePartsFilter;
