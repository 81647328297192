// @flow
import React from 'react';
import type { OperationLimit } from '../../../../lib/types';
import { getGridTemplate } from './elements';
import { formatLimitValue } from '../../lib';
import { operationLimitTypeEnum } from '../../../../lib/enum';
import {
  convertContractVehicleToString,
  convertEmployeeToString,
  convertJobTitleToString
} from '../../../../lib/helpers';
import { Selects } from './../../../../components';
import { Row, Cell } from './../../elements';

const { EmployeeSelect, ContractVehicleSelect } = Selects;

type Props = {
  // Лимит
  limit: OperationLimit,
  // Индекс лимита в массиве лимитов
  index: number,
  // Функция изменения значения лимита по ключу в массиве лимитов
  onChange: (index: number, name: $Keys<OperationLimit>, value: any) => void,
  editable: boolean
};

/**
 * Компонент строки лимита в закреплении
 */
export default ({ limit, index, editable, onChange }: Props) => (
  <>
    <Row media={getGridTemplate(limit.type)} gutter={0}>
      {limit.type === operationLimitTypeEnum.employee && (
        <>
          <Cell>{convertJobTitleToString(limit.jobTitle)}</Cell>
          <Cell style={{ overflow: 'hidden' }}>
            {editable ? (
              <EmployeeSelect
                size="small"
                value={limit.employeeId}
                onChange={(id, { props: { employee } = {} } = {}) => {
                  onChange(index, 'employeeId', id);
                  onChange(index, 'orgUnitId', employee && employee.orgUnitId);
                }}
              />
            ) : (
              convertEmployeeToString(limit.employee)
            )}
          </Cell>
        </>
      )}
      {limit.type === operationLimitTypeEnum.orgUnit && (
        <Cell>{limit.orgUnitName}</Cell>
      )}
      {limit.type === operationLimitTypeEnum.ovb && (
        <>
          <Cell>{limit.ovbName}</Cell>
          <Cell>{limit.orgUnitName}</Cell>
        </>
      )}
      <Cell style={{ overflow: 'hidden' }}>
        {editable ? (
          <ContractVehicleSelect
            size="small"
            value={limit.contractVehicleId}
            onChange={id => onChange(index, 'contractVehicleId', id)}
          />
        ) : (
          convertContractVehicleToString(limit.contractVehicle)
        )}
      </Cell>
      <Cell>{formatLimitValue('hours', limit.hours)}</Cell>
      {limit.type !== operationLimitTypeEnum.ovb && (
        <Cell>{formatLimitValue('hoursWeekend', limit.hoursWeekend)}</Cell>
      )}
      <Cell>{formatLimitValue('distance', limit.distance)}</Cell>
      {limit.type !== operationLimitTypeEnum.ovb && (
        <Cell>
          {formatLimitValue('distanceWeekend', limit.distanceWeekend)}
        </Cell>
      )}
    </Row>
  </>
);
