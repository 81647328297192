// @flow
import React, { useState, useEffect, useCallback } from 'react';

import { Spinner } from './../../components';

import {
  CalendarContainer,
  CalendarItem,
  CalendarBody,
  CalendarHeader,
  CalendarHeaderItem
} from './elements';
import {
  getDaysByWindowDate,
  isToday,
  type CalendarDay,
  isCurrentMonth
} from './helpers';

type CalendarProps = {
  /**
   * Оконная дата - дата,
   * которая используется для отображения
   * текущего месяца в календаре
   */
  windowDate: Date,
  onClick?: (date: Date) => void,
  renderDay?: (date: Date) => any,
  loading: boolean
};

export const Calendar = ({
  windowDate,
  renderDay: customDay,
  loading,
  onClick
}: CalendarProps) => {
  const [days, setDays] = useState(getDaysByWindowDate(windowDate));

  useEffect(() => {
    setDays(getDaysByWindowDate(windowDate));
  }, [windowDate]);

  const handleClick = useCallback(
    (date: Date) => {
      if (onClick) onClick(date);
    },
    [onClick]
  );

  const renderDay = (day: CalendarDay, index: number) => {
    const { date } = day;
    const currentMonth = isCurrentMonth(windowDate, date);
    return (
      <CalendarItem
        isToday={isToday(date)}
        currentMonth={currentMonth}
        isElapsedDay={!currentMonth}
        onClick={() => handleClick(date)}
        key={index}
      >
        {!currentMonth ? new Date(date).getDate() : undefined}
        {customDay && customDay(date)}
      </CalendarItem>
    );
  };

  return (
    <CalendarContainer>
      <CalendarHeader>
        {['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'].map(el => (
          <CalendarHeaderItem>{el}</CalendarHeaderItem>
        ))}
      </CalendarHeader>
      <Spinner isLoading={loading}>
        <CalendarBody>{days.map(renderDay)}</CalendarBody>
      </Spinner>
    </CalendarContainer>
  );
};
