// @flow
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table } from '../../../components/ant/table/Table';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import type { AppState } from '../../../ducks/redux';
import Filter from './components/Filter';
import { fuelGroups, fuelGroupEnum, vehicleTypes } from '../../../lib/enum';
import type { FuelGroup, VehicleType } from '../../../lib/types';

const initialParams: any = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  params: any,
  loading: boolean
};

/**
 * Сравнительный отчет об оказании автотранспортных услуг
 */
export class ComparingTransportServicesList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    loading: false
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      }
    }));
  };

  applyParams = async () => {};

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {};

  mock = [
    {
      orgUnitName: 'Главный',
      date: '20.12.2018',
      type: 'car',
      brandName: 'Nissan',
      licensePlate: 'Т865ХМ 716',
      kilometrage: 166,
      kilometrageDeviation: 0.15,
      fuel: 23,
      fuelDeviation: 0.25,
      fuelGroup: fuelGroupEnum.petrol,
      totalWorkTime: 12
    },
    {
      orgUnitName: 'Главный',
      date: '20.12.2018',
      type: 'car',
      brandName: 'Лада',
      licensePlate: 'К325ЛМ 16',
      kilometrage: 25500,
      kilometrageDeviation: 0.1,
      fuel: 56,
      fuelDeviation: 0.15,
      fuelGroup: fuelGroupEnum.petrol,
      totalWorkTime: 10
    },
    {
      orgUnitName: 'Главный',
      date: '20.12.2018',
      type: 'car',
      brandName: 'Reno',
      licensePlate: 'М124КВ 116',
      kilometrage: 673133,
      kilometrageDeviation: 0.56,
      fuel: 30,
      fuelDeviation: 0.25,
      fuelGroup: fuelGroupEnum.petrol,
      totalWorkTime: 56
    }
  ];

  columns = [
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Дата формирования',
      dataIndex: 'date'
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      render: (type: VehicleType) => vehicleTypes[type]
    },
    {
      title: 'Марка ТС',
      dataIndex: 'brandName'
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate'
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (value: number) => `${value} км.`
    },
    {
      title: '% отклонения по пробегу',
      dataIndex: 'kilometrageDeviation',
      render: (value: number) => `${value} %`
    },
    {
      title: 'ГСМ',
      dataIndex: 'fuel',
      render: (value: number) => `${value} л.`
    },
    {
      title: '% отклонения по ГСМ',
      dataIndex: 'fuelDeviation',
      render: (value: number) => `${value} %`
    },
    {
      title: 'Вид топлива',
      dataIndex: 'fuelGroup',
      render: (value: FuelGroup) => fuelGroups[value]
    },
    {
      title: 'Кол-во мащ/часов',
      dataIndex: 'totalWorkTime'
    }
  ];

  render() {
    const { loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Сравнительный отчет об оказании автотранспортных услуг</h1>}
        />
        <Section>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={this.mock}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({}),
  {}
)(ComparingTransportServicesList);
