// @flow
import React, { Component, type ComponentType } from 'react';
import styled, { css } from 'styled-components';
import range from 'lodash/range';
import isEqual from 'lodash/isEqual';
import Icon from './Icon';
import { setQueryParams } from '../../lib/helpers';
import qs from 'query-string';

const Wrapper = styled.ul`
  margin: 0 -5px;
`;

type PageButtonProps = {
  active?: boolean
};

/**
 * Кнопка в пагинации
 */
const PageButton: ComponentType<PageButtonProps> = styled.li`
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  border-radius: 3px;
  padding: 5px 8px;
  margin: 0 5px;
  transition: background 0.3s;
  &:hover {
    background: #f0f4f8;
  }
  ${props =>
    props.active &&
    css`
      background-color: #e4ebf2;
    `};
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      &:hover {
        background-color: #e4ebf2;
      }
    `};
`;
PageButton.displayName = 'PageButton';

const TotalCount = styled.span`
  font-size: 12px;
  color: #838d96;
  margin-left: 5px;
`;
TotalCount.displayName = 'TotalCount';

type Props = {
  /**
   * общее количество сущностей
   */
  totalCount: number,
  /**
   * количество сущностей на одной странице
   */
  pageSize: number,
  /**
   * Количество отображаемых страниц
   */
  visiblePages: number,
  /**
   * Функция для подгрузки данных
   */
  fetch: (page: number) => Promise<any>,
  /**
   * Название класса для styled-components
   */
  className?: string,
  /**
   * Текущая страница
   */
  page: number,
  queryParam: string,
  location?: Location,
  disableSetQueryParamPage?: boolean,
};

type State = {
  // отступ страниц
  offset: number
};

/**
 * Компонент пагинации
 */
export default class Pagination extends Component<Props, State> {
  state = {
    offset: 1
  };

  static defaultProps = {
    pageSize: 10,
    visiblePages: 5,
    queryParam: 'page'
  };

  getTotalPages() {
    return Math.ceil(this.props.totalCount / this.props.pageSize);
  }

  getCurrentPage() {
    return parseInt(this.props.page, 10) || 1;
  }

  changePage = async (page: number) => {
    const { disableSetQueryParamPage } = this.props;
    if (page !== this.getCurrentPage()) {
      // меняем query параметр при измении страницы
      if (page === 1) {
        /**
         * undefined потому что
         * queryString.stringify({foo: false}) //=> 'foo=false'
         * queryString.stringify({foo: null}); //=> 'foo'
         * queryString.stringify({foo: undefined}); //=> ''
         */
        !disableSetQueryParamPage && setQueryParams({ [this.props.queryParam]: undefined });
      } else {
        !disableSetQueryParamPage && setQueryParams({ [this.props.queryParam]: page });
      }
      await this.props.fetch(page);
    }
  };

  async componentDidUpdate(prevProps: Props) {
    const { disableSetQueryParamPage } = this.props;
    /**
     * Если изменилось значение page, то делаем запрос
     * Подразумевается вызов в случае, когда в location query параметр изменился
     * без нажатия на кнопку
     * Например, когда пользователь нажал назад
     */
    if (
      this.props.location &&
      !isEqual(this.props.location, prevProps.location)
    ) {
      const page =
        parseInt(
          qs.parse(this.props.location.search)[this.props.queryParam],
          10
        ) || 1;
      if (prevProps.page !== page) {
        await this.props.fetch(page);
      }
    }
  }

  renderButton = (page: number) => (
    <PageButton
      key={page}
      active={page === this.getCurrentPage()}
      onClick={async () => this.changePage(page)}
    >
      {page}
    </PageButton>
  );

  toLeft = async () => {
    let { offset } = this.state;
    if (this.getCurrentPage() > 1) {
      await this.changePage(this.getCurrentPage() - 1);
      if (this.getCurrentPage() < this.props.visiblePages * offset) {
        this.setState(prev => ({ offset: prev.offset - 1 }));
      }
    }
  };

  toRight = async () => {
    let { offset } = this.state;
    if (this.getCurrentPage() < this.getTotalPages()) {
      await this.changePage(this.getCurrentPage() + 1);
      if (this.getCurrentPage() > this.props.visiblePages * offset) {
        this.setState(prev => ({ offset: prev.offset + 1 }));
      }
    }
  };

  /**
   * Находит необходимые номера страниц
   * с учетом количества отображаемых страниц (visiblePages)
   * @param page
   */
  findRange(page: number, offset: number = 1): [number[], number] {
    const { visiblePages } = this.props;
    // определяем левую и правую границы для левого списка страниц
    const leftBound = visiblePages * (offset - 1),
      rightBound = visiblePages * offset,
      totalPages = this.getTotalPages();
    if (totalPages < page) {
      // $FlowFixMe
      return [];
    }
    if (leftBound < page && page <= rightBound) {
      // левая часть
      let leftRange = range(
        leftBound + 1,
        rightBound > totalPages ? totalPages + 1 : rightBound + 1
      );
      // если текущая страница последняя, то отображаем предыдущий диапазон
      if (page === totalPages && page !== 1) {
        leftRange = this.findRange(page - 1, offset - 1)[0];
      }
      return [leftRange, totalPages];
    } else {
      return this.findRange(page, offset + 1);
    }
  }

  render() {
    let { className, visiblePages } = this.props;
    const ranges = this.findRange(this.getCurrentPage());
    if (this.getTotalPages()) {
      const [leftRange, lastPage] = ranges;
      if (!leftRange) return null;
      const showLastPage =
        !leftRange.includes(lastPage) &&
        leftRange[visiblePages - 1] !== lastPage;
      const showDivider =
        !leftRange.includes(lastPage) &&
        lastPage - leftRange[visiblePages - 1] !== 1;
      return (
        <Wrapper className={className}>
          {leftRange.map(this.renderButton)}
          {showDivider && (
            <PageButton disabled active>
              ...
            </PageButton>
          )}
          {showLastPage && this.renderButton(lastPage)}
          <PageButton
            active
            onClick={this.toLeft}
            disabled={this.getCurrentPage() === 1}
          >
            <Icon type="arrow-left" size={8} />
          </PageButton>
          <PageButton
            active
            disabled={this.getCurrentPage() === this.getTotalPages()}
            onClick={this.toRight}
          >
            <Icon type="arrow-right" size={8} />
          </PageButton>
          <TotalCount>Всего: {this.props.totalCount}</TotalCount>
        </Wrapper>
      );
    }
    return null;
  }
}
