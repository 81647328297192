// @flow
import React from 'react';
import type { BudgetSummaryStatusType } from '../../../../lib/types';

import { TabItem, Tabs } from '../../../../components/ui';
import { budgetSummaryStatusEnum } from '../../../../lib/enum';

type Props = {
  orgUnitId: number,
  id: number,
  branchBudgetStatus?: BudgetSummaryStatusType,
};

export default ({ id, orgUnitId, branchBudgetStatus }: Props) => {
  return (
    <Tabs withRouter defaultTab={`/budget/summary/${id}/${orgUnitId}`}>
      <TabItem
        label={'Смета затрат'}
        key={'1'}
        url={`/budget/summary/${id}/${orgUnitId}`}
      />
      {branchBudgetStatus === budgetSummaryStatusEnum.approved && (
        <TabItem
          label={'Договоры'}
          key={'2'}
          url={`/budget/summary/${id}/${orgUnitId}/contracts`}
        />
      )}
    </Tabs>
  );
};
