import {
    StockPartCard,
    StockPartForm,
    StockPartList,
} from '../../containers/StockPart';
import { accessTypeEnum } from '../../lib/enum';

// склад
export default [
    {
        component: StockPartList,
        path: '/admin/stock-part',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingStockParts,
            accessTypeEnum.viewingAllStockParts,
            accessTypeEnum.handlingStockParts,
        ],
    },
    {
        component: StockPartForm,
        path: '/admin/stock-part/new',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingStockParts],
    },
    {
        component: StockPartForm,
        path: '/admin/stock-part/:stockPartId/edit',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingStockParts],
    },
    {
        component: StockPartCard,
        path: '/admin/stock-part/:stockPartId',
        access: [accessTypeEnum.admin, accessTypeEnum.handlingStockParts],
    },
];