// @flow
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import React from 'react';
import styled from 'styled-components';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import { vehicleStatusEnum } from '../../../../lib/enum';

import { Filter, FilterContainer, FilterItem, Selects } from './../../../../components';
import Field from './../../../../components/card/Field';

const {
  VehicleModelSelect,
  VehicleSelect,
  YearSelect,
  VehicleTypeSelect
} = Selects;

const StyleContainer = styled.div`
  min-width: 275px;
  flex: 1 auto;
  text-align: right;
  & .ant-btn {
    margin: 0px;
  }
`;

export type VehicleListFilterParams = {
  vehicleModelId?: number,
  vehicleId?: number,
  yearIssued?: number,
  type?: string,
  hideEmpty?: boolean
};

type Props = {
  filter: VehicleListFilterParams,
  showHideEmpty?: boolean,
  vehicleType?: boolean,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({
  showHideEmpty = true,
  filter,
  cleanFilter,
  applyFilter,
  vehicleType
}: Props) => (
  <FilterContainer style={{ marginBottom: '16px' }}>
    <Filter initialValues={filter}>
      {({
        values,
        changeValue
      }: FilterChildrenParams<VehicleListFilterParams>) => (
        <>
          <FilterItem maxWidth="105px">
            <Field mBottomNone>
              <VehicleModelSelect
                placeholder="Все модели ТС"
                size="small"
                value={values.vehicleModelId}
                onChange={value => changeValue('vehicleModelId', value)}
              />
            </Field>
          </FilterItem>
          {vehicleType && (
            <FilterItem maxWidth="105px">
              <Field mBottomNone>
                <VehicleTypeSelect
                  placeholder="Все типы ТС"
                  size="small"
                  value={values.type}
                  onChange={value => changeValue('type', value)}
                />
              </Field>
            </FilterItem>
          )}
          <FilterItem maxWidth="105px">
            <Field mBottomNone>
              <VehicleSelect
                placeholder="Все ТС"
                size="small"
                value={values.vehicleId}
                onChange={value => changeValue('vehicleId', value)}
                filter={{
                  status: [
                    vehicleStatusEnum.draft,
                    vehicleStatusEnum.onRepair,
                    vehicleStatusEnum.reserved,
                    vehicleStatusEnum.working
                  ]
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem maxWidth="80px">
            <Field mBottomNone>
              <YearSelect
                placeholder="Год"
                size="small"
                value={values.yearIssued}
                onChange={value => changeValue('yearIssued', value)}
              />
            </Field>
          </FilterItem>
          {showHideEmpty && (
            <FilterItem style={{ minWidth: '165px' }}>
              <Field mBottomNone>
                <Checkbox
                  style={{ position: 'relative', top: '5px' }}
                  size="small"
                  checked={values.hideEmpty}
                  onChange={e => {
                    changeValue('hideEmpty', e.target.checked);
                  }}
                >
                  Скрыть пустые
                </Checkbox>
              </Field>
            </FilterItem>
          )}

          <StyleContainer style={{ minWidth: '150px' }}>
            <Button
              type="primary"
              size="small"
              onClick={() => applyFilter(values)}
            >
              Применить
            </Button>
            <Button type="plain" size="small" onClick={() => cleanFilter()}>
              Очистить
            </Button>
          </StyleContainer>
        </>
      )}
    </Filter>
  </FilterContainer>
);
