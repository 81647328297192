// @flow
import type { RequestSection } from '../requests';
import RequestsController from '../requests';

export class Render {
  controller: RequestSection;

  constructor(controller: RequestsController) {
    this.controller = controller.getApiSection('render');
  }

  /**
   * Создает графический слой, основанный на координатах из сообщений
   * @param params Параметры
   */
  async createMessagesLayer(params: {
    // имя слоя
    layerName: string,
    // ID объекта, чьи сообщения будут запрошены
    itemId: number,
    // начала временного интервала
    timeFrom: number,
    // окончание временного интервала
    timeTo: number,
    // использовать детектор поездок: 0 - нет, 1 - да
    tripDetector?: 0 | 1,
    // цвет трека в формате ARGB (A - альфа канал или уровень прозрачности), или «trip» (цвета по поездкам, требует «tripDetector»:1)
    trackColor?: string,
    // толщина линии трека в пикселях
    trackWidth: number,
    // отображать стрелки направления движения: 0 - нет, 1 - да
    arrows?: 0 | 1,
    // отображать точки в местах получения сообщений: 0 - нет, 1 - да
    points?: 0 | 1,
    // цвет точек
    pointColor?: string,
    // подписи к точкам: 0 - не отображать, 1 - отображать
    annotations?: 0 | 1,
    // флаги для отображения маркеров (необязательный параметр)
    flags?: number
  }) {
    const defaultParams = {
      arrows: 1,
      tripDetector: 1,
      points: 1,
      annotations: 1,
      trackColor: 'red',
      pointColor: 'blue'
    };
    return await this.controller.fetch('create_messages_layer', {
      ...defaultParams,
      ...params
    });
  }
}
