import {
    SmallScaleMechanizationToolCard,
    SmallScaleMechanizationToolForm,
    SmallScaleMechanizationToolList,
} from '../../containers/SmallScaleMechanizationTool';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: SmallScaleMechanizationToolList,
        path: '/admin/small-scale-mechanization',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: SmallScaleMechanizationToolForm,
        path: '/admin/small-scale-mechanization/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: SmallScaleMechanizationToolForm,
        path: '/admin/small-scale-mechanization/:id/edit',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: SmallScaleMechanizationToolCard,
        path: '/admin/small-scale-mechanization/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];