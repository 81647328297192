// @flow
import React, { Component } from 'react';
import { navigate } from '@reach/router';

import { Tabs, TabItem } from '../../../../../components/ui';
import type { PddTestType } from '../../../../../lib/types';
import { pddTest } from '../../../../../lib/enum';

type Props = {
  selectedTab: string,
  examTraining: PddTestType
};

export default class HeaderTabs extends Component<Props> {
  render() {
    return (
      <Tabs defaultTab={this.props.selectedTab}>
        {this.props.examTraining !== pddTest.exam && (
          <TabItem
            tabKey="tests"
            label="Обучение"
            onClick={() =>
              navigate(`/pdd/test/${this.props.examTraining}/tests/`)
            }
          />
        )}
        <TabItem
          tabKey="results"
          label="Результаты"
          onClick={() =>
            navigate(`/pdd/test/${this.props.examTraining}/results/`)
          }
        />
      </Tabs>
    );
  }
}
