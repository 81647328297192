import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import type { OperationLimitType } from '../../../../lib/types';
import { GridItem } from '../../../../components/layout';
import {
  operationLimitTypeEnum,
  operationLimitTypes
} from '../../../../lib/enum';
import { ListHeader, HeaderCell, HeaderBoldCell } from './../../elements';

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

// Функция возвращает разметку грида для строк
export const getGridTemplate = (type: OperationLimitType) => {
  switch (type) {
    case operationLimitTypeEnum.employee:
      return [
        {
          size: '(max-width: 1399px)',
          cols: ['120px', '300px', '300px', '1fr', '1fr', '1fr', '1fr']
        },
        {
          size: '(min-width: 1400px)',
          cols: ['120px', '400px', '400px', '1fr', '1fr', '1fr', '1fr']
        }
      ];
    case operationLimitTypeEnum.orgUnit:
      return [
        {
          size: '(max-width: 1399px)',
          cols: ['300px', '300px', '1fr', '1fr', '1fr', '1fr']
        },
        {
          size: '(min-width: 1400px)',
          cols: ['400px', '400px', '1fr', '1fr', '1fr', '1fr']
        }
      ];
    case operationLimitTypeEnum.ovb:
    default:
      return [
        {
          size: '(max-width: 1399px)',
          cols: ['300px', '300px', '350px', '1fr', '1fr']
        },
        {
          size: '(min-width: 1400px)',
          cols: ['400px', '400px', '450px', '1fr', '1fr']
        }
      ];
  }
};

const commonCells = (startFrom: number) => (
  <>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: 'span 2'
      }}
    >
      <HeaderBoldCell>Время использования</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7',
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: startFrom,
        gridRow: `2`
      }}
    >
      <HeaderCell>Будние дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{
        borderTop: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: startFrom + 1,
        gridRow: 2
      }}
    >
      <HeaderCell>Выходные дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{}}
      cellUnion={{
        gridColumn: `${startFrom + 2} / span 2`,
        gridRow: 1
      }}
    >
      <HeaderBoldCell>Пробег</HeaderBoldCell>
    </GridItem>
    <GridItem
      style={{
        borderRight: '1px solid #c0ccd7',
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: startFrom + 2,
        gridRow: 2
      }}
    >
      <HeaderCell>Будние дни</HeaderCell>
    </GridItem>
    <GridItem
      style={{
        borderTop: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: startFrom + 3,
        gridRow: 2
      }}
    >
      <HeaderCell>Выходные дни</HeaderCell>
    </GridItem>
  </>
);

export const EmployeeHeader = () => (
  <ListHeader media={getGridTemplate(operationLimitTypeEnum.employee)} rows={2}>
    <GridItem
      cellUnion={{
        gridColumn: 1,
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>Должность</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: 2,
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ФИО</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: 3,
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    {commonCells(4)}
  </ListHeader>
);

export const OrgUnitHeader = () => (
  <ListHeader media={getGridTemplate(operationLimitTypeEnum.orgUnit)} rows={2}>
    <GridItem
      cellUnion={{
        gridColumn: 1,
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>Служба</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: 2,
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    {commonCells(3)}
  </ListHeader>
);

export const ListTableHeader = ({ type }: { type: OperationLimitType }) => (
  <ListHeader cols={getGridTemplate(type)} rows={2}>
    <GridItem
      cellUnion={{
        gridColumn: '1 / span 1',
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>{operationLimitTypes[type]}</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const ListTableOvbHeader = () => (
  <ListHeader media={getGridTemplate(operationLimitTypeEnum.ovb)}>
    <GridItem>
      <HeaderBoldCell>ОВБ</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Служба</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Время использования</HeaderBoldCell>
    </GridItem>
    <GridItem>
      <HeaderBoldCell>Пробег</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
