// @flow
import type { MaintenanceDocument } from './../types';
import { convertFromKopToRub } from './../helpers';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';

export const convertDataFromServer = (
  maintenanceDocument: MaintenanceDocument
): MaintenanceDocument => ({
  ...maintenanceDocument,
  maintenance: {
    ...maintenanceDocument.maintenance,
    totalSum: convertFromKopToRub(maintenanceDocument.maintenance.totalSum)
  }
});

export const addMaintenanceDocument = async (
  maintenanceDocument: MaintenanceDocument
): Promise<MaintenanceDocument> => {
  const added = await fetchRequest.post(
    '/maintenanceDocument',
    maintenanceDocument
  );
  if (added) return added;
  throw new Error('Не удалось создать ремонт');
};

export const updateMaintenanceDocument = async (
  maintenanceDocument: MaintenanceDocument
): Promise<MaintenanceDocument> => {
  const updated = await fetchRequest.put(
    '/maintenanceDocument',
    maintenanceDocument
  );
  if (updated) return updated;
  throw new Error('Не удалось обновить ремонт');
};

export const getMaintenanceDocument = async (
  id: number,
  params: any = {}
): Promise<MaintenanceDocument> =>
  await fetchRequest.get(`/maintenanceDocument/${id}`, params);

export const deleteMaintenanceDocument = async (
  id: number
): Promise<MaintenanceDocument> => {
  const deleted = await fetchRequest.delete(`/maintenanceDocument/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить ремонт');
};

export const fetchMaintenanceDocument = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<MaintenanceDocument>> => {
  const maintenanceDocument = await fetchRequest.get('/maintenanceDocument', {
    ...initialFetchListParams,
    ...params
  });
  if (maintenanceDocument) {
    return {
      ...maintenanceDocument,
      data: maintenanceDocument.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить акты');
};

export default {
  addMaintenanceDocument,
  updateMaintenanceDocument,
  getMaintenanceDocument,
  deleteMaintenanceDocument,
  fetchMaintenanceDocument
};
