// @flow
import React from 'react';
import type { FormikProps } from 'formik';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker } from '../../../components/ant/DatePicker';
import { Table } from '../../../components/ant/table/Table';

import type { WashingPriceList } from '../../../lib/types';

import { Selects, Form } from './../../../components';
import { Section, Footer } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { FormFieldType } from '../../../components/Form';
import CancelButton from './../../../components/CancelButton';
import { Popconfirm, Icon } from '../../../components/ui';
import { SectionTitle } from '../../../components/layout';
import { InputNumber } from '../../../components/inputs';

import type {
  WashingPrice,
  WashingVehicleType,
} from '../../../lib/types/washingPriceList';
import { withEmptyRow } from '../../../lib/helpers';
import WashingVehicleTypeSelect from '../../../components/selects/WashingVehicleTypeSelect';

const SectionContent = styled.div`
  padding: 16px;
`;

const { ContractorSelect } = Selects;

type FormProps = {
  washingPriceList: ?WashingPriceList,
  onSubmit: Function,
  onCancel: () => void,
  employeeOrgUnitId: number,
};

export default ({
  washingPriceList,
  onSubmit,
  employeeOrgUnitId,
  onCancel,
}: FormProps) => {
  return (
    <Form initialValues={washingPriceList} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { values, handleSubmit, handleBlur, setFieldValue, dirty } =
          formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionTitle divider>Договор</SectionTitle>
              <SectionContent>
                <Grid gutter="16px" cols={2}>
                  <GridItem>
                    <FormField
                      label="Подрядчик"
                      name="contractorId"
                      required
                      fast
                    >
                      {({ name, value }) => (
                        <ContractorSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Номер договора"
                      name="contractNumber"
                      required
                      fast
                    >
                      {(field) => (
                        <Input
                          {...field}
                          placeholder="Введите номер договора"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата начала действия прейскуранта"
                      name="startDate"
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment.utc(value).startOf('day').toISOString()
                            );
                            setFieldValue('endDate', undefined);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата окончания действия прейскуранта"
                      name="endDate"
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          disabled={values.startDate ? false : true}
                          disabledDate={(date: string) =>
                            values.startDate
                              ? moment
                                  .utc(date)
                                  .startOf('day')
                                  .isSameOrBefore(moment.utc(values.startDate))
                              : false
                          }
                          onChange={(value: string) =>
                            setFieldValue(
                              name,
                              moment.utc(value).startOf('day').toISOString()
                            )
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>Цены в рублях, без НДС</SectionTitle>
              <FormField name="washingPrices">
                {({ name, value = [] }) => {
                  return (
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: 'Тип ТС',
                          dataIndex: 'washingVehicleTypeId',
                          width: 350,
                          render: (
                            washingVehicleType: WashingVehicleType,
                            data: WashingPrice,
                            index: number
                          ) => (
                            <FormField
                              style={{ marginBottom: 0 }}
                              name={`${name}[${index}].washingVehicleTypeId`}
                            >
                              {({ name, value }) => (
                                <WashingVehicleTypeSelect
                                  size="small"
                                  value={value}
                                  name={name}
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                />
                              )}
                            </FormField>
                          ),
                        },
                        {
                          title: 'Кузов',
                          dataIndex: 'bodyPrice',
                          render: (
                            bodyPrice: number,
                            data: WashingPrice,
                            index: number
                          ) => (
                            <FormField
                              style={{ marginBottom: 0 }}
                              name={`${name}[${index}].bodyPrice`}
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  size="small"
                                  name={name}
                                  value={value}
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                />
                              )}
                            </FormField>
                          ),
                        },
                        {
                          title: 'Салон',
                          dataIndex: 'interiorPrice',
                          render: (
                            interiorPrice: number,
                            data: WashingPrice,
                            index: number
                          ) => (
                            <FormField
                              style={{ marginBottom: 0 }}
                              name={`${name}[${index}].interiorPrice`}
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  size="small"
                                  name={name}
                                  value={value}
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                />
                              )}
                            </FormField>
                          ),
                        },
                        {
                          title: 'ДВС',
                          dataIndex: 'enginePrice',
                          render: (
                            enginePrice: number,
                            data: WashingPrice,
                            index: number
                          ) => (
                            <FormField
                              style={{ marginBottom: 0 }}
                              name={`${name}[${index}].enginePrice`}
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  size="small"
                                  value={value}
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                />
                              )}
                            </FormField>
                          ),
                        },
                        {
                          stopPropagation: true,
                          width: 20,
                          dataIndex: 'washingVehicleTypeId',
                          render: (id: number, record) => (
                            <FormField>
                              {() => (
                                <Popconfirm
                                  title="Вы уверены, что хотите удалить запись?"
                                  onConfirm={() =>
                                    setFieldValue(
                                      `${name}`,
                                      values.washingPrices.filter(
                                        (el) => el !== record
                                      )
                                    )
                                  }
                                >
                                  <Icon type="x" />
                                </Popconfirm>
                              )}
                            </FormField>
                          ),
                        },
                      ]}
                      dataSource={withEmptyRow(value)}
                    />
                  );
                }}
              </FormField>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
