// @flow
import { EditOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import { Link } from '@reach/router';

import type { Kasko } from './../../../lib/types';
import { kaskoApi } from '../../../lib/api';
import { Popconfirm, Icon, ListTable } from './../../../components/ui';
import { formatDateTimeToString } from './../../../lib/helpers';
import { notificationLoading } from './../../../components/Notifications';
import type { Contractor } from '../../../lib/types';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(EditOutlined)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean,
};

type State = {
  loading: boolean,
  kaskoList: Kasko[],
};

class KaskoList extends Component<Props, State> {
  state = {
    loading: false,
    kaskoList: [],
  };

  async componentDidMount() {
    await this.fetchKaskos();
  }

  fetchKaskos = async () => {
    try {
      this.setState({
        loading: true,
      });
      const kaskoList = await kaskoApi.fetchByVehicle(this.props.vehicleId);
      this.setState({
        kaskoList: kaskoList.data,
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  onDelete = async (kasko: Kasko) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      if (kasko.id) {
        // TODO: Добавить удаление через api
        notification.success({
          message: `КАСКО успешно удален`,
        });
        await this.fetchKaskos();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete } = this.props;
    const { kaskoList, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          {
            title: 'Серия и номер КАСКО',
            key: 'kaskoNumber',
          },
          {
            title: 'Дата окончания',
            key: 'endDate',
            render: (value: string) =>
              formatDateTimeToString(value, 'DD.MM.YYYY'),
          },
          {
            title: 'Наименование страховщика',
            key: 'contractor',
            render: (contractor: Contractor) =>
              (contractor && contractor.company.name) || '-',
          },
          {
            width: '20px',
            renderRecord: (record: Kasko) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/vehicles/${this.props.vehicleId}/kasko/${parseInt(
                      record.id
                    )}`}
                  >
                    <StyledAntIcon />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null,
          },
        ]}
        data={kaskoList}
      />
    );
  }
}

export default KaskoList;
