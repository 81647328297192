// @flow
import queryString from 'query-string';
import type { InspectionGibdd, ExecutionBudgetGroupedList } from './../types';

import {
  fetchRequest,
  downloadRequestWithToken,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { InspectionGibddFilterParams } from '../../containers/services/inspectionsGibdd/Filter';

export const convertDataFromServer = (inspectionGibdd: InspectionGibdd) => ({
  ...inspectionGibdd,
  paymentAmount: convertFromKopToRub(inspectionGibdd.paymentAmount),
  act: inspectionGibdd.act
    ? actConvertDataFromServer(inspectionGibdd.act)
    : undefined
});

export const convertDataToServer = (inspectionGibdd: InspectionGibdd) => ({
  ...inspectionGibdd,
  paymentAmount: convertFromRubToKop(inspectionGibdd.paymentAmount)
});

export const addInspectionGibdd = async (
  inspectionGibdd: InspectionGibdd
): Promise<InspectionGibdd> => {
  const added = await fetchRequest.post(
    '/inspectionGibdd',
    convertDataToServer(inspectionGibdd)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать технический осмотр ГИБДД');
};

export const updateInspectionGibdd = async (
  inspectionGibdd: InspectionGibdd
): Promise<InspectionGibdd> => {
  const updated = await fetchRequest.put(
    '/inspectionGibdd',
    convertDataToServer(inspectionGibdd)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить технический осмотр ГИБДД');
};
export const deleteInspectionGibdd = async (
  id: number
): Promise<InspectionGibdd> => {
  const deleted = await fetchRequest.delete(`/inspectionGibdd/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить технический осмотр ГИБДД');
};

export const fetchInspectionGibdd = async (
  id: number,
  params: any = {}
): Promise<InspectionGibdd> => {
  const inspectionGibdd = await fetchRequest.get(
    `/inspectionGibdd/${id}`,
    params
  );
  if (inspectionGibdd) return convertDataFromServer(inspectionGibdd);
  throw new Error('Не удалось получить технический осмотр ГИБДД');
};

export const fetchVehicleInspectionsGibdd = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<InspectionGibdd>> => {
  const inspectionGibddes = await fetchRequest.get(
    `/inspectionGibdd/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (inspectionGibddes) {
    return {
      ...inspectionGibddes,
      data: inspectionGibddes.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список технических осмотров ГИБДД');
};

export const fetchInspectionsGibdd = async (
  params: FetchListParams<InspectionGibdd> = initialFetchListParams
): Promise<ListResponse<InspectionGibdd>> => {
  const inspectionsGibdd = await fetchRequest.get('/inspectionGibdd', {
    ...initialFetchListParams,
    ...params
  });
  if (inspectionsGibdd) {
    return {
      ...inspectionsGibdd,
      data: inspectionsGibdd.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось список технических осмотров ГИБДД');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<InspectionGibdd>> => {
  const inspectionGibdd = await fetchRequest.put(
    `/inspectionGibdd/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (inspectionGibdd) {
    return {
      ...inspectionGibdd,
      data: inspectionGibdd.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за т.о. ГИБДД');
};

export const printReport = async (
  params: InspectionGibddFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/inspectionGibdd/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/inspectionGibdd/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/inspectionGibdd/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export default {
  groupedList,
  getTotalSum,
  printReport,
  updateAct,
  addInspectionGibdd,
  updateInspectionGibdd,
  deleteInspectionGibdd,
  fetchInspectionGibdd,
  fetchVehicleInspectionsGibdd,
  fetchInspectionsGibdd
};
