// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import isNil from 'lodash/isNil';
import React, { Component } from 'react';
import styled from 'styled-components';
import { SectionTitle } from '../../../../components/layout';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import type { Vehicle } from '../../../../lib/types';
import type { FuelCard } from '../../../../lib/types/fuelCard';

import { Spinner } from './../../../../components';
import { Option, Select } from './../../../../components/ant/Select';

const Content = styled.div`
  padding: 16px;
`;
const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

type Props = {
  onSubmit: (fuelCard: FuelCard) => void,
  availableFuelCards: FuelCard[],
  handleSearch: (searchString?: string) => void,
  fetchData: () => void,
  isLoading: boolean,
  vehicle: Vehicle
};

type State = {
  currentFuelCardId?: ?number
};

class FuelCardForm extends Component<Props, State> {
  static defaultProps = {
    availableFuelCards: []
  };
  state = {};

  onFuelCardSelect = async (currentFuelCardId: number) => {
    if (isNil(currentFuelCardId)) {
      await this.props.handleSearch();
    }
    this.setState({
      currentFuelCardId
    });
  };

  onAddClick = async () => {
    const { currentFuelCardId } = this.state;
    const { availableFuelCards } = this.props;
    const fuelCard = availableFuelCards.find(
      item => item.id === currentFuelCardId
    );
    if (fuelCard) {
      await this.props.onSubmit(fuelCard);
      this.setState({
        currentFuelCardId: null
      });
    } else {
      notification.error({
        message: 'Необходимо выбрать топливную карту из списка'
      });
    }
  };

  render() {
    const { currentFuelCardId } = this.state;
    const {
      availableFuelCards,
      handleSearch,
      fetchData,
      isLoading
    } = this.props;
    return (
      <>
        <SectionTitle>Топливная карта</SectionTitle>
        <Content>
          <Grid gutter="16px">
            <GridItem>
              <StyledSelect
                allowClear
                placeholder="Выберите топливную карту"
                value={currentFuelCardId}
                onChange={this.onFuelCardSelect}
                showSearch
                onSearch={handleSearch}
                filterOption={false}
                defaultActiveFirstOption={false}
                onBlur={fetchData}
                notFoundContent={
                  <Spinner isLoading={isLoading}>
                    Топливные карты не найдены
                  </Spinner>
                }
              >
                {availableFuelCards.map((fuelCard: FuelCard) => (
                  <Option key={fuelCard.id} value={fuelCard.id}>
                    {fuelCard.cardNumber}
                  </Option>
                ))}
              </StyledSelect>
            </GridItem>
            <GridItem fullWidth>
              <Button
                disabled={!currentFuelCardId}
                type="primary"
                onClick={this.onAddClick}
              >
                Добавить
              </Button>
            </GridItem>
          </Grid>
        </Content>
      </>
    );
  }
}

export default FuelCardForm;
