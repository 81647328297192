import { BrandForm, BrandList } from '../../containers/Brands';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: BrandForm,
        path: '/admin/brands/new',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingBrandsAndVehicleModels,
        ],
    },
    {
        component: BrandForm,
        path: '/admin/brands/:brandId',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.handlingBrandsAndVehicleModels,
        ],
    },
    {
        component: BrandList,
        path: '/admin/brands',
        access: [
            accessTypeEnum.admin,
            accessTypeEnum.viewingBrandsAndVehicleModels,
            accessTypeEnum.handlingBrandsAndVehicleModels,
        ],
    },
];