// @flow

import React, { useEffect, useState } from 'react';
import { StyledBaseTable as Table } from '../ant/table/StyledBaseTable';

type Props = {
  pagination?: boolean,
  data: any[],
  loading: Boolean,
  columns: any[],
};

export default (props: Props) => {
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns(
      props.columns.map((column) => {
        let dataIndex = '';
        if (Array.isArray(column.dataIndex)) {
          dataIndex = column.dataIndex;
        } else if (typeof column.dataIndex === 'string') {
          dataIndex = column.dataIndex.split('.');
        }
        return {
          ...column,
          dataIndex,
        };
      })
    );
  }, [props.columns]);
  return (
    <Table {...props} rowKey="id" columns={columns} dataSource={props.data} />
  );
};
