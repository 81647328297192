// @flow
import React from 'react';
import notification from 'antd/lib/notification';

import { applyMaskToValue, declineNumber } from './../../../lib/helpers';
import type {
  ReportArchiveVehicle,
  ReportArchiveVehicleParams,
} from '../../../lib/types/reportArchiveVehicle';
import { notificationLoading } from '../../../components/Notifications';
import { reportArchiveVehiclesApi } from '../../../lib/api';

import Header from '../../../components/layout/Header';
import { Table } from '../../../components/ant/table/Table';
import {
  PageFlexColumnWrapper,
  TableFlexColumnWrapper,
} from '../../../components/ant/table/Wrappers';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import Filter from './components/Filter';

const initialParams: ReportArchiveVehicleParams = {};

type State = {
  params: ReportArchiveVehicleParams,
  data: ReportArchiveVehicle[],
  loading: boolean,
  scrollY: number,
};

/**
 * Отчет о рекомендуемых к списанию/замене ТС
 */
export class ArchiveVehiclesList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    scrollY: window.innerHeight - 450,
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState((prevState) => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };

  async componentDidMount() {
    await this.fetchReportArchiveVehicles();
  }

  fetchReportArchiveVehicles = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportArchiveVehiclesApi.fetchReportArchiveVehicles(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyParams = this.fetchReportArchiveVehicles;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print',
      });
      await reportArchiveVehiclesApi.printReportArchiveVehicles(
        this.state.params
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'Тип ТС',
      dataIndex: 'vehicleType',
      width: 100,
      render: (type: string) => type,
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      width: 150,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Марка ТС',
      width: 100,
      dataIndex: 'brandName',
    },
    {
      title: 'Подразделение',
      width: 150,
      dataIndex: 'orgUnitName',
    },
    {
      title: 'Возраст',
      width: 100,
      dataIndex: 'vehicleAge',
      render: (vehicleAge: number) =>
        `${vehicleAge} ${declineNumber(vehicleAge, 'год', 'года', 'лет')}`,
    },
    {
      title: 'Пробег',
      width: 100,
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`,
    },
    {
      title: 'Количество маш/часов',
      width: 100,
      dataIndex: 'engineWorkHours',
      render: (workTime: number) => `${workTime} ч.`,
    },
  ];

  render() {
    const { loading, data, scrollY } = this.state;
    return (
      <PageFlexColumnWrapper>
        <Header left={<h1>Отчет о рекомендуемых к списанию/замене ТС</h1>} />
        <TableFlexColumnWrapper>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            rowKey="vehicleId"
            pagination={false}
            dataSource={data}
            columns={this.columns}
            name="ReportsArchiveVehicles"
            flags={['useNewStyle']}
            customStyle={{
              overflowY: undefined,
              overflowX: undefined,
            }}
            customScroll={{
              y: scrollY,
              x: 1500,
            }}
          />
        </TableFlexColumnWrapper>
      </PageFlexColumnWrapper>
    );
  }
}

export default ArchiveVehiclesList;
