import {
  BioTreatmentFacilityContractList,
  BioTreatmentFacilityContractForm,
  BioTreatmentFacilityContractCard,
} from '../containers/BioTreatmentFacilityContract';
import { accessTypeEnum } from '../lib/enum';

export default [
  {
    component: BioTreatmentFacilityContractList,
    path: '/bioTreatmentFacilityContract',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBioTreatmentFacility,
      accessTypeEnum.handlingBioTreatmentFacility,
      accessTypeEnum.approvingBioTreatmentFacility,
      accessTypeEnum.addFactBioTreatmentFacility,
    ],
  },
  {
    component: BioTreatmentFacilityContractForm,
    path: '/bioTreatmentFacilityContract/new',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingBioTreatmentFacility,
    ],
  },
  {
    component: BioTreatmentFacilityContractCard,
    path: '/bioTreatmentFacilityContract/:id',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBioTreatmentFacility,
      accessTypeEnum.handlingBioTreatmentFacility,
      accessTypeEnum.approvingBioTreatmentFacility,
      accessTypeEnum.addFactBioTreatmentFacility,
    ],
  },
  {
    component: BioTreatmentFacilityContractForm,
    path: '/bioTreatmentFacilityContract/:id/edit',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingBioTreatmentFacility,
    ],
  },
];
