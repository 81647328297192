// @flow
import type { TechnicalFluid } from './../types';
import CommonApi from '../commonApi';
import { downloadRequestWithToken } from './';

const basedUrl = 'technicalFluid';
const technicalFluid = new CommonApi<TechnicalFluid>(
  basedUrl,
  'талон на выдачу тех. жидкости'
);

export default {
  ...technicalFluid,
  print: async (id: number, params?: any, filename?: string) => {
    const result = await downloadRequestWithToken(
      `/${basedUrl}/${id}/print`,
      params,
      filename
    );
    if (result) return result;
    throw new Error('Не удалось загрузить печатную форму');
  }
};
