// @flow
import { navigate } from '@reach/router';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import type { FormikProps } from 'formik';
import moment from 'moment';
import React, { Component } from 'react';
import styled from 'styled-components';
import { Form } from '../../../components';
import { DatePicker } from '../../../components/ant/DatePicker';
import CancelButton from '../../../components/CancelButton';
import type { FormFieldType } from '../../../components/Form';
import { InputNumber } from '../../../components/inputs';

import { Footer, Header, Panel, Section } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { notificationLoading } from '../../../components/Notifications';
import { osagoMultiplier } from '../../../lib/api';

import type { OsagoPowerMultiplier } from '../../../lib/types';
import { disabledDateMin, formatDateTimeToISOString, getValueObject, goBack } from './../../../lib/helpers';

type Props = {
  location: any,
  osagoPowerMultiplierId: ?string
};
type State = {
  osagoPowerMultiplier: ?$Shape<OsagoPowerMultiplier>
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const Required = styled.span`
  color: red;
  position: relative;
  top: -3px;
  right: -2px;
  font-size: 12px;
`;

export default class OsagoPowerMultiplierForm extends Component<Props, State> {
  state = {
    osagoPowerMultiplier: null
  };

  onSubmit = async (values: OsagoPowerMultiplier) => {
    const { osagoPowerMultiplierId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение...',
        key: 'saving'
      });
      if (osagoPowerMultiplierId) {
        await osagoMultiplier.updateOsagoPowerMultiplier(values);
      } else {
        await osagoMultiplier.addOsagoPowerMultiplier(values);
      }
      navigate('/admin/osago-multiplier/power');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  async componentDidMount() {
    let osagoPowerMultiplierId = parseInt(
      this.props.osagoPowerMultiplierId,
      10
    );
    try {
      if (osagoPowerMultiplierId) {
        try {
          const osagoPowerMultiplier = await osagoMultiplier.getOsagoPowerMultiplier(
            osagoPowerMultiplierId
          );
          this.setState({ osagoPowerMultiplier });
        } catch (error) {
          notification.error({
            message: 'Ошибка',
            description: error.message
          });
        }
      } else {
        let osagoPowerMultiplier = {};
        osagoPowerMultiplier.startDate = getValueObject(
          this.props,
          'location.state.startDate'
        );
        this.setState({ osagoPowerMultiplier });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onCancel = () => goBack('/admin/osago-multiplier/power');

  render() {
    const { osagoPowerMultiplierId } = this.props;
    const { osagoPowerMultiplier } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/osago-multiplier/power">
                Коэффициенты ОСАГО мощностей
              </Crumb>
              {osagoPowerMultiplierId ? (
                <Crumb
                  to={`/admin/osago-multiplier/power/edit/${osagoPowerMultiplierId}`}
                >
                  Коэффициенты ОСАГО мощностей №{osagoPowerMultiplierId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/osago-multiplier/power/new`}>
                  Новые коэффициенты ОСАГО мощностей
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoPowerMultiplierId
              ? `Редактируем запись №${osagoPowerMultiplierId}`
              : `Новая запись`}
          </h1>
        </StyledPanel>
        <Form initialValues={osagoPowerMultiplier} onSubmit={this.onSubmit}>
          {(FormField: FormFieldType, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              setFieldValue,
              dirty,
              isSubmitting,
              values
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <SectionContent>
                    <Grid>
                      <GridItem>
                        До 50 л.с включительно
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="lessThan50"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Свыше 50 л.с до 70 л.с включительно
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="from50To70"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Свыше 70 л.с до 100 л.с включительно
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="from70To100"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Свыше 100 л.с до 120 л.с включительно
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="from100To120"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Свыше 120 л.с до 150 л.с включительно
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="from120To150"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Свыше 150 л.с
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="greaterThan150"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>

                    <Grid>
                      <GridItem>
                        Дата начала
                        <Required>*</Required>
                      </GridItem>
                      <GridItem>
                        <FormField
                          required
                          style={{ width: '140px' }}
                          hasFeedback={false}
                          name="startDate"
                          fast
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                                setFieldValue('endDate', undefined);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid>
                      <GridItem>Дата окончания</GridItem>
                      <GridItem>
                        <FormField
                          hasFeedback={false}
                          style={{ width: '140px' }}
                          name="endDate"
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={value ? moment.utc(value) : value}
                              disabled={values.startDate ? false : true}
                              disabledDate={(currentDate: moment) =>
                                disabledDateMin(
                                  currentDate,
                                  moment(values.startDate)
                                )
                              }
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    data-cy="save"
                  >
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.onCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
