// @flow
import type { FuelPrice } from './../types';

import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import CommonApi from './../commonApi';

const convertDataToServer = (fuelPrice: FuelPrice) => ({
  ...fuelPrice,
  ai80Price: convertFromRubToKop(fuelPrice.ai80Price),
  ai92Price: convertFromRubToKop(fuelPrice.ai92Price),
  ai95Price: convertFromRubToKop(fuelPrice.ai95Price),
  dieselFuelPrice: convertFromRubToKop(fuelPrice.dieselFuelPrice),
  motorOilPrice: convertFromRubToKop(fuelPrice.motorOilPrice),
  transmissionOilPrice: convertFromRubToKop(fuelPrice.transmissionOilPrice),
  specialOilPrice: convertFromRubToKop(fuelPrice.specialOilPrice),
  autoGreasePrice: convertFromRubToKop(fuelPrice.autoGreasePrice)
});

const convertDataFromServer = (fuelPrice: FuelPrice) => ({
  ...fuelPrice,
  ai80Price: convertFromKopToRub(fuelPrice.ai80Price),
  ai92Price: convertFromKopToRub(fuelPrice.ai92Price),
  ai95Price: convertFromKopToRub(fuelPrice.ai95Price),
  dieselFuelPrice: convertFromKopToRub(fuelPrice.dieselFuelPrice),
  motorOilPrice: convertFromKopToRub(fuelPrice.motorOilPrice),
  transmissionOilPrice: convertFromKopToRub(fuelPrice.transmissionOilPrice),
  specialOilPrice: convertFromKopToRub(fuelPrice.specialOilPrice),
  autoGreasePrice: convertFromKopToRub(fuelPrice.autoGreasePrice)
});

const fuelPrice = new CommonApi<FuelPrice>(
  'fuelPrice',
  'прейскурант ГСМ',
  convertDataToServer,
  convertDataFromServer
);

export default {
  addFuelPrice: fuelPrice.add,
  updateFuelPrice: fuelPrice.update,
  deleteFuelPrice: fuelPrice.delete,
  getFuelPrice: fuelPrice.get,
  fetchFuelPrice: fuelPrice.fetch
};
