// @flow
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Link } from '@reach/router';
import Popover from 'antd/lib/popover';
import moment from 'moment';
import React, { useState } from 'react';

import { Space } from '../../components/ant/Space';
import CommonListPage from '../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';

import { vehicleApi } from '../../lib/api';
import {
  accessTypeEnum,
  maintenanceScheduleStatusesTypes,
} from '../../lib/enum';
import type { MaintenanceScheduleVehicle, UserAccess } from '../../lib/types';
import { withUserAccess } from '../withUserAccess';

type Props = {
  userAccess: UserAccess[],
  date: number,
};

const breadCrumbs = [
  { name: 'Главная', to: '/' },
  {
    name: 'Ремонты и ТО',
    to: '/maintenances/schedule',
  },
  {
    name: 'График ТО',
  },
];

const access = {};

export default withUserAccess((props: Props) => {
  const [date] = useState(moment.unix(Number(props.date) / 1000).utc());

  const canAdd = () =>
    props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingMaintenances,
      ].includes(access)
    );

  const canApprove = () =>
    props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingMaintenances,
      ].includes(access)
    );

  const columns = [
    {
      title: 'Вид ТО',
      dataIndex: 'maintenanceWorkType.name',
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (licensePlate: string, record: MaintenanceScheduleVehicle) => {
        return (
          <Space align="baseline">
            {licensePlate}
            {!record.isOverdue ? (
              record.kilometrage || record.plannedKilometrage ? (
                <Popover
                  content={
                    <div>
                      <div>
                        Регламентный пробег: {record.plannedKilometrage ?? '-'}
                      </div>
                      <div>
                        Пробег при прохождении ТО: {record.kilometrage ?? '-'}
                      </div>
                    </div>
                  }
                  placement="right"
                >
                  <ExclamationCircleTwoTone twoToneColor="red" />
                </Popover>
              ) : (
                <ExclamationCircleTwoTone twoToneColor="red" />
              )
            ) : null}
          </Space>
        );
      },
    },
    {
      title: 'Марка/модель',
      dataIndex: 'brandName',
      render: (brandName: string, row: MaintenanceScheduleVehicle) => {
        return `${row.brandName} ${row.vehicleModelName}`;
      },
    },
    {
      title: 'Год выпуска',
      dataIndex: 'yearIssued',
    },
    {
      title: 'План по графику',
      dataIndex: 'plannedDate',
      render: (plannedDate: string, row: MaintenanceScheduleVehicle) => {
        const color = !row.maintenanceId
          ? '#0093fe'
          : moment(plannedDate).isSame(
              moment(row.maintenancePlannedDate),
              'day'
            )
          ? '#22D397'
          : 'red';

        return (
          <div style={{ color }}>
            {plannedDate ? moment(plannedDate).format('DD.MM.YYYY') : '-'}
          </div>
        );
      },
    },
    {
      title: 'Запланировано',
      dataIndex: 'maintenancePlannedDate',
      render: (maintenancePlannedDate) =>
        maintenancePlannedDate
          ? moment(maintenancePlannedDate).format('DD.MM.YYYY')
          : '-',
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      render: (startDate) =>
        startDate ? moment(startDate).utc().format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      render: (endDate) =>
        endDate ? moment(endDate).utc().format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Текущий статус',
      dataIndex: 'status',
      render: (status) => {
        return status ? maintenanceScheduleStatusesTypes[status] : '-';
      },
    },
    {
      title: '',
      dataIndex: 'maintenanceId',
      render: (maintenanceId, record: MaintenanceScheduleVehicle) => {
        if (maintenanceId) {
          const url =
            canAdd() || canApprove()
              ? `/maintenances/journal/${maintenanceId}`
              : `/maintenances/journal/read/${maintenanceId}`;
          return <Link to={url}>Перейти</Link>;
        } else {
          return (
            canAdd() && (
              <Link
                state={{
                  maintenanceWorkTypeId: record.maintenanceWorkTypeId,
                  vehicleId: record.vehicleId,
                  plannedDate: record.plannedDate,
                }}
                to={`/maintenances/journal/new`}
              >
                Создать
              </Link>
            )
          );
        }
      },
    },
  ];

  const onFetch = async (p) => {
    return {
      data: await vehicleApi.getMaintenanceScheduleByDate(date.toISOString()),
    };
  };

  return (
    <Page>
      <CommonListPage
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: `График ТО на ${moment(date).format('MMMM YYYY')}`,
          access,
        }}
        tableListProps={{
          columns,
        }}
        oldCrud={{
          onFetch,
        }}
      />
    </Page>
  );
});
