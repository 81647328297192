// @flow
import type { GpsSubscriptionServiceCost } from './../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (
  gpsSubscriptionServiceCost: GpsSubscriptionServiceCost
) => ({
  ...gpsSubscriptionServiceCost,
  cost: convertFromRubToKop(gpsSubscriptionServiceCost.cost)
});

const convertDataFromServer = (
  gpsSubscriptionServiceCost: GpsSubscriptionServiceCost
) => ({
  ...gpsSubscriptionServiceCost,
  cost: convertFromKopToRub(gpsSubscriptionServiceCost.cost)
});

const api = new CommonApi<GpsSubscriptionServiceCost>(
  'gpsSubscriptionServiceCost',
  'Общероссийский классификатор единиц измерения',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...api
};
