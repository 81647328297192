// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { pddTestApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await pddTestApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={pddTestApi.get}
    notFoundText="Тест не найден"
    placeholder="Выберите тест"
    renderOption={(pddTest: any, Option) => (
      <Option key={pddTest.id} value={pddTest.id} pddTest={pddTest}>
        {pddTest.name}
      </Option>
    )}
    {...selectProps}
  />
);
