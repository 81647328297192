// @flow
import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import notification from 'antd/lib/notification';

import type { SideServiceTariff, UserAccess } from '../../lib/types';
import { sideServiceTariffApi } from '../../lib/api';
import {
  sideServiceType,
  vehicleTypes,
  sideServiceTariffMeasure,
  accessTypeEnum,
  sideServiceTariffType,
} from '../../lib/enum';
import { formatDateTimeToString, formatRub } from './../../lib/helpers';

import { Card } from './../../components';
import { Icon } from '../../components/ui';
import { Header, Panel, Section } from '../../components/layout';
import { Crumb } from '../../components/layout/Breadcrumbs';
import Breadcrumbs from '../../components/layout/Breadcrumbs';
import Grid, { GridItem } from './../../components/layout/Grid';

import { withUserAccess } from '../withUserAccess';

const { Field } = Card;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Content = styled.div`
  padding: 16px;
`;

type Props = {
  id?: number,
  userAccess: UserAccess[],
};

type State = {
  sideServiceTariff: SideServiceTariff,
};

export class Form extends React.Component<Props, State> {
  state = {
    sideServiceTariff: null,
  };

  async componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { id } = this.props;
    try {
      if (id) {
        const sideServiceTariff = await sideServiceTariffApi.get(id);
        this.setState({ sideServiceTariff });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  canAdd = () =>
    this.props.userAccess.some((access) =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingSideServiceTariff,
      ].includes(access)
    );

  render() {
    const { id } = this.props;
    const { sideServiceTariff } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/sideServiceTariff">Список тарифов</Crumb>
              {id && <Crumb>Тариф для услуг на сторону №{id}</Crumb>}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {id && `Тарифы для услуг на сторону №${id}`}
            {this.canAdd() && (
              <StyledIcon
                onClick={() =>
                  id && navigate(`/admin/sideServiceTariff/${id}/edit`)
                }
                type="edit"
                size={16}
              />
            )}
          </h1>
        </StyledPanel>
        <Section>
          <Content>
            <Grid>
              <GridItem>
                <Field label="Подразделение">
                  {sideServiceTariff?.orgUnitName ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип услуги">
                  {sideServiceType[sideServiceTariff?.sideServiceType]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата начала">
                  {formatDateTimeToString(
                    sideServiceTariff?.startDate,
                    'DD.MM.YYYY'
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата окончания">
                  {formatDateTimeToString(
                    sideServiceTariff?.endDate,
                    'DD.MM.YYYY'
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип тс">
                  {sideServiceTariff?.vehicleType
                    ? vehicleTypes[sideServiceTariff?.vehicleType]
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Цена, руб">
                  {formatRub(sideServiceTariff?.price)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип учета">
                  {
                    sideServiceTariffMeasure[
                      sideServiceTariff?.sideServiceTariffMeasure
                    ]
                  }
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип контракта услуги">
                  {
                    sideServiceTariffType[
                      sideServiceTariff?.sideServiceTariffType
                    ]
                  }
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>
      </>
    );
  }
}

export default withUserAccess(Form);
