import {
    WashingFrequencyCard,
    WashingFrequencyForm,
    WashingFrequencyList,
} from '../../containers/WashingFrequency';
import { accessTypeEnum } from '../../lib/enum';

export default [
    {
        component: WashingFrequencyList,
        path: '/admin/washing-frequency',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WashingFrequencyCard,
        path: '/admin/washing-frequency/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WashingFrequencyForm,
        path: '/admin/washing-frequency/edit/:id',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
        component: WashingFrequencyForm,
        path: '/admin/washing-frequency/new',
        access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
];