// @flow
import Button from 'antd/lib/button';
import { FormikProps } from 'formik';
import moment from 'moment';
import React, { Component } from 'react';
import { DatePicker } from '../../../../components/ant/DatePicker';
import type { FormFieldType } from '../../../../components/Form';
import { InputNumber } from '../../../../components/inputs';
import { ownerTypes } from '../../../../lib/enum';
import type { Tire, Vehicle } from '../../../../lib/types';
import { convertDateToString } from '../../../Vehicles/lib';

import { Form, Selects } from './../../../../components';
import { Popconfirm } from './../../../../components/ui';

const { VehicleSelect } = Selects;
const getMomentValue = (value: ?moment) => (value ? moment.utc(value) : value);

export type AdditionalData = {
  installDate?: ?(string | moment),
  removeDate?: ?(string | moment),
  normKilometrage?: ?number,
  normWorkHours?: number,
};

type Props = {
  onConfirm: (
    vehicleId: number,
    additionalData: AdditionalData
  ) => Promise<void>,
  tire?: ?Tire,
  vehicleId?: ?number,
  vehicle?: Vehicle,
  text?: string,
  showVehicleSelector: boolean,
};

type State = {
  visible: boolean,
  values: {
    vehicleId?: number,
    installDate?: string,
    removeDate?: string,
  },
};

export default class AttachVehicleButton extends Component<Props, State> {
  initialState = {
    visible: false,
    values: {},
  };

  static defaultProps = {
    text: 'Добавить',
    showVehicleSelector: true,
  };

  state = this.initialState;

  componentDidMount() {
    const { vehicle } = this.props;
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.vehicleId !== this.props.vehicleId) {
      this.setState({
        values: {
          vehicleId: parseInt(this.props.vehicleId, 10) || undefined,
        },
      });
    }
  }

  handleSubmit = (values: any) => {
    const { vehicleId, ...additionalValues } = values;
    if (vehicleId || !this.props.showVehicleSelector) {
      this.props.onConfirm(vehicleId, additionalValues);
      this.setState(this.initialState);
    }
  };

  showPopover = () =>
    this.setState({
      visible: true,
    });

  hidePopover = () => this.setState(this.initialState);

  render() {
    const { visible, values } = this.state;
    const { text, showVehicleSelector, vehicleId } = this.props;
    return (
      <Form initialValues={values} onSubmit={this.handleSubmit}>
        {(FormField: FormFieldType, formikProps: FormikProps) => {
          const { setFieldValue, handleSubmit, handleBlur, values } =
            formikProps;
          return (
            <Popconfirm
              title={
                <>
                  {showVehicleSelector && (
                    <FormField name="vehicleId" required label="ТС закрепления">
                      {({ name, value }) => (
                        <VehicleSelect
                          value={value}
                          filter={{
                            ownerType: ownerTypes.self,
                          }}
                          dropdownStyle={{ zIndex: 1070 }}
                          onChange={(value, option) => {
                            setFieldValue(name, value);
                          }}
                        />
                      )}
                    </FormField>
                  )}
                  <FormField
                    label="Дата установки"
                    hasFeedback={false}
                    name="installDate"
                    required
                  >
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        allowClear={false}
                        value={getMomentValue(value)}
                        name={name}
                        onBlur={handleBlur}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                        // disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                  {!!vehicleId && (
                    <FormField
                      label="Дата снятия с текущего тс"
                      hasFeedback={false}
                      name="removeDate"
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          allowClear={false}
                          value={getMomentValue(value)}
                          name={name}
                          onBlur={handleBlur}
                          onChange={(value: Object, dateString: string) =>
                            setFieldValue(
                              name,
                              convertDateToString(value, dateString)
                            )
                          }
                        />
                      )}
                    </FormField>
                  )}
                  <FormField
                    label="Норматив километража без учета износа"
                    name="normKilometrage"
                    hasFeedback={false}
                    required={!values.normWorkHours}
                  >
                    {({ name, value }) => (
                      <InputNumber
                        value={value}
                        min={2}
                        onBlur={() => handleBlur({ target: { name } })}
                        decimalSeparator=","
                        step={0.01}
                        onChange={(normKilometrage: number) =>
                          setFieldValue(name, normKilometrage)
                        }
                      />
                    )}
                  </FormField>
                  <FormField
                    label="Норматив моточасов без учета износа"
                    name="normWorkHours"
                    hasFeedback={false}
                    required={!values.normKilometrage}
                  >
                    {({ name, value }) => (
                      <InputNumber
                        value={value}
                        min={2}
                        onBlur={() => handleBlur({ target: { name } })}
                        decimalSeparator=","
                        step={0.01}
                        onChange={(normWorkHours: number) =>
                          setFieldValue(name, normWorkHours)
                        }
                      />
                    )}
                  </FormField>
                  <p>
                    Вы подтверждаете закрепление оборудования за выбранным ТС?
                  </p>
                </>
              }
              visible={visible}
              okText="Да"
              placement="bottomLeft"
              cancelText="Нет"
              onConfirm={handleSubmit}
              onCancel={this.hidePopover}
              icon={null}
              overlayClassName="without-icon"
            >
              <Button onClick={this.showPopover} type="primary">
                {text}
              </Button>
            </Popconfirm>
          );
        }}
      </Form>
    );
  }
}
