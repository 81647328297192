// @flow
import React from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import moment from 'moment';

import { Section } from '../../../../components/layout';
import { Icon } from '../../../../components/ui';
import type { Vehicle } from '../../../../lib/types';
import { GridItem } from '../../../../components/layout/Grid';
import Grid from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import SectionTitle from '../SectionTitle';

type Props = {
  vehicle: Vehicle,
  editable: boolean
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

export default class KilometrageTab extends React.Component<Props> {
  onEdit = () =>
    navigate(`/vehicles/${this.props.vehicle.id}/edit/kilometrage`, {
      replace: true
    });

  render() {
    const { vehicle, editable } = this.props;
    return (
      <Section>
        <SectionTitle
          divider
          suffix={
            editable && (
              <StyledIcon onClick={this.onEdit} type="edit" size={16} />
            )
          }
        >
          Пробег
        </SectionTitle>
        <SectionContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Текущий пробег">{vehicle.kilometrage} км</Field>
            </GridItem>
            <GridItem>
              <Field label="Пробег ТС на 1 Января текущего года">
                {vehicle.beginningOfTheYearKilometrage} км
              </Field>
            </GridItem>
            {parseFloat(vehicle.averageDailyKilometrage) > 0 && (
              <GridItem>
                <Field label="Текущий пробег по ПЛ">
                  {vehicle.waybillKilometrage} км
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.averageDailyKilometrage) > 0 && (
              <GridItem>
                <Field
                  label={`Средний суточный пробег (01.01.${moment().year()} - ${moment().format(
                    'DD.MM.YYYY'
                  )})`}
                >
                  {vehicle.averageDailyKilometrage} км
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.averageKilometrage) > 0 && (
              <GridItem>
                <Field label="Среднегодовой пробег">
                  {vehicle.averageKilometrage} км
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.kilometrageFromLastOverhaul) > 0 && (
              <GridItem>
                <Field label="Пробег с последнего кап ремонта">
                  {vehicle.kilometrageFromLastOverhaul} км
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.outputRatio) > 0 && (
              <GridItem>
                <Field label="Коэффициент выхода">
                  {vehicle.outputRatio} %
                </Field>
              </GridItem>
            )}
            {vehicle.engineWorkHours > 0 && (
              <GridItem>
                <Field label="Показания счётчика моточасов">
                  {vehicle.engineWorkHours} ч.
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.averageEngineWorkHours) > 0 && (
              <GridItem>
                <Field label="Среднегодовая наработка моточасов">
                  {vehicle.averageEngineWorkHours} ч.
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.normEngineWorkHours) > 0 && (
              <GridItem>
                <Field label="Норма наработки моточасов в месяц">
                  {vehicle.normEngineWorkHours} ч.
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.averageDailyEngineWorkHours) > 0 && (
              <GridItem>
                <Field label="Среднесуточная наработка моточасов">
                  {parseFloat(vehicle.averageDailyEngineWorkHours).toFixed(3)}{' '}
                  ч.
                </Field>
              </GridItem>
            )}
            {parseFloat(vehicle.engineWorkHoursFromLastOverhaul) > 0 && (
              <GridItem>
                <Field label="Наработка моточасов с последнего кап. ремонта">
                  {vehicle.engineWorkHoursFromLastOverhaul} ч.
                </Field>
              </GridItem>
            )}
          </Grid>
        </SectionContent>
      </Section>
    );
  }
}
