import Table from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';

export const StyledBaseTable: Table = styled(Table)`
  .ant-table {
    font-size: 12px;
  }

  .ant-table-body {
    overflow-x: auto;
  }

  .ant-table-thead > tr > th {
    background: #f0f4f8;
    position: relative;
    padding: 10px 16px;
    border-bottom: none;
    border-top: 1px solid #c0ccd7;
    cursor: pointer;
    user-select: none;
  }

  .ant-table-tbody .ant-table-row {
    position: relative;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #3d4042;
    height: 40px;
  }

  .ant-table-tbody .ant-table-row:nth-child(even) {
    background-color: rgba(228, 235, 242, 0.3);
    font-size: 12px;
  }

  .ant-table-tbody .ant-table-row:nth-child(2n) {
    background: rgba(228, 235, 242, 0.3);
  }

  .ant-table-tbody
    .ant-table-row:nth-child(2n)
    :is(.ant-table-cell-fix-left, .ant-table-cell-fix-right) {
    // Нельзя добавлять прозрачность, будет просвечивать
    background: rgba(247, 249, 251);
  }

  //.ant-table-cell-fix-left-first::after, .ant-table-cell-fix-left-last::after {
  //  right: 8px;
  //}

  //.ant-table table {
  //  width: 100%;
  //}

  //.ant-table-fixed-left table {
  //  width: auto;
  //}

  //.ant-table-tbody > tr > td {
  //  border-bottom: none;
  //}

  //.ant-table-thead > tr > th,
  //.ant-table-tbody > tr > td {
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //  white-space: nowrap;
  //}

  .ant-table-thead > tr > th {
    padding: 6px 16px;
  }

  .ant-table-tbody > tr > td {
    padding: 11px 16px;
  }

  .ant-list-split .ant-list-header {
    border-bottom: none;
    padding-top: 0;
    border-top: 1px solid #c0ccd7;
  }

  .ant-list-header .ant-row {
    background-color: #f0f4f8;
    padding: 10px 16px;
  }

  //.ant-table-row-color-light-red {
  //  background-color: #f5222d14 !important;
  //}
  //
  //.ant-table-row-color-light-grey {
  //  background-color: #80808085 !important;
  //}

  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #e6f7ff;
  }

  .ant-table .ant-table-container::before,
  .ant-table .ant-table-container::after {
    z-index: 3;
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
`;
