// @flow
import React from 'react';

import Modal from 'antd/lib/modal';
import { FormikProps } from 'formik';

import type { FuelAndOilMechanism } from '../../../../../lib/types';
import { Form } from '../../../../../components';
import { Grid, GridItem } from '../../../../../components/layout';
import { StyledInputNumber } from '../../../../../components/hoc/common/components/elements';
import { FuelTypeCalculationSelect } from '../../../../../components/selects/AutoGeneratedEnumSelects';
import { MONTH } from '../../lib';

import type { ModalProps } from '../../components/Common';
import { Button } from 'antd';
import Uploader from '../../../../Vehicles/components/Uploader';
import orderBy from 'lodash/orderBy';
import { fileTagEnum } from '../../../../../lib/enum';
import SmallScaleMechanizationToolSelect from '../../../../../components/selects/SmallScaleMechanizationToolSelect';

export const ModalFuelAndOilMechanism = (
  props: ModalProps<FuelAndOilMechanism>
) => {
  const {
    visible,
    data,
    handleSave,
    handleCancel,
    isRangeMonths,
    readOnly
  } = props;
  return (
    <Modal
      destroyOnClose
      width={600}
      title="Средство малой механизации"
      visible={visible}
      onCancel={handleCancel}
      footer={
        readOnly
          ? null
          : [
              <Button key="back" onClick={handleCancel}>
                Отмена
              </Button>,
              <Button
                key="submit"
                type="primary"
                form="modal-form"
                htmlType="submit"
              >
                Сохранить
              </Button>
            ]
      }
    >
      <Form initialValues={data} onSubmit={handleSave}>
        {(FormField, formikProps: FormikProps) => {
          const { setFieldValue } = formikProps;
          return (
            <form onSubmit={formikProps.handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem fullWidth>
                  <FormField
                    label="Наименование средства малой механизации"
                    name="smallScaleMechanizationToolId"
                    required
                  >
                    {({ name, value }) => (
                      <SmallScaleMechanizationToolSelect
                        name={name}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        disabled={readOnly}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem fullWidth>
                  <FormField label="Марка топлива" name="type" required>
                    {({ name, value }) => (
                      <FuelTypeCalculationSelect
                        name={name}
                        value={value}
                        onSelect={value => setFieldValue(name, value)}
                        disabled={readOnly}
                      />
                    )}
                  </FormField>
                </GridItem>

                {MONTH.map((month, index) => {
                  return (
                    <GridItem>
                      <FormField
                        label={`${month.title}(л. в мес.)`}
                        name={`months[${index}].count`}
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            min={0}
                            value={value}
                            disabled={
                              (isRangeMonths && !isRangeMonths(index)) ||
                              readOnly
                            }
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  );
                })}
                <GridItem fullWidth>
                  <FormField label="Файл обоснования" required name="file">
                    {({ value, name }) => (
                      <Uploader
                        value={orderBy(value ? [value] : [], 'createdTime', [
                          'desc'
                        ])}
                        onChange={files => setFieldValue(name, files[0])}
                        tag={fileTagEnum.all}
                        listType="text"
                        allowEmptyList
                        readOnly={readOnly}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
