// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import isEqual from 'lodash/isEqual';
import InputNumber from 'antd/lib/input-number';
import notification from 'antd/lib/notification';
import Checkbox from 'antd/lib/checkbox';
import sumBy from 'lodash/sumBy';

import type { Employee, Trip, WayPoint } from './../../../lib/types';
import {
  Card,
  TripMap,
  WaypointsForm,
  WaypointsViewer,
} from '../../../components';
import {
  Section,
  SectionTitle,
  TripStatus,
} from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import {
  ownerTypes,
  tripStatusEnum,
  tripStatuses,
  vehicleGroups,
  vehicleTypes,
} from '../../../lib/enum';
import { directions } from './../../../lib/gis';
import {
  applyMaskToValue,
  calculateDateRangeString,
  convertEmployeeToString,
  convertFromMToKm,
  isVehicleTractor,
  validateWaypointsOrder,
  navigate,
} from './../../../lib/helpers';
import { Icon } from './../../../components/ui';
import { getMinMaxWaypointDateTime } from './../../Trips/lib';
import { employeeApi, fuelMultiplierApi } from './../../../lib/api';
import { getVehicleConstMultipliers } from './../../Vehicles/lib';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/inputs/masked-inputs/LicensePlateInput';
import Spoiler from '../../../components/ui/Spoiler';

const { Field } = Card;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-right: 16px;
`;
const CardOperations = styled.div`
  display: flex;
  & > * {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const WaypointsOperations = styled(CardOperations)`
  padding: 16px;
`;
const CardTitle = styled.p`
  display: inline-block;
  margin-right: 10px;
  cursor: ${(props) => (props.canPrint === 'true' ? 'pointer' : 'default')};
`;
const VerticalDivider = styled.div`
  min-height: 100%;
  width: 1px;
  background: #c0ccd7;
  margin: 0 30px 0 20px;
`;
const TripInfo = styled.div`
  background: #f0f4f8;
  padding: 16px;
`;

type TripCardProps = {
  trip: Trip,
  onPrint: (tripId: number) => Promise<void>,
  changeStatus: (tripId: number, status: TripStatus) => Promise<void>,
  updateTrip: (trip: Trip) => Promise<boolean>,
  canEdit: boolean,
  checkedToPrint: boolean,
  onSelectToPrint: (id: number) => void,
  canPrint: boolean,
};

type State = {
  trip: Trip,
  touched: boolean,
  medic?: Employee,
  loadingChangeStatus: boolean,
  expectedRouteGeometry: any,
};

export default class TripShortCard extends Component<TripCardProps, State> {
  state = {
    trip: {
      ...this.props.trip,
      odometerAtStart:
        this.props.trip.vehicle &&
        (this.props.trip.vehicle.waybillKilometrage ?? 0),
    },
    touched: false,
    loadingChangeStatus: false,
    expectedRouteGeometry: null,
  };

  componentDidUpdate(prevProps: TripCardProps) {
    if (!isEqual(prevProps.trip, this.props.trip)) {
      this.setState({
        trip: {
          ...this.props.trip,
          odometerAtStart:
            this.props.trip.vehicle &&
            (this.props.trip.vehicle.waybillKilometrage ?? 0),
        },
      });
    }
  }

  updateTrip = async () => {
    await this.props.updateTrip(this.state.trip);
    this.setState({
      touched: false,
    });
  };

  discardChanges = () =>
    this.setState({
      trip: this.props.trip,
      touched: false,
    });

  changeTripWaypoints = (waypoints: WayPoint[]) => {
    this.setState((prevState: State) => ({
      trip: {
        ...prevState.trip,
        expectedRoute: {
          ...prevState.trip.expectedRoute,
          waypoints,
        },
      },
      touched: true,
    }));
  };

  changeTripInfo = (fieldName: string, value: any) =>
    this.setState((prevState) => ({
      trip: {
        ...prevState.trip,
        [fieldName]: value,
      },
      touched: true,
    }));

  changeExpectedRouteInfo = (fieldName: string, value: any) =>
    this.setState((prevState) => ({
      trip: {
        ...prevState.trip,
        expectedRoute: {
          ...prevState.trip.expectedRoute,
          [fieldName]: value,
        },
      },
      touched: true,
    }));

  onBlurExpectedRouteInfo = async () => {
    const expectedFuelConsumption = await this.calculateFuelConsumption(
      this.state.trip.expectedRoute.distance
    );
    this.setState((prevState) => ({
      trip: {
        ...prevState.trip,
        expectedFuelConsumption,
      },
      touched: true,
    }));
  };

  getFuelMultipliers = async () => {
    const { trip } = this.state;

    let vehicleFuelMultipliers =
      await fuelMultiplierApi.fetchVehicleFuelMultipliers(trip.vehicleId);
    const constMultipliers = await getVehicleConstMultipliers(trip.vehicle);
    vehicleFuelMultipliers = [...vehicleFuelMultipliers, ...constMultipliers];

    return vehicleFuelMultipliers;
  };

  calculateFuelConsumption = async (distance: number) => {
    const { trip } = this.state;
    const vehicleFuelMultipliers = await this.getFuelMultipliers();
    const sumFuelMultipliers =
      1 + (sumBy(vehicleFuelMultipliers, 'value') || 0);

    // Ожидаемый расход = пробег * расход л/1км * сумму коэф. ТС
    const expectedFuelConsumption = parseFloat(
      (
        distance *
        (trip.vehicle.vehicleModel.primaryFuelConsumption / 100) *
        sumFuelMultipliers
      ).toFixed(2)
    );
    return expectedFuelConsumption;
  };

  // Подсчитываем расстояние маршрута + расход топлива по плану
  calculateRoute = async () => {
    try {
      notificationLoading({
        message: 'Построение маршрута...',
        key: 'buildingRoute',
      });
      const { trip } = this.state;
      const expectedWaypoints =
        (trip.expectedRoute && trip.expectedRoute.waypoints) || [];

      const expectedRouteGeometry = await directions(expectedWaypoints);

      if (expectedRouteGeometry && this.props.canEdit) {
        const distance = parseFloat(
          convertFromMToKm(expectedRouteGeometry.distance).toFixed(2)
        );
        // Ожидаемый расход = пробег * расход л/1км * сумму коэф. ТС
        const expectedFuelConsumption = await this.calculateFuelConsumption(
          distance
        );

        this.setState((prevState: State) => {
          const trip = prevState.trip;
          const distanceAtStart = parseFloat(
            convertFromMToKm(expectedRouteGeometry.distanceAtStart).toFixed(2)
          );
          const distanceAtEnd = parseFloat(
            convertFromMToKm(expectedRouteGeometry.distanceAtEnd).toFixed(2)
          );
          return {
            trip: {
              ...trip,
              distanceAtStart,
              distanceAtEnd,
              expectedRoute: {
                ...trip.expectedRoute,
                distance,
              },
              expectedFuelConsumption,
              odometerAtStart: trip.vehicle.waybillKilometrage ?? 0,
              odometerAtEnd: trip.odometerAtStart + distance,
            },
            touched: true,
          };
        }, this.updateTrip);
        notification.success({
          message: 'Маршрут успешно построен',
        });
      }
      this.setState({
        expectedRouteGeometry,
      });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при построении маршрута',
        description: err ? err.message : '',
      });
    } finally {
      notification.close('buildingRoute');
    }
  };

  renderCheckBox = () => {
    const { checkedToPrint, onSelectToPrint, trip, canPrint } = this.props;
    if (!trip) {
      return null;
    }
    if (canPrint) {
      return (
        <StyledCheckbox
          checked={checkedToPrint}
          onChange={(e) => onSelectToPrint(trip.id)}
        />
      );
    }
    return null;
  };

  renderHeaderTitle = function (trip: Trip, canPrint: boolean) {
    const { onSelectToPrint } = this.props;
    let title = `Рейс №${trip.id}`;
    if (
      parseInt(trip.idNumber, 10) > 0 &&
      trip.vehicle &&
      trip.vehicle.ownerType === ownerTypes.self
    ) {
      title = `Путевой лист №${trip.idNumber || ''}`;
    }
    return (
      <CardTitle
        canPrint={canPrint.toString()}
        onClick={canPrint ? () => onSelectToPrint(trip.id) : null}
      >
        {title} по {trip.ordersId.length > 1 ? 'заявкам' : 'заявке'} №
        {trip.ordersId.join(', ')}
      </CardTitle>
    );
  };

  async componentDidMount() {
    const { trip } = this.state;
    const medicId = parseInt(trip.startMedicId);
    if (medicId > 0) {
      const medic = await employeeApi.fetchEmployee(medicId);
      this.setState({ medic });
    }
  }

  render() {
    const { onPrint, changeStatus, canEdit, canPrint }: TripCardProps =
      this.props;
    const {
      trip,
      touched,
      medic,
      expectedRouteGeometry,
      loadingChangeStatus,
    }: State = this.state;
    const isSelfVehicle =
      trip.vehicle && trip.vehicle.ownerType === ownerTypes.self;
    const expectedWaypoints =
      (trip.expectedRoute && trip.expectedRoute.waypoints) || [];
    const routeMinMaxDates = getMinMaxWaypointDateTime(expectedWaypoints);
    return (
      <Section>
        <SectionTitle
          suffix={
            <CardOperations>
              {canEdit && (
                <>
                  <Button type="primary" onClick={this.calculateRoute}>
                    Построить маршрут
                  </Button>
                  <VerticalDivider />
                </>
              )}
              {canPrint && (
                <Button
                  className="printButton"
                  onClick={() => onPrint(trip.id)}
                >
                  Печать
                </Button>
              )}
              {canEdit && trip.status === tripStatusEnum.created && (
                <Button
                  className="startButton"
                  loading={loadingChangeStatus}
                  disabled={loadingChangeStatus}
                  onClick={async () => {
                    this.setState({ loadingChangeStatus: true });
                    await this.props.updateTrip(this.state.trip);
                    await changeStatus(trip.id, tripStatusEnum.opened);
                    this.setState({ loadingChangeStatus: false });
                  }}
                  type="primary"
                >
                  Запустить в работу
                </Button>
              )}
              {canEdit &&
                isSelfVehicle &&
                (trip.status === tripStatusEnum.opened ||
                  trip.status === tripStatusEnum.verification) && (
                  <Button
                    className="verifyBtn"
                    onClick={() =>
                      navigate(`/trips/self/${trip.id}/verification`)
                    }
                  >
                    Таксировка
                  </Button>
                )}
              {canEdit &&
                isSelfVehicle &&
                trip.status === tripStatusEnum.draft && (
                  <Button
                    onClick={async () => {
                      if (
                        !validateWaypointsOrder(trip.expectedRoute.waypoints)
                      ) {
                        (await this.props.updateTrip(this.state.trip)) &&
                          (await changeStatus(trip.id, tripStatusEnum.created));
                      } else {
                        notification.error({
                          message: 'Произошла ошибка при обновлении',
                          description:
                            'Время прибытия в каждой точке должно быть больше времени отправления из предыдущей точки',
                        });
                      }
                    }}
                    type="primary"
                    className="createButton"
                  >
                    Создать
                  </Button>
                )}
              {canEdit && (
                <>
                  <Button
                    className="cancelButton"
                    onClick={() =>
                      changeStatus(trip.id, tripStatusEnum.canceled)
                    }
                    type="plain"
                  >
                    Отменить
                  </Button>
                </>
              )}
            </CardOperations>
          }
        >
          {this.renderCheckBox()}
          {this.renderHeaderTitle(trip, canPrint)}
          {canEdit && (
            <StyledIcon
              className="editButton"
              onClick={() => navigate(`/trips/self/${trip.id}/edit`)}
              type="edit"
              size={16}
            />
          )}
        </SectionTitle>
        <TripInfo>
          <Grid cols={9} gutter="16px" media={[{ size: 'lg', cols: 9 }]}>
            {trip.vehicle.ownerType === ownerTypes.self && (
              <GridItem>
                <Field label="Статус">{tripStatuses[trip.status]}</Field>
              </GridItem>
            )}
            {trip.vehicle && (
              <>
                {trip.vehicle.licensePlate && (
                  <GridItem>
                    <Field label="Гос. номер">
                      {applyMaskToValue(
                        trip.vehicle.licensePlate,
                        formatLicensePlateMask
                      )}
                    </Field>
                  </GridItem>
                )}
              </>
            )}
            {trip.orgUnitName && (
              <GridItem>
                <Field label="Служба">{trip.orgUnitName}</Field>
              </GridItem>
            )}
            {trip.vehicleType || trip.vehicleGroup ? (
              <GridItem>
                <Field label="Тип ТС">
                  {vehicleTypes[trip.vehicleType] ||
                    vehicleGroups[trip.vehicleGroup]}
                </Field>
              </GridItem>
            ) : null}
            {trip.trailer && (
              <GridItem>
                <Field label="Гос. номер прицепа">
                  {applyMaskToValue(
                    trip.trailer.licensePlate,
                    formatLicensePlateMask
                  )}
                </Field>
              </GridItem>
            )}
            {trip.driver && trip.driver.employee && (
              <GridItem>
                <Field label="Водитель">
                  {convertEmployeeToString(trip.driver.employee)}
                </Field>
              </GridItem>
            )}
            {(medic || trip.startMedic) && (
              <GridItem>
                <Field label="Мед. работник">
                  {convertEmployeeToString(medic || trip.startMedic)}
                </Field>
              </GridItem>
            )}
            {trip.startMedicFullName && (
              <GridItem>
                <Field label="Мед. работник">{trip.startMedicFullName}</Field>
              </GridItem>
            )}
            {parseInt(trip.startMechanicId) > 0 && (
              <GridItem>
                <Field label="Контролёр ТО">
                  {convertEmployeeToString(trip.startMechanic)}
                </Field>
              </GridItem>
            )}
            {parseInt(trip.startGpmControllerId) > 0 && (
              <GridItem>
                <Field label="Контролёр ГПМ">
                  {convertEmployeeToString(trip.startGpmController)}
                </Field>
              </GridItem>
            )}
          </Grid>
        </TripInfo>
        {isSelfVehicle && (
          <SectionContent style={{ paddingBottom: '0px' }}>
            <Grid gutter="16px" cols={5} media={[{ size: 'lg', cols: 6 }]}>
              <GridItem>
                <Field label="Нулевой пробег от гаража до объекта, км">
                  {canEdit ? (
                    <InputNumber
                      name="distanceAtStart"
                      min={0}
                      decimalSeparator=","
                      step={0.01}
                      value={trip.distanceAtStart}
                      onChange={(value: number) =>
                        this.changeTripInfo('distanceAtStart', value)
                      }
                    />
                  ) : (
                    trip.distanceAtEnd
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Нулевой пробег от объекта до гаража, км">
                  {canEdit ? (
                    <InputNumber
                      name="distanceAtEnd"
                      min={0}
                      decimalSeparator=","
                      step={0.01}
                      value={trip.distanceAtEnd}
                      onChange={(value: number) =>
                        this.changeTripInfo('distanceAtEnd', value)
                      }
                    />
                  ) : (
                    trip.distanceAtEnd
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Предполагаемый пробег, км">
                  {canEdit ? (
                    <InputNumber
                      name="distance"
                      min={0}
                      decimalSeparator=","
                      step={0.01}
                      onBlur={this.onBlurExpectedRouteInfo}
                      value={trip && trip.expectedRoute.distance}
                      onChange={(value: number) =>
                        this.changeExpectedRouteInfo('distance', value)
                      }
                    />
                  ) : (
                    trip.expectedRoute.distance
                  )}
                </Field>
              </GridItem>
              <GridItem>
                {isVehicleTractor(trip.vehicle) ? (
                  <Field label="Mоточасы при выезде, ч">
                    <InputNumber
                      name="engineWorkHours"
                      min={0}
                      decimalSeparator=","
                      step={0.01}
                      disabled
                      value={trip.vehicle.engineWorkHours}
                    />
                  </Field>
                ) : (
                  <Field label="Пробег при выезде, км">
                    <InputNumber
                      min={0}
                      decimalSeparator=","
                      step={0.01}
                      disabled
                      value={trip.odometerAtStart}
                      onChange={(value: number) =>
                        this.changeTripInfo('odometerAtStart', value)
                      }
                    />
                  </Field>
                )}
              </GridItem>
              <GridItem>
                <Field label="Расход топлива по нормативу, л">
                  {canEdit ? (
                    <InputNumber
                      name="expectedFuelConsumption"
                      min={0}
                      decimalSeparator=","
                      step={0.1}
                      value={trip.expectedFuelConsumption}
                      disabled
                      onChange={(value: number) =>
                        this.changeTripInfo('expectedFuelConsumption', value)
                      }
                    />
                  ) : (
                    trip.expectedFuelConsumption
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Суммарное время движения">
                  <div style={{ marginBottom: '35px' }}>
                    {calculateDateRangeString(
                      routeMinMaxDates.min,
                      routeMinMaxDates.max
                    )}
                  </div>
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        )}
        <Spoiler showDivider={false} label="Точки маршрута">
          {canEdit ? (
            <WaypointsForm
              disableDates={false}
              waypoints={trip.expectedRoute.waypoints}
              onChange={(waypoints: WayPoint[]) =>
                this.changeTripWaypoints(waypoints)
              }
              appendRows={[
                canEdit && expectedRouteGeometry && (
                  <TripMap
                    geoJSON={expectedRouteGeometry.geoJSON}
                    waypoints={trip.expectedRoute.waypoints}
                  />
                ),
              ]}
            />
          ) : (
            <WaypointsViewer waypoints={trip.expectedRoute.waypoints} />
          )}
        </Spoiler>
        {touched && (
          <WaypointsOperations>
            <Button type="primary" onClick={this.updateTrip}>
              Применить изменения
            </Button>
            <Button type="plain" onClick={this.discardChanges}>
              Отменить
            </Button>
          </WaypointsOperations>
        )}
      </Section>
    );
  }
}
