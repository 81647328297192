// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Footer, Section } from '../../../../../components/layout';
import { notificationLoading } from '../../../../../components/Notifications';

import { AntTable, ButtonsRow } from '../../../../../components/ui';
import { vehiclePlanApi } from '../../../../../lib/api';
import { calculationStatusEnum } from '../../../../../lib/enum';

import type { PropsHeader } from '../../lib';
import { COLUMNS_MONTH, commonHeaderPanel, itogCalculation } from '../../lib';

import Filter from './../../components/FilterVehicleList';
import PassTab from './PassTab';

import BudgetTab from './Tab';

type PropsHeaderCustom = PropsHeader & {
  passTab?: boolean,
};

export const headerPanel = (props: PropsHeaderCustom) => {
  const { title, vehiclePlanId } = props;
  return (
    <>
      {commonHeaderPanel({ title, vehiclePlanId })}
      <BudgetTab vehiclePlanId={props.vehiclePlanId} />
      {!!props.passTab && <PassTab vehiclePlanId={props.vehiclePlanId} />}
    </>
  );
};

type FooterProps = {
  calculate: Function,
  changeStatus: Function,
  handleFetch: Function,
  vehiclePlanId: number,
  handlePrint?: (filter: any) => any,
  calculationDone: boolean,
};
const CommonFooter = ({
  calculate,
  changeStatus,
  handleFetch,
  vehiclePlanId,
  handlePrint,
  calculationDone,
}: FooterProps) => {
  const calculateAction = async () => {
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'saving',
      });
      await calculate(vehiclePlanId);
      handleFetch();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось выполнить расчет',
      });
    } finally {
      notification.close('saving');
    }
  };

  const changeStatusAction = async () => {
    try {
      notificationLoading({
        message: 'Утверждение...',
        key: 'approving',
      });
      await changeStatus(vehiclePlanId, calculationStatusEnum.calculationDone);
      handleFetch();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    } finally {
      notification.close('approving');
    }
  };

  return !calculationDone || !!handlePrint ? (
    <Footer>
      <ButtonsRow>
        {!calculationDone && (
          <>
            <Button type="primary" onClick={calculateAction}>
              Рассчитать
            </Button>
            <Button type="primary" onClick={changeStatusAction}>
              Утвердить
            </Button>
          </>
        )}
        {handlePrint && <Button onClick={handlePrint}>Печать</Button>}
      </ButtonsRow>
    </Footer>
  ) : null;
};

type Props = PropsHeaderCustom & {
  location: Location & { state: { page: number } },
  crud: any,
  columns?: any,
  tableProps?: any,
  scrollX?: number,
  calcScrollY?: number,
  statusField: string,
  handlePrint?: (filter: any) => any,
  itogCalculation?: (data: any) => any,
};
export default (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState({});
  const [vehiclePlan, setVehiclePlan] = useState(null);

  const handleFetch = useCallback(async () => {
    setLoading(true);
    try {
      let { data } = await props.crud.fetch({
        vehiclePlanId: props.vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      const vehiclePlan = await vehiclePlanApi.get(props.vehiclePlanId);
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      setData(data);
      setVehiclePlan(vehiclePlan);
    } finally {
      setLoading(false);
    }
  }, [props.crud, props.vehiclePlanId]);

  const applyFilter = (filter) => setFilter(filter);

  const cleanFilter = () => setFilter({});

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    const filtered = data
      .filter((selfVehicle) =>
        !!filter.vehicleId && selfVehicle.selfVehiclePlanVehicle
          ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle.vehicleId
          : true
      )
      .filter((selfVehicle) =>
        !!filter.vehicleModelId && selfVehicle.selfVehiclePlanVehicle
          ? filter.vehicleModelId ===
            selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModelId
          : true
      )
      .filter((selfVehicle) =>
        !!filter.type && selfVehicle.selfVehiclePlanVehicle
          ? filter.type ===
            selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModel.type
          : true
      )
      .filter((selfVehicle) =>
        !!filter.yearIssued && selfVehicle.selfVehiclePlanVehicle
          ? filter.yearIssued ===
            selfVehicle.selfVehiclePlanVehicle.vehicle.yearIssued
          : true
      )
      .filter((selfVehicle) => {
        return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
      });
    if (filtered.length !== 0)
      setFilteredData([
        ...filtered,
        !!props.itogCalculation
          ? props.itogCalculation(filtered)
          : itogCalculation(filtered),
      ]);
    else setFilteredData([]);
  }, [data, filter, props]);

  return (
    <>
      {headerPanel(props)}
      <Section>
        <div style={{ padding: '16px 16px 0px' }}>
          <Filter
            vehicleType={true}
            filter={filter}
            applyFilter={applyFilter}
            cleanFilter={cleanFilter}
          />
        </div>
        <AntTable
          data={filteredData}
          columns={props.columns ? props.columns : COLUMNS_MONTH}
          loading={loading}
          pagination={false}
          scroll={{
            x: props.scrollX ? `${props.scrollX}px` : '2500px',
            y: `calc(100vh - ${
              props.calcScrollY ? props.calcScrollY : '300'
            }px)`,
          }}
          {...props.tableProps}
        />
      </Section>
      {vehiclePlan && (
        <CommonFooter
          calculationDone={
            vehiclePlan[props.statusField] ===
            calculationStatusEnum.calculationDone
          }
          calculate={props.crud.calculate}
          changeStatus={props.crud.changeStatus}
          vehiclePlanId={props.vehiclePlanId}
          handlePrint={
            filteredData.length > 0 && props.handlePrint
              ? // $FlowFixMe не видит, что проверка на существование функции сделана
                () => props.handlePrint(filter)
              : undefined
          }
          handleFetch={handleFetch}
        />
      )}
    </>
  );
};
