// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number
};

export default ({ vehiclePlanId }: Props) => (
  <Tabs withRouter>
    <TabItem
      label="ГСМ"
      url={`/budget/vehicle/${vehiclePlanId}/fuels-and-oils`}
    />
    <TabItem
      label="Средства малой механизации"
      url={`/budget/vehicle/${vehiclePlanId}/mechanisms`}
    />
    <TabItem label="Автошины" url={`/budget/vehicle/${vehiclePlanId}/tire`} />
    <TabItem
      label="Техобслуживание"
      url={`/budget/vehicle/${vehiclePlanId}/maintenance`}
    />
  </Tabs>
);
