// @flow

import Button from 'antd/lib/button';

import notification from 'antd/lib/notification';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { notificationLoading } from '../../../../../components/Notifications';
import { calculationStatusEnum } from '../../../../../lib/enum';
import { plus, toLocalStringRu } from '../../../../../lib/helpers';

import type { OverhaulCalculation, OverhaulCalculationMonth, UserAccess, VehiclePlan } from '../../../../../lib/types';

import { withUserAccess } from '../../../../withUserAccess';
import { canEditOverhaulRepair } from '../../accessRight';

import type { VehicleListFilterParams } from '../../components/FilterVehicleList';

import {
  COLUMNS,
  Container,
  DecomissioningDateWarn,
  filteredData,
  LicensePlate,
  MONTH,
  OverhaulRepairCostWarn,
} from '../../lib';

import { headerPanel } from '../components/Common';
import { Section } from './../../../../../components/layout';

import { AntTable } from './../../../../../components/ui';
import { overhaulCalculationApi, vehiclePlanApi } from './../../../../../lib/api';
import Filter from './../../components/FilterVehicleList';

import Modal from './components/Modal';

const StyledSpan = styled.span`
  color: #1890ff;
`;

type Props = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};
type State = {
  visible: boolean,
  readonly: boolean,
  vehiclePlan: ?VehiclePlan,
  loading: boolean,
  monthIndex: ?number,
  rowIndex: ?number,
  data: OverhaulCalculation[],
  filter: VehicleListFilterParams,
  currentOverhaulCalculationMonth: ?OverhaulCalculationMonth
};
export default withUserAccess(
  class extends React.Component<Props, State> {
    state = {
      visible: false,
      data: [],
      vehiclePlan: null,
      loading: false,
      monthIndex: null,
      rowIndex: null,
      filter: {},
      currentOverhaulCalculationMonth: null,
      readonly: true
    };

    isRangeMonths = (monthIndex: number) => {
      const { vehiclePlan } = this.state;
      if (vehiclePlan?.startDate) {
        const start = moment(vehiclePlan.startDate).get('month');
        const end = moment(vehiclePlan.endDate).get('month');
        return start <= monthIndex && end >= monthIndex;
      }
      return false;
    };

    // дата после списания
    afterPlannedWriteoffDate = (
      monthIndex: number,
      plannedWriteoffDate?: ?string
    ) => {
      const { vehiclePlan } = this.state;
      if (vehiclePlan && plannedWriteoffDate) {
        const year = moment(vehiclePlan.date).get('year');
        return moment(`${year}-${monthIndex + 1}-01`).isAfter(
          plannedWriteoffDate,
          'month'
        );
      }
      return false;
    };

    onCell = (monthIndex: number) => (
      overhaulCalculation: OverhaulCalculation,
      rowIndex: number
    ) => {
      return {
        onClick:
          !this.isDraft() ||
          this.afterPlannedWriteoffDate(
            monthIndex,
            overhaulCalculation?.selfVehiclePlanVehicle?.vehicle
              ?.plannedWriteoffDate ||
              overhaulCalculation?.selfVehiclePlanVehicle?.plannedWriteoffDate
          ) ||
          !canEditOverhaulRepair(this.props.userAccess)
            ? () => {
                !!overhaulCalculation?.id &&
                  this.setState({
                    visible: true,
                    monthIndex,
                    rowIndex,
                    currentOverhaulCalculationMonth:
                      overhaulCalculation?.months[monthIndex]
                  });
              }
            : () => {
                !!overhaulCalculation?.id &&
                  this.isRangeMonths(monthIndex) &&
                  this.setState({
                    visible: true,
                    monthIndex,
                    rowIndex,
                    currentOverhaulCalculationMonth:
                      overhaulCalculation.months[monthIndex]
                  });
              }
      };
    };

    columns = [
      ...COLUMNS.map(column => {
        return column.key === 'licensePlate'
          ? {
              ...column,
              render: (licensePlate: string, record?: any) => {
                return (
                  <Container>
                    <LicensePlate>{licensePlate}</LicensePlate>
                    {record?.selfVehiclePlanVehicle?.plannedWriteoffDate && (
                      <DecomissioningDateWarn
                        date={
                          record?.selfVehiclePlanVehicle.plannedWriteoffDate
                        }
                      />
                    )}
                    {licensePlate &&
                      this.isDraft() &&
                      record?.hasNoMaterialsCost && <OverhaulRepairCostWarn />}
                  </Container>
                );
              }
            }
          : column;
      }),
      ...MONTH.map((month, monthIndex) => ({
        title: month.title,
        children: [
          {
            title: 'Вид работ',
            width: 150,
            onCell: this.onCell(monthIndex),
            render: (cost: ?number, overhaul: OverhaulCalculation) =>
              this.isDraft() &&
              this.isRangeMonths(monthIndex) &&
              !this.afterPlannedWriteoffDate(
                monthIndex,
                overhaul?.selfVehiclePlanVehicle?.vehicle.plannedWriteoffDate ||
                  overhaul?.selfVehiclePlanVehicle?.plannedWriteoffDate
              ) &&
              canEditOverhaulRepair(this.props.userAccess) ? (
                <StyledSpan>
                  {overhaul.months[monthIndex].operations?.length
                    ? 'Редактировать'
                    : 'Добавить'}
                </StyledSpan>
              ) : (
                '-'
              )
          },
          {
            title: 'Стоимость',
            width: 150,
            dataIndex: `months[${monthIndex}].cost`,
            render: (cost: number) => toLocalStringRu(cost)
          }
        ]
      })),
      {
        title: 'Итого стоимость',
        children: [
          {
            title: 'Материалы',
            children: [
              {
                title: 'Хоз. способ',
                width: 150,
                dataIndex: 'internalMaterialsSum',
                render: (internalMaterialsSum: number) =>
                  toLocalStringRu(internalMaterialsSum)
              },
              {
                title: 'Подряд',
                width: 150,
                dataIndex: 'contractMaterialsSum',
                render: (contractMaterialsSum: number) =>
                  toLocalStringRu(contractMaterialsSum)
              }
            ]
          },
          {
            title: 'Работы',
            children: [
              {
                title: 'Хоз. способ',
                width: 150,
                dataIndex: 'internalOperationsSum',
                render: (internalOperationsSum: number) =>
                  toLocalStringRu(internalOperationsSum)
              },
              {
                title: 'Подряд',
                width: 150,
                dataIndex: 'contractOperationsSum',
                render: (contractOperationsSum: number) =>
                  toLocalStringRu(contractOperationsSum)
              }
            ]
          }
        ]
      },
      {
        title: 'Итого',
        width: 150,
        dataIndex: 'sum',
        render: (sum: number) => toLocalStringRu(sum)
      }
    ];

    handleMonthSubmit = (month: OverhaulCalculationMonth) => {
      const { rowIndex, monthIndex } = this.state;
      let { data } = this.state;
      if (data) {
        // $FlowFixMe
        data[rowIndex].months[monthIndex] = month;
        this.setState({ data });
      }
      this.handleMonthCancel();
    };

    handleMonthCancel = () => {
      this.setState({
        visible: false,
        currentOverhaulCalculationMonth: null,
        monthIndex: null,
        rowIndex: null
      });
    };

    componentDidMount() {
      this.getData();
    }

    getData = async () => {
      this.setState({ loading: true });
      try {
        await this.getVehiclePlan();
        await this.fetch();
      } finally {
        this.setState({ loading: false, readonly: !this.isDraft() });
      }
    };

    getVehiclePlan = async () => {
      const { vehiclePlanId } = this.props;
      try {
        const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
        this.setState({ vehiclePlan });
      } catch (error) {
        notification.warning({ message: 'Не удалось запросить данные' });
      }
    };

    fetch = async () => {
      const { vehiclePlanId } = this.props;

      const itogCalculation = (data: any) => {
        const itog = {
          months: Array(12)
            .fill()
            .map(() => ({ cost: 0 })),
          sum: 0
        };
        data.forEach((item: any) => {
          itog.sum = plus(item.sum || 0, itog.sum);
          item.months.forEach((month: any, index) => {
            itog.months[index].cost = plus(
              itog.months[index].cost,
              month.cost || 0
            );
          });
        });
        return itog;
      };

      try {
        let { data } = await overhaulCalculationApi.fetch({
          vehiclePlanId,
          pageSize: undefined,
          page: undefined
        });

        if (data.length) {
          data = [...data, itogCalculation(data)];
        }

        this.setState({ data });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    };

    isDraft = () =>
      this.state.vehiclePlan?.overhaulCalculationStatus ===
      calculationStatusEnum.draft;

    changeStatus = async () => {
      const { vehiclePlanId } = this.props;
      try {
        notificationLoading({
          message: 'Сохранение данных',
          key: 'saving'
        });
        await overhaulCalculationApi.updateCalculations(vehiclePlanId);
        await overhaulCalculationApi.changeStatus(
          vehiclePlanId,
          calculationStatusEnum.calculationDone
        );
        this.getData();
      } catch (error) {
        notification.error({ message: 'Не удалось обновить данные данные' });
      } finally {
        notification.close('saving');
      }
    };

    calculate = async () => {
      const { vehiclePlanId } = this.props;
      const { data } = this.state;
      try {
        notificationLoading({
          message: 'Сохранение данных',
          key: 'saving'
        });
        if (data.length === 0)
          await overhaulCalculationApi.calculate(vehiclePlanId);
        else await overhaulCalculationApi.updateCalculations(vehiclePlanId);
        this.getData();
      } catch (error) {
        notification.error({ message: 'Не удалось обновить данные данные' });
      } finally {
        notification.close('saving');
      }
    };

    applyFilter = (filter: VehicleListFilterParams) =>
      this.setState({ filter });

    cleanFilter = () => this.setState({ filter: {} });

    render() {
      const { vehiclePlanId } = this.props;
      const {
        currentOverhaulCalculationMonth,
        visible,
        loading,
        filter,
        data
      } = this.state;
      const filtered = filteredData(data, filter);
      return (
        <>
          {headerPanel({ title: 'Капитальный ремонт', vehiclePlanId })}
          <Section>
            <div style={{ padding: '16px 16px 0px' }}>
              <Filter
                vehicleType={true}
                filter={filter}
                applyFilter={this.applyFilter}
                cleanFilter={this.cleanFilter}
              />
            </div>
            <AntTable
              data={filtered}
              bordered
              pagination={false}
              loading={loading}
              columns={
                !this.state.readonly
                  ? this.columns
                  : [
                      ...COLUMNS.map(column =>
                        column.key === 'licensePlate'
                          ? {
                              ...column,
                              render: (licensePlate: string, record?: any) => {
                                return (
                                  <Container>
                                    <LicensePlate>{licensePlate}</LicensePlate>
                                    {record?.selfVehiclePlanVehicle
                                      ?.plannedWriteoffDate && (
                                      <DecomissioningDateWarn
                                        date={
                                          record?.selfVehiclePlanVehicle
                                            .plannedWriteoffDate
                                        }
                                      />
                                    )}
                                    {licensePlate &&
                                      this.isDraft() &&
                                      record?.hasNoMaterialsCost && (
                                        <OverhaulRepairCostWarn />
                                      )}
                                  </Container>
                                );
                              }
                            }
                          : column
                      ),
                      ...MONTH.map((month, monthIndex) => ({
                        title: month.title,
                        onCell: this.onCell(monthIndex),
                        dataIndex: `months[${monthIndex}].cost`,
                        width: 150
                      })),
                      {
                        title: 'Итого',
                        width: 150,
                        dataIndex: 'sum',
                        render: (sum: number) => toLocalStringRu(sum)
                      }
                    ]
              }
              scroll={{
                y: 'calc(100vh - 350px)',
                x: '2800px'
              }}
            />
            {visible && (
              <Modal
                visible={visible}
                handleSave={this.handleMonthSubmit}
                handleCancel={this.handleMonthCancel}
                overhaulCalculationMonth={currentOverhaulCalculationMonth}
                readonly={this.state.readonly}
              />
            )}
          </Section>
          {this.isDraft() && (
            <Section style={{ padding: '16px' }}>
              <Button type="primary" onClick={this.calculate}>
                Рассчитать
              </Button>
              <Button
                style={{ marginLeft: '16px' }}
                type="primary"
                onClick={this.changeStatus}
              >
                Утвердить
              </Button>
            </Section>
          )}
        </>
      );
    }
  }
);
