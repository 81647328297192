// @flow

import React, { Component } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import qs from 'query-string';

import { washingPriceListApi } from './../../lib/api';
import type { WashingPriceList } from './../../lib/types';
import { goBack } from './../../lib/helpers';

import { Panel } from './../../components/layout';
import Header from './../../components/layout/Header';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import { notificationLoading } from './../../components/Notifications';

import InnerForm from './components/InnerForm';
import { connect } from 'react-redux';
import type { AppState } from '../../ducks/redux';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  washingPriceListId: ?string,
  copyWashingPriceListId: ?string,
  employeeBranchOrgUnitId: number
};

type State = {
  washingPriceList: ?$Shape<WashingPriceList>
};

class WashingPriceListForm extends Component<Props, State> {
  state = {
    washingPriceList: null
  };

  async componentDidMount() {
    const { employeeBranchOrgUnitId } = this.props;
    let washingPriceListId = parseInt(this.props.washingPriceListId, 10);
    const copyWashingPriceListId = parseInt(
      this.props.copyWashingPriceListId,
      10
    );
    try {
      if (washingPriceListId) {
        let wash = await washingPriceListApi.fetchWashingPrice(
          washingPriceListId
        );
        const washingPrices = [...wash.washingPrices];
        washingPrices.sort((a, b) => {
          return a.washingVehicleType.localeCompare(b.washingVehicleType);
        });
        this.setState({
          washingPriceList: {
            ...wash,
            washingPrices
          }
        });
      } else if (copyWashingPriceListId) {
        await this.copyWashingPriceList(copyWashingPriceListId);
      } else {
        this.setState({
          washingPriceList: {
            orgUnitId: employeeBranchOrgUnitId,
            washingPrices: []
          }
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  hasDuplicates = (values: WashingPriceList): boolean => {
    return (
      values.washingPrices.length !==
      uniqBy(values.washingPrices, 'washingVehicleTypeId').length
    );
  };

  onSubmit = async (values: WashingPriceList) => {
    if (this.hasDuplicates(values)) {
      notification.warning({
        message: 'Проверьте список ТС. Найдены дублирующиеся ТС'
      });
      return;
    }
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      let { washingPrices } = values;
      washingPrices = washingPrices.map(w => ({
        ...w,
        orgUnitId: w.orgUnitId || this.props.employeeBranchOrgUnitId
      }));
      if (this.props.washingPriceListId) {
        await washingPriceListApi.updateWashingPrice({
          ...values,
          washingPrices
        });
      } else {
        await washingPriceListApi.addWashingPrice({
          ...values,
          washingPrices
        });
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/washing-price-list');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  copyWashingPriceList = async (id: number) => {
    const { employeeBranchOrgUnitId } = this.props;
    const { startDate, endDate } = qs.parse(window.location.search);
    const washingPriceList = omit(
      await washingPriceListApi.fetchWashingPrice(id),
      ['contractorId', 'contractNumber', 'id', 'orgUnitId']
    );
    const washingPrices = [...washingPriceList.washingPrices];
    washingPrices.sort((a, b) => {
      return a.washingVehicleType.localeCompare(b.washingVehicleType);
    });
    this.setState({
      washingPriceList: {
        ...washingPriceList,
        orgUnitId: employeeBranchOrgUnitId,
        startDate,
        endDate,
        washingPrices: washingPrices.map(washingVehicleType => ({
          ...omit(washingVehicleType, ['id']),
          orgUnitId: employeeBranchOrgUnitId,
          bodyPrice: 0,
          interiorPrice: 0,
          enginePrice: 0
        }))
      }
    });
  };

  onCancel = () => goBack('/admin/washing-price-list');

  render() {
    const { washingPriceList } = this.state;
    const { washingPriceListId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/">Главная</Crumb>
              <Crumb to="/admin/washing-price-list">Прейскурант на мойки</Crumb>
              {washingPriceListId ? (
                <Crumb
                  to={`/admin/washing-price-list/edit/${washingPriceListId}`}
                >
                  Прейскурант на мойки №{washingPriceListId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/washing-price-list/new`}>
                  Новый прейскурант
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {washingPriceListId
              ? `Прейскурант №${washingPriceListId}`
              : `Новый прейскурант`}
          </h1>
        </StyledPanel>

        <InnerForm
          washingPriceList={washingPriceList}
          employeeOrgUnitId={this.props.employeeBranchOrgUnitId}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(WashingPriceListForm);
