// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';

import MaskInput from '../MaskInput';
import type { VehicleType } from '../../../lib/types';
import { cyrLetter } from '../../../lib/validation';
import { vehicleTypesPlainTree } from '../../../lib/vehicleTypesTree';

type Props = InputProps & {
  /**
   * Тип ТС
   *
   * Необходим для отображения соответствующей маски
   */
  vehicleType: VehicleType,
};

const getMask = (type: VehicleType) => {
  if (vehicleTypesPlainTree.carGroup.includes(type)) return 'a 999 aa 999';
  else return '** **** 999';
};

export const formatLicensePlateMask = (value: string) => {
  const startWithNumber = new RegExp('^\\d'),
    startWithLetter = new RegExp(`^[${cyrLetter}]`),
    oneLetterThreeDigitLetterRegexp = new RegExp(
      `^[${cyrLetter}]\\d{3}[${cyrLetter}]{1,}`
    ),
    oneLetterFourDigitRegexp = new RegExp(`^[${cyrLetter}]\\d{4}`),
    twoLetterRegexp = new RegExp(`^[${cyrLetter}]{2}`),
    fourNumber = new RegExp('\\d{4}');
  if (startWithNumber.test(value)) {
    if (fourNumber.test(value)) return '9999 aa 999';
    return '**** ** ***';
  } else if (startWithLetter.test(value)) {
    if (oneLetterFourDigitRegexp.test(value)) return 'a 9999 999';
    else if (oneLetterThreeDigitLetterRegexp.test(value)) return 'a 999 aa 999';
    else if (twoLetterRegexp.test(value)) return 'aa 9999 999';
    return '** **** ***';
  }
  return '* *** ** ***';
};

/**
 * Маска гос. номера
 */
export default ({ vehicleType, ...inputProps }: Props) => {
  return (
    <MaskInput
      mask={getMask(vehicleType)}
      upperCase
      placeholder="А999АА999"
      formatChars={{
        a: `[${cyrLetter}]`,
        '*': `[0-9${cyrLetter}]`,
      }}
      formatMask={formatLicensePlateMask}
      // удаляем пробелы для хранения в БД
      convertOnSave={(value: string) => value.replace(/\s+/g, '')}
      {...inputProps}
    />
  );
};
