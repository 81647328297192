// @flow
import type {
  CalculationStatus,
  FuelAndOilCalculation,
  FuelAndOilCalculationMonth
} from '../types';

import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';
import type { FuelAndOilCalculationType } from '../types';

export const convertDataToServer = (
  fuelAndOilCalculation: FuelAndOilCalculation
): FuelAndOilCalculation => ({
  ...fuelAndOilCalculation,
  sum: convertFromRubToKop(fuelAndOilCalculation.sum),
  months: fuelAndOilCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  fuelAndOilCalculation: FuelAndOilCalculation
): FuelAndOilCalculation => ({
  ...fuelAndOilCalculation,
  sum: convertFromKopToRub(fuelAndOilCalculation.sum),
  months: fuelAndOilCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  fuelAndOilCalculationMonth: FuelAndOilCalculationMonth
): FuelAndOilCalculationMonth => ({
  ...fuelAndOilCalculationMonth,
  // $FlowFixMe count and type в spread операторе
  fuel: {
    ...fuelAndOilCalculationMonth.fuel,
    cost: convertFromRubToKop(fuelAndOilCalculationMonth.fuel?.cost)
  },
  oils: fuelAndOilCalculationMonth.oils?.map(
    (el: FuelAndOilCalculationType) => ({
      ...el,
      cost: convertFromRubToKop(el.cost)
    })
  ),
  oilsSum: convertFromRubToKop(fuelAndOilCalculationMonth.oilsSum)
});

export const convertDataFromServerMonth = (
  fuelAndOilCalculationMonth: FuelAndOilCalculationMonth
): FuelAndOilCalculationMonth => ({
  ...fuelAndOilCalculationMonth,
  // $FlowFixMe count and type в spread операторе
  fuel: {
    ...fuelAndOilCalculationMonth.fuel,
    cost: convertFromKopToRub(fuelAndOilCalculationMonth.fuel?.cost)
  },
  oils: fuelAndOilCalculationMonth.oils?.map(
    (el: FuelAndOilCalculationType) => ({
      ...el,
      cost: convertFromKopToRub(el.cost)
    })
  ),
  oilsSum: convertFromKopToRub(fuelAndOilCalculationMonth.oilsSum)
});

const basedUrl = 'fuelAndOilCalculation';
const fuelAndOilCalculation = new CommonApi<FuelAndOilCalculation>(
  basedUrl,
  'расчет гсм',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  data: FuelAndOilCalculation
): Promise<FuelAndOilCalculation> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations`,
    data
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export default {
  ...fuelAndOilCalculation,
  updateCalculations,
  calculate,
  changeStatus
};
