// @flow
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import Column from 'antd';
import AntIcon from 'antd/lib/icon';
import AntTable from 'antd/lib/table';
import styled from 'styled-components';

import type { ListState } from '../../../../ducks/redux';
import { getListInitialState } from '../../../../lib/helpers';

import type { BranchBudgetSummaryLineItem } from '../../../../lib/types';

const StyledTable = styled(AntTable)`
  .ant-table-row-colored {
    background: #cccccc;
  }
  // Фикс для хрома, для отображениия текста в ячейке, в которой есть стрелка
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-overflow: initial;
    white-space: pre-line;
  }
  // Для нормального отображения по ширине в хроме
  .ant-table-scroll > div.ant-table-header > table,
  .ant-table-scroll > div.ant-table-body > table {
    width: 100% !important;
  }
`;

type Props = {
  data: BranchBudgetSummaryLineItem[],
  columns: Column[],
  loading: boolean,
  defaultExpandAllRows?: boolean,
  expandRowByClick?: boolean,
};

type State = ListState<BranchBudgetSummaryLineItem> & {
  loading: boolean,
};

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
  };

  render() {
    const {
      data,
      columns,
      defaultExpandAllRows = false,
      expandRowByClick = true,
    } = this.props;
    const { loading } = this.state;
    return (
      <>
        <StyledTable
          pagination={false}
          loading={loading}
          dataSource={data}
          columns={columns}
          childrenColumnName="expenseDirections"
          expandRowByClick={expandRowByClick}
          expandIconAsCell={false}
          expandIconColumnIndex={1}
          expandIcon={(row) =>
            !!row.record.expenseDirections && (
              <AntIcon
                type={row.expanded ? 'up' : 'down'}
                style={{
                  fontSize: '12px',
                  float: 'right',
                }}
              />
            )
          }
          bordered
          rowKey="rowId"
          style={{
            overflow: 'auto',
          }}
          scroll={{
            y: 'calc(100vh - 210px)',
            x: 'auto',
          }}
          rowClassName={(record) => {
            return record.rowId < 0 ? 'ant-table-row-colored' : null;
          }}
          defaultExpandAllRows={defaultExpandAllRows}
        />
      </>
    );
  }
}
