// @flow
// TODO: Переписать нормально
import {
  type TableProps as AntTableProps,
  Table as StyledNewBaseTable,
} from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import debounce from 'lodash/debounce';
import styled, { createGlobalStyle } from 'styled-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { StyledBaseTable } from './StyledBaseTable';
import { TableHeaderCell } from './TableHeaderCell';
import { TableToolbar } from './TableToolbar';

const GlobalStyle = createGlobalStyle`
  .dragLineSplitter {
    border-left: 2px dashed var(--focused-item-color) !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-table {
    font-size: 12px;
  }
  .ant-table-body {
    overflow-x: auto;
  }
  .ant-table-thead > tr > th {
    background: #f0f4f8;
    position: relative;
    padding: 0;
    border-bottom: none;
    border-top: 1px solid #c0ccd7;
    cursor: pointer;
    user-select: none;
    padding: 5px;
  }
  .ant-table-thead > tr > th:hover {
    background: #efeff8;
  }

  .ant-table-tbody .ant-table-row {
    position: relative;
    cursor: pointer;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #3d4042;
    height: 40px;
  }

  .ant-table-column-sorters {
    padding: 6px 10px;
  }

  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize;
  }
  .ant-table-cell-fix-sticky {
    z-index: 99;
  }
`;

type TableFlag = 'useNewStyle';

type TableProps = AntTableProps & {
  flags?: TableFlag[],
  name?: string,
  customStyle?: any,
  customScroll?: any,
};

const hashString = (s: string): number => {
  let hash = 0,
    i,
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

type AntColumnType = ColumnType<any>;

export type CustomColumnType = AntColumnType & {
  visible?: boolean,
  hashId: number,
};

const Table = (props: TableProps) => {
  const [localColumns, setLocalColumns] = useState<CustomColumnType[]>([]);

  const visibleLocalColumns = useCallback(() => {
    return localColumns?.filter((localColumn) => !!localColumn.visible) || [];
  }, [localColumns]);

  const handleResize = useCallback((hashId, size) => {
    setLocalColumns((columns) => {
      const nextColumns = [...columns];
      let resizedColumnIndex = nextColumns.findIndex(
        (col) => col.hashId === hashId
      );
      nextColumns[resizedColumnIndex] = {
        ...nextColumns[resizedColumnIndex],
        width: size.width,
      };
      return nextColumns;
    });
  }, []);

  const reorderColumns = (sIndex: number, dIndex: number) => {
    setLocalColumns((cols) => {
      let columns = [...cols];
      const reorderedColumn = columns[sIndex];
      const lastLeftFixedIndex = columns
        .map((column: CustomColumnType) => !!column.fixed)
        .lastIndexOf(true);
      if (reorderedColumn) {
        if (!reorderedColumn.fixed && dIndex <= lastLeftFixedIndex) return cols;
        if (reorderedColumn.fixed && dIndex >= lastLeftFixedIndex) return cols;

        columns.splice(dIndex + (dIndex >= sIndex ? 1 : 0), 0, reorderedColumn);
        columns.splice(sIndex + (dIndex >= sIndex ? 0 : 1), 1);

        columns = columns.map((column, index) => {
          column.index = index;
          return column;
        });
      }
      return columns;
    });
  };

  const handleColumnsChange = (
    changedColumn: CustomColumnType,
    changedValue: any
  ) => {
    let pickUnPickColumnIndex: number;
    let lastLeftFixedIndex: number;
    setLocalColumns((localColumns: CustomColumnType[]) => {
      const currentIndex = localColumns.findIndex(
        (localColumn: CustomColumnType) =>
          localColumn.hashId === changedColumn.hashId
      );
      let cols = [...localColumns];
      if (
        changedValue.fixed != null &&
        cols[currentIndex].fixed !== changedValue.fixed
      ) {
        pickUnPickColumnIndex = currentIndex;
        lastLeftFixedIndex = localColumns
          .map((column: CustomColumnType) => !!column.fixed)
          .lastIndexOf(true);
      }

      cols[currentIndex] = {
        ...cols[currentIndex],
        ...changedValue,
      };

      if (pickUnPickColumnIndex >= 0) {
        const sIndex = pickUnPickColumnIndex;
        const dIndex =
          lastLeftFixedIndex +
          (pickUnPickColumnIndex > lastLeftFixedIndex ? 1 : 0);
        const reorderedColumn = cols[sIndex];
        cols.splice(dIndex + (dIndex >= sIndex ? 1 : 0), 0, reorderedColumn);
        cols.splice(sIndex + (dIndex >= sIndex ? 0 : 1), 1);

        cols = cols.map((column, index) => {
          column.index = index;
          return column;
        });
      }

      return cols;
    });
  };

  const handleColumnsReset = () => {
    setLocalColumns(
      props.columns.map((column: AntColumnType, index): CustomColumnType => ({
        ...column,
        hashId: hashString(
          column?.title?.toString() ?? '' + column?.dataIndex?.toString() ?? ''
        ),
        visible: true,
        onHeaderCell: (column: CustomColumnType) => ({
          title: column.title,
          hashId: column.hashId,
          width: column.width,
          index: index,
          onResize: handleResize,
        }),
      }))
    );
  };

  const saveColumnsToLocalStorage = useMemo(
    () =>
      debounce(() => {
        if (props.name) {
          window.localStorage.setItem(
            `table-columns-state-${props.name}`,
            JSON.stringify(localColumns)
          );
        }
      }, 800),
    [localColumns, props.name]
  );

  useEffect(() => {
    let cols = window.localStorage.getItem(`table-columns-state-${props.name}`);
    if (!cols && Array.isArray(props.columns)) {
      cols = props.columns.map((column) => ({ ...column, visible: true }));
    } else {
      cols = JSON.parse(cols);
    }
    setLocalColumns(
      cols?.map((column: AntColumnType, index): CustomColumnType => ({
        ...props.columns.find(
          (col: AntColumnType) =>
            hashString(
              column?.title?.toString() ??
                '' + column?.dataIndex?.toString() ??
                ''
            ) ===
            hashString(
              col?.title?.toString() ?? '' + col?.dataIndex?.toString() ?? ''
            )
        ),
        ...column,
        hashId: hashString(
          column?.title?.toString() ?? '' + column?.dataIndex?.toString() ?? ''
        ),
        onHeaderCell: (column: CustomColumnType) => ({
          title: column.title,
          hashId: column.hashId,
          width: column.width,
          index: index,
          onResize: handleResize,
        }),
      })) ?? []
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    saveColumnsToLocalStorage();
    // eslint-disable-next-line
  }, [localColumns]);
  if (!props.flags?.includes('useNewStyle'))
    return <StyledBaseTable {...props} />;

  return (
    <Wrapper>
      <GlobalStyle />
      <TableToolbar
        columns={localColumns}
        onColumnsChange={handleColumnsChange}
        onColumnsReset={handleColumnsReset}
      />
      <ReactDragListView.DragColumn
        onDragEnd={(fromIndex, toIndex) => {
          const columns = [...visibleLocalColumns()];
          const visibleColFrom = columns[fromIndex];
          const visibleColTo = columns[toIndex];

          reorderColumns(
            localColumns.findIndex(
              (col) => col.hashId === visibleColFrom.hashId
            ),
            localColumns.findIndex((col) => col.hashId === visibleColTo.hashId)
          );
        }}
        nodeSelector="th"
        handleSelector="span > svg"
        lineClassName="dragLineSplitter"
      >
        <StyledNewBaseTable
          bordered
          // scroll={{ x: 'max-content' }}
          {...props}
          components={{
            header: {
              cell: TableHeaderCell,
            },
          }}
          columns={visibleLocalColumns()}
          scroll={{
            x: 'auto',
            y: 'auto',
            ...props.customScroll,
          }}
          style={{
            position: 'relative',
            flex: '1',
            overflowY: 'auto',
            overflowX: 'hidden',
            ...props.customStyle,
          }}
          sticky
        />
      </ReactDragListView.DragColumn>
    </Wrapper>
  );
};

export { Table };
