// @flow
import React from 'react';
import { navigate } from '@reach/router';
import qs from 'query-string';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { compareExpenseDirectionApi } from '../../lib/api';
import {
  formatDateTimeToString,
  getListInitialState,
} from './../../lib/helpers';
import type {
  CompareExpenseDirection,
  ListState,
  MaintenanceWorkType,
  OrderContractorType,
  RepairPlan,
  UserAccess,
} from '../../lib/types';
import {
  accessTypeEnum,
  orderContractorTypes,
  repairPlanTypes,
} from './../../lib/enum';
import { Popconfirm, Icon } from '../../components/ui';
import Table from '../../components/ui/Table';
import { Header, Section } from '../../components/layout';
import { notificationLoading } from './../../components/Notifications';

import { withUserAccess } from './../withUserAccess';
import type { CompareExpenseDirectionFilterParams } from './components/Filter';
import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
};

type State = ListState<CompareExpenseDirection> & {
  filter: CompareExpenseDirectionFilterParams,
};

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingCompareExpenseDirections,
];

export class CompareExpenseDirectionList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  columns = [
    {
      title: 'Номер',
      dataIndex: 'id',
    },
    {
      title: 'Вид работ',
      dataIndex: 'maintenanceWorkType',
      render: (maintenanceWorkType: MaintenanceWorkType) =>
        maintenanceWorkType ? maintenanceWorkType.name : '',
    },
    {
      title: 'Способ выполнения',
      dataIndex: 'orderContractor',
      render: (contractor: OrderContractorType) =>
        orderContractorTypes[contractor],
    },
    {
      title: 'График работ',
      dataIndex: 'repairPlan',
      render: (repairPlan: RepairPlan) => repairPlanTypes[repairPlan],
    },
    {
      title: 'Наименование расхода',
      dataIndex: 'expenseDirectionName',
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      width: '150',
      render: (startDate: string) =>
        `${formatDateTimeToString(startDate, 'DD.MM.YYYY')}`,
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      width: '150',
      render: (endDate: string) =>
        `${formatDateTimeToString(endDate, 'DD.MM.YYYY')}`,
    },
  ];

  componentDidMount = async () => {
    const { page } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteCompareExpenseDirection(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
      });
    }
    await this.fetchCompareExpenseDirection(page);
  };

  deleteCompareExpenseDirection = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await compareExpenseDirectionApi.deleteCompareExpenseDirection(id);
      await this.fetchCompareExpenseDirection(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchCompareExpenseDirection = async (page: number) => {
    const data = await compareExpenseDirectionApi.fetchCompareExpenseDirection({
      ...this.state.filter,
      page,
    });
    this.setState({ ...data, page, loading: false });
  };

  canAdd = () =>
    this.props.userAccess.some((access) => addAccess.includes(access));

  applyFilter = (filter: CompareExpenseDirectionFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchCompareExpenseDirection(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchCompareExpenseDirection(page)
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Сопоставление расходов и видов работ</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() => navigate('/admin/compare-expense-direction/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchCompareExpenseDirection}
            fetchOnMount
            onRow={(row: CompareExpenseDirection) => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/compare-expense-direction/${row.id}/edit`)
                  : navigate(`/admin/compare-expense-direction/${row.id}`),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(CompareExpenseDirectionList);
